import { useToast } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"
import {
  useAdminCreateDemographic,
  useAdminUpdateDemographic,
} from "~/api/generated/usabilityhub-components"
import { DemographicAttribute } from "./types"

const CreateDemographicSchema = Yup.object({
  name: Yup.string()
    .max(255, "Name is too long")
    .required("Test name is required"),
  question: Yup.string()
    .max(255, "Question is too long")
    .required("Question is required"),
  demographic_attribute_group_id: Yup.number().required(),
  profile_helper: Yup.string().nullable(),
  target_helper: Yup.string().nullable(),
  multi_select: Yup.boolean().required(),
  sort_order: Yup.number().required(),
  individual_sort_order: Yup.number().required(),
  visible_to_panelists: Yup.boolean().required(),
  visible_to_customers: Yup.boolean().required(),
  options: Yup.array()
    .of(
      Yup.object({
        id: Yup.number().defined().nullable(),
        value: Yup.string().required(),
        profile_helper: Yup.string().defined().nullable(),
        is_none_of_above: Yup.boolean().required(),
        is_prefer_not_to_say: Yup.boolean().required(),
      })
    )
    .required(),
})

type CreateDemographicType = Yup.InferType<typeof CreateDemographicSchema>

const DEFAULT_FORM_VALUES = {
  id: null,
  group: null,
  multi_select: false,
  options: [],
}

export const useDemographicForm = (
  demographicAttribute: DemographicAttribute | null
) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  // Remove any attributes from the serialization that we can't send back to the server
  const { id, group, ...formValues } =
    demographicAttribute ?? DEFAULT_FORM_VALUES

  const form = useForm<CreateDemographicType>({
    resolver: yupResolver(CreateDemographicSchema),
    defaultValues: formValues,
  })

  const { mutateAsync: createDemographic } = useAdminCreateDemographic({
    onSuccess: () => {
      toast({
        title: "Demographic attribute created",
        status: "success",
        position: "top-right",
      })
      return queryClient.invalidateQueries(["api", "admin", "demographics"], {
        exact: true,
      })
    },
    onError: (e) => {
      toast({
        title: "Demographic attribute could not be created",
        description: e.payload.message,
        status: "error",
        position: "top-right",
      })
    },
  })

  const { mutateAsync: updateDemographic } = useAdminUpdateDemographic({
    onSuccess: () => {
      toast({
        title: "Demographic attribute updated",
        status: "success",
        position: "top-right",
      })
      return queryClient.invalidateQueries(["api", "admin", "demographics"], {
        exact: true,
      })
    },
    onError: (e) => {
      toast({
        title: "Demographic attribute could not be updated",
        description: e.payload.message,
        status: "error",
        position: "top-right",
      })
    },
  })

  const onSubmit: SubmitHandler<CreateDemographicType> = async (values) => {
    if (!values || !values.options) return

    if (demographicAttribute) {
      await updateDemographic({
        pathParams: { demographicAttributeId: demographicAttribute.id },
        body: values,
      })
    } else {
      await createDemographic({ body: values })
    }
  }

  return [form, onSubmit] as const
}
