import {
  CompressedCategory,
  compressResponseFilterCategory,
  decompressResponseFilterCategory,
} from "./compress-filter-category"
import { ResponseFilter } from "./filters/types"

/**
 * When changing this type, please keep in mind that it will break the existing URL and the result page wont be able to read the correct filters
 */
export type CompressedResponseFilters = {
  [category in CompressedCategory]: {
    [type in ResponseFilter["type"]]: {
      id: string
      value: any
    }[]
  }
}

export function compressFilters(
  filters: ResponseFilter[]
): CompressedResponseFilters {
  const compressedFilters = filters.reduce((compressedFilters, filter) => {
    const category = compressResponseFilterCategory(filter.category)

    if (!(category in compressedFilters)) {
      //@ts-ignore
      compressedFilters[category] = {}
    }

    if (filter.type in compressedFilters[category]!) {
      compressedFilters[category]![filter.type] = [
        ...compressedFilters[category]![filter.type],
        {
          id: filter.id,
          value: filter.value,
        },
      ]
    } else {
      compressedFilters[category] = {
        ...compressedFilters[category],
        [filter.type]: [
          {
            id: filter.id,
            value: filter.value,
          },
        ],
      }
    }

    return compressedFilters
  }, {} as CompressedResponseFilters)

  return compressedFilters
}

export function decompressFilters(
  compressedFilters: CompressedResponseFilters
): ResponseFilter[] {
  const decompressedFilters = Object.entries(compressedFilters).flatMap(
    ([compressedCategory, typeValues]) => {
      return Object.entries(typeValues).flatMap(([type, baseFilter]) => {
        return baseFilter.map((baseFilter) => ({
          category: decompressResponseFilterCategory(compressedCategory),
          type,
          ...baseFilter,
        }))
      })
    }
  )

  return decompressedFilters as ResponseFilter[]
}
