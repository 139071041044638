import { Flex, Link } from "@chakra-ui/react"
import { Alert } from "DesignSystem/components"
import { CustomerSupportMailtoLink } from "Shared/components/Links/CustomerSupportMailtoLink"
import { ThirdPartyQuote } from "Types"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { useOrderForm } from "../OrderFormProvider"
import { QuoteTable } from "../QuoteTable"
import { ValidationError } from "../ValidationError"
import { ExternalPanelSubmitOrder } from "./ExternalPanelSubmitOrder"
import { useExternalPanelOrderQuote } from "./useExternalPanelOrderQuote"

export const ExternalPanelTab: React.FC = () => {
  const { latestQuote, quoteState } = useExternalPanelOrderQuote()
  const canUserPurchaseCredits = useCurrentUser().can_purchase_credits
  const { targetLocations } = useOrderForm()

  const targetingNonCountryLocations = targetLocations.some(
    (tl) => tl.type !== "country"
  )

  const hideQuoteErrors = targetingNonCountryLocations

  return (
    <Flex flexDirection="column" gap={2}>
      <QuoteTable quote={latestQuote} quoteState={quoteState} />

      {latestQuote && !hideQuoteErrors && (
        <ExternalOrderQuoteErrors
          quote={latestQuote}
          canUserPurchaseCredits={canUserPurchaseCredits}
        />
      )}

      <ExternalPanelSubmitOrder quote={latestQuote} quoteState={quoteState} />
    </Flex>
  )
}

type ExternalOrderQuoteErrorsProps = {
  quote: ThirdPartyQuote
  canUserPurchaseCredits: boolean
}

const ExternalOrderQuoteErrors: React.FC<ExternalOrderQuoteErrorsProps> = ({
  quote,
  canUserPurchaseCredits,
}) => {
  if (!quote.eligible) {
    return (
      <ValidationError>
        Your account is not eligible to place an external panel order. Please{" "}
        {window.Intercom ? (
          <Link onClick={() => window.Intercom?.("show")}>contact support</Link>
        ) : (
          <CustomerSupportMailtoLink>contact support</CustomerSupportMailtoLink>
        )}{" "}
        for assistance.
      </ValidationError>
    )
  }

  if ("errors" in quote && Object.keys(quote.errors).length > 0) {
    return (
      <Flex direction="column">
        {quote.errors.test_too_long && (
          <ValidationError>
            The maximum test length for an external panel order is 20 minutes.
            Please reduce the length of your test, or consider placing a Lyssna
            panel order instead.
          </ValidationError>
        )}

        {quote.errors.no_country_selected && (
          <ValidationError>
            You must select at least one target country for an external panel
            order.
          </ValidationError>
        )}
      </Flex>
    )
  }

  if (!quote.available) {
    return (
      <Alert
        status="warning"
        description={`Sorry, we can\u2019t complete that order from our external panel.`}
      />
    )
  }

  if (!canUserPurchaseCredits && quote.credits_to_be_purchased) {
    return (
      <Alert
        status="warning"
        description={`Placing this order costs money, but you don\u2019t have permission
          to use the credit card stored on this account. Please ask an account
          admin to place this order, or to pre-purchase credits for you to
          spend.`}
      />
    )
  }

  return null
}
