import Fuse from "fuse.js"
import { flatten, keys, values } from "lodash"
import React, {
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { useGetDynamicTemplates } from "~/api/generated/usabilityhub-components"
import {
  AllCategories,
  CATEGORIES,
  Category,
  FILTER_FIELDS,
  Template,
  loadTemplateData,
} from "~/usabilityhub/types/templateLibrary"
import { ROUTES } from "~/usabilityhub/views/routes"

type TemplateLibraryListItem = Fuse.FuseResult<Template>

type TemplateLibraryContextType = {
  loading: boolean
  templates: Template[]
  template: Template | null
  filtered: TemplateLibraryListItem[]
  categories: AllCategories
  filter: Category | null
  counts: Record<Category["id"], number>
  setFilter: Dispatch<SetStateAction<Category | null>>
  query: string
  setQuery: Dispatch<SetStateAction<string>>
  error: ReactNode | null
  setError: Dispatch<SetStateAction<ReactNode | null>>
}

const TemplateLibraryContext = createContext({} as TemplateLibraryContextType)

type TemplateLibraryProviderProps = PropsWithChildren<{
  open: boolean
}>

const minMatchCharLength = 3

const fuseOptions: Fuse.IFuseOptions<Template> = {
  keys: ["title"],
  minMatchCharLength,
  includeMatches: true,
}

export const TemplateLibraryProvider: React.FC<
  TemplateLibraryProviderProps
> = ({ open, children }) => {
  const [templates, setTemplates] = useState<Template[]>([])

  const fuse = useMemo(() => new Fuse(templates, fuseOptions), [templates])

  const [categories, setCategories] = useState<AllCategories>(() =>
    CATEGORIES.reduce(
      (acc, category) => ({ ...acc, [category]: new Map() }),
      {} as AllCategories
    )
  )

  const [error, setError] = useState<ReactNode | null>(null)

  const [filter, setFilter] = useState<Category | null>(null)

  const [query, setQuery] = useState<string>("")

  const { templateId } = useTypedParams(ROUTES.DASHBOARD.TEMPLATES.DETAILS)

  const { isLoading, data, isError } = useGetDynamicTemplates(
    {},
    { enabled: !!open }
  )
  // loading is complete once the query loading is complete and either failed, or we've updated the templates
  const loading = isLoading || (!isError && templates.length === 0)

  useEffect(() => {
    if (!data) return

    const { templates, categories } = loadTemplateData(data)
    setCategories(categories)
    setTemplates(Array.from(templates.values()))
  }, [data])

  const searched = useMemo(
    () =>
      query.length >= minMatchCharLength
        ? fuse.search(query)
        : templates.map((t, i) => ({ item: t, refIndex: i, matches: [] })),
    [query, templates]
  )

  const filtered = useMemo(
    (): TemplateLibraryListItem[] =>
      filter
        ? searched.filter(({ item: template }) =>
            template[FILTER_FIELDS[filter.type]].find((t) => t.id === filter.id)
          )
        : searched,
    [filter, query, searched]
  )

  const zeroCounts = useMemo(
    () =>
      flatten(values(categories).map(keys)).reduce(
        (counts, c) => ({ ...counts, [c]: 0 }),
        {}
      ),
    [categories]
  )

  const counts = useMemo(
    () =>
      searched.reduce(
        (acc: Record<Category["id"], number>, template) => {
          keys(categories).forEach((category: keyof AllCategories) => {
            template.item[category].forEach((c) => {
              acc[c.id] = (acc[c.id] || 0) + 1
            })
          })
          return acc
        },
        { ...zeroCounts }
      ),
    [zeroCounts, categories, filtered]
  )
  counts["total"] = searched.length

  const templateRef = useRef<Template | null>(null)

  const template = useMemo(
    () => templates.find((t) => t.id === templateId) || null,
    [templateId, templates]
  )

  if (template) templateRef.current = template

  const creationTimeout = useRef<number>()

  useEffect(() => {
    return () => {
      clearTimeout(creationTimeout.current)
    }
  }, [])

  return (
    <TemplateLibraryContext.Provider
      value={{
        loading,
        templates,
        filtered,
        template: templateRef.current,
        categories,
        counts,
        filter,
        setFilter,
        query,
        setQuery,
        error,
        setError,
      }}
    >
      {children}
    </TemplateLibraryContext.Provider>
  )
}

export const useTemplateLibrary = () => useContext(TemplateLibraryContext)
