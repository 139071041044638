import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { JOB_ROLES } from "Constants/job-role"
import {
  PLACEHOLDER_EMAIL,
  PLACEHOLDER_NAME,
  PLACEHOLDER_PREFERRED_NAME,
} from "Constants/placeholders"
import {
  Card,
  CardBody,
  CardFooter,
  LegacyCardHeader,
} from "Shared/components/Card/Card"
import { CustomerSupportMailtoLink } from "Shared/components/Links/CustomerSupportMailtoLink"
import { HelpCenterLink } from "Shared/components/Links/HelpCenterLink"
import { AvatarField } from "UsabilityHub/components/AvatarField/AvatarField"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { mapValues, omitBy, pickBy } from "lodash"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import * as Yup from "yup"
import { useUpdateProfile } from "~/api/generated/usabilityhub-components"
import { AccountArchivedBanner } from "./AccountArchivedBanner"

const schema = Yup.object({
  name: Yup.string().required(),
  preferred_name: Yup.string().required(),
  email: Yup.string().email().required(),
  job_role: Yup.string().required(),
  has_custom_job_role: Yup.boolean(),
  custom_job_role: Yup.string().when(
    ["job_role", "has_custom_job_role"],
    ([job_role, has_custom_job_role]) =>
      has_custom_job_role || job_role === "Other"
        ? Yup.string().required("Please enter a role title")
        : Yup.string().transform(() => "")
  ),
  avatar: Yup.mixed().transform(
    (fileList: FileList | undefined) => fileList && fileList[0]
  ),
  email_general: Yup.boolean(),
  email_newsletters_and_promotions: Yup.boolean(),
  email_comment_notifications: Yup.boolean(),
  email_product_updates: Yup.boolean(),
})

type Profile = Yup.InferType<typeof schema>

type PersonalDetails = React.FC<React.PropsWithChildren>

export const PersonalDetails: PersonalDetails = () => {
  const queryClient = useQueryClient()
  const currentUser = useCurrentUser()
  const toast = useToast()
  const {
    custom_job_role,
    email,
    email_general,
    email_newsletters_and_promotions,
    email_comment_notifications,
    email_product_updates,
    job_role,
    omniauth_service: omniauthService,
    name,
    preferred_name,
    first_name,
  } = currentUser
  const currentUserIsOmniauthed = omniauthService !== null

  const { mutateAsync: updateProfile, isLoading: isSubmitting } =
    useUpdateProfile({
      onSuccess: async ({ message }) => {
        await queryClient.invalidateQueries(["api", "accounts", "current"])
        toast({
          title: message,
          status: "success",
        })
      },
    })

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setFocus,
    getValues,
    setValue,
  } = useForm<Profile>({
    resolver: yupResolver(schema),
    defaultValues: {
      name,
      preferred_name: preferred_name || first_name || name,
      email,
      job_role,
      has_custom_job_role: !!custom_job_role || false,
      custom_job_role: custom_job_role || "",
      avatar: undefined,
      email_general,
      email_newsletters_and_promotions,
      email_comment_notifications,
      email_product_updates,
    },
  })

  const jobRole = watch("job_role")
  const hasCustomJobRole = watch("has_custom_job_role")

  useEffect(() => {
    if (hasCustomJobRole) {
      setFocus("custom_job_role")
    } else {
      setValue("custom_job_role", "", { shouldTouch: true })
    }
  }, [hasCustomJobRole, setValue, setFocus])

  useEffect(() => {
    if (jobRole === "Other") {
      setValue("has_custom_job_role", true, { shouldTouch: true })
    } else if (!getValues().custom_job_role?.trim()) {
      setValue("has_custom_job_role", false, { shouldTouch: true })
    }
  }, [jobRole, setValue, getValues])

  const submit = async (user: Profile) => {
    updateProfile({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: {
        ...omitBy(user, (_, key) => key.startsWith("email_")),
        ...mapValues(
          pickBy(user, (_, key) => key.startsWith("email_")),
          (value) => JSON.stringify(value)
        ),
      },
    }).catch((error) => {
      if (error.payload) {
        toast({
          title: error.payload.message,
          status: "error",
        })
      } else {
        throw error
      }
    })
  }

  return (
    <>
      {currentUser.role === "archived" && <AccountArchivedBanner />}

      <form onSubmit={handleSubmit(submit)}>
        <Card>
          <LegacyCardHeader>Update your personal details</LegacyCardHeader>
          <CardBody>
            {currentUserIsOmniauthed && (
              <Alert status="warning">
                <AlertIcon />
                <AlertDescription>
                  You can{"\u2019"}t change your email because your account is
                  authenticated with {omniauthService}.{" "}
                  {omniauthService === "Google" && (
                    <>
                      For more information, head to the{" "}
                      <HelpCenterLink articleSlug="5079020-signing-up-with-google">
                        help center
                      </HelpCenterLink>{" "}
                      or{" "}
                      <CustomerSupportMailtoLink>
                        contact our support team
                      </CustomerSupportMailtoLink>
                      .
                    </>
                  )}
                </AlertDescription>
              </Alert>
            )}
            <FormControl>
              <FormLabel htmlFor="user.name">Full name</FormLabel>
              <Input
                {...register("name")}
                id="user.name"
                isRequired
                placeholder={PLACEHOLDER_NAME}
                type="text"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="user.preferred_name">
                Preferred name
              </FormLabel>
              <Input
                {...register("preferred_name")}
                id="user.preferred_name"
                isRequired
                placeholder={PLACEHOLDER_PREFERRED_NAME}
                type="text"
              />
            </FormControl>
            <FormControl isDisabled={currentUserIsOmniauthed}>
              <FormLabel htmlFor="user.email">Email</FormLabel>
              <Input
                {...register("email")}
                id="user.email"
                isRequired
                placeholder={PLACEHOLDER_EMAIL}
                type="email"
              />
            </FormControl>
            <Stack>
              <FormControl>
                <FormLabel htmlFor="user.job_role">Your role</FormLabel>
                <Select {...register("job_role")} id="user.job_role">
                  <option hidden disabled value="Unspecified">
                    Choose a role
                  </option>
                  {JOB_ROLES.map((jr) => (
                    <option key={jr} value={jr}>
                      {jr}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Stack
                display={jobRole && jobRole !== "Unspecified" ? "flex" : "none"}
              >
                <Checkbox
                  {...register("has_custom_job_role")}
                  isChecked={hasCustomJobRole}
                  isDisabled={jobRole === "Other"}
                  sx={{
                    "& span[data-disabled]": {
                      opacity: 1,
                    },
                  }}
                >
                  Customize my role title
                </Checkbox>
                <FormControl
                  ml={6}
                  w="auto"
                  display={hasCustomJobRole ? "block" : "none"}
                  isInvalid={!!errors.custom_job_role || undefined}
                >
                  <Input
                    {...register("custom_job_role")}
                    id="user.custom_job_role"
                    placeholder="Enter your custom title"
                    type="text"
                    aria-label="Custom role title"
                  />
                  {errors.custom_job_role && (
                    <FormErrorMessage>
                      {errors.custom_job_role.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
            </Stack>
            <FormControl>
              <FormLabel>Avatar</FormLabel>
              <AvatarField
                user={{
                  name: currentUser.name,
                  avatarUrl: currentUser.avatar_url_medium,
                }}
                {...register("avatar")}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Contact preferences</FormLabel>
              <Stack>
                <Checkbox
                  variant="mdWithSmFont"
                  {...register("email_general")}
                  id="email_general"
                >
                  Send me emails about my account and tests
                </Checkbox>
                <Checkbox
                  variant="mdWithSmFont"
                  {...register("email_comment_notifications")}
                  id="email_comment_notifications"
                >
                  Send me notifications about new comments
                </Checkbox>
                <Checkbox
                  variant="mdWithSmFont"
                  {...register("email_newsletters_and_promotions")}
                  id="email_newsletters_and_promotions"
                >
                  Send me occasional newsletters and special offers
                </Checkbox>
                <Checkbox
                  variant="mdWithSmFont"
                  {...register("email_product_updates")}
                  id="email_product_updates"
                >
                  Send me notifications on product updates
                </Checkbox>
              </Stack>
            </FormControl>
            <CardFooter>
              <Button
                isLoading={isSubmitting}
                type="submit"
                colorScheme="brand.primary"
              >
                Save personal details
              </Button>
            </CardFooter>
          </CardBody>
        </Card>
      </form>
    </>
  )
}
