export enum ActionType {
  INIT_USABILITY_TESTS = "INIT_USABILITY_TESTS",

  UPDATE_USABILITY_TEST_REQUEST = "UPDATE_USABILITY_TEST_REQUEST",
  UPDATE_USABILITY_TEST_SUCCESS = "UPDATE_USABILITY_TEST_SUCCESS",
  UPDATE_USABILITY_TEST_FAILURE = "UPDATE_USABILITY_TEST_FAILURE",

  DELETE_USABILITY_TEST_REQUEST = "DELETE_USABILITY_TEST_REQUEST",
  DELETE_USABILITY_TEST_SUCCESS = "DELETE_USABILITY_TEST_SUCCESS",
  DELETE_USABILITY_TEST_FAILURE = "DELETE_USABILITY_TEST_FAILURE",

  DUPLICATE_USABILITY_TEST_REQUEST = "DUPLICATE_USABILITY_TEST_REQUEST",
  DUPLICATE_USABILITY_TEST_SUCCESS = "DUPLICATE_USABILITY_TEST_SUCCESS",
  DUPLICATE_USABILITY_TEST_FAILURE = "DUPLICATE_USABILITY_TEST_FAILURE",

  RELOAD_USABILITY_TEST_SUCCESS = "RELOAD_USABILITY_TEST_SUCCESS",
}
