import { createIcon } from "@chakra-ui/react"
import React from "react"

export const RecordThinkAloudIcon = createIcon({
  displayName: "RecordThinkAloudIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0001 3.99997C8.06709 3.99997 6.50008 5.56697 6.50008 7.49997C6.50008 9.43296 8.06709 11 10.0001 11C11.9331 11 13.5001 9.43296 13.5001 7.49997C13.5001 5.56697 11.9331 3.99997 10.0001 3.99997ZM4.50008 7.49997C4.50008 4.4624 6.96252 1.99997 10.0001 1.99997C13.0376 1.99997 15.5001 4.4624 15.5001 7.49997C15.5001 10.5375 13.0376 13 10.0001 13C6.96252 13 4.50008 10.5375 4.50008 7.49997ZM7.32635 14.4999C7.38341 14.4999 7.44132 14.5 7.50009 14.5H12.5001C12.5589 14.5 12.6168 14.4999 12.6738 14.4999C13.9011 14.4995 14.7391 14.4992 15.4515 14.7153C17.0495 15.2 18.3 16.4505 18.7848 18.0485C19.0009 18.7609 19.0006 19.599 19.0001 20.8262C19.0001 20.8833 19.0001 20.9412 19.0001 21C19.0001 21.5522 18.5524 22 18.0001 22C17.4478 22 17.0001 21.5522 17.0001 21C17.0001 19.5316 16.9892 19.0192 16.8709 18.6291C16.5801 17.6703 15.8297 16.92 14.8709 16.6291C14.4809 16.5108 13.9684 16.5 12.5001 16.5H7.50009C6.03177 16.5 5.51929 16.5108 5.12923 16.6291C4.17042 16.92 3.42011 17.6703 3.12926 18.6291C3.01094 19.0192 3.00008 19.5316 3.00008 21C3.00008 21.5522 2.55237 22 2.00008 22C1.4478 22 1.00008 21.5522 1.00008 21C1.00008 20.9412 1.00006 20.8833 1.00004 20.8262C0.999587 19.599 0.999277 18.7609 1.21538 18.0485C1.70013 16.4505 2.95065 15.2 4.54866 14.7153C5.26106 14.4992 6.09908 14.4995 7.32635 14.4999Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3898 1.11103C20.8808 0.858065 21.4838 1.05099 21.7368 1.54193C22.5394 3.09965 23.0001 4.98479 23.0001 6.99997C23.0001 9.01514 22.5394 10.9003 21.7368 12.458C21.4838 12.9489 20.8808 13.1419 20.3898 12.8889C19.8989 12.6359 19.706 12.0329 19.9589 11.5419C20.6066 10.285 21.0001 8.71674 21.0001 6.99997C21.0001 5.28319 20.6066 3.71491 19.9589 2.458C19.706 1.96705 19.8989 1.36399 20.3898 1.11103ZM17.5532 3.24826C18.0472 3.00142 18.6478 3.20183 18.8946 3.69589C19.3727 4.65265 19.642 5.79182 19.642 6.99997C19.642 8.20811 19.3727 9.34728 18.8946 10.304C18.6478 10.7981 18.0472 10.9985 17.5532 10.7517C17.0591 10.5048 16.8587 9.90423 17.1055 9.41017C17.4368 8.74717 17.642 7.91646 17.642 6.99997C17.642 6.08347 17.4368 5.25276 17.1055 4.58976C16.8587 4.0957 17.0591 3.49509 17.5532 3.24826Z"
        fill="currentColor"
      />
    </>
  ),
})
