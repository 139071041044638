import classNames from "classnames"
import React, {
  Children,
  MouseEvent,
  PropsWithChildren,
  PureComponent,
} from "react"

import styles from "Components/hitzone/hitzone.module.scss"
import { Corner } from "Types"

function cornerClassName(corner: Corner): string {
  return [
    corner[0] === 0 ? styles.left : styles.right,
    corner[1] === 0 ? styles.top : styles.bottom,
  ].join(" ")
}

interface Props {
  corner: Corner
  onMouseDown: (corner: Corner, event: MouseEvent<HTMLElement>) => void
}

export default class Handle extends PureComponent<PropsWithChildren<Props>> {
  private handleMouseDown = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    const { onMouseDown, corner } = this.props
    onMouseDown(corner, event)
  }

  render() {
    const { children, corner } = this.props
    return (
      <div
        className={classNames(
          styles.handle,
          cornerClassName(corner),
          Children.count(children) > 0 && styles.hasChildren
        )}
        onMouseDown={this.handleMouseDown}
      >
        {children}
      </div>
    )
  }
}
