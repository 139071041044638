import { devices } from "Constants/devices"
import {
  DEVICE_FRAME_SVG_TO_DOM_SCALE,
  MAX_DEVICE_FRAME_SIZE_SCALE,
} from "Constants/styles"
import androidPath from "Images/device_frames/android.svg"
import iPhonePath from "Images/device_frames/iphone.svg"
import tabletPath from "Images/device_frames/tablet.svg"
import { DeviceFrame, DeviceFrameType, Dimensions } from "Types"

export function maximumDeviceFrameScreenDimensions(
  deviceFrame: DeviceFrame
): Dimensions {
  const deviceProperties = devices[deviceFrame.device_frame_type]
  const scale = MAX_DEVICE_FRAME_SIZE_SCALE * DEVICE_FRAME_SVG_TO_DOM_SCALE
  const widthProperty =
    deviceFrame.device_orientation === "portrait"
      ? "screenWidth"
      : "screenHeight"
  const heightProperty =
    deviceFrame.device_orientation === "portrait"
      ? "screenHeight"
      : "screenWidth"
  return {
    width: deviceProperties[widthProperty] * scale,
    height: deviceProperties[heightProperty] * scale,
  }
}

export function deviceFramePath(type: DeviceFrameType): string {
  switch (type) {
    case "android":
      return androidPath
    case "iphone":
      return iPhonePath
    case "tablet":
      return tabletPath
  }
}
