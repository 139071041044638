import { useCallback, useContext } from "react"

import { TestResultsFilterState } from "Components/test-results/context/test-result-filter-state"
import { createPrototypeNodeFilter } from "Components/test-results/filters/create-filters/prototype-node-filter"
import { getFilterUtilityFunctions } from "Components/test-results/filters/get-filter-utility-functions"

const { isFilter, toggleFilter } = getFilterUtilityFunctions(
  createPrototypeNodeFilter
)

export function usePrototypeNodeFilter(sectionId: number, nodeId: string) {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handlePrototypeNodeFilterToggle = useCallback(() => {
    setTestResultsFilters((filters) => toggleFilter(filters, sectionId, nodeId))
  }, [sectionId, nodeId])

  return {
    isNodeFilteredIn: isFilter(testResultsFilters, sectionId, nodeId),
    toggleIsFiltered: handlePrototypeNodeFilterToggle,
  }
}
