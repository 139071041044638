import { useTestResultsCommentCache } from "Hooks/useTestResultsCommentCache"
import { getUsabilityTest } from "Redux/reducers/test-results/selectors"
import { useMaybeCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { sortBy } from "lodash"
import React, { PropsWithChildren } from "react"
import { useSelector } from "react-redux"
import { useGetApiTestResults } from "~/api/generated/usabilityhub-components"
import { CommentProvider } from "./CommentProvider"

export const TestResultsCommentProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { addComment, updateComment, deleteComment, markCommentsAsRead } =
    useTestResultsCommentCache()

  const usabilityTest = useSelector(getUsabilityTest)
  const currentUser = useMaybeCurrentUser()

  const { data } = useGetApiTestResults(
    {
      pathParams: {
        usabilityTestUniqueId: usabilityTest.unique_id,
      },
    },
    {
      // On the shared results page there's no user,
      // so for now we're not going to do this API call at all.
      enabled: !!currentUser,
    }
  )

  const sortedComments = sortBy(data?.comments ?? [], (c) => c.created_at)

  return (
    <CommentProvider
      activeContext="test_results"
      usabilityTestId={usabilityTest.id}
      usabilityTestUniqueId={usabilityTest.unique_id}
      addComment={addComment}
      updateComment={updateComment}
      deleteComment={deleteComment}
      markCommentsAsRead={markCommentsAsRead}
      addCommentLocally={() => {
        throw new Error("No local comments on results")
      }}
      updateCommentLocally={() => {
        throw new Error("No local comments on results")
      }}
      deleteCommentLocally={() => {
        throw new Error("No local comments on results")
      }}
      comments={sortedComments}
    >
      {children}
    </CommentProvider>
  )
}
