import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

import {
  ButtonSelect,
  Props as ButtonSelectProps,
} from "Components/form/button-select"
import { Omit } from "Types"

type BaseProps<T> = Omit<ButtonSelectProps<T>, "value" | "onChange">
type AdapterProps<T> = BaseProps<T> & WrappedFieldProps

function ButtonSelectFieldAdapter<T>(props: Readonly<AdapterProps<T>>) {
  const {
    input: { value, onChange },
    ...rest
  } = props
  return (
    <ButtonSelect
      {...rest}
      value={value}
      onChange={onChange as (value: T) => void}
    />
  )
}

interface Props<T> extends BaseProps<T> {
  name: string
}

export function ButtonSelectField<T>(props: Props<T>) {
  return <Field component={ButtonSelectFieldAdapter} {...props} />
}
