import { Availability } from "~/api/generated/usabilityhubSchemas"

type AvailabilityByDay = Availability["availability_by_day"]

export type Day = keyof AvailabilityByDay

type DayLabels = {
  [index in Day]: {
    shortLabel: string
    longLabel: string
  }
}

export const DAY_LABELS: DayLabels = {
  sun: {
    shortLabel: "Sun",
    longLabel: "Sunday",
  },
  mon: {
    shortLabel: "Mon",
    longLabel: "Monday",
  },
  tue: {
    shortLabel: "Tue",
    longLabel: "Tuesday",
  },
  wed: {
    shortLabel: "Wed",
    longLabel: "Wednesday",
  },
  thu: {
    shortLabel: "Thu",
    longLabel: "Thursday",
  },
  fri: {
    shortLabel: "Fri",
    longLabel: "Friday",
  },
  sat: {
    shortLabel: "Sat",
    longLabel: "Saturday",
  },
}

const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>
export const DAYS = getKeys(DAY_LABELS)
