export class ScaledCanvas {
  scale: number
  canvas: HTMLCanvasElement

  constructor(canvas: HTMLCanvasElement, scale = 1) {
    this.canvas = canvas
    this.scale = scale

    const ctx = this.canvas.getContext("2d")
    ctx?.resetTransform()
    ctx?.scale(scale, scale)
  }

  get2DContext() {
    return this.canvas.getContext("2d")!
  }

  getWidth() {
    return this.canvas.width
  }

  getScaledWidth() {
    return this.canvas.width / this.scale
  }

  getHeight() {
    return this.canvas.height
  }

  getScaledHeight() {
    return this.canvas.height / this.scale
  }
}
