import {
  Box,
  Flex,
  Grid,
  Image,
  Spinner,
  Text,
  useMediaQuery,
} from "@chakra-ui/react"
import { Props } from "chakra-react-select"
import React from "react"

import { UsabilityHubNavbar } from "Components/exports"
import { useInfiniteScroll } from "Hooks/use-infinite-scroll"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { DashboardLoadingIndicator } from "UsabilityHub/components/DashboardLoadingIndicator/dashboard-loading-indicator"
import { useInfiniteListProjects } from "UsabilityHub/hooks/useInfiniteProjectList"

import NoResultsImage from "Images/app-illustrations/lyssna/empty-all.png"
import { AllDone } from "~/usabilityhub/components/AllDone"
import { ROUTES } from "../routes"

import { Heading } from "DesignSystem/components"
import { cqContainer } from "Shared/helpers/containerQuerySx"
import { ActiveMenu } from "./ActiveMenu"
import {
  DashboardBreadcrumbs,
  DashboardBreadcrumbsData,
} from "./DashboardBreadcrumbs"
import { DashboardHeader } from "./DashboardHeader"
import { ProjectCard } from "./ProjectCard"
import { ProjectListSkeleton } from "./ProjectListSkeleton"
import { SortMenu } from "./SortMenu"
import { projectsPageGridSxProps } from "./projectListGridBreakpoints"
import { useViewMenuParams } from "./useViewMenuParams"

export const ProjectsPage: React.FC<Props> = () => {
  const { showArchived, sortBy, sortDirection } = useViewMenuParams()
  const [isLargerThan62em] = useMediaQuery("(min-width: 62em)", { ssr: false })

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    isError,
  } = useInfiniteListProjects({
    sort_by: sortBy,
    sort_direction: sortDirection,
    show_archived: showArchived,
  })
  const infiniteScrollerRef = useInfiniteScroll<HTMLDivElement>(fetchNextPage)

  const allProjects = data?.pages?.flatMap((page) => page.projects) ?? []
  const pagesShown = data?.pages?.length ?? 0

  const gridProps = {
    sx: projectsPageGridSxProps,
    mb: 8,
    gap: 4,
  }

  const breadcrumbsData: DashboardBreadcrumbsData = [
    { name: "Dashboard", to: ROUTES.DASHBOARD.path },
    { name: "Projects", isCurrentPage: true },
  ]

  return (
    <Page title="Projects">
      <Box shadow="ds.overflow">
        <UsabilityHubNavbar />
        <DashboardHeader>
          <DashboardBreadcrumbs breadcrumbsData={breadcrumbsData} />
        </DashboardHeader>
      </Box>

      <PageMain>
        <PageContent maxW="8xl" display="flex" flexDirection="column" gap={4}>
          <Box display={["block", null, "none"]} mb={2}>
            <DashboardBreadcrumbs breadcrumbsData={breadcrumbsData} />
          </Box>
          <Flex align="center" gap={3}>
            <Heading
              as="h1"
              textStyle="ds.display.primary"
              color="ds.text.default"
            >
              Projects
            </Heading>
            <DashboardLoadingIndicator query={{ isFetching, isError }} />

            <Flex align="center" gap={2} ms="auto">
              <ActiveMenu iconOnly={!isLargerThan62em} listType="projects" />
              <SortMenu iconOnly={!isLargerThan62em} />
            </Flex>
          </Flex>

          {data === undefined ? (
            <Box sx={cqContainer()}>
              <ProjectListSkeleton numberOfItems={8} gridProps={gridProps} />
            </Box>
          ) : allProjects.length > 0 ? (
            <>
              <Box sx={cqContainer()}>
                <Grid {...gridProps}>
                  {allProjects.map((project) => (
                    <ProjectCard key={project.id} project={project} />
                  ))}
                </Grid>
              </Box>

              {isFetchingNextPage && (
                <Flex justify="center">
                  <Spinner />
                </Flex>
              )}

              <Flex justify="center">
                {hasNextPage ? (
                  <div ref={infiniteScrollerRef} />
                ) : (
                  pagesShown > 1 && <AllDone />
                )}
              </Flex>
            </>
          ) : (
            <Flex direction="column" align="center" mt={10}>
              <Image src={NoResultsImage} maxW={36} alt="No results" />
              <Text>No project results found</Text>
            </Flex>
          )}
        </PageContent>
      </PageMain>
    </Page>
  )
}
