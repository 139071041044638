import { createIcon } from "@chakra-ui/react"
import React from "react"

export const LongTextIcon = createIcon({
  displayName: "LongTextIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="long-text">
      <path
        id="Combined Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.75C7 2.33578 7.33578 2 7.75 2H12.25C12.6642 2 13 2.33578 13 2.75C13 3.16422 12.6642 3.5 12.25 3.5H7.75C7.33578 3.5 7 3.16422 7 2.75ZM1.252 6.538C1.252 6.46101 1.28 6.384 1.315 6.307L3.023 2.45C3.142 2.18401 3.359 2.023 3.653 2.023H3.716C4.01 2.023 4.22 2.18401 4.339 2.45L6.047 6.307C6.082 6.384 6.103 6.454 6.103 6.524C6.103 6.811 5.879 7.042 5.592 7.042C5.34 7.042 5.172 6.895 5.074 6.67101L4.745 5.901H2.589L2.246 6.706C2.155 6.916 1.973 7.042 1.749 7.042C1.469 7.042 1.252 6.818 1.252 6.538ZM4.346 4.949L3.667 3.332L2.988 4.949H4.346ZM7.75 5.5C7.33578 5.5 7 5.83578 7 6.25C7 6.66422 7.33578 7 7.75 7H14.25C14.6642 7 15 6.66422 15 6.25C15 5.83578 14.6642 5.5 14.25 5.5H7.75ZM1 9.75C1 9.33578 1.33578 9 1.75 9H14.25C14.6642 9 15 9.33578 15 9.75C15 10.1642 14.6642 10.5 14.25 10.5H1.75C1.33578 10.5 1 10.1642 1 9.75ZM1.75 12.5C1.33578 12.5 1 12.8358 1 13.25C1 13.6642 1.33578 14 1.75 14H10.25C10.6642 14 11 13.6642 11 13.25C11 12.8358 10.6642 12.5 10.25 12.5H1.75Z"
        fill="currentColor"
      />
    </g>
  ),
})
