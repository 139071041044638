import { Icon } from "@chakra-ui/react"
import React from "react"

import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import { HelpCircleSolidIcon } from "Shared/icons/untitled-ui/HelpCircleSolidIcon"
import { XCircleSolidIcon } from "Shared/icons/untitled-ui/XCircleSolidIcon"
import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"

export function getScreenerQuestionOptionRuleLabel(
  type: NonNullable<ScreenerQuestion["options"]>[0]["rule"]
): React.ReactNode {
  if (type === "qualify") {
    return (
      <>
        <Icon as={CheckCircleSolidIcon} color="green.400" boxSize={4} me={2} />
        Qualify
      </>
    )
  }

  if (type === "disqualify") {
    return (
      <>
        <Icon as={XCircleSolidIcon} color="red.600" boxSize={4} me={2} />
        Disqualify
      </>
    )
  }

  if (type === "not_relevant") {
    return (
      <>
        <Icon as={HelpCircleSolidIcon} color="gray.300" boxSize={4} me={2} />
        Not relevant
      </>
    )
  }

  throw new Error("type is not supported")
}
