import { Box, Flex, Text } from "@chakra-ui/react"
import { ParticipantIcon } from "Components/ParticipantIcon"
import { IconButton } from "Components/icon-button/icon-button"
import { EventTime } from "Components/time/event-time"
import { getHiddenResponseCount } from "Redux/reducers/test-results/selectors"
import { UserResponseIcon } from "Shared/icons/UserResponseIcon"
import { Response } from "Types"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import { UNLIMITED_VISIBLE_RESPONSES_FEATURE } from "UsabilityHub/components/RecruitmentLink/enabledLinkableFeaturesAccountLacks"
import { useFlatLocations } from "UsabilityHub/hooks/useFlatLocations"
import { sample } from "lodash"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { v4 as uuid } from "uuid"
import { useIsSharedTestResults } from "../context/shared-test-results"
import { useFilteredResponses } from "../hooks/use-filtered-responses"
import { useIndividualResponseFilter } from "../hooks/use-individual-response-filter"
import styles from "./participant-list.module.scss"

interface ParticipantProps {
  response: Readonly<Response>
}

const Participant: React.FC<React.PropsWithChildren<ParticipantProps>> = ({
  response,
}) => {
  const allLocations = useFlatLocations("usability_tests")
  const { setResponseId } = useIndividualResponseFilter()

  const location = response.response_demographic_profile?.location ?? null
  const locationData = location && allLocations[location.type][location.id]

  return (
    <li className={styles.item}>
      <ParticipantIcon country={locationData?.countryCode ?? null} />
      <div className={styles.details}>
        <div className={styles.date}>
          <EventTime date={response.submitted_at!} />
        </div>

        {locationData && (
          <div className={styles.country}>{locationData.qualifiedName}</div>
        )}
      </div>
      <IconButton
        icon={<UserResponseIcon />}
        onClick={() => setResponseId(response.id)}
      />
    </li>
  )
}

type FakeParticipantProps = {
  country: string
}
const FakeParticipant: React.FC<FakeParticipantProps> = ({ country }) => {
  const allLocations = useFlatLocations("usability_tests")

  const label = allLocations["country"][country]?.qualifiedName

  return (
    <li className={styles.item}>
      <ParticipantIcon country={country} />

      <div className={styles.details}>
        <div className={styles.date}>x hours ago</div>
        <div className={styles.country}>{label}</div>
      </div>
      <Flex
        justify="center"
        align="center"
        boxSize="26px"
        rounded="2px"
        borderColor="gray.200"
        borderWidth={1}
        color="gray.400"
      >
        <UserResponseIcon boxSize={4} />
      </Flex>
    </li>
  )
}

export const ParticipantList = () => {
  const responses = useFilteredResponses()
  const hiddenResponseCount = useSelector(getHiddenResponseCount)
  const isShared = useIsSharedTestResults()
  const { country } = useFlatLocations("usability_tests")

  const [fakeResponses] = useState(() => {
    if (isShared) return []
    if (hiddenResponseCount === 0) return []

    // Always show at least 3 responses otherwise the text and button don't fit
    return new Array(Math.max(hiddenResponseCount, 3)).fill(null).map(() => ({
      id: uuid(),
      country: sample(country)?.countryCode ?? "AU",
    }))
  })

  return (
    <>
      {responses.length > 0 ? (
        <ol className={styles.list}>
          {responses.map((r) => (
            <Participant key={r.id} response={r} />
          ))}
        </ol>
      ) : (
        <Box textAlign="center" py={10} px={4}>
          Once participants complete your test, they will appear here.
        </Box>
      )}

      {fakeResponses.length > 0 && (
        <Box position="relative">
          <Flex
            position="absolute"
            top="min(200px, 50%)"
            left="0"
            w="full"
            direction="column"
            align="center"
            transform="translateY(-50%)"
            zIndex={1}
          >
            <Text
              align="center"
              fontSize="lg"
              fontWeight="bold"
              color="text.primary"
              mb={1}
            >
              {hiddenResponseCount === 1
                ? "1 response is hidden"
                : `${hiddenResponseCount} responses are hidden`}
            </Text>
            <Text align="center" color="text.primary">
              Upgrade to a paid plan to see all the responses
            </Text>
            <PlanDrawerTrigger
              colorScheme="brand.secondary"
              mt={4}
              requestedFeatures={[UNLIMITED_VISIBLE_RESPONSES_FEATURE]}
              source="test_results_participant_list"
            >
              See all plans
            </PlanDrawerTrigger>
          </Flex>

          <Flex
            direction="column"
            filter="blur(4px)"
            opacity={0.3}
            userSelect="none"
          >
            {fakeResponses.map(({ id, country }) => (
              <FakeParticipant key={id} country={country} />
            ))}
          </Flex>
        </Box>
      )}
    </>
  )
}
