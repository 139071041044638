import { useToast } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { debounce } from "lodash"
import { useCallback, useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { useSetAvailability } from "~/api/generated/usabilityhub-components"
import { ModeratedStudy } from "~/api/generated/usabilityhubSchemas"

import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import { FORM_SAVE_DEBOUNCE_TIME, GENERIC_FORM_ERROR_TOAST } from "./common"

export const BUFFER_MINS = [0, 5, 10, 15, 30, 45, 60, 90, 120, 150, 180]
export const DURATION_MINS = [15, 30, 45, 60, 75, 90, 105, 120]
export const MINIMUM_NOTICE_OPTIONS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 48, 72,
]
export const AVAILABILITY_ALIGNMENT_OPTIONS = [
  ["15 min intervals", 15],
  ["30 min intervals", 30],
  ["60 min intervals", 60],
] as const

export const dayAvailability = Yup.array(
  Yup.object({
    starts_at: Yup.string().required(),
    ends_at: Yup.string().required(),
  }).test("time-range", "End time must be after start time", (value) => {
    const { starts_at, ends_at } = value
    if (!starts_at || !ends_at) {
      return true // don't run validation if starts_at or ends_at are missing
    }
    return (
      new Date(`2000-01-01T${ends_at}`) > new Date(`2000-01-01T${starts_at}`)
    )
  })
).required()
const availabilityOverrides = Yup.array(
  Yup.object({
    date: Yup.string()
      .max(10, "Date cannot be greater than 10 characters")
      .required(),
    availability: dayAvailability,
  }).test(
    "isValidDate",
    "Invalid date",
    (value) => !!new Date(`${value.date}T00:00:00`).getTime()
  )
).required()

export type AvailabilityOverrides = Yup.InferType<typeof availabilityOverrides>

const ModeratedStudyAvailabilitySchema = Yup.object({
  startsAt: Yup.string().required("Select a valid start date"),
  endsAt: Yup.string()
    .required("Select a valid end date")
    .test(
      "valid-date-range",
      "End date must be after start date",
      (_value, context) => {
        // context.parent is typed as `any` in the lib but we know it will have the dates on it
        const { startsAt, endsAt } = context.parent as {
          startsAt: string
          endsAt: string
        }

        // don't run validation if startsAt or endsAt are missing
        if (!startsAt || !endsAt) {
          return true
        }

        return new Date(endsAt) > new Date(startsAt)
      }
    ),
  timezone: Yup.string().required(),
  eventDurationMins: Yup.number().oneOf(DURATION_MINS).required(),
  preEventBufferMins: Yup.number().oneOf(BUFFER_MINS).required(),
  postEventBufferMins: Yup.number().oneOf(BUFFER_MINS).required(),
  minimumNotice: Yup.number().oneOf(MINIMUM_NOTICE_OPTIONS).required(),
  availabilityAlignment: Yup.number()
    .oneOf(AVAILABILITY_ALIGNMENT_OPTIONS.map(([, v]) => v))
    .required(),
  availabilityByDay: Yup.object({
    mon: dayAvailability,
    tue: dayAvailability,
    wed: dayAvailability,
    thu: dayAvailability,
    fri: dayAvailability,
    sat: dayAvailability,
    sun: dayAvailability,
  }).required(),
  // The dates in overriddenDates are in the UTC timezone
  availabilityOverrides: availabilityOverrides,
})

export type ModeratedStudyAvailabilityFormValues = Yup.InferType<
  typeof ModeratedStudyAvailabilitySchema
>

export const useAvailabilitySectionForm = (moderatedStudy: ModeratedStudy) => {
  const toast = useToast()
  const { availability } = moderatedStudy

  const form = useForm<ModeratedStudyAvailabilityFormValues>({
    resolver: yupResolver(ModeratedStudyAvailabilitySchema),
    defaultValues: {
      startsAt: availability.starts_at,
      endsAt: availability.ends_at,
      timezone: availability.timezone,
      eventDurationMins: availability.event_duration_mins,
      preEventBufferMins: availability.pre_event_buffer_mins,
      postEventBufferMins: availability.post_event_buffer_mins,
      availabilityByDay: availability.availability_by_day,
      availabilityOverrides: availability.availability_overrides,
      minimumNotice: availability.minimum_notice,
      availabilityAlignment: availability.availability_alignment,
    },
  })

  const { handleSubmit, watch, reset, getValues } = form
  const { invalidateStudySummaryQuery } = useModeratedStudyContext()

  const setAvailabilityMutation = useSetAvailability({
    onSuccess: () => {
      // invalidate so the no availability indicator refreshes
      void invalidateStudySummaryQuery()
      reset(getValues(), { keepValues: true })
    },
    onError: () => toast(GENERIC_FORM_ERROR_TOAST),
  })

  const onSubmit: SubmitHandler<ModeratedStudyAvailabilityFormValues> = (
    formData
  ) => {
    setAvailabilityMutation.mutate({
      pathParams: {
        moderatedStudyId: moderatedStudy.id,
      },
      body: {
        starts_at: formData.startsAt,
        ends_at: formData.endsAt,
        timezone: formData.timezone,
        event_duration_mins: formData.eventDurationMins,
        pre_event_buffer_mins: formData.preEventBufferMins,
        post_event_buffer_mins: formData.postEventBufferMins,
        availability_by_day: formData.availabilityByDay,
        availability_overrides: formData.availabilityOverrides,
        minimum_notice: formData.minimumNotice,
        availability_alignment: formData.availabilityAlignment,
      },
    })
  }

  const debouncedOnSubmit = useCallback(
    debounce(onSubmit, FORM_SAVE_DEBOUNCE_TIME),
    []
  )

  useEffect(() => {
    // Return the cleanup function to wait for the final call to complete
    return () => {
      // Call the flush method of the debounced function to ensure that the final call is complete
      void debouncedOnSubmit.flush()
    }
  }, [debouncedOnSubmit])

  useEffect(() => {
    const subscription = watch(() => {
      void handleSubmit(debouncedOnSubmit)()
    })

    return () => subscription.unsubscribe()
  }, [watch, handleSubmit, debouncedOnSubmit])

  return form
}
