import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  ListItem,
  OrderedList,
  Stack,
} from "@chakra-ui/react"
import { InformationCircleIcon } from "@heroicons/react/solid"
import React, { useState } from "react"

import { ThemedButton } from "Components/button/themed-button"
import { DisplayInlineMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { useTranslate } from "Shared/hooks/useTranslate"
import { useTestBranding } from "UsabilityHub/components/UsabilityTest/context/testBranding"

import { useCardSortContext } from "./CardSortContext"
import { CardSortProgress } from "./CardSortProgress"

interface Props {
  instructions: string
  enableDoneButton: boolean
  onComplete: () => void
}
export const CardSortHeader: React.FC<Props> = ({
  instructions,
  enableDoneButton,
  onComplete,
}) => {
  const translate = useTranslate()
  const branding = useTestBranding()
  const [showHelp, setShowHelp] = useState(false)
  const { cardSortType } = useCardSortContext()

  return (
    <>
      <Flex
        align="stretch"
        h="fit-content"
        gap={2}
        borderBottomWidth={1}
        borderBottomColor="gray.200"
        bg="white"
        p={4}
        zIndex={7}
      >
        <CardSortProgress flexGrow={1} />

        <IconButton
          variant={showHelp ? "solid" : "outline"}
          icon={
            <Icon
              color={branding.button_color ?? "brand.primary.500"}
              boxSize={5}
              as={InformationCircleIcon}
            />
          }
          aria-label={translate("test.card_sort.toggle_instructions")}
          onClick={() => setShowHelp((v) => !v)}
        />

        <ThemedButton
          isDisabled={!enableDoneButton}
          onClick={() => onComplete()}
          w="fit-content"
        >
          {translate("test.buttons.finish_task")}
        </ThemedButton>
      </Flex>
      {showHelp && (
        <>
          <Box
            bg="blackAlpha.500"
            position="absolute"
            inset={0}
            h="100%"
            onClick={() => setShowHelp(false)}
            zIndex={6} // Shoul be greater than the maximum zIndex of Category component
          />
          <Stack
            position="absolute"
            borderBottomWidth={1}
            borderBottomColor="gray.200"
            bg="white"
            p={4}
            zIndex={7}
            top="73px"
          >
            <Heading fontSize="md" fontWeight="semibold">
              <DisplayInlineMarkdownText
                text={
                  instructions ||
                  translate(
                    "test.instructions.card_sort.reminder_default_heading"
                  )
                }
              />
            </Heading>

            <OrderedList color="gray.500" ps={4} spacing={2}>
              <ListItem>
                {cardSortType === "closed"
                  ? translate("test.instructions.card_sort.step_two_closed")
                  : translate("test.instructions.card_sort.step_two_open")}
              </ListItem>
              {cardSortType !== "closed" && (
                <ListItem>
                  {translate("test.instructions.card_sort.step_three_open")}
                </ListItem>
              )}
              <ListItem>
                {translate("test.instructions.card_sort.step_four")}
              </ListItem>
            </OrderedList>
          </Stack>
        </>
      )}
    </>
  )
}
