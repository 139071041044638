import { Box, HStack, Icon, Text } from "@chakra-ui/react"
import { ViewGridAddIcon } from "@heroicons/react/outline"
import { AnimatePresence, motion, useReducedMotion } from "framer-motion"
import { drop, sampleSize } from "lodash"
import React, { useMemo } from "react"
import { useOnboardingContext } from "../OnboardingContextProvider"

import tile0 from "./goals-0.png"
import tile1 from "./goals-1.png"
import tile2 from "./goals-2.png"
import tile3 from "./goals-3.png"
import tile4 from "./goals-4.png"
import tile5 from "./goals-5.png"
import tile6 from "./goals-6.png"
import tile7 from "./goals-7.png"
import tile8 from "./goals-8.png"
import tile9 from "./goals-9.png"

const COLUMNS = [
  1, // no options selected
  2, // 1 option selected
  3, // 2
  2, // 3
  3, // 4
  3, // 5
  3, // 6
  3, // 7
  3, // 8
  3, // 9
]

const TILES = [
  tile0,
  tile1,
  tile2,
  tile3,
  tile4,
  tile5,
  tile6,
  tile7,
  tile8,
  tile9,
] as const

export const ResearchGoalsAnimation: React.FC = () => {
  const {
    unsaved: { researchGoals },
  } = useOnboardingContext()

  const count = Math.min(researchGoals?.length || 0, 9)

  const tiles = useMemo(
    () => [...sampleSize(drop(TILES, 1), count), TILES[0]].slice(0, 9),
    [count]
  )

  const shouldReduceMotion = useReducedMotion()

  return (
    <Box
      as={motion.div}
      pos="relative"
      variants={{
        out: { rotate: "-6deg", opacity: 0, scale: 0 },
        in: { rotate: "-6deg", opacity: 1, scale: 1 },
      }}
    >
      <Box
        as={motion.div}
        layoutId="container"
        display="grid"
        gridTemplateColumns={`repeat(${COLUMNS[count]}, 180px)`}
        gridAutoRows="192px"
        gap={0}
        p={4}
        bg="white"
        rounded={16}
        boxShadow="lg"
        alignItems="center"
        justifyItems="center"
      >
        <AnimatePresence mode="popLayout">
          {tiles.map((src) => (
            <Box
              layout="position"
              as={motion.img}
              key={src}
              src={src}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
            />
          ))}
          {!count && (
            <HStack
              as={motion.div}
              boxShadow="md"
              rounded={8}
              bg="white"
              pos="absolute"
              top="65%"
              left="57%"
              px={4}
              py={2}
              gap={2}
              initial={{ rotate: "6deg", opacity: 0, scale: 0 }}
              animate={{
                rotate: "6deg",
                opacity: 1,
                scale: 1,
                transition: {
                  type: "spring",
                  duration: shouldReduceMotion ? 0 : 0.2,
                  delay: shouldReduceMotion ? 0 : 0.2,
                },
              }}
              exit={{ rotate: "6deg", opacity: 0, scale: 0 }}
              zIndex={1}
            >
              <Icon as={ViewGridAddIcon} color="brandPalette.neutral.dark" />
              <Text
                as="span"
                whiteSpace="nowrap"
                fontFamily="promo"
                fontWeight="medium"
                color="brandPalette.neutral.darkest"
              >
                Template library
              </Text>
              <Box
                as="svg"
                w="66px"
                h="48px"
                viewBox="0 0 66 48"
                fill="brandPalette.teal.mid"
                pos="absolute"
                left="25%"
                top="80%"
                transform="rotate(-55deg)"
              >
                <path d="M65.88 22.4289L0.797852 0.734863L10.8583 24.3382L0.797852 47.1677L65.88 22.4289Z" />
              </Box>
            </HStack>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  )
}
