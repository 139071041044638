import React from "react"

import { ExpandableRegion } from "Components/expandable-region/expandable-region"
import SectionScreenshot from "Components/test-results/section-screenshot-result/section-screenshot-result"
import { DeviceFrame, UsabilityTestSectionScreenshot } from "Types"

interface Props {
  readonly sectionScreenshot: Readonly<UsabilityTestSectionScreenshot>
  readonly deviceFrame: DeviceFrame | null
}

export const ExpandableSectionScreenshot = ({
  sectionScreenshot,
  deviceFrame,
}: Props) => (
  <ExpandableRegion
    maxHeight={400}
    expandText="Expand image"
    collapseText="Collapse image"
  >
    <SectionScreenshot
      sectionScreenshot={sectionScreenshot}
      deviceFrame={deviceFrame}
    />
  </ExpandableRegion>
)
