import {
  Box,
  Button,
  Center,
  Flex,
  FocusLock,
  Input,
  Text,
  useOutsideClick,
} from "@chakra-ui/react"
import { AppliedFilter, FilterAttribute, FilterOptionChoice } from "Types"
import React, { PropsWithChildren, useRef, useState } from "react"
import { useFilterContext } from "./filter-context"
import { MENU_NAVIGATION_KEYS } from "./utils"

interface Props {
  appliedFilter?: AppliedFilter
  selectedAttribute: FilterAttribute
  onClose: () => void
}

export const FilterMenuSingle: React.FC<PropsWithChildren<Props>> = ({
  appliedFilter,
  selectedAttribute,
  onClose,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [textFilter, setTextFilter] = useState("")
  const { addFilter, changeFilter } = useFilterContext()

  // Close the menu when clicking outside, just like a real one!
  useOutsideClick({ ref: menuRef, handler: onClose })

  if (selectedAttribute.possibleOptions.type !== "single") return null

  const onLabelClick = (choice: FilterOptionChoice) => {
    if (appliedFilter) {
      changeFilter(appliedFilter.id, appliedFilter.comparator, {
        type: "single",
        choice: choice,
      })
    } else {
      addFilter(selectedAttribute, {
        type: "single",
        choice,
      })
    }

    onClose()
  }

  const filteredChoices = selectedAttribute.possibleOptions.choices.filter(
    (choice) => choice.label.toLowerCase().includes(textFilter.toLowerCase())
  )

  const selectItem = (delta: number) => {
    const buttons = menuRef.current?.querySelectorAll("button")
    const currentlyFocusedButton = menuRef.current?.querySelector(
      "button:focus"
    ) as HTMLButtonElement | null

    if (!buttons) return

    const currentIndex = currentlyFocusedButton
      ? Array.from(buttons).indexOf(currentlyFocusedButton)
      : -1 // No checkbox focused means we'll start going down from the input at the top
    const nextIndex = currentIndex + delta

    if (nextIndex < 0 || nextIndex >= buttons.length) return

    const nextButton = buttons[nextIndex] as HTMLButtonElement
    nextButton.focus()
  }

  return (
    <FocusLock>
      <Flex
        ref={menuRef}
        pos="absolute"
        top={0}
        left={0}
        minW="240px"
        maxH="300px"
        direction="column"
        overflowX="hidden"
        overflowY="auto"
        zIndex="dropdown"
        borderWidth={1}
        rounded="md"
        shadow="md"
        bg="white"
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            onClose()
          } else if (e.key === "ArrowDown") {
            e.preventDefault()
            selectItem(1)
          } else if (e.key === "ArrowUp") {
            e.preventDefault()
            selectItem(-1)
          }

          if (e.currentTarget === inputRef.current) return

          if (MENU_NAVIGATION_KEYS.includes(e.key)) return

          e.stopPropagation()
          inputRef.current?.focus()
        }}
      >
        <Box
          position="sticky"
          top="0"
          bgColor="white"
          zIndex="1"
          lineHeight="10"
          fontWeight="semibold"
          fontSize="sm"
        >
          <Input
            autoFocus
            ref={inputRef}
            variant="filled"
            placeholder={selectedAttribute.label}
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
            background="white"
            borderWidth="0 0 1px 0"
            borderColor="gray.200 !important"
            roundedBottom="none"
          />
        </Box>

        {filteredChoices.length === 0 && (
          <Center py={3} px={4} fontSize="sm" color="text.secondary">
            No results found
          </Center>
        )}

        {filteredChoices.map((choice) => {
          return (
            <Button
              key={choice.value}
              variant="unstyled"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              flexGrow={1}
              gap={1.5}
              p={3}
              fontSize="sm"
              fontWeight="normal"
              _focusVisible={{
                outline: "none", // These are pretending to be menu items so the background color shows focus
              }}
              _focusWithin={{
                bg: "gray.50",
              }}
              _hover={{
                bg: "gray.50",
              }}
              onClick={() => onLabelClick(choice)}
            >
              {choice.icon}
              <Text>{choice.label}</Text>
            </Button>
          )
        })}
      </Flex>
    </FocusLock>
  )
}
