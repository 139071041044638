import { ScaledCanvas } from "Utilities/canvas/scaled-canvas"

export function drawOverlay(canvas: ScaledCanvas) {
  const ctx = canvas.get2DContext()

  // getScaledWidth is a custom method of the ScaledCanvas class
  ctx.rect(0, 0, canvas.getScaledWidth(), canvas.getScaledHeight())
  ctx.fillStyle = "rgba(0, 0, 0, 0.7)"
  ctx.fill()
}
