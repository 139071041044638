import { omitBy, startsWith, uniqueId } from "lodash"

import { ProgressHandler, axios, progressPercentage } from "Services/axios"
import {
  AudioScreenshot,
  ImageScreenshot,
  Persisted,
  PostScreenshot,
  RawScreenshot,
  S3Config,
  Screenshot,
  ScreenshotMediaType,
  VideoScreenshot,
} from "Types"
import ScreenshotsApi from "~/api/screenshotsApi"

import { nextClientId } from "../utilities/client-id"

// https://stackoverflow.com/a/35790786
interface FileReaderEventTarget extends EventTarget {
  result: string
}
interface FileReaderEvent extends Event {
  target: FileReaderEventTarget
  getMessage(): string
}

const sharedScreenshotAttributes = (file: File) => ({
  _clientId: nextClientId(),
  _progress: 0,
  _isLoaded: false,
  _isLoading: false,
  _isSaving: false,
  _isViewed: false,
  _file: file,
  name: file.name,
  thumbnail_url: null,
  id: null,
  status: null,
  url: null,
})

function createImageScreenshot(file: File): Promise<ImageScreenshot> {
  return new Promise((resolve, _reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (event) => {
      const image = new Image()
      // Fixed in https://github.com/Microsoft/TypeScript/pull/23069
      const dataUrl = (event as any as FileReaderEvent).target.result
      image.onload = () => {
        resolve({
          ...sharedScreenshotAttributes(file),
          _url: dataUrl,
          media_type: "image",
          display_scale: 1,
          width: image.width,
          height: image.height,
          duration_ms: null,
        })
      }
      image.src = fileReader.result as string
    }
    fileReader.readAsDataURL(file)
  })
}

function createVideoScreenshot(file: File): Promise<VideoScreenshot> {
  return new Promise((resolve, _reject) => {
    const video = document.createElement("video")
    const dataUrl = URL.createObjectURL(file)
    video.preload = "metadata"
    video.onloadedmetadata = () => {
      resolve({
        ...sharedScreenshotAttributes(file),
        _url: dataUrl,
        media_type: "video",
        display_scale: null,
        width: video.videoWidth,
        height: video.videoHeight,
        duration_ms: Math.round(video.duration * 1000),
      })
    }
    video.src = dataUrl
  })
}

function createAudioScreenshot(file: File): Promise<AudioScreenshot> {
  return new Promise((resolve, _reject) => {
    const audio = document.createElement("audio")
    const dataUrl = URL.createObjectURL(file)
    audio.preload = "metadata"
    audio.onloadedmetadata = () => {
      resolve({
        ...sharedScreenshotAttributes(file),
        _url: dataUrl,
        media_type: "audio",
        display_scale: null,
        width: null,
        height: null,
        duration_ms: Math.round(audio.duration * 1000),
      })
    }
    audio.src = dataUrl
  })
}

function directUploadPath(s3Config: Readonly<S3Config>): string {
  return `${s3Config.key}/${uniqueId()}`
}

export async function fileToScreenshot([file, mediaType]: [
  File,
  ScreenshotMediaType,
]): Promise<Screenshot> {
  switch (mediaType) {
    case "video":
      return await createVideoScreenshot(file)
    case "audio":
      return await createAudioScreenshot(file)
    case "image":
      return await createImageScreenshot(file)
  }
}

function createUploadFormData(
  s3Config: Readonly<S3Config>,
  uploadPath: string,
  file: File
): FormData {
  const mimeTypeConfig = file.type.startsWith("audio")
    ? s3Config.audio
    : file.type.startsWith("video")
      ? s3Config.video
      : s3Config.image
  const formData = new FormData()
  formData.append("AWSAccessKeyId", s3Config.access_key_id)
  formData.append("key", uploadPath)
  formData.append("Content-Type", file.type)
  formData.append("acl", "public-read")
  formData.append("policy", mimeTypeConfig.policy)
  formData.append("signature", mimeTypeConfig.signature)

  // Add the file last, as S3 ignores params after the file.
  formData.append("file", file)
  return formData
}

export async function uploadScreenshotFile(
  file: File,
  s3Config: Readonly<S3Config>,
  onProgress: ProgressHandler
): Promise<string> {
  const path = directUploadPath(s3Config)
  const formData = createUploadFormData(s3Config, path, file)

  await axios.post(s3Config.url, formData, {
    onUploadProgress: progressPercentage(onProgress),
  })

  // Only use the last part of URL, the rest will be derived on the server for better security
  return path.match(/uploads\/[a-z0-9]+\/([^\?]+)/)?.[1] ?? path
}

export async function postScreenshot(
  screenshot: Readonly<PostScreenshot>
): Promise<Persisted<RawScreenshot>> {
  const { data: updatedScreenshot } = await axios.post(
    ScreenshotsApi.create.path(),
    {
      screenshot: screenshotToAttributes(screenshot),
    }
  )
  return updatedScreenshot
}

function screenshotToAttributes(
  screenshot: Readonly<PostScreenshot>
): RawScreenshot {
  return omitBy(screenshot, (_value: any, key: string) =>
    startsWith(key, "_")
  ) as any as RawScreenshot
}
