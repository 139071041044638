import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Tooltip,
} from "@chakra-ui/react"
import React from "react"

import { useSectionIndexContext } from "UsabilityHub/contexts"

import { useFigmaOAuth } from "../useFigmaOAuth"

interface FigmaFlowImportProps {
  handleImport: () => void
  importUrl: string
  setImportUrl: (url: string) => void
  error: string | null
}

export const FigmaFlowImport: React.FC<
  React.PropsWithChildren<FigmaFlowImportProps>
> = ({ handleImport, importUrl, setImportUrl, error }) => {
  const sectionIndex = useSectionIndexContext()

  // OAuth hook with handleImport as success callback
  const { handleFigmaOAuth } = useFigmaOAuth(handleImport)

  const isAddButtonDisabled = error !== null || !importUrl

  return (
    <Box
      borderWidth="2px"
      borderStyle="dashed"
      borderColor="gray.300"
      p={6}
      rounded="lg"
    >
      <FormControl isInvalid={error !== null}>
        <HStack>
          <Input
            id={`figma_link_${sectionIndex}`} // TODO: Replace with below in #7101
            data-qa="prototype-flow-link-input"
            value={importUrl}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleFigmaOAuth()
              }
            }}
            placeholder="Enter prototype Flow link"
            onChange={(e) => setImportUrl(e.target.value)}
            focusBorderColor={error !== null ? "red.500" : "brand.primary.500"} // Override focus for immediate feedback
          />
          <Tooltip
            label="Please enter a prototype Flow link."
            placement="left"
            hasArrow
            isDisabled={!isAddButtonDisabled}
          >
            <Button
              colorScheme="brand.primary"
              onClick={handleFigmaOAuth}
              isDisabled={isAddButtonDisabled}
              data-qa="add-prototype-flow-link-button"
              px={6}
            >
              Add
            </Button>
          </Tooltip>
        </HStack>
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    </Box>
  )
}
