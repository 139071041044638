import {
  Flex,
  FlexProps,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
} from "@chakra-ui/react"
import React from "react"

const styleConfig = {
  parts: ["header", "content"],
  baseStyle: {
    header: {
      bg: "white",
      borderBottom: "1px solid",
      borderColor: "gray.100",
      justifyContent: "center",
    },
    content: {
      px: {
        base: 6,
        md: 8,
        lg: 10,
      },
      py: 6,
      alignItems: {
        base: "flex-start",
        lg: "center",
      },
      height: {
        lg: 20,
      },
      justifyContent: "space-between",
      flexDirection: { base: "column", lg: "row" },
      flexGrow: 1,
    },
  },
}

export const Header: React.FC<React.PropsWithChildren<FlexProps>> = (props) => {
  const styles = useMultiStyleConfig("Header", { styleConfig })
  return (
    <StylesProvider value={styles}>
      <Flex as="header" sx={styles.header} {...props} />
    </StylesProvider>
  )
}

export const HeaderContent: React.FC<React.PropsWithChildren<FlexProps>> = (
  props
) => {
  const styles = useStyles()
  return <Flex sx={styles.content} {...props} />
}
