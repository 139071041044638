import { useContext } from "react"

import { ClientIdRectangle } from "Types"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import { useTestResultViewState } from "../context/test-result-view-state"

export const useScreenshotClientHitzones = (screenshotId: number) => {
  const { viewState, setViewState } = useTestResultViewState()
  const { setTestResultsFilters } = useContext(TestResultsFilterState)

  const filteredScreenshotClientHitzones =
    viewState.screenshotClientHitzones.filter(
      (zone) => zone.usability_test_section_screenshot_id === screenshotId
    )

  const handleAddScreenshotClientHitzone = (newZone: ClientIdRectangle) => {
    setViewState((viewState) => ({
      ...viewState,
      screenshotClientHitzones: [
        ...viewState.screenshotClientHitzones,
        { ...newZone, usability_test_section_screenshot_id: screenshotId },
      ],
    }))
  }

  const handleChangeScreenshotClientHitzone = (
    changedZone: ClientIdRectangle
  ) => {
    setViewState((viewState) => ({
      ...viewState,
      screenshotClientHitzones: viewState.screenshotClientHitzones.map(
        (zone) => {
          if (zone._clientId !== changedZone._clientId) return zone

          return {
            ...changedZone,
            usability_test_section_screenshot_id: screenshotId,
          }
        }
      ),
    }))
  }

  const handleRemoveScreenshotClientHitzone = (clientId: string) => {
    setTestResultsFilters((filters) => {
      return filters.filter(
        (filter) =>
          filter.type !== "answer/client-hitzone" || filter.value !== clientId
      )
    })

    setViewState((viewState) => ({
      ...viewState,
      screenshotClientHitzones: viewState.screenshotClientHitzones.filter(
        (zone) => zone._clientId !== clientId
      ),
    }))
  }

  return {
    screenshotZones: filteredScreenshotClientHitzones,
    addScreenshotZone: handleAddScreenshotClientHitzone,
    changeScreenshotZone: handleChangeScreenshotClientHitzone,
    removeScreenshotZone: handleRemoveScreenshotClientHitzone,
  }
}
