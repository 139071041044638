import React from "react"

import { Text } from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { State } from "Redux/app-store"
import { usabilityTestSectionScreenshotsSelector } from "Redux/reducers/screenshots/selectors"
import { useTranslate } from "Shared/hooks/useTranslate"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"
import {
  Body,
  Container,
  Footer,
} from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout"
import { sectionStartTime } from "Utilities/response"
import { isScreenshotPlayable } from "Utilities/screenshot"
import { useSelector } from "react-redux"

export const InformationTask: React.FC<React.PropsWithChildren<InnerProps>> = ({
  responseSection,
  updateResponseSection,
  usabilityTestSection,
}) => {
  const translate = useTranslate()

  const handleContinue = () => {
    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms: 0,
      task_duration_ms: performance.now() - sectionStartTime(responseSection),
    })
  }

  const screenshots = useSelector((state: State) =>
    usabilityTestSectionScreenshotsSelector(state, usabilityTestSection)
  )

  const screenshot = screenshots.length > 0 ? screenshots[0] : null
  const playableScreenshot =
    screenshot && isScreenshotPlayable(screenshot) ? screenshot : null

  const isPlayingScreenshot = playableScreenshot
    ? !playableScreenshot._isViewed
    : false

  return (
    <Container>
      <Body>
        <DisplayBlockMarkdownText text={usabilityTestSection.text} />
      </Body>
      <Footer>
        <ThemedButton onClick={handleContinue} isDisabled={isPlayingScreenshot}>
          {translate("test.buttons.continue")}
        </ThemedButton>
        {playableScreenshot && isPlayingScreenshot && (
          <Text ml={3}>
            {translate(
              `test.screenshots.${playableScreenshot.media_type}.unfinished`
            )}
          </Text>
        )}
      </Footer>
    </Container>
  )
}
