import { Icon, Tooltip } from "@chakra-ui/react"
import React from "react"

import { MessageSquare02OutlineIcon } from "Shared/icons/untitled-ui/MessageSquare02OutlineIcon"

type AdminNotesIndicator = React.FC<{
  hasNote: boolean
}>

export const AdminNotesIndicator: AdminNotesIndicator = ({ hasNote }) => {
  return (
    <>
      {hasNote ? (
        <Tooltip label="Has notes" hasArrow placement="top" fontSize="xs">
          <Icon as={MessageSquare02OutlineIcon} />
        </Tooltip>
      ) : null}
    </>
  )
}
