import React, { InputHTMLAttributes, useEffect, useRef } from "react"
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form"

import {
  TextInput,
  Props as TextInputProps,
} from "Components/form/text-input/text-input"
import { multiRef } from "Utilities/ref"

const TextFieldAdapter = React.forwardRef<
  HTMLInputElement,
  WrappedFieldProps & TextInputProps
>(({ input, onPaste, meta, ...rest }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputRef.current!.setCustomValidity(meta.error || "")
  }, [meta.error])
  return (
    <TextInput
      ref={multiRef(ref, inputRef)}
      {...input}
      {...rest}
      onPaste={onPaste}
    />
  )
})

TextFieldAdapter.displayName = "TextFieldAdapter"

type Props = InputHTMLAttributes<HTMLInputElement> &
  BaseFieldProps<HTMLInputElement> &
  TextInputProps

export const TextField = React.forwardRef<HTMLInputElement, Props>(
  ({ warn, ...props }, ref) => (
    <Field
      component={TextFieldAdapter}
      name={props.name}
      props={{
        ref,
        ...props,
      }}
      warn={warn}
    />
  )
)

TextField.displayName = "TextField"
