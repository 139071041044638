import { Flex, FlexProps, Icon, Text } from "@chakra-ui/react"
import { CheckCircleIcon } from "@heroicons/react/solid"
import React from "react"

import { DisplayInlineMarkdownText } from "Components/display-markdown-text/display-markdown-text"

import { usePlanChangerContextOnScreen } from "./plan-changer-context"

export const PlanChangeSuccess: React.FC<FlexProps> = (props) => {
  const { title, message } = usePlanChangerContextOnScreen(
    "plan-change-success"
  )

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      background="gray.50"
      {...props}
    >
      <Icon as={CheckCircleIcon} boxSize={12} color="green.500" />
      <Text
        align="center"
        fontSize="3xl"
        fontWeight="semibold"
        color="green.500"
        mt={2}
      >
        {title}
      </Text>
      <Text align="center" color="text.primary" fontWeight="normal" mt={4}>
        <DisplayInlineMarkdownText text={message} />
      </Text>
    </Flex>
  )
}
