import { Button, ButtonProps, useDisclosure, useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React, { useCallback } from "react"

import { PurchaseConfirmationModal } from "Components/payments/purchase-confirmation-modal"
import { trackCreditsPurchased } from "JavaScripts/analytics/track"
import { throwIfDeclinedErrorMessage } from "Services/stripe"
import {
  CurrentAccountProvider,
  useCurrentAccount,
} from "UsabilityHub/hooks/useCurrentAccount"
import { centsToDollars, formatDollars } from "Utilities/currency"
import {
  PurchaseCreditsError,
  PurchaseCreditsResponse,
  PurchaseVendorCreditsError,
  PurchaseVendorCreditsResponse,
  usePurchaseCredits,
  usePurchaseVendorCredits,
  useTrackEvent,
} from "~/api/generated/usabilityhub-components"

type Props = React.PropsWithChildren<
  {
    buttonText: string
    creditCount: number
    isDisabled?: boolean
    price: number
  } & ButtonProps
>

export const PurchaseCreditBatchButton: React.FC<Props> = ({
  buttonText,
  creditCount,
  isDisabled,
  price,
  ...buttonProps
}) => {
  const { mutate: trackEvent } = useTrackEvent()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { account_type } = useCurrentAccount()

  const onSuccess = useCallback(
    (data: PurchaseCreditsResponse | PurchaseVendorCreditsResponse) => {
      trackCreditsPurchased(
        "credit_batch",
        data.purchase.credit_count,
        data.purchase.price_in_cents
      )

      void queryClient.invalidateQueries(["api", "account", "total_credits"])

      toast({
        title: data.message,
        status: "success",
      })
      onClose()
    },
    [toast, queryClient, onClose]
  )

  const onError = useCallback(
    (error: PurchaseCreditsError | PurchaseVendorCreditsError) => {
      const { message } = error.payload
      throwIfDeclinedErrorMessage(message)
      throw new Error(message)
    },
    []
  )

  const purchaseCreditsMutation = usePurchaseCredits({
    onSuccess,
    onError,
  })

  const purchaseVendorCreditsMutation = usePurchaseVendorCredits({
    onSuccess,
    onError,
  })

  const handleShowModal = () => {
    onOpen()

    trackEvent({
      body: {
        event: "pageview_credit_purchase_confirmation_form",
        event_properties: { price_in_cents: price },
      },
    })
  }

  const submitPurchaseAsync = async () => {
    const body = { credits: creditCount }
    if (account_type === "vendor") {
      await purchaseVendorCreditsMutation.mutateAsync({ body })
    } else {
      await purchaseCreditsMutation.mutateAsync({ body })
    }
  }

  const formattedPrice = formatDollars(centsToDollars(price))

  return (
    <>
      <Button
        colorScheme="brand.primary"
        onClick={handleShowModal}
        isDisabled={isOpen || isDisabled}
        {...buttonProps}
      >
        {buttonText}
      </Button>
      {isOpen && (
        <PurchaseConfirmationModal
          formattedPrice={formattedPrice}
          heading={`Purchase ${creditCount} credits`}
          onCancel={onClose}
          submitPurchaseAsync={submitPurchaseAsync}
        />
      )}
    </>
  )
}

// Used by the vendors/purchase_credits page, since it doesn't use the SPA and is therefore
// missing important providers.
export const StandalonePurchaseCreditBatchButton: React.FC<Props> = (props) => {
  return (
    <CurrentAccountProvider>
      <PurchaseCreditBatchButton {...props} />
    </CurrentAccountProvider>
  )
}
