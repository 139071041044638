import { Flex, FlexProps, Icon, Tooltip } from "@chakra-ui/react"
import React from "react"

import { getSectionTypeRules } from "Constants/test-section-types"
import { UsabilityTestSectionType } from "Types"

type section = {
  title: string | null
  section_type: string
}

export const TestTypeIcons: React.FC<FlexProps & { sections: section[] }> = ({
  sections,
  ...flexProps
}) => {
  return (
    <Flex
      sx={{
        "& > svg:not(:first-of-type)": {
          marginLeft: "-3px",
        },
      }}
      {...flexProps}
    >
      {sections.slice(0, 20).map((section, index) => {
        const { colorScheme, icon, defaultTitle } = getSectionTypeRules(
          section.section_type as UsabilityTestSectionType
        )

        const label = section.title
          ? `${section.title} (${defaultTitle})`
          : defaultTitle

        return (
          <Tooltip
            key={index}
            hasArrow
            label={label}
            placement="top"
            rounded="md"
          >
            <Icon
              as={icon}
              boxSize="18px"
              color={colorScheme}
              bg="white" // Solid white background so cutouts don't show through
              border="1px solid white"
              rounded="full"
              zIndex={1}
            />
          </Tooltip>
        )
      })}
    </Flex>
  )
}
