import { ElementType } from "react"

import styles from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout.module.scss"
import { createStyledComponent } from "Utilities/component-factory"

export const Container = createStyledComponent("section", styles.container)
export const Header: ElementType = "header"
export const Title = createStyledComponent("h1", `heading ${styles.title}`)
export const SubTitle = createStyledComponent(
  "h3",
  `heading ${styles.subTitle}`
)
export const Instructions = createStyledComponent(
  "p",
  `para ${styles.instructions}`
)
export const Body: ElementType = createStyledComponent("main", styles.body)
export const Footer = createStyledComponent("footer", styles.footer)
