import React, { PureComponent } from "react"

import { ClickMapContainerOld } from "Components/click-map/click-map-container-old"
import {
  ClickMarkerOld,
  ClickType,
} from "Components/click-map/click-marker-old"
import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"

function toClickType(click: NormalizedClick): ClickType {
  switch (click.hit) {
    case true:
      return ClickType.Hit
    case false:
      return ClickType.Miss
    case null:
      return ClickType.Default
  }
}

interface Props {
  clicks: ReadonlyArray<Readonly<NormalizedClick>>
}

export class ClickMapOld extends PureComponent<Props> {
  render() {
    const clickNodes = this.props.clicks.map((click) => (
      <ClickMarkerOld
        key={click.id}
        point={click}
        clickType={toClickType(click)}
      />
    ))
    return <ClickMapContainerOld>{clickNodes}</ClickMapContainerOld>
  }
}
