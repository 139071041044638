import { AvatarGroup } from "@chakra-ui/react"
import { AvatarWithTooltip } from "UsabilityHub/components/AvatarWithTooltip/AvatarWithTooltip"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { keyBy } from "lodash"
import React, { useMemo } from "react"
import { Booking } from "./types"

export const HostAvatars: React.FC<{
  booking: Booking
}> = ({ booking }) => {
  const teamMembers = useTeamMembers()

  const hosts = useMemo(() => {
    const teamMembersById = keyBy(teamMembers, "id")
    return booking.host_ids.map((id) => teamMembersById[id]).filter(Boolean)
  }, [teamMembers, booking.host_ids])

  return (
    <AvatarGroup>
      {hosts.map((host) => (
        <AvatarWithTooltip
          key={host.id}
          name={host.name}
          avatarUrl={host.avatar_url_medium}
        />
      ))}
    </AvatarGroup>
  )
}
