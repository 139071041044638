import { Image } from "@chakra-ui/react"
import React from "react"

import { LinkButton } from "Components/button/link-button"
import styles from "Components/test-results/question-results/tag-counts/tag-counts-placeholder.module.scss"
import Constants from "Constants/shared.json"
import tagsEmptySrc from "Images/tags-empty.svg"
const TagCountsPlaceholder: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <div className={styles.container}>
    <Image src={tagsEmptySrc} opacity={0.2} />
    <div className={styles.content}>{children}</div>
  </div>
)

const LearnMoreButton: React.FC<React.PropsWithChildren<unknown>> = () => (
  <LinkButton href={Constants.HELP_CENTER_ANSWER_TAGGING_URL} isExternal>
    Learn more
  </LinkButton>
)

export const TagCountsEmptyPlaceholder: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <TagCountsPlaceholder>
    <div>
      <p className="para">Tag some answers to see them visualized here.</p>
      <div className={styles.inlineButtons}>
        <LearnMoreButton />
      </div>
    </div>
  </TagCountsPlaceholder>
)
