import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ArrowLeftIcon = createIcon({
  displayName: "ArrowLeftIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="arrow-left">
        <path
          id="Shape"
          d="M15 7.125H4.325L9.225 2.225L8 1L1 8L8 15L9.225 13.775L4.325 8.875H15V7.125Z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})
