import { Dictionary, keyBy } from "lodash"
import { createSelector } from "reselect"

import { State } from "Redux/app-store"
import {
  ParticipantUsabilityTest,
  Screenshot,
  UsabilityTest,
  UsabilityTestSection,
} from "Types"

export const getScreenshots = (state: State): ReadonlyArray<Screenshot> =>
  state.screenshots

const screenshotsByIdSelector = createSelector(getScreenshots, (screenshots) =>
  keyBy(screenshots, "id")
)

const screenshotsByClientIdSelector = createSelector(
  getScreenshots,
  (screenshots) => keyBy(screenshots, "_clientId")
)

function createFinder<T extends keyof Screenshot>(
  key: T,
  getKeyed: (state: State) => Dictionary<Screenshot>
) {
  return (state: State, id: Screenshot[T]) => {
    const screenshot = getKeyed(state)[id as string]
    if (screenshot === null) {
      throw new TypeError(`No screenshot with ${key}=${String(id)}`)
    }
    return screenshot
  }
}

export const getScreenshotWithId = createFinder("id", screenshotsByIdSelector)
export const getScreenshotWithClientId = createFinder(
  "_clientId",
  screenshotsByClientIdSelector
)

export const usabilityTestSectionScreenshotsSelector = createSelector(
  (state: State) => state,
  (_: State, section: UsabilityTestSection) => section,
  (state, section) => {
    return section.section_screenshots.map((sectionScreenshot) =>
      getScreenshotWithId(state, sectionScreenshot.screenshot_id)
    )
  }
)

export function usabilityTestScreenshotsSelector(
  state: State,
  usabilityTest: UsabilityTest | ParticipantUsabilityTest
): ReadonlyArray<Readonly<Screenshot>> {
  return usabilityTest.sections.flatMap(
    (section: UsabilityTestSection) =>
      // see: https://github.com/Microsoft/TypeScript/issues/22685
      usabilityTestSectionScreenshotsSelector(state, section) as Array<
        Readonly<Screenshot>
      >
  )
}
