import { connect } from "react-redux"

import { Screenshot as ScreenshotComponent } from "Components/screenshot/screenshot"
import { State } from "Redux/app-store"
import { getScreenshotWithId } from "Redux/reducers/screenshots/selectors"
import { Omit, Screenshot } from "Types"

import { UnscaledScreenshot } from "./UnscaledScreenshot/UnscaledScreenshot"

interface StateProps {
  screenshot: Screenshot
}

type Props<T extends { screenshot: Readonly<Screenshot> }> = Omit<
  T,
  keyof StateProps
> & {
  screenshotId: number
}

function mapStateToProps<T extends { screenshot: Readonly<Screenshot> }>(
  state: State,
  ownProps: Props<T>
): StateProps {
  return { screenshot: getScreenshotWithId(state, ownProps.screenshotId) }
}

function mergeProps<T extends { screenshot: Readonly<Screenshot> }>(
  stateProps: StateProps,
  _dispatchProps: null,
  ownProps: Props<T>
): StateProps {
  // Merge props without those that are just for the public interface of either
  // exported component. This prevents warnings from React that `dispatch` (from
  // `dispatchProps`) and `screenshotId` are invalid properties for the `img`
  // tab.
  const { screenshotId, ...inheritedProps } = ownProps
  return { ...stateProps, ...inheritedProps }
}

function withScreenshotById() {
  return connect(mapStateToProps, null, mergeProps)
}

export const ScreenshotWithId = withScreenshotById()(ScreenshotComponent)
export const UnscaledScreenshotWithId = withScreenshotById()(UnscaledScreenshot)

if (process.env.DEBUG) {
  ScreenshotWithId.displayName = "ScreenshotWithId"
  UnscaledScreenshotWithId.displayName = "UnscaledScreenshotWithId"
}
