import React from "react"
import { connect } from "react-redux"

import { ClickResult } from "Components/individual-response/individual-section-result/click-result"
import {
  Duration,
  Name,
  Thumbnail,
  WrappedButton,
} from "Components/test-results/progress-box/progress-box"
import { ExpandableSectionScreenshotSummary } from "Components/test-results/section-results/expandable-section-screenshot-summary"
import { useToggleState } from "Hooks/use-toggle-state"
import { State } from "Redux/app-store"
import { getScreenshotWithId } from "Redux/reducers/screenshots/selectors"
import {
  Screenshot,
  ScreenshotClick,
  UsabilityTestSectionScreenshot,
} from "Types"

import { RecordingsSimplePreview } from "Components/test-results/section-results/SectionResultsCards/Recordings/RecordingsSimplePreview"
import { getResponseSectionForSectionIdAndResponseId } from "Redux/reducers/test-results/selectors"
import { useSectionContext } from "UsabilityHub/contexts"
import { useSelector } from "react-redux"
import { NavigationOutcome } from "./navigation-outcome"

interface ImplProps {
  readonly click: Readonly<ScreenshotClick> | null
  readonly deviceFrameId: number | null
  readonly screenshot: Readonly<Screenshot>
  readonly sectionScreenshot: Readonly<UsabilityTestSectionScreenshot>
  /**
   * A 1-indexed step number for this navigation test step.
   * @note Must be provided separately in case `sectionScreenshot.position` is wrong.
   */
  readonly stepNumber: number
}

const NavigationTestResultsStepSummaryImpl = (props: ImplProps) => {
  const { click, deviceFrameId, screenshot, sectionScreenshot, stepNumber } =
    props

  const [isExpanded, toggleExpanded] = useToggleState()

  const { section } = useSectionContext()

  const responseSection = useSelector(
    getResponseSectionForSectionIdAndResponseId(
      section.id,
      click?.response_id || -1
    )
  )

  const summaryNode = (
    <>
      <Thumbnail
        screenshot={screenshot}
        onClick={toggleExpanded}
        isExpanded={isExpanded}
      />
      <Name>
        <NavigationOutcome stepNumber={stepNumber} click={click} />
      </Name>
      <Duration duration={click && click.duration_ms} />
      <WrappedButton onClick={toggleExpanded} minW={28} ml={2}>
        {isExpanded ? "Hide details" : "Show details"}
      </WrappedButton>
      {responseSection && (
        <RecordingsSimplePreview responseSection={responseSection} />
      )}
    </>
  )

  const detailsNode = (
    <ClickResult
      sectionScreenshot={sectionScreenshot}
      deviceFrameId={deviceFrameId}
      hitzones={sectionScreenshot.screenshot_hitzones}
      clicks={click === null ? [] : [click]}
    />
  )

  return (
    <ExpandableSectionScreenshotSummary
      isExpanded={isExpanded}
      summaryChildren={summaryNode}
      detailsChildren={detailsNode}
      isFilter={false}
      isFiltered={false}
    />
  )
}

interface Props {
  readonly sectionScreenshot: Readonly<UsabilityTestSectionScreenshot>
}

export const NavigationTestResultsStepSummary = connect(
  (state: State, ownProps: Props) => ({
    screenshot: getScreenshotWithId(
      state,
      ownProps.sectionScreenshot.screenshot_id
    ),
  })
)(NavigationTestResultsStepSummaryImpl)
