import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const PreferenceTestIcon = createIcon({
  displayName: "PreferenceTestIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 8A8 8 0 110 8a8 8 0 0116 0zM5.752 4.5a2.466 2.466 0 012.251 1.498A2.466 2.466 0 0110.248 4.5 2.207 2.207 0 0112.5 6.747c0 1.4-1.197 2.552-2.994 4.282-.25.242-.514.495-.787.761a1.027 1.027 0 01-1.432 0c-.28-.272-.549-.53-.806-.778-1.788-1.718-2.98-2.864-2.98-4.265A2.211 2.211 0 015.75 4.5z"
    />
  ),
})
