import { Point } from "Types"

// This generic interface permits either React's `MouseEvent` or the standard
// `MouseEvent` provided by JS API.
export interface MouseEventLike {
  clientX: number
  clientY: number
  button: number
  currentTarget: any
}

export function getMousePosition(event: MouseEventLike): Point {
  return { x: event.clientX, y: event.clientY }
}

function getMouseOffset(
  event: MouseEventLike,
  element: HTMLElement = event.currentTarget as HTMLElement
): Point {
  const rect = element.getBoundingClientRect()
  return {
    x: Math.round(event.clientX - rect.left),
    y: Math.round(event.clientY - rect.top),
  }
}

export function getNormalizedMouseOffset(
  event: MouseEventLike,
  element: HTMLElement = event.currentTarget as HTMLElement
): Point {
  const px = getMouseOffset(event, element)
  return {
    x: px.x / element.offsetWidth,
    y: px.y / element.offsetHeight,
  }
}

export function isLeftButton(event: MouseEventLike): boolean {
  return event.button === 0
}
