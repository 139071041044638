import Constants from "Constants/shared.json"
import { centsToDollars } from "Utilities/currency"
import { Plan } from "~/api/generated/usabilityhubSchemas"

export const centsToDollarsRounded = (cents: number) => {
  return Number(centsToDollars(cents).toFixed(2))
}

export const estimatedLtv = (plan: Plan) => {
  // Average monthly churn rate for Google Ads converts is ~15%. This is higher
  // than the average across the board, but is the salient number for Google
  // Ads to use in its optimisation.
  const avgChurnRate = Constants.GOOGLE_ADS_AVG_CHURN_RATE
  return centsToDollarsRounded(plan.monthly_price / avgChurnRate)
}
