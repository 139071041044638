import { Box, BoxProps, Button, Flex, Text } from "@chakra-ui/react"
import React, {
  PropsWithChildren,
  RefObject,
  forwardRef,
  useCallback,
  useLayoutEffect,
  useState,
} from "react"

import { ScrollShadowsContainer } from "Components/scroll-shadow/scroll-shadows"

import { ExpandChevron } from "Components/expand-chevron/expand-chevron"

interface Props extends BoxProps {
  collapsedHeight: number
  expandHeight?: number | string
  expandText: string
  collapseText: string
}

export const ExpandableScrollContainer = forwardRef(
  (
    {
      children,
      collapseText,
      expandText,
      collapsedHeight,
      expandHeight = "auto",
      ...props
    }: PropsWithChildren<Props>,
    scrollableElementRef: RefObject<HTMLDivElement>
  ) => {
    const [canExpand, setCanExpand] = useState(true)
    const [isExpanded, setIsExpanded] = useState(false)

    const handleToggle = useCallback(() => {
      setIsExpanded((isExpanded) => !isExpanded)
    }, [setIsExpanded])

    useLayoutEffect(() => {
      if (!scrollableElementRef.current) return

      const { height } = scrollableElementRef.current.getBoundingClientRect()

      if (height < collapsedHeight) {
        setCanExpand(false)
      }
    }, [collapsedHeight])

    return (
      <Box {...props} overflow="hidden" isolation="isolate">
        <ScrollShadowsContainer
          style={{
            maxHeight: isExpanded ? expandHeight : collapsedHeight,
            overflowY: "auto",
          }}
          ref={scrollableElementRef}
        >
          {children}
        </ScrollShadowsContainer>

        {canExpand && (
          <Flex
            justify="center"
            py={5}
            sx={{ "@media print": { display: "none" } }}
          >
            <Button
              variant="link"
              colorScheme="teal"
              onClick={handleToggle}
              gap={2}
            >
              <ExpandChevron isExpanded={isExpanded} />
              <Text>{isExpanded ? collapseText : expandText}</Text>
            </Button>
          </Flex>
        )}
      </Box>
    )
  }
)

ExpandableScrollContainer.displayName = "ExpandableScrollContainer"
