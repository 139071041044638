import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query"

import {
  ListUsabilityTestsError,
  ListUsabilityTestsQueryParams,
  ListUsabilityTestsResponse,
  fetchListUsabilityTests,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

import { ListUsabilityTestsRequestBody } from "./../../api/generated/usabilityhub-components"

export const useInfiniteListUsabilityTests = (
  queryParams: Omit<ListUsabilityTestsQueryParams, "cursor">,
  filters: ListUsabilityTestsRequestBody["filters"],
  options?: Omit<
    UseInfiniteQueryOptions<
      ListUsabilityTestsResponse,
      ListUsabilityTestsError
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useUsabilityhubContext()

  return useInfiniteQuery<ListUsabilityTestsResponse, ListUsabilityTestsError>(
    queryKeyFn({
      path: "/api/usability_tests",
      operationId: "listUsabilityTests",
      variables: {
        queryParams: {
          ...fetcherOptions.queryParams,
          ...queryParams,
        },
        body: {
          filters,
        },
      },
    }),
    (context) =>
      fetchListUsabilityTests({
        ...fetcherOptions,
        queryParams: {
          ...queryParams,
          ...(context.pageParam ? { cursor: context.pageParam as string } : {}),
        },
        body: {
          filters,
        },
      }),
    {
      ...queryOptions,
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.next_page ?? undefined,
      retry: false,
    }
  )
}
