import { getFormValue } from "Redux/reducers/test-builder-form/selectors/formValues"
import { hasRecording } from "Redux/reducers/test-builder-form/selectors/sections"
import { hasTestLogic } from "Redux/reducers/test-builder-form/selectors/test-logic"
import { getEstimatedDurationRange } from "Redux/reducers/test-builder/selectors/estimate"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useSelector } from "react-redux"

/**
 * Work out which features to flag when the test or plan isn't upgraded.
 */
export const useRestrictedFeatures = (): string[] => {
  const account = useCurrentAccount()
  const accountCanCustomizeMessages =
    account.has_custom_welcome_and_thankyou_messages_feature
  const accountCanUseTestLogic = account.has_test_logic_feature
  const accountCanUseRecording = account.has_recordings_feature
  const isTestUpgraded = useSelector(getFormValue("upgraded")) as boolean
  const testHasWelcomeEnabled = useSelector(
    getFormValue("customize_welcome")
  ) as boolean
  const testHasThankYouEnabled = useSelector(
    getFormValue("customize_thankyou")
  ) as boolean
  const testHasTestLogic = useSelector(hasTestLogic)
  const testHasRecording = useSelector(hasRecording)
  const maxAllowedTestDurationInMinutes = account.max_test_duration_in_minutes
  const estimatedDurationRange = useSelector(getEstimatedDurationRange)

  const restrictedFeatures = []
  if (!isTestUpgraded) {
    // Test logic enabled
    if (!accountCanUseTestLogic && testHasTestLogic) {
      restrictedFeatures.push("Test logic")
    }

    // Recording enabled
    if (!accountCanUseRecording && testHasRecording) {
      restrictedFeatures.push("Recordings")
    }

    // Test has exceeded the maximum limit
    if (
      estimatedDurationRange &&
      maxAllowedTestDurationInMinutes &&
      maxAllowedTestDurationInMinutes > 0 &&
      estimatedDurationRange.upper_minutes > maxAllowedTestDurationInMinutes
    ) {
      restrictedFeatures.push(
        `Exceeds the ${maxAllowedTestDurationInMinutes} minute limit`
      )
    }

    // Custom messages enabled
    if (!accountCanCustomizeMessages) {
      if (testHasWelcomeEnabled) {
        restrictedFeatures.push("Custom welcome screen")
      }
      if (testHasThankYouEnabled) {
        restrictedFeatures.push("Custom thank you screen")
      }
    }
  }

  return restrictedFeatures
}
