import { useTestResultViewState } from "Components/test-results/context/test-result-view-state"
import { useSectionContext } from "UsabilityHub/contexts"
import { useIsPrototypeTaskFlow } from "./use-prototype-type"

export function useTaskGoalNodeId() {
  const { section } = useSectionContext()
  const { goalScreenIdBySectionId } = useTestResultViewState()

  const isTaskFlow = useIsPrototypeTaskFlow()
  if (!isTaskFlow) {
    // Only task flow goals are recognised when the feature flag is enabled
    return null
  }

  return goalScreenIdBySectionId[section.id]
}
