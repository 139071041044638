import { Filter } from "Components/test-results/types/filters"
import { FilterData } from "./types"

export type VariableFilter = Filter<
  `response/attribute:variable/${string}`,
  "attribute/variable",
  string
>

export const filterResponseByVariableValue = (
  filterData: FilterData<VariableFilter>
) => {
  const { response, filter } = filterData

  if (!response.query_parameters) return false

  const variableName = filter.category.split("/")[2]
  return response.query_parameters[variableName] === filter.value
}
