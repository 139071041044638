import {
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react"
import {
  CollectionIcon,
  FolderIcon,
  ViewGridIcon,
} from "@heroicons/react/solid"
import { useFilteredResponses } from "Components/test-results/hooks/use-filtered-responses"
import { QuestionResultsList } from "Components/test-results/question-results/question-results-list"
import { RecordingsTab } from "Components/test-results/section-results/SectionResultsCards/Recordings/RecordingsTab"
import { useSectionRecordings } from "Components/test-results/section-results/SectionResultsCards/Recordings/useSectionRecordings"
import { SectionResultsCard } from "Components/test-results/section-results/SectionResultsCards/SectionResultsCard"
import { useAccountEnabledFeature } from "Hooks/use-account-disabled-feature"
import { State } from "Redux/app-store"
import { getResponseSectionCardSortsForCardSort } from "Redux/reducers/test-results/selectors"
import { SimilarityIcon } from "Shared/icons/SimilarityIcon"
import { PlaySquareSolidIcon } from "Shared/icons/untitled-ui/PlaySquareSolidIcon"
import { SectionResultsSectionText } from "UsabilityHub/components/TestResults/SectionResultsLabels"
import { useSectionContext } from "UsabilityHub/contexts"
import { RECORDING_KEY } from "UsabilityHub/views/settings/FeatureAccessPage/FeatureAccessForm"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import {
  CardSortResultsContextProvider,
  useCardSortResultsContext,
} from "./CardSortResultsContext"
import { CardSortSectionResultsAgreementMatrixTab } from "./CardSortSectionResultsAgreementMatrixTab"
import { CardSortSectionResultsCardsTab } from "./CardSortSectionResultsCardsTab"
import { CardSortSectionResultsCategoryTab } from "./CardSortSectionResultsCategoryTab"
import { CardSortSectionResultsSimilarityMatrixTab } from "./CardSortSectionResultsSimilarityMatrixTab"

export const CardSortSectionResultsCard: React.FC = () => {
  const { section } = useSectionContext()
  const filteredResponses = useFilteredResponses()
  const cardSort = section.card_sort_attributes
  const visibleResponseIds = new Set(filteredResponses.map(({ id }) => id))
  const allData = useSelector((state: State) =>
    cardSort?.id
      ? getResponseSectionCardSortsForCardSort(state, cardSort.id)
      : []
  )
  const sortData = useMemo(() => {
    return allData.filter(
      (d) => d.response_id && visibleResponseIds.has(d.response_id)
    )
  }, [allData, visibleResponseIds])

  if (!cardSort) return null

  return (
    <SectionResultsCard>
      <SectionResultsSectionText />

      <CardSortResultsContextProvider
        sectionId={section.id}
        cardSort={cardSort}
        sortData={sortData}
      >
        <CardSortTabs />
      </CardSortResultsContextProvider>

      <QuestionResultsList />
    </SectionResultsCard>
  )
}

const CardSortTabs = () => {
  const { cards, categories, categoryGroups, cardSortType } =
    useCardSortResultsContext()

  const categoryCount =
    cardSortType === "open"
      ? Object.keys(categoryGroups).length
      : categories.length

  const recordings = useSectionRecordings()
  const recordingFeatureEnabled = useAccountEnabledFeature(RECORDING_KEY)
  const showRecordingsTab = recordings.length > 0 && recordingFeatureEnabled

  return (
    <Tabs isLazy>
      <TabList>
        <Tab data-qa="card-sort-cards-tab">
          <Icon as={CollectionIcon} boxSize={5} me={2} />
          <Text>Cards {cards.length}</Text>
        </Tab>

        <Tab data-qa="card-sort-categories-tab">
          <Icon as={FolderIcon} boxSize={5} me={2} />
          <Text>Categories {categoryCount}</Text>
        </Tab>

        <Tab data-qa="card-sort-agreement-matrix-tab">
          <Icon as={ViewGridIcon} boxSize={5} me={2} />
          <Text>Agreement matrix</Text>
        </Tab>

        {cardSortType !== "closed" && (
          <Tab data-qa="card-sort-similarity-matrix-tab">
            <Icon as={SimilarityIcon} boxSize={4} me={2} />
            <Text>Similarity matrix</Text>
          </Tab>
        )}
        {showRecordingsTab && (
          <Tab>
            <HStack spacing={2}>
              <Icon as={PlaySquareSolidIcon} />
              <span>Recordings {recordings.length}</span>
            </HStack>
          </Tab>
        )}
      </TabList>
      <TabPanels>
        <TabPanel p={0}>
          <CardSortSectionResultsCardsTab />
        </TabPanel>

        <TabPanel p={0}>
          <CardSortSectionResultsCategoryTab />
        </TabPanel>

        <TabPanel px={0}>
          <CardSortSectionResultsAgreementMatrixTab />
        </TabPanel>

        {cardSortType !== "closed" && (
          <TabPanel px={0}>
            <CardSortSectionResultsSimilarityMatrixTab />
          </TabPanel>
        )}

        {showRecordingsTab && (
          <TabPanel px={0}>
            <RecordingsTab />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  )
}
