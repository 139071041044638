import { AnimatePresence, motion } from "framer-motion"
import React, { forwardRef } from "react"
import { AnimatedLink } from "./AnimatedLink"
import { usePathDiagramColors } from "./Legend"
import { LaidOutLink, LaidOutNode } from "./useTreeTestPathDiagramLayout"

type ExpandableNodeProps = {
  node: LaidOutNode
  x?: number
  y?: number
  initialY?: number
  clickedDepth?: number
  horizontalGap: number
  onClick: (node: LaidOutNode, e: React.MouseEvent) => void
  onNodeMouseOver: (node: LaidOutNode) => void
  onNodeMouseOut: () => void
  onLinkMouseOver: (link: LaidOutLink) => void
  onLinkMouseOut: () => void
}

export const ExpandableNode = forwardRef<SVGGElement, ExpandableNodeProps>(
  (
    {
      node,
      x = 0,
      y = 0,
      initialY = 0,
      clickedDepth = 0,
      onClick,
      horizontalGap,
      onNodeMouseOver,
      onNodeMouseOut,
      onLinkMouseOver,
      onLinkMouseOut,
    },
    ref
  ) => {
    const colors = usePathDiagramColors()

    const nodeColor = node.children.length
      ? colors.node.neutral
      : colors.node[node.result]

    const totalChildHeights = node.children.reduce(
      (acc, { height }) => acc + height,
      0
    )
    const nodeHeight = node.height - 4
    let yy = 0
    const initialYValues = node.children.reduce(
      (acc: Record<string, number>, child) => {
        acc[child.id] = yy
        yy += (child.height * nodeHeight) / totalChildHeights
        return acc
      },
      {}
    )

    return (
      <motion.g
        ref={ref}
        key={node.id}
        initial={{
          x: 0,
          y: initialY,
          opacity: 0,
        }}
        animate={{
          x,
          y,
          opacity: 1,
          transition: {
            duration: 0.5,
            delay: Math.max(0, node.depth - clickedDepth - 1) * 0.5,
          },
        }}
        exit={{ x: 0, y: initialY, opacity: 0, transition: { duration: 0.5 } }}
        pointerEvents="none"
      >
        <AnimatePresence>
          {node.expanded &&
            node.links.map((link) => (
              <AnimatedLink
                key={`${link.sourceId}:${link.targetId}:${link.result}`}
                link={link}
                offsetX={0}
                offsetY={-node.y}
                delay={Math.max(0, node.depth - clickedDepth) * 0.5}
                onMouseOver={onLinkMouseOver}
                onMouseOut={onLinkMouseOut}
              />
            ))}
          {node.expanded &&
            node.children.map((child) => (
              <ExpandableNode
                key={child.id}
                node={child}
                x={horizontalGap}
                y={child.y - node.y}
                initialY={initialYValues[child.id] ?? 0}
                clickedDepth={clickedDepth}
                horizontalGap={horizontalGap}
                onClick={onClick}
                onNodeMouseOver={onNodeMouseOver}
                onNodeMouseOut={onNodeMouseOut}
                onLinkMouseOver={onLinkMouseOver}
                onLinkMouseOut={onLinkMouseOut}
              />
            ))}
        </AnimatePresence>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents lint/a11y/useKeyWithMouseEvents: no keyboard support yet */}
        <rect
          data-id={node.id}
          x={0 - 14}
          y={-2}
          width={28}
          height={node.height + 4}
          stroke={nodeColor.stroke}
          strokeWidth={2}
          fill={nodeColor.fill}
          rx={4}
          pointerEvents="auto"
          cursor={node.children.length ? "pointer" : "default"}
          onClick={(e) => onClick(node, e)}
          onMouseOver={() => onNodeMouseOver(node)}
          onMouseOut={onNodeMouseOut}
        />
        {node.children.length > 0 && (
          <path
            d={`M-5,0h10${node.expanded ? "" : "m-5-5v10"}`}
            transform={`translate(0, ${node.height / 2})`}
            fill="none"
            stroke={colors.node.neutral.stroke}
            strokeWidth={2}
            strokeLinecap="round"
            pointerEvents="none"
          />
        )}
        <text
          x={20}
          y={node.height / 2 + 4}
          fill={colors.label}
          pointerEvents="none"
          fontSize="12"
          fontFamily="Inter, sans-serif"
        >
          {node.node?.label || "Pass"} ({node.value})
        </text>
      </motion.g>
    )
  }
)
