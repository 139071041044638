import { Button, Flex, Link, Text } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import React from "react"
import { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"
import { RecruitMethodCard } from "./RecruitMethodSelection/RecruitMethodSelection"

type Props = {
  onManageLink?: () => void
  recruitmentLink: RecruitmentLink | null
  isTestSetLink: boolean
}

export const LinkRecruitmentMethod = ({
  onManageLink,
  recruitmentLink,
  isTestSetLink,
}: Props) => {
  const recruitmentLinkEnabled = !!recruitmentLink?.active

  return (
    <RecruitMethodCard
      method="link"
      bodyContent={
        <Flex flexDirection="column" gap={6}>
          <Text textStyle="ds.paragraph.emphasized">
            Use a link to invite users via any channel you like.
          </Text>
          {isTestSetLink ? (
            <Text textStyle="ds.paragraph.emphasized">
              Participants recruited via this link will be directed to take one
              of the variations in this set at random.{" "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={Constants.HELP_CENTER_VARIATION_SETS_RECRUIT_URL}
              >
                Learn more.
              </Link>
            </Text>
          ) : null}
        </Flex>
      }
      action={
        <Button
          w="fit-content"
          colorScheme="brand.primary"
          onClick={onManageLink}
          isDisabled={!onManageLink}
          data-intercom-target="set-up-link-button"
          data-qa="recruit-from-link-button"
        >
          {recruitmentLinkEnabled ? "Manage link" : "Set up link"}
        </Button>
      }
    />
  )
}
