import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react"

import { updateUserActivityAction } from "Hooks/useUsabilityTestUserActivityLiveUpdates"
import { UsabilityTestUserActivity } from "Types"

type UsabilityTestUserActivityContextValue = {
  readOnly: boolean
  userActivities: UsabilityTestUserActivity[]
  updateUserActivity: updateUserActivityAction
}

const UsabilityTestUserActivityContext =
  createContext<UsabilityTestUserActivityContextValue>({
    readOnly: true,
    userActivities: [],
    updateUserActivity: () => {
      throw new Error("No provider!")
    },
  })

export const useUsabilityTestUserActivityContext =
  (): UsabilityTestUserActivityContextValue => {
    return useContext(UsabilityTestUserActivityContext)
  }

interface Props {
  userActivities: UsabilityTestUserActivity[]
  updateUserActivity: updateUserActivityAction
  isNewTest: boolean
  showHasResponsesWarning: boolean
  setAcceptedWarning: (accepted: boolean) => void
}

export const UsabilityTestUserActivityProvider: React.FC<
  PropsWithChildren<Props>
> = ({
  children,
  userActivities,
  updateUserActivity,
  isNewTest,
  showHasResponsesWarning,
  setAcceptedWarning,
}) => {
  const [readOnly, setReadOnly] = useState(!isNewTest)

  useEffect(() => {
    if (!isNewTest && !showHasResponsesWarning) {
      const currentActivity = userActivities.find(
        (activity) => activity.session_id === sessionStorage.getItem("tabId")
      )

      // Once the connection is interrupted accidently,
      // it will be changed to read-only mode but keep local changes.
      // And the user can click Edit button and then save previous changes.
      setReadOnly(!currentActivity)
    }
  }, [userActivities, isNewTest, showHasResponsesWarning])

  useEffect(() => {
    if (readOnly) setAcceptedWarning(false)
  }, [readOnly, setAcceptedWarning])

  return (
    <UsabilityTestUserActivityContext.Provider
      value={{ readOnly, userActivities, updateUserActivity }}
    >
      {children}
    </UsabilityTestUserActivityContext.Provider>
  )
}
