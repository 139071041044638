import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import {
  useRequestUnarchive,
  useUnarchiveRequested,
} from "~/api/generated/usabilityhub-components"

enum AccessRequestState {
  Loading,
  NotRequested,
  Requested,
}

export const AccountArchivedBanner: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const toast = useToast()
  const {
    isOpen: isRequestAccessModalOpen,
    onOpen: onRequestAccessModalOpen,
    onClose: onRequestAccessModalClose,
  } = useDisclosure()
  const [accessRequested, setAccessRequested] = useState(
    AccessRequestState.Loading
  )
  const { mutate: requestUnarchive, isLoading: isRequesting } =
    useRequestUnarchive({
      onSuccess: () => {
        setAccessRequested(AccessRequestState.Requested)
        onRequestAccessModalOpen()
      },
      onError: (error) => {
        toast({
          title: error.payload.message || "Something went wrong",
          status: "error",
        })
      },
    })

  // Check if the user has already requested to be unarchived
  const { data: requestedData, isError: isRequestedError } =
    useUnarchiveRequested({})
  const requested = !!requestedData?.requested

  useEffect(() => {
    setAccessRequested(
      // If we encounter an error, we can safely assume they haven't requested
      // to be unarchived because the backend will error if they've already
      // requested
      isRequestedError
        ? AccessRequestState.NotRequested
        : requested
          ? AccessRequestState.Requested
          : AccessRequestState.NotRequested
    )
  }, [requested])

  const sendAccessRequest = async () => {
    requestUnarchive({})
  }

  return (
    <>
      <Alert status="error" mt={-2} borderWidth={1} borderColor="red">
        <AlertIcon />
        <AlertTitle>Your account has been archived</AlertTitle>
        <AlertDescription>
          If you{"\u2019"}d like access again, make a request to your account
          owner.
          <Spacer />
          {accessRequested === AccessRequestState.NotRequested ? (
            <Button
              flexShrink={0}
              marginLeft={2}
              isDisabled={isRequesting}
              colorScheme="brand.primary"
              variant="link"
              textDecoration="none"
              onClick={sendAccessRequest}
            >
              Request access
            </Button>
          ) : (
            <Button
              flexShrink={0}
              marginLeft={2}
              as={Box}
              colorScheme="gray"
              variant="link"
              textDecoration="none"
              _hover={{ textDecoration: "none" }}
            >
              {accessRequested === AccessRequestState.Loading
                ? "Loading..."
                : "Access requested"}
            </Button>
          )}
        </AlertDescription>
      </Alert>

      <Modal
        isOpen={isRequestAccessModalOpen}
        onClose={onRequestAccessModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request sent</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Your access request has been sent to the account owner. Once they
            approve you{"\u2019"}ll get a confirmation email.
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="brand.primary"
              onClick={onRequestAccessModalClose}
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
