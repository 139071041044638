import {
  Box,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  Stack,
} from "@chakra-ui/react"
import { countBy, get } from "lodash"
import React, { FocusEventHandler } from "react"
import { Draggable } from "react-beautiful-dnd"
import { useDispatch, useSelector } from "react-redux"
import { Fields, FormSection, WrappedFieldsProps } from "redux-form"

import { Dispatch } from "Redux/app-store"
import {
  addCard,
  deleteCard,
  editCard,
} from "Redux/reducers/test-builder-form/action-creators/card-sort"
import { getFormValues } from "Redux/reducers/test-builder-form/selectors/formValues"
import { CardSortCard } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import { ItemIndexProvider } from "UsabilityHub/contexts/ItemIndexContext"

import {
  DraggableQuestionRow,
  DraggableRowHandle,
} from "../SectionQuestions/DragDrop/DraggableQuestionRow"
import { useSetCustomValidity } from "../SectionQuestions/QuestionFields/MultipleChoice/useSetCustomValidity"
import { useShouldAutoFocus } from "../useShouldAutofocus"

import { Trash01SolidIcon } from "Shared/icons/untitled-ui/Trash01SolidIcon"
import { CardImage } from "./CardImage/CardImage"

interface CardSortSectionSingleCardProps {
  onEnterPress: (optionIndex: number) => void
  skipAutoFocus: boolean
  cardIndex: number
}

const WrappedCardSortSectionSingleCard: React.FC<
  WrappedFieldsProps & CardSortSectionSingleCardProps
> = ({ label, description, cardIndex, skipAutoFocus, onEnterPress }) => {
  const dispatch: Dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const { section } = useSectionContext()
  const formValues = useSelector(getFormValues)
  const { readOnly } = useUsabilityTestUserActivityContext()

  const showImages = section?.card_sort_attributes?.show_images
  const showDescriptions = section?.card_sort_attributes?.show_descriptions
  const cardCount =
    section.card_sort_attributes?.card_sort_cards_attributes.length ?? 0
  const draggableId = `sections.${sectionIndex}.card_sort_attributes.card_sort_cards_attributes.${cardIndex}`
  const isDeleteDisabled = cardCount <= 1
  const autoFocus =
    useShouldAutoFocus(label.input.value) && !skipAutoFocus && cardIndex > 0 // Won't focus on the first card on card sort initialization

  const labelInputRef = useSetCustomValidity(label.meta.error)

  const onDeleteOption = () => {
    dispatch(deleteCard(sectionIndex, cardIndex))
  }

  const onLabelPaste: React.ClipboardEventHandler = (e) => {
    const pastedText = e.clipboardData.getData("text")
    const textArray = pastedText.split("\n")

    // If there's no newlines we just paste the text into the input as normal
    if (textArray.length === 1) return

    // Otherwise we split the text on newlines and create a new card per line
    let cardOffset = 0
    textArray.forEach((text, index) => {
      const fieldName = `sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes`
      let calculatedIndex = cardIndex + index + cardOffset
      let existingCard = get(
        formValues,
        `${fieldName}[${calculatedIndex}]`,
        null
      ) as CardSortCard | null

      while (existingCard && existingCard._destroy) {
        cardOffset += 1
        calculatedIndex += 1
        existingCard = get(
          formValues,
          `${fieldName}[${calculatedIndex}]`,
          null
        ) as CardSortCard | null
      }

      if (existingCard) {
        // If there's already a card we just update the label
        dispatch(editCard(sectionIndex, calculatedIndex, text))
      } else {
        // For the other pasted rows we'll create a new card
        dispatch(addCard(sectionIndex, text))
      }
    })

    // Ignore the original paste event otherwise we end up with an extra card
    e.preventDefault()
  }

  return (
    <Draggable
      isDragDisabled={readOnly}
      draggableId={draggableId}
      key={draggableId}
      index={cardIndex}
    >
      {(draggable, snapshot) => (
        <DraggableQuestionRow draggableProvided={draggable} snapshot={snapshot}>
          <HStack py={2} px={2} data-qa={`card-sort-card-${cardIndex}`}>
            {!readOnly && <DraggableRowHandle draggableProvided={draggable} />}

            {showImages && (
              <ItemIndexProvider value={cardIndex}>
                <CardImage allowedMediaTypes={["image"]} />
              </ItemIndexProvider>
            )}

            <Stack flexGrow={1}>
              <FormControl isInvalid={!label.meta.valid}>
                <Input
                  ref={labelInputRef}
                  autoFocus={autoFocus}
                  isRequired
                  isReadOnly={readOnly}
                  onKeyDown={(evt) =>
                    evt.key === "Enter" && onEnterPress(cardIndex)
                  }
                  onPaste={onLabelPaste}
                  placeholder="Card Text"
                  data-role="card-label"
                  data-qa="card-sort-card-input"
                  fontWeight="bold"
                  _placeholder={{ fontWeight: "bold" }}
                  {...label.input}
                />
              </FormControl>
              {showDescriptions && (
                <Input
                  placeholder="Card Description"
                  isInvalid={!description.meta.valid}
                  isReadOnly={readOnly}
                  onKeyDown={(evt) =>
                    evt.key === "Enter" && onEnterPress(cardIndex)
                  }
                  {...description.input}
                />
              )}
            </Stack>

            <Box>
              <IconButton
                mr={-2}
                variant="ghost"
                aria-label="Delete card"
                icon={
                  <Icon color="gray.500" boxSize={5} as={Trash01SolidIcon} />
                }
                onClick={onDeleteOption}
                isDisabled={readOnly || isDeleteDisabled}
              />
            </Box>
          </HStack>
        </DraggableQuestionRow>
      )}
    </Draggable>
  )
}

export const CardSortSectionSingleCard: React.FC<
  CardSortSectionSingleCardProps
> = ({ cardIndex, skipAutoFocus, onEnterPress }) => {
  // Supress onBlur event since it updates empty strings to null
  const onBlur: FocusEventHandler<HTMLInputElement> = (event) =>
    event.preventDefault()
  const sectionIndex = useSectionIndexContext()

  return (
    <FormSection
      name={`sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes[${cardIndex}]`}
    >
      <Fields
        names={[`label`, `description`]}
        component={WrappedCardSortSectionSingleCard}
        cardIndex={cardIndex}
        skipAutoFocus={skipAutoFocus}
        onEnterPress={onEnterPress}
        onBlur={onBlur}
        validate={validateCard}
      />
    </FormSection>
  )
}

const validateCard = (
  value: string | null,
  allValues: any,
  _props: any,
  name: string
) => {
  if (!name.endsWith(".label")) return
  if (!value) return

  const parent = name.replace(/\[\d+\]\.label$/, "")
  const allCards = get(allValues, parent, []) as CardSortCard[]
  const activeCards = allCards.filter((c) => !c._destroy)
  const countsByLabel = countBy(activeCards, "label")

  return countsByLabel[value] > 1 ? "Duplicate card text" : undefined
}
