import React from "react"

import styles from "./individual-answer-result.module.scss"
import { Props } from "./props"

export const CheckboxAnswer = (props: Props) => (
  <ul className={styles.list}>
    {props.answer.answers.map((a, i) => (
      <li key={i}>{a}</li>
    ))}
  </ul>
)
