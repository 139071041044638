import React, { PropsWithChildren } from "react"

interface SharedTestResultsContext {
  isShared: boolean
}

const SharedTestResultsContext = React.createContext<SharedTestResultsContext>({
  isShared: false,
})

// Not using the typical guard clause pattern here since this provider only exists on the shared
// version of the results and we're happy to use the default value in other places.
export const useIsSharedTestResults = () => {
  const { isShared } = React.useContext(SharedTestResultsContext)

  return isShared
}

interface ProviderProps {
  isShared: boolean
}

export const SharedTestResultsContextProvider: React.FC<
  PropsWithChildren<ProviderProps>
> = ({ isShared, children }) => {
  return (
    <SharedTestResultsContext.Provider value={{ isShared }}>
      {children}
    </SharedTestResultsContext.Provider>
  )
}
