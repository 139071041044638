import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import React from "react"
import { EditDemographicForm } from "./EditDemographicForm"
import { DemographicAttribute } from "./types"

type Props = {
  demographicAttribute: DemographicAttribute | null
  onClose: () => void
  isOpen: boolean
}

export const CreateOrUpdateDemographicModal: React.FC<Props> = ({
  demographicAttribute,
  onClose,
  isOpen,
}) => {
  return (
    <Modal isCentered size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          {demographicAttribute
            ? `Edit "${demographicAttribute.question}"`
            : "Create new demographic attribute"}
          <ModalCloseButton />
        </ModalHeader>

        <EditDemographicForm
          demographicAttribute={demographicAttribute}
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  )
}
