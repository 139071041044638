import { createIcon } from "@chakra-ui/react"
import React from "react"

export const InterviewCalendarIcon = createIcon({
  displayName: "InterviewCalendarIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M8 1a1 1 0 0 1 1 1v1h6V2a1 1 0 1 1 2 0v1.002c.476.003.891.013 1.252.042.562.046 1.079.145 1.564.392a4 4 0 0 1 1.748 1.748c.247.485.346 1.002.392 1.564C22 7.29 22 7.954 22 8.758V11.5a1 1 0 1 1-2 0V11H4v6.2c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 0 0 .874.874c.156.08.38.145.819.18C6.361 21 6.943 21 7.8 21h4.7a1 1 0 1 1 0 2H7.759c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C2 18.71 2 18.046 2 17.242V8.758c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565a4 4 0 0 1 1.748-1.748c.485-.247 1.002-.346 1.564-.392.361-.03.777-.04 1.252-.042V2a1 1 0 0 1 1-1ZM7 5.002c-.446.003-.795.012-1.089.036-.438.035-.663.1-.819.18a2 2 0 0 0-.874.874c-.08.156-.145.38-.18.819C4 7.361 4 7.943 4 8.8V9h16v-.2c0-.857 0-1.439-.038-1.889-.035-.438-.1-.663-.18-.819a2 2 0 0 0-.874-.874c-.156-.08-.38-.145-.819-.18A15.155 15.155 0 0 0 17 5.002V6a1 1 0 1 1-2 0V5H9v1a1 1 0 1 1-2 0v-.998ZM18 14a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Z"
      fill="currentColor"
    />
  ),
})
