import { CSSProperties } from "react"

import { Color } from "Types"

/**
 * Safely turns a URL into an argument suitable for CSS rules such as
 * `background-image`.
 *
 * If `url` is falsy it will be returned directly, avoiding an issue where an
 * element may end up with a rule such as:
 *
 *   background-image: url(undefined)
 */
export function url(urlString: string | undefined | null): string | undefined {
  return urlString ? `url(${urlString})` : undefined
}

/**
 * @param {Number} value01 Number in range [0, 1] to be converted to percentage
 * @param {Number} scale The number of decimal places
 * @return {String}
 */
export function percentage(value01: number, scale?: number): string {
  let value: number | string = (value01 || 0) * 100
  if (scale !== undefined && Number.isFinite(scale)) {
    value = Number(value).toFixed(scale)
  }
  return `${value}%`
}

export function pixels(value: number): string {
  return `${value}px`
}

export function backgroundSizePercentage(x: number, y: number): CSSProperties {
  return { backgroundSize: `${percentage(x)} ${percentage(y)}` }
}

export function rgb({ r, g, b }: Color) {
  const hex = (value: number) => (Math.floor(value) % 256).toString(16)
  return `#${hex(r)}${hex(g)}${hex(b)}`
}
