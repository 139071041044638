import { Grid } from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"

import AsyncStripeProvider from "Components/async-stripe-provider/async-stripe-provider"

import { ChurnSurvey } from "./churn-survey"
import { ContinueOnFree } from "./continue-on-free"
import { EnterpriseContact } from "./enterprise-contact"
import { PaymentDetails } from "./payment-details"
import { PlanChangeFailed } from "./plan-change-failed"
import { PlanChangeSuccess } from "./plan-change-success"
import { usePlanChangerContext } from "./plan-changer-context"
import { PlanComparisonGrid } from "./plan-comparison-grid"
import { PlanDetails } from "./plan-details"

export const PlanChanger: React.FC = () => {
  const { screen } = usePlanChangerContext()

  switch (screen) {
    case "payment-details":
      // payment-details screen will be used for all upgrades and downgrades to a paid plan
      return (
        <SplitPanelLayout>
          <AsyncStripeProvider>
            <PaymentDetails p={8} />
          </AsyncStripeProvider>
        </SplitPanelLayout>
      )
    case "churn-survey":
      return (
        <SplitPanelLayout>
          <ChurnSurvey p={8} />
        </SplitPanelLayout>
      )
    case "enterprise-contact":
      return <EnterpriseContact p={8} />
    case "continue-on-free":
      // Opting to revert to the Free plan from the no plan state after trial ends
      return (
        <SplitPanelLayout>
          <ContinueOnFree p={8} />
        </SplitPanelLayout>
      )
    case "plan-change-success":
      return (
        <SplitPanelLayout>
          <PlanChangeSuccess p={8} />
        </SplitPanelLayout>
      )
    case "plan-change-failed":
      return (
        <SplitPanelLayout>
          <PlanChangeFailed p={8} />
        </SplitPanelLayout>
      )
    case "plan-grid":
      return <PlanComparisonGrid px={8} py={4} />
    default:
      assertNever(screen)
      return null
  }
}

const SplitPanelLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid templateColumns="1fr 1fr" h="full">
      <PlanDetails p={8} borderRightWidth={1} borderColor="gray.200" />
      {children}
    </Grid>
  )
}

const assertNever = (val: never): never => {
  throw new Error(`Unknown screen: ${JSON.stringify(val)}`)
}
