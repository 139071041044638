import { Link, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import React from "react"

import { MailToAnchor } from "Components/anchors/basic"
import Constants from "Constants/shared.json"
import {
  Document,
  DocumentIntro,
  DocumentSection,
} from "Shared/components/Document/Document"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { RoutedLink } from "Shared/components/Links/RoutedLink"
import { ROUTES } from "UsabilityHub/views/routes"

export const SecurityInformationDocument = () => (
  <Document title="Security information">
    <DocumentIntro>
      <Text>
        We understand that the security and privacy of your company{"\u2019"}s
        information is extremely important. To this effect, we make every effort
        to ensure that your information is always handled responsibly and
        securely. This page aims to answer some of the questions we frequently
        receive regarding security practices at Lyssna. If you have any other
        questions please don{"\u2019"}t hesitate to{" "}
        <ExternalLink href={Constants.CONTACT_US_URL}>contact us</ExternalLink>.
      </Text>
    </DocumentIntro>
    <DocumentSection title="SOC 2 Type II certified">
      <Text>
        Lyssna holds a SOC 2 Type II certification covering the security,
        availability and confidentiality trust service criteria.
      </Text>
      <Text>
        We undergo an annual audit from an AICPA certified firm covering the
        following:
      </Text>
      <Text>
        <UnorderedList>
          <ListItem>
            Security: The system is protected against unauthorized access (both
            physical and logical).
          </ListItem>
          <ListItem>
            Availability: The system is available for operation and use as
            committed or agreed.
          </ListItem>
          <ListItem>
            Confidentiality: Information designated as confidential is protected
            as committed or agreed.
          </ListItem>
        </UnorderedList>
      </Text>
      <Text>
        Customers or potential customers interested in attaining a copy of our
        SOC 2 report can{" "}
        <ExternalLink href={Constants.CONTACT_US_URL}>contact us</ExternalLink>.
      </Text>
    </DocumentSection>
    <DocumentSection title="Security policies">
      <Text>
        Lyssna has a comprehensive suite of information security policies
        covering the following key areas:
      </Text>
      <Text>
        <UnorderedList>
          <ListItem>Information security</ListItem>
          <ListItem>Information security roles and responsibilities</ListItem>
          <ListItem>Operations security</ListItem>
          <ListItem>Access control</ListItem>
          <ListItem>Asset management</ListItem>
          <ListItem>Business continuity and disaster recovery</ListItem>
          <ListItem>Cryptography</ListItem>
          <ListItem>Data management</ListItem>
          <ListItem>Human resource security</ListItem>
          <ListItem>Incident response</ListItem>
          <ListItem>Risk management</ListItem>
          <ListItem>Secure development</ListItem>
          <ListItem>Third party management</ListItem>
        </UnorderedList>
      </Text>
      <Text>
        Our management team review and update these policies annually, and all
        team members review and agree to them annually.
      </Text>
    </DocumentSection>
    <DocumentSection title="Penetration testing">
      <Text>
        Lyssna undertakes penetration tests at least annually. Please{" "}
        <ExternalLink href={Constants.CONTACT_US_URL}>contact us</ExternalLink>
        to request a copy of our most recent penetration test.
      </Text>
    </DocumentSection>
    <DocumentSection title="Code review">
      <Text>
        All changes to Lyssna{"\u2019"}s software must pass code review by our
        engineering team before being merged to ensure quality and security are
        maintained.
      </Text>
      <Text>
        Our CI/CD pipeline runs a comprehensive suite of unit and integration
        tests. We also conduct dependency vulnerability scans and static
        application security testing in every CI pipeline to minimise the risk
        of security vulnerabilities.
      </Text>
    </DocumentSection>
    <DocumentSection title="Responsible disclosure program">
      <Text>
        We host a bug bounty program on{" "}
        <ExternalLink href="https://hackerone.com">HackerOne</ExternalLink> for
        security researches to be rewarded for notifying us of vulnerabilities
        they find in our platform using appropriate channels.
      </Text>
      <Text>
        Please{" "}
        <MailToAnchor
          email={Constants.SECURITY_EMAIL_ADDRESS}
          subject="HackerOne invitation request"
          body="Please provide your HackerOne username and a brief overview of your security research experience."
        >
          contact us
        </MailToAnchor>{" "}
        to request an invite to our bug bounty program.
      </Text>
    </DocumentSection>
    <DocumentSection title="Encryption">
      <Text>
        All access to the Lyssna website and application is restricted to HTTPS
        encrypted connections. Lyssna does not service insecure HTTP requests.
      </Text>
      <Text>
        Our production database is encrypted at rest using AES-256, block-level
        storage encryption. Database backups are stored in an AES-256 encrypted
        S3 bucket.
      </Text>
      <Text>
        Lyssna passwords are stored responsibly using BCrypt. They are never
        stored in plaintext and are not readable by staff.
      </Text>
    </DocumentSection>
    <DocumentSection title="Credit card handling and storage">
      <Text>
        Credit card processing is handled by{" "}
        <ExternalLink href="https://stripe.com/">Stripe</ExternalLink>. Credit
        card numbers and CVC numbers are handled exclusively by Stripe in
        accordance with PCI Data Security Standards (PCI DSS) and are not made
        available to Lyssna. Please see{" "}
        <ExternalLink href="https://stripe.com/docs/security/stripe">
          Stripe{"\u2019"}s Security Documentation
        </ExternalLink>{" "}
        for more details about their payment system and security measures.
      </Text>
      <Text>
        Lyssna staff can delete your credit card from Stripe upon request.
      </Text>
    </DocumentSection>
    <DocumentSection title="Hosting">
      <Text>
        Lyssna is hosted on the{" "}
        <ExternalLink href="https://heroku.com/">Heroku</ExternalLink>{" "}
        Platform-as-a-Service. See{" "}
        <ExternalLink href="https://www.heroku.com/policy/security">
          Heroku{"\u2019"}s security policy
        </ExternalLink>{" "}
        for more information. Heroku is ISO 27001 and SOC 2 certified.
      </Text>
    </DocumentSection>
    <DocumentSection title="Logging and monitoring">
      <Text>
        We sanitize our logs of sensitive data, and archive them to a log
        management platform. Logs are retained for up to 12 months.
      </Text>
      <Text>
        We actively monitor logs using a security information and event
        management (SIEM) system to detect anomalies and threats in real time.
      </Text>
    </DocumentSection>
    <DocumentSection title="Image storage">
      <Text>
        Your images are hosted securely on Amazon S3 at secure URLs. See{" "}
        <ExternalLink href="https://aws.amazon.com/s3/faqs/#security">
          Amazon{"\u2019"}s security policy
        </ExternalLink>{" "}
        for more information.
      </Text>
      <Text>
        We store your test, test images, and test results until you delete them.
        When you delete a test from our system we remove it from our database,
        remove all of the responses, and delete the images from Amazon S3.
      </Text>
    </DocumentSection>
    <DocumentSection title="Database backups">
      <Text>
        Full database backups are taken every 24 hours, and are stored in an
        encrypted AWS S3 bucket. Daily backups are retained for 7 days, weekly
        backups are retained for 8 weeks, and monthly backups are retained for
        12 months. Deleted content may remain in backups for this duration.
      </Text>
      <Text>
        We also take continuous backups of our database by persisting Postgresql
        {"\u2019"}s write ahead log (WAL) files to S3. These allow point-in-time
        recovery down to the nearest minute to any time in the previous seven
        days.
      </Text>
      <Text>
        Our recovery time objective (RTO) is 4 hours, and our recovery point
        objective (RPO) is 5 minutes.
      </Text>
    </DocumentSection>
    <DocumentSection title="Availability">
      <Text>
        Our{" "}
        <ExternalLink href={Constants.STATUS_PAGE_URL}>
          status page
        </ExternalLink>{" "}
        documents our historical uptime, which is typically in excess of 99.99%.
        Customers can subscribe to our status page for updates around planned
        maintenance and any incidents that affect platform availability or
        stability.
      </Text>
      <Text>
        Our engineers maintain a 24/7/365 on call rotation to ensure Lyssna
        remains accessible for all timezones across the globe.
      </Text>
    </DocumentSection>
    <DocumentSection title="Lyssna employee security">
      <Text>
        All Lyssna team members undergo annual security awareness training.
      </Text>
      <Text>
        We perform background checks on all new team members in accordance with
        local laws.
      </Text>
      <Text>
        All team members are required to sign and adhere to an industry standard
        confidentiality agreement prior to their first day of work.
      </Text>
      <Text>
        The information in your account is kept confidential by Lyssna
        employees. Please see our{" "}
        <Link as={RoutedLink} to={ROUTES.LEGAL.PRIVACY.path}>
          privacy page
        </Link>
        , for more information.
      </Text>
    </DocumentSection>
    <DocumentSection title="Access security">
      <Text>
        Lyssna follows the principle of least privilege with respect to identity
        and access management.
      </Text>
      <Text>
        We perform quarterly access reviews of all team members with access to
        sensitive and production systems.
      </Text>
      <Text>
        All team members are required to use Single Sign-on (SSO) and
        Multifactor Authentication (MFA) where available. Where passwords are
        required, we generate and store them using an industry leading password
        manager.
      </Text>
    </DocumentSection>
    <DocumentSection title="Vendor and risk management">
      <Text>
        Our management team conducts risk assessments annually to identify and
        mitigate any potential threats, including considerations for fraud.
      </Text>
      <Text>
        Before signing new vendors we assess their security, privacy policy,
        service agreement and Data Processing Adendum (DPA).
      </Text>
    </DocumentSection>
    <DocumentSection title="Security contact">
      <Text>
        For questions, concerns or information, please contact our security team
        at <MailToAnchor email={Constants.SECURITY_EMAIL_ADDRESS} />.
      </Text>
    </DocumentSection>
  </Document>
)
