import classNames from "classnames"
import { nanoid } from "nanoid"
import React, { InputHTMLAttributes, KeyboardEvent, PureComponent } from "react"

import styles from "Components/form/checkbox.module.scss"
import { Omit } from "Types"
import { isEnter, isSpace } from "Utilities/keyboard-event"

interface Props
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "type" | "id" | "className"
  > {
  id?: string
  nowrap?: boolean
  indeterminate?: boolean
  focusRef?: (element: HTMLElement | null) => void
}

export class Checkbox extends PureComponent<Props> {
  private inputElement: HTMLInputElement | null = null
  private defaultId: string = nanoid()

  private handleInputRef = (element: HTMLInputElement | null) => {
    this.inputElement = element
  }

  private handleKeyDown = (event: KeyboardEvent<HTMLLabelElement>) => {
    if (
      // Ignore input on any components *within* the label
      event.currentTarget === event.target &&
      // Toggle on space or enter
      (isSpace(event) || isEnter(event))
    ) {
      event.preventDefault()
      this.inputElement!.click()
    }
  }

  render() {
    const {
      children,
      disabled,
      focusRef,
      id = this.defaultId,
      indeterminate,
      nowrap,
      tabIndex,
      ...props
    } = this.props
    return (
      <div className={styles.container}>
        <input
          ref={this.handleInputRef}
          id={id}
          className={classNames(
            styles.input,
            indeterminate && styles.indeterminate
          )}
          type="checkbox"
          disabled={disabled}
          {...props}
        />
        <label
          ref={focusRef}
          htmlFor={id}
          tabIndex={disabled ? undefined : tabIndex || 0}
          onKeyDown={this.handleKeyDown}
        >
          {children}
        </label>
      </div>
    )
  }
}
