import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { Button, ButtonProps } from "DesignSystem/components"
import React from "react"

export type RecruitmentSummaryCardManageButtonProps = {
  buttonLabel: React.ReactNode
  buttonIcon: ButtonProps["leftIcon"]
  menuItems: {
    text: React.ReactNode
    icon: React.ReactNode
    onClick: (() => void) | undefined
  }[]
}

export const RecruitmentSummaryCardManageButton: React.FC<
  RecruitmentSummaryCardManageButtonProps
> = ({ buttonIcon, buttonLabel, menuItems }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (menuItems.length === 0) {
    // TODO: It's sort of weird that this looks clickable, but it's not
    // (The only scenario where you might end up with a button but no menu items is
    // external panel orders which can't currently be cancelled)
    return <Button leftIcon={buttonIcon}>{buttonLabel}</Button>
  }

  return (
    <Menu autoSelect={false} onOpen={onOpen} onClose={onClose}>
      <MenuButton as={Button} leftIcon={buttonIcon} isSelected={isOpen}>
        {buttonLabel}
      </MenuButton>
      <Portal>
        <MenuList border="none" shadow="ds.overlay">
          {menuItems.map((state) => (
            <MenuItem
              key={`recruit-${state.text}`}
              onClick={state.onClick}
              isDisabled={!state.onClick}
              rounded="8px"
            >
              <Flex gap={2} alignItems="center">
                {state.icon}
                <Text>{state.text}</Text>
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  )
}
