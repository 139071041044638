import {
  Avatar,
  AvatarGroup,
  Box,
  HStack,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import React from "react"

import { AutoUpdatingRelativeTime } from "Components/comment/AutoUpdatingRelativeTime"
import { UsabilityTestUserActivity } from "Types"

import { getUserNameFromActivity } from "./ActiveUserNames"

type Props = {
  activities: UsabilityTestUserActivity[]
}

export const UserActivityAvatarList: React.FC<Props> = ({ activities }) => {
  return (
    <Tooltip
      hasArrow
      rounded="md"
      label={activities.map((activity) => {
        return (
          <Box key={activity.session_id}>
            <Text>{getUserNameFromActivity(activity)}</Text>
            <HStack color="whiteAlpha.600">
              <Text>Opened </Text>
              <AutoUpdatingRelativeTime
                casing="lowercase"
                date={activity.started_at}
                ms="1 !important"
              />
            </HStack>
          </Box>
        )
      })}
      p={2}
    >
      <AvatarGroup
        max={activities.length > 3 ? 2 : 3}
        spacing={-2}
        size="sm"
        sx={{
          "> .chakra-avatar__excess": {
            boxSize: 6,
            fontSize: "xs",
          },
        }}
      >
        {activities.map((activity) => (
          <Avatar
            boxSize={7}
            key={activity.session_id}
            name={activity.user.name}
            src={activity.user.avatar_url}
          />
        ))}
      </AvatarGroup>
    </Tooltip>
  )
}
