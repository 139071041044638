import { Box, Flex, HStack, Spinner } from "@chakra-ui/react"
import React from "react"
import { Helmet } from "react-helmet"
import { Outlet } from "react-router"
import { Availability } from "./Availability"
import { Details } from "./Details"
import { Emails } from "./Emails"
import { Navigation } from "./Navigation"
import { Screener } from "./Screener"
import { StudyDetailsProvider, useStudyDetails } from "./StudyDetailsProvider"
import { TeamMembers } from "./TeamMembers"

export const ModeratedStudyEditPage: React.FC = () => {
  return (
    <StudyDetailsProvider>
      <HStack as="main" flex={1} alignItems="stretch">
        <Helmet>
          <title>Setup</title>
        </Helmet>
        <Box flexShrink={0}>
          <Navigation />
        </Box>
        <Pages />
      </HStack>
    </StudyDetailsProvider>
  )
}

const Pages = () => {
  const { isLoading, separatePages } = useStudyDetails()

  if (isLoading) {
    return <Spinner size="xl" color="teal" />
  }

  return (
    <Flex
      flexDir="column"
      minH="0"
      w="full"
      py={8}
      pl={8}
      alignItems="center"
      width="calc(100vw - 21rem)"
      pr="max(2rem, calc(100vw - 78.5rem) / 2 + 2rem)"
    >
      <Flex flexDir="column" gap="10" w="full" maxW="64rem">
        {separatePages ? (
          <Outlet />
        ) : (
          <>
            <Details />
            <Screener />
            <TeamMembers />
            <Availability />
            <Emails />
          </>
        )}
      </Flex>
    </Flex>
  )
}
