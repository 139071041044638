import { get } from "lodash"
import {
  getFormSyncErrors as getReduxFormSyncErrors,
  getFormSyncWarnings as getReduxFormSyncWarnings,
  getFormValues as getReduxFormValues,
} from "redux-form"
import { createSelector } from "reselect"

import { State } from "Redux/app-store"
import { UsabilityTest } from "Types"

const form = "test-form"

export const getFormName = () => form
export const getFormValues = getReduxFormValues(form) as (
  state: State
) => Readonly<UsabilityTest>
export const getFormValue = (field: string) =>
  createSelector(getFormValues, (values) => get(values, field))
export const getFormSyncErrors = getReduxFormSyncErrors(form) as (
  state: State
) => Record<string, never> | { sections: Record<string, unknown>[] }
export const getFormSyncWarnings = getReduxFormSyncWarnings(form)
