// JsFromRoutes CacheKey 19b917258a56d14401abfb0d07ddec69
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: /* #__PURE__ */ definePathHelper('get', '/screenshots'),
  create: /* #__PURE__ */ definePathHelper('post', '/screenshots'),
  get: /* #__PURE__ */ definePathHelper('get', '/screenshots/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/screenshots/:id'),
}
