import { useCallback, useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import {
  createCommonPathFilter,
  createCommonPathFilterCategory,
} from "../filters/create-filters/common-path-filter"
import { getFilterUtilityFunctions } from "../filters/get-filter-utility-functions"
import { getFiltersWithCategory } from "../filters/get-filters/get-filters-with"

const { isFilter, toggleFilter } = getFilterUtilityFunctions(
  createCommonPathFilter
)

export const useCommonPathFilter = (sectionId: number, pathId: string) => {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handleCommonPathFilterToggle = useCallback(() => {
    setTestResultsFilters((filters) => toggleFilter(filters, sectionId, pathId))
  }, [])

  const sectionHasActiveFilter = getFiltersWithCategory(
    testResultsFilters,
    createCommonPathFilterCategory(sectionId)
  )

  return {
    sectionHasActiveFilter: sectionHasActiveFilter.length > 0,
    isPathFilteredIn: isFilter(testResultsFilters, sectionId, pathId),
    isPathFilteredInRequested: isFilter(testResultsFilters, sectionId, pathId),
    toggleIsFiltered: handleCommonPathFilterToggle,
  }
}
