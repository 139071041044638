import { Box, BoxProps } from "@chakra-ui/react"
import React from "react"
import { useOnboardingContext } from "./OnboardingContextProvider"

// Uses a fieldset to disable all children while the data is loading
// (I wish the <form> element had a `disabled` attribute)
// `display="contents"` stops the fieldset from adding its own element
export const LoadingForm: React.FC<BoxProps> = ({ children, ...props }) => {
  const { isLoading } = useOnboardingContext()

  return (
    <Box
      as="fieldset"
      display="contents"
      disabled={isLoading || undefined}
      opacity={isLoading ? 0.5 : 1}
      transition="opacity 0.5s ease"
      {...props}
    >
      {children}
    </Box>
  )
}
