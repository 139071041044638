import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ParticipantIcon = createIcon({
  displayName: "ParticipantIcon",
  viewBox: "0 0 12 12",
  path: (
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="All-components"
        transform="translate(-879.000000, -2841.000000)"
        fill="#90A2B4"
        fillRule="nonzero"
      >
        <g id="Group-11" transform="translate(148.000000, 2722.000000)">
          <g id="Group-2-Copy-37" transform="translate(731.000000, 119.000000)">
            <path
              d="M6,0 C2.688,0 0,2.688 0,6 C0,9.312 2.688,12 6,12 C9.312,12 12,9.312 12,6 C12,2.688 9.312,0 6,0 Z M6,1.8 C6.996,1.8 7.8,2.604 7.8,3.6 C7.8,4.596 6.996,5.4 6,5.4 C5.004,5.4 4.2,4.596 4.2,3.6 C4.2,2.604 5.004,1.8 6,1.8 Z M6,10.32 C4.5,10.32 3.174,9.552 2.4,8.388 C2.418,7.194 4.8,6.54 6,6.54 C7.194,6.54 9.582,7.194 9.6,8.388 C8.826,9.552 7.5,10.32 6,10.32 Z"
              id="Shape-Copy-10"
            ></path>
          </g>
        </g>
      </g>
    </g>
  ),
})
