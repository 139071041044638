import { OrderedList } from "@chakra-ui/react"
import { useVirtualizer } from "@tanstack/react-virtual"
import React, { FC, PropsWithChildren, useRef } from "react"

import { ExpandableScrollContainer } from "Components/expandable-region/expandable-scroll-container"
import { Answer } from "Components/test-results/question-results/answer/answer"
import { ReviewAnswer } from "Components/test-results/question-results/answer/review-answer"
import { UsabilityTestSectionQuestion as Question, ResponseAnswer } from "Types"

interface AnswerListProps extends PropsWithChildren {
  answers: ReadonlyArray<Readonly<ResponseAnswer>>
  question: Readonly<Readonly<Question>>
  showAdminActions: boolean
  showAnswerTagButton: boolean
  isSelectable: boolean
  isSelectedById: { readonly [id: number]: boolean }
  onSelect: (answer: Readonly<ResponseAnswer>) => void
  onDeselect: (answer: Readonly<ResponseAnswer>) => void
  listClassName?: string
}

export const AnswerList: FC<AnswerListProps> = ({
  answers,
  isSelectable,
  isSelectedById,
  listClassName,
  onDeselect,
  onSelect,
  showAdminActions,
  showAnswerTagButton,
  question,
}) => {
  const containerRef = useRef(null)

  // rendering multiple questions, each with possibly large answer lists can degrade the UI
  // performance and to avoid this scenario, we use a virtualizer from "react-virtual" package
  // which avoids rendering the whole answer list directly to the DOM and instead renders a
  // subset of the "visible" answers at any given time. The virtualizer will determine which
  // answers are visible and determine pixel offsets to place the answers <li> element as we
  // iterate through the visible answer list
  const answerVirtualizer = useVirtualizer({
    count: answers.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 61,
    overscan: 10,
  })

  const AnswerComponent = showAdminActions ? ReviewAnswer : Answer

  return (
    <ExpandableScrollContainer
      collapsedHeight={400}
      expandHeight="100vh"
      expandText="Expand"
      collapseText="Collapse"
      ref={containerRef}
    >
      <OrderedList
        styleType="none"
        margin="0"
        position="relative"
        height={`${answerVirtualizer.getTotalSize()}px`}
        className={listClassName}
      >
        {answerVirtualizer.getVirtualItems().map((virtualAnswerItem) => (
          <li
            key={virtualAnswerItem.key}
            ref={answerVirtualizer.measureElement}
            data-index={virtualAnswerItem.index}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${virtualAnswerItem.start}px)`,
              padding: "6px 0",
            }}
          >
            <AnswerComponent
              answer={answers[virtualAnswerItem.index]}
              isSelectable={isSelectable}
              isSelected={
                isSelectedById[answers[virtualAnswerItem.index].id] || false
              }
              onSelect={onSelect}
              onDeselect={onDeselect}
              question={question}
              showAnswerTagButton={showAnswerTagButton}
            />
          </li>
        ))}
      </OrderedList>
    </ExpandableScrollContainer>
  )
}
