import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { ChevronUpOutlineIcon } from "Shared/icons/untitled-ui/ChevronUpOutlineIcon"
import React from "react"

interface Props {
  isExpanded: boolean
}

export const ExpandChevron: React.FC<React.PropsWithChildren<Props>> = ({
  isExpanded,
}) =>
  isExpanded ? (
    <ChevronUpOutlineIcon boxSize={5} />
  ) : (
    <ChevronDownOutlineIcon boxSize={5} />
  )
