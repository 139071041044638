import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import React, { useContext, useState } from "react"

import { NumberInput } from "Components/form/number-input/number-input"
import { MAX_AGE, MIN_AGE } from "Constants/ages"
import { useCountries } from "Hooks/use-countries"
import { axios } from "Services/axios"
import { SelectWithOptionHelper } from "Shared/components/SelectWithOptionHelper/SelectWithOptionHelper"
import { useTranslate } from "Shared/hooks/useTranslate"
import { Country, ResponseDemographicProfile } from "Types"
import { TesterDemographicsContext } from "UsabilityHub/components/UsabilityTest/context/demographics"
import { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"
import ScreenerApi from "~/api/screenerApi"

interface DemographicSelectOption {
  value: number
  label: string
  helper: string
}

export interface ImplProps {
  addResponseDemographics: (demographics: ResponseDemographicProfile) => void
  recruitmentLink: RecruitmentLink
  thirdPartyOrderId: number | null
  permitUnload: () => void
}

export const DemographicQuestions: React.FC<
  React.PropsWithChildren<ImplProps>
> = ({
  addResponseDemographics,
  thirdPartyOrderId,
  permitUnload,
  recruitmentLink,
}) => {
  const toast = useToast()
  const translate = useTranslate()
  const { demographics, isLoading } = useContext(TesterDemographicsContext)
  const demographicAttributes = demographics.flatMap(
    (group) => group.demographic_attributes
  )
  const { countries, isLoading: countriesLoading } = useCountries()

  // Form state
  const [isSubmitting, setIsSubmitting] = useState(false)
  // Data
  const [age, setAge] = useState<number | null>(null)
  const [country, setCountry] = useState<Country["code"] | null>(null)
  const [selectedDemographics, setSelectedDemographics] = useState<
    Record<number, number[]>
  >({})

  // -- Handlers --

  const handleCountryChanged = (selectedCountry: Readonly<Country>) => {
    setCountry(selectedCountry ? selectedCountry.code : "")
  }

  const handleAgeChanged = (age: number | null) => {
    setAge(age)
  }

  const handleDemographicChanged = (
    demographicAttribute: number,
    selectedOption: { value: number }[]
  ) => {
    setSelectedDemographics((oldDemographics) => ({
      ...oldDemographics,
      [demographicAttribute]: selectedOption.map((o) => o.value),
    }))
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const userDemographics = serializeDemographics()

    if (thirdPartyOrderId !== null) {
      try {
        const { data } = await axios.post(ScreenerApi.screen.path(), {
          id: thirdPartyOrderId,
          demographics: userDemographics,
        })

        if (data.redirect_url != null) {
          // Screened out
          permitUnload()
          window.location.href = data.redirect_url
        } else {
          addResponseDemographics(userDemographics)
        }
      } catch (error) {
        toast({
          title:
            "Sorry, something went wrong. Please try submitting your demographics again.",
          status: "error",
        })
        setIsSubmitting(false)
      }
    } else {
      addResponseDemographics(userDemographics)
    }
  }

  // Answers are mandatory if this response is for a third party order
  const isAnsweringMandatory = thirdPartyOrderId !== null

  const isFormValid = () => {
    if (!isAnsweringMandatory) {
      return true
    }

    const activeRequiredDemographicAttributes =
      recruitmentLink.capture_demographic_attribute_ids
        .map((id) =>
          demographicAttributes.find((attribute) => attribute.id === id)
        )
        .filter((attribute) => attribute?.required)

    const unfilledDemographics = activeRequiredDemographicAttributes.filter(
      (attribute) => {
        if (!attribute) return false

        const attributeValue = selectedDemographics[attribute.id]
        return !attributeValue || !attributeValue.length
      }
    ).length

    if (unfilledDemographics > 0) return false

    return true
  }

  const serializeDemographics = (): ResponseDemographicProfile => {
    return {
      age: age,
      country: country,
      demographic_attribute_option_ids:
        Object.values(selectedDemographics).flat(),
    }
  }

  return (
    <Box maxW="xl" my={10}>
      <Stack spacing={10}>
        <Stack>
          <Heading fontWeight="medium">
            {translate("test.demographic_survey.please_tell_us_about_yourself")}
          </Heading>
          {!isAnsweringMandatory && (
            <Heading size="sm" fontWeight="normal">
              {translate("test.demographic_survey.all_fields_are_optional")}
            </Heading>
          )}
        </Stack>

        {recruitmentLink.capture_country ? (
          <Stack spacing={2}>
            <Text fontWeight="medium">{translate("demographics.country")}</Text>
            <SelectWithOptionHelper
              options={countries}
              getOptionLabel={(c: Country) => c.name}
              getOptionValue={(c: Country) => c.code}
              value={countries.find((c) => c.code === country)}
              onChange={handleCountryChanged}
              data-loading={countriesLoading || undefined}
            />
          </Stack>
        ) : null}

        {recruitmentLink.capture_age ? (
          <Stack spacing={2}>
            <Text fontWeight="medium">{translate("demographics.age")}</Text>
            <Box>
              <NumberInput
                onChange={handleAgeChanged}
                min={MIN_AGE}
                max={MAX_AGE}
                value={age}
              />
            </Box>
          </Stack>
        ) : null}

        {!isLoading &&
          recruitmentLink.capture_demographic_attribute_ids.map(
            (demographicAttributeId) => {
              const demographicAttribute = demographicAttributes.find(
                (attr) => attr.id === demographicAttributeId
              )

              if (!demographicAttribute) {
                return (
                  <Alert key={demographicAttributeId} status="error">
                    <AlertIcon />
                    Missing demographic: {demographicAttributeId}
                  </Alert>
                )
              }

              const attributeName = translate(
                `demographics.${demographicAttribute.code}`
              )

              const options = demographicAttribute.options.map((o) => ({
                value: o.id,
                label: o.code
                  ? translate(
                      `demographics.${demographicAttribute.code}_options.${o.code}`
                    )
                  : o.value,
                // These profile_helper values are not currently translated
                // helper: o.profile_helper,
              }))

              // Later we'll use demographicAttribute.profile_helper but it is not translated yet
              let helperText: string | undefined
              try {
                helperText = translate(
                  `demographics.field_descriptions.${demographicAttribute.code}`
                )
              } catch (e) {
                if (e instanceof TypeError) {
                  // No translation for this field, all good
                } else {
                  throw e
                }
              }

              const currentValue =
                selectedDemographics[demographicAttributeId] ?? []

              return (
                <Stack spacing={2} key={demographicAttributeId}>
                  <Text fontWeight="medium">{attributeName}</Text>
                  {helperText && <Text>{helperText}</Text>}
                  {demographicAttribute.multi_select ? (
                    <SelectWithOptionHelper
                      isMulti
                      closeMenuOnSelect={false}
                      selectedOptionStyle="check"
                      hideSelectedOptions={false}
                      options={options}
                      value={options.filter((o) =>
                        currentValue.includes(o.value)
                      )}
                      onChange={(o: DemographicSelectOption[]) =>
                        handleDemographicChanged(demographicAttributeId, o)
                      }
                    />
                  ) : (
                    <SelectWithOptionHelper
                      options={options}
                      value={options.filter((o) =>
                        currentValue.includes(o.value)
                      )}
                      onChange={(o: DemographicSelectOption) =>
                        handleDemographicChanged(demographicAttributeId, [o])
                      }
                    />
                  )}
                </Stack>
              )
            }
          )}

        <Flex>
          <Button
            colorScheme="brand.primary"
            onClick={handleSubmit}
            isDisabled={!isFormValid()}
            isLoading={isSubmitting}
          >
            {translate("test.buttons.continue")}
          </Button>
        </Flex>
      </Stack>
    </Box>
  )
}
