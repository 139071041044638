import { Box, BoxProps } from "@chakra-ui/react"
import { range } from "lodash"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useAudioVolume } from "../UsabilityTest/content-factory/recording-setup-guide-content/useAudioVolume"
import { useTestRecordingContext } from "../UsabilityTest/context/TestRecordingContext"

type IndicatorSize = "small" | "default" | "emphasized"

type IndicatorProps = BoxProps & {
  size?: IndicatorSize
}

const boxSizes = {
  small: 6,
  default: 8,
  emphasized: 10,
} as const

export const RecordingVolumeIndicator: React.FC<IndicatorProps> = ({
  size = "default",
  ...props
}) => {
  const [state, setState] = useState<"resting" | "active">("resting")

  const { audioDeviceId } = useTestRecordingContext()

  const volumeDecimal = useAudioVolume({
    audioDeviceId,
    onAudioDeviceMissing: () => setState("resting"),
    onSuccess: () => setState("active"),
    onFailure: () => setState("resting"),
    volumeUpdateInterval: 20,
  })

  const bars = useRef<(SVGPathElement | null)[]>([])

  const pulse = useCallback((value: number) => {
    const smallBarSize = 10
    const largeBarSize = 16

    for (const i of range(3)) {
      const y = (value * (i % 2 ? largeBarSize : smallBarSize)) / 2
      bars.current[i]?.setAttribute("d", `M0-${y}v${y * 2}`)
    }
  }, [])

  useEffect(() => {
    pulse(volumeDecimal)
  }, [volumeDecimal])

  return (
    <Box as="svg" boxSize={boxSizes[size]} viewBox="-20 -20 40 40" {...props}>
      <Box
        as="circle"
        cx={0}
        cy={0}
        r={20}
        fill={
          state === "active"
            ? "ds.background.accent.purple.bold"
            : "ds.background.disabled"
        }
      />
      {[0, 1, 2].map((i) => (
        <g key={i} transform={`translate(${i * 7.75 - 7.75}, 0)`}>
          <path
            d="M0-0v0"
            ref={(el) => {
              bars.current[i] = el
            }}
            stroke="white"
            strokeWidth={4.5}
            strokeLinecap="round"
          />
        </g>
      ))}
    </Box>
  )
}
