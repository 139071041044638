import {
  Alert,
  Button,
  ButtonGroup,
  ButtonProps,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { Dispatch } from "Redux/app-store"
import {
  updateTreeId,
  updateTreeNodes,
} from "Redux/reducers/test-builder-form/action-creators/tree_testing"
import { FileChooser } from "Shared/components/FileChooser/FileChooser"
import { useChannel } from "Shared/hooks/useChannel"
import { Upload01OutlineIcon } from "Shared/icons/untitled-ui/Upload01OutlineIcon"
import { useSectionIndexContext } from "UsabilityHub/contexts/SectionIndexContext"
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react"
import { useDispatch } from "react-redux"
import {
  ImportTreeTestTreeVariables,
  useGetTreeTestTree,
  useImportTreeTestTree,
} from "~/api/generated/usabilityhub-components"
import { useTreeContext } from "../TreeProvider"
import ImportComplete from "./import-complete.png"

type ImportState = "ready" | "starting" | "importing" | "complete"

type ProgressMessage =
  | {
      action: "progress"
      data: {
        progress: number
        total: number
      }
    }
  | {
      action: "complete"
      data: {
        tree_id: number
        progress: number
        total: number
      }
    }
  | {
      action: "malformed"
      data: {
        message: string
        line: number
      }
    }
  | {
      action: "error"
      data: {
        message: string
      }
    }

export const ImportTree: React.FC<Partial<ButtonProps>> = (props) => {
  const { section, tree } = useTreeContext()

  const [file, setFile] = useState<File | null>(null)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [state, setState] = useState<ImportState>("ready")

  const [error, setError] = useState<ReactNode | null>(null)

  const [lineCount, setLineCount] = useState(0)

  const [treeId, setTreeId] = useState<string>(
    section.tree_test_attributes?.tree_id || ""
  )

  useEffect(() => {
    if (section.tree_test_attributes?.tree_id) {
      setTreeId(section.tree_test_attributes?.tree_id || "")
    }
  }, [section])

  const { mutateAsync: startImport } = useImportTreeTestTree()

  useEffect(() => {
    if (isOpen) {
      setFile(null)
      setState("ready")
      setError(null)
    }
  }, [isOpen])

  const resetAfterError = useCallback(() => {
    setFile(null)
    setState("ready")
  }, [])

  const importTree = useCallback(() => {
    if (!file) return

    setState("starting")
    setError(null)

    startImport(
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: {
          tree_id: treeId,
          file,
        } as ImportTreeTestTreeVariables["body"],
      },
      {
        onSuccess: ({ tree_id, line_count }) => {
          setTreeId(String(tree_id))
          setLineCount(line_count || 1)
          setState("importing")
        },
        onError: (e) => {
          setError(e.payload.message)
          resetAfterError()
        },
      }
    ).catch((e) => {
      setError(e.payload.message)
      resetAfterError()
    })
  }, [startImport, file, treeId, resetAfterError])

  const onUploadProgress = (message: ProgressMessage) => {
    switch (message.action) {
      case "complete":
        setState("complete")
        break
      case "malformed":
        setError(
          <div>
            <Text>Please fix the error(s) below and try again.</Text>
            <Text>
              <b>{`Line ${message.data.line}:`}</b> {message.data.message}
            </Text>
          </div>
        )
        resetAfterError()
        break
      case "error":
        setError(<Text>{message.data.message}</Text>)
        resetAfterError()
        break
    }
  }

  const closeable = state === "ready" || state === "complete"

  return (
    <>
      <Button leftIcon={<Upload01OutlineIcon />} onClick={onOpen} {...props}>
        Import tree
      </Button>
      <Modal
        size="xl"
        isOpen={isOpen}
        closeOnEsc={closeable}
        closeOnOverlayClick={closeable}
        onClose={onClose}
      >
        <ModalOverlay />
        {state === "complete" ? (
          <ModalContent>
            <ModalBody py={6}>
              <Stack alignItems="center" textAlign="center">
                <Heading
                  as="h2"
                  size="md"
                  m={0}
                  fontFamily="promo"
                  fontWeight="medium"
                >
                  Import complete
                </Heading>
                <Image src={ImportComplete} alt="Import complete" />
                <Text>Your tree has been successfully updated.</Text>
                <Button
                  type="button"
                  variant="solid"
                  colorScheme="brand.primary"
                  onClick={onClose}
                >
                  Return to test
                </Button>
              </Stack>
            </ModalBody>
          </ModalContent>
        ) : state === "importing" ? (
          <ModalContent>
            <ModalBody py={6}>
              <Stack alignItems="center" textAlign="center">
                <ProgressMonitor
                  treeId={treeId}
                  lineCount={lineCount}
                  onMessage={onUploadProgress}
                />
                <Text color="text.subtlest">
                  Do not close the window or navigate away from this page.
                </Text>
              </Stack>
            </ModalBody>
          </ModalContent>
        ) : (
          <ModalContent>
            <ModalHeader>Import tree data from a CSV file</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {error ? (
                <Alert status="error" mb={4}>
                  {error}
                </Alert>
              ) : (
                tree.length > 0 && (
                  <Alert status="info" mb={4}>
                    This will remove your current tree and all nominated correct
                    answers.
                  </Alert>
                )
              )}
              <Text mb={4}>
                Use a new column for each level in the tree when formatting your
                spreadsheet.{" "}
                <Link
                  download
                  href="/dummy_data/sample_tree.csv"
                  textDecoration="none"
                >
                  Download our example spreadsheet
                </Link>
              </Text>
              <FileChooser
                file={file}
                onChange={setFile}
                accept=".csv,.txt,text/csv,text/plain"
              >
                <Text color="text.subtlest" fontSize="small">
                  Ensure your file has fewer than 1000 rows
                </Text>
              </FileChooser>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup isDisabled={state !== "ready"}>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  isDisabled={state !== "ready" || !file}
                  onClick={importTree}
                >
                  Import data
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </>
  )
}

type ProgressState = {
  progress: number
  total: number
}

const ProgressMonitor: React.FC<{
  treeId: string
  lineCount: number
  onMessage: (message: ProgressMessage) => void
}> = ({ treeId, lineCount, onMessage }) => {
  const { section, load, clearCorrectNodes } = useTreeContext()
  const sectionIndex = useSectionIndexContext()
  const dispatch = useDispatch<Dispatch>()

  const { refetch: getNewTree } = useGetTreeTestTree(
    {
      pathParams: {
        id: treeId,
      },
    },
    {
      enabled: false,
    }
  )

  const [state, receive] = useReducer(
    (state: ProgressState, message: ProgressMessage) => {
      if ("progress" in message.data) {
        return {
          ...state,
          progress: message.data.progress,
          total: message.data.total,
        }
      } else {
        return state
      }
    },
    { progress: 0, total: 1 }
  )

  const receiveData = useCallback(
    (message: ProgressMessage) => {
      receive(message)
      onMessage(message)
      if (message.action === "complete") {
        getNewTree().then(({ data }) => {
          if (!data) return
          load(data.nodes)
          clearCorrectNodes()
          dispatch(updateTreeId(sectionIndex, data.id))
          dispatch(updateTreeNodes(sectionIndex, data.nodes))
        })
      }
    },
    [onMessage, receive, dispatch, getNewTree, section, treeId]
  )

  useChannel(
    "TreeImportChannel",
    { tree_id: treeId },
    { onMessage: receiveData }
  )

  return (
    <Stack
      alignSelf="stretch"
      justifyContent="center"
      alignItems="center"
      minH="18rem"
      spacing={4}
    >
      <Heading as="h2" size="md" m={0} fontFamily="promo" fontWeight="medium">
        Importing data
      </Heading>
      <Progress
        value={state.progress}
        max={state.total}
        rounded="sm"
        w="100%"
        maxW="28rem"
        colorScheme="brand.primary"
      />
      <Text>
        {state.progress} of {lineCount} lines
      </Text>
    </Stack>
  )
}
