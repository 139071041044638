import { useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import {
  createLocationFilter,
  createUnknownLocationFilter,
} from "../filters/create-filters/location-filter"
import { ResponseFilter } from "../filters/filters/types"
import { getFiltersWithoutCategory } from "../filters/get-filters/get-filters-without"
import { FILTER_UNKNOWN } from "../utils/unknown"

export const useUpdateLocationsFilter = () => {
  const { setTestResultsFilters } = useContext(TestResultsFilterState)

  return (countries: string[]) => {
    setTestResultsFilters((filters) => {
      const filtersWithoutLocations = getFiltersWithoutCategory(
        filters,
        "response/attribute:location"
      )

      const countryFilters: ResponseFilter[] = countries.map((country) => {
        if (country === FILTER_UNKNOWN) {
          return createUnknownLocationFilter()
        }

        return createLocationFilter(country)
      })

      return [...filtersWithoutLocations, ...countryFilters]
    })
  }
}
