import classNames from "classnames"
import React, {
  UIEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { HTMLAttributes } from "react"

import styles from "./expandable-region.module.scss"

const SCROLL_POSITION_TOLERANCE = 1

interface ScrollShadowsContainerProps extends HTMLAttributes<HTMLDivElement> {
  disableShadow?: boolean
}

export const ScrollShadowsContainer = forwardRef<
  HTMLDivElement,
  ScrollShadowsContainerProps
>(({ disableShadow = false, children, ...props }, forwardedRef) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isScrollAtTop, setIsScrollAtTop] = useState(false)
  const [isScrollAtBottom, setShowBottomShadow] = useState(false)

  const determineScrollingPositions = useCallback(
    ({ scrollTop, scrollHeight, offsetHeight }: HTMLElement) => {
      setIsScrollAtTop(scrollTop <= SCROLL_POSITION_TOLERANCE)

      setShowBottomShadow(
        Math.abs(scrollHeight - offsetHeight - scrollTop) <=
          SCROLL_POSITION_TOLERANCE
      )
    },
    [setIsScrollAtTop, setShowBottomShadow]
  )

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    determineScrollingPositions(e.currentTarget)
  }

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        determineScrollingPositions(entries[0].target as HTMLDivElement)
      }),
    []
  )

  useEffect(() => {
    const element = containerRef.current!
    resizeObserver.observe(element)
    return () => resizeObserver.unobserve(element)
  }, [])

  useLayoutEffect(() => {
    containerRef.current && determineScrollingPositions(containerRef.current)
  }, [disableShadow])

  useImperativeHandle(forwardedRef, () => containerRef.current!)

  const hideTopShadow = disableShadow || isScrollAtTop
  const hideBottomShadow = disableShadow || isScrollAtBottom

  return (
    <div
      className={classNames(styles.container, {
        [styles.hideTopShadow]: hideTopShadow,
        [styles.hideBottomShadow]: hideBottomShadow,
      })}
    >
      <div
        {...props}
        ref={containerRef}
        className={classNames(styles.scrollable, props.className)}
        onScroll={handleScroll}
      >
        {children}
      </div>
    </div>
  )
})

ScrollShadowsContainer.displayName = "ScrollShadowsContainer"
