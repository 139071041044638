import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ShortTextIcon = createIcon({
  displayName: "ShortTextIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="short-text">
      <path
        id="A"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 13.8839C1 13.7077 1.06105 13.5315 1.16279 13.3357L6.45349 1.97902C6.73837 1.37203 7.24709 1 7.9593 1H8.0814C8.7936 1 9.28198 1.37203 9.56686 1.97902L14.8576 13.3357C14.9593 13.5119 15 13.6881 15 13.8448C15 14.4909 14.4913 15 13.8198 15C13.2297 15 12.8227 14.6671 12.5988 14.158L11.439 11.593H4.52035L3.31977 14.2364C3.11628 14.7259 2.68895 15 2.15988 15C1.50872 15 1 14.5105 1 13.8839ZM5.47675 9.45874H10.4826L7.97965 3.91748L5.47675 9.45874Z"
        fill="currentColor"
      />
    </g>
  ),
})
