import {
  ScreenResultDetailsExpandablePanel,
  ScreenResultDetailsZoomablePanel,
} from "./Panel/Panel"
import { ScreenResultDetailsContextProvider } from "./ScreenResultsProvider"
import { ScreenResultDetailsStatusBarContainer } from "./StatusBar/StatusBar"
import { ScreenResultTabsContainer } from "./Tabs/ScreenResultTabsContainer"

export { ScreenResults } from "./ScreenResults"

export const ScreenResultDetails = {
  Provider: ScreenResultDetailsContextProvider,
  Tabs: ScreenResultTabsContainer,
  StatusBar: ScreenResultDetailsStatusBarContainer,
  ExpandablePanel: ScreenResultDetailsExpandablePanel,
  ZoomablePanel: ScreenResultDetailsZoomablePanel,
}
