import { Filter } from "Components/test-results/types/filters"

import { getFilterMeta } from "../get-filter-meta"

import { last } from "lodash"
import { FilterFunction } from "./types"

export type TreeTestFilter = Filter<
  `answer/section:${number}/tree-test`,
  "answer/tree-test",
  { finalNodeId: string | null }
>

export const filterResponseByTreeTest: FilterFunction<TreeTestFilter> = ({
  filter,
  filteredRelatedData: { responseSections },
  allRelatedData: { treeTestPaths },
}) => {
  const { sectionId } = getFilterMeta(filter.category)

  const section = responseSections.find(
    (rs) => rs.usability_test_section_id === sectionId
  )

  if (section === undefined) {
    // The user didn't complete this tree test.
    return false
  }

  return treeTestPaths.some(
    ({ response_section_id, path }) =>
      response_section_id === section.id &&
      last(path) === filter.value.finalNodeId
  )
}
