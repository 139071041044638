export function textColorForBackground(color: string): string {
  const colors = /^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i.exec(color)
  if (!colors) {
    return "black"
  }

  const r = parseInt(colors[1], 16)
  const g = parseInt(colors[2], 16)
  const b = parseInt(colors[3], 16)

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000)
  return brightness > 125 ? "black" : "white"
}

export const DEFAULT_TAG_COLORS = [
  "#e5e7eb",
  "#cb3a32",
  "#edeafd",
  "#7246e5",
  "#a7eaf6",
  "#50b4d0",
  "#d8f5c8",
  "#a5dd91",
  "#fce6c5",
  "#9da3ae",
  "#fae3e3",
  "#c1b7f8",
  "#deeafd",
  "#9cc5f9",
  "#d8f5c8",
  "#60c44c",
  "#f2ca38",
  "#ee8d43",
  "#fae3e3",
  "#cb3a32",
  "#c1b7f8",
  "#3366e3",
  "#d8f5c8",
  "#60c44c",
  "#a5dd91",
  "#f3bc7f",
] as const

export const COLOR_SWATCHES = [
  "#deeafd",
  "#d7f9fd",
  "#d8f5c8",
  "#fcefbb",
  "#fce6c5",
  "#fae3e3",
  "#edeafd",
  "#e5e7eb",
  "#9cc5f9",
  "#a7eaf6",
  "#a5dd91",
  "#f9dc7e",
  "#f3bc7f",
  "#e87976",
  "#c1b7f8",
  "#9da3ae",
  "#3366e3",
  "#50b4d0",
  "#60c44c",
  "#f2ca38",
  "#ee8d43",
  "#cb3a32",
  "#7246e5",
  "#394150",
]
