import React from "react"

import { QuestionResultsList } from "Components/test-results/question-results/question-results-list"
import { ScreenshotClickResults } from "Components/test-results/screenshot-click-results/screenshot-click-results-container"
import { SectionResultsSectionText } from "UsabilityHub/components/TestResults/SectionResultsLabels"
import {
  useDeviceFramesForSectionContext,
  useSectionContext,
} from "UsabilityHub/contexts"

import { SectionResultsCard } from "./SectionResultsCard"

export const FirstClickSectionResultsCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { section } = useSectionContext()
  // There's always only one screenshot for the FirstClick section type.
  const sectionScreenshot = section.section_screenshots[0]
  const deviceFrame = useDeviceFramesForSectionContext(section)

  return (
    <SectionResultsCard>
      <SectionResultsSectionText />
      <ScreenshotClickResults
        sectionScreenshot={sectionScreenshot}
        deviceFrame={deviceFrame}
      />
      <QuestionResultsList />
    </SectionResultsCard>
  )
}
