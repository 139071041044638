import React from "react"

import { percentage } from "Utilities/css-helpers"

import styles from "./range.module.scss"

interface Props {
  low: number
  high: number
}

export const RangeFill: React.FC<React.PropsWithChildren<Props>> = ({
  low,
  high,
}) => {
  return (
    <div
      className={styles.fill}
      style={{
        left: percentage(low),
        width: percentage(high - low),
      }}
    />
  )
}
