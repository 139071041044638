import { Box, Button, Flex, Icon } from "@chakra-ui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import React, { useRef, useState } from "react"
import { ReactNode } from "react"

import { useScrollPercentage } from "Hooks/use-scroll-percentage"

interface Props {
  numberToShow: number
  items: ReactNode[]
  pluralEntityName: string
  maxH: string
}

export const ExpandablePanel: React.FC<Props> = ({
  items,
  pluralEntityName,
  numberToShow,
  maxH,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const scrollPercentage = useScrollPercentage(containerRef)

  const tooManyItemsToFit = items.length > numberToShow
  const showTopShadow =
    scrollPercentage !== null &&
    scrollPercentage > 5 &&
    !isOpen &&
    tooManyItemsToFit
  const showBottomShadow =
    scrollPercentage !== null &&
    scrollPercentage < 95 &&
    !isOpen &&
    tooManyItemsToFit

  return (
    <>
      <Box position="relative">
        {showTopShadow && (
          <Box
            bg="linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height={5}
            zIndex={1}
            pointerEvents="none"
          />
        )}

        <Box
          ref={containerRef}
          maxH={isOpen ? "auto" : maxH}
          overflowY="auto"
          sx={{
            scrollbarWidth: 0,
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {items}
        </Box>

        {showBottomShadow && (
          <Box
            bg="linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)"
            position="absolute"
            bottom={0}
            left={0}
            width="100%"
            height={5}
            zIndex={1}
            pointerEvents="none"
          />
        )}
      </Box>

      {tooManyItemsToFit && (
        <Flex justify="center">
          <Button
            w="fit-content"
            variant="ghost"
            colorScheme="brand.primary"
            size="md"
            leftIcon={
              <Icon
                as={ChevronDownIcon}
                transform={isOpen ? "rotate(0.5turn)" : "rotate(0)"}
                transition="0.2s transform"
              />
            }
            _hover={{
              color: "brand.primary.700",
              bg: "transparent",
            }}
            _active={{
              color: "brand.primary.700",
              bg: "transparent",
            }}
            onClick={() => setIsOpen((v) => !v)}
          >
            {isOpen
              ? `Hide extra ${pluralEntityName}`
              : `Show all ${pluralEntityName}`}
          </Button>
        </Flex>
      )}
    </>
  )
}
