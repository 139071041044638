import { Icon } from "@chakra-ui/react"
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid"
import React from "react"

interface Props {
  disqualified: boolean
  notRelevant: boolean
}

export const AnswerResultIcon: React.FC<Props> = ({
  disqualified,
  notRelevant,
}) => {
  return disqualified ? (
    <Icon as={XCircleIcon} color="red.600" />
  ) : notRelevant ? null : (
    <Icon as={CheckCircleIcon} color="green.600" />
  )
}
