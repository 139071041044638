import { IndividualFigmaTaskPath, ResponseSectionFigmaTask } from "Types"

import { getClicks } from "../../response-section-figma-task/get-clicks"
import { hasGoalScreenBeenReached } from "../../response-section-figma-task/has-goal-screen-been-reached"

export function getIndividualFigmaTaskPathFromTask(
  task: ResponseSectionFigmaTask,
  goalScreenId: string | null
): IndividualFigmaTaskPath {
  const { task_duration_ms, misclick_rate } = task

  return {
    pathId: task.path,
    type: "individual",
    task,
    meta: {
      goalScreenHasBeenReached: hasGoalScreenBeenReached(task, goalScreenId),
      clicks: getClicks(task),
      misclickRate: misclick_rate ?? 0,
      duration: task_duration_ms,
      markedAsCompleted: task.task_result === "completed",
    },
  }
}

export function getIndividualFigmaTaskPathsFromTasks(
  tasks: ResponseSectionFigmaTask[],
  goalScreenId: string | null
) {
  return tasks.map((task) =>
    getIndividualFigmaTaskPathFromTask(task, goalScreenId)
  )
}
