import {
  Flex,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"
import { Field, WrappedFieldProps } from "redux-form"

import { getFormValue } from "Redux/reducers/test-builder-form/selectors/formValues"
import { CardSortType } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"

import { OptionToggle, OptionToggleButton } from "../OptionToggle"
import { WrappedCheckbox } from "../WrappedCheckbox"

const CardSortToggle: React.FC<WrappedFieldProps> = ({ input }) => {
  return (
    <OptionToggle>
      <OptionToggleButton
        {...input}
        targetValue="closed"
        title="Closed card sort"
        description="Let participants sort cards into predefined categories"
      />

      <OptionToggleButton
        {...input}
        targetValue="open"
        title="Open card sort"
        description="Let participants sort cards into categories they create"
      />
    </OptionToggle>
  )
}
export const CardSortOptions = () => {
  const sectionIndex = useSectionIndexContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const currentSortType = useSelector(
    getFormValue(`sections.${sectionIndex}.card_sort_attributes.card_sort_type`)
  ) as CardSortType

  return (
    <Flex direction="column">
      <Field
        name={`sections[${sectionIndex}].card_sort_attributes.card_sort_type`}
        component={CardSortToggle}
      />
      <Text fontWeight="semibold">Card sort options</Text>
      <Flex justify="space-between" align="center" mt={4}>
        <Stack>
          <Field
            name={`sections[${sectionIndex}].card_sort_attributes.require_sort_all`}
            label="Require participants to sort all cards"
            component={WrappedCheckbox}
            disabled={readOnly}
          />
          {currentSortType !== "closed" && (
            <Field
              name={`sections[${sectionIndex}].card_sort_attributes.require_name_all`}
              label="Require participants to name all categories"
              component={WrappedCheckbox}
              disabled={readOnly}
            />
          )}
        </Stack>
        <Field
          name={`sections[${sectionIndex}].card_sort_attributes.number_of_cards_shown`}
          component={CardsShownNumberInput}
        />
      </Flex>
    </Flex>
  )
}

const CardsShownNumberInput: React.FC<
  WrappedFieldProps & { maxCards: number }
> = ({ input }) => {
  const { section } = useSectionContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const cards = section?.card_sort_attributes?.card_sort_cards_attributes ?? []
  const cardsLength = cards.filter((c) => !c._destroy).length ?? 0

  const { onBlur, ...rest } = input

  return (
    <HStack>
      <FormLabel htmlFor={input.name} m={0}>
        Number of cards to show participants
      </FormLabel>
      <NumberInput
        id={input.name}
        size="sm"
        min={1}
        max={cardsLength}
        clampValueOnBlur={false}
        maxW="88px"
        isReadOnly={readOnly}
        onBlur={(e) => {
          onBlur(e)
          Number(e.target.value) > cardsLength
            ? input.onChange(null)
            : input.onChange(e.target.value)
        }}
        {...rest}
      >
        <NumberInputField placeholder="All" rounded="md" />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </HStack>
  )
}
