import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

import { Omit } from "Types"

import {
  QuestionRadioButtonList,
  Props as RadioButtonListProps,
} from "./question-radio-button-list"

type BaseProps = Omit<RadioButtonListProps, "value" | "onChange">
type AdapterProps = BaseProps & WrappedFieldProps

function QuestionRadioButtonListFieldAdapter(props: Readonly<AdapterProps>) {
  const {
    input,
    meta, // unused
    ...rest
  } = props
  return (
    <QuestionRadioButtonList
      {...rest}
      value={input.value}
      onChange={input.onChange}
    />
  )
}

interface Props extends BaseProps {
  name: string
}

export function QuestionRadioButtonListField(props: Readonly<Props>) {
  return <Field component={QuestionRadioButtonListFieldAdapter} {...props} />
}
