import { Box, Stack } from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"

import { getTestLogicTargets } from "Redux/reducers/test-builder-form/selectors/test-logic"
import {
  useQuestionContext,
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"

import { useQuestionPath } from "../useQuestionPath"

import { TestLogicActionsForm } from "./TestLogicActionsForm"
import { useTestLogicStatement } from "./useTestLogicStatement"

export const SectionLogicActions: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()

  const formFieldName = `sections[${sectionIndex}]`

  const targets = useSelector(getTestLogicTargets(section._clientId))

  const { testLogicStatement, isEnabled } = useTestLogicStatement(formFieldName)

  return isEnabled ? (
    <Stack
      spacing={4}
      data-qa={`section-logic-${sectionIndex}`}
      py={4}
      px={8}
      mx={-8}
      bg="brand.primary.50"
    >
      <TestLogicActionsForm
        type="section"
        formFieldName={formFieldName}
        targets={targets}
        testLogicStatement={testLogicStatement!}
      />
    </Stack>
  ) : null
}

export const QuestionLogicActions: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const sectionIndex = useSectionIndexContext()
  const { question, questionIndex } = useQuestionContext()
  const questionPath = useQuestionPath()

  const targets = useSelector(getTestLogicTargets(question._clientId))

  const { testLogicStatement, isEnabled } = useTestLogicStatement(questionPath)

  return isEnabled ? (
    <Box>
      <Stack
        spacing={4}
        data-qa={`question-logic-${sectionIndex}-${questionIndex}`}
        my={-4}
        py={4}
        px={8}
        mx={-8}
        bg="brand.primary.50"
      >
        <TestLogicActionsForm
          type="question"
          formFieldName={questionPath}
          targets={targets}
          testLogicStatement={testLogicStatement!}
        />
      </Stack>
    </Box>
  ) : null
}
