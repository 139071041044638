import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ChevronUpIcon = createIcon({
  displayName: "ChevronUpIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="chevron-up">
        <path
          id="Combined Shape"
          d="M8 10.271L4.25831 14.1032C3.74169 14.6323 2.90408 14.6323 2.38746 14.1032C1.87085 13.574 1.87085 12.7162 2.38746 12.1871L7.06458 7.39684C7.5812 6.86772 8.4188 6.86772 8.93542 7.39684L13.6125 12.1871C14.1292 12.7162 14.1292 13.574 13.6125 14.1032C13.0959 14.6323 12.2583 14.6323 11.7417 14.1032L8 10.271Z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})
