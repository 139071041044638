import {
  Box,
  Button,
  ButtonProps,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { FilterAttribute } from "Types"

import { PlusSolidIcon } from "Shared/icons/untitled-ui/PlusSolidIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import { useFilterContext } from "./filter-context"
import { FilterMenuMulti } from "./filter-menu-multi"
import { FilterMenuSingle } from "./filter-menu-single"
import { FreeTextFilterModal } from "./free-text-filter-modal"

interface Props {
  // Switch to a clear button if there are filters applied
  showClearButton?: boolean
}

export const AddFilterButton: React.FC<ButtonProps & Props> = ({
  showClearButton = false,
  children,
  ...props
}) => {
  const { availableAttributes, filters, addFilter, removeAllFilters } =
    useFilterContext()
  const [selectedAttribute, setSelectedAttribute] =
    useState<FilterAttribute | null>(null)

  return (
    <Box pos="relative">
      <Menu>
        {children ??
          (showClearButton && filters.length > 0 ? (
            <Button
              variant="ghost"
              size="sm"
              leftIcon={<Icon as={XOutlineIcon} color="brand.primary.500" />}
              color="brand.primary.500"
              onClick={removeAllFilters}
            >
              Clear
            </Button>
          ) : (
            <MenuButton
              as={Button}
              variant="ghost"
              size="sm"
              leftIcon={<Icon as={PlusSolidIcon} />}
              {...props}
            >
              Add filter
            </MenuButton>
          ))}
        <MenuList overflow="hidden" position="relative" zIndex="dropdown">
          {availableAttributes.map((attribute) => (
            <MenuItem
              key={attribute.value}
              onClick={() => {
                if (attribute.possibleOptions.type === "static") {
                  addFilter(attribute, { type: "static" })
                } else {
                  setSelectedAttribute(attribute)
                }
              }}
            >
              {attribute.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {selectedAttribute?.possibleOptions.type === "single" && (
        <FilterMenuSingle
          selectedAttribute={selectedAttribute}
          onClose={() => setSelectedAttribute(null)}
        />
      )}

      {selectedAttribute?.possibleOptions.type === "multi" && (
        <FilterMenuMulti
          selectedAttribute={selectedAttribute}
          onClose={() => setSelectedAttribute(null)}
        />
      )}

      {selectedAttribute &&
        selectedAttribute.possibleOptions.type === "freeText" && (
          <FreeTextFilterModal
            attributeLabel={selectedAttribute.label}
            onSave={(value) => {
              addFilter(selectedAttribute, { type: "freeText", value })
              setSelectedAttribute(null)
            }}
            onClose={() => setSelectedAttribute(null)}
          />
        )}
    </Box>
  )
}
