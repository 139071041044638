import { Flex, Tab, TabList, Tabs } from "@chakra-ui/react"
import React from "react"
import { Link, useMatch } from "react-router-dom"

import { Header, HeaderContent } from "Shared/components/Header/Header"

import { ROUTES } from "../routes"

export const Subnav = () => {
  const purchaseRouteMatch = useMatch("/credits/purchase")
  const historyRouteMatch = useMatch("/credits/history")
  const tabIndex = purchaseRouteMatch ? 0 : historyRouteMatch ? 1 : NaN

  return (
    <Header>
      <HeaderContent>
        <Flex flex={1} justifyContent="center">
          <Tabs index={tabIndex} variant="soft-rounded">
            <TabList>
              <Link to={ROUTES.CREDITS.PURCHASE.path}>
                <Tab>Purchase credits</Tab>
              </Link>
              <Link to={ROUTES.CREDITS.HISTORY.path}>
                <Tab>History</Tab>
              </Link>
            </TabList>
          </Tabs>
        </Flex>
      </HeaderContent>
    </Header>
  )
}
