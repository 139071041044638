import { FormLabel, HStack, Switch, Tooltip } from "@chakra-ui/react"
import { InfoCircleOutlineIcon } from "Shared/icons/InfoCircleOutlineIcon"
import React from "react"

type AutoUploadRecordingSwitchProps = {
  id: string
  autoUploadRecordings: boolean
  toggleAutoUploadRecordings: () => void
}

export const AutoUploadRecordingSwitch: React.FC<
  AutoUploadRecordingSwitchProps
> = ({ id, autoUploadRecordings, toggleAutoUploadRecordings }) => {
  return (
    <HStack>
      <Switch
        id={id}
        isChecked={autoUploadRecordings}
        onChange={toggleAutoUploadRecordings}
      />
      <FormLabel
        fontSize="md"
        color="text.primary"
        my={0}
        htmlFor={id}
        cursor="pointer"
        marginEnd={0}
      >
        Automatically fetch recordings from Zoom Cloud if available
      </FormLabel>
      <Tooltip label="To enable this feature, your sessions must be able to be recorded to Zoom Cloud.">
        <InfoCircleOutlineIcon color="text.primary" verticalAlign={"center"} />
      </Tooltip>
    </HStack>
  )
}
