// This function takes the nested demographic structure and a list of active optionIds and returns

import { DemographicAttributeGroup } from "~/api/generated/usabilityhubSchemas"

// a flat list of demographics with only the active options present.
export const getActiveDemographicAttributes = (
  demographicAttributeOptionIds: number[],
  demographics: DemographicAttributeGroup[]
) => {
  if (!demographicAttributeOptionIds) return []

  return demographics.flatMap((group) =>
    group.demographic_attributes.flatMap((attribute) => {
      const matchingOptions = attribute.options?.filter((option) =>
        demographicAttributeOptionIds.includes(option.id)
      )

      if (!matchingOptions?.length) return []

      return { ...attribute, options: matchingOptions }
    })
  )
}
