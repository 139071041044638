import { useState } from "react"

export function useToggleState(
  initialIsToggled = false
): [boolean, () => void] {
  const [isEnabled, setIsEnabled] = useState(initialIsToggled)
  return [
    isEnabled,
    function toggle() {
      setIsEnabled(!isEnabled)
    },
  ]
}
