import { keyBy } from "lodash"
import { createSelector } from "reselect"

import { State } from "Redux/app-store"

const getUsabilityTests = (state: State) => state.usabilityTests

const usabilityTestsByIdSelector = createSelector(
  getUsabilityTests,
  (usabilityTests) => keyBy(usabilityTests, "id")
)

export const getUsabilityTestByUniqueId = (uniqueId: string | undefined) =>
  createSelector(getUsabilityTests, (usabilityTests) => {
    if (!uniqueId) return undefined

    return keyBy(usabilityTests, "unique_id")[uniqueId]
  })

export const getUsabilityTest = createSelector(
  usabilityTestsByIdSelector,
  (_state: State, id: number) => id,
  (testsById, id) => {
    const result = testsById[id]
    if (result === undefined) {
      throw new TypeError(`Couldn${"\u2019"}t find test with id ${id} in tests`)
    }
    return result
  }
)
