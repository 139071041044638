import {
  Avatar,
  Box,
  Divider,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react"
import {
  PanelOrderCompositeIcon,
  RecruitmentLinkCompositeIcon,
} from "Shared/components/CompositeIcons/CompositeIcons"
import { ROUTES } from "UsabilityHub/views/routes"
import { getDateString } from "Utilities/date-formats"
import React, { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { GetModeratedStudyBookingsResponse } from "~/api/generated/usabilityhub-components"
import { bookingParticipantName } from "../BookingCard/BookingCard"
import {
  ParticipantInfoDrawer,
  useParticipantInfoDrawerContext,
} from "../ParticipantInfoDrawer/ParticipantInfoDrawer"

type ListProps = {
  bookings: GetModeratedStudyBookingsResponse
}

export const List: React.FC<ListProps> = ({ bookings }) => {
  const { moderatedStudyId, sessionId } = useTypedParams(
    ROUTES.INTERVIEW.RECORDINGS.SESSION
  )

  const navigate = useNavigate()

  const [activeIndex, setActiveIndex] = useState(0)

  const list = useRef<HTMLDivElement>(null)

  const { openParticipantInfoDrawer } = useParticipantInfoDrawerContext()
  const currentlyVisibleApplicationIds = bookings.map(
    (b) => b.moderated_study_application_id
  )

  const keyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!bookings.length) return

    switch (e.key) {
      case "ArrowUp":
        setActiveIndex((i) => (i + bookings.length - 1) % bookings.length)
        break
      case "ArrowDown":
        setActiveIndex((i) => (i + 1) % bookings.length)
        break
      case "Enter":
      case " ":
        e.preventDefault()
        if (bookings[activeIndex]) listItemClicked(bookings[activeIndex])
        break
    }
  }

  const listItemClicked = (booking: ListProps["bookings"][number]) => {
    navigate(
      ROUTES.INTERVIEW.RECORDINGS.SESSION.buildPath({
        moderatedStudyId,
        sessionId: booking.id,
      }),
      { replace: true }
    )
  }

  return (
    <Box
      bg="white"
      borderWidth={1}
      borderColor="gray.200"
      rounded="md"
      w="267px"
    >
      <Heading as="h3" size="sm" fontWeight="medium" p={4}>
        Participants
      </Heading>
      <Divider />
      <Stack
        p={2}
        gap={0}
        ref={list}
        tabIndex={0}
        role="list"
        aria-activedescendant={
          bookings.length
            ? `participant-${bookings[activeIndex]?.id}`
            : undefined
        }
        _focusVisible={{
          outline: "none",
        }}
        sx={{
          "&:focus-visible": {
            "> [data-active]": {
              outline: "0.125rem solid transparent",
              outlineColor: "teal.500",
              outlineOffset: "-0.125rem",
            },
          },
        }}
        onKeyDown={keyDown}
      >
        {bookings.map((booking, i) => (
          <Flex
            key={booking.id}
            p={2}
            gap={2}
            alignItems="center"
            role="listitem"
            fontSize="sm"
            fontWeight="medium"
            lineHeight="1rem"
            borderRadius="md"
            id={`participant-${booking.id}`}
            data-id={booking.id}
            aria-selected={sessionId === booking.id || undefined}
            data-active={activeIndex === i || undefined}
            onClick={() => {
              setActiveIndex(i)
              listItemClicked(booking)
            }}
            cursor="pointer"
            userSelect="none"
            sx={{
              "&[aria-selected]": {
                background: "teal.50",
                color: "teal.600",
                small: {
                  color: "teal.600",
                  opacity: 0.75,
                },
              },
            }}
          >
            <Flex align="flex-start">
              <Avatar size="sm" bg="gray.200" mixBlendMode="multiply" />
            </Flex>

            <Flex direction="column" gap={1} flexGrow={1}>
              <Flex gap={1}>
                <Text
                  as="span"
                  color={
                    booking.deleted_participant
                      ? "text.secondary"
                      : "text.primary"
                  }
                  fontStyle={booking.deleted_participant ? "italic" : undefined}
                >
                  {bookingParticipantName(booking)}
                </Text>

                {booking.is_panelist ? (
                  <PanelOrderCompositeIcon size={4} isRounded />
                ) : (
                  <RecruitmentLinkCompositeIcon size={4} isRounded />
                )}
              </Flex>

              <Text as="small" color="text.secondary" fontSize="sm">
                {getDateString(new Date(booking.starts_at))}
              </Text>
            </Flex>

            <IconButton
              type="button"
              variant="outline"
              size="sm"
              icon={<Avatar boxSize={5} />}
              aria-label="View participant details"
              onClick={(e) => {
                e.stopPropagation()
                setActiveIndex(i)
                openParticipantInfoDrawer(
                  booking.moderated_study_application_id
                )
              }}
              onKeyDown={(e) => e.stopPropagation()}
              onFocus={() => setActiveIndex(i)}
            />
          </Flex>
        ))}
      </Stack>
      <ParticipantInfoDrawer
        currentlyVisibleApplicationIds={currentlyVisibleApplicationIds}
      />
    </Box>
  )
}
