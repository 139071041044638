import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const SimilarityIcon = createIcon({
  displayName: "SimilarityIcon",
  viewBox: "0 0 14 14",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 10H2.5C1.11929 10 0 8.88071 0 7.5V2.5C0 1.11929 1.11929 0 2.5 0H7.5C8.88071 0 10 1.11929 10 2.5V4H11.5C12.8807 4 14 5.11928 14 6.49999V11.5C14 12.8807 12.8807 14 11.5 14H6.49999C5.11928 14 4 12.8807 4 11.5V10ZM8.75 5.25V7.5C8.75 8.19036 8.19036 8.75 7.5 8.75H5.25V6.49999C5.25 5.80964 5.80964 5.25 6.49999 5.25H8.75Z"
      fill="currentColor"
    />
  ),
})
