import {
  Flex,
  FlexProps,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import { getScreenerQuestionTypeLabel } from "UsabilityHub/views/ModeratedStudy/interviewer/ParticipantInfoDrawer/ParticipantInfoDrawerScreenerAnswer"
import React from "react"
import { GetTestResultsIndividualResponseResponse } from "~/api/generated/usabilityhub-components"

interface Props {
  screenerResponse: NonNullable<
    GetTestResultsIndividualResponseResponse["screener_response"]
  >
}

export const IndividualScreenerResponse: React.FC<
  Props & Omit<FlexProps, "children">
> = ({ screenerResponse, ...flexProps }) => {
  return (
    <Flex direction="column" {...flexProps} gap={8}>
      <Heading fontSize="lg" fontWeight="semibold" color="gray.900">
        Screener answers
      </Heading>

      {screenerResponse.questions.map((question) => {
        return (
          <Flex key={question.text} flexDirection="column" gap={3}>
            <Text fontSize="sm" color="gray.700">
              {getScreenerQuestionTypeLabel(question.type)}
            </Text>
            <Text>{question.text}</Text>
            {question.answers.length === 1 ? (
              <Text color="helperText">{question.answers[0]}</Text>
            ) : (
              <UnorderedList color="helperText">
                {question.answers.map((answer) => (
                  <ListItem key={answer}>{answer}</ListItem>
                ))}
              </UnorderedList>
            )}
          </Flex>
        )
      })}
    </Flex>
  )
}
