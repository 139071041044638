import React from "react"

import { useClickViewState } from "../useClickViewState"

import { ScreenResultTabs } from "./ScreenResultTabs"

export function ScreenResultTabsContainer() {
  const [view, setView] = useClickViewState()

  return <ScreenResultTabs clickView={view} setClickView={setView} />
}
