import { Dictionary, get, isString, reduce } from "lodash"

import { Translations } from "Types"

export type Interpolations = Dictionary<string>

const handlePluralisation = (
  source: string | Translations,
  count: string | undefined
) => {
  if (isString(source) || count === undefined) return source
  if (count === "0" && source.zero) return source.zero
  return count === "1" ? source.one : source.other
}

export function translate(
  translations: Translations,
  path: string,
  interpolations?: Interpolations
) {
  const translation = handlePluralisation(
    get(translations, path),
    get(interpolations, "count")
  )

  if (!isString(translation)) {
    if (process.env.DEBUG) {
      if (translation === undefined) {
        throw new TypeError(`Unknown translation path: ${path}`)
      }
      throw new TypeError(
        `Path ${path} did not map to a string. Got ${String(translation)}`
      )
    }
    return ""
  }

  return reduce(
    interpolations,
    (result, value, key) => result.replace(`%{${key}}`, value),
    translation
  )
}
