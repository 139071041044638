import { useQueryClient } from "@tanstack/react-query"
import { useMemo } from "react"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { TeamMember } from "~/api/generated/usabilityhubSchemas"
import { useListTeamMembers } from "./../../api/generated/usabilityhub-components"

type Arguments = (
  | { ignoreRoles: TeamMember["role"][]; onlyRoles?: undefined }
  | { onlyRoles: TeamMember["role"][]; ignoreRoles?: undefined }
) & {
  onlyActive?: boolean
}

export const useTeamMembers = (
  { onlyRoles, ignoreRoles, onlyActive = false }: Arguments = {
    ignoreRoles: ["archived"],
  }
): TeamMember[] => {
  const { data } = useListTeamMembers(
    {},
    {
      cacheTime: 60_000,
      staleTime: 60_000,
    }
  )

  return useMemo(() => {
    let teamMembers = data ?? []

    if (onlyRoles) {
      teamMembers = teamMembers.filter((t) => onlyRoles.includes(t.role))
    }

    if (ignoreRoles) {
      teamMembers = teamMembers.filter((t) => !ignoreRoles.includes(t.role))
    }

    if (onlyActive) {
      teamMembers = teamMembers.filter((t) => t.sign_in_count > 0)
    }

    return teamMembers
  }, [data, onlyRoles?.join(","), ignoreRoles?.join(","), onlyActive])
}

export const useTeamMember = (id: number) => {
  const teamMembers = useTeamMembers()
  return teamMembers.find((t) => t.id === id)
}

export const useRefreshTeamMembers = () => {
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  const queryKey = queryKeyFn({
    path: "/api/team_members",
    operationId: "listTeamMembers",
    variables: {},
  })

  return async () => queryClient.invalidateQueries(queryKey)
}
