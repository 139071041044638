import { createIcon } from "@chakra-ui/react"
import React from "react"

export const SearchIcon = createIcon({
  displayName: "SearchIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="search">
      <path
        id="search_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.68056 3.31944C8.80092 2.43981 7.74075 2 6.5 2C5.25925 2 4.19908 2.43981 3.31944 3.31944C2.43981 4.19908 2 5.25925 2 6.5C2 7.74075 2.43981 8.80092 3.31944 9.68056C4.19908 10.5602 5.25925 11 6.5 11C7.37963 11 8.18055 10.7685 8.90278 10.3056L12.2917 13.7083C12.4861 13.9028 12.7222 14 13 14C13.2778 14 13.5139 13.9028 13.7083 13.7083C13.9028 13.5139 14 13.2778 14 13C14 12.7222 13.9028 12.4861 13.7083 12.2917L10.3056 8.90278C10.7685 8.18055 11 7.37963 11 6.5C11 5.25925 10.5602 4.19908 9.68056 3.31944ZM8.625 8.625C8.0324 9.20834 7.32408 9.5 6.5 9.5C5.67592 9.5 4.96991 9.20602 4.38194 8.61806C3.79398 8.03009 3.5 7.32408 3.5 6.5C3.5 5.67592 3.79398 4.96991 4.38194 4.38194C4.96991 3.79398 5.67592 3.5 6.5 3.5C7.32408 3.5 8.03009 3.79398 8.61806 4.38194C9.20602 4.96991 9.5 5.67592 9.5 6.5C9.5 7.32408 9.20834 8.0324 8.625 8.625Z"
        fill="currentColor"
      />
    </g>
  ),
})
