import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  ListItem,
  OrderedList,
  Stack,
  Text,
  usePrefersReducedMotion,
} from "@chakra-ui/react"
import React, { MutableRefObject, useRef, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { Alert, Button, ButtonProps, Heading } from "DesignSystem/components"
import { CustomerSupportMailtoLink } from "Shared/components/Links/CustomerSupportMailtoLink"
import { TimezoneChanger } from "Shared/components/TimezoneChanger/TimezoneChanger"
import { CalendarOutlineIcon } from "Shared/icons/untitled-ui/CalendarOutlineIcon"
import { useModeratedStudyPreviewData } from "UsabilityHub/hooks/useModeratedStudyPreviewData"
import { ROUTES } from "UsabilityHub/views/routes"
import { FunctionalModal } from "Utilities/modals/types"
import { useModal } from "Utilities/modals/use-modal"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { usePreviewModeratedStudyApplication } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyApplicationProvider } from "../../applicant/ModeratedStudyApplicationContext"
import { BookingCalendar } from "./BookingCalendar"

interface Props {
  onAvailabilityCalendarPreviewModalOpen?: () => void
}

export const AvailabilityCalendarPreviewModalTrigger: React.FC<
  Props & Omit<ButtonProps, "onClick">
> = ({ onAvailabilityCalendarPreviewModalOpen, ...rest }) => {
  const triggerRef = useRef<HTMLButtonElement | null>(null)
  const { open } = useModal(AvailabilityCalendarPreviewModal, "plan-drawer")

  return (
    <Button
      ref={triggerRef}
      onClick={() => {
        open({
          triggerRef,
        })
        onAvailabilityCalendarPreviewModalOpen?.()
      }}
      {...rest}
    />
  )
}

const AvailabilityCalendarPreviewModal: FunctionalModal<
  Props & { triggerRef?: MutableRefObject<HTMLButtonElement | null> }
> = ({ triggerRef, isOpen, onClose }) => {
  const { moderatedStudyId } = useTypedParams(ROUTES.INTERVIEW.EDIT)
  const queryClient = useQueryClient()
  const {
    error,
    data: application,
    isLoading,
  } = usePreviewModeratedStudyApplication({
    pathParams: {
      moderatedStudyId,
    },
  })

  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null)
  const [selectedTimezone, setSelectedTimezone] = useState(() => {
    // Try to guess default timezone based on the browser
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  const updatePreviewData = useModeratedStudyPreviewData()

  const existingBooking = application?.booking

  const handleBooking = async (
    startTime: string,
    participantTimezone: string
  ) => {
    const startTimeAsDate = new Date(Date.parse(startTime))

    if (application?.moderated_study_application_id === "preview") {
      updatePreviewData({
        booking: {
          id: "preview",
          state: "booked",
          starts_at: startTimeAsDate.toISOString(),
          ends_at: new Date(
            startTimeAsDate.getTime() +
              application.moderated_study.event_duration_mins * 60 * 1000
          ).toISOString(),
          hosts: [{ name: "Host name", email: "host@example.com" }],
          participant_timezone: participantTimezone,
          declined_reschedule_request: false,
          incentive_text: application.recruitment_link?.incentive_text ?? "",
          incentive_type:
            application.recruitment_link?.incentive_type ?? "none",
        },
      })
    }
  }

  handleBooking(new Date().toDateString(), selectedTimezone)

  // We don't want to treat the canceled states as an existing booking
  const existingBookingId =
    existingBooking?.state === "rescheduled_by_researcher"
      ? existingBooking?.id
      : undefined

  const prefersReducedMotion = usePrefersReducedMotion()

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={triggerRef}
      >
        <DrawerOverlay />
        <DrawerContent
          maxW="706px"
          data-qa="change-plan-drawer"
          motionProps={
            prefersReducedMotion
              ? { transformTemplate: () => "none" }
              : undefined
          }
        >
          <HStack
            justify="end"
            px={6}
            py={4}
            borderBottom="1px solid"
            borderColor="ds.border.default"
          >
            <DrawerCloseButton position="static" />
          </HStack>

          <DrawerBody
            pt={6}
            pl={6}
            pr={6}
            pb={0}
            display="grid"
            gridTemplate={{
              base: '"title" auto "alert" auto "timezone" auto "calendar" auto "times" 1fr / 1fr',
              md: '"title title" auto "alert alert" auto "calendar times" 1fr " timezone times" auto / 22.25rem 1fr',
            }}
            rowGap={6}
            columnGap={8}
            overflow={{ md: "hidden" }}
          >
            <Heading gridArea="title" as="h3" textStyle="ds.display.primary">
              Preview availability
            </Heading>
            <Stack gridArea="alert" gap={4}>
              <Alert
                status="default"
                icon={CalendarOutlineIcon}
                description="Available time slots take into account your weekly hours, any date overrides, and the availability of all hosts from their nominated calendars."
              />

              {!isLoading && error && (
                <Alert
                  status="danger"
                  description={
                    <Stack gap={2}>
                      <p>
                        We encountered an issue loading the availability
                        preview. Please try the following steps:
                      </p>
                      <OrderedList mx={6}>
                        <ListItem>Refresh the page and attempt again.</ListItem>
                        <ListItem>
                          If the problem persists, contact our{" "}
                          <CustomerSupportMailtoLink>
                            support team
                          </CustomerSupportMailtoLink>
                          .
                        </ListItem>
                      </OrderedList>
                      <p>We apologize for any inconvenience.</p>
                    </Stack>
                  }
                />
              )}
            </Stack>

            {!isLoading && !error && (
              <ModeratedStudyApplicationProvider
                application={application}
                invalidateApplicationQuery={async () => {
                  queryClient.invalidateQueries([
                    "api",
                    "moderated_study_applications",
                    application.moderated_study_application_id,
                  ])
                }}
              >
                <Stack gridArea="timezone" gap={3} pb={6} align="start">
                  <Text textStyle="ds.heading.secondary">
                    View based on selected timezone
                  </Text>
                  <TimezoneChanger
                    variant="outline"
                    size="sm"
                    rounded={6}
                    iconInside
                    value={selectedTimezone}
                    onChange={(e) => {
                      setSelectedTimezone(e.target.value)
                    }}
                  />
                </Stack>
                <BookingCalendar
                  moderatedStudyApplicationId={
                    application.moderated_study_application_id
                  }
                  slot={selectedSlot}
                  onSlotChange={setSelectedSlot}
                  timezone={selectedTimezone}
                  isFirstLoading={isFirstLoading}
                  setIsFirstLoading={setIsFirstLoading}
                  existingBookingId={existingBookingId}
                  displayFullSlots
                />
              </ModeratedStudyApplicationProvider>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
