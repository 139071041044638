import {
  animate,
  motion,
  useMotionTemplate,
  useMotionValue,
} from "framer-motion"
import React, { forwardRef, useEffect } from "react"
import { usePathDiagramColors } from "./Legend"
import { LaidOutLink } from "./useTreeTestPathDiagramLayout"

type AnimatedLinkProps = {
  link: LaidOutLink
  offsetX?: number
  offsetY?: number
  delay?: number
  onMouseOver: (link: LaidOutLink) => void
  onMouseOut: () => void
}

export const AnimatedLink = forwardRef<SVGPathElement, AnimatedLinkProps>(
  (
    { link, offsetX = 0, offsetY = 0, delay = 0, onMouseOver, onMouseOut },
    ref
  ) => {
    const x1 = useMotionValue(link.x0 + offsetX)
    const x2 = useMotionValue(link.x1 + offsetX)
    const y1 = useMotionValue(link.y0 + offsetY)
    const y2 = useMotionValue(link.y1 + offsetY)
    const xa = useMotionValue(link.x0 + 100 + offsetX)
    const xb = useMotionValue(link.x1 - 100 + offsetX)

    const path = useMotionTemplate`M${x1},${y1}C${xa},${y1},${xb},${y2},${x2},${y2}`

    useEffect(() => {
      animate(x1, offsetX, { duration: 0.5, delay })
      animate(y1, link.y0 + offsetY, { duration: 0.5, delay })
      animate(x2, link.x1 + offsetX, { duration: 0.5, delay })
      animate(y2, link.y1 + offsetY, { duration: 0.5, delay })
    }, [link.y0, link.y1, offsetX, offsetY])

    const colors = usePathDiagramColors()
    const color = colors.link[link.result]

    return (
      <motion.path
        ref={ref}
        d={path}
        stroke={color}
        strokeWidth={link.width}
        fill="none"
        initial={{ pathLength: 0, opacity: 0 }}
        animate={{
          pathLength: 1,
          opacity: 0.5,
          transition: { duration: 0.5, delay },
        }}
        exit={{ pathLength: 0, opacity: 0 }}
        transition={{ duration: 0.5 }}
        pointerEvents="auto"
        onMouseOver={() => onMouseOver(link)}
        onMouseOut={onMouseOut}
      />
    )
  }
)
