import { ChangeEventHandler, FocusEventHandler } from "react"
import { WrappedFieldInputProps } from "redux-form"

export function useMapFieldValue<Value extends string, OutputValue>(
  { onChange, onBlur, ...props }: WrappedFieldInputProps,
  inputValueMappingFunction: (rawInputValue: Value) => OutputValue
) {
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const outputValue = inputValueMappingFunction(e.target.value as Value)
    onChange(outputValue)
  }

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const outputValue = inputValueMappingFunction(e.target.value as Value)
    onBlur(outputValue)
  }

  const setValue = (value: Value) => {
    const outputValue = inputValueMappingFunction(value)
    onBlur(outputValue)
  }

  return {
    ...props,
    onChange: handleOnChange,
    onBlur: handleOnBlur,
    setValue,
  }
}
