import cx from "classnames"
import React, { ComponentType } from "react"

import { QuestionType } from "Types"
import { isBlank } from "Utilities/values"

import { CheckboxAnswer } from "./checkbox-answer"
import styles from "./individual-answer-result.module.scss"
import { LinearScaleAnswer } from "./linear-scale-answer"
import { Props } from "./props"
import { RankingAnswer } from "./ranking-answer"
import { TextAnswer } from "./text-answer"

function getAnswerComponent(
  type: QuestionType
): ComponentType<React.PropsWithChildren<Props>> {
  switch (type) {
    case QuestionType.ShortAnswer:
    case QuestionType.LongAnswer:
    case QuestionType.RadioMultipleChoice:
      return TextAnswer
    case QuestionType.Ranking:
      return RankingAnswer
    case QuestionType.CheckboxMultipleChoice:
      return CheckboxAnswer
    case QuestionType.LinearScale:
      return LinearScaleAnswer
  }
}

export function IndividualAnswerResult(props: Props) {
  const { answer, question } = props

  if (isBlank(answer.answer) && answer.answers.length === 0) {
    return <p className={cx("para", styles.passed)}>Passed</p>
  }
  const AnswerComponent = getAnswerComponent(question.type)
  return <AnswerComponent {...props} />
}
