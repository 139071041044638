import React from "react"
import { useMediaQuery } from "react-responsive"

import { breakLarge } from "Constants/break-points"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"

import { PrototypeTaskProvider } from "./PrototypeTaskContext"
import { PrototypeTaskDesktop } from "./PrototypeTaskDesktop"
import { PrototypeTaskMobile } from "./PrototypeTaskMobile"
import { usePrototypeTask } from "./usePrototypeTask"

export const PrototypeTask: React.FC<React.PropsWithChildren<InnerProps>> = ({
  usabilityTestSection,
  responseSection,
  updateResponseSection,
}) => {
  const isDesktop = useMediaQuery({ minWidth: breakLarge })
  const prototypeTask = usePrototypeTask({
    isDesktop,
    section: usabilityTestSection,
    responseSection,
    updateResponseSection,
  })
  return (
    <PrototypeTaskProvider prototypeTask={prototypeTask}>
      {isDesktop ? (
        <PrototypeTaskDesktop section={usabilityTestSection} />
      ) : (
        <PrototypeTaskMobile section={usabilityTestSection} />
      )}
    </PrototypeTaskProvider>
  )
}
