import { useCallback, useState } from "react"

import { integrationConnectedGoogle } from "JavaScripts/analytics/google"
import { useOAuth } from "./useOAuth"

export const useZoomOAuth = (
  initialState = false,
  onSuccess: () => void = () => {}
) => {
  const [hasZoomOAuthCredentials, setHasZoomOAuthCredentials] =
    useState(initialState)

  const disconnectZoomOAuth = useCallback(
    () => setHasZoomOAuthCredentials(false),
    []
  )
  const { handleWithAuth: handleZoomOAuth } = useOAuth(
    () => {
      setHasZoomOAuthCredentials(true)
      onSuccess()
      integrationConnectedGoogle("Zoom")
    },
    "Zoom",
    "/zoom_oauth2/redirect",
    hasZoomOAuthCredentials
  )

  return { handleZoomOAuth, disconnectZoomOAuth, hasZoomOAuthCredentials }
}
