import { useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { thirdPartyResponsesOrderedGoogle } from "JavaScripts/analytics/google"
import { trackCreditsPurchased } from "JavaScripts/analytics/track"
import { isDeprecatedAxiosError } from "Services/axios"
import { ThirdPartyQuote } from "Types"
import { ROUTES } from "UsabilityHub/views/routes"
import { useState } from "react"
import { useNavigate } from "react-router"
import { thirdPartyOrders } from "~/api"
import { OrderCreateState, OrderResponse, TargetLocation } from "../types"

export const useExternalPanelOrderSubmit = ({
  confirmCreditPurchase,
}: {
  confirmCreditPurchase: () => void
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const navigate = useNavigate()

  const [orderCreateState, setOrderCreateState] =
    useState<OrderCreateState>("idle")
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false)

  const submitOrder = async (
    usabilityTestUniqueId: string,
    requestedResponseCount: number,
    minAge: number,
    maxAge: number,
    targetLocations: TargetLocation[],
    demographicOptionIds: number[],
    quote: ThirdPartyQuote,
    isConfirming: boolean
  ) => {
    if (!isConfirming && quote.credits_to_be_purchased > 0) {
      confirmCreditPurchase()
      return
    }

    // Prevent double-click
    if (isSubmittingOrder) return
    setIsSubmittingOrder(true)

    setOrderCreateState("loading")

    try {
      const params = {
        usability_test_id: usabilityTestUniqueId,
        requested_response_count: requestedResponseCount,
        min_age: minAge,
        max_age: maxAge,
        target_locations: targetLocations,
        demographic_attribute_option_ids: demographicOptionIds,
      }

      const data = await thirdPartyOrders.create<OrderResponse>({
        data: params,
      })
      if (data.purchase) {
        trackCreditsPurchased(
          "response_order",
          data.purchase.credit_count,
          data.purchase.price_in_cents
        )
      }
      thirdPartyResponsesOrderedGoogle()

      void queryClient.invalidateQueries(["api", "account", "total_credits"])

      toast({
        title:
          "Your external panel order has been created, and will start receiving results in the next 24 - 48 hours.",
        status: "success",
        isClosable: false,
      })

      navigate(ROUTES.TEST.RECRUIT.buildPath({ testId: usabilityTestUniqueId }))
    } catch (e) {
      setIsSubmittingOrder(false)
      if (isDeprecatedAxiosError<string>(e)) {
        const message =
          e.response?.data ?? "There was an error creating your order."

        toast({
          title: "Error",
          description: message,
          status: "error",
          isClosable: false,
        })
      } else if (isErrorWithMessage(e)) {
        toast({
          title: "Error",
          description: e.payload.message,
          status: "error",
          isClosable: false,
        })
      } else {
        throw e
      }
    } finally {
      setOrderCreateState("idle")
    }
  }

  return { orderCreateState, submitOrder }
}

// Temporary until we swap this API to OpenAPI
const isErrorWithMessage = (
  e: unknown
): e is { payload: { message: string } } => {
  return (
    typeof e === "object" &&
    e !== null &&
    "payload" in e &&
    typeof e.payload === "object" &&
    e.payload !== null &&
    "message" in e.payload &&
    typeof e.payload.message === "string"
  )
}
