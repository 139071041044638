import { ButtonGroup, Container, Flex } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { AnimatePresence, MotionConfig, Variants, motion } from "framer-motion"
import React from "react"
import { useOnboardingContext } from "./OnboardingContextProvider"

const footerVariants: Variants = {
  out: {
    y: "100%",
    opacity: 0,
  },
  in: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
}

export const Footer: React.FC = () => {
  const { index, step, skip, back, isValid, isSkippable, isSaving } =
    useOnboardingContext()

  return (
    <MotionConfig transition={{ duration: 0.3, ease: [0.4, 0, 0.2, 1] }}>
      <Container
        as="footer"
        maxW="532px"
        gridArea="footer"
        py={8}
        minH={10}
        color="text.secondary"
        fontSize="sm"
      >
        <AnimatePresence mode="popLayout" initial={false}>
          <motion.div
            key={index > 0 ? 1 : 0}
            variants={footerVariants}
            initial="out"
            animate="in"
            exit="out"
          >
            {index > 0 && (
              <Flex justify="space-between" align="center">
                <Button
                  type="button"
                  size="emphasized"
                  w="fit-content"
                  isDisabled={isSaving}
                  visibility={index <= 1 ? "hidden" : undefined}
                  onClick={back}
                >
                  Back
                </Button>

                <ButtonGroup justifySelf="end">
                  {isSkippable && (
                    <Button
                      justifySelf={{ base: "start", lg: "center" }}
                      variant="secondary"
                      size="emphasized"
                      onClick={skip}
                    >
                      Skip
                    </Button>
                  )}

                  <Button
                    variant="primary"
                    size="emphasized"
                    type="submit"
                    form="onboarding_form"
                    isDisabled={!isValid}
                    isLoading={isSaving}
                  >
                    {"continue" in step ? step.continue : "Continue"}
                  </Button>
                </ButtonGroup>
              </Flex>
            )}
          </motion.div>
        </AnimatePresence>
      </Container>
    </MotionConfig>
  )
}
