import { Filter } from "Components/test-results/types/filters"

import { getFilterMeta } from "../get-filter-meta"

import { ReachedGoalScreenFilter } from "./reached-goal-screen"
import { FilterFunction } from "./types"

export type PrototypeNodeFilter = Filter<
  `answer/section:${number}/prototype-node`,
  "answer/prototype-node",
  string
>

export const filterResponseByPrototypeNode: FilterFunction<
  PrototypeNodeFilter | ReachedGoalScreenFilter
> = ({
  filter,
  filteredRelatedData: { responseSections, responseSectionFigmaTasks },
}) => {
  const { sectionId } = getFilterMeta(filter.category)

  const responseSection = responseSections.find(
    (rs) => rs.usability_test_section_id === sectionId
  )

  if (responseSection === undefined) {
    return false
  }

  // Find the task for this response section.
  const figmaTask = responseSectionFigmaTasks.find(
    (ft) => ft.response_section_id === responseSection.id
  )

  // No task means there will definitely be no matching node in this response.
  if (figmaTask === undefined) {
    return false
  }

  return figmaTask.path.includes(filter.value)
}
