import { Box } from "@chakra-ui/react"
import React from "react"

const instructionsStyles = {
  transitionDuration: "200ms",
  transitionProperty: "font-size, font-weight, color",
  maxW: "xl",
  fontWeight: "regular",
}

export const PrimaryInstructions: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Box {...instructionsStyles} color="text.primary" fontSize="lg">
      {children}
    </Box>
  )
}

export const SecondaryInstructions: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Box {...instructionsStyles} color="text.secondary" fontSize="sm">
      {children}
    </Box>
  )
}
