import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const CardSortIcon = createIcon({
  displayName: "CardSortIcon",
  viewBox: "0 0 21 20",
  path: (
    <>
      <path
        d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.5 15.5228 0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10Z"
        fill="#00B5D8"
      />
      <rect x="4.5" y="6" width="12" height="6" rx="1" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 13C5.5 13.5523 5.94772 14 6.5 14H14.5C15.0523 14 15.5 13.5523 15.5 13H5.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.51685 15.083C6.60292 15.5478 7.0104 15.8998 7.50009 15.8998H13.5001C13.9898 15.8998 14.3973 15.5478 14.4833 15.083C14.4239 15.094 14.3627 15.0998 14.3001 15.0998H6.70009C6.6375 15.0998 6.57625 15.094 6.51685 15.083Z"
        fill="white"
      />
    </>
  ),
})
