import {
  Icon,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  StatProps,
} from "@chakra-ui/react"
import { CustomTooltip } from "Components/tooltip/CustomTooltip"
import { tokens } from "DesignSystem/tokens"
import { InfoCircleIcon } from "Shared/icons/InfoCircleIcon"
import { TreeNode } from "Types"
import { useSectionContext } from "UsabilityHub/contexts"
import React, { useMemo } from "react"
import { useIndividualPaths } from "./usePaths"

const useTreeTestStats = () => {
  const paths = useIndividualPaths()
  const responseCount = paths.length
  const successful =
    (paths.filter((path) => path.result === "success").length * 1.0) /
    responseCount
  const direct =
    (paths.filter(
      (path) => path.result === "success" && path.directness === "direct"
    ).length *
      1.0) /
    responseCount

  return {
    responseCount,
    successful,
    direct,
  }
}

export const TreeTestOverview: React.FC = () => {
  const { section } = useSectionContext()
  const treeTest = section.tree_test_attributes

  const { responseCount, successful, direct } = useTreeTestStats()

  const correct_nodes = useMemo<TreeNode[]>(() => {
    const set = new Set(treeTest?.correct_nodes || [])
    return (
      treeTest?.nodes.filter((node) => set.has(node.id)) || ([] as TreeNode[])
    )
  }, [])

  return (
    <StatGroup
      gap={6}
      p={6}
      border="1px solid transparent"
      borderColor="border.default"
      rounded="base"
    >
      <Statistic flex={1} label="Nominated correct answer(s)">
        {correct_nodes?.map((node) => node.label).join(", ")}
      </Statistic>
      <Statistic label="Responses" tooltip="Number of responses">
        {responseCount}
      </Statistic>
      <Statistic
        label="Success rate"
        tooltip="Percentage of respondents who found a correct answer"
      >
        {successful ? `${Math.round(successful * 100)}%` : "N/A"}
      </Statistic>
      <Statistic
        label="Directness"
        tooltip="Percentage of respondents who selected the correct answer without backtracking"
      >
        {direct ? `${Math.round(direct * 100)}%` : "N/A"}
      </Statistic>
    </StatGroup>
  )
}

type StatisticProps = StatProps & {
  label: string
  tooltip?: string
}

const Statistic: React.FC<StatisticProps> = ({
  label,
  tooltip,
  children,
  ...props
}) => (
  <Stat flex="0 0 7rem" {...props}>
    <StatLabel display="flex" alignItems="center" gap={1}>
      {label}
      {tooltip && (
        <CustomTooltip
          tooltip={tooltip}
          sx={{ ...tokens.textStyles.ds.paragraph.primary }}
        >
          <Icon as={InfoCircleIcon} color="grey.dark" boxSize="0.875rem" />
        </CustomTooltip>
      )}
    </StatLabel>
    <StatNumber fontSize="md">{children}</StatNumber>
  </Stat>
)
