import {
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  WrapItem,
} from "@chakra-ui/react"
import emptyAllSrc from "Images/app-illustrations/lyssna/dashboard-preview.png"
import { CreateNewModeratedStudyModal } from "UsabilityHub/components/CreateNewModeratedStudyModal/CreateNewModeratedStudyModal"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { useNavigate } from "react-router"
import { ROUTES } from "../../routes"

export const EmptyDashboard: React.FC = () => {
  const navigate = useNavigate()

  const {
    open: openCreateNewModeratedStudyModal,
    close: closeCreateNewModeratedStudyModal,
  } = useModal(CreateNewModeratedStudyModal)

  const openCreateModal = () => {
    openCreateNewModeratedStudyModal({
      onCreate: (id) => {
        navigate(ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId: id }))
        closeCreateNewModeratedStudyModal()
      },
    })
  }

  const isUserAllowedToCreateTests = true

  return (
    <Flex align="center" justify="center" h="full">
      <WrapItem>
        <Image src={emptyAllSrc} maxWidth={"304px"} p={3} marginEnd={9} />
      </WrapItem>
      <Flex flexDirection="column" gap={6} color="text.primary">
        <Flex flexDirection="column" gap={3}>
          <Heading
            color="text.primary"
            fontWeight="semibold"
            fontSize={20}
            lineHeight={7}
          >
            {isUserAllowedToCreateTests
              ? "Create your first study"
              : "You have no tests"}
          </Heading>
          <Text
            color="text.primary"
            fontWeight="normal"
            fontSize="md"
            maxW="493px"
          >
            {isUserAllowedToCreateTests
              ? "Get the answers to your burning questions by creating your first test or setting up your first interview."
              : "Get the answers to your burning questions with tests or interviews."}
          </Text>
        </Flex>
        {isUserAllowedToCreateTests && (
          <HStack>
            <Button
              colorScheme="brand.primary"
              onClick={() => navigate(ROUTES.TESTS.NEW.path)}
            >
              Create a test
            </Button>
            <Text color="text.secondary">or</Text>
            <Button
              variant="outline"
              color="text.primary"
              onClick={openCreateModal}
            >
              Create an interview
            </Button>
          </HStack>
        )}
      </Flex>
    </Flex>
  )
}
