import { Box } from "@chakra-ui/react"
import React from "react"
import { EmailPreviewSection } from "../EmailPreviewSection"

export const Emails: React.FC = () => (
  <Box
    id="automated_emails"
    bg="white"
    rounded="lg"
    p="8"
    borderColor="gray.200"
    borderWidth="thin"
  >
    <EmailPreviewSection />
  </Box>
)
