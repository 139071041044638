import { FormControl, FormLabel, Select } from "@chakra-ui/react"
import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { useSectionIndexContext } from "UsabilityHub/contexts"

const WrappedInstructionsModalStartPositionSelect: React.FC<
  React.PropsWithChildren<WrappedFieldProps>
> = ({ input }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <FormControl id={input.name} w="48">
      <FormLabel fontSize="sm">Position</FormLabel>
      <Select
        size="md"
        w="4xs"
        isDisabled={readOnly}
        {...input}
        focusBorderColor="brand.primary.500"
      >
        <option value="bottom_right">Bottom right</option>
        <option value="bottom_left">Bottom left</option>
        <option value="top_left">Top left</option>
        <option value="top_right">Top right</option>
      </Select>
    </FormControl>
  )
}

export const InstructionsModalStartPositionSelect: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const sectionIndex = useSectionIndexContext()
  const sectionPath = `sections[${sectionIndex}]`
  return (
    <Field
      name={`${sectionPath}.instructions_modal_start_position`}
      component={WrappedInstructionsModalStartPositionSelect}
    />
  )
}
