import { Box, Heading, Link, Stack, Text } from "@chakra-ui/react"
import { captureMessage } from "@sentry/browser"
import { usePlan } from "UsabilityHub/hooks/usePlans"
import { ConfirmResumeSubscriptionLink } from "UsabilityHub/views/settings/billing/ConfirmResumeSubscriptionLink"
import { centsToDollars, formatDollars } from "Utilities/currency"
import { getDateString } from "Utilities/date-formats"
import { pluralizeWithCount } from "Utilities/string"
import { differenceInCalendarDays } from "date-fns"
import React, { useEffect } from "react"
import { Plan, Subscription } from "~/api/generated/usabilityhubSchemas"
import SettingsApi from "~/api/settingsApi"
import { ConfirmBillingPeriodChangeLink } from "./ConfirmBillingPeriodChangeLink"
import { UpcomingInvoice } from "./Route"

type NextSubscriptionPayment = React.FC<
  React.PropsWithChildren<{
    activeStripeSubscription: Subscription
    plan: Plan
    upcomingInvoice: UpcomingInvoice | undefined
    refreshUpcomingInvoice: () => void
    isUpcomingInvoiceLoaded: boolean
  }>
>

type CouponDiscount = React.FC<
  React.PropsWithChildren<{
    coupon: NonNullable<NonNullable<UpcomingInvoice>["discount"]>["coupon"]
  }>
>

const CouponDiscount: CouponDiscount = ({ coupon }) => {
  if (coupon.amount_off) {
    return <>{centsToDollars(coupon.amount_off)}</>
  } else if (coupon.percent_off) {
    return <>{coupon.percent_off}%</>
  } else {
    throw new TypeError("Stripe coupon has no amount_off and no percent_off")
  }
}

export const NextSubscriptionPayment: NextSubscriptionPayment = ({
  activeStripeSubscription,
  plan,
  upcomingInvoice,
  refreshUpcomingInvoice,
  isUpcomingInvoiceLoaded,
}) => {
  // If the plan changes, we need to re-trigger an invoice fetch.
  useEffect(() => {
    refreshUpcomingInvoice()
  }, [plan])

  const subscriptionCanceledAtPeriodEnd =
    activeStripeSubscription.cancel_at_period_end
  const renewalDate = subscriptionCanceledAtPeriodEnd
    ? new Date(activeStripeSubscription.current_period_end!)
    : new Date(upcomingInvoice ? upcomingInvoice.period_end * 1000 : 0) // I don't think this fallback can actually occur
  const daysUntilExpiry = differenceInCalendarDays(renewalDate, new Date())

  const alternatePlan = usePlan(plan.alternate_plan_unique_id ?? "")

  let row
  let link
  if (subscriptionCanceledAtPeriodEnd) {
    row = (
      <Text>
        Your subscription has been set to cancel, and expires on{" "}
        {getDateString(renewalDate)} (in{" "}
        {pluralizeWithCount(daysUntilExpiry, "day", "days")}).
      </Text>
    )

    if (!plan.discontinued) {
      link = (
        <ConfirmResumeSubscriptionLink
          currentPlan={plan}
          subscriptionId={activeStripeSubscription.guid}
          subscriptionRenewalTime={getDateString(renewalDate)}
        />
      )
    }
  }
  // If an upcoming invoice exists or we're still loading it, show the due date or loading state.
  else if (upcomingInvoice || !isUpcomingInvoiceLoaded) {
    row = upcomingInvoice ? (
      <Text>
        {formatDollars(centsToDollars(upcomingInvoice.amount_due))}{" "}
        {upcomingInvoice.discount && upcomingInvoice.discount.coupon && (
          <>
            (including <b>{upcomingInvoice.discount.coupon.id}</b> coupon
            discount of{" "}
            <CouponDiscount coupon={upcomingInvoice.discount.coupon} />)
          </>
        )}{" "}
        on {getDateString(renewalDate)} (
        <Link
          href={SettingsApi.stripeInvoice.path({ id: "upcoming" })}
          isExternal
        >
          view upcoming invoice
        </Link>
        )
      </Text>
    ) : (
      <Text>Fetching upcoming invoice&hellip;</Text>
    )

    link = alternatePlan && !plan.discontinued && (
      <ConfirmBillingPeriodChangeLink
        currentPlan={plan}
        newPlan={alternatePlan}
        subscriptionId={activeStripeSubscription.guid}
      />
    )
  }
  // Otherwise, we don't have a cancelled subscription OR an upcoming invoice. Log an error.
  else {
    // This shouldn't really happen
    captureMessage(
      "Stripe subscription in unexpected state: not set to cancel at period end, but no upcoming invoice",
      { extra: { subscriptionId: activeStripeSubscription.stripe_id } }
    )
  }

  return (
    <Stack>
      <Heading>Next subscription payment</Heading>
      {row}
      <Box>{link}</Box>
    </Stack>
  )
}
