import { NumericDictionary, groupBy, intersectionBy, meanBy } from "lodash"
import React from "react"

import { useFilteredClicks } from "Components/test-results/hooks/use-filtered-clicks"
import { useFilteredResponseSections } from "Components/test-results/hooks/use-filtered-response-sections"
import { QuestionResultsList } from "Components/test-results/question-results/question-results-list"
import { NavigationTestResultsStepSummary } from "Components/test-results/section-results/navigation-test-results-step-summary"
import { NavigationTestResultsSummary } from "Components/test-results/section-results/navigation-test-results-summary"
import {
  SummaryItem,
  SummaryList,
} from "Components/test-results/section-results/section-screenshot-summary-list"
import {
  NavigationStepSummary,
  NavigationTestSummary,
  ResponseSection,
  ScreenshotClick,
  UsabilityTestSectionScreenshot as SectionScreenshot,
  UsabilityTestSection,
} from "Types"
import { SectionResultsSectionText } from "UsabilityHub/components/TestResults/SectionResultsLabels"
import {
  useDeviceFramesForSectionContext,
  useSectionContext,
} from "UsabilityHub/contexts"

import { SectionResultsCard } from "./SectionResultsCard"

function computeNavigationTestSummary(
  usabilityTestSection: UsabilityTestSection,
  responseSections: ReadonlyArray<ResponseSection>,
  screenshotClicksBySectionScreenshotId: NumericDictionary<
    ReadonlyArray<ScreenshotClick>
  >
): NavigationTestSummary {
  const totalCount = responseSections.length
  const sectionScreenshots: ReadonlyArray<Readonly<SectionScreenshot>> =
    usabilityTestSection.section_screenshots

  // This variable keeps track of the last set of hit clicks seen as we step
  // through the sections.
  let successClicks: ReadonlyArray<ScreenshotClick> = []

  // Calculate step specific data
  const stepSummaries = sectionScreenshots.map(
    (sectionScreenshot): NavigationStepSummary => {
      const stepClicks =
        screenshotClicksBySectionScreenshotId[sectionScreenshot.id] || []
      const hitClicks = stepClicks.filter(
        (screenshotClick) => screenshotClick.hit
      )

      // Store last seen hits.
      successClicks = hitClicks

      // Calculate rates
      const participationRate =
        totalCount > 0 ? stepClicks.length / totalCount : 0
      const hitRate =
        stepClicks.length > 0 ? hitClicks.length / stepClicks.length : 0
      const missRate = stepClicks.length > 0 ? 1 - hitRate : 0
      const averageDuration =
        stepClicks.length > 0 ? meanBy(stepClicks, (s) => s.duration_ms) : 0

      return {
        averageDuration,
        hitRate,
        missRate,
        participationRate,
      }
    }
  )

  // Calculate success rate/duration average
  const successCount = successClicks.length
  const successResponseSections = intersectionBy(
    responseSections,
    successClicks,
    "response_id"
  )
  const successRate = totalCount > 0 ? successCount / totalCount : 0
  const averageSuccessTaskDuration =
    successResponseSections.length > 0
      ? meanBy(successResponseSections, (s) => s.task_duration_ms)
      : 0

  return {
    averageSuccessTaskDuration,
    stepSummaries,
    successRate,
  }
}

export const NavigationSectionResultsCard: React.FC = () => {
  const { section } = useSectionContext()
  const responseSections =
    groupBy(useFilteredResponseSections(), "usability_test_section_id")[
      section.id
    ] || []
  const screenshotClicksBySectionScreenshotId = groupBy(
    useFilteredClicks(),
    (c) => c.usability_test_section_screenshot_id
  )

  const summary = computeNavigationTestSummary(
    section,
    responseSections,
    screenshotClicksBySectionScreenshotId
  )
  const { averageSuccessTaskDuration, stepSummaries, successRate } = summary
  const deviceFrame = useDeviceFramesForSectionContext(section)

  const stepSummaryNodes = section.section_screenshots.map(
    (sectionScreenshot, index) => {
      const summary = stepSummaries[index]
      const stepNumber = index + 1
      return (
        <SummaryItem key={sectionScreenshot.id}>
          <NavigationTestResultsStepSummary
            deviceFrame={deviceFrame}
            stepNumber={stepNumber}
            sectionScreenshot={sectionScreenshot}
            stepSummary={summary}
          />
        </SummaryItem>
      )
    }
  )

  return (
    <SectionResultsCard>
      <SectionResultsSectionText />
      <SummaryList>{stepSummaryNodes}</SummaryList>
      <NavigationTestResultsSummary
        successRate={successRate}
        averageSuccessTaskDuration={averageSuccessTaskDuration}
      />
      <QuestionResultsList />
    </SectionResultsCard>
  )
}
