import { ScreenshotMediaType } from "Types"

import {
  getMaximumFilesize,
  getMediaType,
  getMimeTypes,
  prettyFilesize,
  prettyMimeTypes,
} from "./media-types"

interface ValidateFilesOptions {
  allowedMediaTypes: ScreenshotMediaType[]
  maxFiles: number
}

export interface ValidateFilesResult {
  validFiles: [File, ScreenshotMediaType][]
  invalidFiles: File[]
  extraFiles: File[]
  errors: string[]
}

function createEmptyResult(): ValidateFilesResult {
  return {
    validFiles: [],
    invalidFiles: [],
    extraFiles: [],
    errors: [],
  }
}

export default function validateFiles(
  files: FileList | ReadonlyArray<File>,
  options: Readonly<ValidateFilesOptions>
): ValidateFilesResult {
  const { allowedMediaTypes, maxFiles } = options

  if (files instanceof FileList) {
    files = Array.from(files)
  }

  return files.reduce((result, file) => {
    if (result.validFiles.length >= maxFiles) {
      result.extraFiles.push(file)
    } else {
      // If mime type or media type not allowed
      const mediaType = getMediaType(file.type)
      if (mediaType == null || !allowedMediaTypes.includes(mediaType)) {
        result.invalidFiles.push(file)
        const allowedMimeTypes = allowedMediaTypes.flatMap(getMimeTypes)
        result.errors.push(
          `${file.name} format must be one of: ${prettyMimeTypes(
            allowedMimeTypes
          )}`
        )
        return result
      }

      // If max filesize for mediaType is too large
      const maximumSizeInBytes = getMaximumFilesize(mediaType)
      if (file.size >= maximumSizeInBytes) {
        result.invalidFiles.push(file)
        result.errors.push(
          `${file.name} must be less than ${String(
            prettyFilesize(maximumSizeInBytes)
          )}`
        )
        return result
      }

      result.validFiles.push([file, mediaType])
    }
    return result
  }, createEmptyResult())
}
