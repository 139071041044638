import { Box, BoxProps, Flex, Text } from "@chakra-ui/react"
import { useTranslate } from "Shared/hooks/useTranslate"
import { captureUserMedias } from "UsabilityHub/components/UsabilityTest/useTestTakingRecordings"
import React, { useEffect, useRef, useState } from "react"

type CameraPreviewProps = BoxProps & {
  deviceId: string | undefined
}

const drawCameraFrame = (
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement,
  width: number,
  height: number
) => {
  if (!width || !height) return
  const ctx = canvas.getContext("2d")
  if (!ctx) return

  const canvasWidth = canvas.width
  const canvasHeight = canvas.height
  const scale = Math.min(width / canvasWidth, height / canvasHeight)
  const w = canvasWidth * scale
  const h = canvasHeight * scale

  ctx.drawImage(
    video, // source image
    (width - w) / 2, // left of cropped camera frame
    (height - h) / 2, // top of cropped camera frame
    w, // width of cropped camera frame
    h, // height of cropped camera frame
    0, // left of canvas
    0, // top of canvas
    canvasWidth,
    canvasHeight
  )
}

const CameraPreview: React.FC<CameraPreviewProps> = ({
  deviceId,
  aspectRatio = "1",
  ...props
}) => {
  const translate = useTranslate()
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)

  useEffect(() => {
    if (!canvas) return

    const resizeObserver = new ResizeObserver(() => {
      // Double resolution to improve quality
      canvas.setAttribute("width", `${canvas.clientWidth * 2}`)
      canvas.setAttribute("height", `${canvas.clientHeight * 2}`)
    })

    resizeObserver.observe(canvas)

    return () => resizeObserver.disconnect()
  }, [canvas])

  const animation = useRef<number | null>(null)

  useEffect(() => {
    if (!deviceId || !canvas) return
    let videoStream: MediaStream | null = null

    const video = document.createElement("video")

    const constraints = {
      video: { deviceId: { exact: deviceId } },
      audio: false,
    }

    const success = (stream: MediaStream) => {
      videoStream = stream
      video.srcObject = stream

      video.onloadedmetadata = () => {
        const { width, height } = stream.getVideoTracks()[0].getSettings()
        if (width && height) {
          video.width = width
          video.height = height
        }
        video.play()

        const draw = () => {
          if (canvas.offsetWidth) {
            drawCameraFrame(video, canvas, width ?? 0, height ?? 0)
          }
          animation.current = requestAnimationFrame(draw)
        }

        draw()
      }
    }

    captureUserMedias(constraints, success)

    return () => {
      if (animation.current) cancelAnimationFrame(animation.current)
      videoStream?.getTracks().forEach((track) => track.stop())
    }
  }, [deviceId, canvas])

  return (
    <Flex
      w="full"
      maxW={320}
      pos="relative"
      aspectRatio={aspectRatio}
      backgroundColor="ds.background.neutral.resting"
      borderRadius={8}
      direction="column"
      justify="center"
      align="center"
      color="ds.text.subtlest"
      gap={4}
      overflow="hidden"
      mr="auto"
      {...props}
    >
      {deviceId ? (
        <Box
          as="canvas"
          ref={setCanvas}
          pos="absolute"
          w="100%"
          h="100%"
          inset={0}
          background="black"
          transform="scaleX(-1)"
        />
      ) : (
        <>
          <Box as="svg" width={8} height={8} viewBox="0 0 30 30">
            <path
              fill="currentColor"
              d="M27.714 22.056a2 2 0 0 0 1.474-.7c.372-.436.432-.96.454-1.215.025-.285.025-.64.025-1v-8.283c0-.36 0-.714-.025-1-.022-.254-.082-.779-.454-1.215a2 2 0 0 0-1.678-.694c-.571.045-.984.373-1.18.538-.22.183-.47.434-.724.689L23 11.78c0-.97-.005-1.781-.06-2.45-.06-.75-.192-1.439-.522-2.086a5.334 5.334 0 0 0-2.33-2.33c-.647-.33-1.336-.462-2.086-.523-.721-.059-1.607-.059-2.68-.059H9.99l17.723 17.723ZM2.61.724A1.333 1.333 0 0 0 .725 2.609l2.38 2.38a5.333 5.333 0 0 0-2.19 2.256c-.33.647-.46 1.336-.522 2.086-.059.721-.059 1.607-.059 2.68v5.977c0 1.073 0 1.96.059 2.68.061.75.193 1.44.522 2.086a5.333 5.333 0 0 0 2.331 2.331c.647.33 1.336.461 2.086.522.721.06 1.607.06 2.68.06h7.31c1.074 0 1.96 0 2.681-.06.75-.06 1.439-.192 2.086-.522a5.332 5.332 0 0 0 1.72-1.392l5.582 5.583a1.333 1.333 0 1 0 1.886-1.886L2.61.724Z"
            />
          </Box>
          <Text align="center" textStyle="ds.interface.small" m={2}>
            {translate("test.recording.camera.preview_not_available")}
          </Text>
        </>
      )}
    </Flex>
  )
}

export default CameraPreview
