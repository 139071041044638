import { Link } from "@chakra-ui/react"
import React from "react"

import Constants from "Constants/shared.json"

type PurchasesMailtoLink = React.FC<React.PropsWithChildren<unknown>>

export const PurchasesMailtoLink: PurchasesMailtoLink = () => {
  return (
    <Link href={`mailto:${Constants.PURCHASES_EMAIL_ADDRESS}`}>
      {Constants.PURCHASES_EMAIL_ADDRESS}
    </Link>
  )
}
