import { Box, BoxProps } from "@chakra-ui/react"
import React from "react"
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd"

import { DragHandle } from "Components/drag-handle/drag-handle"

interface DraggableQuestionRowProps extends BoxProps {
  draggableProvided: DraggableProvided
  snapshot: DraggableStateSnapshot
}

export const DraggableQuestionRow: React.FC<
  React.PropsWithChildren<DraggableQuestionRowProps>
> = ({ children, draggableProvided, snapshot, ...boxProps }) => {
  return (
    <Box
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      bg="white"
      borderWidth={snapshot.isDragging ? 1 : boxProps.width}
      boxShadow={snapshot.isDragging ? "sm" : ""}
      borderRadius={snapshot.isDragging ? "sm" : ""}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

interface DraggableRowHandle {
  draggableProvided: DraggableProvided
  isDragDisabled?: boolean
}

export const DraggableRowHandle: React.FC<
  React.PropsWithChildren<DraggableRowHandle>
> = ({ draggableProvided, isDragDisabled = false }) => {
  return (
    <Box
      w={8} // Fixed square width to give a good grab area
      h={8} // ^
      mx={-2} // Negative margin for icon alignment
      px={2}
      color="gray.500"
      flexShrink={0}
      tabIndex={-1} // Skip when tabbing through the question/option input fields
      {...draggableProvided.dragHandleProps}
    >
      <DragHandle isDisabled={isDragDisabled} />
    </Box>
  )
}
