import React from "react"

import { UsabilityTestSection } from "Types"
import { ScreenshotMask } from "UsabilityHub/components/UsabilityTestSectionTask/ScreenshotMask"
import { SectionTaskContent } from "UsabilityHub/components/UsabilityTestSectionTask/SectionTask"

import { usePrototypeTaskContext } from "./PrototypeTaskContext"
import { PrototypeTaskFigmaFlow } from "./PrototypeTaskFigmaFlow"

interface PrototypeTaskContentProps {
  section: UsabilityTestSection
}

export const PrototypeTaskContent: React.FC<
  React.PropsWithChildren<PrototypeTaskContentProps>
> = ({ section }) => {
  const prototypeTask = usePrototypeTaskContext()
  const figmaFlow = section.figma_file_flow
  if (!figmaFlow) return null
  return (
    <SectionTaskContent position="relative">
      <PrototypeTaskFigmaFlow figmaFlow={figmaFlow} />
      {!prototypeTask.isTaskComplete && !prototypeTask.isTaskStarted && (
        <ScreenshotMask />
      )}
    </SectionTaskContent>
  )
}
