import { TreeTestFilter } from "../filters/tree-test"

import { generateFilterId } from "./generate-filter-id"

function createTreeTestFilterCategory(
  sectionId: number
): TreeTestFilter["category"] {
  return `answer/section:${sectionId}/tree-test`
}

export function createTreeTestFilter(
  sectionId: number,
  finalNodeId: string | null
): TreeTestFilter {
  return {
    id: generateFilterId(),
    category: createTreeTestFilterCategory(sectionId),
    type: "answer/tree-test",
    value: { finalNodeId },
  }
}
