import { Button } from "@chakra-ui/react"
import { shuffle } from "lodash"
import React, { useState } from "react"

import { ThemedButton } from "Components/button/themed-button"
import { DisplayInlineMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { useTranslate } from "Shared/hooks/useTranslate"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"
import { reportErrorToSentry } from "Utilities/error"
import {
  isTaskComplete,
  isTaskStarted,
  sectionStartTime,
} from "Utilities/response"

import {
  SectionTask,
  SectionTaskHeader,
  SectionTaskInstructions,
} from "../../SectionTask"

import {
  CarouselSectionTaskContent,
  OverviewSectionTaskContent,
} from "./PreferenceTestSectionTaskContents"
import { useCarousel } from "./useCarousel"

export const PreferenceTestTask: React.FC<
  React.PropsWithChildren<InnerProps>
> = ({
  usabilityTestSection,
  responseSection,
  updateResponseSection,
  deviceFrame,
}) => {
  const translate = useTranslate()
  const [taskStartTime, setTaskStartTime] = useState<number | null>(null)
  const [sortedOptions] = useState(() =>
    usabilityTestSection.randomized
      ? shuffle(usabilityTestSection.section_screenshots)
      : usabilityTestSection.section_screenshots
  )
  const {
    selectedIndex,
    isSelected,
    handleNext,
    handlePrevious,
    handleViewAll,
    handleSelectOption,
  } = useCarousel(sortedOptions.length)

  const isComplete = isTaskComplete(responseSection)
  const isStarted = isTaskStarted(responseSection)

  const handleConfirmSelection = () => {
    if (taskStartTime === null) {
      reportErrorToSentry(new Error("preference selected before task started"))
      return
    }

    updateResponseSection(usabilityTestSection.id, {
      selected_usability_test_section_screenshot_id:
        sortedOptions[selectedIndex].id,
      task_duration_ms: performance.now() - taskStartTime,
    })
  }

  const handleStart = () => {
    const taskStartTime = performance.now()
    setTaskStartTime(taskStartTime)
    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms:
        taskStartTime - sectionStartTime(responseSection),
    })
  }

  const showPreferenceTestInstructions = !isComplete && !isSelected && isStarted
  const showBackButton = isSelected && !isComplete

  return (
    <SectionTask>
      <SectionTaskHeader>
        <SectionTaskInstructions variant={isComplete ? "secondary" : "primary"}>
          <DisplayInlineMarkdownText text={usabilityTestSection.text} />
        </SectionTaskInstructions>
        {showPreferenceTestInstructions && (
          <SectionTaskInstructions variant="secondary">
            {translate("test.instructions.preference_test.review_option")}
          </SectionTaskInstructions>
        )}
        {showBackButton && (
          <Button
            colorScheme="brand.primary"
            variant="link"
            onClick={handleViewAll}
          >
            {"<-"} {translate("test.buttons.return_to_overview")}
          </Button>
        )}
        {!isStarted && (
          <ThemedButton onClick={handleStart}>
            {translate("test.buttons.click_to_view_options")}
          </ThemedButton>
        )}
      </SectionTaskHeader>
      {isSelected ? (
        <CarouselSectionTaskContent
          isReadonly={isComplete}
          deviceFrame={deviceFrame}
          selectedIndex={selectedIndex}
          sectionScreenshot={sortedOptions[selectedIndex]}
          optionCount={sortedOptions.length}
          onConfirm={handleConfirmSelection}
          onNext={handleNext}
          onPrevious={handlePrevious}
          section={usabilityTestSection}
        />
      ) : (
        <OverviewSectionTaskContent
          sectionScreenshots={sortedOptions}
          onSelectOption={handleSelectOption}
          onStartTask={handleStart}
          isTaskStarted={isStarted}
        />
      )}
    </SectionTask>
  )
}
