import {
  CardSortCategory,
  CardSortCategoryGroup,
  CardSortOpenCategory,
} from "Types"

type RequiredProperties<T, P extends keyof T> = Omit<T, P> &
  Required<Pick<T, P>>

// At this point since they are persisted in the database we _know_ they have valid ids
export type EitherCategory = RequiredProperties<
  CardSortCategory | CardSortOpenCategory,
  "id"
>

export const categoryHasId = (
  category: CardSortCategory | CardSortOpenCategory
): category is EitherCategory => {
  return category.id !== null && category.id !== undefined
}

export const notUnsortedGroupLabel = (groupLabel: string): boolean => {
  return groupLabel !== "{label_0}Unsorted"
}

export const isItalicCategoryGroupNames = (
  groupLabel: string,
  categories: EitherCategory[]
): boolean => {
  // The words we're showing isn't literally what the participant entered,
  // but is our description of it(e.g.Untitled, Unsorted, No answer given),
  // which should be shown in italics.
  return (
    !notUnsortedGroupLabel(groupLabel) ||
    (/^{group_[0-9]+}Untitled\s#[0-9]+$/.test(groupLabel) &&
      categories.length === 1 &&
      categories[0].label === "") ||
    groupLabel === "Ungrouped"
  )
}

export const isItalicCategory = (c: EitherCategory) => {
  return categoryIsOpen(c) && c.label === ""
}

export const categoryIsOpen = (
  category: EitherCategory
): category is CardSortOpenCategory => {
  return "card_sort_category_group_id" in category
}

export const getLabel = (
  c: EitherCategory,
  groups: CardSortCategoryGroup[]
) => {
  if (!categoryIsOpen(c)) return c.label
  if (!c.card_sort_category_group_id) return c.label

  const group = groups.find(
    (g) => g.id === Number(c.card_sort_category_group_id)
  )

  return group && group.label
    ? group.label
    : c.label
      ? c.label
      : `Untitled #${c.ranking ?? "?"}`
}

export const getCategoryLabel = (c: EitherCategory) => {
  if (!categoryIsOpen(c)) return c.label

  return c.label ? c.label : `Untitled #${c.ranking ?? "?"}`
}

export const humanizeGroupLabel = (label: string) => {
  return label.substring(label.indexOf("}") + 1)
}

export const extractGroupId = (label: string) => {
  return Number(label.replace(/^\{group_(.+?)\}.*/, "$1"))
}

const groupPrefix = (c: EitherCategory) =>
  categoryIsOpen(c) && c.card_sort_category_group_id
    ? `{group_${c.card_sort_category_group_id}}`
    : `{label_${Number(c.id)}}`

export const groupCategories = (
  categories: EitherCategory[],
  allGroups: CardSortCategoryGroup[]
) =>
  categories.reduce(
    (groups, c) => {
      let group_label = getLabel(c, allGroups)
      group_label = groupPrefix(c) + group_label

      groups[group_label] = groups[group_label] ?? []
      groups[group_label].push(c)

      return groups
    },
    {} as Record<string, EitherCategory[]>
  )
