import {
  Box,
  Heading,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react"
import React from "react"

import Constants from "Constants/shared.json"
import { onClickAddOrttoCookieParam } from "JavaScripts/analytics/ortto"
import { Card, CardBody } from "Shared/components/Card/Card"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { formatPercentage01 } from "Utilities/number"
import { useListCreditBatches } from "~/api/generated/usabilityhub-components"

type FAQSection = React.FC<React.PropsWithChildren<{ title: string }>>

const FAQSection: FAQSection = ({ title, children }) => (
  <Box fontSize="sm">
    <Heading as="h3" size="sm" mb={2}>
      {title}
    </Heading>
    {children}
  </Box>
)

type CreditPriceRange = {
  from: number
  to: number
  discount: number
}

type DiscountTiers = React.FC<{
  baseCreditPrice: number
}>

const DiscountTierSkeletonRow: React.FC = () => {
  return (
    <Tr>
      <Td pl={0} pr="5%" width="65%">
        <Skeleton height="15px" />
      </Td>
      <Td px={0} width="30%">
        <Skeleton height="15px" />
      </Td>
    </Tr>
  )
}

const DiscountTiers: DiscountTiers = ({ baseCreditPrice }) => {
  const { data: creditBatches } = useListCreditBatches({})

  if (!creditBatches) {
    return (
      <>
        <DiscountTierSkeletonRow />
        <DiscountTierSkeletonRow />
        <DiscountTierSkeletonRow />
      </>
    )
  }

  const orderedBatches = creditBatches.batches.sort(
    (a, b) => a.amount - b.amount
  )
  const ranges: CreditPriceRange[] = []
  for (let i = 0; i < orderedBatches.length; i++) {
    ranges.push({
      from: orderedBatches[i].amount,
      to: (orderedBatches[i + 1]?.amount || Infinity) - 1,
      discount:
        1 -
        orderedBatches[i].price / orderedBatches[i].amount / baseCreditPrice,
    })
  }

  return ranges
    .filter((r) => r.discount > 0)
    .map((range) => (
      <Tr key={range.from}>
        <Td px={0}>
          {Number.isFinite(range.to)
            ? `${range.from} - ${range.to}`
            : `${range.from}+`}{" "}
          credits
        </Td>
        <Td textAlign="right">{formatPercentage01(range.discount)} off</Td>
      </Tr>
    ))
}

type FAQs = React.FC<{
  baseCreditPrice: number
}>
export const FAQs: FAQs = ({ baseCreditPrice }) => {
  return (
    <Card>
      <CardBody>
        <Stack spacing={5}>
          <FAQSection title="How much do credits cost?">
            <Text>Credits cost $1 USD each.</Text>
            <Heading as="h4" size="xs" mt={3} mb={1}>
              Bulk discounts
            </Heading>
            <Table variant="simple" size="sm">
              <Tbody>
                <DiscountTiers baseCreditPrice={baseCreditPrice} />
              </Tbody>
            </Table>
          </FAQSection>
          <FAQSection title="How many credits will I need?">
            <Text>
              The cost of a response provided by Lyssna depends on the length
              and complexity of your test, starting at 1 credit per response for
              a short test.
            </Text>
          </FAQSection>
          <FAQSection title="Do credits expire?">
            <Text>
              No. Credits purchased on this page have no expiration date.
            </Text>
          </FAQSection>
          <FAQSection title="Can I spend credits on multiple tests?">
            <Text>
              You can use credits across any number of tests, and can order any
              number of responses on each test.
            </Text>
          </FAQSection>
          <FAQSection title="Can you send me an invoice?">
            <Text>
              We{"\u2019"}re happy to help with invoicing for any purchase over
              $2,000 USD. Email us at{" "}
              <ExternalLink
                href={`mailto:${Constants.PURCHASES_EMAIL_ADDRESS}?subject=Manual invoice request`}
              >
                {Constants.PURCHASES_EMAIL_ADDRESS}
              </ExternalLink>{" "}
              to kick off the process.
            </Text>
          </FAQSection>
          <FAQSection title="Can I get help with procurement or vendor registration requirements?">
            <Text>
              Absolutely!{" "}
              <ExternalLink
                href={Constants.BOOK_MEETING_WITH_SALES_URL}
                onClick={onClickAddOrttoCookieParam}
              >
                Book a meeting
              </ExternalLink>{" "}
              with our Sales team.
            </Text>
          </FAQSection>
        </Stack>
      </CardBody>
    </Card>
  )
}
