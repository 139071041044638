import { useCallback, useState } from "react"

import { integrationConnectedGoogle } from "JavaScripts/analytics/google"
import { useOAuth } from "./useOAuth"

export const useGoogleOAuth = (
  initialState = false,
  onSuccess: () => void = () => {}
) => {
  const [hasGoogleOAuthCredentials, setHasGoogleOAuthCredentials] =
    useState(initialState)

  const disconnectGoogleOAuth = useCallback(
    () => setHasGoogleOAuthCredentials(false),
    []
  )
  const { handleWithAuth: handleGoogleOAuth } = useOAuth(
    () => {
      setHasGoogleOAuthCredentials(true)
      onSuccess()
      integrationConnectedGoogle("Google calendar")
    },
    "Google",
    "/google_oauth2/redirect",
    hasGoogleOAuthCredentials
  )

  return { handleGoogleOAuth, disconnectGoogleOAuth, hasGoogleOAuthCredentials }
}
