import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react"
import { LyssnaLogoCompositeIcon } from "Shared/components/CompositeIcons/CompositeIcons"
import { Link03OutlineIcon } from "Shared/icons/untitled-ui/Link03OutlineIcon"
import { Send01SolidIcon } from "Shared/icons/untitled-ui/Send01SolidIcon"
import { pluralize } from "Utilities/string"
import React from "react"
import { ModeratedStudyApplication } from "./types"

type Props = {
  source: ModeratedStudyApplication["source"]
  applicantsSelectedCount: number
  sessionsAvailableCount: number
  handleSubmit: () => void
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">

export const InviteApplicantsModal: React.FC<Props> = ({
  source,
  applicantsSelectedCount,
  sessionsAvailableCount,
  handleSubmit,
  isOpen,
  onClose,
}) => {
  const selectedApplicantsExceedSessions = sessionsAvailableCount
    ? applicantsSelectedCount > sessionsAvailableCount
    : undefined
  const pluralizedInvitations = pluralize(applicantsSelectedCount, "invitation")
  const pluralizedApplicants = pluralize(applicantsSelectedCount, "applicant")
  const pluralizedSessionsAvailableApplicants = pluralize(
    sessionsAvailableCount,
    "applicant"
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent color="text.primary" maxW="30rem">
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">
          Invite {pluralizedApplicants}
        </ModalHeader>
        <ModalBody pb={4}>
          <Flex justify="flex-start" gap={2}>
            <Flex>
              {source.type === "order" ? (
                <LyssnaLogoCompositeIcon size={6} />
              ) : (
                <Link03OutlineIcon boxSize={5} transform="translateY(2px)" />
              )}
            </Flex>
            <Flex grow={1} flexDirection="column" gap={4} mt="3px">
              <Flex flexDirection="column" gap={2}>
                <Heading fontSize="md" fontWeight="semibold">
                  {source.type === "order" ? "Lyssna panel" : source.name}
                </Heading>
                {source.type === "order" && <Text>{source.name}</Text>}
              </Flex>
              <Flex gap={4} flexDirection="column">
                <Grid templateColumns="1fr 3rem" gap={4}>
                  <GridItem>Applicants selected</GridItem>
                  <GridItem textAlign="end">{applicantsSelectedCount}</GridItem>
                  <GridItem>
                    Sessions available
                    {source.type === "recruitment_link" &&
                      " (from your network)"}
                  </GridItem>
                  <GridItem textAlign="end">{sessionsAvailableCount}</GridItem>
                </Grid>
              </Flex>
            </Flex>
          </Flex>
          <Divider my={3} />
          <Flex flexDirection="column" gap={6}>
            <Text>
              Once confirmed, an invitation will be sent to the selected{" "}
              {pluralizedApplicants} to schedule a session with you.
            </Text>
            {sessionsAvailableCount === 0 ? (
              <Text>
                <strong>Important:</strong> As there are no remaining sessions
                available, you cannot invite more applicants to this study.
              </Text>
            ) : selectedApplicantsExceedSessions ? (
              <Alert status="info">
                <AlertIcon />
                <AlertDescription>
                  As the number of sessions available is less than the number of
                  applicants invited, only {sessionsAvailableCount}{" "}
                  {pluralizedSessionsAvailableApplicants} will be able to book a
                  session with you.
                </AlertDescription>
              </Alert>
            ) : null}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            leftIcon={<Send01SolidIcon />}
            colorScheme="teal"
            onClick={handleSubmit}
            isDisabled={sessionsAvailableCount === 0}
          >
            Send {applicantsSelectedCount} {pluralizedInvitations}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
