import { Link } from "@chakra-ui/react"
import { Alert } from "DesignSystem/components"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import { pluralizeWithCount } from "Utilities/string"
import React from "react"
import { ExportResultsForm } from "./ExportResultsForm"

type Props = {
  allResponsesCount: number
  responsesLimitCount: number
  accountCanExportCsv: boolean
  usabilityTestUniqueId: string
  usabilityTestPrivateId: string
}

const CSV_EXPORTS_FEATURE = { feature: "csv_exports" } as const

export const CSVExportSuggestionAlert: React.FC<Props> = ({
  allResponsesCount,
  responsesLimitCount,
  accountCanExportCsv,
  usabilityTestUniqueId,
  usabilityTestPrivateId,
}) => {
  const csvAlertBaseDescription = `You\u2019ve collected
  ${pluralizeWithCount(allResponsesCount, "response", "responses")} for this
  test, but we\u2019re only able to show the first ${responsesLimitCount}. You
  might be able to spot some patterns here but we suggest you download the full
  export as CSV to conduct a full analysis.`

  return accountCanExportCsv ? (
    <ExportResultsForm
      usabilityTestUniqueId={usabilityTestUniqueId}
      usabilityTestPrivateId={usabilityTestPrivateId}
    >
      <CsvExportEnabledContent
        csvAlertBaseDescription={csvAlertBaseDescription}
        allResponsesCount={allResponsesCount}
      />
    </ExportResultsForm>
  ) : (
    <CsvExportDisabledContent
      csvAlertBaseDescription={csvAlertBaseDescription}
    />
  )
}

const CsvExportEnabledContent: React.FC<{
  csvAlertBaseDescription: string
  allResponsesCount: number
}> = ({ csvAlertBaseDescription, allResponsesCount }) => (
  <Alert
    status="warning"
    alignItems="flex-start"
    title={`We can\u2019t display all the results for this test`}
    description={csvAlertBaseDescription}
    actions={[
      {
        label: `Export ${allResponsesCount === 1 ? "1 result" : `all ${allResponsesCount} results`} as CSV`,
        type: "submit",
      },
    ]}
  />
)

const CsvExportDisabledContent: React.FC<{
  csvAlertBaseDescription: string
}> = ({ csvAlertBaseDescription }) => (
  <Alert
    status="warning"
    alignItems="flex-start"
    title={`We can\u2019t display all the results for this test`}
    description={
      <>
        {csvAlertBaseDescription}{" "}
        {`${csvAlertBaseDescription} CSV exports are available on `}
        <PlanDrawerTrigger
          as={Link}
          variant="unstyled"
          verticalAlign="inherit"
          userSelect="initial"
          source="test_results_response_limit_banner"
          requestedFeatures={[CSV_EXPORTS_FEATURE]}
        >
          paid plans.
        </PlanDrawerTrigger>
      </>
    }
  />
)
