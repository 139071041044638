import { meanBy } from "lodash"
import { useMemo } from "react"
import { useSelector } from "react-redux"

import { getAllClicks } from "Redux/reducers/test-results/selectors"
import { Rectangle, Response, ScreenshotClick } from "Types"
import { rectangleContainsPoint } from "Utilities/rectangle"

import { useFilteredResponses } from "../hooks/use-filtered-responses"

const generateSummary = (
  responses: Response[],
  clicks: ScreenshotClick[],
  screenshotSectionId: number,
  { include, exclude }: ClickZoneFilterOptions
) => {
  const filteredResponseIds = new Set(responses.map((response) => response.id))
  const filteredClicks = clicks.filter((click) =>
    filteredResponseIds.has(click.response_id)
  )

  const screenshotSectionClicks = filteredClicks.filter(
    (click) =>
      click.usability_test_section_screenshot_id === screenshotSectionId
  )

  const selectedClicks = screenshotSectionClicks.filter((click) => {
    if (exclude) {
      const isExcluded = exclude.some((excludeRectangle) =>
        rectangleContainsPoint(excludeRectangle)(click)
      )

      if (isExcluded) return false
      else return true
    }

    if (include) {
      const isIncluded = include.some((excludeRectangle) =>
        rectangleContainsPoint(excludeRectangle)(click)
      )
      if (isIncluded) return true
      else return false
    }

    return true
  })

  return {
    averageDuration: meanBy(selectedClicks, "duration_ms"),
    chosenCount: selectedClicks.length,
    chosenPercentage:
      screenshotSectionClicks.length &&
      selectedClicks.length / screenshotSectionClicks.length,
  }
}

type ClickZoneFilterOptions = {
  include?: Rectangle[]
  exclude?: Rectangle[]
}

export const useSummaryStats = (
  screenshotSectionId: number,
  filterOptions: ClickZoneFilterOptions
) => {
  const filteredResponses = useFilteredResponses()
  const allClicks = useSelector(getAllClicks)

  return useMemo(
    () =>
      generateSummary(
        filteredResponses as Response[],
        allClicks as ScreenshotClick[],
        screenshotSectionId,
        filterOptions
      ),
    [filteredResponses, allClicks, screenshotSectionId, filterOptions]
  )
}
