import { BoxProps, Image, Stack, Text } from "@chakra-ui/react"
import React from "react"
import { SignupAnimation } from "./animations/SignupAnimation"
import Logos from "./customer-logos.png"
import G2 from "./g2.svg"

export const NewCustomerLogos: React.FC<BoxProps> = (props) => {
  return (
    <Stack justifyContent="center" alignItems="center" spacing={14} {...props}>
      <SignupAnimation />
      <Stack spacing={8} alignItems="center" maxW="36rem">
        <Text
          fontFamily="promo"
          fontWeight={500}
          fontSize="1.375rem"
          lineHeight="1.875rem"
          textAlign="center"
        >
          Join the 320,000+ users who use Lyssna to make data-driven decisions.
        </Text>
        <Image
          src={Logos}
          alt="Some of our customers include Google, GoDaddy, and Asana"
        />
      </Stack>
      <Image src={G2} alt="G2 rating: 4.5+" />
    </Stack>
  )
}
