import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import React from "react"

import companiesUsingUsImage from "./logos.png"
import { usePlanChangerContext } from "./plan-changer-context"
export const EnterpriseInfoCard: React.FC = () => {
  const { dispatch } = usePlanChangerContext()

  return (
    <Flex borderWidth={1} borderColor="gray.200" rounded="md" gap={4} p={6}>
      <Box>
        <Heading
          as="h2"
          color="text.primary"
          fontSize="xl"
          fontWeight="semibold"
        >
          Enterprise
        </Heading>

        <Text color="text.primary" fontWeight="normal" mt={1} mb={4}>
          Build organization-wide research capability for maximum impact.
          Include SAML SSO, Priority support, Custom password policy & more
        </Text>

        <Button
          colorScheme="brand.primary"
          fontWeight="semibold"
          onClick={() => dispatch({ type: "enterprise-contact" })}
        >
          Talk to us
        </Button>
      </Box>

      <Flex direction="column" gap={4}>
        <Image src={companiesUsingUsImage} />

        <Text
          color="text.secondary"
          fontSize="xs"
          fontWeight="normal"
          align="center"
        >
          Join over 320,000 marketers, researchers, designers and product
          managers
        </Text>
      </Flex>
    </Flex>
  )
}
