import classNames from "classnames"
import React, {
  Component,
  MouseEvent as ReactMouseEvent,
  ReactInstance,
} from "react"
import ReactDOM from "react-dom"
import { CSSTransition, TransitionGroup } from "react-transition-group"

import styles from "Components/hitzone-editor/hitzone-editor.module.scss"
import { EditableHitzone } from "Components/hitzone/editable-hitzone"
import { Theme } from "Components/hitzone/hitzone"
import { transitionClassNames } from "Components/hitzone/hitzone"
import { transitionTimeout } from "Components/hitzone/hitzone"
import { clamp01, lerp } from "Math/number"
import { ClientId, ClientIdRectangle, Corner, Point } from "Types"
import {
  getClientId,
  matchingClientId,
  nextClientId,
} from "Utilities/client-id"
import {
  MouseEventLike,
  getNormalizedMouseOffset,
  isLeftButton,
} from "Utilities/mouse-event"
import { swapPropertiesInPlace } from "Utilities/object"
import * as PointUtility from "Utilities/point"

export { Theme }

enum Action {
  MaybeTranslate = "MAYBE_TRANSLATE",
  Translate = "TRANSLATE",
  Scale = "SCALE",
}

interface Props {
  className?: string
  hitzones: ReadonlyArray<ClientIdRectangle>
  onCreateHitzone: (hitzone: Readonly<ClientIdRectangle>) => void
  onHitzoneChange: (hitzone: Readonly<ClientIdRectangle>) => void
  onRemoveHitzone: (clientId: ClientId) => void
  showDisplayNumbers?: boolean
  displayNumberOffset?: number
  filterClientIds: ReadonlyArray<ClientId>
  isFilteringByHitzones: boolean
  theme: Theme
}

interface ScalingState {
  currentAction: Action.Scale
  currentTranslationOffset: Readonly<Point>
  currentlySelectedClientId: ClientId
  currentlySelectedCorner: Readonly<Corner>
}

interface InactiveState {
  currentAction: null
  currentTranslationOffset: null
  currentlySelectedClientId: null
  currentlySelectedCorner: null
}

interface TranslateState {
  currentAction: Action.MaybeTranslate | Action.Translate
  currentlySelectedClientId: ClientId
  currentTranslationOffset: Readonly<Point>
  currentlySelectedCorner: null
}

type State = ScalingState | InactiveState | TranslateState

const INACTIVE_STATE: InactiveState = {
  currentAction: null,
  currentTranslationOffset: null,
  currentlySelectedClientId: null,
  currentlySelectedCorner: null,
}

const MIN_SELECTION_SIZE_PX = 10
const MIN_SELECTION_AREA_PX = MIN_SELECTION_SIZE_PX ** 2

function getHitzoneCornerPosition(
  { xa, ya, xb, yb }: ClientIdRectangle,
  corner: Readonly<Corner>
) {
  return {
    x: lerp(xa, xb, corner[0]),
    y: lerp(ya, yb, corner[1]),
  }
}

export class HitzoneEditor extends Component<Props, State> {
  // -- State --

  state: State = INACTIVE_STATE
  private containerElement: HTMLUListElement | null = null

  // -- Private interface --

  private findHitzone(clientId: ClientId): Readonly<ClientIdRectangle> | null {
    const { hitzones } = this.props
    const hitzone = hitzones.find(matchingClientId(clientId))
    return hitzone || null
  }

  private normalizeMousePosition(event: MouseEventLike): Point {
    if (this.containerElement === null) {
      throw new TypeError("containerElement === null")
    }
    return getNormalizedMouseOffset(event, this.containerElement)
  }

  private setPosition(topLeftPosition: Readonly<Point>): void {
    const { currentlySelectedClientId: id } = this.state
    if (id === null) {
      return
    }
    const hitzone = this.findHitzone(id)
    if (hitzone === null) {
      return
    }
    const width = hitzone.xb - hitzone.xa
    const height = hitzone.yb - hitzone.ya
    const clamped = PointUtility.clamp(topLeftPosition, PointUtility.ZERO, {
      x: 1 - width,
      y: 1 - height,
    })
    this.props.onHitzoneChange({
      ...hitzone,
      xa: clamped.x,
      ya: clamped.y,
      xb: clamped.x + width,
      yb: clamped.y + height,
    })
  }

  private setCornerPosition(position: Readonly<Point>): void {
    const { currentlySelectedClientId: id, currentlySelectedCorner: corner } =
      this.state

    if (id === null || corner === null) {
      return
    }

    // Set the correct corner position (without overflowing the [0,1] range).
    const hitzone = this.findHitzone(id)
    if (hitzone === null) {
      return
    }
    const nextHitzone: ClientIdRectangle = { ...hitzone }
    nextHitzone[corner[0] === 0 ? "xa" : "xb"] = clamp01(position.x)
    nextHitzone[corner[1] === 0 ? "ya" : "yb"] = clamp01(position.y)

    // Hitzones must specify corners in order. So if they have crossed over as
    // a result of the above, swap the corners around. Change the selected
    // corner as well so that you can drag a corner "through" the selection.
    if (nextHitzone.xa > nextHitzone.xb || nextHitzone.ya > nextHitzone.yb) {
      const nextCorner = [corner[0], corner[1]] as Corner
      if (nextHitzone.xa > nextHitzone.xb) {
        swapPropertiesInPlace(nextHitzone, "xa", "xb")
        nextCorner[0] = (1 - nextCorner[0]) as 1 | 0
      }
      if (nextHitzone.ya > nextHitzone.yb) {
        swapPropertiesInPlace(nextHitzone, "ya", "yb")
        nextCorner[1] = (1 - nextCorner[1]) as 1 | 0
      }
      this.setState({ currentlySelectedCorner: nextCorner })
    }

    this.props.onHitzoneChange(nextHitzone)
  }

  private subscribeToMouseMove(): void {
    document.addEventListener("mousemove", this.handleMouseMove)
  }

  private unsubscribeFromMouseMove(): void {
    document.removeEventListener("mousemove", this.handleMouseMove)
  }

  private startScalingHitzone(
    clientId: ClientId,
    corner: Readonly<Corner>,
    offset: Readonly<Point>
  ) {
    this.subscribeToMouseMove()
    this.setState({
      currentlySelectedClientId: clientId,
      currentlySelectedCorner: corner,
      currentTranslationOffset: offset,
      currentAction: Action.Scale,
    })
  }

  private isHitzoneTooSmall({ xa, ya, xb, yb }: ClientIdRectangle): boolean {
    if (this.containerElement === null) {
      return false
    }
    const widthPx = (xb - xa) * this.containerElement.offsetWidth
    const heightPx = (yb - ya) * this.containerElement.offsetHeight
    return (
      widthPx < MIN_SELECTION_SIZE_PX ||
      heightPx < MIN_SELECTION_SIZE_PX ||
      widthPx * heightPx < MIN_SELECTION_AREA_PX
    )
  }

  // -- Event handlers

  private handleContainerRef = (container: ReactInstance | null): void => {
    this.containerElement =
      container && (ReactDOM.findDOMNode(container) as HTMLUListElement)
  }

  private handleCenterMouseDown = (
    clientId: ClientId,
    event: ReactMouseEvent<HTMLElement>
  ) => {
    const hitzone = this.findHitzone(clientId)
    // hitzone is null when clicked while animating away.
    if (hitzone == null) return
    const { xa, ya } = hitzone
    this.setState({
      currentlySelectedClientId: clientId,
      currentTranslationOffset: PointUtility.subtract(
        this.normalizeMousePosition(event),
        { x: xa, y: ya }
      ),
      currentAction: Action.MaybeTranslate,
      currentlySelectedCorner: null,
    })
    this.subscribeToMouseMove()
  }

  private handleHandleMouseDown = (
    clientId: ClientId,
    corner: Readonly<Corner>,
    event: ReactMouseEvent<HTMLElement>
  ): void => {
    const hitzone = this.findHitzone(clientId)
    // hitzone is null when clicked while animating away.
    if (hitzone == null) return
    this.startScalingHitzone(
      clientId,
      corner,
      PointUtility.subtract(
        this.normalizeMousePosition(event),
        getHitzoneCornerPosition(hitzone, corner)
      )
    )
  }

  private handleMouseMove = (event: MouseEvent): void => {
    const { state } = this

    // Get the current position of the mouse normalized to this container.
    const normalizedPosition = this.normalizeMousePosition(event)

    if (state.currentAction === null) {
      return
    }

    switch (state.currentAction) {
      case Action.MaybeTranslate:
        // If the user has clicked, but then moves the mouse, we turn this into a
        // translation. (Alternatively, if the user released the mouse we would
        // remove the hitzone.)
        this.setState({ currentAction: Action.Translate })
        break
      case Action.Translate: {
        this.setPosition(
          PointUtility.subtract(
            normalizedPosition,
            state.currentTranslationOffset
          )
        )
        break
      }
      case Action.Scale: {
        this.setCornerPosition(
          PointUtility.subtract(
            normalizedPosition,
            state.currentTranslationOffset
          )
        )
        break
      }
    }
  }

  private handleMouseUp = (event: MouseEvent) => {
    if (isLeftButton(event)) {
      const { state } = this

      // We only listen to mouse movements for dragging, so stop that.
      this.unsubscribeFromMouseMove()

      // If the user clicked the mouse without dragging then we're removing the
      // hitzone under the cursor.
      switch (state.currentAction) {
        case Action.MaybeTranslate:
          this.props.onRemoveHitzone(state.currentlySelectedClientId)
          break
        case Action.Scale: {
          const hitzone = this.findHitzone(state.currentlySelectedClientId)
          if (hitzone !== null && this.isHitzoneTooSmall(hitzone)) {
            this.props.onRemoveHitzone(state.currentlySelectedClientId)
          }
        }
      }

      // All actions require mouse movement, so this always cancels the current
      // action.
      this.setState(INACTIVE_STATE)
    }
  }

  private handleMouseDown = (event: ReactMouseEvent<HTMLUListElement>) => {
    if (isLeftButton(event)) {
      // Prevent default here so that a click and drag that leaves the editable
      // region does not select text in other elements.
      event.preventDefault()

      const { x, y } = this.normalizeMousePosition(event)
      const hitzone: ClientIdRectangle = {
        _clientId: nextClientId(),
        xa: x,
        ya: y,
        xb: x,
        yb: y,
      }
      this.props.onCreateHitzone(hitzone)
      this.startScalingHitzone(getClientId(hitzone), [1, 1], PointUtility.ZERO)
    }
  }

  // -- React lifecycle --

  componentDidMount() {
    document.addEventListener("mouseup", this.handleMouseUp)
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleMouseUp)
    this.unsubscribeFromMouseMove()
  }

  render() {
    const {
      className,
      displayNumberOffset,
      filterClientIds,
      hitzones,
      showDisplayNumbers,
      isFilteringByHitzones,
      theme,
    } = this.props
    const { currentlySelectedClientId } = this.state
    const isFiltering = isFilteringByHitzones || filterClientIds.length > 0

    const hitzonesNodes = hitzones.map((hitzone, index) => (
      <CSSTransition
        key={hitzone._clientId}
        classNames={transitionClassNames}
        timeout={transitionTimeout}
      >
        <EditableHitzone
          hitzone={hitzone}
          onCenterMouseDown={this.handleCenterMouseDown}
          onHandleMouseDown={this.handleHandleMouseDown}
          displayNumber={
            showDisplayNumbers
              ? (displayNumberOffset || 0) + index + 1
              : undefined
          }
          isTooSmall={
            hitzone._clientId === currentlySelectedClientId &&
            this.isHitzoneTooSmall(hitzone)
          }
          isActive={hitzone._clientId === currentlySelectedClientId}
          isFiltered={
            isFiltering && !filterClientIds.includes(hitzone._clientId)
          }
          theme={theme}
        />
      </CSSTransition>
    ))
    return (
      <TransitionGroup
        ref={this.handleContainerRef}
        className={classNames(styles.hitzoneEditor, className)}
        component="ul"
        onMouseDown={this.handleMouseDown}
      >
        {hitzonesNodes}
      </TransitionGroup>
    )
  }
}
