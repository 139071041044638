import {
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { HitzoneEditor, Theme } from "Components/hitzone-editor/hitzone-editor"
import { UnscaledScreenshot } from "Components/screenshot/UnscaledScreenshot/UnscaledScreenshot"
import { MaintainScreenshotAspectRatioContainer as ScreenshotContainer } from "Components/screenshot/maintain-screenshot-aspect-ratio-container"
import {
  ClientId,
  ClientIdRectangle,
  Destructible,
  DeviceFrame,
  ImageScreenshot,
} from "Types"

interface Props {
  constrainWidthToDeviceFrame: Readonly<DeviceFrame> | null
  hitzones: ReadonlyArray<Readonly<Destructible<ClientIdRectangle>>>
  onClose: () => void
  screenshot: Readonly<ImageScreenshot>
  onHitzonesChange: (
    hitzones: ReadonlyArray<Readonly<ClientIdRectangle>>
  ) => void
}

export const HitzoneEditorModal: React.FC<React.PropsWithChildren<Props>> = ({
  constrainWidthToDeviceFrame,
  hitzones,
  onClose,
  screenshot,
  onHitzonesChange,
}) => {
  const [newHitzones, setNewHitzones] = useState(hitzones)

  const handleCreateHitzone = (nextHitzone: Readonly<ClientIdRectangle>) => {
    setNewHitzones([...newHitzones, nextHitzone])
  }

  const handleHitzoneChange = (nextHitzone: Readonly<ClientIdRectangle>) => {
    setNewHitzones(
      newHitzones.map((hitzone) =>
        hitzone._clientId === nextHitzone._clientId ? nextHitzone : hitzone
      )
    )
  }

  const handleRemoveHitzone = (clientId: ClientId) => {
    setNewHitzones(
      newHitzones.map((hitzone) => {
        return hitzone._clientId === clientId
          ? { ...hitzone, _destroy: true }
          : hitzone
      })
    )
  }

  const handleDone = () => {
    onHitzonesChange(newHitzones)
    onClose()
  }

  return (
    <Modal isOpen onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent data-qa="hitzone-editor-modal">
        <ModalHeader>Define hitzones for this image</ModalHeader>
        <ModalBody>
          <Stack spacing={4}>
            <Text>
              Click and drag to select areas that count as successful clicks.
              Click a box once to remove it. <br />
              The navigation task ends when a participant clicks outside of the
              hitzones.
            </Text>
            <Center>
              <ScreenshotContainer
                className="HitzoneEditorModal-screenshotContainer"
                screenshot={screenshot}
                constrainWidthToDeviceFrame={constrainWidthToDeviceFrame}
              >
                <UnscaledScreenshot
                  className="HitzoneEditorModal-screenshot"
                  screenshot={screenshot}
                  canSkipAheadOnFirstWatch
                />
                <HitzoneEditor
                  className="HitzoneEditorModal-hitzoneEditor"
                  hitzones={newHitzones.filter((hz) => !hz._destroy)}
                  onCreateHitzone={handleCreateHitzone}
                  onHitzoneChange={handleHitzoneChange}
                  onRemoveHitzone={handleRemoveHitzone}
                  filterClientIds={[]}
                  isFilteringByHitzones={false}
                  theme={Theme.Hitzone}
                />
              </ScreenshotContainer>
            </Center>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="brand.primary" onClick={handleDone}>
              Done
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
