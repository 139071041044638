import React, { PropsWithChildren, createContext } from "react"
import { ListTargetableLocationsResponse } from "~/api/generated/usabilityhub-components"

type LocationContextType = ListTargetableLocationsResponse["locations"] | null

const LocationContext = createContext<LocationContextType>(null)

export const useLocationContext = () => {
  const locations = React.useContext(LocationContext)

  if (!locations) {
    throw new Error("useLocationContext must be used within a LocationProvider")
  }

  return locations
}

type Props = {
  locations: ListTargetableLocationsResponse["locations"]
}

export const LocationProvider: React.FC<PropsWithChildren<Props>> = ({
  locations,
  children,
}) => {
  return (
    <LocationContext.Provider value={locations}>
      {children}
    </LocationContext.Provider>
  )
}
