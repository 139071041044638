import {
  Alert,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { updateOrttoTrackingUser } from "JavaScripts/analytics/initialize-ortto.js"
import { trackSignedUp } from "JavaScripts/analytics/track"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { useQueryStringParams } from "Shared/hooks/useQueryStringParams"
import { LyssnaLogoIcon } from "Shared/icons/LyssnaLogoIcon"
import { PlusSolidIcon } from "Shared/icons/untitled-ui/PlusSolidIcon"
import { GoogleSignIn } from "UsabilityHub/components/GoogleSignIn/GoogleSignIn"
import { useRefreshCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useRefreshTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { partial } from "lodash"
import React, { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Link as RoutedLink } from "react-router-dom"
import { useRegisterNewUser } from "~/api/generated/usabilityhub-components"
import { ROUTES } from "../routes"
import { useOnboardingContext } from "./OnboardingContextProvider"
import { AccountStep } from "./types"
import { useOnboardingStep } from "./useOnboardingStep"

type AccountFormProps = {
  step: AccountStep
}

const planParamToPlanUniqueId = (param: string) => {
  switch (param) {
    case "basic_monthly":
      return Constants.BASIC_V3_MONTHLY_PLAN_UNIQUE_ID
    case "basic_annual":
      return Constants.BASIC_V3_ANNUAL_PLAN_UNIQUE_ID
    case "pro_monthly":
      return Constants.PRO_V3_MONTHLY_PLAN_UNIQUE_ID
    case "pro_annual":
      return Constants.PRO_V3_ANNUAL_PLAN_UNIQUE_ID
    default:
      return Constants.FREE_PLAN_UNIQUE_ID
  }
}

export const AccountForm: React.FC<AccountFormProps> = ({ step }) => {
  const { submit, setRedirect, recaptchaSiteKey } = useOnboardingContext()

  const query = useQueryStringParams()
  const intendedPlanFromURL = query.get("plan") || ""
  const intendedPlanUniqueId = planParamToPlanUniqueId(intendedPlanFromURL)

  const refreshTeamMembers = useRefreshTeamMembers()
  const refreshCurrentAccount = useRefreshCurrentAccount()

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useOnboardingStep(step)

  const recaptchaRef = React.useRef<ReCAPTCHA>(null)
  const resetRecaptchaResponse = () => {
    recaptchaRef.current?.reset()
  }

  const [signupError, setSignupError] = useState<string | null>(null)

  const { isLoading: submitting, mutateAsync: registerNewUser } =
    useRegisterNewUser()

  const signUp = async (values: AccountStep["data"]) => {
    try {
      const { recaptchaResponse, ...user } = values

      const data = await registerNewUser({
        body: {
          user,
          intended_plan_unique_id: intendedPlanUniqueId,
          "g-recaptcha-response": recaptchaResponse,
        },
      })

      trackSignedUp("signup_form")
      // to fix an issue where the capture widget isn't updating
      // add a call to ortto to update the user's email right away
      updateOrttoTrackingUser(values.email)
      refreshCurrentAccount()
      refreshTeamMembers()

      setRedirect(data.redirect_to || ROUTES.DASHBOARD.path)
      setSignupError(null)
      submit(values)
    } catch (error) {
      if ("payload" in error) {
        setSignupError(error.payload.message)
        resetRecaptchaResponse()
      } else {
        throw error
      }
    }
  }

  return (
    <>
      <Stack
        as="form"
        spacing={6}
        onSubmit={handleSubmit(signUp)}
        maxW="27.5rem"
        justifySelf="center"
      >
        <Box
          as="fieldset"
          disabled={submitting || undefined}
          display="contents"
        >
          <Stack alignSelf="center" align="center">
            <LyssnaLogoIcon h="3.125rem" w="auto" />
            <Text color="text.subtlest" fontWeight="medium">
              Sign up for free. No credit card required.
            </Text>
          </Stack>
          {signupError && (
            <Alert status="error" rounded="md">
              {signupError}
            </Alert>
          )}
          <FormControl isInvalid={!!errors.name || undefined}>
            <FormLabel fontWeight="medium" fontSize="md">
              Your name
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter your full name"
              required
              autoComplete=""
              {...register("name")}
            />
            {errors.name && (
              <FormErrorMessage>{errors.name.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors.email || undefined}>
            <FormLabel fontWeight="medium" fontSize="md">
              Your work email
            </FormLabel>
            <Input
              type="email"
              placeholder="name@company.com"
              required
              autoComplete="email"
              {...register("email")}
            />
            {errors.email && (
              <FormErrorMessage>{errors.email.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors.password || undefined}>
            <FormLabel fontWeight="medium" fontSize="md">
              Password
            </FormLabel>
            <Input
              type="password"
              placeholder="8+ characters"
              required
              {...register("password")}
            />
            {errors.password && (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            )}
          </FormControl>
          {recaptchaSiteKey !== null && (
            <FormControl isInvalid={"recaptchaResponse" in errors}>
              <ReCAPTCHA
                ref={recaptchaRef}
                onErrored={resetRecaptchaResponse}
                onExpired={resetRecaptchaResponse}
                onChange={partial(setValue, "recaptchaResponse")}
                sitekey={recaptchaSiteKey}
              />
              <FormErrorMessage>
                Please confirm you’re not a robot.
              </FormErrorMessage>
            </FormControl>
          )}
          <Button
            type="submit"
            variant="solid"
            colorScheme="brand.primary"
            leftIcon={<PlusSolidIcon />}
          >
            Create free account
          </Button>
          <Text
            as="span"
            alignSelf="center"
            textTransform="uppercase"
            fontWeight="medium"
            color="text.secondary"
          >
            or
          </Text>
          <GoogleSignIn
            buttonVariant="signup_with"
            intent="sign_up"
            requestParams={{ intended_plan_unique_id: intendedPlanUniqueId }}
            alignSelf="stretch"
            display="flex"
            justifyContent="stretch"
            sx={{
              ".g_id_signin": {
                flex: 1,
                "> div": {
                  display: "grid",
                  placeItems: "center",
                },
                "div[role=button]": {
                  div: {
                    justifyContent: "center",
                  },
                  span: {
                    flexGrow: 0,
                  },
                },
              },
            }}
          />
          <Text fontSize="sm" textAlign="center" color="text.subtlest" mt={8}>
            Already have a Lyssna account?{" "}
            <Link as={RoutedLink} to={ROUTES.USERS.SIGN_IN.buildPath({})}>
              Sign in
            </Link>
          </Text>
          <Text
            color="text.subtlest"
            fontSize="sm"
            className="account-setup-small-print"
            my={0}
            mx={{ base: 0, lg: -16 }}
            textAlign="center"
          >
            By signing up, you agree to Lyssna{"\u2019"}s{" "}
            <ExternalLink href={ROUTES.LEGAL.TERMS.buildPath({})} isExternal>
              Terms of Service
            </ExternalLink>{" "}
            and{" "}
            <ExternalLink href={ROUTES.LEGAL.PRIVACY.buildPath({})} isExternal>
              Privacy Policy
            </ExternalLink>
            .
          </Text>
        </Box>
      </Stack>
    </>
  )
}
