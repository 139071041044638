import {
  Button,
  ButtonProps,
  Stack,
  StackProps,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
} from "@chakra-ui/react"
import React from "react"
import { Link, useMatch } from "react-router-dom"

const styleConfig = {
  parts: ["container", "link"],
  baseStyle: {
    container: {
      // Negate button padding for alignment
      mx: -4,
      my: -2,
    },
    link: {
      color: "gray.600",
      justifyContent: "flex-start",
      svg: {
        color: "gray.400",
        width: 6,
        height: 6,
        mr: 1,
      },
      _activeLink: {
        bg: "gray.100",
        color: "gray.900",
        svg: {
          // This is a bit gross but there is no `_groupActiveLink` selector
          color: "gray.600",
        },
      },
    },
  },
}

export const SideNav: React.FC<React.PropsWithChildren<StackProps>> = (
  props
) => {
  const styles = useMultiStyleConfig("SideNav", { styleConfig })
  return (
    <StylesProvider value={styles}>
      <Stack as="nav" sx={styles.container} {...props} />
    </StylesProvider>
  )
}

interface SideNavLinkProps extends ButtonProps {
  to: string
}

export const SideNavLink: React.FC<
  React.PropsWithChildren<SideNavLinkProps>
> = ({ to, ...props }) => {
  const match = useMatch({ end: true, path: to })
  const isActive = !!match
  const styles = useStyles()

  return (
    <Button
      variant="ghost"
      as={Link}
      to={to}
      sx={styles.link}
      aria-current={isActive ? "page" : undefined}
      {...props}
    />
  )
}
