import { Box, useToast } from "@chakra-ui/react"
import classNames from "classnames"
import React, { PropsWithChildren, useEffect, useRef } from "react"

import { TestResultsCommentProvider } from "Components/comment-provider/TestResultsCommentProvider"
import {
  Main,
  Sidebar,
} from "Components/fixed-sidebar-layout/fixed-sidebar-layout"
import { IndividualResponseDrawer } from "Components/individual-response/IndividualResponseDrawer"
import { TestResultsSections } from "Components/test-results/test-results-sections"
import styles from "Components/test-results/test-results.module.scss"
import { useTestResultsCommentLiveUpdates } from "Hooks/useTestResultsCommentLiveUpdates"
import { UsabilityTest } from "Types"

import { DemographicsContextProvider } from "./context/demographics"
import { FilteredResponsesContextProvider } from "./context/filtered-responses"
import { TestResultsFilterStateContextProvider } from "./context/test-result-filter-state"
import { TestResultViewStateProvider } from "./context/test-result-view-state"
import { TestResultsSidebar } from "./test-results-sidebar/test-results-sidebar"

import { LocaleProvider } from "Shared/contexts/LocaleContext"
import { isExternalStudy } from "UsabilityHub/utils/isExternalStudy"
import { ExternalStudyResults } from "./external-study-results/ExternalStudyResults"

interface Props {
  usabilityTest: UsabilityTest
}

export const TestResults: React.FC<Props> = ({ usabilityTest }) => {
  // On component mount, set up a MutationObserver to detect Google Translate being used,
  // and show a warning. See #6377.
  const toast = useToast()
  const toastIdRef = useRef<string | number | undefined>()
  useTestResultsCommentLiveUpdates(usabilityTest.id)

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (/translated/.exec(document.documentElement.className)) {
        toastIdRef.current = toast({
          title: "Google translate can break this page",
          description:
            "Using Google translate on the test results page can cause the page to crash on some interactions. If the page crashes, please reload and try again.",
          duration: null,
          position: "bottom-left",
          status: "warning",
        })
      } else {
        if (toastIdRef.current) {
          toast.close(toastIdRef.current)
        }
      }
    })

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
      childList: false,
      characterData: false,
    })
  })

  return (
    <LocaleProvider
      keys={["demographics", "platform", "device_type", "test.tree_test"]}
    >
      <TestResultViewStateProvider usabilityTest={usabilityTest}>
        <TestResultsFilterStateContextProvider>
          <DemographicsContextProvider>
            <FilteredResponsesContextProvider>
              <TestResultsCommentProvider>
                <TestResultsInner usabilityTest={usabilityTest} />
              </TestResultsCommentProvider>
            </FilteredResponsesContextProvider>
          </DemographicsContextProvider>
        </TestResultsFilterStateContextProvider>
      </TestResultViewStateProvider>
    </LocaleProvider>
  )
}

interface TestResultsInnerProps {
  usabilityTest: UsabilityTest
}

const TestResultsInner: React.FC<
  React.PropsWithChildren<PropsWithChildren<TestResultsInnerProps>>
> = ({ usabilityTest }) => {
  if (isExternalStudy(usabilityTest)) {
    return <ExternalStudyResults />
  }

  return (
    <Box position="relative">
      <Sidebar>
        <TestResultsSidebar />
      </Sidebar>
      <Main className={classNames(styles.filterable)}>
        <TestResultsSections sections={usabilityTest.sections} />
      </Main>

      <DemographicsContextProvider>
        {/*
            Later once the results page is in the SPA, we should change this
            modal to be routed normally
          */}
        <IndividualResponseDrawer />
      </DemographicsContextProvider>
    </Box>
  )
}
