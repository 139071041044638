import { Box, HStack, Stack } from "@chakra-ui/react"
import React, { useState } from "react"
import { Draggable } from "react-beautiful-dnd"
import { FieldArray, WrappedFieldArrayProps } from "redux-form"

import { getSectionTypeRules } from "Constants/test-section-types"
import { ScreenshotHitzone, UsabilityTestSectionType } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useDeviceFramesForSectionContext,
  useSectionContext,
  useSectionTypeContext,
} from "UsabilityHub/contexts"

import { FormFieldError } from "../../FormFieldError"
import { DraggableRowHandle } from "../../SectionQuestions/DragDrop/DraggableQuestionRow"
import { useSectionMediaContext } from "../SectionMediaContext"

import { MediaPreviewModal } from "./MediaPreviewModal"
import { MediaRowEditableDetails } from "./MediaRowEditableDetails"
import { MediaRowThumbnail } from "./MediaRowThumbnail"
import { HitzoneEditorModal } from "./screenshots/hitzone-editor-modal"
import { useSectionMediaPath } from "./useSectionMediaPath"

export enum Mode {
  Normal,
  Editing,
  EditingHitzones,
  Preview,
}

const WrappedMediaRow: React.FC<WrappedFieldArrayProps> = ({
  fields,
  meta,
}) => {
  const [mode, setMode] = useState(Mode.Normal)
  const { section } = useSectionContext()
  const sectionType = useSectionTypeContext()
  const { sectionMediaIndex, sectionMedia, mediaFile } =
    useSectionMediaContext()
  const isReorderable = getSectionTypeRules(sectionType).maxScreenshots > 1
  const deviceFrame = useDeviceFramesForSectionContext(section)
  const sectionMediaCount = section.section_screenshots.length
  const sectionMediaPath = useSectionMediaPath()
  const { readOnly } = useUsabilityTestUserActivityContext()

  // TODO: Rewrite HitzoneEditorModal in chakra and move this logic into
  // the editor
  const handleHitzoneChange = (
    hitzones: ReadonlyArray<Readonly<ScreenshotHitzone>>
  ) => {
    fields.removeAll()
    hitzones.forEach((hitzone) => fields.push(hitzone))
    setMode(Mode.Normal)
  }

  return (
    <Draggable
      draggableId={sectionMediaPath}
      key={sectionMediaPath}
      index={sectionMediaIndex}
      isDragDisabled={readOnly || sectionMediaCount === 1}
    >
      {(draggable) => (
        <Box
          ref={draggable.innerRef}
          {...draggable.draggableProps}
          bg="gray.50"
          p={4}
          mb={6}
        >
          <HStack spacing={8}>
            {isReorderable && !readOnly && (
              <DraggableRowHandle
                draggableProvided={draggable}
                isDragDisabled={sectionMediaCount === 1}
              />
            )}
            <MediaRowThumbnail />
            <Stack
              spacing={0}
              alignSelf="stretch"
              alignItems="stretch"
              minWidth="0"
            >
              <MediaRowEditableDetails
                mode={mode}
                setMode={setMode}
                showHitzoneButton={
                  sectionType === UsabilityTestSectionType.NavigationTest
                }
              />
              <FormFieldError {...meta} />
            </Stack>
            {mediaFile?.media_type === "image" &&
              mode === Mode.EditingHitzones && (
                <HitzoneEditorModal
                  constrainWidthToDeviceFrame={deviceFrame}
                  hitzones={sectionMedia.screenshot_hitzones}
                  onClose={() => setMode(Mode.Normal)}
                  onHitzonesChange={handleHitzoneChange}
                  screenshot={mediaFile}
                />
              )}
            <MediaPreviewModal
              isOpen={mode === Mode.Preview}
              onClose={() => setMode(Mode.Normal)}
            />
          </HStack>
        </Box>
      )}
    </Draggable>
  )
}

export const MediaRow: React.FC<React.PropsWithChildren<unknown>> = () => {
  const sectionMediaPath = useSectionMediaPath()
  return (
    <FieldArray
      name={`${sectionMediaPath}.screenshot_hitzones`}
      component={WrappedMediaRow}
      props={{}}
    />
  )
}
