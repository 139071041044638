import { State } from "Redux/app-store"

import { getTestBuilderState } from "./test-form"

export function getIsEstimationLoading(state: State) {
  return state.testBuilder!.estimations.isLoading
}

export function getHasEstimationError(state: State) {
  return state.testBuilder!.estimations.error
}

export function getEstimatedDurationRange(state: State) {
  return state.testBuilder!.estimations.estimatedDurationRange
}

export function getCreditCount(state: State) {
  return state.testBuilder!.estimations.creditCount
}

export function getIsEstimateValid(state: State) {
  return getTestBuilderState(state).estimations.isValid
}
