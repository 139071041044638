import * as React from "react"

import { UsePrototypeReturn } from "./usePrototypeTask"

const PrototypeTaskContext = React.createContext<UsePrototypeReturn | null>(
  null
)

interface PrototypeTaskProviderProps {
  prototypeTask: UsePrototypeReturn
}

export const PrototypeTaskProvider: React.FC<
  React.PropsWithChildren<PrototypeTaskProviderProps>
> = ({ prototypeTask, children }) => {
  return (
    <PrototypeTaskContext.Provider value={prototypeTask}>
      {children}
    </PrototypeTaskContext.Provider>
  )
}

export const usePrototypeTaskContext = (): UsePrototypeReturn => {
  const context = React.useContext(PrototypeTaskContext)
  if (!context) {
    throw new Error(
      `usePrototypeTaskContext must be rendered within the PrototypeTaskProvider`
    )
  }
  return context
}
