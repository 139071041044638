import {
  Box,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"

interface Props {
  ageRange: [number, number]
  setAgeRange: (ageRange: [number, number]) => void
}

export const AgeRangeSection: React.FC<React.PropsWithChildren<Props>> = ({
  ageRange,
  setAgeRange,
}) => {
  // This is a workaround for a bug in the RangeSlider component:
  // When you render it inside an accordion it can't get the dimensons correctly when it
  // mounts, so the sliders show up moved to the right.
  // This way we delay the render until the accordion is expanded.
  // See https://github.com/chakra-ui/chakra-ui/issues/5255 for more info.
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    setVisible(true)
  }, [])
  if (!visible) return null

  return (
    <Box px={4} pb={6}>
      <RangeSlider
        size="lg"
        aria-label={["minimum age", "maximum age"]}
        defaultValue={[18, 100]}
        min={18}
        value={ageRange}
        onChange={setAgeRange}
      >
        <RangeSliderTrack
          h="10px"
          rounded="full"
          data-qa="age-range-slider-track"
        >
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} zIndex="unset">
          <Text position="relative" top="20px">
            {ageRange[0]}
          </Text>
        </RangeSliderThumb>
        <RangeSliderThumb index={1} zIndex="unset">
          <Text position="relative" top="20px">
            {ageRange[1] ? ageRange[1] : "100+"}
          </Text>
        </RangeSliderThumb>
      </RangeSlider>
    </Box>
  )
}
