import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

import { ClickSelection, UsabilityTest } from "Types"
import {
  getParsedQueryStringByPath,
  pushQueryStringPath,
} from "Utilities/query-string"

export const QUERY_PATH = "view-state"

type ViewState = {
  screenshotClientHitzones: ClickSelection[]
  individualResponseView: number | null
  recordingResponseSectionId: number | null
}

type GoalScreenIdBySectionId = { [key: number]: string }
type CommonPathNamesBySectionId = { [key: number]: { [name: string]: string } }

type TestResultViewStateType = {
  isReviewing: boolean
  isThirdPartyOrder: boolean
  viewState: ViewState
  setViewState: Dispatch<SetStateAction<ViewState>>
  goalScreenIdBySectionId: GoalScreenIdBySectionId
  commonPathNamesBySectionId: CommonPathNamesBySectionId
  setCommonPathNamesBySectionId: Dispatch<
    SetStateAction<CommonPathNamesBySectionId>
  >
}

const TestResultViewState = createContext<TestResultViewStateType | null>(null)

const getInitialState = () => {
  const partialViewState =
    getParsedQueryStringByPath<Partial<ViewState>>(QUERY_PATH)
  return {
    screenshotClientHitzones: [],
    individualResponseView: null,
    recordingResponseSectionId: null,
    ...partialViewState,
  }
}

export const useTestResultViewState = () => {
  const context = useContext(TestResultViewState)

  if (context === null) {
    throw new Error(
      "useTestResultViewState must be used inside the TestResultViewStateProvider"
    )
  }

  return context
}

export const TestResultViewStateProvider: FC<
  React.PropsWithChildren<{
    usabilityTest: UsabilityTest
    isReviewing?: boolean
    isThirdPartyOrder?: boolean
  }>
> = ({
  usabilityTest,
  isReviewing = false,
  isThirdPartyOrder = false,
  children,
}) => {
  const [viewState, setViewState] = useState(getInitialState())

  // Keep the URL query params updated
  useEffect(() => {
    pushQueryStringPath(QUERY_PATH, viewState)
  }, [viewState])

  // Find common paths and goal screens for Figma sections
  const goalScreenIdBySectionId = useMemo<GoalScreenIdBySectionId>(() => {
    return Object.fromEntries(
      usabilityTest.sections.map((section) => {
        if (section.figma_file_flow) {
          if (section.figma_file_flow.goal_node_id) {
            return [section.id, section.figma_file_flow.goal_node_id]
          }
        }
        return []
      })
    )
  }, [usabilityTest])
  const [commonPathNamesBySectionId, setCommonPathNamesBySectionId] =
    useState<CommonPathNamesBySectionId>(() => {
      return Object.fromEntries(
        usabilityTest.sections.map((section) => {
          if (section.figma_file_flow) {
            return [section.id, section.figma_file_flow.common_path_names]
          }
          return []
        })
      )
    })

  return (
    <TestResultViewState.Provider
      value={{
        isReviewing,
        isThirdPartyOrder,
        viewState,
        setViewState,
        goalScreenIdBySectionId,
        commonPathNamesBySectionId,
        setCommonPathNamesBySectionId,
      }}
    >
      {children}
    </TestResultViewState.Provider>
  )
}
