import { ClientHitzoneFilter } from "../filters/client-hitzone"

import { generateFilterId } from "./generate-filter-id"
import { createHitzoneFilterCategory } from "./hitzone-filter"

export function createClientHitzoneFilter(
  sectionId: number,
  screenshotId: number,
  clientHitzoneId: string
): ClientHitzoneFilter {
  return {
    id: generateFilterId(),
    category: createHitzoneFilterCategory(sectionId, screenshotId),
    type: "answer/client-hitzone",
    value: clientHitzoneId,
  }
}
