import {
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { Link } from "react-router-dom"

import { DateDifferenceFromNow } from "Components/time/date-difference-from-now"
import { Header, HeaderContent } from "Shared/components/Header/Header"
import { useGetVariationSet } from "~/api/generated/usabilityhub-components"

import { VariationSetMenu } from "./TestSetMenu/VariationSetMenu"

type Subnav = React.FC<{
  id: number
  uniqueId: string
  creatorName: string | null
  redirectOnDelete: boolean
}>

function getTabIndexByPath(path: string) {
  if (path.match(/\/sets\/([a-z0-9]+)$/)) return 0
  if (path.match(/\/sets\/([a-z0-9]+)\/recruit$/)) return 1

  throw new Error(`No matching tab for path ${path}`)
}

export const TestSetSubnav: Subnav = ({ uniqueId }) => {
  const path = window.location.pathname
  const tabIndex = getTabIndexByPath(path)

  // TODO: This should be moved to the ShowTestSetPage.tsx and RecruitTestSetPage.tsx
  // and passed to this child component once we move away from redux store
  const { data } = useGetVariationSet(
    {
      pathParams: {
        uniqueId,
      },
    },
    {
      staleTime: 15 * 60 * 1000, // 15 minutes
      refetchOnWindowFocus: true,
    }
  )
  if (!data) return null

  return (
    <Header>
      <HeaderContent>
        <Flex flex={1} justifyContent="flex-start">
          <Stack>
            <Heading lineHeight="none">{data.variation_set.name}</Heading>

            <Text
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              fontSize="sm"
              color="text.secondary"
            >
              Created{" "}
              <DateDifferenceFromNow
                date={new Date(data.variation_set.created_at)}
              />{" "}
              ago
              {data.variation_set.creator &&
                ` by ${data.variation_set.creator.name}`}
            </Text>
          </Stack>
        </Flex>
        <Flex flex={1} justifyContent="center" alignSelf="flex-end">
          <Tabs index={tabIndex} variant="soft-rounded">
            <TabList>
              <Link to={`/sets/${uniqueId}`}>
                <Tab>Tests</Tab>
              </Link>
              <Link to={`/sets/${uniqueId}/recruit`}>
                <Tab>Recruit participants</Tab>
              </Link>
            </TabList>
          </Tabs>
        </Flex>
        <Flex flex={1} justifyContent="flex-end">
          <VariationSetMenu variationSet={data.variation_set} />
        </Flex>
      </HeaderContent>
    </Header>
  )
}
