import { ScreenshotHitzone, UsabilityTestSection } from "Types"
import { countHitzones } from "Utilities/screenshot-hitzone"
import { isNavigationTestSection } from "Utilities/usability-test-section"

const needsHitzones = (
  hitzones: readonly ScreenshotHitzone[],
  section: UsabilityTestSection
) => {
  const hitzoneCount = countHitzones(hitzones)
  return isNavigationTestSection(section) && hitzoneCount === 0
}

export const validateHitzones = (
  hitzones: readonly ScreenshotHitzone[],
  section: UsabilityTestSection
): string | undefined => {
  if (needsHitzones(hitzones, section)) {
    return "You must add at least one hitzone."
  }
}
