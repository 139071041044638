import { Text } from "@chakra-ui/react"
import React from "react"

import { TestCardDivider } from "UsabilityHub/components/TestCard/TestCard"
import { useSectionContext } from "UsabilityHub/contexts"

import { SectionCard } from "../SectionCard"
import { SectionInstructionField } from "../SectionFields/SectionInstructionField"
import { SectionQuestions } from "../SectionQuestions/SectionQuestions"
import { SectionLogicActions } from "../TestLogicActions/TestLogicActions"

import { CardSortOptions } from "./CardSortOptions"
import { CardSortSectionCardList } from "./CardSortSectionCardList"
import { CardSortSectionCategoryList } from "./CardSortSectionCategoryList"

export const CardSortSectionCard: React.FC = () => {
  const { section } = useSectionContext()
  const sortType = section.card_sort_attributes?.card_sort_type

  return (
    <SectionCard>
      <SectionLogicActions />
      <TestCardDivider />
      <CardSortOptions />
      <TestCardDivider />
      <SectionInstructionField
        rows={2}
        placeholder="Keep this short and straightforward. If you need to add more context, add an instruction section before this one."
      />
      <CardSortSectionCardList />
      {sortType !== "open" && <CardSortSectionCategoryList />}
      <Text as="label" fontWeight="semibold">
        Follow up questions
      </Text>
      <SectionQuestions />
    </SectionCard>
  )
}
