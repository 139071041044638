import React, { ReactNode } from "react"

import {
  Filter,
  Name,
  Participants,
  Percentage,
  ProgressBox,
} from "Components/test-results/progress-box/progress-box"

import styles from "./answer-counts.module.scss"

export interface Props {
  count: number
  isFilter: boolean
  isFilterRequested: boolean
  isFiltered: boolean
  isLiteral: boolean
  onFilterChange: (isEnabled: boolean) => void
  text: ReactNode
  total: number
}

export const CountItem: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    count,
    isFilter,
    isFilterRequested,
    isFiltered,
    isLiteral,
    onFilterChange,
    text,
    total,
  } = props
  const progress = total === 0 ? 0 : count / total
  return (
    <ProgressBox
      className={styles.countItem}
      progress={progress}
      isFilter={isFilter}
      isFiltered={isFiltered}
    >
      <Name isLiteral={isLiteral}>{text}</Name>
      <Percentage className={styles.percentage} percentage={progress} />
      <Participants count={count} />
      <Filter value={isFilterRequested} onChange={onFilterChange} />
    </ProgressBox>
  )
}
