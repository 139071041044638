// These regexes must be kept at parity with string_utility.rb to ensure that the front-end and
// back-end behave consistently.
const protocol = `(?:(?:http|https)://)`
const auth = "(?:\\S+(?::\\S*)?@)?"
const host = "(?:(?:[a-z\\u00a1-\\uffff0-9][-_]*)*[a-z\\u00a1-\\uffff0-9]+)"
const domain = "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*"
const tld = `(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?`
const port = "(?::\\d{2,5})?"
const path = '(?:[/?#][^\\s"]*)?'
const regex = `(?:${protocol})${auth}(?:${host}${domain}${tld})${port}${path}`

export const containsURL = (value: string) =>
  new RegExp(regex, "gi").test(value)

export const isUrl = (value: string) =>
  new RegExp(`^${regex}$`, "gi").test(value)
