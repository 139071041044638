import { ResponseAttribute } from "../types/attributes"
import { ResponseWithAttributes } from "../types/response"

export function getAttribute<Category extends ResponseAttribute["category"]>(
  response: ResponseWithAttributes,
  attributeCategory: Category
): ResponseAttribute {
  return response.attributes.find(
    ({ category }) => category === attributeCategory
  ) as ResponseAttribute
}

export function getAttributes<Category extends ResponseAttribute["category"]>(
  response: ResponseWithAttributes,
  attributeCategory: Category
): ResponseAttribute[] {
  return response.attributes.filter(
    ({ category }) => category === attributeCategory
  )
}
