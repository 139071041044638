import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import { DotsVerticalIcon } from "@heroicons/react/outline"
import { Alert } from "DesignSystem/components"
import { GoogleCalendarIcon } from "Shared/icons/GoogleCalendarIcon"
import { ConflictCalendarModal } from "UsabilityHub/components/ConflictCalendarModal/ConflictCalendarModal"
import { useGoogleOAuth } from "UsabilityHub/hooks/useGoogleOAuth"
import { getDateString } from "Utilities/date-formats"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import {
  GetIntegrationsResponse,
  useDeleteIntegration,
} from "~/api/generated/usabilityhub-components"

interface Props {
  data?: GetIntegrationsResponse
  onConnectDisconnect: () => void | Promise<void>
}

export const GoogleIntegrationStatus: React.FC<
  React.PropsWithChildren<Props>
> = ({ data, onConnectDisconnect }) => {
  const toast = useToast()
  const { open: openConflictCalendarModal } = useModal(
    ConflictCalendarModal,
    "integration-config"
  )
  const { handleGoogleOAuth, disconnectGoogleOAuth } = useGoogleOAuth(
    !!data?.google,
    async () => {
      await onConnectDisconnect()
      openConflictCalendarModal({
        initialSelectedCalendars: data?.conflict_calendars ?? [],
        singleProvider: "google",
        hasGoogleCalendarError: !!data?.google?.error,
      })
    }
  )
  const { mutateAsync: deleteIntegration, isLoading: isDeletingIntegration } =
    useDeleteIntegration({
      onSuccess: async () => {
        toast({
          title: "Disconnected from Google Calendar",
          status: "success",
        })
        await onConnectDisconnect()
        disconnectGoogleOAuth()
      },
    })

  return (
    <Flex gap={4}>
      <Icon as={GoogleCalendarIcon} boxSize={10} mt={2} />
      <Box>
        <Text fontSize="lg" fontWeight="bold">
          Google Calendar
        </Text>
        <Text>
          Connect your calendar to check your availability and sync events
        </Text>
        {data?.google?.error && (
          <Alert
            mt="2"
            status="danger"
            title={`We\u2019ve lost connection to your Google Calendar`}
            description="We can no longer connect to your Google Calendar due to
                      authentication or permissions issues. Please reconnect to
                      allow Interview applicants to make bookings using this
                      calendar."
          />
        )}
      </Box>
      <Spacer />

      {data === undefined ? (
        <Spinner size="sm" />
      ) : data.google === null ? (
        <Button
          flexShrink={0}
          colorScheme="brand.primary"
          onClick={handleGoogleOAuth}
        >
          Connect
        </Button>
      ) : data.google.error ? (
        <Button
          flexShrink={0}
          colorScheme="brand.primary"
          isDisabled={isDeletingIntegration}
          onClick={async () => {
            await deleteIntegration({
              body: { integration: "google" },
            })
            handleGoogleOAuth()
          }}
        >
          Reconnect
        </Button>
      ) : (
        <>
          <Text
            fontSize="xs"
            color="text.secondary"
            textAlign="right"
            flexShrink={0}
          >
            Connected on
            <br />
            {getDateString(data.google.created_at, "medium")}
          </Text>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<Icon as={DotsVerticalIcon} color="gray.500" />}
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  openConflictCalendarModal({
                    initialSelectedCalendars: data.conflict_calendars,
                    singleProvider: "google",
                    hasGoogleCalendarError: !!data?.google?.error,
                  })
                }}
              >
                Configure
              </MenuItem>
              <MenuItem
                color="red"
                isDisabled={isDeletingIntegration}
                onClick={() => {
                  deleteIntegration({
                    body: { integration: "google" },
                  })
                }}
              >
                Disconnect
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      )}
    </Flex>
  )
}
