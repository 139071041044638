import { useCallback, useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import { createReachedGoalScreenFilter } from "../filters/create-filters/reached-goal-screen-filter"
import { getFilterUtilityFunctions } from "../filters/get-filter-utility-functions"

const { isFilter, toggleFilter } = getFilterUtilityFunctions(
  createReachedGoalScreenFilter
)

export const useReachedGoalScreenFilter = (
  sectionId: number,
  goalNodeId: string
) => {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handleReachedGoalScreenFilterToggle = useCallback(() => {
    setTestResultsFilters((filters) =>
      toggleFilter(filters, sectionId, goalNodeId)
    )
  }, [])

  return {
    isFilter: isFilter(testResultsFilters, sectionId, goalNodeId),
    toggleIsFiltered: handleReachedGoalScreenFilterToggle,
  }
}
