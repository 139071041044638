import * as React from "react"

import { UsabilityTestSection } from "Types"

interface Section {
  section: Readonly<UsabilityTestSection>
}
const SectionContext = React.createContext<Section | null>(null)

interface SectionProviderProps {
  value: Section
}

/**
 * @param {SectionProviderProps} props Props to pass to provider
 * @param {Section} props.value Initial state for context
 * @returns {React.ReactElement} Context Provider
 */
export const SectionProvider: React.FC<
  React.PropsWithChildren<SectionProviderProps>
> = ({ value, children }) => {
  return (
    <SectionContext.Provider value={value}>{children}</SectionContext.Provider>
  )
}

/**
 * Hook to access Section object from within a SectionProvider.
 *
 * @returns {Section}
 */
export const useSectionContext = (): Section => {
  const context = React.useContext(SectionContext)

  if (!context) {
    throw new Error(
      `useSectionContext must be rendered within the SectionProvider`
    )
  }

  return context
}
