import { BoxProps } from "@chakra-ui/react"
import React, { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"

import { setScreenshotViewed } from "Redux/reducers/screenshots/action-creators"
import { Screenshot as Screenshot } from "Types"

import { AudioScreenshot } from "./AudioScreenshot"
import { ImageScreenshot } from "./ImageScreenshot"
import { VideoScreenshot } from "./VideoScreenshot"

export interface UnscaledScreenshotProps extends BoxProps {
  screenshot: Screenshot
  canSkipAheadOnFirstWatch: boolean
}

export const UnscaledScreenshot: React.FC<
  React.PropsWithChildren<UnscaledScreenshotProps>
> = ({ screenshot, canSkipAheadOnFirstWatch, ...boxProps }) => {
  const dispatch = useDispatch()
  const onScreenshotViewed = useCallback(() => {
    if (!screenshot._isViewed) {
      dispatch(setScreenshotViewed(screenshot._clientId))
    }
  }, [screenshot, dispatch])
  useEffect(() => {
    // Images are considered 'viewed' immediately
    if (screenshot.media_type === "image") {
      onScreenshotViewed()
    }
  }, [onScreenshotViewed, screenshot])
  switch (screenshot.media_type) {
    case "image":
      return <ImageScreenshot screenshot={screenshot} {...boxProps} />
    case "video":
      return (
        <VideoScreenshot
          onMediaEnded={onScreenshotViewed}
          screenshot={screenshot}
          canSkipAhead={canSkipAheadOnFirstWatch || screenshot._isViewed}
          {...boxProps}
        />
      )
    case "audio":
      return (
        <AudioScreenshot
          onMediaEnded={onScreenshotViewed}
          screenshot={screenshot}
          canSkipAhead={canSkipAheadOnFirstWatch || screenshot._isViewed}
          {...boxProps}
        />
      )
  }
}
