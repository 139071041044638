import { Box, BoxProps, Center, Tooltip } from "@chakra-ui/react"
import React from "react"

import { CardSortCard } from "Types"

type CellProps = {
  value: number
  firstCard: CardSortCard
  secondCard: CardSortCard
} & BoxProps

export const Cell: React.FC<CellProps> = ({
  value,
  firstCard,
  secondCard,
  ...props
}) => {
  if (value === 0) {
    return (
      <Box flexShrink={0} boxSize={10} bg="gray.50" rounded="md" {...props} />
    )
  }

  const bg = value >= 0.3 ? "brand.primary.300" : "brand.primary.100"
  const percentage = Math.round(value * 100)
  const tooltip = `${percentage}% of all participants sorted “${firstCard.label}” and “${secondCard.label}” together`

  return (
    <Tooltip hasArrow label={tooltip} textAlign="center">
      <Center
        bg={bg}
        rounded="md"
        userSelect="none"
        boxSize={10}
        flexShrink={0}
        _hover={{
          outlineWidth: 2,
          outlineStyle: "solid",
          outlineColor: "gray.700",
        }}
        {...props}
      >
        {percentage}
      </Center>
    </Tooltip>
  )
}
