import React, { PropsWithChildren, createContext, useState } from "react"

import { FigmaTaskScreenResult } from "Types"

import { ScreenView } from "./ScreenResults"

const DEFAULT_VIEW: ScreenView = "IMAGE"

export type ScreenResultDetailsContextType = {
  view: ScreenView
  setView: (newView: ScreenView) => void
  screenResult?: FigmaTaskScreenResult
}

export const ScreenResultDetailsContext =
  createContext<ScreenResultDetailsContextType>({
    view: DEFAULT_VIEW,
    setView: () => null,
  })

interface ScreenResultDetailsContextProviderProps extends PropsWithChildren {
  screenResult: FigmaTaskScreenResult
}

export function ScreenResultDetailsContextProvider({
  screenResult,
  children,
}: ScreenResultDetailsContextProviderProps) {
  const [view, setView] = useState<ScreenView>(DEFAULT_VIEW)

  return (
    <ScreenResultDetailsContext.Provider
      value={{
        view,
        setView,
        screenResult,
      }}
    >
      {children}
    </ScreenResultDetailsContext.Provider>
  )
}
