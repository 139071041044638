import { Box } from "@chakra-ui/react"
import {
  Duration,
  Item,
  Name,
  Percentage,
  Thumbnail,
  WrappedButton,
} from "Components/test-results/progress-box/progress-box"
import { ScreenshotClickResults } from "Components/test-results/screenshot-click-results/screenshot-click-results-container"
import { ExpandableSectionScreenshotSummary } from "Components/test-results/section-results/expandable-section-screenshot-summary"
import styles from "Components/test-results/section-results/navigation-test-results-step-summary.module.scss"
import { State } from "Redux/app-store"
import { getScreenshotWithId } from "Redux/reducers/screenshots/selectors"
import {
  DeviceFrame,
  NavigationStepSummary,
  Omit,
  Screenshot,
  UsabilityTestSectionScreenshot,
} from "Types"
import React, { useState } from "react"
import { connect } from "react-redux"
import {
  MultiSegmentProgress,
  progressColors,
} from "~/usabilityhub/components/MultiSegmentProgressBar/MultiSegmentProgressBar"
import { NavigationTestResultsStepProvider } from "./NavigationTestResultsStepContext"

interface ImplProps {
  deviceFrame: Readonly<DeviceFrame> | null
  sectionScreenshot: Readonly<UsabilityTestSectionScreenshot>
  stepNumber: number // Must be provided separately in case `position` is wrong.
  stepSummary: Readonly<NavigationStepSummary>
  screenshot: Screenshot
}

const NavigationTestResultsStepSummaryImpl: React.FC<ImplProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggleDetailsClick = () => {
    setIsExpanded((v) => !v)
  }

  const {
    deviceFrame,
    screenshot,
    sectionScreenshot,
    stepNumber,
    stepSummary: { averageDuration, hitRate, missRate, participationRate },
  } = props

  const progressValues: { [key: string]: number } = {}

  progressValues[progressColors.success] = hitRate * participationRate * 100
  progressValues[progressColors.tentative] = missRate * participationRate * 100

  const summaryNode = (
    <>
      <Thumbnail
        screenshot={screenshot}
        onClick={handleToggleDetailsClick}
        isExpanded={isExpanded}
      />
      <Name>Step {stepNumber}</Name>
      <Item className={styles.progressContainer}>
        <MultiSegmentProgress
          values={progressValues}
          max={100}
          variant="round"
          size="sm"
        />
      </Item>
      <Duration duration={averageDuration} />
      <Percentage className={styles.hitPercentage} percentage={hitRate} />
      <Percentage className={styles.missPercentage} percentage={missRate} />
      <WrappedButton onClick={handleToggleDetailsClick} minW={28} ml={2}>
        {isExpanded ? "Hide details" : "Show details"}
      </WrappedButton>
    </>
  )

  const detailsNode = (
    <Box
      px={3} // Nav test has a border so add some breathing room.
    >
      <NavigationTestResultsStepProvider
        stepNumber={stepNumber}
        sectionScreenshot={sectionScreenshot}
      >
        <ScreenshotClickResults
          deviceFrame={deviceFrame}
          sectionScreenshot={sectionScreenshot}
        />
      </NavigationTestResultsStepProvider>
    </Box>
  )

  return (
    <ExpandableSectionScreenshotSummary
      isExpanded={isExpanded}
      summaryChildren={summaryNode}
      detailsChildren={detailsNode}
      isFilter={false}
      isFiltered={false}
    />
  )
}

type Props = Omit<ImplProps, "screenshot">

export const NavigationTestResultsStepSummary = connect(
  (state: State, ownProps: Props) => ({
    screenshot: getScreenshotWithId(
      state,
      ownProps.sectionScreenshot.screenshot_id
    ),
  })
)(NavigationTestResultsStepSummaryImpl)
