import {
  Flex,
  Heading,
  Link,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import React from "react"

import { CreditScale } from "Types"
import { centsToDollars, formatDollars } from "Utilities/currency"

interface Props {
  scale: ReadonlyArray<Readonly<CreditScale>>
}

export const CreditPricePopover: React.FC<React.PropsWithChildren<Props>> = ({
  scale,
}) => {
  return (
    <Stack p={2}>
      <Heading size="sm" mb={4}>
        Credits get cheaper the more you buy
      </Heading>
      <Stack divider={<StackDivider />} spacing={2}>
        {scale.map((s) => (
          <Flex key={s.from} justify="space-between">
            <Text>
              {s.from} {s.to ? `– ${s.to}` : "and up"}
            </Text>
            <Text>
              {formatDollars(centsToDollars(s.price_in_cents))}
              /credit
            </Text>
          </Flex>
        ))}
        <Stack spacing={0}>
          <Text>Want to save even more?</Text>
          <Link href="/credits/purchase" target="_blank">
            Purchase a bulk credit pack.
          </Link>
        </Stack>
      </Stack>
    </Stack>
  )
}
