import { Box, Input, ListItem, OrderedList, Text } from "@chakra-ui/react"
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"

import { EditableTag } from "Components/tag-editor/editable-tag"
import { IndeterminateBoolean } from "Types"
import { isEnter } from "Utilities/keyboard-event"
import { findExact, textSearch } from "Utilities/search"

interface TagInfo<T> {
  readonly tag: Readonly<T>
  readonly checkboxState: IndeterminateBoolean
}

interface Props<T> {
  disableCheckboxes: boolean
  disableInput: boolean
  getName: (tag: T) => string
  getLabel: (tag: T) => ReactNode
  identify: (tag: T) => string
  onCheckboxChange: (tag: T, value: boolean) => void
  onCreateTag: (label: string) => void
  onEditTag: (tag: T) => void
  onRemoveTag: (tag: T) => void
  tagInfos: ReadonlyArray<TagInfo<T>>
}

export function TagEditor<T>({
  tagInfos,
  disableInput,
  disableCheckboxes,
  getName,
  getLabel,
  identify,
  onCheckboxChange,
  onEditTag,
  onRemoveTag,
  onCreateTag,
}: Props<T>): JSX.Element {
  const [input, setInput] = useState("")
  const inputRef = useRef<HTMLInputElement | null>(null)

  const searchTerm = input.trim()
  const getTagInfoName = (tagInfo: TagInfo<T>): string => {
    return getName(tagInfo.tag)
  }
  const exactMatch = findExact(tagInfos, searchTerm, getTagInfoName)
  const visibleTagInfos =
    exactMatch === null
      ? textSearch(tagInfos, searchTerm, getTagInfoName)
      : [exactMatch]

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInput(e.target.value)
  }

  const handleCreateTag = () => {
    const cleanInput = input.trim()
    onCreateTag(cleanInput)
    setInput("")
    inputRef.current?.focus()
  }

  const handleKeyDown: KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.target !== inputRef.current || !isEnter(event)) return

    if (visibleTagInfos.length === 0) {
      // If there are no options create one.
      handleCreateTag()
    } else if (visibleTagInfos.length === 1 && !disableCheckboxes) {
      // If there is one option (and it's not disabled) toggle it.
      const { tag, checkboxState } = visibleTagInfos[0]
      onCheckboxChange(tag, !checkboxState)
    }
  }

  useEffect(() => {
    if (disableInput) return

    const setFocus = setTimeout(() => {
      inputRef.current?.focus()
    }, 10)

    return () => clearTimeout(setFocus)
  }, [disableInput])

  return (
    <Box onKeyDown={handleKeyDown} data-qa="tag-editor" py={2}>
      <Box px={3}>
        <Input
          disabled={disableInput}
          onChange={handleInputChange}
          placeholder={
            visibleTagInfos.length === 0 ? "Add tag" : "Add/search tags"
          }
          ref={inputRef}
          value={input}
          data-qa=""
        />
      </Box>
      <OrderedList
        spacing={3}
        styleType="none"
        m="0"
        mt="2"
        maxH="300px"
        overflowY="auto"
        px={3}
      >
        {visibleTagInfos.map(({ checkboxState, tag }) => (
          <ListItem key={identify(tag)}>
            <EditableTag
              tag={tag}
              getLabel={getLabel}
              onEdit={onEditTag}
              onRemove={onRemoveTag}
              onCheckboxChange={onCheckboxChange}
              checkboxValue={checkboxState}
              disableCheckbox={disableCheckboxes}
            />
          </ListItem>
        ))}
        {searchTerm !== "" && exactMatch === null && (
          <ListItem>
            <Text
              as="button"
              w="100%"
              pt="2"
              pb="1"
              noOfLines={1}
              onClick={handleCreateTag}
              whiteSpace="nowrap"
              wordBreak="break-all"
              textAlign="left"
            >
              Add tag “{searchTerm}”
            </Text>
          </ListItem>
        )}
      </OrderedList>
    </Box>
  )
}
