import { useToast } from "@chakra-ui/react"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { CreditCardDeclinedError } from "Services/stripe"
import { useStripeCards } from "UsabilityHub/hooks/useStripeCards"
import { ReactNode } from "react"

interface useCreditCardFieldsOptions {
  cardDeclinedError: ReactNode
  cardName: string
  onError?: () => void
  onLoad?: () => void
  onSuccess?: () => void
}
interface useCreditCardFieldsReturnType {
  handleSubmit: () => Promise<boolean>
}

export const useCreditCardFields = ({
  cardDeclinedError,
  cardName,
  onError,
  onLoad,
  onSuccess,
}: useCreditCardFieldsOptions): useCreditCardFieldsReturnType => {
  const stripe = useStripe()
  const elements = useElements()
  const toast = useToast()
  const { replaceCustomerCard } = useStripeCards()

  const handleSubmit = async () => {
    try {
      onLoad && onLoad()
      toast.closeAll()
      const cardElement = elements ? elements.getElement(CardElement) : null
      await replaceCustomerCard(cardElement, cardName, stripe)
      onSuccess && onSuccess()
      return true
    } catch (error) {
      onError && onError()
      const errorMessage: ReactNode =
        error instanceof CreditCardDeclinedError
          ? cardDeclinedError
          : (error.message as string)
      toast({
        status: "error",
        title: errorMessage,
      })
      return false
    }
  }

  return {
    handleSubmit,
  }
}
