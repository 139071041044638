import classNames from "classnames"
import { nanoid } from "nanoid"
import React, { ChangeEvent, KeyboardEvent, PureComponent } from "react"

import { ReactOption } from "Types"

import styles from "./button-select.module.scss"
import { defaultValueToKey } from "./default-value-to-key"

export interface Props<T> {
  value: T | null
  onChange: (value: T) => void
  options: ReadonlyArray<ReactOption<T>>
  valueToKey?: (value: T) => string
  required?: boolean
}

// @deprecated: We should replace this component with Chakra radio buttons with custom styles.
// See: https://chakra-ui.com/docs/components/radio#custom-radio-buttons
export class ButtonSelect<T> extends PureComponent<Props<T>> {
  static defaultProps = {
    valueToKey: defaultValueToKey,
  }
  private handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { options, onChange } = this.props
    onChange(options[parseInt(event.target.value, 10)].value)
  }

  private handleKeyPress = (event: KeyboardEvent<HTMLLIElement>) => {
    if (event.key === " ") {
      const { currentTarget } = event
      const { options, onChange } = this.props
      const index = parseInt(currentTarget.getAttribute("data-value")!, 10)
      onChange(options[index].value)
      // Disable scroll behaviour induced by pressing space
      event.preventDefault()
    }
  }

  render() {
    const { options, valueToKey, value, required } = this.props
    const optionNodes = options.map((o, i) => {
      const isSelected = o.value === value
      const id = nanoid()
      return (
        <li
          className={classNames(styles.item, isSelected && styles.isSelected)}
          data-value={i}
          key={valueToKey!(o.value)}
          onKeyPress={this.handleKeyPress}
          // biome-ignore lint/a11y/noNoninteractiveTabindex: Only making the minimal set of changes to resolve PRD-4758. We should fix this in a follow-up. We should replace this entire component with Chakra radio buttons with custom styles.
          tabIndex={0}
        >
          <input
            className={styles.input}
            id={id}
            type="radio"
            value={i}
            checked={isSelected}
            onChange={this.handleChange}
            required={required}
          />
          <label className={styles.label} htmlFor={id}>
            {o.label}
          </label>
        </li>
      )
    })
    return <ul className={styles.list}>{optionNodes}</ul>
  }
}
