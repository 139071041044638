import { Box, BoxProps, Flex, HStack, Stack, Text } from "@chakra-ui/react"
import { useLocalCommentContext } from "Components/comment-provider/CommentProvider"
import { CommentThread } from "Components/comment-thread/CommentThread"
import { entityIdentifiersMatch } from "Components/comment-thread/entities"
import { getSectionTypeRules } from "Constants/test-section-types"
import { useUndoToast } from "Hooks/use-undo-toast"
import { Dispatch } from "Redux/app-store"
import {
  deleteQuestion,
  duplicateQuestion,
} from "Redux/reducers/test-builder-form/action-creators/questions"
import { CommonFields } from "UsabilityHub/components/TestForm/TestFormCards/SectionCards/SectionQuestions/QuestionFields/CommonFields"
import { LinearScaleFields } from "UsabilityHub/components/TestForm/TestFormCards/SectionCards/SectionQuestions/QuestionFields/LinearScaleFields"
import { MultipleChoiceFields } from "UsabilityHub/components/TestForm/TestFormCards/SectionCards/SectionQuestions/QuestionFields/MultipleChoice/MultipleChoiceFields"
import { useTestLogicStatement } from "UsabilityHub/components/TestForm/TestFormCards/SectionCards/TestLogicActions/useTestLogicStatement"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useQuestionContext,
  useSectionContext,
  useSectionIndexContext,
  useSectionTypeContext,
} from "UsabilityHub/contexts"
import {
  getQuestionPrefix,
  getQuestionTypeLabel,
  isLinearScaleQuestion,
  isMultipleChoice,
} from "Utilities/usability-test-section-question"
import React, { useMemo, useState } from "react"
import { Draggable } from "react-beautiful-dnd"
import { useDispatch } from "react-redux"
import { FormSection } from "redux-form"
import { Actions } from "../Actions/Actions"
import { QuestionLogicActions } from "../TestLogicActions/TestLogicActions"
import { useQuestionPath } from "../useQuestionPath"
import { DraggableQuestionRow } from "./DragDrop/DraggableQuestionRow"
import { useQuestionDefaultRandomized } from "./useQuestionDefaultRandomized"

type Props = BoxProps & {
  numberLabel?: React.ReactNode
}

export const SectionQuestion: React.FC<React.PropsWithChildren<Props>> = ({
  numberLabel,
  ...boxProps
}) => {
  const { readOnly } = useUsabilityTestUserActivityContext()
  const { question, questionIndex } = useQuestionContext()
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const questionPath = useQuestionPath()

  const { activeThread } = useLocalCommentContext()
  const commentableEntity = useMemo(
    () =>
      ({
        entityContext: "test_builder",
        entityType: "usability_test_section_question",
        entityId: question.id ? String(question.id) : question._clientId,
      }) as const,
    [question.id, question._clientId]
  )

  const isCommentThreadOpen = activeThread
    ? entityIdentifiersMatch(activeThread, commentableEntity)
    : false

  // Set default randomization based on question type
  useQuestionDefaultRandomized()

  return (
    <Draggable
      draggableId={question._clientId}
      key={question._clientId}
      index={questionIndex}
      isDragDisabled={readOnly || section.questions.length === 1}
    >
      {(draggable, snapshot) => (
        <DraggableQuestionRow
          draggableProvided={draggable}
          snapshot={snapshot}
          borderTop="1px solid"
          borderColor="gray.200"
          boxShadow={
            isCommentThreadOpen
              ? "inset 1px 1px 0px var(--chakra-colors-teal-600), inset -1px -1px 0px var(--chakra-colors-teal-600);"
              : undefined
          }
          {...boxProps}
        >
          <Stack
            spacing={6}
            px={8}
            py={8}
            data-qa={`section-question-${questionIndex}`}
            data-commentable={`usability_test_section_question:${
              question.id || question._clientId
            }`} // Needed for showing the comment widget on hover
          >
            <Box
              position="relative" // Needed for positioning the comment widget correctly
            >
              <Flex>
                <HStack flexGrow={1}>
                  <Text as="label" textStyle="label">
                    {numberLabel ??
                      getQuestionPrefix(sectionIndex + 1, questionIndex)}{" "}
                    {getQuestionTypeLabel(question.type)}
                  </Text>
                </HStack>
                {!readOnly && <SectionQuestionActions />}
              </Flex>

              <CommentThread
                entity={commentableEntity}
                isEntityPersisted={question.id !== null}
              />
            </Box>
            <QuestionLogicActions />
            <FormSection
              name={questionPath} // This prepends the question path to all nested Field/s components
            >
              <Stack spacing={6}>
                <CommonFields draggable={draggable} />
                {isMultipleChoice(question.type) && <MultipleChoiceFields />}
                {isLinearScaleQuestion(question) && <LinearScaleFields />}
              </Stack>
            </FormSection>
          </Stack>
        </DraggableQuestionRow>
      )}
    </Draggable>
  )
}

const SectionQuestionActions: React.FC = () => {
  const questionPath = useQuestionPath()

  const { isEnabled, setEnabled } = useTestLogicStatement(questionPath)

  const dispatch = useDispatch<Dispatch>()
  const { question, questionIndex } = useQuestionContext()
  const undoToast = useUndoToast()
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const sectionType = useSectionTypeContext()

  const isDeleteDisabled =
    section.questions.length <= getSectionTypeRules(sectionType).minQuestions

  const onDelete = () => {
    dispatch(deleteQuestion(sectionIndex, questionIndex))
    undoToast(getQuestionTypeLabel(question.type))
  }
  const onDuplicate = () => {
    dispatch(duplicateQuestion(sectionIndex, questionIndex))
  }

  const [isActionsModalActive, setIsActionsModalActive] = useState(false)

  return (
    <Box
      visibility={isEnabled || isActionsModalActive ? "visible" : "hidden"}
      sx={{
        // This simulates groupHover functionality but for a specific named group
        // since otherwise it conflicts with other groups in the hierarchy.
        [`[data-qa='section-question-${questionIndex}']:hover &`]: {
          visibility: "visible",
        },
      }}
    >
      <Actions
        onDuplicate={onDuplicate}
        onDelete={onDelete}
        isEnabled={isEnabled}
        setEnabled={setEnabled}
        isSection={false}
        setIsActionsModalActive={setIsActionsModalActive}
        isDeleteDisabled={isDeleteDisabled}
      />
    </Box>
  )
}
