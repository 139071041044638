import {
  Box,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
  UseRadioProps,
  useRadio,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react"
import { BanIcon, CashIcon } from "@heroicons/react/outline"
import { ArrowLeftIcon } from "@heroicons/react/solid"
import { ErrorMessage } from "@hookform/error-message"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import {
  ModeratedStudyRecruitmentLinkSummaryCard,
  getRecruitmentLinkLink,
} from "UsabilityHub/views/interviews/$uniqueId/recruit/ModeratedStudyRecruitmentLinkSummaryCard"
import { ROUTES } from "UsabilityHub/views/routes"
import { getDateString } from "Utilities/date-formats"
import { pluralize, pluralizeWithCount } from "Utilities/string"
import copy from "copy-to-clipboard"
import { debounce } from "lodash"
import React, { useCallback, useEffect } from "react"
import { Helmet } from "react-helmet"
import {
  FieldValues,
  Path,
  PathValue,
  SubmitHandler,
  UseControllerProps,
  useController,
  useForm,
} from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import * as Yup from "yup"
import {
  useGetModeratedStudyQuota,
  useGetModeratedStudyRecruitmentLink,
  useUpdateModeratedStudiesRecruitmentLink,
} from "~/api/generated/usabilityhub-components"
import { ModeratedStudyRecruitmentLink } from "~/api/generated/usabilityhubSchemas"

const INCENTIVE_OPTIONS = ["none", "custom"] as const
const SELECTION_METHODS = ["handpick", "automatic"] as const
const SELECTION_METHOD_OPTIONS = {
  automatic: {
    label: "Automatic",
    description:
      "Eligible participants will be able to book a session with you right away. Recommended for studies that don't require a one by one review of each participant.",
  },
  handpick: {
    label: "Handpick",
    description:
      "Manually select participants based on their screener responses. Recommended for studies with open-ended questions or quotas.",
  },
}

export function ModeratedStudyRecruitmentLinkEditPage() {
  const { moderatedStudyId, moderatedStudyRecruitmentLinkId } = useTypedParams(
    ROUTES.INTERVIEW.RECRUIT.LINK
  )

  const { data, isLoading } = useGetModeratedStudyRecruitmentLink({
    pathParams: {
      moderatedStudyId: moderatedStudyId,
      moderatedStudyRecruitmentLinkId: moderatedStudyRecruitmentLinkId,
    },
  })

  return (
    <main>
      <Helmet>
        <title>Recruit</title>
      </Helmet>
      <Box mt="8" w="full" maxW="1007px" mx="auto">
        <Stack spacing="4" alignItems="center">
          <Heading fontSize="2xl" fontWeight="normal">
            Recruit participants with a link
          </Heading>
          <Link
            as={RouterLink}
            to={ROUTES.INTERVIEW.RECRUIT.buildPath({ moderatedStudyId })}
            textDecoration="none"
          >
            <HStack>
              <Icon as={ArrowLeftIcon} mr={1} />
              <Text>Back to recruitment options</Text>
            </HStack>
          </Link>
        </Stack>

        {isLoading && (
          <Flex
            mt="32"
            justifyContent="center"
            alignItems="center"
            direction="column"
            gap="4"
          >
            <Spinner size="lg" thickness="4px" color="gray.500" />
            Fetching...
          </Flex>
        )}

        {!isLoading && data && (
          <Box
            my="10"
            bg="ds.surface.overlay.default"
            p="5"
            rounded="16px"
            borderWidth="1px"
            borderColor="ds.border.hovered"
          >
            <ModeratedStudyRecruitmentLinkSummaryCard
              recruitmentLink={data}
              moderatedStudyId={moderatedStudyId}
              showMenu={false}
            />
            <Form moderatedStudyRecruitmentLink={data} />
          </Box>
        )}
      </Box>
    </main>
  )
}

interface FormProps {
  moderatedStudyRecruitmentLink: ModeratedStudyRecruitmentLink
}

// This schema will need to stay in sync with the request body of the
// `useUpdateModeratedStudiesRecruitmentLink` hook below since the result of the form is passed
// directly to the mutation.
const ModeratedStudyRecruitmentLinkSchema = Yup.object()
  .required()
  .shape({
    selection_method: Yup.mixed<(typeof SELECTION_METHODS)[number]>()
      .required()
      .oneOf(SELECTION_METHODS),
    number_of_bookings: Yup.number().required().min(1),
    incentive_type: Yup.mixed<(typeof INCENTIVE_OPTIONS)[number]>()
      .required()
      .oneOf(INCENTIVE_OPTIONS),
    incentive_text: Yup.string()
      .defined()
      .when("incentive_type", ([incentiveType], schema) =>
        incentiveType === "custom"
          ? schema.required("You must provide a custom incentive")
          : schema.nullable()
      ),
  })

type RecruitmentLinkFormType = Yup.InferType<
  typeof ModeratedStudyRecruitmentLinkSchema
>

const Form: React.FC<FormProps> = ({ moderatedStudyRecruitmentLink }) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const {
    handleSubmit,
    register, // For attaching to inputs so react-form knows about it (value and onChange handler that you splat onto the input)
    setValue, // For manually wiring up fields with a different onChange signature (e.g. Chakra NumberInput)
    watch, // Get react state updates when state changes (i.e. incentive field)
    control, // Used to set up the custom radio groups (managed by useController)
    formState: { errors },
  } = useForm<RecruitmentLinkFormType>({
    mode: "all",
    resolver: yupResolver(ModeratedStudyRecruitmentLinkSchema),
    defaultValues: {
      selection_method: moderatedStudyRecruitmentLink.recruitment_mode,
      number_of_bookings:
        moderatedStudyRecruitmentLink.maximum_number_of_bookings ?? 5,
      incentive_type: moderatedStudyRecruitmentLink.incentive_type,
      incentive_text: moderatedStudyRecruitmentLink.incentive_text ?? "",
    },
  })

  const handleCopyRecruitmentLink = () => {
    const link = getRecruitmentLinkLink(moderatedStudyRecruitmentLink.token)
    copy(link)

    toast({
      title: "Link copied to clipboard",
      status: "success",
      duration: 3000,
    })
  }

  const { mutate } = useUpdateModeratedStudiesRecruitmentLink({
    onSuccess: () => {
      toast({
        title: "Recruitment link was successfully updated",
        status: "success",
        duration: 4000,
      })
      return queryClient.invalidateQueries([
        "api",
        "moderated_studies",
        moderatedStudyRecruitmentLink.moderated_study_id,
        "recruitment_links",
        moderatedStudyRecruitmentLink.id,
      ])
    },
    onError: (error) => {
      toast({
        title: "Error",
        description:
          error?.payload?.message ??
          "There was a problem saving the recruitment link",
        status: "error",
      })
    },
  })

  const onSubmit: SubmitHandler<RecruitmentLinkFormType> = (values) => {
    mutate({
      pathParams: {
        moderatedStudyId: moderatedStudyRecruitmentLink.moderated_study_id,
        moderatedStudyRecruitmentLinkId: moderatedStudyRecruitmentLink.id,
      },
      body: values,
    })
  }

  const debouncedOnSubmit = useCallback(
    debounce(handleSubmit(onSubmit), 1000),
    []
  )

  useEffect(() => {
    const subscription = watch(() => {
      void debouncedOnSubmit()
    })

    return () => subscription.unsubscribe()
  })

  const selectionMethod = watch("selection_method")
  const incentiveType = watch("incentive_type")

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack spacing={6}>
        <Flex
          direction="column"
          mt="4"
          mx="-5"
          p="5"
          bgColor="ds.surface.sunken"
          borderColor="ds.border.default"
          borderWidth="1px 0"
          gap={2}
        >
          <Text fontSize="md" color="gray.700" fontWeight="medium">
            Recruitment link
          </Text>

          <Flex align="center" gap={4}>
            <InputGroup>
              <InputRightElement w="fit-content" pe={2}>
                <Link
                  variant="noUnderline"
                  whiteSpace="nowrap"
                  onClick={handleCopyRecruitmentLink}
                >
                  Copy link
                </Link>
              </InputRightElement>
              <Input
                isReadOnly
                defaultValue={getRecruitmentLinkLink(
                  moderatedStudyRecruitmentLink.token
                )}
                flexGrow={1}
              />
            </InputGroup>

            <Stat
              value={moderatedStudyRecruitmentLink.opened_count}
              label="Opened"
            />
            <Stat
              value={
                moderatedStudyRecruitmentLink.applicant_counts.eligible +
                moderatedStudyRecruitmentLink.applicant_counts.ineligible
              }
              label="Applied"
            />
            <Stat
              value={
                moderatedStudyRecruitmentLink.booking_counts.upcoming +
                moderatedStudyRecruitmentLink.booking_counts.past
              }
              label="Booked"
            />
          </Flex>
        </Flex>

        <Flex direction="column">
          <Text fontSize="md" color="gray.700" fontWeight="medium" mb="2">
            Selection method
          </Text>
          <CustomRadioGroup<RecruitmentLinkFormType>
            control={control}
            name="selection_method"
            options={SELECTION_METHODS}
            elementWrapperProps={{ h: "auto" }}
            w="full"
            gap={4}
            sx={{
              // Make sure children are equally sized
              "> *": {
                flexBasis: 0,
                flexGrow: 1,
              },
            }}
          >
            {(value, selected) => {
              // In practice `value` can only be one of the `options` so this is safe to do.
              const safeValue = value as keyof typeof SELECTION_METHOD_OPTIONS
              const { label, description } = SELECTION_METHOD_OPTIONS[safeValue]
              return (
                <Flex align="center">
                  <Box
                    boxSize={5}
                    rounded="100%"
                    bg="white"
                    borderColor={selected ? "brand.primary.500" : "gray.200"}
                    borderWidth={selected ? 6 : 2}
                    transition="0.1s border-width"
                    ms={1}
                    me={5}
                    flexShrink={0}
                  />

                  <Flex direction="column" gap={2}>
                    <Text fontSize="lg" fontWeight="semibold">
                      {label}
                    </Text>
                    <Text
                      fontSize="sm"
                      color="text.secondary"
                      whiteSpace="normal"
                    >
                      {description}
                    </Text>
                  </Flex>
                </Flex>
              )
            }}
          </CustomRadioGroup>
        </Flex>

        {selectionMethod === "automatic" && (
          <Flex direction="column" gap={2}>
            <Text fontSize="md" color="gray.700" fontWeight="medium">
              Automatic booking limit
            </Text>

            <Text color="text.secondary" fontSize="sm" fontWeight="medium">
              The number of applicants who can book a session immediately after
              passing your screener. Others can still apply, and can be manually
              invited at any time.
            </Text>

            <Flex align="center" gap={4}>
              <NumberInput
                ref={register("number_of_bookings").ref}
                maxW="100px"
                min={1}
                onChange={(_, numericValue) => {
                  setValue("number_of_bookings", numericValue)
                }}
              >
                <NumberInputField
                  onInput={(e: React.FormEvent<HTMLInputElement>) => {
                    const numericValue = parseInt(e.currentTarget.value)

                    if (Number.isNaN(numericValue) || numericValue < 1) {
                      e.currentTarget.value = "1"
                    }
                  }}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <SessionsRemainingTag />
            </Flex>
          </Flex>
        )}

        <Box>
          <Text fontSize="md" color="gray.700" fontWeight="medium" mb="2">
            Incentive
          </Text>
          <CustomRadioGroup
            control={control}
            name="incentive_type"
            options={INCENTIVE_OPTIONS}
            gap={2}
          >
            {(value) => (
              <Icon boxSize={6} as={value === "custom" ? CashIcon : BanIcon} />
            )}
          </CustomRadioGroup>
          {incentiveType === "custom" && (
            <FormControl isInvalid={!!errors["incentive_text"]} mt={2}>
              <Input
                placeholder="Enter incentive here (e.g. Amazon gift voucher worth $50)"
                isInvalid={!!errors["incentive_text"]}
                focusBorderColor={
                  errors["incentive_text"] ? "red.500" : undefined
                }
                errorBorderColor="red.500"
                {...register("incentive_text")}
              />
              <FormErrorMessage>
                <ErrorMessage name="incentive_text" errors={errors} />
              </FormErrorMessage>
            </FormControl>
          )}
        </Box>
      </Stack>
    </form>
  )
}

interface StatProps {
  label: string
  value: number
}

const Stat: React.FC<StatProps> = ({ label, value }) => {
  return (
    <Box>
      <Text fontSize="lg" fontWeight="semibold" color="gray.700">
        {value}
      </Text>
      <Text color="text.secondary" fontSize="sm" fontWeight="medium">
        {label}
      </Text>
    </Box>
  )
}

type CustomRadioProps = {
  value: string
  radioProps: UseRadioProps
  wrapperProps: ButtonProps
  children: (option: string, selected: boolean) => React.ReactNode
}

const CustomRadio = React.forwardRef<HTMLInputElement, CustomRadioProps>(
  ({ children, value, radioProps, wrapperProps }, ref) => {
    const { getInputProps, getRadioProps } = useRadio(radioProps)
    const input = getInputProps({ ref })
    const radio = getRadioProps()

    return (
      <Box as="label">
        <input style={{ display: "none" }} {...input} />
        <Button
          as={Box}
          {...radio}
          aria-label={value}
          variant="outline"
          color="brand.neutral.light"
          fontSize="xs"
          px="6"
          py="4"
          margin="1px"
          _checked={{
            borderColor: "brand.primary.500",
            borderWidth: "2px",
            margin: "0",
          }}
          cursor="pointer"
          {...wrapperProps}
        >
          {children(value, radioProps.isChecked ?? false)}
        </Button>
      </Box>
    )
  }
)

CustomRadio.displayName = "RadioButton"

type CustomRadioGroupProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<FlexProps, "children"> & {
    options: readonly string[]
    children: CustomRadioProps["children"]
    elementWrapperProps?: ButtonProps
  }

const CustomRadioGroup = <T extends FieldValues>({
  name,
  control,
  options,
  children,
  elementWrapperProps = {},
  ...props
}: CustomRadioGroupProps<T>) => {
  const { field } = useController({
    name,
    control,
  })

  const { getRootProps, getRadioProps } = useRadioGroup({
    ...field,
    // Convert the type of useController to what Chakra's useRadioGroup expects
    onChange: (newValue: PathValue<T, Path<T>>) => {
      field.onChange(newValue)
    },
  })

  return (
    <Flex {...getRootProps()} {...props}>
      {options.map((value) => {
        return (
          <CustomRadio
            key={value}
            value={value}
            radioProps={getRadioProps({ value })}
            wrapperProps={elementWrapperProps}
          >
            {children}
          </CustomRadio>
        )
      })}
    </Flex>
  )
}

const SessionsRemainingTag: React.FC = () => {
  const { data } = useGetModeratedStudyQuota({})

  if (!data) return null

  const sessionsRemaining = Math.max(
    data.self_recruited_sessions.quota - data.self_recruited_sessions.used,
    0
  )

  const tooltipText =
    sessionsRemaining > 0
      ? `You can collect as many applicants and send as many invitations as you like, but only ${sessionsRemaining} more ${pluralize(
          sessionsRemaining,
          "participant",
          "participants"
        )} from your network, across all active studies, can book a session before your limit resets on ${getDateString(
          data.period_end
        )}.`
      : `You can continue setting up this study and collect applicants, but no new sessions can be booked until your balance renews on ${getDateString(
          data.period_end
        )}.`

  return (
    <Text fontSize="md" color="text.secondary" fontWeight="medium">
      <Tooltip hasArrow rounded="md" label={tooltipText}>
        <Tag>
          You have{" "}
          {pluralizeWithCount(sessionsRemaining, "session", "sessions")} left in
          this month's limit
        </Tag>
      </Tooltip>
    </Text>
  )
}
