import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { SubmitButton } from "Components/button/submit-button"
import {
  CostRow,
  CostRowLabel,
  CostRowValue,
  CostTable,
  CostTableBody,
  CostTableTotal,
  CostTotalLabel,
  CostTotalRow,
  CostTotalValue,
} from "Components/cost-table/cost-table"
import { JsForm } from "Components/form/form"
import { PurchaseConfirmationModal } from "Components/payments/purchase-confirmation-modal"
import Constants from "Constants/shared.json"
import { pluralizeWithCount } from "Utilities/string"

const pluralizeCredits = (n: number) =>
  pluralizeWithCount(n, "credit", "credits")

interface Props {
  creditCount: number
  onCancel: () => void
  onUpgrade: (creditsToBePurchased: number) => void
}

export const UpgradeUsabilityTestModal: React.FC<
  React.PropsWithChildren<Props>
> = ({ creditCount, onUpgrade, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const hasSufficientCredits =
    creditCount >= Constants.TEST_UPGRADE_COST_IN_CREDITS
  const creditsToBePurchased = hasSufficientCredits
    ? 0
    : Constants.TEST_UPGRADE_COST_IN_CREDITS - creditCount
  const formattedPrice = `$${creditsToBePurchased}.00`
  const costTable = (
    <CostTable>
      <CostTableBody>
        <CostRow>
          <CostRowLabel>1 &times; Test upgrade</CostRowLabel>
          <CostRowValue>
            {pluralizeCredits(Constants.TEST_UPGRADE_COST_IN_CREDITS)}
          </CostRowValue>
        </CostRow>
        <CostRow>
          <CostRowLabel>From your credit balance</CostRowLabel>
          <CostRowValue>
            {pluralizeCredits(
              Constants.TEST_UPGRADE_COST_IN_CREDITS - creditsToBePurchased
            )}
          </CostRowValue>
        </CostRow>
        <CostRow>
          <CostRowLabel>Credits required</CostRowLabel>
          <CostRowValue>{pluralizeCredits(creditsToBePurchased)}</CostRowValue>
        </CostRow>
        {!hasSufficientCredits && (
          <CostRow>
            <CostRowLabel>Cost per credit</CostRowLabel>
            <CostRowValue>$1.00</CostRowValue>
          </CostRow>
        )}
      </CostTableBody>
      <CostTableTotal>
        <CostTotalRow fullwidth>
          <CostTotalLabel>Total cost</CostTotalLabel>
          <CostTotalValue>{formattedPrice}</CostTotalValue>
        </CostTotalRow>
      </CostTableTotal>
    </CostTable>
  )

  // If account has no credits, skip straight to purchase modal
  const [showPurchaseModal, setShowPurchaseModal] = useState(creditCount === 0)
  if (showPurchaseModal) {
    return (
      <PurchaseConfirmationModal
        submitPurchaseAsync={() =>
          Promise.resolve(onUpgrade(creditsToBePurchased))
        }
        onCancel={onCancel}
        formattedPrice={formattedPrice}
        heading="Upgrade this test"
      />
    )
  }
  return hasSufficientCredits ? (
    <SufficientCreditsUpgradeModal
      costTable={costTable}
      onConfirm={() => {
        try {
          setIsSubmitting(true)
          onUpgrade(creditsToBePurchased)
        } catch (error) {
          setIsSubmitting(false)
        }
      }}
      onCancel={onCancel}
      isBusy={isSubmitting}
    />
  ) : (
    <InsufficientCreditsUpgradeModal
      costTable={costTable}
      onContinue={() => setShowPurchaseModal(true)}
      onCancel={onCancel}
    />
  )
}

const SufficientCreditsUpgradeModal: React.FC<
  React.PropsWithChildren<{
    costTable: React.ReactNode
    onConfirm: () => void
    onCancel: () => void
    isBusy: boolean
  }>
> = ({ costTable, onConfirm, onCancel, isBusy }) => (
  <Modal isOpen onClose={onCancel}>
    <ModalOverlay />
    <ModalContent>
      <JsForm onSubmit={onConfirm}>
        <ModalHeader>Upgrade this test</ModalHeader>
        <ModalBody>{costTable}</ModalBody>
        <ModalFooter>
          <HStack>
            <Button onClick={onCancel} isDisabled={isBusy}>
              Cancel
            </Button>
            <SubmitButton isLoading={isBusy}>Complete upgrade</SubmitButton>
          </HStack>
        </ModalFooter>
      </JsForm>
    </ModalContent>
  </Modal>
)

const InsufficientCreditsUpgradeModal: React.FC<
  React.PropsWithChildren<{
    costTable: React.ReactNode
    onContinue: () => void
    onCancel: () => void
  }>
> = ({ costTable, onContinue, onCancel }) => {
  return (
    <Modal isOpen onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upgrade this test</ModalHeader>
        <ModalBody>{costTable}</ModalBody>
        <ModalFooter>
          <HStack>
            <Button onClick={onCancel}>Cancel</Button>
            <Button colorScheme="brand.primary" onClick={onContinue}>
              Continue
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
