import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React from "react"

import { useUpdateProject } from "~/api/generated/usabilityhub-components"
import { NewProject } from "~/api/generated/usabilityhubSchemas"

interface Props {
  project: NewProject
  onClose: () => void
}

interface RenameFormElements extends HTMLFormControlsCollection {
  newName: HTMLInputElement
}

interface RenameForm extends HTMLFormElement {
  readonly elements: RenameFormElements
}

export const RenameProjectModal: React.FC<Props> = ({ project, onClose }) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { mutate: renameMutation, isLoading } = useUpdateProject({
    onSuccess: async (data) => {
      // Wait for the queries to be invalidated before closing the modal
      await Promise.all([
        queryClient.invalidateQueries(["api", "projects"]),
        queryClient.invalidateQueries(["api", "usability_tests"]),
      ])
      toast({
        title: "Project renamed",
        description: `Updated to ${data.name}`,
        status: "success",
        duration: 3000,
      })
      onClose()
    },
    onError: (error) => {
      toast({
        title: error.payload.message,
        status: "error",
        duration: 3000,
      })
    },
  })

  const handleRename = (newName: string) => {
    renameMutation({
      pathParams: { id: project.id.toString() },
      body: { name: newName },
    })
  }

  return (
    <Modal
      isOpen
      onClose={onClose}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay>
        <ModalContent>
          <form
            onSubmit={(e: React.FormEvent<RenameForm>) => {
              e.preventDefault()
              void handleRename(e.currentTarget.elements.newName.value)
            }}
          >
            <ModalHeader>
              <Heading>Rename project</Heading>
              <ModalCloseButton />
            </ModalHeader>

            <ModalBody>
              <FormControl>
                <FormLabel fontSize="sm">Project name</FormLabel>
                <Input autoFocus name="newName" defaultValue={project.name} />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button variant="outline" disabled={isLoading} onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand.primary"
                isLoading={isLoading}
                ml={3}
              >
                Rename
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
