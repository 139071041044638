import {
  UnpersistedUsabilityTestSectionScreenshot,
  UsabilityTestSection,
  UsabilityTestSectionScreenshot,
} from "Types"

import { validateHitzones } from "./validateHitzones"

const isProcessing = (
  screenshot:
    | UsabilityTestSectionScreenshot
    | UnpersistedUsabilityTestSectionScreenshot
) => screenshot.screenshot_id === null

export const validateScreenshot = (
  screenshot:
    | UsabilityTestSectionScreenshot
    | UnpersistedUsabilityTestSectionScreenshot,
  section: UsabilityTestSection
): Record<string, unknown> => ({
  screenshot_id: isProcessing(screenshot)
    ? `We${"\u2019"}re still processing your upload. Please wait.`
    : undefined,
  screenshot_hitzones: {
    _error: validateHitzones(screenshot.screenshot_hitzones, section),
  },
})
