import React from "react"

import { useInNoPlanState } from "Utilities/account"
import { Dashboard } from "./Dashboard"
import { NoPlanPage } from "./NoPlanPage"

export const DashboardRoute = () => {
  const inNoPlanState = useInNoPlanState()
  return inNoPlanState ? <NoPlanPage /> : <Dashboard />
}
