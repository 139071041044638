import { Box, Button, Flex, Icon } from "@chakra-ui/react"
import { Tag } from "DesignSystem/components"
import { Dispatch } from "Redux/app-store"
import { addQuestion } from "Redux/reducers/test-builder-form/action-creators/questions"
import { getFormName } from "Redux/reducers/test-builder-form/selectors/formValues"
import { AnnotationQuestionOutlineIcon } from "Shared/icons/untitled-ui/AnnotationQuestionOutlineIcon"
import { LiveWebsiteTestTask } from "Types"
import { TestCardDivider } from "UsabilityHub/components/TestCard/TestCard"
import { SectionQuestion } from "UsabilityHub/components/TestForm/TestFormCards/SectionCards/SectionQuestions/SectionQuestion"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  QuestionProvider,
  useSectionContext,
  useSectionIndexContext,
  useSectionTypeContext,
} from "UsabilityHub/contexts"
import React from "react"
import { useDispatch } from "react-redux"
import { arrayPush } from "redux-form"
import { DroppableQuestionRows } from "../SectionQuestions/DragDrop/DroppableQuestionRows"

type Props = {
  task: Pick<LiveWebsiteTestTask, "id" | "live_website_test_task_questions">
  taskPath: string
}

export const LiveWebsiteTaskQuestions: React.FC<Props> = ({
  task,
  taskPath,
}) => {
  const dispatch: Dispatch = useDispatch()
  const sectionType = useSectionTypeContext()
  const sectionIndex = useSectionIndexContext()
  const { section } = useSectionContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const onAddQuestion = () => {
    const newQuestion = dispatch(addQuestion(sectionIndex, sectionType))
    dispatch(
      arrayPush(getFormName(), `${taskPath}.live_website_test_task_questions`, {
        _clientId: newQuestion._clientId,
      })
    )
  }

  const taskQuestionIds = new Set(
    task.live_website_test_task_questions.map(
      (q) => q.usability_test_section_question_id ?? q._clientId
    )
  )
  const questions = section.questions.filter((q) =>
    taskQuestionIds.has(q.id ?? q._clientId)
  )
  const sectionQuestionClientIds = section.questions.map(
    (question) => question._clientId
  )

  return (
    <Box>
      <Flex direction="column" mb={6}>
        <Box pb={8}>
          <TestCardDivider />
        </Box>
        <Flex textStyle="6xl" fontWeight="medium" mb={3}>
          <Icon
            as={AnnotationQuestionOutlineIcon}
            boxSize={6}
            mr={2}
            color="ds.icon.subtle"
          ></Icon>
          {"Follow up questions"}
        </Flex>
        <span>
          {
            "Questions for the participant to answer after they complete the task."
          }
        </span>
      </Flex>

      <DroppableQuestionRows
        group={task.id}
        path={`sections[${sectionIndex}].questions`}
        droppableType="question"
      >
        {questions.map((question, i) => {
          // The index/sort order of questions is based on the whole section
          const questionIndex = sectionQuestionClientIds.indexOf(
            question._clientId
          )

          return (
            <QuestionProvider
              key={question._clientId}
              value={{ question, questionIndex }}
            >
              <SectionQuestion
                borderTop="0px"
                numberLabel={<Tag label={`Q${i + 1}`} />}
              />
            </QuestionProvider>
          )
        })}
      </DroppableQuestionRows>
      <Box pb={8}>
        <TestCardDivider />
      </Box>

      <Flex justifyContent="flex-start" direction="column">
        {!readOnly && (
          <Button
            size="sm"
            colorScheme="gray"
            onClick={onAddQuestion}
            width={"fit-content"}
          >
            {questions.length === 0 ? "Add a question" : "Add another question"}
          </Button>
        )}
      </Flex>
    </Box>
  )
}
