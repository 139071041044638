import {
  creditsPurchasedGoogle,
  signedUpGoogle,
  subscriptionCreatedGoogle,
  testCreatedGoogle,
  testUpgradedGoogle,
} from "JavaScripts/analytics/google"
import {
  TrackedUsabilityTest,
  creditsPurchasedSegment,
  orderActivatedSegment,
  signedUpSegment,
  subscriptionCreatedSegment,
  testCreatedSegment,
  testUpgradedSegment,
} from "JavaScripts/analytics/segment"
import { ShallowUsabilityTest } from "Types"
import { Plan } from "~/api/generated/usabilityhubSchemas"
import { estimatedLtv } from "./utilities"

export const trackCreditsPurchased = (
  label: string,
  creditCount: number,
  priceInCents: number
) => {
  creditsPurchasedSegment(creditCount, priceInCents)
  creditsPurchasedGoogle(label, priceInCents)
}

export const trackOrderActivated = (requestedResponseCount: number) => {
  orderActivatedSegment(requestedResponseCount)
}

export const trackSignedUp = (label: string) => {
  signedUpSegment()
  signedUpGoogle(label)
}

export const trackSubscriptionCreated = (plan: Plan) => {
  const revenue = estimatedLtv(plan)
  subscriptionCreatedSegment(plan, revenue)
  subscriptionCreatedGoogle(plan, revenue)
}

export const trackTestCreated = (usabilityTest: TrackedUsabilityTest) => {
  testCreatedSegment(usabilityTest)
  testCreatedGoogle()
}

export const trackTestUpgraded = (
  usabilityTest: Readonly<ShallowUsabilityTest>
) => {
  testUpgradedSegment(usabilityTest)
  testUpgradedGoogle()
}
