import Constants from "Constants/shared.json"
import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import { useGetModeratedStudyQuota } from "~/api/generated/usabilityhub-components"

const useGetQuotaData = ({
  studyCreatedBeforeCommercialLaunch,
}: {
  studyCreatedBeforeCommercialLaunch: boolean
}) => {
  const { data, isLoading } = useGetModeratedStudyQuota(
    {},
    { enabled: !studyCreatedBeforeCommercialLaunch }
  )

  const sessionsRemaining = data
    ? Math.max(
        data.self_recruited_sessions.quota - data.self_recruited_sessions.used,
        0
      )
    : undefined

  return {
    data,
    isLoading,
    sessionsRemaining,
  }
}

const useStudyCreatedBeforeCommercialLaunch = (
  moderatedStudy: ReturnType<typeof useModeratedStudyContext>
) => {
  const studyCreatedBeforeCommercialLaunch =
    moderatedStudy.moderatedStudy.created_at <
    Constants.INTERVIEWS_COMMERCIAL_LAUNCH_DATE

  return studyCreatedBeforeCommercialLaunch
}

const useHasReachedSessionLimit = ({
  data,
  isLoading,
  studyCreatedBeforeCommercialLaunch,
}: ReturnType<typeof useGetQuotaData> & {
  studyCreatedBeforeCommercialLaunch: boolean
}) => {
  let isReached: boolean

  if (studyCreatedBeforeCommercialLaunch) {
    isReached = false
  } else if (isLoading || !data) {
    isReached = true // Assume true while the API call is in flight
  } else {
    isReached =
      data.self_recruited_sessions.used >= data.self_recruited_sessions.quota
  }

  return {
    hasReachedSessionLimit: isReached,
    renewalDate:
      data && data.period_end ? new Date(data.period_end) : undefined,
  }
}

export const useGetInvitationInfo = (
  moderatedStudy: ReturnType<typeof useModeratedStudyContext>
) => {
  const studyCreatedBeforeCommercialLaunch =
    useStudyCreatedBeforeCommercialLaunch(moderatedStudy)
  const quotaData = useGetQuotaData({
    studyCreatedBeforeCommercialLaunch,
  })
  const { hasReachedSessionLimit, renewalDate } = useHasReachedSessionLimit({
    studyCreatedBeforeCommercialLaunch,
    ...quotaData,
  })

  return {
    hasReachedSessionLimit,
    renewalDate,
    selfRecruitedSessionsAvailable: quotaData.sessionsRemaining ?? 0,
  }
}
