import { Filter } from "Components/test-results/types/filters"

import { FilterFunction } from "./types"

export type ResponseIdFilter = Filter<"response/id", "response/id", number>

export const filterResponseById: FilterFunction<ResponseIdFilter> = ({
  response,
  filter,
}) => {
  return response.id === filter.value
}
