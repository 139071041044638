import { Checkbox, Flex, Text, Tooltip } from "@chakra-ui/react"
import { RowData, createColumnHelper } from "@tanstack/react-table"
import React from "react"

import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"

import {
  PanelOrderCompositeIcon,
  RecruitmentLinkCompositeIcon,
} from "Shared/components/CompositeIcons/CompositeIcons"
import { getDateString } from "Utilities/date-formats"
import { ApplicantFullNameColumnCell } from "./Cells/ApplicantFullNameCell"
import { ApplicationStatusCell } from "./Cells/ApplicationStatusCell"
import { ScreenerQuestionAnswer } from "./Cells/ScreenerQuestionAnswer"
import { SelectionColumnCell } from "./Cells/SelectionColumnCell"
import { ModeratedStudyApplication } from "./types"

// This is the only convenient way to get custom props passed down to cells.
// There is a "meta" prop in options that can do it, but it can only be used
// when the table/column is instantiated, which happens before we've derived
// the current source. OK to remove this once we support bulk actions on
// applicants from multiple sources (we won't need currentSource anymore)
declare module "@tanstack/react-table" {
  interface CellContext<TData extends RowData, TValue> {
    currentSource?: ModeratedStudyApplication["source"]
  }
}

const columnHelper = createColumnHelper<ModeratedStudyApplication>()

const selectionColumn = columnHelper.display({
  id: "select",
  minSize: 40,
  size: 40,
  maxSize: 40,
  header: ({ table }) => (
    <Checkbox
      isChecked={table.getIsAllRowsSelected()}
      isIndeterminate={table.getIsSomeRowsSelected()}
      // Originally the line below; we're currently only using this checkbox as
      // "unselect all" until we have a way of supporting bulk actions on
      // applicants from multiple sources
      // onChange={table.getToggleAllRowsSelectedHandler()}
      onChange={(_) => table.resetRowSelection()}
      isDisabled={!table.getIsSomeRowsSelected()}
    />
  ),
  cell: ({ row, currentSource }) => {
    return (
      <SelectionColumnCell
        isChecked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
        isDisabled={
          !!currentSource && currentSource.id !== row.original.source.id
        }
      />
    )
  },
})

const applicantFullNameColumn = columnHelper.accessor("full_name", {
  header: "Name",
  cell: (info) => (
    <ApplicantFullNameColumnCell
      fullName={info.getValue()}
      preferredName={info.row.original.preferred_name}
      outcome={info.row.original.response?.outcome}
      isPanelist={info.row.original.is_panelist}
    />
  ),
  minSize: 120,
  size: 350,
  maxSize: Number.MAX_SAFE_INTEGER,
  sortingFn: "text",
})

const applicantEmailColumn = columnHelper.accessor("email", {
  header: "Email",
  minSize: 120,
  size: 200,
  maxSize: 250,
  cell: (info) => (
    <Tooltip hasArrow placement="top" label={info.getValue()}>
      {info.row.original.is_panelist ? (
        <Text isTruncated fontStyle="italic" color="text.secondary">
          From panel
        </Text>
      ) : (
        <Text isTruncated>{info.getValue()}</Text>
      )}
    </Tooltip>
  ),
})

const applicantSourceColumn = columnHelper.accessor("source", {
  header: "Source",
  minSize: 120,
  size: 120,
  maxSize: 150,
  cell: ({ currentSource, ...info }) => (
    <Flex gap={2} align="center">
      <Flex shrink={0}>
        {info.getValue().type === "order" ? (
          <PanelOrderCompositeIcon size={5} isRounded />
        ) : (
          <RecruitmentLinkCompositeIcon size={5} isRounded />
        )}
      </Flex>
      <Text
        isTruncated
        fontWeight={
          currentSource?.id === info.getValue().id ? "semibold" : "normal"
        }
      >
        {info.getValue().name}
      </Text>
    </Flex>
  ),
})

const applicationDateColumn = columnHelper.accessor("created_at", {
  header: "Applied on",
  cell: (info) => {
    const date = new Date(info.getValue())
    return <Text letterSpacing="normal">{getDateString(date)}</Text>
  },
  minSize: 130,
  size: 130,
})

const applicationStatusColumn = columnHelper.accessor("status", {
  header: "Status",
  cell: (info) => <ApplicationStatusCell status={info.getValue()} />,
  minSize: 120,
  size: 120,
  maxSize: 120,
})

export const getScreenerQuestionsColumns = (
  screenerQuestions: ScreenerQuestion[]
) => {
  return screenerQuestions.map((screenerQuestion, index) => {
    return columnHelper.display({
      id: `q:${screenerQuestion.id}`,
      header: () => {
        const questionText = `Q${index + 1}. ${screenerQuestion.text}`
        return (
          <Tooltip
            hasArrow
            placement="top"
            label={<Text whiteSpace="pre-wrap">{questionText}</Text>}
          >
            <Text
              isTruncated
              as={screenerQuestion.deleted_at ? "del" : undefined}
            >
              {questionText}
            </Text>
          </Tooltip>
        )
      },
      minSize: 186,
      size: 186,
      cell: (data) => {
        const answer = data.row.original.response?.answers.find(
          (answer) => answer.question_id === screenerQuestion.id
        )

        return (
          <ScreenerQuestionAnswer
            screenerQuestion={screenerQuestion}
            screenerQuestionAnswer={answer}
          />
        )
      },
    })
  })
}

export const applicationsColumns = [
  selectionColumn,
  applicantFullNameColumn,
  applicantEmailColumn,
  applicationDateColumn,
  applicationStatusColumn,
  applicantSourceColumn,
]
