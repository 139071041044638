// JsFromRoutes CacheKey 7489899e6f9dfd7f757fd685758e57f4
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  recruit: /* #__PURE__ */ definePathHelper('get', '/api/usability_tests/:usability_test_id/recruit'),
  results: /* #__PURE__ */ definePathHelper('get', '/api/usability_tests/:usability_test_id/results'),
  sharedResults: /* #__PURE__ */ definePathHelper('get', '/api/usability_tests/:usability_test_id/results/:private_id'),
  figmaSection: /* #__PURE__ */ definePathHelper('put', '/api/usability_tests/:usability_test_id/figma_section'),
}
