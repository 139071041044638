import React, { PropsWithChildren } from "react"

import { AdminDashboardContext } from "Admin/contexts/AdminDashboardContext"

export function AdminDashboardContextProvider({ children }: PropsWithChildren) {
  return (
    <AdminDashboardContext.Provider value={{ isAdminDashboard: true }}>
      {children}
    </AdminDashboardContext.Provider>
  )
}
