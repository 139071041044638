import React from "react"

import { Interpolations } from "Services/i18n"
import { useTranslate } from "Shared/hooks/useTranslate"
import {
  AnswerForm,
  Props as AnswerFormProps,
} from "UsabilityHub/components/UsabilityTestSectionQuestion/answer-form"

import { QuestionCheckboxListField } from "./question-checkbox-list-field"

const getTranslationParametersForSelectionLimitsInstructions: (
  required: boolean,
  min: number | null,
  max: number | null
) => [string, Interpolations?] = (required, min, max) => {
  if (min !== null && max !== null && min === max)
    return [
      "test.question_checkbox_list_instruction.exactly",
      { count: min.toString() },
    ]
  if (min !== null && max !== null)
    return [
      "test.question_checkbox_list_instruction.between",
      { minimum: min.toString(), maximum: max.toString() },
    ]
  if (min === null && max !== null)
    return [
      "test.question_checkbox_list_instruction.at_most",
      { count: max.toString() },
    ]
  if (min !== null && max === null)
    return [
      "test.question_checkbox_list_instruction.at_least",
      { count: min.toString() },
    ]
  if (required) {
    return [
      "test.question_checkbox_list_instruction.at_least",
      { count: (1).toString() },
    ]
  }
  return ["test.question_checkbox_list_instruction.default"]
}

export const CheckboxQuestion: React.FC<
  React.PropsWithChildren<AnswerFormProps>
> = ({ question, ...rest }) => {
  const translate = useTranslate()

  return (
    <AnswerForm
      instructions={translate(
        ...getTranslationParametersForSelectionLimitsInstructions(
          question.required,
          question.min_selected_options,
          question.max_selected_options
        )
      )}
      question={question}
      {...rest}
    >
      <QuestionCheckboxListField
        max_selected_options={question.max_selected_options}
        name="answers"
        options={question.multiple_choice_options}
        randomized={question.randomized}
        showOtherOption={question.has_other_option}
      />
    </AnswerForm>
  )
}
