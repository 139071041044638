import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { SubmitButton } from "Components/button/submit-button"
import { RailsForm } from "Components/form/form"
import { Mail01OutlineIcon } from "Shared/icons/untitled-ui/Mail01OutlineIcon"

type AdminContactTester = React.FC<{
  contact: string
}>

export const AdminContactTester: AdminContactTester = ({ contact }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [value, setValue] = useState("1")
  return (
    <>
      <Button onClick={onOpen}>
        <Icon as={Mail01OutlineIcon} mr={1} />
        Contact
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Contact this panelist</ModalHeader>
            <RailsForm action={contact} method="POST">
              <ModalBody>
                <Text mb={2}>
                  Select a contact reason below and send an automatically
                  generated email to the panelist.
                </Text>
                <RadioGroup onChange={setValue} value={value} size="sm">
                  <Stack direction="column">
                    <Radio name="selected_email" value="location_changed">
                      Responses no longer match reported country
                    </Radio>
                    <Radio name="selected_email" value="incorrect_language">
                      Responded to a question in a different language
                    </Radio>
                    <Radio name="selected_email" value="skipped_questions">
                      Skipped questions
                    </Radio>
                    <Radio name="selected_email" value="response_quality">
                      Poor response quality
                    </Radio>
                  </Stack>
                </RadioGroup>
              </ModalBody>
              <ModalFooter>
                <HStack>
                  <Button variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                  <SubmitButton colorScheme="brand.primary">
                    Send Email
                  </SubmitButton>
                </HStack>
              </ModalFooter>
            </RailsForm>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}
