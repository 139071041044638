import { Box, BoxProps, Flex, Tooltip, forwardRef } from "@chakra-ui/react"
import React from "react"

export interface PhoneInfo {
  id: number
  phoneNumber: string
  lineType: string
  country: string
  validator: string
  isVoip: boolean
  fraudScore: number
  phoneOwners: string
  carrier: string
  userCountry: string
  highRiskCountryCodes: string[]
}

const AdminPhoneInfo: React.FC<PhoneInfo> = ({
  phoneNumber,
  lineType,
  country,
  validator,
  userCountry,
  isVoip,
  fraudScore,
  phoneOwners,
  carrier,
  highRiskCountryCodes,
}) => {
  return (
    <Flex
      gap={2}
      sx={{
        fontVariantNumeric: "tabular-nums",
      }}
    >
      <Pill
        pillColor={
          country !== userCountry && highRiskCountryCodes.includes(country)
            ? "danger"
            : country !== userCountry
              ? "warn"
              : "ok"
        }
      >
        {country ?? "??"}
      </Pill>

      <PillWithOptionalTooltip
        showTooltip={phoneOwners != null && phoneOwners.length > 0}
        tooltipLabel={`Owners: ${phoneOwners}`}
        label={phoneNumber}
        minW="100px"
      />

      {carrier && (
        <PillWithOptionalTooltip
          showTooltip={carrier.length > 6}
          tooltipLabel={carrier}
          label={carrier.substring(0, 6)}
          minW="43px"
        />
      )}

      <Pill minW="45px">{validator}</Pill>

      <Pill
        pillColor={
          fraudScore >= 85 ? "danger" : fraudScore > 75 ? "warn" : "ok"
        }
        minW="35px"
      >
        {fraudScore ?? "N/A"}
      </Pill>

      {lineType && (
        <PillWithOptionalTooltip
          showTooltip={lineType.length > 6}
          tooltipLabel={lineType}
          label={lineType.substring(0, 6)}
          minW="43px"
          pillColor={isVoip ? "danger" : "ok"}
        />
      )}
    </Flex>
  )
}

type PillProps = {
  // For now these correspond to CSS class modifiers in admin-layout.scss
  pillColor?: "ok" | "warn" | "danger" | "alert"
}

const Pill: React.FC<PillProps & BoxProps> = forwardRef(
  ({ pillColor = "ok", ...boxProps }, ref) => {
    const colorClasses = `FraudFactor FraudFactor--${pillColor}`

    return (
      <Box
        ref={ref}
        className={colorClasses}
        textAlign="center"
        {...boxProps}
      />
    )
  }
)

const PillWithOptionalTooltip: React.FC<
  PillProps & {
    showTooltip: boolean
    tooltipLabel: string
    label: string
    minW: string
  }
> = ({ showTooltip, tooltipLabel, label, minW, pillColor }) =>
  showTooltip ? (
    <Tooltip hasArrow placement="top" label={tooltipLabel}>
      <Pill minW={minW} pillColor={pillColor}>
        {label}
      </Pill>
    </Tooltip>
  ) : (
    <Pill pillColor={pillColor} minW={minW}>
      {label}
    </Pill>
  )

export default AdminPhoneInfo
