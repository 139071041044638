import { lowerCase } from "lodash"
import React from "react"

import { UsabilityTestUserActivity } from "Types"

type Props = {
  activities: UsabilityTestUserActivity[]
}

export const getUserNameFromActivity = (
  activity: UsabilityTestUserActivity
): string =>
  activity.session_id === sessionStorage.getItem("tabId")
    ? "You"
    : activity.user.name

export const ActiveUserNames = ({ activities }: Props) => {
  if (activities.length < 3) {
    const names = activities.map((a) => getUserNameFromActivity(a))
    if (names[1] === "You") names[1] = lowerCase(names[1])
    return <>{names.join(" & ")}</>
  }
  return (
    <>
      {getUserNameFromActivity(activities[0])} & {activities.length - 1} others{" "}
    </>
  )
}
