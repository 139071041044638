import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const TreeTestIcon = createIcon({
  displayName: "TreeTestIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.00001 4.31154C5.35899 4.31154 5.65001 4.60255 5.65001 4.96154V6.61924H9.0872C9.25572 5.9752 9.84159 5.5 10.5385 5.5C11.3669 5.5 12.0385 6.17157 12.0385 7C12.0385 7.82843 11.3669 8.5 10.5385 8.5C10.0564 8.5 9.62746 8.27261 9.35303 7.91924H5.65001V10.0686C5.65001 10.3939 5.91377 10.6577 6.23914 10.6577H9.0872C9.25571 10.0137 9.84159 9.53845 10.5385 9.53845C11.3669 9.53845 12.0385 10.21 12.0385 11.0385C12.0385 11.8669 11.3669 12.5385 10.5385 12.5385C10.0564 12.5385 9.62746 12.3111 9.35304 11.9577H6.23914C5.1958 11.9577 4.35001 11.1119 4.35001 10.0686V4.96154C4.35001 4.60255 4.64102 4.31154 5.00001 4.31154Z"
    />
  ),
})
