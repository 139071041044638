import { Flex } from "@chakra-ui/react"
import { groupBy } from "lodash"
import React from "react"

import { ClickResult } from "Components/individual-response/individual-section-result/click-result"
import { NoResponse } from "Components/individual-response/individual-section-result/no-data"
import { useFilteredClicks } from "Components/test-results/hooks/use-filtered-clicks"
import { SummaryList } from "Components/test-results/section-results/section-screenshot-summary-list"

import { TaskProps } from "../task-props"

import { RecordingsSimplePreview } from "Components/test-results/section-results/SectionResultsCards/Recordings/RecordingsSimplePreview"
import { ClickSummaryOld } from "./click-summary-old"

export const FirstClickSectionIndividualResults = ({
  responseSection,
  usabilityTestSection,
}: TaskProps) => {
  const clicks = getFirstClickSectionClicks({
    responseSection,
    usabilityTestSection,
  })

  return (
    <Flex gap={4} flexFlow="column">
      {responseSection === null ? (
        <NoResponse />
      ) : (
        <>
          <RecordingsSimplePreview responseSection={responseSection} />
          <ClickResult
            sectionScreenshot={usabilityTestSection.section_screenshots[0]}
            deviceFrameId={usabilityTestSection.device_frame_id}
            hitzones={[]}
            clicks={clicks}
          />
        </>
      )}
      <SummaryList>
        {clicks.map((click, index) => {
          const sectionScreenshot =
            usabilityTestSection.section_screenshots.find(
              (ss) => ss.id === click.usability_test_section_screenshot_id
            )
          return sectionScreenshot === undefined ? (
            <li key={click.id}>Section missing</li>
          ) : (
            <li key={click.id}>
              <ClickSummaryOld
                key={click.id}
                click={click}
                clickIndex={index}
                sectionScreenshot={sectionScreenshot}
              />
            </li>
          )
        })}
      </SummaryList>
    </Flex>
  )
}

export const getFirstClickSectionClicks = ({
  responseSection,
  usabilityTestSection,
}: TaskProps) => {
  const sectionScreenshot = usabilityTestSection.section_screenshots[0]
  const filteredClicksBySectionScreenshotId = groupBy(
    useFilteredClicks(),
    (c) => c.usability_test_section_screenshot_id
  )
  const clicks =
    sectionScreenshot === undefined || responseSection === null
      ? []
      : (
          filteredClicksBySectionScreenshotId[sectionScreenshot.id] || []
        ).filter((click) => click.response_id === responseSection.response_id)

  return clicks
}
