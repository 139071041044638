import { Text } from "@chakra-ui/react"
import React from "react"

import { SectionCard } from "../SectionCard"
import { SectionQuestions } from "../SectionQuestions/SectionQuestions"
import { SectionLogicActions } from "../TestLogicActions/TestLogicActions"

import { FiveSecondTask } from "./FiveSecondTask"

export const FiveSecondSectionCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <SectionCard>
      <SectionLogicActions />
      <FiveSecondTask />
      <Text as="label" fontWeight="semibold">
        Follow up questions
      </Text>
      <SectionQuestions />
    </SectionCard>
  )
}
