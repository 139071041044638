import { WordCloud, Props as WordCloudProps } from "Components/WordCloud"
import React, { PureComponent } from "react"

import { sentencesToCountByWord } from "Services/word-cloud"
import { Omit, ResponseAnswer } from "Types"

interface Props extends Omit<WordCloudProps, "countByWord"> {
  answers: ReadonlyArray<ResponseAnswer>
}

export class AnswerWordCloud extends PureComponent<Props> {
  render() {
    const { answers, ...rest } = this.props
    const sentences = answers.reduce((acc, answer) => {
      if (answer.answer !== null) {
        acc.push(answer.answer)
      }
      return acc
    }, [] as string[])
    const countByWord = sentencesToCountByWord(sentences)
    return <WordCloud countByWord={countByWord} {...rest} />
  }
}
