import { useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import {
  createAgeFilter,
  createUnknownAgeFilter,
} from "../filters/create-filters/age-filter"
import { ResponseFilter } from "../filters/filters/types"
import { getFiltersWithoutCategory } from "../filters/get-filters/get-filters-without"
import { getAgeRangeForValue } from "../utils/age-ranges"
import { FILTER_UNKNOWN } from "../utils/unknown"

export const useUpdateAgeRangeFilter = (): ((ageRanges: string[]) => void) => {
  const { setTestResultsFilters } = useContext(TestResultsFilterState)

  return (ageRanges) => {
    setTestResultsFilters((filters) => {
      const filtersWithoutAge = getFiltersWithoutCategory(
        filters,
        "response/attribute:age"
      )

      const ageFilters: ResponseFilter[] = ageRanges.map((age) => {
        if (age === FILTER_UNKNOWN) {
          return createUnknownAgeFilter()
        }

        if (age === "-Infinity") return createAgeFilter(null, 19)

        const range = getAgeRangeForValue(age)!

        return createAgeFilter(range.min, range.max)
      })

      return [...filtersWithoutAge, ...ageFilters]
    })
  }
}
