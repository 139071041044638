import { partition } from "lodash"
import { useMemo } from "react"

import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"

export function useOnScreenClicks(clicks: NormalizedClick[]) {
  // Split the array into clicks on and off the screen
  return useMemo(
    () => partition(clicks, (click) => click.x < 1 && click.y < 1),
    [clicks]
  )
}
