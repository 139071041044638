import { AnswerTagFilter, filterResponseByAnswerTag } from "./answer-tags"
import {
  AttributeDemographicAttributeOptionUnknownFilter,
  filterResponseByAttributeDemographicAttributeOptionUnknown,
} from "./attribute-demographic-attribute-option-unknown"
import {
  AttributeExactValueFilter,
  filterResponseByAttributeExactValue,
} from "./attribute-exact-value"
import { AgeFilter, filterResponseByAttributeRange } from "./attribute-range"
import {
  AttributeUnknownFilter,
  filterResponseByAttributeUnknownFilter,
} from "./attribute-unknown"
import { CardSortFilter, filterResponseByCardSort } from "./card-sort"
import {
  ClientHitzoneFilter,
  filterResponseByClientHitzone,
} from "./client-hitzone"
import { CommonPathFilter, filterResponseByCommonPath } from "./common-path"
import { ExactAnswerFilter, filterResponseByExactAnswer } from "./exact-answer"
import { HitzoneFilter, filterResponseByHitzone } from "./hitzone"
import { OtherAnswerFilter, filterResponseByOtherAnswer } from "./other-answer"
import {
  OtherHitzoneFilter,
  filterResponseByOtherHitzone,
} from "./other-hitzone"
import {
  PreferenceAnswerFilter,
  filterResponseByPreferenceAnswer,
} from "./preference-answer"
import {
  PrototypeNodeFilter,
  filterResponseByPrototypeNode,
} from "./prototype-node"
import {
  RankedAnswerFilter,
  filterResponseByRankedAnswer,
} from "./ranked-answer"
import { ResponseIdFilter, filterResponseById } from "./response-id"
import { TreeTestFilter, filterResponseByTreeTest } from "./tree-test"
import { FilterData, ResponseFilter } from "./types"
import { VariableFilter, filterResponseByVariableValue } from "./variables"

function assertNever(x: never): never {
  // biome-ignore format: ts-ignore
  // @ts-ignore
  throw new Error("Unsupported filter type:" + x.type)
}

export function filterResponseByFilter(
  filterData: FilterData<ResponseFilter>
): boolean {
  if (filterData.filter.type === "response/id")
    return filterResponseById(filterData as FilterData<ResponseIdFilter>)

  if (filterData.filter.type === "attribute/range")
    return filterResponseByAttributeRange(filterData as FilterData<AgeFilter>)
  if (filterData.filter.type === "attribute/exact-value")
    return filterResponseByAttributeExactValue(
      filterData as FilterData<AttributeExactValueFilter>
    )
  if (filterData.filter.type === "attribute/unknown")
    return filterResponseByAttributeUnknownFilter(
      filterData as FilterData<AttributeUnknownFilter>
    )
  if (
    filterData.filter.type === "attribute/unknown-demographic-attribute-option"
  )
    return filterResponseByAttributeDemographicAttributeOptionUnknown(
      filterData as FilterData<AttributeDemographicAttributeOptionUnknownFilter>
    )

  if (filterData.filter.type === "answer/exact-answer")
    return filterResponseByExactAnswer(
      filterData as FilterData<ExactAnswerFilter>
    )
  if (filterData.filter.type === "answer/other-answer")
    return filterResponseByOtherAnswer(
      filterData as FilterData<OtherAnswerFilter>
    )
  if (filterData.filter.type === "answer/client-hitzone")
    return filterResponseByClientHitzone(
      filterData as FilterData<ClientHitzoneFilter>
    )
  if (filterData.filter.type === "answer/hitzone")
    return filterResponseByHitzone(filterData as FilterData<HitzoneFilter>)
  if (filterData.filter.type === "answer/other-hitzone")
    return filterResponseByOtherHitzone(
      filterData as FilterData<OtherHitzoneFilter>
    )
  if (filterData.filter.type === "answer/ranked")
    return filterResponseByRankedAnswer(
      filterData as FilterData<RankedAnswerFilter>
    )
  if (filterData.filter.type === "answer/common-path")
    return filterResponseByCommonPath(
      filterData as FilterData<CommonPathFilter>
    )
  if (filterData.filter.type === "answer/tag")
    return filterResponseByAnswerTag(filterData as FilterData<AnswerTagFilter>)
  if (filterData.filter.type === "answer/preference")
    return filterResponseByPreferenceAnswer(
      filterData as FilterData<PreferenceAnswerFilter>
    )
  if (filterData.filter.type === "answer/card-sort")
    return filterResponseByCardSort(filterData as FilterData<CardSortFilter>)
  if (filterData.filter.type === "answer/tree-test")
    return filterResponseByTreeTest(filterData as FilterData<TreeTestFilter>)
  if (
    filterData.filter.type === "answer/prototype-node" ||
    filterData.filter.type === "answer/reached-goal-screen"
  )
    return filterResponseByPrototypeNode(
      filterData as FilterData<PrototypeNodeFilter>
    )
  if (filterData.filter.type === "attribute/variable")
    return filterResponseByVariableValue(
      filterData as FilterData<VariableFilter>
    )

  assertNever(filterData.filter)
}
