import {
  Avatar,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
  Stack,
} from "@chakra-ui/react"
import React, { PropsWithChildren, forwardRef, useState } from "react"

const AVATAR_MAX_SIZE = 2 * 1024 * 1024

type AvatarFieldProps = PropsWithChildren<
  InputProps & {
    user: {
      avatarUrl: string
      name: string
    }
  }
>

export const AvatarField = forwardRef<HTMLInputElement, AvatarFieldProps>(
  ({ user: { avatarUrl, name }, onChange, ...props }, ref) => {
    const [src, setSrc] = useState<FileReader["result"]>(null)

    const changed = (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.currentTarget
      if (!target.files?.length) return

      const file = target.files[0]
      if (file.size > AVATAR_MAX_SIZE) {
        target.value = ""
        window.alert(
          "Your picture must be less than 2 MB in size - please choose a smaller file."
        )
      } else {
        const fileReader = new FileReader()

        fileReader.onload = (e) => {
          if (!e.target) {
            throw new Error(`Expected nullish event target`)
          }

          setSrc(e.target.result)
        }
        fileReader.readAsDataURL(file)
        onChange?.(event)
      }
    }

    return (
      <Stack direction="row" align="center" spacing={2}>
        <Avatar size="md" name={name} src={(src as string) || avatarUrl} />
        <FormControl>
          <Input
            {...props}
            ref={ref}
            accept="image/*"
            id="avatar"
            onChange={changed}
            type="file"
            display="none"
          />
          <Stack direction="row" align="center" spacing={2}>
            <Button
              as={FormLabel}
              size="sm"
              htmlFor="avatar"
              pb={0}
              cursor="pointer"
              mb={0}
            >
              Choose file
            </Button>
            <FormHelperText mt={0}>The maximum size is 2MB</FormHelperText>
          </Stack>
        </FormControl>
      </Stack>
    )
  }
)
