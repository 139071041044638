/**
 * Based on container, not viewport
 */
export const threeColumnsOrMore = "48rem"

// one column (compact cards)
const cq1Col = "@container (max-width: 31.9999rem)"
// two columns (compact cards)
const cq2Cols = "@container (min-width: 32rem) and (max-width: 47.99rem)"
// three columns or more (regular cards)
const cq3Cols = `@container (min-width: ${threeColumnsOrMore}) and (max-width: 61.99rem)`
const cq4Cols = "@container (min-width: 62rem) and (max-width: 78.99rem)"
const cq5Cols = "@container (min-width: 79rem)"

export const projectsPageGridSxProps = {
  [cq2Cols]: { "&": { gridTemplateColumns: "repeat(2, 1fr)" } },
  [cq3Cols]: { "&": { gridTemplateColumns: "repeat(3, 1fr)" } },
  [cq4Cols]: { "&": { gridTemplateColumns: "repeat(4, 1fr)" } },
  [cq5Cols]: { "&": { gridTemplateColumns: "repeat(5, 1fr)" } },
}

export const recentProjectsGridSxProps = {
  [cq1Col]: {
    "> :nth-child(n+4)": { display: "none" },
  },
  [cq2Cols]: {
    ...projectsPageGridSxProps[cq2Cols],
    "> :nth-child(n+5)": { display: "none" },
  },
  [cq3Cols]: {
    ...projectsPageGridSxProps[cq3Cols],
    "> :nth-child(n+4)": { display: "none" },
  },
  [cq4Cols]: {
    ...projectsPageGridSxProps[cq4Cols],
    "> :nth-child(n+5)": { display: "none" },
  },
  [cq5Cols]: projectsPageGridSxProps[cq5Cols],
}
