import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query"
import {
  GetModeratedStudyApplicationsError,
  GetModeratedStudyApplicationsRequestBody,
  GetModeratedStudyApplicationsResponse,
  GetModeratedStudyApplicationsVariables,
  fetchGetModeratedStudyApplications,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

export const useInfiniteModeratedStudyApplicationList = (
  moderatedStudyId: string,
  queryParams: Omit<
    GetModeratedStudyApplicationsVariables["queryParams"],
    "cursor"
  >,
  filters: GetModeratedStudyApplicationsRequestBody["filters"],
  options?: Omit<
    UseInfiniteQueryOptions<
      GetModeratedStudyApplicationsResponse,
      GetModeratedStudyApplicationsError
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useUsabilityhubContext()

  return useInfiniteQuery<
    GetModeratedStudyApplicationsResponse,
    GetModeratedStudyApplicationsError
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/applications",
      operationId: "getModeratedStudyApplications",
      variables: {
        pathParams: {
          moderatedStudyId,
        },
        queryParams: {
          ...fetcherOptions.queryParams,
          ...queryParams,
        },
        body: {
          filters,
        },
      },
    }),
    (context) =>
      fetchGetModeratedStudyApplications({
        ...fetcherOptions,
        pathParams: {
          moderatedStudyId,
        },
        queryParams: {
          ...queryParams,
          ...(context.pageParam ? { cursor: context.pageParam as string } : {}),
        },
        body: {
          filters,
        },
      }),
    {
      ...queryOptions,
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.next_page ?? undefined,
      retry: false,
    }
  )
}
