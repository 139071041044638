import React, { CanvasHTMLAttributes } from "react"
import { forwardRef } from "react"

function getDimension(
  dimensionValue: string | number | undefined,
  defaultDimensionValue: number
) {
  if (!dimensionValue) return defaultDimensionValue
  if (typeof dimensionValue === "string") return parseInt(dimensionValue)

  return dimensionValue
}

export const HighDPRCanvas = forwardRef<
  HTMLCanvasElement,
  CanvasHTMLAttributes<HTMLCanvasElement>
>(({ width, height, ...props }, forwardedRef) => {
  const dpr = window.devicePixelRatio
  const numericWidth = getDimension(width, 300)
  const numericHeight = getDimension(height, 150)

  return (
    <canvas
      {...props}
      ref={forwardedRef}
      width={dpr * numericWidth}
      height={dpr * numericHeight}
    />
  )
})

HighDPRCanvas.displayName = "HighDPRCanvas"
