import {
  Box,
  HStack,
  Text,
  UseRadioProps,
  forwardRef,
  useRadio,
} from "@chakra-ui/react"
import { layerStyles } from "DesignSystem/layerStyles"
import React, { PropsWithChildren } from "react"

export type RadioProps = PropsWithChildren<
  UseRadioProps & {
    onCheckedChange?: (checked: boolean) => void
  }
>

export const Radio = forwardRef<RadioProps, "input">(
  ({ children, onCheckedChange, width = "max-content", ...props }, ref) => {
    const { state, getInputProps } = useRadio(props)

    const { style, isChecked, isDisabled, ...inputProps } = getInputProps(
      props,
      ref
    )

    const changed = (event: React.ChangeEvent<HTMLInputElement>) => {
      const radio = event.currentTarget
      if (onCheckedChange && state.isChecked !== radio.checked) {
        onCheckedChange(radio.checked)
      }
    }

    const filled = state.isChecked

    return (
      <HStack as="label" gap="1" alignItems="start" data-radio width={width}>
        <Box
          position="relative"
          boxSize={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexShrink={0}
        >
          <Box
            as="svg"
            boxSize="0.875rem"
            rounded={7}
            viewBox="0 0 14 14"
            layerStyle={state.isFocused ? "focused" : undefined}
            sx={
              state.isDisabled
                ? undefined
                : {
                    "[data-radio]:has(:active) &": layerStyles.focused,
                  }
            }
          >
            <Box
              as="circle"
              cx={7}
              cy={7}
              r={7}
              fill={
                state.isDisabled
                  ? "ds.background.disabled"
                  : filled
                    ? "ds.background.selected.bold.resting"
                    : "ds.background.input.resting"
              }
              stroke={
                state.isDisabled
                  ? "ds.border.disabled"
                  : filled
                    ? "none"
                    : "ds.border.input"
              }
              strokeWidth={state.isDisabled && state.isChecked ? 10 : 4}
              sx={{
                "[data-radio]:hover &, [data-radio]:has(:active) &": {
                  fill: state.isDisabled
                    ? "ds.background.disabled"
                    : filled
                      ? "ds.background.selected.bold.hovered"
                      : "ds.background.input.hovered",
                  stroke: state.isDisabled
                    ? "ds.border.disabled"
                    : filled
                      ? "none"
                      : "ds.border.hovered",
                },
              }}
            />
            <Box
              as="circle"
              cx={7}
              cy={7}
              r={2}
              stroke="none"
              fill={state.isDisabled ? "none" : "ds.icon.inverse"}
              transform={`scale(${state.isChecked ? 1 : 0})`}
              transition={
                state.isChecked && !state.isDisabled
                  ? "transform 0.2s ease-in"
                  : "none"
              }
              transformOrigin="center"
            />
          </Box>
          <Box
            as="input"
            type="radio"
            {...inputProps}
            pos="absolute"
            inset={0}
            w="full"
            h="full"
            opacity={0}
            cursor="pointer"
            onChange={changed}
          />
        </Box>
        <Text
          as="div"
          textStyle="ds.paragraph.primary"
          color={state.isDisabled ? "ds.text.disabled" : "ds.text.default"}
          py="0.125rem"
          flexGrow={1}
        >
          {children}
        </Text>
      </HStack>
    )
  }
)
