import { useQueryClient } from "@tanstack/react-query"
import {
  UpdateRecruitmentLinkVariables,
  useUpdateRecruitmentLink,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"

type Props =
  | {
      usability_test_unique_id: string
      test_set_unique_id?: never
    }
  | {
      test_set_unique_id: string
      usability_test_unique_id?: never
    }
  | {
      test_set_unique_id?: never
      usability_test_unique_id?: never
    }

export const usePersistLinkChanges = ({
  usability_test_unique_id,
  test_set_unique_id,
}: Props) => {
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const { mutate: updateRecruitmentLink } = useUpdateRecruitmentLink()

  const persistLinkChanges = (
    recruitmentLink: UpdateRecruitmentLinkVariables["body"]["recruitment_link"]
  ) =>
    new Promise<RecruitmentLink>((resolve) => {
      updateRecruitmentLink(
        {
          body: {
            usability_test_unique_id,
            test_set_unique_id,
            recruitment_link: recruitmentLink,
          },
        },
        {
          onSuccess: async (data) => {
            const queryKey = queryKeyFn({
              operationId: "getRecruitmentLink",
              path: "/api/recruitment_links",
              variables: {
                queryParams: {
                  usability_test_unique_id,
                  test_set_unique_id,
                },
              },
            })
            await queryClient.invalidateQueries(queryKey, { exact: true })
            resolve(data)
          },
        }
      )
    })

  return persistLinkChanges
}
