import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react"
import React, { ReactNode } from "react"
import AutosizeTextarea from "react-autosize-textarea"
import { Field, WrappedFieldProps } from "redux-form"

import { useMarkdownUsage } from "Hooks/use-markdown-usage"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { containsURLs } from "UsabilityHub/components/TestForm/validators/validateContainsUrls"
import { useSectionIndexContext } from "UsabilityHub/contexts"

interface SectionInstructionFieldProps {
  placeholder: string
  rows?: number
  expandable?: boolean
  required?: boolean
  label?: ReactNode
}

export const SectionInstructionField: React.FC<
  React.PropsWithChildren<SectionInstructionFieldProps>
> = ({
  placeholder,
  rows = 1,
  label = "Instruction",
  expandable = false,
  required = false,
}) => {
  const sectionIndex = useSectionIndexContext()
  const sectionPath = `sections[${sectionIndex}]`

  return (
    <Field
      name={`${sectionPath}.text`}
      component={SectionInstructionRow}
      warn={containsURLs}
      props={{ placeholder, rows, label, expandable, required }}
    />
  )
}

const SectionInstructionRow: React.FC<
  React.PropsWithChildren<WrappedFieldProps & SectionInstructionFieldProps>
> = ({ placeholder, rows, label, expandable, required, input, meta }) => {
  const markdownHint = useMarkdownUsage("inline")
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <FormControl id={input.name}>
      <HStack align="baseline">
        <FormLabel flexGrow={1}>
          {label}
          {!required && " (optional)"}
        </FormLabel>
        <Text fontSize="xs" lineHeight={5} color="gray.500">
          {markdownHint}
        </Text>
      </HStack>
      {rows === 1 && !expandable ? (
        <Input
          isRequired={required}
          isReadOnly={readOnly}
          placeholder={placeholder}
          {...input}
        />
      ) : (
        <Textarea
          as={AutosizeTextarea}
          isRequired={required}
          rows={rows}
          fontWeight="normal"
          placeholder={placeholder}
          isReadOnly={readOnly}
          {...input}
        />
      )}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
