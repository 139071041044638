import { useDisclosure } from "@chakra-ui/react"
import React from "react"

import { FigmaTaskPath, FigmaTaskScreenResult } from "Types"

import {
  ScreenThumbnailContainer,
  ScreenThumbnailContainerProps,
} from "../ScreenResults/ScreenThumbnail"

import { PathEntryDetailsModal } from "./PathEntryDetailsModal/PathEntryDetailsModal"

interface PathEntryWithDetailsModalProps
  extends Omit<ScreenThumbnailContainerProps, "nodeId" | "screenResult"> {
  index: number
  screenResults: FigmaTaskScreenResult[]
  path: FigmaTaskPath
}

export function PathEntryWithDetailsModal({
  index,
  screenResults,
  path,
  ...props
}: PathEntryWithDetailsModalProps) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <ScreenThumbnailContainer
        {...props}
        showZoomCursor
        showPathStats
        onClick={onOpen}
        screenResult={screenResults[index]}
      />
      {isOpen && (
        <PathEntryDetailsModal
          size="6xl"
          isOpen
          onClose={onClose}
          initialIndex={index}
          screenResults={screenResults}
          path={path}
        />
      )}
    </>
  )
}
