import { Flex, Link } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Button } from "DesignSystem/components"
import { ContactSupportButton } from "UsabilityHub/components/ContactSupportButton"
import React from "react"
import { FeedbackContent, FeedbackText } from "./FeedbackContent"
import { extractSentiment } from "./extractSentiment"
import { Rating, RatingFormSentiment } from "./types"

const neutralAndNegativeText: FeedbackText = {
  primaryText: "Response received, thank you!",
  secondaryText: (
    <>
      {`Did you know that you can review and delete low-quality responses from
      your results? If it\u2019s within 30 days, they will be replaced
      automatically free of charge. `}
      <Link isExternal href={Constants.HELP_CENTER_DELETING_RESPONSES_URL}>
        Learn more.
      </Link>
    </>
  ),
}

const textContent: Record<RatingFormSentiment, FeedbackText> = {
  positive: {
    primaryText: "Response received, thank you!",
    secondaryText: "We appreciate your help in making our platform better.",
  },
  neutral: neutralAndNegativeText,
  negative: neutralAndNegativeText,
}

export const FeedbackRecorded: React.FC<{
  rating: Rating | null
  onClose: () => void
}> = ({ rating, onClose }) => {
  const sentiment = rating ? extractSentiment(rating) : "neutral"
  const { primaryText, secondaryText } = textContent[sentiment]

  return (
    <FeedbackContent
      primaryText={primaryText}
      secondaryText={secondaryText}
      sentiment={sentiment}
    >
      <Flex gap={2}>
        <Button onClick={onClose}>Close</Button>
        {sentiment === "negative" && (
          <ContactSupportButton variant="secondary" />
        )}
      </Flex>
    </FeedbackContent>
  )
}
