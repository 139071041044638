import {
  Avatar,
  Button,
  Flex,
  IconButton,
  Link,
  SlideFade,
  useInterval,
} from "@chakra-ui/react"
import { formatDuration, intervalToDuration, isToday } from "date-fns"
import React, { useState } from "react"

import { PanelOrderCompositeIcon } from "Shared/components/CompositeIcons/CompositeIcons"
import { AlertTriangleSolidIcon } from "Shared/icons/untitled-ui/AlertTriangleSolidIcon"
import { getTimeString } from "Utilities/date-formats"
import { GetModeratedStudyBookingsResponse } from "~/api/generated/usabilityhub-components"
import { HostAvatars } from "./BookingCard/HostAvatars"
import { formatInterviewParticipantName } from "./BookingCard/formatInterviewParticipantName"
import { useParticipantInfoDrawerContext } from "./ParticipantInfoDrawer/ParticipantInfoDrawer"

type BannerBookingData = {
  bannerBooking: GetModeratedStudyBookingsResponse[number] | null
  isInProgress?: boolean
}

const getBannerBooking = (
  upcoming: GetModeratedStudyBookingsResponse
): BannerBookingData => {
  const now = new Date()
  const inProgress = upcoming.filter(
    (b) =>
      b.state === "booked" &&
      now >= new Date(b.starts_at) &&
      now < new Date(b.ends_at)
  )
  if (inProgress.length > 0)
    return { bannerBooking: inProgress[0], isInProgress: true }

  const upcomingToday = upcoming.filter(
    (b) =>
      b.state === "booked" &&
      isToday(new Date(b.starts_at)) &&
      now < new Date(b.starts_at)
  )
  if (upcomingToday.length > 0)
    return { bannerBooking: upcomingToday[0], isInProgress: false }

  return { bannerBooking: null }
}

interface NextSessionBannerProps {
  upcomingBookings: GetModeratedStudyBookingsResponse
}

export const NextSessionBanner: React.FC<NextSessionBannerProps> = ({
  upcomingBookings,
}) => {
  const [, setTime] = useState<number>()
  const { openParticipantInfoDrawer } = useParticipantInfoDrawerContext()

  // this is a hack to force rerenders on an interval to keep the timer up to date
  useInterval(() => setTime(Date.now()), 5000)

  const { bannerBooking, isInProgress } = getBannerBooking(upcomingBookings)

  if (!bannerBooking) return null

  const { starts_at, ends_at, location, is_panelist } = bannerBooking

  const gradient = isInProgress
    ? "linear(to-l, #31C48D, #0E9F6E)"
    : "linear(to-l, #76A9FA, #3F83F8)"
  const startDate = new Date(starts_at)
  const endDate = new Date(ends_at)
  const scheduledTime = getTimeString(startDate)
  const duration = formatDuration(
    intervalToDuration({
      start: startDate,
      end: endDate,
    }),
    { format: ["hours", "minutes"] }
  )

  // Without this, the final minute before the meeting is in limbo and the timer goes blank
  // I guess because formatDuration rounds the minutes down
  const oneMinuteLater = new Date(Number(startDate) + 60_000)
  const timeUntilNext = formatDuration(
    intervalToDuration({
      start: new Date(),
      end: oneMinuteLater,
    }),
    { format: ["hours", "minutes"] }
  )
    // replace this with a date-fns option config if possible in future
    // (currently converts from 1 hour 30 minutes -> 1h 30m)
    .replace(/\s?([hm])\w+/g, "$1")

  return (
    <SlideFade in unmountOnExit>
      <Flex
        bgGradient={gradient}
        boxShadow="xl"
        justify="space-between"
        align={["flex-start", null, "center"]}
        py={5}
        px={6}
        color="white"
        rounded="md"
        flexDirection={["column", null, "row"]}
        gap={[4, null, 0]}
      >
        <Flex
          basis={[0, null, "9rem"]}
          minWidth="9rem"
          direction="column"
          gap={1}
        >
          <Flex fontWeight="semibold" lineHeight={1}>
            {isInProgress ? "Your session is" : "Next session in"}
          </Flex>
          <Flex fontSize="3xl" fontWeight="bold" lineHeight={1}>
            {isInProgress ? "Now" : timeUntilNext}
          </Flex>
        </Flex>
        <Flex grow={1} gap={3} align="center">
          <IconButton
            alignSelf="flex-start"
            aria-label="View participant information"
            icon={
              is_panelist ? (
                <PanelOrderCompositeIcon size={4} />
              ) : (
                <Avatar size="2xs" />
              )
            }
            variant="outline"
            size="xs"
            onClick={() => {
              openParticipantInfoDrawer(
                bannerBooking.moderated_study_application_id
              )
            }}
            mt={-0.5}
          />

          <Flex direction="column" gap={1.5}>
            <Flex fontSize="lg" fontWeight="semibold" lineHeight={1}>
              {formatInterviewParticipantName(bannerBooking)}
            </Flex>
            <Flex
              fontSize="sm"
              lineHeight={1.25}
              noOfLines={1}
              wordBreak="break-all"
            >
              {[scheduledTime, duration, "Online meeting"]
                .filter((s) => s.length > 0)
                .join(" \u2022 ")}
            </Flex>
            {!location && (
              <Flex
                align="center"
                fontSize="xs"
                fontWeight="medium"
                w="fit-content"
                bg="orange.400"
                color="text.inverted"
                px={2}
                py="1px"
                rounded="2px"
              >
                <AlertTriangleSolidIcon mr={1} />
                This session doesn't have a meeting link.
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          align="center"
          gap={3}
          flexShrink={0}
          ps={[0, null, 4]}
          alignSelf={["stretch", null, "auto"]}
        >
          {location && (
            <>
              <HostAvatars booking={bannerBooking} />
              <Button
                as={Link}
                href={location}
                target="_blank"
                rel="noopener noreferrer"
                color="brand.neutral.default"
                bg="white"
                textDecoration="none"
                ms={["auto", null, 0]}
                _hover={{ textDecoration: "none", color: "brand.neutral" }}
              >
                Join
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </SlideFade>
  )
}
