import { useQueryClient } from "@tanstack/react-query"
import { useCallback, useMemo } from "react"
import {
  GetNoticesResponse,
  useDismissNotice,
  useGetNotices,
} from "~/api/generated/usabilityhub-components"
import { Notice } from "~/api/generated/usabilityhubSchemas"

export const useDismissibleNotice = (key: Notice) => {
  const { isLoading, data } = useGetNotices({})

  const queryKey = ["api", "user_notices"]

  const queryClient = useQueryClient()

  const notices = useMemo(
    () => new Set<Notice>(data?.user_notices ?? []),
    [data]
  )

  const isOpen = notices.has(key)

  const { mutateAsync } = useDismissNotice({
    // Assume the mutation succeeds and update the cache optimisitcally
    // so that the notice is dismissed immediately.
    // See https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates#via-the-cache
    onMutate: async ({ pathParams: { slug } }) => {
      await queryClient.cancelQueries(queryKey)
      const previous = queryClient.getQueryData(queryKey) as GetNoticesResponse
      queryClient.setQueryData(queryKey, {
        user_notices: previous.user_notices.filter((n) => n !== slug),
      })
      return previous
    },
    onError: (_, __, context: { previous: GetNoticesResponse }) => {
      queryClient.setQueryData(queryKey, context.previous)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })

  const dismiss = useCallback(() => {
    mutateAsync({ pathParams: { slug: key } })
  }, [mutateAsync, key])

  return { isLoading, isOpen, dismiss }
}
