import { Box, Collapse, HStack } from "@chakra-ui/react"
import { CommentThread } from "Components/comment-thread/CommentThread"
import { Dispatch } from "Redux/app-store"
import {
  getFormName,
  getFormValue,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { getId } from "Redux/reducers/test-builder/selectors/test-form"
import { useTranslate } from "Shared/hooks/useTranslate"
import {
  TestCard,
  TestCardActions,
  TestCardBody,
  TestCardHeader,
  TestCardHeading,
  TestCardHint,
  TestCardTitle,
} from "UsabilityHub/components/TestCard/TestCard"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { change } from "redux-form"
import { CustomizableScreenToggle } from "../CustomizableScreenToggle"
import { PaidFeatureIndicator } from "../PaidFeatureIndicator"
import { ThankYouScreenFields } from "./ThankYouScreenFields"

const COMMENTABLE_ENTITY = {
  entityContext: "test_builder",
  entityType: "thank_you",
} as const

export const ThankYouScreenCard: React.FC = () => {
  const dispatch = useDispatch<Dispatch>()
  const t = useTranslate()

  const isEnabled = useSelector(getFormValue("customize_thankyou")) as boolean
  const usabilityTestId = useSelector(getId)
  const { readOnly } = useUsabilityTestUserActivityContext()
  const screenType = "thankyou"

  const { thankyou_heading, thankyou_message } = useSelector(getFormValues)

  // When enabling the thankyou screen for the first time, we'll populate the fields with
  // default values based on the currently selected language.
  // (The relevant LocaleProvider is in <TestFormFields />)
  const onEnable = () => {
    if (thankyou_heading || thankyou_message) return

    const defaultThankYouHeading = t("test.thankyou.thankyou_heading")
    const defaultThankYouMessage = t("test.thankyou.thankyou_message")

    dispatch(change(getFormName(), "thankyou_heading", defaultThankYouHeading))
    dispatch(change(getFormName(), "thankyou_message", defaultThankYouMessage))
  }

  return (
    <TestCard id="thank-you-screen-card" commentableEntity={COMMENTABLE_ENTITY}>
      <TestCardHeader>
        <TestCardTitle>
          <TestCardHeading>Thank you screen</TestCardHeading>
          <TestCardHint>(shown to your audience only)</TestCardHint>
        </TestCardTitle>
        <TestCardActions>
          <HStack>
            <PaidFeatureIndicator />
            <CustomizableScreenToggle
              disabled={readOnly}
              screenType={screenType}
              onEnable={onEnable}
            />
          </HStack>
        </TestCardActions>

        <CommentThread
          entity={COMMENTABLE_ENTITY}
          isEntityPersisted={usabilityTestId !== null}
        />
      </TestCardHeader>
      <Box
        sx={
          isEnabled
            ? {
                // Chakra's <Collapse> below adds overflow: hidden which cuts off the side of
                // the inputs. It's only needed during the animations so this is a workaround.
                // See: https://github.com/chakra-ui/chakra-ui/issues/2966
                ".chakra-collapse": {
                  overflow: "initial !important",
                },
              }
            : {}
        }
      >
        <Collapse in={isEnabled} unmountOnExit>
          <TestCardBody>
            <ThankYouScreenFields />
          </TestCardBody>
        </Collapse>
      </Box>
    </TestCard>
  )
}
