import { PrototypeType } from "JavaScripts/types/figma-file-flow"
import { UsabilityTestSection } from "Types"

export function getPrototypeType(
  section: UsabilityTestSection
): PrototypeType | undefined {
  return section.prototype_type || undefined
}

// ts-prune-ignore-next
export function isPrototypeFreeRoam(section: UsabilityTestSection) {
  return getPrototypeType(section) === "free_roam"
}

export function isPrototypeTaskFlow(section: UsabilityTestSection) {
  return getPrototypeType(section) === "task_flow"
}
