import { Box, BoxProps, Center, Tooltip } from "@chakra-ui/react"
import React from "react"

type CellProps = {
  value: number
  max: number
  total: number
  maxIsShared: boolean
  cardLabel: string
  categoryLabel: string
} & BoxProps

export const Cell: React.FC<CellProps> = ({
  value,
  max,
  total,
  maxIsShared,
  cardLabel,
  categoryLabel,
  ...props
}) => {
  const percent = Math.round((value / total) * 100)
  const bg =
    categoryLabel !== "Unsorted" && value === max
      ? maxIsShared
        ? "brand.primary.100"
        : "brand.primary.300"
      : "gray.50"
  const tooltip = `${percent}% of the participants put “${cardLabel}” under “${categoryLabel}” category`

  if (value === 0) {
    return <Box bg="gray.50" rounded="md" {...props} />
  }

  return (
    <Tooltip hasArrow label={tooltip} textAlign="center">
      <Center
        bg={bg}
        rounded="md"
        userSelect="none"
        _hover={{
          outlineWidth: 2,
          outlineStyle: "solid",
          outlineColor: "gray.700",
        }}
        {...props}
      >
        {value === 0 ? "" : `${percent}%`}
      </Center>
    </Tooltip>
  )
}
