import { Consumer, Subscription } from "@rails/actioncable"
import { AppContext } from "Shared/contexts/AppContext"
import { UsabilityTestUserActivity } from "Types"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useContext, useEffect, useRef } from "react"
import { v4 as uuidv4 } from "uuid"

type ActivityStatus = "viewing" | "editing"

export type updateUserActivityAction = (status: ActivityStatus) => void

const UpdateActivityAction = "activity_updated"
type UpdateActivityData = {
  data: {
    status: ActivityStatus
  }
}

const KeepAliveAction = "keep_alive"

type UserActivitiesMessage = {
  data: {
    activities: []
  }
}

interface useUsabilityTestUserAcitivityLiveUpdatesReturnType {
  updateUserActivity: updateUserActivityAction
}

// The params `user_id` and `start_time` are used are not neccessary
// but will be useful to debug related issues.
interface UsabilityTestUserActivitySyncChannelParams {
  channel: "UsabilityTestUserActivitySyncChannel"
  usability_test_id: number
  session_id: string
  start_time: string
  user_id: number
  status?: ActivityStatus
}

export const useUsabilityTestUserActivityLiveUpdates = (
  usabilityTestId: number | null,
  userActivitiesRetrieved: (
    userActivities: UsabilityTestUserActivity[]
  ) => void,
  startWithReadOnly: boolean
): useUsabilityTestUserAcitivityLiveUpdatesReturnType => {
  const { consumer } = useContext(AppContext)
  const channel = useRef<Subscription<Consumer>>()
  const unsubscribe = () => {
    if (channel.current) {
      channel.current.unsubscribe()
      channel.current = undefined
    }
  }

  const updateActivity: updateUserActivityAction = (status) => {
    const data: UpdateActivityData = {
      data: {
        status: status,
      },
    }
    // Calls `UsabilityTestUserActivitySyncChannel#activity_updated(data)` on the server.
    channel.current?.perform(UpdateActivityAction, data)
  }

  // Keep alive every 30 seconds
  const REFRESH_INTERVAL = 30000 //ms

  useEffect(() => {
    const interval = setInterval(() => {
      if (consumer && !consumer.connection.disconnected) {
        channel.current?.perform(KeepAliveAction)
      }
    }, REFRESH_INTERVAL)

    return () => clearInterval(interval)
  }, [consumer])

  const currentUser = useCurrentUser()

  useEffect(() => {
    // Don't subscribe the channel if it's a new test
    if (usabilityTestId) {
      // Session ID is used to distinguish the current browser tab
      // and stored in sessionStorage.
      // If you duplicate the tab, the sessionStorage data is also duplicated on the copied tab.
      // Thus it's generated every time subscribing the channel.
      const sessionId = uuidv4()
      sessionStorage.setItem("tabId", sessionId)

      const channelParams: UsabilityTestUserActivitySyncChannelParams = {
        channel: "UsabilityTestUserActivitySyncChannel",
        usability_test_id: usabilityTestId,
        session_id: sessionId,
        start_time: new Date().toUTCString(),
        user_id: currentUser!.id,
      }
      if (startWithReadOnly) channelParams.status = "viewing"
      channel.current = consumer?.subscriptions.create(channelParams, {
        received: (message: UserActivitiesMessage) => {
          userActivitiesRetrieved(message.data.activities)
        },
      })

      // If we can't connect to the websocket, add a fake user activity so they can still edit.
      // Otherwise people with no websocket support can get stuck in a read-only state.
      if (consumer?.connection.disconnected) {
        userActivitiesRetrieved([
          {
            id: 0,
            user: {
              id: "0",
              name: "",
              avatar_url: "",
            },
            usability_test_id: String(usabilityTestId),
            session_id: sessionStorage.getItem("tabId") ?? "",
            started_at: new Date().toISOString(),
          },
        ])
      }

      window.addEventListener("unload", () => {
        unsubscribe()
      })
    }

    return () => {
      unsubscribe()
    }
  }, [usabilityTestId])

  return { updateUserActivity: updateActivity }
}
