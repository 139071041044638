import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
} from "@chakra-ui/react"
import React from "react"

type SidebarAlert = React.FC<
  React.PropsWithChildren<{
    status: AlertProps["status"]
    title: string
  }>
>

export const SidebarAlert: SidebarAlert = ({ children, status, title }) => {
  return (
    <Alert status={status} alignItems="flex-start" my={4}>
      <AlertIcon />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{children}</AlertDescription>
    </Alert>
  )
}
