import { countBy, shuffle, sortBy } from "lodash"
import { nanoid } from "nanoid"
import React, { useCallback, useMemo, useState } from "react"
import { createContext } from "react"

import { CardSortCard, CardSortCategory, CardSortOpenCategory } from "Types"

import { CategoryId, SortDataMap } from "./types"

interface CardSortContextInterface {
  active: boolean
  cardSortType: "open" | "closed" | "hybrid"
  sortData: SortDataMap
  sortCard: (
    cardId: number,
    oldCategoryId: CategoryId,
    newCategoryId: CategoryId,
    position: number
  ) => void
  addCategory: () => string
  deleteCategory: (id: string) => void
  renameCategory: (id: string, label: string) => void
  cards: CardSortCard[]
  categories: CardSortCategory[]
  openCategories: CardSortOpenCategory[]
  isAllCardsSorted: boolean
  numUnnamedOpenCategories: number
  isRenamingOpenCategoryId: string | null
  setIsRenamingOpenCategoryId: (openCategoryId: string | null) => void
  duplicatedCategoryLabels: string[]
}

const CardSortContext = createContext<CardSortContextInterface>({
  active: false,
  cardSortType: "closed",
  sortData: {
    unsorted: [],
  },
  sortCard: () => {
    throw new Error("No provider!")
  },
  addCategory: () => {
    throw new Error("No provider!")
  },
  deleteCategory: () => {
    throw new Error("No provider!")
  },
  renameCategory: () => {
    throw new Error("No provider!")
  },
  cards: [],
  categories: [],
  openCategories: [],
  isAllCardsSorted: false,
  numUnnamedOpenCategories: 0,
  isRenamingOpenCategoryId: "",
  setIsRenamingOpenCategoryId: () => {
    throw new Error("No provider!")
  },
  duplicatedCategoryLabels: [],
})

export const useCardSortContext = () => {
  const context = React.useContext(CardSortContext)
  if (!context) {
    throw new Error(
      "useCardSortContext must be used within a CardSortContextProvider"
    )
  }

  return context
}

interface Props {
  active: boolean
  cards: CardSortCard[]
  categories: CardSortCategory[]
  cardSortType: CardSortContextInterface["cardSortType"]
  shuffleCards: boolean
  shuffleCategories: boolean
}

export const CardSortContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({
  active,
  cards,
  categories,
  cardSortType,
  shuffleCards,
  shuffleCategories,
  children,
}) => {
  // In the backend, cards are sorted by both the number of responses and position
  // Just sort cards by position if not shuffleCards
  if (!shuffleCards) cards.sort((a, b) => a.position! - b.position!)

  const [isRenamingOpenCategoryId, setIsRenamingOpenCategoryId] = useState<
    string | null
  >(null)

  const [orderedCategories] = useState<CardSortCategory[]>(() =>
    shuffleCategories ? shuffle(categories) : sortBy(categories, "position")
  )
  const [openCategories, setUserCategories] = useState<CardSortOpenCategory[]>(
    []
  )

  const [sortData, setSortData] = useState<SortDataMap>(() => {
    let cardIds = cards.map((c) => c.id!)

    if (shuffleCards) cardIds = shuffle(cardIds)

    return {
      unsorted: cardIds,
    }
  })

  const addCategory = useCallback(() => {
    const newCategoryId = `open_${nanoid()}`
    setIsRenamingOpenCategoryId(newCategoryId)
    setUserCategories((prev) => [
      ...prev,
      { id: newCategoryId, label: "", card_sort_category_group_id: null },
    ])
    return newCategoryId
  }, [])

  // Users can only delete open categories
  // When deleting we will return all cards from the deleted category into "unsorted"
  const deleteCategory = useCallback(
    (id: string) => {
      const oldCardIds = sortData[id] ?? []
      setUserCategories((prev) => prev.filter((c) => c.id !== id))
      setSortData((prev) => ({
        ...prev,
        [id]: [],
        unsorted: [...prev.unsorted, ...oldCardIds],
      }))
    },
    [sortData]
  )

  const renameCategory = useCallback((id: string, label: string) => {
    label = label.trim()
    setUserCategories((prev) =>
      prev.map((c) => (c.id === id ? { ...c, label } : c))
    )
  }, [])

  const duplicatedCategoryLabels = useMemo(() => {
    const countsByLabel = countBy(
      [...orderedCategories, ...openCategories],
      "label"
    )
    return Object.keys(countsByLabel).filter(
      (categoryLabel) =>
        categoryLabel.length > 0 && countsByLabel[categoryLabel] > 1
    )
  }, [orderedCategories, openCategories])

  const isAllCardsSorted = sortData.unsorted.length === 0
  const numUnnamedOpenCategories = openCategories.filter(
    (c) => c.label === ""
  ).length

  const sortCard = useCallback(
    (
      cardId: number,
      oldCategoryId: CategoryId,
      newCategoryId: CategoryId,
      position: number
    ) => {
      setSortData((data) => {
        const oldCategory = [...data[oldCategoryId]]

        if (newCategoryId === "new") {
          newCategoryId = addCategory()
        }

        const newCategory =
          oldCategoryId === newCategoryId
            ? oldCategory
            : [...(data[newCategoryId] || [])]

        const oldIndex = oldCategory.indexOf(cardId)

        if (oldIndex === -1) return data

        if (oldCategoryId === newCategoryId) {
          newCategory[oldIndex] = newCategory[position]
          newCategory[position] = cardId
        } else {
          oldCategory.splice(oldIndex, 1)
          newCategory.splice(position, 0, cardId)
        }

        return {
          ...data,
          [oldCategoryId]: oldCategory,
          [newCategoryId]: newCategory,
        }
      })
    },
    [addCategory]
  )

  return (
    <CardSortContext.Provider
      value={{
        active,
        cardSortType: cardSortType,
        cards,
        categories: orderedCategories,
        openCategories,
        sortData,
        sortCard,
        addCategory,
        deleteCategory,
        renameCategory,
        isAllCardsSorted,
        numUnnamedOpenCategories,
        isRenamingOpenCategoryId,
        setIsRenamingOpenCategoryId,
        duplicatedCategoryLabels,
      }}
    >
      {children}
    </CardSortContext.Provider>
  )
}
