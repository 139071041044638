import { useContext } from "react"

import {
  getSearchParamsForPathValues,
  mergeSearchParams,
} from "Utilities/query-string"

import {
  QUERY_PATH as TEST_RESULTS_FILTER_QUERY_PATH,
  TestResultsFilterState,
} from "../context/test-result-filter-state"
import {
  QUERY_PATH as TEST_RESULTS_VIEW_STATE_QUERY_PATH,
  useTestResultViewState,
} from "../context/test-result-view-state"
import { compressFilters } from "../filters/compress-filters"

export const useResultPageSearchParams = () => {
  const { testResultsFilters } = useContext(TestResultsFilterState)
  const { viewState } = useTestResultViewState()
  const currentSearchParams = new URLSearchParams(window.location.search)

  const filterSearchParams = getSearchParamsForPathValues(
    TEST_RESULTS_FILTER_QUERY_PATH,
    compressFilters(testResultsFilters),
    currentSearchParams
  )
  const viewStateSearchParams = getSearchParamsForPathValues(
    TEST_RESULTS_VIEW_STATE_QUERY_PATH,
    viewState,
    currentSearchParams
  )

  return mergeSearchParams(filterSearchParams, viewStateSearchParams)
}
