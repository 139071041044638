import { createIcon } from "@chakra-ui/react"
import React from "react"

export const MicrosoftIcon = createIcon({
  displayName: "MicrosoftIcon",
  viewBox: "0 0 48 48",
  path: (
    <>
      <rect x="4" y="4" width="19" height="19" fill="#F25022" />
      <rect x="25" y="4" width="19" height="19" fill="#80BA01" />
      <rect x="25" y="25" width="19" height="19" fill="#FFB902" />
      <rect x="4" y="25" width="19" height="19" fill="#02A4EF" />
    </>
  ),
})
