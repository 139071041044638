import React from "react"

import { ParticipantResponse } from "Types"

import { Button, Text } from "@chakra-ui/react"
import { createErrorContent } from "./create-error-content"

interface Props {
  message: string
  response: ParticipantResponse
  retry: null | (() => void)
}

const SubmitError: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { message, response, retry } = props
  return (
    <>
      <Text>
        <strong>{message}</strong>
      </Text>
      {response._isSubmitting && <Text>Working&hellip;</Text>}
      {retry !== null && !response._isSubmitting && (
        <Text>
          <Button variant="link" onClick={retry}>
            Click here
          </Button>{" "}
          to retry.
        </Text>
      )}
    </>
  )
}

export function submitErrorContent(props: Props) {
  return createErrorContent(<SubmitError {...props} />)
}
