import { useSelector } from "react-redux"

import { getFigmaFileVersionById } from "Redux/reducers/test-results/selectors"
import { useSectionContext } from "UsabilityHub/contexts"

export function useFigmaFileVersion(figmaFileVersionId?: number) {
  const { section } = useSectionContext()
  const ffvId =
    figmaFileVersionId ?? section.figma_file_flow!.figma_file_version_id

  return useSelector(getFigmaFileVersionById(ffvId))
}
