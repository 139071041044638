import {
  Alert,
  AlertDescription,
  AlertIcon,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"

import { MailToAnchor } from "Components/anchors/basic"
import Constants from "Constants/shared.json"
import { ROUTES } from "UsabilityHub/views/routes"

interface Props {
  hasIncorrectLanguage: boolean
  uniqueId: string
}

export const UsabilityTestDisabledBanner = ({
  hasIncorrectLanguage,
  uniqueId,
}: Props) => {
  return (
    <Stack>
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>
          <Text>
            This test has been automatically disabled due to reports from
            testers. We{"\u2019"}ll check it out and re-enable it if we don
            {"\u2019"}t find any issues. Please feel free to contact us in the
            meantime at{" "}
            <MailToAnchor email={Constants.CUSTOMER_SUPPORT_EMAIL_ADDRESS} /> if
            you would like to discuss this with us.
          </Text>
        </AlertDescription>
      </Alert>
      {hasIncorrectLanguage && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            <Text>
              This test was flagged due to language issues. Please ensure that
              your test instructions and content are in the <i>same language</i>
              , only in a <i>single language</i>, and that you have the test
              {"\u2019"}s language{" "}
              <Link href={ROUTES.TEST.EDIT.buildPath({ testId: uniqueId })}>
                set correctly here
              </Link>
              .
            </Text>
          </AlertDescription>
        </Alert>
      )}
    </Stack>
  )
}
