import React from "react"

import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"

import { ScreenClicks } from "../ScreenClicks"
import { ScreenView } from "../ScreenResults"
import { useScreenResult } from "../useResults"

import { ZoomableContainer } from "./ZoomableContainer"

interface ZoomableScreenResultDetailsPanelProps {
  clickView: ScreenView
  clicks: NormalizedClick[]
}

/**
This Zoomable variant of the ScreenResultsDetailsPanel lets the parent container dictate the size and will fill the available space with the ScreenClicks component.

Behaviour:
If the figma image is too big to fit, it will be contained to the parent element size but still maintain its aspect-ratio.
If it is smaller, it will stay true to its native size and be placed in the middle of the available space.

Technical Implementation:
Its using a grid with just 1 row and 1 columns, both 1fr wide. This leaves the grid with only 1 grid cell that is 100% in width and in height. 
This allows the content of that cell to position itself in the middle while still maintaining its aspect ratio.

To maintain the aspect ratio we use a wrapper div, which sets the max-height to 100%, max-width to 100% and the aspect-ratio according to the figma image.
The wrapper then aligns itself to the center of the grid cell, this is for when its content is not big enough to fill the whole space.

Demo: https://stackblitz.com/edit/vitejs-vite-1ai2a3?file=src/App.tsx
 */

export function ZoomableScreenResultDetailsPanel({
  clicks,
  clickView,
}: ZoomableScreenResultDetailsPanelProps) {
  const { figmaImage } = useScreenResult()

  return (
    <ZoomableContainer targetDimensions={figmaImage}>
      <ScreenClicks
        // Image/Heatmap/Clickmap will be in their native sizes (and aspect ratios) until hitting the max width of the container
        style={{ width: "100%", height: "100%" }}
        figmaImage={figmaImage}
        clicks={clicks}
        clickView={clickView}
      />
    </ZoomableContainer>
  )
}
