import { Flex, Text } from "@chakra-ui/react"
import { centsToDollars, formatDollars } from "Utilities/currency"
import { pluralizeWithCount } from "Utilities/string"
import React from "react"
import { useGetTeamMembersSummary } from "~/api/generated/usabilityhub-components"
import { Plan } from "~/api/generated/usabilityhubSchemas"

type BillingSummaryProps = {
  selectedPlan: Plan
}

export const BillingSummary: React.FC<BillingSummaryProps> = ({
  selectedPlan,
}) => {
  const { data, isLoading } = useGetTeamMembersSummary({})

  const numberOfMonths = selectedPlan.interval === "year" ? 12 : 1
  const newPaidSeats =
    selectedPlan.included_collaborator_seats === null || data === undefined
      ? 0
      : Math.max(
          0,
          data.collaborators_count - selectedPlan.included_collaborator_seats
        )

  return isLoading ? (
    <Text mt={6}>Loading...</Text>
  ) : (
    <>
      <Text fontWeight="semibold" mt={6}>
        Billing summary
      </Text>
      <Flex justify="space-between" align="center" py={3}>
        <Flex direction="column">
          <Text fontSize="md" fontWeight="normal">
            {selectedPlan.name} plan
            {numberOfMonths > 1 ? ` x ${numberOfMonths} months` : ""}
          </Text>
          <Text fontSize="xs" fontWeight="normal" color="text.secondary">
            includes {selectedPlan.included_collaborator_seats} collaborators
          </Text>
        </Flex>

        <Text fontSize="md" fontWeight="medium">
          {formatDollars(centsToDollars(selectedPlan.monthly_price))}
          {numberOfMonths > 1 ? ` x ${numberOfMonths}` : ""}
        </Text>
      </Flex>

      {newPaidSeats > 0 && (
        <Flex justify="space-between" align="center" pb={3}>
          <Flex direction="column">
            <Text fontSize="md" fontWeight="normal">
              +{pluralizeWithCount(newPaidSeats, "additional collaborator")}
            </Text>
          </Flex>

          <Text fontSize="md" fontWeight="medium">
            {formatDollars(
              centsToDollars(
                selectedPlan.price_per_additional_collaborator_in_cents *
                  newPaidSeats
              )
            )}
            {numberOfMonths > 1 ? ` x ${numberOfMonths}` : ""}
          </Text>
        </Flex>
      )}

      <Flex
        justify="space-between"
        borderTopWidth={1}
        borderColor="gray.200"
        py={3}
      >
        <Text fontSize="lg" fontWeight="bold">
          Total (USD)
        </Text>
        <Text fontSize="md" fontWeight="medium">
          {formatDollars(
            centsToDollars(
              (selectedPlan.monthly_price +
                selectedPlan.price_per_additional_collaborator_in_cents *
                  newPaidSeats) *
                numberOfMonths
            )
          )}
          /{selectedPlan.interval}
        </Text>
      </Flex>
    </>
  )
}
