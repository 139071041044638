import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react"
import { Field, FieldProps, Form, Formik } from "formik"
import React from "react"

import { SubmitButton } from "Components/button/submit-button"
import { ROUTES } from "UsabilityHub/views/routes"
import { FunctionalModal } from "Utilities/modals/types"
import { useNavigate } from "react-router-dom"
import { useCreateVariationSet } from "~/api/generated/usabilityhub-components"

interface Props {
  usabilityTestIds: number[]
}

interface NewTestSetModalFormValues {
  new_set_name: string
}

export const CreateVariationSetModal: FunctionalModal<Props> = ({
  usabilityTestIds,
  onClose,
}) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { mutate: createVariationSet } = useCreateVariationSet({
    onSuccess: (data, variables) => {
      toast({
        title: `Created set “${variables.body.name}”`,
        status: "success",
      })
      onClose()
      navigate(
        ROUTES.TEST_SET.buildPath({ testSetId: data.test_set.unique_id })
      )
    },
    onError: () => {
      toast({ title: "Creating test set failed", status: "error" })
    },
  })

  return (
    <Modal size="lg" isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent data-qa="new-test-set-modal">
          <Formik<NewTestSetModalFormValues>
            initialValues={{
              new_set_name: "",
            }}
            onSubmit={({ new_set_name }) => {
              createVariationSet({
                body: {
                  name: new_set_name,
                  usability_test_ids: usabilityTestIds,
                },
              })
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <ModalHeader>Create a variation set</ModalHeader>
                <ModalBody>
                  <Text mb={2}>
                    Variation sets allow for testing multiple design versions or
                    conducting several tests on a single design, with unique
                    participants for each study. They work for both
                    self-recruited participants and those from the Lyssna panel.
                  </Text>
                  <FormControl id="new_set_name">
                    <FormLabel>Variation set name</FormLabel>
                    <Field name="new_set_name">
                      {({ field }: FieldProps) => (
                        <Input
                          placeholder="e.g. Homepage layouts"
                          required
                          {...field}
                        />
                      )}
                    </Field>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <HStack>
                    <Button
                      variant="outline"
                      onClick={onClose}
                      isDisabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <SubmitButton
                      isLoading={isSubmitting}
                      loadingAction="Creating"
                      data-qa="submit-create-variation-set-modal"
                    >
                      Create variation set
                    </SubmitButton>
                  </HStack>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
