import { Grid, GridItem } from "@chakra-ui/react"
import React from "react"

import PreferenceItem from "Components/preference-test-layout/preference-item"
import Props from "Components/preference-test-layout/preference-overview-props"

export const PreferenceGrid: React.FC<React.PropsWithChildren<Props>> = ({
  sectionScreenshots,
  isTaskStarted,
  onStartTask,
  onSelectOption,
}) => {
  return (
    <Grid
      templateColumns={`repeat(${sectionScreenshots.length}, 1fr)`}
      gap={6}
      mx={6}
    >
      {sectionScreenshots.map((sectionScreenshot, index) => (
        <GridItem
          key={sectionScreenshot.id}
          maxW={`calc(${100 / sectionScreenshots.length}vw)`}
        >
          <PreferenceItem
            sectionScreenshot={sectionScreenshot}
            onSelect={() => onSelectOption(index)}
            isTaskStarted={isTaskStarted}
            onStartTask={onStartTask}
          />
        </GridItem>
      ))}
    </Grid>
  )
}
