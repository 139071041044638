import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react"
import React from "react"

import { formatPercentage01 } from "Utilities/number"
import { formatHumanizedIntegerDuration } from "Utilities/time"

interface Props {
  averageSuccessTaskDuration: number
  successRate: number
}

export const NavigationTestResultsSummary: React.FC<
  React.PropsWithChildren<Props>
> = (props) => {
  const { successRate, averageSuccessTaskDuration } = props
  return (
    <Alert status="info">
      <AlertIcon />
      {successRate > 0 ? (
        <AlertDescription>
          A total <strong>{formatPercentage01(successRate)}</strong> of
          participants completed all steps in an average of{" "}
          <strong>
            {formatHumanizedIntegerDuration(averageSuccessTaskDuration)}
          </strong>
          .
        </AlertDescription>
      ) : (
        <AlertDescription>
          No participants completed all steps.
        </AlertDescription>
      )}
    </Alert>
  )
}
