import { Flex, Spinner, Text } from "@chakra-ui/react"
import React, { useMemo } from "react"
import {
  useTypedParams,
  useTypedSearchParams,
} from "react-router-typesafe-routes/dom"

import { Page, PageMain } from "Shared/components/Page/Page"
import { TestBrandingType } from "Types"
import { TestBrandingContextProvider } from "UsabilityHub/components/UsabilityTest/context/testBranding"
import { ROUTES } from "UsabilityHub/views/routes"

import { PreviewBanner } from "UsabilityHub/components/UsabilityTest/preview-banner"
import { usePreviewModeratedStudyApplication } from "~/api/generated/usabilityhub-components"
import { ApplicationContentLayout } from "./ApplicationContentLayout"

export const RootPreviewLayout: React.FC = () => {
  const { moderatedStudyId } = useTypedParams(ROUTES.INTERVIEW.PREVIEW)
  const [{ recruitmentLinkId }] = useTypedSearchParams(ROUTES.INTERVIEW.PREVIEW)

  const { data, isLoading, error } = usePreviewModeratedStudyApplication({
    pathParams: {
      moderatedStudyId,
    },
    queryParams: recruitmentLinkId
      ? {
          // Optional, will only be set if we're previewing a link specifically
          recruitment_link_id: recruitmentLinkId,
        }
      : {},
  })

  const branding = useMemo(
    () => ({
      type: TestBrandingType.UsabilityHub,
      logo_url: "",
      button_color: data?.brand_color ?? "",
    }),
    [data?.brand_color]
  )

  const pageTitle = data ? data.moderated_study.external_name : "Loading..."

  return (
    <Page title={pageTitle} includeTitlePrefix={false}>
      <PreviewBanner />

      <PageMain direction={{ base: "column", lg: "row" }}>
        {error && (
          <Flex
            flexBasis={1}
            flexGrow={1}
            justifyContent="center"
            align="center"
            bg="white"
          >
            <Text>Something went wrong</Text>
          </Flex>
        )}

        {isLoading && (
          <Flex
            flexBasis={1}
            flexGrow={1}
            justifyContent="center"
            align="center"
            bg="white"
          >
            <Spinner />
          </Flex>
        )}

        {!error && !isLoading && (
          <TestBrandingContextProvider branding={branding}>
            <ApplicationContentLayout application={data} />
          </TestBrandingContextProvider>
        )}
      </PageMain>
    </Page>
  )
}
