import { Text } from "@chakra-ui/react"
import React from "react"

import { SectionCard } from "../SectionCard"
import { SectionInstructionField } from "../SectionFields/SectionInstructionField"
import { SectionMedia } from "../SectionMedia/SectionMedia"
import { SectionQuestions } from "../SectionQuestions/SectionQuestions"
import { SectionLogicActions } from "../TestLogicActions/TestLogicActions"

export const NavigationSectionCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <SectionCard>
      <SectionLogicActions />
      <SectionInstructionField placeholder="How would you..." />
      <SectionMedia allowedMediaTypes={["image"]} />
      <Text as="label" fontWeight="semibold">
        Follow up questions
      </Text>
      <SectionQuestions />
    </SectionCard>
  )
}
