import React from "react"

const INLINE_MARKDOWN = (
  <>
    <em>_italic_</em> <strong>**bold**</strong>
  </>
)
const BLOCK_MARKDOWN = <>{INLINE_MARKDOWN} - bullet list 1. ordered list</>

type MarkdownUsageType = "block" | "inline"

export const useMarkdownUsage = (type: MarkdownUsageType) =>
  type === "block" ? BLOCK_MARKDOWN : INLINE_MARKDOWN
