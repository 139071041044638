import { ResponseSectionRecording } from "JavaScripts/types/recording"
import { getResponseSectionRecording } from "Redux/reducers/test-results/selectors"
import { ResponseSection } from "Types"
import { useSelector } from "react-redux"

export const useResponseSectionRecording = (
  responseSection: ResponseSection | null
): ResponseSectionRecording | null => {
  const responseSectionRecording = useSelector(
    getResponseSectionRecording(responseSection?.id ?? null)
  )

  if (!responseSectionRecording) {
    return null
  }

  const { types } = responseSectionRecording
  const isAudio = types.length === 1 && types[0] === "microphone"

  return responseSection
    ? {
        ...responseSectionRecording,
        isAudio,
        responseSection: responseSection,
      }
    : null
}
