import { useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import { createClientHitzoneFilter } from "../filters/create-filters/client-hitzone-filter"
import { createHitzoneFilter } from "../filters/create-filters/hitzone-filter"
import { createOtherHitzoneFilter } from "../filters/create-filters/other-hitzone-filter"
import { getFilterUtilityFunctions } from "../filters/get-filter-utility-functions"

const {
  isFilter: isHitzoneFilter,
  isFiltered: isHitzoneFiltered,
  toggleFilter: toggleHitzoneFilter,
} = getFilterUtilityFunctions(createHitzoneFilter)

export const useScreenshotHitzoneFilter = (
  sectionId: number,
  screenshotId: number,
  hitzoneId: number
) => {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handleToggleFilter = () => {
    setTestResultsFilters((filters) =>
      toggleHitzoneFilter(filters, sectionId, screenshotId, hitzoneId)
    )
  }

  return {
    isFilter: isHitzoneFilter(
      testResultsFilters,
      sectionId,
      screenshotId,
      hitzoneId
    ),
    isFiltered: isHitzoneFiltered(
      testResultsFilters,
      sectionId,
      screenshotId,
      hitzoneId
    ),
    toggleFilter: handleToggleFilter,
  }
}

const {
  isFilter: isClientHitzoneFilter,
  isFiltered: isClientHitzoneFiltered,
  toggleFilter: toggleClientHitzoneFilter,
} = getFilterUtilityFunctions(createClientHitzoneFilter)

export const useScreenshotClientHitzoneFilter = (
  sectionId: number,
  screenshotId: number,
  clientHitzoneId: string
) => {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handleToggleFilter = () => {
    setTestResultsFilters((filters) =>
      toggleClientHitzoneFilter(
        filters,
        sectionId,
        screenshotId,
        clientHitzoneId
      )
    )
  }

  return {
    isFilter: isClientHitzoneFilter(
      testResultsFilters,
      sectionId,
      screenshotId,
      clientHitzoneId
    ),
    isFiltered: isClientHitzoneFiltered(
      testResultsFilters,
      sectionId,
      screenshotId,
      clientHitzoneId
    ),
    toggleFilter: handleToggleFilter,
  }
}

const {
  isFilter: isOtherHitzoneFilter,
  isFiltered: isOtherHitzoneFiltered,
  toggleFilter: toggleOtherHitzoneFilter,
} = getFilterUtilityFunctions(createOtherHitzoneFilter)

export const useScreenshotOtherFilter = (
  sectionId: number,
  screenshotId: number
) => {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handleToggleFilter = () => {
    setTestResultsFilters((filters) =>
      toggleOtherHitzoneFilter(filters, sectionId, screenshotId)
    )
  }

  return {
    isFilter: isOtherHitzoneFilter(testResultsFilters, sectionId, screenshotId),
    isFiltered: isOtherHitzoneFiltered(
      testResultsFilters,
      sectionId,
      screenshotId
    ),
    toggleFilter: handleToggleFilter,
  }
}
