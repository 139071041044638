import { Flex } from "@chakra-ui/react"
import React, { forwardRef } from "react"

export const LocationList = forwardRef<HTMLDivElement, React.PropsWithChildren>(
  ({ children }, ref) => {
    return (
      <Flex direction="column" gap={1} py={2} ref={ref}>
        {children}
      </Flex>
    )
  }
)
