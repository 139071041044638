import { Switch } from "@chakra-ui/react"
import React from "react"
import { ChangeEventHandler } from "react"
import { WrappedFieldProps } from "redux-form"

interface CardSortSectionCardsCheckboxFieldProps {
  label: string
  disabled?: boolean
  handleCheckboxChange?: (checked: boolean) => void
}

export const WrappedSwitch: React.FC<
  WrappedFieldProps & CardSortSectionCardsCheckboxFieldProps
> = ({ input, label, disabled, handleCheckboxChange }) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    handleCheckboxChange?.(event.target.checked)
    input.onChange(event.target.checked)
  }

  return (
    <Switch
      size="sm"
      id={input.name}
      isChecked={input.value as boolean}
      isDisabled={disabled}
      onChange={handleChange}
      display="flex"
      alignItems="center"
      flexDir="row-reverse"
      gap={2}
      fontSize="sm"
    >
      {label}
    </Switch>
  )
}
