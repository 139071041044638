import { groupBy } from "lodash"

import { ResponseSectionFigmaTask } from "Types"
import { areTasksCommon } from "Utilities/is-tasks-common"

export function getCommonPathKeys(tasks: ResponseSectionFigmaTask[]): string[] {
  const tasksByPath = groupBy(tasks, "path")
  const commonPathKeys = Object.entries(tasksByPath).reduce(
    (acc, [path, tasks]) => (areTasksCommon(tasks) ? [...acc, path] : acc),
    []
  )

  return commonPathKeys
}
