import { createConsumer } from "@rails/actioncable"
import React from "react"
import { Helmet } from "react-helmet"

import { getEnvState } from "JavaScripts/state"
import { AppContext } from "Shared/contexts/AppContext"

export type UsabilityHubProvider = React.FC<
  React.PropsWithChildren<{
    isSPA: boolean
  }>
>

export const UsabilityHubProvider: UsabilityHubProvider = ({
  children,
  isSPA,
}) => {
  const env = getEnvState()
  const protocol = window.location.protocol === "http:" ? "ws" : "wss"
  const consumer = createConsumer(`${protocol}://${window.location.host}/cable`)
  const app = {
    isSPA,
    title: "Lyssna",
    url: `${window.location.protocol || "http:"}//${
      window.location.host || String(env.APP_DOMAIN)
    }/`,
    consumer: consumer,
  }
  return (
    <AppContext.Provider value={app}>
      <Helmet titleTemplate={`${app.title} • %s`} />
      {children}
    </AppContext.Provider>
  )
}
