import { FigmaImage, FigmaTaskScreenResult } from "Types"

import { getAggregateScreenResults } from "./get-aggregate-screen-results"

export function getAggregatedScreenResultsByFigmaImages(
  figmaImages: FigmaImage[],
  screenResults: FigmaTaskScreenResult[][]
) {
  const aggregatedScreenResults: FigmaTaskScreenResult[] = []

  for (const figmaImage of figmaImages) {
    const nodeScreenResults = screenResults.flatMap((screenResults) =>
      screenResults.filter(
        (screenResult) => screenResult.nodeId === figmaImage.figma_node_id
      )
    )

    if (nodeScreenResults.length === 0) {
      aggregatedScreenResults.push({
        nodeId: figmaImage.figma_node_id,
        figmaImage,
        meta: {
          clicks: [],
          duration: 0,
          misclickRate: 0,
          type: "aggregate",
          participants: [],
        },
      })
    } else {
      aggregatedScreenResults.push(getAggregateScreenResults(nodeScreenResults))
    }
  }

  return aggregatedScreenResults
}
