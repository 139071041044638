import { Button, Flex, Tooltip } from "@chakra-ui/react"
import { DeleteResponseModal } from "Components/delete-response-modal/delete-response-modal"
import { ReportUserModal } from "Components/report-user-modal/report-user-modal"
import { State } from "Redux/app-store"
import {
  getOrderForResponseId,
  isReadonly,
} from "Redux/reducers/test-results/selectors"
import { Flag02OutlineIcon } from "Shared/icons/untitled-ui/Flag02OutlineIcon"
import { useMaybeCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModal } from "Utilities/modals/use-modal"
import { isPanelOrdered } from "Utilities/response"
import React from "react"
import { useSelector } from "react-redux"

interface Props {
  response: {
    id: number
    order_id: number | null
  }
  onClose: () => void
}

export const IndividualResponseFooter: React.FC<Props> = ({
  response,
  onClose,
}) => {
  const { open: openDeleteResponseModal } = useModal(DeleteResponseModal)
  const { open: openReportUserModal } = useModal(ReportUserModal)
  const canManage = useMaybeCurrentUser()?.can_manage_tests ?? false

  const isAllowedToDelete =
    canManage &&
    useSelector((state: State) => {
      return !isReadonly(state)
    })

  const withinReviewPeriod = useSelector((state: State) => {
    const order = getOrderForResponseId(state, response.id)
    return order === null || !order.has_response_review_period_elapsed
  })

  if (!isAllowedToDelete) return null

  const onDelete = () =>
    openDeleteResponseModal({ responseId: response.id, onSuccess: onClose })

  const onReport = () =>
    openReportUserModal({ responseId: response.id, onSuccess: onClose })

  return (
    <Flex w="full" gap={2}>
      {isPanelOrdered(response) && withinReviewPeriod && (
        <Button variant="outline" onClick={onReport}>
          <Flag02OutlineIcon color="gray.400" mr={2} />
          Report response
        </Button>
      )}
      <Tooltip hasArrow placement="top" label="Delete response">
        <Button colorScheme="red" onClick={onDelete}>
          Delete
        </Button>
      </Tooltip>
    </Flex>
  )
}
