import * as React from "react"

const SectionIndexContext = React.createContext<number | null>(null)

interface SectionProviderProps {
  value: number
}

export const SectionIndexProvider: React.FC<
  React.PropsWithChildren<SectionProviderProps>
> = ({ value, children }) => {
  return (
    <SectionIndexContext.Provider value={value}>
      {children}
    </SectionIndexContext.Provider>
  )
}

export const useSectionIndexContext = (): number => {
  const context = React.useContext(SectionIndexContext)

  if (context === null) {
    throw new Error(
      `useSectionIndexContext must be rendered within the SectionIndexProvider`
    )
  }

  return context
}
