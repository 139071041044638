import { debounce } from "lodash"
import { MutableRefObject, useEffect, useState } from "react"

const getDimensions = <T extends HTMLElement>(element: T | null) => {
  if (!element) return null

  const { width, height } = element.getBoundingClientRect()

  return { width, height }
}

export function useElementSize<T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  delay = 0
) {
  const [dimensions, setDimensions] = useState(getDimensions(ref.current))

  useEffect(() => {
    function handleResize() {
      setDimensions(getDimensions(ref.current))
    }

    const debouncedHandleResize = debounce(handleResize, delay)
    window.addEventListener("resize", debouncedHandleResize)

    handleResize()
    return () => window.removeEventListener("resize", debouncedHandleResize)
  }, [ref, delay])

  return dimensions
}
