import { Button } from "DesignSystem/components"
import { Link01SolidIcon } from "Shared/icons/untitled-ui/Link01SolidIcon"
import React from "react"

export const ShareViaLinkButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    leftIcon={<Link01SolidIcon color="ds.icon.default" />}
    onClick={onClick}
  >
    Share results
  </Button>
)
