import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const ChatPlusIcon = createIcon({
  displayName: "ChatPlusIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00002 12C4.00002 8.24148 7.46776 5 12 5C16.5323 5 20 8.24148 20 12C20 15.7585 16.5323 19 12 19C10.6098 19 9.30816 18.6899 8.17583 18.1488C7.98071 18.0555 7.76062 18.0281 7.54857 18.0705L4.56804 18.6666L5.33133 16.6312C5.44822 16.3195 5.40234 15.97 5.20894 15.6991C4.43801 14.619 4.00002 13.3518 4.00002 12ZM12 3C6.59115 3 2.00002 6.92196 2.00002 12C2.00002 13.6065 2.4678 15.1108 3.27874 16.4087L2.06369 19.6489C1.9362 19.9888 2.003 20.3712 2.23822 20.6478C2.47343 20.9244 2.84009 21.0518 3.19613 20.9806L7.62323 20.0952C8.94859 20.6761 10.4348 21 12 21C17.4089 21 22 17.078 22 12C22 6.92196 17.4089 3 12 3ZM13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V11L8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H11V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H13V8Z"
      fill="currentColor"
    />
  ),
})
