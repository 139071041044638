import {
  Button,
  ButtonProps,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Spinner,
  useBoolean,
  usePrefersReducedMotion,
} from "@chakra-ui/react"
import React, {
  ComponentProps,
  MutableRefObject,
  useEffect,
  useRef,
} from "react"

import { PlanDrawerFeatures, PlanUpgradeSource } from "Types"
import { FunctionalModal } from "Utilities/modals/types"
import { useModal } from "Utilities/modals/use-modal"

import { usePlans } from "UsabilityHub/hooks/usePlans"
import {
  useHasUpcomingSubscriptionDuringATrial,
  useIsTrialing,
} from "Utilities/account"
import { sortBy } from "lodash"
import { useTrackEvent } from "~/api/generated/usabilityhub-components"
import { featurePriority } from "../UpgradeUsabilityTestBanner/testBuilderFeatures"
import { PlanChanger } from "./plan-changer"
import { PlanChangerContextProvider } from "./plan-changer-context"

interface Props {
  source: PlanUpgradeSource
  requestedFeatures?: PlanDrawerFeatures
  initialState?: ComponentProps<
    typeof PlanChangerContextProvider
  >["initialScreenState"]
  onPlanDrawerOpen?: () => void
}

export const PlanDrawerTrigger: React.FC<
  Props & Omit<ButtonProps, "onClick">
> = ({
  initialState,
  source,
  requestedFeatures = [],
  onPlanDrawerOpen,
  ...rest
}) => {
  const triggerRef = useRef<HTMLButtonElement | null>(null)
  const { open } = useModal(PlanDrawer, "plan-drawer")

  return (
    <Button
      ref={triggerRef}
      onClick={() => {
        open({
          initialState,
          source,
          requestedFeatures,
          triggerRef,
        })
        onPlanDrawerOpen?.()
      }}
      {...rest}
    />
  )
}

// This version doesn't push a FunctionalModal, it just renders the drawer in place.
// It keeps track of its own state for determining whether the draw is open.
// Useful for when you want an initially open drawer that doesn't have a trigger, for example
// during the onboarding flow.
export const StaticPlanDrawer: React.FC<Props> = (props) => {
  const [isOpen, { off: handleClose }] = useBoolean(true)

  return <PlanDrawer isOpen={isOpen} onClose={handleClose} {...props} />
}

const PlanDrawer: FunctionalModal<
  Props & { triggerRef?: MutableRefObject<HTMLButtonElement | null> }
> = ({
  initialState,
  triggerRef,
  source,
  requestedFeatures = [],
  isOpen,
  onClose,
}) => {
  const { mutate: trackEvent } = useTrackEvent()
  const plans = usePlans()
  const feature =
    sortBy(requestedFeatures, ({ feature }) => featurePriority(feature))[0]
      ?.feature ?? null
  useEffect(() => {
    trackEvent({
      body: {
        event: "account_clicked_upsell_link",
        event_properties: { feature, source },
      },
    })
  }, [feature, source])
  const isTrial = useIsTrialing()
  const prefersReducedMotion = usePrefersReducedMotion()

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={triggerRef}
      >
        <DrawerOverlay />
        <DrawerContent
          maxW="1010px"
          data-qa="change-plan-drawer"
          motionProps={
            prefersReducedMotion
              ? { transformTemplate: () => "none" }
              : undefined
          }
        >
          <DrawerCloseButton />
          <DrawerHeader
            fontSize="xl"
            fontWeight="bold"
            borderBottomWidth={1}
            borderColor="gray.200"
          >
            {header(isTrial)}
          </DrawerHeader>

          <DrawerBody p={0}>
            {plans ? (
              <PlanChangerContextProvider
                allPlans={plans}
                source={source}
                requestedFeatures={requestedFeatures}
                initialScreenState={initialState}
                onClose={onClose}
              >
                <PlanChanger />
              </PlanChangerContextProvider>
            ) : (
              <Center h="full">
                <Spinner />
              </Center>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

const header = (isTrial: boolean) => {
  const hasUpcomingSubDuringATrial = useHasUpcomingSubscriptionDuringATrial()

  return isTrial && !hasUpcomingSubDuringATrial ? "Plans" : "Change your plan"
}
