export enum ActionType {
  INITIALIZE_TEST_RESULTS = "INITIALIZE_TEST_RESULTS",
  ADD_COMMON_PATH_FILTER = "TEST_RESULTS_ADD_COMMON_PATH_FILTER",
  ADD_ANSWER_FILTER = "TEST_RESULTS_ADD_ANSWER_FILTER",
  ADD_HITZONE_FILTER = "TEST_RESULTS_ADD_HITZONE_FILTER",
  ADD_OTHER_CLICKS_FILTER = "TEST_RESULTS_ADD_OTHER_CLICKS_FILTER",
  ADD_PREFERENCE_FILTER = "TEST_RESULTS_ADD_PREFERENCE_FILTER",
  ADD_TAG_FILTER = "TEST_RESULTS_ADD_TAG_FILTER",
  APPLY_RESPONSE_FILTER = "TEST_RESULTS_APPLY_RESPONSE_FILTER",
  CHANGE_CLICK_SELECTION = "TEST_RESULTS_RESIZE_CLICK_SELECTION",
  CLEAR_ANSWER_RANK_FILTER = "TEST_RESULTS_CLEAR_ANSWER_RANK_FILTER",
  COMMIT_RESPONSE_FILTER = "TEST_RESULTS_COMMIT_RESPONSE_FILTER",
  CREATE_QUESTION_TAG_FAILURE = "TEST_RESULTS_CREATE_QUESTION_TAG_FAILURE",
  CREATE_QUESTION_TAG_REQUEST = "TEST_RESULTS_CREATE_QUESTION_TAG_REQUEST",
  CREATE_QUESTION_TAG_SUCCESS = "TEST_RESULTS_CREATE_QUESTION_TAG_SUCCESS",
  DELETE_RESPONSE_FAILURE = "TEST_RESULTS_DELETE_RESPONSE_FAILURE",
  DELETE_RESPONSE_REQUEST = "TEST_RESULTS_DELETE_RESPONSE_REQUEST",
  DELETE_RESPONSE_SUCCESS = "TEST_RESULTS_DELETE_RESPONSE_SUCCESS",
  REMOVE_ALL_ANSWER_FILTERS = "TEST_RESULTS_REMOVE_ALL_ANSWER_FILTERS",
  REMOVE_ALL_FILTERS = "TEST_RESULTS_REMOVE_ALL_FILTERS",
  REMOVE_COMMON_PATH_FILTER = "TEST_RESULTS_REMOVE_COMMON_PATH_FILTER",
  REMOVE_ANSWER_FILTER = "TEST_RESULTS_REMOVE_ANSWER_FILTER",
  REMOVE_CLICK_SELECTION = "TEST_RESULTS_REMOVE_CLICK_SELECTION",
  REMOVE_CLICK_SELECTION_FILTER = "TEST_RESULTS_REMOVE_CLICK_SELECTION_FILTER",
  REMOVE_HITZONE_FILTER = "TEST_RESULTS_REMOVE_HITZONE_FILTER",
  REMOVE_OTHER_CLICKS_FILTER = "TEST_RESULTS_REMOVE_OTHER_CLICKS_FILTER",
  REMOVE_PREFERENCE_FILTER = "TEST_RESULTS_REMOVE_PREFERENCE_FILTER",
  REMOVE_QUESTION_TAG_FAILURE = "TEST_RESULTS_REMOVE_QUESTION_TAG_FAILURE",
  REMOVE_QUESTION_TAG_REQUEST = "TEST_RESULTS_REMOVE_QUESTION_TAG_REQUEST",
  REMOVE_QUESTION_TAG_SUCCESS = "TEST_RESULTS_REMOVE_QUESTION_TAG_SUCCESS",
  REMOVE_TAG_FILTER = "TEST_RESULTS_REMOVE_TAG_FILTER",
  SAVE_ORDER_RESPONSES_REVIEW_FAILURE = "SAVE_ORDER_RESPONSES_REVIEW_FAILURE",
  SAVE_ORDER_RESPONSES_REVIEW_REQUEST = "SAVE_ORDER_RESPONSES_REVIEW_REQUEST",
  SAVE_ORDER_RESPONSES_REVIEW_SUCCESS = "SAVE_ORDER_RESPONSES_REVIEW_SUCCESS",
  SET_ANSWER_RANK_FILTER = "TEST_RESULTS_SET_ANSWER_RANK_FILTER",
  TAG_ANSWERS_FAILURE = "TEST_RESULTS_TAG_ANSWERS_FAILURE",
  TAG_ANSWERS_REQUEST = "TEST_RESULTS_TAG_ANSWERS_REQUEST",
  TAG_ANSWERS_SUCCESS = "TEST_RESULTS_TAG_ANSWERS_SUCCESS",
  UNTAG_ANSWERS_FAILURE = "TEST_RESULTS_UNTAG_ANSWERS_FAILURE",
  UNTAG_ANSWERS_REQUEST = "TEST_RESULTS_UNTAG_ANSWERS_REQUEST",
  UNTAG_ANSWERS_SUCCESS = "TEST_RESULTS_UNTAG_ANSWERS_SUCCESS",
  UPDATE_FIGMA_SECTION_FAILURE = "UPDATE_FIGMA_SECTION_FAILURE",
  UPDATE_FIGMA_SECTION_REQUEST = "UPDATE_FIGMA_SECTION_REQUEST",
  UPDATE_FIGMA_SECTION_SUCCESS = "UPDATE_FIGMA_SECTION_SUCCESS",
  UPDATE_ORDER_RESPONSES = "UPDATE_ORDER_RESPONSES",
  UPDATE_QUESTION_TAG_FAILURE = "TEST_RESULTS_UPDATE_QUESTION_TAG_FAILURE",
  UPDATE_QUESTION_TAG_REQUEST = "TEST_RESULTS_UPDATE_QUESTION_TAG_REQUEST",
  UPDATE_QUESTION_TAG_SUCCESS = "TEST_RESULTS_UPDATE_QUESTION_TAG_SUCCESS",
}
