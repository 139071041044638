import { Box, InputProps } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import React, { forwardRef } from "react"

type SelectableButtonProps = InputProps & {
  id: string
  isSelected?: boolean
  multiple?: boolean
}

export const SelectableButton = forwardRef<
  HTMLInputElement,
  SelectableButtonProps
>(({ id, isSelected = false, multiple = false, children, ...props }, ref) => (
  <Button as="label" pos="relative" isSelected={isSelected} cursor="pointer">
    <Box
      as="input"
      ref={ref}
      type={multiple ? "checkbox" : "radio"}
      value={id}
      pos="absolute"
      inset={0}
      opacity={0}
      pointerEvents="none"
      {...props}
    />
    {children}
  </Button>
))

SelectableButton.displayName = "SelectableButton"
