import { Button, Flex, Spacer, Tag, Text } from "@chakra-ui/react"
import { SubscribeToProButton } from "Components/trial-widget/SubscribeToProButton"
import { CornerLabel } from "UsabilityHub/components/UpgradeUsabilityTestBanner/CornerLabel"
import {
  useCurrentAccount,
  useCurrentUser,
} from "UsabilityHub/hooks/useCurrentAccount"
import {
  useHasUpcomingSubscriptionDuringATrial,
  useInNoPlanState,
  useIsTrialing,
  useShowProTrialWidget,
} from "Utilities/account"
import { centsToDollars, formatDollars } from "Utilities/currency"
import { isFree, isPro } from "Utilities/plan"
import React, { PropsWithChildren } from "react"
import { Plan } from "~/api/generated/usabilityhubSchemas"
import {
  DisabledPlanButton,
  ManualSubscription,
  NonAdmin,
} from "./disabled-plan-button"
import { usePlanChangerContext } from "./plan-changer-context"

const PLAN_DESCRIPTIONS: Record<string, string> = {
  free: "Initiate product research in your business — for free.",
  basic_v3: "Boost your research capabilities to build better experiences.",
  pro_v3: "Scale research across your team with advanced features.",
}

const planButtonAction = (
  isTrial: boolean,
  isInNoPlanState: boolean,
  isDowngrade: boolean,
  currentPlanDiscontinued: boolean,
  isFreePlan: boolean,
  hasUpcomingSubscriptionDuringATrial: boolean
) => {
  if (isTrial && !hasUpcomingSubscriptionDuringATrial) {
    if (isFreePlan) {
      return "Switch"
    } else {
      return "Subscribe"
    }
  } else if (isInNoPlanState) {
    if (isFreePlan) {
      return "Switch"
    } else {
      return "Subscribe"
    }
  } else if (currentPlanDiscontinued && !isFreePlan) {
    return "Change"
  } else if (isDowngrade) {
    return "Downgrade"
  } else {
    return "Upgrade"
  }
}

export const PlanHeader: React.FC<{
  plan: Plan
  position: "first" | "middle" | "last"
  currentPlanDiscontinued: boolean
  isActive: boolean
  isDowngrade: boolean
}> = ({ plan, position, currentPlanDiscontinued, isActive, isDowngrade }) => {
  const { dispatch } = usePlanChangerContext()
  const isTrial = useIsTrialing()
  const hasUpcomingSubscriptionDuringATrial =
    useHasUpcomingSubscriptionDuringATrial()

  const currentUser = useCurrentUser()
  const isAdmin = currentUser.role === "admin"
  const currentAccount = useCurrentAccount()
  const isManual = currentAccount?.manual_subscription
  const disabledVariant = isManual
    ? ManualSubscription
    : isAdmin
      ? null
      : NonAdmin

  const isFreePlan = isFree(plan)
  const isInNoPlanState = useInNoPlanState()

  const buttonAction = planButtonAction(
    isTrial,
    isInNoPlanState,
    isDowngrade,
    currentPlanDiscontinued,
    isFreePlan,
    hasUpcomingSubscriptionDuringATrial
  )
  const buttonText = `${buttonAction} to ${plan.name}`

  return (
    <PlanHeaderContainer plan={plan} position={position}>
      {isActive && (!isTrial || hasUpcomingSubscriptionDuringATrial) ? (
        <Button isDisabled fontSize="sm" colorScheme="gray">
          Your current plan
        </Button>
      ) : isPro(plan) &&
        (isTrial || isInNoPlanState) &&
        !hasUpcomingSubscriptionDuringATrial ? (
        <SubscribeToProButton
          width="full"
          source="no_plan_dashboard"
          buttonText={isTrial ? "Subscribe to Pro" : "Continue on Pro"}
          plan={plan}
          withPlanDrawerTrigger={false}
          onClick={() =>
            dispatch({ type: "choose-plan", planUniqueId: plan.unique_id })
          }
        />
      ) : disabledVariant === null ? (
        <Button
          data-qa={`plan-header-${plan.name}`}
          colorScheme="brand.primary"
          variant="solid"
          fontSize="sm"
          onClick={() =>
            dispatch({ type: "choose-plan", planUniqueId: plan.unique_id })
          }
        >
          {buttonText}
        </Button>
      ) : (
        <DisabledPlanButton variant={disabledVariant} buttonText={buttonText} />
      )}
    </PlanHeaderContainer>
  )
}

export const PlanHeaderContent: React.FC<{
  plan: Plan
}> = ({ plan }) => {
  const currentUser = useCurrentUser()
  const isAdmin = currentUser.role === "admin"
  const showProTrialWidget = isAdmin && useShowProTrialWidget()

  return (
    <>
      <Flex direction={"row"}>
        <Text color="text.primary" fontSize="md" fontWeight="bold">
          {plan.name}
        </Text>
        <Spacer />
        {showProTrialWidget && isPro(plan) && (
          <Tag
            size="sm"
            color="purple.700"
            backgroundColor="purple.100"
            borderRadius="full"
          >
            You{"\u2019"}re on a free trial
          </Tag>
        )}
      </Flex>

      <Flex gap={1}>
        <Text color="text.primary" fontSize="2xl" fontWeight="bold">
          {formatDollars(centsToDollars(plan.monthly_price))}
        </Text>

        {plan.monthly_price > 0 && (
          <Text color="text.secondary" fontSize="xs" fontWeight="normal">
            per month <br />
            billed {plan.interval === "year" ? "yearly" : "monthly"}
          </Text>
        )}
      </Flex>

      <Text color="text.secondary" fontSize="sm" fontWeight="normal" mb="auto">
        {PLAN_DESCRIPTIONS[plan.unique_id] ??
          (plan.alternate_plan_unique_id &&
            PLAN_DESCRIPTIONS[plan.alternate_plan_unique_id])}
      </Text>
    </>
  )
}

type PlanHeaderContainerProps = {
  position: "first" | "middle" | "last"
  plan: Plan
}

export const PlanHeaderContainer: React.FC<
  PropsWithChildren<PlanHeaderContainerProps>
> = ({ position, plan, children }) => {
  const currentUser = useCurrentUser()
  const isAdmin = currentUser.role === "admin"
  const showProTrialWidget = isAdmin && useShowProTrialWidget()

  return (
    <Flex
      pos="relative"
      direction="column"
      borderColor="gray.200"
      borderWidth={`1px ${position === "last" ? 1 : 0}px 0 1px`}
      roundedTopLeft={position === "first" ? "md" : undefined}
      roundedTopRight={position === "last" ? "md" : undefined}
      key={plan.unique_id}
      gap={2}
      p={3}
      overflow="hidden"
    >
      {isPro(plan) && !showProTrialWidget && <CornerLabel>Popular</CornerLabel>}

      <PlanHeaderContent plan={plan} />

      {children}
    </Flex>
  )
}
