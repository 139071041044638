import { entityIdOrNull } from "Components/comment-thread/entities"
import { useTestBuilderCommentCache } from "Hooks/useTestBuilderCommentCache"
import { useTestBuilderLocalComments } from "Hooks/useTestBuilderLocalComments"
import {
  getComments,
  getId,
  getUniqueId,
} from "Redux/reducers/test-builder/selectors/test-form"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { sortBy } from "lodash"
import React, { PropsWithChildren } from "react"
import { useSelector } from "react-redux"
import { useGetTestBuilder } from "~/api/generated/usabilityhub-components"
import { Comment } from "~/api/generated/usabilityhubSchemas"
import { CommentProvider } from "./CommentProvider"

export const TestBuilderCommentProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { addComment, updateComment, deleteComment, markCommentsAsRead } =
    useTestBuilderCommentCache()
  const {
    addComment: addCommentLocally,
    updateComment: updateCommentLocally,
    deleteComment: deleteCommentLocally,
  } = useTestBuilderLocalComments()
  const currentUser = useCurrentUser()
  const usabilityTestId = useSelector(getId)
  const usabilityTestUniqueId = useSelector(getUniqueId)

  const { data } = useGetTestBuilder(
    {
      pathParams: {
        usabilityTestUniqueId: usabilityTestUniqueId ?? "new",
      },
    },
    // If the user leaves their computer for some time and misses websocket messages,
    // the list can get out of date.  We'll check in with the server after fifteen minutes.
    {
      staleTime: 15 * 60 * 1000, // 15 minutes
      refetchOnWindowFocus: true,
    }
  )

  const localCommentFragments = useSelector(getComments)
  const localComments: Comment[] = localCommentFragments.map((frag) => {
    return {
      id: frag.comment_id,
      user: {
        id: String(currentUser.id),
        name: currentUser.name,
        avatar_url: currentUser.avatar_url,
      },
      entity_type: frag.entity.entityType,
      entity_id: entityIdOrNull(frag.entity),
      is_read: true,
      content: frag.content,
      created_at: new Date().toISOString(), // Actual created_at won't get set until the test is saved
      last_edited_at: null,
    }
  })

  const allComments = localComments.concat(data?.comments ?? [])
  const sortedComments = sortBy(allComments, (c) => c.created_at)

  return (
    <CommentProvider
      activeContext="test_builder"
      usabilityTestId={usabilityTestId}
      usabilityTestUniqueId={usabilityTestUniqueId}
      addComment={addComment}
      updateComment={updateComment}
      deleteComment={deleteComment}
      markCommentsAsRead={markCommentsAsRead}
      addCommentLocally={addCommentLocally}
      updateCommentLocally={updateCommentLocally}
      deleteCommentLocally={deleteCommentLocally}
      comments={sortedComments}
    >
      {children}
    </CommentProvider>
  )
}
