import { wrap } from "Math/number"

type CycleThroughRecordsProps<T, K extends keyof T> = {
  list: Array<T>
  idFieldName: K
  currentId: T[K]
}

// biome-ignore format: ts-ignore
// @ts-ignore
const cycleThroughRecords = <T, K extends keyof T>({
  list,
  idFieldName,
  currentId,
}: CycleThroughRecordsProps<T, K>) => {
  const currentIdx = list.findIndex((a) => a[idFieldName] === currentId)
  const currentRecord = list[currentIdx]
  const nextRecord = list[wrap(currentIdx + 1, list.length)]
  const nextId = nextRecord[idFieldName]
  const prevRecord = list[wrap(currentIdx - 1, list.length)]
  const prevId = prevRecord[idFieldName]

  return {
    currentRecord,
    nextRecord,
    nextId,
    prevRecord,
    prevId,
  }
}

type CycleThroughArrayProps<T> = {
  list: Array<T>
  currentId: T
}

export const cycleThroughArray = <T>({
  list,
  currentId,
}: CycleThroughArrayProps<T>) => {
  let currentIdx = list.findIndex((a) => a === currentId)
  if (currentIdx === -1) {
    currentIdx = 0
  }
  const nextId = list[wrap(currentIdx + 1, list.length)]
  const prevId = list[wrap(currentIdx - 1, list.length)]

  return {
    nextId,
    prevId,
  }
}
