// JsFromRoutes CacheKey c237b9a6b73ac5fe4418c11d070f86bf
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  responses: /* #__PURE__ */ definePathHelper('get', '/admin/third_party_orders/:id/responses'),
  markAsTestResponse: /* #__PURE__ */ definePathHelper('post', '/admin/third_party_orders/:id/mark_as_test_response'),
  exportResponseStatuses: /* #__PURE__ */ definePathHelper('get', '/admin/third_party_orders/:id/export_response_statuses'),
  cancel: /* #__PURE__ */ definePathHelper('put', '/admin/third_party_orders/:id/cancel'),
  reviewResponses: /* #__PURE__ */ definePathHelper('get', '/admin/third_party_orders/:id/review_responses'),
  saveReviewedResponses: /* #__PURE__ */ definePathHelper('post', '/admin/third_party_orders/:id/save_reviewed_responses'),
  exportRejectedResponses: /* #__PURE__ */ definePathHelper('get', '/admin/third_party_orders/:id/export_rejected_responses'),
  markRejectedResponsesAsReported: /* #__PURE__ */ definePathHelper('post', '/admin/third_party_orders/:id/mark_rejected_responses_as_reported'),
  reassignOrder: /* #__PURE__ */ definePathHelper('patch', '/admin/third_party_orders/:id/reassign_order'),
  list: /* #__PURE__ */ definePathHelper('get', '/admin/third_party_orders'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/third_party_orders/:id/edit'),
  get: /* #__PURE__ */ definePathHelper('get', '/admin/third_party_orders/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/third_party_orders/:id'),
}
