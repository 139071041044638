import { Select, SelectProps } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { WrappedFieldProps } from "redux-form"

/**
 * This component wraps the Chakra Select, inserts redux-form
 * input props and adds browser-based validity checks, you can
 * pass a custom error message if you want the browser-based one
 * to be different to the redux-form one
 */

type WrappedSelect = React.FC<
  React.PropsWithChildren<
    {
      errorMessage?: string
    } & WrappedFieldProps &
      SelectProps
  >
>

export const WrappedSelect: WrappedSelect = ({
  input,
  meta,
  children,
  errorMessage = meta.error,
  ...props
}) => {
  const selectRef = useRef<HTMLSelectElement>(null)
  const showError = meta.touched && !!meta.error
  useEffect(() => {
    selectRef.current!.setCustomValidity(showError ? errorMessage : "")
  }, [errorMessage, showError])
  return (
    <Select ref={selectRef} {...input} isInvalid={showError} {...props}>
      {children}
    </Select>
  )
}
