import React from "react"

import { ParticipantResponse } from "Types"
import {
  AppearanceProps,
  LayoutState,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"

import { PanelThankYou } from "./panel-thank-you"

export default function panelThankYouContent(
  response: ParticipantResponse
): AppearanceProps {
  return {
    layoutState: LayoutState.FocusQuestion,
    mediaContent: null,
    questionContent: <PanelThankYou creditsEarned={response._creditsEarned} />,
    isReportButtonVisible: false,
  }
}
