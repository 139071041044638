import { useCallback, useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import { createCardSortFilter } from "../filters/create-filters/card-sort-filter"
import { getFilterUtilityFunctions } from "../filters/get-filter-utility-functions"

const { isFilter, isFiltered, addFilter, removeFilter, toggleFilter } =
  getFilterUtilityFunctions(createCardSortFilter)

export function useCardSortFilter(
  sectionId: number,
  cardId: number,
  categoryIds: number[]
) {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handleAddCardSortFilter = () => {
    setTestResultsFilters((filters) =>
      addFilter(filters, sectionId, cardId, categoryIds)
    )
  }

  const handleRemoveCardSortFilter = () => {
    setTestResultsFilters((filters) =>
      removeFilter(filters, sectionId, cardId, categoryIds)
    )
  }

  const handleToggleFilter = useCallback(() => {
    setTestResultsFilters((filters) =>
      toggleFilter(filters, sectionId, cardId, categoryIds)
    )
  }, [])

  return {
    isFiltered: isFiltered(testResultsFilters, sectionId, cardId, categoryIds),
    isFilter: isFilter(testResultsFilters, sectionId, cardId, categoryIds),
    addCardSortFilter: handleAddCardSortFilter,
    removeCardSortFilter: handleRemoveCardSortFilter,
    toggleCardSortFilter: handleToggleFilter,
  }
}
