import { Box, Flex, Icon, Image } from "@chakra-ui/react"
import React from "react"
import { useDispatch } from "react-redux"
import { Field, WrappedFieldProps } from "redux-form"

import { getSectionTypeRules } from "Constants/test-section-types"
import { Dispatch } from "Redux/app-store"
import { removeCardImage } from "Redux/reducers/test-builder-form/action-creators/card-sort"
import { ScreenshotMediaType } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useSectionContext,
  useSectionIndexContext,
  useSectionTypeContext,
} from "UsabilityHub/contexts"
import { useItemIndexContext } from "UsabilityHub/contexts/ItemIndexContext"

import { FormFieldError } from "../../FormFieldError"

import { Trash01SolidIcon } from "Shared/icons/untitled-ui/Trash01SolidIcon"
import { CardImageDropZone, CardImageProcessing } from "./card-image-drop-zone"

interface CardImageProps {
  allowedMediaTypes: ScreenshotMediaType[]
}

const WrappedCardImage: React.FC<CardImageProps & WrappedFieldProps> = ({
  allowedMediaTypes,
  meta,
}) => {
  const dispatch = useDispatch<Dispatch>()
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const sectionType = useSectionTypeContext()
  const cardIndex = useItemIndexContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const sectionMediaCount = section.section_screenshots.length
  const cardAttributes =
    section.card_sort_attributes?.card_sort_cards_attributes[cardIndex]
  const maxFileCount = getSectionTypeRules(sectionType).maxScreenshots
  const remainingFileCount = maxFileCount - sectionMediaCount

  return (
    <Box boxSize="88px" pos="relative" data-group>
      {cardAttributes?.is_processing ? (
        <CardImageProcessing />
      ) : cardAttributes?.uploaded_image_url ? (
        <>
          <Image
            w="100%"
            h="100%"
            objectFit="cover"
            src={cardAttributes.uploaded_image_url}
            border="1px solid"
            borderColor="gray.200"
            rounded="lg"
          />
          {!readOnly && (
            <Flex
              position="absolute"
              justify="center"
              align="center"
              inset="0 0 0 0"
              border="1px solid"
              borderColor="red.500"
              bg="#f8b4b480" // red.300 at 50% opacity
              rounded="lg"
              opacity={0}
              transition="opacity 0.1s ease-in-out"
              cursor="pointer"
              _groupHover={{ opacity: 1 }}
              onClick={() => dispatch(removeCardImage(sectionIndex, cardIndex))}
            >
              <Icon as={Trash01SolidIcon} boxSize="20px" color="red.700" />
            </Flex>
          )}
        </>
      ) : (
        <CardImageDropZone
          id={`card_sort_card_image_${sectionIndex}[${cardIndex}]`}
          allowedMediaTypes={allowedMediaTypes}
          maxFiles={remainingFileCount}
        />
      )}
      <FormFieldError {...meta} />
    </Box>
  )
}

export const CardImage: React.FC<CardImageProps> = (props) => {
  const sectionIndex = useSectionIndexContext()
  const cardIndex = useItemIndexContext()
  return (
    <Field
      name={`sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes[${cardIndex}].image_data`}
      component={WrappedCardImage}
      props={props}
    />
  )
}
