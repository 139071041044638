import { DocumentPictureInPictureProvider } from "UsabilityHub/components/DocumentPictureInPicture/DocumentPictureInPictureProvider"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"
import {
  isTaskComplete,
  isTaskStarted,
  sectionStartTime,
} from "Utilities/response"
import React, { useState } from "react"
import { LiveWebsiteTestTaskActive } from "./LiveWebsiteTestTaskActive"

export const LiveWebsiteTestTask: React.FC<
  React.PropsWithChildren<InnerProps>
> = ({ responseSection, updateResponseSection, usabilityTestSection }) => {
  const [clickStartTime, setClickStartTime] = useState<number | null>(null)

  const isStarted = isTaskStarted(responseSection)
  const isComplete = isTaskComplete(responseSection)

  const startTask = () => {
    const startTime = performance.now()

    setClickStartTime(startTime)

    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms: startTime - sectionStartTime(responseSection),
    })
  }

  const finishTask = () => {
    const finishTime = performance.now()

    updateResponseSection(usabilityTestSection.id, {
      task_duration_ms: finishTime - (clickStartTime ?? 0),
    })
  }

  if (!isComplete) {
    return (
      <DocumentPictureInPictureProvider>
        <LiveWebsiteTestTaskActive
          usabilityTestSection={usabilityTestSection}
          isStarted={isStarted}
          handleStart={startTask}
          handleFinish={finishTask}
        />
      </DocumentPictureInPictureProvider>
    )
  }

  // On task completion it will automatically move to the next section
  return null
}
