import { PlanDrawerFeatures } from "Types"
import { Account, RecruitmentLink } from "~/api/generated/usabilityhubSchemas"

export const UNLIMITED_VISIBLE_RESPONSES_FEATURE = {
  feature: "unlimited_visible_responses",
} as const

export const enabledLinkableFeaturesAccountLacks = (
  account: Account,
  recruitmentLink: RecruitmentLink
): PlanDrawerFeatures => {
  const features: PlanDrawerFeatures = []
  if (
    !account.has_recruitment_link_limit_feature &&
    recruitmentLink.enable_response_limit
  ) {
    features.push({
      feature: "recruitment_link_limit",
    })
  }
  if (
    !account.has_demographic_surveys_feature &&
    recruitmentLink.enable_demographics
  ) {
    features.push({
      feature: "demographic_surveys",
    })
  }
  if (!account.has_redirects_feature && recruitmentLink.enable_redirect) {
    features.push({
      feature: "redirects",
    })
  }
  if (
    !account.has_custom_welcome_and_thankyou_messages_feature &&
    recruitmentLink.enable_custom_thank_you
  ) {
    features.push({
      feature: "custom_welcome_and_thankyou_messages",
    })
  }
  return features
}
