import { Alert, AlertDescription, AlertIcon, Button } from "@chakra-ui/react"
import React, { Component, ErrorInfo, ReactNode } from "react"
import { connect } from "react-redux"

import { reportTestFormError } from "Redux/reducers/test-builder/action-creators/report"

interface Props {
  readonly onDeleteSection: (index: number) => void
  readonly sectionIndex: number
  readonly reportErrorToSentry: (
    error: Error,
    extra: Record<string, unknown>
  ) => void
  readonly children: ReactNode
}

interface State {
  readonly error: Error | null
}

class TestFormSectionErrorBoundImpl extends Component<Props, State> {
  readonly state = { error: null }
  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { reportErrorToSentry, ...rest } = this.props
    reportErrorToSentry(error, { errorInfo, ...rest })
  }

  private handleDeleteSection = () => {
    this.props.onDeleteSection(this.props.sectionIndex)
  }

  render() {
    if (this.state.error === null) {
      return this.props.children
    }

    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>
          <p className="para">
            Sorry, this section has encountered an error. The error has been
            reported to the development team, who are working to fix it.
          </p>

          <p className="para">
            Try saving the test and reloading the page. You can also delete this
            section.
          </p>

          <Button colorScheme="red" onClick={this.handleDeleteSection}>
            Remove this section
          </Button>
        </AlertDescription>
      </Alert>
    )
  }
}

export const TestFormSectionErrorBound = connect(null, {
  reportErrorToSentry: reportTestFormError,
})(TestFormSectionErrorBoundImpl)
