import {
  Box,
  Button,
  Heading,
  Stack,
  StackProps,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useRefreshCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { useSwitchAccountToFreePlan } from "~/api/generated/usabilityhub-components"
import { usePlanChangerContextOnScreen } from "./plan-changer-context"

const ConfirmScreen = () => {
  const refreshCurrentAccount = useRefreshCurrentAccount()
  const { dispatch: planChangeDispatch } =
    usePlanChangerContextOnScreen("continue-on-free")
  const toast = useToast()
  const { mutate: switchAccountToFreePlan, isLoading } =
    useSwitchAccountToFreePlan({
      onSuccess: async () => {
        await refreshCurrentAccount()
        planChangeDispatch({
          type: "plan-changed",
          title: "You are now on the Free plan.",
          message: "You can change plan at any time.",
          changeToFree: true,
        })
      },
      onError: () => {
        toast({
          title: "Error",
          description:
            "Something went wrong. Please refresh the page and try again.",
          status: "error",
        })
      },
    })

  const handleClick = () => {
    switchAccountToFreePlan({})
  }

  return (
    <>
      <Heading fontSize="xl" fontWeight="semibold" mb={4}>
        Switch to the Free plan
      </Heading>

      <Text mb={4}>
        The Free plan is free forever, and you will not be billed. However, you
        will miss out on paid features.
      </Text>

      <Button
        type="submit"
        colorScheme="brand.primary"
        onClick={handleClick}
        isLoading={isLoading}
        loadingText="Switching to Free"
        mb={2}
      >
        Switch to Free
      </Button>

      <Box textAlign="center">
        <Text fontSize="sm">
          If you change your mind, you can choose to upgrade to a paid plan at
          any time.
        </Text>
      </Box>
    </>
  )
}

export const ContinueOnFree: React.FC<StackProps> = ({ ...props }) => {
  return (
    <Stack background="gray.50" color="text.primary" {...props}>
      <ConfirmScreen />
    </Stack>
  )
}
