import { Features } from "Types"
import { Plan } from "~/api/generated/usabilityhubSchemas"

export const FEATURES: Array<{
  group: string
  label: string
  comparedLabel?: (oldPlan: Plan, newPlan: Plan) => string
  tooltip: string
  value: (plan: Plan) => string | boolean
  feature?: Features[number]["feature"]
}> = [
  {
    group: "Collaboration",
    label: "Included seats",
    comparedLabel: (oldPlan, newPlan) => {
      const oldSeats = oldPlan.included_collaborator_seats ?? 0
      const newSeats = newPlan.included_collaborator_seats ?? 0
      return `${oldSeats - newSeats} additional seats`
    },
    tooltip: "People that can create and edit tests on your account.",
    value: (plan) => `${plan.included_collaborator_seats ?? 0} seats`,
  },
  {
    group: "Collaboration",
    label: "Viewer seats",
    tooltip: "Ability to add unlimited viewer seats.",
    value: (plan) => plan.viewer_permissions,
    feature: "viewer_permissions",
  },
  {
    group: "Collaboration",
    label: "Team management",
    tooltip: "Ability to set role based permissions per seat.",
    value: (plan) => plan.team_permissions,
    feature: "team_permissions",
  },
  {
    group: "Usage",
    label: "Test duration",
    tooltip:
      "The number of sections and questions you can add to each test. We calculate test durations for you as you build your test.",
    value: (plan) =>
      plan.max_test_duration ? `${plan.max_test_duration} mins` : "Unlimited",
    comparedLabel: (oldPlan, newPlan) => {
      if (
        oldPlan.max_test_duration === null &&
        newPlan.max_test_duration !== null
      ) {
        return "Unlimited test duration"
      } else {
        return `${oldPlan.max_test_duration ?? 0} minute test duration`
      }
    },

    feature: "max_test_duration",
  },
  {
    group: "Usage",
    label: "Self-recruited test responses",
    tooltip:
      "The maximum number of test and survey responses viewable from your network using a recruitment link",
    value: (plan) => (plan.unlimited_visible_responses ? "Unlimited" : "15"),
    feature: "unlimited_visible_responses",
  },
  {
    group: "Usage",
    label: "Self-recruited interview sessions",
    tooltip:
      "The number of interview sessions per month that you can recruit from your own network using a recruitment link",
    value: (plan) =>
      `${plan.self_recruited_interview_sessions_per_month} per month`,
    feature: "self_recruited_interview_sessions_per_month",
  },
  {
    group: "Usage",
    label: "Lyssna panel recruitment for tests and interviews",
    tooltip:
      "Access our global panel of 690k+ participants for tests and interview studies. Additional fees apply when recruiting from our panel.",
    value: () => "Unlimited",
  },
  {
    group: "Tests and surveys",
    label: "Screeners",
    tooltip:
      "Apply screeners to find the right participants for your tests. Screening Lyssna panelists incurs an additional cost of 2 credits per response.",
    value: () => true,
  },
  {
    group: "Tests and surveys",
    label: "Recordings",
    tooltip:
      "Enable screen, audio and camera recordings of participants taking your test.",
    value: (plan) => plan.recordings,
    feature: "recordings",
  },
  {
    group: "Tests and surveys",
    label: "Live website testing",
    tooltip:
      "Conduct recorded, unmoderated tests of live website sessions. Capture screen, camera, and audio of participants.",
    value: () => "Coming soon",
    feature: "recordings",
  },
  {
    group: "Tests and surveys",
    label: "CSV export",
    tooltip: "Export results in CSV format for external analysis.",
    value: (plan) => plan.csv_exports,
    feature: "csv_exports",
  },
  {
    group: "Tests and surveys",
    label: "Variation sets",
    tooltip: "Run the same test on different versions of a design.",
    value: (plan) => plan.test_sets,
    feature: "test_sets",
  },
  {
    group: "Tests and surveys",
    label: "Conditional logic",
    tooltip:
      "Hide or show test sections and questions based on how participants answer.",
    value: (plan) => plan.test_logic,
    feature: "test_logic",
  },
  {
    group: "Tests and surveys",
    label: "Hidden fields",
    tooltip:
      "Capture additional participant information by passing them in as hidden fields via URLs.",
    value: (plan) => plan.query_parameters,
    feature: "query_parameters",
  },
  {
    group: "Tests and surveys",
    label: "Demographic questions",
    tooltip:
      "Ask your own participants demographic questions before the test starts to filter results on.",
    value: (plan) => plan.demographic_surveys,
    feature: "demographic_surveys",
  },
  {
    group: "Tests and surveys",
    label: "Post tests redirect",
    tooltip:
      "Redirect your self recruited participants to a URL after they complete a test.",
    value: (plan) => plan.redirects,
    feature: "redirects",
  },
  {
    group: "Tests and surveys",
    label: "Limit responses",
    tooltip:
      "Automatically turn off recruitment links when you hit your desired participant quota.",
    value: (plan) => plan.recruitment_link_limit,
    feature: "recruitment_link_limit",
  },
  {
    group: "Tests and surveys",
    label: "Branding",
    tooltip: "Upload your logo and colors so your tests match your brand.",
    value: (plan) =>
      plan.white_labeled_tests
        ? "Custom"
        : plan.unbranded_tests
          ? "No branding"
          : "Lyssna",
    feature: "white_labeled_tests",
  },
  {
    group: "Interviews",
    label: "Screeners",
    tooltip:
      "Apply screeners to find the right participants for your interview studies.",
    value: () => true,
  },
  {
    group: "Interviews",
    label: "Video storage",
    tooltip: "Storage allowance for interview recordings",
    value: (plan) => `${plan.video_storage_gb}GB storage allowance`,
    feature: "video_storage_gb",
  },
  {
    group: "Interviews",
    label: "Transcription",
    tooltip: "Transcription hours that can be generated from your recordings",
    value: (plan) =>
      plan.unique_id === "free"
        ? `${plan.interview_transcription_hours} hours total`
        : `${plan.interview_transcription_hours} hours per month`,
    feature: "interview_transcription_hours",
  },
]
