import { Box } from "@chakra-ui/react"
import { UsabilityTestSection, UsabilityTestSectionType } from "Types"
import { SectionIndexProvider, SectionProvider } from "UsabilityHub/contexts"
import { CardSortSectionResultsCard } from "UsabilityHub/views/tests/$uniqueId/results/section-results/section-results-cards/CardSortSectionResultsCard"
import React from "react"
import { DesignSurveySectionResultsCard } from "./section-results/SectionResultsCards/DesignSurveySectionResultsCard"
import { FirstClickSectionResultsCard } from "./section-results/SectionResultsCards/FirstClickSectionResultsCard"
import { FiveSecondSectionResultsCard } from "./section-results/SectionResultsCards/FiveSecondSectionResultsCard"
import { InstructionSectionResultsCard } from "./section-results/SectionResultsCards/InstructionSectionResultsCard"
import { LiveWebsiteTestSectionResultsCard } from "./section-results/SectionResultsCards/LiveWebsiteTestSectionResultsCard"
import { NavigationSectionResultsCard } from "./section-results/SectionResultsCards/NavigationSectionResultsCard"
import { PreferenceSectionResultsCard } from "./section-results/SectionResultsCards/PreferenceSectionResultsCard"
import { PrototypeSectionResultsCard } from "./section-results/SectionResultsCards/PrototypeSectionResultsCard/PrototypeSectionResultsCard"
import { QuestionsSectionResultsCard } from "./section-results/SectionResultsCards/QuestionsSectionResultsCard"
import { TreeTestSectionResultsCard } from "./section-results/SectionResultsCards/TreeTestSectionResultsCard"
import { TestResultsSummary } from "./test-results-summary/TestResultsSummary"

const sectionResultsComponent = (type: UsabilityTestSectionType) => {
  switch (type) {
    case UsabilityTestSectionType.CardSort:
      return <CardSortSectionResultsCard />
    case UsabilityTestSectionType.DesignQuestions:
      return <DesignSurveySectionResultsCard />
    case UsabilityTestSectionType.FirstClickTest:
      return <FirstClickSectionResultsCard />
    case UsabilityTestSectionType.FiveSecondTest:
      return <FiveSecondSectionResultsCard />
    case UsabilityTestSectionType.Information:
      return <InstructionSectionResultsCard />
    case UsabilityTestSectionType.NavigationTest:
      return <NavigationSectionResultsCard />
    case UsabilityTestSectionType.PreferenceTest:
      return <PreferenceSectionResultsCard />
    case UsabilityTestSectionType.PrototypeTask:
      return <PrototypeSectionResultsCard />
    case UsabilityTestSectionType.Questions:
      return <QuestionsSectionResultsCard />
    case UsabilityTestSectionType.TreeTest:
      return <TreeTestSectionResultsCard />
    case UsabilityTestSectionType.LiveWebsiteTest:
      return <LiveWebsiteTestSectionResultsCard />
  }
}

interface TestResultsSectionsProps {
  sections: ReadonlyArray<Readonly<UsabilityTestSection>>
}

export const TestResultsSections: React.FC<
  React.PropsWithChildren<TestResultsSectionsProps>
> = ({ sections }) => {
  return (
    <Box
      // Filtering by clicks changes the length of the answer list and can cause
      // the scroll position to move drastically. Add a large margin to prevent it.
      mb={150}
    >
      <TestResultsSummary />

      {sections.map((section) => {
        const SectionResultsComponent = sectionResultsComponent(section.type)
        return (
          <SectionProvider key={section.id} value={{ section }}>
            <SectionIndexProvider value={section.position}>
              {SectionResultsComponent}
            </SectionIndexProvider>
          </SectionProvider>
        )
      })}
    </Box>
  )
}
