import { Box, Button, Flex, Stack, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useDispatch } from "react-redux"
import { Field } from "redux-form"

import { MAX_CATEGORIES } from "Constants/test-section-types"
import { Dispatch } from "Redux/app-store"
import { addCategory } from "Redux/reducers/test-builder-form/action-creators/card-sort"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"

import { DroppableQuestionRows } from "../SectionQuestions/DragDrop/DroppableQuestionRows"
import { WrappedCheckbox } from "../WrappedCheckbox"

import { CardSortSectionSingleCategory } from "./CardSortSectionSingleCategory"
import { WrappedSwitch } from "./WrappedSwitch"

export const CardSortSectionCategoryList: React.FC = () => {
  const dispatch: Dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const { section } = useSectionContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const categories =
    section?.card_sort_attributes?.card_sort_categories_attributes ?? []
  const nonDeletedCategories = categories.filter((c) => !c._destroy)
  const onAddCategory = () => {
    dispatch(addCategory(sectionIndex))
  }

  const onEnterPress = (optionIndex: number) => {
    if (optionIndex === categories.length - 1) {
      onAddCategory()
    } else {
      // Autofocus the first blank input box
      // `section_${sectionIndex}` is the id of `TestCard` component
      // Ref: ../SectionCard.tsx
      const input = document.querySelector(
        `#section_${sectionIndex} input[data-role="category-label"][value=""]:first-of-type`
      ) as HTMLElement
      input?.focus()
    }
  }

  return (
    <Stack spacing={6}>
      <Box>
        <Flex justify="space-between" align="center">
          <Text fontSize="sm">
            Categories (Press ⏎ for new line or paste a list. Max.{" "}
            {MAX_CATEGORIES})
          </Text>
          <Field
            name={`sections[${sectionIndex}].card_sort_attributes.show_category_descriptions`}
            component={WrappedSwitch}
            label="Descriptions"
            disabled={readOnly}
          />
        </Flex>
        <Box px={6}>
          <DroppableQuestionRows
            path={`sections[${sectionIndex}].card_sort_attributes.card_sort_categories_attributes`}
            droppableType="category"
          >
            {categories.map((category, index) =>
              category._destroy ? null : (
                <CardSortSectionSingleCategory
                  key={index}
                  categoryIndex={index}
                  skipAutoFocus={false}
                  onEnterPress={onEnterPress}
                />
              )
            )}
          </DroppableQuestionRows>
        </Box>
        <VStack align="stretch" spacing={6} ml={6} mt={4}>
          {nonDeletedCategories.length < MAX_CATEGORIES ? (
            <Button
              alignSelf="flex-start"
              size="sm"
              colorScheme="brand.primary"
              isDisabled={readOnly}
              onClick={onAddCategory}
            >
              Add another category
            </Button>
          ) : (
            <Text>Maximum categories reached ({MAX_CATEGORIES})</Text>
          )}
          <Field
            name={`sections[${sectionIndex}].card_sort_attributes.shuffle_categories`}
            label="Randomize the order of categories"
            component={WrappedCheckbox}
            disabled={readOnly}
          />
        </VStack>
      </Box>
    </Stack>
  )
}
