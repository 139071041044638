import React, {
  ChangeEvent,
  ChangeEventHandler,
  Component,
  InputHTMLAttributes,
  Ref,
} from "react"

import { Omit } from "Types"

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  inputRef?: Ref<HTMLInputElement>
  onChange: ChangeEventHandler<HTMLInputElement>
}

interface State {
  changeCount: number
}

/**
 * Component that redraws every time (canning its internal state). Useful for
 * file inputs that should be able to accept the same file repeatedly. If the
 * same input is recycled it will not trigger a change event when this
 * happens.
 *
 * @see http://stackoverflow.com/a/21750576
 * @see http://stackoverflow.com/a/1043969
 */
export default class AutoResetInput extends Component<Props, State> {
  state = { changeCount: 0 }

  private handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ changeCount: this.state.changeCount + 1 })
    this.props.onChange(event)
  }

  render() {
    const { inputRef, onChange, ...rest } = this.props
    return (
      <input
        ref={inputRef}
        key={this.state.changeCount}
        onChange={this.handleChange}
        {...rest}
      />
    )
  }
}
