export type Result<T> = { value: T } | { error: Error }

export function isSuccess<T>(result: Result<T>): result is { value: T } {
  return Object.prototype.hasOwnProperty.call(result, "value")
}

export function getResultValue<T>(result: Result<T>): T {
  if (isSuccess(result)) {
    return result.value
  } else {
    throw result.error
  }
}

export async function promiseToResult<T>(
  promise: Promise<T>
): Promise<Result<T>> {
  try {
    return { value: await promise }
  } catch (error) {
    return { error }
  }
}
