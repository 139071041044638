import { mean } from "lodash"

import { FigmaTaskScreenResult } from "Types"

export function getAverageMisclickRate(screenResults: FigmaTaskScreenResult[]) {
  const finiteMisclickRateArray = screenResults
    .map((screenResult) => screenResult.meta.misclickRate)
    .filter(Number.isFinite)

  return mean(finiteMisclickRateArray)
}
