import { Box, Flex, Image } from "@chakra-ui/react"
import React from "react"

import teamImage from "Images/app-illustrations/lyssna/team.png"
import { Card, CardBody, LegacyCardHeader } from "Shared/components/Card/Card"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { DividerWithText } from "UsabilityHub/components/DividerWithText/DividerWithText"
import { AcceptInvitationGoogleSignIn } from "UsabilityHub/components/GoogleSignIn/GoogleSignIn"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"

import { AcceptInvitationForm } from "./AcceptInvitationForm"
import { LinkExpiredCard } from "./LinkExpiredCard"

type AcceptInvitation = React.FC<{
  failure: boolean
  id: string
  authProvider: string | null
}>

export const AcceptInvitation: AcceptInvitation = ({
  failure,
  id,
  authProvider,
}) => {
  return (
    <Page title="Activate your account">
      <UsabilityHubNavbar />
      <PageMain>
        <PageContent maxW="8xl">
          <Flex wrap="wrap" align="center">
            <Box width={["100%", "100%", "50%", "40%"]}>
              {failure ? (
                <LinkExpiredCard id={id} />
              ) : (
                <Card>
                  <LegacyCardHeader size="lg">Join your team</LegacyCardHeader>
                  <CardBody>
                    <AcceptInvitationForm id={id} authProvider={authProvider} />
                    {!authProvider && (
                      <>
                        <DividerWithText text="OR" />
                        <AcceptInvitationGoogleSignIn
                          buttonVariant="continue_with"
                          requestParams={{ id }}
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              )}
            </Box>
            <Box width={["100%", "100%", "50%", "60%"]}>
              <Flex justify="center" mt={[6, 6, 0, 0]}>
                <Image src={teamImage} maxW="md" />
              </Flex>
            </Box>
          </Flex>
        </PageContent>
      </PageMain>
    </Page>
  )
}
