import { sum } from "lodash"
import { useEffect, useRef, useState } from "react"
import { captureUserMedias } from "../../useTestTakingRecordings"

type Props = {
  audioDeviceId?: string
  onAudioDeviceMissing?: () => void
  onSuccess?: () => void
  onFailure?: () => void
  volumeUpdateInterval?: number // unit is milliseconds
}

// @returns volumeDecimal -  a decimal value between 0 and 1
export const useAudioVolume = ({
  audioDeviceId,
  onAudioDeviceMissing,
  onSuccess,
  onFailure,
  volumeUpdateInterval = 100,
}: Props) => {
  const [audioContext, setAudioContext] = useState<AudioContext>()
  const sourceNodeRef = useRef<MediaStreamAudioSourceNode>()
  const volumeIntervalRef = useRef<NodeJS.Timer>()
  const [volumeDecimal, setVolumeDecimal] = useState<number>(0)
  const streamRef = useRef<MediaStream>()

  const stopStream = () =>
    streamRef.current &&
    streamRef.current.getTracks().forEach((track) => track.stop())

  useEffect(() => {
    const newAudioContext = new AudioContext()
    setAudioContext(newAudioContext)

    return () => {
      clearPreviousSource()
      newAudioContext.close()
    }
  }, [])

  const clearPreviousSource = () => {
    onFailure && onFailure()
    // Stop the previous stream
    stopStream()
    // Clear the previous interval
    if (volumeIntervalRef.current) clearInterval(volumeIntervalRef.current)
    // Disconnect the previous source node
    if (sourceNodeRef.current) sourceNodeRef.current.disconnect()
    setVolumeDecimal(0)
  }

  useEffect(() => {
    if (audioDeviceId && audioContext) {
      const constraints = {
        audio: {
          deviceId: { exact: audioDeviceId },
        },
      }

      const success = (stream: MediaStream) => {
        clearPreviousSource()
        streamRef.current = stream

        onSuccess && onSuccess()

        if (audioContext) {
          const audioSource = audioContext.createMediaStreamSource(stream)

          sourceNodeRef.current = audioSource

          const analyser = audioContext.createAnalyser()

          analyser.smoothingTimeConstant = 0.8
          analyser.fftSize = 1024

          audioSource.connect(analyser)
          const volumes = new Uint8Array(analyser.frequencyBinCount)
          const volumeCallback = () => {
            analyser.getByteFrequencyData(volumes)
            const volumeSum = sum(volumes)
            const averageVolume = volumeSum / volumes.length

            const volumeDecimal =
              averageVolume / (analyser.maxDecibels - analyser.minDecibels)

            setVolumeDecimal(volumeDecimal)
          }

          volumeIntervalRef.current = setInterval(
            volumeCallback,
            volumeUpdateInterval
          )
        }
      }

      captureUserMedias(constraints, success, clearPreviousSource)
    } else {
      onAudioDeviceMissing && onAudioDeviceMissing()
    }
  }, [audioDeviceId, audioContext])

  return volumeDecimal
}
