import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react"
import React from "react"

const navbarStyleConfig = {
  baseStyle: {
    px: {
      base: 4,
      md: 6,
      lg: 8,
    },
    py: 4,
    bg: "white",
    borderBottom: "1px solid",
    borderColor: "border.default",
  },
}

type Navbar = React.FC<React.PropsWithChildren<BoxProps>>

export const Navbar: Navbar = ({ sx = {}, ...props }) => {
  const styles = useStyleConfig("Navbar", { styleConfig: navbarStyleConfig })
  return <Box as="nav" sx={{ ...styles, ...sx }} {...props} />
}
