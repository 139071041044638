import {
  Box,
  Button,
  Checkbox,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  Text,
} from "@chakra-ui/react"
import { MenuList } from "@chakra-ui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { get } from "lodash"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { arrayPush, arrayRemove } from "redux-form"

import {
  getFormName,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"

interface Props {
  field: string
  options: string[]
  disabled: boolean
  placeholder: string
}

export const WrappedMultiSelect: React.FC<Props> = ({
  field,
  disabled,
  options,
  placeholder,
  ...rest
}) => {
  const dispatch = useDispatch()
  const formValues = useSelector(getFormValues)
  const currentValue = get(formValues, field, []) as string[]
  const relevantValues = currentValue.filter((v) => options.includes(v))

  const handleChange = (value: string, selected: boolean) => {
    if (selected) {
      dispatch(arrayPush(getFormName(), field, value))
    } else {
      const index = currentValue.indexOf(value)
      dispatch(arrayRemove(getFormName(), field, index))
    }
  }

  let display
  if (relevantValues.length === 0) {
    display = placeholder
  } else if (relevantValues.length === 1) {
    display = relevantValues[0]
  } else {
    const moreOptions = relevantValues.length - 1
    display = `${relevantValues[0]} + ${moreOptions} other${
      moreOptions === 1 ? "" : "s"
    }`
  }

  return (
    <Box>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          bg="white"
          borderWidth={1}
          rounded="md"
          disabled={disabled}
          pl={4}
          pr={2}
          minW="180px"
          rightIcon={<Icon as={ChevronDownIcon} />}
          fontWeight="normal"
          {...rest}
        >
          <Text
            maxW="350px"
            align="left"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {display}
          </Text>
        </MenuButton>
        <MenuList m={0}>
          {options.map((option) => {
            return (
              <MenuItem key={option} padding={0}>
                <Checkbox
                  variant="mdWithSmFont"
                  isChecked={relevantValues.includes(option)}
                  onChange={(e) => handleChange(option, e.target.checked)}
                  padding={3}
                  flexBasis="100%"
                >
                  {option}
                </Checkbox>
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
    </Box>
  )
}
