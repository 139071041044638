import { useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"

export const useClearAllFilters = () => {
  const { setTestResultsFilters } = useContext(TestResultsFilterState)

  return () => {
    setTestResultsFilters([])
  }
}
