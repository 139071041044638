import { create } from "heatmap.js"

import { ScaledCanvas } from "Utilities/canvas/scaled-canvas"
import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"

import { getHeatmapData } from "./get-heatmap-data"

export function drawHeatmap(canvas: ScaledCanvas, clicks: NormalizedClick[]) {
  const width = canvas.getScaledWidth()
  const height = canvas.getScaledHeight()

  const heatmapContainer = document.createElement("div")
  const radius = (30 * width) / 1000
  const heatmapData = getHeatmapData(clicks, { width, height }, radius)

  // Create heatmap
  const heatmap = create({
    radius,
    container: heatmapContainer,
  })

  heatmap._renderer.setDimensions(width, height)
  heatmap.setData(heatmapData)

  const heatmapCanvas = heatmapContainer.querySelector("canvas")!

  const ctx = canvas.get2DContext()
  ctx.drawImage(heatmapCanvas, 0, 0)
}
