import { ProTrialIcon } from "Components/trial-widget/ProTrialIcon"
import React from "react"
import { useSelector } from "react-redux"

import { getFormValue } from "Redux/reducers/test-builder-form/selectors/formValues"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useShowProTrialWidget } from "Utilities/account"

export const PaidFeatureIndicator: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const showProTrialWidget = useShowProTrialWidget()
  const isUpgraded = useSelector(getFormValue("upgraded")) as boolean
  const accountCanCustomizeMessages =
    useCurrentAccount().has_custom_welcome_and_thankyou_messages_feature
  const featureAvailable = isUpgraded || accountCanCustomizeMessages
  const isIconAttractive = !!(showProTrialWidget || !featureAvailable)

  return (
    <ProTrialIcon
      isAttractive={isIconAttractive}
      withTooltip={!featureAvailable}
    />
  )
}
