import { Tooltip, TooltipProps } from "@chakra-ui/react"
import React from "react"

import { ProCrownIcon } from "Shared/icons/ProCrownIcon"

type ProTrialIconProps = {
  iconSize?: string
  isAttractive: boolean // The icon should be attrative when the user is in a pro trial or doesn't have access to the pro feature
  attractiveColor?: string
  defaultColor?: string
  withTooltip?: boolean
} & Omit<TooltipProps, "children">

export const ProTrialIcon: React.FC<ProTrialIconProps> = ({
  iconSize = 2.5,
  isAttractive,
  attractiveColor = "purple.500",
  defaultColor = "gray.400",
  withTooltip = false,
  ...rest
}) => {
  if (withTooltip)
    return (
      <Tooltip
        placement="top"
        background="white"
        borderWidth={1}
        borderColor="gray.100"
        color="gray.700"
        boxShadow="lg"
        borderRadius="md"
        label={`This is a paid feature. If you enable it, you${"\u2019"}ll need to upgrade before you can recruit participants.`}
        {...rest}
      >
        <ProCrownIcon
          boxSize={iconSize}
          color={isAttractive ? attractiveColor : defaultColor}
          cursor="pointer"
        />
      </Tooltip>
    )

  return (
    <ProCrownIcon
      boxSize={iconSize}
      color={isAttractive ? attractiveColor : defaultColor}
    />
  )
}
