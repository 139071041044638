import React from "react"

import { ScreenshotWithId } from "Components/screenshot/screenshot-with-id"
import styles from "Components/test-results/section-screenshot-result/section-screenshot-result.module.scss"
import { DeviceFrame, UsabilityTestSectionScreenshot } from "Types"

interface Props {
  sectionScreenshot: UsabilityTestSectionScreenshot
  deviceFrame: DeviceFrame | null
}

const UsabilityTestSectionScreenshotResult: React.FC<
  React.PropsWithChildren<Props>
> = (props: Props) => {
  const { deviceFrame, sectionScreenshot } = props
  return (
    <ScreenshotWithId
      className={styles.screenshot}
      constrainWidthToDeviceFrame={deviceFrame}
      screenshotId={sectionScreenshot.screenshot_id}
      canSkipAheadOnFirstWatch
    />
  )
}

export default UsabilityTestSectionScreenshotResult
