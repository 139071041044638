import { useMutableQueryString } from "Shared/hooks/useMutableQueryString"
import { isIn } from "Utilities/values"

import { SORTABLE_FIELDS } from "./sorting"

// Gets sort and archival params out of the query string and validates them.
// Useful in conjunction with the `<SortMenu>` and `<ActiveMenu>` components which set these params.
export const useViewMenuParams = () => {
  const [searchParams] = useMutableQueryString()

  const sortParam = searchParams["sort"] ?? ""
  const sortBy = isIn(SORTABLE_FIELDS, sortParam) ? sortParam : "updated_at"
  const sortDirection = searchParams["sort_dir"] === "asc" ? "asc" : "desc"
  const showArchived = searchParams["show_archived"] === "true"

  return {
    sortBy,
    sortDirection,
    showArchived,
  } as const
}
