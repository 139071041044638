import { useMemo } from "react"
import { useSelector } from "react-redux"

import { getAllResponseSections } from "Redux/reducers/test-results/selectors"

import { useFilteredResponses } from "./use-filtered-responses"

export const useFilteredResponseSections = () => {
  const filteredResponses = useFilteredResponses()
  const responseSections = useSelector(getAllResponseSections)

  return useMemo(
    () =>
      responseSections.filter((responseSection) =>
        filteredResponses.some(
          (response) => response.id === responseSection.response_id
        )
      ),
    [filteredResponses, responseSections]
  )
}
