import { groupBy } from "lodash"

import { ClickSelection, UsabilityTest } from "Types"

import { getScreenshotSectionByScreenshotId } from "./screenshot-section"

export const getClientHitzoneByForSameScreenshotSection = (
  clientHitzoneId: string,
  test: UsabilityTest,
  clientHitzones: ClickSelection[]
) => {
  const groupedClientHitzoneIds = groupBy(clientHitzones, (clientHitzone) => {
    const screenshotSection = getScreenshotSectionByScreenshotId(
      clientHitzone.usability_test_section_screenshot_id,
      test
    )!

    return screenshotSection.id
  })

  for (const clientHitzoneGroup of Object.values(groupedClientHitzoneIds)) {
    const includesClientHitzoneId = clientHitzoneGroup.some(
      (clientHitzone) => clientHitzone._clientId === clientHitzoneId
    )
    if (includesClientHitzoneId)
      return clientHitzoneGroup.map((clientHitzone) => clientHitzone._clientId)
  }
}
