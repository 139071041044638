import { Filter } from "Components/test-results/types/filters"

import { getAttributes } from "../get-attribute"

import { DemographicAttributeOptionIdFilter } from "./attribute-exact-value"
import { FilterFunction } from "./types"

export type UnknownDemographicAttributeOptionFilter = Filter<
  DemographicAttributeOptionIdFilter["category"],
  "attribute/unknown-demographic-attribute-option",
  number[]
>

export type AttributeDemographicAttributeOptionUnknownFilter =
  UnknownDemographicAttributeOptionFilter

export const filterResponseByAttributeDemographicAttributeOptionUnknown: FilterFunction<
  AttributeDemographicAttributeOptionUnknownFilter
> = ({ response, filter }) => {
  const attributes = getAttributes(response, "demographic_attribute_option_id")

  return attributes.every((attribute) =>
    filter.value.every(
      (demographicAttributeOptionId) =>
        demographicAttributeOptionId !== attribute.value
    )
  )
}
