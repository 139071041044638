import {
  ModeratedStudyListEntry,
  Study,
} from "~/api/generated/usabilityhubSchemas"

export const convertModeratedStudyToStudy = (
  moderatedStudy: ModeratedStudyListEntry
): Extract<Study, { type: "moderated_study" }> => {
  return {
    ...moderatedStudy,
    type: "moderated_study",
    name: moderatedStudy.internal_name,
    archived: !!moderatedStudy.archived_at,
    unique_id: moderatedStudy.id,
    admin_disabled: false,
    status_tags: [],
    project: null,
    status: moderatedStudy.recruiting ? "Recruiting" : "Ready",
    booking_counts: {
      ...moderatedStudy.booking_counts,
      short_notice_canceled: 0,
    },
  }
}
