import { Box, Flex, Tag, TagLabel, TagLeftIcon, Text } from "@chakra-ui/react"
import { AutoUpdatingRemainingTime } from "Components/trial-widget/AutoUpdatingRemainingTime"
import { ProCrownIcon } from "Shared/icons/ProCrownIcon"
import { PlanUpgradeSource } from "Types"
import { useMaybeActiveStripeSubscription } from "UsabilityHub/hooks/useCurrentAccount"
import {
  useInNoPlanState,
  useIsTrialing,
  useShowProTrialWidget,
} from "Utilities/account"
import React from "react"
import { PlanDrawerTrigger } from "../PlanChanger/plan-drawer"

type FreeTrialPersistentCallout = React.FC<
  React.PropsWithChildren<{
    source: PlanUpgradeSource
    // Our dashboard nav is quite full already, so we show an alt
    // version on laptop width of badge only to fit the space
    isOnDashboard: boolean
  }>
>

export const FreeTrialPersistentCallout: FreeTrialPersistentCallout = ({
  source,
  isOnDashboard = false,
}) => {
  const isInTrialing = useIsTrialing()
  const isInNoPlanState = useInNoPlanState()
  const showProTrialWidget = useShowProTrialWidget()
  const activeStripeSubscription = useMaybeActiveStripeSubscription()

  if (!(showProTrialWidget || isInNoPlanState)) return null

  return (
    <Box data-qa="pro-trial-nav-widget">
      <PlanDrawerTrigger
        sx={{
          "@media (hover: hover)": {
            ":hover": {
              background: isInTrialing ? "purple.100" : "none",
              "& > span.proTag": {
                background: "purple.700",
                color: "var(--chakra-colors-white)",
              },
            },
            ":hover:disabled": {
              background: "none",
            },
          },
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        background="transparent"
        fontSize="sm"
        fontWeight="500"
        lineHeight="20px"
        minHeight="32px"
        padding="0px 8px 0px 4px"
        height="fit-content"
        gap="4px"
        borderRadius="16px"
        textColor="var(--color-text-subtle)"
        _focusVisible={{
          boxShadow: "var(--chakra-shadows-outline)",
          background: isInTrialing ? "purple.100" : "none",
        }}
        source={source}
        isDisabled={isInNoPlanState}
        _disabled={{
          cursor: "default",
        }}
      >
        {isInTrialing ? (
          <Flex gap="4px" alignItems="center">
            <Tag
              className="proTag"
              background="purple.100"
              color="purple.500"
              margin="4px 10px 4px 4px"
              padding="2px 10px 2px 8px"
              borderRadius="16px"
            >
              <TagLeftIcon
                as={ProCrownIcon}
                marginRight="4px"
                transform="translateY(-1px)"
              />
              <TagLabel>Pro</TagLabel>
            </Tag>
            <Text
              display={{ base: "none", xl: "inline-block" }}
              sx={{
                "@media (hover: hover)": {
                  ":hover": {
                    background: "purple.100",
                    "& > span.proTag": {
                      background: "purple.700",
                      color: "var(--chakra-colors-white)",
                    },
                  },
                },
              }}
            >
              Free trial ends in{" "}
            </Text>
            <Text
              display={{
                base: "none",
                sm: "inline-block",
                lg: isOnDashboard ? "none" : "inline-block",
                xl: "inline-block",
              }}
              fontWeight="700"
            >
              <AutoUpdatingRemainingTime
                date={activeStripeSubscription!.trial_end!}
                addSuffix={false}
              />
            </Text>
            <Text
              display={{
                base: "none",
                sm: "inline-block",
                lg: isOnDashboard ? "none" : "inline-block",
                xl: "none",
              }}
              fontWeight="700"
            >
              {" "}
              left
            </Text>
          </Flex>
        ) : isInNoPlanState ? (
          <Text
            display={{
              base: "none",
              sm: "inline-block",
              lg: isOnDashboard ? "none" : "inline-block",
              xl: "inline-block",
            }}
          >
            Your free trial has ended
          </Text>
        ) : null}
      </PlanDrawerTrigger>
    </Box>
  )
}
