import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Field, FieldProps, Form, Formik } from "formik"
import React from "react"
import { useDispatch } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { showErrorMessage } from "Redux/reducers/flash"
import { axios, isBadRequestError } from "Services/axios"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { ROUTES } from "UsabilityHub/views/routes"
import UsersApi from "~/api/usersApi"

type AcceptInvitationForm = React.FC<{
  id: string
  authProvider: string | null
}>

export const AcceptInvitationForm: AcceptInvitationForm = ({
  id,
  authProvider,
}) => {
  const needsPassword = authProvider === null
  const submitText = authProvider ? "Sign in with SAML" : "Get started"
  const dispatch: Dispatch = useDispatch()
  const initialValues = {
    user: { name: "", password: needsPassword ? "" : undefined },
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const { data } = await axios.put(
            UsersApi.createPassword.path({ id }),
            values
          )
          window.location.href = data.redirect_url
        } catch (error) {
          if (isBadRequestError(error)) {
            dispatch(showErrorMessage(error.response.data.message))
            setSubmitting(false)
            return
          } else {
            throw error
          }
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack spacing={6}>
            <Field name="user.name">
              {({ field, meta }: FieldProps) => (
                <FormControl isInvalid={!!meta.error} isRequired>
                  <FormLabel htmlFor="name">Full name</FormLabel>
                  <Input
                    {...field}
                    autoFocus
                    id="name"
                    isRequired
                    placeholder="Your name"
                    type="text"
                  />
                  {meta.error && (
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </Field>
            {needsPassword && (
              <Field name="user.password">
                {({ field, meta }: FieldProps) => (
                  <FormControl isInvalid={!!meta.error} isRequired>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <Input
                      {...field}
                      id="password"
                      isRequired
                      placeholder="Your password"
                      type="password"
                    />
                    {meta.error && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Field>
            )}
            <Text color="gray.400" fontSize="sm">
              By signing up, you agree to Lyssna{"\u2019"}s{" "}
              <ExternalLink href={ROUTES.LEGAL.TERMS.path}>
                Terms of Service
              </ExternalLink>{" "}
              and{" "}
              <ExternalLink href={ROUTES.LEGAL.PRIVACY.path}>
                Privacy Policy
              </ExternalLink>
              .
            </Text>
            <Button
              isLoading={isSubmitting}
              type="submit"
              colorScheme="brand.primary"
              width="full"
            >
              {submitText}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
