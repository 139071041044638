import { Dispatch } from "Redux/app-store"
import { sectionsWereAdded } from "Redux/reducers/test-builder-form/action-creators/sections"
import { newUsabilityTest } from "Redux/reducers/test-builder/initialize-state"
import { Screenshot } from "Types"
import { InitUsabilityTestAction } from "../action-types"
import { Type } from "../constants"
import { DefinedRawTestBuilderState, TestBuilderState } from "../reducer"

export const initializeUsabilityTest =
  (testBuilder: DefinedRawTestBuilderState, screenshots: Screenshot[]) =>
  (dispatch: Dispatch) => {
    const state: TestBuilderState = {
      ...newUsabilityTest(testBuilder, {
        screenshots,
      }),
      testSetId: testBuilder.testSetId,
    }

    dispatch({
      type: Type.INIT_USABILITY_TEST,
      payload: state,
    } as InitUsabilityTestAction)
    dispatch(sectionsWereAdded(state.initialValues.sections))
  }
