import { VariableFilter } from "../filters/variables"
import { generateFilterId } from "./generate-filter-id"

export function createVariableFilterCategory(
  variableName: string
): VariableFilter["category"] {
  return `response/attribute:variable/${variableName}`
}

export function createVariableFilter(
  variableName: string,
  value: string
): VariableFilter {
  return {
    id: generateFilterId(),
    category: createVariableFilterCategory(variableName),
    type: "attribute/variable",
    value,
  }
}
