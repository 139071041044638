import { Box, Spinner } from "@chakra-ui/react"
import React, { useEffect } from "react"

import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ThankYouMessageCopy } from "Types"
import styles from "UsabilityHub/components/UsabilityTest/content-factory/intro-outro.module.scss"
import CustomerLogo from "UsabilityHub/components/UsabilityTestLayout/CustomerLogo"
import {
  AppearanceProps,
  LayoutState,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"
import {
  Body,
  Container,
  Header,
  Title,
} from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout"
import { delay } from "Utilities/promise"

interface Props {
  copy: ThankYouMessageCopy
  logoUrl: string | null
  redirectLink: string | null
}

const CustomThankYouMessage: React.FC<React.PropsWithChildren<Props>> = ({
  redirectLink,
  copy,
  logoUrl,
}) => {
  const translate = useTranslate()

  useEffect(() => {
    const redirect = async () => {
      if (redirectLink) {
        await delay(3000)
        window.location.href = redirectLink
      }
    }
    void redirect()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.scroller}>
        {logoUrl && (
          <div className={styles.customerLogo}>
            <CustomerLogo url={logoUrl} />
          </div>
        )}
        <div className={styles.content}>
          <Container>
            <Header>
              <Title>{copy.thankyou_heading}</Title>
            </Header>
            <Body>
              <DisplayBlockMarkdownText text={copy.thankyou_message} />
              {redirectLink && (
                <Box>
                  {translate("test.thankyou.about_to_be_redirected")}
                  <Spinner size="xs" ml={2} />
                </Box>
              )}
            </Body>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default function customThankYouMessageContent(
  props: Props
): AppearanceProps {
  return {
    layoutState: LayoutState.FocusQuestion,
    mediaContent: null,
    questionContent: <CustomThankYouMessage {...props} />,
    isReportButtonVisible: false,
  }
}
