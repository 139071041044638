import { KeyboardEvent } from "react"

/**
 * This might be unnecessary. `keyCode` is deprecated, but I still use is
 * because I wasn't sure of legacy support for `key`.
 */
export function isEnter(event: KeyboardEvent<HTMLElement>): boolean {
  return (
    event.key === "Enter" ||
    // 13 is the code for Enter. We removed https://www.npmjs.com/package/key-codes library constants and should probably remove this code as keyCodes is deprecated.
    event.keyCode === 13
  )
}

export function isSpace(event: KeyboardEvent<HTMLElement>): boolean {
  return (
    event.key === " " ||
    // 32 is the code for Space. We removed https://www.npmjs.com/package/key-codes library constants and should probably remove this code as keyCodes is deprecated.
    event.keyCode === 32
  )
}

/**
 * This is a portable version of `event.ctrlKey`.
 * OSX uses `metaKey` conventionally in place of `ctrl`.
 */
function isCtrlHeld(event: KeyboardEvent<HTMLElement>): boolean {
  return event.metaKey || event.ctrlKey
}

export function preventFormSubmission(event: KeyboardEvent<HTMLElement>) {
  if (
    // Form will be submitted on "enter" in some input elements.
    isEnter(event) &&
    // Permit submission with cmd/ctrl held.
    !isCtrlHeld(event) &&
    // This is only something we care about in certain input elements. Text
    // areas, buttons, select, radio button etc. do not submit on "enter".
    event.target instanceof HTMLInputElement &&
    event.target.type !== "button"
  ) {
    event.preventDefault()
  }
}
