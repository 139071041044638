import React, { useEffect } from "react"
import { Outlet, useMatch } from "react-router"

import { useToast } from "@chakra-ui/react"
import { TestSetSubnav } from "Components/test-set/Subnav"
import { Page } from "Shared/components/Page/Page"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"
import { useGetVariationSet } from "~/api/generated/usabilityhub-components"

export function RootTestSetLayout() {
  const toast = useToast()
  const testSetUniqueId = useMatch("/sets/:testSetId/*")?.params.testSetId

  const { data, error } = useGetVariationSet(
    {
      pathParams: { uniqueId: testSetUniqueId! },
    },
    { enabled: !!testSetUniqueId }
  )
  const testSet = data?.variation_set

  useEffect(() => {
    if (error) {
      toast({ title: "Error loading test set", status: "error" })
    }
  }, [error])

  return (
    <Page>
      <UsabilityHubNavbar />
      {testSet && (
        <TestSetSubnav
          id={testSet.id}
          uniqueId={testSet.unique_id}
          creatorName={testSet.creator?.name ?? null}
          redirectOnDelete
        />
      )}

      <Outlet />
    </Page>
  )
}
