import { usePrevious } from "@chakra-ui/react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { updateQuestionRandomized } from "Redux/reducers/test-builder-form/action-creators/questions"
import { QuestionType } from "Types"
import {
  useQuestionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"

// Reset randomized value when changing from or to a "Ranking"
// question since they are randomized by default
export const useQuestionDefaultRandomized = (): void => {
  const dispatch: Dispatch = useDispatch()
  const { question, questionIndex } = useQuestionContext()
  const sectionIndex = useSectionIndexContext()
  const prevQuestionType = usePrevious(question.type)
  useEffect(() => {
    const changingToOrFromRanked =
      prevQuestionType &&
      question.type !== prevQuestionType &&
      (question.type === QuestionType.Ranking ||
        prevQuestionType === QuestionType.Ranking)

    if (changingToOrFromRanked) {
      dispatch(
        updateQuestionRandomized(sectionIndex, questionIndex, question.type)
      )
    }
  }, [question.type, sectionIndex, questionIndex, prevQuestionType, dispatch])
}
