import { Role } from "Types"

export const userRolePermissionMatrix: Record<string, Role[]> = {
  manage_account: ["admin"],
  manage_team: ["admin"],
  purchase_credits: ["admin"],
  manage_tests: ["admin", "member"],
  spend_credits: ["admin", "member"],
  view_tests: ["admin", "member", "guest"],
}

export type Permission = keyof typeof userRolePermissionMatrix
