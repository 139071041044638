import classnames from "classnames"
import React, { Component } from "react"

import { getNormalizedMouseOffset, isLeftButton } from "Utilities/mouse-event"

import { RangeFill } from "./fill"
import { percentToValue, preventTextSelection, valueToPercent } from "./helpers"
import { RangeKnob } from "./knob"
import styles from "./range.module.scss"
import { RangeTicks } from "./ticks"

// -- Helpers --

interface Props {
  className?: string
  ticks: boolean
  onChange: (value: number) => void
  scale: ReadonlyArray<number>
  value: number
  label?: boolean
}

export class Slider extends Component<Props> {
  private trackElementRef = React.createRef<HTMLDivElement>()
  private isDragging = false

  // -- Handlers --

  private handleDocumentMouseUp = (event: MouseEvent) => {
    if (this.isDragging && isLeftButton(event)) {
      this.isDragging = false
      this.stopListeners()
    }
  }

  private handleDocumentMouseMove = (event: MouseEvent) => {
    if (this.isDragging) {
      const { scale, onChange } = this.props
      const { x } = getNormalizedMouseOffset(
        event,
        this.trackElementRef.current!
      )
      onChange(percentToValue(x, scale))
    }
  }

  private handleTrackClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isLeftButton(event) && !this.isDragging) {
      const { x } = getNormalizedMouseOffset(event)
      const knobValue = percentToValue(x, this.props.scale)

      // Snap to current cursor position.
      this.props.onChange(knobValue)
      // And start dragging as if the user had clicked on the knob.
      this.startDraggingKnob()
    }
  }

  private handleTickClick = (tickValue: number) => {
    if (!this.isDragging) {
      this.props.onChange(tickValue)
    }
  }

  private handleKnobMouseDown = () => {
    this.startDraggingKnob()
  }

  // -- Private interface --

  private startDraggingKnob() {
    this.isDragging = true
    this.startListeners()
  }

  private startListeners() {
    this.stopListeners()
    window.addEventListener("mousemove", this.handleDocumentMouseMove)
    window.addEventListener("mouseup", this.handleDocumentMouseUp)
  }

  private stopListeners() {
    window.removeEventListener("mousemove", this.handleDocumentMouseMove)
    window.removeEventListener("mouseup", this.handleDocumentMouseUp)
  }

  // -- React lifecycle --

  componentWillUnmount() {
    this.stopListeners()
  }

  render() {
    const { className, ticks, scale, value, label } = this.props
    const position01 = valueToPercent(value, scale)

    return (
      <div
        className={classnames(styles.range, className)}
        onMouseDown={preventTextSelection}
      >
        <div
          ref={this.trackElementRef}
          className={styles.track}
          data-qa="track"
          onMouseDown={this.handleTrackClick}
        >
          <RangeFill low={0} high={position01} />
          <RangeKnob
            name="knob"
            position01={position01}
            onMouseDown={this.handleKnobMouseDown}
            label={label && value}
          />
        </div>
        {ticks && <RangeTicks scale={scale} onClick={this.handleTickClick} />}
      </div>
    )
  }
}
