import { isEqual } from "lodash"

import { ResponseFilter } from "./filters/types"
import { getFiltersWithCategory } from "./get-filters/get-filters-with"
import { isFilterSame } from "./is-filter-same"

type GenericFilterCreateFunction = (...args: any[]) => ResponseFilter

export function getFilterUtilityFunctions<
  CreateFilterFunction extends GenericFilterCreateFunction,
>(createFilter: CreateFilterFunction) {
  const isFiltered = (
    responseFilters: ResponseFilter[],
    ...args: Parameters<CreateFilterFunction>
  ) => {
    const representativeFilter = createFilter(...args)

    const filters = getFiltersWithCategory(
      responseFilters,
      representativeFilter.category
    )

    if (!filters.length) return false

    return filters.every((filter) => {
      return !isEqual(filter.value, representativeFilter.value)
    })
  }

  const isFilter = (
    responseFilters: ResponseFilter[],
    ...args: Parameters<CreateFilterFunction>
  ) => {
    const representativeFilter = createFilter(...args)

    const filters = getFiltersWithCategory(
      responseFilters,
      representativeFilter.category
    )

    if (!filters.length) return false

    return filters.some((filter) =>
      isEqual(filter.value, representativeFilter.value)
    )
  }

  const addFilter = (
    responseFilters: ResponseFilter[],
    ...args: Parameters<CreateFilterFunction>
  ) => {
    return [...responseFilters, createFilter(...args)]
  }

  const removeFilter = (
    responseFilters: ResponseFilter[],
    ...args: Parameters<CreateFilterFunction>
  ) => {
    return responseFilters.filter(
      (responseFilter) => !isFilterSame(responseFilter, createFilter(...args))
    )
  }

  const toggleFilter = (
    responseFilters: ResponseFilter[],
    ...args: Parameters<CreateFilterFunction>
  ) => {
    const filter = createFilter(...args)
    const foundFilter = responseFilters.find((responseFilter) =>
      isFilterSame(responseFilter, filter)
    )

    if (!foundFilter) {
      return addFilter(responseFilters, ...args)
    }

    return removeFilter(responseFilters, ...args)
  }

  return {
    isFiltered,
    isFilter,
    addFilter,
    removeFilter,
    toggleFilter,
  }
}
