import React, { useCallback, useContext, useEffect, useState } from "react"

import { isAxiosErrorWithMessage } from "Services/axios"
import { apiAdminIpAddressInfo } from "~/api"

type IpAddressInfo = {
  ip_address: string
  isp: string
  country_code: string
  country_name: string
  city: string
  vpn: boolean
  proxy: boolean
  tor: boolean
  threat: boolean
  cloud_provider: boolean
}

type IpState = { ipAddress: string } & (
  | { state: "ready"; ipInfo: IpAddressInfo }
  | { state: "loading"; ipAddress: string }
  | { state: "error"; ipAddress: string; message: string }
)

type AdminIpAddressContextType = {
  ipAddresses: IpState[]
  lookupIp: (ipAddress: string, date: string) => Promise<void>
}

const AdminIpAddressContext = React.createContext<AdminIpAddressContextType>({
  ipAddresses: [],
  lookupIp: () => {
    throw new Error("Not within provider")
  },
})

// So we don't kick off two requests when they come in at the same time
const seenIps = new Set<string>()

export const useAdminIpLookup = (
  ipAddress: string | null,
  date: string
): IpState => {
  const { ipAddresses, lookupIp } = useContext(AdminIpAddressContext)
  const ipInfo =
    ipAddress === null
      ? null
      : ipAddresses.find((ip) => ip.ipAddress === ipAddress)

  useEffect(() => {
    if (ipAddress !== null && !ipInfo) {
      if (!seenIps.has(ipAddress)) {
        seenIps.add(ipAddress)
        void lookupIp(ipAddress, date)
      }
    }
  }, [ipAddress, lookupIp, date, ipInfo])

  if (ipAddress === null)
    return { state: "error", ipAddress: "blank", message: "Blank IP address" }

  if (ipInfo) return ipInfo

  return { ipAddress, state: "loading" }
}

export const AdminIpAddressContextProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [ipAddressesState, setIpAddressesState] = useState<IpState[]>([])

  const lookupIp = useCallback(async (ipAddress: string, date: string) => {
    setIpAddressesState((prev) => [...prev, { ipAddress, state: "loading" }])
    try {
      const data = await lookupIpAddress(ipAddress, date)

      setIpAddressesState((prev) =>
        prev.map((ip) =>
          ip.ipAddress === ipAddress
            ? { ipAddress, state: "ready", ipInfo: data }
            : ip
        )
      )
    } catch (error) {
      if (!isAxiosErrorWithMessage(error)) throw error

      const message = error.response.data.message

      setIpAddressesState((prev) =>
        prev.map((ip) =>
          ip.ipAddress === ipAddress
            ? { ipAddress, state: "error", message }
            : ip
        )
      )
    }
  }, [])

  return (
    <AdminIpAddressContext.Provider
      value={{
        ipAddresses: ipAddressesState,
        lookupIp,
      }}
    >
      {children}
    </AdminIpAddressContext.Provider>
  )
}

const lookupIpAddress = async (
  ipAddress: string,
  date: string
): Promise<IpAddressInfo> => {
  return apiAdminIpAddressInfo.lookup<IpAddressInfo>({
    query: {
      ip_address: ipAddress,
      date,
    },
  })
}

export const fraudStatus = (
  userCountry: string,
  ip: IpAddressInfo,
  highRiskCountryCodes: string[]
) => {
  const countryMatches = ip.country_code === userCountry

  if (
    !countryMatches &&
    (ip.threat || highRiskCountryCodes.includes(ip.country_code))
  ) {
    return "alert"
  } else if (ip.vpn || !countryMatches) {
    return "warn"
  } else {
    return "ok"
  }
}
