import { useFilteredResponseSections } from "Components/test-results/hooks/use-filtered-response-sections"
import { State } from "Redux/app-store"
import { TreeNode } from "Types"
import { useSectionContext } from "UsabilityHub/contexts"
import {
  groupBy,
  keyBy,
  last,
  map,
  mapValues,
  orderBy,
  pick,
  sumBy,
} from "lodash"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"

export type IndividualPath = {
  id: string
  nodes: (TreeNode | null)[]
  result: "success" | "failure" | "skipped"
  directness: "direct" | "indirect"
  time: number
  responseSectionId: number
}

export type CommonPath = Omit<IndividualPath, "responseSectionId"> & {
  participants: number
}

export const useIndividualPaths = (): IndividualPath[] => {
  const { section } = useSectionContext()

  const nodes = useMemo(
    () => keyBy(section.tree_test_attributes?.nodes || [], "id"),
    [section]
  )

  const correctNodeIds = useMemo(
    () => new Set(section.tree_test_attributes?.correct_nodes || []),
    [section]
  )

  const filteredResponseSections = useFilteredResponseSections()

  const responseSections = useMemo(
    () =>
      filteredResponseSections.filter(
        ({ usability_test_section_id }) =>
          usability_test_section_id === section.id
      ),
    [filteredResponseSections, section.id]
  )

  const responseSectionIds = useMemo(
    () => new Set(map(responseSections, "id")),
    [responseSections]
  )

  const treeTestPaths = useSelector(
    (state: State) => state.testResults?.responseSectionTreeTestPaths
  )

  const allPaths = useMemo(
    () =>
      treeTestPaths?.filter(({ response_section_id }) =>
        responseSectionIds.has(response_section_id)
      ) || [],
    [treeTestPaths, responseSectionIds]
  )

  const responseTimes = useMemo(
    () =>
      mapValues(
        keyBy(
          responseSections.filter((section) =>
            responseSectionIds.has(section.id)
          ),
          "id"
        ),
        "task_duration_ms"
      ),
    [responseSections, responseSectionIds]
  )

  const filteredPaths = useMemo(() => {
    const isDirect = (path: readonly (string | null)[]) => {
      if (!path.length || (path.length === 1 && !path[0])) return true
      const lastNode = last(path)
      if (!lastNode) return false
      return nodes[lastNode].depth >= path.length - 1
    }

    const pathResult = (path: readonly (string | null)[]) => {
      const lastNode = last(path)
      if (!lastNode) return "skipped"
      if (correctNodeIds.has(lastNode)) return "success"
      return "failure"
    }

    return allPaths
      .filter((path) => responseSectionIds.has(path.response_section_id))
      .map(
        ({ path, response_section_id }) =>
          ({
            id: path.join(":"),
            nodes: path.map((p) => p && nodes[p]) as (TreeNode | null)[],
            result: pathResult(path),
            directness: isDirect(path) ? "direct" : "indirect",
            time: responseTimes[response_section_id] || 0,
            responseSectionId: response_section_id,
          }) as const
      )
  }, [allPaths, responseSectionIds, responseTimes, correctNodeIds, nodes])

  return filteredPaths
}

// Group results by the path taken
export const useGroupedPaths = (): CommonPath[] => {
  const individualPaths = useIndividualPaths()

  const groupedPaths = useMemo(() => {
    const grouped = groupBy(individualPaths, "id")

    return map(Object.values(grouped), (paths) => {
      const participants = paths.length
      const time = sumBy(paths, "time") / participants
      const { id, nodes, result, directness } = paths[0]
      return { id, nodes, result, directness, time, participants }
    })
  }, [individualPaths])

  return groupedPaths
}

// Common paths are grouped paths taken by more than 1 participant
export const useCommonPaths = (): CommonPath[] => {
  const groupedPaths = useGroupedPaths()

  return groupedPaths.filter((paths) => paths.participants > 1)
}

type IndividualPathSort = "result" | "time"

type CommonPathSort = "most_common" | "least_common" | "result" | "average_time"

export type SortOrderType<T extends CommonPath | IndividualPath> =
  T extends CommonPath ? CommonPathSort : IndividualPathSort

const isCommonPath = (path: IndividualPath | CommonPath): path is CommonPath =>
  "participants" in path

const sortWeights = {
  success: 0,
  failure: 2,
  skipped: 4,
  direct: 0,
  indirect: 1,
} as const

const sortOptions = {
  most_common: {
    label: "Most common",
    ordering: [["participants"], ["desc"]],
  },
  least_common: {
    label: "Least common",
    ordering: [["participants"], ["asc"]],
  },
  result: {
    label: "Result",
    ordering: [
      [
        ({ result, directness }: CommonPath | IndividualPath) =>
          sortWeights[result] + sortWeights[directness],
      ],
      ["asc"],
    ],
  },
  time: {
    label: "Time taken",
    ordering: [["time"], ["asc"]],
  },
  average_time: {
    label: "Average time taken",
    ordering: [["time"], ["asc"]],
  },
} as const

export const useSortPaths = <T extends CommonPath | IndividualPath>(
  paths: T[]
) => {
  const isCommon = !!paths.length && isCommonPath(paths[0])

  const [sortOrder, setSortOrder] = useState<SortOrderType<T>>(
    () => (isCommon ? "most_common" : "result") as SortOrderType<T>
  )

  const sorted = useMemo(
    () => orderBy(paths, ...sortOptions[sortOrder].ordering),
    [paths, sortOrder]
  )

  const options = pick(
    sortOptions,
    isCommon
      ? ["most_common", "least_common", "result", "average_time"]
      : ["result", "time"]
  )

  return { sorted, options, sortOrder, setSortOrder }
}
