import React from "react"

import { createErrorContent } from "./create-error-content"

export function disconnectedErrorContent() {
  return createErrorContent(
    <>
      <p className="para">
        <strong>You were disconnected for too long</strong>
      </p>
      <p className="para">Sorry, you can no longer complete this test.</p>
    </>
  )
}
