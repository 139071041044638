export async function minDuration<T>(
  ms: number,
  promise: Promise<T>
): Promise<T> {
  const [, result] = await Promise.all([delay(ms), promise])
  return result
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

/**
 * Be extremely careful with this! Created for async functions that result in a
 * navigation with page load.
 *
 * @returns a promise that never resolves
 */
export function neverResolve(): Promise<any> {
  return new Promise<any>(() => {
    // Do nothing
  })
}
