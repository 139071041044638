import { Box } from "@chakra-ui/react"
import React from "react"
import { ModeratedStudyAvailabilityForm } from "../availability/ModeratedStudyAvailabilityForm"
import { useStudyDetails } from "./StudyDetailsProvider"

export const Availability: React.FC = () => {
  const { availabilityForm } = useStudyDetails()

  return (
    <Box
      id="availability"
      bg="white"
      rounded="lg"
      p="8"
      borderColor="gray.200"
      borderWidth="thin"
    >
      <ModeratedStudyAvailabilityForm availabilityForm={availabilityForm} />
    </Box>
  )
}
