import { Filter } from "Components/test-results/types/filters"
import { UsabilityTest } from "Types"

import { getFilterMeta } from "../get-filter-meta"

import { ExactAnswerFilter } from "./exact-answer"
import { FilterFunction } from "./types"

export type OtherAnswerFilter = Filter<
  ExactAnswerFilter["category"],
  "answer/other-answer",
  undefined
>

const getQuestion = ({ sections }: UsabilityTest, questionId: number) => {
  const question = sections.reduce((question, section) => {
    if (question) return question

    return section.questions.find((question) => question.id === questionId)
  }, undefined)

  return question
}

export const filterResponseByOtherAnswer: FilterFunction<OtherAnswerFilter> = ({
  filter,
  filteredRelatedData: { responseAnswers },
  allRelatedData: { usabilityTest },
}) => {
  const { questionId } = getFilterMeta(filter.category)
  const question = getQuestion(usabilityTest, questionId)

  if (!question) return false

  const filteredResponseAnswers = responseAnswers.filter(
    (responseAnswer) =>
      responseAnswer.usability_test_section_question_id === questionId
  )

  return filteredResponseAnswers.every((responseAnswer) => {
    if (
      Array.isArray(responseAnswer.answers) &&
      responseAnswer.answers.length
    ) {
      // When all answers given are included in the question choices, reject
      return !responseAnswer.answers.every((answer) =>
        question.multiple_choice_options.includes(answer)
      )
    }

    // When answer is included in the question choices, reject
    return !question.multiple_choice_options.includes(responseAnswer.answer!)
  })
}
