import { Text, TextProps } from "@chakra-ui/react"
import Fuse from "fuse.js"
import { chunk, flatten } from "lodash"
import { zip } from "lodash"
import React, { Fragment, useMemo } from "react"

type HighlightedTextProps = Omit<TextProps, "children"> & {
  matches: Readonly<Fuse.FuseResultMatch[]>
  text: string
}

type Chunk = [string, string]

export const HighlightedText: React.FC<HighlightedTextProps> = ({
  matches,
  text,
  sx = {},
  ...props
}) => {
  const chunks = useMemo(() => {
    const indices = flatten(
      matches[0]?.indices?.map(([a, b]) => [a, b + 1]) || []
    )
    return chunk(zip([0, ...indices], [...indices, text.length]), 2).map(
      ([plain, highlighted]): Chunk =>
        highlighted
          ? ([text.slice(...plain), text.slice(...highlighted)] as Chunk)
          : ([text.slice(...plain), ""] as Chunk)
    )
  }, [matches, text])

  return (
    <Text
      as="span"
      {...props}
      sx={{
        "& u": {
          textDecoration: "none",
          backgroundColor: "yellow.200",
          mx: "-0.125em",
          px: "0.125em",
          mixBlendMode: "multiply",
          borderRadius: "0.25em",
          ...sx,
        },
      }}
    >
      {chunks.map(([plain, highlighted], i) => (
        <Fragment key={i}>
          {plain}
          {highlighted && <u>{highlighted}</u>}
        </Fragment>
      ))}
    </Text>
  )
}
