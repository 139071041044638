import { Spinner, Stack } from "@chakra-ui/react"
import React from "react"
import { useApi } from "./ApiProvider"
import { ExperimentRow } from "./ExperimentRow"

export const Experiments: React.FC = () => {
  const { loading, experiments } = useApi()

  if (loading) return <Spinner />

  return (
    <Stack spacing={4}>
      {experiments.map((experiment) => (
        <ExperimentRow key={experiment.name} experiment={experiment} />
      ))}
    </Stack>
  )
}
