import { Grid } from "@chakra-ui/react"
import { cq } from "Shared/helpers/containerQuerySx"
import React, { PropsWithChildren } from "react"
import { BREAKPOINT_MAX } from "../breakpoints"

export const SummaryActionsGrid = ({ children }: PropsWithChildren) => (
  <Grid
    columnGap={4}
    rowGap={2}
    gridAutoRows="auto"
    gridTemplateColumns="1fr"
    sx={cq(BREAKPOINT_MAX, {
      gridTemplateRows: "auto",
      gridTemplateColumns: "repeat(auto-fit, minmax(30%, 1fr))",
    })}
  >
    {children}
  </Grid>
)
