import { Box, BoxProps } from "@chakra-ui/react"
import React, { FunctionComponent, useRef } from "react"
type Context = React.RefObject<HTMLDivElement> | null

export const BottomPanelPortalContext = React.createContext<Context>(null)

export const BottomPanelPortal: FunctionComponent<
  React.PropsWithChildren<BoxProps>
> = ({ children, ...props }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  return (
    <BottomPanelPortalContext.Provider value={containerRef}>
      {children}
      <Box ref={containerRef} {...props} />
    </BottomPanelPortalContext.Provider>
  )
}
