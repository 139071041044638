import { BaseError } from "make-error"

import { isBlank } from "Utilities/values"

export class LoadImageError extends BaseError {
  src: string

  constructor(src: string) {
    super("Failed to load image")
    this.src = src
  }
}

export async function loadImage(src: string): Promise<Event> {
  if (isBlank(src)) throw new Error(`Image src is empty: ${src}`)
  return new Promise<Event>((resolve, reject) => {
    const image = new Image()
    image.onload = resolve
    image.onerror = (_event: Event) => reject(new LoadImageError(src))
    image.src = src
  })
}
