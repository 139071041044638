import { Checkbox, Stack, Text } from "@chakra-ui/react"
import React, { useContext } from "react"

import { DemographicsContext } from "Components/test-results/context/demographics"
import { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"

interface Props {
  readonly onChange: (changes: Partial<RecruitmentLink>) => void
  readonly values: RecruitmentLink
}

export const DemographicQuestionsFields: React.FC<Props> = ({
  values,
  onChange,
}) => {
  const { demographics } = useContext(DemographicsContext)
  const coreDemographicAttributes = demographics
    .flatMap((group) => group.demographic_attributes)
    .filter((attribute) => attribute.is_core)

  return (
    <Stack spacing={0}>
      <AttributeRow
        name="Age"
        value={values.capture_age}
        onChange={(v) => onChange({ capture_age: v })}
      />
      <AttributeRow
        name="Country"
        value={values.capture_country}
        onChange={(v) => onChange({ capture_country: v })}
      />

      {coreDemographicAttributes.map((attribute) => {
        return (
          <AttributeRow
            key={attribute.id}
            name={attribute.name}
            value={values.capture_demographic_attribute_ids.includes(
              attribute.id
            )}
            onChange={(v) => {
              if (v) {
                onChange({
                  capture_demographic_attribute_ids:
                    values.capture_demographic_attribute_ids.concat(
                      attribute.id
                    ),
                })
              } else {
                onChange({
                  capture_demographic_attribute_ids:
                    values.capture_demographic_attribute_ids.filter(
                      (id) => id !== attribute.id
                    ),
                })
              }
            }}
          />
        )
      })}
    </Stack>
  )
}

interface AttributeRowProps {
  name: string
  value: boolean
  onChange: (value: boolean) => void
}
const AttributeRow: React.FC<AttributeRowProps> = ({
  name,
  value,
  onChange,
}) => (
  <Checkbox
    size="sm"
    isChecked={value}
    onChange={(e) => onChange(e.target.checked)}
  >
    <Text fontSize="small" color="gray.500">
      {name}
    </Text>
  </Checkbox>
)
