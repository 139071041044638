import { Box, Grid, keyframes } from "@chakra-ui/react"
import React from "react"

const colorMap = {
  green: "ds.icon.accent.green",
  teal: "ds.icon.accent.teal",
  red: "ds.icon.accent.red",
  brand: "ds.icon.brand",
} as const

type Props = {
  colorScheme: keyof typeof colorMap
  boxSize?: number
}

export const Beacon = ({ colorScheme, boxSize = 4 }: Props) => {
  const color = colorMap[colorScheme]

  const boxSizeRem = boxSize / 4
  const dotSizeRem = boxSizeRem / 2

  return (
    <Grid boxSize={boxSize} placeItems="center">
      <Box
        pos="relative"
        boxSize={`${dotSizeRem}rem`}
        rounded="full"
        bg={color}
        _before={{
          content: `""`,
          position: "absolute",
          inset: 0,
          rounded: "full",
          bg: color,
          animation: `${ripple} 1.75s infinite ease-out`,
          _mediaReduceMotion: {
            "&": { animation: "none" },
          },
        }}
      />
    </Grid>
  )
}

// In a 24px container, the dot should be a 12px diameter
// and the ripple should grow to a 22px diameter (22/12 = 1.83)
// (this matches our icons: 22px content inside 24px container)
const ripple = keyframes`
  0%{
    scale: 0.95;
    opacity: 1;
  }
  90%{
    scale: 1.83;
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
`
