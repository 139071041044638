import { GetModeratedStudyBookingsResponse } from "~/api/generated/usabilityhub-components"

type FormatInterviewParticipantNameProps = Pick<
  GetModeratedStudyBookingsResponse[number],
  "full_name" | "preferred_name"
>

export const formatInterviewParticipantName = ({
  full_name,
  preferred_name,
}: FormatInterviewParticipantNameProps) => {
  if (full_name && preferred_name) {
    return full_name !== preferred_name
      ? `${full_name} (${preferred_name})`
      : full_name
  }
  if (preferred_name) {
    return preferred_name
  }
  return full_name ?? ""
}
