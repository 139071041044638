import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"
import { useDestroyDemographicPreset } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { DemographicPreset } from "~/api/generated/usabilityhubSchemas"

type Props = {
  preset: DemographicPreset
}

export const DeletePresetModal: FunctionalModal<Props> = ({
  preset,
  isOpen,
  onClose,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  const { mutate, isLoading } = useDestroyDemographicPreset({
    onSuccess: async () => {
      const key = queryKeyFn({
        path: "/api/demographic_presets",
        operationId: "listDemographicPresets",
        variables: {},
      })

      await queryClient.invalidateQueries(key)
      onClose()

      toast({
        title: "Deleted saved demographic group",
        status: "success",
      })
    },
    onError: () => {
      toast({
        title: "Failed to delete saved demographic group",
        status: "error",
      })
    },
  })

  const handleDelete = () => {
    mutate({
      pathParams: {
        id: preset.id,
      },
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent maxW="xl" my="auto">
        <ModalHeader pb={6}>
          <Heading>Delete this saved demographic group</Heading>

          <ModalCloseButton />
        </ModalHeader>

        <ModalBody py={4}>
          <Text>
            Are you sure you want to delete ‘{preset.name}’? This will
            permanently delete this saved group for all members of your team and
            cannot be undone.
          </Text>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>

          <Button
            colorScheme="red"
            variant="solid"
            isLoading={isLoading}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
