import { Divider, Flex, Text } from "@chakra-ui/react"
import React from "react"

type DividerWithText = React.FC<
  React.PropsWithChildren<{
    text: string
  }>
>

export const DividerWithText: DividerWithText = ({ text }) => {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Divider flexGrow={1} />
      <Text
        color="gray.500"
        fontWeight="semibold"
        fontSize="xs"
        marginX={2}
        textAlign="center"
      >
        {text}
      </Text>
      <Divider flexGrow={1} />
    </Flex>
  )
}
