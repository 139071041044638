import React from "react"

interface AdminDashboardContextType {
  isAdminDashboard: boolean
}

export const AdminDashboardContext =
  React.createContext<AdminDashboardContextType>({
    isAdminDashboard: false,
  })
