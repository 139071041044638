import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { SubmitButton } from "Components/button/submit-button"
import { interviewCreatedGoogle } from "JavaScripts/analytics/google"
import { ROUTES } from "UsabilityHub/views/routes"
import { FunctionalModal } from "Utilities/modals/types"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import * as Yup from "yup"
import { useDuplicateModeratedStudy } from "~/api/generated/usabilityhub-components"

type Props = {
  moderatedStudyId: string
  studyName: string
}

const DuplicateModeratedStudyFormSchema = Yup.object({
  internal_name: Yup.string()
    .max(100, "Study name is too long (max 100 characters)")
    .required("Study name is required"),
})

type FormValues = Yup.InferType<typeof DuplicateModeratedStudyFormSchema>

export const DuplicateModeratedStudyModal: FunctionalModal<Props> = ({
  moderatedStudyId,
  studyName,
  isOpen,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const toast = useToast()

  const { mutateAsync: duplicateModeratedStudy } = useDuplicateModeratedStudy({
    onSuccess: async (data) => {
      toast({
        title: "Study duplicated",
        status: "success",
      })

      interviewCreatedGoogle()

      void queryClient.invalidateQueries(["api", "moderated_studies"], {
        exact: true,
      })
      await queryClient.invalidateQueries(["api", "studies"])

      onClose()
      navigate(
        ROUTES.INTERVIEW.EDIT.buildPath({
          moderatedStudyId: data.moderated_study_id,
        })
      )
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(DuplicateModeratedStudyFormSchema),
    defaultValues: {
      internal_name: studyName,
    },
  })

  const onSubmit = async (values: FormValues) => {
    try {
      await duplicateModeratedStudy({
        pathParams: {
          moderatedStudyId,
        },
        body: values,
      })
    } catch (e) {
      toast({
        title: e.payload?.message ?? "Test duplicate failed",
        status: "error",
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Duplicate “{studyName}”</ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing={4}>
              <FormControl isInvalid={!!errors.internal_name}>
                <FormLabel htmlFor="email">Study name</FormLabel>
                <Input variant="filled" {...register("internal_name")} />
                <FormHelperText>
                  This is an internal name that participants won{"\u2019"}t see.
                </FormHelperText>
                {errors.internal_name && (
                  <FormErrorMessage>
                    {errors.internal_name.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button
                variant="outline"
                onClick={onClose}
                isDisabled={isSubmitting}
              >
                Cancel
              </Button>
              <SubmitButton
                isDisabled={!isValid}
                isLoading={isSubmitting}
                loadingAction="Duplicating"
              >
                Duplicate
              </SubmitButton>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
