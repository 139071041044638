import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"

export const DesktopOnlyModal: FunctionalModal<{ onSubmit: () => void }> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent maxW="xl">
        <ModalHeader>
          Desktop-only required for live website testing
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          Live website testing is only supported on desktop devices. Adding this
          section will set your entire test to desktop-only participation.
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>

          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              onSubmit()
              onClose()
            }}
          >
            Add section and set to desktop only
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
