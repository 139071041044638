import {
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

import Constants from "Constants/shared.json"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { useSectionIndexContext } from "UsabilityHub/contexts"
import { SToMs } from "Utilities/time"

import { SectionInstructionField } from "../SectionFields/SectionInstructionField"
import { SectionMedia } from "../SectionMedia/SectionMedia"

export const FiveSecondTask: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <Stack spacing={5}>
      <FiveSecondDurationField />
      <SectionMedia allowedMediaTypes={["image"]} />
    </Stack>
  )
}

const FiveSectionDurationNumberInput: React.FC<
  React.PropsWithChildren<WrappedFieldProps>
> = ({ input }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  const seconds = input.value as number
  const placeholder = `Look at the design for ${seconds} seconds and remember as much as you can`

  return (
    <HStack spacing={10}>
      <SectionInstructionField placeholder={placeholder} />
      <Stack justify="space-between">
        <Text as="label" textStyle="label" htmlFor={input.name}>
          Design shown for
        </Text>
        <HStack>
          <NumberInput
            w={24}
            min={Constants.MIN_FIVE_SECOND_TEST_DURATION_MS / SToMs}
            max={Constants.MAX_FIVE_SECOND_TEST_DURATION_MS / SToMs}
            id={input.name}
            isReadOnly={readOnly}
            {...input}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Text as="span" textStyle="label">
            seconds
          </Text>
        </HStack>
      </Stack>
    </HStack>
  )
}

const FiveSecondDurationField = () => {
  const sectionIndex = useSectionIndexContext()
  return (
    <Field
      name={`sections[${sectionIndex}].task_time_limit_ms`}
      component={FiveSectionDurationNumberInput}
      format={(value: any) => Number(value) / SToMs}
      parse={(value: any) => Number(value) * SToMs}
    />
  )
}
