import { Box, Button, Flex, Text } from "@chakra-ui/react"
import React, {
  FC,
  PropsWithChildren,
  UIEvent,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react"

import { ExpandChevron } from "Components/expand-chevron/expand-chevron"
interface Props {
  maxHeight: number
  expandText: string
  collapseText: string
  className?: string
  disabled?: boolean
}

interface State {
  isExpanded: boolean
  isToggleVisible: boolean
  isAtTop: boolean
  isAtBottom: boolean
}

type Action =
  | { type: "toggle" }
  | { type: "scroll"; element: HTMLElement }
  | { type: "setToggleVisible"; isToggleVisible: boolean }

/**
 * A "near enough" margin that will consider the pane fully scrolled even if
 * it's this many pixels away.
 */
const ScrollPositionTolerance = 1

/**
 * The minimum height of content that can be hidden. ie. if max height is
 * 400px, the content is 401px high and `MinimumOverlap` is 0, then pressing
 * "expand" would reveal only one row of hidden pixels. When set to 100 the
 * content has to be at least 500px high before it is contracted.
 *
 * WARNING: This is required to prevent thrashing in Windows (or other systems
 * with scrollbars?). Should be set quite high... Even at 100 thrashing can
 * occur (see #2810).
 */
const MinimumOverlap = 200

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "toggle":
      return {
        ...state,
        isExpanded: !state.isExpanded,
      }
    case "scroll": {
      const { scrollTop, scrollHeight, offsetHeight } = action.element
      return {
        ...state,
        isAtTop: state.isExpanded || scrollTop < ScrollPositionTolerance,
        isAtBottom:
          state.isExpanded ||
          Math.abs(scrollHeight - offsetHeight - scrollTop) <
            ScrollPositionTolerance,
      }
    }
    case "setToggleVisible":
      return {
        ...state,
        isToggleVisible: action.isToggleVisible,
      }
    default:
      return state
  }
}

export const ExpandableRegion: FC<PropsWithChildren<Props>> = ({
  maxHeight,
  children,
  className,
  expandText,
  collapseText,
  disabled = false,
}) => {
  if (disabled) return children

  const [{ isExpanded, isToggleVisible, isAtTop, isAtBottom }, dispatch] =
    useReducer(reducer, {
      isExpanded: false,
      isToggleVisible: false,
      isAtTop: true,
      isAtBottom: true,
    } satisfies State)

  const [scrollableElement, handleScrollableRef] = useState<HTMLElement | null>(
    null
  )

  const handleToggle = () => dispatch({ type: "toggle" })

  const handleScroll = (event: UIEvent<HTMLElement>) =>
    dispatch({ type: "scroll", element: event.currentTarget })

  const handleScrollHeight = useCallback(() => {
    if (!scrollableElement) return
    const { scrollHeight } = scrollableElement
    const isToggleVisible = scrollHeight > maxHeight + MinimumOverlap
    dispatch({ type: "setToggleVisible", isToggleVisible })
    dispatch({ type: "scroll", element: scrollableElement })
  }, [scrollableElement, maxHeight])

  useEffect(handleScrollHeight, [handleScrollHeight])

  return (
    <Box overflow="hidden" isolation="isolate">
      <Box
        position="relative"
        _before={{
          ...scrollContainerPseudoElementStyles,
          top: 0,
          height: isAtTop || !isToggleVisible ? 0 : "15px",
          bg: "linear-gradient(rgba(0,0,0,0.05), transparent)",
        }}
        _after={{
          ...scrollContainerPseudoElementStyles,
          bottom: 0,
          height: isAtBottom || !isToggleVisible ? 0 : "15px",
          bg: "linear-gradient(transparent, rgba(0,0,0,0.05))",
        }}
        sx={{
          "@media print": {
            "&:before, &:after": {
              display: "none !important",
            },
          },
        }}
      >
        <Box
          className={className}
          overflowY="scroll"
          sx={{
            "&::-webkit-scrollbar": {
              width: "0 !important",
            },
            "@media print": {
              maxHeight: "none !important",
            },
          }}
          style={isExpanded || !isToggleVisible ? undefined : { maxHeight }}
          onScroll={handleScroll}
          ref={handleScrollableRef}
        >
          {children}
        </Box>
      </Box>
      {isToggleVisible && (
        <Flex
          justify="center"
          py={5}
          sx={{ "@media print": { display: "none" } }}
        >
          <Button
            variant="link"
            colorScheme="teal"
            onClick={handleToggle}
            gap={2}
          >
            <ExpandChevron isExpanded={isExpanded} />
            <Text>{isExpanded ? collapseText : expandText}</Text>
          </Button>
        </Flex>
      )}
    </Box>
  )
}

const scrollContainerPseudoElementStyles = {
  display: "block",
  content: '" "',
  position: "absolute",
  left: 0,
  right: 0,
  opacity: 1,
  transition: "height 200ms",
  zIndex: 200,
  pointerEvents: "none",
}
