/**
 * Regex test from Figma site to confirm URL is acceptable as embed.
 *
 * @see https://www.figma.com/developers/embed
 * @param {string} url URL to test regex against
 * @returns {boolean} Is the provided URL is a Figma prototype or file URL.
 */

export const DEFAULT_SCALING_SETTING = "scale_down_width"

export const SCALING_SETTINGS = [
  "min_zoom",
  "scale_down",
  "scale_down_width",
  "contain",
]

const figmaURLRegex =
  /https:\/\/([\w.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/

export const isFigmaEmbedUrl = (url: string): boolean => {
  return figmaURLRegex.test(url)
}

const getFigmaEmbedUrlParams = (url: string) => {
  const searchQuery = new URL(url).search
  return new URLSearchParams(searchQuery)
}

export const getStartNodeId = (url: string): string | null => {
  if (!isFigmaEmbedUrl(url)) return null
  const searchParams = getFigmaEmbedUrlParams(url)
  return searchParams.get("starting-point-node-id")
}

export const getFigmaFileKey = (url: string): string | null => {
  const urlArray = figmaURLRegex.exec(url)
  return urlArray ? urlArray[3] : null
}

export const getFigmaScaling = (url: string): string | null => {
  if (!isFigmaEmbedUrl(url)) return null
  const searchParams = getFigmaEmbedUrlParams(url)
  return searchParams.get("scaling")
}
