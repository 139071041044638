import { Text } from "@chakra-ui/react"
import { Role } from "Types"
import {
  useActiveStripeSubscription,
  useCurrentPlan,
} from "UsabilityHub/hooks/useCurrentAccount"
import { useIsTrialing } from "Utilities/account"
import { getDateString } from "Utilities/date-formats"
import {
  billingPeriodForPlan,
  planCostPerAdditionalSeatInInterval,
} from "Utilities/plan"
import { getRoleOption } from "Utilities/role"
import React from "react"

interface ChargeDetailsTextProps {
  billingPeriod: string
  newRoleName: string
  oldRoleName: string
  roleCost: string
  userEmail: string
}
const ChargeDetailsText: React.FC<
  React.PropsWithChildren<ChargeDetailsTextProps>
> = ({ billingPeriod, newRoleName, oldRoleName, roleCost, userEmail }) => {
  const subscription = useActiveStripeSubscription()
  let trialEnds, isCancelling
  const isTrialing = useIsTrialing()
  if (subscription) {
    trialEnds = isTrialing ? subscription.trial_end : null
    isCancelling = subscription.cancel_at_period_end
  }

  const willChargeAtTrialEnd = isTrialing && !isCancelling
  const willNotChargeAtTrialEnd = isTrialing && isCancelling

  const trialEndsText = getDateString(trialEnds)

  // If they're on a trial and it's ending, no charge at all.
  if (willNotChargeAtTrialEnd) {
    return (
      <Text>
        This user is free on your trial which ends on {trialEndsText}.
      </Text>
    )
  }

  // Otherwise work out when they'll be charged.
  return (
    <>
      <Text>
        Changing {userEmail} from {oldRoleName} to {newRoleName} will cost{" "}
        <strong>{roleCost}</strong>, pro-rated until the end of your{" "}
        {billingPeriod} billing period.
      </Text>

      {willChargeAtTrialEnd ? (
        <Text>
          You{"\u2019"}ll be charged the {billingPeriod} fee when your trial
          ends on {trialEndsText}.
        </Text>
      ) : (
        <Text>
          You{"\u2019"}ll be charged immediately. We{"\u2019"}ll send you a
          receipt detailing this new charge.
        </Text>
      )}
    </>
  )
}

type ChangeUserRoleModalContent = React.FC<
  React.PropsWithChildren<{
    hasPaidSeatAvailable: boolean
    oldRole: Role
    newRole: Role
    userEmail: string
  }>
>

export const ChangeUserRoleModalContent: ChangeUserRoleModalContent = ({
  hasPaidSeatAvailable,
  oldRole,
  newRole,
  userEmail,
}) => {
  const plan = useCurrentPlan()

  if (!plan) return null

  const roleCost = planCostPerAdditionalSeatInInterval(plan)
  const billingPeriod = billingPeriodForPlan(plan)

  const oldRoleOption = getRoleOption(oldRole)
  const newRoleOption = getRoleOption(newRole)

  if (
    // Downgrading to unpaid seat
    oldRoleOption.paid &&
    !newRoleOption.paid &&
    plan.viewer_permissions
  ) {
    return (
      <Text>
        {newRoleOption.name}s are free and unlimited on {plan.name} accounts.
      </Text>
    )
  }

  // Sidegrading a paid seat
  if (oldRoleOption.paid && newRoleOption.paid) {
    return (
      <Text>
        You{"\u2019"}re already paying for {userEmail}, so changing their role
        from {oldRoleOption.name} to {newRoleOption.name} won{"\u2019"}t cost
        you anything.
      </Text>
    )
  }

  if (
    // Upgrading to a paid set
    !oldRoleOption.paid &&
    newRoleOption.paid
  ) {
    if (hasPaidSeatAvailable) {
      return (
        <Text>
          You have an available seat on your account, so changing {userEmail}{" "}
          from {oldRoleOption.name} to {newRoleOption.name} won{"\u2019"}t cost
          you anything.
        </Text>
      )
    } else {
      return (
        <ChargeDetailsText
          billingPeriod={billingPeriod}
          oldRoleName={oldRoleOption.name}
          newRoleName={newRoleOption.name}
          roleCost={roleCost}
          userEmail={userEmail}
        />
      )
    }
  }

  // Should be exhaustive, but throw to appease the typescript gods
  //
  // TODO: This _should_ never be hit but it does get triggered by running:
  //
  //    rails t test/system/managing_team_members_1_test.rb:502
  //
  // throw new Error("unreachable")
  return null
}
