import {
  Avatar,
  Button,
  Flex,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"

import { WrapIf } from "Shared/components/WrapIf/WrapIf"
import { AccountOwnerTag } from "UsabilityHub/components/AccountOwnerTag/AccountOwnerTag"
import { TeamMember } from "~/api/generated/usabilityhubSchemas"
import { ChangeUserRoleForm } from "./ChangeUserRoleForm"
import { EditUserModal } from "./EditUserModal"
import { ResendInvitationForm } from "./ResendInvitationForm"

type UsersListItem = React.FC<
  React.PropsWithChildren<{
    currentUserCanManageTeam: boolean
    hasPaidSeatAvailable: boolean
    user: TeamMember
    isAccountOwner: boolean
    isEditable: boolean
    isDeletable: boolean
    canChangeRole: boolean
    canMakeAccountOwner: boolean
    flashHighlight: boolean
  }>
>

export const UsersListItem: UsersListItem = ({
  currentUserCanManageTeam,
  hasPaidSeatAvailable,
  user,
  isAccountOwner,
  isEditable,
  isDeletable,
  canChangeRole,
  canMakeAccountOwner,
  flashHighlight,
}) => {
  const {
    isOpen: isEditModalOpen,
    onOpen: openEditModal,
    onClose: closeEditModal,
  } = useDisclosure()

  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    flashHighlight &&
      ref.current?.scrollIntoView({ behavior: "smooth", block: "start" })
  }, [flashHighlight])

  return (
    <>
      <Flex
        alignItems={["start", "start", "center"]}
        data-qa={`user-row-${user.id}`}
        justifyContent="space-between"
        flexDirection={["column", "column", "row"]}
        backgroundColor={flashHighlight ? "yellow.100" : undefined}
        transition="0.4s background-color"
        ref={ref}
      >
        <Stack direction="row" spacing={3} alignItems="center">
          <Avatar src={user.avatar_url_medium} size="sm" name={user.name} />
          <Stack spacing={0}>
            {user.name.toLowerCase() !== user.email && (
              <Text lineHeight="shorter" fontWeight="semibold">
                {user.name}
                {isAccountOwner && <AccountOwnerTag ml={1} />}
              </Text>
            )}
            <Text lineHeight="shorter" color="gray.600">
              {user.email}
            </Text>
          </Stack>
        </Stack>
        {currentUserCanManageTeam && (
          <WrapIf
            condition={isAccountOwner}
            wrapper={(children) => (
              <Tooltip
                hasArrow
                label={`You can${"\u2019"}t edit this person${"\u2019"}s details because they${"\u2019"}re the account owner.`}
              >
                {children}
              </Tooltip>
            )}
          >
            <Stack direction="row" py={2} ps={[0, 0, 2]} spacing={2}>
              {user.sign_in_count === 0 && (
                <ResendInvitationForm userId={user.id} />
              )}
              <Button
                size="sm"
                onClick={openEditModal}
                variant="outline"
                isDisabled={!isEditable}
              >
                Edit
              </Button>
              <Flex flexGrow={1} justifyContent="center">
                <ChangeUserRoleForm
                  hasPaidSeatAvailable={hasPaidSeatAvailable}
                  user={user}
                  isDisabled={!canChangeRole}
                  isDeletable={isDeletable}
                />
              </Flex>
            </Stack>
          </WrapIf>
        )}
      </Flex>
      <EditUserModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        user={user}
        canMakeAccountOwner={canMakeAccountOwner}
      />
    </>
  )
}
