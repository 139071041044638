import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import React from "react"
import { useDispatch } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { updateFigmaFileVersion } from "Redux/reducers/figma-file-versions/actions"
import { FigmaFileVersion } from "Types"
import { FunctionalModal } from "Utilities/modals/types"

interface Props {
  newFigmaFileVersion: FigmaFileVersion
  oldFigmaFileVersion: FigmaFileVersion
  handleClose: () => void
  afterConfirm?: () => void
}

export const ConfirmFigmaFileVersionOverwriteModal: FunctionalModal<Props> = ({
  oldFigmaFileVersion,
  newFigmaFileVersion,
  handleClose,
  afterConfirm,
}) => {
  const dispatch = useDispatch<Dispatch>()

  const handleConfirm = () => {
    dispatch(updateFigmaFileVersion(oldFigmaFileVersion, newFigmaFileVersion))
    afterConfirm?.()
    handleClose()
  }

  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalHeader>Warning</ModalHeader>
        <ModalBody>
          This will update all sections in this test that use this Figma file to
          the latest version. Do you want to continue?
        </ModalBody>
        <ModalFooter flexDir="row-reverse" justifyContent="flex-start">
          <Button colorScheme="brand.primary" onClick={handleConfirm} ml={3}>
            Yes
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
