import { assertNever } from "Components/filter-controls/utils"
import { Rating, RatingFormSentiment } from "./types"

export const extractSentiment = (rating: Rating): RatingFormSentiment => {
  switch (rating) {
    case 1:
      return "negative"
    case 2:
      return "negative"
    case 3:
      return "neutral"
    case 4:
      return "positive"
    case 5:
      return "positive"
    default:
      assertNever(rating)
      return "neutral"
  }
}
