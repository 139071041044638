import { add } from "date-fns"
import { debounce } from "lodash"
import { useCallback, useEffect, useState } from "react"

export const TIMEOUT_WARNING_GRACE_PERIOD = 0.25 // Proportion of idle timeout

// NOTE: `keypress` is not handled correctly in Chrome mobile, so `keyup` must
// be monitored.
//

// see: https://stackoverflow.com/a/25826882/317135
const ACTIVITY_EVENTS: Array<keyof DocumentEventMap> = [
  "mousedown",
  "mousemove",
  "keypress",
  "keyup",
  "touchmove",
  "wheel",
  "progress",
  "timeupdate",
]

export const useActivityMonitor = (idleTimeoutMs: number | null) => {
  const [timeOutAt, setTimeOutAt] = useState<Date | null>(null)

  const totalTimeout = idleTimeoutMs ?? 0
  const gracePeriod = totalTimeout * TIMEOUT_WARNING_GRACE_PERIOD

  const clearTimeout = useCallback(() => setTimeOutAt(null), [])

  const debouncedWarning = useCallback(
    debounce(() => {
      setTimeOutAt(
        (oldTimeout) =>
          oldTimeout ??
          add(Date.now(), {
            seconds: gracePeriod / 1000,
          })
      )
    }, totalTimeout - gracePeriod),
    [totalTimeout, gracePeriod]
  )

  useEffect(() => {
    if (idleTimeoutMs !== null) {
      // Defer deletion every time the user does anything.
      addActivityEventListener(() => debouncedWarning())

      // Kick it off. Any subsequent user activity will just push the deletion back.
      debouncedWarning()

      return () => {
        removeActivityEventListener(() => debouncedWarning())
      }
    }
  }, [idleTimeoutMs])

  return { timeOutAt, clearTimeout }
}

function addActivityEventListener(handler: () => void) {
  // These listeners are added with useCapture set to true as HTMLMediaElement
  // events don't bubble up to the document, so we need to capture them instead
  ACTIVITY_EVENTS.forEach((name) =>
    document.addEventListener(name, handler, true)
  )

  // We can't track events from an embedded iframe so we'll need to use the message
  // event we get from Figma to keep the response from being deleted
  window.addEventListener("message", handler)
}

function removeActivityEventListener(handler: () => void) {
  ACTIVITY_EVENTS.forEach((name) =>
    document.removeEventListener(name, handler, true)
  )

  window.removeEventListener("message", handler)
}
