import { Filter } from "Components/test-results/types/filters"

import { getAttributes } from "../get-attribute"
import { getAttributeCategoryFromCategory } from "../get-attribute-from-category"

import { LocationFilter } from "./attribute-exact-value"
import { AgeFilter } from "./attribute-range"
import { FilterFunction } from "./types"

export type UnknownLocationFilter = Filter<
  LocationFilter["category"],
  "attribute/unknown",
  undefined
>

export type UnknownAgeFilter = Filter<
  AgeFilter["category"],
  "attribute/unknown",
  undefined
>

export type AttributeUnknownFilter = UnknownLocationFilter | UnknownAgeFilter

export const filterResponseByAttributeUnknownFilter: FilterFunction<
  AttributeUnknownFilter
> = ({ response, filter }) => {
  const attributeCategory = getAttributeCategoryFromCategory(filter.category)!

  const categoryAttributes = getAttributes(response, attributeCategory)

  if (categoryAttributes.length !== 0) return false

  return true
}
