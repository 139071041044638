import { Box, Button, Flex, Stack, Text } from "@chakra-ui/react"
import React from "react"

interface OptionToggleButtonProps {
  value: string
  targetValue: string
  onChange: (newValue: string) => void
  title: string
  description: string
  readOnly?: boolean
}

export const OptionToggleButton: React.FC<OptionToggleButtonProps> = ({
  value,
  targetValue,
  onChange,
  title,
  description,
  readOnly,
}) => {
  const active = value === targetValue

  return (
    <Button
      w="100%"
      h="auto"
      variant="outline"
      bg={active ? "brand.primary.50" : "white"}
      borderColor={active ? "brand.primary.500" : "gray.200"}
      borderWidth={active ? 2 : 1}
      rounded="lg"
      _hover={{
        bg: active ? undefined : "gray.50",
      }}
      isDisabled={readOnly}
      onClick={() => onChange(targetValue)}
    >
      <Flex w="100%" align="center" my={4}>
        <Box
          boxSize={5}
          rounded="100%"
          bg="white"
          borderColor={active ? "brand.primary.500" : "gray.200"}
          borderWidth={active ? 6 : 2}
          transition="0.1s border-width"
          ms={1}
          me={5}
          flexShrink={0}
        />

        <Stack spacing={1} align="flex-start">
          <Text>{title}</Text>
          <Text
            fontWeight="normal"
            size="sm"
            color="gray.500"
            whiteSpace="normal"
            textAlign="left"
          >
            {description}
          </Text>
        </Stack>
      </Flex>
    </Button>
  )
}
