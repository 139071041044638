import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import React, { useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import * as Yup from "yup"

import { SubmitButton } from "Components/button/submit-button"
import { trackTestCreated } from "JavaScripts/analytics/track"
import { ProCrownIcon } from "Shared/icons/ProCrownIcon"
import { ProjectSelector } from "UsabilityHub/components/ProjectSelector/project-selector"
import { isExternalStudy } from "UsabilityHub/utils/isExternalStudy"
import { reportErrorToSentry } from "Utilities/error"
import { FunctionalModal } from "Utilities/modals/types"
import { useHasTestSetsFeature } from "Utilities/plan"
import {
  useCreateProject,
  useCreateVariationSet,
  useDuplicateUsabilityTest,
} from "~/api/generated/usabilityhub-components"

const DuplicateUsabilityStudySchema = Yup.object({
  duplicateTestName: Yup.string()
    .max(255, "Test name is too long")
    .required("Test name is required"),
  createInVariation: Yup.string()
    .oneOf(["true", "false"])
    .required("Picking one option is required"),
  usabilityVariationSetName: Yup.string().when("createInVariation", {
    is: "true",
    then: (schema) =>
      schema
        .max(255, "Variation set name is too long")
        .required("Variation set name is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  selectedProject: Yup.string().optional(),
})

type DuplicateTestOptions = {
  name: string
  project_id: string
  test_set_id?: number
}

type DuplicateUsabilityStudy = Yup.InferType<
  typeof DuplicateUsabilityStudySchema
>

interface DuplicateUsabilityTestModalProps {
  usabilityTest: {
    id: number
    name: string
    variation_set: { id: number; name: string } | null
    project: { id: string } | null
  } & Parameters<typeof isExternalStudy>[0] // We need to be able to check if it's external, and there's a few ways to do it.
}

export const DuplicateStudyModal: FunctionalModal<
  DuplicateUsabilityTestModalProps
> = ({ usabilityTest, isOpen, onClose }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const toast = useToast()
  // The duplication requests will be finished before the redirection.
  // Use this state to avoid clicking on the button again.
  const [isDuplicating, setIsDuplicating] = useState(false)

  const isAllowedTestSets = useHasTestSetsFeature()

  const {
    id: usabilityTestId,
    name: usabilityTestName,
    variation_set: variationSet,
  } = usabilityTest

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { isValid, isSubmitting, errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(DuplicateUsabilityStudySchema),
    defaultValues: {
      duplicateTestName: usabilityTestName,
      selectedProject: usabilityTest.project
        ? usabilityTest.project.id.replaceAll(/[^0-9a-f]+/gi, "")
        : undefined,
      createInVariation: variationSet ? "true" : "false",
      usabilityVariationSetName: variationSet?.name ?? "",
    },
  })

  const { mutateAsync: createProject } = useCreateProject()
  const { mutateAsync: createVariationSet } = useCreateVariationSet({})
  const { mutateAsync: duplicateStudy } = useDuplicateUsabilityTest({
    onSuccess: () => {
      void queryClient.invalidateQueries(["api", "usability_tests"])
    },
  })

  const onSubmit: SubmitHandler<DuplicateUsabilityStudy> = async (values) => {
    setIsDuplicating(true)

    const options: DuplicateTestOptions = {
      name: values.duplicateTestName,
      project_id: "",
    }

    try {
      // If they are adding a new project (string name instead of UUID), first create it via the API
      let createNewProject = false
      if (values.selectedProject?.match(/^[0-9a-f]{32}$/)) {
        options.project_id = values.selectedProject
      } else if (values.selectedProject) {
        createNewProject = true
        const newProject = await createProject({
          body: {
            name: values.selectedProject,
          },
        })
        options.project_id = newProject.id
      }

      if (values.createInVariation === "true") {
        if (variationSet) {
          options.test_set_id = variationSet.id
        } else {
          // If they are adding a new variation set, first create it via the API
          const newVariationSetData = await createVariationSet({
            body: {
              name: values.usabilityVariationSetName!,
              usability_test_ids: [usabilityTestId],
            },
          })

          options.test_set_id = newVariationSetData.test_set.id
        }
      }

      const data = await duplicateStudy({
        pathParams: { usabilityTestId: usabilityTestId.toString() },
        body: options,
      })
      trackTestCreated({
        ...usabilityTest,
        tests_by_creator_count: data.tests_by_creator_count,
        test_template_name: data.test_template_name,
        has_test_logic: data.has_test_logic,
        duplicate: true,
      })

      // If we invalidate projects query in the `onSuccess` of `useCreateProject` as usual,
      // it won't count the newly duplicated test as the duplicate request occurs after it.
      // Thus, we invalidate the projects query at the end.
      if (createNewProject)
        await queryClient.invalidateQueries(["api", "projects"])

      toast({
        title: "Test duplicated",
        description: `Duplicated test "${values.duplicateTestName}"`,
        status: "success",
        duration: 3000,
      })

      navigate(`/tests/${data.unique_id}/edit`)
      onClose()
    } catch (error) {
      setIsDuplicating(false)
      toast({
        title: "Test duplicate failed",
        status: "error",
        duration: 3000,
      })
      reportErrorToSentry(error)
    }
  }

  const createInVariationValue = watch("createInVariation")

  const isVariationSetAllowed = !isExternalStudy(usabilityTest)

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Duplicate “{usabilityTestName}”</ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing={4}>
              <FormControl isInvalid={!!errors.duplicateTestName}>
                <FormLabel>New test name</FormLabel>
                <Input
                  {...register("duplicateTestName")}
                  autoFocus
                  type="text"
                  placeholder="e.g. Homepage layout"
                />
                {errors.duplicateTestName && (
                  <FormErrorMessage>
                    {errors.duplicateTestName.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.selectedProject}>
                <FormLabel>Save to project:</FormLabel>
                <Controller
                  name="selectedProject"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <ProjectSelector
                      value={value ?? null}
                      onChange={(projectId) => onChange(String(projectId))}
                    />
                  )}
                />
                {errors.selectedProject && (
                  <FormErrorMessage>
                    {errors.selectedProject.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              {isVariationSetAllowed && (
                <>
                  <FormControl>
                    <Text>
                      Do you want the new test to be added as a variation?
                    </Text>

                    <RadioGroup value={createInVariationValue} mt="2">
                      <Stack direction="column">
                        {variationSet?.name === undefined ? (
                          isAllowedTestSets ? (
                            <Radio
                              {...register("createInVariation")}
                              value="true"
                            >
                              Yes – create a new variation set
                            </Radio>
                          ) : (
                            <Tooltip
                              label="Run the same test on different versions of a design, not available on Free plan. Upgrade to Basic or Pro plan."
                              placement="top"
                              fontSize="xs"
                              shouldWrapChildren
                            >
                              <Radio
                                {...register("createInVariation")}
                                value="true"
                                isDisabled
                              >
                                Yes – create a new variation set{" "}
                                <ProCrownIcon
                                  transform="translateY(-1px)"
                                  fontSize={"xs"}
                                />
                              </Radio>
                            </Tooltip>
                          )
                        ) : (
                          <Radio
                            {...register("createInVariation")}
                            value="true"
                          >
                            Yes – make it a variation in &quot;
                            {variationSet.name}
                            &quot;
                          </Radio>
                        )}
                        <Radio {...register("createInVariation")} value="false">
                          No – make it a standalone test
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                  {!variationSet && createInVariationValue === "true" && (
                    <FormControl isInvalid={!!errors.usabilityVariationSetName}>
                      <FormLabel>Variation set name</FormLabel>
                      <Input
                        {...register("usabilityVariationSetName")}
                        placeholder="e.g. Homepage layout"
                      />
                      {errors.usabilityVariationSetName && (
                        <FormErrorMessage>
                          {errors.usabilityVariationSetName.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button
                variant="outline"
                onClick={onClose}
                isDisabled={isSubmitting || isDuplicating}
              >
                Cancel
              </Button>
              <SubmitButton
                isDisabled={!isValid}
                isLoading={isSubmitting || isDuplicating}
                loadingAction="Duplicating"
              >
                Duplicate
              </SubmitButton>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
