import classNames from "classnames"
import React from "react"

import styles from "Components/hitzone-map/hitzone-map.module.scss"
import { Theme } from "Components/hitzone/hitzone"
import { NumberedHitzone } from "Components/hitzone/numbered-hitzone"
import { ScreenshotHitzone } from "Types"

type Props = {
  readonly className?: string
  readonly hitzones: ReadonlyArray<ScreenshotHitzone>
} & (
  | {
      // If `true` the `filterHitzoneIds` prop _must_ be provided.
      readonly isFilteringByClickSelection: boolean
      readonly filterHitzoneIds: ReadonlyArray<number>
    }
  | {
      // If `false` the `filterHitzoneIds` prop _may_ be provided.
      readonly isFilteringByClickSelection?: false
      readonly filterHitzoneIds?: ReadonlyArray<number>
    }
)

export const HitzoneMap = (props: Props) => {
  const {
    className,
    filterHitzoneIds,
    hitzones,
    isFilteringByClickSelection = false,
  } = props
  const isFiltering =
    isFilteringByClickSelection && filterHitzoneIds!.length > 0
  const hitzoneElements = hitzones.map((hitzone, index) => (
    // Key by index - hitzones are not necessarily persisted yet.
    <NumberedHitzone
      key={index}
      hitzone={hitzone}
      displayNumber={index + 1}
      isFiltered={isFiltering && !filterHitzoneIds!.includes(hitzone.id)}
      theme={Theme.Hitzone}
    />
  ))

  return (
    <ul className={classNames(className, styles.hitzoneMap)}>
      {hitzoneElements}
    </ul>
  )
}
