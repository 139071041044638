import { Flex, Spacer, Stack } from "@chakra-ui/react"
import React from "react"

import { AdminIpAddressContextProvider } from "./AdminIpAddressContext"
import { AdminIpAddressFraudStatus } from "./AdminIpAddressFraudStatus"
import { AdminIpAddressInfo } from "./AdminIpAddressInfo"

interface Props {
  userCountry: string
  signupIp: string | null
  currentIp: string | null
  lastIp: string | null
  signupDate: string
  currentDate: string
  lastDate: string
  recentResponses: [string | null, string, string | null][] // ip_address, created_at, geolocated_country
  highRiskCountryCodes: string[]
}

export const AdminRiskFactors: React.FC<Props> = ({
  userCountry,
  signupIp,
  currentIp,
  lastIp,
  signupDate,
  currentDate,
  lastDate,
  recentResponses,
  highRiskCountryCodes,
}) => {
  return (
    <AdminIpAddressContextProvider>
      <table className="AdminTable AdminTable--attributes">
        <tbody>
          <tr>
            <td>At sign up</td>
            <td>
              <AdminIpAddressInfo
                userCountry={userCountry}
                date={signupDate}
                ipAddress={signupIp}
                highRiskCountryCodes={highRiskCountryCodes}
              />
            </td>
            <td className="AdminTable-tag">
              <AdminIpAddressFraudStatus
                ipAddress={signupIp}
                date={signupDate}
                userCountry={userCountry}
                highRiskCountryCodes={highRiskCountryCodes}
              />
            </td>
          </tr>

          <tr>
            <td>Current sign in</td>
            <td>
              <AdminIpAddressInfo
                userCountry={userCountry}
                date={currentDate}
                ipAddress={currentIp}
                highRiskCountryCodes={highRiskCountryCodes}
              />
            </td>
            <td className="AdminTable-tag">
              <AdminIpAddressFraudStatus
                ipAddress={currentIp}
                date={currentDate}
                userCountry={userCountry}
                highRiskCountryCodes={highRiskCountryCodes}
              />
            </td>
          </tr>
          <tr>
            <td>Previous sign in</td>
            <td>
              <AdminIpAddressInfo
                userCountry={userCountry}
                date={lastDate}
                ipAddress={lastIp}
                highRiskCountryCodes={highRiskCountryCodes}
              />
            </td>
            <td className="AdminTable-tag">
              <AdminIpAddressFraudStatus
                ipAddress={lastIp}
                date={lastDate}
                userCountry={userCountry}
                highRiskCountryCodes={highRiskCountryCodes}
              />
            </td>
          </tr>
          <tr>
            <td>Recent responses</td>
            <td colSpan={2}>
              <Stack maxH="120px" overflowY="auto">
                {recentResponses.map(([ip, date, geolocatedCountry]) => (
                  <Flex key={`${ip ?? "null"}-${date}`} justify="space-between">
                    <AdminIpAddressInfo
                      showFraudIndicator
                      userCountry={userCountry}
                      responseCountry={geolocatedCountry}
                      key={date}
                      date={date}
                      ipAddress={ip}
                      highRiskCountryCodes={highRiskCountryCodes}
                    />
                    <Spacer w={2} />
                    <AdminIpAddressFraudStatus
                      ipAddress={ip}
                      date={date}
                      userCountry={userCountry}
                      highRiskCountryCodes={highRiskCountryCodes}
                    />
                  </Flex>
                ))}
              </Stack>
            </td>
          </tr>
        </tbody>
      </table>
    </AdminIpAddressContextProvider>
  )
}
