import { Flex, FlexProps } from "@chakra-ui/react"
import React from "react"

export const ScreenshotOverlayBackdrop: React.FC<
  React.PropsWithChildren<FlexProps>
> = (flexProps) => {
  return (
    <Flex
      position="absolute"
      top={0}
      right={0}
      left={0}
      bottom={0}
      backgroundColor="rgba(0, 0, 0, 0.7)"
      alignItems="center"
      justifyContent="center"
      {...flexProps}
    />
  )
}
