import { Alert, AlertIcon, Stack } from "@chakra-ui/react"
import { isEqual } from "lodash"
import React from "react"
import { DragDropContext, DropResult } from "react-beautiful-dnd"
import { useDispatch, useSelector } from "react-redux"
import { FieldArray, WrappedFieldArrayProps } from "redux-form"

import { Dispatch } from "Redux/app-store"
import {
  moveCard,
  moveCategory,
} from "Redux/reducers/test-builder-form/action-creators/card-sort"
import { moveQuestionOption } from "Redux/reducers/test-builder-form/action-creators/options"
import { moveQuestion } from "Redux/reducers/test-builder-form/action-creators/questions"
import { UsabilityTestSection } from "Types"
import { reportErrorToSentry } from "Utilities/error"
import { getSectionSummaries } from "~/application/javascripts/redux/reducers/test-builder-form/selectors/sections"

import { AddSection } from "../AddSection"

import { AlertTriangleSolidIcon } from "Shared/icons/untitled-ui/AlertTriangleSolidIcon"
import { Section } from "./Section"

type TestFormSectionsProps = WrappedFieldArrayProps<
  Readonly<UsabilityTestSection>
>
const TestFormSections: React.FC<
  React.PropsWithChildren<TestFormSectionsProps>
> = React.memo(
  ({ meta }) => {
    const sectionSummaries = useSelector(getSectionSummaries, (left, right) =>
      isEqual(left, right)
    )
    const dispatch: Dispatch = useDispatch()
    const handleDragEnd = (result: DropResult) => {
      // Check if it was dropped outside of the list
      if (result.destination == null) {
        return
      }
      // The type is used for conditionally allowing dropping.
      // So the type may have a group appended to it.
      const type = result.type.split(":")[0]
      // Droppable IDs are the path of the item in the test builder redux form.
      const fromPath = result.source.droppableId.split(":")[0]
      const toPath = result.destination.droppableId.split(":")[0]
      switch (type) {
        case "question": {
          dispatch(
            moveQuestion(
              fromPath,
              toPath,
              result.source.index,
              result.destination.index
            )
          )
          break
        }
        case "option": {
          dispatch(
            moveQuestionOption(
              // Options can only be dragged within their question so source and dest are the same.
              fromPath,
              result.source.index,
              result.destination.index
            )
          )
          break
        }
        case "card": {
          dispatch(
            moveCard(fromPath, result.source.index, result.destination.index)
          )
          break
        }
        case "category": {
          dispatch(
            moveCategory(
              fromPath,
              result.source.index,
              result.destination.index
            )
          )
          break
        }
        default:
          reportErrorToSentry(new TypeError(`Invalid droppableType: "${type}"`))
      }
    }

    const showError = meta.invalid && meta.submitFailed

    return (
      <Stack spacing={8}>
        {showError && (
          <Alert status="error">
            <AlertIcon as={AlertTriangleSolidIcon} boxSize={6} />
            {meta.error}
          </Alert>
        )}
        <AddSection insertionIndex={0} />
        <DragDropContext onDragEnd={handleDragEnd}>
          {sectionSummaries.map((summary) => {
            return [
              <Section key={summary.sectionClientId} {...summary} />,
              <AddSection
                key={`${summary.sectionClientId}_addSection`}
                insertionIndex={summary.sectionIndex + 1}
              />,
            ]
          })}
        </DragDropContext>
      </Stack>
    )
  },
  (prevProps, nextProps) => {
    // Redux form inserts lots of unused and constantly changing props
    // To prevent unnecessary re-renders, ignore everything except the
    // meta props we're actually using and ensure they actually changed
    return (
      prevProps.meta.error === nextProps.meta.error &&
      prevProps.meta.submitFailed === nextProps.meta.submitFailed &&
      prevProps.meta.invalid === nextProps.meta.invalid
    )
  }
)

TestFormSections.displayName = "TestFormSections"

export const TestFormSectionsField = () => (
  <FieldArray name="sections" component={TestFormSections} props={{}} />
)
