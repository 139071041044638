import { FC } from "react"
import React from "react"
import { useSelector } from "react-redux"

import { ScreenshotClickResultsOld as Impl } from "Components/test-results/screenshot-click-results/screenshot-click-results-old"
import { State } from "Redux/app-store"
import { getScreenshotWithId } from "Redux/reducers/screenshots/selectors"
import {
  DeviceFrame,
  ImageScreenshot,
  Screenshot,
  UsabilityTestSectionScreenshot,
} from "Types"
import { useSectionContext } from "UsabilityHub/contexts"

import { createHitzoneFilterCategory } from "../filters/create-filters/hitzone-filter"
import {
  getFiltersWithCategory,
  getFiltersWithType,
} from "../filters/get-filters/get-filters-with"
import { useScreenshotClientHitzones } from "../hooks/use-screenshot-zones"
import { useSectionScreenshotClicks } from "../hooks/use-section-screenshot-clicks"
import { useTestResultsFilters } from "../hooks/use-test-results-filters"

interface Props {
  deviceFrame: Readonly<DeviceFrame> | null
  sectionScreenshot: Readonly<UsabilityTestSectionScreenshot>
}

export const ScreenshotClickResults: FC<Props> = ({
  sectionScreenshot,
  deviceFrame,
}) => {
  const filters = useTestResultsFilters()
  const { section } = useSectionContext()
  const screenshotID = sectionScreenshot.screenshot_id
  const screenshotSectionID = sectionScreenshot.id
  const {
    screenshotZones,
    addScreenshotZone,
    changeScreenshotZone,
    removeScreenshotZone,
  } = useScreenshotClientHitzones(screenshotSectionID)
  const screenshot = useSelector<State, Screenshot>((state) =>
    getScreenshotWithId(state, screenshotID)
  )
  const clicks = useSectionScreenshotClicks(screenshotSectionID)

  const screenshotFilters = getFiltersWithCategory(
    filters,
    createHitzoneFilterCategory(section.id, screenshotSectionID)
  )
  const filterHitzoneIds = getFiltersWithType(
    screenshotFilters,
    "answer/hitzone"
  ).map((filter) => filter.value)

  const filterClientHitzoneIds = getFiltersWithType(
    screenshotFilters,
    "answer/client-hitzone"
  ).map((filter) => filter.value)

  return (
    <Impl
      hitzones={sectionScreenshot.screenshot_hitzones}
      constrainWidthToDeviceFrame={deviceFrame}
      clicks={clicks}
      clickSelections={screenshotZones}
      filterHitzoneIds={filterHitzoneIds}
      filterClickSelectionClientIds={filterClientHitzoneIds}
      screenshot={screenshot as ImageScreenshot}
      sectionScreenshot={sectionScreenshot}
      onChangeClickSelection={changeScreenshotZone}
      onCreateClickSelection={addScreenshotZone}
      onRemoveClickSelection={removeScreenshotZone}
    />
  )
}
