import { ClientId, Screenshot, TestLogicStatement, UsabilityTest } from "Types"
import { getAnswers } from "Utilities/test-logic"
import { isTestLogicTargetOption } from "Utilities/test-logic-statement"
import {
  getFieldWithClientIdIn,
  isFieldBeforeField,
  isFieldPresent,
} from "Utilities/usability-test-field"

export const validateTestLogicStatement = (
  testLogicStatement: TestLogicStatement,
  fieldId: ClientId,
  usabilityTest: UsabilityTest,
  screenshots: readonly Screenshot[]
): Record<string, unknown> => ({
  _targetClientId: validateTestLogicTarget(
    testLogicStatement._targetClientId,
    fieldId,
    usabilityTest
  ),
  value: validateTestLogicValue(
    testLogicStatement.values,
    testLogicStatement._targetClientId,
    usabilityTest,
    screenshots
  ),
})

// ts-prune-ignore-next used in test
export const validateTestLogicTarget = (
  targetId: ClientId | null,
  fieldId: ClientId,
  usabilityTest: UsabilityTest
): string | undefined => {
  if (!targetId) {
    return "Please select a target"
  }
  if (usabilityTest.sections) {
    if (!isFieldPresent(usabilityTest.sections, targetId)) {
      return "The target for this logic condition was deleted, please select a new target or turn logic off."
    }
    if (!isFieldBeforeField(usabilityTest.sections, targetId, fieldId)) {
      return "The target needs to come before this condition, please select a new target or turn logic off."
    }
    const field = getFieldWithClientIdIn(targetId, usabilityTest.sections)
    if (field && !isTestLogicTargetOption(field)) {
      return "The target for this logic condition was changed to an unsupported question type. Please select a compatible target, change the type or remove logic."
    }
  }
}

// ts-prune-ignore-next used in test
export const validateTestLogicValue = (
  values: string[],
  targetId: ClientId | null,
  usabilityTest: UsabilityTest,
  screenshots: readonly Screenshot[]
): string | undefined => {
  // No target selected means don't check the answers yet.
  if (!targetId) {
    return
  }
  if (!values?.length) {
    return "Please select an answer"
  }
  const target = getFieldWithClientIdIn(targetId, usabilityTest.sections)
  // No target found means something went wrong, but the target validator will pick it up.
  if (!target) {
    return
  }
  const answers = getAnswers(target, screenshots)
  if (values.some((v) => !answers.includes(v))) {
    return "Please select an answer"
  }
}
