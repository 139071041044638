import { createSelector } from "reselect"

import {
  getFormSyncErrors,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { UsabilityTest, UsabilityTestSection } from "Types"
import { getFullSectionTitle } from "Utilities/get-full-section-title"
import { flattenNested } from "Utilities/object"
import { sectionContainsTestLogic } from "~/application/javascripts/utilities/test-logic-statement"

export const getSections = createSelector(
  getFormValues,
  (values: Partial<UsabilityTest>): ReadonlyArray<UsabilityTestSection> => {
    return (values && values.sections) || []
  }
)

export const getSectionSummaries = createSelector(getSections, (sections) =>
  sections.map((section, index) => ({
    sectionType: section.type,
    sectionClientId: section._clientId,
    sectionIndex: index,
    sectionHasLogic: sectionContainsTestLogic(section),
    sectionHasRecording: Object.values(
      section.recording_attributes || {}
    ).includes(true),
  }))
)

export const getSectionByClientId = (clientId: string) =>
  createSelector([getSections], (sections) => {
    const section = sections.find((section) => section._clientId === clientId)
    if (!section) {
      throw Error(`No section with clientId: ${clientId}`)
    }
    return section
  })

export const getActiveSectionCount = createSelector(
  getSections,
  (sections) => sections.filter((section) => !section.archived).length
)

// Check if any of the section’s nested fields have errors
// NB: This does not cover required fields
export const getSectionValid = (sectionIndex: number) =>
  createSelector(getFormSyncErrors, (errors) => {
    if (errors.sections) {
      const sectionErrors = flattenNested(errors.sections[sectionIndex]) // Flatten to array of errors
        .filter(Boolean) // Filter out undefined

      return sectionErrors.length === 0
    } else {
      return false
    }
  })

export const getSectionTitleBySectionClientId = (
  clientId: string,
  sectionIndex: number
) =>
  createSelector(getSectionByClientId(clientId), (section) => {
    return getFullSectionTitle(section, sectionIndex)
  })

export const hasRecording = createSelector(
  getSections,
  (sections: ReadonlyArray<UsabilityTestSection>) =>
    sections.some((section) =>
      Object.values(section.recording_attributes || {}).includes(true)
    )
)
