import { AnswerTagFilter } from "../filters/answer-tags"

import { generateFilterId } from "./generate-filter-id"

export function createAnswerTagFilterCategory(
  sectionId: number,
  questionId: number
): AnswerTagFilter["category"] {
  return `answer/section:${sectionId}/question:${questionId}/tag`
}

export function createAnswerTagFilter(
  sectionId: number,
  questionId: number,
  tagId: number
): AnswerTagFilter {
  return {
    id: generateFilterId(),
    category: createAnswerTagFilterCategory(sectionId, questionId),
    type: "answer/tag",
    value: tagId,
  }
}
