import {
  Button,
  Flex,
  Icon,
  ListItem,
  Spacer,
  UnorderedList,
  VStack,
} from "@chakra-ui/react"
import { ExclamationIcon } from "@heroicons/react/outline"
import React from "react"

import { ThemedButton } from "Components/button/themed-button"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { PrototypeType } from "JavaScripts/types/figma-file-flow"
import { useTranslate } from "Shared/hooks/useTranslate"
import {
  ResponseAnswer,
  UsabilityTestSection,
  UsabilityTestSectionQuestion,
} from "Types"

import { UsePrototypeReturn } from "../usePrototypeTask"

import { PrimaryInstructions, SecondaryInstructions } from "./Instructions"
import { TaskFlowOrFreeRoamButton } from "./TaskFlowOrFreeRoamButton"
import { TaskFlowOrFreeRoamInstructionsContent } from "./TaskFlowOrFreeRoamInstructions"
import {
  PrototypeTaskPhase,
  phasesWithReportButtonVisible,
} from "./usePrototypeTaskPhases"

interface InstructionsContentProps {
  prototypeTask: UsePrototypeReturn
  openSkipModal?: () => void
  section: UsabilityTestSection
  prototypeTaskPhase: PrototypeTaskPhase
  prototypeType: PrototypeType
  advanceToNextPhase: () => void
  question?: UsabilityTestSectionQuestion
  handleQuestionSubmit: (
    answer: Omit<ResponseAnswer, "id" | "response_id">
  ) => void
}

export const InstructionsContent: React.FC<InstructionsContentProps> = ({
  prototypeTask,
  openSkipModal,
  section,
  prototypeTaskPhase,
  advanceToNextPhase,
  prototypeType,
  question,
  handleQuestionSubmit,
}) => {
  const translate = useTranslate()
  const taskInstructions = section.text

  const isReportButtonVisible =
    openSkipModal !== undefined &&
    prototypeTaskPhase &&
    phasesWithReportButtonVisible.includes(prototypeTaskPhase)

  const instructionText =
    prototypeType === "task_flow"
      ? translate(
          "test.instructions.prototype_task.task_flow.start_instructions"
        )
      : translate(
          "test.instructions.prototype_task.free_roam.start_instructions"
        )
  const instructionList = instructionText
    .trim()
    .split(/\s*\n\s*/g)
    .map((instructionPoint: string, index: number) => (
      <ListItem key={index}>{instructionPoint}</ListItem>
    ))
  return (
    <>
      <VStack
        alignItems="flex-start"
        maxH="calc(100vh - 12rem)"
        overflowY="auto"
        overflowX="auto"
      >
        {!prototypeTask.isTaskStarted ? (
          <>
            <PrimaryInstructions>
              <DisplayBlockMarkdownText text={taskInstructions} />
            </PrimaryInstructions>
            <SecondaryInstructions>
              <UnorderedList marginStart={4}>{instructionList}</UnorderedList>
            </SecondaryInstructions>
          </>
        ) : (
          <TaskFlowOrFreeRoamInstructionsContent
            section={section}
            prototypeType={prototypeType}
            prototypeTaskPhase={prototypeTaskPhase}
            taskInstructions={taskInstructions}
            question={question}
            handleQuestionSubmit={handleQuestionSubmit}
          />
        )}
        <Spacer />
      </VStack>
      <Spacer height={4} />
      <Flex justifyContent="space-between" alignItems="center" w="full">
        {!prototypeTask.isTaskStarted && (
          <ThemedButton
            isLoading={!prototypeTask.isTaskLoaded}
            loadingText={translate("test.buttons.task_loading")}
            onClick={prototypeTask.startTask}
          >
            {translate("test.buttons.start_task")}
          </ThemedButton>
        )}
        {prototypeTask.isTaskStarted && (
          <TaskFlowOrFreeRoamButton
            prototypeType={prototypeType}
            prototypeTask={prototypeTask}
            prototypeTaskPhase={prototypeTaskPhase}
            advanceToNextPhase={advanceToNextPhase}
          />
        )}
        {isReportButtonVisible && (
          <Button
            onClick={openSkipModal}
            background="none"
            leftIcon={<Icon as={ExclamationIcon} />}
          >
            Report
          </Button>
        )}
      </Flex>
    </>
  )
}
