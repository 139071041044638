import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Spacer,
  useToast,
} from "@chakra-ui/react"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import React, { useEffect, useState } from "react"
import {
  useDenyUnarchiveRequest,
  useTeamMemberUnarchiveRequests,
} from "~/api/generated/usabilityhub-components"
import { TeamMember } from "~/api/generated/usabilityhubSchemas"

export const TeamMemberUnarchiveRequests: React.FC<
  React.PropsWithChildren<{ setJumpToUserId: (id: number) => void }>
> = ({ setJumpToUserId }) => {
  const archivedTeamMembers = useTeamMembers({ onlyRoles: ["archived"] })
  const toast = useToast()
  const [
    teamMembersWithUnarchiveRequests,
    setTeamMembersWithUnarchiveRequests,
  ] = useState<TeamMember[]>([])

  const { data, isError } = useTeamMemberUnarchiveRequests({})

  useEffect(() => {
    if (!data) {
      return
    }
    setTeamMembersWithUnarchiveRequests(
      archivedTeamMembers.filter((user) =>
        data.team_member_unarchive_request_user_ids.includes(user.id)
      )
    )
  }, [data])

  useEffect(() => {
    if (!isError) {
      return
    }
    toast({
      title: "Unable to fetch team member unarchive requests",
      status: "error",
    })
  }, [isError])

  return (
    <>
      {teamMembersWithUnarchiveRequests.map(({ id, name, email }) => (
        <Request
          key={id}
          userId={id}
          name={name}
          email={email}
          setJumpToUserId={setJumpToUserId}
          removeRequest={() => {
            setTeamMembersWithUnarchiveRequests((users) => {
              return users.filter((u) => u.id !== id)
            })
          }}
        />
      ))}
    </>
  )
}

interface RequestProps {
  userId: number
  name: string
  email: string
  setJumpToUserId: (id: number) => void
  removeRequest: () => void
}

const Request: React.FC<React.PropsWithChildren<RequestProps>> = ({
  userId,
  name,
  email,
  setJumpToUserId,
  removeRequest,
}) => {
  const toast = useToast()
  const { mutate: postDenyUnarchiveRequest } = useDenyUnarchiveRequest({
    onSuccess: () => {
      removeRequest()
    },
    onError: () => {
      toast({
        title: "Unable to deny request",
        status: "error",
      })
    },
  })

  const jumpToUser = () => {
    setJumpToUserId(userId)
  }

  return (
    <Alert status="warning" mt={-2} mb={4} borderWidth={1} borderColor="orange">
      <AlertIcon />
      <AlertTitle>Access request</AlertTitle>
      <AlertDescription>
        {name} ({email}) has requested that their account be un-archived. You
        can do this from the list below.
        <Spacer />
        <Button
          flexShrink={0}
          marginLeft={2}
          isDisabled={false}
          colorScheme="green"
          textDecoration="none"
          onClick={jumpToUser}
        >
          View user
        </Button>
        <Button
          flexShrink={0}
          marginLeft={4}
          isDisabled={false}
          colorScheme="gray"
          variant="link"
          textDecoration="none"
          onClick={() => {
            postDenyUnarchiveRequest({ body: { user_id: userId } })
          }}
        >
          Deny
        </Button>
      </AlertDescription>
    </Alert>
  )
}
