import React from "react"

import { Hitzone, Props as HitzoneProps } from "Components/hitzone/hitzone"
import styles from "Components/hitzone/hitzone.module.scss"

interface Props extends HitzoneProps {
  displayNumber: number
}

export const NumberedHitzone: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { displayNumber, ...rest } = props
  return (
    <Hitzone {...rest}>
      <div className={`${styles.handle} ${styles.hasChildren}`}>
        {displayNumber}
      </div>
    </Hitzone>
  )
}
