function handleUnload(event: BeforeUnloadEvent) {
  const message = "Changes you made will not be saved"
  event.returnValue = message
  return message
}

export function beforeUnloadHandler() {
  function preventUnload() {
    window.addEventListener("beforeunload", handleUnload)
  }

  function permitUnload() {
    window.removeEventListener("beforeunload", handleUnload)
  }

  return [preventUnload, permitUnload]
}
