import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { Role } from "Types"
import { useRefreshCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useRefreshTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { getRoleOption } from "Utilities/role"
import { Form, Formik, useFormikContext } from "formik"
import React from "react"
import { useUpdateUser } from "~/api/generated/usabilityhub-components"
import { TeamMember } from "~/api/generated/usabilityhubSchemas"
import { ChangeUserRoleModalContent } from "./ChangeUserRoleModalContent"
import { UserRoleSelect } from "./UserRoleSelect"

type ChangeUserRoleForm = React.FC<
  React.PropsWithChildren<{
    hasPaidSeatAvailable: boolean
    user: TeamMember
    isDisabled: boolean
    isDeletable: boolean
  }>
>

export const ChangeUserRoleForm: ChangeUserRoleForm = ({
  hasPaidSeatAvailable,
  user,
  isDisabled,
  isDeletable,
}) => {
  const toast = useToast()
  const refreshCurrentAccount = useRefreshCurrentAccount()
  const refreshTeamMembers = useRefreshTeamMembers()
  const {
    isOpen: isChangeUserRoleModalOpen,
    onClose: closeChangeUserRoleModal,
    onOpen: openChangeUserRoleModal,
  } = useDisclosure()

  const { mutate: updateUser, isLoading } = useUpdateUser({
    onSuccess: async (data) => {
      toast({
        title: data.message,
        status: "success",
      })

      refreshTeamMembers()
      refreshCurrentAccount()

      closeChangeUserRoleModal()
    },
    onError: (error) => {
      toast({
        title: error.payload.message,
        status: "error",
      })
    },
  })

  return (
    <Formik
      initialValues={{ user: { role: user.role } }}
      onSubmit={openChangeUserRoleModal}
    >
      {({ values, resetForm }) => {
        const roleOption = getRoleOption(values.user.role as Role)
        const closeModalAndReset = () => {
          closeChangeUserRoleModal()
          resetForm()
        }

        return (
          <>
            <Form>
              <UserRoleSelect
                name="user.role"
                isDisabled={isDisabled}
                user={user}
                hasPaidSeatAvailable={hasPaidSeatAvailable}
                isDeletable={isDeletable}
              />
              <AutoSubmit />
            </Form>
            <Modal
              isOpen={isChangeUserRoleModalOpen}
              onClose={closeModalAndReset}
              closeOnOverlayClick={false}
            >
              <ModalOverlay>
                <ModalContent borderRadius="md">
                  <ModalHeader>Change to {roleOption.name}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Stack>
                      <Text>{roleOption.description}</Text>
                      <ChangeUserRoleModalContent
                        oldRole={user.role as Role}
                        newRole={values.user.role as Role}
                        hasPaidSeatAvailable={hasPaidSeatAvailable}
                        userEmail={user.email}
                      />
                    </Stack>
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={closeModalAndReset} mr={3}>
                      Cancel
                    </Button>
                    <Button
                      onClick={async () => {
                        updateUser({
                          pathParams: {
                            userId: user.id,
                          },
                          body: {
                            role: values.user.role,
                          },
                        })
                      }}
                      colorScheme="brand.primary"
                      isLoading={isLoading}
                    >
                      Change role
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </ModalOverlay>
            </Modal>
          </>
        )
      }}
    </Formik>
  )
}

const AutoSubmit = () => {
  const formik = useFormikContext()
  React.useEffect(() => {
    if (formik.dirty) {
      void formik.submitForm()
    }
  }, [formik.values])
  return null
}
