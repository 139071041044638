import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const DesignSurveyIcon = createIcon({
  displayName: "DesignSurveyIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-3.5-2.25A1.75 1.75 0 0010.75 4h-5.5A1.75 1.75 0 003.5 5.75v4.5c0 .966.784 1.75 1.75 1.75h5.5a1.75 1.75 0 001.75-1.75v-4.5zm-7.75 0a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5v3l-1.525-.508a1 1 0 00-1.149.394l-1.41 2.114H5.25a.5.5 0 01-.5-.5v-4.5zM7 8.5a1 1 0 100-2 1 1 0 000 2z"
    />
  ),
})
