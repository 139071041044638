import React, { HTMLAttributes } from "react"

import styles from "Components/click-map/click-map.module.scss"
import { Omit } from "Types"

type Props = Omit<HTMLAttributes<HTMLDivElement>, "className">

export const ClickMapContainerOld = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => <div ref={ref} className={styles.container} {...props} />
)

ClickMapContainerOld.displayName = "ClickMapContainer"
