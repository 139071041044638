import { PrototypeNodeFilter } from "../filters/prototype-node"

import { generateFilterId } from "./generate-filter-id"

function createPrototypeNodeFilterCategory(
  sectionId: number
): PrototypeNodeFilter["category"] {
  return `answer/section:${sectionId}/prototype-node`
}

export function createPrototypeNodeFilter(
  sectionId: number,
  nodeId: string
): PrototypeNodeFilter {
  return {
    id: generateFilterId(),
    category: createPrototypeNodeFilterCategory(sectionId),
    type: "answer/prototype-node",
    value: nodeId,
  }
}
