import { otherSymbol } from "../question-results/answer-counts/helpers"

import { useExactAnswersFilter } from "./use-exact-answers-filter"
import { useOtherAnswersFilter } from "./use-other-answers-filter"

export function useAnswersFilter(
  sectionId: number,
  questionId: number,
  answer: string
) {
  const otherAnswerHook = useOtherAnswersFilter(sectionId, questionId)
  const exactAnswerHook = useExactAnswersFilter(sectionId, questionId, answer)

  if (answer === otherSymbol) {
    return otherAnswerHook
  }

  return exactAnswerHook
}
