import {
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { Fields, WrappedFieldsProps } from "redux-form"

import { useQuestionContext } from "UsabilityHub/contexts"

const WrappedCheckboxLimitFields: React.FC<
  React.PropsWithChildren<WrappedFieldsProps>
> = ({ min_selected_options, max_selected_options }) => {
  const { question } = useQuestionContext()
  const minOptions = question.min_selected_options
  const maxOptions = question.max_selected_options
  const optionsCount = question.multiple_choice_options.length
  const isChoiceRequired = question.required
  const canChooseOther = question.has_other_option

  const minUpperBound = maxOptions === null ? optionsCount : maxOptions

  const minChoiceLimits = {
    min: isChoiceRequired ? 1 : 0,
    max: minUpperBound,
  }

  const maxChoiceLimits = {
    min: Math.max(1, minOptions ?? 1),
    max: canChooseOther ? Infinity : optionsCount,
  }

  return (
    <HStack height={5}>
      <Text as="label" fontSize="sm">
        Selection limits: From
      </Text>
      <NumberInput
        {...minChoiceLimits}
        {...min_selected_options.input}
        width={24}
      >
        <NumberInputField placeholder={minChoiceLimits.min.toString()} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Text as="label" fontSize="sm">
        to
      </Text>
      <NumberInput
        {...maxChoiceLimits}
        {...max_selected_options.input}
        width={24}
      >
        <NumberInputField placeholder="Any" />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </HStack>
  )
}

export const CheckboxLimitFields: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <Fields
      names={["min_selected_options", "max_selected_options"]}
      component={WrappedCheckboxLimitFields}
      parse={(value: string) => {
        // Transform empty strings from number fields to null values
        // or numbers, which is what redux is expecting
        return value === "" ? null : Number(value)
      }}
    />
  )
}
