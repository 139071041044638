import { Box, Flex, Input, useToast } from "@chakra-ui/react"
import { Alert, Button, Heading } from "DesignSystem/components"
import { RecruitPageUsabilityTest } from "UsabilityHub/hooks/useUsabilityTestRecruit"
import React, { useState } from "react"
import { useAssignUsabilityTest } from "~/api/generated/usabilityhub-components"

type ManualAssignmentForm = HTMLFormElement & { email: HTMLInputElement }

type Props = {
  usabilityTest: RecruitPageUsabilityTest
}

export const ManualAssignment: React.FC<Props> = ({ usabilityTest }) => {
  const { mutateAsync: assignPanelist } = useAssignUsabilityTest()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const toast = useToast()

  const submit = async (e: React.FormEvent) => {
    e.preventDefault()

    setLoading(true)
    setError(null)

    const { email } = e.target as ManualAssignmentForm

    await assignPanelist({
      pathParams: { usabilityTestId: usabilityTest.unique_id },
      body: { email: email.value },
    })
      .then((response) => {
        navigator.clipboard.writeText(response.url)
        toast({
          description: `Make sure you're logged in as ${email.value} to access the test.`,
          title: "URL copied to clipboard",
          status: "success",
        })
      })
      .catch((e) => {
        if (e.status === 404) {
          setError("Panelist not found")
        } else {
          setError(e.payload.message)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Box
      pos="relative"
      p={6}
      mb={6}
      bg="ds.background.warning.subtle.resting"
      shadow="ds.raised"
      rounded="16px"
    >
      <Flex direction="column" gap={4}>
        <Heading as="h2" textStyle="ds.display.primary">
          Manual assignment (non-production)
        </Heading>
        <Flex as="form" gap="4" onSubmit={submit}>
          <Box as="fieldset" disabled={loading || undefined} display="contents">
            <Input
              flex="1"
              type="email"
              id="email"
              placeholder="Panelist email address"
              isInvalid={!!error}
            />
            <Button type="submit" size="emphasized" variant="primary">
              Assign
            </Button>
          </Box>
        </Flex>
        {error && (
          <Alert status="warning" description={error}>
            {error}
          </Alert>
        )}
      </Flex>
    </Box>
  )
}
