import {
  HStack,
  Heading,
  IconButton,
  List,
  ListItem,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { UniqueIdentifier } from "@dnd-kit/core"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import { ChevronRightOutlineIcon } from "Shared/icons/untitled-ui/ChevronRightOutlineIcon"
import { MinusCircleOutlineIcon } from "Shared/icons/untitled-ui/MinusCircleOutlineIcon"
import { TreeNode } from "Types"
import { last } from "lodash"
import React, { Fragment, useMemo } from "react"
import { useTreeContext } from "./TreeProvider"

const path = (
  nodes: Map<UniqueIdentifier, TreeNode>,
  node: TreeNode
): TreeNode[] => {
  const parent = node.parent_id && nodes.get(node.parent_id)
  return parent ? [...path(nodes, parent), node] : [node]
}

export const CorrectPaths: React.FC = () => {
  const { tree, correctNodeIds, deselect, expand } = useTreeContext()

  const map = useMemo(
    () =>
      new Map<UniqueIdentifier, TreeNode>(tree.map((node) => [node.id, node])),
    [tree]
  )

  const paths = useMemo<TreeNode[][]>(
    () =>
      tree.flatMap((node) =>
        correctNodeIds.has(node.id) ? [path(map, node)] : []
      ),
    [map, tree, correctNodeIds]
  )

  const removeCorrectAnswer = (path: TreeNode[]) => {
    deselect(last(path)!)
  }

  const showNode = (node: TreeNode) => {
    expand(node)

    // wait for the node to be expanded before scrolling to it
    setTimeout(() => {
      document.querySelector(`[data-node-id="${node.id}"]`)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    })
  }

  return (
    <Stack spacing={2}>
      <Heading as="h4" size="xs" fontWeight="semibold">
        {`Correct answer${paths.length === 1 ? "" : "s"}`}
      </Heading>
      {paths.length ? (
        <Stack as={List} spacing={2}>
          {paths.map((path) => (
            <ListItem
              key={path.map((node) => node.id).join("-")}
              display="grid"
              gridTemplateColumns="auto 1fr auto"
              gap={2}
              rounded={6}
              pl={3}
              alignItems="start"
              sx={{
                "&:hover": {
                  bg: "bg.neutral.subtle.hover",
                  "> button": {
                    opacity: 1,
                  },
                },
              }}
              onClick={() => showNode(last(path)!)}
            >
              <CheckCircleSolidIcon
                color="icon.success"
                my="0.625rem"
                boxSize={5}
              />
              <HStack gap={1} flexWrap="wrap" alignItems="center">
                {path.map((node, i) => (
                  <Fragment key={node.id}>
                    {i > 0 && <ChevronRightOutlineIcon color="grey.dark" />}
                    <Text
                      key={node.id}
                      fontSize="sm"
                      lineHeight="1.5rem"
                      my={2}
                      sx={{
                        "&:last-child": {
                          color: "icon.success",
                          fontWeight: "medium",
                        },
                      }}
                    >
                      {node.label}
                    </Text>
                  </Fragment>
                ))}
              </HStack>
              <Tooltip label="Remove correct answer">
                <IconButton
                  variant="ghost"
                  icon={<MinusCircleOutlineIcon />}
                  aria-label="Remove correct answer"
                  opacity={0}
                  _hover={{ bg: "transparent" }}
                  _active={{ bg: "transparent" }}
                  onClick={(e) => {
                    e.stopPropagation()
                    removeCorrectAnswer(path)
                  }}
                />
              </Tooltip>
            </ListItem>
          ))}
        </Stack>
      ) : (
        <Text fontSize="sm" color="text.subtlest" textAlign="center">
          Select at least one node from your tree
        </Text>
      )}
    </Stack>
  )
}
