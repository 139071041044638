import { debounce } from "lodash"
import React, { useEffect, useState } from "react"
import { findDOMNode } from "react-dom"

import { isHidden } from "Utilities/element"

const RESIZE_DEBOUNCE_DELAY_MS = 200

interface DimensionProps {
  width: number
  height: number
}

// This is used to get the dimensions of the element that the ref is attached to.
export const useDimensions = (ref: React.RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState<DimensionProps>({
    width: 0,
    height: 0,
  })

  const updateDimensions = (element: HTMLElement) => {
    if (!isHidden(element)) {
      const width = element.offsetWidth
      const height = element.offsetHeight
      setDimensions({ width, height })
    }
  }

  const handleWindowResize = debounce(
    updateDimensions,
    RESIZE_DEBOUNCE_DELAY_MS
  )

  useEffect(() => {
    if (!ref.current) return

    const element = findDOMNode(ref.current) as HTMLElement
    const resizeFn = () => {
      handleWindowResize(element)
    }
    window.addEventListener("resize", resizeFn)
    updateDimensions(element)

    return () => window.removeEventListener("resize", resizeFn)
  }, [ref.current])

  return dimensions
}
