import { Box, BoxProps, ResponsiveValue } from "@chakra-ui/react"
import { PanInfo, motion } from "framer-motion"
import React, { useState } from "react"

type VerticalEdge = "top" | "bottom"
type HorizontalEdge = "right" | "left"
type Corner = `${VerticalEdge}_${HorizontalEdge}`

type CornerDraggableProps = BoxProps & {
  initial?: Corner
  margin: ResponsiveValue<string | number>
}

const positions = {
  top_left: {
    top: 0,
    left: 0,
  },
  top_right: {
    top: 0,
    right: 0,
  },
  bottom_left: {
    bottom: 0,
    left: 0,
  },
  bottom_right: {
    bottom: 0,
    right: 0,
  },
} as const

const MotionBox = motion(Box)

export const CornerDraggable: React.FC<CornerDraggableProps> = ({
  initial = "bottom_right",
  children,
  ...props
}) => {
  const [corner, setCorner] = useState<Corner>(initial)

  const handleDragUpdate = (_: Event, info: PanInfo) => {
    const { innerHeight, innerWidth } = window
    const halfHeight = innerHeight / 2
    const halfWidth = innerWidth / 2
    const { x, y } = info.point

    if (x < halfWidth) {
      if (y < halfHeight) {
        setCorner("top_left")
      } else {
        setCorner("bottom_left")
      }
    } else {
      if (y < halfHeight) {
        setCorner("top_right")
      } else {
        setCorner("bottom_right")
      }
    }
  }

  return (
    <MotionBox
      layout="position"
      transition={{
        type: "spring",
        stiffness: 700,
        damping: 40,
        delay: 0.15,
      }}
      position="fixed"
      zIndex={3}
      style={positions[corner]}
      drag
      dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
      dragElastic={1}
      onDrag={handleDragUpdate}
      dragTransition={{
        type: "spring",
        bounceStiffness: 1000,
        bounceDamping: 10,
        mass: 0.5,
      }}
      _hover={{ cursor: "grab" }}
      _active={{ cursor: "grabbing" }}
      {...props}
    >
      {children}
    </MotionBox>
  )
}
