import {
  ButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import React from "react"

import { useMutableQueryString } from "Shared/hooks/useMutableQueryString"
import { BoxOutlineIcon } from "Shared/icons/untitled-ui/BoxOutlineIcon"
import { BoxSolidIcon } from "Shared/icons/untitled-ui/BoxSolidIcon"

import { Button, IconButton } from "DesignSystem/components"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { useViewMenuParams } from "./useViewMenuParams"

interface Props {
  iconOnly?: boolean
  listType: "projects" | "studies"
}

export const ActiveMenu: React.FC<ButtonProps & Props> = ({
  iconOnly = false,
  listType = "studies",
  ...buttonProps
}) => {
  const [, setSearchParams] = useMutableQueryString()
  const { showArchived } = useViewMenuParams()

  const BoxIcon = showArchived ? BoxSolidIcon : BoxOutlineIcon

  return (
    <Menu>
      {iconOnly ? (
        <MenuButton as={IconButton} icon={<BoxIcon />} />
      ) : (
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownOutlineIcon />}
          {...buttonProps}
        >
          {showArchived ? `Archived ${listType}` : `Active ${listType}`}
        </MenuButton>
      )}

      <MenuList overflow="hidden">
        <MenuItem
          onClick={() => {
            setSearchParams({
              show_archived: null,
            })
          }}
        >
          {`Active ${listType}`}
        </MenuItem>

        <MenuItem
          onClick={() => {
            setSearchParams({
              show_archived: "true",
            })
          }}
        >
          {`Archived ${listType}`}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
