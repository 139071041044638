import { State } from "Redux/app-store"
import { cancelResponse } from "Redux/reducers/current-response/action-creators"
import {
  LoadUsabilityTestFailure,
  LoadUsabilityTestRequest,
  LoadUsabilityTestSuccess,
  Type,
} from "Redux/reducers/participant-usability-test/action-types"
import { loadScreenshot } from "Redux/reducers/screenshots/action-creators"
import { usabilityTestScreenshotsSelector } from "Redux/reducers/screenshots/selectors"
import { AsyncThunkAction, ParticipantDeletionReason } from "Types"
import { reportErrorToSentry } from "Utilities/error"
import {
  PrototypeNotViewableError,
  isFigmaFileVersionViewable,
} from "Utilities/figma-file-version"
import { assertPresent } from "Utilities/values"

export const loadUsabilityTest =
  (isPreview: boolean): AsyncThunkAction<State> =>
  async (dispatch, getState) => {
    const usabilityTest = assertPresent(getState().participantUsabilityTest)
    dispatch<LoadUsabilityTestRequest>({
      type: Type.LOAD_USABILITY_TEST_REQUEST,
    })
    const screenshots = usabilityTestScreenshotsSelector(
      getState(),
      usabilityTest
    )
    const figmaFileVersions = getState().figmaFileVersions
    try {
      await Promise.all([
        ...screenshots.map((screenshot) =>
          dispatch(loadScreenshot(screenshot._clientId))
        ),
        ...figmaFileVersions.map((ffv) => {
          // Skip caching of response from Figma in preview mode
          const skipCache = isPreview
          return isFigmaFileVersionViewable(ffv, skipCache)
        }),
      ])
      dispatch<LoadUsabilityTestSuccess>({
        type: Type.LOAD_USABILITY_TEST_SUCCESS,
      })
    } catch (error) {
      dispatch<LoadUsabilityTestFailure>({
        type: Type.LOAD_USABILITY_TEST_FAILURE,
      })
      if (assertPresent(getState().currentResponse).id != null) {
        dispatch(cancelResponse(ParticipantDeletionReason.ImagesFailedToLoad))
      }

      // Don't report prototype not viewable errors in production, there's
      // nothing we can do about them.
      if (process.env.DEBUG || !(error instanceof PrototypeNotViewableError)) {
        reportErrorToSentry(error, {
          extra: {
            usabilityTestId: usabilityTest.id,
          },
        })
      }
    }
  }
