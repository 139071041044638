import { Button, ButtonProps } from "@chakra-ui/react"
import React from "react"

type SubmitButton = React.FC<
  React.PropsWithChildren<
    {
      loadingAction?: string
    } & ButtonProps
  >
>

export const SubmitButton: SubmitButton = React.forwardRef(
  (
    {
      children,
      colorScheme = "brand.primary",
      loadingAction = "Please wait",
      ...buttonProps
    },
    ref: React.Ref<HTMLButtonElement>
  ) => (
    <Button
      ref={ref}
      type="submit"
      colorScheme={colorScheme}
      loadingText={`${loadingAction}…`}
      {...buttonProps}
    >
      {children}
    </Button>
  )
)

SubmitButton.displayName = "SubmitButton"
