import { CardSortCard, CardSortCategory, CardSortCategoryCard } from "Types"
import { maxBy } from "lodash"

export type FilterType = ClosedFilterType | OpenFilterType

type OpenFilterType =
  | "all"
  | "groupedCategories"
  | "ungroupedCategories"
  | "bestMatch"

export const OPEN_FILTER_TYPE_LABELS: { [t in OpenFilterType]: string } = {
  all: "All categories",
  groupedCategories: "Grouped categories",
  ungroupedCategories: "Ungrouped categories",
  bestMatch: "Best match categories",
}

export type ClosedFilterType = Exclude<
  OpenFilterType,
  "groupedCategories" | "ungroupedCategories"
>

export const CLOSED_FILTER_TYPE_LABELS: { [t in ClosedFilterType]: string } = {
  all: "All cards",
  bestMatch: "Best match",
}

export const generateBestMatches = (
  cards: CardSortCard[],
  categories: CardSortCategory[],
  sortData: CardSortCategoryCard[]
) => {
  return new Map(
    cards.map((card) => {
      const bestCategory = maxBy(
        categories,
        (cat) =>
          sortData.filter(
            (s) =>
              s.card_sort_card_id === card.id &&
              (s.card_sort_category_id ?? s.card_sort_open_category_id) ===
                Number(cat.id)
          ).length
      )

      return [card.id!, Number(bestCategory?.id)]
    })
  )
}
