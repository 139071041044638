import { useCallback, useState } from "react"

import { useOAuth } from "./useOAuth"

export const useMicrosoftOAuth = (
  initialState = false,
  onSuccess: () => void = () => {}
) => {
  const [hasMicrosoftOAuthCredentials, setHasMicrosoftOAuthCredentials] =
    useState(initialState)

  const disconnectMicrosoftOAuth = useCallback(
    () => setHasMicrosoftOAuthCredentials(false),
    []
  )
  const { handleWithAuth: handleMicrosoftOAuth } = useOAuth(
    () => {
      setHasMicrosoftOAuthCredentials(true)
      onSuccess()
    },
    "Microsoft",
    "/microsoft_oauth2/redirect",
    hasMicrosoftOAuthCredentials
  )

  return {
    handleMicrosoftOAuth,
    disconnectMicrosoftOAuth,
    hasMicrosoftOAuthCredentials,
  }
}
