import { Flex, Heading, Tag, Text } from "@chakra-ui/react"
import { LinkButton } from "Components/button/link-button"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ROUTES } from "UserCrowd/views/routes"
import {
  centsToDollars,
  creditsToCents,
  formatDollars,
} from "Utilities/currency"
import React from "react"

type Props = {
  creditReward: number | null
  isPanelist: boolean
}
export const ScreenedOutScreen: React.FC<Props> = ({
  creditReward,
  isPanelist,
}) => {
  const t = useTranslate()

  return (
    <Flex direction="column" justify="center" h="full" mx={[6, 12]}>
      <Flex direction="column" align="flex-start">
        <Tag textTransform="uppercase">{t("test.screener.screener_tag")}</Tag>

        {isPanelist ? (
          // Copy for panelists
          <>
            <Heading as="h2" fontFamily="promo" size="lg" mt={6}>
              {t("test.screener.screened_out.panelist.heading")}
            </Heading>

            <Text mt={4}>{t("test.screener.screened_out.panelist.body")}</Text>

            {creditReward && (
              <Text mt={4}>
                {t("test.screener.screened_out.panelist.reward", {
                  reward: formatDollars(
                    centsToDollars(creditsToCents(creditReward))
                  ),
                })}
              </Text>
            )}
          </>
        ) : (
          // Copy for self-recruited participants
          <>
            <Heading as="h2" fontFamily="promo" size="lg" mt={6}>
              {t("test.screener.screened_out.self_recruited.heading")}
            </Heading>

            <Text mt={4}>
              {t("test.screener.screened_out.self_recruited.body")}
            </Text>
          </>
        )}

        <Flex mt={8} gap={2}>
          {isPanelist && (
            <LinkButton
              href={ROUTES.DASHBOARD.path}
              colorScheme="brand.primary"
            >
              Dashboard
            </LinkButton>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
