import { UsabilityTest } from "Types"

export const getQuestionByQuestionId = (
  questionId: number,
  test: UsabilityTest
) => {
  return test.sections
    .flatMap((section) => section.questions)
    .find((question) => question.id === questionId)
}
