import {
  Accordion,
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  Grid,
  Heading,
  Spinner,
  Text,
  useInterval,
} from "@chakra-ui/react"
import { flatten, groupBy, isEqual, mapValues, merge, orderBy } from "lodash"
import React, { useEffect, useReducer, useState } from "react"
import { Helmet } from "react-helmet"
import { Navigate } from "react-router"

import { ROUTES } from "UsabilityHub/views/routes"
import {
  GetModeratedStudyBookingsResponse,
  useGetModeratedStudyBookings,
} from "~/api/generated/usabilityhub-components"

import { useQueryClient } from "@tanstack/react-query"
import { ZoomSettingsModal } from "UsabilityHub/components/ZoomSettingsModal"
import { useIntegrations } from "UsabilityHub/hooks/useIntegrations"
import { useMicrosoftOAuth } from "UsabilityHub/hooks/useMicrosoftOAuth"
import { useZoomOAuth } from "UsabilityHub/hooks/useZoomOAuth"
import { useModal } from "Utilities/modals/use-modal"
import { pluralizeWithCount } from "Utilities/string"
import {
  BookingCard,
  MarkBookingFunc,
  ResolveMarkedBooking,
} from "./BookingCard/BookingCard"
import BookingStateUpdateModal from "./BookingStateUpdateModal"
import { NextSessionBanner } from "./BookingsPageNextSessionBanner"
import { useModeratedStudyContext } from "./ModeratedStudyContext"
import { ParticipantInfoDrawer } from "./ParticipantInfoDrawer/ParticipantInfoDrawer"

const BOOKING_STATES = ["upcoming", "past", "canceled", "reported"] as const

type Booking = GetModeratedStudyBookingsResponse[number]

export function ModeratedStudyBookingsPage() {
  const queryClient = useQueryClient()
  const { moderatedStudyId } = useModeratedStudyContext()
  const { open: openZoomSettingsModal } = useModal(ZoomSettingsModal)
  const { data: integrations } = useIntegrations()

  const isCurrentUserZoomIntegrated = !!integrations?.zoom
  const isCurrentUserMicrosoftIntegrated = !!integrations?.microsoft

  const { handleZoomOAuth } = useZoomOAuth(
    isCurrentUserZoomIntegrated,
    async () => {
      await queryClient.invalidateQueries([
        "api",
        "moderated_studies",
        moderatedStudyId,
      ])
      openZoomSettingsModal({})
    }
  )

  const { handleMicrosoftOAuth } = useMicrosoftOAuth(
    isCurrentUserMicrosoftIntegrated,
    async () => {
      await queryClient.invalidateQueries([
        "api",
        "moderated_studies",
        moderatedStudyId,
      ])
      // TODO
      // openMicrosoftSettingsModal({})
    }
  )

  const { data, isLoading, isError, error } = useGetModeratedStudyBookings(
    {
      pathParams: {
        moderatedStudyId: moderatedStudyId,
      },
    },
    { staleTime: 60_000, refetchInterval: 60_000 }
  )

  // Keep summary counts in sync with the main list of sessions
  useEffect(() => {
    queryClient.invalidateQueries(
      ["api", "moderated_studies", moderatedStudyId, "summary"],
      {
        exact: true,
      }
    )
  }, [data])

  if (error?.status === 404) {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <>
      <Helmet>
        <title>Sessions</title>
      </Helmet>

      {isLoading && (
        <Flex
          mt="32"
          justifyContent="center"
          alignItems="center"
          direction="column"
          gap="4"
        >
          <Spinner size="lg" thickness="4px" color="gray.500" />
          Fetching...
        </Flex>
      )}

      {isError && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            Something went wrong loading the booking data. Please try refreshing
            the page.
          </AlertDescription>
        </Alert>
      )}

      {!isLoading && !isError && data && (
        <main>
          <Box p={8} w="full" gap={4} maxW="68rem" mx="auto">
            <BookingList
              moderatedStudyId={moderatedStudyId}
              bookings={data}
              handleZoomOAuth={handleZoomOAuth}
              handleMicrosoftOAuth={handleMicrosoftOAuth}
            />
          </Box>
        </main>
      )}
    </>
  )
}

type BookingsByDate = Record<string, GetModeratedStudyBookingsResponse>

// ts-prune-ignore-next used in test
export type UnsortedBookingsBySection = {
  upcoming: GetModeratedStudyBookingsResponse
  past: GetModeratedStudyBookingsResponse
  canceled: GetModeratedStudyBookingsResponse
  reported: GetModeratedStudyBookingsResponse
}

// ts-prune-ignore-next used in test
export type GroupedSortedBookingsByDate = {
  upcoming: BookingsByDate
  past: BookingsByDate
  canceled: BookingsByDate
  reported: BookingsByDate
}

// ts-prune-ignore-next used in test
export const unsortedBookingsBySectionToGroupedSortedBookingsByDate = (
  sortedBookings: UnsortedBookingsBySection
): GroupedSortedBookingsByDate => {
  return Object.entries(sortedBookings).reduce(
    (prev, [categoryName, flatBookings]) => {
      return {
        ...prev,
        [categoryName]: sortBookingsWithinDays(
          groupBookingsByDate(flatBookings)
        ),
      }
    },
    {} as GroupedSortedBookingsByDate
  )
}

// ts-prune-ignore-next used in test
export const groupBookingsBySection = (
  bookings: GetModeratedStudyBookingsResponse
): UnsortedBookingsBySection => {
  return merge(
    { upcoming: [], past: [], canceled: [], reported: [] },
    mapValues(groupBy(bookings, bookingState), (list, state) =>
      orderBy(list, "starts_at", state === "upcoming" ? "asc" : "desc")
    )
  )
}

// ts-prune-ignore-next used in test
export const groupBookingsByDate = (
  bookings: GetModeratedStudyBookingsResponse
) =>
  bookings.reduce(
    (acc, booking) => {
      const date = new Date(booking.starts_at).toDateString()
      const existingBookings = acc[date] ?? []
      return {
        ...acc,
        [date]: [...existingBookings, booking],
      }
    },
    {} as Record<string, GetModeratedStudyBookingsResponse>
  )

const sortBookingsChronologically = (
  bookingsForDay: GetModeratedStudyBookingsResponse
) => {
  return orderBy(bookingsForDay, "starts_at", "asc")
}

// ts-prune-ignore-next used in test
export const sortBookingsWithinDays = (
  groupedBookings: Record<string, GetModeratedStudyBookingsResponse>
): Record<string, GetModeratedStudyBookingsResponse> => {
  const sorted = Object.entries(groupedBookings).map(
    ([dateString, bookingsForDay]) => {
      // No matter how the days are ordered in a section, we always want to order sessions
      // within a day in ascending order.
      const sortedDayBookings = sortBookingsChronologically(bookingsForDay)

      return [dateString, sortedDayBookings]
    }
  )
  return Object.fromEntries(sorted)
}

type BookingsReducerAction =
  | { type: "tick" }
  | { type: "reset"; bookings: GetModeratedStudyBookingsResponse }

const sortedBookingsReducer = (
  state: UnsortedBookingsBySection,
  action: BookingsReducerAction
) => {
  if (action.type === "reset") return groupBookingsBySection(action.bookings)
  const newState = groupBookingsBySection([
    ...state.canceled,
    ...state.past,
    ...state.upcoming,
    ...state.reported,
  ])
  if (!isEqual(newState, state)) return newState
  return state
}

const BookingList: React.FC<{
  moderatedStudyId: string
  bookings: GetModeratedStudyBookingsResponse
  handleZoomOAuth: () => void
  handleMicrosoftOAuth: () => void
}> = ({
  moderatedStudyId,
  bookings,
  handleZoomOAuth,
  handleMicrosoftOAuth,
}) => {
  const [sortedBookings, dispatch] = useReducer(sortedBookingsReducer, {
    upcoming: [],
    past: [],
    canceled: [],
    reported: [],
  })
  const {
    upcoming: upcomingByDate,
    past: pastByDate,
    canceled: canceledByDate,
    reported: reportedByDate,
  } = unsortedBookingsBySectionToGroupedSortedBookingsByDate(sortedBookings)

  const [markingBooking, setMarkingBooking] = useState<{
    booking: Booking
    newState: ResolveMarkedBooking["newState"]
    resolve: (arg: ResolveMarkedBooking) => void
  } | null>(null)

  const markBooking: MarkBookingFunc = ({ booking, as: newState }) =>
    new Promise((resolve) =>
      setMarkingBooking({
        booking,
        newState,
        // Maybe someone smarter than me can improve the types here
        resolve: resolve as (arg: ResolveMarkedBooking) => void,
      })
    )

  useInterval(() => dispatch({ type: "tick" }), 5_000)

  useEffect(() => dispatch({ bookings, type: "reset" }), [bookings])

  if (bookings.length === 0) {
    return (
      <Text align="center" color="text.secondary">
        No sessions
      </Text>
    )
  }

  const flatSortedUpcoming = flatten(Object.values(upcomingByDate))
  const flatSortedPast = flatten(Object.values(pastByDate))
  const currentlyVisibleApplicationIds = [
    ...flatSortedUpcoming,
    ...flatSortedPast,
    ...flatten(Object.values(canceledByDate)),
    ...flatten(Object.values(reportedByDate)),
  ].map((b) => b.moderated_study_application_id)

  return (
    <Flex direction="column" gap={6}>
      <NextSessionBanner upcomingBookings={flatSortedUpcoming} />
      {flatSortedUpcoming.length > 0 ? (
        <BookingListSection
          openFirst
          sectionTitle="Upcoming sessions"
          bookingsByDate={upcomingByDate}
          incompleteBookingsCount={0}
          moderatedStudyId={moderatedStudyId}
          showMissingLocationWarning
          onMarkBooking={markBooking}
          handleZoomOAuth={handleZoomOAuth}
          handleMicrosoftOAuth={handleMicrosoftOAuth}
        />
      ) : (
        <NoUpcomingSessionsCard />
      )}
      <BookingListSection
        sectionTitle="Past sessions"
        bookingsByDate={pastByDate}
        incompleteBookingsCount={
          flatSortedPast.filter(
            (b) => !["complete", "auto_complete", "no_show"].includes(b.state)
          ).length
        }
        moderatedStudyId={moderatedStudyId}
        onMarkBooking={markBooking}
        handleZoomOAuth={handleZoomOAuth}
        handleMicrosoftOAuth={handleMicrosoftOAuth}
        showMissingLocationWarning={false}
      />
      <BookingListSection
        sectionTitle="Canceled sessions"
        bookingsByDate={canceledByDate}
        incompleteBookingsCount={0}
        moderatedStudyId={moderatedStudyId}
        showMissingLocationWarning={false}
        onMarkBooking={markBooking}
        handleZoomOAuth={handleZoomOAuth}
        handleMicrosoftOAuth={handleMicrosoftOAuth}
      />
      <BookingListSection
        sectionTitle="Reported sessions"
        bookingsByDate={reportedByDate}
        incompleteBookingsCount={0}
        moderatedStudyId={moderatedStudyId}
        showMissingLocationWarning={false}
        onMarkBooking={markBooking}
        handleZoomOAuth={handleZoomOAuth}
        handleMicrosoftOAuth={handleMicrosoftOAuth}
      />

      {markingBooking && (
        <BookingStateUpdateModal
          booking={markingBooking.booking}
          newState={markingBooking.newState}
          onConfirm={markingBooking.resolve}
          onClose={() => setMarkingBooking(null)}
        />
      )}

      <ParticipantInfoDrawer
        currentlyVisibleApplicationIds={currentlyVisibleApplicationIds}
      />
    </Flex>
  )
}

/**
 * bookingsByDate is in this format:
 * {
 *   "Wed Apr 05 2023": [booking1, booking2],
 *   "Thu Apr 06 2023": [booking3],
 *   "Fri Apr 07 2023": [booking4]
 * }
 */
const BookingListSection: React.FC<{
  sectionTitle: string
  moderatedStudyId: string
  bookingsByDate: Record<string, GetModeratedStudyBookingsResponse>
  incompleteBookingsCount: number
  showMissingLocationWarning: boolean
  onMarkBooking: MarkBookingFunc
  handleZoomOAuth: () => void
  handleMicrosoftOAuth: () => void
  openFirst?: boolean
}> = ({
  sectionTitle,
  moderatedStudyId,
  bookingsByDate,
  incompleteBookingsCount,
  showMissingLocationWarning,
  onMarkBooking,
  handleZoomOAuth,
  handleMicrosoftOAuth,
  openFirst = false,
}) => {
  // Skip sections with no relevant bookings
  if (Object.values(bookingsByDate).every((sgb) => sgb.length === 0))
    return null

  return (
    <Flex
      direction="column"
      bg="white"
      rounded="lg"
      borderWidth={1}
      borderColor="gray.200"
      gap={6}
      p={8}
    >
      <Heading
        id={sectionTitle.replace(/ /, "_").toLowerCase()}
        color="text.primary"
        fontWeight="semibold"
      >
        {sectionTitle}
      </Heading>

      {incompleteBookingsCount > 0 && (
        <Alert status="info">
          <AlertIcon />

          <AlertDescription>
            <Text fontWeight="bold">
              You have {pluralizeWithCount(incompleteBookingsCount, "session")}{" "}
              to mark attendance
            </Text>
            <Text>
              Sessions that have not been marked “Completed” or “No Show” within
              5 days after the session will be automatically marked as
              completed.
            </Text>
          </AlertDescription>
        </Alert>
      )}

      <Accordion allowToggle defaultIndex={openFirst ? 0 : undefined}>
        <Grid templateColumns="64px 1fr" gap={4}>
          {Object.entries(bookingsByDate).map(
            ([dateString, sortedDayBookings]) => {
              const date = new Date(Date.parse(dateString))

              return (
                <React.Fragment key={dateString}>
                  <DateLabel date={date} />

                  {sortedDayBookings.map((booking) => (
                    <BookingCard
                      key={booking.id}
                      moderatedStudyId={moderatedStudyId}
                      booking={booking}
                      showMissingLocationWarning={showMissingLocationWarning}
                      onMarkBooking={onMarkBooking}
                      handleZoomOAuth={handleZoomOAuth}
                      handleMicrosoftOAuth={handleMicrosoftOAuth}
                    />
                  ))}
                </React.Fragment>
              )
            }
          )}
        </Grid>
      </Accordion>
    </Flex>
  )
}

const NoUpcomingSessionsCard: React.FC = () => {
  return (
    <Flex
      direction="column"
      bg="white"
      rounded="lg"
      borderWidth={1}
      borderColor="gray.200"
      gap={6}
      p={8}
    >
      <Heading color="text.primary" fontWeight="semibold">
        Upcoming sessions
      </Heading>
      <Text align="center" color="text.secondary" my={4}>
        No upcoming sessions
      </Text>
      {/*
        * TODO: We'll add this later after beta
      <Button colorScheme="brand.primary" mx="auto" onClick={() => alert("TODO")}>
        Close study
      </Button>
      */}
    </Flex>
  )
}

const DateLabel: React.FC<{ date: Date }> = ({ date }) => {
  return (
    <Flex
      h="64px"
      direction="column"
      justify="center"
      align="center"
      rounded="lg"
      borderWidth={1}
      borderColor="gray.300"
      p={1.5}
      gridColumn="1"
    >
      <Text
        color="blackAlpha.500"
        fontWeight="bold"
        fontSize="sm"
        lineHeight={1}
        textTransform="uppercase"
      >
        {date.toLocaleDateString(undefined, {
          month: "short",
        })}
      </Text>
      <Text color="text.primary" fontWeight="bold" fontSize="xl" lineHeight={1}>
        {date.toLocaleDateString(undefined, {
          day: "numeric",
        })}
      </Text>
      <Text
        color="text.primary"
        fontWeight="medium"
        fontSize="sm"
        lineHeight={1}
        textTransform="uppercase"
      >
        {date.toLocaleDateString(undefined, {
          weekday: "short",
        })}
      </Text>
    </Flex>
  )
}

const bookingState = (
  booking: GetModeratedStudyBookingsResponse[number]
): (typeof BOOKING_STATES)[number] => {
  if (
    String(booking.state).startsWith("canceled") ||
    booking.state === "rescheduled_by_researcher"
  )
    return "canceled"

  if (booking.state === "reported") return "reported"

  const now = new Date()
  const endTime = new Date(booking.ends_at)
  if (endTime < now) return "past"
  return "upcoming"
}
