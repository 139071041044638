import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers"
import { shuffle, uniq } from "lodash"
import React, { useMemo } from "react"

import { ReorderableListField } from "Components/redux-form/reorderable-list"
import { useTranslate } from "Shared/hooks/useTranslate"
import { AnswerForm } from "UsabilityHub/components/UsabilityTestSectionQuestion/answer-form"

import { Props as QuestionProps } from "./answer-form"

export const RankingQuestion: React.FC<
  React.PropsWithChildren<QuestionProps>
> = ({ question, ...rest }) => {
  const translate = useTranslate()

  // There is no database constraint that multiple choice options must be
  // unique, and this was not validated for some time (see #3354). Remove
  // duplicates before rendering the ReorderableList.
  const sortedOptions = useMemo(() => {
    const options = uniq(question.multiple_choice_options)
    return question.randomized ? shuffle(options) : options
  }, [question.multiple_choice_options])
  return (
    <AnswerForm
      initialValues={{ answers: sortedOptions }}
      instructions={translate("test.reorderable_list_instruction")}
      question={question}
      {...rest}
    >
      <ReorderableListField
        name="answers"
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      />
    </AnswerForm>
  )
}
