import { createIcon } from "@chakra-ui/react"
import React from "react"

export const RankIcon = createIcon({
  displayName: "RankIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="ranks">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.32958 7.08695C2.32958 7.4864 2.63502 7.80977 3.01233 7.80977C3.38964 7.80977 3.69509 7.4864 3.69509 7.08695V1.72283C3.69509 1.32337 3.38964 1 3.01233 1H2.99437C2.80571 1 2.60807 1.04755 2.34754 1.12364L1.45816 1.39946C1.17967 1.48505 1 1.73234 1 2.02717C1 2.36956 1.27849 2.65489 1.6019 2.65489C1.67377 2.65489 1.77259 2.63587 1.84446 2.61685L2.32958 2.48369V7.08695ZM5.0793 10.2287C5.0793 10.6662 5.39373 10.923 5.82494 10.923H9.23872C9.57111 10.923 9.84062 10.6472 9.84062 10.2953C9.84062 9.94337 9.57111 9.65804 9.23872 9.65804H7.04671L8.07085 8.82108C9.18482 7.90804 9.72383 7.35641 9.72383 6.24364V6.22462C9.72383 4.9787 8.86141 4.17027 7.55878 4.17027C6.60652 4.17027 6.0136 4.53169 5.51051 5.14989C5.41169 5.27353 5.34881 5.44473 5.34881 5.60641C5.34881 5.97734 5.6273 6.27217 5.97766 6.27217C6.19327 6.27217 6.35497 6.16755 6.44481 6.06294C6.79517 5.66348 7.07367 5.48277 7.46895 5.48277C7.96304 5.48277 8.31341 5.78712 8.31341 6.34826C8.31341 6.89989 8.00796 7.27081 7.22639 7.94608L5.43864 9.49635C5.20507 9.69608 5.0793 9.94336 5.0793 10.2287ZM15 12.822C15 14.0775 14.1376 15 12.7361 15C11.8378 15 11.164 14.7052 10.6519 14.2391C10.5262 14.125 10.4184 13.9253 10.4184 13.716C10.4184 13.3356 10.7238 13.0122 11.0831 13.0122C11.2808 13.0122 11.4155 13.0978 11.5233 13.193C11.8827 13.5163 12.26 13.6875 12.7541 13.6875C13.2751 13.6875 13.6435 13.3737 13.6435 12.8791V12.8601C13.6435 12.318 13.1853 12.0136 12.4127 12.0136H12.1971C11.8737 12.0136 11.6042 11.7283 11.6042 11.3859C11.6042 11.1862 11.685 11.015 11.9006 10.7867L13.1224 9.48372H11.1909C10.8675 9.48372 10.598 9.19839 10.598 8.856C10.598 8.51361 10.8675 8.22829 11.1909 8.22829H14.2544C14.6586 8.22829 14.9551 8.47557 14.9551 8.87502C14.9551 9.23644 14.7934 9.43616 14.5418 9.69296L13.3111 10.9389C14.1645 11.0911 15 11.5666 15 12.803V12.822Z"
        fill="currentColor"
      />
    </g>
  ),
})
