import { Box, Flex, Spinner, useToast } from "@chakra-ui/react"
import React, { FC, useEffect } from "react"
import { Helmet } from "react-helmet"
import { Navigate } from "react-router"
import { useTypedParams } from "react-router-typesafe-routes/dom"

import { NoPermissionWarning } from "Components/not-allowed-warning/not-allowed-warning"
import { DemographicsContextProvider } from "Components/test-results/context/demographics"
import { useCanCurrentUserDo } from "Hooks/use-can-current-user-do"
import { PageMain } from "Shared/components/Page/Page"
import { useUsabilityTestRecruitPage } from "~/api/generated/usabilityhub-components"

import { ROUTES } from "UsabilityHub/views/routes"
import { UsabilityTestRecruit } from "./UsabilityTestRecruit"

export const UsabilityTestRecruitRoute: FC = () => {
  const canUserManageTests = useCanCurrentUserDo("manage_tests")

  if (!canUserManageTests) {
    return (
      <Layout title="Recruit Participants">
        <Box maxW={1024} mx="auto" my="7">
          <NoPermissionWarning permission="manage_tests" />
        </Box>
      </Layout>
    )
  }

  const toast = useToast()

  const { testId: usabilityTestId } = useTypedParams(ROUTES.TEST.RECRUIT)
  const { data, isLoading, error } = useUsabilityTestRecruitPage({
    pathParams: { usabilityTestId },
  })

  const usabilityTest = data?.usability_test

  useEffect(() => {
    if (error) {
      toast({
        title:
          error.payload.message ??
          "Lyssna has encountered an error while fetching data",
        status: "error",
      })
    }
  }, [error])

  if (error) {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <Layout title={usabilityTest?.name ?? "Recruit Participants"}>
      {isLoading && (
        <Flex mt="32" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      )}
      {!isLoading && usabilityTest && (
        <DemographicsContextProvider>
          <UsabilityTestRecruit
            usabilityTest={usabilityTest}
            // TODO: This was broken at some point, the response stopped
            // containing the value and this was always passed as undefined.
            // We need to update the backend to send the data.
            usabilityTestContainsUrls={false}
          />
        </DemographicsContextProvider>
      )}
    </Layout>
  )
}

const Layout: FC<{ title: string } & React.PropsWithChildren> = ({
  title,
  children,
}) => {
  return (
    <PageMain>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box width="100%" mt="8">
        {children}
      </Box>
    </PageMain>
  )
}
