import { Box, HStack, Stack } from "@chakra-ui/react"
import CameraPreview from "Shared/components/CameraPreview/CameraPreview"
import React from "react"
import { useSectionRecordingContext } from "../UsabilityTest/context/SectionRecordingContext"
import { useTestRecordingContext } from "../UsabilityTest/context/TestRecordingContext"
import { RecordingIndicator } from "./RecordingIndicator"
import { RecordingVolumeIndicator } from "./RecordingVolumeIndicator"
import { CornerDraggable } from "./SectionTasks/PrototypeTask/InstructionsModal/CornerDraggable"

export const RecordingThumbnail: React.FC = () => {
  const { sectionRecordingTypes, isValidRecordingState } =
    useSectionRecordingContext()

  const { videoDeviceId } = useTestRecordingContext()

  const hasAudio = sectionRecordingTypes?.includes("microphone")
  const hasCamera = !!videoDeviceId && sectionRecordingTypes?.includes("camera")

  if (!isValidRecordingState || (!hasAudio && !hasCamera)) return null

  return (
    <CornerDraggable initial="bottom_right" margin={2}>
      {hasCamera ? (
        <Stack gap={1} align="stretch">
          <CameraThumbnail />
          <RecordingIndicator />
        </Stack>
      ) : (
        <HStack gap={1} align="stretch">
          <RecordingVolumeIndicator size="emphasized" />
          <RecordingIndicator />
        </HStack>
      )}
    </CornerDraggable>
  )
}

const CameraThumbnail: React.FC = () => {
  const { videoDeviceId } = useTestRecordingContext()

  return (
    <Box pos="relative">
      <CameraPreview
        deviceId={videoDeviceId}
        w="7.25rem"
        aspectRatio={1}
        borderRadius={12}
        backgroundColor="black"
      />
      <RecordingVolumeIndicator
        size="small"
        pos="absolute"
        right={1}
        bottom={1}
      />
    </Box>
  )
}
