import React from "react"

import { SectionCard } from "../SectionCard"
import { SectionQuestions } from "../SectionQuestions/SectionQuestions"
import { SectionLogicActions } from "../TestLogicActions/TestLogicActions"

export const QuestionsSectionCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <SectionCard>
      <SectionLogicActions />
      <SectionQuestions />
    </SectionCard>
  )
}
