import { useContext, useMemo } from "react"

import { filterNodeClicksByOccurrences } from "Components/test-results/utils/filter-node-clicks/filter-node-clicks-by-occurrence"
import { getAllClicks } from "Components/test-results/utils/get-node-clicks-by-response/get-all-clicks"

import { ClickOccurrenceFilterContext } from "./ClickOccurrenceFilterProvider"
import { ScreenResultDetailsContext } from "./ScreenResultsProvider"

export function useFilteredClicksByClickOccurrence() {
  const { screenResult } = useContext(ScreenResultDetailsContext)
  const clicks = screenResult?.meta.clicks ?? [[]]

  const { clickOccurrences: visibleClickOccurrences } = useContext(
    ClickOccurrenceFilterContext
  )

  const filteredClicks = useMemo(
    () => filterNodeClicksByOccurrences(clicks, visibleClickOccurrences),
    [clicks, visibleClickOccurrences]
  )

  const allNodeClicks = useMemo(() => getAllClicks(clicks), [clicks])
  const allFilteredNodeClicks = useMemo(
    () => getAllClicks(filteredClicks),
    [filteredClicks]
  )

  return {
    allVisibleClicks: allNodeClicks,
    visibleClicks: allFilteredNodeClicks,
  }
}
