import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React from "react"

import { SubmitButton } from "Components/button/submit-button"
import { JsForm } from "Components/form/form"
import { FunctionalModal } from "Utilities/modals/types"
import { useArchiveUsabilityTest } from "~/api/generated/usabilityhub-components"

interface Props {
  usabilityTestId: number
  name: string
  hasActiveThirdPartyOrders: boolean
}

export const ArchiveStudyModal: FunctionalModal<Props> = ({
  usabilityTestId,
  name,
  hasActiveThirdPartyOrders,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutateAsync: archiveStudy, isLoading } = useArchiveUsabilityTest({
    onSuccess: () => {
      void queryClient.invalidateQueries(["api", "usability_tests"])
      void queryClient.invalidateQueries(["api", "studies"])
      void queryClient.invalidateQueries(["api", "account", "total_credits"])
    },
  })

  const onConfirm = async () => {
    try {
      const data = await archiveStudy({
        pathParams: { usabilityTestId: usabilityTestId.toString() },
      })

      let message = `Archived test "${name}"`

      if (data.refund_point_count > 0) {
        message += `and refunded ${data.refund_point_count} unused credits`
      }

      toast({
        title: "Test archived",
        description: message,
        status: "success",
        duration: 3000,
      })

      onClose()
    } catch (error) {
      toast({
        title: "Test archive failed",
        status: "error",
        duration: 3000,
      })
    }
  }

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <JsForm onSubmit={onConfirm}>
          {!hasActiveThirdPartyOrders && (
            <>
              <ModalHeader>Archive this test?</ModalHeader>
              <ModalBody>
                <Text>
                  Are you sure you want to archive this test? This will cancel
                  any active orders, disable the recruitment link, and hide it
                  by default on your dashboard.
                </Text>
              </ModalBody>
              <ModalFooter>
                <HStack>
                  <Button onClick={onClose} variant="outline">
                    Cancel
                  </Button>
                  <SubmitButton isLoading={isLoading} loadingAction="Archiving">
                    Archive
                  </SubmitButton>
                </HStack>
              </ModalFooter>
            </>
          )}
          {hasActiveThirdPartyOrders && (
            <>
              <ModalHeader>You can{"\u2019"}t archive this test</ModalHeader>
              <ModalBody>
                <Text>
                  This test has active third party orders which are awaiting
                  completion. You will have to cancel your third party orders
                  before you may archive this test. Please contact our support
                  if you plan to cancel the third party orders.
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="brand.primary" onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </JsForm>
      </ModalContent>
    </Modal>
  )
}
