import { Menu } from "@chakra-ui/react"
import { ROLE_OPTIONS } from "Constants/role"
import { useField, useFormikContext } from "formik"
import React from "react"
import { TeamMember } from "~/api/generated/usabilityhubSchemas"
import { UserRoleMenuButton } from "./UserRoleMenuButton"
import { UserRoleMenuList } from "./UserRoleMenuList"

type UserRoleSelect = React.FC<
  React.PropsWithChildren<{
    name: string
    isDisabled: boolean
    user: TeamMember | undefined
    hasPaidSeatAvailable: boolean
    isDeletable: boolean
  }>
>

export const UserRoleSelect: UserRoleSelect = ({
  name,
  isDisabled,
  user,
  isDeletable,
}) => {
  const [field] = useField(name)
  const { setFieldValue } = useFormikContext()

  return (
    <Menu placement="bottom-end" eventListeners={false}>
      <UserRoleMenuButton
        buttonText={
          ROLE_OPTIONS.find((opt) => opt.value === field.value)!.label
        }
        isDisabled={isDisabled}
        size="sm"
      />
      <UserRoleMenuList
        onRoleSelect={(roleValue: string) => {
          void setFieldValue(name, roleValue)
        }}
        user={user}
        isDeletable={isDeletable}
      />
    </Menu>
  )
}
