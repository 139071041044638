import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React, { useRef } from "react"

import {
  useArchiveProject,
  useGetProjectHasActiveThirdPartyOrders,
} from "~/api/generated/usabilityhub-components"
import { NewProject } from "~/api/generated/usabilityhubSchemas"

interface Props {
  project: NewProject
  onClose: () => void
}

export const ArchiveProjectModal: React.FC<Props> = ({ project, onClose }) => {
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null)
  const queryClient = useQueryClient()
  const toast = useToast()

  const { data: hasActiveThirdPartyOrdersData } =
    useGetProjectHasActiveThirdPartyOrders({
      pathParams: {
        id: project.id.toString(),
      },
    })
  const hasActiveThirdPartyOrders =
    hasActiveThirdPartyOrdersData?.has_active_third_party_orders

  const { mutateAsync: archiveProject, isLoading } = useArchiveProject({
    onSuccess: () => {
      void queryClient.invalidateQueries(["api", "projects"])
      void queryClient.invalidateQueries(["api", "account", "total_credits"])
    },
  })

  const handleArchive = async () => {
    try {
      const data = await archiveProject({
        pathParams: { id: project.id.toString() },
      })

      let message = `Archived project "${project.name}"`
      if (data.refund_point_count > 0) {
        message += ` and refunded ${data.refund_point_count} unused credits`
      }

      toast({
        title: "Project archived",
        description: message,
        status: "success",
        duration: 3000,
      })

      onClose()
    } catch (error) {
      toast({
        title: "Project archiving failed",
        description: "Please try again",
        status: "error",
        duration: 3000,
      })
    }
  }

  return (
    <AlertDialog
      isOpen
      leastDestructiveRef={cancelButtonRef}
      onClose={onClose}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
    >
      <AlertDialogOverlay>
        {hasActiveThirdPartyOrders ? (
          <AlertDialogContent>
            <AlertDialogHeader>
              You can{"\u2019"}t archive this project
            </AlertDialogHeader>

            <AlertDialogCloseButton />

            <AlertDialogBody>
              Tests have active third party orders which are awaiting completion
              in this project. You will have to cancel your third party orders
              before you may archive this project. Please contact our support if
              you plan to cancel the third party orders.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="brand.primary" onClick={onClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        ) : (
          <AlertDialogContent>
            <AlertDialogHeader>Archive this project?</AlertDialogHeader>

            <AlertDialogCloseButton />

            <AlertDialogBody>
              Are you sure you want to archive this project? This will cancel
              any active orders and disable the recruitment link of any tests it
              contains. It will also be hidden by default on your Dashboard and
              Projects page.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelButtonRef}
                variant="outline"
                disabled={isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                onClick={handleArchive}
                colorScheme="brand.primary"
                isLoading={isLoading}
                ml={3}
              >
                Archive
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        )}
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
