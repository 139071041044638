import { Button, ButtonProps, Input } from "@chakra-ui/react"
import React, { ChangeEvent, useRef } from "react"
import { useDispatch } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { addScreenshots } from "Redux/reducers/test-builder-form/action-creators/screenshots"
import { getMimeTypes } from "Services/media-types"
import validateFiles from "Services/validate-files"
import { ScreenshotMediaType } from "Types"
import { useS3ConfigContext } from "UsabilityHub/components/TestForm/S3ConfigContext"
import { useSectionIndexContext } from "UsabilityHub/contexts"

interface MediaFileInputButtonProps extends ButtonProps {
  allowedMediaTypes: ScreenshotMediaType[]
  maxFiles: number
}

export const MediaFileInputButton: React.FC<
  React.PropsWithChildren<MediaFileInputButtonProps>
> = ({ children, allowedMediaTypes, maxFiles, ...buttonProps }) => {
  const dispatch: Dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const inputRef = useRef<HTMLInputElement>(null)
  const allowedMimeTypes = allowedMediaTypes.flatMap(getMimeTypes)
  const s3Config = useS3ConfigContext()

  // When the button is clicked, trigger the file input.
  const onButtonClick = () => {
    inputRef.current?.click()
  }

  // When there's an input change, validate and add the media files to the section.
  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const validatedFiles = validateFiles(event.target.files as FileList, {
      allowedMediaTypes,
      maxFiles,
    })

    // Clear the underlying input, which allows the same file to be uploaded again.
    // See https://github.com/wearelyssna/hub/issues/1451.
    if (inputRef.current) inputRef.current.value = ""

    void dispatch(addScreenshots(sectionIndex, validatedFiles, s3Config))
  }

  return (
    <>
      <Button
        {...buttonProps}
        colorScheme="brand.secondary"
        onClick={onButtonClick}
      >
        {children}
      </Button>
      <Input
        id={`file_button_${sectionIndex}`} // Capybara attach_file requires id
        ref={inputRef}
        type="file"
        display="none"
        accept={allowedMimeTypes.join(",")}
        multiple={maxFiles > 1}
        onChange={onFileChange}
      />
    </>
  )
}
