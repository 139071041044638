import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const FiveSecondTestIcon = createIcon({
  displayName: "FiveSecondTestIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="currentColor"
      d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-5.655-1.735c.34-.401.527-.91.53-1.435v-.1a1 1 0 00-1-1h-3.69a1 1 0 00-1 1v.1c0 .52.185 1.03.53 1.435l1.095 1.31c.2.24.2.575 0 .82L5.7 9.745c-.33.398-.511.898-.515 1.415v.105a1 1 0 001 1h3.69a1 1 0 001-1v-.1c0-.515-.185-1.02-.52-1.425l-1.11-1.35a.629.629 0 010-.815l1.1-1.31zm-2.68 2.75L6.55 10.614a.5.5 0 00.41.786H9.09a.5.5 0 00.422-.768L8.498 9.034a.5.5 0 00-.832-.019z"
    />
  ),
})
