import React from "react"

import { Box, Button } from "@chakra-ui/react"
import { useTranslate } from "Shared/hooks/useTranslate"
import { SectionTaskFooter } from "UsabilityHub/components/UsabilityTestSectionTask/SectionTask"

type Props = {
  onCancel: () => void
  onConfirm: () => void
}

export const ConfirmClickPopper: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { children, ...handlers } = props
  return (
    <div>
      <SectionTaskFooter>
        <Content {...handlers} />
      </SectionTaskFooter>
    </div>
  )
}

type ContentProps = {
  onCancel: () => void
  onConfirm: () => void
}

const Content: React.FC<ContentProps> = ({ onConfirm, onCancel }) => {
  const translate = useTranslate()

  return (
    <Box p="10px" textAlign="center">
      <Button colorScheme="brand.primary" onClick={onConfirm}>
        {translate("test.buttons.confirm_click")}
      </Button>
      <Box py="7px" px="10px">
        <Button variant="link" fontSize="sm" onClick={onCancel}>
          {translate("test.buttons.cancel")}
        </Button>
      </Box>
    </Box>
  )
}
