import React, { CSSProperties } from "react"

import { Point } from "Types"
import {
  ClickType,
  getClickMarkerColor,
} from "Utilities/click-maps/get-click-marker-color"
import { percentage } from "Utilities/css-helpers"

interface Props {
  clickType?: ClickType
  point: Point
}

function getStyle({ x, y }: Point, clickType: ClickType): CSSProperties {
  return {
    position: "absolute",
    width: "14px",
    height: "14px",
    margin: "-7px 0 0 -7px",
    borderRadius: "99px",
    background: getClickMarkerColor(clickType),
    border: "2px solid white",
    left: percentage(x),
    top: percentage(y),
  }
}

export const ClickMarker = ({ point, clickType = "default" }: Props) => {
  return <div style={getStyle(point, clickType)} />
}
