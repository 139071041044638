import {
  Alert,
  AlertDescription,
  AlertIcon,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"
import { PurchasesMailtoLink } from "Shared/components/Links/PurchasesMailtoLink"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import { useActiveStripeSubscription } from "UsabilityHub/hooks/useCurrentAccount"
import { getDateString } from "Utilities/date-formats"
import React from "react"
import { Account, Plan } from "~/api/generated/usabilityhubSchemas"

interface PlanStates {
  currentAccount: Account
  currentPlan: Readonly<Plan>
  paidUserCount: number
}

// Even an account has a manual subscription,
// the subscribed plan should be one of the list.
export const PlanStatesAllowedUniqueIds = [
  "free",
  "basic_v3",
  "basic_v3_annual",
  "pro_v3",
  "pro_v3_annual",
]

export const PlanStatesAlert: React.FC<React.PropsWithChildren<PlanStates>> = ({
  currentAccount,
  currentPlan,
  paidUserCount,
}) => {
  // The field currentAccount.paid_seat_count cannot be null if it's a manual subscription
  let content
  if (currentAccount.manual_subscription) {
    content = `You${"\u2019"}re on a manually billed subscription to the ${
      currentPlan.name
    } plan with ${currentAccount.paid_seat_count!} collaborators.`
  } else {
    content = `You${"\u2019"}re on the ${
      currentPlan.name
    } plan, which includes ${currentPlan.included_collaborator_seats!} collaborators`
    if (
      currentPlan.collaborator_seat_limit! >
      currentPlan.included_collaborator_seats!
    ) {
      content += ` and supports up to ${currentPlan.collaborator_seat_limit!}`
    }
    content += "."
  }

  if (currentPlan.viewer_permissions) {
    content += " You can also add unlimited viewers."
  }

  const activeStripeSubscription = useActiveStripeSubscription()

  // Check if the the account has any additional paid seat besides the number of paid seats available on the plan
  const hasVacantSeats =
    !currentAccount.manual_subscription &&
    currentAccount.paid_seat_count &&
    paidUserCount < currentAccount.paid_seat_count &&
    currentAccount.paid_seat_count > currentPlan.included_collaborator_seats!
  const reachedSeatLimit =
    !currentAccount.manual_subscription &&
    currentPlan.collaborator_seat_limit !== null &&
    paidUserCount >= currentPlan.collaborator_seat_limit

  return (
    <Alert status="info" alignItems="start">
      <AlertIcon />
      <AlertDescription>
        <Stack>
          <Text>
            {content}
            {reachedSeatLimit && currentPlan.unique_id === "free" && (
              <>
                {" "}
                To add more users you{"\u2019"}ll need to{" "}
                <PlanDrawerTrigger
                  as={Link}
                  variant="unstyled"
                  verticalAlign="inherit"
                  userSelect="initial"
                  color="brand.primary.500"
                  textDecoration="none"
                  source="team_members_page_upgrade_banner"
                >
                  upgrade to a paid plan
                </PlanDrawerTrigger>
                .
              </>
            )}
            {currentAccount.manual_subscription && (
              <>
                {" "}
                Please contact <PurchasesMailtoLink /> if you need additional
                collaborators.
              </>
            )}
          </Text>
          {hasVacantSeats ? (
            <Text>
              You currently have{" "}
              {currentAccount.paid_seat_count! - paidUserCount} vacant
              collaborator seat
              {currentAccount.paid_seat_count! - paidUserCount > 1 ? "s" : null}
              . If you don{"\u2019"}t fill vacant seats, your subscription will
              be automatically reduced at your next renewal on{" "}
              {getDateString(
                activeStripeSubscription!.current_period_end!,
                "long"
              )}
              .
            </Text>
          ) : null}
          {reachedSeatLimit && currentPlan.unique_id.startsWith("basic_v3") && (
            <Text>
              To add more collaborators to your team you{"\u2019"}ll need to{" "}
              <PlanDrawerTrigger
                as={Link}
                variant="unstyled"
                verticalAlign="inherit"
                userSelect="initial"
                color="brand.primary.500"
                textDecoration="none"
                source="team_members_page_upgrade_banner"
              >
                upgrade to the Pro plan
              </PlanDrawerTrigger>
              .
            </Text>
          )}
        </Stack>
      </AlertDescription>
    </Alert>
  )
}
