import { State } from "Redux/app-store"
import { updatePrototypeVersion } from "Redux/reducers/test-builder-form/action-creators/prototypes"
import { getSections } from "Redux/reducers/test-builder-form/selectors/sections"
import { FigmaFileVersion, PayloadAction, ThunkAction } from "Types"

export enum ActionTypes {
  INIT_FIGMA_FILE_VERSIONS = "INIT_FIGMA_FILE_VERSIONS",
  ADD_FIGMA_FILE_VERSION = "ADD_FIGMA_FILE_VERSION",
  REMOVE_FIGMA_FILE_VERSION = "REMOVE_FIGMA_FILE_VERSION",
}

type InitFigmaFileVersionAction = PayloadAction<
  ActionTypes.INIT_FIGMA_FILE_VERSIONS,
  FigmaFileVersion[]
>
type AddFigmaFileVersionAction = PayloadAction<
  ActionTypes.ADD_FIGMA_FILE_VERSION,
  FigmaFileVersion
>
type RemoveFigmaFileVersionAction = PayloadAction<
  ActionTypes.REMOVE_FIGMA_FILE_VERSION,
  number
>

export type Action =
  | InitFigmaFileVersionAction
  | AddFigmaFileVersionAction
  | RemoveFigmaFileVersionAction

export const initializeFigmaFileVersions = (
  ffvs: FigmaFileVersion[]
): InitFigmaFileVersionAction => ({
  type: ActionTypes.INIT_FIGMA_FILE_VERSIONS,
  payload: ffvs,
})
// Add a single figma file version
export const addFigmaFileVersion = (
  ffv: FigmaFileVersion
): AddFigmaFileVersionAction => ({
  type: ActionTypes.ADD_FIGMA_FILE_VERSION,
  payload: ffv,
})

// Remove a single figma file version
const removeFigmaFileVersion = (
  ffvId: number
): RemoveFigmaFileVersionAction => ({
  type: ActionTypes.REMOVE_FIGMA_FILE_VERSION,
  payload: ffvId,
})

// Update an existing figma file version to a newer version
export const updateFigmaFileVersion =
  (oldFfv: FigmaFileVersion, newFfv: FigmaFileVersion): ThunkAction<State> =>
  (dispatch) => {
    // First: Add the version to the store
    dispatch(addFigmaFileVersion(newFfv))
    // Second: Update existing sections to point to the new version
    dispatch(updatePrototypeVersion(oldFfv, newFfv))
    // Finally: Remove the old version from the store
    dispatch(removeFigmaFileVersion(oldFfv.id))
  }

// Remove any unused figma file versions
export const removeUnusedFigmaFileVersions: ThunkAction<State> = (
  dispatch,
  getState
) => {
  const { figmaFileVersions } = getState()

  // Get all figma file version ids associated with figma file flows
  const activeFFVIds = getSections(getState()).map(
    (section) => section.figma_file_flow?.figma_file_version_id
  )

  // Get all figma file versions ids that aren't associated
  // with a figma file flow
  const inactiveFFVIds = figmaFileVersions
    .filter(({ id }) => !activeFFVIds.includes(id))
    .map((ffv) => ffv.id)

  // Remove all unused figma file versions
  inactiveFFVIds.forEach((FfvId) => dispatch(removeFigmaFileVersion(FfvId)))
}
