import {
  Box,
  Checkbox,
  Flex,
  FocusLock,
  Input,
  Text,
  useOutsideClick,
} from "@chakra-ui/react"
import { AppliedFilter, FilterAttribute, FilterOptionChoice } from "Types"
import React, { useRef, useState } from "react"
import { useFilterContext } from "./filter-context"
import { MENU_NAVIGATION_KEYS } from "./utils"

interface Props {
  appliedFilter?: AppliedFilter
  selectedAttribute: FilterAttribute
  onClose: () => void
}

export const FilterMenuMulti: React.FC<Props> = ({
  appliedFilter = null,
  selectedAttribute,
  onClose,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [textFilter, setTextFilter] = useState("")
  const [editingFilter, setEditingFilter] = useState<AppliedFilter | null>(
    appliedFilter
  )
  const { addFilter, changeFilter } = useFilterContext()

  // Close the menu when clicking outside, just like a real one!
  useOutsideClick({ ref: menuRef, handler: onClose })

  if (selectedAttribute.possibleOptions.type !== "multi") return null

  // This handler is used for the checkboxes on multi-select items.
  // The first time you click a checkbox we'll add a new filter with that choice, but keep the menu open.
  // We'll store the filter in local state so we can update it as you click more checkboxes.
  const onMultiFilterChange = (choice: FilterOptionChoice) => {
    if (editingFilter) {
      if (editingFilter.options.type !== "multi") return

      const currentlyActive = editingFilter.options.choices.some(
        (c) => c.value === choice.value
      )

      const newChoices = currentlyActive
        ? editingFilter.options.choices.filter((c) => c.value !== choice.value)
        : [...editingFilter.options.choices, choice]

      const updatedFilter = changeFilter(
        editingFilter.id,
        editingFilter.comparator,
        {
          type: "multi",
          choices: newChoices,
        }
      )

      setEditingFilter(updatedFilter)
    } else {
      const newFilter = addFilter(selectedAttribute, {
        type: "multi",
        choices: [choice],
      })

      setEditingFilter(newFilter)
    }
  }

  // This handler is used when clicking directly on the label.
  // In that case, we'll toggle the filter and then close the menu.
  const onMultiFilterLabelClick = (choice: FilterOptionChoice) => {
    onMultiFilterChange(choice)
    onClose()
  }

  const filteredChoices = selectedAttribute.possibleOptions.choices.filter(
    (choice) => choice.label.toLowerCase().includes(textFilter.toLowerCase())
  )

  const selectCheckbox = (delta: number) => {
    const checkboxes = menuRef.current?.querySelectorAll("input[type=checkbox]")
    const currentCheckbox = menuRef.current?.querySelector(
      "input[type=checkbox]:focus"
    )

    if (!checkboxes) return

    const currentIndex = currentCheckbox
      ? Array.from(checkboxes).indexOf(currentCheckbox)
      : -1 // No checkbox focused means we'll start going down from the input at the top
    const nextIndex = currentIndex + delta

    if (nextIndex < 0 || nextIndex >= checkboxes.length) return

    const nextCheckbox = checkboxes[nextIndex] as HTMLInputElement
    nextCheckbox.focus()
  }

  return (
    <FocusLock>
      <Flex
        ref={menuRef}
        pos="absolute"
        top={0}
        left={0}
        minW="240px"
        maxH="300px"
        direction="column"
        overflowX="hidden"
        overflowY="auto"
        zIndex="dropdown"
        borderWidth={1}
        rounded="md"
        shadow="md"
        bg="white"
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            onClose()
          } else if (e.key === "ArrowDown") {
            e.preventDefault()
            selectCheckbox(1)
          } else if (e.key === "ArrowUp") {
            e.preventDefault()
            selectCheckbox(-1)
          }

          if (e.currentTarget === inputRef.current) return

          if (MENU_NAVIGATION_KEYS.includes(e.key)) return

          e.stopPropagation()
          inputRef.current?.focus()
        }}
      >
        <Box
          position="sticky"
          top="0"
          bgColor="white"
          zIndex="1"
          lineHeight="10"
          fontWeight="semibold"
          fontSize="sm"
        >
          <Input
            autoFocus
            ref={inputRef}
            variant="filled"
            placeholder={selectedAttribute.label}
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Escape") onClose()

              // If you hit enter in the filter and there's only one visible option, select it
              if (e.key === "Enter" && filteredChoices.length === 1) {
                onMultiFilterChange(filteredChoices[0])
                onClose()
              }
            }}
            background="white"
            borderWidth="0 0 1px 0"
            borderColor="gray.200 !important"
            roundedBottom="none"
          />
        </Box>
        {filteredChoices.map((choice) => {
          return (
            <Box
              key={choice.value}
              p={0}
              _focusWithin={{
                bg: "gray.50",
              }}
              _hover={{
                bg: "gray.50",
              }}
            >
              <Checkbox
                variant="mdWithSmFont"
                isChecked={
                  (editingFilter &&
                    editingFilter.options.type === "multi" &&
                    editingFilter.options.choices.includes(choice)) ??
                  false
                }
                flexGrow={1}
                onChange={() => onMultiFilterChange(choice)}
                onKeyDown={(e) => {
                  // Hitting enter with a checkbox focused is the same as clicking the label
                  // (not the same as toggling the checkbox, which uses the space key)
                  if (e.key === "Enter") {
                    onMultiFilterLabelClick(choice)
                  }
                }}
                ps={3}
                sx={{
                  "& > .chakra-checkbox__label": {
                    flexGrow: 1,
                    ms: 2,
                  },
                }}
              >
                <Flex
                  w="100%"
                  align="center"
                  flexGrow={1}
                  onClick={(e) => {
                    // Don't want to trigger the checkbox change in this case
                    e.preventDefault()
                    onMultiFilterLabelClick(choice)
                  }}
                  gap={1.5}
                  py={3}
                >
                  {choice.icon}
                  <Text noOfLines={1}>{choice.label}</Text>
                </Flex>
              </Checkbox>
            </Box>
          )
        })}
      </Flex>
    </FocusLock>
  )
}
