import { ResponseFilter } from "../filters/types"

// ts-prune-ignore-next used in test
export function getFiltersWithCategories<
  Filters extends ResponseFilter,
  FilterCategory extends Filters["category"],
>(responseFilters: Filters[], filterCategories: FilterCategory[]) {
  return responseFilters.filter((responseFilter) =>
    filterCategories.some((category) => category === responseFilter.category)
  ) as Extract<Filters, { category: FilterCategory }>[]
}

export function getFiltersWithCategory<
  Filters extends ResponseFilter,
  FilterCategory extends Filters["category"],
>(responseFilters: Filters[], filterCategory: FilterCategory) {
  return getFiltersWithCategories<Filters, FilterCategory>(responseFilters, [
    filterCategory,
  ])
}

// ts-prune-ignore-next used in test
export function getFiltersWithTypes<
  Filters extends ResponseFilter,
  FilterType extends Filters["type"],
>(responseFilters: Filters[], types: FilterType[]) {
  return responseFilters.filter((responseFilter) =>
    types.some((type) => type === responseFilter.type)
  ) as Extract<Filters, { type: FilterType }>[]
}

export function getFiltersWithType<
  Filters extends ResponseFilter,
  FilterType extends Filters["type"],
>(responseFilters: Filters[], type: FilterType) {
  return getFiltersWithTypes<Filters, FilterType>(responseFilters, [type])
}
