import {
  Box,
  Flex,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { useInfiniteScroll } from "Hooks/use-infinite-scroll"
import { CustomerSupportMailtoLink } from "Shared/components/Links/CustomerSupportMailtoLink"
import { RoutedLink } from "Shared/components/Links/RoutedLink"
import { CreateNewModeratedStudyModal } from "UsabilityHub/components/CreateNewModeratedStudyModal/CreateNewModeratedStudyModal"
import { DashboardLoadingIndicator } from "UsabilityHub/components/DashboardLoadingIndicator/dashboard-loading-indicator"
import { ModeratedStudyEmptyState } from "UsabilityHub/components/ModeratedStudyEmptyState/ModeratedStudyEmptyState"
import { useInfiniteStudyList } from "UsabilityHub/hooks/useInfiniteStudyList"
import { useIsInterviewsEnabled } from "UsabilityHub/hooks/useIsInterviewsEnabled"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { useNavigate } from "react-router"
import { useTypedSearchParams } from "react-router-typesafe-routes/dom"
import { useListProjects } from "~/api/generated/usabilityhub-components"
import { ROUTES } from "../routes"
import { EmptyDashboard } from "./EmptyDashboard/EmptyDashboard"
import { RecentProjectList } from "./RecentProjectList"
import { StudyList } from "./StudyList"

const TABS = ["all", "tests", "interviews"]

export const DashboardList: React.FC = () => {
  const navigate = useNavigate()
  const interviewsEnabled = useIsInterviewsEnabled()
  const [{ tab }] = useTypedSearchParams(ROUTES.DASHBOARD)

  // The 'All studies' query always runs since it includes the counts for the other tabs
  const recentStudiesQuery = useInfiniteStudyList({})

  const recentTestsQuery = useInfiniteStudyList(
    {
      study_type: "usability_test",
    },
    {
      enabled: tab === "tests",
    }
  )

  const recentInterviewsQuery = useInfiniteStudyList(
    {
      study_type: "moderated_study",
    },
    {
      enabled: tab === "interviews",
    }
  )

  const recentProjectsQuery = useListProjects({
    queryParams: {
      sort_by: "updated_at",
      sort_direction: "desc",
      page_size: 5,
    },
  })

  const {
    open: openCreateNewModeratedStudyModal,
    close: closeCreateNewModeratedStudyModal,
  } = useModal(CreateNewModeratedStudyModal)

  const openCreateModal = () => {
    openCreateNewModeratedStudyModal({
      onCreate: (id) => {
        navigate(ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId: id }))
        closeCreateNewModeratedStudyModal()
      },
    })
  }

  const testInfiniteScrollerRef = useInfiniteScroll<HTMLDivElement>(
    recentTestsQuery.fetchNextPage
  )
  const allStudiesInfiniteScrollerRef = useInfiniteScroll<HTMLDivElement>(
    recentStudiesQuery.fetchNextPage
  )
  const interviewInfiniteScrollerRef = useInfiniteScroll<HTMLDivElement>(
    recentInterviewsQuery.fetchNextPage
  )

  const mainPageLoading =
    recentStudiesQuery.isLoading || recentProjectsQuery.isLoading

  const anyError = recentStudiesQuery.isError || recentProjectsQuery.isError

  const allRecentTests =
    recentTestsQuery.data?.pages.flatMap((page) => page.studies) ?? []
  const allRecentProjects = recentProjectsQuery.data?.projects ?? []
  const allRecentInterviewStudies =
    recentInterviewsQuery.data?.pages.flatMap((page) => page.studies) ?? []
  const allRecentStudies =
    recentStudiesQuery.data?.pages.flatMap((page) => page.studies) ?? []

  if (anyError) {
    return (
      <Flex direction="column" textAlign="center" gap={6}>
        <Heading>Something went wrong</Heading>
        <Text>
          <CustomerSupportMailtoLink>
            Send us an email
          </CustomerSupportMailtoLink>{" "}
          if you{"\u2019"}re having trouble and we{"\u2019"}ll help out.
        </Text>
      </Flex>
    )
  }

  // If there are no projects, tests, or studies, show the full page empty state.
  if (
    !mainPageLoading &&
    allRecentProjects.length === 0 &&
    allRecentStudies.length === 0
  ) {
    return <EmptyDashboard />
  }

  const queryForActiveTab = {
    interviews: recentInterviewsQuery,
    tests: recentTestsQuery,
    all: recentStudiesQuery,
  }[tab ?? "all"]

  const tabStyles = {
    _selected: {
      color: "ds.text.selected",
      borderColor: "ds.border.selected",
    },
    _focus: { boxShadow: "none" },
    _active: { bg: "none" },
    whitespace: "nowrap",
    pb: 5,
  }

  return (
    <Box w="full">
      <RecentProjectList
        projects={allRecentProjects}
        isLoading={
          // Until both queries finish we don't know which empty state to show
          recentProjectsQuery.isLoading ||
          (!recentProjectsQuery.isLoading &&
            allRecentProjects.length === 0 &&
            recentStudiesQuery.isLoading)
        }
      />

      <Tabs
        isLazy
        textStyle="ds.interface.large"
        borderBottom="transparent"
        index={tab === undefined ? 0 : TABS.indexOf(tab)}
      >
        <TabList>
          <Tab
            as={RoutedLink}
            to={ROUTES.DASHBOARD.buildPath({}, { tab: "all" })}
            data-intercom-target="dashboard-all-tab"
            whiteSpace="nowrap"
            {...tabStyles}
          >
            All studies
            <Text
              color={
                !tab || tab === "all" ? "ds.text.selected" : "ds.text.subtle"
              }
              ms={2}
            >
              {recentStudiesQuery.data?.pages[0].counts.total}
            </Text>
          </Tab>

          <Tab
            as={RoutedLink}
            to={ROUTES.DASHBOARD.buildPath({}, { tab: "tests" })}
            data-intercom-target="dashboard-tests-tab"
            {...tabStyles}
          >
            Tests
            <Text
              color={tab === "tests" ? "ds.text.selected" : "ds.text.subtle"}
              ms={2}
            >
              {recentStudiesQuery.data?.pages[0].counts.usability_test}
            </Text>
          </Tab>
          <Tooltip
            hasArrow
            rounded="sm"
            label="This feature is not available on your account."
            isDisabled={interviewsEnabled}
          >
            <Tab
              as={RoutedLink}
              to={ROUTES.DASHBOARD.buildPath({}, { tab: "interviews" })}
              isDisabled={!interviewsEnabled}
              data-intercom-target="dashboard-interviews-tab"
              {...tabStyles}
            >
              Interviews
              <Text
                color={
                  tab === "interviews" ? "ds.text.selected" : "ds.text.subtle"
                }
                ms={2}
              >
                {recentStudiesQuery.data?.pages[0].counts.moderated_study}
              </Text>
            </Tab>
          </Tooltip>

          <Flex w="full" justify="flex-end" align="center">
            <DashboardLoadingIndicator query={queryForActiveTab} />
          </Flex>
        </TabList>

        <TabPanels mt={4}>
          <TabPanel p={0}>
            <StudyList
              context="dashboard"
              studies={allRecentStudies}
              compactPrompt
              isLoading={mainPageLoading}
            />

            {recentStudiesQuery.isFetchingNextPage && (
              <Flex justify="center" mt={10}>
                <Spinner />
              </Flex>
            )}

            {recentStudiesQuery.hasNextPage && (
              <div ref={allStudiesInfiniteScrollerRef} />
            )}
          </TabPanel>

          <TabPanel p={0}>
            <StudyList
              studies={allRecentTests}
              context="dashboard"
              compactPrompt
              isLoading={mainPageLoading || recentTestsQuery.isLoading}
            />

            {recentTestsQuery.isFetchingNextPage && (
              <Flex justify="center" mt={10}>
                <Spinner />
              </Flex>
            )}

            {recentTestsQuery.hasNextPage && (
              <div ref={testInfiniteScrollerRef} />
            )}
          </TabPanel>

          <TabPanel p={0}>
            <StudyList
              context="dashboard"
              studies={allRecentInterviewStudies}
              compactPrompt
              isLoading={mainPageLoading || recentInterviewsQuery.isLoading}
              emptyStateSlot={
                <ModeratedStudyEmptyState handleCreateStudy={openCreateModal} />
              }
            />

            {recentInterviewsQuery.isFetchingNextPage && (
              <Flex justify="center" mt={10}>
                <Spinner />
              </Flex>
            )}

            {recentInterviewsQuery.hasNextPage && (
              <div ref={interviewInfiniteScrollerRef} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
