import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

import { Omit } from "Types"

import {
  Props as CheckboxListProps,
  QuestionCheckboxList,
} from "./question-checkbox-list"

type BaseProps = Omit<CheckboxListProps, "value" | "onChange">
type AdapterProps = BaseProps & WrappedFieldProps

function QuestionCheckboxListFieldAdapter(props: Readonly<AdapterProps>) {
  const {
    input,
    meta, // unused
    ...rest
  } = props
  return (
    <QuestionCheckboxList
      {...rest}
      value={input.value}
      onChange={input.onChange}
    />
  )
}

interface Props extends BaseProps {
  name: string
}

export function QuestionCheckboxListField(props: Readonly<Props>) {
  return <Field component={QuestionCheckboxListFieldAdapter} {...props} />
}
