import { Box } from "@chakra-ui/react"
import { DataPoint, Heatmap, create as createHeatmap } from "heatmap.js"
import React, { HTMLAttributes, useEffect, useRef } from "react"

interface Props extends HTMLAttributes<HTMLElement> {
  radius: number
  data: ReadonlyArray<DataPoint>
  width: number
  height: number
  max: number
  min?: number
}

export const HeatMapOld: React.FC<Props> = (props) => {
  const heatmap = useRef<Heatmap<string, string, string> | null>(null)
  const container = useRef(null)
  const { radius, data, min = 0, max, width, height } = props

  const setDimensions = (width: number, height: number) => {
    heatmap.current?._renderer.setDimensions(width, height)
  }

  const setData = (
    data: ReadonlyArray<DataPoint>,
    max: number,
    min: number
  ) => {
    heatmap.current?.setData({ data, min, max })
  }

  useEffect(() => {
    if (!heatmap.current && container.current) {
      heatmap.current = createHeatmap({
        radius,
        container: container.current,
      })
    }

    if (container.current) {
      heatmap.current?.configure({
        radius,
        container: container.current,
      })
    }
  }, [radius])

  useEffect(() => {
    if (width != null && height != null) {
      setDimensions(width, height)
    }

    setData(data, max, min)
  }, [width, height, data, min, max])

  // We use sx here to
  // - override the position: relative that heatmap.js adds to its container
  // - style the injected canvas element that heatmap.js creates
  return (
    <Box
      ref={container}
      top="0"
      left="0"
      w="100%"
      h="100%"
      sx={{
        position: "absolute !important",
        "& canvas": {
          width: "100%",
          height: "100%",
        },
      }}
    />
  )
}
