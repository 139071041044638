import { FormControl, FormLabel, Input } from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"
import { Fields, WrappedFieldsProps } from "redux-form"

import { getFormValue } from "Redux/reducers/test-builder-form/selectors/formValues"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { containsURLs } from "UsabilityHub/components/TestForm/validators/validateContainsUrls"

import { MarkdownFormLabel, MarkdownTextarea } from "../../TestFormMarkdown"

const WrappedWelcomeScreenFields: React.FC<
  React.PropsWithChildren<WrappedFieldsProps>
> = ({ welcome_heading, welcome_message, welcome_button_text }) => {
  const isEnabled = useSelector(getFormValue("customize_welcome")) as boolean
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <>
      <FormControl id={welcome_heading.input.name}>
        <FormLabel>Title</FormLabel>
        <Input
          isReadOnly={readOnly}
          isRequired={isEnabled}
          {...welcome_heading.input}
        />
      </FormControl>
      <FormControl id={welcome_message.input.name}>
        <MarkdownFormLabel>Message</MarkdownFormLabel>
        <MarkdownTextarea
          isReadOnly={readOnly}
          isRequired={isEnabled}
          {...welcome_message.input}
        />
      </FormControl>
      <FormControl id={welcome_button_text.input.name}>
        <FormLabel>Start button text</FormLabel>
        <Input
          isReadOnly={readOnly}
          isRequired={isEnabled}
          {...welcome_button_text.input}
          width={1 / 3}
        />
      </FormControl>
    </>
  )
}

export const WelcomeScreenFields: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <Fields
    names={["welcome_heading", "welcome_message", "welcome_button_text"]}
    component={WrappedWelcomeScreenFields}
    warn={containsURLs}
  />
)
