import { Flex, Spinner, Text } from "@chakra-ui/react"
import React from "react"

export const DisconnectedBanner = () => (
  <Flex
    justify="center"
    align="center"
    h={14}
    bg="red.600"
    color="white"
    gap={2}
  >
    <Text>Connection lost. Reconnecting&hellip;</Text>
    <Spinner size="xs" />
  </Flex>
)
