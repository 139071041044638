// - Uploading screenshots

export enum ActionType {
  SET_SCREENSHOTS = "SET_SCREENSHOTS",
  // The user has requested to upload a screenshot
  UPLOAD_SCREENSHOTS_REQUEST = "UPLOAD_SCREENSHOTS_REQUEST",

  // The screenshot has uploaded some amount to s3
  UPLOAD_SCREENSHOT_PROGRESS = "UPLOAD_SCREENSHOT_PROGRESS",

  // The screenshot upload to s3 has been completed
  UPLOAD_SCREENSHOT_UPLOADED = "UPLOAD_SCREENSHOT_UPLOADED",

  // The screenshot has been successfully saved to server, meaning the entire
  // process is complete. (except preloading)
  UPLOAD_SCREENSHOT_CREATED = "UPLOAD_SCREENSHOT_CREATED",

  // The screenshots have successfully finished their backend processing (this is
  // just generating a thumbnail)
  UPLOAD_SCREENSHOT_SUCCESS = "UPLOAD_SCREENSHOT_SUCCESS",

  // Any of the above steps failed.
  UPLOAD_SCREENSHOT_FAILURE = "UPLOAD_SCREENSHOT_FAILURE",

  // - Load screenshot

  LOAD_SCREENSHOT_REQUEST = "LOAD_SCREENSHOT_REQUEST",
  LOAD_SCREENSHOT_SUCCESS = "LOAD_SCREENSHOT_SUCCESS",
  LOAD_SCREENSHOT_FAILURE = "LOAD_SCREENSHOT_FAILURE",

  // - Modify screenshot

  UPDATE_SCREENSHOT_REQUEST = "UPDATE_SCREENSHOT_REQUEST",
  UPDATE_SCREENSHOT_SUCCESS = "UPDATE_SCREENSHOT_SUCCESS",
  UPDATE_SCREENSHOT_FAILURE = "UPDATE_SCREENSHOT_FAILURE",

  // - Screenshot viewed

  UPDATE_SCREENSHOT_VIEWED = "UPDATE_SCREENSHOT_VIEWED",

  // - Unpersisted

  UPDATE_UNPERSISTED_SCREENSHOT = "UPDATE_UNPERSISTED_SCREENSHOT",
}
