import { useMemo } from "react"

export const useSingleShot = () => {
  const called = useMemo(() => new Set<string>(), [])
  return (callback: () => void, key: string) => {
    if (called.has(key)) {
      return
    }
    called.add(key)
    callback()
  }
}
