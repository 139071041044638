import Constants from "Constants/shared.json"
import { Features } from "Types"
import { Plan } from "~/api/generated/usabilityhubSchemas"

type TestBuilderFeature = {
  label: (plan: Plan) => string | null
  tooltip?: string
  feature: Extract<
    Features[number]["feature"],
    | "max_test_duration"
    | "test_logic"
    | "custom_welcome_and_thankyou_messages"
    | "recordings"
  >
  identifier:
    | Extract<
        Features[number]["feature"],
        "max_test_duration" | "test_logic" | "recordings"
      >
    | "custom_welcome"
    | "custom_thankyou"
  minRequiredPlan?: string
  priority: number
}

// Check if two items in a list have the same priority
// Return the list if it's valid, otherwise return never
type UniquePriorities<List, Seen = never> = List extends readonly [
  infer Head,
  ...infer Tail,
]
  ? Head extends { priority: infer Priority }
    ? Priority extends Seen
      ? never
      : [Head, ...UniquePriorities<Tail, Priority | Seen>]
    : never
  : List

// At runtime this is just an identity function, but at
// compile time it provides type checking for unique priorities
// e.g.
//   const good = checkUniquePriorities([{ priority: 1 }, { priority: 2 }] as const)
//   const bad = checkUniquePriorities([{ priority: 1 }, { priority: 2 }, { priority: 2}] as const)
//   ^^ Type error: Argument of type '{ priority: 2; }[]' is not assignable to parameter of type 'never'.
const checkUniquePriorities = <T extends readonly unknown[]>(
  arr: UniquePriorities<T>
) => arr

export const TEST_BUILDER_FEATURES = checkUniquePriorities([
  {
    label: (plan) => (plan.recordings ? "Recordings" : null),
    tooltip: "Your test has recording enabled",
    feature: "recordings",
    identifier: "recordings",
    priority: 1,
  },
  {
    label: (plan) =>
      plan.max_test_duration
        ? `Tests up to ${plan.max_test_duration} mins`
        : "︎Unlimited test duration",
    tooltip: "Your test length exceeds 5 mins",
    feature: "max_test_duration",
    identifier: "max_test_duration",
    priority: 2,
  },
  {
    label: (plan) => (plan.test_logic ? "Conditional logic" : null),
    tooltip: "Conditional logic is not included in Basic plan",
    feature: "test_logic",
    identifier: "test_logic",
    priority: 3,
  },
  {
    label: (plan) =>
      plan.custom_welcome_and_thankyou_messages
        ? "︎Custom welcome screen"
        : null,
    feature: "custom_welcome_and_thankyou_messages",
    identifier: "custom_welcome",
    minRequiredPlan: Constants.BASIC_V3_MONTHLY_PLAN_UNIQUE_ID,
    priority: 4,
  },
  {
    label: (plan) =>
      plan.custom_welcome_and_thankyou_messages
        ? "︎︎Custom thank you screen"
        : null,
    feature: "custom_welcome_and_thankyou_messages",
    identifier: "custom_thankyou",
    minRequiredPlan: Constants.BASIC_V3_MONTHLY_PLAN_UNIQUE_ID,
    priority: 5,
  },
] as const satisfies Array<TestBuilderFeature>)

export const featurePriority = (feature: string) =>
  TEST_BUILDER_FEATURES.find((f) => f.feature === feature)?.priority ?? null
