import { useRef } from "react"

import { isBlank } from "Utilities/values"

export const useShouldAutoFocus = (value: string): boolean => {
  // Track the number of renders
  const renderCount = useRef(0)
  renderCount.current++

  // Autofocus should only happen on first render and when
  // there is no value
  return renderCount.current === 1 && isBlank(value)
}
