import React, { useEffect, useRef, useState } from "react"

import { ThemedButton } from "Components/button/themed-button"
import { ClickableScreenshotOverlay } from "Components/clickable-screenshot-overlay/clickable-screenshot-overlay"
import { DisplayInlineMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { useTranslate } from "Shared/hooks/useTranslate"
import { Point } from "Types"
import { ScreenshotMask } from "UsabilityHub/components/UsabilityTestSectionTask/ScreenshotMask"
import ConditionalScreenshotDeviceFrame from "UsabilityHub/components/UsabilityTestSectionTask/conditional-screenshot-device-frame"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"
import { reportErrorToSentry } from "Utilities/error"
import { isPointInRectangle } from "Utilities/rectangle"
import {
  isTaskComplete,
  isTaskStarted,
  sectionStartTime,
} from "Utilities/response"
import {
  SectionTask,
  SectionTaskContent,
  SectionTaskHeader,
  SectionTaskInstructions,
} from "../SectionTask"

export const NavigationTestTask: React.FC<
  React.PropsWithChildren<InnerProps>
> = ({
  responseSection,
  updateResponseSection,
  usabilityTestSection,
  deviceFrame,
  addScreenshotClick,
}) => {
  const translate = useTranslate()

  const [runningTaskDurationMs, setRunningTaskDurationMs] = useState<number>(0)
  const [currentClickStartTime, setCurrentClickStartTime] = useState<
    number | null
  >(null)
  const [screenshotIndex, setScreenshotIndex] = useState<number>(0)

  const usabilityTestSectionId = usabilityTestSection.id
  const sectionScreenshots = usabilityTestSection.section_screenshots
  const sectionScreenshot = sectionScreenshots[screenshotIndex]

  const contentRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const deviceFrameContentRef = useRef<HTMLDivElement>(null)

  const isStarted = isTaskStarted(responseSection)
  const isComplete = isTaskComplete(responseSection)

  // When the screenshot changes, scroll to the top.
  useEffect(() => {
    scrollUpToTop()
  }, [screenshotIndex])

  const handleClickStart = () => {
    const currentClickStartTime = performance.now()
    setCurrentClickStartTime(currentClickStartTime)
    setScreenshotIndex(0)

    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms:
        currentClickStartTime - sectionStartTime(responseSection),
    })
  }

  const handleClick = (point: Point, timestamp: number) => {
    const sectionScreenshot = sectionScreenshots[screenshotIndex]
    if (sectionScreenshot === undefined) {
      reportErrorToSentry(
        new Error(
          `screenshotIndex exceeds total screenshot count: ` +
            `${screenshotIndex} > ${sectionScreenshots.length}`
        )
      )
    }

    if (currentClickStartTime === null) {
      reportErrorToSentry(
        new Error(`added click before instructions were read`)
      )
      return
    }

    const clickDurationMs = timestamp - currentClickStartTime
    const isHit = sectionScreenshot.screenshot_hitzones.some(
      isPointInRectangle(point)
    )
    addScreenshotClick({
      duration_ms: clickDurationMs,
      hit: isHit,
      usability_test_section_screenshot_id: sectionScreenshot.id,
      ...point,
    })

    if (isHit && !(screenshotIndex === sectionScreenshots.length - 1)) {
      setRunningTaskDurationMs(runningTaskDurationMs + clickDurationMs)
      // NOTE: Don't use `timestamp` here because it doesn't include the time
      //       spent confirming the previous click.
      setCurrentClickStartTime(performance.now())
      setScreenshotIndex(screenshotIndex + 1)
    } else {
      updateResponseSection(usabilityTestSectionId, {
        task_duration_ms: runningTaskDurationMs + clickDurationMs,
      })
    }
  }

  const scrollUpToTop = () => {
    if (deviceFrameContentRef.current !== null) {
      // If we have a device frame, scroll it to the top, but leave the container
      // unchanged so that the device doesn't move around...
      deviceFrameContentRef.current.scrollTop = 0
    } else if (
      contentRef.current !== null &&
      containerRef.current !== null &&
      containerRef.current.parentElement !== null
    ) {
      // ...Otherwise scroll the container up so that the top of the image is
      // visible.
      containerRef.current.parentElement.scrollTop = Math.min(
        containerRef.current.scrollTop,
        contentRef.current.offsetTop
      )
    }
  }

  return (
    <SectionTask ref={containerRef}>
      <SectionTaskHeader>
        <SectionTaskInstructions variant={isComplete ? "secondary" : "primary"}>
          <DisplayInlineMarkdownText text={usabilityTestSection.text} />
        </SectionTaskInstructions>
        {!isComplete && (
          <SectionTaskInstructions variant="secondary">
            {translate("test.instructions.nav_flow_test.click_image")}
          </SectionTaskInstructions>
        )}
        {!isStarted && (
          <ThemedButton onClick={handleClickStart}>
            {translate("test.buttons.click_to_view_interface")}
          </ThemedButton>
        )}
      </SectionTaskHeader>
      <SectionTaskContent ref={contentRef}>
        <ConditionalScreenshotDeviceFrame
          key={sectionScreenshot.id}
          screenshotId={sectionScreenshot.screenshot_id}
          deviceFrame={deviceFrame}
          deviceFrameContentRef={deviceFrameContentRef}
          isScrollable={isStarted}
          canSkipAheadOnFirstWatch
        >
          {isStarted ? (
            <ClickableScreenshotOverlay
              onClick={handleClick}
              requireClickConfirmation={false}
            />
          ) : (
            <ScreenshotMask />
          )}
        </ConditionalScreenshotDeviceFrame>
      </SectionTaskContent>
    </SectionTask>
  )
}
