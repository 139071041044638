import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Stack,
} from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"

import { IndividualAnswerResult } from "Components/individual-response/individual-section-result/individual-answer-result/individual-answer-result"
import { NoResponse } from "Components/individual-response/individual-section-result/no-data"
import { getAllResponseAnswers } from "Redux/reducers/test-results/selectors"
import {
  SectionResultsQuestionHeading,
  SectionResultsQuestionText,
} from "UsabilityHub/components/TestResults/SectionResultsLabels"
import { QuestionProvider, useSectionContext } from "UsabilityHub/contexts"
import { sortBy } from "lodash"

interface QuestionsIndividualResultsProps {
  responseId: number
  // Optionally provide an allowlist and only show questions with IDs in the list.
  // Useful for when you want to show only some questions from the section, for example
  // Live Website Tests which have the questions divided over several tasks.
  questionIdAllowlist?: number[]
}

export const QuestionsIndividualResults: React.FC<
  React.PropsWithChildren<QuestionsIndividualResultsProps>
> = ({ responseId, questionIdAllowlist }) => {
  const { section } = useSectionContext()

  // Map ResponseAnswers into a question-indexed list.
  const responseAnswers = useSelector(getAllResponseAnswers).filter(
    (a) => a.response_id === responseId
  )

  const questions = questionIdAllowlist
    ? section.questions.filter((q) => questionIdAllowlist.includes(q.id))
    : section.questions

  const questionsAndAnswers = questions.map((q) => {
    const answer = responseAnswers.find(
      (a) => a.usability_test_section_question_id === q.id
    )!
    return { question: q, answer }
  })

  // Sort the questions in the order the participant saw them.
  const sortedQuestionsAndAnswers = sortBy(
    questionsAndAnswers,
    ({ answer }) => answer?.id
  )

  return (
    <Box>
      {section.questions_randomized && (
        <Alert mt={4}>
          <AlertIcon />
          <AlertDescription>
            The order of questions in this section was randomized
          </AlertDescription>
        </Alert>
      )}

      {sortedQuestionsAndAnswers.map(({ question, answer }, index) => {
        return (
          <QuestionProvider
            key={question.id}
            value={{ question, questionIndex: question.position }}
          >
            {index > 0 && (
              <Box pt={8}>
                <Box height="1px" bg="gray.200" mx={-8} />
              </Box>
            )}
            <Stack key={question.id} spacing={3} pt={8}>
              <SectionResultsQuestionHeading />
              <SectionResultsQuestionText />
              {answer ? (
                <IndividualAnswerResult question={question} answer={answer} />
              ) : (
                <NoResponse />
              )}
            </Stack>
          </QuestionProvider>
        )
      })}
    </Box>
  )
}
