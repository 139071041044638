import { ParticipantDeletionReason, ResponseDeletionReason } from "Types"

export function isParticipantDeletionReason(
  reason: ResponseDeletionReason
): reason is ParticipantDeletionReason {
  switch (reason) {
    case ParticipantDeletionReason.CanceledToStartAnotherResponse:
    case ParticipantDeletionReason.ConfusingTest:
    case ParticipantDeletionReason.ImagesFailedToLoad:
    case ParticipantDeletionReason.Inactive:
    case ParticipantDeletionReason.InappropriateTest:
    case ParticipantDeletionReason.IncorrectProfileLanguage:
    case ParticipantDeletionReason.IncorrectTestLanguage:
    case ParticipantDeletionReason.Skipped:
    case ParticipantDeletionReason.TechnicalProblem:
      return true
    default:
      return false
  }
}
