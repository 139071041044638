import { Box, useDisclosure } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import { LinkRecruitmentMethod } from "UsabilityHub/components/LinkRecruitmentMethod"
import { RecruitMethodSelection } from "UsabilityHub/components/RecruitMethodSelection/RecruitMethodSelection"
import { RecruitmentLinkFormModal } from "UsabilityHub/components/RecruitmentLink/RecruitmentLinkFormModal"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { UsabilityTestRecruitmentLinkSummaryCard } from "UsabilityHub/views/tests/$uniqueId/recruit/UsabilityTestRecruitmentLinkSummaryCard"
import { useTestSetRecruitLink } from "UsabilityHub/views/tests/$uniqueId/recruit/useUsabilityTestRecruitLink"
import React from "react"
import { VariationSet } from "~/api/generated/usabilityhubSchemas"
import { PanelRecruitmentUnavailable } from "./PanelRecruitmentUnavailable"

interface TestSetRecruitProps {
  testSet: VariationSet
}

export const TestSetRecruit: React.FC<TestSetRecruitProps> = ({ testSet }) => {
  const { isOpen: isLinkModalOpen, onOpen, onClose } = useDisclosure()
  const currentAccount = useCurrentAccount()
  const recruitmentLink = useTestSetRecruitLink(testSet.unique_id)

  const recordingTypes = [
    ...new Set(
      testSet.usability_tests.flatMap((test) => test.test_recording_types)
    ),
  ]

  return (
    <Box maxW="6xl" mx="auto">
      <RecruitMethodSelection mb={8}>
        <PanelRecruitmentUnavailable />
        <LinkRecruitmentMethod
          onManageLink={onOpen}
          recruitmentLink={recruitmentLink}
          isTestSetLink
        />
      </RecruitMethodSelection>

      {isLinkModalOpen && (
        <RecruitmentLinkFormModal
          account={currentAccount}
          onClose={onClose}
          linkable={testSet}
          isUpgraded={false}
          isTestSetLink
          recruitmentLink={recruitmentLink}
          recordingTypes={recordingTypes}
        />
      )}

      {recruitmentLink && recruitmentLink.active && (
        <>
          <Heading as="h2" textStyle="ds.display.primary" mb={5}>
            Recruitment activity
          </Heading>
          <UsabilityTestRecruitmentLinkSummaryCard
            testSetId={String(testSet.id)}
            recruitedResponseCount={testSet.recruited_response_count}
            onManageLink={onOpen}
            recruitmentLink={recruitmentLink}
          />
        </>
      )}
    </Box>
  )
}
