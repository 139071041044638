import { createIcon } from "@chakra-ui/react"
import React from "react"

export const SpinnerStaticIcon = createIcon({
  displayName: "SpinnerStaticIcon",
  viewBox: "0 0 100 100",
  path: (
    <g id="spinner-static">
      <g transform="rotate(0 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="1"
        />
      </g>
      <g transform="rotate(30 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="0.05"
        />
      </g>
      <g transform="rotate(60 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="0.1"
        />
      </g>
      <g transform="rotate(90 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="0.15"
        />
      </g>
      <g transform="rotate(120 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="0.2"
        />
      </g>
      <g transform="rotate(150 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="0.3"
        />
      </g>
      <g transform="rotate(180 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="0.4"
        />
      </g>
      <g transform="rotate(210 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="0.5"
        />
      </g>
      <g transform="rotate(240 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="0.6"
        />
      </g>
      <g transform="rotate(270 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="0.7"
        />
      </g>
      <g transform="rotate(300 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="0.8"
        />
      </g>
      <g transform="rotate(330 50 50)">
        <rect
          fill="currentColor"
          height="12"
          width="6"
          ry="6"
          rx="3"
          y="24"
          x="47"
          opacity="0.9"
        />
      </g>
      <g></g>
    </g>
  ),
})
