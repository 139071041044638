import classNames from "classnames"
import React, { FC } from "react"

import { FilterToggle } from "Components/test-results/section-results/filter-toggle"
import { useSectionContext } from "UsabilityHub/contexts"
import { backgroundSizePercentage } from "Utilities/css-helpers"
import { formatPercentage01 } from "Utilities/number"

import { useExactAnswersFilter } from "../hooks/use-exact-answers-filter"

import styles from "./linear-scale-question-results.module.scss"

interface ImplProps {
  count: number
  isFilter: boolean
  isFilterRequested: boolean
  isFiltered: boolean
  onFilterChange: (isFilter: boolean) => void
  percentage: number
  text: string
}

function Impl(props: Readonly<ImplProps>) {
  const {
    count,
    isFilter,
    isFilterRequested,
    isFiltered,
    onFilterChange,
    percentage,
    text,
  } = props
  return (
    <>
      <div
        className={classNames(
          styles.progressBar,
          isFilter && styles.isFilter,
          isFiltered && styles.isFiltered
        )}
        style={backgroundSizePercentage(1, percentage)}
      >
        <div>
          <div className={styles.percentage}>
            {formatPercentage01(percentage)}
          </div>
          <div className={styles.count}>{count}</div>
        </div>
        <div className={styles.label}>{text}</div>
      </div>
      <div className={styles.toggleContainer}>
        <FilterToggle value={isFilterRequested} onChange={onFilterChange} />
      </div>
    </>
  )
}

interface Props {
  questionId: number
  text: string
  percentage: number
  count: number
}

export const LinearScaleItem: FC<Props> = ({
  questionId,
  text,
  count,
  percentage,
}) => {
  const { section } = useSectionContext()
  const { isFilter, isFiltered, toggleAnswerFilter } = useExactAnswersFilter(
    section.id,
    questionId,
    text
  )
  return (
    <Impl
      count={count}
      percentage={percentage}
      text={text}
      isFilter={isFilter}
      isFilterRequested={isFilter}
      isFiltered={isFiltered}
      onFilterChange={toggleAnswerFilter}
    />
  )
}
