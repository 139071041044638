import { Button, Flex, Link, Text } from "@chakra-ui/react"
import { Dispatch } from "Redux/app-store"
import { addResponseAnswer } from "Redux/reducers/current-response/action-creators"
import { ResponseAnswer, Unpersisted } from "Types"
import UsabilityTestSectionQuestion from "UsabilityHub/components/UsabilityTestSectionQuestion/UsabilityTestSectionQuestion"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { PartipantTestSection } from "../../props"

type Props = {
  usabilityTestSection: PartipantTestSection
  currentTaskIndex: number
  handleNextTask: () => void
  onClose: () => void
  onReport: () => void
}

export const LiveWebsiteTestControlPanel: React.FC<Props> = ({
  usabilityTestSection,
  currentTaskIndex,
  handleNextTask,
  onClose,
  onReport,
}) => {
  const dispatch: Dispatch = useDispatch()
  const [questionIndex, setQuestionIndex] = useState<number | null>(null)

  if (!usabilityTestSection.live_website_test) {
    throw new Error("Live website test not found")
  }

  const tasks = usabilityTestSection.live_website_test.live_website_test_tasks

  if (currentTaskIndex >= tasks.length) {
    return (
      <Flex direction="column" p={4} gap={4} h="full">
        <Text>You have finished all the tasks.</Text>

        <Button colorScheme="brand.primary" onClick={onClose}>
          Fantastic
        </Button>

        <Text>
          If you encounter any problems, please{" "}
          <Link onClick={onReport}>report the study</Link>.
        </Text>
      </Flex>
    )
  }

  const task = tasks[currentTaskIndex]

  const nextStep = () => {
    if (questionIndex === null) {
      if (task.live_website_test_task_questions.length === 0) {
        handleNextTask()
      } else {
        setQuestionIndex(0)
      }
    } else if (
      questionIndex <
      task.live_website_test_task_questions.length - 1
    ) {
      setQuestionIndex(questionIndex + 1)
    } else {
      setQuestionIndex(null)
      handleNextTask()
    }
  }

  if (questionIndex !== null) {
    const questionId =
      task.live_website_test_task_questions[questionIndex]
        .usability_test_section_question_id
    const question = usabilityTestSection.questions.find(
      (q) => q.id === questionId
    )

    if (!question) {
      throw new Error(`Question ${questionId} not found`)
    }

    return (
      <Flex direction="column" justify="center" h="full" p={4}>
        <UsabilityTestSectionQuestion
          section={usabilityTestSection}
          question={question}
          onAnswerSubmit={(args: Readonly<Unpersisted<ResponseAnswer>>) => {
            dispatch(addResponseAnswer(args))
            nextStep()
          }}
        />
      </Flex>
    )
  }

  return (
    <Flex direction="column" p={4} gap={4} h="full">
      <Text fontWeight="bold">Task {currentTaskIndex + 1}</Text>

      <Text>{task.instructions}</Text>

      <Button colorScheme="brand.primary" onClick={nextStep}>
        Finished
      </Button>

      <Text>
        If you encounter any problems, please{" "}
        <Link onClick={onReport}>report the study</Link>.
      </Text>
    </Flex>
  )
}
