import { Response, TestResultsOrder } from "Types"
import { getSpecificResponseSource } from "Utilities/response-source"

import {
  NumberAttribute,
  ResponseAttribute,
  SourceAttribute,
  StringAttribute,
} from "../types/attributes"

function getAgeAttribute(response: Response): NumberAttribute | undefined {
  if (!response.response_demographic_profile?.age) {
    return undefined
  }

  return {
    category: "age",
    value: response.response_demographic_profile.age,
  }
}

function getLocationAttribute(response: Response): StringAttribute | undefined {
  const location = response.response_demographic_profile?.location

  if (!location) {
    return undefined
  }

  return {
    category: "location",
    value: `${location.type}-${location.id}`,
  }
}

function getSourceAttribute(
  orders: ReadonlyArray<TestResultsOrder>,
  response: Response
): SourceAttribute | undefined {
  const source = getSpecificResponseSource(orders, response)

  return {
    category: "source",
    value: source,
  }
}

function getDemographicAttributes(
  response: Response
): NumberAttribute[] | undefined {
  const demographicAttributeOptionIds =
    response.response_demographic_profile?.demographic_attribute_option_ids

  if (!demographicAttributeOptionIds) return undefined

  return demographicAttributeOptionIds.map((demographicAttributeOptionId) => {
    return {
      category: "demographic_attribute_option_id",
      value: demographicAttributeOptionId,
    }
  })
}

function getAttributes(
  response: Response,
  ...getAttributeFunction: Array<
    (response: Response) => ResponseAttribute | ResponseAttribute[] | undefined
  >
) {
  return getAttributeFunction
    .flatMap((fn) => fn(response))
    .filter(
      (attribute): attribute is ResponseAttribute => attribute !== undefined
    )
}

export function getResponseAttributes(
  orders: ReadonlyArray<TestResultsOrder>,
  response: Response
) {
  return getAttributes(
    response,
    getAgeAttribute,
    getLocationAttribute,
    (r) => getSourceAttribute(orders, r),
    getDemographicAttributes
  )
}
