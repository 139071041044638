import { Flex, Grid } from "@chakra-ui/react"
import { Heading, IconButton } from "DesignSystem/components"
import { FaceContentOutlineIcon } from "Shared/icons/untitled-ui/FaceContentOutlineIcon"
import { FaceFrownOutlineIcon } from "Shared/icons/untitled-ui/FaceFrownOutlineIcon"
import { FaceHappyOutlineIcon } from "Shared/icons/untitled-ui/FaceHappyOutlineIcon"
import { FaceNeutralOutlineIcon } from "Shared/icons/untitled-ui/FaceNeutralOutlineIcon"
import { FaceSadOutlineIcon } from "Shared/icons/untitled-ui/FaceSadOutlineIcon"
import React from "react"
import { Rating } from "./types"

export const SentimentPicker: React.FC<{
  submitRating: (sentiment: Rating) => void
  rating: Rating | null
}> = ({ submitRating, rating }) => {
  const handleSentimentRating = (chosenRating: Rating) => {
    if (chosenRating !== rating) submitRating(chosenRating)
  }

  const questionText =
    "How satisfied are you with the quality of our panel responses received for this study?"

  return (
    <Flex grow="1" flexDirection="column" gap={4} alignItems="stretch" py={2}>
      <Heading textStyle="ds.heading.primary" as="h3">
        {questionText}
      </Heading>
      <Grid gap={2} templateRows="1fr" templateColumns="repeat(5, 1fr)">
        <IconButton
          size="emphasized"
          w="full"
          icon={<FaceSadOutlineIcon boxSize={5} />}
          aria-label="very unsatisfied"
          onClick={() => handleSentimentRating(1)}
          isSelected={rating === 1}
        />
        <IconButton
          size="emphasized"
          w="full"
          icon={<FaceFrownOutlineIcon boxSize={5} />}
          aria-label="unsatisfied"
          onClick={() => handleSentimentRating(2)}
          isSelected={rating === 2}
        />
        <IconButton
          size="emphasized"
          w="full"
          icon={<FaceNeutralOutlineIcon boxSize={5} />}
          aria-label="neutral"
          onClick={() => handleSentimentRating(3)}
          isSelected={rating === 3}
        />
        <IconButton
          size="emphasized"
          w="full"
          icon={<FaceContentOutlineIcon boxSize={5} />}
          aria-label="satisfied"
          onClick={() => handleSentimentRating(4)}
          isSelected={rating === 4}
        />
        <IconButton
          size="emphasized"
          w="full"
          icon={<FaceHappyOutlineIcon boxSize={5} />}
          aria-label="very satisfied"
          onClick={() => handleSentimentRating(5)}
          isSelected={rating === 5}
        />
      </Grid>
      <Flex justifyContent="space-between" gap={2} color="ds.text.subtle">
        <Heading textStyle="ds.heading.subtle" as="h4">
          Very unsatisfied
        </Heading>
        <Heading textStyle="ds.heading.subtle" as="h4" textAlign="end">
          Very satisfied
        </Heading>
      </Flex>
    </Flex>
  )
}
