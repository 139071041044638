import { Textarea, TextareaProps } from "@chakra-ui/react"
import React, { TextareaHTMLAttributes } from "react"
import AutosizeTextarea from "react-autosize-textarea"
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form"

const TextareaAdapter: React.FC<
  React.PropsWithChildren<WrappedFieldProps & TextareaProps>
> = ({ input, onPaste, ...rest }) => (
  <Textarea as={AutosizeTextarea} {...input} {...rest} onPaste={onPaste} />
)

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> &
  BaseFieldProps<HTMLTextAreaElement> &
  TextareaProps

export const TextareaField: React.FC<React.PropsWithChildren<Props>> = ({
  warn,
  ...props
}) => (
  <Field
    component={TextareaAdapter}
    name={props.name}
    props={props}
    warn={warn}
  />
)
