import { Box, Flex, Icon, Text } from "@chakra-ui/react"
import { UploadIcon } from "@heroicons/react/outline"
import { FileInputButton } from "Components/form/file-input/file-input-button"
import { Dispatch } from "Redux/app-store"
import { addScreenshots } from "Redux/reducers/test-builder-form/action-creators/screenshots"
import {
  getMaximumFilesize,
  getMimeTypes,
  prettyFilesize,
  prettyMimeTypes,
} from "Services/media-types"
import { ValidateFilesResult } from "Services/validate-files"
import { FileDragAndDrop } from "Shared/components/FileDragAndDrop/FileDragAndDrop"
import { ScreenshotMediaType } from "Types"
import { useS3ConfigContext } from "UsabilityHub/components/TestForm/S3ConfigContext"
import { useSectionIndexContext } from "UsabilityHub/contexts"
import { pluralize } from "Utilities/string"
import React from "react"
import { useDispatch } from "react-redux"
import {
  MAX_HEIGHT,
  MAX_WIDTH,
} from "~/application/javascripts/constants/screenshot"

interface ScreenshotDropZoneProps {
  allowedMediaTypes: ScreenshotMediaType[]
  autoFocus?: boolean
  id: string
  maxFiles: number
}

export const ScreenshotDropZone: React.FC<
  React.PropsWithChildren<ScreenshotDropZoneProps>
> = ({ allowedMediaTypes, autoFocus, id, maxFiles }) => {
  const dispatch: Dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const s3Config = useS3ConfigContext()

  const hasAudioVideoUploadSupport =
    allowedMediaTypes.includes("video") && allowedMediaTypes.includes("audio")

  const handleValidateFilesResult = (
    validatedFiles: Readonly<ValidateFilesResult>
  ) => {
    void dispatch(addScreenshots(sectionIndex, validatedFiles, s3Config))
  }
  return (
    <FileDragAndDrop
      allowedMediaTypes={allowedMediaTypes}
      maxFiles={maxFiles}
      handleValidateFilesResult={(result: ValidateFilesResult) =>
        handleValidateFilesResult(result)
      }
    >
      {({ isOver }) => (
        <div>
          <Flex
            justify="space-between"
            bg={isOver ? "gray.100" : "transparent"}
            border="2px dashed"
            borderColor="gray.300"
            p={8}
            rounded="lg"
          >
            <Box>
              <Text fontWeight="semibold">
                {isOver
                  ? "Drop it here!"
                  : `Drag and drop to upload  ${pluralize(
                      maxFiles,
                      `a file`,
                      `files`
                    )}`}
              </Text>
              <Text color="gray.600">
                {prettyMimeTypes(allowedMediaTypes.flatMap(getMimeTypes))}{" "}
                supported
              </Text>
              <Text color="gray.500" fontSize="sm">
                Max image dimensions are {MAX_HEIGHT}x{MAX_WIDTH}. Max file size
                is {prettyFilesize(getMaximumFilesize("image"))}{" "}
                {hasAudioVideoUploadSupport &&
                  `for images and ${prettyFilesize(
                    getMaximumFilesize("video")
                  )} for video/audio`}
              </Text>
            </Box>
            <FileInputButton
              allowedMediaTypes={allowedMediaTypes}
              id={`${id}_button`}
              maxFiles={maxFiles}
              onFilesSelected={handleValidateFilesResult}
              autoFocus={autoFocus}
              leftIcon={<Icon as={UploadIcon} />}
            >
              Browse files
            </FileInputButton>
          </Flex>
        </div>
      )}
    </FileDragAndDrop>
  )
}
