import { Avatar, Flex, Icon, IconButton, Text } from "@chakra-ui/react"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import React from "react"

type BadgeUser = {
  name: string
  avatar_url_medium: string
}

const UserBadge: React.FC<{
  user: BadgeUser | undefined
  handleRemove: (() => void) | undefined
  showRemoveButton?: boolean
}> = ({ user, handleRemove, showRemoveButton = true }) => {
  return (
    <Flex
      align="center"
      borderWidth={1}
      borderColor="gray.200"
      rounded="md"
      overflow="hidden"
      gap={2}
      minHeight={showRemoveButton ? "auto" : "33px"}
      pe={showRemoveButton ? 0 : 2}
    >
      <Avatar
        size="sm"
        boxSize={6}
        fontSize="xs"
        name={user?.name ?? "Deleted user"}
        src={user?.avatar_url_medium}
        ms={2}
      />
      <Text
        color="text.primary"
        fontSize="sm"
        fontWeight="normal"
        whiteSpace="nowrap"
        lineHeight={1}
      >
        {user?.name ?? "Deleted user"}
      </Text>
      {showRemoveButton && (
        <IconButton
          icon={<Icon color="gray.500" boxSize={5} as={XOutlineIcon} />}
          size="sm"
          boxSize="30px"
          minW="30px"
          aria-label="Remove"
          rounded="none"
          borderLeftWidth={1}
          borderColor="gray.200"
          bg="gray.100"
          isDisabled={!handleRemove}
          onClick={handleRemove}
        />
      )}
    </Flex>
  )
}

export default UserBadge
