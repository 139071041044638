import { Flex, Input, Text, Textarea } from "@chakra-ui/react"
import React from "react"

type Props = {
  reportReason: string
  setReportReason: (reportReason: string) => void
  reportLink: string
  setReportLink: (reportLink: string) => void
}

export const ParticipantReportingForm: React.FC<Props> = ({
  reportReason,
  setReportReason,
  reportLink,
  setReportLink,
}) => {
  return (
    <Flex direction="column" gap={2}>
      <Text fontSize="md" fontWeight="medium" color="text.primary">
        Reason for reporting
      </Text>

      <Textarea
        value={reportReason}
        placeholder="Write your comments here"
        onChange={(e) => setReportReason(e.target.value)}
      />

      <Text fontSize="md" fontWeight="medium" color="text.primary" mt={4}>
        Video recording (Optional)
      </Text>

      <Input
        value={reportLink}
        placeholder="Paste your recording link here"
        onChange={(e) => setReportLink(e.target.value)}
      />

      <Text fontSize="sm" color="gray.500">
        Please ensure that viewing permissions for the video recording are
        enabled.
      </Text>
    </Flex>
  )
}
