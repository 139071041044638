import { FC, useMemo } from "react"
import React from "react"

import {
  ClickSelectionSummary as Impl,
  Props as ImplProps,
} from "Components/test-results/screenshot-click-results/click-selection-summary"
import { Omit, ScreenshotHitzone } from "Types"
import { useSectionContext } from "UsabilityHub/contexts"

import { useScreenshotOtherFilter } from "../hooks/use-screenshot-filter"
import { useScreenshotClientHitzones } from "../hooks/use-screenshot-zones"

import { useSummaryStats } from "./use-summary-props"

type Injected =
  | "averageDuration"
  | "chosenCount"
  | "chosenPercentage"
  | "clippingRectangle"
  | "isFilter"
  | "isFiltered"
  | "isFilterRequested"
  | "onFilterChange"
  | "name"

interface Props extends Omit<ImplProps, Injected> {
  sectionScreenshotId: number
  hitzones: ReadonlyArray<Readonly<ScreenshotHitzone>>
}

export const OtherSummary: FC<Props> = ({
  sectionScreenshotId,
  screenshot,
  hitzones,
}) => {
  const { section } = useSectionContext()
  const { isFilter, isFiltered, toggleFilter } = useScreenshotOtherFilter(
    section.id,
    sectionScreenshotId
  )

  const { screenshotZones } = useScreenshotClientHitzones(sectionScreenshotId)

  const filterOptions = useMemo(
    () => ({ exclude: [...hitzones, ...screenshotZones] }),
    [hitzones.length, screenshotZones]
  )
  const summaryStats = useSummaryStats(sectionScreenshotId, filterOptions)

  return (
    <Impl
      name="Other"
      clippingRectangle={null}
      isFilter={isFilter}
      isFilterRequested={isFilter}
      isFiltered={isFiltered}
      screenshot={screenshot}
      onFilterChange={toggleFilter}
      {...summaryStats}
    />
  )
}
