import * as React from "react"

import { UsabilityTestSectionQuestion } from "Types"

interface Question {
  question: UsabilityTestSectionQuestion
  questionIndex: number
}
const QuestionContext = React.createContext<Question | null>(null)

interface QuestionProviderProps {
  value: Question
}

/**
 * @param {QuestionProviderProps} props Props to pass to provider
 * @param {Question} props.value Initial state for context
 * @returns {React.ReactElement} Context Provider
 */
export const QuestionProvider: React.FC<
  React.PropsWithChildren<QuestionProviderProps>
> = ({ value, children }) => {
  return (
    <QuestionContext.Provider value={value}>
      {children}
    </QuestionContext.Provider>
  )
}

/**
 * Hook to access Question object from within a QuestionProvider.
 *
 * @returns {Question}
 */
export const useQuestionContext = (): Question => {
  const context = React.useContext(QuestionContext)

  if (!context) {
    throw new Error(
      `useQuestionContext must be rendered within the QuestionProvider`
    )
  }

  return context
}
