import { Box, Flex, Spinner, useToast } from "@chakra-ui/react"
import { NoPermissionWarning } from "Components/not-allowed-warning/not-allowed-warning"
import { useCanCurrentUserDo } from "Hooks/use-can-current-user-do"
import { Dispatch, State } from "Redux/app-store"
import { initializeFigmaFileVersions } from "Redux/reducers/figma-file-versions/actions"
import { setScreenshots } from "Redux/reducers/screenshots/action-creators"
import { getInitializeScreenshots } from "Redux/reducers/screenshots/helper"
import { initializeUsabilityTest } from "Redux/reducers/test-builder/action-creators/initialize-test"
import { DefinedRawTestBuilderState } from "Redux/reducers/test-builder/reducer"
import { PageMain } from "Shared/components/Page/Page"
import { LocaleProvider } from "Shared/contexts/LocaleContext"
import { useQueryStringParams } from "Shared/hooks/useQueryStringParams"
import {
  DeviceFrame,
  FigmaFileVersion,
  S3Config,
  Screenshot,
  Translations,
} from "Types"
import { TestForm } from "UsabilityHub/components/TestForm/TestForm"
import { FigmaOauthCredentialsProvider } from "UsabilityHub/contexts/FigmaOauthCredentialsContext"
import { usePromptConfiguration } from "UsabilityHub/contexts/PromptConfigurationContext"
import { ROUTES } from "UsabilityHub/views/routes"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router"
import { unstable_usePrompt } from "react-router-dom"
import { isDirty } from "redux-form"
import { fetchUsabilityTestNew } from "~/api/generated/usabilityhub-components"

interface InitUsabilityTestResponseData {
  current_user_has_figma_oauth_credentials: boolean
  device_frames: DeviceFrame[]
  figmaFileVersions: FigmaFileVersion[]
  test_builder: DefinedRawTestBuilderState
  screenshots: Screenshot[]
  i18n: Translations
  projects: string[]
  s3Config: S3Config
}

export const NewUsabilityTestRoute = () => {
  const dispatch: Dispatch = useDispatch()
  const toast = useToast()
  const queryParams = useQueryStringParams()
  const canUserManageTests = useCanCurrentUserDo("manage_tests")
  const testFormIsDirty = useSelector<State>(isDirty("test-form")) as boolean
  const { isPromptEnabled } = usePromptConfiguration()
  const [s3Config, setS3Config] = useState<S3Config | null>(null)
  const [deviceFrames, setDeviceFrames] = useState<DeviceFrame[]>([])
  const [
    currentUserHasFigmaOauthCredentials,
    setCurrentUserHasFigmaOauthCredentials,
  ] = useState<boolean>(false)

  const testSetIDParameter = queryParams.get("test_set_id")
  const testSetID = testSetIDParameter
    ? parseInt(testSetIDParameter)
    : undefined

  const [isInitialized, setIsInitialized] = useState(!canUserManageTests)
  const [error, setError] = useState<undefined | "redirect-to-dashboard">()

  const showTestForm = canUserManageTests && isInitialized

  useEffect(() => {
    if (!canUserManageTests) return

    const fetchData = async () => {
      const data = (await fetchUsabilityTestNew({
        // Filter out undefined query params
        queryParams: Object.fromEntries(
          Object.entries({
            name: queryParams.get("name") ?? undefined,
            template: queryParams.get("template") ?? undefined,
            test_set_id: testSetID,
          }).filter(([_, v]) => v !== undefined)
        ),
      }).catch((e) => {
        setError("redirect-to-dashboard")

        if (typeof e?.payload === "object" && "message" in e.payload) {
          toast({
            title: e.payload.message,
            status: "error",
          })
        } else {
          toast({
            title: "Lyssna has encountered an error while fetching data",
            status: "error",
          })
        }
      })) as InitUsabilityTestResponseData | undefined
      // TODO: We have to use `as` here because the OpenAPI definition for this
      // endpoint hasn't been completed

      if (!data) {
        return void setIsInitialized(true)
      }

      setCurrentUserHasFigmaOauthCredentials(
        data.current_user_has_figma_oauth_credentials
      )

      const initializedScreenshots = getInitializeScreenshots(data.screenshots)

      dispatch(initializeFigmaFileVersions(data.figmaFileVersions))
      dispatch(setScreenshots(initializedScreenshots))
      dispatch(
        initializeUsabilityTest(data.test_builder, initializedScreenshots)
      )

      setS3Config(data.s3Config)
      setDeviceFrames(data.device_frames)

      setIsInitialized(true)
    }

    void fetchData()
  }, [canUserManageTests])

  unstable_usePrompt({
    when: testFormIsDirty && isPromptEnabled,
    message: `You${"\u2019"}ve made changes to this test that haven${"\u2019"}t been saved. Are you sure you want to leave without saving these changes?`,
  })

  if (error === "redirect-to-dashboard") {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <PageMain>
      <Helmet>
        <title>New usability test</title>
      </Helmet>
      <Box width="100%">
        {!canUserManageTests && (
          <Box maxW={1024} mx="auto" my="7">
            <NoPermissionWarning permission="manage_tests" />
          </Box>
        )}
        {!isInitialized && (
          <Flex
            mt="32"
            justifyContent="center"
            alignItems="center"
            direction="column"
            gap="4"
          >
            <Spinner size="lg" thickness="4px" color="gray.500" />
            Building test...
          </Flex>
        )}

        {showTestForm && (
          <LocaleProvider keys={["permitted_device_type"]}>
            <FigmaOauthCredentialsProvider
              currentUserHasFigmaOauthCredentials={
                currentUserHasFigmaOauthCredentials
              }
            >
              <TestForm s3Config={s3Config!} deviceFrames={deviceFrames} />
            </FigmaOauthCredentialsProvider>
          </LocaleProvider>
        )}
      </Box>
    </PageMain>
  )
}
