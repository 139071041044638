import { Box, Flex, Image, Link, Text } from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import { Alert, Heading } from "DesignSystem/components"
import CameraPreview from "Shared/components/CameraPreview/CameraPreview"
import { useTranslate } from "Shared/hooks/useTranslate"
import { DisplayModal } from "UserCrowd/components/DisplayModal/DisplayModal"
import React, { useMemo } from "react"
import { useSectionRecordingContext } from "../../UsabilityTest/context/SectionRecordingContext"
import { useTestRecordingContext } from "../../UsabilityTest/context/TestRecordingContext"
import { RecordingVolumeIndicator } from "../RecordingVolumeIndicator"

import ScreenRecordingImage from "Images/app-illustrations/screen-recording.png"
import { InfoCircleIcon } from "Shared/icons/InfoCircleIcon"

type Props = {
  onContinue: () => void
  onShowThinkAloudDemo: () => void
  isDisabled: boolean
  justWatchedDemo?: boolean
}

export const NextSectionRecorded: React.FC<Props> = ({
  onContinue,
  isDisabled,
  justWatchedDemo = false,
  onShowThinkAloudDemo,
}) => {
  const { sectionRecordingTypes } = useSectionRecordingContext()

  const translate = useTranslate()

  const hasAudio = sectionRecordingTypes.includes("microphone")
  const hasCamera = sectionRecordingTypes.includes("camera")

  const watchVideoAlertContent = useMemo(() => {
    const text = translate("test.recording.think_aloud.rewatch")
    const [before, linkText, after] = text.split(/[\[\]]/) as [
      string,
      string,
      string,
    ]
    return (
      <>
        {before}
        <Link as="button" onClick={onShowThinkAloudDemo}>
          {linkText}
        </Link>
        {after}
      </>
    )
  }, [translate, onShowThinkAloudDemo])

  return (
    <DisplayModal.TwoPaneContent
      topHeight={["calc(100vw + 4rem)", "488px", "auto"]}
      content={
        <Flex
          flex={1}
          direction="column"
          gap={4}
          align="center"
          justify="center"
          background="ds.background.accent.gray.subtler"
          borderRadius={16}
          p={6}
          borderBottomRadius={{ base: 0, md: 16 }}
        >
          {hasAudio ? (
            <>
              <CameraAndAudioPreview camera={hasCamera} />
              <Text textStyle="ds.paragraph.primary" align="center">
                {translate(
                  `test.recording.microphone.camera_${hasCamera ? "on" : "off"}`
                )}
              </Text>
            </>
          ) : (
            <Image
              src={ScreenRecordingImage}
              maxW={300}
              alt="Screen recording enabled"
            />
          )}
        </Flex>
      }
      controls={
        <ThemedButton
          onClick={onContinue}
          width="fit-content"
          alignSelf="end"
          size="sm"
          isDisabled={isDisabled}
        >
          {translate("test.buttons.continue_and_start_recording")}
        </ThemedButton>
      }
    >
      <Flex direction="column" gap={4}>
        {hasAudio ? (
          <>
            <Heading as="h2" textStyle="ds.display.primary">
              {translate("test.recording.microphone.heading_think_aloud")}
            </Heading>
            <Text textStyle="ds.paragraph.emphasized">
              {translate("test.recording.microphone.prompt_think_aloud")}
            </Text>
            {!justWatchedDemo && (
              <Alert
                status="default"
                icon={InfoCircleIcon}
                description={watchVideoAlertContent}
                // Temporary workaround for mismatch between DS and Figma
                sx={{
                  "> * > :has(> .chakra-icon)": { display: "none" },
                }}
              />
            )}
          </>
        ) : (
          <>
            <Heading as="h2" textStyle="ds.display.primary">
              {translate("test.recording.section_prompt")}
            </Heading>
            <Text textStyle="ds.paragraph.emphasized">
              {translate("test.recording.screen.description")}
            </Text>
            <Text textStyle="ds.paragraph.primary" color="ds.text.subtle">
              {translate("test.recording.screen.prompt")}
            </Text>
          </>
        )}
      </Flex>
    </DisplayModal.TwoPaneContent>
  )
}

const CameraAndAudioPreview: React.FC<{ camera?: boolean }> = ({ camera }) => {
  const { videoDeviceId } = useTestRecordingContext()

  return (
    <Box
      display="grid"
      w="calc(100cqmin - 3rem)"
      aspectRatio={camera ? "1" : undefined}
      placeItems={camera ? "end" : "center"}
    >
      {camera && (
        <CameraPreview gridArea="1 / 1" deviceId={videoDeviceId} maxW="100%" />
      )}
      <RecordingVolumeIndicator
        gridArea="1 / 1"
        size="emphasized"
        zIndex={1}
        m={1}
      />
    </Box>
  )
}
