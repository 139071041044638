import { getSectionTypeRules } from "Constants/test-section-types"
import { UsabilityTestSection, UsabilityTestSectionQuestion } from "Types"
import { pluralize } from "Utilities/string"

const needsMoreQuestions = (
  questions: UsabilityTestSectionQuestion[],
  section: UsabilityTestSection
) => {
  const { minQuestions } = getSectionTypeRules(section.type)
  return questions.length < minQuestions
}

export const validateQuestions = (
  questions: UsabilityTestSectionQuestion[],
  section: UsabilityTestSection
): string | undefined => {
  if (needsMoreQuestions(questions, section)) {
    const { minQuestions } = getSectionTypeRules(section.type)
    return `There must be at least ${minQuestions} ${pluralize(
      minQuestions,
      "question",
      "questions"
    )} in this section`
  }
}
