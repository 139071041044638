import { createIcon } from "@chakra-ui/react"
import React from "react"

export const Translate01SolidIcon = createIcon({
  displayName: "Translate01SolidIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        d="M8.99998 3C8.99998 2.44772 8.55226 2 7.99998 2C7.44769 2 6.99998 2.44772 6.99998 3V4H1.99998C1.44769 4 0.999977 4.44772 0.999977 5C0.999977 5.55228 1.44769 6 1.99998 6H10.2791C9.81374 7.9747 9.03158 9.79672 7.97605 11.413C7.08671 10.5782 6.3325 9.58445 5.92113 8.61081C5.70619 8.10207 5.11952 7.8639 4.61078 8.07884C4.10204 8.29379 3.86388 8.88045 4.07882 9.38919C4.63256 10.6998 5.62061 11.981 6.77479 13.0255C5.3524 14.7065 3.59064 16.0928 1.55494 17.1045C1.06037 17.3503 0.858678 17.9505 1.10446 18.445C1.35025 18.9396 1.95043 19.1413 2.44501 18.8955C4.76111 17.7445 6.75889 16.1609 8.36356 14.2438C8.77034 14.5038 9.18231 14.7289 9.59016 14.9122C10.0939 15.1385 10.6858 14.9136 10.9121 14.4098C11.1385 13.906 10.9136 13.3142 10.4098 13.0878C10.1298 12.9621 9.84644 12.8103 9.56458 12.637C10.8733 10.6696 11.8153 8.42967 12.3282 6H14C14.5523 6 15 5.55228 15 5C15 4.44772 14.5523 4 14 4H11.5165C11.5047 3.99979 11.4928 3.99979 11.4811 4H8.99998V3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1523 9.30806C16.7402 9.10906 16.2598 9.10906 15.8477 9.30806C15.4892 9.48117 15.2949 9.78278 15.1983 9.94509C15.0972 10.1152 14.9934 10.3322 14.8915 10.5455L12.0199 16.5497C12.0134 16.5625 12.0072 16.5754 12.0013 16.5885L10.0979 20.5685C9.85957 21.0667 10.0703 21.6638 10.5685 21.9021C11.0668 22.1404 11.6638 21.9296 11.9021 21.4314L13.5433 17.9999H19.4567L21.0979 21.4314C21.3361 21.9296 21.9332 22.1404 22.4314 21.9021C22.9297 21.6638 23.1404 21.0667 22.9021 20.5685L20.9987 16.5885C20.9927 16.5754 20.9866 16.5625 20.9801 16.5497L18.1085 10.5455C18.0066 10.3323 17.9028 10.1151 17.8016 9.94509C17.7051 9.78278 17.5107 9.48117 17.1523 9.30806ZM16.5 11.8177L18.5002 15.9999H14.4998L16.5 11.8177Z"
        fill="currentColor"
      />
    </>
  ),
})
