import { trackTestCreated } from "JavaScripts/analytics/track"
import { State } from "Redux/app-store"
import { showErrorMessage, showNoticeMessage } from "Redux/reducers/flash"
import {
  SaveTestFormFailureAction,
  SaveTestFormRequestAction,
  SaveTestFormSuccessAction,
  SetIsPreviewClickedAction,
} from "Redux/reducers/test-builder/action-types"
import { Type } from "Redux/reducers/test-builder/constants"
import { addUsabilityTestClientFields } from "Redux/reducers/test-builder/helpers"
import { createPostPayload } from "Redux/reducers/test-builder/selectors/save"
import { AsyncThunkAction, UsabilityTest } from "Types"
import { ROUTES } from "UsabilityHub/views/routes"
import { reportErrorToSentry } from "Utilities/error"
import { compact, inRange } from "lodash"
import {
  fetchCreateUsabilityTest,
  fetchUpdateUsabilityTest,
} from "~/api/generated/usabilityhub-components"
import PreviewsApi from "~/api/previewsApi"

export const preview =
  (): AsyncThunkAction<State, Readonly<UsabilityTest>> => async (dispatch) => {
    // Open a new tab triggered by the click event to avoid popup blocker issues.
    //
    // If the browser fails to open a new window, just use the current window
    // instead.
    //
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/open#Return_value
    const previewWindow = window.open("", "_blank") || window

    try {
      const usabilityTest = await dispatch(save())
      const uniqueId = usabilityTest.unique_id

      // Replace this tab's href (we may now have a unique_id if the test was just created)
      const path = ROUTES.TEST.EDIT.buildPath({ testId: uniqueId })
      window.history.replaceState({ path }, "", path)

      // Load the preview in the desired window.
      previewWindow.location.href = PreviewsApi.test.path({
        unique_id: uniqueId,
      })

      return usabilityTest
    } catch (error) {
      // If the save failed then close the preview window immediately.
      if (previewWindow !== window) {
        previewWindow.close()
      }
      throw error
    }
  }

// TODO: Remove use of `createAction` here... Consider preventing saving with errors so that we
//       don't return `null`.
export const save =
  (): AsyncThunkAction<State, Readonly<UsabilityTest>> =>
  async (dispatch, getState) => {
    try {
      dispatch<SaveTestFormRequestAction>({ type: Type.SAVE_TEST_FORM_REQUEST })

      // Structure data as the server expects and either create or update the test.
      const data = createPostPayload(getState())

      let rawUsabilityTest = null
      if (data.unique_id != null) {
        const responseData = await fetchUpdateUsabilityTest({
          pathParams: { usabilityTestId: data.unique_id },
          body: data,
        })

        rawUsabilityTest = responseData
      } else {
        const responseData = await fetchCreateUsabilityTest({
          body: data,
        })

        // NOTE: We've switched this over to use the generated "fetch" functions.
        // We haven't finished fully typing the endpoints in OpenAPI so we need
        // to use cast to `any`
        rawUsabilityTest = responseData as any
        trackTestCreated({ ...rawUsabilityTest, duplicate: false })
      }

      // Add client relation keys.
      const usabilityTest = addUsabilityTestClientFields(
        rawUsabilityTest,
        getState().screenshots
      )

      dispatch<SaveTestFormSuccessAction>({
        type: Type.SAVE_TEST_FORM_SUCCESS,
        payload: {
          _lastSavedAt: new Date(),
          ...usabilityTest,
        },
      })
      dispatch(showNoticeMessage("Your test was successfully saved."))
      return usabilityTest
    } catch (error) {
      let errorMessage: string | null = null
      if (
        typeof error?.payload === "object" &&
        "message" in error.payload &&
        inRange(error.status, 400, 500)
      ) {
        errorMessage = error.payload.message
      } else {
        reportErrorToSentry(error)
      }
      dispatch(
        showErrorMessage(
          compact([
            "Sorry, we were unable to save your test",
            errorMessage,
          ]).join(": "),
          5000
        )
      )
      dispatch<SaveTestFormFailureAction>({ type: Type.SAVE_TEST_FORM_FAILURE })
      throw error
    }
  }

export function setIsPreviewClicked(
  isPreviewClicked: boolean
): SetIsPreviewClickedAction {
  return {
    type: Type.SET_TEST_FORM_IS_PREVIEW_CLICKED,
    payload: isPreviewClicked,
  }
}
