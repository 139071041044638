import { Flex, Input } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import React from "react"
import { FeedbackContent, FeedbackText } from "./FeedbackContent"
import { extractSentiment } from "./extractSentiment"
import { Rating, RatingFormSentiment } from "./types"

const textContent: Record<RatingFormSentiment, FeedbackText> = {
  positive: {
    primaryText: "Thank you for your feedback!",
    secondaryText: "We\u2019d love to know what made your experience great.",
  },
  neutral: {
    primaryText: "We appreciate your feedback",
    secondaryText:
      "Help us improve by telling us the main reasons for your rating.",
  },
  negative: {
    primaryText: "Sorry to hear about your experience",
    secondaryText: "Help us improve by telling us the reason for your rating.",
  },
}

export const AskForFeedback: React.FC<{
  rating: Rating | null
  submitFeedback: (comment: string) => void
  skipFeedback: () => void
}> = ({ rating, submitFeedback, skipFeedback }) => {
  const [comment, setComment] = React.useState("")
  const sentiment = rating ? extractSentiment(rating) : "neutral"
  const { primaryText, secondaryText } = textContent[sentiment]

  const handleSubmit = () => {
    if (comment.length > 0) submitFeedback(comment)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value)
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleSubmit()
  }

  return (
    <FeedbackContent
      primaryText={primaryText}
      secondaryText={secondaryText}
      sentiment={sentiment}
    >
      <Flex columnGap={2} rowGap={4} flexWrap="wrap">
        <Input
          size="sm"
          rounded="8px"
          borderColor="ds.border.input"
          _hover={{
            borderColor: "ds.border.input",
            bg: "ds.background.input.hovered",
          }}
          _focus={{
            borderColor: "ds.border.focused",
            bg: "ds.background.input.resting",
            boxShadow: "none",
          }}
          maxInlineSize="30rem"
          flexBasis="16rem"
          flexGrow="1"
          placeholder="Please tell us why…"
          value={comment}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
        />
        <Flex gap={2}>
          <Button
            variant="primary"
            onClick={handleSubmit}
            isDisabled={comment.length === 0}
          >
            Submit
          </Button>
          <Button onClick={skipFeedback}>Skip</Button>
        </Flex>
      </Flex>
    </FeedbackContent>
  )
}
