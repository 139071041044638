import {
  QuestionType,
  TestLogicBehaviour,
  TestLogicComparator,
  TestLogicStatement,
  UsabilityTestSection,
  UsabilityTestSectionOrQuestion,
  UsabilityTestSectionType,
} from "Types"

import { isMarkedForDestruction } from "./destructible"
import { isPrototypeTaskFlow } from "./get-prototype-type"

const TEST_LOGIC_TARGET_OPTIONS: ReadonlyArray<
  QuestionType | UsabilityTestSectionType
> = [
  QuestionType.LinearScale,
  QuestionType.RadioMultipleChoice,
  QuestionType.CheckboxMultipleChoice,
  UsabilityTestSectionType.PreferenceTest,
]

export const isTestLogicTargetOption = (
  field: UsabilityTestSectionOrQuestion
): boolean => {
  return (
    TEST_LOGIC_TARGET_OPTIONS.includes(field.type) ||
    (field.type === UsabilityTestSectionType.PrototypeTask &&
      isPrototypeTaskFlow(field))
  )
}

// Checks whether test logic is present and not marked for destruction
export const isTestLogicEnabled = (
  testLogicStatement: TestLogicStatement | null | undefined
): testLogicStatement is TestLogicStatement =>
  testLogicStatement ? !isMarkedForDestruction(testLogicStatement) : false

// Given a section, checks whether test logic is present either on the
// section itself or on any of its questions
export const sectionContainsTestLogic = (section: UsabilityTestSection) =>
  isTestLogicEnabled(section.test_logic_statement) ||
  section.questions.some((question) =>
    isTestLogicEnabled(question.test_logic_statement)
  )

export const createTestLogicStatement = (): TestLogicStatement => {
  return {
    _destroy: false,
    _targetClientId: null,
    id: null,
    target_id: null,
    target_type: null,
    behaviour: TestLogicBehaviour.SHOW,
    comparator: TestLogicComparator.EQ,
    values: [],
  }
}
