import { Box, Stack } from "@chakra-ui/react"
import React, { ComponentType } from "react"
import { useSelector } from "react-redux"

import { getAllResponseSections } from "Redux/reducers/test-results/selectors"
import { UsabilityTestSectionType } from "Types"
import {
  SectionResultsSectionHeading,
  SectionResultsSectionText,
} from "UsabilityHub/components/TestResults/SectionResultsLabels"
import { useSectionContext } from "UsabilityHub/contexts"

import { QuestionsIndividualResults } from "./QuestionsIndividualResults"
import { CardSortSectionIndividualResults } from "./SectionTaskIndividualResults/CardSortSectionIndividualResults"
import { DesignSurveySectionIndividualResults } from "./SectionTaskIndividualResults/DesignSurveySectionIndividualResults"
import { FirstClickSectionIndividualResults } from "./SectionTaskIndividualResults/FirstClickSectionIndividualResults/FirstClickSectionIndividualResults"
import { FiveSecondSectionIndividualResults } from "./SectionTaskIndividualResults/FiveSecondSectionIndividualResults"
import { LiveWebsiteTestIndividualResults } from "./SectionTaskIndividualResults/LiveWebsiteTestIndividualResults"
import { NavigationSectionIndividualResults } from "./SectionTaskIndividualResults/NavigationSectionIndividualResults/NavigationSectionIndividualResults"
import { PreferenceSectionIndividualResults } from "./SectionTaskIndividualResults/PreferenceSectionIndividualResults"
import { PrototypeSectionIndividualResults } from "./SectionTaskIndividualResults/PrototypeSectionIndividualResults"
import { TreeTestSectionIndividualResults } from "./SectionTaskIndividualResults/TreeTestSectionIndividualResults"
import { TaskProps } from "./SectionTaskIndividualResults/task-props"

function getTaskComponent(
  type: UsabilityTestSectionType
): ComponentType<React.PropsWithChildren<TaskProps>> | null {
  switch (type) {
    case UsabilityTestSectionType.CardSort:
      return CardSortSectionIndividualResults
    case UsabilityTestSectionType.PreferenceTest:
      return PreferenceSectionIndividualResults
    case UsabilityTestSectionType.DesignQuestions:
      return DesignSurveySectionIndividualResults
    case UsabilityTestSectionType.FiveSecondTest:
      return FiveSecondSectionIndividualResults
    case UsabilityTestSectionType.FirstClickTest:
      return FirstClickSectionIndividualResults
    case UsabilityTestSectionType.NavigationTest:
      return NavigationSectionIndividualResults
    case UsabilityTestSectionType.PrototypeTask:
      return PrototypeSectionIndividualResults
    case UsabilityTestSectionType.TreeTest:
      return TreeTestSectionIndividualResults
    case UsabilityTestSectionType.LiveWebsiteTest:
      return LiveWebsiteTestIndividualResults
    // Some section types have no task.
    case UsabilityTestSectionType.Information:
    case UsabilityTestSectionType.Questions:
    case UsabilityTestSectionType.ExternalStudy:
      return null
  }
}

interface IndividualSectionResultsProps {
  responseId: number
}

// Some section types handle showing their own questions, for example the Live Website Tests
// break them up by task, so we don't show the default question list for those.
const SECTION_TYPES_WITHOUT_QUESTIONS = new Set([
  UsabilityTestSectionType.LiveWebsiteTest,
])

export const IndividualSectionResult: React.FC<
  React.PropsWithChildren<IndividualSectionResultsProps>
> = ({ responseId }) => {
  const { section } = useSectionContext()

  // Get the ResponseSection for this response.
  const responseSection =
    useSelector(getAllResponseSections)
      .filter((s) => s.response_id === responseId)
      .find((rs) => rs.usability_test_section_id === section.id) || null

  const TaskComponent = getTaskComponent(section.type)
  return (
    <Box p={8}>
      <Stack spacing={5}>
        <SectionResultsSectionHeading />
        <SectionResultsSectionText />
        {TaskComponent && (
          <TaskComponent
            responseSection={responseSection}
            usabilityTestSection={section}
          />
        )}
      </Stack>

      {!SECTION_TYPES_WITHOUT_QUESTIONS.has(section.type) && (
        <QuestionsIndividualResults responseId={responseId} />
      )}
    </Box>
  )
}
