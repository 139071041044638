import React from "react"
import { connect } from "react-redux"

import { State } from "Redux/app-store"
import { createHasQuestionEverReceivedOtherAnswer } from "Redux/reducers/test-results/selectors"
import { CheckboxQuestion, Omit, ResponseAnswer, SortMethod } from "Types"

import { AnswerCountItem } from "./answer-count-item"
import { AnswerCounts } from "./answer-counts"
import { emptyCounts, getKey, otherSymbol } from "./helpers"

type Answer = string | typeof otherSymbol

function countCheckboxAnswers(
  question: CheckboxQuestion,
  answers: ReadonlyArray<ResponseAnswer>,
  includeOther: boolean
): Map<Answer, number> {
  const result: Map<Answer, number> = emptyCounts(
    question.multiple_choice_options
  )
  if (includeOther) {
    result.set(otherSymbol, 0)
  }
  return answers.reduce((acc, answer) => {
    let hasOther = false
    answer.answers.forEach((a) => {
      if (acc.has(a)) {
        acc.set(a, acc.get(a)! + 1)
      } else {
        hasOther = true
      }
    })
    if (hasOther) {
      acc.set(otherSymbol, acc.get(otherSymbol)! + 1)
    }
    return acc
  }, result)
}

interface Props {
  question: CheckboxQuestion
  answers: ReadonlyArray<ResponseAnswer>
  sortMethod: SortMethod
  includeOther: boolean
}

const CheckboxAnswerCountsImpl: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { answers, includeOther, question, sortMethod } = props
  return (
    <AnswerCounts
      countItemComponent={AnswerCountItem}
      questionId={question.id}
      countByAnswer={countCheckboxAnswers(question, answers, includeOther)}
      responseCount={answers.length}
      sortMethod={sortMethod}
      getKey={getKey}
    />
  )
}

export const CheckboxAnswerCounts = connect(() => {
  const hasQuestionEverReceivedOtherAnswer =
    createHasQuestionEverReceivedOtherAnswer()
  return (state: State, { question }: Omit<Props, "includeOther">) => ({
    includeOther:
      question.has_other_option ||
      hasQuestionEverReceivedOtherAnswer(state, question.id),
  })
})(CheckboxAnswerCountsImpl)
