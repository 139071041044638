import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { ChangeSubscriptionPlanForm } from "UsabilityHub/views/settings/billing/ChangeSubscriptionPlanForm"
import { billingPeriodForPlan, planCostPerInterval } from "Utilities/plan"
import React from "react"
import { Plan } from "~/api/generated/usabilityhubSchemas"

type ConfirmBillingPeriodChangeModal = React.FC<
  React.PropsWithChildren<{
    isOpen: boolean
    onClose: () => void
    currentPlan: Plan
    newPlan: Plan
    subscriptionId: string
  }>
>

export const ConfirmBillingPeriodChangeModal: ConfirmBillingPeriodChangeModal =
  ({ isOpen, onClose, currentPlan, newPlan, subscriptionId }) => {
    const currentPlanPeriod = billingPeriodForPlan(currentPlan)
    const newPlanPeriod = billingPeriodForPlan(newPlan)
    const titleCasePeriod =
      newPlanPeriod.charAt(0).toUpperCase() + newPlanPeriod.slice(1)

    const paidSeatCount = useTeamMembers({
      ignoreRoles: ["guest", "archived"],
    }).length

    const isAnnual = newPlanPeriod === "annual"
    const pricePerPeriod = planCostPerInterval(newPlan, paidSeatCount)

    return (
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Change billing cycle</ModalHeader>
            <ModalBody>
              <Stack spacing={2}>
                <Text>
                  {titleCasePeriod} pricing is{" "}
                  <strong>
                    {pricePerPeriod} (USD) / {newPlan.interval}
                  </strong>{" "}
                  for your team.
                </Text>
                <Text>
                  Your account will automatically receive a credit for time left
                  on your {currentPlanPeriod} subscription
                  {isAnnual &&
                    ` and you${"\u2019"}ll be billed immediately for the annual subscription, minus this credit`}
                  .
                </Text>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <ChangeSubscriptionPlanForm
                buttonText={`Switch to ${newPlanPeriod} billing`}
                onClose={onClose}
                planUniqueId={newPlan.unique_id}
                subscriptionId={subscriptionId}
              />
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    )
  }
