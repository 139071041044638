import isSafari from "is-safari"

export async function fetchImage(url: string) {
  return new Promise<HTMLImageElement>((resolve) => {
    const image = new Image()

    image.onload = () => {
      resolve(image)
    }
    if (!isSafari) {
      image.crossOrigin = "anonymous"
    }
    image.src = url
  })
}
