import { FigmaFileVersion, NoopAction } from "Types"

import { Action, ActionTypes } from "./actions"

export type FigmaFileVersionsState = ReadonlyArray<Readonly<FigmaFileVersion>>

export const figmaFileVersions = (
  state: FigmaFileVersionsState = [],
  action: Action | NoopAction = { type: "__NOOP__" }
): FigmaFileVersionsState => {
  switch (action.type) {
    case ActionTypes.INIT_FIGMA_FILE_VERSIONS:
      return action.payload
    case ActionTypes.ADD_FIGMA_FILE_VERSION:
      // Filter out the FigmaFileVersion if it's already in there, then add it.
      // This ensures we have the latest version across all sections.
      return [
        ...state.filter((ffv) => ffv.id !== action.payload.id),
        action.payload,
      ]
    case ActionTypes.REMOVE_FIGMA_FILE_VERSION:
      return [...state.filter((ffv) => ffv.id !== action.payload)]
    default:
      return state
  }
}
