import { Box, Flex, Select, Text } from "@chakra-ui/react"
import CameraPreview from "Shared/components/CameraPreview/CameraPreview"
import { useTranslate } from "Shared/hooks/useTranslate"
import { MicrophoneIcon } from "Shared/icons/MicrophoneIcon"
import { Webcam02OutlineIcon } from "Shared/icons/Webcam02OutlineIcon"
import { ParticipantDeletionReason } from "Types"
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { useTestRecordingContext } from "../../context/TestRecordingContext"
import { StepContentProps } from "./RecordingSetupGuideContent"
import { SoundMeter } from "./SoundMeter"

// Would love to use Extract here but it doesn't work with generated properties
type AudioDeviceInfo = Omit<MediaDeviceInfo, "kind"> & { kind: "audioinput" }
type VideoDeviceInfo = Omit<MediaDeviceInfo, "kind"> & { kind: "videoinput" }

const isAudioDeviceInfo = (
  device: MediaDeviceInfo
): device is AudioDeviceInfo => device.kind === "audioinput"

const isVideoDeviceInfo = (
  device: MediaDeviceInfo
): device is VideoDeviceInfo => device.kind === "videoinput"

export const DeviceSelection: React.FC<StepContentProps> = ({
  setPassable,
}) => {
  const {
    allowedRecordingTypes,
    audioDeviceId,
    setAudioDeviceId,
    videoDeviceId,
    setVideoDeviceId,
    setError,
  } = useTestRecordingContext()
  const [audioDevices, setAudioDevices] = useState<AudioDeviceInfo[]>([])
  const [videoDevices, setVideoDevices] = useState<VideoDeviceInfo[]>([])
  const attempts = useRef(0)

  const hasCamera = allowedRecordingTypes.includes("camera")

  const findAndSetDevices = () =>
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const audioDeviceList = devices.filter(isAudioDeviceInfo)
        const videoDeviceList = devices.filter(isVideoDeviceInfo)
        setAudioDeviceId && setAudioDeviceId(audioDeviceList[0]?.deviceId)
        hasCamera &&
          setVideoDeviceId &&
          setVideoDeviceId(videoDeviceList[0]?.deviceId)
        setAudioDevices(audioDeviceList)
        setVideoDevices(videoDeviceList)
        setPassable(true)
      })
      .catch(() => {
        setError(ParticipantDeletionReason.RecordingPermissionDenied)
      })

  useEffect(() => {
    findAndSetDevices()
  }, [])

  const allDevices = useMemo(
    () => [...audioDevices, ...videoDevices],
    [audioDevices, videoDevices]
  )

  useEffect(() => {
    // If there are no devices or the device names aren't loaded on the first try
    // (looking at you, Firefox)
    // then try again a very short 100ms delay for up to 10 attempts (1 second total)
    if (
      attempts.current <= 10 &&
      (allDevices.length === 0 || allDevices.filter((d) => !d.label))
    ) {
      const timeout = setTimeout(() => {
        findAndSetDevices()
        attempts.current += 1
      }, 100)

      return () => clearTimeout(timeout)
    }
  }, [allDevices])

  const translate = useTranslate()

  return (
    <>
      <Box>{translate("test.recording.microphone.selection_body")}</Box>

      {hasCamera && <CameraPreview deviceId={videoDeviceId} />}

      <Flex align="center" gap={4}>
        <Box fontWeight="medium" fontSize="sm" whiteSpace="nowrap">
          {translate("test.recording.microphone.selection_input")}
        </Box>
        <SoundMeter />
      </Flex>

      <Flex gap={4} align="center">
        {hasCamera && (
          <DeviceSelect
            type="camera"
            icon={<Webcam02OutlineIcon />}
            devices={videoDevices}
            value={videoDeviceId}
            onChange={(value) => setVideoDeviceId?.(value)}
          />
        )}
        <DeviceSelect
          type="microphone"
          icon={<MicrophoneIcon />}
          devices={audioDevices}
          value={audioDeviceId}
          onChange={(value) => setAudioDeviceId?.(value)}
        />
      </Flex>
    </>
  )
}

type DeviceSelectProps<T extends AudioDeviceInfo | VideoDeviceInfo> = {
  type: "microphone" | "camera"
  icon: ReactNode
  devices: T[]
  value: T["deviceId"] | undefined
  onChange: (deviceId: T["deviceId"]) => void
}

const DeviceSelect = <T extends AudioDeviceInfo | VideoDeviceInfo>({
  type,
  icon,
  devices,
  value,
  onChange,
}: DeviceSelectProps<T>) => {
  const translate = useTranslate()

  return (
    <Flex direction="column" gap={2} flex="1 0 0">
      <Text fontWeight="medium">
        {translate(`test.recording.${type}.selection_type`)}
      </Text>
      <Flex align="center" position="relative">
        <Flex position="absolute" left={3.5} zIndex={1}>
          {icon}
        </Flex>

        <Select
          bg="gray.50"
          w="fit-content"
          cursor="pointer"
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
          sx={{
            "--input-padding": "36px",
            pt: "0px",
            "&:focus": {
              bg: "gray.50",
            },
          }}
        >
          {devices.map((device, index) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || `${type} ${index + 1}`}
            </option>
          ))}
        </Select>
      </Flex>
    </Flex>
  )
}
