// JsFromRoutes CacheKey 8284e220e19b2104912e7043965319e5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  suspended: /* #__PURE__ */ definePathHelper('get', '/admin/testers/suspended'),
  moderatedStudiesEligibity: /* #__PURE__ */ definePathHelper('put', '/admin/testers/:id/moderated_studies_eligibity'),
  adminApproval: /* #__PURE__ */ definePathHelper('put', '/admin/testers/:id/admin_approval'),
  answers: /* #__PURE__ */ definePathHelper('get', '/admin/testers/:id/answers'),
  risk: /* #__PURE__ */ definePathHelper('get', '/admin/testers/:id/risk'),
  demographics: /* #__PURE__ */ definePathHelper('get', '/admin/testers/:id/demographics'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/testers/:id'),
  contact: /* #__PURE__ */ definePathHelper('post', '/admin/testers/:id/contact'),
  list: /* #__PURE__ */ definePathHelper('get', '/admin/testers'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/testers/:id/edit'),
  get: /* #__PURE__ */ definePathHelper('get', '/admin/testers/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/testers/:id'),
}
