import { uniqBy } from "lodash"
import { array, boolean, object, string } from "yup"
import { NewCustomerLogos } from "../SignUp/NewCustomerLogos"
import { AccountForm } from "./AccountForm"
import { InviteTeamForm } from "./InviteTeamForm"
import { ResearchGoalsForm } from "./ResearchGoalsForm"
import { RoleForm } from "./RoleForm"
import { TrafficSourceForm } from "./TrafficSourceForm"
import { InviteTeamAnimation } from "./animations/InviteTeamAnimation"
import { ResearchGoalsAnimation } from "./animations/ResearchGoalsAnimation"
import { RoleAnimation } from "./animations/RoleAnimation"
import { TrafficSourceAnimation } from "./animations/TrafficSourceAnimation"
import {
  AccountStep,
  EmailAddress,
  InviteTeamStep,
  OnboardingStep,
  Password,
  ResearchGoal,
  ResearchGoalsStep,
  Role,
  RoleStep,
  TeamMember,
  TrafficSourceStep,
} from "./types"

const NAME_FORMAT = /^[^\/.:<>]*(\.\s[^\/.:]*)*(\.)?$/
const PASSWORD_COMPLEXITY = /(?=.*[a-z])(?=.*[_\W\dA-Z])/

export const STEPS: readonly OnboardingStep<any, any>[] = [
  {
    id: "account",
    title: "Create an account",
    schema: object({
      name: string()
        .required("Please enter your name")
        .matches(
          NAME_FORMAT,
          "Please remove special characters such as slashes, colons, and periods"
        ),
      email: string<EmailAddress>()
        .email("Please enter a valid email address")
        .required("Please enter your email address"),
      password: string<Password>()
        .min(8, "Please try a longer password")
        .required()
        .matches(
          PASSWORD_COMPLEXITY,
          "Please include at least one lowercase letter, and at least one uppercase letter or digit or special character"
        ),
      recaptchaResponse: string().required(),
    }),
    data: {
      name: "",
      email: "" as EmailAddress,
      password: "" as Password,
      recaptchaResponse: "",
    },
    component: AccountForm,
    animation: NewCustomerLogos,
  } as AccountStep,
  {
    id: "role",
    title: "Your role",
    schema: object({
      preferredName: string()
        .matches(
          NAME_FORMAT,
          "Please remove special characters such as slashes, colons, and periods"
        )
        .required("Enter your preferred name to continue"),
      role: string<Role>()
        .required("Please select a role")
        // The server currently accepts "Unspecified" but we want customers on this flow to choose a role
        .notOneOf(["Unspecified" as Role]),
      customRoleTitle: boolean()
        .required()
        .when("role", ([role], s) =>
          role === "other" ? s.transform(() => true) : s
        ),
      roleTitle: string().when(
        ["role", "customRoleTitle"],
        ([role, checked]) =>
          checked || role === "Other"
            ? string()
                .matches(
                  NAME_FORMAT,
                  "Please remove special characters such as slashes, colons, and periods"
                )
                .required("Please enter a role title")
            : string().transform(() => "")
      ),
    }),
    data: {
      // preferredName: "", deliberately omitted, it should be populated from the current user
      role: "" as Role,
      customRoleTitle: false,
      roleTitle: "",
    } as RoleStep["data"],
    component: RoleForm,
    animation: RoleAnimation,
    isSkippable: false,
  } as RoleStep,
  {
    id: "research",
    title: "Research goals",
    schema: object({
      researchGoals: array().of(string<ResearchGoal>().required()).required(),
      researchNeeds: string().when("researchGoals", ([goals], s) =>
        goals.length > 0
          ? s
          : s
              .matches(
                NAME_FORMAT,
                "Please remove special characters such as slashes, colons, and periods"
              )
              .required(
                "Please describe your research needs or select from the list above"
              )
      ),
    }),
    data: {
      researchGoals: [],
      researchNeeds: "",
    } as ResearchGoalsStep["data"],
    component: ResearchGoalsForm,
    animation: ResearchGoalsAnimation,
    isSkippable: true,
  } as ResearchGoalsStep,
  {
    id: "collaborators", // would love this to be /setup/team but that route already exists
    title: "Invite your team",
    schema: object({
      teamMembers: array()
        .of(
          object({
            email: string<EmailAddress>().email(),
          }).required()
        )
        .transform((value) =>
          uniqBy(
            value.filter((v: TeamMember) => !!v.email?.trim()),
            "email"
          )
        )
        .required()
        .min(1),
    }),
    data: {
      teamMembers: Array(2).fill({ email: "" }),
    } as InviteTeamStep["data"],
    component: InviteTeamForm,
    animation: InviteTeamAnimation,
    isSkippable: true,
    continue: "Send invites",
  } as InviteTeamStep,
  {
    id: "grapevine",
    title: "How did you hear about us?",
    schema: object({
      trafficSource: string().required(
        "Enter a description of how you heard about us to continue"
      ),
    }),
    data: {
      trafficSource: "",
    },
    component: TrafficSourceForm,
    animation: TrafficSourceAnimation,
    isSkippable: false,
    continue: "Finish",
  } as TrafficSourceStep,
] as const
