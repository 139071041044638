import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ZoomOutIcon = createIcon({
  displayName: "ZoomOutIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="zoom-out">
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 6.216C7.0008 6.42418 6.91846 6.62405 6.77126 6.77126C6.62405 6.91846 6.42418 7.00079 6.216 7H3.261C2.83917 6.98492 2.505 6.6386 2.505 6.21649C2.505 5.7944 2.83917 5.44807 3.261 5.433H4.325L1.23 2.338C0.942848 2.02901 0.951656 1.5482 1.24993 1.24992C1.5482 0.951645 2.02901 0.942841 2.338 1.23L5.433 4.325V3.25999C5.44807 2.83817 5.7944 2.504 6.2165 2.504C6.6386 2.504 6.98493 2.83817 7 3.25999V6.215V6.216ZM7 12.739V9.784C7.0008 9.57565 6.91833 9.37561 6.7709 9.22839C6.62348 9.08116 6.42335 8.99893 6.215 9H3.26C2.97341 8.98976 2.70409 9.13681 2.55775 9.38344C2.41142 9.63008 2.41142 9.93692 2.55775 10.1835C2.70409 10.4302 2.97341 10.5772 3.26 10.567H4.325L1.23 13.662C0.942848 13.971 0.951656 14.4518 1.24993 14.7501C1.5482 15.0483 2.02901 15.0571 2.338 14.77L5.433 11.675V12.739C5.42276 13.0256 5.56982 13.2949 5.81644 13.4412C6.06308 13.5876 6.36993 13.5876 6.61655 13.4412C6.86319 13.2949 7.01024 13.0256 7 12.739ZM9.22874 9.22874C9.08154 9.37595 8.9992 9.57582 9 9.784V12.739C8.98976 13.0256 9.13681 13.2949 9.38345 13.4412C9.63007 13.5876 9.93692 13.5876 10.1836 13.4412C10.4302 13.2949 10.5772 13.0256 10.567 12.739V11.675L13.662 14.77C13.971 15.0571 14.4518 15.0483 14.7501 14.7501C15.0483 14.4518 15.0572 13.971 14.77 13.662L11.675 10.567H12.739C13.0256 10.5772 13.2949 10.4302 13.4412 10.1835C13.5876 9.93692 13.5876 9.63008 13.4412 9.38344C13.2949 9.13681 13.0256 8.98976 12.739 9H9.784C9.57582 8.99919 9.37595 9.08154 9.22874 9.22874ZM9 3.261V6.216C8.9992 6.42418 9.08154 6.62405 9.22874 6.77126C9.37595 6.91846 9.57582 7.00079 9.784 7H12.739C13.0256 7.01024 13.2949 6.86319 13.4412 6.61655C13.5876 6.36992 13.5876 6.06308 13.4412 5.81644C13.2949 5.56981 13.0256 5.42276 12.739 5.433H11.675L14.77 2.338C15.0572 2.02901 15.0483 1.5482 14.7501 1.24992C14.4518 0.951645 13.971 0.942841 13.662 1.23L10.567 4.325V3.261C10.5519 2.83917 10.2056 2.50499 9.7835 2.50499C9.3614 2.50499 9.01507 2.83917 9 3.261Z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})
