import { Accordion, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react"
import React from "react"
import { useGetModeratedStudy } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyOrder } from "~/api/generated/usabilityhubSchemas"
import { ModeratedStudyOrderSummaryCardExpandedSection } from "./ModeratedStudyOrderSummaryCardExpandedSection"
import { ModeratedStudyOrderSummaryCardHeader } from "./ModeratedStudyOrderSummaryCardHeader"

type Props = {
  moderatedStudyId: string
  orders: ModeratedStudyOrder[]
}

export const ModeratedStudyOrderSummaryList: React.FC<Props> = ({
  moderatedStudyId,
  orders,
}) => {
  const { data: moderatedStudy } = useGetModeratedStudy({
    pathParams: {
      moderatedStudyId,
    },
  })

  if (orders.length === 0) return null
  if (!moderatedStudy) return null

  return (
    <Box bg="ds.surface.raised.resting" rounded="16px" shadow="ds.raised">
      <Accordion allowMultiple>
        {orders.map((order) => {
          return (
            <AccordionItem key={order.id} border="none">
              <ModeratedStudyOrderSummaryCardHeader order={order} />
              <AccordionPanel p={6}>
                <ModeratedStudyOrderSummaryCardExpandedSection order={order} />
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
    </Box>
  )
}
