import { Flex, Input, Stack } from "@chakra-ui/react"
import styled from "@emotion/styled"
import React from "react"

import { Slider } from "Components/form/range/slider"
import { Heading } from "DesignSystem/components"
import { useOrderForm } from "./OrderFormProvider"

// We are using the legacy slider (for now!) but we still want it in Chakra primary color
const RecoloredSlider = styled(Slider)`
  & [class$="fill"] {
    background-color: var(--chakra-colors-brand-primary-500);
  }
`

interface Props {
  responseScale: number[]
}

export const NoOfPanelistsSection: React.FC<React.PropsWithChildren<Props>> = ({
  responseScale,
  children,
}) => {
  const { numParticipants, setNumParticipants } = useOrderForm()

  return (
    <Stack data-intercom-target="number-of-participants">
      <Flex justify="space-between" align="center" mb={4}>
        <Heading as="h2" textStyle="ds.display.primary">
          {children}
        </Heading>
        <Input
          name="requested_response_count"
          type="number"
          value={numParticipants}
          onChange={(e) => {
            let inputValue = e.target.value ? e.target.valueAsNumber : 1

            if (inputValue < 1) {
              e.target.value = "1"
              inputValue = 1
            }

            setNumParticipants(inputValue)
          }}
          width="60px"
          px={2}
          min={responseScale[0]}
        />
      </Flex>

      <RecoloredSlider
        value={numParticipants}
        onChange={setNumParticipants}
        scale={responseScale}
        ticks
      />
    </Stack>
  )
}
