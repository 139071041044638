import { Filter } from "Components/test-results/types/filters"

import { getFilterMeta } from "../get-filter-meta"

import { FilterFunction } from "./types"

export type AnswerTagFilter = Filter<
  `answer/section:${number}/question:${number}/tag`,
  "answer/tag",
  number
>

export const filterResponseByAnswerTag: FilterFunction<AnswerTagFilter> = ({
  filter,
  filteredRelatedData: { responseAnswers },
  allRelatedData: { answerTags },
}) => {
  const { questionId } = getFilterMeta(filter.category)

  const answer = responseAnswers.find(
    (a) => a.usability_test_section_question_id === questionId
  )

  if (answer === undefined) {
    // The user didn't answer this question at all.
    return false
  }

  // Filter tags to the answer
  const tagsForAnswer = answerTags.filter(
    (answerTag) => answerTag.response_answer_id === answer.id
  )

  // -1 is a special value meaning filter to "untagged" answers
  if (filter.value === -1) {
    return tagsForAnswer.length === 0
  } else {
    return tagsForAnswer.some((tag) => tag.question_tag_id === filter.value)
  }
}
