import { Link, Text, TextProps } from "@chakra-ui/react"
import React, { useState } from "react"

const COLLAPSE_THRESHOLD = 280 // chars

type Props = {
  text: string
} & TextProps

export const TruncatableText: React.FC<Props> = ({ text, ...textProps }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  if (text.length <= COLLAPSE_THRESHOLD) {
    return (
      <Text whiteSpace="pre-wrap" {...textProps}>
        {text}
      </Text>
    )
  }

  const collapsedText = text.slice(0, COLLAPSE_THRESHOLD)

  return (
    <Text whiteSpace="pre-wrap" {...textProps}>
      {isCollapsed ? collapsedText : text}
      <Link variant="noUnderline" onClick={() => setIsCollapsed((c) => !c)}>
        {isCollapsed ? "...see more" : " see less"}
      </Link>
    </Text>
  )
}
