import React, { useEffect, useRef } from "react"

import {
  Box,
  Button,
  Badge as ChakraBadge,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Radio,
  RadioGroup,
  Spacer,
  Switch,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react"
import { DotsVerticalIcon } from "@heroicons/react/solid"
import Constants from "Constants/shared.json"
import { Badge } from "DesignSystem/components"
import { Alert } from "DesignSystem/components/Alert"
import { useAccountEnabledFeature } from "Hooks/use-account-disabled-feature"
import { getFormValue } from "Redux/reducers/test-builder-form/selectors/formValues"
import { ProCrownIcon } from "Shared/icons/ProCrownIcon"
import { Copy01OutlineIcon } from "Shared/icons/untitled-ui/Copy01OutlineIcon"
import { LinkExternal02OutlineIcon } from "Shared/icons/untitled-ui/LinkExternal02OutlineIcon"
import { ScreenRecordingOutlineIcon } from "Shared/icons/untitled-ui/ScreenRecordingOutlineIcon"
import { ThinkAloudOutlineIcon } from "Shared/icons/untitled-ui/ThinkAloudOutlineIcon"
import { Trash01OutlineIcon } from "Shared/icons/untitled-ui/Trash01OutlineIcon"
import {
  PermittedDeviceType,
  UsabilityTestSectionType as SectionType,
  UsabilityTestSectionType,
} from "Types"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import { RECORDING_KEY } from "UsabilityHub/views/settings/FeatureAccessPage/FeatureAccessForm"
import { useSelector } from "react-redux"
import { Fields, FormSection, type WrappedFieldProps } from "redux-form"

const RECORDABLE_SECTIONS_TYPES: SectionType[] = [
  SectionType.PrototypeTask,
  SectionType.FirstClickTest,
  SectionType.NavigationTest,
  SectionType.CardSort,
  SectionType.TreeTest,
  SectionType.LiveWebsiteTest,
]

export type ActionsVisibilityProps = {
  setIsActionsModalActive: (isActive: boolean) => void
}

type ActionsProps = {
  onDuplicate: () => void
  onDelete: () => void
  isEnabled: boolean
  setEnabled: (enabled: boolean) => void
  isSection: boolean
  isDeleteDisabled?: boolean
} & ActionsVisibilityProps

const normalButtonStyles = {
  border: "1px",
  borderColor: "transparent",
  backgroundColor: "ds.background.neutral.resting",
  _expanded: { bg: "ds.background.neutral.pressed" },
  _hover: { bg: "ds.background.neutral.hovered" },
}

export const Actions: React.FC<ActionsProps> = ({
  onDuplicate,
  onDelete,
  isEnabled,
  setEnabled,
  isSection,
  setIsActionsModalActive,
  isDeleteDisabled,
}) => {
  const { section } = useSectionContext()

  const recordingFeatureEnabled = useAccountEnabledFeature(RECORDING_KEY)
  const displayRecordButton =
    recordingFeatureEnabled &&
    isSection &&
    RECORDABLE_SECTIONS_TYPES.includes(section.type)

  return (
    <Flex gap="2" w="full">
      {displayRecordButton && (
        <RecordSettingsForm setIsActionsModalActive={setIsActionsModalActive} />
      )}
      <Button
        size="sm"
        leftIcon={
          <ProCrownIcon boxSize={2.5} color={"ds.neutral"} opacity={0.6} />
        }
        {...normalButtonStyles}
        onClick={() => setEnabled(!isEnabled)}
        data-qa="toggle-logic-switch"
      >
        {isEnabled ? "Remove logic" : "Add logic"}
      </Button>
      <Menu
        placement="bottom-end"
        onOpen={() => setIsActionsModalActive(true)}
        onClose={() => setIsActionsModalActive(false)}
        isLazy
      >
        <MenuButton
          data-qa={
            isSection
              ? "section-context-menu-button"
              : "question-context-menu-button"
          }
          as={IconButton}
          aria-label="Options"
          icon={
            <Icon boxSize={4} as={DotsVerticalIcon} color="ds.icon.default" />
          }
          {...normalButtonStyles}
          size="sm"
          onClick={(e) => e.stopPropagation()}
        />
        <Portal>
          <MenuList onClick={(e) => e.stopPropagation()} overflow="hidden">
            <MenuItem
              aria-label={
                isSection ? "Duplicate section" : "Duplicate question"
              }
              icon={
                <Icon color="gray.500" boxSize={5} as={Copy01OutlineIcon} />
              }
              onClick={onDuplicate}
              data-qa={isSection ? "duplicate-section" : "duplicate-question"}
            >
              Duplicate
            </MenuItem>
            <MenuItem
              aria-label={isSection ? "Delete section" : "Delete question"}
              color="red.600"
              icon={
                <Icon color="red.600" boxSize={5} as={Trash01OutlineIcon} />
              }
              onClick={onDelete}
              isDisabled={isDeleteDisabled}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  )
}

const RecordSettingsForm: React.FC<ActionsVisibilityProps> = ({
  setIsActionsModalActive,
}) => {
  const sectionIndex = useSectionIndexContext()
  const name = `sections[${sectionIndex}].recording_attributes`
  const {
    section: { prototype_type },
  } = useSectionContext()
  const disabledMessage =
    prototype_type === "free_roam"
      ? "Not currently supported on free flow"
      : null

  return (
    <FormSection name={name} disabled={!!disabledMessage}>
      <Fields
        names={["screen", "microphone", "camera"]}
        component={RecordButton}
        disabledMessage={disabledMessage}
        setIsActionsModalActive={setIsActionsModalActive}
      />
    </FormSection>
  )
}

type RecordFields = {
  screen: WrappedFieldProps
  microphone: WrappedFieldProps
  camera: WrappedFieldProps
  disabledMessage?: string
}

const RecordButton: React.FC<RecordFields & ActionsVisibilityProps> = ({
  screen,
  microphone,
  camera,
  setIsActionsModalActive,
  disabledMessage = null,
}) => {
  const { section } = useSectionContext()
  const permittedDeviceType: PermittedDeviceType = useSelector(
    getFormValue("permitted_device_type")
  )

  const allowsMobile = permittedDeviceType !== PermittedDeviceType.DesktopOnly

  const trigger = useRef<HTMLButtonElement>(null)

  const mobileScreenRecording = allowsMobile && !!screen.input.value

  const triggerTop = useRef<number | null>(null)

  const isOpen = useRef(false)

  const setIsOpen = (value: boolean) => {
    isOpen.current = value
    setIsActionsModalActive(value)

    if (trigger.current) {
      triggerTop.current =
        trigger.current.getBoundingClientRect().top + window.scrollY
    }
  }

  // Changing the value of the screen recording switch might cause an alert to
  // be shown further up the page. This code keeps the position of the 'record'
  // button the same, so that the mouse is still over the switch even though the
  // height of the page has changed.
  useEffect(() => {
    if (!trigger.current || !isOpen.current || !triggerTop.current) return

    const top = trigger.current.getBoundingClientRect().top + window.scrollY
    window.scrollBy({ top: top - triggerTop.current })
    triggerTop.current = top
  }, [mobileScreenRecording])

  useEffect(() => {
    if (disabledMessage) {
      // force disable the recordings if they're enabled and
      // the user switches to free flow
      if (screen.input.value) screen.input.onChange(false)
      if (microphone.input.value) microphone.input.onChange(false)
    }
  }, [disabledMessage])

  const recordingEnabled = screen.input.value || microphone.input.value
  const activeStyles = {
    backgroundColor: "ds.background.selected.subtle.resting",
    color: "ds.text.selected",
    border: "1px",
    borderColor: "ds.border.selected",
    _expanded: { bg: "ds.background.selected.subtle.resting" },
    _hover: { bg: "ds.background.selected.subtle.hovered" },
  }

  const disabledStyles = {
    border: "1px",
    borderColor: "transparent",
    backgroundColor: "ds.background.disabled",
    color: "ds.text.disabled",
    _expanded: { bg: "ds.background.disabled" },
    _hover: { bg: "ds.background.disabled" },
  }

  const isScreenRecordingLocked =
    section.type === UsabilityTestSectionType.LiveWebsiteTest

  return (
    <Popover
      trigger="click"
      placement="bottom-end"
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <PopoverTrigger>
        {/* Thanks to a weird passing refs issue which meant I couldn't pass the button as a child, I've declared a minimal button (styles only) for the tooltip and the proper button in the else */}
        {disabledMessage ? (
          <Tooltip label={disabledMessage} placement="top" borderRadius={4}>
            <Button
              size="sm"
              leftIcon={
                <ProCrownIcon
                  boxSize={2.5}
                  color={"ds.neutral"}
                  opacity={0.6}
                />
              }
              rightIcon={<Badge label="new" colorScheme="new" />}
              {...disabledStyles}
              disabled
            >
              Record
            </Button>
          </Tooltip>
        ) : (
          <Button
            ref={trigger}
            size="sm"
            leftIcon={
              <ProCrownIcon
                boxSize={2.5}
                color={recordingEnabled ? "ds.icon.selected" : "ds.neutral"}
                opacity={0.6}
              />
            }
            rightIcon={<Badge label="new" colorScheme="new" />}
            {...(recordingEnabled ? activeStyles : normalButtonStyles)}
          >
            Record
          </Button>
        )}
      </PopoverTrigger>
      <Portal>
        <Box zIndex={1} position="relative">
          <PopoverContent w="359px">
            <PopoverBody p={0}>
              <Flex direction="column" p={6}>
                <HStack gap={1} width="full">
                  <Text color="ds.text.default" textStyle="ds.display.primary">
                    Record
                  </Text>
                  <Spacer />
                  <Link
                    textDecoration="none"
                    color="ds.text.subtle"
                    href={Constants.HELP_CENTER_RECORDINGS_URL}
                    target="_blank"
                    rel="noopener noreferer"
                    fontSize={14}
                  >
                    Learn more
                    <Icon
                      as={LinkExternal02OutlineIcon}
                      boxSize={4}
                      ms={0.5}
                      verticalAlign="text-bottom"
                    />
                  </Link>
                </HStack>
                <Flex direction="column" mt={4} gap={4}>
                  <VStack>
                    <HStack
                      alignItems="center"
                      color="text.primary"
                      fontWeight="medium"
                      justifyContent="space-between"
                      width="full"
                    >
                      <FormLabel
                        htmlFor={microphone.input.name}
                        cursor="pointer"
                        mb={0}
                      >
                        <Flex
                          alignItems="center"
                          gap={2}
                          color="ds.text.default"
                        >
                          <Icon
                            as={ThinkAloudOutlineIcon}
                            boxSize={6}
                            color="ds.icon.subtle"
                          />
                          <Text
                            color="ds.text.default"
                            textStyle="ds.heading.primary"
                          >
                            Think aloud
                          </Text>
                        </Flex>
                      </FormLabel>

                      <Switch
                        id={microphone.input.name}
                        data-qa="recording-microphone-switch"
                        isChecked={microphone.input.value as boolean}
                        {...microphone.input}
                        onChange={(e) => {
                          if (!e.target.checked) {
                            camera.input.onChange(false)
                          }
                          microphone.input.onChange(e.target.checked)
                        }}
                      />
                    </HStack>
                    <Text
                      color="ds.text.default"
                      textStyle="ds.paragraph.primary"
                    >
                      Ask the participant to speak their thoughts aloud while
                      completing this section.
                    </Text>
                    {(microphone.input.value as boolean) && (
                      <RadioGroup width="full">
                        <VStack
                          p={4}
                          alignItems="flex-start"
                          bgColor="ds.background.neutral.resting"
                          borderRadius="8"
                        >
                          <Radio
                            name={camera.input.name}
                            isChecked={camera.input.value !== true}
                            onChange={(e) =>
                              camera.input.onChange(!e.target.checked)
                            }
                          >
                            <Text
                              textStyle="ds.paragraph.secondary"
                              fontSize="14px"
                            >
                              Audio only
                            </Text>
                          </Radio>
                          <Radio
                            name={camera.input.name}
                            isChecked={camera.input.value === true}
                            onChange={(e) =>
                              camera.input.onChange(e.target.checked)
                            }
                          >
                            <Text
                              textStyle="ds.paragraph.secondary"
                              fontSize="14px"
                            >
                              Camera and audio
                            </Text>
                          </Radio>
                        </VStack>
                      </RadioGroup>
                    )}
                  </VStack>

                  <Divider />

                  <VStack>
                    <HStack
                      alignItems="center"
                      color="text.primary"
                      fontWeight="medium"
                      justifyContent="space-between"
                      width="full"
                    >
                      <FormLabel
                        htmlFor={screen.input.name}
                        cursor="pointer"
                        mb={0}
                      >
                        <Flex
                          alignItems="center"
                          gap={2}
                          color="ds.text.default"
                        >
                          <Icon
                            as={ScreenRecordingOutlineIcon}
                            boxSize={6}
                            color="ds.icon.subtle"
                          />
                          <Text
                            color="ds.text.default"
                            textStyle="ds.heading.primary"
                          >
                            Screen
                          </Text>
                          <ChakraBadge
                            textColor="ds.text.default"
                            fontSize="11"
                            fontStyle="normal"
                            fontWeight="600"
                            letterSpacing="0.275px"
                            paddingX="1"
                            paddingY="0.25"
                            backgroundColor="ds.background.neutral.resting"
                            borderRadius="3"
                          >
                            DESKTOP ONLY
                          </ChakraBadge>
                        </Flex>
                      </FormLabel>

                      <Switch
                        id={screen.input.name}
                        data-qa="recording-screen-switch"
                        isChecked={screen.input.value as boolean}
                        isDisabled={isScreenRecordingLocked}
                        {...screen.input}
                      />
                    </HStack>
                    <Text
                      color="ds.text.default"
                      textStyle="ds.paragraph.primary"
                    >
                      {
                        "Capture the participant\u2019s interactions with the screen."
                      }
                    </Text>
                    {mobileScreenRecording && (
                      <Alert
                        status="warning"
                        description={`Mobile responses won\u2019t include screen recordings.`}
                      />
                    )}
                  </VStack>
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  )
}
