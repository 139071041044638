import { Avatar, AvatarGroup } from "@chakra-ui/react"
import React from "react"

import { Comment } from "~/api/generated/usabilityhubSchemas"

type Props = {
  users: NonNullable<Comment["user"]>[]
}

export const AvatarList: React.FC<Props> = ({ users }) => {
  return (
    <AvatarGroup
      max={users.length > 3 ? 2 : 3}
      spacing={-2}
      size="sm"
      sx={{
        "> .chakra-avatar__excess": {
          boxSize: 6,
          fontSize: "xs",
        },
      }}
    >
      {users.map((user) => (
        <Avatar
          boxSize={7}
          key={user.id}
          name={user.name}
          src={user.avatar_url}
        />
      ))}
    </AvatarGroup>
  )
}
