import React from "react"

import { MailToAnchor } from "Components/anchors/basic"
import Constants from "Constants/shared.json"

import { createErrorContent } from "./create-error-content"

const body = (responseId: number) =>
  `This email is in relation to response ${responseId}.

---

[Please add any more information that may be relevant]
`

function Contact({ responseId }: { responseId: number }) {
  return (
    <p className="para">
      If you feel this is incorrect, please{" "}
      <MailToAnchor
        email={Constants.TESTER_SUPPORT_EMAIL_ADDRESS}
        subject="My response was rejected in error"
        body={body(responseId)}
      >
        contact support
      </MailToAnchor>
      .
    </p>
  )
}

export function tooFastErrorContent({ responseId }: { responseId: number }) {
  return createErrorContent(
    <>
      <p className="para">
        <strong>Your response was rejected</strong>
      </p>
      <p className="para">
        Sorry, we can{"\u2019"}t accept your response as you appear to have
        rushed through the test too quickly. You will not be paid for this
        response.
      </p>
      <Contact responseId={responseId} />
    </>
  )
}

export function maliciousErrorContent({ responseId }: { responseId: number }) {
  return createErrorContent(
    <>
      <p className="para">
        <strong>Your response was rejected</strong>
      </p>
      <p className="para">
        Sorry, we can{"\u2019"}t accept your response as you appear to have used
        profanity in your answers. You will not be paid for this response.
      </p>
      <Contact responseId={responseId} />
    </>
  )
}
