import {
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { SimilarityMatrix } from "Components/test-results/section-results/SectionResultsCards/SimilarityMatrix/SimilarityMatrix"
import Constants from "Constants/shared.json"
import { Expand06OutlineIcon } from "Shared/icons/untitled-ui/Expand06OutlineIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import React from "react"
import { useCardSortResultsContext } from "./CardSortResultsContext"

export const CardSortSectionResultsSimilarityMatrixTab: React.FC = () => {
  const { cards, sortData } = useCardSortResultsContext()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Flex gap={2} align="center">
        <Alert>
          <AlertIcon />
          <Text>
            This table shows the percentage of participants who sorted each pair
            of cards together.{" "}
            <Link
              target="_blank"
              rel="noopener noreferer"
              href={Constants.HELP_CENTER_CARD_SORT_RESULTS_URL}
            >
              Learn more.
            </Link>
          </Text>
        </Alert>

        <IconButton
          variant="outline"
          aria-label="Show full modal"
          icon={<Icon as={Expand06OutlineIcon} />}
          onClick={onOpen}
        />
      </Flex>

      <SimilarityMatrix cards={cards} sortData={sortData} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="fit-content" maxW="95vw">
          <ModalHeader>
            <Flex justify="space-between">
              <Heading>Similarity Matrix</Heading>
              <IconButton
                variant="outline"
                aria-label="close modal"
                icon={<Icon as={XOutlineIcon} />}
                onClick={onClose}
              />
            </Flex>
          </ModalHeader>
          <ModalBody pb={6}>
            <SimilarityMatrix cards={cards} sortData={sortData} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
