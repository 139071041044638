import { ReactOption, Response, TestResultsOrder } from "Types"
import { getSpecificResponseSource } from "Utilities/response-source"
import { getOptionsFromMap } from "./get-options"

export const getSourceValueFromResponse = getSpecificResponseSource

const getSourceFromResponses = (
  orders: ReadonlyArray<TestResultsOrder>,
  responses: Readonly<Response[]>
) => {
  const sources = responses.map((r) => getSourceValueFromResponse(orders, r))
  const uniqueSources = [...new Set(sources)]

  const sourcesMap: [string, string][] = uniqueSources.map((source) => [
    source,
    source,
  ])

  return new Map(sourcesMap)
}

export const getSourcesOptionFromResponses = (
  orders: ReadonlyArray<TestResultsOrder>,
  responses: Readonly<Response[]>
): ReactOption<string>[] => {
  const sourcesMap = getSourceFromResponses(orders, responses)
  return getOptionsFromMap(sourcesMap)
}
