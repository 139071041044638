import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import React from "react"

interface Props {
  readonly onClose: (isConfirmed: boolean) => void
}

export const ConfirmDisableLinkModal = ({ onClose }: Props) => (
  <Modal isOpen onClose={() => onClose(false)}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <Text>Disable recruitment link for this test?</Text>
      </ModalHeader>
      <ModalBody>
        This link will no longer work for anyone who has it.
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button variant="outline" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={() => onClose(true)}>
            Disable
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  </Modal>
)
