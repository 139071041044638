import { useQueryClient } from "@tanstack/react-query"
import { CardGroup } from "Shared/components/Card/Card"
import { CreditCard } from "Types"
import { StaticPlanDrawer } from "UsabilityHub/components/PlanChanger/plan-drawer"
import {
  useActiveStripeSubscription,
  useCurrentAccount,
  useCurrentPlan,
  useMaybeManualSubscription,
} from "UsabilityHub/hooks/useCurrentAccount"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { isFree } from "Utilities/plan"
import { getParsedQueryString } from "Utilities/query-string"
import React from "react"
import { Helmet } from "react-helmet"
import {
  UpcomingInvoiceResponse,
  useUpcomingInvoice,
} from "~/api/generated/usabilityhub-components"
import { BillingDetails } from "./BillingDetails"
import { DiscontinuedPlanAlert } from "./DiscontinuedPlanAlert"
import { ManualSubscriptionUnpaidAlert } from "./ManualSubscriptionUnpaidAlert"
import { ModeratedStudiesQuotaInformation } from "./ModeratedStudiesQuotaInformation"
import { PaymentAndSubscription } from "./PaymentAndSubscription"
import { StripeSubscriptionPastDueAlert } from "./StripeSubscriptionPastDueAlert"
import { StripeSubscriptionUnpaidAlert } from "./StripeSubscriptionUnpaidAlert"

export type UpcomingInvoice = NonNullable<UpcomingInvoiceResponse["invoice"]>

export const BillingRoute: React.FC = () => {
  const currentAccount = useCurrentAccount()
  const teamMemberCount = useTeamMembers({
    onlyRoles: ["admin", "member"],
  }).length

  let creditCard: CreditCard | undefined
  if (currentAccount.stripe_card_last4) {
    creditCard = {
      expiry: currentAccount.stripe_card_expiry ?? undefined,
      last4: currentAccount.stripe_card_last4,
    }
  }

  const currentPlan = useCurrentPlan()

  const activeStripeSubscription = useActiveStripeSubscription()
  const activeManualSubscription = useMaybeManualSubscription("active")
  const pendingManualSubscription = useMaybeManualSubscription("pending")
  const { data: upcomingInvoice, isLoading: isUpcomingInvoiceLoaded } =
    useUpcomingInvoice({})
  const queryClient = useQueryClient()
  const refreshUpcomingInvoice = () => {
    queryClient.invalidateQueries([
      "api",
      "settings",
      "billing",
      "upcoming_invoice",
    ])
  }
  const showDiscontinuedPlanAlert =
    activeStripeSubscription &&
    !activeStripeSubscription.cancel_at_period_end &&
    currentPlan?.discontinued

  const isShowPlans = "showPlans" in getParsedQueryString()
  return (
    <>
      {isShowPlans && (
        <StaticPlanDrawer
          source="plan_settings"
          initialState={{
            screen: "plan-grid",
            selectedPlan: null,
          }}
        />
      )}
      <CardGroup>
        <Helmet>
          <title>Billing settings</title>
        </Helmet>
        {showDiscontinuedPlanAlert && (
          <DiscontinuedPlanAlert
            planInterval={currentPlan.interval}
            planName={currentPlan.name}
            planUniqueId={currentPlan.unique_id}
            subscriptionGuid={activeStripeSubscription.guid}
            subscriptionRenewalTime={
              activeStripeSubscription.current_period_end
            }
            teamMemberCount={teamMemberCount}
          />
        )}
        {activeStripeSubscription &&
          activeStripeSubscription.stripe_status === "past_due" && (
            <StripeSubscriptionPastDueAlert />
          )}
        {activeStripeSubscription &&
          activeStripeSubscription.stripe_status === "unpaid" && (
            <StripeSubscriptionUnpaidAlert />
          )}
        {currentAccount.manual_subscription &&
          !activeManualSubscription &&
          currentPlan &&
          !isFree(currentPlan) && <ManualSubscriptionUnpaidAlert />}
        <PaymentAndSubscription
          activeManualSubscription={activeManualSubscription}
          activeStripeSubscription={activeStripeSubscription}
          coupon={currentAccount.stripe_coupon_serialized}
          creditCard={creditCard}
          manualSubscription={currentAccount.manual_subscription}
          pendingManualSubscription={pendingManualSubscription}
          plan={currentPlan}
          upcomingInvoice={upcomingInvoice?.invoice ?? undefined}
          refreshUpcomingInvoice={refreshUpcomingInvoice}
          isUpcomingInvoiceLoaded={isUpcomingInvoiceLoaded}
        />
        <ModeratedStudiesQuotaInformation
          isFreePlan={currentPlan ? isFree(currentPlan) : true}
        />
        <BillingDetails />
      </CardGroup>
    </>
  )
}
