import { Alert, AlertDescription, AlertTitle, Stack } from "@chakra-ui/react"
import React from "react"
import { PathResult } from "./PathResult"
import { PathSortControls } from "./PathSortControls"
import { CommonPath, useSortPaths } from "./usePaths"

type TreeTestCommonPathsProps = {
  paths: CommonPath[]
}

export const TreeTestCommonPaths: React.FC<TreeTestCommonPathsProps> = ({
  paths,
}) => {
  const { sorted, options, sortOrder, setSortOrder } = useSortPaths(paths)

  if (paths.length === 0) {
    return (
      <Alert status="info" mt={8}>
        <AlertTitle>No common paths to show</AlertTitle>
        <AlertDescription>
          Common paths are paths taken by multiple participants
        </AlertDescription>
      </Alert>
    )
  }

  return (
    <Stack spacing={4} py={4}>
      <PathSortControls {...{ options, sortOrder, setSortOrder }} />
      {sorted.map((path) => (
        <PathResult key={path.id} path={path as CommonPath} />
      ))}
    </Stack>
  )
}
