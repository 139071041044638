import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react"
import React, { ReactNode } from "react"

import {
  AppearanceProps,
  LayoutState,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"

export function createErrorContent(children: ReactNode): AppearanceProps {
  return {
    isReportButtonVisible: false,
    layoutState: LayoutState.FocusQuestion,
    mediaContent: null,
    questionContent: (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>{children}</AlertDescription>
      </Alert>
    ),
  }
}
