import React from "react"

import { NoResponse } from "Components/individual-response/individual-section-result/no-data"
import { CardSortResult } from "Components/test-results/section-results/card-sort-result"
import { ResponseSection, UsabilityTestSection } from "Types"

import { TaskProps } from "./task-props"

interface Props {
  responseSection: ResponseSection
  usabilityTestSection: UsabilityTestSection
}

export const CardSortSectionIndividualResults: React.FC<Props> = ({
  responseSection,
  usabilityTestSection,
}: TaskProps) => {
  return responseSection === null ? (
    <NoResponse />
  ) : (
    <CardSortResult
      cardSort={usabilityTestSection.card_sort_attributes}
      responseSection={responseSection}
    />
  )
}
