import { useToast } from "@chakra-ui/react"
import { useStripeCards } from "UsabilityHub/hooks/useStripeCards"

interface useRemoveCreditCardOptions {
  onError?: () => void
  onLoad?: () => void
  onSuccess?: () => void
}
interface useRemoveCreditCardReturnType {
  handleSubmit: () => Promise<void>
}

export const useRemoveCreditCard = ({
  onError,
  onLoad,
  onSuccess,
}: useRemoveCreditCardOptions): useRemoveCreditCardReturnType => {
  const toast = useToast()
  const { removeCustomerCard } = useStripeCards()

  const handleSubmit = async () => {
    try {
      onLoad && onLoad()
      toast.closeAll()
      await removeCustomerCard()
      onSuccess && onSuccess()
      toast({
        status: "success",
        title: "Your credit card was removed successfully",
      })
    } catch (error) {
      onError && onError()
      const errorMessage = (error as Error).message
      toast({
        status: "error",
        title: errorMessage,
      })
    }
  }

  return {
    handleSubmit,
  }
}
