import { useDispatch, useSelector } from "react-redux"
import { change } from "redux-form"

import { Dispatch } from "Redux/app-store"
import {
  getFormName,
  getFormValue,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { TestLogicStatement } from "Types"
import {
  createTestLogicStatement,
  isTestLogicEnabled,
} from "Utilities/test-logic-statement"

export const useTestLogicStatement = (formFieldName: string) => {
  const dispatch: Dispatch = useDispatch()

  // Fixed a bug by handling passing an undefined testLogicStatement
  //
  // Bug: Create a new test with exactly one question section, save and continue the test
  //  Return to test builder and insert an instructions section before the question.
  //  Before handling the undefined case, this would have thrown because this code was looking for section[0].question[0], which is now section[1].question[0]
  //  This is again an instance where we don't understand the order in which the app updates the store and renders components that are reading from the store.
  const testLogicStatement: TestLogicStatement | null | undefined = useSelector(
    getFormValue(formFieldName)
  )?.test_logic_statement
  const setTestLogicStatement = (value: TestLogicStatement) => {
    const field = `${formFieldName}.test_logic_statement`
    dispatch(change(getFormName(), field, value))
  }
  const isEnabled = isTestLogicEnabled(testLogicStatement)
  const setEnabled = (enabled: boolean) => {
    // If we've already got one, just update its destroy flag so we keep or destroy as necessary.
    if (testLogicStatement) {
      setTestLogicStatement({ ...testLogicStatement, _destroy: !enabled })
    }
    // Otherwise, initialise a default TLS.
    else {
      setTestLogicStatement(createTestLogicStatement())
    }
  }
  return {
    testLogicStatement,
    setTestLogicStatement,
    isEnabled,
    setEnabled,
  }
}
