import {
  Box,
  Flex,
  Grid,
  Image,
  ListItem,
  ModalBody,
  ModalFooter,
  OrderedList,
  Spinner,
  Stack,
  Tag,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import { ISbRichtext } from "@storyblok/react"
import { Heading } from "DesignSystem/components"
import { UseTemplateButton } from "UsabilityHub/components/UseTemplateButton"
import React, { useEffect } from "react"
import { useNavigate } from "react-router"
import {
  MARK_TEXT_STYLE,
  NODE_LI,
  NODE_OL,
  NODE_UL,
  RenderOptions,
  render,
} from "storyblok-rich-text-react-renderer"
import { useTemplateLibrary } from "~/usabilityhub/hooks/useTemplateLibrary"
import { ROUTES } from "~/usabilityhub/views/routes"
import { PreviewButton } from "./PreviewButton"

const markResolvers: RenderOptions["markResolvers"] = {
  [MARK_TEXT_STYLE]: (children) => <Text as="span">{children}</Text>,
}

const nodeResolvers: RenderOptions["nodeResolvers"] = {
  [NODE_LI]: (children) => <ListItem>{children}</ListItem>,
  [NODE_OL]: (children) => <OrderedList>{children}</OrderedList>,
  [NODE_UL]: (children) => <UnorderedList>{children}</UnorderedList>,
}

const richText = (content: ISbRichtext) =>
  render(content, { nodeResolvers, markResolvers })

export const TemplateDetails: React.FC = () => {
  const { loading, template, setError } = useTemplateLibrary()

  const navigate = useNavigate()

  useEffect(() => {
    if (!loading && !template) {
      navigate(ROUTES.DASHBOARD.TEMPLATES.path, { replace: true })
    }
  }, [loading, template])

  if (loading || !template) {
    return (
      <ModalBody
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" color="brand.primary.500" thickness="0.25rem" />
      </ModalBody>
    )
  }

  return (
    <>
      <ModalBody
        pt={3}
        px={{ base: 4, md: 8 }}
        pb={5}
        minH={0}
        overflowY="auto"
      >
        <Grid
          display={{ base: "flex", md: "grid" }}
          flexDirection="column"
          gridTemplate={{
            base: `"image" auto "description" auto "useCases" auto / 1fr`,
            md: `"image description" auto "useCases description" 1fr / 1fr 1fr`,
          }}
          gridRowGap={3}
          gridColumnGap={8}
        >
          <Stack
            gridArea="description"
            display={{ base: "contents", md: "flex" }}
            lineHeight={2}
          >
            <Flex alignItems="start" wrap="wrap" gap={2} pb={2} order={-2}>
              {template.methodologies.map((m) => (
                <Tag key={m.id} bgColor="brand.background.light">
                  {m.name}
                </Tag>
              ))}
            </Flex>
            <Heading as="h1" textStyle="ds.display.emphasized" order={-2}>
              {template.title}
            </Heading>
            <Stack>
              <Text>{template.shortDescription}</Text>
              {template.discoverContent && (
                <Stack gap={3} fontSize={{ base: "sm", md: "md" }} my={3}>
                  <Heading as="h4" textStyle="ds.heading.primary">
                    This template will help you discover:
                  </Heading>
                  <Box>{richText(template.discoverContent)}</Box>
                </Stack>
              )}
            </Stack>
          </Stack>
          {template.useCases.length > 0 && (
            <Stack
              gridArea="useCases"
              border="1px solid"
              borderColor="gray.200"
              borderRadius={12}
              p={{ base: 3, md: 6 }}
              gap={{ base: 3, md: 6 }}
              alignSelf={{ base: "stretch", md: "start" }}
            >
              <Heading as="h3" textStyle="ds.heading.primary">
                This template is for:
              </Heading>
              <Flex wrap="wrap" gap={2}>
                {template.useCases.map((useCase) => (
                  <Tag
                    key={useCase.id}
                    size="lg"
                    variant="solid"
                    colorScheme="gray"
                  >
                    {useCase.name}
                  </Tag>
                ))}
              </Flex>
            </Stack>
          )}
          {template.image && (
            <Image
              gridArea="image"
              borderRadius={12}
              src={template.image}
              alt=""
              objectFit="cover"
              objectPosition="center"
              justifySelf="stretch"
              order={-1}
            />
          )}
        </Grid>
      </ModalBody>
      <ModalFooter
        p={{ base: 4, md: 5 }}
        gap={3}
        borderColor="gray.200"
        borderTopWidth="1px"
        backgroundColor="white"
      >
        {template?.localTemplateId && (
          <>
            <PreviewButton template={template} />
            <UseTemplateButton
              template={template}
              onNavigate={navigate}
              onError={setError}
            />
          </>
        )}
      </ModalFooter>
    </>
  )
}
