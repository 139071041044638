import { DisplayInlineMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { shuffle } from "lodash"
import React from "react"
import { defaultMemoize as memoize } from "reselect"

import { Option } from "Types"

function multipleChoiceOptionsToOptions(
  options: ReadonlyArray<string>,
  randomize: boolean
): ReadonlyArray<Option<string>> {
  return (randomize ? shuffle(options) : options).map((text) => ({
    value: text,
    label: <DisplayInlineMarkdownText text={text} />,
  }))
}

export function makeMultipleChoiceOptionsToOptions() {
  return memoize(multipleChoiceOptionsToOptions)
}
