import React from "react"

import { CommonPathMeta, IndividualPathMeta } from "Types"

import { useTaskGoalNodeId } from "../../hooks/use-goal-node-id"

import { CommonPathStats } from "./CommonPathStats"
import { IndividualPathStats } from "./IndividualPathStats"
import { PathStatProps } from "./PathStatProps"

type BaseProps = Omit<
  PathStatProps<IndividualPathMeta | CommonPathMeta>,
  "goalScreenId" | "pathMeta"
>

type CommonPathContainerProps = BaseProps & {
  type: "common"
  pathMeta: CommonPathMeta
}

type IndividualPathContainerProps = BaseProps & {
  type: "individual"
  pathMeta: IndividualPathMeta
}

export function PathStatsContainer(
  props: CommonPathContainerProps | IndividualPathContainerProps
) {
  const goalScreenId = useTaskGoalNodeId()

  if (props.type === "individual") {
    return <IndividualPathStats {...props} goalScreenId={goalScreenId} />
  }

  return <CommonPathStats {...props} goalScreenId={goalScreenId} />
}
