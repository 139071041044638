import { Center } from "@chakra-ui/react"
import { assertNever } from "Components/filter-controls/utils"
import { useFlatLocations } from "UsabilityHub/hooks/useFlatLocations"
import React from "react"
import { ListTargetableLocationsResponse } from "~/api/generated/usabilityhub-components"
import { useOrderForm } from "../OrderFormProvider"
import { TargetLocation } from "../types"
import { LocationList } from "./LocationList"
import { useLocationContext } from "./LocationProvider"
import { LocationRow, SelectionState } from "./LocationRow"
import { LocationSelectMenu } from "./LocationSelectMenu"
import { useToggleLocation } from "./useToggleLocation"

type Props = {
  searchTerm: string
  menuRef: React.RefObject<HTMLDivElement>
}

const MAX_MATCHES_TO_SHOW = 30

export const LocationSearchMenu: React.FC<Props> = ({
  searchTerm,
  menuRef,
}) => {
  const { context } = useOrderForm()
  const flatLocations = useFlatLocations(context)
  const locations = useLocationContext()
  const {
    toggleCountry,
    toggleState,
    toggleCity,
    isCountrySelected,
    isStateSelected,
    isCitySelected,
  } = useToggleLocation()

  const matches = searchLocations(locations, searchTerm)

  if (matches.length === 0) {
    return (
      <Center color="ds.text.subtle" minH={10}>
        No matches found
      </Center>
    )
  }

  const afterDrill = () => {
    if (!menuRef.current) return

    menuRef.current.scrollTop = 0
  }

  if (matches.length > 0) {
    const visibleMatches = sortedMatchesWithData(matches, flatLocations)

    return (
      <LocationSelectMenu menuRef={menuRef}>
        {(setCurrentLevel) => (
          <LocationList>
            {visibleMatches.map((locationData) => {
              const { type } = locationData // Needed for ts to handle the discriminated union typecheck properly

              let selectionState: SelectionState = "unselected"
              let onDrill: undefined | (() => void)

              if (type === "country") {
                selectionState = isCountrySelected(locationData.countryCode)

                onDrill =
                  locationData.stateCount > 0
                    ? () =>
                        setCurrentLevel([
                          {
                            type: "country",
                            id: locationData.countryCode,
                            scrollPosition: menuRef.current?.scrollTop ?? 0,
                          },
                        ])
                    : undefined
              } else if (type === "state") {
                selectionState = isStateSelected(
                  locationData.countryCode,
                  locationData.stateId
                )

                onDrill =
                  locationData.cityCount > 0
                    ? () =>
                        setCurrentLevel([
                          {
                            type: "country",
                            id: locationData.countryCode,
                            scrollPosition: 0,
                          },
                          {
                            type: "state",
                            id: locationData.stateId.toString(),
                            scrollPosition: menuRef.current?.scrollTop ?? 0,
                          },
                        ])
                    : undefined
              } else if (type === "city") {
                selectionState = isCitySelected(
                  locationData.countryCode,
                  locationData.stateId,
                  locationData.cityId
                )
              } else {
                assertNever(type)
              }

              const [label, secondaryLabel] = calculateLabels(
                locationData,
                flatLocations
              )

              return (
                <LocationRow
                  key={getUniqueId(locationData)}
                  label={label}
                  secondaryLabel={secondaryLabel}
                  selectionState={selectionState}
                  onDrill={
                    onDrill
                      ? () => {
                          onDrill?.()
                          afterDrill()
                        }
                      : undefined
                  }
                  onToggle={() => {
                    if (type === "country") {
                      toggleCountry(locationData.countryCode)
                    } else if (type === "state") {
                      toggleState(
                        locationData.countryCode,
                        locationData.stateId
                      )
                    } else if (type === "city") {
                      toggleCity(
                        locationData.countryCode,
                        locationData.stateId,
                        locationData.cityId
                      )
                    } else {
                      assertNever(type)
                    }
                  }}
                />
              )
            })}
          </LocationList>
        )}
      </LocationSelectMenu>
    )
  }
}

const searchLocations = (
  locations: ListTargetableLocationsResponse["locations"],
  searchTerm: string
) => {
  const matches: TargetLocation[] = []
  const searchTermLowercase = searchTerm.toLowerCase()

  // TODO: ideally strip accents etc so you can search without using the exact diacritics
  // If only Intl.Collator did partial matches :(

  for (const location of locations) {
    if (location.name.toLowerCase().includes(searchTermLowercase)) {
      matches.push({ type: "country", id: location.code })
    }

    for (const state of location.states) {
      if (state.long_name.toLowerCase().includes(searchTermLowercase)) {
        matches.push({ type: "state", id: state.id.toString() })
      }

      for (const city of state.cities) {
        if (city.long_name.toLowerCase().includes(searchTermLowercase)) {
          matches.push({ type: "city", id: city.id.toString() })
        }
      }
    }
  }

  return matches
}

const sortedMatchesWithData = (
  matches: TargetLocation[],
  flatLocations: ReturnType<typeof useFlatLocations>
) => {
  const matchesWithData = matches.map((match) => {
    return flatLocations[match.type][match.id]
  })

  const sortedMatches = matchesWithData.sort((a, b) => {
    return a.qualifiedName.localeCompare(b.qualifiedName)
  })

  return sortedMatches.slice(0, MAX_MATCHES_TO_SHOW)
}

const getUniqueId = (
  locationData: ReturnType<typeof sortedMatchesWithData>[number]
) => {
  switch (locationData.type) {
    case "country":
      return `country-${locationData.countryCode}`
    case "state":
      return `state-${locationData.stateId}`
    case "city":
      return `city-${locationData.cityId}`
    default:
      assertNever(locationData)
  }
}

const calculateLabels = (
  locationData: ReturnType<typeof sortedMatchesWithData>[number],
  flatLocations: ReturnType<typeof useFlatLocations>
): [string, string | undefined] => {
  switch (locationData.type) {
    case "country":
      return [locationData.friendlyName, undefined]
    case "state": {
      const country = flatLocations.country[locationData.countryCode]
      return [locationData.friendlyName, country?.friendlyName]
    }
    case "city": {
      const state = flatLocations.state[locationData.stateId]
      const country = flatLocations.country[locationData.countryCode]
      return [
        locationData.friendlyName,
        state?.friendlyName + ", " + country?.friendlyName,
      ]
    }
    default:
      assertNever(locationData)
      return ["", undefined] // In practice the line above will throw
  }
}
