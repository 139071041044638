import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Tooltip,
  VStack,
} from "@chakra-ui/react"
import React from "react"
import { Control, useFieldArray, useWatch } from "react-hook-form"

import { PlusSolidIcon } from "Shared/icons/untitled-ui/PlusSolidIcon"
import { Trash01OutlineIcon } from "Shared/icons/untitled-ui/Trash01OutlineIcon"

import { TimeSlotsCopyMenu } from "./TimeSlotsCopyMenu"
import { TimeSlotsSelector } from "./TimeSlotsSelector"
import { Day } from "./days"
import { TimeSlot, times } from "./times"

interface Props {
  control: Control<any>
  fieldName: string
  day?: Day
  handleCopy?: (source: Day, targets: Day[]) => void
}

export const TimeSlots: React.FC<Props> = ({
  control,
  fieldName,
  day,
  handleCopy,
}) => {
  const {
    fields: timeSlotsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: fieldName,
  })

  const timeSlots = useWatch({
    control,
    name: fieldName,
  }) as TimeSlot[]

  const addTimeSlot = () => {
    const noOfTimeSlots = timeSlots.length
    if (noOfTimeSlots === 0) {
      append([{ starts_at: "09:00", ends_at: "17:00" }])
    } else {
      const lastTime = times[times.length - 1][0]
      const lastEndTime = timeSlots[noOfTimeSlots - 1].ends_at
      // Do nothing if the last time slot is already the end of the day
      if (lastEndTime !== lastTime) {
        // Set the end time of last time slot as the start time of the next time slot
        // and the end time of the next time slot is one hour later
        const nextEndTime = times.find(
          (time) =>
            time[0] > lastEndTime &&
            time[0].split(":")[1] === lastEndTime.split(":")[1]
        )?.[0]
        append({
          starts_at: lastEndTime,
          ends_at: nextEndTime ?? lastTime,
        })
      }
    }
  }

  const canCopyDays = handleCopy && day

  return (
    <VStack width="full" spacing={2} alignItems="flex-start">
      {timeSlotsFields.map((field, index) => (
        <Flex key={field.id} justify="between" w="full">
          <TimeSlotsSelector
            control={control}
            timeSlotField={field as TimeSlot}
            fieldName={fieldName}
            index={index}
          />
          <Spacer />
          <HStack>
            {index === 0 ? (
              <>
                <Tooltip hasArrow placement="top" label="Add new time slot">
                  <IconButton
                    variant="ghost"
                    size="sm"
                    aria-label="Add new time slot"
                    icon={
                      <Icon as={PlusSolidIcon} color="brand.neutral.default" />
                    }
                    onClick={addTimeSlot}
                  />
                </Tooltip>
                {canCopyDays && (
                  <TimeSlotsCopyMenu day={day} handleCopy={handleCopy} />
                )}
              </>
            ) : (
              <IconButton
                variant="ghost"
                size="sm"
                aria-label="Delete the time slot"
                icon={
                  <Icon as={Trash01OutlineIcon} color="brand.neutral.default" />
                }
                onClick={() => remove(index)}
              />
            )}
          </HStack>
        </Flex>
      ))}
    </VStack>
  )
}
