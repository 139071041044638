import {
  Alert,
  AlertDescription,
  AlertIcon,
  Center,
  Spinner,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React, { PropsWithChildren, createContext, useContext } from "react"
import {
  GetCurrentAccountResponse,
  useGetCurrentAccount,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

const CurrentAccountContext = createContext<GetCurrentAccountResponse | null>(
  null
)

type Props = {
  unauthenticatedFallback?: React.ReactNode
}

// Lots of pages expect information about the current account to be available immediately
// because it used to be serialized into a script tag on the page and loaded into Redux.
// For that reason this provider blocks rendering until the query finishes.
export const CurrentAccountProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  unauthenticatedFallback,
}) => {
  const { data, isLoading, isError, error } = useGetCurrentAccount(
    {},
    {
      staleTime: 60_000,
      cacheTime: 60_000,
      // Don't retry 401 errors since that just means you are not logged in
      retry: (count, error) => count < 3 && error?.status !== 401,
    }
  )

  if (isLoading) {
    return (
      <Center minH="100vh">
        <Spinner size="xl" />
      </Center>
    )
  }

  if (isError && error.status === 401 && unauthenticatedFallback) {
    return unauthenticatedFallback
  }

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>
          There was an error loading your account information. Please refresh
          the page and try again.
        </AlertDescription>
      </Alert>
    )
  }

  return (
    <CurrentAccountContext.Provider value={data}>
      {children}
    </CurrentAccountContext.Provider>
  )
}

export const useCurrentAccount = () => {
  const contextData = useContext(CurrentAccountContext)

  if (!contextData) {
    throw new Error(
      "useCurrentAccount must be used within a CurrentAccountProvider"
    )
  }

  return contextData.current_account
}

export const useCurrentUser = () => {
  const contextData = useContext(CurrentAccountContext)

  if (!contextData) {
    throw new Error(
      "useCurrentAccount must be used within a CurrentAccountProvider"
    )
  }

  return contextData.current_user
}

export const useCurrentPlan = () => {
  const contextData = useContext(CurrentAccountContext)

  if (!contextData) {
    throw new Error(
      "useCurrentPlan must be used within a CurrentAccountProvider"
    )
  }

  return contextData.current_plan
}

export const useActiveStripeSubscription = () => {
  const contextData = useContext(CurrentAccountContext)

  if (!contextData) {
    throw new Error(
      "useActiveStripeSubscription must be used within a CurrentAccountProvider"
    )
  }

  return contextData.active_stripe_subscription
}

// Suitable for use when we may or may not be inside the provider, like <UsabilityHubNavbar />
// Use only if you really need it!
export const useMaybeCurrentAccount = () => {
  return useContext(CurrentAccountContext)?.current_account ?? null
}

export const useMaybeCurrentUser = () => {
  return useContext(CurrentAccountContext)?.current_user ?? null
}

export const useMaybeCurrentPlan = () => {
  return useContext(CurrentAccountContext)?.current_plan ?? null
}

export const useMaybeActiveStripeSubscription = () => {
  return useContext(CurrentAccountContext)?.active_stripe_subscription
}

export const useMaybeManualSubscription = (state: "active" | "pending") => {
  const contextData = useContext(CurrentAccountContext)

  return contextData?.manual_subscriptions.find((s) => s.state === state)
}

export const useRefreshCurrentAccount = () => {
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  const queryKey = queryKeyFn({
    path: "/api/account/current",
    operationId: "getCurrentAccount",
    variables: {},
  })

  return async () => queryClient.invalidateQueries(queryKey)
}
