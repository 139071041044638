import { change } from "redux-form"

import { State } from "Redux/app-store"
import { removeUnusedFigmaFileVersions } from "Redux/reducers/figma-file-versions/actions"
import { getFormName } from "Redux/reducers/test-builder-form/selectors/formValues"
import { getSections } from "Redux/reducers/test-builder-form/selectors/sections"
import {
  FigmaFileVersion,
  ThunkAction,
  UsabilityTestSection,
} from "~/application/javascripts/types"

/**
 * Attach a FigmaFileFlow to a UsabilitySection.
 *
 * @param sectionIndex
 * @param figmaFileVersion
 * @param startNodeId
 */
export const addSectionPrototype =
  (
    sectionIndex: number,
    figmaFileVersion: FigmaFileVersion,
    startNodeId: string,
    scaling: string
  ): ThunkAction<State> =>
  (dispatch) => {
    // Add the flow to the section
    void dispatch(
      change(getFormName(), `sections[${sectionIndex}].figma_file_flow`, {
        figma_file_version_id: figmaFileVersion.id,
        start_node_id: startNodeId,
        scaling: scaling,
        show_success_screen: true,
      })
    )
  }

/**
 * Update UsabilitySections with a new FigmaFileVersion.
 *
 * @param oldFFV
 * @param newFFV
 */
export const updatePrototypeVersion =
  (oldFFV: FigmaFileVersion, newFFV: FigmaFileVersion): ThunkAction<State> =>
  (dispatch, getState) => {
    const hasOldFFV = (section: UsabilityTestSection) =>
      section.figma_file_flow?.figma_file_version_id === oldFFV.id

    const sectionsToUpdate = getSections(getState())
      // Grab the sectionIndex for the updater
      .map((section, sectionIndex) => ({
        section,
        sectionIndex,
      }))
      .filter(({ section }) => hasOldFFV(section))

    // Update all the sections to the new version
    sectionsToUpdate.forEach(({ sectionIndex }) =>
      dispatch(setSectionPrototype(sectionIndex, newFFV))
    )
  }

/**
 * Set a UsabilitySection's FigmaFileVersion.
 *
 * @param sectionIndex
 * @param figmaFileVersion
 */
const setSectionPrototype = (
  sectionIndex: number,
  figmaFileVersion: FigmaFileVersion
) =>
  change(
    getFormName(),
    `sections[${sectionIndex}].figma_file_flow.figma_file_version_id`,
    figmaFileVersion.id
  )

/**
 * Remove the relation between a UsabilitySection and a FigmaFileVersion.
 *
 * @param sectionIndex
 * @param figmaFileVersion
 */
export const removeSectionPrototype =
  (sectionIndex: number): ThunkAction<State> =>
  async (dispatch) => {
    // Remove the figma file version from the section
    // change is synchronous so we need to wrap in a promise to ensure it runs first
    await Promise.resolve(
      dispatch(
        change(getFormName(), `sections[${sectionIndex}].figma_file_flow`, null)
      )
    )

    // Remove any unused FFVs from the global bucket to prevent future import conflicts
    void dispatch(removeUnusedFigmaFileVersions)
  }

/**
 * Set a prototype section's goal.
 *
 * @param sectionIndex
 * @param goalNodeId
 */
export const setPrototypeGoal = (
  sectionIndex: number,
  goalNodeId: string | null
) =>
  change(
    getFormName(),
    `sections[${sectionIndex}].figma_file_flow.goal_node_id`,
    goalNodeId
  )
