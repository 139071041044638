// This file should be kept in sync with stylesheets/shared/constants.scss
export const LAYER_FADE_DURATION_MS = 100
export const DASHBOARD_LAYER_FADE_DELAY_MS = 250
export const PREFERENCE_STEP_LAYER_FADE_DELAY_MS = 100

export const MIN_DEVICE_FRAME_SIZE_SCALE = 0
export const MAX_DEVICE_FRAME_SIZE_SCALE = 1.5
export const DEVICE_FRAME_SVG_TO_DOM_SCALE = 0.5

export const ANIMATION_BUFFER_MS = 50

export const HITZONE_LEAVE_DURATION_MS = 200
