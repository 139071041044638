import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Collapse,
  Switch,
} from "@chakra-ui/react"
import { PaidFeatureTag } from "Components/paid-feature-tag/paid-feature-tag"
import cx from "classnames"
import { nanoid } from "nanoid"
import React, { Component, ReactNode } from "react"
import { Account, RecruitmentLink } from "~/api/generated/usabilityhubSchemas"
import { DemographicQuestionsFields } from "./DemographicQuestionsFields"
import { ParticipantLimitFields } from "./ParticipantLimitFields"
import { PostTestRedirectionFields } from "./PostTestRedirectionFields"
import { ThankYouMessageFields } from "./ThankYouMessageFields"
import styles from "./recruitment-link-form.module.scss"

interface SectionProps {
  readonly hasFeature: boolean
  readonly isProFeature?: boolean
  readonly children?: ReactNode
  readonly description: ReactNode
  readonly isActive: boolean
  readonly onActiveChange: (active: boolean) => void
  readonly title: ReactNode
}

class ToggleSection extends Component<SectionProps> {
  private id = nanoid()
  private toggleInputRef = React.createRef<HTMLInputElement>()

  private updateValidity({ isActive, hasFeature }: Readonly<SectionProps>) {
    this.toggleInputRef.current!.setCustomValidity(
      !hasFeature && isActive ? "Upgrade your account to use this feature" : ""
    )
  }

  componentDidMount() {
    this.updateValidity(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<SectionProps>) {
    this.updateValidity(nextProps)
  }

  render() {
    const {
      children,
      description,
      hasFeature,
      isProFeature,
      isActive,
      onActiveChange,
      title,
    } = this.props
    return (
      <section className={styles.section}>
        <label htmlFor={this.id} className={styles.header}>
          <div className={styles.toggleWrapper}>
            <Switch
              id={this.id}
              ref={this.toggleInputRef}
              onChange={() => onActiveChange(!isActive)}
              isChecked={isActive}
            />
          </div>
          <div>
            <h1 className={cx("heading", styles.title)}>{title}</h1>
            <small>{description}</small>
          </div>
          <Box ms="auto">
            {isProFeature && <PaidFeatureTag hasFeature={hasFeature} />}
          </Box>
        </label>
        {children !== undefined && (
          <Collapse in={isActive}>
            <div className={styles.collapseContentWrapper}>
              <div className={styles.content}>{children}</div>
            </div>
          </Collapse>
        )}
      </section>
    )
  }
}

interface Props {
  readonly account: Account
  readonly recruitmentLink: RecruitmentLink
  readonly recruitedResponseCount: number
  readonly onChange: (changes: Partial<RecruitmentLink>) => void
  readonly testUpgraded: boolean
  readonly isTestSetLink: boolean
}

export const RecruitmentLinkFormFields: React.FC<Props> = ({
  account,
  recruitmentLink,
  recruitedResponseCount,
  onChange,
  testUpgraded,
  isTestSetLink,
}) => {
  return (
    <>
      {isTestSetLink && (
        <>
          <Alert status="info">
            <AlertIcon />
            <AlertDescription>
              Participants recruited via the set link will see these
              customizations, not the customizations configured on individual
              tests within the set.
            </AlertDescription>
          </Alert>
          <ToggleSection
            title="Thank you message"
            description="Thank your participants personally, or give them a reward (like a coupon code) after completing your test."
            isActive={recruitmentLink.enable_custom_thank_you}
            onActiveChange={(active) =>
              onChange({ enable_custom_thank_you: active })
            }
            hasFeature
          >
            <ThankYouMessageFields
              values={recruitmentLink}
              onChange={onChange}
              isActive={recruitmentLink.enable_custom_thank_you}
            />
          </ToggleSection>
        </>
      )}
      <ToggleSection
        title="Limit number of participants"
        description="Stop accepting responses after this number of participants."
        isActive={recruitmentLink.enable_response_limit}
        onActiveChange={(v) => onChange({ enable_response_limit: v })}
        hasFeature={
          testUpgraded ||
          isTestSetLink ||
          account.has_recruitment_link_limit_feature
        }
        isProFeature
      >
        <ParticipantLimitFields
          recruitedResponseCount={recruitedResponseCount}
          onChange={(v) => onChange({ response_limit: v })}
          value={recruitmentLink.response_limit ?? 0}
          required={recruitmentLink.enable_response_limit}
        />
      </ToggleSection>
      <ToggleSection
        title="Demographic questions"
        description="Ask pre-defined demographic questions prior to test starting."
        isActive={recruitmentLink.enable_demographics}
        onActiveChange={(v) => onChange({ enable_demographics: v })}
        hasFeature={
          testUpgraded ||
          isTestSetLink ||
          account.has_demographic_surveys_feature
        }
        isProFeature
      >
        <DemographicQuestionsFields
          values={recruitmentLink}
          onChange={onChange}
        />
      </ToggleSection>
      <ToggleSection
        title="Post-test redirection"
        description="Redirect participants to a website after completing the test."
        isActive={recruitmentLink.enable_redirect}
        onActiveChange={(v) => onChange({ enable_redirect: v })}
        hasFeature={
          testUpgraded || isTestSetLink || account.has_redirects_feature
        }
        isProFeature
      >
        <PostTestRedirectionFields
          value={recruitmentLink.redirect_link || ""}
          onChange={(v) => onChange({ redirect_link: v })}
          required={recruitmentLink.enable_redirect}
        />
      </ToggleSection>
      <ToggleSection
        title="Kiosk mode"
        description="Allow this tests to be taken multiple times within a single session."
        isActive={recruitmentLink.allow_multiple_responses}
        onActiveChange={(v) => onChange({ allow_multiple_responses: v })}
        hasFeature
      />
    </>
  )
}
