import {
  Flex,
  Grid,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Heading, IconButton } from "DesignSystem/components"
import { Checkbox } from "DesignSystem/components/Checkbox"
import { Copy01OutlineIcon } from "Shared/icons/untitled-ui/Copy01OutlineIcon"
import { PlusCircleOutlineIcon } from "Shared/icons/untitled-ui/PlusCircleOutlineIcon"
import { Trash01OutlineIcon } from "Shared/icons/untitled-ui/Trash01OutlineIcon"
import { FunctionalModal } from "Utilities/modals/types"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import * as Yup from "yup"
import { DAYS, DAY_LABELS } from "../../availability/days"
import { getTimes, times } from "../../availability/times"
import {
  dayAvailability,
  useAvailabilitySectionForm,
} from "../../moderated-study-builder/forms/useAvailabilitySectionForm"

const DayAvailabilitySchema = Yup.object({
  availabilityByDay: Yup.object({
    mon: dayAvailability,
    tue: dayAvailability,
    wed: dayAvailability,
    thu: dayAvailability,
    fri: dayAvailability,
    sat: dayAvailability,
    sun: dayAvailability,
  }).required(),
})

type DayAvailabilityFormValues = Yup.InferType<typeof DayAvailabilitySchema>

type Day = keyof DayAvailabilityFormValues["availabilityByDay"]

type GeneralAvailabilityProps = {
  availabilityForm: ReturnType<typeof useAvailabilitySectionForm>
  onSubmit: (values: DayAvailabilityFormValues) => void
}

export const GeneralAvailability: FunctionalModal<GeneralAvailabilityProps> = ({
  availabilityForm,
  onSubmit,
  onClose,
}) => {
  const form = useForm<DayAvailabilityFormValues>({
    resolver: yupResolver(DayAvailabilitySchema),
    defaultValues: {
      availabilityByDay: availabilityForm.watch("availabilityByDay"),
    },
  })

  const availabilityByDay = form.watch("availabilityByDay")

  const submit = () => {
    onSubmit(form.getValues())
    onClose()
  }

  return (
    <Modal isOpen size="xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h2" textStyle="ds.display.primary">
            Edit general availability
          </Heading>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Grid gridTemplateColumns="auto 1fr auto" columnGap={6} rowGap={0}>
            {DAYS.map((day) => (
              <Row
                key={day}
                day={day}
                availability={availabilityByDay[day] || []}
                onChange={(day, availability) =>
                  form.setValue(`availabilityByDay.${day}`, availability)
                }
              />
            ))}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={submit}>
              Save changes
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type RowProps = {
  day: Day
  availability: DayAvailabilityFormValues["availabilityByDay"][Day]
  onChange: (
    day: Day,
    availability: DayAvailabilityFormValues["availabilityByDay"][Day]
  ) => void
}

const Row: React.FC<RowProps> = ({ day, availability, onChange }) => {
  const addTimeSlot = () => {
    const noOfTimeSlots = availability.length
    if (noOfTimeSlots === 0) {
      onChange(day, [{ starts_at: "09:00", ends_at: "17:00" }])
    } else {
      const lastTime = times[times.length - 1][0]
      const lastEndTime = availability[noOfTimeSlots - 1].ends_at
      // Do nothing if the last time slot is already the end of the day
      if (lastEndTime !== lastTime) {
        // Set the end time of last time slot as the start time of the next time slot
        // and the end time of the next time slot is one hour later
        const nextEndTime = times.find(
          (time) =>
            time[0] > lastEndTime &&
            time[0].split(":")[1] === lastEndTime.split(":")[1]
        )?.[0]
        onChange(day, [
          ...availability,
          {
            starts_at: lastEndTime,
            ends_at: nextEndTime ?? lastTime,
          },
        ])
      }
    }
  }

  return (
    <Grid
      gridColumn="1 / -1"
      gridTemplateColumns="subgrid"
      borderTop="1px solid"
      borderColor={day === "sun" ? "transparent" : "ds.border.default"}
      minH={12}
      rowGap={2}
      p={2}
      sx={{
        "--day-controls-opacity": "0",
        _hover: {
          "--day-controls-opacity": "1",
        },
        '&:has([aria-expanded="true"])': {
          "--day-controls-opacity": "1",
        },
      }}
    >
      <HStack gap={2} align="center">
        <Switch
          isChecked={availability.length > 0}
          onChange={(e) => {
            if (!e.currentTarget.checked) {
              onChange(day, [])
            } else {
              onChange(day, [{ starts_at: "09:00", ends_at: "17:00" }])
            }
          }}
        />
        <Text textStyle="ds.heading.secondary">
          {DAY_LABELS[day].shortLabel}
        </Text>
      </HStack>
      {availability.length ? (
        availability.map((timeSlot, index) => (
          <TimeSlot
            key={index}
            startsAt={timeSlot.starts_at}
            endsAt={timeSlot.ends_at}
            onChange={(starts_at, ends_at) => {
              const newAvailability = [...availability]
              newAvailability.splice(index, 1, { starts_at, ends_at })
              onChange(day, newAvailability)
            }}
            onDelete={() => {
              const newAvailability = [...availability]
              newAvailability.splice(index, 1)
              onChange(day, newAvailability)
            }}
          />
        ))
      ) : (
        <Text
          as="span"
          textStyle="ds.paragraph.primary"
          color="ds.text.subtle"
          alignSelf="center"
        >
          Unavailable
        </Text>
      )}
      <HStack align="center" gap={2}>
        <TimeSlotsCopyMenu
          day={day}
          handleCopy={(targets) => {
            for (const target of targets) {
              onChange(target, availability)
            }
          }}
        />
        <IconButton
          gridRow={1}
          gridColumn={3}
          variant="secondary"
          aria-label="Add time slot"
          icon={<PlusCircleOutlineIcon />}
          onClick={addTimeSlot}
        />
      </HStack>
    </Grid>
  )
}

type TimeSlotProps = {
  startsAt: string
  endsAt: string
  onChange: (startsAt: string, endsAt: string) => void
  onDelete: () => void
}

const SELECT_WIDTH = 25

const TimeSlot: React.FC<TimeSlotProps> = ({
  startsAt,
  endsAt,
  onChange,
  onDelete,
}) => {
  return (
    <HStack
      gridColumn={2}
      justify="start"
      align="center"
      gap="2"
      sx={{
        "--controls-opacity": "0",
        _hover: {
          "--controls-opacity": "1",
        },
        '&:has([aria-expanded="true"])': {
          "--controls-opacity": "1",
        },
      }}
    >
      <Select
        size="sm"
        rounded={6}
        value={startsAt}
        onChange={(e) => {
          onChange(e.target.value, endsAt)
        }}
        minWidth="fit-content"
        width={SELECT_WIDTH}
      >
        {getTimes(null, endsAt).map((time: string[]) => (
          <option key={time[0]} value={time[0]}>
            {time[1]}
          </option>
        ))}
      </Select>
      <Text textStyle="ds.paragraph.primary" color="ds.text.subtle">
        to
      </Text>
      <Select
        size="sm"
        rounded={6}
        value={endsAt}
        onChange={(e) => {
          onChange(startsAt, e.target.value)
        }}
        minWidth="fit-content"
        width={SELECT_WIDTH}
      >
        {getTimes(startsAt, null).map((time: string[]) => (
          <option key={time[0]} value={time[0]}>
            {time[1]}
          </option>
        ))}
      </Select>

      <HStack gap={2} opacity={"var(--controls-opacity)"}>
        <Tooltip hasArrow placement="top" label="Delete time slot">
          <IconButton
            variant="secondary"
            aria-label="Delete time slot"
            icon={<Trash01OutlineIcon />}
            onClick={onDelete}
          />
        </Tooltip>
      </HStack>
    </HStack>
  )
}

interface Props {
  day: Day
  handleCopy: (targets: Day[]) => void
}

const TimeSlotsCopyMenu: React.FC<Props> = ({ day: sourceDay, handleCopy }) => {
  const [targetDays, setTargetDays] = useState<Day[]>([])

  const [tooltipDisabled, setTooltipDisabled] = useState(false)

  const onClose = () => {
    setTargetDays([])
    setTooltipDisabled(true)
    setTimeout(() => setTooltipDisabled(false))
  }

  return (
    <Menu onClose={onClose}>
      {({ onClose }) => (
        <>
          <Tooltip
            hasArrow
            placement="top"
            label="Copy times to …"
            isDisabled={tooltipDisabled}
          >
            <MenuButton
              as={IconButton}
              variant="secondary"
              aria-label="Copy times to …"
              icon={<Copy01OutlineIcon />}
              opacity="var(--day-controls-opacity)"
            />
          </Tooltip>
          <MenuList py={2}>
            <MenuGroup title="Copy times to" textColor="text.secondary">
              <Stack spacing={2} p={3}>
                {DAYS.map((d) => (
                  <Checkbox
                    key={`checkbox-${DAY_LABELS[d].shortLabel}`}
                    isChecked={d === sourceDay || targetDays.includes(d)}
                    isDisabled={d === sourceDay}
                    onCheckedChange={() =>
                      setTargetDays((list) =>
                        list.includes(d)
                          ? list.filter((item) => item !== d)
                          : list.concat(d)
                      )
                    }
                  >
                    {DAY_LABELS[d].longLabel}
                  </Checkbox>
                ))}
              </Stack>
            </MenuGroup>
            <MenuDivider />
            <Flex justify="space-around" px={2} gap={1}>
              <Button variant="ghost" onClick={onClose} flexBasis="50%">
                Cancel
              </Button>
              <Button
                colorScheme="brand.primary"
                onClick={() => {
                  handleCopy(targetDays)
                  onClose()
                }}
                flexBasis="50%"
              >
                Apply
              </Button>
            </Flex>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
