import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { PresetList } from "./PresetList"

type Props = {
  onClose: () => void
}

export const PresetModal: React.FC<Props> = ({ onClose }) => {
  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />

      <ModalContent maxW="xl">
        <ModalHeader borderBottomColor="gray.200" borderBottomWidth={1} pb={6}>
          <Heading>Select a saved demographic group</Heading>

          <Text fontSize="md" fontWeight={400} mt={2}>
            Applying a saved group will add all of the corresponding demographic
            selections to your current order.
          </Text>

          <Box
            rounded="md"
            bg="blue.50"
            fontSize="sm"
            fontWeight={400}
            p={4}
            mt={3}
          >
            Any previously added demographic selections on your order will be
            replaced when a saved group is applied.
          </Box>

          <ModalCloseButton />
        </ModalHeader>

        <ModalBody py={4} px={0}>
          <PresetList onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
