import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { DotIcon } from "Shared/icons/DotIcon"
import { CheckOutlineIcon } from "Shared/icons/untitled-ui/CheckOutlineIcon"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { Flag02OutlineIcon } from "Shared/icons/untitled-ui/Flag02OutlineIcon"
import { RefreshCcw02OutlineIcon } from "Shared/icons/untitled-ui/RefreshCcw02OutlineIcon"
import { SlashCircle01OutlineIcon } from "Shared/icons/untitled-ui/SlashCircle01OutlineIcon"
import { Trash01OutlineIcon } from "Shared/icons/untitled-ui/Trash01OutlineIcon"
import React from "react"
import { MarkBookingFunc, isComplete } from "./BookingCard"
import { Booking } from "./types"
import useBookingActions from "./useBookingActions"

type Props = {
  booking: Booking
  bookingActions: ReturnType<typeof useBookingActions>
  onMarkBooking: MarkBookingFunc
}
export const BookingContextMenu: React.FC<Props> = ({
  booking,
  onMarkBooking,
  bookingActions,
}) => {
  const {
    openCancelBookingModal,
    openRescheduleBookingModal,
    markAsComplete,
    markAsPaid,
    markAsNoShow,
    markAsReported,
  } = bookingActions

  const handleCancelBooking = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    openCancelBookingModal()
  }

  const handleRescheduleBooking = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    openRescheduleBookingModal()
  }

  const handleMarkAsComplete = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const { confirm, markAsPaid, rating, comment } = await onMarkBooking({
      booking,
      as: "complete",
    })
    if (confirm) {
      markAsComplete({ mark_as_paid: !!markAsPaid, rating, comment })
    }
  }

  const handleMarkAsReported = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const { confirm, reportReason, reportLink } = await onMarkBooking({
      booking,
      as: "reported",
    })
    if (confirm) {
      markAsReported({ reportReason, reportLink })
    }
  }

  const handleMarkAsPaid = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    markAsPaid()
  }

  const handleMarkAsNoShow = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const { confirm } = await onMarkBooking({
      booking,
      as: "no_show",
    })
    if (confirm) {
      markAsNoShow()
    }
  }

  const bookingHasStarted = booking.starts_at < new Date().toISOString()
  const bookingActive = booking.state === "booked"
  const bookingComplete = isComplete(booking.state)
  const isPanelist = booking.is_panelist

  // Each boolean here corresponds to showing/hiding one menu item below
  const canMarkIncentivePaid =
    bookingComplete && booking.incentive && !booking.incentive_paid
  const canMarkAsComplete = bookingActive && bookingHasStarted
  const canMarkAsNoShow = bookingActive && bookingHasStarted
  const canReport = bookingActive && isPanelist && bookingHasStarted
  const canRequestReschedule = bookingActive
  const canCancel = bookingActive && !bookingHasStarted

  return (
    <Menu isLazy>
      <MenuButton
        as={Button}
        variant="outline"
        size="sm"
        fontWeight="normal"
        rightIcon={<Icon as={ChevronDownOutlineIcon} />}
        position="relative"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        Actions
        {booking.starts_at < new Date().toISOString() &&
          booking.state === "booked" && (
            <DotIcon
              color="red.500"
              position="absolute"
              top="-0.5rem"
              right="-0.5rem"
              boxSize="21px"
              pointerEvents="none"
            />
          )}
      </MenuButton>
      <MenuList overflow="hidden">
        {canMarkIncentivePaid && (
          <MenuItem onClick={handleMarkAsPaid} icon={<CheckOutlineIcon />}>
            Mark as Incentive paid
          </MenuItem>
        )}

        {canMarkAsComplete && (
          <MenuItem
            onClick={handleMarkAsComplete}
            icon={<CheckOutlineIcon color="green.500" />}
            color="green.500"
          >
            Mark as Completed
          </MenuItem>
        )}

        {canMarkAsNoShow && (
          <MenuItem
            onClick={handleMarkAsNoShow}
            icon={<SlashCircle01OutlineIcon color="red.500" />}
            color="red.500"
          >
            Mark as No-show
          </MenuItem>
        )}

        {canReport && (
          <>
            <MenuDivider my={0} />
            <MenuItem
              onClick={handleMarkAsReported}
              icon={<Flag02OutlineIcon color="gray.400" />}
            >
              Report participant
            </MenuItem>
          </>
        )}

        {canRequestReschedule && (
          <MenuItem
            onClick={handleRescheduleBooking}
            icon={<RefreshCcw02OutlineIcon />}
          >
            Request to reschedule
          </MenuItem>
        )}

        {canCancel && (
          <MenuItem
            onClick={handleCancelBooking}
            icon={<Trash01OutlineIcon color="red.500" />}
            color="red.500"
          >
            Cancel session
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}
