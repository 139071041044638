import React from "react"

import {
  DesktopOnly,
  MobileOnly,
} from "Components/break-points/desktop-and-mobile-break-points"
import { PreferenceGrid } from "Components/preference-test-layout/preference-grid"
import PreferenceList from "Components/preference-test-layout/preference-list"
import Props from "Components/preference-test-layout/preference-overview-props"

const PreferenceOverview: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  return (
    <>
      <DesktopOnly key="desktopView">
        <PreferenceGrid {...props} />
      </DesktopOnly>
      <MobileOnly key="mobileView">
        <PreferenceList {...props} />
      </MobileOnly>
    </>
  )
}

export default PreferenceOverview
