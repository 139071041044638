import { Flex, Td, Text, Th, Tr } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import React from "react"

export const DemographicTargetSummaryTableRow: React.FC<{
  heading: string
  value: React.ReactNode
  isSubHeading?: boolean
  hasTags?: boolean
  secondaryText?: string
}> = ({ heading, value, isSubHeading, hasTags, secondaryText }) => (
  <Tr>
    <Th verticalAlign="top">
      <Heading
        as="h4"
        textStyle={isSubHeading ? "ds.heading.secondary" : "ds.heading.primary"}
      >
        {heading}
      </Heading>
    </Th>
    <Td>
      <TdContent
        value={value}
        hasTags={hasTags}
        secondaryText={secondaryText}
      />
    </Td>
  </Tr>
)

const TdContent: React.FC<{
  value: React.ReactNode
  hasTags?: boolean
  secondaryText?: string
}> = ({ value, hasTags, secondaryText }) => {
  const valueContent =
    typeof value === "string" ? (
      <Text as="span" textStyle="ds.paragraph.emphasized">
        {value}
      </Text>
    ) : (
      value
    )

  if (hasTags)
    return (
      <Flex wrap="wrap" gap={2}>
        {valueContent}
      </Flex>
    )

  if (secondaryText)
    return (
      <Flex gap={1} flexDirection="column">
        {valueContent}
        <Text
          textStyle="ds.paragraph.primary"
          color="ds.text.subtle"
          style={{ fontVariantNumeric: "initial" }}
        >
          {secondaryText}
        </Text>
      </Flex>
    )

  return valueContent
}
