import React from "react"

import { NeutralButton } from "Components/button/neutral-button"
import { ThemedButton } from "Components/button/themed-button"
import { PrototypeType } from "JavaScripts/types/figma-file-flow"
import { useTranslate } from "Shared/hooks/useTranslate"

import { UsePrototypeReturn } from "../usePrototypeTask"

import { PrototypeTaskPhase } from "./usePrototypeTaskPhases"

interface ButtonProps {
  prototypeType: PrototypeType
  prototypeTaskPhase: PrototypeTaskPhase
  prototypeTask: UsePrototypeReturn
  advanceToNextPhase: () => void
}

export const TaskFlowOrFreeRoamButton: React.FC<
  React.PropsWithChildren<ButtonProps>
> = ({
  prototypeType,
  prototypeTaskPhase,
  prototypeTask,
  advanceToNextPhase,
}) => {
  const translate = useTranslate()

  if (prototypeType === "task_flow") {
    if (prototypeTaskPhase === "interacting")
      return (
        <NeutralButton
          onClick={() => {
            advanceToNextPhase?.()
          }}
          isDisabled={!prototypeTask.isTaskFinishEnabled}
        >
          {translate("test.buttons.end_task")}
        </NeutralButton>
      )
    if (prototypeTaskPhase === "success_screen")
      return (
        <ThemedButton
          onClick={advanceToNextPhase}
          isDisabled={!prototypeTask.isTaskFinishEnabled}
        >
          {translate("test.buttons.continue")}
        </ThemedButton>
      )

    // Here we show a question component instead as part of the content,
    // and it has its own button
    if (prototypeTaskPhase === "answering_questions") {
      return null
    }
  } else if (prototypeType === "free_roam") {
    if (prototypeTaskPhase === "interacting") {
      return (
        <ThemedButton
          onClick={advanceToNextPhase}
          isDisabled={!prototypeTask.isTaskFinishEnabled}
        >
          {translate("test.buttons.continue")}
        </ThemedButton>
      )
    }

    // Here we show a question component instead as part of the content,
    // and it has its own button
    if (prototypeTaskPhase === "answering_questions") {
      return null
    }
  }

  return null
}
