import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Alert, Heading } from "DesignSystem/components"
import { CustomerSupportMailtoLink } from "Shared/components/Links/CustomerSupportMailtoLink"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import React, { useCallback } from "react"
import { useFieldArray } from "react-hook-form"
import { useOnboardingContext } from "./OnboardingContextProvider"
import { EmailAddress, InviteTeamStep } from "./types"
import { isFailed, isSuccessful } from "./useInviteTeamMembers"
import { useOnboardingStep } from "./useOnboardingStep"

type InviteTeamFormProps = {
  step: InviteTeamStep
}

export const InviteTeamForm: React.FC<InviteTeamFormProps> = ({ step }) => {
  const { inviteTeamMembers, invitedTeamMembers, isLoading, submit } =
    useOnboardingContext()

  const { handleSubmit, control, register, watch } = useOnboardingStep(step)

  const { fields } = useFieldArray({ control, name: "teamMembers" })

  const sendInvites = useCallback(
    async (data: InviteTeamStep["data"]) => {
      const emails = data.teamMembers
        .filter(
          (t) => !!t.email && !isSuccessful(invitedTeamMembers.get(t.email))
        )
        .map((t) => t.email) as EmailAddress[]
      if (emails.length) {
        inviteTeamMembers(emails, (results) => {
          if (![...results.entries()].some(([_, v]) => isFailed(v))) {
            submit(data)
          }
        })
      } else {
        submit(data)
      }
    },
    [inviteTeamMembers, invitedTeamMembers, submit]
  )

  const teamMembers = watch("teamMembers")

  return (
    <Stack
      as="form"
      id="onboarding_form"
      spacing={6}
      width="100%"
      onSubmit={handleSubmit(sendInvites)}
    >
      <Heading
        as="h1"
        textStyle="ds.display.emphasized"
        color="ds.text.default"
      >
        Who do you collaborate with?
      </Heading>

      <Text textStyle="ds.paragraph.emphasized" color="ds.text.default">
        Invite your team to get the most out of Lyssna.
      </Text>

      {fields.length > 0 ? (
        <>
          <Stack spacing={2}>
            {fields.map((field, index) => {
              const props = register(`teamMembers.${index}.email` as const)
              const email = teamMembers[index].email
              const result = email && invitedTeamMembers.get(email)
              const succeeded = isSuccessful(result)
              const firstIndex = Math.max(
                0,
                teamMembers.findIndex(
                  (t) =>
                    !t ||
                    (t?.email && !isSuccessful(invitedTeamMembers.get(t.email)))
                )
              )
              return (
                <FormControl
                  key={field.id}
                  isDisabled={succeeded || undefined}
                  isInvalid={(email && isFailed(result)) || undefined}
                >
                  {index === 0 && (
                    <FormLabel sx={{ "&[data-disabled]": { opacity: 1 } }}>
                      <Text
                        textStyle="ds.heading.primary"
                        color="ds.text.default"
                      >
                        Invite with email
                      </Text>
                    </FormLabel>
                  )}
                  {succeeded ? (
                    <HStack
                      align="center"
                      bg="bg.neutral.subtle.hover"
                      rounded={8}
                      px={4}
                      py={2}
                    >
                      <CheckCircleSolidIcon color="icon.subtle" />
                      <Text as="span" flex={1}>
                        {teamMembers[index].email}
                      </Text>
                      <Text
                        as="span"
                        color="status.success"
                        fontWeight="medium"
                      >
                        Invite sent
                      </Text>
                    </HStack>
                  ) : (
                    <InputGroup>
                      <Input
                        placeholder="coworker@lyssna.com"
                        autoFocus={index === firstIndex || undefined}
                        autoComplete="off"
                        {...props}
                      />
                    </InputGroup>
                  )}
                  {email && isFailed(result) && (
                    <FormErrorMessage>{result.error}</FormErrorMessage>
                  )}
                </FormControl>
              )
            })}
          </Stack>
          <Alert
            status="info"
            description="Need more? You can add more collaborators later."
          />
        </>
      ) : (
        !isLoading && (
          <Stack gap={0}>
            <Text>
              You have reached the maximum number of team members on your
              current plan. Please{" "}
              <CustomerSupportMailtoLink>
                contact support
              </CustomerSupportMailtoLink>{" "}
              for more information.
            </Text>
          </Stack>
        )
      )}
    </Stack>
  )
}
