import {
  Stripe,
  StripeCardElement,
  StripeError,
  loadStripe,
} from "@stripe/stripe-js"
import { BaseError } from "make-error"

import { getEnvState } from "JavaScripts/state"
import { reportErrorToSentry } from "Utilities/error"
import { neverResolve } from "Utilities/promise"
import { Result, getResultValue, promiseToResult } from "Utilities/result"
import { ScriptLoadError, loadScript } from "Utilities/script"

const env = getEnvState()
const StripePublicKey = env.STRIPE_PUBLIC_KEY
const StripeScriptUri = env.STRIPE_SCRIPT_URI

export class CreditCardDeclinedError extends BaseError {
  declineCode: string | null
  constructor(message: string, declineCode: string | null) {
    super(message)
    this.declineCode = declineCode
  }
}

export function throwIfDeclinedStripeError(error: StripeError): void {
  if (error.code === "card_declined") {
    throw new CreditCardDeclinedError(
      error.message!,
      error.decline_code || null
    )
  }
}

export function throwIfDeclinedErrorMessage(message: string | null): void {
  if (message !== null && message.includes("Your card was declined")) {
    throw new CreditCardDeclinedError(message, null)
  }
}

export async function vaultCardInStripe(
  cardElement: StripeCardElement | null,
  cardName: string,
  stripe: Stripe | null
): Promise<string> {
  if (stripe === null) {
    throw new Error("Stripe not found, please reload the page and try again.")
  }
  if (cardElement === null) {
    throw new Error(
      "Card details not found, please reload the page and try again."
    )
  }
  const { token, error } = await stripe.createToken(cardElement, {
    name: cardName,
  })
  if (error !== undefined) {
    throwIfDeclinedStripeError(error)
    throw new Error(error.message)
  }
  if (token === undefined) {
    throw new Error("Failed to create token")
  }
  return token.id
}

async function loadStripePromise(): Promise<Stripe | null> {
  // Validate injected vars
  if (typeof StripeScriptUri !== "string") {
    throw new TypeError(`Invalid stripe script URI: ${String(StripeScriptUri)}`)
  }
  if (typeof StripePublicKey !== "string") {
    throw new TypeError(`Invalid stripe public key: ${String(StripePublicKey)}`)
  }

  // This shouldn't happen, but I'm leaving it here just in case someone gets
  // confused and adds it back to the DOM.
  if (window.Stripe !== undefined) {
    const stripe = loadStripe(StripePublicKey)
    reportErrorToSentry(
      new TypeError(
        "Stripe was already defined when trying to load it dynamically"
      )
    )
    return stripe
  }

  // Dynamically add the stripe script element to the DOM. It is recommended
  // that we always include the Stripe script whether it's used in the current
  // view or not.
  await loadScript(StripeScriptUri)
  return loadStripe(StripePublicKey)
}

// NOTE: This function _cannot_ raise an error because the browser will
// recognise it as "unhandled" and report to Sentry. Instead we wrap the promise
// in a Result to be deferred until requested.
async function createStripePromise(): Promise<Result<Stripe | null>> {
  return promiseToResult(loadStripePromise())
}

// NOTE: It is advisable to _always_ include stripe on every page load, even
// when no payment will occur on the page. This is used to aid fraud
// detection. This is why we're not lazily adding the script to the DOM in
// `getStripe`.
const stripePromise = createStripePromise()

export async function getStripe(): Promise<Stripe | null> {
  try {
    return getResultValue(await stripePromise)
  } catch (error) {
    if (error instanceof ScriptLoadError) {
      if (
        confirm(
          `Sorry, we couldn${"\u2019"}t reach our payment server. ` +
            "Please refresh the page to try again.\n\n" +
            "Press OK to reload the page."
        )
      ) {
        window.location.reload()
        return neverResolve()
      }
    }
    throw error
  }
}
