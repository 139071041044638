import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Center,
  Flex,
  FormLabel,
  Icon,
  Spinner,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { MicrosoftIcon } from "Shared/icons/MicrosoftIcon"
import { useIntegrations } from "UsabilityHub/hooks/useIntegrations"
import { useMicrosoftOAuth } from "UsabilityHub/hooks/useMicrosoftOAuth"
import { getDateString } from "Utilities/date-formats"
import React from "react"
import { useListOutlookCalendars } from "~/api/generated/usabilityhub-components"

type Props = {
  toggleCalendar: (cal: any, checked: boolean) => void
  selectedCalendars: { id: string }[]
}
export const OutlookConflictCalendars: React.FC<Props> = ({
  toggleCalendar,
  selectedCalendars,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { data: integrations } = useIntegrations()
  const connected = !!integrations?.microsoft

  const {
    data: outlookCalendars,
    error,
    isLoading,
  } = useListOutlookCalendars(
    {},
    {
      enabled: connected,
    }
  )

  const { handleMicrosoftOAuth } = useMicrosoftOAuth(connected, () => {
    toast({
      title: "Microsoft account connected",
      status: "success",
      duration: 3000,
    })
    queryClient.invalidateQueries(["api", "outlook_calendars"])
    queryClient.invalidateQueries(["api", "moderated_studies"])
    queryClient.invalidateQueries(["api", "integrations"], { exact: true })
  })

  return (
    <Flex gap={4}>
      <Icon as={MicrosoftIcon} boxSize={10} />

      <Flex direction="column" gap={3}>
        <Text color="text.primary" fontSize="md" fontWeight="semibold">
          Microsoft
        </Text>

        <Text>
          Connect your Microsoft account to check your Outlook Calendar
          availability and sync events
        </Text>

        {connected && integrations && (
          <>
            <Text color="text.secondary">
              Connected on {getDateString(integrations.microsoft?.created_at)}
            </Text>

            {isLoading && (
              <Center minH="200px">
                <Spinner />
              </Center>
            )}

            {error && (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>{error.payload.message}</AlertDescription>
              </Alert>
            )}

            {!isLoading && !error && outlookCalendars && (
              <Flex direction="column" gap={2}>
                {outlookCalendars.map((cal) => (
                  <Flex gap={3} key={cal.id}>
                    <Switch
                      id={cal.id}
                      data-qa="toggle-conflict-calendar-switch"
                      onChange={(event) =>
                        toggleCalendar(cal, event.target.checked)
                      }
                      isChecked={selectedCalendars
                        .map((c) => c.id)
                        .includes(cal.id)}
                    />
                    <FormLabel
                      fontSize="md"
                      color="text.primary"
                      my={0}
                      htmlFor={cal.id}
                      cursor="pointer"
                    >
                      {cal.summary}
                    </FormLabel>
                  </Flex>
                ))}
              </Flex>
            )}
          </>
        )}
      </Flex>

      {!connected && integrations && (
        <Button size="sm" flexShrink={0} onClick={handleMicrosoftOAuth}>
          Connect
        </Button>
      )}
    </Flex>
  )
}
