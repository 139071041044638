import { Button, Flex, Heading, Tag, Text } from "@chakra-ui/react"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ReportIcon } from "Shared/icons/ReportIcon"
import React from "react"

type Props = {
  onContinue: () => void
  isPanelist: boolean
  showReportModal: () => void
}

export const ScreenedInScreen: React.FC<Props> = ({
  onContinue,
  isPanelist,
  showReportModal,
}) => {
  const t = useTranslate()

  return (
    <Flex direction="column" justify="center" h="full" mx={[6, 12]}>
      <Flex direction="column" align="flex-start">
        {isPanelist && (
          <Button
            position="absolute"
            top={4}
            right={4}
            onClick={showReportModal}
          >
            <ReportIcon />
          </Button>
        )}

        <Tag textTransform="uppercase">{t("test.screener.screener_tag")}</Tag>

        <Heading as="h2" fontFamily="promo" size="lg" mt={6}>
          {t("test.screener.screened_in.heading")}
        </Heading>

        <Text mt={4}>{t("test.screener.screened_in.body")}</Text>

        <Flex mt={8} gap={2}>
          <Button colorScheme="brand.primary" onClick={onContinue}>
            {t("test.buttons.continue")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
