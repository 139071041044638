import { Icon } from "@chakra-ui/react"
import classNames from "classnames"
import { isEmpty } from "lodash"
import React, { ReactNode } from "react"

import { ClearButton } from "Components/column-form/clear-button"
import { ColumnFormSection } from "Components/column-form/column-form-section"
import styles from "Components/column-form/column-form.module.scss"
import { CustomTooltip } from "Components/tooltip/CustomTooltip"
import { HelpCircleSolidIcon } from "Shared/icons/untitled-ui/HelpCircleSolidIcon"

interface Props {
  title: ReactNode
  onClear: () => void
  disabled: boolean
  infoMessage?: string
}

export const ClearableFormSection: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { children, disabled, infoMessage, title, onClear } = props
  // NOTE: `React.Children.count(children)` returns `1` for `false`, so using
  //       `isEmpty` instead.
  const hasChildren = !isEmpty(children)
  return (
    <ColumnFormSection>
      <h1
        className={classNames(
          "heading",
          styles.sectionHeading,
          hasChildren || styles.noChildren
        )}
      >
        <span>{title}</span>
        {disabled && infoMessage && (
          <CustomTooltip tooltip={infoMessage} color="ds.text.subtle" p={5}>
            <Icon
              as={HelpCircleSolidIcon}
              color="brand.primary.500"
              boxSize={5}
            />
          </CustomTooltip>
        )}
        {!disabled && <ClearButton onClick={onClear} disabled={disabled} />}
      </h1>
      {children}
    </ColumnFormSection>
  )
}
