import { useQueryClient } from "@tanstack/react-query"
import { UsabilityTestRecruitPageResponse } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

export type RecruitPageUsabilityTest =
  UsabilityTestRecruitPageResponse["usability_test"]

export const useRefreshUsabilityTestRecruit = (
  usabilityTestUniqueId: string
) => {
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  const queryKey = queryKeyFn({
    path: "/api/usability_tests/{usability_test_id}/recruit",
    operationId: "usabilityTestRecruitPage",
    variables: { pathParams: { usabilityTestId: usabilityTestUniqueId } },
  })

  return async () => queryClient.invalidateQueries(queryKey)
}
