import { useEffect, useRef, useState } from "react"

// This hook is a thin wrapper around the browser's native IntersectionObserver API
// which lets us subscribe to be notified when elements start or stop intersecting with
// each other.  In this case we're taking a specific element and checking when it begins
// to intersect with the viewport, so we can use it as an infinite scroll trigger.
//
// It returns a function you can attach to the `ref` prop of the element you want to monitor.
export function useInfiniteScroll<T extends HTMLElement>(callback: () => void) {
  const [targetElement, setTargetElement] = useState<T | null>(null)
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    if (targetElement) {
      observer.current = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          callback()
        }
      })

      observer.current.observe(targetElement)
    }

    return () => {
      observer.current?.disconnect()
    }
  }, [callback, targetElement])

  // Attach this to the `ref` of a div (or any other element) that you want to monitor for intersection
  // with the viewport.
  return setTargetElement
}
