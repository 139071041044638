import { Center, Flex, Image, Spinner, Text } from "@chakra-ui/react"
import React from "react"
import UHtoLyssna from "./UHtoLyssna.png"

export const RedirectToLyssnaLoadingScreen = () => {
  const textStyles = {
    fontSize: { base: "md", md: "xl" },
    fontWeight: "medium",
    textAlign: "center",
    w: "100%",
  }

  return (
    <Center height="100vh" width="100%">
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        margin="24px"
      >
        <Image
          src={UHtoLyssna}
          alt="UsabilityHub is now Lyssna"
          maxW="520px"
          w="100%"
          marginBottom="7"
        />
        <Text sx={textStyles}>UsabilityHub.com is now Lyssna.com.</Text>
        <Text sx={textStyles}>Loading your elevated experience.</Text>
        <Spinner
          color="brand.primary.500"
          width="52px"
          height="52px"
          thickness="5px"
          margin="24px auto"
        />
      </Flex>
    </Center>
  )
}
