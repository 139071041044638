import AwsS3Multipart, { AwsS3UploadParameters } from "@uppy/aws-s3-multipart"
import { SuccessResponse, Uppy, UppyFile } from "@uppy/core"
import XHRUpload from "@uppy/xhr-upload"
import Constants from "Constants/shared.json"
import { interviewVideoUploadedManuallyGoogle } from "JavaScripts/analytics/google"
import { getEnvState } from "JavaScripts/state"
import { AppContext } from "Shared/contexts/AppContext"
import { reportErrorToSentry } from "Utilities/error"
import { useContext, useEffect, useRef, useState } from "react"
import {
  fetchListParts,
  useAbortMultipartUpload,
  useAddRecordingToModeratedStudyBooking,
  useCompleteMultipartUpload,
  useCreateMultipartUpload,
  usePostPresignedConfig,
  useSignPart,
} from "~/api/generated/usabilityhub-components"

type Metadata = {
  name: string
  url: string
}

type WebsocketMessage =
  | {
      action: "complete"
      data: {
        status: 200
        recording_id: string
      }
    }
  | {
      action: "failed"
      data: {
        status: 500
        error: string
      }
    }

export const useUploadRecording = (
  moderatedStudyId: string,
  sessionId: string,
  onProgress: (fraction: number) => void
) => {
  const { consumer } = useContext(AppContext)
  const [currentFile, setCurrentFile] = useState<File | null>(null)
  const [uploading, setUploading] = useState<boolean>(false)
  const [complete, setComplete] = useState<boolean>(false)
  const [metadata, setMetadata] = useState<Metadata | null>(null)
  const [jobId, setJobId] = useState<string | null>(null)

  const { mutateAsync: presignedConfig } = usePostPresignedConfig()
  const { mutateAsync: createMultipartUpload } = useCreateMultipartUpload()
  const { mutateAsync: completeMultipartUpload } = useCompleteMultipartUpload()
  const { mutateAsync: abortMultipartUpload } = useAbortMultipartUpload()
  const { mutateAsync: signPart } = useSignPart()

  // Use Uppy to co-ordinate getting pre-signed URLs and performing direct uploads.
  const initUppy = () => {
    const uppy = new Uppy({
      id: sessionId,
      restrictions: {
        // Don't use Uppy's size validation as it throws generic errors we can't reason on
        // maxFileSize: Constants.MAX_RECORDING_FILESIZE_IN_BYTES,
        maxNumberOfFiles: 1,
      },
      // https://uppy.io/docs/uppy/#debuglogger
      // logger: debugLogger,
    })
      .on("upload-progress", (_file, progress) => {
        // Scale to 99%. The last 1% is sending the attachement metadata to the app
        onProgress((progress.bytesUploaded * 0.99) / progress.bytesTotal)
      })
      .on("upload-error", (_file, error, _response) => {
        onFailure(error.message)
        reportErrorToSentry(error)
      })
    if (getEnvState().S3_RECORDINGS) {
      uppy
        .use(AwsS3Multipart, {
          shouldUseMultipart: (file: UppyFile) => {
            // Use multipart only for files larger than 100MB.
            // https://uppy.io/docs/aws-s3-multipart/#shouldusemultipartfile
            return file.size > 100 * 2 ** 20
          },
          // Get config for a pre-signed S3 URL for single part uploads
          getUploadParameters: (file: UppyFile) => {
            return new Promise<AwsS3UploadParameters>((resolve, reject) => {
              presignedConfig(
                {},
                {
                  onSuccess: (data) => {
                    resolve({
                      method: "POST",
                      url: data.url,
                      fields: {
                        "Content-Type": file.type!,
                        ...data.fields,
                      },
                    })
                  },
                  onError: (error) => reject(error.payload.message),
                }
              )
            })
          },
          createMultipartUpload: () => {
            return new Promise<{ uploadId: string; key: string }>(
              (resolve, reject) => {
                createMultipartUpload(
                  {},
                  {
                    onSuccess: (data) =>
                      resolve({ uploadId: data.upload_id, key: data.key }),
                    onError: (error) => reject(error.payload.message),
                  }
                )
              }
            )
          },
          listParts: async (_file, opts) => {
            return await fetchListParts({
              pathParams: { key: opts.key, uploadId: opts.uploadId },
            })
              .then((data) => {
                return data.map((part) => {
                  return {
                    PartNumber: part.part_number,
                    Size: part.size,
                    ETag: part.etag,
                  }
                })
              })
              .catch(() => {
                throw new Error("Failed to list parts")
              })
          },
          signPart: async (_file, opts) => {
            // Uppy stalls out if we return a Promise here
            const data = await signPart(
              {
                pathParams: {
                  key: opts.key,
                  uploadId: opts.uploadId,
                  partNumber: opts.partNumber,
                },
              },
              {
                onError: (error) => {
                  // generify the error so the message is available to Uppy's "upload-error" event
                  throw new Error(error.payload.message)
                },
              }
            )
            return { method: "PUT", url: data.url }
          },
          completeMultipartUpload: (_file, opts) => {
            return new Promise<{ location?: string }>((resolve, reject) => {
              completeMultipartUpload(
                {
                  pathParams: {
                    key: opts.key,
                    uploadId: opts.uploadId,
                  },
                  body: {
                    parts: opts.parts.map((part) => {
                      return {
                        part_number: part.PartNumber!,
                        etag: part.ETag!,
                      }
                    }),
                  },
                },
                {
                  onSuccess: (data) => resolve({ location: data.location }),
                  onError: (error) => reject(error.payload.message),
                }
              )
            })
          },
          abortMultipartUpload: (_file, opts) => {
            return new Promise<void>((resolve, reject) => {
              abortMultipartUpload(
                {
                  pathParams: {
                    key: opts.key,
                    uploadId: opts.uploadId,
                  },
                },
                {
                  onSuccess: () => resolve(),
                  onError: (error) => reject(error.payload.message),
                }
              )
            })
          },
        })
        .on("upload-success", (file: UppyFile, response: SuccessResponse) => {
          // Only use the last part of URL, the rest will be derived on the server for better security
          const key =
            // we return an unescaped URL from multipart uploads
            response.uploadURL?.match(/\/cache\/[a-z0-9]+\/([^\?]+)/)?.[1] ??
            // Uppy returns an escaped URL from singlepart uploads
            response.uploadURL?.match(/%2Fcache%2F[a-z0-9]+%2F([^\?]+)/)?.[1]
          if (key) {
            // 3: for S3 uploads. Once the upload has completed, send the file metadata to the app
            addBooking({
              pathParams: {
                moderatedStudyId: moderatedStudyId,
                moderatedStudyBookingId: sessionId,
              },
              body: { key, filename: file.name },
            })
          } else {
            onFailure("Couldn't find key in S3 response")
          }
        })
    } else {
      uppy
        .use(XHRUpload, { endpoint: "/api/storage/recording" })
        .on("upload-success", (file: UppyFile, response: SuccessResponse) => {
          // 3: for test uploads. Once the upload has completed, send the file metadata to the app
          addBooking({
            pathParams: {
              moderatedStudyId: moderatedStudyId,
              moderatedStudyBookingId: sessionId,
            },
            body: {
              // Only use the last part of URL, the rest will be derived on the server for better security
              key: response.body["id"].match(/([^\/]+$)/)?.[0],
              filename: file.name,
            },
          })
        })
    }
    return uppy
  }
  // Outer components get re-rendered a lot. Need the original Uppy reference to cancel uploads
  const uppy = useRef(initUppy())

  // right now, we don't use these strings, but we may revisit this from a UX perspective
  const [error, setError] = useState<string | null>(null)

  const onFailure = (message: string) => {
    setCurrentFile(null)
    uppy.current
      .getFiles()
      .forEach((uppyFile) => uppy.current.removeFile(uppyFile.id))
    setUploading(false)
    setComplete(false)
    onProgress(0)
    setError(message)
  }

  const onSuccess = () => {
    onProgress(1)
    setUploading(false)
    setComplete(true)
    interviewVideoUploadedManuallyGoogle()
  }

  useEffect(() => {
    if (jobId) {
      // 5: subscribe to updates about the job we're waiting for
      const channel = consumer?.subscriptions.create(
        {
          channel: "RecordingUploadChannel",
          job_id: jobId,
        },
        {
          received: (message: WebsocketMessage) => {
            if (message.action === "complete") {
              // 6: done!
              onSuccess()
            } else {
              onFailure(message.data.error)
            }
          },
        }
      )

      return () => {
        channel?.unsubscribe()
      }
    }
  }, [jobId, consumer])

  const { mutate: addBooking } = useAddRecordingToModeratedStudyBooking({
    onSuccess: (data) => {
      // 4: once the app has accepted the file metadata, set the job to wait for
      setJobId(data.job_id)
    },
    onError: (error) => onFailure(error?.payload.message),
  })

  const maxRecordingFilesizeGb =
    Constants.MAX_RECORDING_FILESIZE_IN_BYTES / 1024 / 1024 / 1024

  const startUpload = () => {
    if (currentFile && !uploading && !complete) {
      if (currentFile.size < Constants.MAX_RECORDING_FILESIZE_IN_BYTES) {
        setUploading(true)
        setComplete(false)
        setError(null)
        setMetadata({
          name: currentFile.name,
          url: URL.createObjectURL(currentFile),
        })

        // 2: pass control to Uppy to manage the upload. Details on Uppy config
        uppy.current.addFile({
          data: currentFile,
          name: currentFile.name,
        })
        uppy.current.upload()
      } else {
        setError(`Recording is too large (max is ${maxRecordingFilesizeGb}GB)`)
      }
    }
  }

  const abortUpload = () => {
    if (currentFile && uploading && !complete) {
      uppy.current.cancelAll()
      setCurrentFile(null)
      setUploading(false)
      setComplete(false)
      setMetadata(null)
      onProgress(0)
    }
  }

  useEffect(() => {
    if (currentFile && !uploading && !complete) {
      // 1: start the upload process when a file is added
      startUpload()
    }
  }, [currentFile, uploading, complete])

  return {
    upload: (file: File) => {
      // 0: set the file to upload to kick things off
      setCurrentFile(file)
    },
    abortUpload,
    uploading,
    complete,
    metadata,
    error,
  }
}
