import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  HStack,
} from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"

import { useLanguage } from "Hooks/use-language"
import { getFormValues } from "Redux/reducers/test-builder-form/selectors/formValues"
import { CustomerSupportMailtoLink } from "Shared/components/Links/CustomerSupportMailtoLink"
import {
  TestCard,
  TestCardBody,
  TestCardHeader,
  TestCardHeading,
  TestCardTitle,
} from "UsabilityHub/components/TestCard/TestCard"

import { ShallowUsabilityTest } from "Types"
import { NewProjectField } from "./NewProjectField"
import {
  TestLanguageField,
  TestNameField,
  TestPermittedDeviceTypeField,
} from "./TestDetailsFields"

export const TestDetailsCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const formValues = useSelector(getFormValues) as Partial<ShallowUsabilityTest>
  const selectedLanguage = useLanguage(formValues.language_code ?? null)

  return (
    <TestCard id="test-details-card">
      <TestCardHeader>
        <TestCardTitle>
          <TestCardHeading>Study details</TestCardHeading>
        </TestCardTitle>
      </TestCardHeader>
      <TestCardBody>
        <HStack spacing={6}>
          <TestNameField />
          <TestLanguageField />
        </HStack>
        <HStack spacing={6} alignItems="start">
          <NewProjectField />
          <TestPermittedDeviceTypeField />
        </HStack>
        {selectedLanguage && !selectedLanguage.is_supported && (
          <Alert status="warning" alignItems="flex-start">
            <AlertIcon />
            <Box>
              <AlertTitle mr={2}>
                We don{"\u2019"}t yet have our test taking interface translated
                into {selectedLanguage.english_name}.
              </AlertTitle>
              <AlertDescription>
                You can still choose this language, but some instructions on the
                page will be written in English. Please{" "}
                <CustomerSupportMailtoLink>
                  get in touch
                </CustomerSupportMailtoLink>{" "}
                if you{"\u2019"}d like to request this translation.
              </AlertDescription>
            </Box>
          </Alert>
        )}
      </TestCardBody>
    </TestCard>
  )
}
