import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react"
import React from "react"

import { getEnvState } from "JavaScripts/state"
import { panelistSignUpPath } from "Shared/constants/routes"

type RedirectedFromUserCrowdAlert = React.FC<React.PropsWithChildren<unknown>>

export const RedirectedFromUserCrowdAlert: RedirectedFromUserCrowdAlert =
  () => {
    const env = getEnvState()
    const userCrowdSignUpUrl = panelistSignUpPath(env.PANELIST_DOMAIN)

    return (
      <Alert status="error" justifyContent="center">
        <AlertIcon />
        <AlertTitle mr={2}>
          Customer accounts cannot log in to UserCrowd.
        </AlertTitle>
        <AlertDescription>
          You need to{" "}
          <a href={userCrowdSignUpUrl}>sign up for a separate tester account</a>{" "}
          to access UserCrowd. If you intended to log in to Lyssna, please log
          in again below.
        </AlertDescription>
      </Alert>
    )
  }
