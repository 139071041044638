import { Tag, TagProps, Tooltip } from "@chakra-ui/react"
import React from "react"

type AccountOwnerTag = React.FC<React.PropsWithChildren<TagProps>>

export const AccountOwnerTag: AccountOwnerTag = (tagProps) => {
  const label = "This user is the account owner"
  return (
    <Tooltip
      aria-label={label}
      label={label}
      zIndex={1900}
      placement="top"
      hasArrow
    >
      <Tag size="sm" colorScheme="orange" {...tagProps}>
        Owner
      </Tag>
    </Tooltip>
  )
}
