import { Button, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { Plan } from "~/api/generated/usabilityhubSchemas"
import { ConfirmBillingPeriodChangeModal } from "./ConfirmBillingPeriodChangeModal"

type ConfirmBillingPeriodChangeLink = React.FC<
  React.PropsWithChildren<{
    readonly currentPlan: Plan
    readonly newPlan: Plan
    readonly subscriptionId: string
  }>
>

export const ConfirmBillingPeriodChangeLink: ConfirmBillingPeriodChangeLink = ({
  currentPlan,
  newPlan,
  subscriptionId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const buttonText =
    currentPlan.interval === "month"
      ? `Pay annually and save`
      : `Switch to monthly billing`

  return (
    <>
      <Button variant="outline" onClick={onOpen}>
        {buttonText}
      </Button>
      <ConfirmBillingPeriodChangeModal
        isOpen={isOpen}
        onClose={onClose}
        currentPlan={currentPlan}
        newPlan={newPlan}
        subscriptionId={subscriptionId}
      />
    </>
  )
}
