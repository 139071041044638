import {
  BoxProps,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react"
import { useToast } from "@chakra-ui/react"
import { Language, Option, ParticipantDeletionReason as Reason } from "Types"
import { throwIfNonAxiosError } from "Utilities/error"
import { memoizeOnce } from "Utilities/memoization"
import React, { useState } from "react"
import { defaultValueToKey } from "../form/default-value-to-key"

const EXTERNAL_STUDY_REASONS: ReadonlyArray<Option<Reason>> = [
  {
    value: Reason.NoCompletionCode,
    label: "The test did not provide a completion code",
  },
  {
    value: Reason.ParticipantIdNotRequested,
    label: "The test did not ask for my Participant ID",
  },
]

const USABILITY_TEST_REASONS: ReadonlyArray<Option<Reason>> = [
  {
    value: Reason.TestSentMeOffsite,
    label: "This test asked me to complete tasks on another website",
  },
]

const optionsForLanguage = memoizeOnce(
  (language: Language, isExternal: boolean): ReadonlyArray<Option<Reason>> => {
    return [
      {
        value: Reason.IncorrectProfileLanguage,
        label: `I don${"\u2019"}t speak ${language.english_name}`,
      },
      {
        value: Reason.IncorrectTestLanguage,
        label: `This test isn${"\u2019"}t in ${language.english_name}`,
      },
      {
        value: Reason.InappropriateTest,
        label: "This test contains inappropriate content",
      },
      {
        value: Reason.ConfusingTest,
        label: "This test is confusing or poorly worded",
      },
      {
        value: Reason.TechnicalProblem,
        label: "There is a technical problem with this test",
      },
      {
        value: Reason.TestRequestedPersonalInformation,
        label: (
          <>
            This test is requesting my personal information
            <br />
            (e.g. email address)
          </>
        ),
      },
      ...(isExternal ? EXTERNAL_STUDY_REASONS : USABILITY_TEST_REASONS),
      { value: Reason.Skipped, label: "I just want to skip it" },
    ]
  }
)

interface Props {
  readonly onSkip: (reason: Reason) => Promise<void>
  readonly onClose: () => void
  readonly language: Language
  readonly isOpen: boolean
  isExternal?: boolean
}

export const SkipUsabilityTestModal: React.FC<
  React.PropsWithChildren<Props>
> = ({ onSkip, onClose, language, isOpen, isExternal = false }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="lg">
        <ModalHeader>
          Are you sure you want to skip this test?
          <Text fontSize="md" fontWeight="normal">
            Please select the reason you want to skip it.
          </Text>
        </ModalHeader>
        <ModalBody mb={4}>
          <SkipUsabilityTestForm
            isExternal={isExternal}
            onClose={onClose}
            onSkip={onSkip}
            language={language}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

type SkipUsabilityTestFormProps = {
  onClose: () => void
  onSkip: (reason: Reason) => Promise<void>
  language: Language
  isExternal: boolean
} & BoxProps

export const SkipUsabilityTestForm: React.FC<SkipUsabilityTestFormProps> = ({
  onClose,
  onSkip,
  language,
  isExternal,
}) => {
  const [selectedReason, setSelectedReason] = useState<null | Reason>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const toast = useToast()

  const handleSubmit = async () => {
    if (selectedReason !== null) {
      setIsSubmitting(true)
      try {
        await onSkip(selectedReason)
        setIsSubmitting(false)
        onClose()
      } catch (error) {
        throwIfNonAxiosError(error)
        toast({
          title: "Something went wrong",
          description: "Please try again.",
          status: "error",
          position: "top-right",
        })
        setIsSubmitting(false)
      }
    }
  }

  const handleSelectReason = (reason: Reason) => {
    setSelectedReason(reason)
  }

  return (
    <>
      <RadioGroup
        onChange={handleSelectReason}
        defaultValue={selectedReason ?? ""}
      >
        <Stack>
          {optionsForLanguage(language, isExternal).map((option) => (
            <Radio key={defaultValueToKey(option.value)} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>

      <Flex gap={2} justify="flex-end" mt={4}>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          colorScheme="brand.primary"
          isDisabled={selectedReason === null || isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting && <Spinner size="xs" />} Skip the test
        </Button>
      </Flex>
    </>
  )
}
