import { Button, ButtonProps } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { PlanUpgradeSource } from "Types"
import {
  DisabledPlanButton,
  ManualSubscription,
  NonAdmin,
} from "UsabilityHub/components/PlanChanger/disabled-plan-button"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import {
  useCurrentUser,
  useMaybeCurrentAccount,
} from "UsabilityHub/hooks/useCurrentAccount"
import { usePlan } from "UsabilityHub/hooks/usePlans"
import React from "react"
import { Plan } from "~/api/generated/usabilityhubSchemas"
import { Sparkles } from "./RandomSparkles"

type Props = {
  source: PlanUpgradeSource
  buttonText?: string
  // It can be the monthly or annual pro plan
  plan?: Plan
  withPlanDrawerTrigger?: boolean
}

export const SubscribeToProButton: React.FC<Props & ButtonProps> = ({
  source,
  buttonText = "Subscribe to Pro",
  plan,
  withPlanDrawerTrigger = true,
  ...props
}) => {
  if (
    plan &&
    plan.unique_id !== Constants.PRO_V3_MONTHLY_PLAN_UNIQUE_ID &&
    plan.unique_id !== Constants.PRO_V3_ANNUAL_PLAN_UNIQUE_ID
  ) {
    throw new Error(`Cannot use sparkly button for non-pro plans`)
  }

  const targetPlan = plan ?? usePlan(Constants.PRO_V3_MONTHLY_PLAN_UNIQUE_ID)
  const currentUser = useCurrentUser()
  const isAdmin = currentUser.role === "admin"
  const currentAccount = useMaybeCurrentAccount()
  const isManual = currentAccount?.manual_subscription
  const disabledVariant = isManual
    ? ManualSubscription
    : isAdmin
      ? null
      : NonAdmin

  if (!targetPlan) return null

  return disabledVariant === null ? (
    <Sparkles>
      {withPlanDrawerTrigger ? (
        <PlanDrawerTrigger
          data-qa={`sparkly-pro-button-${targetPlan.unique_id}`}
          colorScheme="purple"
          bgColor="purple.700"
          variant="solid"
          fontSize="sm"
          fontWeight="medium"
          initialState={{
            screen: "payment-details",
            selectedPlan: targetPlan,
          }}
          source={source}
          {...props}
        >
          {buttonText}
        </PlanDrawerTrigger>
      ) : (
        <Button
          data-qa={`sparkly-pro-button-${targetPlan.unique_id}`}
          width="full"
          colorScheme="purple"
          bgColor="purple.700"
          variant="solid"
          fontSize="sm"
          fontWeight="medium"
          {...props}
        >
          {buttonText}
        </Button>
      )}
    </Sparkles>
  ) : (
    <DisabledPlanButton variant={disabledVariant} buttonText={buttonText} />
  )
}
