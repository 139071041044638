import { Link, LinkProps } from "@chakra-ui/react"
import React from "react"

export const IntercomTriggerLink: React.FC<LinkProps> = ({
  children = "Talk to us",
  ...props
}) => (
  <Link as="button" onClick={() => Intercom("showNewMessage", null)} {...props}>
    {children}
  </Link>
)
