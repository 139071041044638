import { Box } from "@chakra-ui/react"
import { nanoid } from "nanoid"
import React, { useMemo } from "react"
import { Droppable } from "react-beautiful-dnd"

interface DroppableQuestionRowsProps {
  path: string
  droppableType: "question" | "option" | "card" | "category"
  group?: string // Restrict dropping to a specific group
}

export const DroppableQuestionRows: React.FC<
  React.PropsWithChildren<DroppableQuestionRowsProps>
> = ({ children, path, droppableType, group }) => {
  // `type` defines what types of Draggable can be dropped where.
  const droppableTypeWithGroup = group
    ? `${droppableType}:${group}`
    : droppableType
  const droppableId = useMemo(() => `${path}:${nanoid()}`, [])
  return (
    <Droppable
      droppableId={droppableId}
      key={droppableId}
      type={droppableTypeWithGroup}
    >
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          bg="gray.300"
          borderRadius="sm"
          mx={-8} // Make the drop target box go all the way to the section edge
        >
          {children}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  )
}
