import {
  Button,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import React from "react"

type DeleteAccountModal = React.FC<
  React.PropsWithChildren<
    {
      onDeleteAccount: () => void
    } & Pick<React.ComponentProps<typeof Modal>, "isOpen" | "onClose">
  >
>

export const DeleteAccountModal: DeleteAccountModal = ({
  isOpen,
  onClose,
  onDeleteAccount,
}) => {
  const accountUserCount = useTeamMembers().length
  const hasActiveStripeSubscription =
    !!useCurrentAccount().has_active_stripe_subscription

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent borderRadius="md">
          <ModalHeader>Do you want to delete your account?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Here{"\u2019"}s what will happen:
            <List my={2} styleType="disc">
              {accountUserCount > 1 && (
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    All {accountUserCount} users
                  </Text>{" "}
                  on this account will be deleted
                </ListItem>
              )}
              {hasActiveStripeSubscription && (
                <ListItem>Your subscription will be canceled</ListItem>
              )}
              <ListItem>All tests will be deleted</ListItem>
              <ListItem>All test responses will be deleted</ListItem>
            </List>
            <Text fontWeight="bold">This action cannot be undone.</Text>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onDeleteAccount} colorScheme="red">
              Delete my account
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
