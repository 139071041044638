import { Button, Heading, Stack, Text } from "@chakra-ui/react"
import React from "react"
import { Link } from "react-router-dom"

interface HasActiveOrdersErrorProps {
  testUniqueId: string
}

export const HasActiveOrdersError: React.FC<
  React.PropsWithChildren<HasActiveOrdersErrorProps>
> = ({ testUniqueId }) => {
  return (
    <Stack spacing={5} my={24} mx="auto" maxW="xl" textAlign="center">
      <Heading as="h3" size="md" fontWeight="semibold">
        Sorry, you can{"\u2019"}t edit a test that has active orders
      </Heading>
      <Text>
        If you need to make a change to this test, please cancel your active
        orders first. You{"\u2019"}ll be automatically refunded credits for the
        remainder of your order.
      </Text>
      <Button
        as={Link}
        colorScheme="brand.primary"
        to={`/tests/${testUniqueId}/recruit`}
      >
        Manage this test{"\u2019"}s orders
      </Button>
    </Stack>
  )
}
