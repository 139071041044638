import { useBoolean } from "@chakra-ui/react"
import { motion } from "framer-motion"
import React, { PropsWithChildren } from "react"

import { ScrollShadowsContainer } from "Components/scroll-shadow/scroll-shadows"

const MotionScrollShadowContainer = motion(ScrollShadowsContainer)

type ExpandableContainerProps = PropsWithChildren<{
  collapsedHeight: number
  canExpand?: boolean
}>

export function ExpandableContainer({
  canExpand = true,
  collapsedHeight,
  children,
  ...props
}: ExpandableContainerProps) {
  const [isExpanded, { toggle: toggleIsExpanded }] = useBoolean(false)

  return (
    <MotionScrollShadowContainer
      {...props}
      disableShadow={isExpanded}
      initial={false}
      animate={{
        height: isExpanded ? "auto" : `${collapsedHeight}px`,
        transitionEnd: {
          overflowY: isExpanded ? "hidden" : "scroll",
        },
      }}
      transition={{
        type: "spring",
        stiffness: 400,
        damping: 30,
      }}
      style={{
        cursor: canExpand ? (isExpanded ? "zoom-out" : "zoom-in") : "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        width: "100%",
      }}
      onClick={canExpand ? toggleIsExpanded : undefined}
    >
      {children}
    </MotionScrollShadowContainer>
  )
}
