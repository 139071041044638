// JsFromRoutes CacheKey 1c721cb8497bc5cc1fb665cd49e60b9e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  shareResults: /* #__PURE__ */ definePathHelper('get', '/tests/:id/results/:private_id'),
  exportResults: /* #__PURE__ */ definePathHelper('post', '/tests/:id/results/:private_id/export_results'),
  upgrade: /* #__PURE__ */ definePathHelper('post', '/tests/:id/upgrade'),
  unarchive: /* #__PURE__ */ definePathHelper('put', '/tests/:id/unarchive'),
}
