import {
  Button,
  Checkbox,
  Heading,
  SlideFade,
  Stack,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { isAxiosErrorWithMessage } from "Services/axios"
import { AdminNote } from "Types"

type AdminNotesActions = React.FC<{
  addNote: (note: Partial<AdminNote>) => Promise<void>
}>

export const AdminNotesActions: AdminNotesActions = ({ addNote }) => {
  const toast = useToast()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [newNoteText, setNewNoteText] = useState("")
  const [isPinned, setIsPinned] = useState(false)

  const saveNote = async () => {
    cancelEditing()
    try {
      await addNote({
        text: newNoteText,
        pinned_at: isPinned ? new Date().toISOString() : null,
      })
    } catch (e) {
      if (isAxiosErrorWithMessage(e)) {
        toast({
          description: e.response.data.message,
          status: "error",
          title: `Your note couldn${"\u2019"}t be saved`,
        })
      } else {
        throw e
      }
    }
  }

  const cancelEditing = () => {
    onClose()
    setNewNoteText("")
    setIsPinned(false)
  }

  return (
    <>
      <Stack
        isInline
        justifyContent="space-between"
        alignItems="center"
        mb={3.5}
      >
        <Heading>Notes</Heading>
        <Stack isInline>
          {!isOpen && (
            <Button
              colorScheme="brand.primary"
              variant="ghost"
              size="sm"
              onClick={onOpen}
            >
              Add a note
            </Button>
          )}
          {isOpen && (
            <>
              <Button
                colorScheme="brand.secondary"
                size="sm"
                onClick={saveNote}
                isDisabled={newNoteText.length === 0}
              >
                Save
              </Button>
              <Button colorScheme="gray" size="sm" onClick={cancelEditing}>
                Cancel
              </Button>
            </>
          )}
        </Stack>
      </Stack>
      {isOpen && (
        <SlideFade in={isOpen} offsetY={6}>
          <Textarea
            p={3}
            onChange={(e) => setNewNoteText(e.target.value)}
            value={newNoteText}
            fontSize={14}
            autoFocus
          />
          <Checkbox
            variant="mdWithSmFont"
            isChecked={isPinned}
            onChange={(e) => setIsPinned(e.target.checked)}
            mt={2}
          >
            Pin to top of list
          </Checkbox>
        </SlideFade>
      )}
    </>
  )
}
