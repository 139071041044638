import React from "react"

import { ClickMarker } from "Components/click-map/click-marker"
import { ScreenshotOverlayBackdrop } from "Components/screenshot-overlay-backdrop/screenshot-overlay-backdrop"
import { Point } from "Types"

interface ClickPreviewProps {
  point: Point
}

export const ClickPreview: React.FC<ClickPreviewProps> = ({ point }) => (
  <div>
    <ScreenshotOverlayBackdrop draggable={false} />
    <ClickMarker point={point} />
  </div>
)
