import React from "react"

import styles from "Components/time/styles.module.scss"
import { durationStringS, formatPreciseDuration, msToS } from "Utilities/time"

interface Props {
  ms: number | null
}

/**
 * Renders a precise duration suitable for recording a time in a test
 * response or similar. Shows precise time in tooltip on hover.
 */
export function PreciseDuration({ ms }: Props) {
  if (ms === null || Number.isNaN(ms)) {
    return <>n/a</>
  }
  return (
    <time
      className={styles.hasTooltip}
      dateTime={durationStringS(msToS(ms))}
      title={`${ms.toFixed(0)}ms`}
    >
      {formatPreciseDuration(ms)}
    </time>
  )
}
