import { Flex } from "@chakra-ui/react"
import React from "react"

import { OptionToggleButton as OtherOptionToggleButton } from "Components/option-toggle/OptionToggle"

import { useUsabilityTestUserActivityContext } from "../../UsabilityTestUserActivityContext"

interface OptionToggleButtonProps {
  value: string
  targetValue: string
  onChange: (newValue: string) => void
  title: string
  description: string
}

export const OptionToggleButton: React.FC<OptionToggleButtonProps> = (
  props
) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return <OtherOptionToggleButton readOnly={readOnly} {...props} />
}

export const OptionToggle: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Flex gap={4} mb={6}>
      {children}
    </Flex>
  )
}
