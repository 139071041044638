import { ComponentWithAs, IconProps } from "@chakra-ui/react"

import { CardSortIcon } from "Shared/icons/CardSortIcon"
import { DesignSurveyIcon } from "Shared/icons/DesignSurveyIcon"
import { FirstClickTestIcon } from "Shared/icons/FirstClickTestIcon"
import { FiveSecondTestIcon } from "Shared/icons/FiveSecondTestIcon"
import { InstructionIcon } from "Shared/icons/InstructionIcon"
import { NavigationTestIcon } from "Shared/icons/NavigationTestIcon"
import { PreferenceTestIcon } from "Shared/icons/PreferenceTestIcon"
import { PrototypeTestIcon } from "Shared/icons/PrototypeTestIcon"
import { QuestionsIcon } from "Shared/icons/QuestionsIcon"
import { TreeTestIcon } from "Shared/icons/TreeTestIcon"
import { Laptop01OutlineIcon } from "Shared/icons/untitled-ui/Laptop01OutlineIcon"

import { OptionalProperties } from "Shared/types/OptionalProperties"
import {
  PermittedDeviceType,
  UsabilityTestSectionType as SectionType,
} from "Types"
import { mapValues } from "lodash"

export interface SectionTypeRules {
  defaultTitle: string
  description: string
  icon: ComponentWithAs<"svg", IconProps>
  badgeText?: string
  colorScheme: string
  minScreenshots: number
  maxScreenshots: number
  initialQuestions: number
  minQuestions: number
  qaId: string
  isRecordable: boolean
  permittedDeviceType: PermittedDeviceType
}

// If a section doesn't actually take screenshots then make the acceptable
// number highly permissive. If the test has an invalid number in the DB then
// there is no way to remove them via the UI, making it impossible to edit.
const noScreenshotRange = {
  minScreenshots: 0,
  maxScreenshots: Infinity,
}

// The order sections are displayed as options in the UI
export const ORDERED_SECTIONS_TYPES = [
  SectionType.PrototypeTask,
  SectionType.Information,
  SectionType.FirstClickTest,
  SectionType.FiveSecondTest,
  SectionType.Questions,
  SectionType.DesignQuestions,
  SectionType.PreferenceTest,
  SectionType.NavigationTest,
  SectionType.CardSort,
  // Note that ExternalStudy does not appear in the UI, so it is not included here.
  // LiveWebsiteTest / TreeTest are included conditionally based on the feature flags, see AddSectionCard.tsx
]

export const getSectionTypeRules = (
  sectionType: SectionType
): Readonly<SectionTypeRules> => ALL_RULES[sectionType]

const DEFAULTS = {
  permittedDeviceType: PermittedDeviceType.Any,
} as const

const RULES: Readonly<
  Record<
    SectionType,
    Readonly<OptionalProperties<SectionTypeRules, keyof typeof DEFAULTS>>
  >
> = {
  [SectionType.CardSort]: {
    defaultTitle: "Card sort",
    icon: CardSortIcon,
    colorScheme: "brand.secondary.300",
    description: "Ask participants to sort cards into categories",
    initialQuestions: 1,
    minQuestions: 0,
    qaId: "card-sort-section",
    isRecordable: true,
    ...noScreenshotRange,
  },
  [SectionType.FirstClickTest]: {
    defaultTitle: "First click",
    icon: FirstClickTestIcon,
    colorScheme: "purple.500",
    description:
      "Ask participants to carry out a task by clicking somewhere on the image",
    minScreenshots: 1,
    maxScreenshots: 1,
    initialQuestions: 1,
    minQuestions: 0,
    qaId: "first-click-section",
    isRecordable: true,
  },
  [SectionType.DesignQuestions]: {
    defaultTitle: "Design survey",
    icon: DesignSurveyIcon,
    colorScheme: "orange.400",
    description:
      "Ask questions one at a time while participants view an image, watch a video or listen to audio",
    minScreenshots: 1,
    maxScreenshots: 1,
    initialQuestions: 1,
    minQuestions: 1,
    qaId: "design-survey-section",
    isRecordable: false,
  },
  [SectionType.FiveSecondTest]: {
    defaultTitle: "Five second test",
    icon: FiveSecondTestIcon,
    colorScheme: "blue.500",
    description:
      "Participants are shown an image for a short time before answering",
    minScreenshots: 1,
    maxScreenshots: 1,
    initialQuestions: 1,
    minQuestions: 1,
    qaId: "five-second-test-section",
    isRecordable: false,
  },
  [SectionType.Information]: {
    defaultTitle: "Instruction",
    icon: InstructionIcon,
    colorScheme: "gray.500",
    description:
      "Set the scene or provide special instructions for participants to read",
    minScreenshots: 0,
    maxScreenshots: 1,
    minQuestions: 0,
    initialQuestions: 0,
    qaId: "instruction-section",
    isRecordable: false,
  },
  [SectionType.NavigationTest]: {
    defaultTitle: "Navigation test",
    icon: NavigationTestIcon,
    colorScheme: "brand.secondary.500",
    description:
      "Set a task for participants to carry out by clicking through a series of screens",
    minScreenshots: 1,
    maxScreenshots: 12,
    initialQuestions: 1,
    minQuestions: 0,
    qaId: "navigation-test-section",
    isRecordable: true,
  },
  [SectionType.PreferenceTest]: {
    defaultTitle: "Preference test",
    icon: PreferenceTestIcon,
    colorScheme: "red.500",
    description: "Instruct participants to choose between options",
    minScreenshots: 2,
    maxScreenshots: 6,
    initialQuestions: 1,
    minQuestions: 0,
    qaId: "preference-test-section",
    isRecordable: false,
  },
  [SectionType.Questions]: {
    defaultTitle: "Questions",
    icon: QuestionsIcon,
    colorScheme: "orange.500",
    description: "Straight up questions asked one at a time",
    minQuestions: 1,
    initialQuestions: 1,
    qaId: "questions-section",
    isRecordable: false,
    ...noScreenshotRange,
  },
  [SectionType.PrototypeTask]: {
    defaultTitle: "Prototype test",
    icon: PrototypeTestIcon,
    colorScheme: "pink.500",
    description:
      "Sync your Figma prototype and set a task for participants to complete",
    initialQuestions: 1,
    minQuestions: 0,
    qaId: "prototype-task-section",
    isRecordable: true,
    ...noScreenshotRange,
  },
  [SectionType.TreeTest]: {
    defaultTitle: "Tree test",
    icon: TreeTestIcon,
    colorScheme: "#71C73B",
    description:
      "Validate your information architecture by asking participants to locate specific items in a tree structure",
    initialQuestions: 1,
    minQuestions: 0,
    qaId: "tree-test-section",
    isRecordable: true,
    ...noScreenshotRange,
  },
  [SectionType.ExternalStudy]: {
    defaultTitle: "External study",
    icon: Laptop01OutlineIcon,
    colorScheme: "#9E78ED",
    description: "",
    initialQuestions: 0,
    minQuestions: 0,
    qaId: "external_study-section",
    isRecordable: false,
    ...noScreenshotRange,
  },
  [SectionType.LiveWebsiteTest]: {
    defaultTitle: "Live website test",
    badgeText: "NEW",
    icon: PrototypeTestIcon,
    colorScheme: "#CD5492",
    description:
      "Record participant interactions on your live site as they complete assigned tasks",
    initialQuestions: 0,
    minQuestions: 0,
    qaId: "live-website-test-section",
    isRecordable: true,
    permittedDeviceType: PermittedDeviceType.DesktopOnly,
    ...noScreenshotRange,
  },
}

const ALL_RULES = mapValues(RULES, (r) => ({ ...DEFAULTS, ...r }))

export const MAX_CARDS = 100
export const MAX_CATEGORIES = 30
