import { createSelector } from "reselect"

import { State } from "Redux/app-store"
import { ParticipantResponse, ParticipantUsabilityTest } from "Types"
import {
  calculateResponseState,
  isDeleted,
  isSubmitted,
} from "Utilities/response"
import {
  DemographicAttribute,
  RecruitmentLink,
} from "~/api/generated/usabilityhubSchemas"

export function isInProgress(state: State): boolean {
  const response = state.currentResponse
  return response !== null && !isDeleted(response) && !isSubmitted(response)
}

export function isConnected(state: State): boolean {
  return !getCurrentResponse(state)._isDisconnected
}

export function getCurrentResponse(
  state: State
): Readonly<ParticipantResponse> {
  const response = state.currentResponse
  if (response === null) {
    throw new TypeError("No response in progress")
  }
  return response
}

export const responseStateSelector = createSelector(
  (_state: State, usabilityTest: ParticipantUsabilityTest) => usabilityTest,
  (
    _state: State,
    _usabilityTest: ParticipantUsabilityTest,
    recruitmentLink: RecruitmentLink | null
  ) => recruitmentLink,
  getCurrentResponse,
  (
    _state: State,
    _usabilityTest: ParticipantUsabilityTest,
    _recruitmentLink: RecruitmentLink | null,
    demographics: DemographicAttribute[]
  ) => demographics,
  calculateResponseState
)

export const getScreenshotClickBySectionScreenshotId = (
  sectionScreenshotId: number
) =>
  createSelector(getCurrentResponse, ({ screenshot_clicks }) =>
    screenshot_clicks.find(
      (click) =>
        click.usability_test_section_screenshot_id === sectionScreenshotId
    )
  )
