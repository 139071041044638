import { HStack, Switch, Text } from "@chakra-ui/react"
import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

const WrappedCustomizableScreenToggle: React.FC<
  React.PropsWithChildren<
    WrappedFieldProps & { disabled: boolean; onEnable?: () => void }
  >
> = ({ input: { onChange, ...input }, disabled, onEnable }) => {
  return (
    <HStack>
      <Text as="label" htmlFor={input.name}>
        Customize
      </Text>
      <Switch
        id={input.name}
        display="inline-flex" // Fix for Chakra switch not being vertically aligned
        isDisabled={disabled}
        isChecked={input.value as boolean}
        onChange={(e) => {
          if (e.target.checked) {
            onEnable?.()
          }

          onChange(e)
        }}
        {...input}
      />
    </HStack>
  )
}

interface CustomizableScreenToggleFieldProps {
  screenType: "welcome" | "thankyou"
  disabled?: boolean
  onEnable?: () => void
}

export const CustomizableScreenToggle: React.FC<
  React.PropsWithChildren<CustomizableScreenToggleFieldProps>
> = ({ screenType, disabled = false, onEnable }) => (
  <Field
    name={`customize_${screenType}`}
    component={WrappedCustomizableScreenToggle}
    disabled={disabled}
    onEnable={onEnable}
  />
)
