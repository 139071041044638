import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Text,
} from "@chakra-ui/react"
import { SubscribeToProButton } from "Components/trial-widget/SubscribeToProButton"
import { ProCrownIcon } from "Shared/icons/ProCrownIcon"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import {
  useHasUpcomingSubscriptionDuringATrial,
  useInNoPlanState,
  useIsTrialing,
  useShowProTrialWidget,
} from "Utilities/account"
import { getDateString } from "Utilities/date-formats"
import React from "react"
import { Plan, Subscription } from "~/api/generated/usabilityhubSchemas"

type CalloutDuringTrial = React.FC<{
  activeStripeSubscription: Subscription | undefined
  plan: Plan | undefined
  trialName: string
}>

export const CalloutDuringTrial: CalloutDuringTrial = ({
  activeStripeSubscription,
  plan,
  trialName,
}) => {
  const isInTrial = useIsTrialing()
  const isInNoPlanState = useInNoPlanState()
  const hasUpcomingSubscription = useHasUpcomingSubscriptionDuringATrial()
  const showProTrialWidget = useShowProTrialWidget()

  if (!isInTrial && !isInNoPlanState) return null

  const trialEndDate = activeStripeSubscription?.trial_end!
  const trialEndDateText = getDateString(trialEndDate)
  const title = `Your ${trialName} has ended`
  return (
    <>
      {isInNoPlanState ? (
        <Alert data-qa="callout-during-trial" status="info">
          <AlertIcon alignSelf="flex-start" />
          <Box>
            <AlertTitle>{title}</AlertTitle>
            <AlertDescription>
              <Text mb={2}>
                To continue using Lyssna, either subscribe to a paid plan or
                select the Free plan.
              </Text>
              <PlanDrawerTrigger
                colorScheme="brand.primary"
                variant="solid"
                source="plan_settings"
              >
                View plans
              </PlanDrawerTrigger>
            </AlertDescription>
          </Box>
        </Alert>
      ) : hasUpcomingSubscription ? (
        <Alert status="info">
          <AlertIcon alignSelf="flex-start" />
          <Box>
            <AlertTitle>Thanks for subscribing!</AlertTitle>

            <AlertDescription>
              You{"\u2019"}re now subscribed to the {plan!.name} plan, but you
              still get the rest of your trial time for free. You{"\u2019"}ll be
              billed for your new subscription when your trial ends on{" "}
              {trialEndDateText}. You can still choose a different plan or
              cancel your subscription if you change your mind.
            </AlertDescription>
          </Box>
        </Alert>
      ) : (
        <Alert
          data-qa="callout-during-trial"
          colorScheme={showProTrialWidget ? "purple" : "brand.primary"}
        >
          <AlertIcon as={showProTrialWidget ? ProCrownIcon : undefined} w="5" />
          <Box>
            <AlertTitle>Your trial ends {trialEndDateText}.</AlertTitle>

            <AlertDescription>
              <Text mb={2}>
                To keep using Lyssna {plan!.name} features, subscribe during or
                after your trial. You won{"\u2019"}t be charged when the trial
                ends unless you subscribe to a paid plan. If you subscribe
                during the trial, you{"\u2019"}ll still get the remaining trial
                time free.
              </Text>
              {showProTrialWidget && (
                <SubscribeToProButton
                  source="plan_setting_banner"
                  plan={plan}
                />
              )}
            </AlertDescription>
          </Box>
        </Alert>
      )}
    </>
  )
}
