import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ThumbsUpIcon = createIcon({
  displayName: "ThumbsUpIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="currentColor"
      d="M4.01561 7.1707C3.81528 7.37476 3.55074 7.50354 3.26656 7.53532C2.65964 7.60318 2.19188 8.10179 2.16286 8.7118L2.0241 11.6281C1.9904 12.3364 2.40082 12.991 3.05311 13.2692C3.18332 13.3248 3.30607 13.3964 3.4185 13.4824L3.77521 13.7554C4.42918 14.2558 5.1913 14.5961 6.00047 14.7489L6.70065 14.8811C7.56097 15.0436 8.44163 15.0684 9.30973 14.9547L11.0977 14.7204C11.2382 14.702 11.3674 14.6333 11.4612 14.5271L11.7003 14.2564C12.0606 13.8484 12.1807 13.2814 12.0166 12.7624L11.9993 12.7077C12.6585 12.2773 12.7975 11.3697 12.2974 10.7617L12.2811 10.7419C13.1106 10.3731 13.4554 9.37265 13.022 8.57498C13.5893 8.46566 13.9993 7.96916 13.9993 7.39139V7.31111C13.9993 6.59288 13.418 6.01018 12.6997 6.00853L7.83784 5.99736L8.14765 5.25478C8.27419 4.95146 8.37332 4.63742 8.44384 4.31642L8.58669 3.66627C8.65378 3.36089 8.65762 3.04501 8.59796 2.73809L8.50264 2.24763C8.46039 2.0303 8.34216 1.8351 8.16912 1.697L8.14409 1.67703C7.83341 1.42908 7.39593 1.41734 7.07239 1.64826C6.8969 1.77352 6.77287 1.95824 6.72337 2.16808L6.46164 3.27757L6.12133 4.30104C5.8816 5.02202 5.48028 5.67868 4.94802 6.22087L4.01561 7.1707Z"
    />
  ),
})
