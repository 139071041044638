import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import React from "react"

import { UsabilityTestUserActivity } from "Types"
import { FunctionalModal } from "Utilities/modals/types"

import { ActiveUserNames } from "./ActiveUserNames"

type Props = {
  handleAcceptWarning: () => void
  activities: UsabilityTestUserActivity[]
}

export const SomeoneIsEditingWarningModal: FunctionalModal<Props> = ({
  handleAcceptWarning,
  activities,
  isOpen,
  onClose,
}) => {
  // Filter out current user from the user activity list
  const userActivities = activities.filter(
    (activity) => activity.session_id !== sessionStorage.getItem("tabId")
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Be careful — someone is editing this test!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            Any changes you make could conflict with changes from{" "}
            <b>
              <ActiveUserNames activities={userActivities} />
            </b>
            . It{"\u2019"}s safest to wait until they{"\u2019"}re finished.
          </Text>
          <Text>Alternatively, you can suggest changes via comments.</Text>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button onClick={onClose} mr={2}>
              Cancel
            </Button>
            <Button colorScheme="brand.primary" onClick={handleAcceptWarning}>
              I{"\u2019"}ll proceed with caution
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
