import { Flex, Link, Text } from "@chakra-ui/react"
import React from "react"

import Constants from "Constants/shared.json"
import { Alert } from "DesignSystem/components"
import { PanelRecruitmentSummary } from "UsabilityHub/components/PanelRecruitmentSummary"
import { RecruitMethodCard } from "UsabilityHub/components/RecruitMethodSelection/RecruitMethodSelection"

export const PanelRecruitmentUnavailable: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <RecruitMethodCard
    method="panel"
    bodyContent={
      <Flex flexDirection="column" gap={6}>
        <PanelRecruitmentSummary />

        <Text textStyle="ds.paragraph.emphasized">
          Within a variation set, every response across every order for these
          tests will be from a different panelist.{" "}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={Constants.HELP_CENTER_VARIATION_SETS_UNIQUE_URL}
          >
            Learn more.
          </Link>
        </Text>
        <Alert
          status="info"
          description="Orders must be placed for each test individually."
        />
      </Flex>
    }
  />
)
