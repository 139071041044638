import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  Text,
} from "@chakra-ui/react"
import { useDroppable } from "@dnd-kit/core"
import { SortableContext } from "@dnd-kit/sortable"
import React, { useEffect, useRef } from "react"

import { useTranslate } from "Shared/hooks/useTranslate"
import { CardSortCategory, CardSortOpenCategory, CardWithId } from "Types"
import { useTestBranding } from "UsabilityHub/components/UsabilityTest/context/testBranding"

import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { Trash01SolidIcon } from "Shared/icons/untitled-ui/Trash01SolidIcon"
import { useCardSortContext } from "./CardSortContext"
import { DraggableCard } from "./DraggableCard"

export const EMPTY_CATEGORY_DROP_ZONE_PREFIX = "empty-"

type Props = (
  | {
      category: CardSortCategory
      isOpenCategory: false
    }
  | {
      category: CardSortOpenCategory
      isOpenCategory: true
    }
) & {
  draggingCardId: number | null
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
  isDuplicated: boolean
  isComplete?: boolean
}

export const Category: React.FC<Props> = ({
  category,
  isOpenCategory,
  draggingCardId,
  collapsed,
  setCollapsed,
  isDuplicated,
  isComplete,
}) => {
  const { button_color } = useTestBranding()
  const { setNodeRef, isOver } = useDroppable({
    id: `${EMPTY_CATEGORY_DROP_ZONE_PREFIX}${category.id!}`,
  })
  const {
    active,
    cards,
    renameCategory,
    deleteCategory,
    sortData,
    isRenamingOpenCategoryId,
    setIsRenamingOpenCategoryId,
  } = useCardSortContext()
  const translate = useTranslate()

  // Delay focusing the input slightly since the drag and drop interaction
  // is still in control of the focus during the drop.
  const categoryNameRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!isOver && isRenamingOpenCategoryId === category.id) {
      setTimeout(() => categoryNameRef.current?.focus(), 500)
    }
  }, [isOver, isRenamingOpenCategoryId, category.id])
  const isActuallyOpen = !collapsed || !active

  const categoryId = category.id ?? "unsorted"
  const cardsInCategory = (sortData[categoryId] || [])
    .map((cardId) => cards.find((c) => cardId === c.id))
    .filter((c): c is CardWithId => !!(c && c.id))

  const untitled = category.label === ""

  const isDraggingOver = cardsInCategory.length
    ? cardsInCategory.find((c) => c.id === draggingCardId)
    : isOver

  return (
    <Flex
      ref={cardsInCategory.length === 0 ? setNodeRef : undefined}
      position="relative"
      w="100%"
      key={categoryId}
      direction="column"
      h="fit-content"
      borderWidth={2}
      borderColor={
        untitled || isDraggingOver
          ? button_color
          : isDuplicated
            ? "red"
            : "gray.500"
      }
      borderStyle={untitled ? "dashed" : "solid"}
      bg="gray.50"
      rounded="lg"
    >
      {isDraggingOver && (
        <Box position="absolute" inset="0" bg={`${button_color}20`} />
      )}

      <Flex
        position={["sticky", "inherit"]}
        top={["-10px", "0px"]}
        justify="space-between"
        bg={isDraggingOver ? `${button_color}20` : "gray.50"}
        rounded="lg"
        zIndex={5}
        overflow="hidden"
      >
        {isDraggingOver && (
          <>
            <Box position="absolute" inset="0" bg="gray.50" zIndex={-2} />
            <Box
              position="absolute"
              inset="0"
              bg={`${button_color}20`}
              zIndex={-1}
            />
          </>
        )}
        {isRenamingOpenCategoryId === category.id ? (
          <Input
            ref={categoryNameRef}
            placeholder={translate("test.card_sort.category_name_placeholder")}
            defaultValue={category.label}
            size="xs"
            h="27px"
            rounded="none"
            variant="unstyled"
            fontSize="md"
            fontWeight="bold"
            my={1}
            mx={2}
            px={1}
            borderWidth="0 0 1px 0"
            borderColor={button_color}
            _focus={{
              boxShadow: "none",
            }}
            onChange={(e) => renameCategory(category.id!, e.target.value)}
            onBlur={() => {
              setIsRenamingOpenCategoryId(null)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault()
                setIsRenamingOpenCategoryId(null)
              }
            }}
          />
        ) : (
          <Heading
            fontSize="md"
            fontWeight="bold"
            lineHeight="24px"
            alignSelf="flex-start"
            onClick={
              isOpenCategory && !isComplete
                ? () => setIsRenamingOpenCategoryId(category.id)
                : undefined
            }
            color={untitled ? button_color : isDuplicated ? "red" : undefined}
            fontStyle={untitled ? "italic" : undefined}
            mt={1}
            mb="5px" // Because of 1px border on the input
            mx={2}
            flexGrow={1}
            cursor="pointer"
          >
            {category.label || translate("test.card_sort.untitled_category")}
          </Heading>
        )}

        <IconButton
          variant="ghost"
          size="sm"
          icon={
            <Icon
              transform={isActuallyOpen ? "rotate(0.5turn)" : "rotate(0)"}
              transition="0.2 transform"
              as={ChevronDownOutlineIcon}
            />
          }
          aria-label={translate("test.card_sort.toggle_category")}
          disabled={!active}
          onClick={() => setCollapsed(!collapsed)}
        />
      </Flex>

      {!isOpenCategory && category.description && (
        <Text fontSize="xs" color="gray.500" px={2}>
          {category.description}
        </Text>
      )}
      <SortableContext
        id={categoryId}
        items={cardsInCategory}
        strategy={() => null}
      >
        <Box pt={3} flexGrow={1} zIndex={4}>
          <Box
            minH="83px"
            maxH={isActuallyOpen ? undefined : "83px"}
            overflow="hidden"
          >
            <Flex
              direction="column"
              align="center"
              minH="100%"
              overflow="hidden"
            >
              {cardsInCategory.length > 0 ? (
                cardsInCategory.map((card) => {
                  return (
                    <DraggableCard
                      key={card.id}
                      card={card}
                      parentCategoryId={category.id!}
                      beingDragged={draggingCardId === card.id}
                    />
                  )
                })
              ) : (
                <Flex
                  justify="center"
                  align="center"
                  my="auto"
                  color="gray.500"
                  h="83px"
                  px={2}
                >
                  {isDraggingOver ? null : (
                    <Text fontSize={["sm", "md"]} textAlign="center">
                      {translate("test.card_sort.empty_category")}
                    </Text>
                  )}
                </Flex>
              )}
            </Flex>
          </Box>
        </Box>
      </SortableContext>

      <Flex justify="space-between" align="center" px={2} py={2}>
        <Text fontSize="xs" color="gray.500">
          {translate("test.card_sort.n_cards", {
            count: cardsInCategory.length.toString(),
          })}
        </Text>

        {isOpenCategory && (
          <IconButton
            variant="ghost"
            size="xs"
            icon={<Icon as={Trash01SolidIcon} />}
            aria-label={translate("test.card_sort.delete_category")}
            disabled={!active}
            onClick={() => {
              deleteCategory(category.id)
            }}
          />
        )}
      </Flex>
    </Flex>
  )
}
