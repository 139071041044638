import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query"
import {
  ListStudiesError,
  ListStudiesQueryParams,
  ListStudiesResponse,
  fetchListStudies,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

export const useInfiniteStudyList = (
  queryParams: Omit<ListStudiesQueryParams, "cursor">,
  options?: Omit<
    UseInfiniteQueryOptions<ListStudiesResponse, ListStudiesError>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useUsabilityhubContext()

  return useInfiniteQuery<ListStudiesResponse, ListStudiesError>(
    queryKeyFn({
      path: "/api/studies",
      operationId: "listStudies",
      variables: {
        queryParams: {
          ...fetcherOptions.queryParams,
          ...queryParams,
        },
      },
    }),
    (context) =>
      fetchListStudies({
        ...fetcherOptions,
        queryParams: {
          ...fetcherOptions.queryParams,
          ...queryParams,
          ...(context.pageParam
            ? {
                cursor: context.pageParam as string,
              }
            : {}),
        },
      }),
    {
      ...queryOptions,
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.next_page ?? undefined,
      retry: false,
    }
  )
}
