import { intersectionWith } from "lodash"
import React, { useEffect, useState } from "react"

import { Button, Flex, Text } from "@chakra-ui/react"
import { ExpandChevron } from "Components/expand-chevron/expand-chevron"
import {
  CheckboxList,
  Props as CheckboxListProps,
} from "Components/form/checkbox-list/checkbox-list"
import { ReactOption } from "Types"

export interface Props<T> extends CheckboxListProps<T> {
  /**
   * The number of unselected options to display when collapsed. If more than
   * this many are selected, they will all be shown. If there are fewer options
   * in total then this will be the number shown.
   */
  targetCollapsedCount: number
}

type Options<T> = ReadonlyArray<ReactOption<T>>
type MutableOptions<T> = Array<ReactOption<T>>

/**
 * Stably filter the list of provided options, including all selected and any
 * unselected options to a minimum of `targetCount` and a maximum of
 * `options.length`.
 */
function filterCollapsed<T>(
  options: Options<T>,
  value: ReadonlyArray<T>,
  targetCount: number
): Options<T> {
  let remainingCount = Math.max(targetCount, value.length)
  let remainingUnselected = remainingCount - value.length
  const result: MutableOptions<T> = []
  for (let i = 0; i < options.length && remainingCount > 0; i++) {
    const option = options[i]
    if (value.includes(option.value)) {
      result.push(option)
      remainingCount--
    } else if (remainingUnselected > 0) {
      result.push(option)
      remainingUnselected--
      remainingCount--
    }
  }
  return result
}

/**
 * Select the options with the same values as last time from the new option list.
 * This is so we can update the labels for any who changed.
 *
 * NOTE: This actually isn't stable, if the `options` array changes order they
 *       checkboxes will change order. I am just assuming this doesn't happen,
 *       but if it's a problem it will require rolling our own version of
 *       `intersectionWith` that is stable against the second arg instead of the
 *       first.
 */
function filterSameOptions<T>(
  options: Options<T>,
  prevVisible: Options<T>
): Options<T> {
  return intersectionWith(options, prevVisible, (a, b) => a.value === b.value)
}

export const ExpandableCheckboxList = <T,>({
  options,
  value,
  targetCollapsedCount,
  ...rest
}: Props<T>) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [visibleOptions, setVisibleOptions] = useState(() =>
    filterCollapsed(options, value, targetCollapsedCount)
  )

  const handleToggle = () => {
    setIsExpanded((prev) => {
      const isExpanded = !prev
      setVisibleOptions(
        isExpanded
          ? options
          : filterCollapsed(options, value, targetCollapsedCount)
      )
      return isExpanded
    })
  }

  useEffect(() => {
    setVisibleOptions(
      isExpanded ? options : filterSameOptions(options, visibleOptions)
    )
  }, [options, value])

  // No need to show the toggle if there are fewer options than the target, or if all options are selected.
  const isToggleHidden =
    options.length <= targetCollapsedCount || options.length === value.length

  return (
    <div>
      <CheckboxList options={visibleOptions} value={value} {...rest} />
      {!isToggleHidden && (
        <Button variant="link" display="block" onClick={handleToggle}>
          <Flex gap={2}>
            <ExpandChevron isExpanded={isExpanded} />
            <Text>
              {isExpanded
                ? "Show less"
                : `Show ${options.length - visibleOptions.length} more`}
            </Text>
          </Flex>
        </Button>
      )}
    </div>
  )
}
