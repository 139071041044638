import { Box, Center, Flex, Stack, Text } from "@chakra-ui/react"
import { SortableContext } from "@dnd-kit/sortable"
import React from "react"

import { useTranslate } from "Shared/hooks/useTranslate"
import { CardWithId } from "Types"

import { useCardSortContext } from "./CardSortContext"
import { DraggableCard } from "./DraggableCard"
interface Props {
  draggingCardId: number | null
}

export const UncategorizedCardList: React.FC<Props> = ({ draggingCardId }) => {
  const { cards, sortData, numUnnamedOpenCategories } = useCardSortContext()

  const translate = useTranslate()

  const unsortedCards = sortData["unsorted"]
    .map((cardId) => cards.find((c) => c.id === cardId))
    .filter((c): c is CardWithId => !!(c && c.id))

  return (
    <Box gap={2} h="100%" overflow="hidden scroll" p={2}>
      <SortableContext
        id="unsorted"
        items={unsortedCards}
        strategy={() => null}
      >
        {unsortedCards.length > 0 ? (
          <Flex
            wrap="wrap"
            justifyContent="center"
            w="100%"
            columnGap={2}
            pb={4}
          >
            <Box w="140px" display={["block", "none"]}>
              <Text fontSize="xs" fontWeight="bold">
                {translate("test.card_sort.cards")}
              </Text>
            </Box>
            {unsortedCards.map((card) => {
              if (!card || !card.id) return null

              return (
                <DraggableCard
                  key={card.id}
                  card={card}
                  parentCategoryId="unsorted"
                  beingDragged={card.id === draggingCardId}
                />
              )
            })}
          </Flex>
        ) : (
          <Center w="100%" h="100%">
            {numUnnamedOpenCategories === 0 ? (
              <Stack w="100%">
                <Text fontWeight="bold" align="center">
                  {translate("test.card_sort.finished_sort.heading")}
                </Text>
                <Text fontSize="xs" align="center">
                  {translate("test.card_sort.finished_sort.message")}
                </Text>
              </Stack>
            ) : (
              <Stack>
                <Text fontWeight="bold" align="center">
                  {translate("test.card_sort.finished_sort.heading_almost")}
                </Text>
                <Text fontSize="xs" align="center">
                  {translate(
                    "test.card_sort.finished_sort.n_untitled_categories",
                    { count: numUnnamedOpenCategories.toString() }
                  )}{" "}
                  {translate("test.card_sort.finished_sort.message_almost")}
                </Text>
              </Stack>
            )}
          </Center>
        )}
      </SortableContext>
    </Box>
  )
}
