import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react"
import { assertNever } from "Components/filter-controls/utils"
import { IconButton } from "DesignSystem/components"
import { PanelOrderCompositeIcon } from "Shared/components/CompositeIcons/CompositeIcons"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { XCircleSolidIcon } from "Shared/icons/untitled-ui/XCircleSolidIcon"
import { Beacon } from "UsabilityHub/components/Beacon"
import Created from "UsabilityHub/components/Created"
import { CanceledOrCompleteLabel } from "UsabilityHub/components/recruitment-summary-card/CanceledOrCompleteLabel"
import { RecruitmentSummaryCard } from "UsabilityHub/components/recruitment-summary-card/RecruitmentSummaryCard"
import {
  RecruitmentSummaryCardManageButton,
  RecruitmentSummaryCardManageButtonProps,
} from "UsabilityHub/components/recruitment-summary-card/RecruitmentSummaryCardManageButton"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModal } from "Utilities/modals/use-modal"
import { pluralizeWithCount } from "Utilities/string"
import React from "react"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"
import { CancelOrderModal } from "./CancelOrderModal"
import { UsabilityTestOrderSummaryCardExpandedSection } from "./UsabilityTestOrderSummaryCardExpandedSection"
import { PanelOrThirdPartyTestOrder, isPanelTestOrder } from "./orderTypes"

interface OrderSummaryProps {
  title: string
  order: PanelOrThirdPartyTestOrder
  recordingTypes: RecordingType[]
}

export const UsabilityTestOrderSummaryCard: React.FC<
  React.PropsWithChildren<OrderSummaryProps>
> = ({ title, order, recordingTypes }) => {
  const currentUser = useCurrentUser()
  const { open: showCancelModal } = useModal(CancelOrderModal)

  const showCancelAction =
    currentUser.can_spend_credits &&
    isPanelTestOrder(order) &&
    (order.state === "active" || order.state === "tentatively_complete")

  const manageButtonStates: Record<
    string,
    RecruitmentSummaryCardManageButtonProps
  > = {
    enabled: {
      buttonLabel: "Recruiting",
      buttonIcon: <Beacon colorScheme="green" />,
      ...{
        menuItems: showCancelAction
          ? [
              {
                text: "Cancel order",
                icon: (
                  <XCircleSolidIcon boxSize="1.125rem" color="ds.icon.danger" />
                ),
                onClick: () => showCancelModal({ order }),
              },
            ]
          : [],
      },
    },
  }

  const description =
    order.state === "canceled" && isPanelTestOrder(order)
      ? `Refunded ${order.total_refunded_credit_count} credits`
      : undefined

  return (
    <Box bg="ds.surface.raised.resting" rounded="16px" shadow="ds.raised">
      <Accordion allowToggle>
        <AccordionItem border="none">
          <RecruitmentSummaryCard
            isAccordionButton
            icon={<PanelOrderCompositeIcon size={12} />}
            title={title}
            description={description}
            manage={
              isCanceledOrComplete(order.state) ? (
                <CanceledOrCompleteLabel state={order.state} />
              ) : (
                <RecruitmentSummaryCardManageButton
                  {...manageButtonStates["enabled"]}
                />
              )
            }
            metrics={`${order.completed_response_count} of ${pluralizeWithCount(order.requested_response_count, "response", "responses")}`}
            profile={
              <Created
                createdAt={order.created_at}
                name={order.creator ? order.creator.name : ""}
                // TODO: in future serialize avatars into order.creator and pass it here
                avatarUrl=""
              />
            }
            menu={
              <AccordionButton
                as={IconButton}
                boxSize={8}
                variant="secondary"
                data-qa="order-toggle-button"
              >
                <AccordionIcon
                  as={ChevronDownOutlineIcon}
                  boxSize={4}
                  color="ds.icon.subtle"
                />
              </AccordionButton>
            }
          />

          <AccordionPanel p={6}>
            <UsabilityTestOrderSummaryCardExpandedSection
              order={order}
              recordingTypes={recordingTypes}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}

/**
 * This is to make sure we handle all states, to avoid bugs like when we handled
 * "canceled" but not "auto_canceled", and to be intentional about which states
 * we consider "complete" from a recruitment summary UI perspective.
 */
const isCanceledOrComplete = (
  orderState: PanelOrThirdPartyTestOrder["state"]
  // Type guard here is because the type isn't automatically narrowed otherwise
): orderState is "complete" | "canceled" | "auto_canceled" => {
  switch (orderState) {
    case "canceled":
    case "auto_canceled":
    case "complete":
      return true
    case "active":
    case "complete_pending_review":
    case "tentatively_complete":
    case "replacements_required":
    case "awaiting_payment":
    case "awaiting_placement":
      return false
    default:
      assertNever(orderState)
      return false
  }
}
