import {
  Button,
  Flex,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Text,
  Tooltip,
  useBoolean,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import teamsIcon from "Images/teams-logo.png"
import zoomIcon from "Images/zoom-logo.png"
import { AlertTriangleSolidIcon } from "Shared/icons/untitled-ui/AlertTriangleSolidIcon"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { Copy06OutlineIcon } from "Shared/icons/untitled-ui/Copy06OutlineIcon"
import { Link03OutlineIcon } from "Shared/icons/untitled-ui/Link03OutlineIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useIntegrations } from "UsabilityHub/hooks/useIntegrations"
import React, { useEffect, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { GetModeratedStudyBookingsResponse } from "~/api/generated/usabilityhub-components"
import { useModeratedStudyContext } from "../ModeratedStudyContext"
import InPlaceEditor from "./InPlaceEditor"
import { BookingForm, LocationType } from "./formSchema"

type Props = {
  booking: GetModeratedStudyBookingsResponse[number]
  bookingForm: UseFormReturn<BookingForm>
  handleSave: () => Promise<void>
  handleZoomOAuth: () => void
  handleMicrosoftOAuth: () => void
  isReadOnly: boolean
}
export const LocationEditor: React.FC<Props> = ({
  booking,
  bookingForm,
  handleSave,
  handleZoomOAuth,
  handleMicrosoftOAuth,
  isReadOnly,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const [selectedLocationType, setSelectedLocationType] =
    useState<LocationType>(bookingForm.watch("location_type"))
  const [postOAuthConnectingTo, setPostOAuthConnectingTo] = useState<
    "zoom" | "microsoft" | null
  >(null)

  const {
    moderatedStudyId,
    moderatedStudy: { members },
  } = useModeratedStudyContext()

  const mainHost = members.find((member) => member.role === "main_host")
  const currentUser = useCurrentUser()
  const { data: integrationData } = useIntegrations()

  const isCurrentUserMainHost = currentUser
    ? currentUser.id === mainHost?.id
    : false

  const isMainHostZoomIntegrated = mainHost?.has_zoom_oauth_credentials
  const isMainHostMicrosoftIntegrated =
    mainHost?.has_microsoft_oauth_credentials
  const [
    isConnectIntegrationModalVisible,
    { on: showConnectIntegrationModal, off: hideConnectIntegrationModal },
  ] = useBoolean(false)

  const initiateZoomOAuth = () => {
    setPostOAuthConnectingTo("zoom")
    handleZoomOAuth()
  }

  const initiateMicrosoftOAuth = () => {
    setPostOAuthConnectingTo("microsoft")
    handleMicrosoftOAuth()
  }

  const integrationLocationsData = {
    zoom: {
      name: "Zoom",
      handleConnect: initiateZoomOAuth,
    },
    teams: {
      name: "Microsoft Teams",
      handleConnect: initiateMicrosoftOAuth,
    },
  } as const

  // We don't have direct access to the onSuccess callback when connecting zoom from this page
  // since it's triggered via a browser event. Instead, use a flag to indicate that we should
  // swap to zoom once the connection process has finished.
  useEffect(() => {
    if (!postOAuthConnectingTo) return
    if (!isCurrentUserMainHost) return
    if (!isMainHostZoomIntegrated && !isMainHostMicrosoftIntegrated) return

    if (postOAuthConnectingTo === "zoom" && isMainHostZoomIntegrated)
      setLocationType("zoom")
    if (postOAuthConnectingTo === "microsoft" && isMainHostMicrosoftIntegrated)
      setLocationType("teams")
    setPostOAuthConnectingTo(null)
  }, [
    postOAuthConnectingTo,
    isCurrentUserMainHost,
    isMainHostZoomIntegrated,
    isMainHostMicrosoftIntegrated,
  ])

  const {
    watch,
    register,
    resetField,
    trigger,
    setValue,
    formState: { errors, isDirty, isSubmitting },
  } = bookingForm

  const location = watch("location")
  const locationType = watch("location_type")

  const setLocationType = (locationType: LocationType) => {
    setSelectedLocationType(locationType)
    setValue("location_type", locationType)
    if (locationType === "zoom") {
      // Enable auto-uploading recordings by default for Zoom
      setValue("auto_upload_recordings", true)
    } else if (locationType === "teams") {
      // set teams location
    } else {
      // If it's not an integration, they'll need to set the location explicitly, so unset the location
      setValue("location", "")
    }
    handleSave()
    resetField("location")
    queryClient.invalidateQueries([
      "api",
      "moderated_studies",
      moderatedStudyId,
      "bookings",
    ])
  }

  const changeLocationTypeToZoom = () => {
    if (isMainHostZoomIntegrated) {
      // Main host is zoom integrated, set location type freely
      setLocationType("zoom")
    } else if (isCurrentUserMainHost) {
      // Current user is main host but not yet connected to zoom, show a connect modal
      // If they confirm the modal, we'll trigger the oauth flow and then update the location type
      setSelectedLocationType("zoom")
      showConnectIntegrationModal()
    }
  }

  const changeLocationTypeToMicrosoftTeams = () => {
    if (isMainHostMicrosoftIntegrated) {
      // Main host is Microsoft integrated, set location type freely
      setLocationType("teams")
    } else if (isCurrentUserMainHost) {
      // Current user is main host but not yet connected to Microsoft, show a connect modal
      // If they confirm the modal, we'll trigger the oauth flow and then update the location type
      setSelectedLocationType("teams")
      showConnectIntegrationModal()
    }
  }

  const copyLocationToClipboard = async () => {
    await navigator.clipboard.writeText(booking.location)

    toast({
      title: "Link copied to clipboard",
      status: "success",
    })
  }

  const zoomOptionEnabled =
    isMainHostZoomIntegrated || isCurrentUserMainHost || locationType === "zoom"

  // If you don't have Microsoft connected at all, we show the option as enabled
  // since we don't know whether you have Teams access or not yet.
  const doesCurrentUserHaveTeamsAccess =
    integrationData?.microsoft === null ||
    integrationData?.microsoft.teams_enabled
  const microsoftOptionEnabled =
    (doesCurrentUserHaveTeamsAccess &&
      (isMainHostMicrosoftIntegrated || isCurrentUserMainHost)) ||
    locationType === "teams"
  const microsoftOptionDisabledBecause = !doesCurrentUserHaveTeamsAccess
    ? "Your Microsoft account does not have access to Microsoft Teams."
    : !isCurrentUserMainHost
      ? "Only the main host can add a Microsoft Teams meeting link"
      : ""

  // Show error if an automatic meeting failed to be created.
  const noMeetingLink =
    locationType === "none" ||
    (locationType !== "custom" && booking.location === "")

  return (
    <Flex direction="column">
      <Flex gap={4} w="full" align="center">
        <Menu>
          {noMeetingLink ? (
            <MenuButton
              as={Button}
              variant="outline"
              flexShrink={0}
              leftIcon={<AlertTriangleSolidIcon color="red.500" />}
              rightIcon={<ChevronDownOutlineIcon />}
              color="red.500"
              isDisabled={isReadOnly}
            >
              Add a meeting link
            </MenuButton>
          ) : (
            <MenuButton
              as={Button}
              variant="outline"
              flexShrink={0}
              leftIcon={
                locationType === "zoom" ? (
                  <Image src={zoomIcon} boxSize={4} />
                ) : locationType === "teams" ? (
                  <Image src={teamsIcon} boxSize={4} />
                ) : (
                  <Link03OutlineIcon boxSize={4} color="gray.400" />
                )
              }
              alignSelf="flex-start" // When the validation error shows it looks weird if this is centered
              rightIcon={<ChevronDownOutlineIcon />}
              isDisabled={isReadOnly}
            >
              {locationType === "zoom" ? (
                <Flex align="center">Zoom</Flex>
              ) : locationType === "teams" ? (
                <Flex align="center">Microsoft Teams</Flex>
              ) : (
                "Custom"
              )}
            </MenuButton>
          )}
          <Portal>
            <MenuList overflow="hidden">
              <Tooltip
                hasArrow
                rounded="md"
                placement="top"
                label="Only the main host can add a Zoom meeting link"
                isDisabled={zoomOptionEnabled}
              >
                <MenuItem
                  onClick={changeLocationTypeToZoom}
                  icon={<Image src={zoomIcon} boxSize={6} />}
                  isDisabled={!zoomOptionEnabled}
                >
                  <Text color="text.primary">Zoom</Text>
                </MenuItem>
              </Tooltip>

              <Tooltip
                hasArrow
                rounded="md"
                placement="top"
                label={microsoftOptionDisabledBecause}
                isDisabled={microsoftOptionEnabled}
              >
                <MenuItem
                  onClick={changeLocationTypeToMicrosoftTeams}
                  icon={<Image src={teamsIcon} boxSize={6} />}
                  isDisabled={!microsoftOptionEnabled}
                >
                  <Text color="text.primary">Microsoft Teams</Text>
                </MenuItem>
              </Tooltip>

              <MenuItem
                onClick={() => setLocationType("custom")}
                icon={<Link03OutlineIcon boxSize={6} color="gray.400" />}
              >
                <Text color="text.primary">Custom link</Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>

        {locationType === "custom" ? (
          <InPlaceEditor
            defaultEditing={!location}
            fieldName="location"
            label="Location"
            errors={errors}
            warning={location ? undefined : "No meeting link"}
            handleSave={handleSave}
            isDirty={isDirty}
            resetField={resetField}
            isSubmitting={isSubmitting}
            watch={watch}
            trigger={trigger}
            register={register}
            isReadOnly={isReadOnly}
          />
        ) : (
          booking.location && (
            <Flex gap={2} align="center">
              <Link
                isExternal
                colorScheme="text.primary"
                variant="noUnderline"
                href={booking.location}
              >
                {booking.location}
              </Link>
              <Tooltip hasArrow placement="top" label="Copy to clipboard">
                <IconButton
                  variant="ghost"
                  size="xs"
                  aria-label="Copy location to clipboard"
                  icon={<Copy06OutlineIcon />}
                  onClick={copyLocationToClipboard}
                />
              </Tooltip>
            </Flex>
          )
        )}
      </Flex>

      {!noMeetingLink && (
        <Text fontSize="sm" color="text.secondary" mt={2}>
          This meeting link is not revealed to the participants until 10 minutes
          prior to the session.
        </Text>
      )}

      {isConnectIntegrationModalVisible &&
        selectedLocationType !== "none" &&
        selectedLocationType !== "custom" && (
          <ConnectYourAccountModal
            integrationName={
              integrationLocationsData[selectedLocationType].name
            }
            handleConnect={
              integrationLocationsData[selectedLocationType].handleConnect
            }
            handleClose={hideConnectIntegrationModal}
          />
        )}
    </Flex>
  )
}

type ConnectYourAccountModalProps = {
  integrationName: "Zoom" | "Microsoft Teams"
  handleClose: () => void
  handleConnect: () => void
}

const ConnectYourAccountModal: React.FC<ConnectYourAccountModalProps> = ({
  integrationName,
  handleClose,
  handleConnect,
}) => {
  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Connect your {integrationName} account</ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={2}>
              <Text>
                Connect your {integrationName} account to automatically add
                meeting links to booked sessions.
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.primary"
              onClick={() => {
                handleConnect()
                handleClose()
              }}
            >
              Connect {integrationName}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
