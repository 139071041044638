import { CardGroup } from "Shared/components/Card/Card"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { Helmet } from "react-helmet"
import { OrganizationAlert } from "./OrganizationAlert"
import { OrganizationForm } from "./OrganizationForm"

export const OrganizationRoute: React.FC = () => {
  const currentAccount = useCurrentAccount()

  return (
    <CardGroup>
      <Helmet>
        <title>Organization settings</title>
      </Helmet>

      {!currentAccount.has_white_labeled_test_feature && <OrganizationAlert />}

      <OrganizationForm />
    </CardGroup>
  )
}
