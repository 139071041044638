import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { FunctionalModal } from "Utilities/modals/types"
import { capitalizeFirstLetter } from "Utilities/string"
import React from "react"
import { useForm } from "react-hook-form"

type DeleteModalProps = {
  onSubmit: () => void
  onClose: () => void
}

type RenameModalProps = {
  onSubmit: (newFilename: string) => void
  onClose: () => void
  prevFilename?: string
}

const DeleteModalContent = ({ onSubmit, onClose }: DeleteModalProps) => (
  <>
    <ModalBody>
      <Text>
        Are you sure you want to delete this video? It will not be recoverable.
      </Text>
    </ModalBody>

    <ModalFooter>
      <Button
        colorScheme="red"
        mr={3}
        onClick={() => {
          onSubmit()
          onClose()
        }}
      >
        Delete
      </Button>
      <Button variant="ghost" onClick={onClose}>
        Cancel
      </Button>
    </ModalFooter>
  </>
)

const RenameModalContent = ({
  onSubmit,
  onClose,
  prevFilename,
}: RenameModalProps) => {
  const { register, handleSubmit } = useForm()

  return (
    <ModalBody>
      <form
        onSubmit={handleSubmit((values) => {
          onSubmit(values["filename"])
          onClose()
        })}
      >
        <Input
          type="text"
          placeholder="Filename"
          defaultValue={prevFilename}
          {...register("filename", { required: true })}
        />
        <HStack justifyContent="flex-end" w="100%" mt={2}>
          <Button colorScheme="brand.primary" type="submit">
            Submit
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </HStack>
      </form>
    </ModalBody>
  )
}

type Props = {
  onSubmit: (newFilename?: string) => void
  title: "rename" | "delete"
  prevFilename?: string
}

export const RecordingEditorModal: FunctionalModal<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  prevFilename,
}) => {
  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{capitalizeFirstLetter(title)}</ModalHeader>
        <ModalCloseButton />
        {title === "rename" ? (
          <RenameModalContent
            onSubmit={onSubmit}
            onClose={onClose}
            prevFilename={prevFilename}
          />
        ) : (
          <DeleteModalContent onSubmit={onSubmit} onClose={onClose} />
        )}
      </ModalContent>
    </Modal>
  )
}
