import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const QuestionsIcon = createIcon({
  displayName: "QuestionsIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 8A8 8 0 110 8a8 8 0 0116 0zM5.738 4.921A3.055 3.055 0 017.985 4c.737 0 1.366.215 1.812.613.447.4.703.976.703 1.678v.022c0 .707-.245 1.229-.644 1.598-.378.35-.889.558-1.441.667L8.38 9l-.001.004c-.047.271-.242.515-.538.515h-.062c-.292 0-.503-.228-.54-.52v-.842c0-.347.243-.7.58-.74h.001c.476-.047.778-.165.96-.337.18-.17.26-.404.26-.725v-.022c0-.274-.1-.5-.28-.66-.181-.162-.453-.262-.806-.262-.518 0-.938.193-1.334.567l-.003.002-.002.002a.699.699 0 01-.441.173.687.687 0 01-.674-.695c0-.189.072-.39.238-.54zm1.213 6.233c0-.48.354-.846.828-.846s.828.367.828.846-.354.846-.828.846-.828-.367-.828-.846z"
    />
  ),
})
