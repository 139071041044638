import { Box } from "@chakra-ui/react"
import {
  Main,
  Sidebar,
} from "Components/fixed-sidebar-layout/fixed-sidebar-layout"
import { IndividualResponseDrawer } from "Components/individual-response/IndividualResponseDrawer"
import React from "react"
import { DemographicsContextProvider } from "../context/demographics"
import { TestResultsSidebar } from "../test-results-sidebar/test-results-sidebar"
import { TestResultsSummary } from "../test-results-summary/TestResultsSummary"
import { ExternalStudyParticipantTable } from "./ExternalStudyParticipantTable"

export const ExternalStudyResults: React.FC = () => {
  return (
    <Box position="relative">
      <Sidebar>
        <TestResultsSidebar hideParticipants />
      </Sidebar>

      <Main>
        <TestResultsSummary />

        <Box
          bg="ds.surface.raised.resting"
          rounded="16px"
          shadow="ds.raised"
          py={8}
          px={8}
        >
          <ExternalStudyParticipantTable />
        </Box>
      </Main>

      <DemographicsContextProvider>
        <IndividualResponseDrawer />
      </DemographicsContextProvider>
    </Box>
  )
}
