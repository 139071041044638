import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { CreditCardFields } from "Components/credit-card-fields/credit-card-fields"
import { useCreditCardFields } from "Components/credit-card-fields/useCreditCardFields"
import { FunctionalModal } from "Utilities/modals/types"

import { useModal } from "Utilities/modals/use-modal"
import AsyncStripeProvider from "../async-stripe-provider/async-stripe-provider"
import { CreditCardDeclinedMessage } from "../credit-card-declined-message"
import { JsForm } from "../form/form"

type CreditCardModalFormProps = {
  readonly modalHeading: string
  readonly modalButtonText: string
}

const CreditCardFormModalImpl: FunctionalModal<CreditCardModalFormProps> = ({
  modalHeading,
  modalButtonText,
  onClose,
}) => {
  const toast = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [cardName, setCardName] = useState("")

  const { handleSubmit } = useCreditCardFields({
    cardDeclinedError: <CreditCardDeclinedMessage />,
    cardName,
    onError: () => setIsSubmitting(false),
    onLoad: () => setIsSubmitting(true),
    onSuccess: () => {
      toast({
        title: "Your credit card was updated successfully",
        status: "success",
      })
      onClose()
    },
  })

  return (
    <Modal isOpen size="md" onClose={onClose}>
      {/*
        Make sure this modal stacks correctly with non-Chakra modals, and work around
        the Chakra overlay bug: https://github.com/chakra-ui/chakra-ui/issues/322
        1450 is larger than the default modal z-index (1400) but smaller than the next bucket (1500).
        TODO: We can remove this when the recruitment modal has been converted to Chakra.
      */}
      <ModalOverlay zIndex={1450}>
        <ModalContent>
          <JsForm onSubmit={handleSubmit}>
            <ModalHeader>{modalHeading}</ModalHeader>
            <ModalBody>
              <Text mb={4}>
                This card will be used to pay for credits you purchase and any
                subscription payments.
              </Text>
              <CreditCardFields name={cardName} onNameChange={setCardName} />
            </ModalBody>
            <ModalFooter>
              <Button isDisabled={isSubmitting} mr={2} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={isSubmitting}
                type="submit"
                colorScheme="brand.primary"
              >
                {modalButtonText}
              </Button>
            </ModalFooter>
          </JsForm>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

const CreditCardFormModal: FunctionalModal<CreditCardModalFormProps> = (
  props
) => (
  <AsyncStripeProvider>
    <CreditCardFormModalImpl {...props} />
  </AsyncStripeProvider>
)

export const CreditCardFormModalLinkButton: React.FC<
  React.PropsWithChildren<CreditCardModalFormProps>
> = ({ children, ...modalProps }) => {
  const { open: openCreditCardFormModal } = useModal(CreditCardFormModal)

  const handleClick = () => {
    openCreditCardFormModal(modalProps)
  }

  return (
    <Button variant="link" onClick={handleClick}>
      {children}
    </Button>
  )
}

export const CreditCardFormModalButton: React.FC<
  React.PropsWithChildren<
    CreditCardModalFormProps & { buttonProps?: ButtonProps }
  >
> = ({ children, buttonProps, ...modalProps }) => {
  const { open: openCreditCardFormModal } = useModal(CreditCardFormModal)

  const handleClick = () => {
    openCreditCardFormModal(modalProps)
  }

  return (
    <Button onClick={handleClick} {...buttonProps}>
      {children}
    </Button>
  )
}
