import { Flex, Text, Tooltip } from "@chakra-ui/react"
import React from "react"

import { capitalize } from "lodash"
import { AnswerResultIcon } from "./AnswerResultIcon"

interface ApplicantFullNameColumnCellProps {
  fullName: string | null
  preferredName: string
  outcome?: string
  isPanelist: boolean
}

export function ApplicantFullNameColumnCell({
  fullName,
  preferredName,
  outcome,
  isPanelist,
}: ApplicantFullNameColumnCellProps) {
  return (
    <Flex alignItems="center" gap={2} className="group">
      <Flex justify="space-between" grow={1} maxW="calc(100% - 2rem)">
        <Text display="block" overflow="hidden" textOverflow="ellipsis" me={2}>
          {isPanelist ? preferredName : fullName + " (" + preferredName + ")"}
        </Text>
        {outcome && (
          <Tooltip hasArrow placement="top" label={capitalize(outcome)}>
            <Flex alignItems="center" basis={4}>
              <AnswerResultIcon
                disqualified={outcome === "disqualified"}
                notRelevant={false}
              />
            </Flex>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  )
}
