import { Box, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react"
import { times } from "lodash"
import React, { PropsWithChildren, ReactNode } from "react"

import { CheckboxList } from "Components/form/checkbox-list/checkbox-list"
import { FilterButton } from "Components/test-results/section-results/filter-toggle"
import { useSectionContext } from "UsabilityHub/contexts"

import { useRankedAnswersFilter } from "../hooks/use-ranked-answers-filter"

interface Props {
  questionId: number
  answer: string
  optionCount: number
  children: ReactNode
}

export const FilterDropdown: React.FC<PropsWithChildren<Props>> = ({
  questionId,
  answer,
  optionCount,
  children,
}) => {
  const { section } = useSectionContext()
  const { ranks, changeRanks } = useRankedAnswersFilter(
    section.id,
    questionId,
    answer
  )

  const options = times(optionCount, (i) => ({
    value: i,
    label: (
      <Text align="end" flexGrow={1} ms={4}>
        Rank {i + 1}
      </Text>
    ),
  }))

  return (
    <Menu>
      <MenuButton as={FilterButton} isEnabled={ranks.length > 0} />
      <MenuList minW="auto">
        <Box maxW="200px" p="10px">
          <Text fontWeight="medium" mb={2}>
            {children}
          </Text>
          <CheckboxList
            options={options}
            value={ranks}
            onChange={changeRanks}
          />
        </Box>
      </MenuList>
    </Menu>
  )
}
