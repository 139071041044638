import {
  Alert,
  Center,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Switch,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Tag } from "DesignSystem/components"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"
import {
  useGetActiveFeatureFlags,
  useListAllFeatureFlags,
  useToggleFeatureFlag,
} from "~/api/generated/usabilityhub-components"

export const FeatureFlagModal: FunctionalModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent maxW="4xl">
        <ModalHeader>Feature flags</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FeatureFlagList />
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}

const FeatureFlagList = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { data, isLoading, isError } = useListAllFeatureFlags({})
  const {
    data: activeFeatureFlags,
    isLoading: isActiveLoading,
    isError: isActiveError,
  } = useGetActiveFeatureFlags({})

  const { mutate: toggleFlag } = useToggleFeatureFlag({
    onSuccess: (_data, variables) => {
      toast({
        title: `${variables.pathParams.featureFlagName} turned ${variables.body.enabled ? "on" : "off"}`,
        status: "success",
      })

      return queryClient.invalidateQueries(["api", "feature_flags"], {
        exact: true,
      })
    },
    onError: (_data, variables) => {
      toast({
        title: `Failed to change state for ${variables.pathParams.featureFlagName}`,
        status: "error",
      })
    },
  })

  if (isLoading || isActiveLoading) {
    return (
      <Flex direction="column" gap={2}>
        <Skeleton h={6} w="100%" />
        <Skeleton h={6} w="100%" />
        <Skeleton h={6} w="100%" />
        <Skeleton h={6} w="100%" />
        <Skeleton h={6} w="100%" />
        <Skeleton h={6} w="100%" />
        <Skeleton h={6} w="100%" />
        <Skeleton h={6} w="100%" />
      </Flex>
    )
  }

  if (isError || isActiveError) {
    return <Alert status="error">Could not load feature flag data.</Alert>
  }

  return (
    <Grid
      templateColumns="max-content max-content 1fr max-content"
      gap={2}
      justifyItems="start"
    >
      {data.feature_flags.map((featureFlag) => (
        <React.Fragment key={featureFlag.flag}>
          <Tooltip
            hasArrow
            placement="top"
            label="This flag is enabled globally on this environment so you cannot turn it off."
            isDisabled={!featureFlag.enabled_globally}
          >
            {/* This box is so the tooltip works with disabled switches */}
            <Center>
              <Switch
                isDisabled={featureFlag.enabled_globally}
                isChecked={activeFeatureFlags.feature_flags.includes(
                  featureFlag.flag
                )}
                onChange={(e) =>
                  toggleFlag({
                    pathParams: { featureFlagName: featureFlag.flag },
                    body: { enabled: e.target.checked },
                  })
                }
              />
            </Center>
          </Tooltip>

          <Tag label={featureFlag.flag} />
          <Text flexGrow={1} fontSize="xs">
            {featureFlag.description}
          </Text>
          <Text
            fontSize="xs"
            color="ds.text.subtle"
            ms="auto"
            sx={{
              fontVariantNumeric: "tabular-nums",
            }}
          >
            {featureFlag.owner} ({featureFlag.added_at})
          </Text>
        </React.Fragment>
      ))}
    </Grid>
  )
}
