import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"

type Props = {
  resetDuration: () => void
  numOrders: number
}

export const DurationLockedModal: FunctionalModal<Props> = ({
  resetDuration,
  numOrders,
  isOpen,
  onClose,
}) => {
  const handleClose = () => {
    resetDuration()
    onClose()
  }
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Change duration?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight="semibold" mb={2}>
              You have {numOrders} active panel{" "}
              {numOrders === 1 ? "order" : "orders"}.
            </Text>
            <Text>
              Changing the duration of your study will not affect these orders.
              If you want to change the duration, you must cancel the active
              orders and place a new one.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Flex justify="flex-end" gap={2}>
              <Button variant="outline" onClick={handleClose}>
                Discard change
              </Button>

              <Button colorScheme="brand.primary" onClick={onClose}>
                Change duration
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
