import {
  ListTargetableLocationsPathParams,
  useListTargetableLocations,
} from "~/api/generated/usabilityhub-components"

type GranularCountry = {
  type: "country"
  countryCode: string
  friendlyName: string
  qualifiedName: string
  stateCount: number
}

type GranularState = {
  type: "state"
  stateId: number
  friendlyName: string
  qualifiedName: string
  countryCode: string
  cityCount: number
}

type GranularCity = {
  type: "city"
  cityId: number
  friendlyName: string
  qualifiedName: string
  stateId: number
  countryCode: string
}

type FlatLocations = {
  country: Record<string, GranularCountry>
  state: Record<string, GranularState>
  city: Record<string, GranularCity>
}

// The locations endpoint comes back in a nested structure, Country -> State -> City, so this will give you
// an easier way to access individual elements directly by ID (or code).
// Sorry if you thought this hook was going to only return countries with minimal elevation change.
export const useFlatLocations = (
  scope: ListTargetableLocationsPathParams["scope"]
): FlatLocations => {
  const { data, isLoading, isError } = useListTargetableLocations(
    {
      pathParams: { scope },
    },
    { cacheTime: Infinity, staleTime: Infinity }
  )

  if (isLoading || isError) {
    return {
      country: {},
      state: {},
      city: {},
    }
  }

  const allCountries = data.locations
  const allStates = allCountries.flatMap((country) =>
    country.states.map((state) => ({ ...state, country }))
  )
  const allCities = allStates.flatMap((state) =>
    state.cities.map((city) => ({ ...city, state }))
  )

  return {
    country: Object.fromEntries(
      allCountries.map((country) => [
        country.code,
        {
          type: "country",
          countryCode: country.code,
          friendlyName: country.name,
          qualifiedName: country.name,
          stateCount: country.states.length,
        },
      ])
    ),
    state: Object.fromEntries(
      allStates.map((state) => [
        state.id,
        {
          type: "state",
          countryCode: state.country.code,
          stateId: state.id,
          friendlyName: state.long_name,
          qualifiedName: `${state.long_name}, ${state.country.code}`,
          cityCount: state.cities.length,
        },
      ])
    ),
    city: Object.fromEntries(
      allCities.map((city) => [
        city.id,
        {
          type: "city",
          countryCode: city.state.country.code,
          stateId: city.state.id,
          cityId: city.id,
          friendlyName: city.long_name,
          qualifiedName: `${city.long_name}, ${city.state.short_name}, ${city.state.country.code}`,
        },
      ])
    ),
  }
}
