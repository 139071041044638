import { Flex, Spinner } from "@chakra-ui/react"
import React, { PropsWithChildren, createContext } from "react"

import { useListDemographics } from "~/api/generated/usabilityhub-components"
import { DemographicAttributeGroup } from "~/api/generated/usabilityhubSchemas"

interface DemographicsContext {
  demographics: DemographicAttributeGroup[]
  isLoading: boolean
}

export const DemographicsContext = createContext<DemographicsContext>({
  demographics: [],
  isLoading: true,
})

export function DemographicsContextProvider({
  children,
}: PropsWithChildren<unknown>): JSX.Element {
  const { data: demographics, isLoading, isError } = useListDemographics({})

  if (isLoading)
    return (
      <Flex mt="32" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    )

  if (isError) {
    return (
      <Flex mt="32" justifyContent="center" alignItems="center">
        There was an error loading the demographics. Try refreshing the page.
      </Flex>
    )
  }

  return (
    <DemographicsContext.Provider
      value={{
        demographics: demographics ?? [],
        isLoading: demographics === undefined,
      }}
    >
      {children}
    </DemographicsContext.Provider>
  )
}
