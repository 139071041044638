import React from "react"

import { DeviceFrame, Dimensions, Omit, Screenshot } from "Types"

import { screenshotOnScreenDimensions } from "../../utilities/screenshot"

import {
  Props as ContainerProps,
  MaintainAspectRatioContainer,
} from "./maintain-aspect-ratio-container"

interface Props extends Omit<ContainerProps, "dimensions"> {
  constrainWidthToDeviceFrame: DeviceFrame | null
  maxPlayableSize?: Dimensions
  screenshot: Screenshot
  disabled?: boolean
}

export const MaintainScreenshotAspectRatioContainer = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const {
    constrainWidthToDeviceFrame,
    screenshot,
    maxPlayableSize,
    disabled,
    ...containerProps
  } = props
  const { children } = containerProps
  if (disabled) {
    return <>{children}</>
  }

  const dimensions = screenshotOnScreenDimensions(
    screenshot,
    constrainWidthToDeviceFrame,
    maxPlayableSize
  )
  return (
    <MaintainAspectRatioContainer
      ref={ref}
      dimensions={dimensions}
      {...containerProps}
    />
  )
})

MaintainScreenshotAspectRatioContainer.displayName =
  "MaintainScreenshotAspectRatioContainer"
