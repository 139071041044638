import { TagEditor } from "Components/tag-editor/tag-editor"
import { Tag } from "Components/tags/tags"
import { Dispatch } from "Redux/app-store"
import {
  createQuestionTag,
  tagAnswers,
  untagAnswers,
} from "Redux/reducers/test-results/action-creators"
import {
  getAnswerTags,
  isTaggingSelector,
} from "Redux/reducers/test-results/selectors"
import {
  ResponseAnswer as Answer,
  UsabilityTestSectionQuestion as Question,
  QuestionTag,
} from "Types"
import { reduceIndeterminateBoolean } from "Utilities/indeterminate-boolean"
import React, { ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"

function identify(tag: Readonly<QuestionTag>): string {
  return tag.id.toString()
}

function getName(tag: Readonly<QuestionTag>): string {
  return tag.name
}

function getLabel(tag: Readonly<QuestionTag>): ReactNode {
  return (
    <Tag color={tag.color} tagIndex={tag._index}>
      {getName(tag)}
    </Tag>
  )
}

interface ImplProps {
  question: Readonly<Question>
  answers: ReadonlyArray<Readonly<Answer>>
  handleEditTag: (questionTag: QuestionTag) => void
  handleDeleteTag: (questionTag: QuestionTag) => void
}

export const AnswerTagEditor: React.FC<React.PropsWithChildren<ImplProps>> = ({
  answers,
  question,
  handleEditTag,
  handleDeleteTag,
}) => {
  const isBusy = useSelector(isTaggingSelector)
  const answerTags = useSelector(getAnswerTags)
  const dispatch = useDispatch<Dispatch>()

  const handleCreateTag = (label: string) => {
    void dispatch(
      createQuestionTag(
        {
          name: label,
          usability_test_section_question_id: question.id,
          _index: 1, // TODO: Fix types
        },
        answers.map((a) => a.id)
      )
    )
  }

  const handleCheckboxChange = (
    questionTag: Readonly<QuestionTag>,
    isChecked: boolean
  ) => {
    const answerIds = answers.map((a) => a.id)
    const action = isChecked ? tagAnswers : untagAnswers
    void dispatch(action(questionTag.id, answerIds))
  }
  const questionTags = question.question_tags
  const tagInfos = questionTags.map((tag, index) => ({
    tag: { ...tag, _index: index },
    checkboxState: reduceIndeterminateBoolean(answers, (answer) =>
      answerTags.find(
        (answerTag) =>
          answerTag.response_answer_id === answer.id &&
          answerTag.question_tag_id === tag.id
      )
    ),
  }))

  return (
    <TagEditor
      disableCheckboxes={isBusy || answers.length === 0}
      disableInput={isBusy}
      getLabel={getLabel}
      getName={getName}
      identify={identify}
      onCheckboxChange={handleCheckboxChange}
      onCreateTag={handleCreateTag}
      onEditTag={handleEditTag}
      onRemoveTag={handleDeleteTag}
      tagInfos={tagInfos}
    />
  )
}
