import { Icon, Link, Text } from "@chakra-ui/react"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { Banner } from "Components/banner/banner"
import { VariationSetIcon } from "Shared/icons/VariationSetIcon"
import { ROUTES } from "UsabilityHub/views/routes"
import { pluralize } from "Utilities/string"

interface Props {
  variationSetName: string
  variationSetUniqueId: string
  variationSetTestCount: number
}

export const UsabilityTestInVariationSetBanner: React.FC<Props> = ({
  variationSetName,
  variationSetUniqueId,
  variationSetTestCount,
}) => {
  if (variationSetTestCount === 0) {
    // This should never be true, but just in case it happens it's nicer to
    // show nothing instead of an empty blue box
    return null
  }

  return (
    <Banner type="info">
      <Icon as={VariationSetIcon} />
      <Text>
        {variationSetTestCount === 1 && (
          <>
            This test is the first variation in the set “{variationSetName}”.{" "}
            <Link
              as={RouterLink}
              to={ROUTES.TEST_SET.buildPath({
                testSetId: variationSetUniqueId,
              })}
            >
              Create more variations here
            </Link>
            .
          </>
        )}
        {variationSetTestCount > 1 && (
          <>
            This test is one of the variations in the set “{variationSetName}”.{" "}
            <Link
              as={RouterLink}
              to={`/sets/${variationSetUniqueId}`}
              colorScheme="blue"
            >
              View all {pluralize(variationSetTestCount, "variation")}
            </Link>
            .
          </>
        )}
      </Text>
    </Banner>
  )
}
