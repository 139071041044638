import { entityIdOrNull } from "Components/comment-thread/entities"
import { State } from "Redux/app-store"
import { getFormValues } from "Redux/reducers/test-builder-form/selectors/formValues"
import {
  UsabilityTestSectionQuestion as Question,
  UsabilityTestSectionScreenshot as SectionScreenshot,
  UsabilityTest,
  UsabilityTestSection,
} from "Types"
import { reportErrorToSentry } from "Utilities/error"
import { cloneDeep } from "lodash"
import { createSelector } from "reselect"
import { ClientCommentFragment } from "../reducer"

type ClientCommentPayload = {
  _clientId: string
  comment_id: string
  content: string
}

interface UsabilityTestPostPayload extends UsabilityTest {
  test_set_id: number | null
  // Any comments on un-persisted items will be sent through with the rest of the payload
  comments: ClientCommentPayload[]
}

// Use to remove pending comments that have been orphaned by deleting their section, question, or thread
// ts-prune-ignore-next used in test
export const filterOrphanedComments = (
  test: Readonly<UsabilityTest>,
  comments: ClientCommentFragment[]
) => {
  // Filter threads that have been orphaned by deleting their section or question
  const firstPass = comments.filter((comment) => {
    const entityId = entityIdOrNull(comment.entity)
    if (entityId) {
      switch (comment.entity.entityType) {
        case "usability_test_section":
          return test.sections.some(({ _clientId }) => _clientId === entityId)
        case "usability_test_section_question":
          return test.sections.some(({ questions }) =>
            questions.some(({ _clientId }) => _clientId === entityId)
          )
        // Ignore case "comment". Don't know if a reply has been orphaned until we filter the threads
      }
    }
    return true
  })
  // Filter replies that have been orphaned by deleting their thread
  return firstPass.filter((comment) => {
    const entityId = entityIdOrNull(comment.entity)
    if (entityId && comment.entity.entityType === "comment") {
      return firstPass.some(({ comment_id }) => comment_id === entityId)
    }
    return true
  })
}

export const createPostPayload = createSelector(
  getFormValues,
  (state: State) => state.testBuilder,
  (nextTest, testBuilderState): UsabilityTestPostPayload => {
    if (testBuilderState == null) {
      throw new TypeError(`Test builder state is null`)
    }
    if (nextTest == null) {
      throw new TypeError(`Form values are null`)
    }

    const {
      comments,
      questionDeletions,
      sectionDeletions,
      sectionScreenshotDeletions,
    } = testBuilderState

    // Duplicate the form values so that we can modify them.
    const payload: UsabilityTestPostPayload = {
      ...cloneDeep(nextTest),
      comments: filterOrphanedComments(nextTest, comments).map((c) => ({
        comment_id: c.comment_id,
        content: c.content,
        // On the server we only need the clientId to identify what we're commenting on
        // Entities without an id just store their name in the ID field, e.g. _clientId="welcome"
        _clientId: entityIdOrNull(c.entity) ?? c.entity.entityType,
      })),
      test_set_id: testBuilderState.testSetId,
    }

    // Assign `position` values based on form sort order.
    payload.sections.forEach((section, i) => {
      section.position = i
      section.questions.forEach((question, j) => {
        question.position = j
      })
      section.section_screenshots.forEach((sectionScreenshot, j) => {
        sectionScreenshot.position = j
      })
    })

    // Now restore deletions into the payload structure, so that they can be
    // processed by the server...

    // For any screenshots with the _destroy flag set on the front end, we set
    // the archived flag so that they're soft-deleted on the server
    payload.sections.forEach((section) => {
      section.section_screenshots = section.section_screenshots.map(
        (screenshot) =>
          screenshot._destroy ? { ...screenshot, archived: true } : screenshot
      )
    })

    // Sections first so that children of deleted sections can be restored.
    sectionDeletions.forEach(({ sectionId }) => {
      payload.sections.push({
        archived: true,
        id: sectionId,
        questions: [] as Question[],
        section_screenshots: [] as SectionScreenshot[],
      } as UsabilityTestSection)
    })

    questionDeletions.forEach(({ sectionId, questionId }) => {
      const section = payload.sections.find(({ id }) => id === sectionId)
      if (section === undefined) {
        reportErrorToSentry(
          new TypeError(`Could not find section with ID ${sectionId}`)
        )
        return
      }
      section.questions.push({ archived: true, id: questionId } as Question)
    })

    sectionScreenshotDeletions.forEach(({ sectionId, sectionScreenshotId }) => {
      const section = payload.sections.find(({ id }) => id === sectionId)
      if (section === undefined) {
        reportErrorToSentry(
          new TypeError(`Could not find section with ID ${sectionId}`)
        )
        return
      }
      section.section_screenshots.push({
        archived: true,
        id: sectionScreenshotId,
      } as SectionScreenshot)
    })

    return payload
  }
)

export const getUsabilityTestLastSavedAt = (state: State): Date | undefined => {
  return state.testBuilder?.initialValues?._lastSavedAt
}
