type Options = {
  format: "image/jpeg" | "image/png"
  quality: number
}

const defaultOptions: Options = {
  format: "image/jpeg",
  quality: 0.8,
}

export function downloadCanvas(
  canvas: HTMLCanvasElement,
  filename: string,
  partialOptions: Partial<Options> = defaultOptions
) {
  const { format, quality } = { ...defaultOptions, ...partialOptions }

  const link = document.createElement("a")
  link.download = filename
  link.href = canvas.toDataURL(format, quality)
  link.click()
}
