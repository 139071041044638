import { useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import {
  createDemographicAttributeOptionIdFilter,
  createDemographicAttributeOptionIdFilterCategory,
  createUnknownDemographicAttributeOptionIdFilter,
} from "../filters/create-filters/demographic-attribute-option-Id-filter"
import { getFiltersWithoutCategory } from "../filters/get-filters/get-filters-without"
import { FILTER_UNKNOWN } from "../utils/unknown"

export const useUpdateDemographicAttributeOptionIds = (
  demographicAttributeId: string,
  availableDemographicAttributeOptionIds: number[]
): ((demographicAttributeOptionIds: string[]) => void) => {
  const { setTestResultsFilters } = useContext(TestResultsFilterState)

  return (demographicAttributeOptionIds: string[]) => {
    setTestResultsFilters((filters) => {
      const filtersWithoutDemographicAttribute = getFiltersWithoutCategory(
        filters,
        createDemographicAttributeOptionIdFilterCategory(demographicAttributeId)
      )

      const demographicAttributeFilters = demographicAttributeOptionIds.map(
        (id) => {
          if (id === FILTER_UNKNOWN) {
            return createUnknownDemographicAttributeOptionIdFilter(
              demographicAttributeId,
              availableDemographicAttributeOptionIds
            )
          }

          return createDemographicAttributeOptionIdFilter(
            demographicAttributeId,
            parseFloat(id)
          )
        }
      )

      return [
        ...filtersWithoutDemographicAttribute,
        ...demographicAttributeFilters,
      ]
    })
  }
}
