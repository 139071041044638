import { Grid } from "@chakra-ui/react"
import { cq } from "Shared/helpers/containerQuerySx"
import React, { PropsWithChildren } from "react"
import { BREAKPOINT_MAX, BREAKPOINT_MID } from "../breakpoints"

export const SummaryMetricsCardsGrid = ({ children }: PropsWithChildren) => (
  <Grid
    gap={4}
    gridTemplate={`"status" 1fr "participants" 1fr "responses" 1fr / 1fr`}
    sx={{
      ...cq(BREAKPOINT_MID, {
        gridTemplate: `"status status" 1fr "participants responses" 1fr / 1fr 1fr`,
      }),
      ...cq(BREAKPOINT_MAX, {
        gridTemplate: `"status participants responses" 1fr / 1fr 1fr 1fr`,
      }),
    }}
  >
    {children}
  </Grid>
)
