import { CommonPathFilter } from "../filters/common-path"

import { generateFilterId } from "./generate-filter-id"

export function createCommonPathFilterCategory(
  sectionId: number
): CommonPathFilter["category"] {
  return `answer/section:${sectionId}/common-path`
}

export function createCommonPathFilter(
  sectionId: number,
  path: string
): CommonPathFilter {
  return {
    id: generateFilterId(),
    category: createCommonPathFilterCategory(sectionId),
    type: "answer/common-path",
    value: path,
  }
}
