import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"
import { useUpdateDemographicPreset } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { DemographicPreset } from "~/api/generated/usabilityhubSchemas"

type Props = {
  preset: DemographicPreset
}

const RenameFormSchema = Yup.object().shape({
  preset_name: Yup.string()
    .required("Saved group name is required")
    .max(100, "Saved group name must be 100 characters or less"),
})

type RenameForm = Yup.InferType<typeof RenameFormSchema>

export const RenamePresetModal: FunctionalModal<Props> = ({
  preset,
  isOpen,
  onClose,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  const {
    handleSubmit,
    register,
    formState: { isValid, isSubmitting, errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(RenameFormSchema),
    defaultValues: {
      preset_name: preset.name,
    },
  })

  const { mutateAsync } = useUpdateDemographicPreset({
    onSuccess: async () => {
      const key = queryKeyFn({
        path: "/api/demographic_presets",
        operationId: "listDemographicPresets",
        variables: {},
      })

      await queryClient.invalidateQueries(key)
      onClose()

      toast({
        title: "Renamed demographic preset",
        status: "success",
      })
    },
    onError: () => {
      toast({
        title: "Failed to update demographic preset",
        status: "error",
      })
    },
  })

  const onSubmit: SubmitHandler<RenameForm> = async (values) => {
    return mutateAsync({
      pathParams: {
        id: preset.id,
      },
      body: {
        name: values.preset_name,
        demographic_attribute_option_ids:
          preset.demographic_attribute_option_ids,
        min_age: preset.min_age,
        max_age: preset.max_age,
        countries: preset.countries,
        location_state_ids: preset.location_state_ids,
        location_city_ids: preset.location_city_ids,
      },
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent maxW="xl" my="auto">
        <ModalHeader pb={6}>
          <Heading>Rename saved demographic group</Heading>

          <ModalCloseButton />
        </ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody py={4}>
            <FormControl isInvalid={!!errors.preset_name}>
              <FormLabel fontWeight={500} mb={2}>
                Saved group name
              </FormLabel>

              <Input
                autoFocus
                focusBorderColor={errors.preset_name ? "red.500" : undefined}
                placeholder="Enter saved group name"
                {...register("preset_name")}
              />

              {errors.preset_name && (
                <FormErrorMessage>
                  {errors.preset_name.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>

            <Button
              colorScheme="brand.primary"
              type="submit"
              variant="solid"
              isLoading={isSubmitting}
              isDisabled={!isValid}
            >
              Rename
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
