import { Box, Tag, TagLabel } from "@chakra-ui/react"
import React from "react"

interface ScreenThumbnailTagProps {
  isStartNode?: boolean
}

export const ScreenThumbnailTag: React.FC<
  React.PropsWithChildren<ScreenThumbnailTagProps>
> = ({ isStartNode }) => {
  // If it's the start screen, it's a straight up tag.
  if (isStartNode) {
    return (
      <Tag
        variant="solid"
        colorScheme="gray"
        size="sm"
        borderRadius="full"
        mx="auto"
      >
        <TagLabel>Start screen</TagLabel>
      </Tag>
    )
  }

  // If setting a goal screen is disabled render an element which replaces the tag in height
  return <Box height="5" />
}
