import { Checkbox } from "@chakra-ui/react"
import React from "react"
import { WrappedFieldProps } from "redux-form"

interface WrappedCheckboxFieldProps {
  label: string
  disabled?: boolean
}

export const WrappedCheckbox: React.FC<
  WrappedFieldProps & WrappedCheckboxFieldProps
> = ({ input, label, disabled = false }) => {
  return (
    <Checkbox
      id={input.name}
      isChecked={input.value as boolean}
      isDisabled={disabled}
      onChange={(event) => input.onChange(event.target.checked)}
      variant="mdWithSmFont"
    >
      {label}
    </Checkbox>
  )
}
