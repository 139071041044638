import { useFigmaFileVersion } from "./use-ffv-id"
import { useFigmaFileFlow } from "./use-figma-file-flow"

export function useFigmaImages(figmaFileVersionId?: number) {
  const ffv = useFigmaFileVersion(figmaFileVersionId)
  const fff = useFigmaFileFlow()!

  const figmaImages = ffv.images.reduce((acc, element) => {
    if (element.figma_node_id === fff.start_node_id) {
      return [element, ...acc]
    }
    return [...acc, element]
  }, [])

  return figmaImages
}
