import classNames from "classnames"
import { memoize, upperFirst } from "lodash"
import React, { Ref } from "react"

import { DeviceFrameType, DeviceFrame as IDeviceFrame } from "Types"

import styles from "./device-frame.module.scss"

export enum Size {
  ScreenshotEditorPreview = "screenshotEditorPreview",
  Square = "square",
}

interface Props {
  deviceFrame: Pick<IDeviceFrame, "device_frame_type" | "device_orientation">
  size?: Size
  contentRef?: Ref<HTMLDivElement>
  isScrollable?: boolean
}

const deviceClass = memoize(
  (type: DeviceFrameType) =>
    styles[`device${upperFirst(type)}` as keyof typeof styles]
)

const DeviceFrame: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { contentRef, children, deviceFrame, isScrollable, size } = props
  const className = classNames(
    styles.deviceFrame,
    deviceClass(deviceFrame.device_frame_type),
    styles[deviceFrame.device_orientation],
    size && styles[size],
    isScrollable === false || styles.scrollable
  )
  return (
    <div className={className}>
      <div className={styles.content}>
        <div ref={contentRef} className={styles.internal}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default DeviceFrame
