import { assertNever } from "Components/filter-controls/utils"
import Constants from "Constants/shared.json"
import { useCurrentPlan } from "UsabilityHub/hooks/useCurrentAccount"
import { centsToDollars, formatDollars } from "Utilities/currency"
import { Plan } from "~/api/generated/usabilityhubSchemas"

export function intervalAdjective(plan: Plan): string {
  switch (plan.interval) {
    case "month":
      return "monthly"
    case "year":
      return "annually"
    default:
      assertNever(plan.interval)
      return "unknown"
  }
}

export const billingPeriodForPlan = (plan: Plan): string => {
  switch (plan.interval) {
    case "month":
      return "monthly"
    case "year":
      return "annual"
    default:
      assertNever(plan.interval)
      return "unknown"
  }
}

export const isEnterprise = (plan: Plan): boolean => {
  return Constants.ENTERPRISE_ANNUAL_PLAN_UNIQUE_ID === plan.unique_id
}

export function isPaid(plan: Plan): boolean {
  return plan.monthly_price > 0
}

export function isFree(plan: Plan): boolean {
  return !isPaid(plan)
}

export const isPro = (plan: Plan) => {
  return (
    plan.unique_id === Constants.PRO_V3_MONTHLY_PLAN_UNIQUE_ID ||
    plan.unique_id === Constants.PRO_V3_ANNUAL_PLAN_UNIQUE_ID
  )
}

function getPricePerAdditionalSeatInInterval(plan: Plan): number {
  return plan.interval === "year"
    ? plan.price_per_additional_collaborator_in_cents * 12
    : plan.price_per_additional_collaborator_in_cents
}

export function planCostPerInterval(plan: Plan, paidSeatCount: number): string {
  let totalCost = plan.amount
  if (paidSeatCount > plan.included_collaborator_seats!) {
    totalCost +=
      getPricePerAdditionalSeatInInterval(plan) *
      (paidSeatCount - plan.included_collaborator_seats!)
  }
  return `${formatDollars(centsToDollars(totalCost))}`
}

export function planCostPerAdditionalSeatInInterval(plan: Plan): string {
  return `${formatDollars(
    centsToDollars(getPricePerAdditionalSeatInInterval(plan))
  )} (USD) / ${plan.interval}`
}

export const useHasTestSetsFeature = (): boolean => {
  const plan = useCurrentPlan()

  if (!plan) return false

  return plan.test_sets
}
