import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react"
import { ShareField } from "Components/share-field/share-field"
import { Button } from "DesignSystem/components"
import { Dispatch, State } from "Redux/app-store"
import { isReadonly } from "Redux/reducers/test-results/selectors"
import {
  updateUsabilityTestFailure,
  updateUsabilityTestRequest,
  updateUsabilityTestSuccess,
} from "Redux/reducers/usability-tests/action-creators"
import { getUsabilityTest } from "Redux/reducers/usability-tests/selectors"
import { ShallowUsabilityTest } from "Types"
import { useMaybeCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { appendQueryString } from "Utilities/query-string"
import React, { ChangeEvent } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  useUsabilityTestDisableShareLink,
  useUsabilityTestEnableShareLink,
} from "~/api/generated/usabilityhub-components"
import UsabilityTestsApi from "~/api/usabilityTestsApi"
import { useResultPageSearchParams } from "../hooks/use-result-page-query-params"

interface ShareTestResultsModalProps {
  isOpen: boolean
  onClose: () => void
  usabilityTestId: number
}

export const ShareTestResultsModal: React.FC<
  React.PropsWithChildren<ShareTestResultsModalProps>
> = ({ isOpen, onClose, usabilityTestId }) => {
  const dispatch: Dispatch = useDispatch()
  const toast = useToast()

  const usabilityTest = useSelector((state: State) =>
    getUsabilityTest(state, usabilityTestId)
  )
  const isLinkEnabled = usabilityTest.is_results_share_link_enabled
  const resultsPageSearchParams = useResultPageSearchParams()

  const path = UsabilityTestsApi.shareResults.path({
    id: usabilityTest.unique_id,
    private_id: usabilityTest.private_id,
  })
  const url = appendQueryString(
    `${window.location.origin}${path}`,
    resultsPageSearchParams.toString()
  )

  const onShareToggleSuccess = (enabled: boolean) => {
    // Return a curried function that contains the enabled boolean
    return () => {
      // Tell the store about the change.
      dispatch(updateUsabilityTestRequest(usabilityTestId))
      dispatch(
        updateUsabilityTestSuccess(usabilityTestId, {
          is_results_share_link_enabled: enabled,
        })
      )
    }
  }
  const onShareToggleError = () => {
    dispatch(updateUsabilityTestFailure(usabilityTestId))
    toast({
      title:
        "Sorry, we were unable to toggle share link. Please check the link and try again.",
      status: "error",
    })
  }

  const { mutate: enableShareLink, isLoading: enableShareLoading } =
    useUsabilityTestEnableShareLink({
      onSuccess: onShareToggleSuccess(true),
      onError: onShareToggleError,
    })
  const { mutate: disableShareLink, isLoading: disableShareLoading } =
    useUsabilityTestDisableShareLink({
      onSuccess: onShareToggleSuccess(false),
      onError: onShareToggleError,
    })

  const onLinkEnabledChange = (enable: boolean) => {
    const pathParams = { usabilityTestId }
    if (enable) {
      enableShareLink({ pathParams })
    } else {
      disableShareLink({ pathParams })
    }
  }

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share test results</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <Text>
              You can share results for this test with the link below.
            </Text>
            <ShareField text={url} disabled={!isLinkEnabled} />
            <ShareLinkToggle
              usabilityTest={usabilityTest}
              onToggle={onLinkEnabledChange}
              isLoading={enableShareLoading || disableShareLoading}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} size="emphasized">
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface ShareLinkToggleProps {
  usabilityTest: Readonly<ShallowUsabilityTest>
  onToggle: (isEnabled: boolean) => void
  isLoading: boolean
}
const ShareLinkToggle: React.FC<
  React.PropsWithChildren<ShareLinkToggleProps>
> = ({ usabilityTest, onToggle, isLoading }) => {
  const isTestReadonly = useSelector(isReadonly)
  const userCanManageTests = useMaybeCurrentUser()?.can_manage_tests ?? false
  const canToggleLink = !isTestReadonly && userCanManageTests
  const isLinkEnabled = usabilityTest.is_results_share_link_enabled

  if (!canToggleLink) {
    if (!isLinkEnabled) {
      return <Text>You don't have permission to enable this link.</Text>
    }
    return null
  }

  return (
    <FormControl display="flex" alignItems="center">
      <Switch
        id="toggleShareLink"
        isChecked={isLinkEnabled}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onToggle(event.target.checked)
        }
        isDisabled={isLoading}
        size="sm"
      />
      <FormLabel
        htmlFor="toggleShareLink"
        cursor="pointer"
        fontWeight="normal"
        mb={0}
        pl={2}
        data-qa="toggle-share-link-label"
      >
        {isLinkEnabled ? (
          <>Link active &mdash; Anyone with the link can view results</>
        ) : (
          <>Link disabled &mdash; Enable to share your results</>
        )}
      </FormLabel>
    </FormControl>
  )
}
