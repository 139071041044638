import { useCurrentAccount } from "./useCurrentAccount"

const INTERVIEWS_DISABLED_ACCOUNT_IDS = [
  1009224, // Morgan Stanley
]
export const useIsInterviewsEnabled = () => {
  const { id: accountId } = useCurrentAccount()

  // We are intentionally hardcoding account IDs here to disable interviews for them quickly.
  // If we find ourselves doing many more of these, we should have another talk about solving this properly.
  return !INTERVIEWS_DISABLED_ACCOUNT_IDS.includes(accountId)
}
