import {
  FigmaImage,
  FigmaTaskScreenResult,
  ResponseSectionFigmaTask,
} from "Types"

import { getScreenResultMeta } from "./get-screen-result-meta/get-screen-result-meta"

export function getScreenResultsFromTask(
  task: ResponseSectionFigmaTask,
  figmaImages: FigmaImage[]
): FigmaTaskScreenResult[] {
  return task.screens.map((screen, screenIndex, allScreens) => ({
    nodeId: screen.presented_node_id,
    taskId: task.id,
    figmaImage: figmaImages.find(
      (figmaImage) => figmaImage.figma_node_id === screen.presented_node_id
    )!,
    meta: getScreenResultMeta(screen, screenIndex, allScreens, task),
  }))
}

export function getScreenResultsFromTasks(
  tasks: ResponseSectionFigmaTask[],
  figmaImages: FigmaImage[]
): FigmaTaskScreenResult[][] {
  return tasks.map((task) => getScreenResultsFromTask(task, figmaImages))
}
