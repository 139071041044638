import React, { useMemo } from "react"

import { CommentThread } from "Components/comment-thread/CommentThread"
import {
  TestCard,
  TestCardBody,
  TestCardHeader,
} from "UsabilityHub/components/TestCard/TestCard"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"

import { EditableSectionCardHeader } from "./EditableSectionCardHeader"

export const SectionCard: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const sectionIndex = useSectionIndexContext()
  const { section } = useSectionContext()
  const commentableEntity = useMemo(
    () =>
      ({
        entityContext: "test_builder",
        entityType: "usability_test_section",
        entityId: section.id ? String(section.id) : section._clientId,
      }) as const,
    [section.id, section._clientId]
  )

  return (
    <TestCard
      id={`section_${sectionIndex}`}
      commentableEntity={commentableEntity}
    >
      <TestCardHeader data-group-hover="usability-test-section-header">
        <EditableSectionCardHeader />

        <CommentThread
          entity={commentableEntity}
          isEntityPersisted={section.id !== null}
        />
      </TestCardHeader>
      <TestCardBody>{children}</TestCardBody>
    </TestCard>
  )
}
