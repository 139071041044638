import { Button, Heading, Image, Spinner, Text } from "@chakra-ui/react"
import { IntercomTriggerLink } from "Components/IntercomTriggerLink"
import Constants from "Constants/shared.json"
import LyssnaErrorIllustration from "Images/app-illustrations/lyssna/error.png"
import LyssnaIllustration from "Images/app-illustrations/lyssna/setup.png"
import * as SubscriptionService from "Services/subscription-service"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"
import {
  useCurrentAccount,
  useRefreshCurrentAccount,
} from "UsabilityHub/hooks/useCurrentAccount"
import { usePlan } from "UsabilityHub/hooks/usePlans"
import { useIsTrialing } from "Utilities/account"
import React, { useCallback, useEffect } from "react"
import { useNavigate } from "react-router"
import { ROUTES } from "../routes"

export const FreeTrial: React.FC = () => {
  const trialPlan = usePlan(Constants.PRO_V3_MONTHLY_PLAN_UNIQUE_ID)
  const currentAccount = useCurrentAccount()
  const refreshCurrentAccount = useRefreshCurrentAccount()
  const isInTrialing = useIsTrialing()
  const navigate = useNavigate()

  const [hasError, setHasError] = React.useState(false)

  const createTrialSubscription = useCallback(async () => {
    setHasError(false)

    if (!currentAccount?.is_eligible_for_free_trial) {
      if (isInTrialing) {
        navigate(ROUTES.SETUP.ONBOARDING.buildPath({ stepId: "role" }), {
          replace: true,
        })
      } else {
        navigate(ROUTES.DASHBOARD.path, { state: { freeTrial: "used" } })
      }
      return
    }

    try {
      await SubscriptionService.createTrialSubscription({
        plan: trialPlan!, // The button is disabled until trialPlan is non-null
      })

      refreshCurrentAccount()
      navigate(ROUTES.SETUP.ONBOARDING.buildPath({ stepId: "role" }), {
        replace: true,
      })
    } catch (error) {
      setHasError(true)
    }
  }, [trialPlan, currentAccount])

  useEffect(() => {
    const timeout = setTimeout(createTrialSubscription, 100)

    return () => clearTimeout(timeout)
  }, [createTrialSubscription])

  return (
    <Page title="Free trial">
      <UsabilityHubNavbar isOnSignUpPage />
      <PageMain>
        <PageContent
          display="grid"
          placeContent="center"
          justifyItems="center"
          gap={4}
        >
          {hasError ? (
            <>
              <Image
                src={LyssnaErrorIllustration}
                alt=""
                role="presentation"
                w="150px"
              />
              <Heading
                color="chakra-subtle-text"
                fontSize="3xl"
                textAlign="center"
              >
                Something went wrong
              </Heading>
              <Text as="p" textAlign="center" maxW="lg">
                While attempting to create your account, an unexpected error
                occurred. We recommend trying again, as this may fix the issue.
              </Text>
              <Button
                variant="solid"
                colorScheme="brand.primary"
                w="xs"
                isLoading={!trialPlan}
                onClick={createTrialSubscription}
              >
                Try again
              </Button>
              <Text as="p" mt={6}>
                Need extra support? <IntercomTriggerLink />
              </Text>
            </>
          ) : (
            <>
              <Image src={LyssnaIllustration} alt="" role="presentation" />
              <Heading
                color="chakra-subtle-text"
                fontSize="3xl"
                textAlign="center"
              >
                Setting up your account
              </Heading>
              <Text as="p" textAlign="center" maxW="lg" fontSize="sm">
                You{"\u2019"}re seconds away from joining the Lyssna community,
                made up of over 320,000 people, passionate about research — just
                like you!
              </Text>
              <Spinner
                size="xl"
                color="brand.primary.500"
                emptyColor="gray.100"
                thickness="4px"
              />
            </>
          )}
        </PageContent>
      </PageMain>
    </Page>
  )
}
