import { Flex } from "@chakra-ui/react"
import { Badge } from "DesignSystem/components"
import { Checkbox } from "DesignSystem/components/Checkbox"
import Created from "UsabilityHub/components/Created"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import {
  archivedColors,
  defaultColors,
} from "UsabilityHub/views/dashboard/cardColors"
import React from "react"
import { Link } from "react-router-dom"
import { Study } from "~/api/generated/usabilityhubSchemas"

export type SelectableTestRow = {
  isSelectable: true
  isSelected: boolean
  handleSelect: (newValue: boolean) => void
}

export type UnselectableTestRow = {
  isSelectable: false
}

type Props = {
  study: Study
  link?: string
  combineAdjacent?: boolean
  studyNameSlot: React.ReactNode
  tagSlot: React.ReactNode
  statsSlot: React.ReactNode
  projectInfoSlot: React.ReactNode
  manageButtonSlot: React.ReactNode
  contextMenuSlot: React.ReactNode
} & (SelectableTestRow | UnselectableTestRow)

// TODO: remove this once variation sets is no longer using it
export const studyCardGridTemplateColumns = [
  "auto 2rem",
  "auto 2rem",
  "2rem 1fr 7rem 12.5rem 3.5rem",
  "2rem 1fr 7rem 12.5rem 3.5rem",
  "2rem 1fr 6rem 12.5rem 12rem 8rem 4rem",
]

export const StudyCard: React.FC<Props> = ({
  study,
  link,
  studyNameSlot,
  tagSlot,
  statsSlot,
  projectInfoSlot,
  manageButtonSlot,
  contextMenuSlot,
  combineAdjacent = false,
  ...props
}) => {
  const userCanManageTests = useCurrentUser().can_manage_tests

  return (
    <Flex
      minH="88px"
      zIndex={0}
      align="stretch"
      rounded={["12px", null, "16px"]}
      shadow={combineAdjacent || study.archived ? undefined : "ds.raised"}
      basis="100%"
      ps={8}
      pe={[4, null, 6]}
      py={[4, null, 6]}
      pos="relative"
      cursor="pointer"
      borderWidth={1}
      borderColor="transparent"
      data-qa="test-row"
      role="group"
      {...(study.archived ? archivedColors : defaultColors)}
      sx={{
        // When two test rows are placed next to each other they meld together visually
        // (for example, inside a test set)
        borderRadius: combineAdjacent ? 0 : undefined,
        "&:last-of-type": {
          borderBottomRadius: combineAdjacent ? "16px" : undefined,
        },
      }}
    >
      <Flex
        as={Link}
        to={link}
        tabIndex={-1}
        _before={{
          content: `""`,
          position: "absolute",
          inset: 0,
          zIndex: 0,
        }}
      />

      {props.isSelectable && userCanManageTests && (
        <Flex
          align="center"
          pos="absolute"
          top={"calc(50% - 12px)"}
          left={1}
          visibility={props.isSelected ? "visible" : "hidden"}
          _groupHover={{ visibility: "visible" }}
        >
          <Checkbox
            isChecked={props.isSelected}
            onCheckedChange={(v) => props.handleSelect(v)}
          />
        </Flex>
      )}

      <Flex
        direction={["column", null, null, "row"]}
        flexGrow={1}
        columnGap={6}
        rowGap={3}
      >
        <Flex flexGrow={1}>
          <Flex
            display="flex"
            flexGrow={1}
            alignItems="center"
            w="full"
            minWidth={0}
          >
            <Flex w="full" direction="column" wordBreak="break-word" gap={3}>
              <Flex
                w="full"
                textStyle="ds.interface.medium"
                color="ds.text.subtle"
                align="flex-start"
                _focusVisible={{
                  outline: "none",
                  boxShadow: "outline",
                }}
              >
                <Flex
                  alignItems={["flex-start", null, "center"]}
                  direction={["column", null, "row"]}
                  gap={2}
                >
                  {/* Relatively positioned to make sure these sit in a separate
                  block formatting context above the main card link (otherwise
                  they can't be clicked) */}
                  <Flex pos="relative">{tagSlot}</Flex>
                  <Flex pos="relative" gap={2}>
                    {projectInfoSlot}
                  </Flex>
                </Flex>

                <Flex flexGrow={1} />

                <Flex display={["flex", null, null, "none"]}>
                  {contextMenuSlot}
                </Flex>
              </Flex>

              {studyNameSlot}
            </Flex>
          </Flex>
        </Flex>

        <Flex direction={["column", null, "row"]} columnGap={4} rowGap={3}>
          <Flex flexDir="column" justifyContent="center" flexShrink={0}>
            {statsSlot}
          </Flex>

          <Flex
            w="full"
            justifyContent={["space-between", null, "flex-end"]}
            gap={4}
            flexGrow={1}
          >
            <Flex justifyContent="center" alignItems="center" flexShrink={0}>
              {study.archived ? (
                <Badge
                  colorScheme="default"
                  variant="subtle"
                  label="Archived"
                />
              ) : (
                manageButtonSlot
              )}
            </Flex>

            <Flex
              justifyContent="center"
              alignItems="center"
              whiteSpace="nowrap"
              zIndex={1}
            >
              <Created
                createdAt={study.created_at}
                name={study.creator?.name ?? "User"}
                email={study.creator?.email}
                avatarUrl={study.creator?.avatar_url ?? ""}
              />

              <Flex display={["none", null, null, "flex"]} ps={2}>
                {contextMenuSlot}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
