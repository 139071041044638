import * as React from "react"

const ItemIndexContext = React.createContext<number | null>(null)

interface ItemProviderProps {
  value: number
}

export const ItemIndexProvider: React.FC<
  React.PropsWithChildren<ItemProviderProps>
> = ({ value, children }) => {
  return (
    <ItemIndexContext.Provider value={value}>
      {children}
    </ItemIndexContext.Provider>
  )
}

export const useItemIndexContext = (): number => {
  const context = React.useContext(ItemIndexContext)

  if (context === null) {
    throw new Error(
      `useItemIndexContext must be rendered within the ItemIndexProvider`
    )
  }

  return context
}
