import { useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import {
  createRankedAnswerFilter,
  createRankedAnswerFilterCategory,
} from "../filters/create-filters/ranked-answer-filter"
import { getFiltersWithCategory } from "../filters/get-filters/get-filters-with"
import { removeFilterById } from "../filters/remove-filter"

export const useRankedAnswersFilter = (
  sectionId: number,
  questionId: number,
  answer: string
) => {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const filters = getFiltersWithCategory(
    testResultsFilters,
    createRankedAnswerFilterCategory(sectionId, questionId)
  )

  const answerFilter = filters.find((filter) => filter.value.answer === answer)

  const handleChangeRanks = (ranks: number[]) => {
    setTestResultsFilters((filters) => {
      if (ranks.length === 0 && answerFilter) {
        return removeFilterById(filters, answerFilter.id)
      }

      const existingFilter = filters.find(
        (filter) => filter.id === answerFilter?.id
      )

      if (existingFilter) {
        return filters.map((filter) => {
          if (filter.id === answerFilter?.id) {
            return {
              ...answerFilter,
              value: {
                answer: answerFilter.value.answer,
                ranks,
              },
            }
          }

          return filter
        })
      }

      return [
        ...filters,
        createRankedAnswerFilter(sectionId, questionId, answer, ranks),
      ]
    })
  }

  return {
    ranks: answerFilter?.value.ranks ?? [],
    changeRanks: handleChangeRanks,
  }
}
