import { PrototypeResponseStates } from "JavaScripts/types/figma-file-flow"
import {
  QuestionType,
  Screenshot,
  UsabilityTestSectionType as SectionType,
  UsabilityTestSectionOrQuestion,
} from "Types"

/**
 * Currently supported:
 * - Section: Preference test
 * - Question: Linear scale
 * - Question: Radio buttons
 *
 * @param target
 * @param screenshots
 */
export const getAnswers = (
  target: UsabilityTestSectionOrQuestion,
  screenshots: readonly Screenshot[]
): string[] => {
  let answers: string[] = []
  switch (target.type) {
    // Preference test: list of screenshots.
    case SectionType.PreferenceTest:
      {
        answers = target.section_screenshots.map((ss) => {
          const screenshot = screenshots.find((s) => s.id === ss.screenshot_id)
          return screenshot ? screenshot.name : ""
        })
      }
      break

    // Prototype task flow test: completed or not.
    case SectionType.PrototypeTask:
      {
        answers = PrototypeResponseStates
      }
      break

    // Linear scale: range from min to max.
    case QuestionType.LinearScale:
      // Loose equality here to cover null | undefined.
      if (target.min_value != null && target.max_value != null) {
        answers = Array.from(
          Array(target.max_value - target.min_value + 1),
          (_, x) => (x + target.min_value).toString()
        )
      }
      break

    // Multiple choice: all options plus an optional "Other".
    case QuestionType.CheckboxMultipleChoice:
    case QuestionType.RadioMultipleChoice:
      if (target.has_other_option) {
        answers = [...target.multiple_choice_options, "Other"]
      } else {
        answers = [...target.multiple_choice_options]
      }
      // When an option is empty, for some reason it's undefined instead of an empty string. Replace them.
      answers = answers.map((a) => (a === undefined ? "" : a))
      break
  }

  return [...new Set(answers)] // filter out duplicates
}
