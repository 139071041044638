import classNames from "classnames"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { loadScreenshot } from "Redux/reducers/screenshots/action-creators"
import { DeviceFrame, Dimensions } from "Types"
import { LoadImageError } from "Utilities/image"

import {
  UnscaledScreenshot,
  UnscaledScreenshotProps,
} from "./UnscaledScreenshot/UnscaledScreenshot"
import { MaintainScreenshotAspectRatioContainer } from "./maintain-screenshot-aspect-ratio-container"
import styles from "./screenshot.module.scss"

interface Props extends UnscaledScreenshotProps {
  constrainWidthToDeviceFrame: DeviceFrame | null
  maxPlayableSize?: Dimensions
}

export const Screenshot: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  constrainWidthToDeviceFrame,
  screenshot,
  onClick,
  maxPlayableSize,
  ...unscaledProps
}) => {
  const dispatch: Dispatch = useDispatch()
  useEffect(() => {
    if (!screenshot._isLoaded) {
      ;async () => {
        try {
          await dispatch(loadScreenshot(screenshot._clientId))
        } catch (error) {
          if (!(error instanceof LoadImageError)) {
            throw error
          }
        }
      }
    }
  }, [dispatch, screenshot._clientId, screenshot._isLoaded])
  return (
    <MaintainScreenshotAspectRatioContainer
      className={classNames(styles.container, className)}
      constrainWidthToDeviceFrame={constrainWidthToDeviceFrame}
      screenshot={screenshot}
      onClick={onClick}
      maxPlayableSize={maxPlayableSize}
    >
      <UnscaledScreenshot
        className={styles.screenshot}
        screenshot={screenshot}
        {...unscaledProps}
      />
      {children}
    </MaintainScreenshotAspectRatioContainer>
  )
}
