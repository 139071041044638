import { HStack, Select } from "@chakra-ui/react"
import React from "react"
import {
  CommonPath,
  IndividualPath,
  SortOrderType,
  useSortPaths,
} from "./usePaths"

type PathSortControlsProps<T extends CommonPath | IndividualPath> = Pick<
  ReturnType<typeof useSortPaths<T>>,
  "options" | "sortOrder" | "setSortOrder"
>

export const PathSortControls = <T extends CommonPath | IndividualPath>({
  options,
  sortOrder,
  setSortOrder,
}: PathSortControlsProps<T>) => {
  return (
    <HStack justify="end">
      <Select
        value={sortOrder}
        width="auto"
        onChange={(e) =>
          setSortOrder(e.currentTarget.value as SortOrderType<T>)
        }
      >
        {Object.entries(options).map(([key, option]) => (
          <option key={key} value={key}>
            {option.label}
          </option>
        ))}
      </Select>
    </HStack>
  )
}
