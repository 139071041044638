import { Dimensions } from "Types"

import { ScaledCanvas } from "./scaled-canvas"

export function createScaledCanvas({ width, height }: Dimensions, scale = 1) {
  const canvas = document.createElement("canvas")

  // Set the "actual" size of the canvas
  canvas.width = width * scale
  canvas.height = height * scale

  return new ScaledCanvas(canvas, scale)
}
