import React from "react"
import { Outlet } from "react-router"

import { Page } from "Shared/components/Page/Page"
import { useScrollToTop } from "Shared/hooks/useScrollToTop"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"

export const RootSettingsLayout: React.FC = () => {
  useScrollToTop()

  return (
    <Page>
      <UsabilityHubNavbar />
      <Outlet />
    </Page>
  )
}
