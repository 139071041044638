import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"

import { AssignmentInvitation } from "Components/assignment-invitation/assignment-invitation"
import { LinkButton } from "Components/button/link-button"
import { ROUTES } from "UsabilityHub/views/routes"
import { ROUTES as TesterAppRoutes } from "UserCrowd/views/routes"
import {
  centsToDollars,
  creditsToCents,
  formatDollars,
} from "Utilities/currency"
import {
  useListUsercrowdOrderAssignments,
  usePanelistStats,
} from "~/api/generated/usabilityhub-components"

interface Props {
  creditsEarned: number
}

export const PanelThankYou: React.FC<React.PropsWithChildren<Props>> = ({
  creditsEarned,
}) => {
  const moneyEarned = formatDollars(
    centsToDollars(creditsToCents(creditsEarned))
  )
  const { data: allAssignments } = useListUsercrowdOrderAssignments({})
  const { data: panelistStats } = usePanelistStats({})

  const assignment =
    allAssignments?.panelist_order_assignments.find(
      (a) => !a.response && !a.revoked && a.order.state === "active"
    ) ?? null

  return (
    <Container maxW="100ch">
      <SimpleGrid columns={[1, 1, 2]} gap={4} alignItems="center">
        <Stack spacing={3}>
          <Heading mb={2}>Thank you</Heading>

          {creditsEarned > 0 && (
            <Text>
              You{"\u2019"}ve earned {moneyEarned}!
            </Text>
          )}

          {assignment === null ? (
            <LinkButton
              href={
                // TODO: Should this be used by testers? See also, comment in EditPasswordForm
                ROUTES.DASHBOARD.path
              }
              colorScheme="brand.primary"
            >
              Return to the dashboard
            </LinkButton>
          ) : (
            <>
              <Text>There{"\u2019"}s another test for you</Text>
              <AssignmentInvitation
                assignment={assignment}
                source="end_of_test"
              />
            </>
          )}
        </Stack>

        {panelistStats && panelistStats.profile_completeness < 100 ? (
          <Box h="fit-content" rounded="md" background="gray.100" p={7}>
            <Stack spacing={4}>
              <Heading>Want to get sent more tests?</Heading>
              <Text>
                Your tester profile is only{" "}
                {Math.round(panelistStats.profile_completeness)}% complete.
                Answering more profile questions gives you more opportunities to
                be selected for tests.
              </Text>
              <LinkButton
                variant="outline"
                href={TesterAppRoutes.SETTINGS.PROFILE.path}
              >
                Complete my profile
              </LinkButton>
            </Stack>
          </Box>
        ) : null}
      </SimpleGrid>
    </Container>
  )
}
