import { UnorderedList } from "@chakra-ui/react"
import { ListItem } from "@chakra-ui/react"
import { Box } from "@chakra-ui/react"
import { CommentThread } from "Components/comment-thread/CommentThread"
import { useFilteredResponseSections } from "Components/test-results/hooks/use-filtered-response-sections"
import { TestResultsSectionErrorBound } from "Components/test-results/section-results/test-results-section-error-bound"
import { Alert } from "DesignSystem/components"
import { TEST_RECORDING_TYPES } from "JavaScripts/types/recording"
import { State } from "Redux/app-store"
import {
  getIsReview,
  getResponseSectionRecordingsForResponseSections,
} from "Redux/reducers/test-results/selectors"
import {
  TestCard,
  TestCardBody,
  TestCardHeader,
  TestCardTitle,
} from "UsabilityHub/components/TestCard/TestCard"
import { SectionResultsSectionHeading } from "UsabilityHub/components/TestResults/SectionResultsLabels"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import { pluralizeWithCount } from "Utilities/string"
import { upperFirst } from "lodash"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"

interface SectionResultsCardProps {
  taskTimeLimit?: number
}

export const SectionResultsCard: React.FC<
  React.PropsWithChildren<SectionResultsCardProps>
> = ({ children, taskTimeLimit }) => {
  const sectionIndex = useSectionIndexContext()
  const { section } = useSectionContext()

  const commentableEntity = useMemo(
    () =>
      ({
        entityContext: "test_results",
        entityType: "usability_test_section",
        entityId: String(section.id),
      }) as const,
    [section.id]
  )

  const showAdminActions = useSelector(getIsReview)
  const filteredResponseSections = useFilteredResponseSections().filter(
    (responseSection) =>
      responseSection.usability_test_section_id === section.id
  )
  const recordingsCount = useSelector((state: State) =>
    getResponseSectionRecordingsForResponseSections(
      state,
      filteredResponseSections
    )
  ).length
  const recordingTypes = TEST_RECORDING_TYPES.filter(
    (type) => section.recording_attributes && section.recording_attributes[type]
  )
  const showRecordingAlert = showAdminActions && recordingsCount > 0

  return (
    <Box
      mt={8} // Stack messes with the page break, so set a margin manually.
      sx={{
        "@media print": {
          pageBreakAfter: "always",
        },
      }}
    >
      <TestCard
        id={`section_${sectionIndex}`}
        commentableEntity={commentableEntity}
      >
        <TestCardHeader h={showRecordingAlert ? "auto" : "10"}>
          <Box w="full">
            <TestCardTitle>
              <SectionResultsSectionHeading taskTimeLimit={taskTimeLimit} />
            </TestCardTitle>
            {showRecordingAlert && (
              <Alert
                status="warning"
                description={
                  <Box>
                    This test section contains{" "}
                    {pluralizeWithCount(
                      recordingsCount,
                      "recording",
                      "recordings"
                    )}
                    :
                    <UnorderedList>
                      {recordingTypes.map((type, index) => (
                        <ListItem key={index}>
                          {type === "screen"
                            ? "Screen share"
                            : upperFirst(type)}
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                }
              />
            )}
          </Box>

          <CommentThread isEntityPersisted entity={commentableEntity} />
        </TestCardHeader>
        <TestCardBody>
          <TestResultsSectionErrorBound>
            {children}
          </TestResultsSectionErrorBound>
        </TestCardBody>
      </TestCard>
    </Box>
  )
}
