import React from "react"

import { TesterDemographicsContextProvider } from "UsabilityHub/components/UsabilityTest/context/demographics"
import {
  AppearanceProps,
  LayoutState,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"

import {
  DemographicQuestions,
  ImplProps,
} from "./demographic-questions-content"

export default function demographicQuestionsContent(
  props: ImplProps
): AppearanceProps {
  return {
    isReportButtonVisible: true,
    layoutState: LayoutState.FocusQuestion,
    mediaContent: null,
    questionContent: (
      <TesterDemographicsContextProvider>
        <DemographicQuestions {...props} />
      </TesterDemographicsContextProvider>
    ),
  }
}
