import { Button, ButtonProps, Text } from "@chakra-ui/react"
import React from "react"
import { useTemplateLibrary } from "~/usabilityhub/hooks/useTemplateLibrary"
import { Category } from "~/usabilityhub/types/templateLibrary"

type FilterButtonProps = ButtonProps & {
  category: Category | null
}

export const FilterButton: React.FC<FilterButtonProps> = ({
  category,
  ...props
}) => {
  const { filter, setFilter, counts } = useTemplateLibrary()

  const isSelected =
    filter?.id === category?.id && filter?.type === category?.type

  const count = (category ? counts[category.id] : counts["total"]) || 0

  const isDisabled = !count

  return (
    <Button
      variant="ghost"
      h="auto"
      py={2}
      px={3}
      lineHeight={1.5}
      color={isSelected ? undefined : "grey.darker"}
      colorScheme={isSelected ? "brand.primary" : undefined}
      backgroundColor={isSelected ? "grey.light" : "transparent"}
      display="flex"
      alignItems="start"
      gap={3}
      isDisabled={isDisabled}
      aria-selected={isSelected}
      fontWeight="medium"
      _hover={{ backgroundColor: "grey.light" }}
      _focus={{ backgroundColor: "grey.mid" }}
      _active={{ backgroundColor: "grey.mid" }}
      _selected={{ color: "brand.primary.500", fontWeight: "semibold" }}
      onClick={() => setFilter(category ?? null)}
      data-group
      {...props}
    >
      <Text as="span" align="left" flex={1} minW={0} whiteSpace="normal">
        {category?.name ?? "All templates"}
      </Text>
      <Text
        as="span"
        color={isSelected ? undefined : "grey.dark"}
        _groupHover={{ color: isSelected ? undefined : "grey.darker" }}
      >
        {count}
      </Text>
    </Button>
  )
}
