import { Box, Flex } from "@chakra-ui/react"
import { PanInfo, motion, useDragControls } from "framer-motion"
import React, { useContext, useEffect, useState } from "react"
import { PropsWithChildren } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import { InstructionsModalCornerPosition } from "JavaScripts/types/instructions-modal"
import { Dispatch, State } from "Redux/app-store"
import { addResponseAnswer } from "Redux/reducers/current-response/action-creators"
import { getCurrentResponse } from "Redux/reducers/current-response/selectors"
import { ResponseAnswer, UsabilityTestSection } from "Types"
import { getPrototypeType } from "Utilities/get-prototype-type"

import { UsePrototypeReturn } from "../usePrototypeTask"

import { DragLockContext } from "./DragLockContext"
import { InstructionsContent } from "./InstructionsContent"
import { InstructionsModalInner } from "./InstructionsModalInner"
import { usePrototypeTaskPhases } from "./usePrototypeTaskPhases"

export type InstructionsModalState =
  | "desktopStart"
  | "desktopDefault"
  | "desktopMinimized"
  | "mobileStart"
  | "mobileDefault"
  | "mobileMinimized"

export const draggableStates: InstructionsModalState[] = [
  "desktopDefault",
  "desktopMinimized",
  "mobileMinimized",
]
export const minimizedStates: InstructionsModalState[] = [
  "desktopMinimized",
  "mobileMinimized",
]

interface InstructionsModalProps {
  state: InstructionsModalState
  section: UsabilityTestSection
  toggleState: () => void
  openSkipModal: () => void
  isPanelist: boolean
  prototypeTask: UsePrototypeReturn
}

const DESKTOP_DRAG_PADDING = 32
const MOBILE_DRAG_PADDING = 16

type CornerPosition = {
  top?: number
  left?: number
  right?: number
  bottom?: number
}

const desktopCornerPositionVals: Map<
  InstructionsModalCornerPosition,
  CornerPosition
> = new Map([
  ["top_left", { left: DESKTOP_DRAG_PADDING, top: DESKTOP_DRAG_PADDING }],
  ["top_right", { right: DESKTOP_DRAG_PADDING, top: DESKTOP_DRAG_PADDING }],
  [
    "bottom_right",
    { right: DESKTOP_DRAG_PADDING, bottom: DESKTOP_DRAG_PADDING },
  ],
  ["bottom_left", { left: DESKTOP_DRAG_PADDING, bottom: DESKTOP_DRAG_PADDING }],
])

const mobileCornerPositionVals: Map<
  InstructionsModalCornerPosition,
  CornerPosition
> = new Map([
  ["top_left", { left: MOBILE_DRAG_PADDING, top: MOBILE_DRAG_PADDING }],
  ["top_right", { right: MOBILE_DRAG_PADDING, top: MOBILE_DRAG_PADDING }],
  ["bottom_right", { right: MOBILE_DRAG_PADDING, bottom: MOBILE_DRAG_PADDING }],
  ["bottom_left", { left: MOBILE_DRAG_PADDING, bottom: MOBILE_DRAG_PADDING }],
])

const bottomVals: Map<InstructionsModalState, number> = new Map([
  ["desktopStart", 8],
  ["mobileStart", 0],
  ["mobileDefault", 0],
])

const widthVals: Map<InstructionsModalState, string> = new Map([
  ["mobileStart", "full"],
  ["mobileDefault", "full"],
  ["desktopMinimized", "200px"],
  ["mobileMinimized", "auto"],
])

const MotionFlex = motion(Flex)
const InstructionsModalImpl: React.FC<
  PropsWithChildren<InstructionsModalProps>
> = ({
  section,
  state,
  toggleState,
  openSkipModal,
  isPanelist,
  prototypeTask,
}) => {
  const modalStartPosition =
    section.instructions_modal_start_position ?? "bottom_right"
  const enterSide = modalStartPosition.includes("left") ? "left" : "right"
  const [cornerPosition, setCornerPosition] = useState(
    (state.includes("mobile")
      ? mobileCornerPositionVals
      : desktopCornerPositionVals
    ).get(modalStartPosition)
  )
  const [isDragging, setIsDragging] = useState(false)
  const { isDraggingLocked } = useContext(DragLockContext)

  const prototypeType = getPrototypeType(section)!
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)

  const usabilityTest = useSelector(
    (state: State) => state.participantUsabilityTest
  )
  const response = useSelector(getCurrentResponse)
  const { prototypeTaskPhase, advanceToNextPhase, question } =
    usePrototypeTaskPhases(usabilityTest, response, section)

  const nextPhase = () => {
    advanceToNextPhase((taskFlowSuccessAcknowledged?: boolean) => {
      if (prototypeType === "task_flow") {
        setIsOverlayVisible(true)
      }
      prototypeTask.finishTask(
        prototypeTask.isGoalScreenVisited ? "completed" : "gave_up",
        taskFlowSuccessAcknowledged
      )
    })
  }

  const dispatch: Dispatch = useDispatch()

  const handleQuestionSubmit = (answer: Omit<ResponseAnswer, "id">) => {
    dispatch(addResponseAnswer(answer))
  }

  const shouldStopAtGoalScreen =
    prototypeType === "task_flow" &&
    prototypeTaskPhase === "interacting" &&
    prototypeTask.isGoalScreenVisited

  useEffect(() => {
    if (shouldStopAtGoalScreen) {
      setIsOverlayVisible(true)
      setTimeout(() => {
        advanceToNextPhase((taskFlowSuccessAcknowledged?: boolean) => {
          prototypeTask.finishTask("completed", taskFlowSuccessAcknowledged)
        })
        if (state === "desktopMinimized" || state === "mobileMinimized")
          toggleState()
      }, 1000)
    }
  }, [
    shouldStopAtGoalScreen,
    prototypeTask.finishTask,
    advanceToNextPhase,
    toggleState,
    state,
  ])

  const controls = useDragControls()

  const handleDragUpdate = (_: Event, info: PanInfo) => {
    const { innerHeight, innerWidth } = window
    const halfHeight = innerHeight / 2
    const halfWidth = innerWidth / 2
    const { x, y } = info.point
    const vals = state.includes("mobile")
      ? mobileCornerPositionVals
      : desktopCornerPositionVals

    if (x < halfWidth && y < halfHeight) {
      setCornerPosition(vals.get("top_left"))
    } else if (x > halfWidth && y < halfHeight) {
      setCornerPosition(vals.get("top_right"))
    } else if (x < halfWidth && y > halfHeight) {
      setCornerPosition(vals.get("bottom_left"))
    } else if (x > halfWidth && y > halfHeight) {
      setCornerPosition(vals.get("bottom_right"))
    }
  }

  const desktopStartPanelEnter = {
    x: (400 + DESKTOP_DRAG_PADDING) * (enterSide === "left" ? -1 : 1),
    opacity: 0,
  }

  const mobileStartPanelEnter = {
    y: 200,
    opacity: 0,
  }

  const variants: { [K in InstructionsModalState]: object } = {
    desktopStart: { x: 0, opacity: 1, transition: { delay: 0.5 } },
    desktopDefault: { x: 0, opacity: 1 },
    desktopMinimized: { x: 0, opacity: 1 },
    mobileStart: { y: 0, opacity: 1, transition: { delay: 0.5 } },
    mobileDefault: { y: 0, opacity: 1 },
    mobileMinimized: { y: 0, opacity: 1 },
  }

  return (
    <>
      {isOverlayVisible && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={1}
          opacity={0.5}
          bg="#000"
        />
      )}
      {isDragging && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={1}
          opacity={0}
        ></Box>
      )}

      <MotionFlex
        layout
        transition={{
          type: "spring",
          stiffness: 700,
          damping: 40,
          delay: 0.15,
        }}
        variants={variants}
        initial={
          state.includes("mobile")
            ? mobileStartPanelEnter
            : desktopStartPanelEnter
        }
        animate={state}
        position="fixed"
        top={state === "desktopStart" ? 8 : "auto"}
        right={state === "desktopStart" && enterSide === "right" ? 8 : "auto"}
        left={state === "desktopStart" && enterSide === "left" ? 8 : "auto"}
        bottom={bottomVals.get(state) ?? "auto"}
        zIndex={2}
        w={widthVals.get(state) ?? "400px"}
        alignItems="center"
        style={draggableStates.includes(state) && { ...cornerPosition }}
        drag={draggableStates.includes(state) && !isDraggingLocked}
        dragControls={controls}
        dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
        dragElastic={1}
        onDrag={handleDragUpdate}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={() => setIsDragging(false)}
        dragTransition={{
          type: "spring",
          bounceStiffness: 1000,
          bounceDamping: 10,
          mass: 0.5,
        }}
        // Leaving this commented out means that the modal starts with focus, but uncommenting it causes issues with follow up questions.
        // Prototypes have shortcut keys available,including 'z' to change zoom. It's currently a better tradeoff to require panelists
        // to click on the prototype before using hotkeys
        // onMouseDown={(e: Event) => e.preventDefault()}
        bg="white"
        borderWidth="1px"
        borderColor="gray.200"
        boxShadow="lg"
        rounded="lg"
        overflow="hidden"
        _hover={state !== "desktopStart" ? { cursor: "grab" } : undefined}
        _active={state !== "desktopStart" ? { cursor: "grabbing" } : undefined}
      >
        <InstructionsModalInner
          state={state}
          toggleState={toggleState}
          prototypeTaskPhase={prototypeTaskPhase}
        >
          <InstructionsContent
            prototypeTask={prototypeTask}
            section={section}
            openSkipModal={isPanelist ? openSkipModal : undefined}
            prototypeTaskPhase={prototypeTaskPhase}
            prototypeType={prototypeType}
            question={question}
            advanceToNextPhase={nextPhase}
            handleQuestionSubmit={handleQuestionSubmit}
          />
        </InstructionsModalInner>
      </MotionFlex>
    </>
  )
}

export const InstructionsModal: React.FC<
  PropsWithChildren<InstructionsModalProps>
> = (props) => {
  // There is a problem when we have draggable item inside of this modal, which itself is draggable
  // If we used the same library to handle both dragging interactions, this would probably not be an issue
  // since they are able to prevent propagation by themselves. (At least framer motion does, I have tested that)
  // A better approach could also be to only allow the container to be draggable when it is the target, instead of any child element.
  // Follow up ticket: https://linear.app/usabilityhub/issue/PRD-1617/fix-nested-drag-n-drop-functionality-properly
  const [isDraggingLocked, setIsDragging] = useState(false)

  const lockDragging = () => setIsDragging(true)
  const unlockDragging = () => setIsDragging(false)

  return (
    <DragLockContext.Provider
      value={{ isDraggingLocked, lockDragging, unlockDragging }}
    >
      <InstructionsModalImpl {...props} />
    </DragLockContext.Provider>
  )
}
