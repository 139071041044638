import { AnswerCounts } from "Components/test-results/question-results/answer-counts/answer-counts"
import { emptyCounts } from "Components/test-results/question-results/answer-counts/helpers"
import { TagCountItem } from "Components/test-results/question-results/tag-counts/tag-count-item"
import { TagCountsEmptyPlaceholder } from "Components/test-results/question-results/tag-counts/tag-counts-placeholder"
import {
  AnswerTag,
  UsabilityTestSectionQuestion as Question,
  QuestionTag,
  ResponseAnswer,
  SortMethod,
} from "Types"
import { keyBy } from "lodash"
import React from "react"

function countTags(
  question: Readonly<Question>,
  answers: ReadonlyArray<ResponseAnswer>,
  answerTags: ReadonlyArray<Readonly<AnswerTag>>
): Map<QuestionTag, number> {
  // Track a fake "untagged" tag to count answers that have no tags
  const untagged: QuestionTag = {
    id: -1,
    name: "Untagged",
    _index: -1,
    usability_test_section_question_id: question.id,
  }

  const questionTags = question.question_tags
    .map((tag, i): QuestionTag => ({ ...tag, _index: i }))
    .concat([untagged])

  const questionTagById = keyBy(questionTags, "id")
  return answers.reduce((acc, answer) => {
    let hasTag = false

    answerTags.forEach((answerTag) => {
      // If we have a tag for this answer
      if (answerTag.response_answer_id === answer.id) {
        // Get the question tag from the map to use as a key
        const questionTag = questionTagById[answerTag.question_tag_id]
        // Update the value of the map (count) for that question tag
        acc.set(questionTag, acc.get(questionTag)! + 1)
        hasTag = true
      }
    })

    // If the answer has no tags, increment the count for the "untagged" tag
    if (!hasTag) {
      acc.set(untagged, (acc.get(untagged) ?? 0) + 1)
    }

    return acc
  }, emptyCounts(questionTags))
}

function getKey(tag: { name: string }): string {
  return tag.name
}

interface Props {
  question: Readonly<Question>
  answers: ReadonlyArray<ResponseAnswer>
  answerTags: ReadonlyArray<Readonly<AnswerTag>>
  sortMethod: SortMethod
}

export const TagCounts: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { question, answers, answerTags, sortMethod } = props

  if (question.question_tags.length === 0) {
    return <TagCountsEmptyPlaceholder />
  }

  return (
    <AnswerCounts
      countItemComponent={TagCountItem}
      questionId={question.id}
      countByAnswer={countTags(question, answers, answerTags)}
      responseCount={answers.length}
      sortMethod={sortMethod}
      getKey={getKey}
    />
  )
}
