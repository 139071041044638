import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import {
  Card,
  CardBody,
  CardFooter,
  LegacyCardHeader,
} from "Shared/components/Card/Card"
import {
  useCurrentAccount,
  useCurrentPlan,
  useCurrentUser,
  useRefreshCurrentAccount,
} from "UsabilityHub/hooks/useCurrentAccount"
import React, { useState } from "react"
import { useUpdatePasswordComplexity } from "~/api/generated/usabilityhub-components"

export const PasswordComplexityCard = () => {
  const currentAccount = useCurrentAccount()
  const refreshCurrentAccount = useRefreshCurrentAccount()
  const plan = useCurrentPlan()
  const currentUser = useCurrentUser()
  const isAllowed = currentUser.can_manage_team && plan?.password_complexity
  const toast = useToast()

  const { mutate: updatePasswordComplexity, isLoading: isSubmitting } =
    useUpdatePasswordComplexity({
      onSuccess: async () => {
        await refreshCurrentAccount()
        toast({
          title: "Your password complexity settings have been saved",
          status: "success",
        })
      },
      onError: (error) => {
        toast({
          title:
            error.payload.message ??
            "Unable to save password complexity settings",
          status: "error",
        })
      },
    })

  // State
  const [minimumLength, setMinimumLength] = useState(
    currentAccount.password_complexity_minimum_length
      ? currentAccount.password_complexity_minimum_length
      : Constants.DEFAULT_PASSWORD_MINIMUM_LENGTH
  )
  const [enforceCharacters, setEnforceCharacters] = useState<string>(
    currentAccount.password_complexity_enforce_characters ? "on" : "off"
  )

  const isDisabled = !isAllowed || isSubmitting
  const handleMinLengthChange = (_: string, valueAsNumber: number) =>
    Number.isNaN(valueAsNumber)
      ? setMinimumLength(0)
      : setMinimumLength(valueAsNumber)
  const handleSubmit = () => {
    updatePasswordComplexity({
      body: {
        password_complexity_minimum_length: minimumLength,
        password_complexity_enforce_characters: enforceCharacters === "on",
      },
    })
  }

  return (
    <Card>
      <LegacyCardHeader>Password complexity</LegacyCardHeader>
      <CardBody>
        <Text>
          Specify password complexity rules that will apply to all team members
          on your account.
        </Text>
        <FormControl isDisabled={isDisabled}>
          <FormLabel htmlFor="password_complexity_minimum_length">
            Minimum password length
          </FormLabel>
          <Tooltip
            isDisabled={isAllowed}
            label="Contact support to change this setting"
            hasArrow
            placement="top"
          >
            <NumberInput
              id="password_complexity_minimum_length"
              min={Constants.DEFAULT_PASSWORD_MINIMUM_LENGTH}
              max={128}
              value={minimumLength}
              isDisabled={isDisabled}
              maxW="xs"
              onChange={handleMinLengthChange}
            >
              <NumberInputField />
            </NumberInput>
          </Tooltip>
        </FormControl>
        <FormControl isDisabled={isDisabled}>
          <FormLabel htmlFor="password_complexity_enforce_characters">
            Enforce password complexity rules
          </FormLabel>
          <Tooltip
            isDisabled={isAllowed}
            label="Contact support to change this setting"
            hasArrow
            placement="top"
          >
            <RadioGroup
              id="password_complexity_enforce_characters"
              ml={2}
              display="inline-block"
              onChange={setEnforceCharacters}
              value={enforceCharacters}
            >
              <Radio
                id="password_complexity_enforce_characters_off"
                isDisabled={isDisabled}
                mr={4}
                value="off"
              >
                Off
              </Radio>
              <Radio
                id="password_complexity_enforce_characters_on"
                isDisabled={isDisabled}
                value="on"
              >
                On
              </Radio>
            </RadioGroup>
          </Tooltip>
          <FormHelperText>
            Require that passwords contain one uppercase letter, one lowercase
            letter, one digit and one special character.
          </FormHelperText>
        </FormControl>
        <CardFooter>
          <Button
            onClick={handleSubmit}
            isLoading={isSubmitting}
            isDisabled={isDisabled}
            type="submit"
            colorScheme="brand.primary"
          >
            Save password complexity
          </Button>
        </CardFooter>
      </CardBody>
    </Card>
  )
}
