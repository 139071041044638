import React from "react"

import {
  ClippedThumbnail,
  Duration,
  Filter,
  Name,
  Participants,
  Percentage,
  ProgressBox,
  Thumbnail,
} from "Components/test-results/progress-box/progress-box"
import styles from "Components/test-results/screenshot-click-results/click-selection-summary.module.scss"
import { ImageScreenshot, Rectangle } from "Types"

export interface Props {
  name: string
  clippingRectangle: Readonly<Rectangle> | null
  averageDuration: number
  chosenPercentage: number
  chosenCount: number
  isFilter: boolean
  isFiltered: boolean
  isFilterRequested: boolean
  onFilterChange: (isFiltered: boolean) => void
  screenshot: ImageScreenshot
}

export const ClickSelectionSummary: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const {
    averageDuration,
    chosenCount,
    chosenPercentage,
    clippingRectangle,
    isFilter,
    isFiltered,
    isFilterRequested,
    name,
    onFilterChange,
    screenshot,
  } = props
  return (
    <ProgressBox
      className={styles.progressBox}
      progress={chosenPercentage}
      isFilter={isFilter}
      isFiltered={isFiltered}
    >
      {clippingRectangle === null ? (
        <Thumbnail screenshot={screenshot} />
      ) : (
        <ClippedThumbnail
          screenshot={screenshot}
          clippingRectangle={clippingRectangle}
        />
      )}
      <Name>{name}</Name>
      <Duration duration={averageDuration} />
      <Percentage className={styles.percentage} percentage={chosenPercentage} />
      <Participants count={chosenCount} />
      <Filter value={isFilterRequested} onChange={onFilterChange} />
    </ProgressBox>
  )
}
