import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react"
import { TestResultsFilterState } from "Components/test-results/context/test-result-filter-state"
import { createAnswerTagFilter } from "Components/test-results/filters/create-filters/answer-tag-filter"
import { getFilterUtilityFunctions } from "Components/test-results/filters/get-filter-utility-functions"
import { AnswerTagEditor } from "Components/test-results/question-results/written-answer-question-results/answer-tag-editor"
import { Dispatch } from "Redux/app-store"
import { removeQuestionTag } from "Redux/reducers/test-results/action-creators"
import { countAnswerTags } from "Redux/reducers/test-results/selectors"
import {
  QuestionTag,
  ResponseAnswer,
  UsabilityTestSectionQuestion,
} from "Types"
import { useSectionContext } from "UsabilityHub/contexts"
import { useModal } from "Utilities/modals/use-modal"
import React, { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ConfirmDeleteTagModal } from "./confirm-delete-tag-modal"
import { EditTagModal } from "./edit-tag-modal"

interface Props {
  answers: ReadonlyArray<Readonly<ResponseAnswer>>
  question: Readonly<UsabilityTestSectionQuestion>
}

export const AnswerTagEditorTooltipTarget: React.FC<
  React.PropsWithChildren<Props>
> = ({ answers, question, children }) => {
  const dispatch = useDispatch<Dispatch>()
  const { open: openEditTagModal } = useModal(EditTagModal)
  const { open: openConfirmDeleteTagModal } = useModal(ConfirmDeleteTagModal)

  const {
    section: { id: sectionId },
  } = useSectionContext()
  const { setTestResultsFilters } = useContext(TestResultsFilterState)
  const { removeFilter } = getFilterUtilityFunctions(createAnswerTagFilter)

  const handleRemoveFilter = (questionId: number, tagId: number) => {
    setTestResultsFilters((filters) => {
      return removeFilter(filters, sectionId, questionId, tagId)
    })
  }

  const countTags = useSelector(countAnswerTags)

  const handleEditTag = (questionTag: QuestionTag) => {
    const answerCount = countTags(questionTag.id)
    openEditTagModal({ questionTag, answerCount })
  }

  const handleDeleteTag = (questionTag: QuestionTag) => {
    handleRemoveFilter(
      questionTag.usability_test_section_question_id,
      questionTag.id
    )
    void dispatch(removeQuestionTag(questionTag))
  }

  const handleDeleteTagOrConfirm = (questionTag: QuestionTag) => {
    const answerCount = countTags(questionTag.id)
    if (answerCount !== 0) {
      // If any answers use this tag, show a confirmation modal
      openConfirmDeleteTagModal({ questionTag, handleDeleteTag, answerCount })
    } else {
      // Otherwise, remove it immediately without confirmation
      handleDeleteTag(questionTag)
    }
  }

  return (
    <Popover isLazy offset={[268, -40]}>
      <PopoverTrigger>{children}</PopoverTrigger>

      <Portal>
        <PopoverContent>
          <PopoverBody p={0}>
            <AnswerTagEditor
              answers={answers}
              question={question}
              handleEditTag={handleEditTag}
              handleDeleteTag={handleDeleteTagOrConfirm}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
