import { ListIterateeCustom, findIndex, isMatch } from "lodash"

import { reportErrorToSentry } from "Utilities/error"

function splice<T extends Record<string, unknown>>(
  array: ReadonlyArray<T>,
  index: number,
  deleteCount: number,
  ...insertions: T[]
): ReadonlyArray<T> {
  const result = [...array]
  result.splice(index, deleteCount, ...insertions)
  return result
}

function safeFindIndex<T extends Record<string, unknown>>(
  array: ReadonlyArray<T>,
  matcher: ListIterateeCustom<T, boolean>,
  recordName: string,
  assertMatcher: Partial<T> | undefined,
  require: boolean
): number {
  const index = findIndex(array, matcher)
  if (index === -1) {
    if (require) {
      reportErrorToSentry(
        new TypeError(`Couldn${"\u2019"}t find ${recordName}`),
        {
          extra: { matcher, array },
        }
      )
    }
  } else {
    const found = array[index]
    if (assertMatcher !== undefined && !isMatch(found, assertMatcher)) {
      reportErrorToSentry(
        new TypeError(`${recordName} was in unexpected state`),
        {
          extra: { record: found, assertMatcher },
        }
      )
    }
  }
  return index
}
/**
 * Factor to create a set of immutable array helpers, designed for Redux/React
 * state management.
 *
 * The record name will be used for reporting errors, either as exceptions in
 * development, or via Sentry in production.
 *
 * @param recordName
 *   The humanized singular name of the record type, eg. "usability test"
 */
export function createArrayUtility<T extends Record<string, any>>(
  recordName: string
) {
  return {
    /**
     * Create a copy of an array with one element updated with given attributes.
     *
     * @param array
     *   The array to modify.
     * @param matcher
     *   Set of attributes used to identify the preferred record.
     * @param attributes
     *   Attributes to merge into the record if found.
     * @param assertMatcher?
     *   A set of attributes used to check preconditions for the record if found.
     * @returns ReadonlyArray
     */
    mergeFirst(
      array: ReadonlyArray<T>,
      matcher: ListIterateeCustom<T, boolean>,
      attributes: Partial<T>,
      assertMatcher?: Partial<T>,
      require = true
    ): ReadonlyArray<T> {
      const index = safeFindIndex(
        array,
        matcher,
        recordName,
        assertMatcher,
        require
      )
      if (index === -1) {
        return array
      }
      return splice(
        array,
        index,
        1,
        Object.assign({}, array[index], attributes)
      )
    },

    /**
     * Create a copy of an array with one element updated by given callback.
     *
     * @param array
     *   The array to modify.
     * @param matcher
     *   Set of attributes used to identify the preferred record.
     * @param update
     *   Callback used to transform the found element into its replacement.
     * @param assertMatcher
     *   A set of attributes used to check preconditions for the record if found.
     * @returns ReadonlyArray
     */
    updateFirst(
      array: ReadonlyArray<T>,
      matcher: ListIterateeCustom<T, boolean>,
      update: (record: Readonly<T>) => T,
      assertMatcher?: Partial<T>,
      require = true
    ): ReadonlyArray<T> {
      const index = safeFindIndex(
        array,
        matcher,
        recordName,
        assertMatcher,
        require
      )
      if (index === -1) {
        return array
      }
      return splice(array, index, 1, update(array[index]))
    },

    /**
     * Create a copy of an array with one particular element removed.
     *
     * @param array
     *   The array to modify.
     * @param matcher
     *   Set of attributes used to identify the preferred record.
     * @param assertMatcher
     *   A set of attributes used to check preconditions for the record if found.
     * @returns ReadonlyArray
     */
    removeFirst(
      array: ReadonlyArray<T>,
      matcher: ListIterateeCustom<T, boolean>,
      assertMatcher?: Partial<T>,
      require = true
    ): ReadonlyArray<T> {
      const index = safeFindIndex(
        array,
        matcher,
        recordName,
        assertMatcher,
        require
      )
      if (index === -1) {
        return array
      }
      return splice(array, index, 1)
    },
  }
}
