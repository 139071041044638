import { useFilteredClicks } from "./use-filtered-clicks"

export const useSectionScreenshotClicks = (sectionScreenshotId: number) => {
  const filteredClicks = useFilteredClicks()

  return filteredClicks.filter(
    (click) =>
      click.usability_test_section_screenshot_id === sectionScreenshotId
  )
}
