import React, {
  PropsWithChildren,
  useEffect,
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from "react"

import { FormScreenerQuestions } from "Shared/components/ScreenerForm/ScreenerForm"
import { useAvailabilitySectionForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useAvailabilitySectionForm"
import { useDeviceRequirementForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useDeviceRequirementForm"
import { useMeetingUrlForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useMeetingUrlForm"
import { useStudyDetailsForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useStudyDetailsForm"
import { useTeamMembersForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useTeamMembersForm"

import { useFeatureFlagLoading } from "Hooks/use-feature-flag"
import { useModeratedStudyScreenerQuestions } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/screeners/useModeratedStudyScreenerQuestions"
import { useModeratedStudyContext } from "../ModeratedStudyContext"

type StudyDetailsContext = {
  moderatedStudyId: string
  studyDetailsForm: ReturnType<typeof useStudyDetailsForm>
  meetingUrlForm: ReturnType<typeof useMeetingUrlForm>
  deviceRequirementForm: ReturnType<typeof useDeviceRequirementForm>
  availabilityForm: ReturnType<typeof useAvailabilitySectionForm>
  teamMembersForm: ReturnType<typeof useTeamMembersForm>
  isScreenerEnabled: boolean
  formScreenerQuestions: FormScreenerQuestions
  setFormScreenerQuestions: Dispatch<SetStateAction<FormScreenerQuestions>>
  combinedScreenerQuestionErrors: {
    formState: {
      errors: Record<string, string>
      isScreenerEnabled: boolean
    }
  }
  isMutatingTeamMembers: boolean
  setMutatingTeamMembers: Dispatch<SetStateAction<boolean>>
  isAnyFormDirty: boolean
  isAnyFormSubmitting: boolean
  isLoading: boolean
  separatePages: boolean
} & ReturnType<typeof useModeratedStudyScreenerQuestions>

const StudyDetailsContext = createContext<StudyDetailsContext | null>(null)

export const StudyDetailsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { loading, enabled } = useFeatureFlagLoading("interview_setup")

  const { moderatedStudy, invalidateStudySummaryQuery } =
    useModeratedStudyContext()

  const moderatedStudyId = moderatedStudy.id

  // We've hoisted these form up so they can be passed to the sections that need them as well as the
  // saving indicator which needs to keep track of all form activity.
  const availabilityForm = useAvailabilitySectionForm(moderatedStudy)
  const studyDetailsForm = useStudyDetailsForm(moderatedStudy)
  const meetingUrlForm = useMeetingUrlForm(moderatedStudy)
  const deviceRequirementForm = useDeviceRequirementForm(moderatedStudy)
  const teamMembersForm = useTeamMembersForm(moderatedStudy)

  // We also need to track some individual mutations to affect the saving indicator
  // Once we need more of these it might pay to abstract this out
  const [isMutatingTeamMembers, setMutatingTeamMembers] = useState(false)
  const mutationState = [
    { formState: { isSubmitting: isMutatingTeamMembers, isDirty: false } },
  ]

  const [isScreenerEnabled, setIsScreenerEnabled] = useState<boolean>(
    moderatedStudy.screener_enabled
  )

  // Each screener question is a form in itself, so we're storing status information for each one in
  // an array so we can pass it to the status component and saving indicator.
  const [formScreenerQuestions, setFormScreenerQuestions] =
    useState<FormScreenerQuestions>([])

  const {
    screenerQuestions,
    appendScreenerQuestion,
    removeScreenerQuestion,
    updateScreenerQuestion,
    duplicateScreenerQuestion,
    onScreenerToggle,
  } = useModeratedStudyScreenerQuestions({
    moderatedStudyId,
    setIsScreenerEnabled,
    onDisable: () => setFormScreenerQuestions([]),
  })

  // The screener questions each have their own form, but the UI treats it as a single entry.
  // Loop over each question and combine the errors so they can be passed to the status component.
  const combinedScreenerQuestionErrors = {
    formState: {
      errors: formScreenerQuestions.reduce((errors, question) => {
        // Note this is a shallow merge, so if different questions have errors on the same field
        // some will be overridden. For now it doesn't matter but if we add more advanced error
        // messaging we might need to update this.
        return { ...errors, ...question.formState.errors }
      }, {}),
      isScreenerEnabled,
    },
  }

  // After availability or team members are saved, we need to invalidate the study summary query
  const shouldUpdateSummary =
    availabilityForm.formState.isSubmitSuccessful ||
    teamMembersForm.formState.isSubmitSuccessful

  useEffect(() => {
    if (shouldUpdateSummary) void invalidateStudySummaryQuery()
  }, [shouldUpdateSummary])

  useEffect(() => {
    setIsScreenerEnabled(moderatedStudy.screener_enabled)
  }, [moderatedStudy.screener_enabled])

  const forms: { formState: { isDirty: boolean; isSubmitting: boolean } }[] = [
    studyDetailsForm,
    meetingUrlForm,
    deviceRequirementForm,
    availabilityForm,
    teamMembersForm,
    ...mutationState,
    ...formScreenerQuestions,
  ]
  const isAnyFormDirty = forms.some((f) => f.formState.isDirty)
  const isAnyFormSubmitting = forms.some((f) => f.formState.isSubmitting)

  return (
    <StudyDetailsContext.Provider
      value={{
        moderatedStudyId,
        studyDetailsForm,
        meetingUrlForm,
        deviceRequirementForm,
        availabilityForm,
        isScreenerEnabled,
        screenerQuestions,
        formScreenerQuestions,
        setFormScreenerQuestions,
        appendScreenerQuestion,
        removeScreenerQuestion,
        updateScreenerQuestion,
        duplicateScreenerQuestion,
        onScreenerToggle,
        combinedScreenerQuestionErrors,
        teamMembersForm,
        isMutatingTeamMembers,
        setMutatingTeamMembers,
        isAnyFormDirty,
        isAnyFormSubmitting,
        isLoading: loading,
        separatePages: !!enabled,
      }}
    >
      {children}
    </StudyDetailsContext.Provider>
  )
}

export const useStudyDetails = (): StudyDetailsContext => {
  const context = useContext(StudyDetailsContext)
  if (!context) {
    throw new Error(
      "useStudyDetails must be used within a StudyDetailsProvider"
    )
  }
  return context
}
