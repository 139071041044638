import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Text,
} from "@chakra-ui/react"
import { ArrowRightIcon } from "@heroicons/react/solid"
import React from "react"

import { useTranslate } from "Shared/hooks/useTranslate"
import { CardSortCardPersisted } from "Types"
import { useTestBranding } from "UsabilityHub/components/UsabilityTest/context/testBranding"

import { useCardSortContext } from "./CardSortContext"
import { DragHandle } from "./DragHandle"

export interface Props {
  card: CardSortCardPersisted
  parentCategoryId: string
  isDragging?: boolean
}

export const Card: React.FC<Props> = ({
  card,
  parentCategoryId,
  isDragging = false,
}) => {
  const { active, cardSortType, categories, openCategories, sortCard } =
    useCardSortContext()
  const { button_color } = useTestBranding()
  const translate = useTranslate()

  // Open categories that aren't named yet won't appear in the "move to" menu
  const targetableCategories = [...categories, ...openCategories].filter(
    (c) => c.label !== ""
  )

  return (
    <Stack
      w={["150px", "216px"]}
      borderWidth={1}
      borderColor="gray.200"
      bg="white"
      rounded="lg"
      cursor={active ? "pointer" : "default"}
      overflow="hidden"
      _hover={{
        boxShadow: active ? "md" : "none",
      }}
      boxShadow={isDragging ? "lg" : "none"}
      mb={2}
      flexShrink={0}
      userSelect="none"
    >
      {card.image_url && (
        <Image
          w="100%"
          src={card.image_url}
          objectFit="cover"
          sx={{ aspectRatio: "4 / 3" }}
        />
      )}
      <Stack p={3} flexGrow={1}>
        <Text fontSize={["sm", "md"]} fontWeight="medium">
          {card.label}
        </Text>
        {card.description && (
          <Text fontSize="xs" color="gray.500">
            {card.description}
          </Text>
        )}

        <Box flexGrow={1} />

        <Flex justify="space-between" align="center">
          <Icon as={DragHandle} boxSize="20px" color="gray.500" />
          <Menu preventOverflow isLazy>
            <MenuButton
              as={IconButton}
              variant="ghost"
              size="sm"
              color="gray.500"
              aria-label={translate("test.card_sort.move_to_menu")}
              icon={<Icon boxSize="20px" as={ArrowRightIcon} />}
              disabled={!active}
              data-qa="move_to_category_menu_button"
            />
            <Portal>
              <MenuList maxH="sm" maxW="90vw" overflowY="auto" zIndex={5}>
                <MenuGroup
                  title={translate("test.card_sort.move_to_heading")}
                  color="gray.500"
                  fontWeight="normal"
                  m={0}
                  p={3}
                >
                  {targetableCategories.map((category) => {
                    return (
                      <MenuItem
                        key={category.id}
                        onClick={() =>
                          sortCard(card.id!, parentCategoryId, category.id!, 0)
                        }
                      >
                        {category.label}
                      </MenuItem>
                    )
                  })}
                  {cardSortType !== "closed" && (
                    <MenuItem
                      color={button_color}
                      onClick={() =>
                        sortCard(card.id!, parentCategoryId, "new", 0)
                      }
                    >
                      {translate("test.card_sort.move_to_new_category")}
                    </MenuItem>
                  )}
                </MenuGroup>
              </MenuList>
            </Portal>
          </Menu>
        </Flex>
      </Stack>
    </Stack>
  )
}
