import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"

import { getEnvState } from "JavaScripts/state"
import { Dispatch } from "Redux/app-store"
import { getFigmaFlowPrototypeUrl } from "Redux/reducers/figma-file-versions/selectors"
import { setPrototypeGoal } from "Redux/reducers/test-builder-form/action-creators/prototypes"
import { FigmaEmbed } from "Shared/components/FigmaEmbed/FigmaEmbed"
import { FigmaFileFlow, FigmaMessage } from "Types"
import { useSectionIndexContext } from "UsabilityHub/contexts"

import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import { StartOrGoal } from "./StartAndGoalSelection"

type StartAndGoalSelectionModalProps = {
  startOrGoal: StartOrGoal
  figmaFileFlow: FigmaFileFlow
  isOpen: boolean
  onClose: () => void
}

export const StartAndGoalSelectionModal: React.FC<
  StartAndGoalSelectionModalProps
> = ({ startOrGoal, figmaFileFlow, isOpen, onClose }) => {
  // capture node id of Figma screens
  const [currentScreen, setCurrentScreen] = useState<string | null>(null)
  const onFigmaMessageEvent = useCallback((figmaMessage: FigmaMessage) => {
    if (figmaMessage.type === "PRESENTED_NODE_CHANGED") {
      setCurrentScreen(figmaMessage.data.presentedNodeId as string)
    }
  }, [])
  useEffect(() => {
    const handleIFrameMessage = (event: MessageEvent<FigmaMessage>) => {
      if (event.origin === getEnvState().FIGMA_DOMAIN) {
        onFigmaMessageEvent(event.data)
      }
    }
    window.addEventListener("message", handleIFrameMessage, false)
    return () => {
      window.removeEventListener("message", handleIFrameMessage)
    }
  }, [onFigmaMessageEvent])

  // switch state
  const isDisabled =
    !currentScreen || currentScreen === figmaFileFlow.start_node_id
  const isCurrentSelection =
    !!currentScreen && currentScreen === figmaFileFlow.goal_node_id

  // set goal
  const dispatch = useDispatch<Dispatch>()
  const sectionIndex = useSectionIndexContext()
  const onSelect = () => {
    // A button is not an ideal widget to set a mutable text value.
    // Explicitly setting goal rather than using redux-form, makes things much simpler.
    dispatch(setPrototypeGoal(sectionIndex, currentScreen))
    handleClose()
  }

  const handleClose = () => {
    setCurrentScreen(null)
    onClose()
  }

  // Wrap the actual modal to allow clearing state with a custom onClose method
  return (
    <WrappedStartAndGoalSelectionModal
      startOrGoal={startOrGoal}
      figmaFileFlow={figmaFileFlow}
      isOpen={isOpen}
      onClose={handleClose}
      isDisabled={isDisabled}
      isCurrentSelection={isCurrentSelection}
      onSelect={onSelect}
    />
  )
}

type WrappedStartAndGoalSelectionModalProps =
  StartAndGoalSelectionModalProps & {
    isDisabled: boolean
    isCurrentSelection: boolean
    onSelect: () => void
  }

const getTooltipLabel = (
  startOrGoal: StartOrGoal,
  isDisabled: boolean,
  isCurrentSelection: boolean
) => {
  if (startOrGoal === "goal") {
    if (isDisabled) {
      return `Goal screen can${"\u2019"}t be the same as start screen`
    } else if (isCurrentSelection) {
      return "This is the current goal screen"
    }
  } else {
    return null
  }
}

const WrappedStartAndGoalSelectionModal: React.FC<
  WrappedStartAndGoalSelectionModalProps
> = ({
  startOrGoal,
  figmaFileFlow,
  isOpen,
  onClose,
  isDisabled,
  isCurrentSelection,
  onSelect,
}) => {
  const figmaFlowPrototypeUrl = useSelector(
    getFigmaFlowPrototypeUrl(figmaFileFlow)
  )

  const tooltipLabel = getTooltipLabel(
    startOrGoal,
    isDisabled,
    isCurrentSelection
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w="full" maxW="1200px">
        <ModalHeader borderBottom="1px" borderColor="gray.200">
          <HStack>
            <Text
              fontWeight="semibold"
              fontSize="sm"
            >{`Click through the prototype until you reach the ${startOrGoal} screen`}</Text>
            <Spacer />
            <HStack spacing={4}>
              <Tooltip
                hasArrow
                label={tooltipLabel}
                placement="bottom"
                fontSize="xs"
                shouldWrapChildren
                isDisabled={!tooltipLabel}
              >
                <Button
                  id="screen-selection-modal-button"
                  isDisabled={isDisabled || isCurrentSelection}
                  colorScheme="brand.secondary"
                  onClick={onSelect}
                >{`Set as ${startOrGoal} screen`}</Button>
              </Tooltip>
              <IconButton
                id="screen-selection-modal-close"
                icon={<Icon as={XOutlineIcon} />}
                aria-label="Close"
                variant="outline"
                onClick={onClose}
              />
            </HStack>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <Flex
            mx="auto"
            bgColor="gray.50"
            w="full"
            h="888px"
            align="center"
            justify="center"
          >
            <FigmaEmbed prototypeUrl={figmaFlowPrototypeUrl} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
