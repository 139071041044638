import { useMemo, useState } from "react"

import { FigmaTaskPath } from "Types"
import { compareBoolDesc } from "Utilities/sort"

export type FigmaTaskPathSortMethod =
  | "none"
  | "duration"
  | "misclicks"
  | "participants"
  | "goalScreen"

export type SortMethodOptions<SortMethod extends FigmaTaskPathSortMethod> = {
  [index in SortMethod]: string
}

export function useSortedFigmaTaskPaths<
  SortMethod extends FigmaTaskPathSortMethod,
  F extends FigmaTaskPath,
>(paths: F[], defaultSortMethod: SortMethod) {
  const [sortMethod, setSortMethod] = useState<SortMethod>(defaultSortMethod)

  // Only re-calc sort method if the common paths or sort method change.
  const sortedPaths = useMemo(
    () =>
      paths.sort((pathA, pathB) => {
        switch (sortMethod) {
          case "none":
            return 0
          case "duration":
            return pathB.meta.duration - pathA.meta.duration
          case "misclicks":
            return pathB.meta.misclickRate - pathA.meta.misclickRate
          case "participants":
            if (
              "participantsCount" in pathA.meta &&
              "participantsCount" in pathB.meta
            ) {
              return pathB.meta.participantsCount - pathA.meta.participantsCount
            }

            return 0
          case "goalScreen":
            // Sort by reachedGoalScreen then participantCount

            // Participant count is only important when reachedGoalScreen is
            // the same
            if (
              pathA.meta.goalScreenHasBeenReached ===
              pathB.meta.goalScreenHasBeenReached
            ) {
              if (pathA.type === "individual" || pathB.type === "individual")
                return 0
              return pathB.meta.participantsCount - pathA.meta.participantsCount
            }

            return compareBoolDesc(
              pathA.meta.goalScreenHasBeenReached,
              pathB.meta.goalScreenHasBeenReached
            )
          default:
            throw new Error(`sortMethod (${sortMethod} is not supported)`)
        }
      }),
    [paths, sortMethod]
  )

  return { sortedPaths, sortMethod, setSortMethod }
}
