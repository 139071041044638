import { Flex, FlexProps, Text } from "@chakra-ui/react"
import { ClockRewindOutlineIcon } from "Shared/icons/untitled-ui/ClockRewindOutlineIcon"
import React from "react"

type Props = {
  duration: string | null
  isLoading: boolean
} & FlexProps

export const EstimatedTurnaround: React.FC<Props> = ({
  duration,
  isLoading,
  ...flexProps
}) => {
  return (
    <Flex justify="center" align="center" {...flexProps}>
      <ClockRewindOutlineIcon boxSize={5} mr={2} />
      <Text>Estimated turnaround time:</Text>
      <Text
        fontWeight={500}
        // TODO: these should be text.success and text.warning once those tokens exist
        color={duration && !isLoading ? "green.700" : "#9C4221"}
        ml={1}
      >
        {isLoading ? "Pending" : (duration ?? "—")}
      </Text>
    </Flex>
  )
}
