import { AppContext } from "Shared/contexts/AppContext"
import { useContext, useEffect, useRef } from "react"

export function useChannel<
  Params = Record<string, never>,
  Message = Record<string, unknown>,
>(
  channelId: string,
  params: Params = {} as Params,
  options: { onMessage?: (message: Message) => void } = {}
) {
  const { consumer } = useContext(AppContext)

  const onMessage = useRef(options.onMessage)

  const cachedParams = useRef(params)

  useEffect(() => {
    onMessage.current = options.onMessage
    cachedParams.current = params
  }, [options, params])

  useEffect(() => {
    if (!consumer) return

    const identifier = {
      channel: channelId,
      ...cachedParams.current,
    }

    const channel = consumer.subscriptions.create(identifier, {
      received: (message: Message) => {
        onMessage.current?.(message)
      },
    })

    return () => {
      channel.unsubscribe()
    }
  }, [channelId, consumer])
}
