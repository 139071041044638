import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  OrderedList,
  Portal,
  ResponsiveValue,
  Select,
  Text,
} from "@chakra-ui/react"
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { yupResolver } from "@hookform/resolvers/yup"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { Trash01OutlineIcon } from "Shared/icons/untitled-ui/Trash01OutlineIcon"
import { debounce, isEqual } from "lodash"
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { v4 as uuidv4 } from "uuid"
import * as Yup from "yup"
import {
  ScreenerQuestion,
  ScreenerQuestionType,
} from "~/api/generated/usabilityhubSchemas"
import { ScreenerQuestionHeader } from "./ScreenerQuestionHeader"
import { SortableScreenerQuestionOption } from "./SortableScreenerQuestionOption"
import {
  SCREENER_QUESTION_OPTION_RULE,
  SCREENER_QUESTION_TYPES,
} from "./constants"
import { getScreenerQuestionOptionRuleLabel } from "./getScreenerQuestionOptionRuleLabel"
import { getScreenerQuestionLabel } from "./getScreenerQuestionTypeLabel"

const ScreenerQuestionOptionSchema = Yup.object().shape({
  id: Yup.string().required(),
  value: Yup.string().required("Choice cannot be empty"),
  rule: Yup.string().oneOf(SCREENER_QUESTION_OPTION_RULE).required(),
})

const ScreenerQuestionSchema: Yup.ObjectSchema<
  Omit<ScreenerQuestion, "deleted_at">
> = Yup.object().shape({
  id: Yup.string().required(),
  type: Yup.string().oneOf(SCREENER_QUESTION_TYPES).required(),
  text: Yup.string().required("Question cannot be empty"),
  shuffle_options: Yup.boolean().required(),
  options: Yup.array(ScreenerQuestionOptionSchema).when(
    "type",
    ([type], schema) => {
      if (type === "single_select" || type === "multi_select") {
        return schema
          .min(2)
          .required()
          .test({
            name: "unique",
            test: (options, ctx) => {
              const values = options.map((option) => option.value)
              const indexesWithDuplication = values
                .map((v, i) => {
                  return values.indexOf(v) !== values.lastIndexOf(v) ? i : -1
                })
                .filter((v) => v !== -1)

              if (indexesWithDuplication.length === 0) return true

              // We need to return one error for the overall options field which contains the message
              // displayed at the bottom. We also need to return an error for each duplicate option
              // so we can highlight the specific array elements affected.
              // In Yup that means wrapping the ValidationErrors into one parent ValidationError.
              return new Yup.ValidationError([
                ctx.createError({
                  path: "options",
                  message: "Duplicate options",
                }),
                ...indexesWithDuplication.map((index) =>
                  ctx.createError({
                    path: `options[${index}].value`,
                    message: "Duplicate options",
                  })
                ),
              ])
            },
            message: "Duplicate options",
          })
          .test({
            name: "qualify",
            test: (options) =>
              options.some((option) => option.rule === "qualify"),
            message: "You need at least one qualifying answer",
          })
      }

      return schema.notRequired()
    }
  ),
})

type ScreenerQuestionFormProps = {
  screenerQuestion: ScreenerQuestion
  onRemove: () => void
  onUpdate: (
    updatedScreenerQuestion: ScreenerQuestion,
    position: number
  ) => Promise<void>
  onDuplicate: () => void
  questionPosition: number
  formStateCallback?: (
    questionId: string,
    isDirty: boolean,
    isSubmitting: boolean,
    errors: Record<string, unknown>
  ) => void
  onFormChange?: (newState: unknown) => void
  renderFormElement?: boolean
  validQuestionTypes: ScreenerQuestionType[]
  readOnly: boolean
  maxScreenerQuestionsReached: boolean
  padding?: ResponsiveValue<string | number>
}

export const ScreenerQuestionForm: React.FC<
  PropsWithChildren<ScreenerQuestionFormProps>
> = ({
  screenerQuestion,
  onRemove,
  onDuplicate,
  formStateCallback,
  onUpdate: externalOnUpdate,
  questionPosition,
  children,
  onFormChange,
  renderFormElement,
  validQuestionTypes,
  readOnly,
  maxScreenerQuestionsReached,
  padding = 8,
}) => {
  const {
    register,
    watch,
    formState: { isDirty, isSubmitting, errors },
    control,
    reset,
    getValues,
    handleSubmit,
  } = useForm<ScreenerQuestion>({
    mode: "onChange",
    shouldFocusError: false,
    resolver: yupResolver(ScreenerQuestionSchema),
    defaultValues: screenerQuestion,
  })

  const saveFormData = useCallback(
    debounce(() => {
      // `handleSubmit` will receive the form data if form validation is successful.
      void handleSubmit(async (data) => {
        await externalOnUpdate(data, questionPosition)
        // Mark the form as no longer dirty once the save succeeds
        reset(getValues(), { keepValues: true })
      })()
    }, 1000), // Debounce for 1 seconds
    [questionPosition]
  )

  // Keep parent in sync with form state changes; used for the sidebar / save indicator
  useEffect(() => {
    formStateCallback?.(screenerQuestion.id, isDirty, isSubmitting, errors)
  }, [formStateCallback, screenerQuestion.id, isDirty, isSubmitting, errors])

  useEffect(() => {
    // Return the cleanup function to wait for the final call to complete
    return () => {
      // Call the flush method of the debounced function to ensure that the final call is complete
      void saveFormData.flush()
    }
  }, [saveFormData])

  const [firstSave, setFirstSave] = useState(true)
  useEffect(() => {
    const formDataSubscription = watch((value) => {
      // Check if the form data has changed for the first time to
      // avoid unnecessary saving of the initial form data.
      // After the first save, we should always save the form data
      // if it has changed.
      //
      // Note that the `screenerQuestion` value will not be changed after saving
      // to avoid resetting the form and to provide a better user experience.
      //
      // In this case, if the user changes a field value from "a" to "ab",
      // it will be saved but the value is still "a" in the `screenerQuestion`.
      // If the user changes that value from "ab" back to "a" later, the changed value
      // is considered the same as the `screenerQuestion`.
      //
      // Thus, we uses `!firstSave` to ensure that any changes will be saved
      // after the first save.
      if (!isEqual(screenerQuestion, value) || !firstSave) {
        void saveFormData()
        setFirstSave(false)
      }
    })
    return () => formDataSubscription.unsubscribe()
  }, [watch, screenerQuestion, saveFormData])

  const { append, remove, replace, fields, move, update } = useFieldArray({
    control,
    name: "options",
  })

  const sensors = useSensors(useSensor(PointerSensor))

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const oldIndex = fields.findIndex((entry) => entry.id === active.id)
      const newIndex = fields.findIndex((entry) => entry.id === over?.id)

      move(oldIndex, newIndex)
    }
  }

  // This is for usability test screeners - we need to listen for changes
  // to questions so that we can sync the Redux store with react-hook-form
  useEffect(() => {
    const subscription = watch((newQuestion) => {
      onFormChange?.(newQuestion)
    })

    return () => subscription.unsubscribe()
  }, [onFormChange])

  const typeValue = watch("type")
  const screenerQuestionNeedsOptions =
    typeValue === "single_select" || typeValue === "multi_select"

  // Remove "Not Relevant" option for single select questions
  const availableOptionRules = useMemo(
    () =>
      SCREENER_QUESTION_OPTION_RULE.filter(
        (optionRule) =>
          typeValue !== "single_select" || optionRule !== "not_relevant"
      ),
    [typeValue]
  )

  // When changing type, we might need to migrate some option rules if they are no longer allowed
  const watchedFields = watch("options")
  useEffect(() => {
    watchedFields?.forEach((field, index) => {
      if (!availableOptionRules.includes(field.rule)) {
        update(index, { ...field, rule: availableOptionRules[1] })
      }
    })
  }, [watchedFields, availableOptionRules, update])

  const addBlankOption = () => {
    append({
      id: uuidv4(),
      value: "",
      rule: typeValue === "single_select" ? "disqualify" : "not_relevant",
    })
  }

  // When a user pastes text with newlines in it, we'll split by newline and insert
  // or replace each line as its own option starting from the point they pasted.
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (readOnly) return

    const text = event.clipboardData.getData("text")

    if (!text.includes("\n")) return

    event.preventDefault()
    const startIndex = Number(event.currentTarget.dataset.fieldIndex)
    const lines = text.split("\n")
    const optionsToAdd = lines.map(
      (line) =>
        ({
          id: uuidv4(),
          value: line,
          rule: typeValue === "single_select" ? "disqualify" : "not_relevant",
        }) as const
    )
    const newOptionArray = fields.slice()
    newOptionArray.splice(startIndex, lines.length, ...optionsToAdd)
    replace(newOptionArray)
  }

  const errorMessages = Object.values(errors?.options ?? []).map((error) => {
    if (!error) return null
    if (typeof error !== "object") return null
    if ("message" in error) return error.message
    if ("value" in error) return error.value.message

    return null
  })

  const distinctErrorMessages = [...new Set(errorMessages.filter((m) => m))]

  return (
    <>
      <Box
        as={renderFormElement ? "form" : "div"}
        display="flex"
        flexDirection="column"
        bg="white"
        gap={6}
        pb={8}
        px={padding}
      >
        <ScreenerQuestionHeader
          type={typeValue}
          onDelete={onRemove}
          onDuplicate={onDuplicate}
          readOnly={readOnly}
          maxScreenerQuestionsReached={maxScreenerQuestionsReached}
        />

        <Flex gap={2}>
          <Box flexGrow={1}>
            <FormControl isInvalid={!!errors.text}>
              <FormLabel>Question</FormLabel>
              <Flex align="center" gap={2}>
                {children}
                <Input
                  {...register("text")}
                  isDisabled={readOnly}
                  type="text"
                />
              </Flex>

              {errors.text && (
                <FormErrorMessage>{errors.text?.message}</FormErrorMessage>
              )}
            </FormControl>
          </Box>

          <Box w="220px">
            <FormControl>
              <FormLabel>&nbsp;</FormLabel>

              <Controller
                render={({ field }) => (
                  <Select {...field} isDisabled={readOnly} w="full">
                    {validQuestionTypes.map((questionType) => (
                      <option key={questionType} value={questionType}>
                        {getScreenerQuestionLabel(questionType)}
                      </option>
                    ))}
                  </Select>
                )}
                name="type"
                control={control}
              />
            </FormControl>
          </Box>
        </Flex>

        {screenerQuestionNeedsOptions && (
          <Box>
            <Flex justify="space-between">
              <Heading fontSize="sm" fontWeight="normal">
                Choices (Press ⏎ for new line or paste a list)
              </Heading>
              <Heading w="220px" fontSize="sm" fontWeight="normal">
                Eligibility
              </Heading>
            </Flex>

            <OrderedList
              position="relative"
              listStyleType="none"
              m={0}
              mt={4}
              display="flex"
              flexDirection="column"
              gap={4}
            >
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  items={fields}
                  strategy={verticalListSortingStrategy}
                >
                  {fields.map((field, index) => (
                    <SortableScreenerQuestionOption
                      key={field.id}
                      id={field.id}
                      readOnly={readOnly}
                    >
                      <Flex gap={2} alignItems="center">
                        <Box flexGrow={1}>
                          <FormControl
                            isInvalid={!!errors.options?.[index]?.value}
                          >
                            <Input
                              {...register(`options.${index}.value` as const)}
                              onPaste={handlePaste}
                              data-field-index={index}
                              onKeyDown={(event) => {
                                if (readOnly) return

                                if (event.key === "Enter") {
                                  event.preventDefault()
                                  addBlankOption()
                                }
                              }}
                              isDisabled={readOnly}
                            />
                          </FormControl>
                        </Box>

                        <Box w="180px">
                          <Controller
                            render={({ field }) => (
                              <Menu>
                                <MenuButton
                                  as={Button}
                                  variant="outline"
                                  w="full"
                                  fontWeight="normal"
                                  textAlign="left"
                                  rightIcon={
                                    <Icon as={ChevronDownOutlineIcon} />
                                  }
                                  onBlur={field.onBlur}
                                  isDisabled={readOnly}
                                >
                                  <Flex align="center">
                                    {getScreenerQuestionOptionRuleLabel(
                                      field.value
                                    )}
                                  </Flex>
                                </MenuButton>
                                <Portal>
                                  <MenuList>
                                    {availableOptionRules.map((optionRule) => (
                                      <MenuItem
                                        key={optionRule}
                                        onClick={() =>
                                          field.onChange(optionRule)
                                        }
                                      >
                                        {getScreenerQuestionOptionRuleLabel(
                                          optionRule
                                        )}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </Portal>
                              </Menu>
                            )}
                            name={`options.${index}.rule` as const}
                            control={control}
                          />
                        </Box>
                        <IconButton
                          variant="ghost"
                          size="sm"
                          aria-label="Delete question option"
                          data-qa={`delete-screener-question-option-${index}`}
                          icon={<Icon as={Trash01OutlineIcon} />}
                          isDisabled={readOnly || fields.length <= 2}
                          onClick={() => remove(index)}
                        />
                      </Flex>
                    </SortableScreenerQuestionOption>
                  ))}
                </SortableContext>
              </DndContext>
            </OrderedList>

            <FormControl isInvalid={!!errors.options} mt={4} ms={10}>
              {distinctErrorMessages.map((errorMessage) => (
                <FormErrorMessage key={errorMessage}>
                  <FormErrorIcon />
                  {errorMessage}
                </FormErrorMessage>
              ))}
            </FormControl>

            {typeValue === "multi_select" && (
              <Box mt={3} ms={7}>
                <Text color="text.primary" fontSize="sm">
                  To be eligible, a participant must select at least one
                  "Qualify" option and no "Disqualify" options. Selecting any
                  "Not relevant" options won't affect their eligibility.
                </Text>
              </Box>
            )}
            <Flex justify="space-between" wrap="wrap" gap={4} mt={6}>
              <Button
                variant="outline"
                size="sm"
                onClick={addBlankOption}
                isDisabled={readOnly}
              >
                Add another choice
              </Button>
              <Checkbox {...register("shuffle_options")} isDisabled={readOnly}>
                Randomize the order of choices
              </Checkbox>
            </Flex>
          </Box>
        )}
      </Box>
    </>
  )
}
