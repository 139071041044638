export function humanizeList(
  values: ReadonlyArray<string>,
  conjunction = "and"
): string {
  if (values.length === 0) return ""
  const last = values[values.length - 1]
  if (values.length === 1) return last
  const allButLast = values.slice(0, values.length - 1)
  return [allButLast.join(", "), last].join(` ${conjunction} `)
}

/**
 * Like Lodash's `zip` but it truncate the arrays to the minimum length provided
 * rather than padding with `undefined`.
 */
export function minZip<A, B>(
  as: ReadonlyArray<A>,
  bs: ReadonlyArray<B>
): Array<[A, B]> {
  const result = [] as Array<[A, B]>
  const length = Math.min(as.length, bs.length)
  for (let i = 0; i < length; i++) {
    result.push([as[i], bs[i]])
  }
  return result
}

export function keyTrueBy(array: ReadonlyArray<number>): { [key: number]: true }
export function keyTrueBy(array: ReadonlyArray<string>): { [key: string]: true }
export function keyTrueBy(
  array: ReadonlyArray<string | number>
): Record<string, true> {
  return array.reduce<Record<string | number, true>>((acc, value) => {
    acc[value] = true
    return acc
  }, {})
}

export function isUnique<T extends string | number>(
  array: ReadonlyArray<T>
): boolean {
  const seen: Partial<Record<T, boolean>> = {}
  const duplicateIndex = array.findIndex((v) => {
    if (seen[v] === undefined) {
      seen[v] = true
      return false
    }
    return true
  })
  return duplicateIndex === -1
}
