import { AnswerTagFilter } from "../filters/answer-tags"
import { DemographicAttributeOptionIdFilter } from "../filters/attribute-exact-value"
import { CommonPathFilter } from "../filters/common-path"
import { ExactAnswerFilter } from "../filters/exact-answer"
import { HitzoneFilterCategory } from "../filters/hitzone"
import { PreferenceAnswerFilter } from "../filters/preference-answer"
import { RankedAnswerFilter } from "../filters/ranked-answer"
import { ResponseFilter } from "../filters/types"

const exactAnswerRegex = /answer\/section:[0-9]+\/question:[0-9]+\/answer/

export function isExactAnswerCategory(
  category: ResponseFilter["category"]
): category is ExactAnswerFilter["category"] {
  return exactAnswerRegex.test(category)
}

const answerTagFilterRegex = /answer\/section:[0-9]+\/question:[0-9]+\/tag/

export function isAnswerTagFilterCategory(
  category: ResponseFilter["category"]
): category is AnswerTagFilter["category"] {
  return answerTagFilterRegex.test(category)
}

const preferenceAnswerFilterRegex = /answer\/section:[0-9]+\/preference/

export function isPreferenceAnswerFilterCategory(
  category: ResponseFilter["category"]
): category is PreferenceAnswerFilter["category"] {
  return preferenceAnswerFilterRegex.test(category)
}

const demographicAttributeOptionIdFilterRegex =
  /response\/attribute:demographic_attribute_option_id\/[0-9a-zA-Z]+/

export function isDemographicAttributeOptionIdFilterCategory(
  category: ResponseFilter["category"]
): category is DemographicAttributeOptionIdFilter["category"] {
  return demographicAttributeOptionIdFilterRegex.test(category)
}

const hitzoneFilterRegex =
  /answer\/section:[0-9]+\/screenshot:[0-9]+\/screenshot-click/

export function isHitzoneFilterCategory(
  category: ResponseFilter["category"]
): category is HitzoneFilterCategory {
  return hitzoneFilterRegex.test(category)
}

const rankedAnswerFilterRegex =
  /answer\/section:[0-9]+\/question:[0-9]+\/ranked/

export function isRankedAnswerFilterCategory(
  category: ResponseFilter["category"]
): category is RankedAnswerFilter["category"] {
  return rankedAnswerFilterRegex.test(category)
}

const commonPathFilterRegex = /answer\/section:[0-9]+\/common-path/

export function isCommonPathFilterCategory(
  category: ResponseFilter["category"]
): category is CommonPathFilter["category"] {
  return commonPathFilterRegex.test(category)
}
