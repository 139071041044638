import { Center, Flex, Spinner } from "@chakra-ui/react"
import { Alert, Button } from "DesignSystem/components"
import { sortBy } from "lodash"
import React, { useState } from "react"
import { useAdminListDemographics } from "~/api/generated/usabilityhub-components"
import { CreateOrUpdateDemographicModal } from "./CreateOrUpdateDemographicModal"
import { DemographicAttributeCard } from "./DemographicAttributeCard"
import { DemographicAttribute } from "./types"

export const AdminDemographicEditor: React.FC = () => {
  const {
    data: demographicAttributes,
    isLoading,
    isError,
    error,
  } = useAdminListDemographics({})
  const [editingAttribute, setEditingAttribute] = useState<
    DemographicAttribute | "new" | null
  >(null)
  const [sortByPriority, setSortByPriority] = useState(false)

  if (isLoading)
    return (
      <Center minH="200px">
        <Spinner size="lg" />
      </Center>
    )

  if (isError)
    return <Alert status="danger" description={error.payload?.message} />

  // The API returns the list sorted by sort_order, but it's useful to be able to see them by individual_sort_order too.
  // (sort_order affects how they appear in the profile, individual_sort_order affects the order they appear
  // in the dashboard widget)
  const sortedAttributes = sortByPriority
    ? sortBy(demographicAttributes, (a) => a.individual_sort_order)
    : demographicAttributes

  return (
    <Flex direction="column" gap={4} w="max-content">
      <Flex gap={4}>
        <Button
          variant={sortByPriority ? "outline" : "primary"}
          onClick={() => setSortByPriority(false)}
        >
          Sort by profile order
        </Button>

        <Button
          variant={sortByPriority ? "primary" : "outline"}
          onClick={() => setSortByPriority(true)}
        >
          Sort by priority
        </Button>

        <Button
          variant="primary"
          onClick={() => setEditingAttribute("new")}
          ms="auto"
        >
          New
        </Button>
      </Flex>

      {sortedAttributes.map((attribute) => (
        <DemographicAttributeCard
          key={attribute.id}
          attribute={attribute}
          sortingByPriority={sortByPriority}
          onEdit={() => setEditingAttribute(attribute)}
        />
      ))}

      <CreateOrUpdateDemographicModal
        demographicAttribute={
          editingAttribute === "new" ? null : editingAttribute
        }
        isOpen={editingAttribute !== null}
        onClose={() => setEditingAttribute(null)}
      />
    </Flex>
  )
}
