import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React, { useState } from "react"

import { ProjectSelector } from "UsabilityHub/components/ProjectSelector/project-selector"
import { FunctionalModal } from "Utilities/modals/types"
import {
  useAddTestsToProject,
  useCreateProject,
} from "~/api/generated/usabilityhub-components"

interface Props {
  testIds: number[]
  onSuccess?: () => void
}
export const MoveUsabilityTestsModal: FunctionalModal<Props> = ({
  testIds,
  onClose,
  onSuccess,
}) => {
  const queryClient = useQueryClient()
  const [selectedProject, setSelectedProject] = useState<string | null>(null)

  const toast = useToast()

  const { mutateAsync: createMutation, isLoading: createLoading } =
    useCreateProject()

  const { mutateAsync: moveTestsMutation, isLoading: moveLoading } =
    useAddTestsToProject({
      onSuccess: () => {
        void queryClient.invalidateQueries(["api", "projects"])
        void queryClient.invalidateQueries(["api", "usability_tests"])
        void queryClient.invalidateQueries(["api", "studies"])
      },
    })

  const isLoading = createLoading || moveLoading

  const handleMove = async () => {
    try {
      // The button is disabled in this case so it shouldn't be possible to get here in this state
      if (!selectedProject) return

      let projectId: string

      if (selectedProject.match(/^[0-9a-fA-F]{32}$/)) {
        projectId = selectedProject
      } else {
        // If they are adding a new project (e.g. selectedProject is not a UUID), first create it via the API
        const newProject = await createMutation({
          body: {
            name: selectedProject,
          },
        })
        projectId = newProject.id
      }

      await moveTestsMutation({
        pathParams: {
          id: projectId.toString(),
        },
        body: {
          test_ids: testIds,
        },
      })

      toast({
        title: "Tests moved",
        status: "success",
        duration: 3000,
      })

      onSuccess?.()
      onClose()
    } catch (error) {
      toast({
        title: "Test move failed",
        status: "error",
        duration: 3000,
      })
    }
  }

  return (
    <Modal
      isOpen
      onClose={onClose}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            Move {testIds.length} {testIds.length === 1 ? "test" : "tests"}
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <FormControl>
              <FormLabel fontSize="sm">Project</FormLabel>
              <ProjectSelector
                value={selectedProject}
                onChange={setSelectedProject}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" disabled={isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.primary"
              isLoading={isLoading}
              isDisabled={!selectedProject}
              onClick={handleMove}
              ms={3}
            >
              Move
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
