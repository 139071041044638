import { Filter } from "Components/test-results/types/filters"

import { getFilterMeta } from "../get-filter-meta"

import { FilterFunction } from "./types"

export type CardSortFilter = Filter<
  `answer/section:${number}/card-sort`,
  "answer/card-sort",
  { cardId: number; categoryIds: number[] }
>

export const filterResponseByCardSort: FilterFunction<CardSortFilter> = ({
  filter,
  filteredRelatedData: { responseSections },
  allRelatedData: { cardSortCategoryCards },
}) => {
  const { sectionId } = getFilterMeta(filter.category)

  const section = responseSections.find(
    (rs) => rs.usability_test_section_id === sectionId
  )

  if (section === undefined) {
    // The user didn't complete this card sort.
    return false
  }

  const relatedSortData = cardSortCategoryCards.filter(
    (sortData) => sortData.response_section_id === section.id
  )

  return relatedSortData.some(
    ({
      card_sort_card_id,
      card_sort_category_id,
      card_sort_open_category_id,
    }) => {
      // When filter.value.categoryId = 0
      // it's equal to the null value of card_sort_category_id in the responses
      return (
        card_sort_card_id === filter.value.cardId &&
        filter.value.categoryIds.includes(
          Number(card_sort_open_category_id ?? card_sort_category_id)
        )
      )
    }
  )
}
