import { Box } from "@chakra-ui/react"
import React from "react"
import { useModeratedStudyContext } from "../ModeratedStudyContext"
import { CalendarConnection } from "../availability/CalendarConnection"
import { useStudyDetails } from "./StudyDetailsProvider"

export const TeamMembers: React.FC = () => {
  const { moderatedStudyId, moderatedStudy } = useModeratedStudyContext()

  const { teamMembersForm, setMutatingTeamMembers } = useStudyDetails()

  return (
    <Box
      id="team_members"
      bg="white"
      rounded="lg"
      p="8"
      borderColor="gray.200"
      borderWidth="thin"
    >
      <CalendarConnection
        moderatedStudyId={moderatedStudyId}
        members={moderatedStudy.members}
        bookingCalendar={moderatedStudy.booking_calendar}
        teamMembersForm={teamMembersForm}
        setMutatingTeamMembers={setMutatingTeamMembers}
      />
    </Box>
  )
}
