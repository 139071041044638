import { useToast } from "@chakra-ui/react"
import { useGetRecruitmentLink } from "~/api/generated/usabilityhub-components"

export const useUsabilityTestRecruitLink = (uniqueId: string) =>
  useRecruitLink(uniqueId, false)

export const useTestSetRecruitLink = (uniqueId: string) =>
  useRecruitLink(uniqueId, true)

const useRecruitLink = (uniqueId: string, isTestSetLink: boolean) => {
  const toast = useToast()

  const { data } = useGetRecruitmentLink(
    {
      queryParams: {
        [isTestSetLink ? "test_set_unique_id" : "usability_test_unique_id"]:
          uniqueId,
      },
    },
    {
      onError: () => {
        toast({
          title: "Unable to load recruitment link modal",
          status: "error",
        })
      },
    }
  )

  return data ?? null
}
