import { mean } from "lodash"

import { FigmaTaskScreenResult } from "Types"

export function getAverageScreenDuration(
  screenResults: FigmaTaskScreenResult[]
) {
  const finiteScreenDurationArray = screenResults
    .map((screenResult) => screenResult.meta.duration)
    .filter(Number.isFinite)

  return mean(finiteScreenDurationArray)
}
