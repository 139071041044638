import { TreeTest } from "Types"
import { useSectionContext } from "UsabilityHub/contexts"
import { groupBy, keyBy, take, uniqBy, values, zip } from "lodash"
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react"
import { TreeTestLink, TreeTestNode } from "~/api/generated/usabilityhubSchemas"
import { useGroupedPaths } from "./usePaths"

type TreeTestClickResult = TreeTestLink["result"]

type Shape = {
  nodes: TreeTestNode[]
  links: TreeTestLink[]
  treeTest: TreeTest | null
}

const Context = createContext<Shape>({
  nodes: [],
  links: [],
  treeTest: null,
} as Shape)

export const AggregatedResultsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { section } = useSectionContext()

  const commonPaths = useGroupedPaths()

  const aggregated = useMemo(() => {
    const allNodes = keyBy(section.tree_test_attributes?.nodes || [], "id")

    const correctNodes = (section.tree_test_attributes?.correct_nodes || [])
      .map((id) => allNodes[id])
      .filter(Boolean)

    const correctPathNodeIds = new Set<string>(
      values(allNodes)
        .filter((node) =>
          correctNodes.some((n) => node.left <= n.left && node.right >= n.right)
        )
        .map((n) => String(n.id))
    )

    const correctNodeIds = new Set(correctNodes.map((n) => String(n.id)))

    const links: TreeTestLink[] = values(
      groupBy(
        commonPaths.flatMap((path) =>
          zip(
            [null, ...take(path.nodes, path.nodes.length - 1)],
            path.nodes
          ).map(([source, target], i) => {
            const isLast = i === path.nodes.length - 1
            const result: TreeTestClickResult = target
              ? `${
                  (isLast ? correctNodeIds : correctPathNodeIds).has(
                    String(target.id)
                  )
                    ? "correct"
                    : "incorrect"
                }_${isLast ? "target" : "path"}`
              : "skipped"

            return {
              id: `${i}:${source?.id || "start"}:${target?.id || "skip"}`,
              source: i ? `${i - 1}:${source?.id || "start"}` : null,
              target: target ? `${isLast ? "end" : i}:${target.id}` : "skipped",
              result,
              value: path.participants,
            }
          })
        ),
        (p) => `${p.source}:${p.target}`
      )
    ).map((group) =>
      group.reduce((acc, link) => ({ ...acc, value: acc.value + link.value }))
    )

    const nodes: TreeTestNode[] = values(
      uniqBy(
        commonPaths.flatMap((path) =>
          zip(
            [null, ...take(path.nodes, path.nodes.length - 1)],
            path.nodes
          ).map(([_, target], i) => {
            const isLast = i === path.nodes.length - 1
            const result: TreeTestClickResult = target
              ? `${
                  (isLast ? correctNodeIds : correctPathNodeIds).has(
                    String(target.id)
                  )
                    ? "correct"
                    : "incorrect"
                }_${isLast ? "target" : "path"}`
              : "skipped"

            const targetNode = (target && allNodes[target.id]) || null

            return {
              ...(targetNode
                ? { ...targetNode }
                : { parent_id: null, label: "Pass" }),
              id: target ? `${isLast ? "end" : i}:${target.id}` : "skipped",
              result,
            }
          })
        ),
        "id"
      )
    )

    return { links, nodes, treeTest: section.tree_test_attributes }
  }, [commonPaths, section])

  return <Context.Provider value={aggregated}>{children}</Context.Provider>
}

export const useAggregatedResults = () => useContext(Context)
