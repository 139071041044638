import { Box, Flex, Spinner, useToast } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Navigate } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"

import { NoPermissionWarning } from "Components/not-allowed-warning/not-allowed-warning"
import { DemographicsContextProvider } from "Components/test-results/context/demographics"
import { useCanCurrentUserDo } from "Hooks/use-can-current-user-do"
import { PageContent, PageMain } from "Shared/components/Page/Page"
import { useGetVariationSet } from "~/api/generated/usabilityhub-components"

import { ROUTES } from "~/usabilityhub/views/routes"
import { TestSetRecruit } from "./TestSetRecruit"

export function RecruitTestSetPage() {
  const { testSetId } = useTypedParams(ROUTES.TEST_SET.RECRUIT)

  const canUserManageTests = useCanCurrentUserDo("manage_tests")
  const { data, isLoading, error } = useGetVariationSet(
    { pathParams: { uniqueId: testSetId } },
    { enabled: canUserManageTests }
  )
  const testSet = data?.variation_set
  const toast = useToast()

  useEffect(() => {
    if (error) {
      toast({
        title:
          error.payload.message ??
          "Lyssna has encountered an error while fetching data",
        status: "error",
      })
    }
  }, [error])

  if (error) {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <PageMain>
      <Helmet>
        <title>{testSet ? `Set • ${testSet.name}` : "Loading"}</title>
      </Helmet>
      <PageContent maxW="6xl">
        <Box my={30}>
          {!canUserManageTests && (
            <Box maxW={1024} mx="auto" my="7">
              <NoPermissionWarning permission="manage_account" />
            </Box>
          )}
          {isLoading && (
            <Flex
              mt="32"
              justifyContent="center"
              alignItems="center"
              direction="column"
              gap="4"
            >
              <Spinner size="lg" thickness="4px" color="gray.500" />
              Fetching variation set...
            </Flex>
          )}
          {canUserManageTests && !isLoading && testSet && (
            <DemographicsContextProvider>
              <TestSetRecruit testSet={testSet} />
            </DemographicsContextProvider>
          )}
        </Box>
      </PageContent>
    </PageMain>
  )
}
