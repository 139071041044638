import { Avatar, AvatarProps, Tooltip } from "@chakra-ui/react"
import React from "react"

// The parent <AvatarGroup> passes props directly to its children, which does not work
// well when the immediate child is a <Tooltip>. To work around this let's manually pass
// all the tooltip props to the <Avatar> directly.
export const AvatarWithTooltip: React.FC<
  { name: string; avatarUrl: string } & AvatarProps
> = ({ name, avatarUrl, ...rest }) => {
  return (
    <Tooltip label={name}>
      <Avatar showBorder name={name} src={avatarUrl} size="xs" {...rest} />
    </Tooltip>
  )
}
