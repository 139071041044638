import React from "react"

import { createErrorContent } from "./create-error-content"

export function abandonedErrorContent() {
  return createErrorContent(
    <>
      <p className="para">
        <strong>Response abandoned</strong>
      </p>
      <p className="para">
        You abandoned this response from a different tab. It can no longer be
        completed.
      </p>
    </>
  )
}
