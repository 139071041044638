import { useDispatch } from "react-redux"

import {
  addLocalComment,
  deleteLocalComment,
  editLocalComment,
} from "Redux/reducers/test-builder/action-creators/localComments"

import { ClientCommentFragment } from "./../redux/reducers/test-builder/reducer"

// The server (and tanstack-query) is the source of the truth for comments, but if commenting on
// a test (or section/question) that hasn't been saved yet, we need to keep track of the comments locally.
// This hook provides a similar interface to useTestBuilderCommentCache but will instead store the comments
// as ClientCommentFragment objects in Redux.
export const useTestBuilderLocalComments = () => {
  const dispatch = useDispatch()

  const addComment = (newComment: ClientCommentFragment) => {
    dispatch(addLocalComment(newComment))
  }

  const updateComment = (
    editedComment: Omit<ClientCommentFragment, "entity">
  ) => {
    dispatch(editLocalComment(editedComment))
  }

  const deleteComment = (commentId: string) => {
    dispatch(deleteLocalComment(commentId))
  }

  return { addComment, updateComment, deleteComment }
}
