import React from "react"

import { QuestionResultsList } from "Components/test-results/question-results/question-results-list"
import { ExpandableSectionScreenshot } from "Components/test-results/section-results/expandable-section-screenshot"
import {
  useDeviceFramesForSectionContext,
  useSectionContext,
} from "UsabilityHub/contexts"

import { SectionResultsCard } from "./SectionResultsCard"

export const DesignSurveySectionResultsCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { section } = useSectionContext()

  // There's always only one screenshot for the DesignQuestions section type.
  const sectionScreenshot = section.section_screenshots[0]
  const deviceFrame = useDeviceFramesForSectionContext(section)

  return (
    <SectionResultsCard>
      <ExpandableSectionScreenshot
        sectionScreenshot={sectionScreenshot}
        deviceFrame={deviceFrame}
      />
      <QuestionResultsList />
    </SectionResultsCard>
  )
}
