import { Filter } from "Components/test-results/types/filters"

import { getFilterMeta } from "../get-filter-meta"

import { FilterFunction } from "./types"

export type RankedAnswerFilter = Filter<
  `answer/section:${number}/question:${number}/ranked`,
  "answer/ranked",
  {
    answer: string
    ranks: number[]
  }
>

export const filterResponseByRankedAnswer: FilterFunction<
  RankedAnswerFilter
> = ({ filter, filteredRelatedData: { responseAnswers } }) => {
  const { answer, ranks } = filter.value
  const { questionId } = getFilterMeta(filter.category)

  const responseAnswer = responseAnswers.find(
    (a) => a.usability_test_section_question_id === questionId
  )

  if (!responseAnswer) {
    // The user didn't answer this question at all, so they can't have given
    // the correct answer.
    return false
  }

  if (ranks.some((i) => responseAnswer.answers[i] === answer)) {
    return true
  }

  return false
}
