import { Box, Flex, FlexProps, Grid, Image } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import linkIllustration from "Images/app-illustrations/lyssna/recruit-link.png"
import panelIllustration from "Images/app-illustrations/lyssna/recruit-panel.png"
import React, { PropsWithChildren } from "react"

export const RecruitMethodSelection: React.FC<
  PropsWithChildren & FlexProps
> = ({ children, ...props }) => (
  <Flex flexDirection={["column", null, "row"]} gap={4} {...props}>
    {children}
  </Flex>
)

type Props = {
  method: "panel" | "link"
  bodyContent: React.ReactNode
  action?: React.ReactNode
  cornerBadge?: React.ReactNode
}

export const RecruitMethodCard = ({
  method,
  bodyContent,
  action,
  cornerBadge,
}: Props) => (
  <Grid
    pos="relative"
    flexBasis="50%"
    templateRows={action ? "auto auto 1fr auto" : "auto auto 1fr"}
    gap={6}
    p={6}
    bg="ds.surface.raised.resting"
    shadow="ds.raised"
    // TODO replace this with the appropriate DS token once we have it
    rounded="16px"
    data-qa={`recruit-from-${method}-card`}
  >
    <Flex>
      <Flex>
        <Image
          src={method === "link" ? linkIllustration : panelIllustration}
          w={16}
        />
      </Flex>
      {cornerBadge}
    </Flex>
    <Box>
      <Heading as="h2" textStyle="ds.display.primary">
        {method === "link"
          ? "Recruit with a Lyssna link"
          : "Recruit from our panel"}
      </Heading>
    </Box>
    <Box>{bodyContent}</Box>
    {action && <Box>{action}</Box>}
  </Grid>
)
