import { Box } from "@chakra-ui/react"
import React from "react"
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd"
import { useDispatch } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { moveScreenshot } from "Redux/reducers/test-builder-form/action-creators/screenshots"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"

import { MediaRow } from "./MediaRow/MediaRow"
import { SectionMediaProvider } from "./SectionMediaContext"

export const SectionMediaEditor: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useDispatch<Dispatch>()
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    dispatch(
      moveScreenshot(
        sectionIndex,
        result.source.index,
        result.destination.index
      )
    )
  }
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable
        isDropDisabled={readOnly}
        droppableId={section._clientId}
        type="screenshot"
      >
        {(droppable) => (
          // eslint-disable-next-line @typescript-eslint/unbound-method
          <Box ref={droppable.innerRef} {...droppable.droppableProps}>
            {section.section_screenshots.map(
              (sectionMedia, sectionMediaIndex) => {
                return (
                  <SectionMediaProvider
                    key={sectionMedia._clientId}
                    value={{ sectionMedia, sectionMediaIndex }}
                  >
                    <MediaRow />
                  </SectionMediaProvider>
                )
              }
            )}
            {droppable.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}
