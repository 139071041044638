import {
  Box,
  Flex,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react"
import { LinkButton } from "Components/button/link-button"
import { NoPermissionWarning } from "Components/not-allowed-warning/not-allowed-warning"
import { DISPLAY_STRING_BY_VALUE } from "Constants/point-transactions"
import { useCanCurrentUserDo } from "Hooks/use-can-current-user-do"
import { useInfiniteScroll } from "Hooks/use-infinite-scroll"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { getDateString } from "Utilities/date-formats"
import React, { useCallback, useState } from "react"
import {
  ListPointTransactionsResponse,
  fetchListPointTransactions,
} from "~/api/generated/usabilityhub-components"
import { Subnav } from "../Subnav"

type PointTransaction = ListPointTransactionsResponse[number]

const HistoryPageContent: React.FC = () => {
  const [transactions, setTransactions] = useState<PointTransaction[]>([])
  const [nextPage, setNextPage] = useState<number | "finished">(1)
  const [isLoading, setIsLoading] = useState(false)

  const currentAccount = useCurrentAccount()
  const creditCount = currentAccount.total_points

  const loadNextPage = useCallback(async () => {
    if (isLoading) return
    if (nextPage === "finished") return

    setIsLoading(true)

    const data = await fetchListPointTransactions({
      queryParams: { page: nextPage },
    })

    setNextPage((n) =>
      n === "finished" || data.length === 0 ? "finished" : n + 1
    )
    setTransactions((oldTransactions) => oldTransactions.concat(data))
    setIsLoading(false)
  }, [nextPage, isLoading])

  const infiniteScrollerRef = useInfiniteScroll<HTMLDivElement>(loadNextPage)

  const canUserManageTests = useCanCurrentUserDo("manage_account")
  if (!canUserManageTests) {
    return (
      <Box maxW={1024} mx="auto" my="7">
        <NoPermissionWarning permission="manage_account" />
      </Box>
    )
  }

  return (
    <>
      <Flex direction="column" align="center">
        <Heading size="lg" fontWeight="normal">
          History
        </Heading>
        <Text color="gray.500" mt={4}>
          You currently have {creditCount} credits
        </Text>
        <LinkButton href="/credits/purchase" colorScheme="brand.primary" mt={5}>
          Buy more credits
        </LinkButton>
      </Flex>

      <TableContainer bg="white" borderWidth={1} rounded="md" mt={10}>
        <Table>
          <Tbody>
            {transactions.map((transaction) => {
              const totalPoints = transaction.total_points
              return (
                <Tr key={transaction.id}>
                  <Td
                    fontWeight="semibold"
                    color={totalPoints < 0 ? "red.400" : "green.400"}
                    px={10}
                    py={6}
                  >
                    {totalPoints < 0 ? "-" : "+"}
                    {Math.abs(totalPoints)}
                  </Td>
                  <Td color="gray.500" px={10} py={6}>
                    {transaction.description ||
                      DISPLAY_STRING_BY_VALUE[transaction.reason]}
                  </Td>
                  <Td color="gray.500" textAlign="right" px={10} py={6}>
                    {getDateString(transaction.created_at)}
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {isLoading && (
        <Flex justify="center" mt={10}>
          <Spinner />
        </Flex>
      )}

      <div ref={infiniteScrollerRef} />
    </>
  )
}

export const HistoryRoute: React.FC = () => {
  return (
    <Page title="Purchase credits">
      <UsabilityHubNavbar />
      <Subnav />
      <PageMain>
        <PageContent maxW="6xl">
          <HistoryPageContent />
        </PageContent>
      </PageMain>
    </Page>
  )
}
