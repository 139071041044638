import { Flex, Img, Text } from "@chakra-ui/react"
import { assertNever } from "Components/filter-controls/utils"
import { Heading } from "DesignSystem/components"
import feedbackNegativeIllustration from "Images/app-illustrations/feedback-negative.png"
import feedbackNeutralIllustration from "Images/app-illustrations/feedback-neutral.png"
import feedbackPositiveIllustration from "Images/app-illustrations/feedback-positive.png"
import { cq } from "Shared/helpers/containerQuerySx"
import React, { PropsWithChildren } from "react"
import { BREAKPOINT_MAX } from "../breakpoints"
import { RatingFormSentiment } from "./types"

export type FeedbackText = {
  primaryText: React.ReactNode
  secondaryText: React.ReactNode
}

type Props = PropsWithChildren<
  FeedbackText & {
    sentiment: RatingFormSentiment
  }
>

export const FeedbackContent: React.FC<Props> = ({
  children,
  primaryText,
  secondaryText,
  sentiment,
}) => {
  return (
    <Flex grow="1" gap={6} alignItems="stretch">
      <Flex grow="1" flexDirection="column" gap={3} py={2}>
        <Flex flexDirection="column" gap={2}>
          <Heading textStyle="ds.heading.primary" as="h3">
            {primaryText}
          </Heading>
          <Text textStyle="ds.paragraph.primary">{secondaryText}</Text>
        </Flex>
        {children}
      </Flex>
      <Flex
        alignSelf="center"
        basis="11.25rem"
        maxH="7.75rem"
        flexShrink={0}
        alignItems="center"
        justifyContent="center"
        display="none"
        sx={cq(BREAKPOINT_MAX, { display: "flex" })}
      >
        <FeedbackIllustration sentiment={sentiment ?? "neutral"} />
      </Flex>
    </Flex>
  )
}

const Illustration: React.FC<{ src: string }> = ({ src }) => (
  <Img src={src} objectFit="contain" maxH="100%" role="presentation" />
)

const FeedbackIllustration: React.FC<{ sentiment: RatingFormSentiment }> = ({
  sentiment,
}) => {
  switch (sentiment) {
    case "positive":
      return <Illustration src={feedbackPositiveIllustration} />
    case "neutral":
      return <Illustration src={feedbackNeutralIllustration} />
    case "negative":
      return <Illustration src={feedbackNegativeIllustration} />
    default:
      assertNever(sentiment)
      return feedbackNeutralIllustration
  }
}
