import { useToast } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { debounce } from "lodash"
import { useCallback, useMemo } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { useUpdateDeviceRequirement } from "~/api/generated/usabilityhub-components"
import { ModeratedStudy } from "~/api/generated/usabilityhubSchemas"

import {
  DEVICE_PERIPHERAL_OPTIONS,
  DEVICE_TYPE_OPTIONS,
} from "Shared/constants/testTakingDeviceRequirements"
import { FORM_SAVE_DEBOUNCE_TIME, GENERIC_FORM_ERROR_TOAST } from "./common"

const DeviceTypesSchema = Yup.mixed<(typeof DEVICE_TYPE_OPTIONS)[number]>()
  .oneOf(DEVICE_TYPE_OPTIONS)
  .defined()
  .required()

const DevicePeripheralsSchema = Yup.mixed<
  (typeof DEVICE_PERIPHERAL_OPTIONS)[number]
>()
  .oneOf(DEVICE_PERIPHERAL_OPTIONS)
  .defined()
  .required()

const DeviceRequirementsFormSchema = Yup.object().shape({
  device_types: Yup.array(DeviceTypesSchema)
    .min(1, "At least one type of device is required")
    .required(),
  device_peripherals: Yup.array(DevicePeripheralsSchema).min(0).required(),
})

export type DeviceRequirementFormFields = Yup.InferType<
  typeof DeviceRequirementsFormSchema
>

export const useDeviceRequirementForm = (moderatedStudy: ModeratedStudy) => {
  const toast = useToast()

  const form = useForm({
    resolver: yupResolver(DeviceRequirementsFormSchema),
    defaultValues: {
      device_types: moderatedStudy.device_requirement.device_types,
      device_peripherals: moderatedStudy.device_requirement.device_peripherals,
    },
  })

  const { trigger, handleSubmit, reset, getValues } = form

  const { mutateAsync: editDeviceRequirement } = useUpdateDeviceRequirement({
    onSuccess: () => reset(getValues(), { keepValues: true }),
    onError: () => toast(GENERIC_FORM_ERROR_TOAST),
  })

  const onDeviceRequirementSubmit: SubmitHandler<DeviceRequirementFormFields> =
    useCallback(
      async (data) => {
        // Because of the debounce we have to manually revalidate here
        if (!(await trigger())) return

        await editDeviceRequirement({
          body: data,
          pathParams: { id: moderatedStudy.device_requirement.id },
        })
      },
      [editDeviceRequirement, moderatedStudy.device_requirement.id, trigger]
    )

  const debouncedDeviceSubmit = useMemo(
    () =>
      debounce(
        handleSubmit(onDeviceRequirementSubmit),
        FORM_SAVE_DEBOUNCE_TIME
      ),
    [handleSubmit, onDeviceRequirementSubmit]
  )

  return {
    ...form,
    handleSubmit: debouncedDeviceSubmit,
  }
}
