import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react"
import React from "react"

import Constants from "Constants/shared.json"
import { PlanDrawerFeatures } from "Types"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"

type RecruitmentUpgradeBanner = React.FC<
  React.PropsWithChildren<{
    allowTestUpgrades: boolean
    creditCount: number
    openUpgradeModal: () => void
    paidFeatures: PlanDrawerFeatures
  }>
>

export const RecruitmentUpgradeBanner: RecruitmentUpgradeBanner = ({
  allowTestUpgrades,
  creditCount,
  openUpgradeModal,
  paidFeatures,
}) => {
  const upgradeCost =
    creditCount > 0
      ? `${Constants.TEST_UPGRADE_COST_IN_CREDITS} credits`
      : `$${Constants.TEST_UPGRADE_COST_IN_CREDITS} USD`

  return (
    <Flex mx={6} gap={3}>
      <Alert status="info" variant="withButton">
        <AlertIcon />
        <AlertTitle>You{"\u2019"}ve turned on paid features</AlertTitle>
        <AlertDescription>
          <Flex direction="column" color="gray.700" align="flex-start">
            <Text>
              You{"\u2019"}ll need to upgrade to enable the recruitment link.
            </Text>
            {allowTestUpgrades && (
              <Link fontSize="xs" as="button" onClick={openUpgradeModal} mt={1}>
                Upgrade single test for {upgradeCost}
              </Link>
            )}
          </Flex>
        </AlertDescription>
        <PlanDrawerTrigger
          requestedFeatures={paidFeatures}
          source="test_recruitment_settings"
          colorScheme="brand.primary"
        >
          Upgrade your plan
        </PlanDrawerTrigger>
      </Alert>
    </Flex>
  )
}
