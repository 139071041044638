import { useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { interviewOrderPlacedGoogle } from "JavaScripts/analytics/google"
import { useDebouncedValue } from "Shared/hooks/useDebouncedValue"
import { useMemo, useState } from "react"
import { useNavigate } from "react-router"
import {
  useCreateModeratedStudyOrder,
  useGetModeratedStudyOrderQuote,
} from "~/api/generated/usabilityhub-components"
import { useOrderForm } from "../NewOrderPage/OrderFormProvider"
import { ROUTES } from "../routes"

const QUOTE_DEBOUNCE_TIME_MS = 300

export const useInterviewOrder = ({
  moderatedStudyId,
  handpickPanelists,
  isConfirming,
  setIsConfirming,
}: {
  moderatedStudyId: string
  handpickPanelists: boolean
  isConfirming: boolean
  setIsConfirming: (isConfirming: boolean) => void
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const navigate = useNavigate()

  const { ageRange, targetLocations, selectedOptionIds, numParticipants } =
    useOrderForm()

  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false)

  const { mutate: createOrder, isLoading: createOrderLoading } =
    useCreateModeratedStudyOrder({
      onSuccess: () => {
        void queryClient.invalidateQueries(["api", "account", "total_credits"])

        toast({
          title: "Your order has been created successfully.",
          status: "success",
        })

        navigate(
          ROUTES.INTERVIEW.RECRUIT.buildPath({
            moderatedStudyId,
          })
        )
      },
      onError: (e) => {
        toast({
          title:
            e.payload.message ?? "There was a problem creating your order.",
          status: "error",
        })
      },
    })

  // These need to be referentially stable so we can debounce them successfully.
  const stableParams = useMemo(
    () => ({
      min_age: ageRange[0],
      max_age: ageRange[1],
      target_locations: targetLocations,
      demographic_attribute_option_ids: selectedOptionIds,
      requested_response_count: numParticipants,
      handpick_panelists: handpickPanelists,
    }),
    [
      ageRange,
      targetLocations,
      selectedOptionIds,
      numParticipants,
      handpickPanelists,
    ]
  )

  // Params update instantly to keep the UI feeling snappy, but we have a debounced copy
  // to use in the query key so we don't spam the API.
  const debouncedParams = useDebouncedValue(
    stableParams,
    QUOTE_DEBOUNCE_TIME_MS
  )

  const { data: latestQuote } = useGetModeratedStudyOrderQuote(
    {
      pathParams: { moderatedStudyId },
      body: debouncedParams,
    },
    {
      keepPreviousData: true,
    }
  )

  const handleSubmit = () => {
    if (!latestQuote) throw new Error("No quote available")

    if (!isConfirming && latestQuote.credits_to_be_purchased > 0) {
      setIsConfirming(true)
      return
    }

    // Prevent double-click
    if (isSubmittingOrder) return
    setIsSubmittingOrder(true)

    createOrder({
      pathParams: { moderatedStudyId },
      body: {
        demographic_target_id: latestQuote.demographic_target_id,
        requested_response_count: numParticipants,
        handpick_panelists: handpickPanelists,
      },
    })

    interviewOrderPlacedGoogle()
  }

  return { latestQuote, createOrderLoading, handleSubmit }
}
