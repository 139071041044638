// JsFromRoutes CacheKey 0e4014dd5a49f864e0c9fe644232f534
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  viewable: /* #__PURE__ */ definePathHelper('get', '/api/figma_file_versions/:figma_file_version_id/viewable'),
  images: /* #__PURE__ */ definePathHelper('get', '/api/figma_file_versions/:figma_file_version_id/images'),
  image: /* #__PURE__ */ definePathHelper('get', '/api/figma_file_versions/:figma_file_version_id/image/:figma_node_id'),
  create: /* #__PURE__ */ definePathHelper('post', '/api/figma_file_versions'),
}
