import { useCallback, useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import { createExactAnswerFilter } from "../filters/create-filters/exact-answer-filter"
import { getFilterUtilityFunctions } from "../filters/get-filter-utility-functions"

const { isFilter, isFiltered, addFilter, removeFilter, toggleFilter } =
  getFilterUtilityFunctions(createExactAnswerFilter)

export function useExactAnswersFilter(
  sectionId: number,
  questionId: number,
  answer: string
) {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handleAddAnswer = () => {
    setTestResultsFilters((filters) =>
      addFilter(filters, sectionId, questionId, answer)
    )
  }

  const handleRemoveAnswer = () => {
    setTestResultsFilters((filters) =>
      removeFilter(filters, sectionId, questionId, answer)
    )
  }

  const handleToggleFilter = useCallback(() => {
    setTestResultsFilters((filters) =>
      toggleFilter(filters, sectionId, questionId, answer)
    )
  }, [])

  return {
    isFiltered: isFiltered(testResultsFilters, sectionId, questionId, answer),
    isFilter: isFilter(testResultsFilters, sectionId, questionId, answer),
    addAnswerFilter: handleAddAnswer,
    removeAnswerFilter: handleRemoveAnswer,
    toggleAnswerFilter: handleToggleFilter,
  }
}
