import { useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import { v4 as uuidv4 } from "uuid"
import {
  useDeleteScreenerQuestion,
  useDisableModeratedStudyScreener,
  useEnableModeratedStudyScreener,
  useFindOrCreateModeratedStudyScreenerQuestion,
  useGetModeratedStudy,
} from "~/api/generated/usabilityhub-components"
import {
  ModeratedStudy,
  ScreenerQuestion,
} from "~/api/generated/usabilityhubSchemas"
import { getUpdateScreenerQuestionRequestBody } from "./getUpdateScreenerQuestionRequestBody"

type Props = {
  moderatedStudyId: string
  setIsScreenerEnabled: (value: boolean) => void
  onDisable: () => void
}

export const useModeratedStudyScreenerQuestions = ({
  moderatedStudyId,
  setIsScreenerEnabled,
  onDisable,
}: Props) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { invalidateModeratedStudyQuery } = useModeratedStudyContext()
  const { data: moderatedStudy } = useGetModeratedStudy({
    pathParams: {
      moderatedStudyId,
    },
  })

  const showError = (operation: string) => {
    toast({
      title: "Error",
      description: `The screener question could not be ${operation}`,
      status: "error",
    })
  }

  const { mutateAsync: enableScreener } = useEnableModeratedStudyScreener({
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      queryClient.cancelQueries({
        queryKey: ["api", "moderated_studies", moderatedStudyId],
      })
      setIsScreenerEnabled(true)
    },
    onSuccess: invalidateModeratedStudyQuery,
    onError: () =>
      toast({
        title: "Error",
        description: "The screener could not be enabled – please try again",
        status: "error",
      }),
  })

  const { mutateAsync: disableScreener } = useDisableModeratedStudyScreener({
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      queryClient.cancelQueries({
        queryKey: ["api", "moderated_studies", moderatedStudyId],
      })
      setIsScreenerEnabled(false)
    },
    onSuccess: invalidateModeratedStudyQuery,
    onError: () =>
      toast({
        title: "Error",
        description: "The screener could not be disabled – please try again",
        status: "error",
      }),
  })

  const onScreenerToggle = async (value: boolean) => {
    if (value) {
      await enableScreener({
        pathParams: { moderatedStudyId },
      })

      if (moderatedStudy?.screener_questions.length === 0)
        appendScreenerQuestion()
    } else {
      await disableScreener({
        pathParams: { moderatedStudyId },
      })

      onDisable()
    }
  }

  const { mutateAsync: findOrCreateModeratedScreenerQuestion } =
    useFindOrCreateModeratedStudyScreenerQuestion({
      onMutate: async (variables) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({
          queryKey: ["api", "moderated_studies", moderatedStudyId],
        })

        const newPosition = variables.body.position

        // Optimistic update
        setScreenerQuestions((prevQuestions) => {
          // Mimics what the backend does; removes the current question from
          // the array, then inserts it in the new position
          const currQuestion = prevQuestions.find(
            (q) => q.id === variables.body.id
          )
          if (!currQuestion) return prevQuestions

          const newQuestions = prevQuestions.filter(
            (q) => q.id !== variables.body.id
          )
          newQuestions.splice(newPosition, 0, currQuestion)
          return newQuestions
        })
      },
      onSuccess: () => {
        return queryClient.invalidateQueries(
          ["api", "moderated_studies", moderatedStudyId],
          { exact: true }
        )
      },
      onError: () => showError("updated"),
    })

  const { mutate: deleteScreenerQuestion } = useDeleteScreenerQuestion({
    onMutate: async (variables) => {
      if (!moderatedStudy) return

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["api", "moderated_studies", moderatedStudyId],
      })

      // Optimistic update
      setScreenerQuestions((prevQuestions) =>
        prevQuestions.filter(
          (q) => q.id !== variables.pathParams.screenerQuestionId
        )
      )
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(
        ["api", "moderated_studies", moderatedStudyId],
        { exact: true }
      )
    },
    onError: (error) => {
      if (error.status === 404) {
        return queryClient.invalidateQueries(
          ["api", "moderated_studies", moderatedStudyId],
          { exact: true }
        )
      } else {
        showError("deleted")
      }
    },
  })

  const setScreenerQuestions = (
    screenerUpdater: (
      previousScreeners: ScreenerQuestion[]
    ) => ScreenerQuestion[]
  ) => {
    queryClient.setQueryData<ModeratedStudy>(
      ["api", "moderated_studies", moderatedStudyId],
      (moderatedStudy) => {
        if (!moderatedStudy) return
        return {
          ...moderatedStudy,
          updated_at: new Date().toISOString(),
          screener_questions: screenerUpdater(
            moderatedStudy.screener_questions
          ),
        }
      }
    )
  }

  const appendScreenerQuestion = async () => {
    if (!moderatedStudy) return
    const numberOfQuestions = moderatedStudy.screener_questions.length
    const newPosition = numberOfQuestions

    const newScreenerQuestion: ScreenerQuestion = {
      id: uuidv4(),
      type: "single_select",
      text: "New question",
      shuffle_options: false,
      options: [
        {
          id: uuidv4(),
          value: "Choice 1",
          rule: "qualify",
        },
        {
          id: uuidv4(),
          value: "Choice 2",
          rule: "disqualify",
        },
      ],
    }

    // Cancel any outgoing refetches
    // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({
      queryKey: ["api", "moderated_studies", moderatedStudyId],
    })

    // Optimistic update
    setScreenerQuestions((prevQuestions) => [
      ...prevQuestions,
      newScreenerQuestion,
    ])

    await findOrCreateModeratedScreenerQuestion({
      pathParams: {
        moderatedStudyId: moderatedStudyId,
      },
      body: getUpdateScreenerQuestionRequestBody(
        newScreenerQuestion,
        newPosition
      ),
    })
  }

  const removeScreenerQuestion = (screenerQuestionId: string) => {
    if (!moderatedStudy) return
    deleteScreenerQuestion({
      pathParams: {
        screenerQuestionId: screenerQuestionId,
      },
      body: {
        moderated_study_id: moderatedStudy.id,
      },
    })
  }

  const updateScreenerQuestion = async (
    updatedScreenerQuestion: ScreenerQuestion,
    position: number
  ) => {
    await findOrCreateModeratedScreenerQuestion({
      pathParams: {
        moderatedStudyId: moderatedStudyId,
      },
      body: getUpdateScreenerQuestionRequestBody(
        updatedScreenerQuestion,
        position
      ),
    })
  }

  const duplicateScreenerQuestion = async (
    screenerQuestion: ScreenerQuestion
  ) => {
    if (!moderatedStudy) return
    const numberOfQuestions = moderatedStudy.screener_questions.length
    const newPosition = numberOfQuestions

    const newScreenerQuestion = {
      ...screenerQuestion,
      id: uuidv4(),
      options: screenerQuestion.options?.map((option) => ({
        ...option,
        id: uuidv4(),
      })),
    }

    // Cancel any outgoing refetches
    // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({
      queryKey: ["api", "moderated_studies", moderatedStudyId],
    })

    // Optimistic update
    setScreenerQuestions((prevQuestions) => [
      ...prevQuestions,
      newScreenerQuestion,
    ])

    await findOrCreateModeratedScreenerQuestion({
      pathParams: {
        moderatedStudyId: moderatedStudyId,
      },
      body: getUpdateScreenerQuestionRequestBody(
        newScreenerQuestion,
        newPosition
      ),
    })
  }

  return {
    screenerQuestions: moderatedStudy?.screener_questions ?? [],
    appendScreenerQuestion,
    removeScreenerQuestion,
    updateScreenerQuestion,
    duplicateScreenerQuestion,
    onScreenerToggle,
  } as const
}
