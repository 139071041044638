import { GridItem } from "@chakra-ui/react"
import { isExternalStudy } from "UsabilityHub/utils/isExternalStudy"
import React from "react"
import { useTestResultsSummary } from "../useTestResultsSummary"
import { ExportCSVButton } from "./ExportCSVButton"
import { RateResponsesButton } from "./RateResponsesButton"
import { ShareViaLinkButton } from "./ShareViaLinkButton"
import { SummaryActionsGrid } from "./SummaryActionsGrid"

export const SummaryActions: React.FC<{
  handleShareClick: () => void
  handleRateClick: () => void
  isRatingFormVisible: boolean
  hasRatedAlready: boolean
  usabilityTestUniqueId: string
  usabilityTestPrivateId: string
  filteredResponseIds: number[]
  accountCanExportCsv: boolean
  showRatingButton: boolean
}> = ({
  handleShareClick,
  handleRateClick,
  isRatingFormVisible,
  hasRatedAlready,
  usabilityTestUniqueId,
  usabilityTestPrivateId,
  filteredResponseIds,
  accountCanExportCsv,
  showRatingButton,
}) => {
  const { usabilityTest } = useTestResultsSummary()
  const isExternal = isExternalStudy(usabilityTest)

  return (
    <SummaryActionsGrid>
      {isExternal ? (
        // Empty grid items to avoid the rate button being extraordinarily wide
        <>
          <GridItem display="grid"></GridItem>
          <GridItem display="grid"></GridItem>
        </>
      ) : (
        <>
          <GridItem display="grid">
            <ShareViaLinkButton onClick={handleShareClick} />
          </GridItem>
          <GridItem display="grid">
            <ExportCSVButton
              usabilityTestPrivateId={usabilityTestPrivateId}
              usabilityTestUniqueId={usabilityTestUniqueId}
              filteredResponseIds={filteredResponseIds}
              accountCanExportCsv={accountCanExportCsv}
            />
          </GridItem>
        </>
      )}

      {showRatingButton && (
        <GridItem display="grid">
          <RateResponsesButton
            hasRatedAlready={hasRatedAlready}
            onClick={handleRateClick}
            isRatingFormVisible={isRatingFormVisible}
          />
        </GridItem>
      )}
    </SummaryActionsGrid>
  )
}
