import { UseToastOptions, useToast } from "@chakra-ui/react"
import copy from "copy-to-clipboard"

type CopyTextProps = {
  text: string
  toast?: UseToastOptions
}

/**
 * - Copies text to the clipboard.
 * - Shows an optional toast if you pass in `UseToastOptions`
 * (same props as a regular Chakra toast).
 */
export const useCopyTextWithToast = () => {
  const chakraToast = useToast()

  const copyText = ({ text, toast }: CopyTextProps) => {
    if (!text) return
    copy(text, { format: "text/plain" })
    if (toast) {
      chakraToast(toast)
    }
  }

  return copyText
}
