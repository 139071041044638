import { Icon } from "@chakra-ui/react"
import { HomeIcon } from "@heroicons/react/outline"
import React from "react"

import { RoutedLink } from "Shared/components/Links/RoutedLink"

import { Button } from "DesignSystem/components"
import { ROUTES } from "UsabilityHub/views/routes"

type DashboardButton = React.FC<React.PropsWithChildren<unknown>>

export const DashboardButton: DashboardButton = () => {
  return (
    <Button
      as={RoutedLink}
      to={ROUTES.DASHBOARD.path}
      variant="secondary"
      leftIcon={<Icon as={HomeIcon} />}
    >
      Dashboard
    </Button>
  )
}
