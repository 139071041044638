import { Heading, Text } from "@chakra-ui/layout"
import { Flex } from "@chakra-ui/react"
import { QuestionResultsList } from "Components/test-results/question-results/question-results-list"
import { TestCardDivider } from "UsabilityHub/components/TestCard/TestCard"
import { SectionResultsSectionText } from "UsabilityHub/components/TestResults/SectionResultsLabels"
import { useSectionContext } from "UsabilityHub/contexts"
import React from "react"
import { RecordingsTab } from "./Recordings/RecordingsTab"
import { SectionResultsCard } from "./SectionResultsCard"

export const LiveWebsiteTestSectionResultsCard: React.FC = () => {
  const { section } = useSectionContext()

  if (!section.live_website_test) {
    throw new TypeError("Live website test not found")
  }

  const tasks = section.live_website_test.live_website_test_tasks

  return (
    <SectionResultsCard>
      <SectionResultsSectionText />

      {/* Recordings exist per section at the moment, but will be per-task in future. */}
      <RecordingsTab />

      {tasks.map((task, taskIndex) => {
        // Find IDs of all questions attached to this task so we can filter the QuestionResultsList to just those answers.
        const questionIdAllowlist = task.live_website_test_task_questions.map(
          (q) => q.usability_test_section_question_id
        )

        return (
          <Flex key={task.id} direction="column" gap={4}>
            <TestCardDivider />

            <Heading as="h3">Task {taskIndex + 1}</Heading>

            <Text>{task.instructions}</Text>

            <QuestionResultsList questionIdAllowlist={questionIdAllowlist} />
          </Flex>
        )
      })}
    </SectionResultsCard>
  )
}
