import {
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  Tooltip,
  useBoolean,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import Constants from "Constants/shared.json"
import { Alert, Button, Heading } from "DesignSystem/components"
import { Radio } from "DesignSystem/components/Radio"
import teamsIcon from "Images/teams-logo.png"
import zoomIcon from "Images/zoom-logo-2.png"
import { Link03OutlineIcon } from "Shared/icons/untitled-ui/Link03OutlineIcon"
import { LinkExternal01OutlineIcon } from "Shared/icons/untitled-ui/LinkExternal01OutlineIcon"
import { ZoomSettingsModal } from "UsabilityHub/components/ZoomSettingsModal"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useIntegrations } from "UsabilityHub/hooks/useIntegrations"
import { useMicrosoftOAuth } from "UsabilityHub/hooks/useMicrosoftOAuth"
import { useZoomOAuth } from "UsabilityHub/hooks/useZoomOAuth"
import { useModal } from "Utilities/modals/use-modal"
import React, { useState } from "react"
import { useModeratedStudyContext } from "../ModeratedStudyContext"
import { LocationType } from "../moderated-study-builder/forms/useMeetingUrlForm"
import { Card } from "./Card"
import { RadioCards } from "./RadioCards"
import { useStudyDetails } from "./StudyDetailsProvider"

export const MeetingLink: React.FC = () => {
  const { meetingUrlForm } = useStudyDetails()

  const queryClient = useQueryClient()
  const { open: openZoomSettingsModal } = useModal(ZoomSettingsModal)
  const [
    isRemoveLocationModalVisible,
    { on: showRemoveLocationModal, off: hideRemoveLocationModal },
  ] = useBoolean(false)
  const [
    isConnectIntegrationModalVisible,
    { on: showConnectIntegrationModal, off: hideConnectIntegrationModal },
  ] = useBoolean(false)
  const [selectedLocationType, setSelectedLocationType] =
    useState<LocationType>(meetingUrlForm.watch("location_type"))
  const {
    moderatedStudyId,
    moderatedStudy: { members },
  } = useModeratedStudyContext()

  const { data: integrationData } = useIntegrations()

  const mainHost = members.find((member) => member.role === "main_host")
  const currentUser = useCurrentUser()

  const isCurrentUserMainHost = currentUser
    ? currentUser.id === mainHost?.id
    : false
  const isCurrentUserZoomIntegrated = members.find(
    (member) => member.id === currentUser?.id
  )?.has_zoom_oauth_credentials
  const isCurrentUserMicrosoftIntegrated = members.find(
    (member) => member.id === currentUser?.id
  )?.has_microsoft_oauth_credentials
  const isMainHostZoomIntegrated = mainHost?.has_zoom_oauth_credentials
  const isMainHostMicrosoftIntegrated =
    mainHost?.has_microsoft_oauth_credentials

  const { handleZoomOAuth } = useZoomOAuth(
    isCurrentUserZoomIntegrated,
    async () => {
      await queryClient.invalidateQueries([
        "api",
        "moderated_studies",
        moderatedStudyId,
      ])
      setLocationType("zoom")
      openZoomSettingsModal({})
    }
  )

  const { handleMicrosoftOAuth } = useMicrosoftOAuth(
    isCurrentUserMicrosoftIntegrated,
    async () => {
      await queryClient.invalidateQueries([
        "api",
        "moderated_studies",
        moderatedStudyId,
      ])
      setLocationType("teams")
      // TODO
      // openMicrosoftSettingsModal({})
    }
  )

  const integrationLocationsData = {
    zoom: {
      name: "Zoom",
      handleConnect: handleZoomOAuth,
    },
    teams: {
      name: "Microsoft Teams",
      handleConnect: handleMicrosoftOAuth,
    },
  } as const

  const {
    handleSubmit: handleMeetingUrlSubmit,
    formState: { errors: meetingUrlErrors },
    register,
    setValue: setMeetingUrlValue,
  } = meetingUrlForm

  const locationType = meetingUrlForm.watch("location_type")
  const autoUploadRecordings = meetingUrlForm.watch("auto_upload_recordings")
  const setLocationType = (locationType: LocationType) => {
    setSelectedLocationType(locationType)
    setMeetingUrlValue("location_type", locationType)
    if (locationType === "zoom") {
      // Enable auto-uploading recordings by default for Zoom
      setMeetingUrlValue("auto_upload_recordings", true)
    }
    handleMeetingUrlSubmit()
  }

  const changeLocationTypeToZoom = () => {
    if (isMainHostZoomIntegrated) {
      // Main host is zoom integrated, set location type freely
      setLocationType("zoom")
    } else if (isCurrentUserMainHost) {
      // Current user is main host but not yet connected to zoom, show a connect modal
      // If they confirm the modal, we'll trigger the oauth flow and then update the location type
      setSelectedLocationType("zoom")
      showConnectIntegrationModal()
    }
  }

  const changeLocationTypeToMicrosoftTeams = () => {
    if (isMainHostMicrosoftIntegrated) {
      setLocationType("teams")
    } else if (isCurrentUserMainHost) {
      // Current user is main host but not yet connected to Microsoft, show a connect modal
      // If they confirm the modal, we'll trigger the oauth flow and then update the location type
      setSelectedLocationType("teams")
      showConnectIntegrationModal()
    }
  }

  const removeLocationType = () => {
    if (
      locationType === "custom" &&
      meetingUrlForm.watch("meeting_url") !== ""
    ) {
      showRemoveLocationModal()
    } else {
      setLocationType("none")
    }
  }

  const zoomOptionEnabled =
    isMainHostZoomIntegrated || isCurrentUserMainHost || locationType === "zoom"

  // If you don't have Microsoft connected at all, we show the option as enabled
  // since we don't know whether you have Teams access or not yet.
  const doesCurrentUserHaveTeamsAccess =
    integrationData?.microsoft === null ||
    integrationData?.microsoft.teams_enabled

  const microsoftOptionEnabled =
    (doesCurrentUserHaveTeamsAccess &&
      (isMainHostMicrosoftIntegrated || isCurrentUserMainHost)) ||
    locationType === "teams"
  const microsoftOptionDisabledBecause = !doesCurrentUserHaveTeamsAccess
    ? "Your Microsoft account does not have access to Microsoft Teams."
    : !isCurrentUserMainHost
      ? "Only the main host can add a Microsoft Teams meeting link"
      : ""

  const changeLocationType = (type: LocationType) => {
    switch (type) {
      case "custom":
        setLocationType("custom")
        break
      case "zoom":
        changeLocationTypeToZoom()
        break
      case "teams":
        changeLocationTypeToMicrosoftTeams()
        break
      default:
        removeLocationType()
    }
  }

  return (
    <Card.Root>
      <Card.Header title="Meeting link" />
      <Card.Body>
        <Stack as="form" spacing={6} onChange={handleMeetingUrlSubmit}>
          <Text as="p" m={0} textStyle="ds.paragraph.primary">
            Choose how you{"\u2019"}d like to manage the meeting links for your
            upcoming sessions.
          </Text>
          <RadioCards.Root
            name="meeting_link_type"
            value={locationType}
            onChange={changeLocationType}
          >
            <RadioCards.Item
              value={locationType === "none" ? "none" : "custom"}
              description="Use one link for all sessions or add a link after each booking."
            >
              <span>Custom link</span>
            </RadioCards.Item>
            <Tooltip
              hasArrow
              rounded="md"
              placement="top"
              label="Only the main host can add a Zoom meeting link"
              isDisabled={zoomOptionEnabled}
            >
              <RadioCards.Item
                value="zoom"
                description="Automatically generate a new Zoom link for each session when booked."
                isDisabled={!zoomOptionEnabled}
              >
                <Image src={zoomIcon} boxSize={6} alt="Zoom" />
                <span>Zoom</span>
              </RadioCards.Item>
            </Tooltip>
            <Tooltip
              hasArrow
              rounded="md"
              placement="top"
              label={microsoftOptionDisabledBecause}
              isDisabled={microsoftOptionEnabled}
            >
              <RadioCards.Item
                value="teams"
                description="Automatically generate a new Teams link for each session when booked."
                isDisabled={!microsoftOptionEnabled}
              >
                <Image src={teamsIcon} boxSize={6} alt="Teams" />
                <span>Microsoft Teams</span>
              </RadioCards.Item>
            </Tooltip>
          </RadioCards.Root>

          {(locationType === "custom" || locationType === "none") && (
            <Stack spacing={3}>
              <Heading as="h3" textStyle="ds.heading.secondary">
                Settings
              </Heading>
              <Radio
                name="customLocationType"
                value="custom"
                width="full"
                isChecked={locationType === "custom"}
                onCheckedChange={(checked) => {
                  if (checked) {
                    changeLocationType("custom")
                  }
                }}
              >
                <Stack alignItems="stretch" w="full" spacing={1}>
                  <Text>
                    Enter a single link that will be used for all session
                    bookings.
                  </Text>
                  {locationType === "custom" && (
                    <FormControl isInvalid={!!meetingUrlErrors.meeting_url}>
                      <InputGroup>
                        <InputLeftElement>
                          <Icon as={Link03OutlineIcon} color="ds.icon.subtle" />
                        </InputLeftElement>
                        <Input
                          {...register("meeting_url")}
                          type="text"
                          placeholder="Enter a meeting link e.g. Zoom, Google Meet, Microsoft Teams"
                        />
                      </InputGroup>
                      {meetingUrlErrors.meeting_url && (
                        <FormErrorMessage>
                          {meetingUrlErrors.meeting_url?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                </Stack>
              </Radio>
              <Radio
                name="customLocationType"
                value="none"
                width="full"
                isChecked={locationType === "none"}
                onCheckedChange={(checked) => {
                  if (checked) {
                    changeLocationType("none")
                  }
                }}
              >
                <Stack>
                  <Text>
                    Manually enter a meeting link after each session is booked.
                  </Text>
                  {locationType === "none" && (
                    <Alert
                      status="info"
                      description={
                        <>
                          You can manually add or edit the link for a session up
                          to 10 minutes before the session start time.{" "}
                          <Link
                            display="inline-flex"
                            alignItems="center"
                            variant="noUnderline"
                            isExternal
                            href={
                              Constants.HELP_CENTER_MANAGING_INTERVIEW_SESSIONS_URL
                            }
                          >
                            Learn more.
                            <LinkExternal01OutlineIcon ms={1} />
                          </Link>
                        </>
                      }
                    />
                  )}
                </Stack>
              </Radio>
            </Stack>
          )}
          {locationType === "zoom" && isMainHostZoomIntegrated && (
            <>
              <HStack
                align="center"
                spacing={3}
                padding={4}
                rounded={8}
                bg="ds.background.neutral.resting"
              >
                <Image src={zoomIcon} boxSize={5} alt="Zoom" />
                <Text textStyle="ds.heading.secondary" flexGrow={1}>
                  Connected to main host{"\u2019"}s ({mainHost?.name}){" "}
                  {integrationLocationsData[locationType].name} account.{" "}
                </Text>
                {isCurrentUserMainHost && (
                  <Button
                    variant="link"
                    color="ds.text.brand"
                    size="flush"
                    textStyle="ds.heading.secondary"
                    onClick={openZoomSettingsModal}
                  >
                    Advanced options
                  </Button>
                )}
              </HStack>
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <Heading as="h4" textStyle="ds.heading.secondary">
                    Settings
                  </Heading>
                  <Text textStyle="ds.paragraph.secondary">
                    To enable this feature, your sessions must be able to be
                    recorded to Zoom Cloud.
                  </Text>
                </Stack>

                <HStack as="label" align="center" spacing={2}>
                  <Switch
                    id={"auto-upload-recordings"}
                    isChecked={autoUploadRecordings}
                    onChange={() =>
                      setMeetingUrlValue(
                        "auto_upload_recordings",
                        !autoUploadRecordings
                      )
                    }
                  />

                  <Text textStyle="ds.paragraph.primary" m={0}>
                    Automatically fetch recordings from Zoom Cloud if available
                  </Text>
                </HStack>
              </Stack>
            </>
          )}

          {locationType === "teams" && isMainHostMicrosoftIntegrated && (
            <HStack
              align="center"
              spacing={3}
              padding={4}
              rounded={8}
              bg="ds.background.neutral.resting"
            >
              <Image src={teamsIcon} boxSize={5} alt="Teams" />
              <Text textStyle="ds.heading.secondary" flexGrow={1}>
                Connected to main host{"\u2019"}s ({mainHost?.name}){" "}
                {integrationLocationsData[locationType].name} account.{" "}
              </Text>
            </HStack>
          )}
        </Stack>

        {isRemoveLocationModalVisible && (
          <RemoveLocationModal
            handleRemove={() => setLocationType("none")}
            handleClose={hideRemoveLocationModal}
          />
        )}

        {isConnectIntegrationModalVisible &&
          selectedLocationType !== "none" &&
          selectedLocationType !== "custom" && (
            <ConnectYourAccountModal
              integrationName={
                integrationLocationsData[selectedLocationType].name
              }
              handleConnect={
                integrationLocationsData[selectedLocationType].handleConnect
              }
              handleClose={() => {
                setSelectedLocationType(locationType)
                hideConnectIntegrationModal()
              }}
            />
          )}
      </Card.Body>
    </Card.Root>
  )
}

type RemoveLocationModalProps = {
  handleClose: () => void
  handleRemove: () => void
}

const RemoveLocationModal: React.FC<RemoveLocationModalProps> = ({
  handleClose,
  handleRemove,
}) => {
  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Remove location?</ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={2}>
              <Text>
                Are you sure you want to remove this location? The future
                sessions will not have a meeting link added to them.
              </Text>

              <Text>
                <strong>Note:</strong> Links for previously booked sessions
                remain unchanged.
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.primary"
              onClick={() => {
                handleRemove()
                handleClose()
              }}
            >
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

type ConnectYourAccountModalProps = {
  integrationName: "Zoom" | "Microsoft Teams"
  handleClose: () => void
  handleConnect: () => void
}

const ConnectYourAccountModal: React.FC<ConnectYourAccountModalProps> = ({
  integrationName,
  handleClose,
  handleConnect,
}) => {
  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Connect your {integrationName} account</ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={2}>
              <Text>
                Connect your {integrationName} account to automatically add
                meeting links to future sessions.
              </Text>

              <Text>
                <strong>Note:</strong> Links for previously booked sessions
                remain unchanged.
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.primary"
              onClick={() => {
                handleConnect()
                handleClose()
              }}
            >
              Connect {integrationName}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
