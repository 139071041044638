import React from "react"

import styles from "./range.module.scss"
import { RangeTick } from "./tick"

interface Props {
  scale: ReadonlyArray<number>
  onClick: (value: number) => void
}

export const RangeTicks: React.FC<Props> = (props) => {
  const { scale, onClick } = props

  return (
    <div className={styles.ticks}>
      {scale.map((tick, i) => (
        <RangeTick
          key={`${tick}`}
          index={i}
          count={scale.length}
          value={tick}
          onClick={onClick}
        />
      ))}
    </div>
  )
}
