import { createSelector } from "reselect"

import { getSectionTypeRules } from "Constants/test-section-types"
import { UsabilityTestSectionOrQuestion } from "JavaScripts/types/usability-test-section-or-question"
import { getSections } from "Redux/reducers/test-builder-form/selectors/sections"
import { ClientId, TestLogicTarget, UsabilityTestSection } from "Types"
import {
  isTestLogicTargetOption,
  sectionContainsTestLogic,
} from "Utilities/test-logic-statement"
import {
  getClientIdsBefore,
  getSectionIdForQuestionClientId,
} from "Utilities/usability-test-field"
import { getQuestionPrefix } from "Utilities/usability-test-section-question"

/**
 * Returns a TestLogicTarget for each section or question _before_ the given field
 * We filter out any sections/questions whose UsabilityTestSectionType | QuestionType isn't supported by test logic.
 * If a section randomizes the order of questions, we can't target them within that same section.
 *
 * @param fieldClientId
 */
export const getTestLogicTargets = (fieldClientId: ClientId) =>
  createSelector(
    getSections,
    (sections: ReadonlyArray<UsabilityTestSection>): TestLogicTarget[] => {
      // Get preceding fields
      const fieldsBeforeField = getClientIdsBefore(
        sections as UsabilityTestSection[],
        fieldClientId
      )

      // Find parent section for source if there is one
      const parentSectionClientId = getSectionIdForQuestionClientId(
        fieldClientId,
        sections as UsabilityTestSection[]
      )

      // Create filter function for targets
      const isValidTarget = (field: UsabilityTestSectionOrQuestion) =>
        fieldsBeforeField.includes(field._clientId) &&
        isTestLogicTargetOption(field)

      // Build up list of targets
      const targets: TestLogicTarget[] = []
      sections.forEach((section, sectionIndex) => {
        if (isValidTarget(section)) {
          targets.push({
            type: "section",
            label: `${sectionIndex + 1}. ${
              getSectionTypeRules(section.type).defaultTitle
            }`,
            targetClientId: section._clientId,
          })
        }

        // When a section randomizes the order of questions inside, they can't use test logic to target each other.
        if (
          section.questions_randomized &&
          section._clientId === parentSectionClientId
        ) {
          return
        }

        section.questions.forEach((question, questionIndex) => {
          if (isValidTarget(question)) {
            targets.push({
              type: "question",
              label: `${getQuestionPrefix(sectionIndex + 1, questionIndex)} ${
                question.text
              }`,
              targetClientId: question._clientId,
            })
          }
        })
      })
      return targets
    }
  )

export const hasTestLogic = createSelector(
  getSections,
  (sections: ReadonlyArray<UsabilityTestSection>) =>
    sections.some(sectionContainsTestLogic)
)
