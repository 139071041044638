import React from "react"

import { getEnvState } from "JavaScripts/state"
import { UhBrandedThankYou } from "UsabilityHub/components/UsabilityTest/content-factory/thank-you-content/uh-branded-thank-you"
import {
  AppearanceProps,
  LayoutState,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"

export default function uhBrandedThankYouContent(): AppearanceProps {
  return {
    isReportButtonVisible: false,
    layoutState: LayoutState.FocusQuestion,
    mediaContent: null,
    questionContent: (
      <UhBrandedThankYou panelDomain={getEnvState().PANELIST_DOMAIN} />
    ),
  }
}
