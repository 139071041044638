import React from "react"

import styles from "Components/test-results/screenshot-click-results/click-selection-summary-list.module.scss"
import { ClickSelectionSummary } from "Components/test-results/screenshot-click-results/click-selection-summary.click-selection"
import { HitzoneSummary } from "Components/test-results/screenshot-click-results/click-selection-summary.hitzone"
import { OtherSummary } from "Components/test-results/screenshot-click-results/click-selection-summary.other"
import { ClickSelection, ImageScreenshot, ScreenshotHitzone } from "Types"

interface Props {
  hitzones: ReadonlyArray<Readonly<ScreenshotHitzone>>
  clickSelections: ReadonlyArray<Readonly<ClickSelection>>
  screenshot: Readonly<ImageScreenshot>
  sectionScreenshotId: number
}

export const ClickSelectionSummaryList: React.FC<
  React.PropsWithChildren<Props>
> = (props) => {
  const { clickSelections, hitzones, screenshot, sectionScreenshotId } = props
  const hitzoneCount = hitzones.length
  const hitzoneNodes = hitzones.map((hitzone, index) => (
    <li key={hitzone.id}>
      <HitzoneSummary
        hitzone={hitzone}
        screenshot={screenshot}
        name={`Highlight #${index + 1} (Hitzone)`}
      />
    </li>
  ))
  const clickSelectionNodes = clickSelections.map((clickSelection, index) => (
    <li key={clickSelection._clientId}>
      <ClickSelectionSummary
        clickSelection={clickSelection}
        screenshot={screenshot}
        name={`Highlight #${hitzoneCount + 1 + index}`}
      />
    </li>
  ))
  return (
    <ol className={styles.list}>
      {hitzoneNodes}
      {clickSelectionNodes}
      <OtherSummary
        sectionScreenshotId={sectionScreenshotId}
        screenshot={screenshot}
        hitzones={hitzones}
      />
    </ol>
  )
}
