// JsFromRoutes CacheKey 8d2f4eb95f6e7fbf66d2a49da47e079a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  cancel: /* #__PURE__ */ definePathHelper('put', '/admin/orders/:id/cancel'),
  deleteResponses: /* #__PURE__ */ definePathHelper('post', '/admin/orders/:id/delete_responses'),
  list: /* #__PURE__ */ definePathHelper('get', '/admin/orders'),
  get: /* #__PURE__ */ definePathHelper('get', '/admin/orders/:id'),
}
