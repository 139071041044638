import { Text } from "@chakra-ui/react"
import React from "react"

export const highlightSearchTerm = (
  text: string,
  search: string
): React.ReactNode => {
  const index = text.toLowerCase().indexOf(search)

  if (index === -1) return text

  return (
    <Text as="span" whiteSpace="pre-wrap">
      {text.slice(0, index)}
      <Text as="span" bg="yellow.300" color="black" rounded="sm">
        {text.slice(index, index + search.length)}
      </Text>
      {text.slice(index + search.length)}
    </Text>
  )
}
