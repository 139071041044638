import { BaseError } from "make-error"

import { apiFigmaFileVersions } from "~/api"

import { FigmaFileVersion } from "../types"

export class PrototypeNotViewableError extends BaseError {}

// Check if a figma file version can be loaded via the embed url via
// a server proxy since we can't ask query Figma directly due to CORS
export const isFigmaFileVersionViewable = async (
  figmaFileVersion: FigmaFileVersion,
  skipCache: boolean
): Promise<void> => {
  try {
    const { viewable } = await apiFigmaFileVersions.viewable<{
      viewable: boolean
    }>({
      figma_file_version_id: figmaFileVersion.id,
      query: {
        skip_cache: skipCache,
      },
    })
    if (viewable) {
      return Promise.resolve()
    } else {
      return Promise.reject(
        new PrototypeNotViewableError("Prototype not viewable")
      )
    }
  } catch (error) {
    return Promise.reject("Prototype viewable check failed")
  }
}

export const getNodeTitle = (
  figmaFileVersion: FigmaFileVersion,
  nodeId: string
): string | undefined => {
  return figmaFileVersion.frame_titles
    ? figmaFileVersion.frame_titles[nodeId]
    : undefined
}
