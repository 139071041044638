import {
  Box,
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  VStack,
  useToast,
} from "@chakra-ui/react"
import { useRefreshTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import React from "react"
import {
  GetDeletingUserInfoResponse,
  useDeleteUser,
  useGetDeletingUserInfo,
} from "~/api/generated/usabilityhub-components"
import { TeamMember } from "~/api/generated/usabilityhubSchemas"

type DeleteUserModalProps = React.PropsWithChildren<
  {
    user: TeamMember
  } & Pick<React.ComponentProps<typeof Modal>, "isOpen" | "onClose">
>

type DeleteUserModal = React.FC<DeleteUserModalProps>

export const DeleteUserModal: DeleteUserModal = ({ isOpen, onClose, user }) => {
  const { data: deletingUserInfo, isLoading } = useGetDeletingUserInfo({
    pathParams: { userId: user.id },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent borderRadius="md">
          <ModalHeader>Delete user</ModalHeader>
          <ModalCloseButton />
          {isLoading || deletingUserInfo === undefined ? (
            <ModalBody>Loading...</ModalBody>
          ) : (
            <DeleteUserModalBody
              onClose={onClose}
              user={user}
              deletingUserInfo={deletingUserInfo}
            />
          )}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

type DeleteUserModalBodyProps = {
  deletingUserInfo: GetDeletingUserInfoResponse
  user: TeamMember
} & Pick<React.ComponentProps<typeof Modal>, "onClose">

const DeleteUserModalBody: React.FC<DeleteUserModalBodyProps> = ({
  onClose,
  user,
  deletingUserInfo,
}) => {
  const toast = useToast()
  const refreshTeamMembers = useRefreshTeamMembers()

  const moderatedStudyHostCount = deletingUserInfo.moderated_study_host_count
  const isModeratedStudyHost = moderatedStudyHostCount > 0

  const moderatedStudyActiveBookingHostCount =
    deletingUserInfo.moderated_study_active_booking_host_count
  const isModeratedStudyBookingHost = moderatedStudyActiveBookingHostCount > 0

  const { mutate } = useDeleteUser({
    onSuccess: async () => {
      toast({
        title: `User ${user.email} was deleted.`,
        status: "success",
      })

      return refreshTeamMembers()
    },
    onError: (error) => {
      toast({
        title: error.payload.message,
        status: "error",
      })
    },
  })

  return (
    <>
      <ModalBody>
        <VStack align="stretch">
          <Text>
            Are you sure you want to delete{" "}
            <Box as="span" fontWeight="bold">
              {user.first_name}
            </Box>
            ?
          </Text>

          {(isModeratedStudyHost || isModeratedStudyBookingHost) && (
            <>
              <Text>{user.first_name} is the main host of:</Text>
              <UnorderedList pl={6}>
                {isModeratedStudyHost && (
                  <ListItem>{moderatedStudyHostCount} interviews</ListItem>
                )}
                {isModeratedStudyBookingHost && (
                  <ListItem>
                    {moderatedStudyActiveBookingHostCount} upcoming sessions
                  </ListItem>
                )}
              </UnorderedList>
              <Text>
                If deleted, you will be assigned as the main host for those. You
                may need to reconfigure the booking calendar used on these
                studies.
              </Text>
            </>
          )}

          <Text>
            You{"\u2019"}ll still be able to access all the tests and interviews
            created by {user.email}.
          </Text>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} mr={3}>
          Cancel
        </Button>
        <Button
          colorScheme="red"
          onClick={async () => {
            mutate({
              pathParams: { userId: user.id },
            })
          }}
        >
          Delete user
        </Button>
      </ModalFooter>
    </>
  )
}
