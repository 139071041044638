import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Switch,
  Text,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import zoomIcon from "Images/zoom-logo.png"
import { useIntegrations } from "UsabilityHub/hooks/useIntegrations"
import { FunctionalModal } from "Utilities/modals/types"
import React, { useEffect, useState } from "react"
import { useUpdateZoomSettings } from "~/api/generated/usabilityhub-components"

export const ZoomSettingsModal: FunctionalModal = ({ onClose, isOpen }) => {
  const queryClient = useQueryClient()

  const { data } = useIntegrations()

  const [waitingRoom, setWaitingRoom] = useState(false)
  const [joinBeforeHost, setJoinBeforeHost] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const { mutate } = useUpdateZoomSettings({
    onSuccess: async () => {
      onClose()
      return queryClient.invalidateQueries(["api", "integrations"])
    },
  })

  const updateZoomSettings = () => {
    mutate({
      body: {
        waiting_room: waitingRoom,
        join_before_host: joinBeforeHost,
      },
    })
  }

  useEffect(() => {
    if (data?.zoom) {
      setWaitingRoom(data.zoom.settings.waiting_room)
      setJoinBeforeHost(data.zoom.settings.join_before_host)
      setIsLoaded(true)
    }
  }, [data])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader p={4}>
            <Flex gap={4}>
              <Image src={zoomIcon} boxSize={10} />
              <Flex direction="column">
                <Text fontSize="md" fontWeight="semibold" color="text.primary">
                  Zoom
                </Text>
              </Flex>
            </Flex>
          </ModalHeader>

          <ModalBody p={4} borderWidth="1px 0">
            <Flex direction="column" gap={2}>
              {isLoaded && data?.zoom ? (
                <>
                  <Switch
                    display="flex"
                    alignItems="center"
                    fontSize="sm"
                    color="text.primary"
                    isChecked={waitingRoom}
                    onChange={(e) => setWaitingRoom(e.target.checked)}
                  >
                    Enable waiting room
                  </Switch>
                  <Switch
                    display="flex"
                    alignItems="center"
                    fontSize="sm"
                    color="text.primary"
                    isChecked={joinBeforeHost}
                    onChange={(e) => setJoinBeforeHost(e.target.checked)}
                  >
                    Let participants join before host
                  </Switch>
                </>
              ) : (
                <>
                  <Skeleton h={5} w="175px" />
                  <Skeleton h={5} w="255px" />
                </>
              )}

              <Text fontSize="sm" fontWeight="normal" color="text.secondary">
                These settings are applied to all Zoom-enabled interview studies
                and sessions in Lyssna.
              </Text>
              <Text fontSize="sm" fontWeight="normal" color="text.secondary">
                Note that your organization's global account settings in Zoom
                may override these options. To verify, please review your
                organization's security and waiting room settings.
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter px={4} py={2}>
            <Button colorScheme="brand.primary" onClick={updateZoomSettings}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
