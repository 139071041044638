import React from "react"

import { CheckboxIcon } from "Shared/icons/CheckboxIcon"
import { LongTextIcon } from "Shared/icons/LongTextIcon"
import { RadioIcon } from "Shared/icons/RadioIcon"
import { RangeIcon } from "Shared/icons/RangeIcon"
import { RankIcon } from "Shared/icons/RankIcon"
import { ShortTextIcon } from "Shared/icons/ShortTextIcon"
import { QuestionType } from "Types"

export function iconForQuestionType(type: QuestionType): React.ReactNode {
  switch (type) {
    case QuestionType.CheckboxMultipleChoice:
      return <CheckboxIcon />
    case QuestionType.LinearScale:
      return <RangeIcon />
    case QuestionType.LongAnswer:
      return <LongTextIcon />
    case QuestionType.RadioMultipleChoice:
      return <RadioIcon />
    case QuestionType.Ranking:
      return <RankIcon />
    case QuestionType.ShortAnswer:
      return <ShortTextIcon />
  }
}
