import { FindOrCreateModeratedStudyScreenerQuestionRequestBody } from "~/api/generated/usabilityhub-components"
import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"

export const getUpdateScreenerQuestionRequestBody = (
  { type, id, text, shuffle_options, options }: ScreenerQuestion,
  position: number
): FindOrCreateModeratedStudyScreenerQuestionRequestBody => {
  if (type === "long_text" || type === "short_text") {
    return {
      id,
      type,
      position,
      shuffle_options,
      text,
    }
  }

  return {
    id,
    type,
    text,
    position,
    shuffle_options,
    screener_question_options: options,
  }
}
