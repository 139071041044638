import {
  Unpersisted,
  UsabilityTestSection,
  UsabilityTestSectionType,
} from "Types"

type Section = UsabilityTestSection | Unpersisted<UsabilityTestSection>

export const isQuestionsSection = (section: Section): boolean =>
  section.type === UsabilityTestSectionType.Questions

export const isDesignQuestionsSection = (section: Section): boolean =>
  section.type === UsabilityTestSectionType.DesignQuestions

export const isNavigationTestSection = (section: Section): boolean =>
  section.type === UsabilityTestSectionType.NavigationTest

export const isPrototypeTaskSection = (section: Section): boolean =>
  section.type === UsabilityTestSectionType.PrototypeTask
