import React from "react"
import { connect } from "react-redux"

import { State } from "Redux/app-store"
import {
  createHasQuestionEverReceivedOtherAnswer,
  createHasSingleAnswerQuestionEverBeenPassed,
} from "Redux/reducers/test-results/selectors"
import { Omit, RadioQuestion, ResponseAnswer, SortMethod } from "Types"
import { presence } from "Utilities/values"

import { AnswerCountItem } from "./answer-count-item"
import { AnswerCounts } from "./answer-counts"
import { emptyCounts, getKey, otherSymbol } from "./helpers"

type Answer = string | null | typeof otherSymbol

function countRadioAnswers(
  question: RadioQuestion,
  answers: ReadonlyArray<ResponseAnswer>,
  includePassed: boolean,
  includeOther: boolean
): Map<Answer, number> {
  const result: Map<Answer, number> = emptyCounts(
    question.multiple_choice_options
  )
  if (includePassed) {
    result.set(null, 0)
  }
  if (includeOther) {
    result.set(otherSymbol, 0)
  }
  return answers.reduce((acc, answer) => {
    const key = presence(answer.answer)
    if (acc.has(key)) {
      acc.set(key, acc.get(key)! + 1)
    } else {
      acc.set(otherSymbol, acc.get(otherSymbol)! + 1)
    }
    return acc
  }, result)
}

interface Props {
  question: RadioQuestion
  answers: ReadonlyArray<ResponseAnswer>
  sortMethod: SortMethod
  includePassed: boolean
  includeOther: boolean
}

const RadioAnswerCountsImpl: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { question, answers, sortMethod, includePassed, includeOther } = props
  return (
    <AnswerCounts
      countItemComponent={AnswerCountItem}
      questionId={question.id}
      countByAnswer={countRadioAnswers(
        question,
        answers,
        includePassed,
        includeOther
      )}
      responseCount={answers.length}
      sortMethod={sortMethod}
      getKey={getKey}
    />
  )
}

export const RadioAnswerCounts = connect(() => {
  const hasSingleAnswerQuestionEverBeenPassed =
    createHasSingleAnswerQuestionEverBeenPassed()
  const hasQuestionEverReceivedOtherAnswer =
    createHasQuestionEverReceivedOtherAnswer()
  return (
    state: State,
    ownProps: Omit<Props, "includePassed" | "includeOther">
  ) => ({
    includePassed:
      // Simply checking if the question is "required" or if a single answer is
      // passed is not sufficient. The passed answer may be filtered out, or the
      // question may have had its "required" flag switched by a user. We need
      // to check if any passed responses have been provided, regardless of
      // their current presence, otherwise the passed item will not be
      // consistently visible.
      hasSingleAnswerQuestionEverBeenPassed(state, ownProps.question.id),
    includeOther:
      // Same reasoning as above.
      ownProps.question.has_other_option ||
      hasQuestionEverReceivedOtherAnswer(state, ownProps.question.id),
  })
})(RadioAnswerCountsImpl)
