import { FigmaTaskClick } from "Types"

import {
  ClickOccurrence,
  filterNodeClicksByOccurrences,
} from "./filter-node-clicks/filter-node-clicks-by-occurrence"
import { getAllClicks } from "./get-node-clicks-by-response/get-all-clicks"
import { NodeClicksByResponse } from "./get-node-clicks-by-response/get-node-clicks-by-response"

/**
 * Runs through a list of grouped clicks to create a map to group them by occurrence (1st clicks, 2nd clicks etc.)
 * When provided with a maxOccurrence, all occurrences after that maxOccurrence will be grouped in that maxOccurrence.
 * When maxOccurrence is 5, the last key of the map will be "5+" and all clicks that are 5th or more will be grouped in there.
 */
export function getClicksByOccurrence(
  clicks: NodeClicksByResponse,
  clickOccurrenceGroups: ClickOccurrence[]
) {
  const occurrences: [ClickOccurrence, FigmaTaskClick[]][] = []

  for (const clickOccurrenceGroup of clickOccurrenceGroups) {
    occurrences.push([
      clickOccurrenceGroup,
      getAllClicks(
        filterNodeClicksByOccurrences(clicks, [clickOccurrenceGroup])
      ),
    ])
  }

  return new Map<ClickOccurrence, FigmaTaskClick[]>(occurrences)
}
