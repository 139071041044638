import { UsabilityTest } from "JavaScripts/types/usability-test"
import { Dispatch } from "Redux/app-store"
import {
  getFormName,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { useDispatch, useSelector } from "react-redux"
import { arrayMove, arrayRemove, change } from "redux-form"
import { v4 as uuidv4 } from "uuid"
import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"

export const useUsabilityTestScreenerQuestions = () => {
  const dispatch = useDispatch<Dispatch>()
  const formValues = useSelector(getFormValues) as Partial<UsabilityTest>
  const { screener } = formValues
  const screenerQuestions = screener?.questions ?? []

  const appendScreenerQuestion = async () => {
    const newScreenerQuestion: ScreenerQuestion = {
      id: uuidv4(),
      type: "single_select",
      text: "",
      shuffle_options: false,
      options: [
        {
          id: uuidv4(),
          value: "",
          rule: "qualify",
        },
        {
          id: uuidv4(),
          value: "",
          rule: "disqualify",
        },
      ],
    }

    dispatch(
      change(getFormName(), "screener.questions", [
        ...screenerQuestions,
        newScreenerQuestion,
      ])
    )
  }

  const removeScreenerQuestion = async (id: string) => {
    const questionIndex = screenerQuestions.findIndex((sq) => sq.id === id)

    // If we're removing the last question, disable the screener
    if (screenerQuestions.length === 1) {
      dispatch(change(getFormName(), "screener.enabled", false))
    }

    dispatch(arrayRemove(getFormName(), "screener.questions", questionIndex))
  }

  const updateScreenerQuestion = async (
    updatedScreenerQuestion: ScreenerQuestion,
    position: number
  ) => {
    const oldIndex = screenerQuestions.findIndex(
      (q) => q.id === updatedScreenerQuestion.id
    )
    const newIndex = position
    dispatch(arrayMove(getFormName(), "screener.questions", oldIndex, newIndex))
  }

  const duplicateScreenerQuestion = async (
    screenerQuestion: ScreenerQuestion
  ) => {
    const newScreenerQuestion = {
      ...screenerQuestion,
      id: uuidv4(),
      options: screenerQuestion.options?.map((option) => ({
        ...option,
        id: uuidv4(),
      })),
    }

    dispatch(
      change(getFormName(), "screener.questions", [
        ...screenerQuestions,
        newScreenerQuestion,
      ])
    )
  }

  return {
    screenerQuestions,
    appendScreenerQuestion,
    removeScreenerQuestion,
    updateScreenerQuestion,
    duplicateScreenerQuestion,
  }
}
