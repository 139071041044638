import { PreferenceAnswerFilter } from "../filters/preference-answer"

import { generateFilterId } from "./generate-filter-id"

export function createPreferenceAnswerFilterCategory(
  sectionId: number
): PreferenceAnswerFilter["category"] {
  return `answer/section:${sectionId}/preference`
}

export function createPreferenceAnswerFilter(
  sectionId: number,
  screenshotId: number
): PreferenceAnswerFilter {
  return {
    id: generateFilterId(),
    category: createPreferenceAnswerFilterCategory(sectionId),
    type: "answer/preference",
    value: screenshotId,
  }
}
