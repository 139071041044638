import React, { FC } from "react"

import { Tag } from "Components/tags/tags"
import { useTagsFilter } from "Components/test-results/hooks/use-tags-filter"
import { CountItemProps } from "Components/test-results/question-results/answer-counts/answer-counts"
import { CountItem } from "Components/test-results/question-results/answer-counts/count-item"
import { QuestionTag } from "Types"
import { useSectionContext } from "UsabilityHub/contexts"

type Props = CountItemProps<QuestionTag>

export const TagCountItem: FC<Props> = ({ option, questionId, ...props }) => {
  const { id, name } = option
  const { section } = useSectionContext()
  const { isFilter, isFiltered, toggleFilter } = useTagsFilter(
    section.id,
    questionId,
    id
  )

  const text = (
    <Tag
      color={option.color}
      tagIndex={option._index}
      // id is -1 for the "untagged" tag
      fontStyle={option.id === -1 ? "italic" : "initial"}
    >
      {name}
    </Tag>
  )

  return (
    <CountItem
      isLiteral
      {...props}
      isFilter={isFilter}
      isFilterRequested={isFilter}
      isFiltered={isFiltered}
      text={text}
      onFilterChange={toggleFilter}
    />
  )
}
