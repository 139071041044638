import { CardSortCard, CardSortCategoryCard } from "Types"
import { sortBy } from "lodash"
import { notUnsortedGroupLabel } from ".././card-sort-categories/grouping"

export type SortType = "position" | "alphabetically" | "numCategories"

interface SortData {
  cards: CardSortCategoryCard[]
  groupLabelByCategoryId: Record<string, string>
}

const SORT_FUNCTIONS: Record<
  SortType,
  (cards: CardSortCard[], sortData: SortData) => CardSortCard[]
> = {
  alphabetically: (cards) => {
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    })
    return cards.sort((a, b) =>
      collator.compare(a.label.toLowerCase(), b.label.toLowerCase())
    )
  },
  position: (cards) => sortBy(cards, (c) => c.position ?? 0),
  numCategories: (cards, sortData) =>
    sortBy(
      cards,
      (c) => getNumberOfCategories(c, sortData),
      (c) => c.position ?? 0
    ),
}

export const SORT_TYPE_LABELS: { [t in SortType]: string } = {
  position: "Default",
  alphabetically: "Alphabetically",
  numCategories: "Number of categories",
}

export const sortCards = (
  cards: CardSortCard[],
  sortType: SortType,
  sortData: SortData
) => {
  return SORT_FUNCTIONS[sortType](cards, sortData)
}

const getNumberOfCategories = (card: CardSortCard, sortData: SortData) => {
  const relatedSortData = sortData.cards.filter(
    (s) => s.card_sort_card_id === card.id
  )

  return new Set(
    relatedSortData
      .map(
        (s) =>
          sortData.groupLabelByCategoryId[
            Number(s.card_sort_category_id ?? s.card_sort_open_category_id)
          ]
      )
      .filter(notUnsortedGroupLabel)
  ).size
}
