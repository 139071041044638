import { Page } from "Shared/components/Page/Page"
import { OAuthResponseLayout } from "Shared/components/oauth/OAuthResponseLayout"
import { getIntegrationName } from "Shared/helpers/getIntegrationName"
import React, { useEffect } from "react"

export const OAuthErrorRoute: React.FC = () => {
  useEffect(() => {
    window.opener?.postMessage({ type: "OAUTH_ERROR", integration }, "*")
  })
  const queryParams = new URLSearchParams(window.location.search)
  const integration = getIntegrationName(queryParams.get("integration"))
  const message = `We couldn\u2019t connect Lyssna to your ${integration} account. Please close this window and try again.`
  return (
    <Page title="OAuth Error">
      <OAuthResponseLayout message={message} />
    </Page>
  )
}
