import classNames from "classnames"
import React, { ReactNode, useRef } from "react"

import styles from "Components/fixed-sidebar-layout/fixed-sidebar-layout.module.scss"

const layoutClassName = styles.layout

interface Props {
  readonly className?: string
  readonly children: ReactNode
}

export const Layout = ({ children, className }: Props) => (
  <div className={classNames(layoutClassName, className)}>{children}</div>
)

export const Sidebar = ({ children, className }: Props) => {
  const sidebarEl = useRef<HTMLDivElement>(null)

  return (
    <div ref={sidebarEl} className={classNames(styles.sidebar, className)}>
      <div className={styles.sidebarContent}>{children}</div>
    </div>
  )
}

export const Main = ({ children, className }: Props) => (
  <div className={styles.mainScrollContainer}>
    <div className={styles.mainScrollable}>
      <div className={classNames(styles.mainContent, className)}>
        {children}
      </div>
    </div>
  </div>
)
