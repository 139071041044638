import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"

export function getScreenerQuestionLabel(
  type: ScreenerQuestion["type"]
): string {
  if (type === "long_text") {
    return "Long text"
  }

  if (type === "short_text") {
    return "Short text"
  }

  if (type === "single_select") {
    return "Single choice"
  }

  if (type === "multi_select") {
    return "Multiple choice"
  }

  throw new Error("type is not supported")
}
