import { Interpolations, translate } from "Services/i18n"
import { LocaleContext } from "Shared/contexts/LocaleContext"
import { useCallback, useContext } from "react"

export const useTranslate = () => {
  const localeContext = useContext(LocaleContext)

  if (!localeContext) {
    throw new Error("useTranslate must be used within a LocaleProvider")
  }

  const { translations } = localeContext

  return useCallback(
    (path: string, interpolations?: Interpolations) =>
      translate(translations, path, interpolations),
    [translations]
  )
}
