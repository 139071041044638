import { useContext } from "react"

import { Response } from "Types"

import { FilteredResponsesContext } from "../context/filtered-responses"

export const useFilteredResponses = (): Readonly<Response[]> => {
  const { filteredResponses } = useContext(FilteredResponsesContext)

  return filteredResponses
}
