import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import React, { useState } from "react"

interface Props {
  attributeLabel: string
  onSave: (value: string) => void
  onClose: () => void
}

export const FreeTextFilterModal: React.FC<Props> = ({
  attributeLabel,
  onSave,
  onClose,
}) => {
  const [value, setValue] = useState("")

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Filter by {attributeLabel}...</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            autoFocus
            placeholder="Type here..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSave(value)
              }
            }}
          />
          <Flex justify="flex-end" gap={4} mt={6} mb={4}>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.primary"
              isDisabled={value === ""}
              onClick={() => onSave(value)}
            >
              Apply
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
