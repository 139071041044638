import { Flex, SkeletonText, Stack } from "@chakra-ui/react"
import React from "react"

export const StudySkeleton: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex
    h={["9.75rem", null, "6.25rem"]}
    p={[4, null, 8]}
    gap={[6, null, 4]}
    bg="white"
    w="100%"
    rounded="16px"
    shadow="ds.raised"
    align={["flex-start", null, "center"]}
    direction={["column", null, "row"]}
  >
    <Flex
      flexBasis="51%"
      w={["full", null, "auto"]}
      align="top"
      gap={4}
      pt={[6, null, 0]}
    >
      <Flex
        flexDirection="column"
        gap={1}
        flexGrow="1"
        maxW={48}
        mt={[1, null, 0]}
      >
        <SkeletonText
          noOfLines={2}
          spacing={4}
          skeletonHeight="2"
          flexGrow="1"
          maxW={48}
        />
      </Flex>
    </Flex>
    <Flex
      flexBasis="49%"
      flexGrow="1"
      alignItems={["flex-start", null, "center"]}
      gap={8}
      h="full"
      w={["100%", null, "auto"]}
    >
      <Flex
        flexDirection={["row", null, "column"]}
        flexBasis={["12rem", null, "40%"]}
        gap={2}
      >
        <SkeletonText noOfLines={1} w={["40%", null, 6]} />
        <SkeletonText noOfLines={1} w={["40%", null, "100%"]} maxW="8rem" />
      </Flex>
      <Stack flexBasis="60%" display={["none", null, "flex"]}>
        <SkeletonText noOfLines={1} w={6} />
        <SkeletonText noOfLines={1} maxW="8rem" />
      </Stack>
    </Flex>
  </Flex>
)
