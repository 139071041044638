import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const NavigationTestIcon = createIcon({
  displayName: "NavigationTestIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-8.971.959c.109.359.387 1.257.628 2.033a.5.5 0 00.939.044l2.24-5.391a.5.5 0 00-.653-.654l-5.4 2.235a.5.5 0 00.042.938c.75.233 1.622.503 2.035.627a.25.25 0 01.169.168z"
    />
  ),
})
