import { Box, Flex } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Tag } from "DesignSystem/components"
import { Users01SolidIcon } from "Shared/icons/untitled-ui/Users01SolidIcon"
import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import React from "react"
import { usePutApiModeratedStudiesId } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { InPlaceEditor } from "~/usabilityhub/components/InPlaceEditor/InPlaceEditor"
import { ModeratedStudyAlerts } from "./ModeratedStudyAlerts"
import { SessionLimitIndicator } from "./SessionLimitIndicator"

export const ModeratedStudySubNavigation = () => {
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const { moderatedStudyId, moderatedStudy, moderatedStudySummary } =
    useModeratedStudyContext()

  const { mutateAsync } = usePutApiModeratedStudiesId({
    onSuccess: () => {
      const key = queryKeyFn({
        operationId: "getModeratedStudy",
        path: "/api/moderated_studies/{moderated_study_id}",
        variables: {
          pathParams: {
            moderatedStudyId: moderatedStudyId,
          },
        },
      })
      return queryClient.invalidateQueries(key)
    },
  })

  const handleSave = async (newName: string) => {
    return mutateAsync({
      pathParams: {
        moderatedStudyId: moderatedStudyId,
      },
      body: {
        internal_name: newName,
        external_name: moderatedStudy.external_name ?? "",
        description: moderatedStudy.description ?? "",
      },
    })
  }

  return (
    <Box
      as="header"
      py="3"
      bg="white"
      borderBottomWidth="1px"
      borderBottomColor="gray.100"
    >
      <Flex
        display="flex"
        gap={1}
        justify="space-between"
        align="center"
        mx="auto"
        px={8}
      >
        <Flex gap={2} alignItems="center">
          <Tag
            label="Interview"
            leftIcon={Users01SolidIcon}
            colorScheme="cyan"
          />
          <InPlaceEditor
            value={moderatedStudy.internal_name}
            textStyle="ds.heading.primary"
            handleSave={handleSave}
            inputProps={{ maxLength: 100, size: "sm" }}
            buttonProps={{ size: "default", alignSelf: "center" }}
          />
        </Flex>
        <Flex gap={2} align="center" flexShrink={0}>
          {/* moderatedStudySummary exists check prevents a flicker on a false negative before data load */}
          {moderatedStudySummary && (
            <ModeratedStudyAlerts
              showText
              hasAvailabilities={
                !!moderatedStudySummary.has_available_booking_slots
              }
            />
          )}

          <SessionLimitIndicator />
        </Flex>
      </Flex>
    </Box>
  )
}
