import { createIcon } from "@chakra-ui/react"
import React from "react"

export const Minimize01OutlineIcon = createIcon({
  displayName: "Minimize01OutlineIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      d="M4 14H10M10 14V20M10 14L3 21M20 10H14M14 10V4M14 10L21 3"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
})
