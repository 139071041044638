import React from "react"

import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { ResetPasswordForm } from "Shared/forms/ResetPasswordForm"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"

export const ResetPasswordRoute: React.FC = () => (
  <Page title="Reset password">
    <UsabilityHubNavbar />
    <PageMain>
      <PageContent maxW="md">
        <ResetPasswordForm />
      </PageContent>
    </PageMain>
  </Page>
)
