import { Icon, Stack, Text } from "@chakra-ui/react"
import { CreditCard02OutlineIcon } from "Shared/icons/untitled-ui/CreditCard02OutlineIcon"
import React from "react"

interface Props {
  cardLast4: string | null
}

export const CardDetails: React.FC<React.PropsWithChildren<Props>> = ({
  cardLast4,
}) => {
  return (
    <Stack isInline alignItems="center" justifyContent="center" py={1} px={2}>
      <Icon as={CreditCard02OutlineIcon} mr={2} />
      <Text>&bull;&bull;&bull;&bull;</Text>
      <Text>&bull;&bull;&bull;&bull;</Text>
      <Text>&bull;&bull;&bull;&bull;&nbsp;</Text>
      <Text>{cardLast4}</Text>
    </Stack>
  )
}
