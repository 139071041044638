import * as d3 from "d3"
import cloud from "d3-cloud"
import { easeCubicIn } from "d3-ease"
import { Dictionary, maxBy, minBy, orderBy } from "lodash"
import React, { HTMLAttributes, useEffect, useMemo, useState } from "react"

import { Box } from "@chakra-ui/react"
import { Datum, countByWordToData, getBounds } from "Services/word-cloud"
import { pixels } from "Utilities/css-helpers"
import { useDimensions } from "./decorators/dimensions"

type Range = [number, number]

export interface Props extends HTMLAttributes<HTMLElement> {
  countByWord: Dictionary<number>
  fontScaleRange?: Range
  opacityScaleRange?: Range
}

const TWEEN_DURATION = 600

// Helper function to do d3 tweens.
function tweenAttribute(attribute: string, getTo: (d: Datum) => string) {
  return function (this: SVGTextElement, d: Datum) {
    return d3.interpolateString(this.getAttribute(attribute)!, getTo(d))
  }
}

function createScale(range: Range, domain: Range) {
  return d3.scaleLog().range(range).domain(domain)
}

function getDomain(data: ReadonlyArray<Datum>): Range {
  return data.length === 0
    ? [0, 0]
    : [
        (minBy(data, "count") as Datum).count,
        (maxBy(data, "count") as Datum).count,
      ]
}

export const WordCloud: React.FC<Props> = ({
  countByWord,
  fontScaleRange = [12, 100],
  opacityScaleRange = [0.4, 1],
  className,
  ...rest
}) => {
  const cloudLayout = useMemo(
    () =>
      (cloud as any)()
        .size([1000, 1000])
        .rotate(0)
        .font("Roboto")
        .fontWeight("700")
        .timeInterval(100)
        .spiral("rectangular"),
    []
  )

  const [wordGroup, setWordGroup] =
    useState<d3.Selection<SVGGElement, Datum, null, undefined>>()
  const containerRef = React.createRef<HTMLDivElement>()

  // Get the size of the container...
  const { width, height } = useDimensions(containerRef)

  const updateCloud = () => {
    const data = countByWordToData(countByWord)
    const fontScale = createScale(fontScaleRange as Range, getDomain(data))

    cloudLayout
      .fontSize((d: Readonly<Datum>) => fontScale(d.count))
      .words(orderBy(data, "count"))
      .start()
  }

  useEffect(() => {
    if (!containerRef.current) return

    const width = containerRef.current.offsetWidth
    const height = containerRef.current.offsetHeight

    const newSvg = d3
      .select<HTMLDivElement, Datum>(containerRef.current!)
      .append<SVGElement>("svg")
    const newWordGroup = newSvg
      .append<SVGGElement>("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`)
    setWordGroup(newWordGroup)
  }, [containerRef.current])

  useEffect(() => {
    if (!wordGroup) return

    cloudLayout.on("end", (data: ReadonlyArray<Datum>) => draw(data))
    updateCloud()
  }, [wordGroup])

  const updateZoom = (data: ReadonlyArray<Datum>) => {
    if (!wordGroup) return

    // ..and contents.
    const cloudBounds = getBounds(data)

    // Get content dimensions..
    const cloudWidth = cloudBounds.maxX - cloudBounds.minX
    const cloudHeight = cloudBounds.maxY - cloudBounds.minY

    // ...and center (which varies).
    const cloudCenterX = (cloudBounds.minX + cloudBounds.maxX) / 2
    const cloudCenterY = (cloudBounds.minY + cloudBounds.maxY) / 2

    // We want to do a 'contain' style fill. Different strategy for
    // width/height.
    const containerAspect = width / height
    const cloudAspect = cloudWidth / cloudHeight

    // Note that the scale is inverse to zoom here (we scale down to "zoom" in).
    const scale =
      cloudAspect > containerAspect
        ? [width / cloudWidth, height / (cloudWidth / containerAspect)]
        : [(height * cloudAspect) / cloudWidth, height / cloudHeight]

    // The cloud center actually moves around relative to its container object,
    // so we need to:
    //
    //  1. Center the group in the container.
    //  2. Zoom so that the extremities of the group are visible.
    //  3. Adjust the offset so that the cloud is centered.
    //
    const transformTween = tweenAttribute(
      "transform",
      () =>
        `translate(${width / 2}, ${height / 2})` +
        `scale(${scale})` +
        `translate(${-cloudCenterX}, ${-cloudCenterY})`
    )
    const transition = d3
      .transition()
      .duration(TWEEN_DURATION)
      .ease(easeCubicIn)

    // Actually transition the group.
    wordGroup.transition(transition).attrTween("transform", transformTween)
  }

  useEffect(() => {
    updateCloud()
  }, [countByWord])

  useEffect(() => {
    updateZoom(cloudLayout.words())
  }, [width, height])

  const draw = (data: ReadonlyArray<Datum>) => {
    if (!wordGroup) return

    const domain = getDomain(data)
    const opacityScale = createScale(opacityScaleRange!, domain)

    updateZoom(data)

    const words = wordGroup
      .selectAll<SVGTextElement, Datum>("g text")
      .data<Datum>(data as Datum[], (d) => d.text)

    const tweenTransform = tweenAttribute(
      "transform",
      (d) => `translate(${d.x}, ${d.y})`
    )
    const tweenFontSize = tweenAttribute("font-size", (d) => pixels(d.size))

    const transition = d3
      .transition()
      .duration(TWEEN_DURATION)
      .ease(easeCubicIn)

    // New words.
    words
      .enter()
      .append<SVGTextElement>("text")
      .style("font-size", (d) => pixels(d.size * 0.75))
      .style("letter-spacing", "-.025em")
      .style(
        "font-family",
        // Copied from `shared/_font-families.scss`.
        "-apple-system, BlinkMacSystemFont, 'Segoe UI'," +
          " Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'," +
          " 'Segoe UI Symbol'"
      )
      .style("font-weight", "500")
      .style("fill", "#4a585d")
      .style("opacity", (_d) => 0)
      .attr("text-anchor", "middle")
      .text((d) => d.text)
      .attr("transform", (d) => `translate(${d.x}, ${d.y})`)
      .transition(transition)
      .style("opacity", (d) => opacityScale(d.count))

    // Entering and existing words.
    words
      .transition(transition)
      .style("font-size", (d) => pixels(d.size))
      .attrTween("transform", tweenTransform)
      .styleTween("font-size", tweenFontSize)
      .style("opacity", (d) => opacityScale(d.count))

    // Exiting words.
    words
      .exit<Datum>()
      .transition(transition)
      .styleTween("font-size", tweenFontSize)
      .style("fill-opacity", 1e-6)
      .style("font-size", pixels(1))
      .remove()
  }

  return (
    <Box
      ref={containerRef}
      height="full"
      overflow="hidden"
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      sx={{
        svg: {
          height: "full",
          width: "full",
        },
      }}
      {...rest}
    />
  )
}
