import Constants from "Constants/shared.json"
import { PlanDrawerFeatures, PlanUpgradeSource } from "Types"
import { useCurrentPlan } from "UsabilityHub/hooks/useCurrentAccount"
import React, { PropsWithChildren, createContext, useContext } from "react"
import { Plan } from "~/api/generated/usabilityhubSchemas"

type ChurnSurveySource = "switch-to-free" | "cancel-subscription"

type PlanChangerScreenState =
  | { screen: "plan-grid"; selectedPlan: null }
  | { screen: "churn-survey"; churnSurveySource: ChurnSurveySource }
  | { screen: "payment-details"; selectedPlan: Plan }
  | { screen: "continue-on-free" }
  | { screen: "enterprise-contact" }
  | { screen: "plan-change-success"; title: string; message: string }
  | { screen: "plan-change-failed"; error: string }

type PlanChangerGlobalState = {
  allPlans: readonly Plan[]
  currentPlan: Plan | undefined
  showAnnual: boolean
}

type PlanChangerHelpers = {
  dispatch: React.Dispatch<PlanChangerAction>
  source: PlanUpgradeSource
  requestedFeatures: PlanDrawerFeatures
  onClose: () => void
}

type PlanChangerState = PlanChangerScreenState & PlanChangerGlobalState
type PlanChangerContextValue = PlanChangerState & PlanChangerHelpers

const PlanChangerContext = createContext<PlanChangerContextValue | null>(null)

export type PlanChangerAction =
  | {
      type: "choose-plan"
      planUniqueId: Plan["unique_id"]
    }
  | { type: "back-to-grid" }
  | { type: "enterprise-contact" }
  | { type: "toggle-show-annual" }
  | {
      type: "plan-changed"
      title: string
      message: string
      changeToFree?: boolean
    }
  | { type: "plan-change-failed"; error: string }

const planChangerReducer = (
  state: PlanChangerState,
  action: PlanChangerAction
): PlanChangerState => {
  let selectedPlan: Plan | undefined

  switch (action.type) {
    case "choose-plan":
      selectedPlan = state.allPlans.find(
        (p) => p.unique_id === action.planUniqueId
      )
      if (!selectedPlan) {
        throw new Error(`Invalid plan selected: ${action.planUniqueId}`)
      }

      if (
        selectedPlan.amount === 0 &&
        state.currentPlan &&
        state.currentPlan.amount !== 0
      ) {
        return {
          ...state,
          screen: "churn-survey",
          churnSurveySource: "switch-to-free",
        }
      }

      if (selectedPlan.amount === 0 && !state.currentPlan) {
        return {
          ...state,
          screen: "continue-on-free",
        }
      }

      // payment-details screen will be used for all upgrades and downgrades to a paid plan
      return {
        ...state,
        screen: "payment-details",
        selectedPlan,
      }
    case "back-to-grid":
      return { ...state, screen: "plan-grid", selectedPlan: null }
    case "enterprise-contact":
      return { ...state, screen: "enterprise-contact" }
    case "toggle-show-annual":
      return { ...state, showAnnual: !state.showAnnual }
    case "plan-changed":
      // Update the plan in local state to match what they just changed to
      // Only important on the off chance they go back via "<- All plans"
      // instead of closing the drawer.
      selectedPlan =
        state.screen === "payment-details"
          ? state.selectedPlan
          : state.currentPlan

      if (action.changeToFree) {
        const freePlan = state.allPlans.find(
          (p) => p.unique_id === Constants.FREE_PLAN_UNIQUE_ID
        )
        selectedPlan = freePlan
      }

      return {
        ...state,
        screen: "plan-change-success",
        currentPlan: selectedPlan,
        title: action.title,
        message: action.message,
      }
    case "plan-change-failed":
      return { ...state, screen: "plan-change-failed", error: action.error }
    default:
      assertNever(action)
      return state
  }
}

const assertNever = (action: never): never => {
  throw new Error(`Invalid action: ${JSON.stringify(action)}`)
}

// This generic hook will return the full context regardless of which screen is active.
// That means the calling code will have to handle all possible cases, but could be useful
// for components that might be used on several different screens.
export const usePlanChangerContext = () => {
  const context = useContext(PlanChangerContext)

  if (!context) {
    throw new Error(
      "usePlanChangerContext must be used within a PlanChangerContextProvider"
    )
  }

  return context
}

// Passing the screen to this hook lets us narrow the type of the context returned to the one
// that relates to that screen. You'll get an exception if you pass a screen that doesn't match
// what the context thinks is active.
export const usePlanChangerContextOnScreen = <
  S extends PlanChangerScreenState["screen"],
>(
  currentScreen: S
): PlanChangerGlobalState &
  PlanChangerHelpers &
  Extract<PlanChangerScreenState, { screen: S }> => {
  const context = useContext(PlanChangerContext)

  if (!context) {
    throw new Error(
      "usePlanChangerContext must be used within a PlanChangerContextProvider"
    )
  }

  if (!isOnScreen(context, currentScreen)) {
    throw new Error(
      `usePlanChangerContextOnScreen called for wrong screen: Expected ${currentScreen}, got ${context.screen}`
    )
  }

  return context
}

// This is a type guard that allows us to narrow the type of the context to the screen
// we're actually on, which makes it easier to access the screen-specific props in a typesafe way.
const isOnScreen = <S extends PlanChangerScreenState["screen"]>(
  state: PlanChangerScreenState,
  screen: S
): state is Extract<PlanChangerScreenState, { screen: S }> => {
  return state.screen === screen
}

interface PlanChangerContextProviderProps {
  source: PlanUpgradeSource
  requestedFeatures: PlanDrawerFeatures
  initialScreenState?: PlanChangerScreenState
  allPlans: Plan[]
  onClose: () => void
}

export const PlanChangerContextProvider: React.FC<
  PropsWithChildren<PlanChangerContextProviderProps>
> = ({
  initialScreenState,
  allPlans,
  source,
  requestedFeatures,
  onClose,
  children,
}) => {
  // currentPlan might be null when the user is in no plan state at the end of a trial
  const currentPlan = useCurrentPlan()

  const initialState: PlanChangerState = {
    showAnnual: currentPlan?.interval === "year",
    allPlans,
    currentPlan: currentPlan ?? undefined,
    ...(initialScreenState ?? { screen: "plan-grid", selectedPlan: null }),
  }
  const [state, dispatch] = React.useReducer(planChangerReducer, initialState)

  return (
    <PlanChangerContext.Provider
      value={{ ...state, source, requestedFeatures, onClose, dispatch }}
    >
      {children}
    </PlanChangerContext.Provider>
  )
}
