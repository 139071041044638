import { getApplicationState, getEnvState } from "JavaScripts/state"
import { ShallowUsabilityTest } from "Types"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { reportErrorToSentry } from "Utilities/error"
import { getParsedQueryString } from "Utilities/query-string"
import { CurrentAccount, Plan } from "~/api/generated/usabilityhubSchemas"
import { initializeSegment } from "./initialize-segment.js"
import { centsToDollarsRounded } from "./utilities"

declare global {
  interface Window {
    // The Segment integration for Wootric (InMoment) has no way to set this
    // field so we need to access it's settings directly
    // https://github.com/segmentio/analytics.js-integrations/blob/master/integrations/wootric/lib/index.js
    wootricSettings?: {
      show_opt_out: boolean
    }
  }
}

const groupTraits = (userId: string, currentAccount: CurrentAccount) => {
  return {
    user_id: userId,
    created_at: currentAccount.created_at,
    contact_name: currentAccount.contact_name,
    tax_details: currentAccount.tax_details,
    stripe_customer_id: currentAccount.stripe_customer_id,
    karma_points: currentAccount.karma_points,
    is_eligible_for_free_trial: currentAccount.is_eligible_for_free_trial,
    address: currentAccount.business_address,
    // NOTE: monthly spend is only supplied in float format (not currency)
    lifetime_value: centsToDollarsRounded(currentAccount.total_spend_in_cents),
    monthly_spend: centsToDollarsRounded(
      currentAccount.approximate_monthly_spend_in_cents
    ),
    name:
      currentAccount.business_name ||
      (currentAccount.owner_name && `${currentAccount.owner_name}'s account`),
    collaborators: currentAccount.collaborators_count,
    viewers: currentAccount.viewers_count,
    plan: currentAccount.plan_unique_id,
  }
}

let attemptedSegmentInstall = false

export const installSegment = (
  currentUser: ReturnType<typeof useCurrentUser> | null,
  currentAccount: CurrentAccount | null
) => {
  attemptedSegmentInstall = true
  const { isEmulatingUser } = getApplicationState()
  const env = getEnvState()

  const noSegmentPaths = [
    /^\/do\//,
    /^\/take\//,
    /^\/preview\//,
    /^\/flagged/,
    /^\/third_party/,
    /^\/testers/,
    /^\/payouts/,
    /^\/demo/,
    /^\/admin/,
    /^\/apply/,
    /^\/applications/,
  ]

  // Initialize Segment every time this script is loaded except:
  // - if the user is being emulated
  // - on test taking pages, tester app pages, admin, or other excluded pages
  // - the user is logged into a non-customer (eg. Tester/Vendor) account
  if (
    !process.env.CI &&
    env.JS_SEGMENT_WRITE_KEY !== "" &&
    !isEmulatingUser &&
    !noSegmentPaths.some((r) => r.test(window.location.pathname)) &&
    (currentAccount === null || currentAccount.account_type === "creator")
  ) {
    if (window.wootricSettings) {
      window.wootricSettings.show_opt_out = true
    } else {
      window.wootricSettings = { show_opt_out: true }
    }
    // Initialize Segment for everybody. This tracks the page() vew. It also
    // loads vendor JavaScripts from desintations attached to the Segment Souce
    // associated with the JS_SEGMENT_WRITE_KEY, e.g. Intercom, FullStory.
    const analytics = initializeSegment(env.JS_SEGMENT_WRITE_KEY)

    // Identify logged in customers with Segment
    if (
      currentUser != null &&
      currentAccount != null &&
      currentAccount.account_type === "creator"
    ) {
      const { id, intercom_user_hash, name, email, role, created_at } =
        currentUser
      const account_id = currentAccount.id
      analytics.identify(
        id.toString(),
        {
          name,
          email,
          role,
          created_at,
        },
        {
          integrations: {
            Intercom: {
              user_hash: intercom_user_hash,
            },
          },
        }
      )

      analytics.group(
        account_id.toString(),
        groupTraits(id.toString(), currentAccount)
      )

      const params = getParsedQueryString()
      if (Object.keys(params).includes("start_live_chat")) {
        // Use Segment's ready() method (https://segment.com/docs/sources/website/analytics.js/#ready)
        // to ensure Intercom has been loaded before we try to show the message
        analytics.ready(() => Intercom("showNewMessage"))
      }
    } else {
      // Intercom won't delete user's session id entirely.
      // If someone save the intercom cookies manually,
      // he'll be able to access the intercom messenger after logout.
      // So we always hide intercom messenger before logging in.
      analytics.ready(() =>
        Intercom("update", {
          hide_default_launcher: true,
        })
      )
    }

    // Track special page views as events.
    // NOTE: This should only be done for pages tracked for logged out users. We
    // track most logged-in user actions, including special pageview events, in
    // the appriopriate Rails controller action.
    if (/^\/users\/sign_up/.test(window.location.pathname)) {
      analytics.track("Viewed sign up page")
    }
  }
}

// biome-ignore lint/complexity/noBannedTypes: we want to use this type
const trackWithSegment = (event: string, properties?: Object) => {
  // Segment might not have been installed for reasons outlined above, but calling
  // it from a path that doesn't attempt installation is likely a code error.
  if (attemptedSegmentInstall) {
    window.analytics?.track(event, properties)
    if (process.env.DEBUG) {
      console.debug("trackWithSegment", event, properties)
    }
  } else {
    reportErrorToSentry(
      new Error(`Segment not installed tracking event ${event}`)
    )
  }
}

// Break the rule (in Analytics.rb) about tracking revenue once only. Ad platforms
// need the data to come from the client. If this turns out to result in
// double-counted revenue in other platforms we can filter this "CS" event
// from being sent there.
export const creditsPurchasedSegment = (
  creditCount: number,
  priceInCents: number
) => {
  trackWithSegment("Purchased credits", {
    credit_count: creditCount,
    revenue: centsToDollarsRounded(priceInCents),
  })
}

export const orderActivatedSegment = (requestedResponseCount: number) => {
  trackWithSegment("Activated order", {
    requested_response_count: requestedResponseCount,
  })
}

export const signedUpSegment = () => {
  trackWithSegment("Signed up")
}

export const subscriptionCreatedSegment = (plan: Plan, revenue: number) => {
  trackWithSegment("Subscription created", {
    plan: plan.name,
    plan_unique_id: plan.unique_id,
    revenue: revenue,
  })
}

export type TrackedUsabilityTest = {
  name: string
  test_template_name: string | null
  tests_by_creator_count: number
  has_test_logic: boolean
  duplicate: boolean
}

export const testCreatedSegment = (usabilityTest: TrackedUsabilityTest) => {
  const properties = {
    name: usabilityTest.name,
    duplicate: usabilityTest.duplicate,
  }
  trackWithSegment("Created test", properties)

  if (usabilityTest.test_template_name) {
    trackWithSegment("Created test from template", properties)
    trackWithSegment(
      `Created test from ${usabilityTest.test_template_name} template`,
      properties
    )
  }

  if (usabilityTest.tests_by_creator_count === 1) {
    trackWithSegment("Created first test", properties)
  }

  if (usabilityTest.has_test_logic) {
    trackWithSegment("Created test with logic", properties)
  }
}

export const testUpgradedSegment = (
  usabilityTest: Readonly<ShallowUsabilityTest>
) => {
  const properties = {
    name: usabilityTest.name,
  }
  trackWithSegment("Upgraded test", properties)
}

export const suitableForNpsSurveySegment = () => {
  trackWithSegment("Suitable for NPS")
}
