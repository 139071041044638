import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { PrototypeType } from "JavaScripts/types/figma-file-flow"
import { useTranslate } from "Shared/hooks/useTranslate"
import {
  UsabilityTestSectionQuestion as Question,
  ResponseAnswer,
  UsabilityTestSection,
} from "Types"
import UsabilityTestSectionQuestion from "UsabilityHub/components/UsabilityTestSectionQuestion/UsabilityTestSectionQuestion"
import React from "react"
import { PrimaryInstructions, SecondaryInstructions } from "./Instructions"
import { PrototypeTaskPhase } from "./usePrototypeTaskPhases"

interface ContentProps {
  prototypeTaskPhase: PrototypeTaskPhase
  prototypeType: PrototypeType
  question?: Question
  taskInstructions: string
  section: UsabilityTestSection
  handleQuestionSubmit: (
    answer: Omit<ResponseAnswer, "id" | "response_id">
  ) => void
}

export const TaskFlowOrFreeRoamInstructionsContent: React.FC<
  React.PropsWithChildren<ContentProps>
> = ({
  prototypeTaskPhase,
  prototypeType,
  question,
  taskInstructions,
  section,
  handleQuestionSubmit,
}) => {
  const translate = useTranslate()
  if (prototypeType === "task_flow") {
    if (prototypeTaskPhase === "interacting") {
      return (
        <>
          <PrimaryInstructions>
            <DisplayBlockMarkdownText text={taskInstructions} />
          </PrimaryInstructions>
          <SecondaryInstructions>
            {translate(
              "test.instructions.prototype_task.task_flow.test_taking_instructions"
            )}
          </SecondaryInstructions>
        </>
      )
    } else if (prototypeTaskPhase === "success_screen") {
      return (
        <>
          <PrimaryInstructions>
            {translate("test.instructions.prototype_task.test_success_primary")}
          </PrimaryInstructions>
          <SecondaryInstructions>
            {translate(
              "test.instructions.prototype_task.test_success_secondary"
            )}
          </SecondaryInstructions>
        </>
      )
    } else if (prototypeTaskPhase === "answering_questions" && question) {
      return (
        <UsabilityTestSectionQuestion
          section={section}
          key={question.id}
          question={question}
          onAnswerSubmit={handleQuestionSubmit}
          willShowInInstructionsModal
        />
      )
    }
  } else if (prototypeType === "free_roam") {
    if (prototypeTaskPhase === "interacting") {
      return (
        <>
          <PrimaryInstructions>
            <DisplayBlockMarkdownText text={taskInstructions} />
          </PrimaryInstructions>
          <SecondaryInstructions>
            {translate(
              "test.instructions.prototype_task.free_roam.test_taking_instructions"
            )}
          </SecondaryInstructions>
        </>
      )
    } else if (prototypeTaskPhase === "answering_questions" && question) {
      return (
        <UsabilityTestSectionQuestion
          section={section}
          key={question.id}
          question={question}
          onAnswerSubmit={handleQuestionSubmit}
          willShowInInstructionsModal
        />
      )
    }
  }
  return null
}
