import classNames from "classnames"
import React, { MouseEventHandler, Ref } from "react"

import styles from "Components/icon-button/icon-button.module.scss"

interface Props {
  icon: React.ReactNode
  innerRef?: Ref<HTMLButtonElement>
  onClick: MouseEventHandler<HTMLButtonElement>
  noBorder?: boolean
}

export const IconButton: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  icon,
  innerRef,
  noBorder,
}) => (
  <button
    type="button"
    className={classNames(styles.button, noBorder && styles.noBorder)}
    onClick={onClick}
    ref={innerRef}
  >
    {icon}
  </button>
)
