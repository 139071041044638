import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ChevronDownIcon = createIcon({
  displayName: "ChevronDownIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="chevron-down">
        <path
          id="Combined Shape"
          d="M8 11.229L11.7417 7.39684C12.2583 6.86772 13.0959 6.86772 13.6125 7.39684C14.1292 7.92595 14.1292 8.78381 13.6125 9.31293L8.93542 14.1032C8.4188 14.6323 7.5812 14.6323 7.06458 14.1032L2.38746 9.31293C1.87085 8.78381 1.87085 7.92595 2.38746 7.39684C2.90408 6.86772 3.74169 6.86772 4.25831 7.39684L8 11.229Z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})
