import React from "react"
import { ApiProvider } from "./ApiProvider"
import { Experiments } from "./Experiments"

export const ExperimentAdmin = () => {
  return (
    <ApiProvider>
      <Experiments />
    </ApiProvider>
  )
}
