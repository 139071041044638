import { Spinner, Text, useToast } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useTypedSearchParams } from "react-router-typesafe-routes/dom"

import { Document } from "Shared/components/Document/Document"
import { ROUTES } from "UsabilityHub/views/routes"
import { useDeleteApiSubprocessorListNotificationSubscriptions } from "~/api/generated/usabilityhub-components"

export const SubprocessorsUnsubscribe: React.FC = () => {
  const { mutate, isLoading, isError, isSuccess } =
    useDeleteApiSubprocessorListNotificationSubscriptions({
      onError: (error) => {
        toast({
          title: error.payload.message,
          status: "error",
        })
      },
      onSuccess: (data) => {
        toast({
          title: data.message,
          status: "success",
        })
      },
    })
  const toast = useToast()

  const [{ token }] = useTypedSearchParams(
    ROUTES.LEGAL.SUBPROCESSORS.UNSUBSCRIBE
  )

  useEffect(() => {
    if (token) {
      mutate({ pathParams: { id: token } })
    }
  }, [])

  return (
    <Document title="Sub-processor list notifications">
      <Helmet>
        <title>Unsubscribe from sub-processor list notifications</title>
      </Helmet>
      {isLoading && (
        <Text>
          <Spinner size="xs" mr={2} />
          Unsubscribing...
        </Text>
      )}
      {isError && <Text>Unable to unsubscribe</Text>}
      {isSuccess && (
        <Text>
          You are no longer subscribed to notifications when our sub-processor
          list is updated.
        </Text>
      )}
    </Document>
  )
}
