import { BaseError } from "make-error"

export class ScriptLoadError extends BaseError {
  readonly event: ErrorEvent
  readonly src: string
  constructor(src: string, event: ErrorEvent) {
    super("Failed to load script with src='${src}'")
    this.event = event
    this.src = src
  }
}

export function loadScript(src: string): Promise<Event> {
  return new Promise((resolve, reject) => {
    const scriptElement = document.createElement("script")
    scriptElement.addEventListener("error", (event) => {
      reject(new ScriptLoadError(src, event))
    })
    scriptElement.addEventListener("load", resolve)
    scriptElement.src = src
    const targetNode = document.querySelector("script")
    targetNode!.parentNode!.insertBefore(scriptElement, targetNode)
  })
}
