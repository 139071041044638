import { useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import { createAnswerTagFilter } from "../filters/create-filters/answer-tag-filter"
import { getFilterUtilityFunctions } from "../filters/get-filter-utility-functions"

const { isFilter, isFiltered, toggleFilter } = getFilterUtilityFunctions(
  createAnswerTagFilter
)

export const useTagsFilter = (
  sectionId: number,
  questionId: number,
  tagId: number
) => {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handleToggleFilter = () => {
    setTestResultsFilters((filters) =>
      toggleFilter(filters, sectionId, questionId, tagId)
    )
  }

  return {
    isFilter: isFilter(testResultsFilters, sectionId, questionId, tagId),
    isFiltered: isFiltered(testResultsFilters, sectionId, questionId, tagId),
    toggleFilter: handleToggleFilter,
  }
}
