import React, { ComponentType } from "react"

import { QuestionType } from "Types"

import { Props } from "./answer-form"
import { CheckboxQuestion } from "./checkbox-question"
import { LinearScaleQuestion } from "./linear-scale-question"
import { LongAnswerQuestion } from "./long-answer-question"
import { RadioQuestion } from "./radio-question"
import { RankingQuestion } from "./ranking-question"
import { ShortAnswerQuestion } from "./short-answer-question"

function getQuestionComponent(
  questionType: QuestionType
): ComponentType<React.PropsWithChildren<Props>> {
  switch (questionType) {
    case QuestionType.CheckboxMultipleChoice:
      return CheckboxQuestion
    case QuestionType.RadioMultipleChoice:
      return RadioQuestion
    case QuestionType.ShortAnswer:
      return ShortAnswerQuestion
    case QuestionType.LongAnswer:
      return LongAnswerQuestion
    case QuestionType.LinearScale:
      return LinearScaleQuestion
    case QuestionType.Ranking:
      return RankingQuestion
  }
}

const UsabilityTestSectionQuestion: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const QuestionComponent = getQuestionComponent(props.question.type)
  return <QuestionComponent {...props} />
}

export default UsabilityTestSectionQuestion
