import { Box } from "@chakra-ui/react"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ParticipantDeletionReason } from "Types"
import React, { useEffect } from "react"
import { useTestRecordingContext } from "../../context/TestRecordingContext"
import { captureUserMedias } from "../../useTestTakingRecordings"
import { StepContentProps } from "./RecordingSetupGuideContent"

export const MicrophonePermissionRequest: React.FC<StepContentProps> = ({
  setPassable,
}) => {
  const { setError, allowedRecordingTypes } = useTestRecordingContext()
  useEffect(() => {
    const constraints = {
      audio: true,
      video: allowedRecordingTypes.includes("camera"),
    }
    const success = (stream: MediaStream) => {
      stream.getTracks().forEach((track) => track.stop())
      setPassable(true)
    }
    const failure = () => {
      setError(ParticipantDeletionReason.RecordingPermissionDenied)
    }
    captureUserMedias(constraints, success, failure)
  }, [])

  const translate = useTranslate()

  return (
    <>
      <Box>{translate("test.recording.microphone.body")}</Box>
    </>
  )
}
