import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const InstructionIcon = createIcon({
  displayName: "InstructionIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0a8 8 0 100 16A8 8 0 008 0zM6.649 10.667c.25-.79.65-1.691.925-2.311.137-.308.242-.546.284-.667.178-.525-.142-.756-1.156.142l-.23-.427c1.155-1.262 3.555-1.555 2.746.41l-.235.552c-.393.923-.678 1.594-.85 2.132-.302.933.463.55 1.21-.142l.02.032c.09.144.128.203.22.385-1.663 1.583-3.512 1.725-2.934-.097v-.01zM8.63 5.209c.293.311.818.32 1.173.018.356-.303.4-.8.107-1.111-.284-.303-.818-.312-1.173-.01-.347.303-.4.8-.107 1.103z"
    />
  ),
})
