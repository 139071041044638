import React from "react"

import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { isBlank } from "Utilities/values"

import styles from "./individual-answer-result.module.scss"
import { Props } from "./props"

// TODO: Style "passed"
export const TextAnswer = ({ answer: { answer } }: Props) => (
  <div className={styles.text}>
    {isBlank(answer) ? (
      <div>Passed</div>
    ) : (
      <DisplayBlockMarkdownText text={answer} />
    )}
  </div>
)
