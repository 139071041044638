import { useLocalStorage } from "Shared/hooks/useLocalStorage"
import { assign, cloneDeep, first, merge, pick, set } from "lodash"
import { useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ValidationError } from "yup"
import { ROUTES } from "../routes"
import { useCurrentUser } from "./../../hooks/useCurrentAccount"
import { STEPS } from "./constants"

type Steps = typeof STEPS
type Step = Steps[number]
type StepData = Step["data"]

type OnboardingUser = Pick<
  ReturnType<typeof useCurrentUser>,
  "id" | "preferred_name"
>

type KeysOfUnion<T> = T extends T ? keyof T : never
type DataKey = KeysOfUnion<StepData>
export type AllStepData = {
  [K in DataKey]: Extract<StepData, { [P in K]: unknown }>[K]
}

type Action =
  | { type: "reset"; steps: Steps }
  | {
      type: "skip"
    }
  | { type: "back" }
  | { type: "submit"; data: StepData }
  | { type: "jump"; id: Step["id"] }
  | { type: "update"; data: Partial<AllStepData> }

type State = {
  index: number
  steps: Steps
  errors: ValidationError["errors"] | null
}

const nextStep = (state: State) => ({
  ...state,
  index: state.index + 1,
  errors: null,
})

const previousStep = (state: State) => ({
  ...state,
  index: state.index - 1,
  errors: null,
})

const submitStep = (state: State, data: StepData, onComplete: () => void) => {
  const index = state.index
  const step = cloneDeep({ ...state.steps[index] })
  merge(step.data, data)
  const steps = set([...state.steps], index, step) as typeof STEPS

  try {
    step.schema.validateSync(data)
    const newState = { ...state, steps: steps, errors: null }
    if (index === steps.length - 1) {
      onComplete()
      return newState
    } else {
      return nextStep(newState)
    }
  } catch (err) {
    return {
      ...state,
      steps: steps,
      errors: { [err.path]: first(err.errors) },
    }
  }
}

const jumpTo = (state: State, id: Step["id"]) => {
  const index = state.steps.findIndex((step) => step.id === id)
  return index === state.index
    ? state
    : { ...state, index: index < 0 ? state.index : index, errors: null }
}

const updateStepData = (state: State, data: Record<string, unknown>) => {
  const steps = state.steps.map(
    (step) =>
      ({
        ...step,
        data: assign({}, step.data, pick(data, Object.keys(step.data))),
      }) as Step
  )

  return { ...state, steps }
}

const makeInitialState = (
  steps: Steps = STEPS,
  startAt: Step["id"] = steps[0].id,
  currentUser: OnboardingUser | null = null
): State => {
  const cloned = cloneDeep(steps) as typeof STEPS
  const index = currentUser
    ? Math.max(
        cloned.findIndex((step) => step.id === startAt),
        1
      )
    : 0
  return { index, steps: cloned, errors: null }
}

export type UseStepsOptions = {
  steps?: Steps
  stepId?: Step["id"]
  currentUser?: OnboardingUser
  onComplete?: (redirect: string, isFirstTimeSignup: boolean) => void
  onStepChange?: (stepId: Step["id"]) => void
}

export const useSteps = ({
  currentUser,
  steps: initial = STEPS,
  stepId = initial[0].id,
  onComplete,
  onStepChange,
}: UseStepsOptions = {}) => {
  const initialStepId = useRef<Step["id"] | null>(stepId)

  const [redirect, setRedirect] = useLocalStorage<string | null>(
    "redirect_url",
    null
  )

  const complete = useCallback(() => {
    onComplete?.(redirect || ROUTES.DASHBOARD.buildPath({}), true)
  }, [redirect, onComplete])

  const [{ index, steps, errors }, dispatch] = useReducer(
    (state: State, action: Action) => {
      switch (action.type) {
        case "reset":
          return makeInitialState(action.steps, stepId, currentUser || null)
        case "skip":
          return nextStep(state)
        case "back":
          return previousStep(state)
        case "submit":
          return submitStep(state, action.data, complete)
        case "jump":
          return jumpTo(state, action.id)
        case "update":
          return updateStepData(state, action.data)
        default:
          return state
      }
    },
    { index: 0, steps: initial, errors: null },
    ({ steps }) => makeInitialState(steps, stepId, currentUser || null)
  )

  useEffect(() => {
    // Don't jump on initial render
    if (initialStepId.current !== stepId) {
      dispatch({ type: "jump", id: stepId })
      initialStepId.current = null
    }
  }, [stepId])

  const { length } = steps

  const skip = useCallback(() => {
    if (index < 1) {
      throw new Error("Cannot skip the first step")
    }
    if (index === length - 1) {
      complete()
    } else {
      dispatch({ type: "skip" })
    }
  }, [index, complete])

  const back = useCallback(() => {
    if (index < 1) {
      throw new Error("Cannot return to the previous step")
    }
    dispatch({ type: "back" })
  }, [index, length])

  const submit = useCallback((data: StepData) => {
    dispatch({ type: "submit", data })
  }, [])

  const update = useCallback((data: Record<string, unknown>) => {
    dispatch({ type: "update", data })
  }, [])

  const currentStep = steps[index]

  const isSkippable = "isSkippable" in currentStep && currentStep.isSkippable

  const [isValid, setValid] = useState(false)

  const validateCurrentStep = useCallback(
    (extra: Partial<StepData> = {}) => {
      const data = { ...currentStep.data, ...extra }
      try {
        currentStep.schema.validateSync(data)
        setValid(true)
      } catch (e) {
        setValid(false)
      }
    },
    [currentStep]
  )

  const [unsaved, setUnsaved] = useState<Partial<StepData>>({})

  const onDirty = useCallback(
    (fields: Partial<StepData>) => {
      validateCurrentStep(fields)
      setUnsaved((prev) => ({ ...prev, ...fields }))
    },
    [validateCurrentStep]
  )

  useEffect(() => {
    onStepChange?.(currentStep.id)
  }, [onStepChange, currentStep.id])

  return {
    index,
    step: currentStep,
    steps,
    currentUser,
    errors,
    skip,
    back,
    submit,
    update,
    redirect,
    unsaved,
    setRedirect,
    isSkippable,
    isValid,
    onDirty,
  }
}
