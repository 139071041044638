import { PreferenceConfidence, PreferencePerformance } from "Types"

function getPreferenceZScore(preferred_a: number, preferred_b: number): number {
  // p_1 = Pa = number of users who converted within the experiment split (conversion rate)
  // p_2 = Pc = number of users who converted within the control split (conversion rate)
  // n_1 = Na = the number of impressions within the experiment split
  // n_2 = Nc = the number of impressions within the control split
  // s_1 = SEa = standard error of p_1, the estimate of the mean
  // s_2 = SEc = standard error of p_2, the estimate of the control
  // s_p = SEp = standard error of p_1 - p_2, assuming a pooled variance
  // s_unp = SEunp = standard error of p_1 - p_2, assuming unpooled variance

  const total_participants = preferred_a + preferred_b
  const p_1 = preferred_a / total_participants
  const p_2 = preferred_b / total_participants
  const n_1 = total_participants
  const n_2 = n_1

  // Formula for standard error: root(pq/n) = root(p(1-p)/n)
  const s_1 = Math.sqrt((p_1 * (1 - p_1)) / n_1)
  const s_2 = Math.sqrt((p_2 * (1 - p_2)) / n_2)

  // Formula for pooled error of the difference of the means: root(pi*(1-pi)*(1/na+1/nc)
  // pi = (xa + xc) / (na + nc)
  const pi = (p_1 * n_1 + p_2 * n_2) / (n_1 + n_2)
  const s_p = Math.sqrt(pi * (1 - pi) * (1 / n_1 + 1 / n_2))

  // Formula for unpooled error of the difference of the means: root(sa**2/na + sc**2/nc)
  const s_unp = Math.sqrt(s_1 ** 2 + s_2 ** 2)

  // Boolean variable decides whether we can pool our variances
  const pooled = s_1 / s_2 < 2 && s_2 / s_1 < 2

  // Assign standard error either the pooled or unpooled variance
  const se = pooled ? s_p : s_unp

  // Calculate z-score
  return (p_1 - p_2) / se
}

export function getPreferencePerformance(
  preferredA: number,
  preferredB: number
): PreferencePerformance {
  const zScore = getPreferenceZScore(preferredA, preferredB)
  if (preferredA + preferredB < 10) {
    return {
      zScore,
      percentage: 0,
      confidence: PreferenceConfidence.InsufficientData,
    }
  }
  if (zScore >= 2.58) {
    return {
      zScore,
      percentage: 0.99,
      confidence: PreferenceConfidence.VeryConfident,
    }
  }
  if (zScore >= 1.96) {
    return {
      zScore,
      percentage: 0.95,
      confidence: PreferenceConfidence.Confident,
    }
  }
  if (zScore >= 1.65) {
    return {
      zScore,
      percentage: 0.9,
      confidence: PreferenceConfidence.FairlyConfident,
    }
  }
  return {
    zScore,
    percentage: 0,
    confidence: PreferenceConfidence.NoStatisticalSignificance,
  }
}
