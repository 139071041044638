import Constants from "Constants/shared.json"
import { ScreenshotMediaType } from "Types"
import { humanizeList } from "Utilities/array"

const prettySize = require("prettysize")

export const getMediaType = (mimetype: string): ScreenshotMediaType | null => {
  if (Constants.AUDIO_MIME_TYPES.includes(mimetype)) {
    return "audio"
  }
  if (Constants.VIDEO_MIME_TYPES.includes(mimetype)) {
    return "video"
  }
  if (Constants.IMAGE_MIME_TYPES.includes(mimetype)) {
    return "image"
  }
  return null
}

export const getMimeTypes = (mediaType: ScreenshotMediaType): string[] => {
  switch (mediaType) {
    case "video":
      return Constants.VIDEO_MIME_TYPES
    case "audio":
      return Constants.AUDIO_MIME_TYPES
    case "image":
      return Constants.IMAGE_MIME_TYPES
  }
}

export const getMaximumFilesize = (mediaType: ScreenshotMediaType): number => {
  switch (mediaType) {
    case "video":
      return Constants.MAX_VIDEO_FILESIZE_IN_BYTES
    case "audio":
      return Constants.MAX_AUDIO_FILESIZE_IN_BYTES
    case "image":
      return Constants.MAX_IMAGE_FILESIZE_IN_BYTES
  }
}

const formatMimeTypes = (mimeTypes: ReadonlyArray<string>): string[] =>
  mimeTypes.map((mimeType) => mimeType.split("/")[1].toUpperCase())

export const prettyFilesize = (filesize: number): string =>
  prettySize(filesize, true)

export const prettyMimeTypes = (mimeTypes: ReadonlyArray<string>): string =>
  humanizeList(formatMimeTypes(mimeTypes), "or")
