import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import AsyncStripeProvider from "Components/async-stripe-provider/async-stripe-provider"
import { SubmitButton } from "Components/button/submit-button"
import { CreditCardDeclinedMessage } from "Components/credit-card-declined-message"
import { CreditCardFields } from "Components/credit-card-fields/credit-card-fields"
import { JsForm } from "Components/form/form"
import { CreditCardDeclinedError } from "Services/stripe"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useStripeCards } from "UsabilityHub/hooks/useStripeCards"
import React, { ReactNode, useState } from "react"
import { CardDetails } from "./card-details"

interface Props {
  readonly formattedPrice: string
  readonly heading: string
  readonly submitPurchaseAsync: () => Promise<void>
  readonly onCancel: () => void
}

const ModalPurchaseConfirmationImpl: React.FC<
  React.PropsWithChildren<Props>
> = ({ formattedPrice, heading, onCancel, submitPurchaseAsync }) => {
  const toast = useToast()
  const stripe = useStripe()
  const elements = useElements()
  const { replaceCustomerCard } = useStripeCards()

  const cardLast4 = useCurrentAccount().stripe_card_last4
  const [isCreditCardFormVisible, setIsCreditCardFormVisible] = useState(
    !cardLast4
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [cardName, setCardName] = useState("")

  const showCreditCardForm = () => {
    if (stripe !== null) {
      setIsCreditCardFormVisible(true)
    } else {
      toast({
        status: "error",
        title: `We couldn${"\u2019"}t load the payment service. Please refresh the page and try again.`,
      })
    }
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    toast.closeAll()
    try {
      if (isCreditCardFormVisible) {
        const cardElement = elements ? elements.getElement(CardElement) : null
        await replaceCustomerCard(cardElement, cardName, stripe)
      }
      await submitPurchaseAsync()
    } catch (error) {
      setIsSubmitting(false)
      const errorMessage: ReactNode =
        error instanceof CreditCardDeclinedError ? (
          <CreditCardDeclinedMessage />
        ) : (
          (error.message as string)
        )
      toast({
        status: "error",
        title: errorMessage,
      })
    }
  }

  const onClose = () => {
    setIsCreditCardFormVisible(false)
    onCancel()
  }

  return (
    <Modal size="sm" isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <JsForm onSubmit={handleSubmit}>
            <ModalHeader>
              {heading}
              <Text fontSize="md" fontWeight="normal">
                {formattedPrice} will be charged to your credit card
              </Text>
            </ModalHeader>
            <ModalBody>
              {isCreditCardFormVisible ? (
                <Stack spacing={3}>
                  <Text textAlign="left">
                    This card will be saved to your account.
                  </Text>
                  <CreditCardFields
                    name={cardName}
                    onNameChange={(name) => setCardName(name)}
                  />
                </Stack>
              ) : (
                <Stack mb={2} textAlign="center">
                  <CardDetails cardLast4={cardLast4} />
                  <Button
                    variant="link"
                    colorScheme="teal"
                    onClick={showCreditCardForm}
                  >
                    Change billing details
                  </Button>
                </Stack>
              )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose} isDisabled={isSubmitting} mr={2}>
                Cancel
              </Button>
              <SubmitButton isLoading={isSubmitting} loadingAction="Purchasing">
                Complete purchase
              </SubmitButton>
            </ModalFooter>
          </JsForm>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export const PurchaseConfirmationModal: React.FC<
  React.PropsWithChildren<Props>
> = (props) => (
  <AsyncStripeProvider>
    <ModalPurchaseConfirmationImpl {...props} />
  </AsyncStripeProvider>
)
