import { AccordionButton, AccordionIcon } from "@chakra-ui/react"
import { IconButton } from "DesignSystem/components"
import { PanelOrderCompositeIcon } from "Shared/components/CompositeIcons/CompositeIcons"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import Created from "UsabilityHub/components/Created"
import { InterviewMetrics } from "UsabilityHub/components/InterviewMetrics"
import { RecruitmentSummaryCard } from "UsabilityHub/components/recruitment-summary-card/RecruitmentSummaryCard"
import React from "react"
import { ModeratedStudyOrder } from "~/api/generated/usabilityhubSchemas"
import { RecruitingButton } from "./RecruitingButton"

export const ModeratedStudyOrderSummaryCardHeader: React.FC<{
  order: ModeratedStudyOrder
}> = ({ order }) => {
  const description = order.handpick_panelists ? "Handpick" : "Automatic"

  return (
    <RecruitmentSummaryCard
      isAccordionButton
      icon={<PanelOrderCompositeIcon />}
      title={order.name}
      description={description}
      manage={<RecruitingButton order={order} />}
      metrics={
        <InterviewMetrics
          applicantCounts={order.applicant_counts}
          bookingCounts={order.booking_counts}
          maxBookings={order.requested_response_count}
        />
      }
      profile={
        <Created
          createdAt={order.created_at}
          name={order.creator?.name ?? ""}
          email={order.creator?.email ?? ""}
          avatarUrl={order.creator?.avatar_url ?? ""}
        />
      }
      menu={
        <AccordionButton as={IconButton} boxSize={8} variant="secondary">
          <AccordionIcon
            as={ChevronDownOutlineIcon}
            boxSize={4}
            color="ds.icon.subtle"
          />
        </AccordionButton>
      }
    />
  )
}
