import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { CheckIcon } from "@heroicons/react/outline"
import { Sparkles } from "Components/trial-widget/RandomSparkles"
import Constants from "Constants/shared.json"
import { Heading } from "DesignSystem/components"
import newPanelIllustration from "Images/app-illustrations/lyssna/crown.png"
import { PlanDrawerFeatures } from "Types"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import {
  useCurrentAccount,
  useCurrentPlan,
} from "UsabilityHub/hooks/useCurrentAccount"
import { RecruitPageUsabilityTest } from "UsabilityHub/hooks/useUsabilityTestRecruit"
import { ROUTES } from "UsabilityHub/views/routes"
import {
  RestrictedPaywallFeature,
  listRestrictedPaywallFeatures,
} from "Utilities/usability-test"
import React, { useState } from "react"
import { Plan } from "~/api/generated/usabilityhubSchemas"
import { CornerLabel } from "./CornerLabel"
import { UpgradeUsabilityTestModal } from "./UpgradeUsabilityTestModal"
import { TEST_BUILDER_FEATURES } from "./testBuilderFeatures"

type UpgradeUsabilityTestPaywall = React.FC<{
  usabilityTest: Pick<
    RecruitPageUsabilityTest,
    | "unique_id"
    | "upgraded"
    | "last_estimated_duration_upper_minutes"
    | "has_test_logic"
    | "customize_welcome"
    | "customize_thankyou"
    | "test_recording_types"
  >
  onUpgradeTest: (creditsToBePurchased: number) => void
  visiblePlans: Plan[]
}>

export const UpgradeUsabilityTestPaywall: UpgradeUsabilityTestPaywall = ({
  usabilityTest,
  onUpgradeTest,
  visiblePlans,
}) => {
  const currentAccount = useCurrentAccount()
  const currentPlan = useCurrentPlan()

  const restrictedFeatures = listRestrictedPaywallFeatures(
    usabilityTest,
    currentAccount,
    visiblePlans
  )

  const fitPlans = Object.keys(restrictedFeatures).filter((plan_unique_id) =>
    Object.values(restrictedFeatures[plan_unique_id]).every(
      (featureValue) => featureValue === "required"
    )
  )

  const availablePlans = visiblePlans.filter(
    (plan) => plan.unique_id !== currentPlan?.unique_id
  )

  const availablePlanIds = availablePlans.map((plan) => plan.unique_id)

  const editTestUrl = ROUTES.TEST.EDIT.buildPath({
    testId: usabilityTest.unique_id,
  })

  // Support the few remaining accounts that can upgrade individual tests
  const allowTestUpgrades = currentAccount.allow_test_upgrades
  const [upgradeTestModalOpen, setUpgradeTestModalOpen] = useState(false)
  const creditCount = currentAccount.total_points
  const upgradeCost =
    creditCount > 0
      ? `${Constants.TEST_UPGRADE_COST_IN_CREDITS} credits`
      : `$${Constants.TEST_UPGRADE_COST_IN_CREDITS} USD`
  const onUpgradeTestClick = () => setUpgradeTestModalOpen(true)

  return (
    <Flex
      direction="column"
      align="center"
      justifySelf="center"
      p={10}
      m="-30px auto 0 auto"
      maxWidth="666px"
      textAlign="center"
      fontSize="md"
      color="text.default"
    >
      <Image src={newPanelIllustration} maxW="148px" />
      <Heading as="h1" textStyle="ds.display.emphasized" mt={4}>
        Ready to recruit?
      </Heading>
      <Text mt={4} fontWeight="medium" fontSize={16}>
        You{"\u2019"}re on the {currentPlan!.name} plan, but your test is using{" "}
        {availablePlans.length === 1
          ? `${availablePlans[0].name} plan`
          : "paid"}{" "}
        features. Upgrade to recruit participants.
      </Text>
      <Flex direction="row" gap="22px" mt={10} width="full">
        {visiblePlans.map((plan) => (
          <PlanSummary
            key={plan.unique_id}
            plan={plan}
            restrictedFeatures={restrictedFeatures[plan.unique_id]}
            fitPlans={fitPlans}
            availablePlanIds={availablePlanIds}
          />
        ))}
      </Flex>

      <Stack
        direction={allowTestUpgrades ? "column" : "row"}
        mt={10}
        fontSize={14}
        fontWeight="medium"
        mb={12}
      >
        <Text>Not ready to subscribe, but still want to recruit? </Text>
        {allowTestUpgrades ? (
          <>
            <Box>
              <Link as="button" onClick={onUpgradeTestClick}>
                {`Upgrade single test for ${upgradeCost}`}
              </Link>{" "}
              OR{" "}
              <Link href={editTestUrl}>edit test to remove paid features</Link>
            </Box>
            {upgradeTestModalOpen && (
              <UpgradeUsabilityTestModal
                creditCount={creditCount}
                onUpgrade={onUpgradeTest}
                onCancel={() => setUpgradeTestModalOpen(false)}
              />
            )}
          </>
        ) : (
          <Link display="inline" href={editTestUrl} variant="noUnderline">
            Edit test to remove paid features
          </Link>
        )}
      </Stack>
    </Flex>
  )
}

const PlanSummary: React.FC<{
  plan: Plan
  restrictedFeatures: RestrictedPaywallFeature
  fitPlans: Plan["unique_id"][]
  availablePlanIds: Plan["unique_id"][]
}> = ({ plan, restrictedFeatures, fitPlans, availablePlanIds }) => {
  const isOnlyAvailablePlan =
    availablePlanIds.length === 1 && availablePlanIds[0] === plan.unique_id

  const isCurrentPlan = !availablePlanIds.includes(plan.unique_id)
  const buttonText = isCurrentPlan
    ? "Your current plan"
    : `Explore ${plan.name}`

  const isProPlan = plan.name === "Pro"

  const requestedFeaturesForPlanDrawer: PlanDrawerFeatures = []

  const tooltipContents: Array<string> = []

  return (
    <Flex
      pos="relative"
      flexDirection="column"
      bg="white"
      borderWidth={1}
      borderColor="gray.200"
      rounded="md"
      p={6}
      textAlign="left"
      gap={3}
      color="text.primary"
      width="full"
    >
      {isProPlan && (
        <CornerLabel
          top={4}
          right={4}
          borderRadius="sm"
          background="purple.500"
        >
          {fitPlans.length === 1 && fitPlans[0] === plan.unique_id
            ? "Best fit for you"
            : "Popular"}
        </CornerLabel>
      )}
      <Heading textStyle="ds.display.primary" as="h2">
        {plan.name} plan
      </Heading>
      <List spacing={1} fontSize={14} fontWeight="medium">
        {isProPlan && (
          <ListItem
            key={`${plan.unique_id}_everything_in_basic`}
            display="flex"
            alignItems="center"
            fontWeight="semibold"
          >
            Everything in Basic plus...
          </ListItem>
        )}
        {TEST_BUILDER_FEATURES.map((feature, index) => {
          const featureValue = restrictedFeatures[feature.identifier]
          if ("tooltip" in feature && featureValue === "limited") {
            if (tooltipContents.length > 0) {
              tooltipContents.push(feature.tooltip!.toLowerCase())
            } else {
              tooltipContents.push(feature.tooltip!)
            }
          }

          if (
            "minRequiredPlan" in feature &&
            feature.minRequiredPlan &&
            feature.minRequiredPlan !== plan.unique_id
          )
            return null

          const label = feature.label(plan)

          if (!label && !featureValue) return null

          const isFeatureRequired = featureValue && featureValue === "required"

          if (isFeatureRequired)
            requestedFeaturesForPlanDrawer.push({ feature: feature.feature })

          return (
            <ListItem
              key={`${plan.unique_id}_${index}`}
              display="flex"
              alignItems="center"
              color={isFeatureRequired ? "toast.success" : undefined}
              fontWeight={isFeatureRequired ? "semibold" : undefined}
            >
              {label}
              {isFeatureRequired && (
                <ListIcon as={CheckIcon} ps={1} boxSize={5} fontWeight="bold" />
              )}
            </ListItem>
          )
        })}
      </List>

      {isOnlyAvailablePlan ? (
        <Sparkles>
          <PlanDrawerTrigger
            colorScheme="brand.primary"
            requestedFeatures={requestedFeaturesForPlanDrawer}
            source="test_recruitment_page_upgrade_paywall"
            width="full"
            initialState={{
              screen: "payment-details",
              selectedPlan: plan,
            }}
          >
            {buttonText}
          </PlanDrawerTrigger>
        </Sparkles>
      ) : isCurrentPlan ? (
        <Button isDisabled colorScheme="gray">
          {buttonText}
        </Button>
      ) : !fitPlans.includes(plan.unique_id) ? (
        <Tooltip placement="bottom" label={tooltipContents.join(" and ")}>
          <Button isDisabled colorScheme="gray">
            {buttonText}
          </Button>
        </Tooltip>
      ) : (
        <PlanDrawerTrigger
          colorScheme="brand.primary"
          requestedFeatures={requestedFeaturesForPlanDrawer}
          source="test_recruitment_page_upgrade_paywall"
          initialState={{
            screen: "payment-details",
            selectedPlan: plan,
          }}
        >
          {buttonText}
        </PlanDrawerTrigger>
      )}
    </Flex>
  )
}
