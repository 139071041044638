import { Stack } from "@chakra-ui/react"
import { sortBy } from "lodash"
import React, { useContext } from "react"
import { useSelector } from "react-redux"

import { TestResultsFilterState } from "Components/test-results/context/test-result-filter-state"
import { createPreferenceAnswerFilterCategory } from "Components/test-results/filters/create-filters/preference-answer-filter"
import { getFiltersWithCategory } from "Components/test-results/filters/get-filters/get-filters-with"
import { useAllSectionPreferenceSummaries } from "Components/test-results/hooks/use-preference-summary"
import { QuestionResultsList } from "Components/test-results/question-results/question-results-list"
import { PreferenceTestOptionPerformance } from "Components/test-results/section-results/preference-test-option-performance"
import { ScreenshotPreferenceOverview } from "Components/test-results/section-results/screenshot-preference-overview"
import {
  AnimatedSummaryList,
  SummaryItem,
} from "Components/test-results/section-results/section-screenshot-summary-list"
import { State } from "Redux/app-store"
import { usabilityTestSectionScreenshotsSelector } from "Redux/reducers/screenshots/selectors"
import { WinningPreferenceSummary } from "Types"
import { SectionResultsSectionText } from "UsabilityHub/components/TestResults/SectionResultsLabels"
import {
  useDeviceFramesForSectionContext,
  useSectionContext,
} from "UsabilityHub/contexts"
import { minZip } from "Utilities/array"

import { SectionResultsCard } from "./SectionResultsCard"

export const PreferenceSectionResultsCard: React.FC = () => {
  const { section } = useSectionContext()

  const screenshots = useSelector((state: State) =>
    usabilityTestSectionScreenshotsSelector(state, section)
  )

  const preferenceSummaries = useAllSectionPreferenceSummaries(section)
  const deviceFrame = useDeviceFramesForSectionContext(section)
  // If the user has filtered to exactly one answer, don't show the winner summary since
  // it won't be very meaningful.
  const { testResultsFilters } = useContext(TestResultsFilterState)
  const isFilteredToOneAnswer =
    getFiltersWithCategory(
      testResultsFilters,
      createPreferenceAnswerFilterCategory(section.id)
    ).length === 1

  const sorted = sortBy(
    minZip(section.section_screenshots, preferenceSummaries),
    ([, summary]) => -summary.count
  )

  const winningIndex = preferenceSummaries.findIndex(
    (summary) => summary.isWinner
  )
  const winner =
    winningIndex === -1
      ? null
      : (preferenceSummaries[winningIndex] as WinningPreferenceSummary)

  return (
    <SectionResultsCard>
      <Stack spacing={5}>
        <SectionResultsSectionText />
        <AnimatedSummaryList>
          {sorted.map(([sectionScreenshot, summary]) => (
            <SummaryItem key={sectionScreenshot.id}>
              <ScreenshotPreferenceOverview
                deviceFrame={deviceFrame}
                sectionScreenshot={sectionScreenshot}
                preferenceSummary={summary}
              />
            </SummaryItem>
          ))}
        </AnimatedSummaryList>
        {winner !== null && !isFilteredToOneAnswer && (
          <PreferenceTestOptionPerformance
            performance={winner.performance}
            optionName={screenshots[winningIndex].name}
          />
        )}
      </Stack>
      <QuestionResultsList />
    </SectionResultsCard>
  )
}
