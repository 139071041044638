import { CheckOutlineIcon } from "Shared/icons/untitled-ui/CheckOutlineIcon"
import { SlashCircle01OutlineIcon } from "Shared/icons/untitled-ui/SlashCircle01OutlineIcon"
import React from "react"

import { Icon } from "@chakra-ui/react"
import { FilterComparator } from "Types"

export const IS: FilterComparator = {
  value: "eq",
  labelSingle: "is",
  labelMulti: "is any of",
  icon: <Icon as={CheckOutlineIcon} color="brand.neutral.light" />,
}

export const IS_NOT: FilterComparator = {
  value: "neq",
  labelSingle: "is not",
  labelMulti: "is not",
  icon: <Icon as={SlashCircle01OutlineIcon} color="brand.neutral.light" />,
}

export const INCLUDES_ANY: FilterComparator = {
  value: "include_any",
  labelSingle: "includes",
  labelMulti: "includes any of",
}

export const INCLUDES_ALL: FilterComparator = {
  value: "include_all",
  labelSingle: "includes",
  labelMulti: "includes all of",
  onlyShowForMulti: true,
}

export const EXCLUDES_ANY: FilterComparator = {
  value: "exclude_any",
  labelSingle: "does not include",
  labelMulti: "exclude if any of",
}

export const EXCLUDES_ALL: FilterComparator = {
  value: "exclude_all",
  labelSingle: "does not include",
  labelMulti: "exclude if all",
  onlyShowForMulti: true,
}

// ts-prune-ignore-next
export const CONTAINS: FilterComparator = {
  value: "contains",
  labelSingle: "contains",
  labelMulti: "contains",
}

// ts-prune-ignore-next
export const DOES_NOT_CONTAIN: FilterComparator = {
  value: "not_contains",
  labelSingle: "does not contain",
  labelMulti: "does not contain",
}

export const BEFORE: FilterComparator = {
  value: "lt",
  labelSingle: "before",
  labelMulti: "before",
}

export const AFTER: FilterComparator = {
  value: "gt",
  labelSingle: "after",
  labelMulti: "after",
  default: true,
}
