import { Heading, Stack, Text } from "@chakra-ui/react"
import React from "react"

import { Coupon } from "Types"
import { centsToDollars, formatDollars } from "Utilities/currency"

type CouponCode = React.FC<
  React.PropsWithChildren<{
    coupon: Coupon
  }>
>

const COUPON_DURATION_OPTIONS = (durationInMonths: number) => ({
  repeating: `discounts your first ${durationInMonths} monthly renewals by`,
  forever: "discounts all subscription payments by",
  once: "discounts your first subscription payment by",
})

export const CouponCode: CouponCode = ({ coupon }) => {
  // This is the coupon the account is associated with, and will be applied
  // when they subscribe. We reset this association to nil in
  // Subscriptions::HandleStripeEvent when the account subscribes, or changes
  // plans
  return (
    <Stack>
      <Heading>Coupon code</Heading>
      <Text>
        This coupon will be automatically applied when you subscribe to a paid
        plan:
      </Text>
      <Text>
        <Text as="span" fontWeight="bold">
          {coupon.stripe_id}
        </Text>{" "}
        &ndash;{" "}
        {COUPON_DURATION_OPTIONS(coupon.duration_in_months)[coupon.duration]}{" "}
        {coupon.percent_off
          ? `${coupon.percent_off}%`
          : formatDollars(centsToDollars(coupon.amount_off ?? 0))}
      </Text>
    </Stack>
  )
}
