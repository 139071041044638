import {
  Button,
  Center,
  HStack,
  Progress,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react"
import React from "react"

type Metadata = {
  name: string
  url: string
}

type UploadingTileProps = {
  metadata: Metadata | null
  onAbort?: () => void
  progress?: number
  uploading?: boolean
}

const UploadingTile: React.FC<UploadingTileProps> = ({
  metadata,
  onAbort,
  progress,
  uploading,
}) => (
  <HStack backgroundColor="grey.light" p={4} gap={4}>
    <Center overflow={"hidden"} borderRadius="sm" h={"88px"} flexShrink={0}>
      {/* biome-ignore lint/a11y/useMediaCaption: this is a placeholder so there are no captions available */}
      <video
        preload="metadata"
        src={metadata?.url || ""}
        className="ScreenshotEditor-image"
      />
    </Center>
    <VStack w="full" h="full" gap={2}>
      <HStack w="full" h="full">
        <VStack alignItems="flex-start" gap={1}>
          <Text
            color="text.default"
            fontSize={16}
            fontWeight="semibold"
            lineHeight={6}
          >
            {metadata?.name || ""}
          </Text>
          <Text color="text.secondary" fontSize={14} lineHeight={5}>
            Upload is in progress
          </Text>
        </VStack>
        <Spacer />
        {onAbort && (
          <Button
            as="label"
            colorScheme="brand.primary"
            size="sm"
            variant="solid"
            cursor="pointer"
            alignSelf={"start"}
            onClick={onAbort}
          >
            {/* uploading only comes from our upload, not the autofetch */}
            {uploading ? "Cancel upload" : "Cancel auto-fetch"}
          </Button>
        )}
      </HStack>
      <Spacer />
      <Progress
        marginY={3}
        variant="round"
        colorScheme="brand.primary"
        size="sm"
        value={progress}
        aria-valuenow={progress}
        isIndeterminate={progress == null}
      />
    </VStack>
  </HStack>
)

export default UploadingTile
