import {
  Box,
  Button,
  Flex,
  Icon,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react"
import { FireIcon } from "@heroicons/react/solid"
import cx from "classnames"
import React, { FC, useRef, useState } from "react"
import { connect } from "react-redux"

import { ClickMapOld } from "Components/click-map/click-map-old"
import { ExpandableRegion } from "Components/expandable-region/expandable-region"
import { HitzoneEditor, Theme } from "Components/hitzone-editor/hitzone-editor"
import { HitzoneMap } from "Components/hitzone-map/hitzone-map"
import { ScreenshotOverlayBackdrop } from "Components/screenshot-overlay-backdrop/screenshot-overlay-backdrop"
import { UnscaledScreenshot } from "Components/screenshot/UnscaledScreenshot/UnscaledScreenshot"
import { MaintainScreenshotAspectRatioContainer } from "Components/screenshot/maintain-screenshot-aspect-ratio-container"
import { ClickSelectionSummaryList } from "Components/test-results/screenshot-click-results/click-selection-summary-list"
import styles from "Components/test-results/screenshot-click-results/screenshot-click-results.module.scss"
import { showError } from "Redux/reducers/flash"
import {
  ClickSelection,
  ClientId,
  ClientIdRectangle,
  DeviceFrame,
  ImageScreenshot,
  ScreenshotClick,
  ScreenshotHitzone,
  UsabilityTestSectionScreenshot,
} from "Types"
import { downloadPng } from "Utilities/dom-to-image"
import { reportErrorToSentry } from "Utilities/error"

import { useAccountEnabledFeature } from "Hooks/use-account-disabled-feature"
import { CursorClick01SolidIcon } from "Shared/icons/untitled-ui/CursorClick01SolidIcon"
import { Download01OutlineIcon } from "Shared/icons/untitled-ui/Download01OutlineIcon"
import { Image03SolidIcon } from "Shared/icons/untitled-ui/Image03SolidIcon"
import { PlaySquareSolidIcon } from "Shared/icons/untitled-ui/PlaySquareSolidIcon"
import { RECORDING_KEY } from "UsabilityHub/views/settings/FeatureAccessPage/FeatureAccessForm"
import { useTrackEvent } from "~/api/generated/usabilityhub-components"
import { ClickHeatMapOld } from "../click-heat-map-old"
import { RecordingsTab } from "../section-results/SectionResultsCards/Recordings/RecordingsTab"
import { useSectionRecordings } from "../section-results/SectionResultsCards/Recordings/useSectionRecordings"

const TabId = {
  HeatMap: 0,
  ClickMap: 1,
  Image: 2,
  Recordings: 3,
} as const

interface Props {
  clicks: ReadonlyArray<ScreenshotClick>
  hitzones: ReadonlyArray<ScreenshotHitzone>
  screenshot: ImageScreenshot
  clickSelections: ReadonlyArray<ClickSelection>
  constrainWidthToDeviceFrame: Readonly<DeviceFrame> | null
  onCreateClickSelection: (clickSelection: ClientIdRectangle) => void
  onChangeClickSelection: (clickSelection: ClientIdRectangle) => void
  onRemoveClickSelection: (clientId: ClientId) => void
  filterHitzoneIds: ReadonlyArray<number>
  filterClickSelectionClientIds: ReadonlyArray<ClientId>
  sectionScreenshot: Readonly<UsabilityTestSectionScreenshot>
  onError: (message: string) => void
}

const ScreenshotClickResultsImpl: FC<Props> = ({
  hitzones,
  filterHitzoneIds,
  filterClickSelectionClientIds,
  clicks,
  clickSelections,
  screenshot,
  sectionScreenshot,
  constrainWidthToDeviceFrame,
  onError,
  onCreateClickSelection,
  onChangeClickSelection,
  onRemoveClickSelection,
}) => {
  const { mutate: trackEvent } = useTrackEvent()

  const [selectedTabId, setSelectedTabId] = useState<0 | 1 | 2 | 3>(
    clicks.length > 0 ? TabId.HeatMap : TabId.Image
  )
  const [isExporting, setIsExporting] = useState(false)

  const screenshotContainerRef = useRef<HTMLDivElement | null>(null)

  const exportImage = async () => {
    if (isExporting) return

    const ref = screenshotContainerRef.current

    let event
    switch (selectedTabId) {
      case TabId.ClickMap:
        event = "account_exported_click_map"
        break
      case TabId.HeatMap:
        event = "account_exported_heat_map"
        break
      case TabId.Recordings:
        throw new TypeError("Exporting recordings is not supported")
      case TabId.Image:
        throw new TypeError("Exporting plain image is not supported")
    }

    if (ref) {
      setIsExporting(true)
      try {
        trackEvent({
          body: {
            event,
            event_properties: {
              usability_test_section_screenshot_id: sectionScreenshot.id,
            },
          },
        })
        await downloadPng(ref, screenshot.name)
      } catch (e) {
        onError(
          "Sorry, we were unable to export your results. Please try again."
        )
        reportErrorToSentry(e)
      } finally {
        setIsExporting(false)
      }
    }
  }

  const isFaded =
    selectedTabId !== TabId.Recordings &&
    (clickSelections.length > 0 || selectedTabId !== TabId.Image)
  const hideBackground = selectedTabId === TabId.Recordings
  const isEmptyResultSet = clicks.length === 0
  const exportButtonText =
    selectedTabId === TabId.ClickMap
      ? "Export click map as image"
      : "Export heat map as image"

  const recordings = useSectionRecordings()
  const recordingFeatureEnabled = useAccountEnabledFeature(RECORDING_KEY)
  const showRecordingsTab = recordings.length > 0 && recordingFeatureEnabled

  return (
    <Box>
      <Tabs
        onChange={(index) => setSelectedTabId(index as 0 | 1 | 2 | 3)}
        isLazy
      >
        <TabList color="gray.600">
          <Tab data-qa="heat-map-tab">
            <Icon
              as={FireIcon}
              mr={2}
              display={{ base: "none", xl: "block" }}
            />
            <Text>Heat map</Text>
          </Tab>
          <Tab data-qa="click-map-tab">
            <Icon
              as={CursorClick01SolidIcon}
              mr={2}
              display={{ base: "none", xl: "block" }}
            />
            <Text>Click map</Text>
          </Tab>
          <Tab data-qa="image-tab">
            <Icon
              as={Image03SolidIcon}
              mr={2}
              display={{ base: "none", xl: "block" }}
            />
            <Text>Image</Text>
          </Tab>
          {showRecordingsTab && (
            <Tab data-qa="recording-tab">
              <Icon
                as={PlaySquareSolidIcon}
                mr={2}
                display={{ base: "none", xl: "block" }}
              />
              <Text>Recordings {recordings.length}</Text>
            </Tab>
          )}
          <Spacer />
          {selectedTabId !== TabId.Image &&
            selectedTabId !== TabId.Recordings && (
              <Button
                size="sm"
                colorScheme="brand.primary"
                fontWeight="normal"
                isDisabled={isExporting || isEmptyResultSet}
                isLoading={isExporting}
                loadingText={exportButtonText}
                onClick={exportImage}
              >
                <Icon as={Download01OutlineIcon} boxSize="12px" mr={2} />
                {exportButtonText}
              </Button>
            )}
        </TabList>
        <ExpandableRegion
          maxHeight={400}
          expandText="Expand image"
          collapseText="Collapse image"
          disabled={hideBackground}
        >
          <Flex justifyContent="center" py={6}>
            <MaintainScreenshotAspectRatioContainer
              ref={screenshotContainerRef}
              className={styles.screenshotContainer}
              screenshot={screenshot}
              constrainWidthToDeviceFrame={constrainWidthToDeviceFrame}
              disabled={hideBackground}
            >
              {!hideBackground && (
                <UnscaledScreenshot
                  className={styles.layer}
                  screenshot={screenshot}
                  canSkipAheadOnFirstWatch
                />
              )}
              {isFaded && (
                <ScreenshotOverlayBackdrop>
                  {clicks.length === 0 && (
                    <p className={cx("para", styles.noData)}>
                      No clicks to display
                    </p>
                  )}
                </ScreenshotOverlayBackdrop>
              )}
              <TabPanels>
                <TabPanel>
                  <ClickHeatMapOld
                    clicks={clicks}
                    width={screenshot.width}
                    height={screenshot.height}
                  />
                </TabPanel>
                <TabPanel>
                  <ClickMapOld clicks={clicks} />
                </TabPanel>
                <TabPanel />
                {showRecordingsTab && (
                  <TabPanel p={0}>
                    <RecordingsTab />
                  </TabPanel>
                )}
              </TabPanels>
              {!hideBackground && (
                <>
                  {hitzones.length > 0 && (
                    <HitzoneMap
                      hitzones={hitzones}
                      filterHitzoneIds={filterHitzoneIds}
                      isFilteringByClickSelection={
                        filterClickSelectionClientIds.length > 0
                      }
                    />
                  )}
                  <HitzoneEditor
                    key="selection"
                    className={styles.selectionEditor}
                    hitzones={clickSelections}
                    onCreateHitzone={onCreateClickSelection}
                    onHitzoneChange={onChangeClickSelection}
                    onRemoveHitzone={onRemoveClickSelection}
                    showDisplayNumbers
                    displayNumberOffset={hitzones.length}
                    filterClientIds={filterClickSelectionClientIds}
                    isFilteringByHitzones={filterHitzoneIds.length > 0}
                    theme={Theme.SelectionEditor}
                  />
                </>
              )}
            </MaintainScreenshotAspectRatioContainer>
          </Flex>
        </ExpandableRegion>
      </Tabs>
      {!hideBackground && (
        <ClickSelectionSummaryList
          sectionScreenshotId={sectionScreenshot.id}
          hitzones={hitzones}
          clickSelections={clickSelections}
          screenshot={screenshot}
        />
      )}
    </Box>
  )
}

export const ScreenshotClickResultsOld = connect(null, { onError: showError })(
  ScreenshotClickResultsImpl
)
