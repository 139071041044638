import { createIcon } from "@chakra-ui/react"
import React from "react"

export const AlertOctagonSolidIcon = createIcon({
  displayName: "AlertOctagonSolidIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.52269 0.999947L8.47165 0.999855C8.28174 0.999348 8.0426 0.998709 7.80727 1.05521C7.6032 1.1042 7.40811 1.18501 7.22916 1.29467C7.02281 1.42112 6.85417 1.59067 6.72024 1.72531L6.68421 1.76147L1.76147 6.68421L1.72531 6.72024C1.59067 6.85416 1.42112 7.02281 1.29467 7.22916C1.18501 7.40811 1.1042 7.6032 1.05521 7.80727C0.998709 8.0426 0.999348 8.28174 0.999855 8.47165L0.999947 8.52269V15.4772L0.999855 15.5282C0.999348 15.7182 0.998709 15.9573 1.05521 16.1926C1.1042 16.3967 1.18501 16.5918 1.29467 16.7707C1.42112 16.9771 1.59067 17.1457 1.72532 17.2797L1.76147 17.3157L6.68421 22.2384L6.72024 22.2746C6.85417 22.4092 7.02281 22.5788 7.22916 22.7052C7.40811 22.8149 7.6032 22.8957 7.80727 22.9447C8.0426 23.0012 8.28174 23.0005 8.47166 23L8.52269 22.9999H15.4772L15.5282 23C15.7181 23.0005 15.9573 23.0012 16.1926 22.9447C16.3967 22.8957 16.5918 22.8149 16.7707 22.7052C16.9771 22.5788 17.1457 22.4092 17.2797 22.2746L17.3157 22.2384L22.2384 17.3157L22.2746 17.2797C22.4092 17.1457 22.5788 16.9771 22.7052 16.7707C22.8149 16.5918 22.8957 16.3967 22.9447 16.1926C23.0012 15.9573 23.0005 15.7181 23 15.5282L22.9999 15.4772V8.52269L23 8.47166C23.0005 8.28174 23.0012 8.0426 22.9447 7.80727C22.8957 7.6032 22.8149 7.40811 22.7052 7.22916C22.5788 7.02282 22.4092 6.85417 22.2746 6.72025L22.2384 6.68421L17.3157 1.76147L17.2797 1.72531C17.1457 1.59067 16.9771 1.42112 16.7707 1.29467C16.5918 1.18501 16.3967 1.1042 16.1926 1.05521C15.9573 0.998709 15.7182 0.999348 15.5282 0.999855L15.4772 0.999947H8.52269ZM13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12V8ZM12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H12.01C12.5623 17 13.01 16.5523 13.01 16C13.01 15.4477 12.5623 15 12.01 15H12Z"
      fill="currentColor"
    />
  ),
})
