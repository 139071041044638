import {
  SectionTypeRules,
  getSectionTypeRules,
} from "Constants/test-section-types"
import { UsabilityTestSection, UsabilityTestSectionType } from "Types"

export const getResultSectionTypeRules = (
  section: UsabilityTestSection
): SectionTypeRules => {
  const sectionTypeRules = getSectionTypeRules(section.type)

  if (section.type !== UsabilityTestSectionType.PrototypeTask) {
    return sectionTypeRules
  }
  const suffix =
    section.prototype_type === "task_flow" ? " - Task flow" : " - Free flow"
  return {
    ...sectionTypeRules,
    defaultTitle: sectionTypeRules.defaultTitle + suffix,
  }
}
