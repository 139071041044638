import React from "react"
import { connect } from "react-redux"

import { ClickMapOld } from "Components/click-map/click-map-old"
import {
  ClippedThumbnail,
  Duration,
  Name,
  thumbnailDimensions,
} from "Components/test-results/progress-box/progress-box"
import { ExpandableSectionScreenshotSummary } from "Components/test-results/section-results/expandable-section-screenshot-summary"
import { State } from "Redux/app-store"
import { getScreenshotWithId } from "Redux/reducers/screenshots/selectors"
import {
  ImageScreenshot,
  Rectangle,
  ScreenshotClick,
  UsabilityTestSectionScreenshot,
} from "Types"

interface Props {
  readonly screenshot: Readonly<ImageScreenshot>
  readonly click: Readonly<ScreenshotClick>
  readonly clickIndex: number
}

export function clickClippingRectangle(
  click: Readonly<ScreenshotClick>,
  screenshot: Readonly<ImageScreenshot>
): Rectangle {
  const clipMarginX =
    thumbnailDimensions.width / (screenshot.width * screenshot.display_scale)
  const clipMarginY =
    thumbnailDimensions.height / (screenshot.height * screenshot.display_scale)

  return {
    xa: click.x - clipMarginX,
    xb: click.x + clipMarginX,
    ya: click.y - clipMarginY,
    yb: click.y + clipMarginY,
  }
}

const ClickSummaryImpl = ({ screenshot, click, clickIndex }: Props) => {
  const summaryNode = (
    <>
      <ClippedThumbnail
        screenshot={screenshot}
        clippingRectangle={clickClippingRectangle(click, screenshot)}
      >
        <ClickMapOld
          clicks={[{ id: click.id, x: 0.5, y: 0.5, hit: click.hit }]}
        />
      </ClippedThumbnail>
      <Name>Click {clickIndex + 1}</Name>
      <Duration duration={click && click.duration_ms} />
    </>
  )

  return (
    <ExpandableSectionScreenshotSummary
      isExpanded={false}
      summaryChildren={summaryNode}
      detailsChildren={null}
      isFilter={false}
      isFiltered={false}
    />
  )
}

interface OwnProps {
  readonly sectionScreenshot: UsabilityTestSectionScreenshot
}

export const ClickSummaryOld = connect(
  (state: State, { sectionScreenshot }: OwnProps) => ({
    screenshot: getScreenshotWithId(state, sectionScreenshot.screenshot_id),
  })
)(ClickSummaryImpl)
