import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"
import { DefaultValues, Path, Resolver, useForm } from "react-hook-form"
import { AnyObject } from "yup"
import { useOnboardingContext } from "./OnboardingContextProvider"
import { OnboardingStep } from "./types"

type UseOnboardingStepOptions<T extends AnyObject> = {
  defaultValues?: DefaultValues<T>
}

export function useOnboardingStep<ID extends string, T extends AnyObject>(
  step: OnboardingStep<ID, T>,
  {
    defaultValues = step.data as DefaultValues<T>,
  }: UseOnboardingStepOptions<T> = {}
) {
  const { onDirty } = useOnboardingContext()

  const form = useForm<T>({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(step.schema) as unknown as Resolver<T>,
  })

  useEffect(() => {
    // It might seem simpler to use form.reset(step.data)
    // but this causes problems if the user clicks "continue"
    // without changing anything.

    for (const [key, value] of Object.entries(step.data)) {
      form.setValue(key as Path<T>, value)
    }
  }, [step.data, form])

  useEffect(() => {
    onDirty(form.getValues())
    const { unsubscribe } = form.watch(() => {
      onDirty(form.getValues())
    })
    return () => unsubscribe()
  }, [onDirty, form])

  return form
}
