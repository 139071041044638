import {
  Flex,
  Icon,
  IconButton,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react"
import { FaceFrownOutlineIcon } from "Shared/icons/untitled-ui/FaceFrownOutlineIcon"
import { FaceHappyOutlineIcon } from "Shared/icons/untitled-ui/FaceHappyOutlineIcon"
import { FaceNeutralOutlineIcon } from "Shared/icons/untitled-ui/FaceNeutralOutlineIcon"
import React from "react"

// Keep this in sync with the model validation in ModeratedStudyBookingRating
const RATING_OPTIONS = [
  ["poor", "Poor", FaceFrownOutlineIcon],
  ["neutral", "Neutral", FaceNeutralOutlineIcon],
  ["good", "Good", FaceHappyOutlineIcon],
] as const

export type Rating = (typeof RATING_OPTIONS)[number][0]

type Props = {
  rating: Rating | undefined
  setRating: (rating: Rating) => void
  comment: string
  setComment: (comment: string) => void
}

export const ParticipantRatingForm: React.FC<Props> = ({
  rating,
  setRating,
  comment,
  setComment,
}) => {
  return (
    <Flex direction="column" gap={2}>
      <Text fontSize="md" fontWeight="medium" color="text.primary">
        Rate participant quality
      </Text>

      <Flex gap={2}>
        {RATING_OPTIONS.map(([value, label, iconComponent]) => {
          return (
            <Tooltip
              key={value}
              hasArrow
              rounded="md"
              placement="top"
              label={label}
            >
              <IconButton
                boxSize={12}
                variant="outline"
                icon={<Icon boxSize={5} color="gray.500" as={iconComponent} />}
                aria-label={label}
                outline={value === rating ? "2px solid" : undefined}
                outlineColor={value === rating ? "blue.500" : undefined}
                onClick={() => setRating(value as Rating)}
              />
            </Tooltip>
          )
        })}
      </Flex>

      <Text fontSize="md" fontWeight="medium" color="text.primary">
        Please tell us why you gave that rating
      </Text>

      <Textarea
        value={comment}
        placeholder="Write your comments here"
        onChange={(e) => setComment(e.target.value)}
      />
    </Flex>
  )
}
