import { useListDisabledFeatures } from "~/api/generated/usabilityhub-components"
import { FeatureName } from "~/api/generated/usabilityhubSchemas"

const useAccountDisabledFeatureLoading = (featureName: FeatureName) => {
  const { data, isLoading, isError } = useListDisabledFeatures(
    {},
    { retry: false, cacheTime: Infinity, staleTime: Infinity }
  )

  if (isLoading || isError) {
    return { loading: true, enabled: undefined } as const
  }

  return {
    loading: false,
    enabled: !data.disabled_features.includes(featureName),
  } as const
}

/**
 * Get the value of a feature that customers can disable for their account.
 */
export const useAccountEnabledFeature = (featureName: FeatureName): boolean => {
  const { loading, enabled } = useAccountDisabledFeatureLoading(featureName)

  return loading ? false : enabled
}
