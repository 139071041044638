import { Box, Collapse, HStack } from "@chakra-ui/react"
import { CommentThread } from "Components/comment-thread/CommentThread"
import { Dispatch } from "Redux/app-store"
import {
  getFormName,
  getFormValue,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { getId } from "Redux/reducers/test-builder/selectors/test-form"
import { useTranslate } from "Shared/hooks/useTranslate"
import {
  TestCard,
  TestCardActions,
  TestCardBody,
  TestCardHeader,
  TestCardHeading,
  TestCardTitle,
} from "UsabilityHub/components/TestCard/TestCard"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { change } from "redux-form"
import { CustomizableScreenToggle } from "../CustomizableScreenToggle"
import { PaidFeatureIndicator } from "../PaidFeatureIndicator"
import { WelcomeScreenFields } from "./WelcomeScreenFields"

const COMMENTABLE_ENTITY = {
  entityContext: "test_builder",
  entityType: "welcome",
} as const

export const WelcomeScreenCard: React.FC = () => {
  const dispatch = useDispatch<Dispatch>()
  const t = useTranslate()

  const isEnabled = useSelector(getFormValue("customize_welcome")) as boolean
  const usabilityTestId = useSelector(getId)
  const { readOnly } = useUsabilityTestUserActivityContext()
  const screenType = "welcome"

  const { welcome_button_text, welcome_heading, welcome_message } =
    useSelector(getFormValues)

  // When enabling the welcome screen for the first time, we'll populate the fields with
  // default values based on the currently selected language.
  // (The relevant LocaleProvider is in <TestFormFields />)
  const onEnable = () => {
    if (welcome_button_text || welcome_heading || welcome_message) return

    const defaultWelcomeHeading = t("test.welcome.welcome_heading")
    const defaultWelcomeMessage = t("test.welcome.welcome_message")
    const defaultWelcomeButton = t("test.welcome.welcome_button_text")

    dispatch(change(getFormName(), "welcome_heading", defaultWelcomeHeading))
    dispatch(change(getFormName(), "welcome_message", defaultWelcomeMessage))
    dispatch(change(getFormName(), "welcome_button_text", defaultWelcomeButton))
  }

  return (
    <TestCard id="welcome-screen-card" commentableEntity={COMMENTABLE_ENTITY}>
      <TestCardHeader>
        <TestCardTitle>
          <TestCardHeading>Welcome screen</TestCardHeading>
        </TestCardTitle>
        <TestCardActions>
          <HStack>
            <PaidFeatureIndicator />
            <CustomizableScreenToggle
              disabled={readOnly}
              screenType={screenType}
              onEnable={onEnable}
            />
          </HStack>
        </TestCardActions>

        <CommentThread
          entity={COMMENTABLE_ENTITY}
          isEntityPersisted={usabilityTestId !== null}
        />
      </TestCardHeader>
      <Box
        sx={
          isEnabled
            ? {
                // Chakra's <Collapse> below adds overflow: hidden which cuts off the side of
                // the inputs. It's only needed during the animations so this is a workaround.
                // See: https://github.com/chakra-ui/chakra-ui/issues/2966
                ".chakra-collapse": {
                  overflow: "initial !important",
                },
              }
            : {}
        }
      >
        <Collapse in={isEnabled} unmountOnExit>
          <TestCardBody>
            <WelcomeScreenFields />
          </TestCardBody>
        </Collapse>
      </Box>
    </TestCard>
  )
}
