import React, { ReactElement } from "react"

type WrapIf = React.FC<
  React.PropsWithChildren<{
    children: ReactElement
    condition: boolean
    wrapper: (children: ReactElement) => ReactElement
  }>
>

export const WrapIf: WrapIf = ({ children, condition, wrapper }) =>
  condition ? wrapper(children) : children
