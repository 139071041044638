import { useToast } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { debounce } from "lodash"
import { useCallback, useMemo } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import {
  PutModeratedStudyMeetingUrlRequestBody,
  usePutModeratedStudyMeetingUrl,
} from "~/api/generated/usabilityhub-components"
import { ModeratedStudy } from "~/api/generated/usabilityhubSchemas"
import { FORM_SAVE_DEBOUNCE_TIME, GENERIC_FORM_ERROR_TOAST } from "./common"

export type LocationType =
  PutModeratedStudyMeetingUrlRequestBody["location_type"]
const MeetingUrlFormSchema = Yup.object().shape(
  {
    location_type: Yup.string<LocationType>()
      .oneOf(["none", "zoom", "teams", "custom"])
      .required(),
    meeting_url: Yup.string().when("meeting_url", (val) =>
      val.length > 0
        ? Yup.string().url("Please enter a valid URL")
        : Yup.string().notRequired()
    ),
    auto_upload_recordings: Yup.boolean().required(),
  },
  [["meeting_url", "meeting_url"]]
)

type MeetingUrlFormFields = Yup.InferType<typeof MeetingUrlFormSchema>

export const useMeetingUrlForm = (moderatedStudy: ModeratedStudy) => {
  const toast = useToast()
  const form = useForm({
    resolver: yupResolver(MeetingUrlFormSchema),
    defaultValues: {
      location_type: moderatedStudy.location_type ?? "none",
      meeting_url: moderatedStudy.meeting_url ?? "",
      auto_upload_recordings:
        moderatedStudy.auto_upload_recordings ??
        moderatedStudy.location_type === "zoom",
    },
  })

  const { handleSubmit, reset, getValues } = form

  const { mutate: editMeetingUrl } = usePutModeratedStudyMeetingUrl({
    onSuccess: () => reset(getValues(), { keepValues: true }),
    onError: () => toast(GENERIC_FORM_ERROR_TOAST),
  })

  const onMeetingUrlSubmit: SubmitHandler<MeetingUrlFormFields> = useCallback(
    (data) => {
      editMeetingUrl({
        body: {
          location_type: data.location_type,
          meeting_url: data.meeting_url || "",
          auto_upload_recordings:
            data.auto_upload_recordings && data.location_type === "zoom",
        },
        pathParams: { moderatedStudyId: moderatedStudy.id },
      })
    },
    [editMeetingUrl, moderatedStudy.id]
  )

  const debouncedMeetingUrlSubmit = useMemo(
    () => debounce(handleSubmit(onMeetingUrlSubmit), FORM_SAVE_DEBOUNCE_TIME),
    [handleSubmit, onMeetingUrlSubmit]
  )

  return {
    ...form,
    handleSubmit: debouncedMeetingUrlSubmit,
  }
}
