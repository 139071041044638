import {
  trackCreditsPurchased,
  trackTestUpgraded,
} from "JavaScripts/analytics/track"
import { State } from "Redux/app-store"
import { showErrorMessage, showNoticeMessage } from "Redux/reducers/flash"
import { ActionType } from "Redux/reducers/usability-tests/constants"
import { axios, isDeprecatedAxiosError } from "Services/axios"
import { throwIfDeclinedErrorMessage } from "Services/stripe"
import {
  Account,
  AsyncThunkAction,
  BasicAction,
  Meta,
  PayloadAction,
  ShallowUsabilityTest,
} from "Types"
import { reportErrorToSentry } from "Utilities/error"
import UsabilityTestsApi from "~/api/usabilityTestsApi"

// -- Types --

type UsabilityTestMeta = Meta<{ id: number }>
type UsabilityTestAction<T extends ActionType> = BasicAction<T> &
  UsabilityTestMeta

// Init

type InitUsabilityTests = PayloadAction<
  ActionType.INIT_USABILITY_TESTS,
  ReadonlyArray<ShallowUsabilityTest>
>

// Update

type UpdateUsabilityTestRequestAction =
  UsabilityTestAction<ActionType.UPDATE_USABILITY_TEST_REQUEST>
type UpdateUsabilityTestSuccessAction = PayloadAction<
  ActionType.UPDATE_USABILITY_TEST_SUCCESS,
  Partial<ShallowUsabilityTest>
> &
  UsabilityTestMeta
type UpdateUsabilityTestFailureAction =
  UsabilityTestAction<ActionType.UPDATE_USABILITY_TEST_FAILURE>

// Reload
type ReloadUsabilityTestSuccessAction = PayloadAction<
  ActionType.RELOAD_USABILITY_TEST_SUCCESS,
  Partial<ShallowUsabilityTest>
> &
  UsabilityTestMeta

// Duplicate

type DuplicateUsabilityTestRequestAction =
  UsabilityTestAction<ActionType.DUPLICATE_USABILITY_TEST_REQUEST>
type DuplicateUsabilityTestFailureAction =
  UsabilityTestAction<ActionType.DUPLICATE_USABILITY_TEST_FAILURE>

// Union

export type Actions =
  | InitUsabilityTests
  | UpdateUsabilityTestRequestAction
  | UpdateUsabilityTestSuccessAction
  | UpdateUsabilityTestFailureAction
  | ReloadUsabilityTestSuccessAction
  | DuplicateUsabilityTestRequestAction
  | DuplicateUsabilityTestFailureAction

// -- Action creators --

export const initUsabilityTests = (
  tests: ReadonlyArray<ShallowUsabilityTest>
) => {
  return {
    type: ActionType.INIT_USABILITY_TESTS,
    payload: tests,
  }
}

export const updateUsabilityTestRequest = (
  id: number
): UpdateUsabilityTestRequestAction => {
  return {
    meta: { id },
    type: ActionType.UPDATE_USABILITY_TEST_REQUEST,
  }
}

export const updateUsabilityTestSuccess = (
  id: number,
  newAttributes: Partial<ShallowUsabilityTest>
): UpdateUsabilityTestSuccessAction => {
  return {
    meta: { id },
    payload: newAttributes,
    type: ActionType.UPDATE_USABILITY_TEST_SUCCESS,
  }
}

export const updateUsabilityTestFailure = (
  id: number
): UpdateUsabilityTestFailureAction => {
  return {
    meta: { id },
    type: ActionType.UPDATE_USABILITY_TEST_FAILURE,
  }
}

interface UpgradeResponse {
  message: string
  account: Account
  usability_test: ShallowUsabilityTest
  purchase?: {
    credit_count: number
    price_in_cents: number
  }
}

export const upgradeUsabilityTest =
  (uniqueId: string, creditsToBePurchased: number): AsyncThunkAction<State> =>
  async (dispatch) => {
    const url = UsabilityTestsApi.upgrade.path({ id: uniqueId })
    const params = {
      credits_to_be_purchased: creditsToBePurchased,
    }
    try {
      const { data } = await axios.post<UpgradeResponse>(url, params)
      trackTestUpgraded(data.usability_test)
      if (data.purchase) {
        trackCreditsPurchased(
          "test_upgrade",
          data.purchase.credit_count,
          data.purchase.price_in_cents
        )
      }
      dispatch(showNoticeMessage(data.message))
      // We need to refresh currentAccount and usabilityTest via the hook, so
      // that will have to happen at the callsite. Later once we de-redux this
      // we can batch it all together.
    } catch (error) {
      if (
        isDeprecatedAxiosError<string>(error) &&
        error.response !== undefined
      ) {
        throwIfDeclinedErrorMessage(error.response.data)
        throw new Error(error.response.data)
      }
      throw error
    }
  }

export const unarchiveUsabilityTest =
  (testUniqueId: string, testName: string): AsyncThunkAction<State> =>
  async (dispatch) => {
    const url = UsabilityTestsApi.unarchive.path({ id: testUniqueId })

    try {
      // NOTE: This response body is no longer used
      await axios.put(url)
      dispatch(showNoticeMessage(`Unarchived test "${testName}"`))
    } catch (error) {
      dispatch(
        showErrorMessage(
          `There was an error unarchiving the test "${testName}"`
        )
      )
      reportErrorToSentry(error)
    }
  }
