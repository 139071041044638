// These types are enforced on the backend too so if you make any changes
// you will also need to apply them in Comments::AddUsabilityTestComment
export type EntityContext = "test_builder" | "test_results"
type StandaloneEntityType = "screener" | "welcome" | "thank_you"
type ParametricEntityType =
  | "usability_test_section"
  | "usability_test_section_question"
  | "comment"

type StandaloneEntity = {
  entityContext: "test_builder"
  entityType: StandaloneEntityType
}
type ParametricEntity = {
  entityContext: EntityContext
  entityType: ParametricEntityType
  entityId: string
}

export type EntityIdentifier = StandaloneEntity | ParametricEntity

const entityHasId = (
  entityIdentifer: EntityIdentifier
): entityIdentifer is ParametricEntity => {
  return "entityId" in entityIdentifer
}

export const entityIdOrNull = (
  entityIdentifier: EntityIdentifier
): string | null => {
  if (!entityHasId(entityIdentifier)) return null

  return entityIdentifier.entityId
}

export const entityIdentifiersMatch = (
  ident1: EntityIdentifier,
  ident2: EntityIdentifier
) => {
  return (
    ident1.entityContext === ident2.entityContext &&
    ident1.entityType === ident2.entityType &&
    entityIdOrNull(ident1) === entityIdOrNull(ident2)
  )
}

export const serializeEntity = (entity: EntityIdentifier) =>
  `${entity.entityType}:${entityIdOrNull(entity) ?? ""}`
