import { ResponseFilter } from "../filters/types"

// ts-prune-ignore-next used in test
export function getFiltersWithoutCategories<
  Filters extends ResponseFilter,
  FilterCategory extends Filters["category"],
>(responseFilters: Filters[], filterCategories: FilterCategory[]) {
  return responseFilters.filter(
    (responseFilter) =>
      !filterCategories.some((category) => category === responseFilter.category)
  ) as Exclude<Filters, { category: FilterCategory }>[]
}

export function getFiltersWithoutCategory<
  Filters extends ResponseFilter,
  FilterCategory extends Filters["category"],
>(responseFilters: Filters[], filterCategory: FilterCategory) {
  return getFiltersWithoutCategories<Filters, FilterCategory>(responseFilters, [
    filterCategory,
  ])
}
