import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import React from "react"

import { useMutableQueryString } from "Shared/hooks/useMutableQueryString"

import { Button, IconButton } from "DesignSystem/components"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { FilterLinesOutlineIcon } from "Shared/icons/untitled-ui/FilterLinesOutlineIcon"
import { SORT_OPTIONS } from "./sorting"
import { useViewMenuParams } from "./useViewMenuParams"

interface Props {
  iconOnly?: boolean
}

export const SortMenu: React.FC<Props> = ({ iconOnly = false }) => {
  const [, setSearchParams] = useMutableQueryString()
  const { sortBy, sortDirection } = useViewMenuParams()

  return (
    <Menu>
      {iconOnly ? (
        <MenuButton as={IconButton} icon={<FilterLinesOutlineIcon />} />
      ) : (
        <MenuButton
          as={Button}
          leftIcon={<FilterLinesOutlineIcon />}
          rightIcon={<ChevronDownOutlineIcon />}
        >
          Sort
        </MenuButton>
      )}
      <MenuList overflow="hidden">
        {SORT_OPTIONS.map(([field, direction, label]) => {
          return (
            <MenuItem
              key={`${field}-${direction}`}
              bg={
                sortBy === field && sortDirection === direction
                  ? "gray.100"
                  : "white"
              }
              onClick={() => {
                setSearchParams({
                  sort: field,
                  sort_dir: direction,
                })
              }}
            >
              {label}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}
