import React, { useRef } from "react"

import { useModalContext } from "./modal-context"
import { StandardModalProps } from "./types"

const MODAL_CLOSE_DELAY_MS = 300

const useUniqueKey = () => {
  const key = useRef(Math.random().toString())

  return key.current
}

type UseModalReturn<Props> = {
  open: (props: Props) => void
  close: () => void
}

/**
 * The `useModal` hook is used to trigger a modal. The implementation as well
 * as the state are abstracted away from the component where it is used. Any
 * logic (forms, validation, submit) concerning the modal will be encapsulated
 * in the modal itself.
 *
 * These modal are not routable, and are not accessible globally. That means
 * the modal state is local to the hook it was used by and cannot be opened
 * or closed from any other place.
 *
 * The same hook and modal may be used in multiple places to avoid repetition,
 * but they will be isolated from each other resulting in two open modal should
 * they be opened at the same time.
 *
 * The hook takes the modal component as a parameter and uses the ChakraUI hook
 * `useDisclosure` to host the state.
 *
 * @example
 *  const MyComponent: React.FC<Props> = (props) => {
 *    const { open: openModal } = useModal(DeleteConfirmationModal)
 *
 *    return (
 *      <>
 *        <header>
 *          <h2>Foo</h2>
 *          <button onClick={openModal}>Delete</button>
 *        </header>
 *        <section>Here is some content</section>
 *      </>
 *    )
 *  }
 */
export function useModal<GenericModalProps>(
  Modal: React.ComponentType<
    React.PropsWithChildren<StandardModalProps & GenericModalProps>
  >,
  providedKey?: string
): UseModalReturn<GenericModalProps> {
  const { setModals } = useModalContext()

  const defaultKey = useUniqueKey()
  const key = providedKey ?? defaultKey

  const handleOpen = (props: GenericModalProps) => {
    setModals((modals) => [
      ...modals,
      <Modal key={key} {...props} onClose={handleClose} isOpen />,
    ])
  }

  const handleClose = () => {
    // When we close the modal, replace it with a version that has isOpen=false first
    // This allows the exit animation to play before we wait MODAL_CLOSE_DELAY_MS and then
    // remove it from the react tree completely.
    setModals((modals) =>
      modals.map((modal) =>
        modal.key === key ? (
          <Modal key={key} {...modal.props} isOpen={false} />
        ) : (
          modal
        )
      )
    )

    setTimeout(() => {
      setModals((modals) => modals.filter((modal) => modal.key !== key))
    }, MODAL_CLOSE_DELAY_MS)
  }

  return {
    open: handleOpen,
    close: handleClose,
  }
}
