import {
  Button,
  Flex,
  Heading,
  Input,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react"
import React, { ChangeEvent, useState } from "react"

import { PurchaseCreditBatchButton } from "Components/payments/purchase-credit-batch-button/purchase-credit-batch-button"
import { Card, CardBody } from "Shared/components/Card/Card"
import { priceForCredits } from "Utilities/credit-scale"
import { centsToDollars, formatDollars } from "Utilities/currency"
import { formatPercentage01 } from "Utilities/number"
import {
  ListCreditBatchesResponse,
  useListCreditBatches,
} from "~/api/generated/usabilityhub-components"
import { Account } from "~/api/generated/usabilityhubSchemas"

type QuickBuyButton = React.FC<{
  batch: ListCreditBatchesResponse["batches"][number]
  baseCreditPrice: number
  setAmountToBuy: (amount: number) => void
}>

const QuickBuyButton: QuickBuyButton = ({
  batch,
  setAmountToBuy,
  baseCreditPrice,
}) => {
  const discount = 1 - batch.price / batch.amount / baseCreditPrice
  const formattedDiscount = formatPercentage01(discount)

  return (
    <Button
      size="sm"
      variant="outline"
      onClick={() => setAmountToBuy(batch.amount)}
    >
      {batch.amount} credits
      {discount > 0 && ` (${formattedDiscount} off)`}
    </Button>
  )
}

const BuyCreditsLoadingState: React.FC = () => {
  return (
    <Card>
      <CardBody direction="row" flexWrap="wrap" spacing={0}>
        <Stack
          flex="1 1"
          flexBasis={{ base: "100%", md: "50%" }}
          pr={{ base: 0, md: 4, lg: 8 }}
        >
          <Skeleton height="20px" />
        </Stack>
        <Stack
          flex="0 1"
          flexBasis={{ base: "100%", md: "50%" }}
          pl={{ base: 0, md: 4, lg: 8 }}
          pt={{ base: 8, md: 0 }}
          spacing={3}
        >
          <Skeleton height="20px" />
        </Stack>
      </CardBody>
    </Card>
  )
}

type BuyCredits = React.FC<{
  currentAccount: Account
}>

export const BuyCredits: BuyCredits = ({ currentAccount }) => {
  const [amountToBuy, setAmountToBuy] = useState<number>(0)
  const { data: creditBatches } = useListCreditBatches({})

  if (!creditBatches) return <BuyCreditsLoadingState />

  // Full cost of a single credit for this account.
  const baseCreditPrice = currentAccount.base_credit_price
  // Cost of each credit including any batch discounts.
  const discountedCreditPrice = priceForCredits(
    creditBatches.batches,
    amountToBuy,
    baseCreditPrice
  )

  // Percentage discount in whole numbers (eg. 20% off).
  const discountPercentage = Math.round(
    (1 - discountedCreditPrice / baseCreditPrice) * 100
  )
  // Only show discount details if there is one.
  const showDiscount = discountPercentage > 0

  // Summed costs to show as line items.
  const fullCost = baseCreditPrice * amountToBuy
  const actualCost = discountedCreditPrice * amountToBuy
  const discountedCost = fullCost - actualCost

  const onAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Only allow numbers to be typed in by only updating the state if the input is a set of numbers.
    const re = /^[0-9\b]+$/
    if (e.target.value === "") {
      setAmountToBuy(0)
    } else if (re.test(e.target.value)) {
      setAmountToBuy(parseInt(e.target.value, 10))
    }
  }

  return (
    <Card>
      <CardBody direction="row" flexWrap="wrap" spacing={0}>
        <Stack
          flex="1 1"
          flexBasis={{ base: "100%", md: "50%" }}
          pr={{ base: 0, md: 4, lg: 8 }}
        >
          <Heading as="h3" size="md">
            How many?
          </Heading>
          <Input
            placeholder="1000"
            value={amountToBuy || ""}
            onChange={onAmountChange}
            maxLength={5}
            textAlign="right"
            mb={5}
            data-qa="credits-to-buy"
          />
          <Flex justifyContent="space-between">
            <Text>
              {amountToBuy} credits x{" "}
              {formatDollars(centsToDollars(baseCreditPrice))}
            </Text>
            <Text>{formatDollars(centsToDollars(fullCost))}</Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            textColor={showDiscount ? "green.600" : ""}
          >
            <Text>Discount {showDiscount && `(${discountPercentage}%)`}</Text>
            <Text>
              {showDiscount && "-"}
              {formatDollars(centsToDollars(discountedCost))}
            </Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            fontWeight="bold"
            pt={1}
            borderColor="gray.300"
            borderTopWidth={1}
          >
            <Text>Total (USD)</Text>
            <Text>{formatDollars(centsToDollars(actualCost))}</Text>
          </Flex>
          <Flex justifyContent="center">
            <PurchaseCreditBatchButton
              width="100%"
              mt={1}
              buttonText="Checkout"
              isDisabled={!amountToBuy}
              creditCount={amountToBuy}
              price={actualCost}
            />
          </Flex>
        </Stack>
        <Stack
          flex="0 1"
          flexBasis={{ base: "100%", md: "50%" }}
          pl={{ base: 0, md: 4, lg: 8 }}
          pt={{ base: 8, md: 0 }}
          spacing={3}
        >
          <Heading as="h3" size="md">
            Quick buy
          </Heading>
          {creditBatches.batches
            .sort((a, b) => a.amount - b.amount)
            .map((batch) => (
              <QuickBuyButton
                key={batch.amount}
                batch={batch}
                baseCreditPrice={baseCreditPrice}
                setAmountToBuy={setAmountToBuy}
              />
            ))}
        </Stack>
      </CardBody>
    </Card>
  )
}
