import { Filter } from "Components/test-results/types/filters"

import { getFilterMeta } from "../get-filter-meta"

import { FilterFunction } from "./types"

export type ExactAnswerFilter = Filter<
  `answer/section:${number}/question:${number}/answer`,
  "answer/exact-answer",
  string | null
>

export const filterResponseByExactAnswer: FilterFunction<ExactAnswerFilter> = ({
  filter,
  filteredRelatedData: { responseAnswers },
}) => {
  const { questionId } = getFilterMeta(filter.category)

  const filteredAnswers = responseAnswers.filter(
    (responseAnswer) =>
      responseAnswer.usability_test_section_question_id === questionId
  )

  return filteredAnswers.some((responseAnswer) => {
    if (responseAnswer.answer) {
      return responseAnswer.answer === filter.value
    }

    if (responseAnswer.answers.length) {
      return responseAnswer.answers.some((answer) => answer === filter.value)
    }

    return false
  })
}
