import { useEffect } from "react"

import { getEnvState } from "JavaScripts/state"
import { FigmaMessage } from "JavaScripts/types/figma-file-version-answer"

import { usePrototypeTaskContext } from "./PrototypeTaskContext"

export const useFigmaMessages = (): void => {
  const prototypeTask = usePrototypeTaskContext()
  useEffect(() => {
    const handleIFrameMessage = (event: MessageEvent<FigmaMessage>) => {
      if (event.origin === getEnvState().FIGMA_DOMAIN) {
        prototypeTask.onFigmaMessageEvent(event.data)
      }
    }
    window.addEventListener("message", handleIFrameMessage, false)
    return () => {
      window.removeEventListener("message", handleIFrameMessage)
    }
  }, [prototypeTask])
}
