import { DataPoint } from "heatmap.js"
import { countBy, mean } from "lodash"
import React from "react"

import { HeatMapOld } from "Components/heat-map-old/heat-map-old"
import { Click } from "Types"

/* Slice up the data in squares of size `gridSize`, then count the number of
 * clicks that fall within each grid.
 */
const countByGrid = (data: ReadonlyArray<DataPoint>, gridSize: number) => {
  return countBy(
    data,
    (datum) =>
      `${Math.floor(datum.x / gridSize)},${Math.floor(datum.y / gridSize)}`
  )
}

/* First estimate density by counting how many clicks landed in each
 * radius-length-sided grid square in the target area. Then find an
 * average of these values.
 */
const estimateDataMax = (data: ReadonlyArray<DataPoint>, radius: number) => {
  if (data.length === 0) return 1
  const present = Object.values(countByGrid(data, radius))
  return mean(present)
}

interface ClickHeatMapProps {
  clicks: ReadonlyArray<Click>
  width: number
  height: number
}

export const ClickHeatMapOld: React.FC<
  React.PropsWithChildren<ClickHeatMapProps>
> = ({ width, height, clicks }) => {
  const radius = (30 * width) / 1000

  // Set the clicks individually (so we don't have to calculate the max value)
  const data = clicks.map(
    (click): DataPoint => ({
      value: 1,
      x: Math.floor(click.x * width),
      y: Math.floor(click.y * height),
    })
  )
  const max = estimateDataMax(data, radius)

  return (
    <HeatMapOld
      radius={radius}
      data={data}
      width={width}
      height={height}
      max={max}
    />
  )
}
