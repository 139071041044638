import { escapeRegExp, identity } from "lodash"

/**
 * Find an exact match from a list of elements.
 *
 * @param items elements to match against
 * @param searchTerm search input
 * @param getLabel fn that returns the string to match from a tag
 */
export function findExact<T>(
  items: ReadonlyArray<T>,
  searchTerm: string,
  getLabel: (tag: T) => string
): T | null {
  const regex = RegExp(`^${escapeRegExp(searchTerm.trim())}$`, "i")
  return items.find((tag) => regex.test(getLabel(tag))) || null
}

/**
 * Search a set of elements
 * @param items elements to match against
 * @param searchTerm search input
 * @param getLabel fn that returns the string to match from a tag
 */
export function textSearch<T>(
  items: ReadonlyArray<T>,
  searchTerm: string,
  getLabel: (tag: T) => string
): ReadonlyArray<T> {
  const regexes = searchTerm
    .trim()
    .split(/\s+/g)
    .filter(identity)
    .map((term) => RegExp(escapeRegExp(term), "i"))

  if (regexes.length === 0) {
    return items
  }

  return items.filter((tag) => {
    const label = getLabel(tag)
    return regexes.every((r) => r.test(label))
  })
}
