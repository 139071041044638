import { BasicAction, PayloadAction, RawParticipantUsabilityTest } from "Types"

export enum Type {
  INIT_USABILITY_TEST_DATA = "INIT_USABILITY_TEST_DATA",
  LOAD_USABILITY_TEST_REQUEST = "LOAD_USABILITY_TEST_REQUEST",
  LOAD_USABILITY_TEST_SUCCESS = "LOAD_USABILITY_TEST_SUCCESS",
  LOAD_USABILITY_TEST_FAILURE = "LOAD_USABILITY_TEST_FAILURE",
}

// This was previously serialized into the page at load time, but we're now getting it via an API
// so we need an action to get it into the store asynchronously.
export type InitUsabilityTestData = PayloadAction<
  Type.INIT_USABILITY_TEST_DATA,
  RawParticipantUsabilityTest
>
export type LoadUsabilityTestRequest =
  BasicAction<Type.LOAD_USABILITY_TEST_REQUEST>
export type LoadUsabilityTestSuccess =
  BasicAction<Type.LOAD_USABILITY_TEST_SUCCESS>
export type LoadUsabilityTestFailure =
  BasicAction<Type.LOAD_USABILITY_TEST_FAILURE>
