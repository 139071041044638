import { UsabilityTestSection } from "Types"
import React, { PropsWithChildren, createContext, useContext } from "react"
import { UseTreeOptions, useTree } from "./useTree"

type TreeProviderProps = UseTreeOptions & {
  section: UsabilityTestSection
  onClearCorrectNodes: () => void
}

type TreeContextShape = ReturnType<typeof useTree> & {
  section: UsabilityTestSection
  clearCorrectNodes: () => void
}

export const TreeContext = createContext({} as TreeContextShape)

export const useTreeContext = () => useContext(TreeContext)

const TreeProvider: React.FC<PropsWithChildren<TreeProviderProps>> = ({
  section,
  children,
  onClearCorrectNodes,
  ...options
}) => {
  const { ...value } = useTree(options)

  return (
    <TreeContext.Provider
      value={{
        ...value,
        section,
        clearCorrectNodes: onClearCorrectNodes,
      }}
    >
      {children}
    </TreeContext.Provider>
  )
}

export default TreeProvider
