import {
  Box,
  Center,
  Flex,
  HStack,
  Select,
  Spinner,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import { Alert, Button, Heading } from "DesignSystem/components"
import { useLanguages } from "Hooks/use-languages"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import React from "react"
import { useNavigate } from "react-router"
import { PanelEstimateResponse } from "~/api/generated/usabilityhub-components"
import { DemographicAttributeGroup } from "~/api/generated/usabilityhubSchemas"
import { DemographicTargetingSummary } from "../NewOrderPage/DemographicTargetingSummary"
import { useOrderForm } from "../NewOrderPage/OrderFormProvider"
import { QuoteState } from "../NewOrderPage/types"
import { StudyType } from "./Route"

interface Props {
  demographics: DemographicAttributeGroup[]
  languageCode: string
  setLanguageCode: (languageCode: string) => void
  studyType: StudyType
  setStudyType: (studyType: StudyType) => void
  quote: PanelEstimateResponse | null
  quoteState: QuoteState
}

export const UhSummaryPanel: React.FC<React.PropsWithChildren<Props>> = ({
  demographics,
  languageCode,
  setLanguageCode,
  studyType,
  setStudyType,
  quote,
  quoteState,
}) => {
  const navigate = useNavigate()
  const languages = useLanguages()

  const { selectedOptionIds, clearAllOptionsForAttribute } = useOrderForm()

  const studyTypes = {
    short_survey: "Short survey (5-10 questions)",
    long_survey: "Long survey (20-50 questions)",
    preference_test: "Preference test (4 designs)",
    figma_prototype_test: "Figma prototype test (10 screens)",
    open_card_sort: "Open card sort (20 cards)",
  }

  const activeDemographicAttributes = demographics.flatMap((group) => {
    return group.demographic_attributes
      .map((attribute) => ({
        ...attribute,
        options: attribute.options?.filter((option) =>
          selectedOptionIds.includes(option.id)
        ),
      }))
      .filter((attribute) => attribute.options?.length)
  })

  return (
    <Box h="fit-content" bg="white" p={6} shadow="ds.raised" rounded="16px">
      <Stack spacing={4} data-intercom-target="uh-summary">
        <Heading as="h2" textStyle="ds.display.primary">
          Order summary
        </Heading>

        <StackDivider borderTopWidth="1px" borderColor="ds.border.default" />

        {languages.length > 0 && (
          <>
            <HStack>
              <Text
                flexShrink={0}
                as="label"
                width={32}
                textStyle="label"
                htmlFor="language_code"
                fontSize="md"
                display="inline-flex"
                alignItems="center"
              >
                <Text me={1}>Language</Text>
                <HelpPopover>
                  The language panelists need to understand to take the study.
                </HelpPopover>
              </Text>
              <Select
                isRequired
                id="language_code"
                value={languageCode}
                onChange={(e) => {
                  setLanguageCode(e?.target?.value)
                }}
              >
                {languages.map(({ display_string, code }) => (
                  <option key={code} value={code}>
                    {display_string}
                  </option>
                ))}
              </Select>
            </HStack>

            <StackDivider
              borderTopWidth="1px"
              borderColor="ds.border.default"
            />

            <HStack>
              <Text
                flexShrink={0}
                as="label"
                width={32}
                textStyle="label"
                htmlFor="study_type"
                fontSize="md"
                display="inline-flex"
                alignItems="center"
              >
                <Text me={1}>Study type</Text>
                <HelpPopover>
                  Examples of different studies you can create. You can create
                  studies of any composition and complexity in the app.
                </HelpPopover>
              </Text>
              <Select
                isRequired
                id="study_type"
                value={studyType}
                onChange={(e) => {
                  setStudyType(e?.target?.value as StudyType)
                }}
              >
                {Object.entries(studyTypes).map(([type, display_string]) => (
                  <option key={type} value={type}>
                    {display_string}
                  </option>
                ))}
              </Select>
            </HStack>

            <StackDivider
              borderTopWidth="1px"
              borderColor="ds.border.default"
            />
          </>
        )}

        <DemographicTargetingSummary
          showPresets={false}
          activeDemographicAttributes={activeDemographicAttributes}
          clearDemographicAttribute={clearAllOptionsForAttribute}
        />

        <Stack
          color={quoteState === "loading" ? "gray.400" : "ds.text.default"}
        >
          {quote ? (
            <>
              <Flex
                flexDirection="column"
                p={6}
                gap={2}
                bg="ds.background.neutral.resting"
                rounded="12px"
              >
                <Flex
                  justify="space-between"
                  data-intercom-target="cost-per-response"
                >
                  <Box>Cost per response</Box>
                  <Text textAlign="end">
                    {quote.formatted_price_per_response}
                  </Text>
                </Flex>
                <Flex justify="space-between" data-intercom-target="total-cost">
                  <Text textStyle="ds.display.primary" minW="6em">
                    Total cost
                  </Text>
                  <Text textStyle="ds.display.primary" textAlign="end">
                    {quote.formatted_price}
                  </Text>
                </Flex>
              </Flex>

              {quote.estimated_duration ? (
                <Box pt={4} data-intercom-target="estimated-turnaround-time">
                  <Alert
                    status="info"
                    description={
                      <>
                        <Flex align="center" gap={1}>
                          <Text>Estimated turnaround time:</Text>
                          {quoteState === "loading" ? (
                            <Spinner size="xs" />
                          ) : (
                            <Text>{quote.estimated_duration}</Text>
                          )}
                        </Flex>
                      </>
                    }
                  />
                </Box>
              ) : (
                <Alert
                  status="warning"
                  description={`We can\u2019t fulfill that order from the Lyssna panel.`}
                />
              )}
            </>
          ) : quoteState === "error" ? (
            <Alert
              status="danger"
              description="There was an error calculating your quote. Please try again."
            />
          ) : (
            <Stack justify="center" align="center" h="192px">
              <Spinner />
            </Stack>
          )}
        </Stack>

        <Stack>
          <Box pb={2}>
            <Button
              variant="primary"
              size="emphasized"
              w="100%"
              onClick={() => navigate(`/users/sign_up`)}
            >
              Sign Up
            </Button>
          </Box>
          <Center>Create a free account to place an order </Center>
        </Stack>
      </Stack>
    </Box>
  )
}
