import { Flex, Text } from "@chakra-ui/react"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import { XCircleSolidIcon } from "Shared/icons/untitled-ui/XCircleSolidIcon"
import React from "react"

export const CanceledOrCompleteLabel: React.FC<{
  state: "canceled" | "auto_canceled" | "complete"
}> = ({ state }) => {
  const icon =
    state === "complete" ? (
      <CheckCircleSolidIcon color="ds.icon.success" />
    ) : (
      <XCircleSolidIcon color="ds.icon.danger" />
    )
  const text = (
    <Text textStyle="ds.interface.medium">
      {state === "complete" ? "Complete" : "Canceled"}
    </Text>
  )

  return (
    <Flex alignItems="center" gap={2}>
      {icon}
      {text}
    </Flex>
  )
}
