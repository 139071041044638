import { keyTrueBy } from "Utilities/array"
import { Dictionary, countBy, flatMap, words as getWords, reject } from "lodash"

type CountByWord = Dictionary<number>

export interface Datum {
  count: number
  size: number
  text: string
  x0: number
  x1: number
  x: number
  y0: number
  y1: number
  y: number
}

// Deliberately leaving in % and $, since these might have meaning in a word cloud.
const PUNCTUATION =
  /[\u2000-\u206F\u2E00-\u2E7F\\!"#&()*+,\-./:;<=>?@[\]^_`{|}~\s]/g

const PREFIXES = ["not"]
const WORDS_REGEX = new RegExp(`((${PREFIXES.join("|")})\\s+)?[^\\s]+`, "g")
const COMMON_WORDS = keyTrueBy([
  `a`,
  `an`,
  `and`,
  `as`,
  `at`,
  `be`,
  `but`,
  `by`,
  `for`,
  `i`,
  `if`,
  `in`,
  `is`,
  `it's`,
  `it`,
  `its`,
  `my`,
  `of`,
  `on`,
  `or`,
  `so`,
  `than`,
  `that`,
  `the`,
  `this`,
  `to`,
])

function isCommonWord(word: string): boolean {
  return COMMON_WORDS[word] || false
}

function sentenceToWords(sentence: string): string[] {
  if (sentence == null) {
    return []
  }

  const words = getWords(
    sentence.toLowerCase().replace(PUNCTUATION, " "),
    WORDS_REGEX
  )

  // If there is only one word, return it without filtering out common words.
  // This is explicitly to allow passing 'A', 'B', 'C' etc in a multiple-choice
  // style click test.
  return words.length <= 1 ? words : reject(words, isCommonWord)
}

export function sentencesToCountByWord(sentences: string[] = []): CountByWord {
  const words = flatMap(sentences, sentenceToWords)
  return countBy(words)
}

export function countByWordToData(countByWord: CountByWord): Datum[] {
  const words = Object.keys(countByWord)
  return words.reduce((result, text) => {
    const count = countByWord[text]
    result.push({ count, text } as Datum)
    return result
  }, [] as Datum[])
}

interface Bounds {
  minX: number
  minY: number
  maxX: number
  maxY: number
}

const DEFAULT_BOUNDS: Bounds = {
  maxX: 1,
  maxY: 1,
  minX: -1,
  minY: -1,
}

/**
 * @example
 *
 * const bounds = getBounds(wordCloud.words());
 * // { minX: -10, minY: -20, maxX: 100, maxY -10 }
 */
export function getBounds(words: ReadonlyArray<Datum>): Bounds {
  if (words.length === 0) {
    return DEFAULT_BOUNDS
  }

  return words.reduce(
    (bounds, { x, x0, x1, y, y0, y1 }) => {
      if (x + x0 < bounds.minX) bounds.minX = x + x0
      if (x + x1 > bounds.maxX) bounds.maxX = x + x1
      if (y + y0 < bounds.minY) bounds.minY = y + y0
      if (y + y1 > bounds.maxY) bounds.maxY = y + y1
      return bounds
    },
    {
      minX: Infinity,
      maxX: -Infinity,
      minY: Infinity,
      maxY: -Infinity,
    } as Bounds
  )
}
