import React from "react"

import Constants from "Constants/shared.json"

import { ExternalLink } from "./ExternalLink"

type HelpCenterLink = React.FC<
  React.PropsWithChildren<{
    articleSlug?: string
    children: string
  }>
>

export const HelpCenterLink: HelpCenterLink = ({ articleSlug, children }) => {
  return (
    <ExternalLink
      href={
        articleSlug
          ? `${Constants.HELP_CENTER_URL}/en/articles/${articleSlug}`
          : Constants.HELP_CENTER_URL
      }
    >
      {children}
    </ExternalLink>
  )
}
