import { Link, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { Plan } from "~/api/generated/usabilityhubSchemas"
import { ConfirmResumeSubscriptionModal } from "./ConfirmResumeSubscriptionModal"

type ConfirmResumeSubscriptionLink = React.FC<{
  currentPlan: Plan
  subscriptionId: string
  subscriptionRenewalTime: string
}>

export const ConfirmResumeSubscriptionLink: ConfirmResumeSubscriptionLink = ({
  currentPlan,
  subscriptionId,
  subscriptionRenewalTime,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const currentPlanUniqueId = currentPlan.unique_id
  const currentPlanName = currentPlan.name

  return (
    <>
      <Link as="button" onClick={onOpen}>
        Resume your subscription
      </Link>
      <ConfirmResumeSubscriptionModal
        isOpen={isOpen}
        onClose={onClose}
        currentPlanUniqueId={currentPlanUniqueId}
        currentPlanName={currentPlanName}
        subscriptionId={subscriptionId}
        subscriptionRenewalTime={subscriptionRenewalTime}
      />
    </>
  )
}
