import { Button, useToast } from "@chakra-ui/react"
import { JsForm } from "Components/form/form"
import { isBadRequestError } from "Services/axios"
import { useRefreshCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import React, { useState } from "react"
import { apiSubscriptions } from "~/api"

interface Props {
  readonly buttonText: string
  readonly planUniqueId: string
  readonly subscriptionId: string
  readonly onClose: () => void
}
export const ChangeSubscriptionPlanForm: React.FC<Props> = ({
  buttonText,
  onClose,
  planUniqueId,
  subscriptionId,
}) => {
  const toast = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const refreshCurrentAccount = useRefreshCurrentAccount()

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      const data = await apiSubscriptions.update<{ message: string }>({
        params: {
          guid: subscriptionId,
        },
        data: {
          plan_unique_id: planUniqueId,
          resume_subscription: true,
        },
      })
      onClose()
      // Tell the user their plan has been updated.
      toast({
        status: "success",
        title: data.message,
      })
      // Update the subscription and account state so we can show the new plan details immediately.
      refreshCurrentAccount()
    } catch (error) {
      if (isBadRequestError(error)) {
        toast({
          status: "error",
          title: error.response.data.message,
        })
        setIsSubmitting(false)
      } else {
        throw error
      }
    }
  }

  return (
    <JsForm onSubmit={handleSubmit}>
      <Button onClick={onClose} mr={3}>
        Cancel
      </Button>
      <Button
        type="submit"
        colorScheme="brand.primary"
        isLoading={isSubmitting}
      >
        {buttonText}
      </Button>
    </JsForm>
  )
}
