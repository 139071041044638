import { RecruitmentLinkState } from "Types"
import type { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"

export function recruitmentLinkStateWithRecruitmentLink(
  recruitedResponseCount: number,
  recruitmentLink: RecruitmentLink
): RecruitmentLinkState {
  // If the link is enabled, it is "collecting" unless it's reached its
  // response limit.
  if (recruitmentLink.active) {
    const isComplete =
      recruitmentLink.enable_response_limit &&
      recruitedResponseCount >= (recruitmentLink.response_limit || 0)
    return isComplete
      ? RecruitmentLinkState.Complete
      : RecruitmentLinkState.Collecting
  }

  // A link with a limit is considered "canceled" if it has not reached its
  // limit once enabled.
  //
  // NOTE: This logic works because you can't set a limit without enabling the
  //       link at least once.
  if (recruitmentLink.enable_response_limit) {
    return recruitedResponseCount >= (recruitmentLink.response_limit || 0)
      ? RecruitmentLinkState.Complete
      : RecruitmentLinkState.Canceled
  }

  // Otherwise we just call any disabled link that has received a response
  // "complete"
  return recruitedResponseCount > 0
    ? RecruitmentLinkState.Complete
    : RecruitmentLinkState.Disabled
}
