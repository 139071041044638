import { Icon, Spinner, Tooltip } from "@chakra-ui/react"
import { ExclamationIcon } from "@heroicons/react/solid"
import React from "react"

type ReactQueryState = {
  isFetching: boolean
  isError: boolean
}

type Props = {
  query: ReactQueryState
}

export const DashboardLoadingIndicator: React.FC<Props> = ({ query }) => {
  return (
    <>
      {query.isFetching && <Spinner data-qa="dashboard-spinner" size="sm" />}
      {query.isError && (
        <Tooltip
          hasArrow
          rounded="md"
          label="An error occurred while fetching data."
        >
          <Icon as={ExclamationIcon} color="red.500" />
        </Tooltip>
      )}
    </>
  )
}
