import { useContext } from "react"

import {
  ScreenResultDetailsContext,
  ScreenResultDetailsContextType,
} from "./ScreenResultsProvider"

export function useClickViewState(): [
  ScreenResultDetailsContextType["view"],
  ScreenResultDetailsContextType["setView"],
] {
  const { view, setView } = useContext(ScreenResultDetailsContext)

  return [view, setView]
}
