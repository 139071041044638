import classNames from "classnames"
import React, { CSSProperties, Ref } from "react"

import styles from "Components/click-map/click-map.module.scss"
import { Point } from "Types"
import { percentage } from "Utilities/css-helpers"

export enum ClickType {
  Default,
  Hit,
  Miss,
}

interface Props {
  readonly clickType?: ClickType
  readonly point: Point
  readonly innerRef?: Ref<HTMLDivElement>
}

function position({ x, y }: Point): CSSProperties {
  return {
    left: percentage(x),
    top: percentage(y),
  }
}

export const ClickMarkerOld = ({ point, clickType, innerRef }: Props) => {
  const className = classNames(styles.clickMarker, {
    [styles.isHit]: clickType === ClickType.Hit,
    [styles.isMiss]: clickType === ClickType.Miss,
  })
  return <div ref={innerRef} className={className} style={position(point)} />
}
