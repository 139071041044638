import { Flex, Skeleton, Spacer, Stack } from "@chakra-ui/react"
import React from "react"

import { PrototypeType } from "JavaScripts/types/figma-file-flow"

import { FigmaFlowFileNameTitle } from "../PrototypeSectionFigmaFlow"

interface FigmaFlowSkeletonProps {
  prototypeType?: PrototypeType
}

export const FigmaFlowSkeleton: React.FC<FigmaFlowSkeletonProps> = ({
  prototypeType = "task_flow",
}) => {
  return (
    <Stack spacing={4}>
      <Flex>
        <FigmaFlowFileNameTitle>
          <Skeleton height="16px" width="128px" />
        </FigmaFlowFileNameTitle>
        <Spacer />
        <Skeleton height="16px" width="324px" />
      </Flex>
      <Flex alignItems="center">
        <Skeleton height="32px" width="330px" />
        <Spacer />
        <Skeleton height="32px" width="173px" marginStart={2} />
        <Skeleton height="32px" width="103px" marginStart={2} />
      </Flex>
      <Flex
        rounded="md"
        border="1px"
        borderColor="gray.200"
        bgColor="gray.50"
        overflowX="auto"
      >
        <Flex
          flexBasis="full"
          direction="column"
          align="center"
          p={8}
          pb={{ task_flow: 14, free_roam: 8 }[prototypeType]}
          gap={4}
        >
          <Flex w="full" justify="center" flexBasis="110px">
            <Skeleton
              rounded="md"
              p={2}
              flexBasis="400px"
              flexShrink={1}
              gap={2}
            />
            {prototypeType === "task_flow" && (
              <>
                <Spacer />
                <Skeleton
                  rounded="md"
                  p={2}
                  flexBasis="400px"
                  flexShrink={1}
                  gap={2}
                />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Stack>
  )
}
