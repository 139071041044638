import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react"
import { AlertCircleSolidIcon } from "Shared/icons/untitled-ui/AlertCircleSolidIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import React from "react"
import {
  CurrentUser,
  ModeratedStudy,
} from "~/api/generated/usabilityhubSchemas"

// We need to show slightly different error messages depending on the context
// and if error is specifically for the current user
export const googleCalendarCredentialsError = (
  context: "booking" | "conflict",
  forCurrentUser: boolean
) => {
  if (context === "booking") {
    if (forCurrentUser) {
      return "Applicants are unable to make a booking until your Google Calendar is reconnected from your Profile Settings"
    } else {
      return "Applicants are unable to make a booking until this Google Calendar is reconnected"
    }
  } else if (context === "conflict") {
    if (forCurrentUser) {
      return "We are unable to check the availability of this calendar until your Google Calendar is reconnected from your Profile Settings"
    } else {
      return "We are unable to check the availability of this calendar until the Google Calendar is reconnected"
    }
  }
}

const CALENDAR_INTEGRATION_ERROR_LABEL = "Calendar integration action required"

// NOTE: The order these errors override each other is important, we use early
// returns for the more important errors
const getError = (
  moderatedStudy: ModeratedStudy,
  currentUser: CurrentUser,
  hasAvailabilities: boolean
) => {
  const currentMember = moderatedStudy.members.find(
    (m) => m.id === currentUser.id
  )
  if (moderatedStudy.booking_calendar?.google_calendar_error) {
    const forCurrentUser = currentMember?.role === "main_host"
    return {
      label: CALENDAR_INTEGRATION_ERROR_LABEL,
      tooltip: googleCalendarCredentialsError("booking", forCurrentUser),
    }
  }

  const memberGoogleCalendarError = moderatedStudy.members.find(
    (m) => m.has_google_calendar_error
  )
  if (memberGoogleCalendarError) {
    const forCurrentUser = memberGoogleCalendarError === currentMember
    return {
      label: CALENDAR_INTEGRATION_ERROR_LABEL,
      tooltip: googleCalendarCredentialsError("conflict", forCurrentUser),
    }
  }

  if (!hasAvailabilities)
    return {
      label: "No availablity",
      tooltip:
        "Applicants are unable to choose a time as all of the time slots are in the past or booked",
    }
}

interface Props {
  hasAvailabilities: boolean
  showText?: boolean
}

export const ModeratedStudyAlerts = ({
  hasAvailabilities,
  showText,
}: Props) => {
  const { moderatedStudy } = useModeratedStudyContext()
  const currentUser = useCurrentUser()
  const error = getError(moderatedStudy, currentUser, hasAvailabilities)

  return (
    error && (
      <Tooltip
        hasArrow
        fontSize="sm"
        lineHeight="5"
        fontWeight="normal"
        backgroundColor="gray.900"
        borderRadius="4"
        padding="8px"
        textAlign="center"
        label={error.tooltip}
      >
        {/* TODO: when we do PRD-3178, we'll improve responsiveness here by implementing the "showText" prop correctly */}
        <Flex
          backgroundColor="ds.background.danger.subtle.resting"
          align="center"
          borderRadius="6px"
          height="8"
          px="1.5"
          gap="2"
        >
          <Icon as={AlertCircleSolidIcon} color="ds.icon.danger" boxSize="5" />
          {showText && (
            <Text textStyle="ds.paragraph.primary">{error.label}</Text>
          )}
        </Flex>
      </Tooltip>
    )
  )
}
