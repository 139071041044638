import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import React from "react"

import { FunctionalModal } from "Utilities/modals/types"

interface Props {
  numOfReportedResponses: number
  handleConfirm: () => Promise<void>
}

export const ConfirmReviseReportedResponsesModal: FunctionalModal<Props> = ({
  numOfReportedResponses,
  handleConfirm,
  onClose,
}) => {
  const confirmRevise = async () => {
    await handleConfirm()
  }

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalHeader>Warning</ModalHeader>
        <ModalBody>
          {numOfReportedResponses}{" "}
          {numOfReportedResponses > 1
            ? "rejected responses have "
            : "rejected response has "}
          been reported to Cint. Do you want to revise{" "}
          {numOfReportedResponses > 1
            ? "their grades "
            : `it${"\u2019"}s grade `}
          to Good?
        </ModalBody>
        <ModalFooter flexDir="row-reverse" justifyContent="flex-start">
          <Button colorScheme="brand.primary" onClick={confirmRevise} ml={3}>
            Yes
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
