import { ResultDataProvider } from "UsabilityHub/views/tests/$uniqueId/results/ResultDataProvider"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import React, { ComponentProps } from "react"
import { OrderResponsesReview } from "./order-responses-review"

// This wrapper is needed because we use mount_react to render OrderResponsesReview directly
// so it doesn't have access to the context providers from the SPA.
export const OrderResponsesReviewWrapper: React.FC<
  ComponentProps<typeof OrderResponsesReview>
> = (props) => {
  return (
    <ModalContextProvider>
      <ResultDataProvider
        usabilityTestUniqueId={null}
        useSharedApi={false}
        isAdmin
        privateId={null}
        responseId={null}
        orderId={props.orderId}
        revising={props.revising}
        isThirdPartyOrder={!!props.isThirdPartyOrder}
      >
        <OrderResponsesReview {...props} />
      </ResultDataProvider>
    </ModalContextProvider>
  )
}
