import React from "react"

import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { EditPasswordForm } from "Shared/forms/EditPasswordForm"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"

export const EditPasswordRoute: React.FC = () => (
  <Page title="Edit password">
    <UsabilityHubNavbar />
    <PageMain>
      <PageContent maxW="md">
        <EditPasswordForm />
      </PageContent>
    </PageMain>
  </Page>
)
