import { useCallback, useEffect } from "react"

export const useBeforeUnload = (
  active: boolean,
  message = "Changes you made will not be saved"
): void => {
  const handleUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      event.returnValue = message
      return message
    },
    [message]
  )

  useEffect(() => {
    if (!active) return

    window.addEventListener("beforeunload", handleUnload)
    return () => window.removeEventListener("beforeunload", handleUnload)
  }, [handleUnload, active])
}
