/* eslint-disable react/jsx-key */
import { createIcon } from "@chakra-ui/react"
import React from "react"

export const FigmaIcon = createIcon({
  displayName: "FigmaIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path d="M8 24a4 4 0 0 0 4-4v-4H8a4 4 0 0 0 0 8Z" fill="#24CE85" />
      <path d="M4 12a4 4 0 0 0 4 4h4V8H8a4 4 0 0 0-4 4Z" fill="#A160FC" />
      <path d="M4.003 4a4 4 0 0 0 4 4h4V0h-4a4 4 0 0 0-4 4Z" fill="#F04F2B" />
      <path d="M11.999 0v8h4a4 4 0 0 0 0-8h-4Z" fill="#FD7366" />
      <path d="M20 12a4 4 0 1 0-8.001 0A4 4 0 0 0 20 12Z" fill="#2CBDFB" />
    </>
  ),
})
