import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const PrototypeTestIcon = createIcon({
  displayName: "PrototypeTestIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-5.618-3.683a.5.5 0 01.853 0l1.078 1.762a.5.5 0 01-.515.753l-.364-.065v2.91c0 1.347-.915 2.33-2.14 2.33s-2.14-.983-2.14-2.33v-2.65c0-.745-.441-1.08-.89-1.08-.448 0-.889.335-.889 1.08v3.786a.625.625 0 11-1.25 0V7.027c0-1.347.915-2.33 2.14-2.33 1.224 0 2.14.983 2.14 2.33v2.65c0 .745.44 1.08.89 1.08.447 0 .889-.335.889-1.08v-2.91l-.365.065a.5.5 0 01-.514-.753l1.077-1.762z"
    />
  ),
})
