import {
  Box,
  Flex,
  Grid,
  GridProps,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react"
import { cq } from "Shared/helpers/containerQuerySx"
import React from "react"
import { threeColumnsOrMore } from "./projectListGridBreakpoints"

const ProjectSkeleton = () => {
  return (
    <Flex
      data-qa="project-skeleton"
      w="full"
      justifyContent="flex-start"
      p={5}
      h="5.25rem"
      flexDir="row"
      align="center"
      gap={4}
      sx={cq(threeColumnsOrMore, {
        h: "9.625rem",
        flexDir: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: 0,
      })}
      bg="white"
      rounded="16px"
      shadow="ds.raised"
    >
      <SkeletonCircle size="10" />
      <SkeletonText
        noOfLines={2}
        spacing={2}
        skeletonHeight="2"
        w="50%"
        mt={0}
        sx={cq(threeColumnsOrMore, { mt: 4 })}
      />
    </Flex>
  )
}

interface Props {
  numberOfItems: number
  // Use the same margin/padding/spacing as the loaded version's grid
  // to avoid visual jumps when loaded
  gridProps: GridProps
}

export const ProjectListSkeleton: React.FC<Props> = ({
  numberOfItems,
  gridProps,
}) => (
  <Grid {...gridProps}>
    {Array(numberOfItems)
      .fill(undefined)
      .map((_, i) => (
        // Wrapped in a box so that unsetting `display: none` with `display: block`
        // works on all children of the parent grid - `revert` & `unset` don't work
        // (the project cards are `display: block`, not `flex`)
        <Box key={`ps-` + String(i)}>
          <ProjectSkeleton />
        </Box>
      ))}
  </Grid>
)
