import {
  Button,
  Flex,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { FunctionalModal } from "Utilities/modals/types"
import React, { useState } from "react"
import {
  CancelModeratedStudyBookingVariables,
  GetModeratedStudyBookingsResponse,
} from "~/api/generated/usabilityhub-components"

interface Props {
  cancelBooking: (
    variables: Partial<CancelModeratedStudyBookingVariables>
  ) => void
  booking: GetModeratedStudyBookingsResponse[number]
}

export const CancelBookingModal: FunctionalModal<Props> = ({
  isOpen,
  onClose,
  cancelBooking,
  booking,
}) => {
  const [reason, setReason] = useState<string | null>(null)

  const isPanelist = booking.is_panelist
  const isBookingWithin12Hours = bookingWithin12Hours(
    new Date(booking.starts_at)
  )

  const handleCancel = () => {
    cancelBooking({
      body: { reason, booking_begins_soon: isBookingWithin12Hours },
    })
    onClose()
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setReason(e.target.value)

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader textColor="text.primary" fontSize="lg" fontWeight="bold">
            Cancel session
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody as={VStack} align="left" spacing={2}>
            <Heading fontSize="md" fontWeight="medium" textColor="text.primary">
              Reason for cancelation (optional)
            </Heading>
            <Textarea
              height="8.25rem"
              textColor="text.primary"
              onChange={handleTextChange}
              autoFocus
            />
            {isPanelist ? (
              isBookingWithin12Hours ? (
                <>
                  <Text fontSize="sm" color="text.primary">
                    <strong>Important:</strong> This session is within the next
                    12 hours. If you proceed to cancel, we will not attempt to
                    replace the session with another panelist and
                    <Text
                      as="span"
                      textDecoration="underline"
                      fontWeight="bold"
                    >
                      you will not be refunded.
                    </Text>{" "}
                  </Text>
                  <Text fontSize="sm" color="text.primary">
                    <Link
                      href={
                        Constants.HELP_CENTER_MANAGING_INTERVIEW_SESSIONS_URL
                      }
                      isExternal
                    >
                      See more about our cancelation policy for more details.
                    </Link>
                  </Text>
                </>
              ) : (
                <Text fontSize="sm" color="text.primary">
                  Once you confirm cancelation, we will automatically attempt to
                  replace the session with another panelist.
                </Text>
              )
            ) : (
              <Text fontSize="sm" color="text.primary">
                Once you confirm cancelation, the participant will be
                automatically notified along with the reason you provide here.
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex gap={2}>
              <Button
                variant="outline"
                textColor="text.primary"
                onClick={onClose}
              >
                Keep the session
              </Button>
              <Button
                variant="solid"
                colorScheme="brand.primary"
                onClick={handleCancel}
              >
                Cancel the session
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export const bookingWithin12Hours = (bookingStartsAt: Date): boolean => {
  const now = new Date()
  const timeDifferenceInMilliseconds = Math.abs(
    bookingStartsAt.valueOf() - now.valueOf()
  )
  const cutoffInMilliseconds = 12 * 60 * 60 * 1000 // 12 hours
  return timeDifferenceInMilliseconds < cutoffInMilliseconds
}
