import React, { PropsWithChildren } from "react"
import { createPortal } from "react-dom"
import { useDocumentPictureInPictureContext } from "./DocumentPictureInPictureProvider"

export const DocumentPictureInPicture: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isOpen, pipWindow } = useDocumentPictureInPictureContext()

  if (!isOpen || !pipWindow) return null

  return createPortal(children, pipWindow.document.body)
}
