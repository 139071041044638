import { Text } from "@chakra-ui/react"
import React from "react"

import { TestCardDivider } from "UsabilityHub/components/TestCard/TestCard"

import { SectionCard } from "../SectionCard"
import { SectionInstructionField } from "../SectionFields/SectionInstructionField"
import { SectionQuestions } from "../SectionQuestions/SectionQuestions"
import { SectionLogicActions } from "../TestLogicActions/TestLogicActions"

import { TreeContainer as Tree } from "./Tree"
import { TreeTestTaskInstructions } from "./Tree/TreeTestTaskInstructions"

export const TreeTestSectionCard: React.FC = () => (
  <SectionCard>
    <SectionLogicActions />
    <TestCardDivider />
    <SectionInstructionField
      required
      rows={2}
      label={<TreeTestTaskInstructions />}
      placeholder="Keep this short and straightforward. If you need to add more context, add an instruction section before this one."
    />
    <Tree />
    <Text as="label" fontWeight="semibold">
      Follow-up questions
    </Text>
    <SectionQuestions />
  </SectionCard>
)
