import { Grid, Spinner } from "@chakra-ui/react"
import React from "react"
import { useGetExperiments } from "~/api/generated/usabilityhub-components"
import { EmptyDashboard as NewEmptyDashboard } from "./NewEmptyDashboard"
import { EmptyDashboard as OldEmptyDashboard } from "./OldEmptyDashboard"

export const EmptyDashboard = () => {
  const { isLoading, data } = useGetExperiments({})

  if (isLoading) {
    return (
      <Grid h="full" placeContent="center">
        <Spinner />
      </Grid>
    )
  }

  return data?.empty_dashboard === "proposed" ? (
    <OldEmptyDashboard />
  ) : (
    <NewEmptyDashboard />
  )
}
