import { getSectionTypeRules } from "Constants/test-section-types"
import { UsabilityTestSection, UsabilityTestSectionScreenshot } from "Types"
import { pluralize } from "Utilities/string"

const needsMoreScreenshots = (
  screenshots: UsabilityTestSectionScreenshot[],
  section: UsabilityTestSection
) => {
  const { minScreenshots } = getSectionTypeRules(section.type)
  return screenshots.length < minScreenshots
}

const hasTooManyScreenshots = (
  screenshots: UsabilityTestSectionScreenshot[],
  section: UsabilityTestSection
) => {
  const { maxScreenshots } = getSectionTypeRules(section.type)
  return screenshots.length > maxScreenshots
}

export const validateScreenshots = (
  screenshots: UsabilityTestSectionScreenshot[],
  section: UsabilityTestSection
): string | undefined => {
  if (needsMoreScreenshots(screenshots, section)) {
    const { minScreenshots } = getSectionTypeRules(section.type)
    return `You need to upload ${pluralize(
      minScreenshots,
      "a file",
      `at least ${minScreenshots} files`
    )}`
  }
  if (hasTooManyScreenshots(screenshots, section)) {
    const { maxScreenshots } = getSectionTypeRules(section.type)
    return `You have too many screenshots in this section, please remove ${
      screenshots.length - maxScreenshots
    }`
  }
}
