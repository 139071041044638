import { keyBy } from "lodash"

import {
  ClientId,
  UsabilityTestSection,
  UsabilityTestSectionOrQuestion,
} from "Types"

const getOrderedFields = (
  sections: UsabilityTestSection[]
): UsabilityTestSectionOrQuestion[] =>
  sections.flatMap((section) => [section, section.questions].flat())

const getOrderedClientIds = (sections: UsabilityTestSection[]): ClientId[] =>
  getOrderedFields(sections).map((field) => field._clientId)

export const getClientIdsBefore = (
  sections: UsabilityTestSection[],
  fieldId: ClientId
): ClientId[] => {
  const orderedClientIds = getOrderedClientIds(sections)
  const fieldIndex = orderedClientIds.indexOf(fieldId)
  return orderedClientIds.slice(0, fieldIndex)
}

export const getFieldWithClientIdIn = (
  clientId: ClientId,
  sections: UsabilityTestSection[]
): UsabilityTestSectionOrQuestion | void =>
  keyBy(getOrderedFields(sections), "_clientId")[clientId]

export const getSectionIdForQuestionClientId = (
  clientId: ClientId,
  sections: UsabilityTestSection[]
) => {
  return sections.find((section) =>
    section.questions.some((q) => q._clientId === clientId)
  )?._clientId
}

export const isFieldPresent = (
  sections: UsabilityTestSection[],
  fieldId: ClientId
): boolean => getOrderedClientIds(sections).includes(fieldId)

export const isFieldBeforeField = (
  sections: UsabilityTestSection[],
  firstFieldId: ClientId,
  secondFieldId: ClientId
): boolean => getClientIdsBefore(sections, secondFieldId).includes(firstFieldId)
