import React from "react"

import {
  AnswerForm,
  Props,
} from "UsabilityHub/components/UsabilityTestSectionQuestion/answer-form"

import { QuestionRadioButtonListField } from "./question-radio-button-list-field"

export function RadioQuestion(props: Readonly<Props>) {
  const { question } = props
  return (
    <AnswerForm {...props}>
      <QuestionRadioButtonListField
        name="answer"
        options={question.multiple_choice_options}
        randomized={question.randomized}
        showOtherOption={question.has_other_option}
        required={question.required}
      />
    </AnswerForm>
  )
}
