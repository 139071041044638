import {
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Tooltip,
  VStack,
} from "@chakra-ui/react"
import { motion } from "framer-motion"
import React from "react"

import { useTranslate } from "Shared/hooks/useTranslate"
import { DragHandleIcon } from "Shared/icons/DragHandleIcon"
import { MaximizeIcon } from "Shared/icons/MaximizeIcon"
import { MinimizeIcon } from "Shared/icons/MinimizeIcon"

import { usePrototypeTaskContext } from "../PrototypeTaskContext"

import {
  InstructionsModalState,
  draggableStates,
  minimizedStates,
} from "./InstructionsModal"
import { PrototypeTaskPhase } from "./usePrototypeTaskPhases"

interface InstructionsModalInnerProps {
  state: InstructionsModalState
  toggleState: () => void
  prototypeTaskPhase?: PrototypeTaskPhase
}

const outerVariants: { [K in InstructionsModalState]: object } = {
  desktopStart: {
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 0.5,
    },
  },
  desktopDefault: {
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 32,
    paddingRight: 32,
    opacity: 1,
  },
  desktopMinimized: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 24,
    paddingRight: 24,
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
  mobileStart: {
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 0.5,
    },
  },
  mobileDefault: {
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 0.5,
    },
  },
  mobileMinimized: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
    paddingBottom: 12,
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
}

const innerVariants: { [K in InstructionsModalState]: object } = {
  desktopStart: {
    opacity: 1,
  },
  desktopDefault: {
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
  desktopMinimized: {
    opacity: 0,
  },
  mobileStart: {
    opacity: 1,
  },
  mobileDefault: {
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.3,
    },
  },
  mobileMinimized: {
    opacity: 0,
  },
}

const initialStyles = {
  opacity: 0,
  paddingTop: 32,
  paddingBottom: 32,
  paddingLeft: 32,
  paddingRight: 32,
}

const MotionFlex = motion(Flex)
const MotionBox = motion(Box)
export const InstructionsModalInner: React.FC<
  React.PropsWithChildren<InstructionsModalInnerProps>
> = ({ state, toggleState, prototypeTaskPhase, children }) => {
  const translate = useTranslate()
  const prototypeTask = usePrototypeTaskContext()

  const isDraggerIconVisible =
    prototypeTask.isTaskStarted && draggableStates.includes(state)
  const isMinMaxButtonVisible = prototypeTask.isTaskStarted
  const isMinimized = minimizedStates.includes(state)
  const minMaxButtonText = isMinimized
    ? translate("test.instructions.prototype_task.instruction_maximize")
    : translate("test.instructions.prototype_task.instruction_minimize")

  return (
    <MotionFlex
      variants={outerVariants}
      layout="position"
      initial={initialStyles}
      w="full"
    >
      <VStack alignItems="start" w="full">
        <Flex justifyContent="space-between" alignItems="center" w="full">
          {state !== "mobileMinimized" && (
            <Heading as="h2" size="sm">
              {prototypeTaskPhase === "answering_questions"
                ? translate("test.instructions.prototype_task.question_header")
                : translate(
                    "test.instructions.prototype_task.instruction_header"
                  )}
            </Heading>
          )}
          <HStack spacing={0}>
            <Icon
              as={DragHandleIcon}
              marginStart={minimizedStates.includes(state) ? 3 : "auto"}
              marginEnd={1}
              visibility={isDraggerIconVisible ? "visible" : "hidden"}
            />
            <Flex>
              <Tooltip
                hasArrow
                placement="top"
                label={minMaxButtonText}
                closeOnPointerDown
              >
                <IconButton
                  icon={
                    isMinimized ? (
                      <Icon as={MaximizeIcon} />
                    ) : (
                      <Icon as={MinimizeIcon} />
                    )
                  }
                  variant="ghost"
                  size="md"
                  aria-label={minMaxButtonText}
                  onClick={toggleState}
                  visibility={isMinMaxButtonVisible ? "visible" : "hidden"}
                />
              </Tooltip>
            </Flex>
          </HStack>
        </Flex>

        <MotionBox
          display={!minimizedStates.includes(state) ? "block" : "none"}
          variants={innerVariants}
          transition={{ duration: 0.15 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          layout="position"
          width="100%"
        >
          {children}
        </MotionBox>
      </VStack>
    </MotionFlex>
  )
}
