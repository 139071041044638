import { useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"

type Options =
  | {
      replace?: boolean
    }
  | undefined

export const useMutableQueryString = ({ replace = false }: Options = {}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)

  const setParams = useCallback(
    (values: Record<string, string | null>) => {
      const searchParams = new URLSearchParams(location.search)
      const oldParams = searchParams.toString()

      for (const [key, value] of Object.entries(values)) {
        if (value === null) {
          searchParams.delete(key)
        } else {
          searchParams.set(key, value)
        }
      }

      const newParams = searchParams.toString()

      if (oldParams === newParams) return

      location.search = newParams
      navigate(location, { replace })
    },
    [history, location]
  )

  return [Object.fromEntries(searchParams), setParams] as const
}
