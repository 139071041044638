import { HitzoneFilter, HitzoneFilterCategory } from "../filters/hitzone"

import { generateFilterId } from "./generate-filter-id"

export function createHitzoneFilterCategory(
  sectionId: number,
  sectionScreenshotId: number
): HitzoneFilterCategory {
  return `answer/section:${sectionId}/screenshot:${sectionScreenshotId}/screenshot-click`
}

export function createHitzoneFilter(
  sectionId: number,
  screenshotId: number,
  hitzoneId: number
): HitzoneFilter {
  return {
    id: generateFilterId(),
    category: createHitzoneFilterCategory(sectionId, screenshotId),
    type: "answer/hitzone",
    value: hitzoneId,
  }
}
