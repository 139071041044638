import { Alert, AlertTitle, Stack } from "@chakra-ui/react"
import { UniqueIdentifier } from "@dnd-kit/core"
import { TreeNode } from "Types"
import {
  groupBy,
  last,
  mapValues,
  orderBy,
  partition,
  sum,
  sumBy,
  values,
} from "lodash"
import React, { useMemo } from "react"
import { useAggregatedResults } from "../AggregatedResultsProvider"
import { Answer, Item } from "./Item"

export const TreeTestAnswers: React.FC = () => {
  const data = useAggregatedResults()

  const paths = useMemo(
    () =>
      data.treeTest?.nodes?.reduce(
        (acc, node) => ({
          ...acc,
          [node.id]: [...(acc[node.parent_id || ""] || []), node],
        }),
        {} as Record<string, TreeNode[]>
      ) || {},
    [data.treeTest]
  )

  const correctNodeIds = useMemo(
    () => new Set<UniqueIdentifier | null>(data.treeTest?.correct_nodes || []),
    [data.treeTest]
  )

  const answers = useMemo<Answer[]>(() => {
    if (!data) return []
    const finalNodes = data.nodes.filter(
      (node) => node.id.startsWith("end:") || node.result === "skipped"
    )
    if (finalNodes.length === 0) return []

    const finalIds = new Set(finalNodes.map((node) => node.id))
    const counts = mapValues(
      groupBy(
        data.links.filter((link) => finalIds.has(link.target)),
        "target"
      ),
      (v) => sumBy(v, "value")
    )
    const total = sum(values(counts))
    const rawAnswers: Answer[] = finalNodes.map((node) => ({
      ...node,
      id: last(node.id.split(":")) || "",
      count: counts[node.id] || 0,
      total,
      percentage: ((counts[node.id] || 0) * 100.0) / (total || 1),
      path: paths[last(node.id.split(":")) || ""] || null,
    }))

    const [skippedAnswers, rest] = partition(
      rawAnswers,
      (a) => a.id === "skipped"
    )

    const finalAnswers = rest

    if (skippedAnswers.length > 0) {
      const skippedAnswer = skippedAnswers.reduce((acc, obj) => {
        if (Object.keys(acc).length === 0) {
          acc = obj
        } else {
          acc.count += obj.count
          acc.percentage += obj.percentage
        }
        return acc
      }, {} as Answer)

      finalAnswers.push(skippedAnswer)
    }

    return orderBy(finalAnswers, ["count"], ["desc"])
  }, [data, paths])

  if (answers.length === 0) {
    return (
      <Alert status="info" mt={8}>
        <AlertTitle>No tree test data available</AlertTitle>
      </Alert>
    )
  }

  return (
    <Stack spacing={4} py={4}>
      {answers.map((answer) => (
        <Item
          key={answer.id}
          answer={answer}
          correct={correctNodeIds.has(last(answer.path)?.id || null)}
        />
      ))}
    </Stack>
  )
}
