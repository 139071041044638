// This file should be kept in sync with stylesheets/shared/devices.scss
export const devices = {
  android: {
    width: 547,
    height: 1055,
    screenX: 27.58,
    screenY: 91.87,
    screenWidth: 490.31,
    screenHeight: 869.72,
  },
  iphone: {
    width: 517,
    height: 1055,
    screenX: 33.67,
    screenY: 126.31,
    screenWidth: 449.67,
    screenHeight: 803.58,
  },
  tablet: {
    width: 1024,
    height: 1552,
    screenX: 35.99,
    screenY: 143.92,
    screenWidth: 952.03,
    screenHeight: 1267.44,
  },
}
