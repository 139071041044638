import React, { HTMLAttributes, useEffect, useRef } from "react"

import { useImage } from "UsabilityHub/hooks/useImage"
import { clearCanvas } from "Utilities/canvas/clear-canvas"
import { ScaledCanvas } from "Utilities/canvas/scaled-canvas"
import { drawHeatmap } from "Utilities/click-maps/draw-heatmap"
import { drawImage } from "Utilities/click-maps/draw-image"
import { drawOverlay } from "Utilities/click-maps/draw-overlay"
import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"

import { HighDPRCanvas } from "../../../../usabilityhub/components/Canvas/HighDprCanvas"

interface ClickHeatMapProps extends HTMLAttributes<HTMLCanvasElement> {
  clicks: NormalizedClick[]
  imageUrl?: string
  width: number
  height: number
}

export const ClickHeatMap: React.FC<ClickHeatMapProps> = ({
  width,
  height,
  clicks,
  imageUrl,
  ...imageProps
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { image } = useImage(imageUrl)

  useEffect(() => {
    const generateHeatmapUrl = () => {
      const canvas = canvasRef.current

      if (!canvas) return

      clearCanvas(canvas)
      const scaledCanvas = new ScaledCanvas(canvas, window.devicePixelRatio)

      if (image) {
        drawImage(scaledCanvas, image)
        drawOverlay(scaledCanvas)
      }
      drawHeatmap(scaledCanvas, clicks)
    }

    void generateHeatmapUrl()
  }, [image, clicks, width, height])

  return (
    <HighDPRCanvas
      {...imageProps}
      ref={canvasRef}
      width={width}
      height={height}
    />
  )
}
