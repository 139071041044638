import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import { DotsVerticalIcon } from "@heroicons/react/outline"
import { MicrosoftIcon } from "Shared/icons/MicrosoftIcon"
import { ConflictCalendarModal } from "UsabilityHub/components/ConflictCalendarModal/ConflictCalendarModal"
import { useMicrosoftOAuth } from "UsabilityHub/hooks/useMicrosoftOAuth"
import { getDateString } from "Utilities/date-formats"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import {
  GetIntegrationsResponse,
  useDeleteIntegration,
} from "~/api/generated/usabilityhub-components"

interface Props {
  data?: GetIntegrationsResponse
  onConnectDisconnect: () => void | Promise<void>
}

export const MicrosoftIntegrationStatus: React.FC<
  React.PropsWithChildren<Props>
> = ({ data, onConnectDisconnect }) => {
  const toast = useToast()
  const { open: openConflictCalendarModal } = useModal(ConflictCalendarModal)
  const { handleMicrosoftOAuth, disconnectMicrosoftOAuth } = useMicrosoftOAuth(
    !!data?.microsoft,
    async () => {
      await onConnectDisconnect()
      openConflictCalendarModal({
        initialSelectedCalendars: data?.conflict_calendars ?? [],
        singleProvider: "outlook",
        hasGoogleCalendarError: false,
      })
    }
  )

  const { mutateAsync: deleteIntegration, isLoading: isDeletingIntegration } =
    useDeleteIntegration({
      onSuccess: async () => {
        toast({
          title: "Disconnected integration",
          status: "success",
        })
        await onConnectDisconnect()
        disconnectMicrosoftOAuth()
      },
    })

  return (
    <Flex gap={4}>
      <MicrosoftIcon boxSize={10} mt={2} />
      <Box>
        <Text fontSize="lg" fontWeight="bold">
          Microsoft
        </Text>
        <Text>
          Connect your Microsoft account to check your Outlook Calendar
          availability and sync events and meetings on your Interview studies
          via Microsoft Teams
        </Text>
      </Box>
      <Spacer />

      {data === undefined ? (
        <Spinner size="sm" />
      ) : data.microsoft === null ? (
        <Button
          flexShrink={0}
          colorScheme="brand.primary"
          onClick={handleMicrosoftOAuth}
        >
          Connect
        </Button>
      ) : (
        <>
          <Text
            fontSize="xs"
            color="text.secondary"
            textAlign="right"
            flexShrink={0}
          >
            Connected on
            <br />
            {getDateString(data.microsoft.created_at, "medium")}
          </Text>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<Icon as={DotsVerticalIcon} color="gray.500" />}
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  openConflictCalendarModal({
                    initialSelectedCalendars: data.conflict_calendars,
                    singleProvider: "outlook",
                    hasGoogleCalendarError: false,
                  })
                }}
              >
                Configure
              </MenuItem>
              <MenuItem
                color="red"
                isDisabled={isDeletingIntegration}
                onClick={() => {
                  deleteIntegration({
                    body: { integration: "microsoft" },
                  })
                }}
              >
                Disconnect
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      )}
    </Flex>
  )
}
