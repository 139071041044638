import { Rating, RatingFormState } from "./types"

type Action =
  | {
      type: "RATE"
      payload: { rating: Rating }
    }
  | {
      type: "SUBMIT_FEEDBACK"
      payload?: never
    }
  | {
      type: "SKIP_FEEDBACK"
      payload?: never
    }

export const ratingFormReducer = (
  state: RatingFormState,
  action: Action
): RatingFormState => {
  switch (action.type) {
    case "RATE":
      return {
        ...state,
        stage: "ask-for-feedback",
        rating: action.payload.rating,
      }
    case "SUBMIT_FEEDBACK":
      return {
        ...state,
        stage: "finished",
      }
    case "SKIP_FEEDBACK":
      return {
        ...state,
        stage: "finished",
      }
    default:
      throw new Error(`Error with CSAT rating form: ${action}`)
  }
}
