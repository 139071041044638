import { Flex } from "@chakra-ui/react"
import feedbackNegativeIllustration from "Images/app-illustrations/feedback-negative.png"
import feedbackNeutralIllustration from "Images/app-illustrations/feedback-neutral.png"
import feedbackPositiveIllustration from "Images/app-illustrations/feedback-positive.png"
import { cq } from "Shared/helpers/containerQuerySx"
import React, { Dispatch, Fragment, SetStateAction } from "react"
import { Helmet } from "react-helmet"
import { v4 as uuidv4 } from "uuid"
import { BREAKPOINT_MAX } from "../breakpoints"
import { AskForFeedback } from "./AskForFeedback"
import { FeedbackRecorded } from "./FeedbackRecorded"
import { SentimentPicker } from "./SentimentPicker"
import { Rating } from "./types"
import { useRatingFormState } from "./useRatingFormState"

type Props = {
  onClose: () => void
  lastRating: Rating | null
  setLastRating: Dispatch<SetStateAction<Rating | null>>
  usabilityTestUniqueId: string
}

export const RatingForm: React.FC<Props> = ({
  onClose,
  lastRating,
  setLastRating,
  usabilityTestUniqueId,
}) => {
  // Preserve the rating id across renders
  // (renews on each mount, i.e. if you close the rating form and reopen you get a new id)
  const ratingId = React.useRef(uuidv4()).current

  const { submitRating, submitFeedback, skipFeedback, stage, rating } =
    useRatingFormState({
      ratingId,
      lastRating,
      setLastRating,
      usabilityTestUniqueId,
    })

  const stageContent = {
    default: <SentimentPicker submitRating={submitRating} rating={rating} />,
    "ask-for-feedback": (
      <AskForFeedback
        rating={rating}
        submitFeedback={submitFeedback}
        skipFeedback={skipFeedback}
      />
    ),
    finished: <FeedbackRecorded rating={rating} onClose={onClose} />,
  }[stage]

  return (
    <Fragment>
      {/* preload illustrations for the feedback stage */}
      <Helmet>
        <link rel="preload" href={feedbackPositiveIllustration} as="image" />
        <link rel="preload" href={feedbackNeutralIllustration} as="image" />
        <link rel="preload" href={feedbackNegativeIllustration} as="image" />
      </Helmet>
      <Flex
        bg="ds.background.neutral.resting"
        rounded="12px"
        pos="relative"
        // Just tall enough to accommodate the wrapped lines of body text on the
        // neutral/negative post-feedback stage when the component is at its
        // widest on the current page layout, to help prevent its height
        // changing over the course of the form experience
        // (will still happen at smaller sizes)
        minH="10.25rem"
        px={4}
        py={4}
        sx={cq(BREAKPOINT_MAX, { px: 6 })}
      >
        {stageContent}
      </Flex>
    </Fragment>
  )
}
