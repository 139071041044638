import {
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react"
import { Field, FieldProps, Form, Formik } from "formik"
import React, { useState } from "react"

import { axios, isBadRequestError } from "Services/axios"
import { usersSamlSignInPath } from "Shared/constants/routes"

type SAMLSignInForm = React.FC<React.PropsWithChildren<unknown>>

interface SAMLSignInFormValues {
  email: string
}

export const SAMLSignInForm: SAMLSignInForm = () => {
  const toast = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleSubmit = async (values: SAMLSignInFormValues) => {
    setIsSubmitting(true)
    try {
      const { data } = await axios.post(usersSamlSignInPath(), values)
      window.location.href = data.saml_sign_in_url
    } catch (error) {
      setIsSubmitting(false)
      if (isBadRequestError(error)) {
        toast({
          title: error.response.data.message,
          status: "error",
        })
      } else {
        throw error
      }
    }
  }
  return (
    <Formik<SAMLSignInFormValues>
      initialValues={{ email: "" }}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormControl isRequired>
          <FormLabel htmlFor="email">Email address</FormLabel>
          <Field name="email">
            {({ field }: FieldProps) => (
              <Input {...field} autoFocus isRequired id="email" type="email" />
            )}
          </Field>
        </FormControl>
        <Button
          mt={6}
          isLoading={isSubmitting}
          loadingText="Signing in"
          type="submit"
          colorScheme="brand.primary"
          width="full"
        >
          Sign in
        </Button>
      </Form>
    </Formik>
  )
}
