import {
  Avatar,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React, { useState } from "react"

type SpeakerNameProps = {
  name: string
  onChange: (name: string) => void
}

export const SpeakerName: React.FC<SpeakerNameProps> = ({ name, onChange }) => {
  const currentUser = useCurrentUser()
  const [editing, setEditing] = useState(false)

  const canEdit = currentUser.can_manage_tests ?? false

  const keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter":
        e.preventDefault()
        e.currentTarget.blur()
        break
      case "Escape":
        e.currentTarget.value = name
        e.currentTarget.blur()
        break
    }
  }

  return editing ? (
    <InputGroup mx={-2}>
      <InputLeftElement pointerEvents="none">
        <Avatar size="xs" />
      </InputLeftElement>
      <Input
        defaultValue={name}
        autoFocus
        onBlur={(e) => {
          onChange(e.currentTarget.value)
          setEditing(false)
        }}
        onFocus={(e) => e.currentTarget.select()}
        onKeyDown={keyDown}
      />
    </InputGroup>
  ) : (
    <Button
      variant="ghost"
      fontWeight="semibold"
      px={2}
      py={0}
      mx={-2}
      leftIcon={<Avatar size="xs" />}
      onClick={canEdit ? () => setEditing(true) : undefined}
    >
      {name}
    </Button>
  )
}
