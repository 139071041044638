import { Box, Divider, Stack, Text } from "@chakra-ui/react"
import { TreeNode } from "Types"
import { useSectionContext } from "UsabilityHub/contexts/SectionContext"
import { AnimatePresence, Variants, motion } from "framer-motion"
import { keyBy, partition } from "lodash"
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react"
import { MERGE_RESULTS } from "./Legend"
import { LaidOutLink, LaidOutNode } from "./useTreeTestPathDiagramLayout"

type PopoverProps = ComponentPropsWithoutRef<"div"> & {
  x: number
  y: number
  element: LaidOutNode | LaidOutLink | null
}

const isLink = (element: LaidOutNode | LaidOutLink): element is LaidOutLink =>
  "sourceId" in element

const variants: Variants = {
  out: {
    opacity: 0,
    scale: 0.75,
    transition: { duration: 0.1, ease: "easeInOut" },
    transformOrigin: "top left",
  },
  in: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.1, ease: "easeInOut" },
    transformOrigin: "top left",
  },
}

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  ({ element: requestedElement, style, ...props }, ref) => {
    const { section } = useSectionContext()

    const nodesById = useMemo(
      () =>
        keyBy(section.tree_test_attributes?.nodes || [], (n) => String(n.id)),
      [section.tree_test_attributes]
    )

    const [element, setElement] = useState<LaidOutNode | LaidOutLink | null>(
      requestedElement
    )

    // Cache the last non-null thing so that it doesn't disappear when we're hiding the popover
    useEffect(() => {
      if (requestedElement) setElement(requestedElement)
    }, [requestedElement])

    return (
      <div ref={ref} style={{ ...style, pointerEvents: "none" }} {...props}>
        <AnimatePresence>
          {!!requestedElement && (
            <Box
              as={motion.div}
              key="popover"
              variants={variants}
              initial="out"
              animate="in"
              exit="out"
              bg="rgba(255, 255, 255, 0.85)"
              boxShadow="lg"
              p={4}
              border="1px solid"
              borderColor="brandPalette.neutral.mid"
              rounded={4}
              pointerEvents="none"
              fontSize="xs"
            >
              {!!element &&
                (isLink(element) ? (
                  <LinkSummary link={element} nodesById={nodesById} />
                ) : (
                  <NodeSummary node={element} nodesById={nodesById} />
                ))}
            </Box>
          )}
        </AnimatePresence>
      </div>
    )
  }
)

const LINK_RESULTS = {
  direct_success: " and found the correct node directly",
  indirect_success: " and found the correct node indirectly",
  indirect_failure: ", but did not find the correct node",
  indirect_skipped: ", but ultimately gave up",
} as const

const people = (n: number) => `${n} ${n === 1 ? "person" : "people"}`

const LinkSummary: React.FC<{
  link: LaidOutLink
  nodesById: Record<string, TreeNode>
}> = ({ link, nodesById }) => {
  const sourceId = link.sourceId.split(":").pop() ?? link.sourceId
  const targetId =
    (link.targetId && link.targetId.split(":").pop()) ?? link.targetId

  const source = nodesById[sourceId]
  const target = targetId ? nodesById[targetId] : null

  return (
    <Text maxW="12rem">
      {people(link.value)}{" "}
      {target
        ? `took the path from ${source?.label} to ${target.label}${
            LINK_RESULTS[
              MERGE_RESULTS[link.result] as keyof typeof LINK_RESULTS
            ]
          }`
        : `passed after ${source?.label}`}
    </Text>
  )
}

const NodeSummary: React.FC<{
  node: LaidOutNode
  nodesById: Record<string, TreeNode>
}> = ({ node, nodesById }) => {
  const nodeId = node.id.split(":").pop() || ""
  const label = nodeId === "skip" ? "Pass" : nodesById[nodeId].label

  const [passLinks, continueLinks] = partition(node.links, (link) =>
    link.targetId.endsWith(":skip")
  )

  const continued = continueLinks.reduce((total, link) => total + link.value, 0)
  const passed = passLinks.reduce((total, link) => total + link.value, 0)
  const stopped = node.value - continued - passed

  const rows = [
    continued && `${people(continued)} continued onwards`,
    stopped && `${people(stopped)} chose this as the correct answer`,
    passed && `${people(passed)} passed at this point`,
  ].filter(Boolean)

  return (
    <Stack>
      <Text fontWeight="medium">{`${people(node.value)} ${
        nodeId === "skip"
          ? "passed at this point"
          : node.depth
            ? `took this path to get to ${label}`
            : `started at ${label}`
      }`}</Text>
      {rows.length && <Divider />}
      {rows.map((row, i) => (
        <Text key={i} color="text.subtle">
          {row}
        </Text>
      ))}
      {node.children.length > 1 && !node.expanded && (
        <>
          <Divider />
          <Text color="text.subtlest">
            Click to expand this node
            <br />
            Shift + click to expand all paths from here
          </Text>
        </>
      )}
    </Stack>
  )
}
