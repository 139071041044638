import { isEqual } from "lodash"

import { ResponseFilter } from "./filters/types"

export function isFilterSame(filterA: ResponseFilter, filterB: ResponseFilter) {
  return (
    filterA.category === filterB.category &&
    filterA.type === filterB.type &&
    isEqual(filterA.value, filterB.value)
  )
}
