import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react"

type DeviceFramesContextValue = {
  currentUserHasFigmaOauthCredentials: boolean
  setCurrentUserHasFigmaOauthCredentials: (value: boolean) => void
}

const FigmaOauthCredentialsContext =
  createContext<DeviceFramesContextValue | null>(null)

export const useFigmaOAuthContext = () => {
  const context = useContext(FigmaOauthCredentialsContext)

  if (!context) {
    throw new Error(
      "useFigmaOAuthContext must be used inside the FigmaOauthCredentialsProvider"
    )
  }

  return context
}

interface Props {
  currentUserHasFigmaOauthCredentials: boolean
}

export const FigmaOauthCredentialsProvider: React.FC<
  PropsWithChildren<Props>
> = ({ children, currentUserHasFigmaOauthCredentials: initial }) => {
  const [
    currentUserHasFigmaOauthCredentials,
    setCurrentUserHasFigmaOauthCredentials,
  ] = useState(initial)

  return (
    <FigmaOauthCredentialsContext.Provider
      value={{
        currentUserHasFigmaOauthCredentials,
        setCurrentUserHasFigmaOauthCredentials,
      }}
    >
      {children}
    </FigmaOauthCredentialsContext.Provider>
  )
}
