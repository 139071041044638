import { Box, Spinner } from "@chakra-ui/react"
import React, { useEffect } from "react"

import { useTranslate } from "Shared/hooks/useTranslate"
import styles from "UsabilityHub/components/UsabilityTest/content-factory/intro-outro.module.scss"
import {
  AppearanceProps,
  LayoutState,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"
import {
  Body,
  Container,
} from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout"
import { delay } from "Utilities/promise"

interface Props {
  redirectLink: string
}

const CustomThankYouMessage: React.FC<React.PropsWithChildren<Props>> = ({
  redirectLink,
}) => {
  const translate = useTranslate()

  useEffect(() => {
    const redirect = async () => {
      if (redirectLink) {
        await delay(3000)
        window.location.href = redirectLink
      }
    }
    void redirect()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.scroller}>
        <div className={styles.content}>
          <Container>
            <Body>
              <Box>
                {translate("test.thankyou.about_to_be_redirected")}
                <Spinner size="xs" ml={2} />
              </Box>
            </Body>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default function customThankYouMessageContent(
  props: Props
): AppearanceProps {
  return {
    layoutState: LayoutState.FocusQuestion,
    mediaContent: null,
    questionContent: <CustomThankYouMessage {...props} />,
    isReportButtonVisible: false,
  }
}
