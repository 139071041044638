export const generateFriendlyProjectPath = (
  project: {
    id: string
    name: string
  },
  { absolute = false } = {}
) => {
  const { name, id } = project

  const idWithoutHyphens = id.replace(/-/g, "")
  const slug = name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-+|-+$)/g, "")

  const relativePath = `/projects/${slug}-${idWithoutHyphens}`

  if (absolute) {
    const url = new URL(window.location.href)
    url.pathname = relativePath
    return url.toString()
  } else {
    return relativePath
  }
}
