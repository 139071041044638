import { get } from "lodash"
import { arrayMove, arrayPush, arrayRemove, arraySplice } from "redux-form"

import { State } from "Redux/app-store"
import {
  getFormName,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { ThunkAction } from "~/application/javascripts/types"

export const addQuestionOption =
  (sectionIndex: number, questionIndex: number): ThunkAction<State> =>
  (dispatch) => {
    dispatch(
      arrayPush(
        getFormName(),
        `sections[${sectionIndex}].questions[${questionIndex}].multiple_choice_options`,
        ""
      )
    )
  }

export const spliceQuestionOption =
  (
    sectionIndex: number,
    questionIndex: number,
    optionIndex: number,
    optionText: string
  ): ThunkAction<State> =>
  (dispatch, getState) => {
    const path = `sections[${sectionIndex}].questions[${questionIndex}].multiple_choice_options`
    const current = get(getFormValues(getState()), path) as string[]

    dispatch(
      arraySplice(
        getFormName(),
        path,
        optionIndex,
        current[optionIndex] === undefined ? 0 : 1,
        optionText
      )
    )
  }

export const deleteQuestionOption =
  (
    sectionIndex: number,
    questionIndex: number,
    optionIndex: number
  ): ThunkAction<State> =>
  (dispatch) => {
    dispatch(
      arrayRemove(
        getFormName(),
        `sections[${sectionIndex}].questions[${questionIndex}].multiple_choice_options`,
        optionIndex
      )
    )
  }

export const moveQuestionOption =
  (optionPath: string, from: number, to: number): ThunkAction<State> =>
  (dispatch) => {
    dispatch(arrayMove(getFormName(), optionPath, from, to))
  }
