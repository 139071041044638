import { ResponseSectionFigmaTask } from "Types"

/**
 * A task will be considered to have reached the goal screen when any of the screens on the path match the goal node.
 *
 * This definition will need adjustments once we have free-roam and task-flow prototypes
 * The task-flow prototype needs the goal screen as the last screen.
 * Free-roam can have any of the screens match the goalNodeId to return true.
 */
export function hasGoalScreenBeenReached(
  task: ResponseSectionFigmaTask,
  goalScreenId: string | null
) {
  if (!goalScreenId) return false

  return task.screens.some(
    (screen) => screen.presented_node_id === goalScreenId
  )
}
