import { Validator } from "redux-form"

import { UsabilityTest } from "Types"
import {
  getQuestionWithClientIdIn,
  isRequirable,
} from "Utilities/usability-test-section-question"

export const targetIsOptionalQuestion: Validator = (
  targetId: string,
  usabilityTest: UsabilityTest
) => {
  if (usabilityTest.sections) {
    const question = getQuestionWithClientIdIn(targetId, usabilityTest.sections)
    if (question && isRequirable(question.type) && !question.required) {
      return `The target you${"\u2019"}re using for this condition is optional. If participants don${"\u2019"}t answer that question this condition won${"\u2019"}t be triggered. You may want to make it required.`
    }
  }
}
