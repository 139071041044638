import {
  Accordion,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
} from "@chakra-ui/react"
import React, { useRef } from "react"
import { useTemplateLibrary } from "~/usabilityhub/hooks/useTemplateLibrary"
import { FilterButton } from "./FilterButton"
import { FilterList } from "./FilterList"

import { FilterLinesOutlineIcon } from "Shared/icons/untitled-ui/FilterLinesOutlineIcon"
import { SearchMdOutlineIcon } from "Shared/icons/untitled-ui/SearchMdOutlineIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import { CATEGORIES } from "~/usabilityhub/types/templateLibrary"

export const Filters: React.FC = () => {
  const { filter, query, setQuery } = useTemplateLibrary()

  const searchInput = useRef<HTMLInputElement>(null)

  const clearSearch = () => {
    setQuery("")
    searchInput.current?.focus()
  }

  const [showFilters, setShowFilters] = React.useState(false)

  return (
    <>
      <Box
        gridArea="filters"
        py={{ md: 3 }}
        pl={{ md: 8 }}
        pr={{ md: 7 }}
        minH={0}
        overflowY={{ md: "auto" }}
        zIndex={10}
        top={0}
      >
        <HStack mb={{ base: 2, md: 8 }} p={{ base: 4, md: 0 }} gap={3}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchMdOutlineIcon w={5} h={5} color="gray.400" />
            </InputLeftElement>
            <Input
              ref={searchInput}
              type="text"
              color="gray.500"
              placeholder="Search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              borderRadius="100vw"
              borderColor="blackAlpha.400"
              sx={{
                "&:focus-visible": {
                  boxShadow: "0 0 0 1px var(--chakra-colors-brand-primary-500)",
                  borderColor: "brand.primary.500",
                },
              }}
            />
            {query && (
              <InputRightElement>
                <IconButton
                  icon={<Icon as={XOutlineIcon} />}
                  aria-label="Clear search"
                  size="sm"
                  variant="ghost"
                  p={0}
                  onClick={clearSearch}
                  color="text.secondary"
                  rounded="100vw"
                  sx={{
                    "& svg": {
                      w: 5,
                      h: 5,
                    },
                  }}
                />
              </InputRightElement>
            )}
          </InputGroup>
          <Button
            variant="ghost"
            colorScheme={showFilters ? "brand.primary" : undefined}
            backgroundColor={showFilters ? "grey.light" : undefined}
            _hover={{ backgroundColor: "grey.light" }}
            _focus={{
              backgroundColor: showFilters ? "grey.light" : "transparent",
            }}
            _active={{ backgroundColor: "grey.mid" }}
            leftIcon={<Icon as={FilterLinesOutlineIcon} />}
            display={{ base: "flex", md: "none" }}
            onClick={() => setShowFilters((current) => !current)}
          >
            Filter
          </Button>
        </HStack>

        <Stack
          display={{ base: showFilters ? "flex" : "none", md: "flex" }}
          px={{ base: 4, md: 0 }}
        >
          <FilterButton category={null} />

          <Accordion
            allowToggle
            defaultIndex={filter ? CATEGORIES.indexOf(filter.type) : undefined}
          >
            {CATEGORIES.map((category) => (
              <FilterList key={category} category={category} />
            ))}
          </Accordion>
        </Stack>
      </Box>
    </>
  )
}
