import { Range, ReactOption, Response } from "Types"

import { getOptionsFromMap } from "./get-options"
import { FILTER_UNKNOWN } from "./unknown"

const ageRangeInterval = 5
const minAgeRangeUpperBound = 19
const maxAgeRangeLowerBound = 90

const AGE_RANGE_LOOKUP = new Map<number, Range>()
AGE_RANGE_LOOKUP.set(-Infinity, { min: -Infinity, max: minAgeRangeUpperBound })
for (
  let i = minAgeRangeUpperBound + 1;
  i < maxAgeRangeLowerBound;
  i += ageRangeInterval
) {
  AGE_RANGE_LOOKUP.set(i, { min: i, max: i + ageRangeInterval - 1 })
}
AGE_RANGE_LOOKUP.set(maxAgeRangeLowerBound, {
  min: maxAgeRangeLowerBound,
  max: Infinity,
})

export function getAgeRangeForValue(age: string) {
  return AGE_RANGE_LOOKUP.get(parseInt(age))
}

function getAgeRangeMin(age: number | null): number | null {
  if (age === null) return null
  const min = Math.floor(age / ageRangeInterval) * ageRangeInterval
  if (min < minAgeRangeUpperBound) {
    return -Infinity
  }
  if (min > maxAgeRangeLowerBound) {
    return maxAgeRangeLowerBound
  }
  return min
}

function ageRangeMinToName(min: number | null): string {
  if (min === null) {
    return "Unknown"
  }
  const { max } = AGE_RANGE_LOOKUP.get(min)!
  if (min === -Infinity) {
    return `< ${max}`
  }
  if (max === Infinity) {
    return `> ${min}`
  }
  return `${min}–${max}`
}

export const getAgeRangeValueFromResponse = (response: Response): string =>
  String(
    getAgeRangeMin(response.response_demographic_profile?.age ?? null) ||
      FILTER_UNKNOWN
  )

const getAgesFromResponses = (
  responses: Readonly<Response[]>
): Map<string, string> => {
  const ages = responses.map(getAgeRangeValueFromResponse)

  // Deduplicate age ranges
  const uniqueAges = [...new Set([...ages])]
  const ageRanges = [
    ...new Set(
      uniqueAges.map((stringAge) =>
        getAgeRangeMin(stringAge === FILTER_UNKNOWN ? null : Number(stringAge))
      )
    ),
  ]

  const ageRangeMap: [string, string][] = ageRanges.map((ageRange) => [
    ageRange ? String(ageRange) : FILTER_UNKNOWN,
    ageRangeMinToName(ageRange),
  ])

  return new Map(ageRangeMap)
}

const getStartingAge = (value: string) => {
  if (value === FILTER_UNKNOWN) return Number.POSITIVE_INFINITY

  if (value === "-Infinity") return Number.NEGATIVE_INFINITY
  if (value === "Infinity") return maxAgeRangeLowerBound

  return parseInt(value)
}

const byStartingAge = (a: ReactOption<string>, b: ReactOption<string>) => {
  const startingAgeRangeNumberA = getStartingAge(a.value)
  const startingAgeRangeNumberB = getStartingAge(b.value)

  return startingAgeRangeNumberA - startingAgeRangeNumberB
}

export const getAgeRangeOptionFromResponses = (
  responses: Readonly<Response[]>
): ReactOption<string>[] => {
  return getOptionsFromMap(getAgesFromResponses(responses)).sort(byStartingAge)
}
