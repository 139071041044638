import {
  Button,
  Center,
  Flex,
  Icon,
  Stack,
  Text,
  useBreakpoint,
} from "@chakra-ui/react"
import { ChevronDoubleDownIcon, PlusIcon } from "@heroicons/react/solid"
import React, { useRef, useState } from "react"

import { useElementSize } from "Hooks/useElementSize"
import { useTranslate } from "Shared/hooks/useTranslate"

import { nonNull } from "Utilities/nonNull"
import { useCardSortContext } from "./CardSortContext"
import { Category } from "./Category"
import { NewCategoryDropZone } from "./NewCategoryDropZone"

interface Props {
  isComplete: boolean
  draggingCardId: number | null
}
export const CategoryArea: React.FC<Props> = ({
  isComplete,
  draggingCardId,
}) => {
  const categoryAreaRef = useRef<HTMLDivElement>(null)
  const {
    active,
    cardSortType,
    categories,
    addCategory,
    openCategories,
    duplicatedCategoryLabels,
  } = useCardSortContext()
  const dimensions = useElementSize<HTMLDivElement>(categoryAreaRef)
  const [collapsedCategories, setCollapsedCategories] = useState([] as string[])
  const translate = useTranslate()

  const breakpoint = useBreakpoint({ ssr: false })
  const categoryWidth = breakpoint === "base" ? 170 : 250
  const categoryAreaPadding = breakpoint === "base" ? 2 : 8

  const toggleCategoryCollapsed = (
    categoryId: string,
    isCollapsed: boolean
  ) => {
    if (isCollapsed) {
      setCollapsedCategories((prev) => [...prev, categoryId])
    } else {
      setCollapsedCategories((prev) => prev.filter((c) => c !== categoryId))
    }
  }
  const allCategoriesCollapsed = [...categories, ...openCategories].every(
    (c) => !c.id || collapsedCategories.includes(c.id)
  )

  const categoryColumns = dimensions
    ? Math.max(
        1,
        Math.floor(
          (dimensions.width - 2 * 4 * categoryAreaPadding) /
            (categoryWidth + 12)
        )
      )
    : 1

  return (
    <Flex
      ref={categoryAreaRef}
      justify={["center", "flex-start"]}
      h="100%"
      pos="relative"
      bg="gray.100"
      gap={[2, 6]}
      width="fit-content"
      maxWidth="100vw"
      flexGrow={1}
      overflow="hidden auto"
      borderWidth="0 0 0 1px"
      p={categoryAreaPadding}
    >
      {categories.length === 0 && openCategories.length === 0 && active && (
        <Center position="absolute" inset="0" pointerEvents="none">
          <Text fontSize="md" align="center" color="gray.500" m={2}>
            {translate("test.card_sort.no_categories")}
          </Text>
        </Center>
      )}

      {new Array(categoryColumns).fill(null).map((_, i) => {
        const categoriesForColumn = categories.filter(
          (_, ci) => ci % categoryColumns === i
        )

        const openCategoriesForColumn = openCategories.filter(
          (_, ci) => (categories.length + ci) % categoryColumns === i
        )

        return (
          <Stack
            key={`${i}-${categoriesForColumn.length}-${openCategoriesForColumn.length}`}
            spacing={[2, 6]}
            w={`min(calc(50vw - 16px), ${categoryWidth}px)`}
            h={["fit-content", "100%"]}
            flexShrink={0}
          >
            {active && breakpoint === "base" && (
              <Flex justify="space-between">
                <Text fontSize="xs" fontWeight="bold">
                  {translate("test.card_sort.categories")}
                </Text>
                <Button
                  variant="link"
                  textDecoration="none !important"
                  colorScheme="brand.primary"
                  size="xs"
                  rightIcon={
                    <Icon
                      as={ChevronDoubleDownIcon}
                      transform={
                        allCategoriesCollapsed ? "rotate(0)" : "rotate(0.5turn)"
                      }
                      transition="0.2s transform"
                    />
                  }
                  onClick={() => {
                    if (allCategoriesCollapsed) {
                      setCollapsedCategories([])
                    } else {
                      setCollapsedCategories(
                        [...categories, ...openCategories]
                          .map((c) => c.id)
                          .filter(nonNull)
                      )
                    }
                  }}
                >
                  {allCategoriesCollapsed
                    ? translate("test.card_sort.open_all_categories")
                    : translate("test.card_sort.close_all_categories")}
                </Button>
              </Flex>
            )}

            {categoriesForColumn.map((category) => (
              <Category
                key={category.id}
                category={category}
                isOpenCategory={false}
                draggingCardId={draggingCardId}
                isDuplicated={duplicatedCategoryLabels.includes(category.label)}
                collapsed={
                  category.id
                    ? collapsedCategories.includes(category.id)
                    : false
                }
                setCollapsed={(v) =>
                  category.id && toggleCategoryCollapsed(category.id, v)
                }
              />
            ))}

            {openCategoriesForColumn.map((openCategory) => (
              <Category
                key={openCategory.id}
                isDuplicated={duplicatedCategoryLabels.includes(
                  openCategory.label
                )}
                isOpenCategory
                draggingCardId={draggingCardId}
                category={openCategory}
                collapsed={collapsedCategories.includes(openCategory.id)}
                setCollapsed={(v) =>
                  toggleCategoryCollapsed(openCategory.id, v)
                }
                isComplete={isComplete}
              />
            ))}

            {cardSortType !== "closed" && <NewCategoryDropZone index={i} />}

            {breakpoint === "base" && cardSortType !== "closed" && (
              <Button
                variant="outline"
                leftIcon={<Icon as={PlusIcon} />}
                size="xs"
                rounded="none"
                w="50vw"
                position="fixed"
                bottom={0}
                left="50vw"
                borderWidth="1px 0 0 0"
                zIndex={5}
                onClick={() => {
                  addCategory()
                  categoryAreaRef.current?.scrollTo({
                    top: categoryAreaRef.current.scrollHeight,
                    behavior: "smooth",
                  })
                }}
              >
                {translate("test.card_sort.add_category")}
              </Button>
            )}
          </Stack>
        )
      })}
    </Flex>
  )
}
