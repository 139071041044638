import { useContext } from "react"

import { ScreenResultDetailsContext } from "./ScreenResultsProvider"

export function useScreenResult() {
  const { screenResult } = useContext(ScreenResultDetailsContext)

  if (!screenResult) {
    throw new Error("Missing TaskResultProvider")
  }

  return screenResult
}
