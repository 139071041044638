import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query"

import {
  ListProjectsQueryParams,
  ListVariationSetsError,
  ListVariationSetsResponse,
  fetchListVariationSets,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

export const useInfiniteListVariationSets = (
  queryParams: Omit<ListProjectsQueryParams, "cursor">,
  options?: Omit<
    UseInfiniteQueryOptions<ListVariationSetsResponse, ListVariationSetsError>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useUsabilityhubContext()

  return useInfiniteQuery<ListVariationSetsResponse, ListVariationSetsError>(
    queryKeyFn({
      path: "/api/test_sets/variation_sets",
      operationId: "listVariationSets",
      variables: {
        queryParams: {
          ...fetcherOptions.queryParams,
          ...queryParams,
        },
      },
    }),
    (context) =>
      fetchListVariationSets({
        ...fetcherOptions,
        queryParams: {
          ...queryParams,
          ...(context.pageParam ? { cursor: context.pageParam as string } : {}),
        },
      }),
    {
      ...queryOptions,
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.next_page ?? undefined,
      retry: false,
    }
  )
}
