import { createIcon } from "@chakra-ui/react"
import React from "react"

export const CreateStudyTemplateIcon = createIcon({
  displayName: "CreateStudyTemplateIcon",
  viewBox: "0 0 48 48",
  path: (
    <>
      <rect width="48" height="48" rx="6" fill="#FFFACF" />
      <path
        d="M21.5447 8.73947C20.1566 8.87273 18.5797 8.54932 17.5691 8.55069C13.8982 8.55565 13.1418 8.52965 8.39648 8.45074C8.60507 11.4569 8.60596 14.5049 8.55195 21.5989C13.125 21.7674 15.7257 21.3102 21.7223 21.7766C21.5512 16.4382 21.5201 13.6028 21.5447 8.73947Z"
        fill="#978101"
      />
      <path
        d="M39.4264 8.59773C35.4596 8.31044 30.5829 8.58082 26.3436 8.51033C26.3436 10.8209 26.3436 16.622 26.4983 21.5931C31.0486 21.7607 35.8739 21.7699 39.6032 21.7699C39.6032 15.0308 39.402 13.4369 39.4264 8.59773Z"
        fill="#978101"
      />
      <path
        d="M21.6841 39.3721C21.9714 35.4053 21.701 30.5286 21.7715 26.2894C19.4609 26.2894 13.6598 26.2894 8.68871 26.4441C8.52107 30.9944 8.51191 35.8197 8.51191 39.5489C15.251 39.5489 16.8449 39.3477 21.6841 39.3721Z"
        fill="#978101"
      />
      <path
        d="M30.7045 25.9086L34.6781 26.2537L34.7614 31.0213L39.0128 30.6771L39.6038 34.0885L34.829 34.3255L34.9073 39.3743L31.3813 39.5493L31.1312 34.5089L26.4716 34.7401L26.2314 31.6725L30.9675 31.2095L30.7045 25.9086Z"
        fill="#533F03"
      />
    </>
  ),
})
