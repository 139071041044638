import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  SelectProps,
  Skeleton,
  Text,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { ProTrialCallout } from "Components/trial-widget/ProTrialCallout"
import { useLanguages } from "Hooks/use-languages"
import {
  Card,
  CardBody,
  CardFooter,
  LegacyCardHeader,
} from "Shared/components/Card/Card"
import { FileInput } from "Shared/components/FileInput/FileInput"
import {
  useCurrentAccount,
  useCurrentPlan,
  useRefreshCurrentAccount,
} from "UsabilityHub/hooks/useCurrentAccount"
import { useShowProTrialWidget } from "Utilities/account"
import React, { forwardRef } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"
import { useUpdateOrganizationSettings } from "~/api/generated/usabilityhub-components"

const OrganizationFormSchema = Yup.object({
  organization_name: Yup.string().defined(),
  logo: Yup.mixed<File>(),
  delete_logo: Yup.string().oneOf(["true", "false"]).required(),
  default_test_button_color: Yup.string().required(),
  default_test_language_code: Yup.string().required(),
})

type OrganizationForm = Yup.InferType<typeof OrganizationFormSchema>

export const OrganizationForm: React.FC = () => {
  const toast = useToast()
  const currentAccount = useCurrentAccount()
  const refreshCurrentAccount = useRefreshCurrentAccount()
  const plan = useCurrentPlan()

  const showProTrialWidget = useShowProTrialWidget()

  const {
    handleSubmit,
    register,
    setValue,
    formState: { isSubmitting },
  } = useForm<OrganizationForm>({
    mode: "all",
    resolver: yupResolver(OrganizationFormSchema),
    defaultValues: {
      organization_name: currentAccount.organization_name,
      logo: undefined,
      delete_logo: "false",
      default_test_button_color: currentAccount.default_test_button_color,
      default_test_language_code: currentAccount.default_test_language_code,
    },
  })

  const { mutateAsync } = useUpdateOrganizationSettings({})

  const onSubmit: SubmitHandler<OrganizationForm> = async (fieldValues) => {
    try {
      await mutateAsync({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: {
          ...fieldValues,
          logo: fieldValues.logo,
        },
      })
      await refreshCurrentAccount()
      toast({
        status: "success",
        title: "Organization settings saved",
      })
    } catch (error) {
      toast({
        status: "error",
        title: "Failed to save organization settings",
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <LegacyCardHeader>
          Update details about your organization
        </LegacyCardHeader>
        <CardBody>
          {showProTrialWidget && (
            <ProTrialCallout
              title="Customize your branding"
              content={`During your ${plan!.name} trial, you can customize the logo and button
        color participants see when they participate in a study.`}
            />
          )}

          <FormControl>
            <FormLabel color="inherit">
              <Heading as="h3">Organization name</Heading>
            </FormLabel>

            <Input {...register("organization_name")} />
          </FormControl>

          <FormControl>
            <FormLabel color="inherit">
              <Heading as="h3">Logo</Heading>
            </FormLabel>
            <Text fontSize="sm" mb={2}>
              The image you choose here will be used instead of the Lyssna logo
              when you recruit users with a link (but not when you use the
              Lyssna panel).
            </Text>
            <Text fontSize="sm" mb={4}>
              This must be a JPG or PNG file that will be displayed at up to
              100px tall and 500px wide. We recommend using a PNG with a
              transparent background.
            </Text>

            <FileInput
              name={register("logo").name}
              srcUrl={currentAccount.logo_url ?? ""}
              onChange={(file) => {
                if (file) {
                  setValue("logo", file)
                  setValue("delete_logo", "false")
                } else {
                  setValue("logo", undefined)
                  setValue("delete_logo", "true")
                }
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel color="inherit">
              <Heading as="h3">Button color</Heading>
            </FormLabel>
            <Text fontSize="sm" mb={4}>
              The color you choose here will be used as the background color of
              buttons in tests, shown instead of Lyssna branded colors to
              participants you recruit with a link (but not when you use the
              Lyssna panel).
            </Text>
            <Input
              {...register("default_test_button_color")}
              type="color"
              maxW="88px"
            />
          </FormControl>

          <FormControl>
            <FormLabel color="inherit">
              <Heading as="h3">Default language</Heading>
            </FormLabel>

            <LanguageSelect {...register("default_test_language_code")} />

            <FormHelperText>
              You can choose a different language for each test, but this will
              be used as the default.
            </FormHelperText>
          </FormControl>

          <CardFooter>
            <Button
              type="submit"
              colorScheme="brand.primary"
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </CardFooter>
        </CardBody>
      </Card>
    </form>
  )
}

const LanguageSelect = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const languages = useLanguages()

    if (languages.length === 0) return <Skeleton h={10} rounded="md" />

    return (
      <Select {...props} ref={ref}>
        {languages.map((language) => (
          <option key={language.code} value={language.code}>
            {language.english_name}
          </option>
        ))}
      </Select>
    )
  }
)
