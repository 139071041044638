import { ButtonGroup, Icon, IconButton, SimpleGrid } from "@chakra-ui/react"
import React from "react"

import { ORDERED_SECTIONS_TYPES } from "Constants/test-section-types"
import { UsabilityTestSectionType } from "Types"
import {
  TestCard,
  TestCardActions,
  TestCardBody,
  TestCardHeader,
  TestCardHeading,
  TestCardProps,
  TestCardTitle,
} from "UsabilityHub/components/TestCard/TestCard"

import { useFeatureFlag } from "Hooks/use-feature-flag"
import { Trash01OutlineIcon } from "Shared/icons/untitled-ui/Trash01OutlineIcon"
import { UsabilityTestSectionType as SectionType } from "Types"
import { AddSectionCardButton } from "./AddSectionCardButton"

interface AddSectionCardProps extends TestCardProps {
  onCancel?: () => void
  onAddSection: (sectionType: UsabilityTestSectionType) => void
}

export const AddSectionCard: React.FC<
  React.PropsWithChildren<AddSectionCardProps>
> = ({ onCancel, onAddSection, id }) => {
  const treeTestingEnabled = useFeatureFlag("tree_testing")
  const liveWebsiteTestEnabled = useFeatureFlag("live_website_testing")

  const sectionTypes = [
    ...ORDERED_SECTIONS_TYPES,
    ...(treeTestingEnabled ? [SectionType.TreeTest] : []),
    ...(liveWebsiteTestEnabled ? [SectionType.LiveWebsiteTest] : []),
  ]

  return (
    <TestCard id={id}>
      <TestCardHeader>
        <TestCardTitle>
          <TestCardHeading>Add a section</TestCardHeading>
        </TestCardTitle>
        {onCancel && (
          <TestCardActions>
            <ButtonGroup
              margin={-2} // Negative margin to counter button padding for alignment
            >
              <IconButton
                onClick={onCancel}
                variant="ghost"
                aria-label="Cancel"
                icon={
                  <Icon color="gray.500" boxSize={5} as={Trash01OutlineIcon} />
                }
              />
            </ButtonGroup>
          </TestCardActions>
        )}
      </TestCardHeader>
      <TestCardBody>
        <SimpleGrid columns={2} spacing={4}>
          {sectionTypes.map((sectionType) => (
            <AddSectionCardButton
              key={sectionType}
              sectionType={sectionType}
              onAddSection={onAddSection}
            />
          ))}
        </SimpleGrid>
      </TestCardBody>
    </TestCard>
  )
}
