import { DemographicPreset } from "~/api/generated/usabilityhubSchemas"
import { TargetLocation } from "../types"

// Transforms the individual country / state / city arrays on a preset into a single array
// of TargetLocation objects.
export function extractLocations(preset: DemographicPreset) {
  const countryNames: TargetLocation[] = preset.countries.map((code) => ({
    type: "country",
    id: code,
  }))
  const stateNames: TargetLocation[] = preset.location_state_ids.map((id) => ({
    type: "state",
    id: id.toString(),
  }))
  const cityNames: TargetLocation[] = preset.location_city_ids.map((id) => ({
    type: "city",
    id: id.toString(),
  }))

  return [...countryNames, ...stateNames, ...cityNames]
}
