import { FigmaTaskScreenResult, FigmaTaskScreenResultMeta } from "Types"

import { getAverageMisclickRate } from "./get-average-misclick-rate"
import { getAverageScreenDuration } from "./get-average-screen-duration"

function getAggregateScreenResultMeta(
  screenResults: FigmaTaskScreenResult[]
): FigmaTaskScreenResultMeta {
  const allParticipants = new Set(
    screenResults.flatMap((screenResult) => screenResult.meta.participants)
  )
  return {
    type: "aggregate",
    clicks: screenResults.flatMap((screenResult) => screenResult.meta.clicks),
    duration: getAverageScreenDuration(screenResults),
    misclickRate: getAverageMisclickRate(screenResults),
    participants: [...allParticipants],
  }
}
export function getAggregateScreenResults(
  results: FigmaTaskScreenResult[]
): FigmaTaskScreenResult {
  return {
    nodeId: results[0].nodeId,
    figmaImage: results[0].figmaImage,
    meta: getAggregateScreenResultMeta(results),
  }
}
