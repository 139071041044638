import {
  AppearanceProps,
  LayoutState,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"
import { ROUTES } from "UserCrowd/views/routes"
import React, { useEffect } from "react"

const PracticeTestThankYou: React.FC = () => {
  useEffect(() => {
    window.location.href = ROUTES.DASHBOARD.PRACTICE_TEST_COMPLETE.path
  }, [])

  return null
}

export default function practiceTestThankYouContent(): AppearanceProps {
  return {
    layoutState: LayoutState.FocusQuestion,
    mediaContent: null,
    questionContent: <PracticeTestThankYou />,
    isReportButtonVisible: false,
  }
}
