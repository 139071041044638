import { Box, Flex } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import React from "react"
import { GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksResponse } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyOrderSummaryList } from "./ModeratedStudyOrderSummaryList"
import { ModeratedStudyRecruitmentLinkSummaryCard } from "./ModeratedStudyRecruitmentLinkSummaryCard"

type Props = {
  moderatedStudyId: string
  recruitmentLinksData:
    | GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksResponse
    | undefined
}

export const ModeratedStudyRecruitmentList: React.FC<Props> = ({
  moderatedStudyId,
  recruitmentLinksData,
}) => {
  if (!recruitmentLinksData) return null

  const recruitmentLinks =
    recruitmentLinksData.moderated_study_recruitment_links

  return (
    <>
      {(recruitmentLinks.length > 0 ||
        recruitmentLinksData.moderated_study_orders.length > 0) && (
        <Box mt="8">
          <Heading as="h2" textStyle="ds.display.primary">
            Recruitment activity
          </Heading>
        </Box>
      )}
      {recruitmentLinks.length > 0 && (
        <Flex mt="4" flexDirection="column" gap={4}>
          {recruitmentLinks.map((recruitmentLink) => (
            <ModeratedStudyRecruitmentLinkSummaryCard
              key={recruitmentLink.id}
              recruitmentLink={recruitmentLink}
              moderatedStudyId={moderatedStudyId}
            />
          ))}
        </Flex>
      )}

      <Box mt="4">
        <ModeratedStudyOrderSummaryList
          moderatedStudyId={moderatedStudyId}
          orders={recruitmentLinksData.moderated_study_orders}
        />
      </Box>
    </>
  )
}
