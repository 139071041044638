import { Filter } from "Components/test-results/types/filters"
import { rectangleContainsPoint } from "Utilities/rectangle"

import { FilterFunction } from "./types"

export type HitzoneFilterCategory =
  `answer/section:${number}/screenshot:${number}/screenshot-click`

export type HitzoneFilter = Filter<
  HitzoneFilterCategory,
  "answer/hitzone",
  number
>

export const filterResponseByHitzone: FilterFunction<HitzoneFilter> = ({
  filter,
  filteredRelatedData: { screenshotClicks },
  allRelatedData: { hitzones },
}) => {
  const hitzone = hitzones.find((hitzone) => hitzone.id === filter.value)

  if (!hitzone) return false

  const filteredScreenshotClicks = screenshotClicks.filter(
    (click) =>
      click.usability_test_section_screenshot_id ===
      hitzone?.usability_test_section_screenshot_id
  )

  return filteredScreenshotClicks.some(rectangleContainsPoint(hitzone))
}
