import { Flex, Heading, Icon, IconButton, Tooltip } from "@chakra-ui/react"
import React from "react"

import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"

import { Copy01OutlineIcon } from "Shared/icons/untitled-ui/Copy01OutlineIcon"
import { Trash01OutlineIcon } from "Shared/icons/untitled-ui/Trash01OutlineIcon"
import { getScreenerQuestionLabel } from "./getScreenerQuestionTypeLabel"

function getScreenerQuestionHeadline(type: ScreenerQuestion["type"]): string {
  return `${getScreenerQuestionLabel(type)} question`
}

interface Props {
  type: ScreenerQuestion["type"]
  onDelete: () => void
  onDuplicate: () => void
  readOnly: boolean
  maxScreenerQuestionsReached: boolean
}

export const ScreenerQuestionHeader: React.FC<Props> = ({
  type,
  onDelete,
  onDuplicate,
  readOnly,
  maxScreenerQuestionsReached,
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Heading fontSize="sm" fontWeight="normal">
        {getScreenerQuestionHeadline(type)}
      </Heading>

      <Flex gap={1}>
        <Tooltip hasArrow placement="top" label="Duplicate question">
          <IconButton
            variant="ghost"
            size="sm"
            aria-label="Duplicate question"
            icon={<Icon as={Copy01OutlineIcon} />}
            onClick={onDuplicate}
            isDisabled={readOnly || maxScreenerQuestionsReached}
          />
        </Tooltip>

        <IconButton
          variant="ghost"
          size="sm"
          aria-label="Delete question"
          data-qa="delete-screener-question"
          icon={<Icon as={Trash01OutlineIcon} />}
          onClick={onDelete}
          isDisabled={readOnly}
        />
      </Flex>
    </Flex>
  )
}
