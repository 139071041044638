import { Box, CloseButton, Flex, Image, Text } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Button } from "DesignSystem/components/Button"
import graphic from "Images/app-illustrations/incentive.png"
import cheekyArrowSrc from "Images/cheeky-arrow.svg"
import { AnnouncementBanner } from "Shared/components/AnnouncementBanner"
import { TextHighlight } from "Shared/components/TextHighlight/TextHighlight"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  useDismissOrderDiscount,
  useGetExperiments,
} from "~/api/generated/usabilityhub-components"
import { ROUTES } from "~/usabilityhub/views/routes"

export const DiscountOrderBanner: React.FC<React.PropsWithChildren> = () => {
  const { isLoading, data } = useGetExperiments({})
  const [isDismissed, setIsDismissed] = useState(false)
  const { mutate: dismissDiscount } = useDismissOrderDiscount()

  const dismiss = () => {
    setIsDismissed(true)
    dismissDiscount({})
  }

  if (isLoading) return null

  if (data?.panel_order_discount === "banner") {
    return (
      <AnnouncementBanner
        bg="ds.background.accent.yellow.subtle"
        mt={-8}
        mb={8}
        isOpen={!isDismissed}
        image={graphic}
        actions={
          <>
            <Button
              variant="upgrade"
              size="emphasized"
              as={Link}
              to={ROUTES.TEST.RECRUIT.$.ORDERS.relativePath}
              data-intercom-target="place-an-order-button"
            >
              Redeem offer
            </Button>
          </>
        }
        onClose={dismiss}
      >
        Looking for fast feedback? Give our research panel a try and get{" "}
        {`$${Constants.INTRO_OFFER_DISCOUNT_IN_CENTS / 100} `}
        off when you spend ${Constants.INTRO_OFFER_MINIMUM_SPEND_IN_CENTS / 100}{" "}
        or more on your first order. Best of all, quality is guaranteed!
      </AnnouncementBanner>
    )
  }

  return isDismissed ? null : (
    <Flex w="55%" my={4} mx="auto" justifyContent="center">
      <Flex transform="rotate(-3deg)" transformOrigin="top left">
        <Box m={3} w="100px" h="50px">
          <Image src={cheekyArrowSrc} />
        </Box>
        <Flex flexDirection="column">
          <Text fontSize="xl" fontWeight="bold" mb={1}>
            Haven{"\u2019"}t tried our panel yet?
          </Text>
          <Text>
            Try it now and we{"\u2019"}ll give you{" "}
            <TextHighlight>
              ${Constants.INTRO_OFFER_DISCOUNT_IN_CENTS / 100} off your first
              order
            </TextHighlight>{" "}
            of ${Constants.INTRO_OFFER_MINIMUM_SPEND_IN_CENTS / 100} or more.
          </Text>
        </Flex>
        <CloseButton onClick={dismiss} />
      </Flex>
    </Flex>
  )
}
