import { createIcon } from "@chakra-ui/react"
import React from "react"

export const FilterFunnel01SolidIcon = createIcon({
  displayName: "FilterFunnel01SolidIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.56809 2C3.57871 2 3.58934 2.00001 3.59999 2.00001L20.4319 2C20.6843 1.99997 20.9301 1.99994 21.1382 2.01695C21.3668 2.03563 21.6365 2.07969 21.908 2.21799C22.2843 2.40974 22.5903 2.7157 22.782 3.09202C22.9203 3.36345 22.9644 3.63318 22.983 3.86178C23 4.06993 23 4.31571 23 4.56809L23 5.2694C23 5.28764 23 5.30639 23.0001 5.32559C23.0007 5.53391 23.0015 5.79616 22.9343 6.05176C22.8761 6.27325 22.7803 6.4831 22.651 6.67216C22.5019 6.89031 22.3032 7.06149 22.1454 7.19746C22.1308 7.21 22.1166 7.22224 22.1028 7.23419L15.707 12.7772C15.5983 12.8714 15.5439 12.9189 15.506 12.9552L15.5031 12.958L15.5029 12.9621C15.5003 13.0145 15.5 13.0867 15.5 13.2306V18.4584C15.5 18.4693 15.5001 18.4821 15.5003 18.4964C15.5017 18.6339 15.5048 18.9193 15.4054 19.1858C15.3218 19.4099 15.1858 19.6108 15.0087 19.7716C14.7983 19.9629 14.5321 20.0661 14.4039 20.1158C14.3905 20.121 14.3787 20.1256 14.3685 20.1296L10.9406 21.5008C10.7817 21.5644 10.606 21.6347 10.4511 21.681C10.2844 21.7307 10.0106 21.7957 9.69481 21.7301C9.30778 21.6497 8.96815 21.4198 8.74974 21.0903C8.57152 20.8214 8.53021 20.543 8.51449 20.3698C8.49987 20.2088 8.49993 20.0196 8.49998 19.8484L8.49999 13.2306C8.49999 13.0867 8.4997 13.0145 8.49711 12.9621L8.4969 12.958L8.49396 12.9552C8.45606 12.9189 8.40167 12.8714 8.29294 12.7772L1.89717 7.23419C1.88338 7.22224 1.86918 7.21 1.85462 7.19747C1.6968 7.06149 1.49812 6.89032 1.34897 6.67216C1.21972 6.4831 1.12389 6.27325 1.06568 6.05176C0.998502 5.79617 0.999267 5.53391 0.999875 5.3256C0.999931 5.30639 0.999986 5.28764 0.999986 5.2694V4.60001C0.999986 4.58936 0.999985 4.57873 0.999983 4.56811C0.999953 4.31572 0.999924 4.06993 1.01693 3.86178C1.03561 3.63318 1.07968 3.36345 1.21797 3.09202C1.40972 2.7157 1.71568 2.40974 2.092 2.21799C2.36343 2.07969 2.63316 2.03563 2.86176 2.01695C3.06991 1.99994 3.31571 1.99997 3.56809 2Z"
      fill="currentColor"
    />
  ),
})
