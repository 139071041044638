import { CSSProperties } from "react"

import { Dimensions } from "Types"
import { pixels } from "Utilities/css-helpers"

export function aspectRatio({ width, height }: Readonly<Dimensions>): number {
  return height === 0 ? 0 : width / height
}

export function containWidth(
  dimensions: Readonly<Dimensions>,
  nextWidth: number
): Dimensions {
  if (nextWidth === dimensions.width) {
    return {
      height: dimensions.height,
      width: dimensions.width,
    }
  }

  const aspect = aspectRatio(dimensions)
  const correctedHeight = nextWidth / aspect
  return { width: nextWidth, height: correctedHeight }
}

export function scaleDown(
  source: Dimensions,
  container: Readonly<Dimensions>
): Dimensions {
  // If source is smaller than container, return source
  if (source.width < container.width && source.height < container.height) {
    return { width: source.width, height: source.height }
  }

  // Else contain
  const sourceAspect = aspectRatio(source)
  return contain(sourceAspect, container)
}

export function contain(
  sourceAspect: number,
  container: Readonly<Dimensions>
): Dimensions {
  if (sourceAspect < aspectRatio(container)) {
    return {
      width: container.height * sourceAspect,
      height: container.height,
    }
  }
  return {
    width: container.width,
    height: container.width / sourceAspect,
  }
}

export function toCss({
  width,
  height,
}: Readonly<Dimensions>): Pick<CSSProperties, "width" | "height"> {
  return {
    width: pixels(width),
    height: pixels(height),
  }
}

export function elementDimensions(element: HTMLElement): Dimensions {
  return {
    width: element.offsetWidth,
    height: element.offsetHeight,
  }
}
