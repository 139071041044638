import { Alert, AlertIcon } from "@chakra-ui/react"
import React, { useState } from "react"

import {
  CardSortData,
  CardSortOpenCategoryData,
} from "JavaScripts/types/response-section"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"
import {
  cardDragStartTime,
  cardSortTime,
  cardsSortTime,
  isTaskComplete,
  isTaskStarted,
  sectionStartTime,
} from "Utilities/response"
import { CardSort } from "./CardSortTask/CardSort"
import { CardSortContextProvider } from "./CardSortTask/CardSortContext"
import { SortDataMap } from "./CardSortTask/types"

export const CardSortTask: React.FC<InnerProps> = ({
  updateResponseSection,
  usabilityTestSection,
  responseSection,
}) => {
  const [startTime, setStartTime] = useState<number>()

  if (!usabilityTestSection.card_sort) {
    return (
      <Alert status="error">
        <AlertIcon />
        No card sort data!
      </Alert>
    )
  }

  const isStarted = isTaskStarted(responseSection)
  const isComplete = isTaskComplete(responseSection)

  const {
    card_sort: {
      card_sort_type: cardSortType,
      shuffle_cards: shuffleCards,
      shuffle_categories: shuffleCategories,
      card_sort_cards: cards,
      card_sort_categories: categories,
      require_sort_all: requireSortAll,
      require_name_all: requireNameAll,
    },
  } = usabilityTestSection

  const onStart = () => {
    const clickStartTime = performance.now()
    setStartTime(clickStartTime)

    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms:
        clickStartTime - sectionStartTime(responseSection),
    })
  }

  const onDragCardStart = (cardId: number) => {
    const dragCardStartTime = performance.now()
    const sectionCardsSortTime = cardsSortTime(responseSection)

    updateResponseSection(usabilityTestSection.id, {
      cards_sort_time: {
        ...sectionCardsSortTime,
        [cardId]: {
          start_time_ms: dragCardStartTime,
        },
      },
    })
  }

  const onDragCardEnd = (cardId: number) => {
    const dragCardEndTime = performance.now()
    const sectionCardsSortTime = cardsSortTime(responseSection)
    const start_time_ms = cardDragStartTime(responseSection, cardId)

    updateResponseSection(usabilityTestSection.id, {
      cards_sort_time: {
        ...sectionCardsSortTime,
        [cardId]: {
          start_time_ms: start_time_ms,
          end_time_ms: dragCardEndTime,
        },
      },
    })
  }

  const onComplete = (sortData: SortDataMap) => {
    if (!startTime) {
      throw new Error("onComplete called before onStart")
    }

    const completeTime = performance.now()

    // Transformed sortData
    const cardSortOpenCategoriesData: CardSortOpenCategoryData[] = []
    const cardSortCategoriesCardsData: CardSortData[] = []

    Object.entries(sortData).forEach(([categoryId, cardIds]) => {
      const uniqueCardIds = [...new Set(cardIds)]
      categoryId.startsWith("open_")
        ? cardSortOpenCategoriesData.push({
            label: categoryId.substring(
              5,
              categoryId.lastIndexOf("_uniqueid_")
            ),
            card_sort_categories_cards_attributes: uniqueCardIds.map(
              (cardId, index) => ({
                card_sort_card_id: Number(cardId),
                position_within_category: index,
                sort_time_ms: cardSortTime(responseSection, Number(cardId)),
              })
            ),
          })
        : uniqueCardIds.forEach((cardId, index) =>
            cardSortCategoriesCardsData.push({
              card_sort_card_id: Number(cardId),
              card_sort_category_id:
                categoryId === "unsorted" ? null : Number(categoryId),
              position_within_category: index,
              sort_time_ms: cardSortTime(responseSection, Number(cardId)),
            })
          )
    })

    updateResponseSection(usabilityTestSection.id, {
      task_duration_ms: completeTime - startTime,
      card_sort_categories_cards_attributes: cardSortCategoriesCardsData,
      card_sort_open_categories_attributes: cardSortOpenCategoriesData.filter(
        (data) => data.card_sort_categories_cards_attributes.length > 0
      ),
    })
  }

  return (
    <CardSortContextProvider
      active={isStarted && !isComplete}
      cardSortType={cardSortType}
      cards={cards}
      categories={categories}
      shuffleCards={shuffleCards}
      shuffleCategories={shuffleCategories}
    >
      <CardSort
        instructions={usabilityTestSection.text}
        isStarted={isStarted}
        isComplete={isComplete}
        onStart={onStart}
        onComplete={onComplete}
        onDragCardStart={onDragCardStart}
        onDragCardEnd={onDragCardEnd}
        requireSortAll={requireSortAll}
        requireNameAll={requireNameAll}
      />
    </CardSortContextProvider>
  )
}
