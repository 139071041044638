import { Filter } from "Components/test-results/types/filters"

import { getFilterMeta } from "../get-filter-meta"

import { FilterFunction } from "./types"

export type CommonPathFilter = Filter<
  `answer/section:${number}/common-path`,
  "answer/common-path",
  string
>

export const filterResponseByCommonPath: FilterFunction<CommonPathFilter> = ({
  filter,
  filteredRelatedData: { responseSections, responseSectionFigmaTasks },
}) => {
  const { sectionId } = getFilterMeta(filter.category)

  const responseSection = responseSections.find(
    (rs) => rs.usability_test_section_id === sectionId
  )

  if (responseSection === undefined) {
    return false
  }

  // Find the path for this response section.
  const figmaTask = responseSectionFigmaTasks.find(
    (ft) => ft.response_section_id === responseSection.id
  )

  // No path means they definitely didn't take any of our filter paths.
  if (figmaTask === undefined) {
    return false
  }

  return filter.value === figmaTask.path
}
