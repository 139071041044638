import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

import { useSectionMediaContext } from "../SectionMediaContext"

import {
  useDeviceFramesForSectionContext,
  useSectionContext,
} from "UsabilityHub/contexts"
import { TestFormEditorScreenshot } from "./screenshots/screenshot"
import { useSectionMediaPath } from "./useSectionMediaPath"

const WrappedMediaRowThumbnail: React.FC<
  React.PropsWithChildren<WrappedFieldProps>
> = () => {
  const { sectionMedia, mediaFile } = useSectionMediaContext()
  const { section } = useSectionContext()
  const deviceFrame = useDeviceFramesForSectionContext(section)

  // TODO: Convert screenshot rendering components to Chakra.
  return (
    <TestFormEditorScreenshot
      screenshot={mediaFile}
      hitzones={sectionMedia.screenshot_hitzones || []}
      deviceFrame={deviceFrame}
    />
  )
}

// Register the field so a validation error will stop the form being submitted
export const MediaRowThumbnail: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const sectionMediaPath = useSectionMediaPath()
  return (
    <Field
      name={`${sectionMediaPath}.screenshot_id`}
      component={WrappedMediaRowThumbnail}
    />
  )
}
