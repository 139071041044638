import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const MaximizeIcon = createIcon({
  displayName: "MaximizeIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        fill="currentColor"
        d="M20.7092 3.29502C20.804 3.3904 20.8757 3.50014 20.9241 3.61722C20.9727 3.73425 20.9996 3.8625 21 3.997L21 4V9C21 9.55228 20.5523 10 20 10C19.4477 10 19 9.55228 19 9V6.41421L14.7071 10.7071C14.3166 11.0976 13.6834 11.0976 13.2929 10.7071C12.9024 10.3166 12.9024 9.68342 13.2929 9.29289L17.5858 5H15C14.4477 5 14 4.55228 14 4C14 3.44772 14.4477 3 15 3H19.9998C20.2749 3 20.5242 3.11106 20.705 3.29078L20.7092 3.29502Z"
      />
      <path
        fill="currentColor"
        d="M3.29078 20.705C3.19595 20.6096 3.12432 20.4999 3.07588 20.3828C3.02735 20.2657 3.0004 20.1375 3 20.003L3 20V15C3 14.4477 3.44771 14 4 14C4.55228 14 5 14.4477 5 15L5 17.5858L9.29289 13.2929C9.68342 12.9024 10.3166 12.9024 10.7071 13.2929C11.0976 13.6834 11.0976 14.3166 10.7071 14.7071L6.41421 19H9C9.55228 19 10 19.4477 10 20C10 20.5523 9.55228 21 9 21H4.00017C3.72511 21 3.47581 20.8889 3.29502 20.7092L3.29078 20.705Z"
      />
    </>
  ),
})
