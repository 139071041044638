import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import {
  Card,
  CardBody,
  CardFooter,
  LegacyCardHeader,
} from "Shared/components/Card/Card"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { Field, FieldProps, Form, Formik } from "formik"
import React from "react"
import { useUpdateBillingDetails } from "~/api/generated/usabilityhub-components"

type BillingDetails = React.FC<React.PropsWithChildren<unknown>>

export const BillingDetails: BillingDetails = () => {
  const currentAccount = useCurrentAccount()
  const toast = useToast()
  const { billing_details, billing_email, contact_name } = currentAccount
  const { mutate: updateBillingDetails, isLoading: isSubmitting } =
    useUpdateBillingDetails({
      onSuccess: () => {
        toast({
          title: "Your changes have been saved",
          status: "success",
        })
      },
      onError: (error) => {
        toast({
          title: error.payload.message ?? "Unable to save changes",
          status: "error",
        })
      },
    })

  return (
    <Formik
      initialValues={{
        account: {
          billing_details,
          billing_email,
          contact_name: contact_name || "",
        },
      }}
      onSubmit={(body) => updateBillingDetails({ body })}
    >
      <Form>
        <Card>
          <LegacyCardHeader>Billing details</LegacyCardHeader>
          <CardBody>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Field name="account.billing_email" type="email">
                {({ field }: FieldProps) => <Input {...field} />}
              </Field>
              <FormHelperText>
                Invoices will be sent to this address.
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Contact name</FormLabel>
              <Field name="account.contact_name" type="text">
                {({ field }: FieldProps) => <Input {...field} />}
              </Field>
              <FormHelperText>
                Invoices will be made out to this name.
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Additional details</FormLabel>
              <Field name="account.billing_details">
                {({ field }: FieldProps) => <Textarea {...field} />}
              </Field>
              <FormHelperText>
                If you need to add a billing address or a tax information to
                your invoices, put it here.
              </FormHelperText>
            </FormControl>
            <CardFooter>
              <Button
                isLoading={isSubmitting}
                type="submit"
                colorScheme="brand.primary"
              >
                Save billing details
              </Button>
            </CardFooter>
          </CardBody>
        </Card>
      </Form>
    </Formik>
  )
}
