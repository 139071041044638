import { Dimensions, FigmaTaskClick } from "Types"

import { NormalizedClick } from "./normalized-clicks"

function getNormalizedFigmaTaskClick(
  click: FigmaTaskClick,
  clickIndex: number,
  { width, height }: Dimensions
): NormalizedClick {
  return {
    id: clickIndex,
    x: click.frame_click_x / width,
    y: click.frame_click_y / height,
    hit: click.is_hotspot_click,
  }
}

export function getNormalizedFigmaTaskClicks(
  clicks: FigmaTaskClick[],
  dimensionObject: Dimensions
) {
  return clicks.map((click, clickIndex) =>
    getNormalizedFigmaTaskClick(click, clickIndex, dimensionObject)
  )
}
