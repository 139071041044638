import { useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import { createPreferenceAnswerFilter } from "../filters/create-filters/preference-answer-filter"
import { getFilterUtilityFunctions } from "../filters/get-filter-utility-functions"

const { isFilter, isFiltered, toggleFilter } = getFilterUtilityFunctions(
  createPreferenceAnswerFilter
)

export const usePreferenceFilter = (
  sectionId: number,
  sectionScreenshotId: number
) => {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handleFilterToggle = () => {
    setTestResultsFilters((filters) =>
      toggleFilter(filters, sectionId, sectionScreenshotId)
    )
  }

  return {
    isFilter: isFilter(testResultsFilters, sectionId, sectionScreenshotId),
    isFiltered: isFiltered(testResultsFilters, sectionId, sectionScreenshotId),
    toggleFilter: handleFilterToggle,
  }
}
