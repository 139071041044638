import React from "react"
import { useSelector } from "react-redux"

import { getFigmaFlowPrototypeUrl } from "Redux/reducers/figma-file-versions/selectors"
import { FigmaEmbed } from "Shared/components/FigmaEmbed/FigmaEmbed"
import { FigmaFileFlow } from "Types"

import { useFigmaMessages } from "./useFigmaMessages"

interface PrototypeTaskFigmaFlowProps {
  figmaFlow: FigmaFileFlow
}

export const PrototypeTaskFigmaFlow: React.FC<
  React.PropsWithChildren<PrototypeTaskFigmaFlowProps>
> = ({ figmaFlow }) => {
  useFigmaMessages()

  // Previously we have overridden the scaling settings to "min-zoom" when on mobile
  // Now decide to remove our override.
  // This may result in a small proto in some instances but it is more consistent and respects the option the user set.
  const prototypeUrl = useSelector(getFigmaFlowPrototypeUrl(figmaFlow))
  return <FigmaEmbed prototypeUrl={prototypeUrl}>Figma flow</FigmaEmbed>
}
