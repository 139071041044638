import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  ImageProps,
  Stack,
  StackDivider,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import {
  CursorClickIcon,
  FilterIcon,
  UserCircleIcon,
} from "@heroicons/react/solid"
import isSafari from "is-safari"
import React from "react"

import { getAllClicks } from "Components/test-results/utils/screen-results/get-all-clicks"
import { FigmaImage, FigmaTaskScreenResult } from "Types"
import { useSectionContext } from "UsabilityHub/contexts/SectionContext"
import { pluralizeWithCount } from "Utilities/string"
import { formatDigitalClockTimeString } from "Utilities/time"

import { ClockOutlineIcon } from "Shared/icons/untitled-ui/ClockOutlineIcon"
import { useTaskGoalNodeId } from "../hooks/use-goal-node-id"
import { usePrototypeNodeFilter } from "../hooks/use-prototype-node-filter"

export interface ScreenThumbnailContainerProps
  extends Omit<
    ScreenThumbnailProps,
    "figmaImage" | "isGoal" | "time" | "totalClicks"
  > {
  screenResult: FigmaTaskScreenResult
}

export function ScreenThumbnailContainer({
  screenResult,
  ...props
}: ScreenThumbnailContainerProps) {
  const { section } = useSectionContext()
  const { isNodeFilteredIn } = usePrototypeNodeFilter(
    section.id,
    screenResult.nodeId
  )
  const taskGoalNodeId = useTaskGoalNodeId()

  const isGoalScreen = taskGoalNodeId === screenResult.nodeId

  return (
    <ScreenThumbnail
      {...props}
      figmaImage={screenResult.figmaImage}
      isGoal={isGoalScreen}
      time={screenResult.meta.duration}
      totalClicks={getAllClicks(screenResult).length}
      isFilterActive={isNodeFilteredIn}
    />
  )
}

interface ScreenThumbnailState {
  isGoal: boolean
  isSelected: boolean
  isFilterActive: boolean
}

interface ScreenThumbnailProps extends Partial<ScreenThumbnailState> {
  showFilterButton?: boolean
  figmaImage?: FigmaImage
  time?: number
  totalClicks?: number
  participantsCount?: number
  onClick?: () => void
  onFilterButtonClick?: () => void
  hideFrameTitle?: boolean
  hideStats?: boolean
  showZoomCursor?: boolean
  showPathStats?: boolean
}

type ColorScheme = {
  bg: string
  border: string
  text: string
  divider: string
  stats: string
}

const defaultColors: ColorScheme = {
  bg: "gray.100",
  border: "gray.300",
  text: "gray.600",
  divider: "gray.300",
  stats: "gray.400",
}

const selectedColors: ColorScheme = {
  bg: "brand.primary.500",
  border: "brand.primary.500",
  text: "white",
  divider: "whiteAlpha.500",
  stats: "whiteAlpha.700",
}

function getColorScheme({
  isGoal,
  isSelected,
  isFilterActive,
}: ScreenThumbnailState) {
  if (isSelected && isFilterActive) {
    return selectedColors
  }

  if (isSelected) {
    return selectedColors
  }

  if (isGoal) {
    return {
      ...selectedColors,
      bg: "green.500",
      border: "green.500",
    }
  }

  if (isFilterActive) {
    return {
      ...defaultColors,
      border: selectedColors.border,
    }
  }

  return defaultColors
}

export const ScreenThumbnail: React.FC<
  React.PropsWithChildren<ScreenThumbnailProps>
> = ({
  isGoal = false,
  isSelected = false,
  isFilterActive = false,
  showFilterButton = false,
  figmaImage,
  time,
  totalClicks,
  onClick,
  hideFrameTitle = false,
  hideStats = false,
  showPathStats = false,
  showZoomCursor,
  onFilterButtonClick,
  participantsCount,
}) => {
  const colors = getColorScheme({ isGoal, isSelected, isFilterActive })
  const imageProps: ImageProps = {}
  if (!isSafari) {
    imageProps.crossOrigin = "anonymous"
  }

  return (
    <Flex
      position="relative"
      role="group"
      bg={colors.bg}
      border="2px"
      borderColor={colors.border}
      _hover={{
        borderColor: !isSelected && !isGoal && !isFilterActive && "gray.500",
        shadow: "lg",
      }}
      _groupFocusVisible={{
        borderColor: !isSelected && !isGoal && !isFilterActive && "gray.500",
        shadow: "lg",
      }}
      rounded="md"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      flexDirection="column"
      onClick={onClick}
      flexShrink={0}
      cursor={showZoomCursor ? "zoom-in" : "inherit"}
    >
      {showFilterButton && (
        <Tooltip
          hasArrow
          placement="top"
          label="Filter the test based on participants who have visited this screen"
        >
          <IconButton
            // This component is already a child of a button, and button cannot appear as children of buttons so it pretends to be a div
            as="div"
            role="button"
            icon={
              <Icon
                as={FilterIcon}
                color={isFilterActive ? "brand.primary.500" : "gray.500"}
              />
            }
            aria-label="Filter"
            display={isFilterActive ? "flex" : "none"}
            sx={{
              '[data-group-name="screen"]:hover &': {
                display: "flex",
              },
            }}
            position="absolute"
            top="1"
            right="1"
            variant="outline"
            size="xs"
            colorScheme={isFilterActive ? "brand.primary" : "gray"}
            onClick={onFilterButtonClick}
          />
        </Tooltip>
      )}
      <Flex
        h="80px"
        w="100%"
        p={1}
        alignItems="center"
        justifyContent="center"
        borderBottomRadius="4px"
        borderTopRadius={0}
        bg="white"
        data-qa={figmaImage && `node-id-${figmaImage.figma_node_id}`}
      >
        {figmaImage ? (
          <Image {...imageProps} src={figmaImage.url} h="100%" maxW="150px" />
        ) : (
          <Text>Processing&hellip;</Text>
        )}
      </Flex>
      {(!hideStats || !hideFrameTitle) && (
        <Stack direction="column" w="100%" py={1} px={1} spacing={1}>
          {!hideFrameTitle && (
            <Text
              flexBasis={4}
              flexShrink={0}
              fontSize="xs"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              color={colors.text}
              fontWeight="medium"
            >
              {figmaImage?.title ?? "Title loading"}
            </Text>
          )}
          {!hideStats && (
            <>
              <StackDivider h="1px" bg={colors.divider} />

              <HStack justifyContent="space-between" textColor={colors.stats}>
                {showPathStats ? (
                  <PathStats time={time} totalClicksCount={totalClicks} />
                ) : (
                  <Stats time={time} participantsCounts={participantsCount} />
                )}
              </HStack>
            </>
          )}
        </Stack>
      )}
    </Flex>
  )
}

interface PathStatsProps {
  time?: number
  totalClicksCount?: number
}

function PathStats({ time, totalClicksCount }: PathStatsProps) {
  return (
    <>
      <HStack spacing={1}>
        <ClockOutlineIcon />
        <Text fontSize="xs" fontWeight="medium">
          {formatDigitalClockTimeString(time ?? 0)}
        </Text>
      </HStack>
      <HStack spacing={1}>
        <Icon as={CursorClickIcon} />
        <Text fontSize="xs" fontWeight="medium">
          {pluralizeWithCount(totalClicksCount ?? 0, "click", "clicks")}
        </Text>
      </HStack>
    </>
  )
}

interface StatsProps {
  participantsCounts?: number
  time?: number
}

function Stats({ participantsCounts, time }: StatsProps) {
  return (
    <>
      <HStack spacing={1}>
        <Icon as={UserCircleIcon} />
        <Text fontSize="xs" fontWeight="medium">
          {participantsCounts ?? 0}
        </Text>
      </HStack>
      <HStack spacing={1}>
        <ClockOutlineIcon />
        <Text fontSize="xs" fontWeight="medium">
          {formatDigitalClockTimeString(time ?? 0)}
        </Text>
      </HStack>
    </>
  )
}
