import { UsabilityTestSection, UsabilityTestSectionType } from "Types"

// We have several representations of a test on the frontend, and this needs to handle them all:
// 1. The API response from useUsabilityTestPage
// 2. DashboardUsabilityTest
// 3. Anything that extends the RawParticipantUsabilityTest interface
// ...and probably others that will come up as we go
export const isLiveWebsiteTest = (
  test:
    | {
        sections: UsabilityTestSection[] | { section_type: string }[]
      }
    | { is_live_website_test: boolean }
) => {
  if ("is_live_website_test" in test && test.is_live_website_test) return true
  if (test && "sections" in test) {
    return test.sections.some(
      (section) =>
        "type" in section &&
        section.type === UsabilityTestSectionType.LiveWebsiteTest
    )
  }
  return false
}
