import { createIcon } from "@chakra-ui/react"
import React from "react"

export const CreateStudyTestIcon = createIcon({
  displayName: "CreateStudyTestIcon",
  viewBox: "0 0 48 48",
  path: (
    <>
      <g>
        <rect width="48" height="48" rx="6" fill="#F0E8FF" />
        <path
          d="M33.962 15.272 4.87 22.51l11.708 7.962 4.826 12.861 12.558-28.06Z"
          fill="#5723B4"
        />
        <g fill="#B698F2">
          <path d="m22.578 10.357 3.342 2.426.959-.997-2.295-3.108-2.006 1.679ZM32.32 11.08l-1.784-.432-.722-4.712 3.066-.164-.56 5.308ZM38.55 7.529l-2.91 4.45.753 1.543 3.826-3.316-1.67-2.677ZM42.573 15.638l-4.771.716-.179 1.689 4.645.49.305-2.895ZM40.082 24.218l-3.352-2.915-1.063 1.413 2.627 3.997 1.788-2.495Z" />
        </g>
      </g>
    </>
  ),
})
