import { IndeterminateBoolean } from "Types"

/**
 * Reduces an array of values to an `IndeterminateBoolean`. `condition` is
 * invokoed for each element.
 *
 * @returns `true` if all truthy, `false` is all falsy, otherwise `"mixed"`
 */
export function reduceIndeterminateBoolean<T>(
  elements: ReadonlyArray<T>,
  condition: (element: T) => any
): IndeterminateBoolean {
  const presentCount = elements.reduce((count, element) => {
    return condition(element) ? count + 1 : count
  }, 0)
  switch (presentCount) {
    case 0:
      return false
    case elements.length:
      return true
    default:
      return "mixed"
  }
}
