import { UnknownDemographicAttributeOptionFilter } from "../filters/attribute-demographic-attribute-option-unknown"
import { DemographicAttributeOptionIdFilter } from "../filters/attribute-exact-value"

import { generateFilterId } from "./generate-filter-id"

export function createDemographicAttributeOptionIdFilterCategory(
  demographicAttributeCode: string
): DemographicAttributeOptionIdFilter["category"] {
  return `response/attribute:demographic_attribute_option_id/${demographicAttributeCode}`
}

export function createDemographicAttributeOptionIdFilter(
  demographicAttributeCode: string,
  id: number
): DemographicAttributeOptionIdFilter {
  return {
    id: generateFilterId(),
    category: createDemographicAttributeOptionIdFilterCategory(
      demographicAttributeCode
    ),
    type: "attribute/exact-value",
    value: id,
  }
}

export function createUnknownDemographicAttributeOptionIdFilter(
  demographicAttributeCode: string,
  demographicAttributeOptionIds: number[]
): UnknownDemographicAttributeOptionFilter {
  return {
    id: generateFilterId(),
    category: createDemographicAttributeOptionIdFilterCategory(
      demographicAttributeCode
    ),
    type: "attribute/unknown-demographic-attribute-option",
    value: demographicAttributeOptionIds,
  }
}
