import { GetModeratedStudyApplicationsResponse } from "~/api/generated/usabilityhub-components"

type NonInvitableStatus =
  GetModeratedStudyApplicationsResponse["applications"][number]["status"]

// we want to allow sending invites to 'invited' (as a reminder) and 'canceled' self-recruited participants
export const NON_INVITABLE_SELF_RECRUITED_STATUSES: NonInvitableStatus[] = [
  "booked",
  "reschedule_requested",
  "declined_by_applicant",
  "declined_by_researcher",
  "declined_by_automatic",
  "complete",
  "auto_complete",
  "no_show",
  "reported",
  "invitation_revoked",
]
export const NON_INVITABLE_PANEL_ORDER_STATUSES: NonInvitableStatus[] = [
  "invited",
  "canceled",
  "booked",
  "reschedule_requested",
  "declined_by_applicant",
  "declined_by_researcher",
  "declined_by_automatic",
  "complete",
  "auto_complete",
  "no_show",
  "reported",
  "invitation_revoked",
]
