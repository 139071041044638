import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react"
import InvitationsSentImage from "Images/app-illustrations/lyssna/invitations-sent.png"
import {
  capitalizeFirstLetter,
  pluralize,
  pluralizeWithCount,
} from "Utilities/string"
import React from "react"

type Props = {
  applicantsSelectedCount: number
  sessionsAvailableCount: number
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">

export const InvitationsSentModal: React.FC<Props> = ({
  applicantsSelectedCount,
  sessionsAvailableCount,
  isOpen,
  onClose,
}) => {
  const pluralizedInvitations = pluralize(applicantsSelectedCount, "invitation")
  const pluralizedApplicants = pluralize(applicantsSelectedCount, "applicant")
  const pluralizedSessionsAvailableApplicantsWithCount = pluralizeWithCount(
    sessionsAvailableCount,
    "applicant"
  )
  const selectedApplicantsExceedSessions =
    applicantsSelectedCount > sessionsAvailableCount

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent color="text.primary" maxW="30rem">
        <ModalBody pt={2} pb={0}>
          <Flex flexDirection="column" align="center" gap={4}>
            <Flex
              flexDirection="column"
              align="center"
              textAlign="center"
              gap={4}
            >
              <Image src={InvitationsSentImage} maxW="208px" />
              <Heading fontSize="2xl" fontWeight="semibold">
                {applicantsSelectedCount} {pluralizedInvitations} sent
              </Heading>
              <Text>
                {capitalizeFirstLetter(pluralizedInvitations)} sent to{" "}
                {applicantsSelectedCount} selected {pluralizedApplicants}.
              </Text>
            </Flex>
            {selectedApplicantsExceedSessions && (
              <Alert status="info">
                <AlertIcon />
                <AlertDescription>
                  As the number of sessions available is less than the number of
                  applicants invited, only{" "}
                  {pluralizedSessionsAvailableApplicantsWithCount} will be able
                  to book a session with you.
                </AlertDescription>
              </Alert>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" onClick={onClose}>
            Got it
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
