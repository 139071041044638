import {
  Button,
  Center,
  Flex,
  FormLabel,
  Icon,
  Link,
  Spinner,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Alert } from "DesignSystem/components/Alert"
import { GoogleCalendarIcon } from "Shared/icons/GoogleCalendarIcon"
import { useGoogleOAuth } from "UsabilityHub/hooks/useGoogleOAuth"
import { useIntegrations } from "UsabilityHub/hooks/useIntegrations"
import { ROUTES } from "UsabilityHub/views/routes"
import { getDateString } from "Utilities/date-formats"
import React from "react"
import { useGetGoogleCalendars } from "~/api/generated/usabilityhub-components"

type Props = {
  toggleCalendar: (cal: any, checked: boolean) => void
  selectedCalendars: { id: string }[]
  hasGoogleCalendarError: boolean
}
export const GoogleConflictCalendars: React.FC<Props> = ({
  toggleCalendar,
  selectedCalendars,
  hasGoogleCalendarError,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { data: integrations } = useIntegrations()
  const connected = !!integrations?.google
  const { data: googleCalendars, isLoading } = useGetGoogleCalendars(
    {},
    {
      enabled: connected,
    }
  )

  const { handleGoogleOAuth } = useGoogleOAuth(connected, () => {
    toast({
      title: "Google Calendar connected",
      status: "success",
      duration: 3000,
    })
    queryClient.invalidateQueries(["api", "google_calendars"])
    queryClient.invalidateQueries(["api", "moderated_studies"])
    queryClient.invalidateQueries(["api", "integrations"], { exact: true })
  })

  return (
    <Flex gap={4}>
      <Icon as={GoogleCalendarIcon} boxSize={10} />

      <Flex direction="column" gap={3}>
        <Text color="text.primary" fontSize="md" fontWeight="semibold">
          Google
        </Text>

        {hasGoogleCalendarError ? (
          <Alert
            mt="2"
            status="danger"
            title={`We\u2019ve lost connection to your Google Calendar`}
            description={
              <>
                Applicants are unable to make a booking until your Google
                Calendar is reconnected from your{" "}
                <Link href={ROUTES.SETTINGS.PROFILE.path}>
                  Profile Settings
                </Link>
              </>
            }
          />
        ) : (
          <>
            <Text>
              Connect your Google account to check your Google Calendar
              availability and sync events
            </Text>

            {connected && integrations && (
              <>
                <Text color="text.secondary">
                  Connected on {getDateString(integrations.google?.created_at)}
                </Text>

                {isLoading ? (
                  <Center minH="200px">
                    <Spinner />
                  </Center>
                ) : (
                  <Flex direction="column" gap={2}>
                    {googleCalendars?.map((cal) => (
                      <Flex gap={3} key={cal.id}>
                        <Switch
                          id={cal.id}
                          data-qa="toggle-conflict-calendar-switch"
                          onChange={(event) =>
                            toggleCalendar(cal, event.target.checked)
                          }
                          isChecked={selectedCalendars
                            .map((c) => c.id)
                            .includes(cal.id)}
                        />
                        <FormLabel
                          fontSize="md"
                          color="text.primary"
                          my={0}
                          htmlFor={cal.id}
                          cursor="pointer"
                        >
                          {cal.summary}
                        </FormLabel>
                      </Flex>
                    ))}
                  </Flex>
                )}
              </>
            )}
          </>
        )}
      </Flex>

      {!connected && integrations && (
        <Button size="sm" flexShrink={0} onClick={handleGoogleOAuth}>
          Connect
        </Button>
      )}
    </Flex>
  )
}
