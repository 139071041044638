import { Box, BoxProps, Flex, Tooltip, forwardRef } from "@chakra-ui/react"
import React from "react"

import { fraudStatus, useAdminIpLookup } from "./AdminIpAddressContext"

interface Props {
  date: string
  ipAddress: string | null
  userCountry: string
  highRiskCountryCodes: string[]
  showFraudIndicator?: boolean
  responseCountry?: string | null
}

export const AdminIpAddressInfo: React.FC<Props> = ({
  date,
  userCountry,
  ipAddress,
  highRiskCountryCodes,
  showFraudIndicator = false,
  responseCountry = false,
}) => {
  const ipState = useAdminIpLookup(ipAddress, date)

  if (ipState.state === "loading") return <Pill>Loading...</Pill>

  const ip =
    ipState.state === "ready"
      ? ipState.ipInfo
      : {
          ip_address:
            ipState.state === "error"
              ? ipState.message
              : (ipAddress ?? "Blank IP address"),
          country_code: "??",
          country_name: "Unknown country",
          city: "Unknown city",
          isp: "Unknown ISP",
          vpn: false,
          tor: false,
          proxy: false,
          threat: false,
          cloud_provider: false,
        }

  return (
    <Flex
      gap={2}
      sx={{
        fontVariantNumeric: "tabular-nums",
      }}
    >
      <Pill w="90px">
        {new Date(date).toLocaleDateString(undefined, { dateStyle: "medium" })}
      </Pill>

      {responseCountry !== false && (
        <Tooltip
          hasArrow
          placement="top"
          label="Geolocated country when the response is created"
        >
          <Pill
            pillColor={
              showFraudIndicator && responseCountry !== userCountry
                ? "warn"
                : "ok"
            }
          >
            {responseCountry ?? "??"}
          </Pill>
        </Tooltip>
      )}

      <Tooltip
        hasArrow
        placement="top"
        label={`${ip.city}, ${ip.country_name}`}
      >
        <Pill
          pillColor={
            showFraudIndicator
              ? fraudStatus(userCountry, ip, highRiskCountryCodes)
              : "ok"
          }
        >
          {ip.country_code}
        </Pill>
      </Tooltip>

      <Tooltip hasArrow placement="top" label={ip.isp}>
        <Pill minW="120px">{ip.ip_address}</Pill>
      </Tooltip>

      {ip.vpn && <Pill pillColor={ip.threat ? "danger" : "ok"}>VPN</Pill>}
      {ip.proxy && <Pill pillColor={ip.threat ? "danger" : "ok"}>Proxy</Pill>}
      {ip.tor && <Pill pillColor={ip.threat ? "danger" : "ok"}>TOR</Pill>}
      {ip.cloud_provider && (
        <Pill pillColor={ip.threat ? "danger" : "ok"}>Cloud</Pill>
      )}
    </Flex>
  )
}

type PillProps = {
  // For now these correspond to CSS class modifiers in admin-layout.scss
  pillColor?: "ok" | "warn" | "danger" | "alert"
}

const Pill: React.FC<PillProps & BoxProps> = forwardRef(
  ({ pillColor = "ok", ...boxProps }, ref) => {
    const colorClasses = `FraudFactor FraudFactor--${pillColor}`

    return (
      <Box
        ref={ref}
        className={colorClasses}
        textAlign="center"
        {...boxProps}
      />
    )
  }
)
