import {
  Badge as BaseBadge,
  BadgeProps as BaseBadgeProps,
} from "DesignSystem/components/Badge"
import { upperFirst } from "lodash"
import React from "react"

type Result = "success" | "failure" | "skipped"
type Directness = "direct" | "indirect"

const LABELS: Record<Result, string> = {
  success: "success",
  failure: "failure",
  skipped: "pass",
}

type BadgeProps = Omit<BaseBadgeProps, "children" | "label"> & {
  result: Result
  directness?: Directness
}

export const Badge = ({ result, directness, ...props }: BadgeProps) => (
  <BaseBadge
    variant={
      result === "failure" || (result === "success" && directness === "direct")
        ? "bold"
        : "subtle"
    }
    colorScheme={result === "success" ? "success" : undefined}
    {...props}
    label={[directness && upperFirst(directness), LABELS[result]]
      .filter(Boolean)
      .join(" ")}
  />
)
