import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { useRemoveCreditCard } from "Shared/hooks/useRemoveCreditCard/useRemoveCreditCard"

type RemoveCardModalProps = Omit<ModalProps, "children">

export const RemoveCardModal: React.FC<
  React.PropsWithChildren<RemoveCardModalProps>
> = ({ onClose, ...modalProps }) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const { handleSubmit } = useRemoveCreditCard({
    onError: () => setIsDeleting(false),
    onLoad: () => setIsDeleting(true),
    onSuccess: () => {
      setIsDeleting(false)
      onClose()
    },
  })

  return (
    <Modal onClose={onClose} {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remove card on file</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            By removing the credit card on file you will no longer have a valid
            billing method on your account, meaning any subscriptions will no
            longer auto-renew.
          </Text>
          <Text mt={2}>
            You will also need to add a new card to purchase credits.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            isLoading={isDeleting}
            loadingText="Removing card"
            colorScheme="brand.primary"
            data-qa="remove-credit-card-button"
          >
            Remove card
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
