import { Checkbox, CheckboxProps } from "@chakra-ui/react"
import React from "react"

interface Props {
  isDisabled: boolean
}

export function SelectionColumnCell({
  isDisabled,
  ...props
}: CheckboxProps & Props) {
  return <Checkbox isDisabled={isDisabled} {...props} />
}
