import { FC, useMemo } from "react"
import React from "react"

import {
  ClickSelectionSummary as Impl,
  Props as ImplProps,
} from "Components/test-results/screenshot-click-results/click-selection-summary"
import { ClickSelection } from "Types"
import { Omit } from "Types"
import { useSectionContext } from "UsabilityHub/contexts"

import { useScreenshotClientHitzoneFilter } from "../hooks/use-screenshot-filter"

import { useSummaryStats } from "./use-summary-props"

type Injected =
  | "averageDuration"
  | "chosenCount"
  | "chosenPercentage"
  | "clippingRectangle"
  | "isFilter"
  | "isFiltered"
  | "isFilterRequested"
  | "onFilterChange"

interface Props extends Omit<ImplProps, Injected> {
  clickSelection: Readonly<ClickSelection>
}

export const ClickSelectionSummary: FC<Props> = ({
  name,
  clickSelection,
  screenshot,
}) => {
  const { section } = useSectionContext()
  const { isFilter, isFiltered, toggleFilter } =
    useScreenshotClientHitzoneFilter(
      section.id,
      clickSelection.usability_test_section_screenshot_id,
      clickSelection._clientId
    )

  const filterOptions = useMemo(
    () => ({ include: [clickSelection] }),
    [clickSelection]
  )
  const summaryStats = useSummaryStats(
    clickSelection.usability_test_section_screenshot_id,
    filterOptions
  )

  return (
    <Impl
      clippingRectangle={clickSelection}
      name={name}
      isFilter={isFilter}
      isFilterRequested={isFilter}
      isFiltered={isFiltered}
      screenshot={screenshot}
      onFilterChange={toggleFilter}
      {...summaryStats}
    />
  )
}
