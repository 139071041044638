import { Avatar, Icon } from "@chakra-ui/react"
import { sortBy } from "lodash"
import React from "react"

import {
  AFTER,
  BEFORE,
  EXCLUDES_ALL,
  EXCLUDES_ANY,
  INCLUDES_ALL,
  INCLUDES_ANY,
  IS,
  IS_NOT,
} from "Components/filter-controls/comparators"
import { ORDERED_SECTIONS_TYPES } from "Constants/test-section-types"
import { getSectionTypeRules } from "Constants/test-section-types"
import { FilterAttribute } from "Types"
import { NewProject } from "~/api/generated/usabilityhubSchemas"

type VariationSet = {
  id: number
  name: string
}

const DATE_IN_THE_PAST = [
  { label: "1 week ago", value: "7" },
  { label: "2 weeks ago", value: "14" },
  { label: "3 weeks ago", value: "21" },
  { label: "1 month ago", value: "30" },
  { label: "2 months ago", value: "60" },
  { label: "3 months ago", value: "90" },
  { label: "6 months ago", value: "180" },
  { label: "1 year ago", value: "365" },
]

const STATIC_DASHBOARD_ATTRIBUTES: FilterAttribute[] = [
  {
    label: "Created",
    value: "created_at",
    possibleComparators: [BEFORE, AFTER],
    possibleOptions: {
      type: "single",
      choices: DATE_IN_THE_PAST,
    },
  },
  {
    label: "Modified",
    value: "updated_at",
    possibleComparators: [BEFORE, AFTER],
    possibleOptions: {
      type: "single",
      choices: DATE_IN_THE_PAST,
    },
  },
  {
    label: "Status",
    value: "status",
    possibleComparators: [IS, IS_NOT],
    possibleOptions: {
      type: "multi",
      pluralEntity: "states",
      choices: [
        { label: "Ready", value: "ready" },
        { label: "Collecting", value: "collecting" },
        { label: "Complete", value: "complete" },
      ],
    },
  },
  // Not using archived or title on the front-end but the backend still supports them so I'll
  // leave them in here for future reference:
  // {
  //   label: "Archived",
  //   value: "archived",
  //   possibleComparators: [IS, IS_NOT],
  //   possibleOptions: { type: "static" },
  // },
  // {
  //   label: "Title",
  //   value: "title",
  //   possibleComparators: [CONTAINS, DOES_NOT_CONTAIN],
  //   possibleOptions: { type: "freeText" },
  // },
]

type MinimalTeamMember = {
  id: number
  name: string
  avatar_url_medium: string
}

export const generateDashboardFilters = (
  teamMembers: MinimalTeamMember[],
  projects: ReadonlyArray<Pick<NewProject, "id" | "name">>,
  variationSets: ReadonlyArray<Pick<VariationSet, "id" | "name">>
): FilterAttribute[] => {
  return [
    ...STATIC_DASHBOARD_ATTRIBUTES,
    {
      label: "Author",
      value: "creator",
      possibleComparators: [IS, IS_NOT],
      possibleOptions: {
        type: "multi",
        pluralEntity: "people",
        choices: sortBy(teamMembers, "name").map((member) => ({
          label: member.name,
          value: member.id.toString(),
          icon: (
            <Avatar
              size="xs"
              boxSize={5}
              src={member.avatar_url_medium}
              name={member.name}
            />
          ),
        })),
      },
    },
    {
      label: "Project",
      value: "project",
      possibleComparators: [IS, IS_NOT],
      possibleOptions: {
        type: "multi",
        pluralEntity: "projects",
        choices: [
          { label: "No project", value: "null" },
          ...projects.map((project) => ({
            label: project.name,
            value: project.id.toString(),
          })),
        ],
      },
    },
    {
      label: "Section type",
      value: "section_type",
      possibleComparators: [
        INCLUDES_ALL,
        INCLUDES_ANY,
        EXCLUDES_ANY,
        EXCLUDES_ALL,
      ],
      possibleOptions: {
        type: "multi",
        pluralEntity: "section types",
        choices: ORDERED_SECTIONS_TYPES.map((sectionType) => {
          const sectionTypeRules = getSectionTypeRules(sectionType)

          return {
            label: sectionTypeRules.defaultTitle,
            value: sectionType,
            icon: (
              <Icon
                as={sectionTypeRules.icon}
                color={sectionTypeRules.colorScheme}
              />
            ),
          }
        }),
      },
    },
    {
      label: "Variation set",
      value: "variation_set",
      possibleComparators: [IS, IS_NOT],
      possibleOptions: {
        type: "multi",
        pluralEntity: "variation sets",
        choices: variationSets.map((set) => ({
          label: set.name,
          value: set.id.toString(),
        })),
      },
    },
  ]
}
