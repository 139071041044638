import { UsabilityTestSectionScreenshot } from "Types"
import React from "react"

interface NavigationTestResultsStep {
  stepNumber: number
  sectionScreenshot: UsabilityTestSectionScreenshot
}

const NavigationTestResultsStepContext =
  React.createContext<NavigationTestResultsStep | null>(null)

export const NavigationTestResultsStepProvider: React.FC<
  React.PropsWithChildren<NavigationTestResultsStep>
> = ({ stepNumber, sectionScreenshot, children }) => {
  return (
    <NavigationTestResultsStepContext.Provider
      value={{
        stepNumber,
        sectionScreenshot,
      }}
    >
      {children}
    </NavigationTestResultsStepContext.Provider>
  )
}

export const useNavigationTestResultsStepContext =
  (): NavigationTestResultsStep => {
    const context = React.useContext(NavigationTestResultsStepContext)

    if (!context) {
      throw new Error(
        `useNavigationTestResultsStepContext must be rendered within the NavigationTestResultsStepContext`
      )
    }

    return context
  }
