import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import React from "react"

import { MaintainScreenshotAspectRatioContainer } from "Components/screenshot/maintain-screenshot-aspect-ratio-container"
import { screenshotSrc } from "Utilities/screenshot"

import {
  useDeviceFramesForSectionContext,
  useSectionContext,
} from "UsabilityHub/contexts"
import { useSectionMediaContext } from "../SectionMediaContext"

// `styles.previewContainer` is required by the children of `MaintainScreenshotAspectRatioContainer`.
// It'll go away when we convert the screenshot rendering components to Chakra.
import styles from "./screenshots/screenshots.module.scss"

interface MediaPreviewModalProps {
  isOpen: boolean
  onClose: () => void
}

export const MediaPreviewModal: React.FC<
  React.PropsWithChildren<MediaPreviewModalProps>
> = ({ isOpen, onClose }) => {
  const { mediaFile } = useSectionMediaContext()

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="md" width="auto">
        <ModalHeader>{mediaFile?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RenderedMedia />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand.primary" onClick={onClose}>
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const RenderedMedia = () => {
  const { mediaFile } = useSectionMediaContext()
  const { section } = useSectionContext()
  const deviceFrame = useDeviceFramesForSectionContext(section)

  return (
    <>
      {mediaFile?.media_type === "image" && (
        // TODO: Convert screenshot rendering components to Chakra.
        <MaintainScreenshotAspectRatioContainer
          screenshot={mediaFile}
          constrainWidthToDeviceFrame={deviceFrame}
          className={styles.previewContainer}
        >
          <Image
            src={screenshotSrc(mediaFile)}
            position="absolute"
            left={0}
            top={0}
            w="100%"
          />
        </MaintainScreenshotAspectRatioContainer>
      )}
      {mediaFile?.media_type === "video" && (
        <Box
          as="video"
          width={mediaFile.width}
          controls
          src={screenshotSrc(mediaFile)}
        />
      )}
      {mediaFile?.media_type === "audio" && (
        <Box as="audio" width="full" controls src={screenshotSrc(mediaFile)} />
      )}
    </>
  )
}
