import { Box, Grid } from "@chakra-ui/react"
import { ROUTES } from "UsabilityHub/views/routes"
import { orderBy } from "lodash"
import React, { useEffect, useMemo } from "react"
import { Helmet } from "react-helmet"
import { Outlet, useNavigate } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { useGetModeratedStudyBookings } from "~/api/generated/usabilityhub-components"
import { isRecordingVisible } from "../BookingCard/BookingCard"
import { StorageOrTranscriptionExhaustedBanner } from "../StorageOrTranscriptionQuotaExhaustedBanner"
import { Empty } from "./Empty"
import { List } from "./List"
import { Loading } from "./Loading"

export function ModeratedStudyRecordingsPage() {
  const { moderatedStudyId, sessionId } = useTypedParams(
    ROUTES.INTERVIEW.RECORDINGS.SESSION
  )
  const navigate = useNavigate()

  const { isLoading, data } = useGetModeratedStudyBookings({
    pathParams: { moderatedStudyId },
  })

  useEffect(() => {
    if (
      isLoading ||
      !bookings?.length ||
      bookings.find((b) => b.id === sessionId)
    ) {
      return
    }

    // Show the first session in the list if there isn't one selected
    navigate(
      ROUTES.INTERVIEW.RECORDINGS.SESSION.buildPath({
        moderatedStudyId,
        sessionId: bookings[0].id,
      }),
      { replace: true }
    )
  }, [isLoading, data, moderatedStudyId, sessionId, navigate])

  const bookings = useMemo(
    () =>
      orderBy(
        (data || []).filter((b) => isRecordingVisible(b)),
        "starts_at",
        "desc"
      ),
    [data]
  )

  return (
    <>
      <Helmet>
        <title>Results</title>
      </Helmet>

      <StorageOrTranscriptionExhaustedBanner />

      <main>
        <Box p={8} w="full" gap={4} maxW="68rem" mx="auto">
          {isLoading ? (
            <Loading />
          ) : bookings.length > 0 ? (
            <Grid gridTemplateColumns={"18rem 1fr"} gap={8} alignItems="start">
              <List bookings={bookings} />
              <Outlet />
            </Grid>
          ) : (
            <Empty />
          )}
        </Box>
      </main>
    </>
  )
}
