import React from "react"

import { ClickMapOld } from "Components/click-map/click-map-old"
import { ExpandableRegion } from "Components/expandable-region/expandable-region"
import { HitzoneMap } from "Components/hitzone-map/hitzone-map"
import { UnscaledScreenshot } from "Components/screenshot/UnscaledScreenshot/UnscaledScreenshot"
import { MaintainScreenshotAspectRatioContainer as ScreenshotContainer } from "Components/screenshot/maintain-screenshot-aspect-ratio-container"
import styles from "Components/test-results/screenshot-click-results/screenshot-click-results.module.scss"
import {
  DeviceFrame,
  ImageScreenshot,
  ScreenshotClick,
  ScreenshotHitzone,
} from "Types"

interface Props {
  clicks: ReadonlyArray<ScreenshotClick>
  hitzones: ReadonlyArray<ScreenshotHitzone>
  screenshot: ImageScreenshot
  constrainWidthToDeviceFrame: Readonly<DeviceFrame> | null
}

export const IndividualScreenshotClickResultOld = ({
  clicks,
  constrainWidthToDeviceFrame,
  hitzones,
  screenshot,
}: Props) => (
  <ExpandableRegion
    className={styles.screenshotWrapper}
    maxHeight={400}
    expandText="Expand image"
    collapseText="Collapse image"
  >
    <ScreenshotContainer
      className={styles.screenshotContainer}
      screenshot={screenshot}
      constrainWidthToDeviceFrame={constrainWidthToDeviceFrame}
    >
      <UnscaledScreenshot
        className={styles.layer}
        screenshot={screenshot}
        canSkipAheadOnFirstWatch
      />
      <ClickMapOld clicks={clicks} />
      {hitzones.length > 0 && (
        <HitzoneMap
          hitzones={hitzones}
          filterHitzoneIds={[]}
          isFilteringByClickSelection={false}
        />
      )}
    </ScreenshotContainer>
  </ExpandableRegion>
)
