import { Checkbox, Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import React from "react"

type Props = {
  handleStart: () => void
}

export const ExternalStudyWelcome: React.FC<Props> = ({ handleStart }) => {
  const [agreedToTerms, setAgreedToTerms] = React.useState(false)

  return (
    <Flex
      direction="column"
      maxW="800px"
      h="fit-content"
      bg="white"
      rounded="16px"
      mx="auto"
      mt={6}
      p={4}
      gap={4}
    >
      <Text fontWeight="bold">Hello there 👋</Text>

      <Text>
        You are being asked to complete a study on a website outside of the
        UserCrowd platform.
      </Text>

      <Flex direction="column" bg="gray.200" rounded="8px" p={4}>
        <Text mb={4}>
          To keep your privacy safe, do not share the following during the test:
        </Text>

        <UnorderedList>
          <ListItem>
            Personal information (like your name, email or address)
          </ListItem>
          <ListItem>Login details for personal accounts</ListItem>
          <ListItem>
            Sensitive data (like health or medical information)
          </ListItem>
          <ListItem>
            Financial info (like credit card numbers or payment logins)
          </ListItem>
        </UnorderedList>
      </Flex>

      <Checkbox
        isChecked={agreedToTerms}
        onChange={() => setAgreedToTerms((v) => !v)}
      >
        I understand that this study will take place outside of the UserCrowd
        platform, and I will protect my privacy.
      </Checkbox>

      <ThemedButton
        w="fit-content"
        isDisabled={!agreedToTerms}
        onClick={handleStart}
      >
        Get started
      </ThemedButton>
    </Flex>
  )
}
