import { Box, BoxProps } from "@chakra-ui/react"
import React from "react"

export const CornerLabel: React.FC<React.PropsWithChildren<BoxProps>> = ({
  children,
  ...props
}) => (
  <Box
    pos="absolute"
    top={0}
    right={0}
    background="green.500"
    color="white"
    roundedBottomLeft="sm"
    fontSize="xs"
    fontWeight="bold"
    px={1}
    py="1px"
    {...props}
  >
    {children}
  </Box>
)
