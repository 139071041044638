import {
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import { AppliedFilter as AppliedFilterType, FilterComparator } from "Types"
import React, { useState } from "react"
import { useFilterContext } from "./filter-context"
import { FilterMenuMulti } from "./filter-menu-multi"
import { FilterMenuSingle } from "./filter-menu-single"
import { assertNever } from "./utils"

interface Props {
  entityBeingFiltered: string
  filter: AppliedFilterType
}

export const AppliedFilter: React.FC<Props> = ({
  entityBeingFiltered,
  filter,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { changeFilter, removeFilter } = useFilterContext()

  // There are some comparators that only make sense for type=multi filters when
  // multiple options are selected.
  const validComparators =
    filter.options.type === "multi" && filter.options.choices.length > 1
      ? filter.attribute.possibleComparators
      : filter.attribute.possibleComparators.filter((c) => !c.onlyShowForMulti)

  return (
    <Flex
      pos="relative" // So that FilterMenuMulti's pos=absolute menu can be positioned here
      h={8}
      align="stretch"
      borderColor="gray.200"
      borderWidth={1}
      rounded="md"
      fontSize="sm"
      bg="gray.100"
      ps={2}
    >
      <Flex align="center" pe={1}>
        {filterAttributeLabel(filter, entityBeingFiltered)}
      </Flex>
      <Menu>
        <MenuButton
          color="brand.neutral.light"
          _hover={{ bg: "gray.200" }}
          px={1}
        >
          {filterComparatorLabel(filter)}
        </MenuButton>
        <MenuList overflow="hidden" zIndex="dropdown">
          {validComparators.map((comparator) => (
            <MenuItem
              key={comparator.value}
              onClick={() =>
                changeFilter(filter.id, comparator, filter.options)
              }
            >
              {comparator.icon}
              <Text ms={comparator.icon ? 1.5 : 0}>
                {filterComparatorLabel(filter, comparator)}
              </Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {filter.options.type === "single" &&
      filter.attribute.possibleOptions.type === "single" ? (
        <>
          <Button
            size="sm"
            bg="transparent"
            _hover={{ bg: "gray.200" }}
            fontWeight="normal"
            rounded="none"
            color="inherit"
            px={1}
            onClick={() => setIsMenuOpen(true)}
          >
            {filterOptionLabel(filter)}
          </Button>
          {isMenuOpen && (
            <FilterMenuSingle
              appliedFilter={filter}
              selectedAttribute={filter.attribute}
              onClose={() => setIsMenuOpen(false)}
            />
          )}
        </>
      ) : filter.options.type === "multi" &&
        filter.attribute.possibleOptions.type === "multi" ? (
        <>
          <Button
            size="sm"
            bg="transparent"
            _hover={{ bg: "gray.200" }}
            fontWeight="normal"
            rounded="none"
            color="inherit"
            px={1}
            onClick={() => setIsMenuOpen(true)}
          >
            {filterOptionLabel(filter)}
          </Button>
          {isMenuOpen && (
            <FilterMenuMulti
              appliedFilter={filter}
              selectedAttribute={filter.attribute}
              onClose={() => setIsMenuOpen(false)}
            />
          )}
        </>
      ) : (
        <Flex align="center" ps={1}>
          {filterOptionLabel(filter)}
        </Flex>
      )}

      <IconButton
        variant="ghost"
        size="xs"
        icon={<Icon as={XOutlineIcon} />}
        aria-label="Remove filter"
        onClick={() => removeFilter(filter.id)}
        my="auto"
      />
    </Flex>
  )
}

const filterAttributeLabel = (
  filter: AppliedFilterType,
  entityBeingFiltered: string
) => {
  const filterType = filter.options.type

  if (filterType === "static") {
    return entityBeingFiltered
  } else if (
    filterType === "single" ||
    filterType === "multi" ||
    filterType === "freeText" ||
    filterType === "static"
  ) {
    return filter.attribute.label
  } else {
    assertNever(filter.options)
  }
}

const filterComparatorLabel = (
  filter: AppliedFilterType,
  comparator: FilterComparator = filter.comparator
) => {
  const filterType = filter.options.type

  if (filterType === "multi" && filter.options.choices.length > 1) {
    return comparator.labelMulti
  } else if (
    filterType === "single" ||
    filterType === "multi" ||
    filterType === "freeText" ||
    filterType === "static"
  ) {
    return comparator.labelSingle
  } else {
    assertNever(filter.options)
  }
}

const filterOptionLabel = (filter: AppliedFilterType) => {
  if (filter.options.type === "static") {
    return filter.attribute.label
  } else if (filter.options.type === "freeText") {
    return filter.options.value
  } else if (filter.options.type === "single") {
    return filter.options.choice.label
  } else if (filter.options.type === "multi") {
    const plural =
      filter.attribute.possibleOptions.type === "multi"
        ? filter.attribute.possibleOptions.pluralEntity
        : "options"

    const icons = filter.options.choices.map((c) => c.icon).filter(Boolean)
    const text =
      filter.options.choices.length !== 1
        ? `${filter.options.choices.length} ${plural}`
        : filter.options.choices[0].label

    return (
      <Flex
        alignItems="center"
        sx={{
          "& > :not(p)": {
            borderWidth: 1,
            borderColor: "gray.100",
            rounded: "full",
            boxSize: 4,
          },
          "& > :not(p):not(:first-of-type)": {
            marginLeft: "-5px",
          },
        }}
      >
        {icons}
        <Text ml={icons.length > 0 ? 1 : 0}>{text}</Text>
      </Flex>
    )
  } else {
    assertNever(filter.options)
  }
}
