import React, { ChangeEvent } from "react"

import { Omit } from "Types"

import { TextInput, Props as TextInputProps } from "./text-input/text-input"

interface Props extends Omit<TextInputProps, "onChange"> {
  onChange: (value: string) => void
}

export const Text = React.forwardRef<HTMLInputElement, Props>(
  ({ onChange, ...rest }, ref) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    }
    return <TextInput ref={ref} onChange={handleChange} {...rest} />
  }
)

Text.displayName = "Text"
