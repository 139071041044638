import { Alert, AlertTitle, Stack } from "@chakra-ui/react"
import { useIndividualResponseFilter } from "Components/test-results/hooks/use-individual-response-filter"
import { State } from "Redux/app-store"
import React from "react"
import { useSelector } from "react-redux"
import { PathResult } from "./PathResult"
import { PathSortControls } from "./PathSortControls"
import { IndividualPath, useSortPaths } from "./usePaths"

type TreeTestIndividualPathsProps = {
  paths: IndividualPath[]
}

export const TreeTestIndividualPaths: React.FC<
  TreeTestIndividualPathsProps
> = ({ paths }) => {
  const { sorted, options, sortOrder, setSortOrder } = useSortPaths(paths)

  const responseSections = useSelector(
    (state: State) => state.testResults?.responseSections || []
  )

  const { setResponseId } = useIndividualResponseFilter()

  const participantClicked = (responseSectionId: number) => {
    const responseSection = responseSections.find(
      (s) => s.id === responseSectionId
    )
    if (responseSection) {
      setResponseId(responseSection.response_id)
    }
  }

  if (paths.length === 0) {
    return (
      <Alert status="info" mt={8}>
        <AlertTitle>No tree test data available</AlertTitle>
      </Alert>
    )
  }

  return (
    <Stack spacing={4} py={4}>
      <PathSortControls {...{ options, sortOrder, setSortOrder }} />
      {sorted.map((path: IndividualPath) => (
        <PathResult
          key={path.responseSectionId}
          path={path}
          onParticipantClick={participantClicked}
        />
      ))}
    </Stack>
  )
}
