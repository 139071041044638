import React, { useState } from "react"

import {
  DesktopOnly,
  MobileOnly,
} from "Components/break-points/desktop-and-mobile-break-points"
import {
  AnswerForm,
  Props,
} from "UsabilityHub/components/UsabilityTestSectionQuestion/answer-form"

import { TextareaField } from "../../../application/javascripts/components/redux-form/textarea"

export function LongAnswerQuestion(props: Readonly<Props>) {
  const [pasteDetected, setPasteDetected] = useState(false)
  const onPaste = () => setPasteDetected(true)

  return (
    <AnswerForm {...props} pasteDetected={pasteDetected}>
      <DesktopOnly>
        <TextareaField name="answer" rows={4} onPaste={onPaste} autoFocus />
      </DesktopOnly>
      <MobileOnly>
        <TextareaField name="answer" rows={4} onPaste={onPaste} />
      </MobileOnly>
    </AnswerForm>
  )
}
