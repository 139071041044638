import { createIcon } from "@chakra-ui/react"
import React from "react"

export const DragHandleIcon = createIcon({
  displayName: "DragHandleIcon",
  viewBox: "0 0 20 20",
  path: (
    <path
      fill="currentColor"
      d="M7 6a2 2 0 110-4 2 2 0 010 4zM7 12a2 2 0 110-4 2 2 0 010 4zM7 18a2 2 0 110-4 2 2 0 010 4zM13 6a2 2 0 110-4 2 2 0 010 4zM13 12a2 2 0 110-4 2 2 0 010 4zM13 18a2 2 0 110-4 2 2 0 010 4z"
    />
  ),
})
