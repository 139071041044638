import { Button, Tooltip } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import React from "react"

export const NonAdmin = {
  label:
    "Contact an admin team member to upgrade or downgrade your subscription",
}
export const ManualSubscription = {
  label: `Please contact ${Constants.PURCHASES_EMAIL_ADDRESS} to upgrade or downgrade your subscription`,
}

export const DisabledPlanButton: React.FC<{
  variant: typeof NonAdmin | typeof ManualSubscription
  buttonText: string
}> = ({ variant, buttonText }) => (
  <Tooltip
    placement="top"
    label={variant.label}
    borderRadius={4}
    px={3}
    textAlign={"center"}
  >
    <Button fontSize="sm" isDisabled>
      {buttonText}
    </Button>
  </Tooltip>
)
