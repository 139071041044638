import { Box } from "@chakra-ui/react"
import React, { useContext } from "react"

import { ClickOccurrenceFilterContext } from "../ClickOccurrenceFilterProvider"
import { useClickViewState } from "../useClickViewState"
import { useDownloadScreenResult } from "../useDownloadScreenResult"
import { useFilteredClicksByClickOccurrence } from "../useFilteredClicks"
import { useScreenResult } from "../useResults"

import { ScreenResultStatsBar } from "./ScreenResultsStatusBar"

export function ScreenResultDetailsStatusBarContainer() {
  const [clickView] = useClickViewState()
  const taskResult = useScreenResult()
  const { clickOccurrences, availableClickOccurrences, setClickOccurrences } =
    useContext(ClickOccurrenceFilterContext)

  const { visibleClicks } = useFilteredClicksByClickOccurrence()

  const handleDownload = useDownloadScreenResult(
    taskResult.figmaImage,
    clickView,
    visibleClicks
  )

  return (
    <Box backgroundColor="gray.50" borderTopColor="gray.200" borderTopWidth={1}>
      <ScreenResultStatsBar
        screenView={clickView}
        screenResultMeta={taskResult.meta}
        clickOccurrences={clickOccurrences}
        availableClickOccurrences={availableClickOccurrences}
        setClickOccurrences={setClickOccurrences}
        onDownload={handleDownload}
      />
    </Box>
  )
}
