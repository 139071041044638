import { clamp } from "lodash"

// Clicking and dragging on the slider can highlight text on the page if the
// cursor moves far from the slider. Subsequent drags will then be consumed
// by the browser trying to copy the selected content, preventing the value
// from being changed. Prevent this.
export function preventTextSelection(event: React.MouseEvent<HTMLDivElement>) {
  event.preventDefault()
}

export function percentToValue(
  percent: number,
  scale: ReadonlyArray<number>
): number {
  // Get the bucket that correspond to the percent
  const bucketCount = scale.length - 1
  let bucketIndex = 0
  for (let i = 0; i < bucketCount; i++) {
    const current = i / bucketCount
    const next = i + 1 / bucketCount
    if (percent >= current && percent < next) {
      bucketIndex = i
    }
  }

  // Calculate how much in are we into the bucket
  const bucketStart = (1 / bucketCount) * bucketIndex
  const bucketEnd = (1 / bucketCount) * (bucketIndex + 1)
  const bucketPercent = (bucketStart - percent) / (bucketStart - bucketEnd)

  // Translate the normalized percent to the lower and upper values
  // of the bucket
  const bucketLowValue = scale[bucketIndex]
  const bucketHighValue = scale[bucketIndex + 1]
  const value =
    bucketPercent * (bucketHighValue - bucketLowValue) + bucketLowValue

  // Normalize the value
  const lowerBound = scale[0]
  const upperBound = scale[scale.length - 1]
  return clamp(Math.round(value), lowerBound, upperBound)
}

export function valueToPercent(value: number, scale: ReadonlyArray<number>) {
  // Get the bucket that correspond to the percent
  const bucketCount = scale.length - 1
  let bucketIndex = 0
  for (let i = 0; i < bucketCount; i++) {
    const current = scale[i]
    const next = scale[i + 1]
    if (value >= current && value < next) {
      bucketIndex = i
      break
    }
  }

  const bucketLowValue = scale[bucketIndex]
  const bucketHighValue = scale[bucketIndex + 1]
  const bucketOffset = bucketIndex / bucketCount
  const valueOffset =
    1 - (bucketHighValue - value) / (bucketHighValue - bucketLowValue)
  const percent = bucketOffset + valueOffset / bucketCount

  return clamp(percent, 0, 1)
}
