import React from "react"
import { useSelector } from "react-redux"

import { NoResponse } from "Components/individual-response/individual-section-result/no-data"
import { IndividualPath } from "Components/test-results/section-results/SectionResultsCards/PrototypeSectionResultsCard/PathsSummaries/IndividualPathsList/IndividualPathsList"
import { useTaskGoalNodeId } from "Components/test-results/section-results/SectionResultsCards/PrototypeSectionResultsCard/hooks/use-goal-node-id"
import { getIndividualFigmaTaskPathFromTask } from "Components/test-results/utils/task-paths/get-individual-paths/get-individual-paths"
import { getFigmaTaskForResponseSection } from "Redux/reducers/test-results/selectors"

import { TaskProps } from "./task-props"

export const PrototypeSectionIndividualResults: React.FC<
  React.PropsWithChildren<TaskProps>
> = ({ responseSection }) => {
  const figmaTask = useSelector(
    getFigmaTaskForResponseSection(responseSection?.id || null)
  )
  const goalNodeId = useTaskGoalNodeId()

  return figmaTask === null ? (
    <NoResponse />
  ) : (
    <IndividualPath
      path={getIndividualFigmaTaskPathFromTask(figmaTask, goalNodeId)}
      sortMethod="none"
      showRecordings
    />
  )
}
