import { Flex, Grid, Text, Tooltip } from "@chakra-ui/react"
import React from "react"

import { DotIcon } from "Shared/icons/DotIcon"

type Props = {
  applicantCounts: {
    eligible: number
    ineligible: number
  }
  bookingCounts: {
    upcoming: number
    past: number
    short_notice_canceled?: number
  }
  maxBookings?: number
}
export const InterviewMetrics: React.FC<Props> = ({
  applicantCounts,
  bookingCounts,
  maxBookings,
}) => {
  const totalApplicants = applicantCounts.eligible + applicantCounts.ineligible
  const totalBookings =
    bookingCounts.past +
    bookingCounts.upcoming +
    (bookingCounts.short_notice_canceled ?? 0)

  return (
    <Flex gap={3} minW="200px">
      <Tooltip
        rounded="md"
        placement="top"
        label={
          <Grid
            templateColumns="auto max-content max-content"
            columnGap={2}
            alignItems="center"
            py={2}
            pl={2}
            pr={3}
          >
            <DotIcon color="green.500" boxSize="1em" />
            <Text pr={14}>Eligible</Text>
            <Text>{applicantCounts.eligible}</Text>
            <DotIcon color="red.500" boxSize="1em" />
            <Text pr={14}>Not Eligible</Text>
            <Text>{applicantCounts.ineligible}</Text>
          </Grid>
        }
      >
        <Text textStyle="ds.heading.secondary" zIndex={1}>
          {totalApplicants}{" "}
          <Text
            as="span"
            color="ds.text.default"
            textStyle="ds.paragraph.primary"
          >
            Applicants
          </Text>
        </Text>
      </Tooltip>
      <Tooltip
        rounded="md"
        placement="top"
        label={
          <Grid
            templateColumns="repeat(2, max-content)"
            columnGap={14}
            py={2}
            px={3}
          >
            <Text>Upcoming</Text>
            <Text>{bookingCounts.upcoming}</Text>
            <Text>Past</Text>
            <Text>{bookingCounts.past}</Text>
          </Grid>
        }
      >
        <Text textStyle="ds.heading.secondary" zIndex={1}>
          {totalBookings}
          {maxBookings && ` of ${maxBookings}`}{" "}
          <Text
            as="span"
            color="ds.text.default"
            textStyle="ds.paragraph.primary"
          >
            Sessions
          </Text>
        </Text>
      </Tooltip>
    </Flex>
  )
}
