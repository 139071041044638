import cx from "classnames"
import React from "react"

import styles from "./individual-answer-result.module.scss"
import { Props } from "./props"

export const LinearScaleAnswer = ({ answer, question }: Props) => (
  <p className={cx("para", styles.text)}>
    {answer.answer} of {question.max_value}
  </p>
)
