import React from "react"

import { NoResponse } from "Components/individual-response/individual-section-result/no-data"
import { ExpandableSectionScreenshot as ExpandableScreenshotImpl } from "Components/test-results/section-results/expandable-section-screenshot"
import { UsabilityTestSection } from "Types"
import { useDeviceFramesContext } from "UsabilityHub/contexts"

import { TaskProps } from "./task-props"

interface ExpandableScreenshotProps {
  usabilityTestSection: Readonly<UsabilityTestSection>
}

const ExpandableScreenshot: React.FC<ExpandableScreenshotProps> = ({
  usabilityTestSection,
}) => {
  const deviceFrameId = usabilityTestSection.device_frame_id
  const deviceFrames = useDeviceFramesContext()
  const deviceFrame =
    (deviceFrameId && deviceFrames.find((d) => d.id === deviceFrameId)) || null
  const sectionScreenshot = usabilityTestSection.section_screenshots[0]

  return (
    <ExpandableScreenshotImpl
      deviceFrame={deviceFrame}
      sectionScreenshot={sectionScreenshot}
    />
  )
}

export const DesignSurveySectionIndividualResults = ({
  responseSection,
  usabilityTestSection,
}: TaskProps) => {
  return responseSection === null ? (
    <NoResponse />
  ) : (
    <ExpandableScreenshot usabilityTestSection={usabilityTestSection} />
  )
}
