import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ClickSectionIcon = createIcon({
  displayName: "ClickSectionIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="click-section">
        <path
          id="Combined Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.79257 8.2712L12.7984 11.277C13.0672 11.5459 13.0672 11.9818 12.7984 12.2507L12.2507 12.7984C11.9818 13.0672 11.5459 13.0672 11.2771 12.7984L8.27129 9.79251L6.77978 12.0691C6.57141 12.3871 6.14467 12.476 5.82663 12.2676C5.69358 12.1805 5.59483 12.0499 5.54714 11.8982L3.03186 3.89488C2.91786 3.53215 3.11949 3.14568 3.48222 3.03167C3.61659 2.98944 3.76068 2.98944 3.89505 3.03167L11.8982 5.547C12.2609 5.661 12.4626 6.04747 12.3486 6.41021C12.3009 6.56195 12.2021 6.6925 12.0691 6.77966L9.79257 8.2712Z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})
