import { Box, Button, Flex } from "@chakra-ui/react"
import { useLocalCommentContext } from "Components/comment-provider/CommentProvider"
import { Comment } from "Components/comment/Comment"
import { deletedUserName } from "Constants/user"
import { Z_INDEX_COMMENT_THREADS } from "Constants/zIndexes"
import { useMaybeCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { AnimatePresence, motion } from "framer-motion"
import { uniqBy } from "lodash"
import React from "react"
import { useMediaQuery } from "react-responsive"
import { Comment as CommentType } from "~/api/generated/usabilityhubSchemas"
import { AddCommentForm } from "./AddCommentForm"
import { CommentMarker } from "./CommentMarker"
import {
  EntityIdentifier,
  entityIdOrNull,
  entityIdentifiersMatch,
  serializeEntity,
} from "./entities"

const MotionBox = motion(Box)

type Props = {
  entity: EntityIdentifier
  isEntityPersisted: boolean
  offsetX?: number
  offsetY?: number
}

export const CommentThread: React.FC<Props> = ({
  entity,
  isEntityPersisted,
  offsetX = -10,
  offsetY = -16,
}) => {
  const currentUser = useMaybeCurrentUser()
  const { usabilityTestId, comments: allComments } = useLocalCommentContext()
  const { activeThread, closeActiveThread, expandActiveThread } =
    useLocalCommentContext()
  const parentComment = allComments.find(
    (c) =>
      c.entity_type === entity.entityType &&
      c.entity_id === entityIdOrNull(entity)
  )
  const replies = allComments.filter(
    (c) => c.entity_type === "comment" && c.entity_id === parentComment?.id
  )

  const comments = parentComment ? [parentComment, ...replies] : []

  // Show comments on the right of the marker if we can fit them, fallback to the left.
  const commentDirection = useMediaQuery({ minWidth: 1788 }) ? "right" : "left"
  const isOpen = activeThread
    ? entityIdentifiersMatch(entity, activeThread)
    : false
  // Comment threads are hidden on the admin dashoard order review/revise page.
  const canAddComments =
    (currentUser?.role === "admin" || currentUser?.role === "member") &&
    !window.location.pathname.includes("/orders/")
  const distinctUsers = uniqBy(comments, (comment) => comment?.user?.id).map(
    (c) =>
      c.user ??
      ({
        name: deletedUserName,
        id: "",
        avatar_url: "",
      } as NonNullable<CommentType["user"]>)
  )

  return (
    <Flex
      position="absolute"
      top={`${offsetY}px`}
      right={`${offsetX}px`}
      transform="translateX(100%)"
      zIndex={isOpen ? Z_INDEX_COMMENT_THREADS : undefined}
      // This is so we can have the whole <CommentThread> element be higher when open
      // so the thread elements don't get covered by the comment markers, but still allow
      // clicking through the whitespace behind the comment marker.
      pointerEvents="none"
      // Trick to hide the tooltip while still making it focusable by keyboard
      // The focus-within rule below applies when children are focused (the button in this case)
      opacity={isOpen || distinctUsers.length ? 1 : 0}
      sx={{
        "> *": {
          pointerEvents: "all",
        },
        // This simulates groupHover functionality but for a specific named group
        // since otherwise it conflicts with other groups in the hierarchy.
        [`[data-commentable='${serializeEntity(
          entity
        )}']:hover &, &:focus-within`]: {
          opacity: 1,
        },
      }}
    >
      <CommentMarker
        entity={entity}
        comments={comments ?? []}
        usersInvolved={distinctUsers}
        isOpen={isOpen}
        canAddComments={canAddComments}
        badgePosition={commentDirection}
      />
      <AnimatePresence>
        {isOpen && (
          <MotionBox
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: commentDirection === "left" ? -20 : 20 }}
            exit={{ opacity: 0, x: commentDirection === "left" ? -20 : 20 }}
            position="absolute"
            left={
              commentDirection === "right" ? "calc(100% + 12px)" : undefined
            }
            right={
              commentDirection === "left" ? "calc(100% + 12px)" : undefined
            }
            transition={{ duration: 0.15 }}
            w="300px"
            bg="white"
            rounded="md"
            borderWidth={1}
            borderColor="gray.200"
            shadow="lg"
          >
            {comments?.length ? (
              <>
                <Box>
                  {activeThread?.collapsed ? (
                    <>
                      <Comment
                        comment={comments[0]}
                        isEntityPersisted={isEntityPersisted}
                      />
                      <Button
                        variant="unstyled"
                        w="full"
                        h={6}
                        display="flex"
                        alignItems="center"
                        gap={1}
                        color="brand.neutral.light"
                        fontSize="xs"
                        lineHeight={4}
                        onClick={expandActiveThread}
                      >
                        <Box
                          flexGrow={1}
                          h="1px"
                          borderBottomWidth={1}
                          borderColor="gray.200"
                        />
                        {comments.length - 1} replies
                        <Box
                          flexGrow={1}
                          borderBottomWidth={1}
                          borderColor="gray.200"
                        />
                      </Button>
                      <Comment
                        comment={comments[comments.length - 1]}
                        isEntityPersisted={isEntityPersisted}
                      />
                    </>
                  ) : (
                    <AnimatePresence initial={false}>
                      {comments.map((c) => (
                        <Comment
                          key={c.id}
                          comment={c}
                          isEntityPersisted={isEntityPersisted}
                        />
                      ))}
                    </AnimatePresence>
                  )}
                </Box>

                {canAddComments && (
                  <AddCommentForm
                    usabilityTestId={usabilityTestId}
                    entity={entity}
                    closeThread={closeActiveThread}
                    isEntityPersisted={isEntityPersisted}
                    replyingTo={comments[0].id}
                  />
                )}
              </>
            ) : (
              <AddCommentForm
                lockedOpen
                entity={entity}
                usabilityTestId={usabilityTestId}
                closeThread={closeActiveThread}
                isEntityPersisted={isEntityPersisted}
              />
            )}
          </MotionBox>
        )}
      </AnimatePresence>
    </Flex>
  )
}
