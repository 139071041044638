import { HStack, Input, Select, Stack, Text } from "@chakra-ui/react"
import { range } from "lodash"
import React from "react"
import { Fields, WrappedFieldsProps } from "redux-form"

import Constants from "Constants/shared.json"
import { QuestionType, UsabilityTestSectionType } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useQuestionContext,
  useSectionTypeContext,
} from "UsabilityHub/contexts"
import { memoizeOnce } from "Utilities/memoization"

const createOptions = memoizeOnce((from: number, to: number) =>
  range(from, to + 1)
)

const START_VALUE_OPTIONS = createOptions(
  Constants.MIN_LINEAR_SCALE_LOWER_BOUND,
  Constants.MAX_LINEAR_SCALE_LOWER_BOUND
)
const END_VALUE_OPTIONS = createOptions(
  Constants.MIN_LINEAR_SCALE_UPPER_BOUND,
  Constants.MAX_LINEAR_SCALE_UPPER_BOUND
)

const WrappedLinearScaleFields: React.FC<
  React.PropsWithChildren<WrappedFieldsProps>
> = ({ min_value, max_value, min_label, max_label }) => {
  const { question } = useQuestionContext()
  const sectionType = useSectionTypeContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const isLinearScaleQuestionAndPrototypeSection =
    question.type === QuestionType.LinearScale &&
    sectionType === UsabilityTestSectionType.PrototypeTask
  const startValuePlaceholder = isLinearScaleQuestionAndPrototypeSection
    ? "e.g. Very difficult"
    : "Start label (optional)"
  const endValuePlaceholder = isLinearScaleQuestionAndPrototypeSection
    ? "e.g. Very easy"
    : "End label (optional)"

  return (
    <Stack>
      <HStack>
        <Text
          flexShrink={0}
          as="label"
          width={24}
          textAlign="right"
          textStyle="label"
          htmlFor={min_value.input.name}
        >
          Start value
        </Text>
        <Select
          id={min_value.input.name}
          {...min_value.input}
          width={24}
          isRequired
          isDisabled={readOnly}
        >
          {START_VALUE_OPTIONS.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
        <Input
          {...min_label.input}
          isReadOnly={readOnly}
          placeholder={startValuePlaceholder}
          maxLength={24}
        />
      </HStack>
      <HStack>
        <Text
          flexShrink={0}
          as="label"
          width={24}
          textAlign="right"
          textStyle="label"
          htmlFor={max_value.input.name}
        >
          End value
        </Text>
        <Select
          id={max_value.input.name}
          {...max_value.input}
          width={24}
          isRequired
          isDisabled={readOnly}
        >
          {END_VALUE_OPTIONS.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
        <Input
          {...max_label.input}
          isReadOnly={readOnly}
          placeholder={endValuePlaceholder}
          maxLength={24}
        />
      </HStack>
    </Stack>
  )
}

export const LinearScaleFields: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <Fields
      names={["min_value", "max_value", "min_label", "max_label"]}
      component={WrappedLinearScaleFields}
      parse={(value: string, name) => {
        // Transform strings to number fields for min/max value fields which is
        // what redux is expecting
        const valueMatcher = /value/
        if (valueMatcher.test(name)) {
          return Number(value)
        }
        return value
      }}
    />
  )
}
