import { sortBy } from "lodash"
import { nanoid } from "nanoid"

import { SortMethod } from "Types"

// Prefer to not change this string. It is included in shared filter URLS.
// Previously this was a `Symbol` but then it could not be serialized.
export const otherSymbol = "__OTHER__"

type CountEntry<T> = [T, number]
export function sortEntries<T>(
  entries: ReadonlyArray<CountEntry<T>>,
  sortMethod: SortMethod
): ReadonlyArray<CountEntry<T>> {
  switch (sortMethod) {
    case SortMethod.PopularityDescending:
      return sortBy(entries, ([_, count]) => -count)
    case SortMethod.Unsorted:
      return entries
  }
}

// Initializing counts object order of multiple choice options. This way their
// default order will be that in which they were provided by the user.
export function emptyCounts<T>(array: ReadonlyArray<T>): Map<T, number> {
  return array.reduce((acc, option) => {
    acc.set(option, 0)
    return acc
  }, new Map<T, number>())
}

const passedId = nanoid()
const otherId = nanoid()
export function getKey(answer: string | null | typeof otherSymbol): string {
  if (answer === null) return passedId
  if (answer === otherSymbol) return otherId
  return answer
}
