import { Button, ButtonProps } from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"

export const NeutralButton: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...buttonProps
}) => (
  <Button
    bg="gray.200"
    color="brand.neutral.default"
    _hover={{
      filter: "brightness(95%)",
    }}
    _active={{
      filter: "brightness(90%)",
    }}
    {...buttonProps}
  >
    {children}
  </Button>
)
