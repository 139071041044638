import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ArrowRightIcon = createIcon({
  displayName: "ArrowRightIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="arrow-right">
        <path
          id="Shape"
          d="M1 7.125H11.675L6.775 2.225L8 1L15 8L8 15L6.775 13.775L11.675 8.875H1V7.125Z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})
