import { createIcon } from "@chakra-ui/react"
import React from "react"

export const PreferenceSectionIcon = createIcon({
  displayName: "PreferenceSectionIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="preference-section">
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.88083C7.55333 3.79496 6.302 3 5 3C3.30467 3 2 4.21125 2 5.82124C2 9.0846 7.61681 13 8 13C8.37212 13 14 9.0846 14 5.82124C14 4.21125 12.6953 3 11 3C9.69667 3 8.44667 3.79496 8 4.88083Z"
        fill="currentColor"
      />
    </g>
  ),
})
