import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react"
import { CardGroup } from "Shared/components/Card/Card"
import { useCurrentPlan } from "UsabilityHub/hooks/useCurrentAccount"
import { isEnterprise } from "Utilities/plan"
import React from "react"
import { Helmet } from "react-helmet"
import { PasswordComplexityCard } from "./PasswordComplexityCard"
import { SAMLSettingsCard } from "./SAMLSettingsCard"

export const SecurityRoute: React.FC = () => {
  const plan = useCurrentPlan()

  return (
    <>
      <Helmet>
        <title>Security</title>
      </Helmet>
      {(!plan || !isEnterprise(plan)) && (
        <Alert status="info" mb={6}>
          <AlertIcon />
          <AlertDescription>
            These settings are only available on Enterprise plans.
          </AlertDescription>
        </Alert>
      )}
      <CardGroup>
        <PasswordComplexityCard />
        <SAMLSettingsCard />
      </CardGroup>
    </>
  )
}
