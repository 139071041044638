import { Flex, Image, Text } from "@chakra-ui/react"
import { useInfiniteScroll } from "Hooks/use-infinite-scroll"
import NoResultsImage from "Images/app-illustrations/lyssna/empty-all.png"
import { convertModeratedStudyToStudy } from "UsabilityHub/utils/convertModeratedStudyToStudy"
import React, { useState } from "react"
import { ListModeratedStudiesResponse } from "~/api/generated/usabilityhub-components"
import { StudyList } from "../dashboard/StudyList"

interface Props {
  moderatedStudies: ListModeratedStudiesResponse
}

const PAGE_SIZE = 20

export const SearchResultsModeratedStudiesTab: React.FC<Props> = ({
  moderatedStudies,
}) => {
  const [numVisible, setNumVisible] = useState(PAGE_SIZE)
  const paginationRef = useInfiniteScroll(() => {
    if (numVisible < moderatedStudies.length) {
      setNumVisible((v) => v + PAGE_SIZE)
    }
  })

  if (moderatedStudies.length === 0) {
    return (
      <Flex direction="column" align="center" mt={10}>
        <Image src={NoResultsImage} maxW={36} alt="No results" />

        <Text>No interview study results found</Text>
      </Flex>
    )
  }

  const visibleModeratedStudies = moderatedStudies.slice(0, numVisible)
  const decoratedModeratedStudies = visibleModeratedStudies.map(
    convertModeratedStudyToStudy
  )

  return (
    <Flex direction="column" gap={4}>
      <StudyList
        context="search"
        isLoading={false}
        studies={decoratedModeratedStudies}
      />

      <div ref={paginationRef} />
    </Flex>
  )
}
