import { Box, Button, useToast } from "@chakra-ui/react"
import copy from "copy-to-clipboard"
import React, { useCallback } from "react"

import { TextInput } from "Components/form/text-input/text-input"

interface Props {
  readonly disabled?: boolean
  readonly text: string
}

export const ShareField = ({ disabled, text }: Props) => {
  const toast = useToast()

  const handleCopy = useCallback(() => {
    copy(text, { format: "text/plain" })
    toast({
      title: "Link copied to clipboard",
      status: "success",
      duration: 3000,
    })
  }, [text])
  return (
    <Box position="relative" height={10} width="100%" userSelect="all">
      <TextInput isReadOnly value={text} isDisabled={disabled} autoSelect />
      <Button
        position="absolute"
        height={9}
        fontSize="md"
        fontWeight="light"
        right={1}
        top={0.5}
        pr={2}
        bg="linear-gradient(to right, rgba(255, 255, 255, 0.01), white 15%);"
        color="brand.primary.500"
        onClick={handleCopy}
        isDisabled={disabled}
        _hover={{ color: "gray.700" }}
        _active={{}}
        _disabled={{
          opacity: 0,
          cursor: "not-allowed",
        }}
        transition="opacity 0.2s"
      >
        Copy
      </Button>
    </Box>
  )
}
