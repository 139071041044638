import { useQueryClient } from "@tanstack/react-query"
import { useCallback, useState } from "react"

import {
  DismissNoticePathParams,
  GetNoticesResponse,
  useDismissNotice,
  useGetNotices,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

// Technically there are separate types for the two APIs so this intersection will
// help us make sure they don't get out of sync
type NoticeSlug = GetNoticesResponse["user_notices"][number] &
  DismissNoticePathParams["slug"]

export const useShowNotice = (slug: NoticeSlug) => {
  const [isPendingDismiss, setIsPendingDismiss] = useState(false)
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  const { data } = useGetNotices(
    {},
    { cacheTime: Infinity, staleTime: Infinity }
  )
  const { mutate } = useDismissNotice({
    onSuccess: (_data, variables) => {
      const queryKey = queryKeyFn({
        operationId: "getNotices",
        path: "/api/user_notices",
        variables,
      })
      return queryClient
        .invalidateQueries(queryKey)
        .then(() => setIsPendingDismiss(false))
    },
  })

  const showNotice = isPendingDismiss
    ? false
    : (data?.user_notices.includes(slug) ?? false)
  const dismissNotice = useCallback(() => {
    setIsPendingDismiss(true)
    mutate({ pathParams: { slug } })
  }, [slug, mutate])

  return [showNotice, dismissNotice] as const
}
