import { Text, TextProps, Tooltip, TooltipProps } from "@chakra-ui/react"
import React from "react"

type ProFeatureTooltipProps = TextProps & Pick<TooltipProps, "label">

export const ProFeatureTooltip: React.FC<ProFeatureTooltipProps> = ({
  children,
  label,
  ...props
}) => (
  <Tooltip
    placement="top"
    label={label}
    background="white"
    borderWidth={1}
    borderColor="gray.100"
    color="gray.700"
    boxShadow="lg"
    borderRadius="md"
  >
    <Text
      as="span"
      textDecoration="underline"
      textDecorationStyle="dotted"
      textDecorationColor="purple.500"
      textUnderlineOffset={4}
      cursor="pointer"
      {...props}
    >
      {children}
    </Text>
  </Tooltip>
)
