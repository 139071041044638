import { Box } from "@chakra-ui/react"
import { UsabilityHubNavbar } from "Components/exports"
import { Heading } from "DesignSystem/components"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { dashboardGreeting } from "Shared/helpers/dashboardGreeting"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import ConfettiExplosion from "react-confetti-explosion"
import { useLocation } from "react-router"
import { DashboardHeader } from "./DashboardHeader"
import { DashboardList } from "./DashboardList"
import { UsedFreeTrial } from "./UsedFreeTrial"

export const Dashboard: React.FC = () => {
  const user = useCurrentUser()
  const { state } = useLocation()

  const justFinishedOnboarding = !!state?.onboardingComplete

  return (
    <Page title="Dashboard">
      <Box shadow="ds.overflow">
        <UsabilityHubNavbar isOnDashboard />
        <DashboardHeader />
      </Box>
      <PageMain>
        <PageContent
          maxW="8xl"
          display="flex"
          flexDirection="column"
          gap={8}
          mb={16}
        >
          <Heading
            as="h1"
            color="ds.text.default"
            textStyle="ds.display.emphasized"
            mt={4}
            display="flex"
          >
            {dashboardGreeting(user)}
          </Heading>
          <DashboardList />
        </PageContent>
      </PageMain>
      {justFinishedOnboarding && (
        <Box pos="fixed" top="25%" left="50%" zIndex={100} pointerEvents="none">
          <ConfettiExplosion
            key="confetti"
            particleCount={1000}
            colors={["#EF2E61", "##F590BE", "#FDDC65", "#0DAACE"]}
          />
        </Box>
      )}
      <UsedFreeTrial />
    </Page>
  )
}
