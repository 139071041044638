import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react"

import Constants from "Constants/shared.json"
import React from "react"

export const OrganizationAlert: React.FC = () => (
  <Alert status="info">
    <AlertIcon />
    <AlertDescription>
      Custom test branding is applied if you{"\u2019"}re on the{" "}
      {Constants.PRO_PLAN_NAME} plan.
    </AlertDescription>
  </Alert>
)
