import { SourceAttribute } from "Components/test-results/types/attributes"
import { ResponseSource, TestResultsOrder } from "Types"

const SOURCE_NAMES: Record<ResponseSource, string> = {
  [ResponseSource.Invite]: "Self-recruited",
  [ResponseSource.UhPanel]: "Lyssna panel",
  [ResponseSource.ExternalPanel]: "External panel",
}

export function getResponseSource(response: {
  order_id: number | null
  third_party_order_id: number | null
}): ResponseSource {
  if (response.order_id !== null) {
    return ResponseSource.UhPanel
  }
  if (response.third_party_order_id !== null) {
    return ResponseSource.ExternalPanel
  }
  return ResponseSource.Invite
}

export function getSpecificResponseSource(
  orders: ReadonlyArray<TestResultsOrder>,
  response: {
    order_id: number | null
    third_party_order_id: number | null
  }
): SourceAttribute["value"] {
  const matchingOrder = orders.find((o) =>
    o.is_external
      ? o.id === response.third_party_order_id
      : o.id === response.order_id
  )

  if (matchingOrder) return matchingOrder.name

  return "Self-recruited"
}

export function formatResponseSource(source: ResponseSource): string {
  return SOURCE_NAMES[source]
}
