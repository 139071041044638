import { Box, Heading, Link, Stack, Text } from "@chakra-ui/react"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { ROUTES } from "UsabilityHub/views/routes"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

type BillingHistory = React.FC<
  React.PropsWithChildren<{
    manualSubscription: boolean
  }>
>

export const BillingHistory: BillingHistory = ({ manualSubscription }) => {
  const currentAccount = useCurrentAccount()

  return (
    <Stack>
      <Heading>Billing history</Heading>
      {manualSubscription && (
        <Text>
          Please note that this section does not include invoices raised
          manually for your subscription
        </Text>
      )}
      {currentAccount.stripe_customer_id ? (
        <Box>
          <Link as={RouterLink} to={ROUTES.SETTINGS.BILLING.HISTORY.path}>
            View billing history
          </Link>
        </Box>
      ) : (
        <Text>No history.</Text>
      )}
    </Stack>
  )
}
