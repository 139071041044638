import React, { MouseEvent as SyntheticMouseEvent, PureComponent } from "react"

import { ClickMapContainerOld } from "Components/click-map/click-map-container-old"
import { ConfirmClickPopper } from "Components/confirm-click-popper/confirm-click-popper"
import { Point } from "Types"
import { reportErrorToSentry } from "Utilities/error"
import {
  getMousePosition,
  getNormalizedMouseOffset,
} from "Utilities/mouse-event"
import { magnitude, subtract } from "Utilities/point"
import { Rectangle0011, isPointInRectangle } from "Utilities/rectangle"

import { ClickPreview } from "./ClickPreview"

interface Props {
  readonly onClick: (point: Point, timestamp: number) => void
  readonly requireClickConfirmation: boolean
}

interface State {
  readonly isConfirmed: boolean
  readonly lastClickTime: number
  readonly normalizedPoint: Point | null
}

export class ClickableScreenshotOverlay extends PureComponent<Props, State> {
  // -- Initialization --

  state: State = {
    isConfirmed: false,
    lastClickTime: 0,
    normalizedPoint: null,
  }

  private isComponentMounted = false
  private clickStartPoint: Point | null = null
  private clickableRef = React.createRef<HTMLDivElement>()
  private readonly maxClickMoveDistance = 20

  // -- Handlers --

  // Handle a mouse down on the click map
  private handleMouseDown = (event: SyntheticMouseEvent<HTMLElement>) => {
    this.clickStartPoint = getMousePosition(event)
  }

  // Unset the mouse down flag. Called _after_ `handleMouseUp` (if on the
  // clickable region).
  private handleMouseUpAnywhere = (_event: MouseEvent) => {
    this.clickStartPoint = null
  }

  // Handle a mouse move over the clickable area
  private handleMouseMove = (event: SyntheticMouseEvent<HTMLElement>) => {
    if (this.clickStartPoint !== null) {
      // Have we moved too far from the original click position?
      const point = getMousePosition(event)
      const delta = subtract(point, this.clickStartPoint)
      const distance = magnitude(delta)
      if (distance > this.maxClickMoveDistance) {
        this.clickStartPoint = null
        return
      }

      // Have we dragged off the clickable area?
      const normalizedPoint = getNormalizedMouseOffset(
        event,
        this.clickableRef.current!
      )
      if (!isPointInRectangle(normalizedPoint, Rectangle0011)) {
        this.clickStartPoint = null
      }
    }
  }

  private handleMouseClick = (event: SyntheticMouseEvent<HTMLElement>) => {
    // HACK: There is a race condition between the state update and the final
    // click going through when confirmation is not required. This click will
    // propagate up to the layout and cause a zoom toggle, hiding the follow up
    // questions!
    if (!this.state.isConfirmed) {
      event.stopPropagation()
    }
  }

  // Handle click map mouse up. If we use click it registers in Firefox even
  // after a drag that ends up outside of the clickable area. This results in
  // #1924.
  private handleMouseUp = (event: SyntheticMouseEvent<HTMLElement>) => {
    // Do not accept click unless it started on the same element and did not
    // drag.
    // NOTE: This flag will be disabled by `handleMouseUpAnywhere`.
    if (this.clickStartPoint === null) return

    const { requireClickConfirmation } = this.props
    const { isConfirmed, normalizedPoint } = this.state

    // There is already a click we can reposition it if confirmation is required
    // and has not yet been given.
    if (
      normalizedPoint !== null &&
      (isConfirmed || !requireClickConfirmation)
    ) {
      return
    }

    this.setState(
      {
        lastClickTime: performance.now(),
        normalizedPoint: getNormalizedMouseOffset(event),
      },
      requireClickConfirmation ? undefined : this.handleConfirm
    )
  }

  private handleCancel = () => {
    if (!this.state.isConfirmed) {
      this.setState({ normalizedPoint: null })
    }
  }

  private handleConfirm = () => {
    const { normalizedPoint, lastClickTime } = this.state
    if (normalizedPoint == null) {
      reportErrorToSentry(new Error(`point is null`))
      return
    }
    this.props.onClick(normalizedPoint, lastClickTime)

    // HACK: Delay confirming until the next update so that we can prevent the
    // click propagation with `handleClick`. If we do this syncronously it gets
    // toggled _before_ the click event.
    setTimeout(() => {
      if (this.isComponentMounted) {
        this.setState({ isConfirmed: true })
      }
    })
  }

  // -- Component lifecycle --

  componentDidMount() {
    this.isComponentMounted = true
    window.addEventListener("mouseup", this.handleMouseUpAnywhere)
  }

  componentWillUnmount() {
    this.isComponentMounted = false
    window.removeEventListener("mouseup", this.handleMouseUpAnywhere)
  }

  render() {
    const { requireClickConfirmation } = this.props
    const { normalizedPoint, isConfirmed } = this.state

    return (
      <>
        <ClickMapContainerOld
          ref={this.clickableRef}
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onMouseMove={this.handleMouseMove}
          onClick={this.handleMouseClick}
        >
          {normalizedPoint !== null && <ClickPreview point={normalizedPoint} />}
        </ClickMapContainerOld>
        {requireClickConfirmation &&
          !isConfirmed &&
          normalizedPoint !== null && (
            <ConfirmClickPopper
              onCancel={this.handleCancel}
              onConfirm={this.handleConfirm}
            />
          )}
      </>
    )
  }
}
