import { Box, Button, HStack, Input, useToast } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import React, { useEffect, useRef, useState } from "react"
import { usePutApiModeratedStudiesId } from "~/api/generated/usabilityhub-components"
import { Study } from "~/api/generated/usabilityhubSchemas"

type StudyNameProps = {
  study: Study
  renaming: boolean
  setRenaming: (renaming: boolean) => void
}

const StudyName: React.FC<StudyNameProps> = ({
  study,
  renaming,
  setRenaming,
}) => {
  const toast = useToast()
  const inputRef = useRef<HTMLInputElement>(null)

  const { mutateAsync } = usePutApiModeratedStudiesId({})

  const [studyName, setStudyName] = useState(study.name)
  const [isValidInput, setIsValidInput] = useState(true)

  useEffect(() => {
    setStudyName(study.name)
  }, [study.name])

  useEffect(() => {
    // reset isValidInput when renaming is toggled
    if (renaming) setIsValidInput(true)
  }, [renaming])

  const renameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const el = e.currentTarget
    switch (e.key) {
      case "Enter":
        e.preventDefault()
        void renameStudy()
        break
      case "Escape":
        e.preventDefault()
        el.value = study.name
        el.blur()
        break
    }
  }

  const renameStudy = async () => {
    if (inputRef.current === null) return

    const newStudyName = inputRef.current.value

    if (newStudyName.length === 0) return

    if (newStudyName !== studyName) {
      try {
        await mutateAsync({
          pathParams: {
            moderatedStudyId: study.id,
          },
          body: {
            internal_name: newStudyName,
          },
        })

        setStudyName(newStudyName) // Cache the new value so it displays straight away
      } catch (e) {
        toast({
          status: "error",
          title: e.payload.message ?? "Error renaming study",
        })
      } finally {
        setRenaming(false)
      }
    } else {
      setRenaming(false)
    }
  }

  return (
    <Box
      gridArea="name"
      textOverflow="ellipsis"
      overflow={renaming ? "visible" : "hidden"}
    >
      {renaming ? (
        <HStack
          onBlur={(e) =>
            !e.currentTarget.contains(e.relatedTarget) && setRenaming(false)
          }
        >
          <Input
            ref={inputRef}
            size="sm"
            defaultValue={studyName}
            autoFocus
            onFocus={(e) => e.currentTarget.select()}
            onChange={(e) => {
              setIsValidInput(e.currentTarget.value.length > 0)
            }}
            onKeyDown={renameKeyDown}
          />
          <Button
            variant="solid"
            size="sm"
            colorScheme="brand.primary"
            isDisabled={!isValidInput}
            onClick={() => void renameStudy()}
          >
            Save
          </Button>
        </HStack>
      ) : (
        <Heading
          textStyle="ds.heading.primary"
          color="ds.text.default"
          as="h3"
          noOfLines={2}
        >
          {studyName}
        </Heading>
      )}
    </Box>
  )
}

export default StudyName
