import "JavaScripts/debug"
import "JavaScripts/polyfills"
import { State } from "Redux/app-store"
import createUhStore from "Redux/create-uh-store"
import initializeState, { InitializerMap } from "Redux/initialize-state"
import { figmaFileVersions } from "Redux/reducers/figma-file-versions/reducer"
import flash from "Redux/reducers/flash"
import flashInitializer from "Redux/reducers/flash/initialize-state"
import screenshots from "Redux/reducers/screenshots/reducer"
import testBuilder from "Redux/reducers/test-builder/reducer"
import testResults from "Redux/reducers/test-results/reducer"
import usabilityTests from "Redux/reducers/usability-tests/reducer"
import { mountSpa } from "Shared/mountSpa"
import "Stylesheets/fonts/mint-grotesk.css"
import { UsabilityHubProvider } from "UsabilityHub/UsabilityHubProvider"
import { UsabilityHub } from "UsabilityHub/views/UsabilityHub"
import { getJsonFromScriptElement } from "Utilities/get-json-from-script-element"
import { combineReducers } from "redux"
import { reducer as form } from "redux-form"

const rawState = getJsonFromScriptElement("initial_state")

const store = createUhStore(
  combineReducers({
    figmaFileVersions,
    flash,
    form,
    testBuilder,
    testResults,
    usabilityTests,
    screenshots,
  }),
  initializeState(rawState, { flash: flashInitializer } as InitializerMap<
    typeof rawState,
    State,
    keyof typeof rawState & keyof State
  >)
)

mountSpa(UsabilityHub, {}, store, UsabilityHubProvider)
