import {
  Avatar,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react"
import { deletedUserName } from "Constants/user"
import { useTeamMember } from "UsabilityHub/hooks/useTeamMembers"
import React from "react"

type Props = {
  userId: number
  fallbackName?: string
  children: (userName: string) => React.ReactNode
}

export const UserPopover: React.FC<Props> = ({
  userId,
  fallbackName,
  children,
}) => {
  const user = useTeamMember(userId)
  const userName = user?.name ?? fallbackName ?? deletedUserName
  const userEmail = user?.email ?? "–"

  return (
    <Popover placement="top" trigger="hover">
      <Portal>
        <PopoverContent w="fit-content" boxShadow="xl">
          <PopoverBody>
            <Flex gap={2} alignItems="center">
              <Avatar
                boxSize={8}
                name={userName}
                src={user?.avatar_url_medium}
              />
              <Flex direction="column">
                <Text
                  fontSize="sm"
                  lineHeight={5}
                  fontWeight="semibold"
                  color="text.primary"
                >
                  {userName}
                </Text>
                <Text fontSize="xs" lineHeight={4} color="text.secondary">
                  {userEmail}
                </Text>
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
      <PopoverTrigger>{children(userName)}</PopoverTrigger>
    </Popover>
  )
}
