import {
  Box,
  BoxProps,
  HStack,
  Stack,
  StackProps,
  Text,
} from "@chakra-ui/react"
import { kebabCase } from "lodash"
import React from "react"

import { useLocalCommentContext } from "Components/comment-provider/CommentProvider"
import {
  EntityIdentifier,
  entityIdentifiersMatch,
  serializeEntity,
} from "Components/comment-thread/entities"

export interface TestCardProps {
  id: string
  commentableEntity?: EntityIdentifier
}
export const TestCard: React.FC<
  React.PropsWithChildren<TestCardProps & BoxProps>
> = ({ id, children, commentableEntity, ...rest }) => {
  const { activeThread } = useLocalCommentContext()
  // Highlight the card if it has an associated comment thread and that thread
  // is currently active.
  const highlight =
    commentableEntity && activeThread
      ? entityIdentifiersMatch(activeThread, commentableEntity)
      : false

  return (
    <Box
      id={id}
      data-qa={`card-${kebabCase(id)}`}
      bg="ds.surface.raised.resting"
      rounded="16px"
      shadow="ds.raised"
      border="1px solid"
      borderColor={highlight ? "brand.primary.600" : "transparent"}
      data-commentable={
        commentableEntity ? serializeEntity(commentableEntity) : undefined
      } // This controls when to show any comment widgets inside
      p={8}
      {...rest}
    >
      {children}
    </Box>
  )
}

export const TestCardHeader: React.FC<StackProps> = ({
  children,
  ...props
}) => {
  return (
    <HStack position="relative" h="10" {...props}>
      {children}
    </HStack>
  )
}

export const TestCardTitle: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <HStack flexGrow={1} alignItems="baseline">
      {children}
    </HStack>
  )
}

export const TestCardActions: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return <Box flexShrink={0}>{children}</Box>
}

export const TestCardHeading: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Text
      as="h2"
      fontSize="lg"
      fontWeight="semibold"
      lineHeight={7}
      color="gray.900"
    >
      {children}
    </Text>
  )
}

export const TestCardHint: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Text as="span" color="gray.500">
      {children}
    </Text>
  )
}

export const TestCardBody: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Stack spacing={6} marginTop={6}>
      {children}
    </Stack>
  )
}

export const TestCardDivider: React.FC<React.PropsWithChildren<BoxProps>> = (
  props
) => (
  <Box {...props}>
    <Box height="px" mx={-8} bg="gray.200" />
  </Box>
)
