import classnames from "classnames"
import React from "react"

import styles from "./cost-table.module.scss"

export const CostTable: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <table className={styles.table}>{children}</table>

export const CostTableBody: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <tbody>{children}</tbody>

export const CostRow: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <tr className={styles.row}>{children}</tr>

export const CostRowLabel: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <th className={styles.label}>{children}</th>

export const CostRowValue: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <td className={styles.value}>{children}</td>

export const CostTableTotal: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <tfoot>{children}</tfoot>

export const CostTotalRow: React.FC<
  React.PropsWithChildren<{ fullwidth?: boolean }>
> = ({ children }) => <tr className={styles.totalRow}>{children}</tr>

export const CostTotalLabel: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <th className={styles.totalLabel}>{children}</th>

export const CostTotalValue: React.FC<
  React.PropsWithChildren<{ strikethrough?: boolean }>
> = ({ children, strikethrough = false }) => (
  <td
    className={classnames(
      styles.totalValue,
      strikethrough && styles.strikethrough
    )}
  >
    {children}
  </td>
)
