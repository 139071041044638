import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"
import { FieldError, UseFormRegisterReturn } from "react-hook-form"
import {
  AVAILABILITY_ALIGNMENT_OPTIONS,
  BUFFER_MINS,
  MINIMUM_NOTICE_OPTIONS,
} from "../../moderated-study-builder/forms/useAvailabilitySectionForm"
import { Card } from "../Card"
import { useStudyDetails } from "../StudyDetailsProvider"

export const Advanced: React.FC = () => {
  const { availabilityForm } = useStudyDetails()

  const {
    register,
    formState: { errors },
  } = availabilityForm

  return (
    <>
      <Card.Root>
        <Card.Header title="Advanced availability" />
        <Card.Body>
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fit, minmax(12rem, 1fr))"
            gap={4}
            alignItems="stretch"
          >
            <EventBuffer
              label="Buffer before session"
              description="Add a gap before booked interviews."
              registration={register("preEventBufferMins")}
              error={errors.preEventBufferMins}
            />

            <EventBuffer
              label="Buffer after session"
              description="Add a gap after booked interviews."
              registration={register("postEventBufferMins")}
              error={errors.postEventBufferMins}
            />

            <StackedSelect
              label="Session start times"
              description="Select when sessions can begin (e.g. 15 mins: 9:00, 9:15, 9:30)."
              registration={register("availabilityAlignment")}
              error={errors.availabilityAlignment}
            >
              {AVAILABILITY_ALIGNMENT_OPTIONS.map(([label, minutes]) => (
                <option key={minutes} value={minutes}>
                  {label}
                </option>
              ))}
            </StackedSelect>

            <StackedSelect
              label="Minimum notice"
              description="Minimum time in advance that an appointment can be booked."
              registration={register("minimumNotice")}
              error={errors.minimumNotice}
            >
              {MINIMUM_NOTICE_OPTIONS.map((hours) => (
                <option key={hours} value={hours}>
                  {hours} {hours === 1 ? "hour" : "hours"}
                </option>
              ))}
            </StackedSelect>
          </Box>
        </Card.Body>
      </Card.Root>
    </>
  )
}

type StackedSelectProps = {
  label: string
  description: string
  registration: UseFormRegisterReturn
  error: FieldError | undefined
}

const StackedSelect: React.FC<PropsWithChildren<StackedSelectProps>> = ({
  label,
  description,
  registration,
  error,
  children,
}) => (
  <VStack alignItems="flex-start" spacing={2}>
    <FormControl
      isInvalid={!!error}
      flex={1}
      display="flex"
      flexDirection="column"
    >
      <FormLabel flex="1">
        <Text as="div" textStyle="ds.heading.secondary" mb={1}>
          {label}
        </Text>
        <Text as="div" textStyle="ds.paragraph.secondary">
          {description}
        </Text>
      </FormLabel>
      <Select
        {...registration}
        fontSize="sm"
        lineHeight={5}
        fontWeight="normal"
        size="sm"
        borderRadius={6}
      >
        {children}
      </Select>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  </VStack>
)

const EventBuffer: React.FC<StackedSelectProps> = (props) => (
  <StackedSelect {...props}>
    {BUFFER_MINS.map((duration) => (
      <option key={duration} value={duration}>
        {formatBufferMins(duration)}
      </option>
    ))}
  </StackedSelect>
)

const formatBufferMins = (bufferMins: number) => {
  if (bufferMins === 0) {
    return "No buffer"
  } else if (bufferMins <= 60) {
    return `${bufferMins} min`
  } else {
    const hours = Math.floor(bufferMins / 60)
    const mins = (bufferMins - hours * 60) % 60
    return `${hours} hr ${mins > 0 ? `${mins} min` : ""}`
  }
}
