import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CloseButton,
  Stack,
  Text,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { WrappedFieldProps } from "redux-form"

import { NewTabAnchor } from "Components/anchors/basic"
import Constants from "Constants/shared.json"
import { TestLogicBehaviour, TestLogicTarget } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"

import { useSectionContext } from "UsabilityHub/contexts"
import { WrappedMultiSelect } from "./WrappedMultiSelect/WrappedMultiSelect"
import { WrappedSelect } from "./WrappedSelect/WrappedSelect"

type TestLogicActionsFields = React.FC<
  React.PropsWithChildren<{
    behaviour: WrappedFieldProps
    _targetClientId: WrappedFieldProps
    values: WrappedFieldProps
    type: "question" | "section"
    targets: ReadonlyArray<TestLogicTarget>
    answers: string[]
    verb: string
  }>
>

export const TestLogicActionsFields: TestLogicActionsFields = ({
  behaviour,
  _targetClientId,
  values,
  type,
  targets,
  answers,
  verb,
}) => {
  const { section } = useSectionContext()
  const { readOnly } = useUsabilityTestUserActivityContext()
  const [dismissedRandomizationWarning, setDismissedRandomizationWarning] =
    useState(false)
  // Show error alert if target is set to something invalid
  const showErrorAlert =
    _targetClientId.meta.error && _targetClientId.input.value

  // Only show warning if alert isn’t shown
  const showWarningAlert = !showErrorAlert && _targetClientId.meta.warning
  return (
    <Box>
      <Stack isInline alignItems="center" spacing={5}>
        <WrappedSelect
          isDisabled={readOnly}
          data-qa="behaviour-select"
          {...behaviour}
        >
          <option value={TestLogicBehaviour.SHOW}>Show</option>
          <option value={TestLogicBehaviour.HIDE}>Hide</option>
        </WrappedSelect>
        <Text flex="1 0 auto">this {type} if</Text>
        <WrappedSelect
          placeholder="Select a target"
          errorMessage="Please select a target"
          isDisabled={readOnly}
          data-qa="target-select"
          {..._targetClientId}
        >
          {targets.map((target) => (
            <option key={target.label} value={target.targetClientId}>
              {target.label}
            </option>
          ))}
        </WrappedSelect>
        <Text flex="1 0 auto">{verb}</Text>
        <WrappedMultiSelect
          data-qa="answers-select"
          disabled={readOnly || !_targetClientId.meta.valid}
          placeholder="Select an answer"
          options={answers}
          field={values.input.name}
        />
      </Stack>
      {showErrorAlert && (
        <Alert status="error" mt={3}>
          <AlertIcon />
          <AlertDescription>{_targetClientId.meta.error}</AlertDescription>
        </Alert>
      )}
      {showWarningAlert && (
        <Alert status="warning" mt={3}>
          <AlertIcon />
          <AlertDescription>{_targetClientId.meta.warning}</AlertDescription>
        </Alert>
      )}
      {targets.length === 0 && (
        <Alert status="warning" mt={3}>
          <AlertIcon />
          <AlertDescription>
            {`There aren${"\u2019"}t any questions before this part of the test to select as a target. `}
            <NewTabAnchor href={Constants.HELP_CENTER_CONDITIONAL_LOGIC_URL}>
              Learn more
            </NewTabAnchor>
          </AlertDescription>
        </Alert>
      )}
      {type === "question" &&
        section.questions_randomized &&
        !dismissedRandomizationWarning && (
          <Alert status="warning" mt={3} pos="relative" pe={10}>
            <AlertIcon />
            <AlertDescription>
              When the order of questions is randomized, you cannot add test
              logic based on responses within the same section.{" "}
              <NewTabAnchor href={Constants.HELP_CENTER_CONDITIONAL_LOGIC_URL}>
                Learn more
              </NewTabAnchor>
            </AlertDescription>

            <CloseButton
              pos="absolute"
              top={2}
              right={2}
              onClick={() => setDismissedRandomizationWarning(true)}
            />
          </Alert>
        )}
    </Box>
  )
}
