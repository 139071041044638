import { DataPoint, HeatmapData } from "heatmap.js"
import { countBy, mean } from "lodash"

import { Dimensions } from "Types"
import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"

/* Slice up the data in squares of size `gridSize`, then count the number of
 * clicks that fall within each grid.
 */
const countByGrid = (data: ReadonlyArray<DataPoint>, gridSize: number) => {
  return countBy(
    data,
    (datum) =>
      `${Math.floor(datum.x / gridSize)},${Math.floor(datum.y / gridSize)}`
  )
}

/* First estimate density by counting how many clicks landed in each
 * radius-length-sided grid square in the target area. Then find an
 * average of these values.
 */
const estimateDataMax = (data: ReadonlyArray<DataPoint>, radius: number) => {
  if (data.length === 0) return 1
  const present = Object.values(countByGrid(data, radius))
  return mean(present)
}

export function getHeatmapData(
  normalizedClicks: NormalizedClick[],
  { width, height }: Dimensions,
  radius: number
): HeatmapData<DataPoint<"value", "x", "y">> {
  const dataPoints = normalizedClicks.map(
    (click): DataPoint => ({
      value: 1,
      x: Math.floor(click.x * width),
      y: Math.floor(click.y * height),
    })
  )
  const max = estimateDataMax(dataPoints, radius)
  const min = 0

  return {
    max,
    min,
    data: dataPoints,
  }
}
