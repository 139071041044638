import { Actions } from "Redux/reducers/usability-tests/action-creators"
import { ActionType } from "Redux/reducers/usability-tests/constants"
import { NoopAction, ShallowUsabilityTest } from "Types"
import { createArrayUtility } from "Utilities/immutable-array"

export type ReducerState = ReadonlyArray<Readonly<ShallowUsabilityTest>>

const { mergeFirst } =
  createArrayUtility<Readonly<ShallowUsabilityTest>>("usability test")

export default function usabilityTests(
  state: ReducerState = [],
  action: Actions | NoopAction = { type: "__NOOP__" }
): ReducerState {
  switch (action.type) {
    case ActionType.INIT_USABILITY_TESTS: {
      return action.payload ?? []
    }
    case ActionType.UPDATE_USABILITY_TEST_SUCCESS: {
      const {
        payload: attributes,
        meta: { id },
      } = action
      return mergeFirst(state, { id }, attributes)
    }
    case ActionType.RELOAD_USABILITY_TEST_SUCCESS: {
      const {
        payload,
        meta: { id },
      } = action
      return mergeFirst(state, { id }, payload)
    }
    default:
      return state
  }
}
