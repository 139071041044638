import { getSectionTypeRules } from "Constants/test-section-types"
import { UsabilityTestSection } from "Types"

export function getFullSectionTitle(
  section: UsabilityTestSection,
  index: number
) {
  const title = section.title ?? getSectionTypeRules(section.type).defaultTitle
  return `${index + 1}. ${title}`
}
