import { get, times } from "lodash"
import { arrayInsert, arrayMove, arrayRemove, change } from "redux-form"

import { getSectionTypeRules } from "Constants/test-section-types"
import { PrototypeType } from "JavaScripts/types/figma-file-flow"
import { State } from "Redux/app-store"
import {
  getFormName,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { getSections } from "Redux/reducers/test-builder-form/selectors/sections"
import { createCheckpoint } from "Redux/reducers/test-builder/action-creators/undo"
import { SaveDeletedSectionAction } from "Redux/reducers/test-builder/action-types"
import { Type } from "Redux/reducers/test-builder/constants"
import { RequiredProperties } from "Shared/types/RequiredProperties"
import {
  PermittedDeviceType,
  ThunkAction,
  UsabilityTestSection,
  UsabilityTestSectionType,
} from "Types"
import { nextClientId } from "Utilities/client-id"
import {
  createQuestion,
  duplicateTreeTestAttributes,
  isPersisted,
  replaceIds,
  stripTestLogicStatement,
} from "../../test-builder/helpers"
import { duplicateCardSortCardsImages } from "./card_sort_cards_image"
import { createBlankTask } from "./live-website-test"

type DefinedSection = RequiredProperties<
  Partial<UsabilityTestSection>,
  "type" | "text" | "position"
>

// ts-prune-ignore-next used in test
export const getPrototypeType = (
  sectionType: UsabilityTestSectionType,
  prototypeType?: PrototypeType | null
) => {
  if (sectionType === UsabilityTestSectionType.PrototypeTask) {
    if (prototypeType) {
      return prototypeType
    } else {
      return "task_flow"
    }
  }
  return null
}

export const addSection =
  (
    type: UsabilityTestSectionType,
    position: number | null = null,
    data: Partial<UsabilityTestSection> = {}
  ): ThunkAction<State> =>
  (dispatch, getState) => {
    const state = getState()
    const sections = getSections(state)
    const newIndex = position !== null ? position : sections.length

    // Create the new section
    const newSection: DefinedSection = {
      id: null as any, // Type hack... TODO: fix
      type,
      text: "",
      position: newIndex,
      section_screenshots: [],
      device_frame_id: null,
      questions: times(getSectionTypeRules(type).initialQuestions, () =>
        createQuestion(type)
      ),
      randomized: type === UsabilityTestSectionType.PreferenceTest,
      task_time_limit_ms:
        type === UsabilityTestSectionType.FiveSecondTest ? 5000 : 0,
      ...data,
      _clientId: nextClientId(),
      archived: false,
      test_logic_statement: data.test_logic_statement || null,
      recording_attributes:
        type === UsabilityTestSectionType.LiveWebsiteTest
          ? {
              id: "",
              screen: true,
              microphone: false,
              camera: false,
            }
          : undefined,

      card_sort_attributes:
        type === UsabilityTestSectionType.CardSort
          ? {
              card_sort_type: "closed",
              shuffle_cards: true,
              shuffle_categories: false,
              show_images: false,
              show_descriptions: false,
              show_category_descriptions: false,
              require_sort_all: true,
              require_name_all: true,
              number_of_cards_shown: null,
              card_sort_cards_attributes: [{ label: "" }],
              card_sort_categories_attributes: [{ label: "" }],
              card_sort_open_categories_attributes: [],
              card_sort_category_groups: [],
              ...data.card_sort_attributes,
            }
          : null,
      live_website_test:
        type === UsabilityTestSectionType.LiveWebsiteTest
          ? {
              live_website_test_tasks: [createBlankTask()],
            }
          : null,
      prototype_type: getPrototypeType(data.type ?? type, data.prototype_type),
    }

    // Add it
    dispatch(insertDefinedSection(newSection))
  }

/*
 * Insert a section that has already been defined,
 * e.g. From undo history, or from data loaded from API
 */
export const insertDefinedSection =
  (section: DefinedSection): ThunkAction<State> =>
  (dispatch) => {
    dispatch(arrayInsert(getFormName(), "sections", section.position, section))
    dispatch(sectionsWereAdded([section]))
  }

/*
 * When sections are added to the state,
 * we need to ensure that any rules on the new section are applied.
 */
export const sectionsWereAdded =
  (
    sections: RequiredProperties<Partial<UsabilityTestSection>, "type">[]
  ): ThunkAction<State> =>
  (dispatch) => {
    for (const section of sections) {
      const rules = getSectionTypeRules(section.type)
      if (
        rules.permittedDeviceType &&
        rules.permittedDeviceType !== PermittedDeviceType.Any
      ) {
        dispatch(
          change(
            getFormName(),
            "permitted_device_type",
            rules.permittedDeviceType
          )
        )
      }
    }
  }

export const duplicateSection =
  (sectionIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    // Get the step
    const state = getState()
    const values = getFormValues(state)
    const sections = get(
      values,
      "sections",
      []
    ) as ReadonlyArray<UsabilityTestSection>
    const section = sections[sectionIndex]
    if (!section) return

    // Forward to add section action, but strip logic and change the ids to new ones first
    const newSection = stripTestLogicStatement(replaceIds(section))

    // All ids in image_data are removed by the previous step stripTestLogicStatement().
    // Reload all image_data from section for reuploading the images later.
    if (
      section.card_sort_attributes &&
      section.card_sort_attributes.show_images
    ) {
      section.card_sort_attributes.card_sort_cards_attributes.forEach(
        (card, index) => {
          newSection.card_sort_attributes!.card_sort_cards_attributes[
            index
          ].image_data = card.image_data
        }
      )
    }

    if (section.tree_test_attributes) {
      newSection.tree_test_attributes = duplicateTreeTestAttributes(
        section.tree_test_attributes
      )
    }

    // Remove all delete-pending cards & categories from new section
    if (newSection.card_sort_attributes) {
      newSection.card_sort_attributes.card_sort_cards_attributes =
        newSection.card_sort_attributes.card_sort_cards_attributes.filter(
          (card) => !card._destroy
        )
      newSection.card_sort_attributes.card_sort_categories_attributes =
        newSection.card_sort_attributes.card_sort_categories_attributes.filter(
          (category) => !category._destroy
        )
    }

    const newIndex = sectionIndex + 1
    dispatch(addSection(section.type, newIndex, newSection))
    if (newSection.card_sort_attributes) {
      void dispatch(duplicateCardSortCardsImages(newIndex))
    }
    dispatch(selectSection(sectionIndex))
  }

export const deleteSection =
  (sectionIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    const values = getFormValues(getState())

    const field = `sections`
    const section = values.sections[sectionIndex]

    dispatch(
      createCheckpoint({
        type: "delete-section",
        sectionIndex,
        section,
      })
    )

    if (isPersisted(section)) {
      dispatch<SaveDeletedSectionAction>({
        type: Type.SAVE_DELETED_TEST_FORM_SECTION,
        payload: { sectionId: section.id },
      })
    }
    dispatch(arrayRemove(getFormName(), field, sectionIndex))
  }

export const moveSection =
  (from: number, to: number): ThunkAction<State> =>
  (dispatch) => {
    dispatch(arrayMove(getFormName(), "sections", from, to))
  }

const selectSection =
  (sectionIndex: number): ThunkAction<State> =>
  () => {
    // Scroll to the end of the section this brings the
    // animation of the new section into view. It's difficult to animate to the
    // newly added section while it's animating in.
    const element = document.getElementById(`section_${sectionIndex}`)
    if (element !== null) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }
