import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { PencilIcon } from "@heroicons/react/solid"
import React from "react"
import { useDispatch } from "react-redux"

import { useUndoToast } from "Hooks/use-undo-toast"
import { Dispatch } from "Redux/app-store"
import { updateScreenshotNameAndDisplayScale } from "Redux/reducers/screenshots/action-creators"
import { deleteSectionScreenshot } from "Redux/reducers/test-builder-form/action-creators/screenshots"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { useSectionIndexContext } from "UsabilityHub/contexts"
import { countHitzones } from "Utilities/screenshot-hitzone"

import { useSectionMediaContext } from "../SectionMediaContext"

import { EyeOutlineIcon } from "Shared/icons/untitled-ui/EyeOutlineIcon"
import { Trash01OutlineIcon } from "Shared/icons/untitled-ui/Trash01OutlineIcon"
import { Mode } from "./MediaRow"
import {
  DisplayScaleField,
  MediaFileNameForm,
} from "./MediaRowEditableDetailsFields"

interface MediaRowEditableDetailsProps {
  mode: Mode
  setMode: (mode: Mode) => void
  showHitzoneButton: boolean
}

export const MediaRowEditableDetails: React.FC<
  React.PropsWithChildren<MediaRowEditableDetailsProps>
> = ({ mode, setMode, showHitzoneButton }) => {
  const dispatch: Dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const { readOnly } = useUsabilityTestUserActivityContext()
  const undoToast = useUndoToast()
  const { sectionMediaIndex, sectionMedia, mediaFile } =
    useSectionMediaContext()
  const hitzoneCount = countHitzones(sectionMedia.screenshot_hitzones)
  const hasHitzones = hitzoneCount > 0

  const handleDelete = () => {
    dispatch(deleteSectionScreenshot(sectionIndex, sectionMediaIndex))
    undoToast(sectionMedia.screenshot_name ?? "Media")
  }

  // We have to manually dispatch an update here because `screenshots` are
  // on the global state, not part of the test-form redux form.
  // This is persisted straight away regardless of whether the user saves the test.
  const handleDataChange = (name: string | null, scale: number | null) => {
    // Default to what it was before if we haven't got any new data.
    // We've decided not to mess with any redux actions for now, so we have
    // to update both at once.
    const mediaFileName = name || mediaFile.name
    const displayScale = scale || mediaFile.display_scale!
    void dispatch(
      updateScreenshotNameAndDisplayScale(
        mediaFile._clientId,
        mediaFileName,
        displayScale
      )
    )
    setMode(Mode.Normal)
  }
  const onMediaFileNameSave = (name: string) => {
    handleDataChange(name, null)
  }
  const onDisplayScaleSave = (scale: number) => {
    handleDataChange(null, scale)
  }

  return (
    <Box flexGrow={1}>
      {mode === Mode.Editing ? (
        <MediaFileNameForm
          onCancel={() => setMode(Mode.Normal)}
          onSave={onMediaFileNameSave}
        />
      ) : (
        <Box py={2}>
          <HStack spacing={1}>
            <Text
              fontWeight="bold"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="100%"
            >
              {mediaFile?.name}
            </Text>
            <Tooltip hasArrow placement="top" label="Rename">
              <IconButton
                aria-label="Rename media file"
                icon={<Icon as={PencilIcon} boxSize={5} />}
                color="gray.500"
                variant="ghost"
                size="sm"
                onClick={() => setMode(Mode.Editing)}
                isDisabled={readOnly}
              />
            </Tooltip>
          </HStack>
          <ButtonGroup variant="outline" size="sm" mt={3}>
            {showHitzoneButton &&
              (hasHitzones ? (
                <Button
                  isDisabled={readOnly}
                  onClick={() => setMode(Mode.EditingHitzones)}
                >
                  Edit hitzones
                </Button>
              ) : (
                <Button
                  colorScheme="brand.secondary"
                  variant="solid"
                  isDisabled={readOnly}
                  onClick={() => setMode(Mode.EditingHitzones)}
                >
                  Add hitzones
                </Button>
              ))}
            <Button
              leftIcon={<Icon as={EyeOutlineIcon} />}
              isDisabled={readOnly}
              onClick={() => setMode(Mode.Preview)}
            >
              Preview
            </Button>
            <Button
              leftIcon={<Icon as={Trash01OutlineIcon} />}
              isDisabled={readOnly}
              onClick={handleDelete}
            >
              Remove
            </Button>
          </ButtonGroup>
          {mediaFile?.display_scale !== null && (
            <DisplayScaleField onSave={onDisplayScaleSave} />
          )}
        </Box>
      )}
    </Box>
  )
}
