import { getAllCardSortOpenCategoriesRanking } from "Redux/reducers/test-results/selectors"
import {
  CardSort,
  CardSortCard,
  CardSortCategoryCard,
  CardSortCategoryGroup,
  CardSortOpenCategory,
  CardSortType,
} from "Types"
import { uniq } from "lodash"
import React, { useCallback, useState } from "react"
import { createContext } from "react"
import { useSelector } from "react-redux"
import CardSortCategoriesApi from "~/api/api/cardSortCategoriesApi"
import {
  EitherCategory,
  categoryHasId,
  groupCategories,
} from "./card-sort-categories/grouping"

interface CardSortResultsContextInterface {
  sectionId: number
  cards: CardSortCard[]
  categories: EitherCategory[]
  categoryGroups: CardSortCategoryGroup[]
  groupedCategories: Record<string, EitherCategory[]>
  updateGroupings: (
    groupId: number,
    categoryIds: (string | null)[],
    newLabel: string
  ) => Promise<void>
  cardSortType: CardSortType
  sortData: CardSortCategoryCard[]
  totalResponses: number
}

const CardSortResultsContext = createContext<CardSortResultsContextInterface>({
  sectionId: 0,
  cards: [],
  categories: [],
  cardSortType: "closed",
  categoryGroups: [],
  groupedCategories: {},
  updateGroupings: () => {
    throw new Error("Not inside a CardSortResultsContext")
  },
  totalResponses: 0,
  sortData: [],
})

export const useCardSortResultsContext = () => {
  const context = React.useContext(CardSortResultsContext)
  if (!context) {
    throw new Error(
      "useCardSortResultsContext must be used within a CardSortResultsContextProvider"
    )
  }

  return context
}

const allCategories = (
  cardSort: CardSort,
  openCategoriesWithRanking: CardSortOpenCategory[],
  anyUnsorted: boolean
) => {
  const categories = [
    ...(cardSort.card_sort_categories_attributes ?? []),
    ...openCategoriesWithRanking,
  ].filter(categoryHasId)
  if (anyUnsorted) {
    categories.push(
      // Add a category for unsorted cards
      // Unsorted cards have card_sort_category_id as null
      // But the null value will be converted to 0 via Number()
      {
        id: null,
        label: "Unsorted",
      }
    )
  }
  return categories
}
interface Props {
  sectionId: number
  cardSort: CardSort
  sortData: CardSortCategoryCard[]
}

export const CardSortResultsContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ sectionId, cardSort, sortData, children }) => {
  const [anyUnsorted] = useState(
    sortData.some(
      (c) =>
        c.card_sort_category_id == null && c.card_sort_open_category_id == null
    )
  )

  const cards = cardSort.card_sort_cards_attributes ?? []

  // Add ranking to card sort open category
  // in order to show "Untitled #${ranking}" as the label of untitled groups/categories
  const CardSortOpenCategoriesRanking = useSelector(
    getAllCardSortOpenCategoriesRanking
  )
  const getRankingOfOpenCategory = useCallback(
    (cardSortOpenCategoryId: number) => {
      const ids = Object.values(CardSortOpenCategoriesRanking).find((e) =>
        (e as number[]).includes(cardSortOpenCategoryId)
      ) as number[]
      return ids ? ids.indexOf(cardSortOpenCategoryId) + 1 : 0
    },
    [CardSortOpenCategoriesRanking]
  )
  const getOpenCategoriesWithRanking = useCallback(
    (cardSort: CardSort) =>
      cardSort.card_sort_open_categories_attributes
        ? cardSort.card_sort_open_categories_attributes.map((c) => ({
            ...c,
            ranking: getRankingOfOpenCategory(Number(c.id)),
          }))
        : [],
    [getRankingOfOpenCategory]
  )

  const [categories, setCategories] = useState(() =>
    allCategories(cardSort, getOpenCategoriesWithRanking(cardSort), anyUnsorted)
  )
  const [categoryGroups, setCategoryGroups] = useState(
    cardSort?.card_sort_category_groups ?? []
  )

  const updateGroupings = async (
    groupId: number,
    categoryIds: (string | null)[],
    newLabel: string
  ) => {
    const cardSort = await CardSortCategoriesApi.group<CardSort>({
      data: {
        open_category_ids: categoryIds,
        group_id: groupId,
        group_name: newLabel.trim(),
      },
    })

    setCategories(
      allCategories(
        cardSort,
        getOpenCategoriesWithRanking(cardSort),
        anyUnsorted
      )
    )
    setCategoryGroups(cardSort.card_sort_category_groups)
  }

  const groupedCategories = groupCategories(categories, categoryGroups)

  const totalResponses = uniq(
    sortData.map((sd) => sd.response_section_id)
  ).length

  return (
    <CardSortResultsContext.Provider
      value={{
        sectionId,
        cardSortType: cardSort.card_sort_type,
        cards,
        categories,
        categoryGroups,
        groupedCategories,
        updateGroupings,
        sortData,
        totalResponses,
      }}
    >
      {children}
    </CardSortResultsContext.Provider>
  )
}
