import cx from "classnames"
import React from "react"

import { NumberInput } from "Components/form/number-input/number-input"

import styles from "./participant-limit-fields.module.scss"

interface Props {
  readonly recruitedResponseCount: number
  readonly onChange: (limit: number) => void
  readonly value: number
  readonly required: boolean
}

export const ParticipantLimitFields = ({
  onChange,
  recruitedResponseCount,
  value,
  required,
}: Props) => (
  <div className={styles.container}>
    <NumberInput
      className={styles.input}
      min={required ? recruitedResponseCount || 1 : undefined}
      max={required ? 1_000_000 : undefined}
      onChange={onChange}
      value={value}
    />
    {recruitedResponseCount > 0 && (
      <p className={cx("para", styles.text)}>
        You will receive {Math.max(0, value - recruitedResponseCount)} more
        responses.
      </p>
    )}
  </div>
)
