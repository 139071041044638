import { Image, Stack } from "@chakra-ui/react"
import { ROUTES } from "UsabilityHub/views/routes"
import { AnimatePresence, Variants, motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import { useLocation, useOutlet } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { useTemplateLibrary } from "~/usabilityhub/hooks/useTemplateLibrary"

import errorImage from "Images/app-illustrations/lyssna/error.png"

const animationVariants: Variants = {
  initial: (custom: number) => ({
    opacity: 0,
    x: `${100 * custom}%`,
  }),
  in: {
    opacity: 1,
    x: 0,
  },
  out: (custom: number) => ({
    opacity: 0,
    x: `${-100 * custom}%`,
  }),
}

export const Contents: React.FC = () => {
  const { error, setError } = useTemplateLibrary()

  const { pathname } = useLocation()

  useEffect(() => {
    setError(null)
  }, [pathname])

  const { templateId } = useTypedParams(ROUTES.DASHBOARD.TEMPLATES.DETAILS)

  const showingDetails = !!templateId

  const state = error ? "error" : showingDetails ? "details" : "list"

  return (
    <AnimatePresence
      initial={false}
      mode="popLayout"
      custom={showingDetails || error ? 1 : -1}
    >
      {error ? (
        <motion.div
          key="error"
          variants={animationVariants}
          initial="initial"
          animate="in"
          exit="out"
          custom={1}
          data-state="error"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
          }}
        >
          <Stack align="center" flex={1} justifyContent="center" p={16} gap={5}>
            <Image src={errorImage} alt="" h={60} />
            {error}
          </Stack>
        </motion.div>
      ) : (
        <motion.div
          key={pathname}
          variants={animationVariants}
          initial="initial"
          animate="in"
          exit="out"
          custom={showingDetails ? 1 : -1}
          data-state={state}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
          }}
        >
          <AnimatedOutlet />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const AnimatedOutlet: React.FC = () => {
  const o = useOutlet()
  const [outlet] = useState(o)

  return <>{outlet}</>
}
