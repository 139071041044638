// JsFromRoutes CacheKey 1c6c7b1006f10e12459c0d2b9175bd01
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: /* #__PURE__ */ definePathHelper('get', '/admin/admin_notes'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/admin_notes'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/admin_notes/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/admin_notes/:id'),
}
