import { useSelector } from "react-redux"

import { getAllClicks } from "Redux/reducers/test-results/selectors"

import { useFilteredResponses } from "./use-filtered-responses"

export const useFilteredClicks = () => {
  const filteredResponses = useFilteredResponses()
  const allClicks = useSelector(getAllClicks)

  return allClicks.filter((click) =>
    filteredResponses.some((response) => click.response_id === response.id)
  )
}
