import { Filter } from "Components/test-results/types/filters"
import { rectangleContainsPoint } from "Utilities/rectangle"

import { HitzoneFilterCategory } from "./hitzone"
import { FilterFunction } from "./types"

export type ClientHitzoneFilter = Filter<
  HitzoneFilterCategory,
  "answer/client-hitzone",
  string
>

export const filterResponseByClientHitzone: FilterFunction<
  ClientHitzoneFilter
> = ({
  filter,
  filteredRelatedData: { screenshotClicks },
  allRelatedData: { clientHitzones },
}) => {
  const clientHitzone = clientHitzones.find(
    (clientHitzone) => clientHitzone._clientId === filter.value
  )!

  const filteredScreenshotClicks = screenshotClicks.filter(
    (click) =>
      click.usability_test_section_screenshot_id ===
      clientHitzone?.usability_test_section_screenshot_id
  )

  return filteredScreenshotClicks.some(rectangleContainsPoint(clientHitzone))
}
