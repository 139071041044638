import { createIcon } from "@chakra-ui/react"
import React from "react"

export const UserResponseIcon = createIcon({
  displayName: "UserResponseIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="user_response">
      <g id="Shape">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8C1 4.136 4.136 1 8 1C11.864 1 15 4.136 15 8C15 8.3395 14.9758 8.67338 14.929 9H9C8.66733 9 8.33834 9 8 9C6.66667 9 4.02 9.69206 4 10.9556C4.86 12.1873 6.33333 13 8 13V15C4.136 15 1 11.864 1 8ZM10 5C10 3.89333 9.10667 3 8 3C6.89333 3 6 3.89333 6 5C6 6.10667 6.89333 7 8 7C9.10667 7 10 6.10667 10 5Z"
          fill="currentColor"
        />
        <path
          d="M11 10.5C11 10.2239 11.2239 10 11.5 10H14.5C14.7761 10 15 10.2239 15 10.5C15 10.7761 14.7761 11 14.5 11H11.5C11.2239 11 11 10.7761 11 10.5Z"
          fill="currentColor"
        />
        <path
          d="M9.5 10C9.22386 10 9 10.2239 9 10.5C9 10.7761 9.22386 11 9.5 11C9.77614 11 10 10.7761 10 10.5C10 10.2239 9.77614 10 9.5 10Z"
          fill="currentColor"
        />
        <path
          d="M11 12.5C11 12.2239 11.2239 12 11.5 12H14.5C14.7761 12 15 12.2239 15 12.5C15 12.7761 14.7761 13 14.5 13H11.5C11.2239 13 11 12.7761 11 12.5Z"
          fill="currentColor"
        />
        <path
          d="M9.5 12C9.22386 12 9 12.2239 9 12.5C9 12.7761 9.22386 13 9.5 13C9.77614 13 10 12.7761 10 12.5C10 12.2239 9.77614 12 9.5 12Z"
          fill="currentColor"
        />
        <path
          d="M11 14.5C11 14.2239 11.2239 14 11.5 14H14.5C14.7761 14 15 14.2239 15 14.5C15 14.7761 14.7761 15 14.5 15H11.5C11.2239 15 11 14.7761 11 14.5Z"
          fill="currentColor"
        />
        <path
          d="M9.5 14C9.22386 14 9 14.2239 9 14.5C9 14.7761 9.22386 15 9.5 15C9.77614 15 10 14.7761 10 14.5C10 14.2239 9.77614 14 9.5 14Z"
          fill="currentColor"
        />
      </g>
    </g>
  ),
})
