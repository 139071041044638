import { Heading, Stack, Text, Wrap, WrapItem } from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"

import { EventTime } from "Components/time/event-time"
import { State } from "Redux/app-store"
import { getUsabilityTest } from "Redux/reducers/usability-tests/selectors"
import { Header, HeaderContent } from "Shared/components/Header/Header"

interface ShareUsabilityTestResultsHeaderProps {
  id: number
}

export function ShareUsabilityTestResultsHeader({
  id,
}: ShareUsabilityTestResultsHeaderProps) {
  const usabilityTest = useSelector((state: State) =>
    getUsabilityTest(state, id)
  )

  return (
    <Header>
      <HeaderContent>
        <Stack>
          <Heading lineHeight="none" display="flex" gap={1}>
            {usabilityTest.project && (
              <Text color="gray.500" fontWeight="500">
                {usabilityTest.project} /{" "}
              </Text>
            )}
            {usabilityTest?.name || <Text color="gray.500">Untitled</Text>}
          </Heading>
          {usabilityTest?.created_at && (
            <Wrap>
              <WrapItem>
                <Text color="gray.500" fontSize="sm">
                  Created <EventTime date={usabilityTest.created_at} />
                  {usabilityTest.creator
                    ? ` by ${usabilityTest.creator.name}`
                    : null}
                </Text>
              </WrapItem>
            </Wrap>
          )}
        </Stack>
      </HeaderContent>
    </Header>
  )
}
