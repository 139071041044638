import { FormControl, HStack, Select, Text } from "@chakra-ui/react"
import React, { useState } from "react"
import { Control, Controller } from "react-hook-form"

import { TimeSlot, getTimes } from "./times"

interface Props {
  timeSlotField: TimeSlot
  fieldName: string
  index: number
  control: Control
}

const SELECT_WIDTH = 32

export const TimeSlotsSelector: React.FC<Props> = ({
  timeSlotField,
  fieldName,
  index,
  control,
}) => {
  // This is used to control the time options in the drop-down list.
  //
  // `useWatch` will cause two renders after deleting a time slot
  // and the selected option will be reset to the first option in the second render.
  // So we use `useState` to track the selected options instead.
  const [[starts_at, ends_at], setTimeSlot] = useState([
    timeSlotField.starts_at,
    timeSlotField.ends_at,
  ])

  return (
    <HStack spacing="1">
      <FormControl>
        <Controller
          control={control}
          name={`${fieldName}.${index}.starts_at`}
          render={({ field }) => (
            <Select
              {...field}
              onChange={(e) => {
                field.onChange(e)
                setTimeSlot([e.target.value, ends_at])
              }}
              minWidth="fit-content"
              width={SELECT_WIDTH}
            >
              {getTimes(null, ends_at).map((time: string[]) => (
                <option key={time[0]} value={time[0]}>
                  {time[1]}
                </option>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <Text>&ndash;</Text>
      <FormControl>
        <Controller
          control={control}
          name={`${fieldName}.${index}.ends_at`}
          render={({ field }) => (
            <Select
              {...field}
              onChange={(e) => {
                field.onChange(e)
                setTimeSlot([starts_at, e.target.value])
              }}
              minWidth="fit-content"
              width={SELECT_WIDTH}
            >
              {getTimes(starts_at, null).map((time: string[]) => (
                <option key={time[0]} value={time[0]}>
                  {time[1]}
                </option>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </HStack>
  )
}
