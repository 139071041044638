import React from "react"

import { SideNav, SideNavLink } from "Shared/components/SideNav/SideNav"

import { ROUTES } from "~/usabilityhub/views/routes"

export const LegalsSideNav: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SideNav>
      <SideNavLink to={ROUTES.LEGAL.TERMS.path}>Services agreement</SideNavLink>
      <SideNavLink to={ROUTES.LEGAL.PRIVACY.path}>Privacy policy</SideNavLink>
      <SideNavLink to={ROUTES.LEGAL.DPA.path}>
        Data Processing Agreement
      </SideNavLink>
      <SideNavLink to={ROUTES.LEGAL.SUBPROCESSORS.path}>
        Sub-processor list
      </SideNavLink>
      <SideNavLink to={ROUTES.LEGAL.SECURITY.path}>
        Security information
      </SideNavLink>
      <SideNavLink to={ROUTES.LEGAL.COOKIES.path}>Cookies</SideNavLink>
    </SideNav>
  )
}
