import React from "react"

import { ThemedButton } from "Components/button/themed-button"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ScreenshotMask } from "UsabilityHub/components/UsabilityTestSectionTask/ScreenshotMask"
import ConditionalScreenshotDeviceFrame from "UsabilityHub/components/UsabilityTestSectionTask/conditional-screenshot-device-frame"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"
import { isTaskStarted, sectionStartTime } from "Utilities/response"
import { floorMsToS } from "Utilities/time"

import {
  SectionTask,
  SectionTaskContent,
  SectionTaskHeader,
  SectionTaskInstructions,
} from "../SectionTask"

export const FiveSecondTestTask: React.FC<
  React.PropsWithChildren<InnerProps>
> = ({
  deviceFrame,
  responseSection,
  updateResponseSection,
  usabilityTestSection,
}) => {
  const translate = useTranslate()

  const sectionId = usabilityTestSection.id
  const timeLimit = usabilityTestSection.task_time_limit_ms
  const screenshotId = usabilityTestSection.section_screenshots[0].screenshot_id
  const isStarted = isTaskStarted(responseSection)

  const handleClickStart = () => {
    const revealTime = performance.now()
    updateResponseSection(sectionId, {
      instructions_duration_ms: revealTime - sectionStartTime(responseSection),
    })
    setTimeout(() => handleTimeUp(revealTime), timeLimit)
  }

  const handleTimeUp = (revealTime: number | null) => {
    if (revealTime == null) {
      throw new TypeError("revealTime == null")
    }
    updateResponseSection(sectionId, {
      task_duration_ms: performance.now() - revealTime,
    })
  }

  return (
    <SectionTask>
      <SectionTaskHeader>
        <SectionTaskInstructions>
          {usabilityTestSection.text && usabilityTestSection.text !== ""
            ? usabilityTestSection.text
            : translate(
                "test.instructions.five_second_test.look_and_remember",
                {
                  duration_s: floorMsToS(timeLimit).toString(),
                }
              )}
        </SectionTaskInstructions>
        {!isStarted && (
          <ThemedButton onClick={handleClickStart}>
            {translate("test.buttons.view_image_for_five_seconds", {
              duration_s: floorMsToS(timeLimit).toString(),
            })}
          </ThemedButton>
        )}
      </SectionTaskHeader>
      <SectionTaskContent>
        <ConditionalScreenshotDeviceFrame
          screenshotId={screenshotId}
          deviceFrame={deviceFrame}
          isScrollable={isTaskStarted(responseSection)}
          canSkipAheadOnFirstWatch
        >
          {!isTaskStarted(responseSection) && <ScreenshotMask />}
        </ConditionalScreenshotDeviceFrame>
      </SectionTaskContent>
    </SectionTask>
  )
}
