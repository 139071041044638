import { intersection } from "lodash"
import React, { useContext } from "react"
import { useSelector } from "react-redux"

import { ClearableCheckboxListSection } from "Components/demographic-filter-form/clearable-checkbox-list-section"
import {
  getAllOrders,
  getAllResponses,
} from "Redux/reducers/test-results/selectors"
import { ReactOption, Response } from "Types"
import { DemographicAttribute } from "~/api/generated/usabilityhubSchemas"

import { DemographicsContext } from "../context/demographics"
import { AnswerFilterSection } from "../filters/answer-filter-section"
import { createDemographicAttributeOptionIdFilterCategory } from "../filters/create-filters/demographic-attribute-option-Id-filter"
import { createVariableFilterCategory } from "../filters/create-filters/variable-filter"
import { ResponseFilter } from "../filters/filters/types"
import { getFiltersWithCategory } from "../filters/get-filters/get-filters-with"
import { useFilteredResponses } from "../hooks/use-filtered-responses"
import { useIsTestResultsFiltersEmpty } from "../hooks/use-is-test-results-filters-empty"
import { useTestResultsFilters } from "../hooks/use-test-results-filters"
import { useUpdateAgeRangeFilter } from "../hooks/use-update-age-range-filter"
import { useUpdateDemographicAttributeOptionIds } from "../hooks/use-update-demographic-attribute-option-ids"
import { useUpdateLocationsFilter } from "../hooks/use-update-locations-filter"
import { useUpdateSourcesFilter } from "../hooks/use-update-sources-filter"
import { useUpdateVariableFilter } from "../hooks/useUpdateVariableFilter"
import {
  getAgeRangeOptionFromResponses,
  getAgeRangeValueFromResponse,
} from "../utils/age-ranges"
import { getEnhancedOptions } from "../utils/get-options"
import { useLocationsFromResponses } from "../utils/locations"
import {
  getSourceValueFromResponse,
  getSourcesOptionFromResponses,
} from "../utils/sources"
import { FILTER_UNKNOWN } from "../utils/unknown"

const useDemographicAttributesForResponses = (
  responses: Readonly<Response[]>
): DemographicAttribute[] => {
  const { demographics } = useContext(DemographicsContext)

  // Flat map out the demographic attributes
  const demographicAttributes = demographics.flatMap(
    (demographicAttributeGroup) =>
      demographicAttributeGroup.demographic_attributes
  )

  // Get all relevant demographic attribute option IDs from the responses
  const allDemographicAttributeOptionIdsForResponses = responses.flatMap(
    (response) =>
      response.response_demographic_profile?.demographic_attribute_option_ids
  )

  // Filter to the ones related to the demographic attribute option IDs
  return demographicAttributes
    .map((demographicAttribute) => {
      const demographicAttributeOptionIds = demographicAttribute.options.map(
        (demographicAttributeOption) => demographicAttributeOption.id
      )

      const validDemographicAttributeOptionIds = intersection(
        allDemographicAttributeOptionIdsForResponses,
        demographicAttributeOptionIds
      )

      // Keep attributes with any matching option IDs we keep it
      return {
        ...demographicAttribute,
        options: demographicAttribute.options.filter(({ id }) =>
          validDemographicAttributeOptionIds.includes(id)
        ),
      }
    })
    .filter((demographicAttribute) => demographicAttribute.options.length !== 0)
}

const TARGET_COLLAPSED_COUNT = 3

export const TestResultsFiltersForm: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const orders = useSelector(getAllOrders)

  const handleLocationsChange = useUpdateLocationsFilter()
  const handleAgeRangeChange = useUpdateAgeRangeFilter()
  const handleSourcesChange = useUpdateSourcesFilter()

  const { getLocationOptionFromResponses, getLocationValueFromResponse } =
    useLocationsFromResponses()

  // Get relevant options based on response data
  const allResponses = useSelector(getAllResponses)
  const countryOptions = getLocationOptionFromResponses(allResponses)
  const ageRangeOptions = getAgeRangeOptionFromResponses(allResponses)
  const sourceOptions = getSourcesOptionFromResponses(orders, allResponses)

  // Enhance the options with counts and filteredCounts
  const isTestResultsFilterEmpty = useIsTestResultsFiltersEmpty()
  const filteredResponses = useFilteredResponses()
  const testResultFilters = useTestResultsFilters()

  const enhancedLocationOptions = getEnhancedOptions(
    countryOptions,
    allResponses,
    filteredResponses,
    getLocationValueFromResponse,
    { showFilteredCount: !isTestResultsFilterEmpty }
  )

  const enhancedAgeRangeOptions = getEnhancedOptions(
    ageRangeOptions,
    allResponses,
    filteredResponses,
    getAgeRangeValueFromResponse,
    { showFilteredCount: !isTestResultsFilterEmpty }
  )

  const enhancedSourceOptions = getEnhancedOptions(
    sourceOptions,
    allResponses,
    filteredResponses,
    (r) => getSourceValueFromResponse(orders, r),
    { showFilteredCount: !isTestResultsFilterEmpty }
  )

  // Get relevant demographic attributes based on response data
  //
  // TODO: These still contain options that don't match any responses, should
  // these be filtered out here?
  const demographicAttributes =
    useDemographicAttributesForResponses(allResponses)

  const locationsFilterValue = getFiltersWithCategory(
    testResultFilters,
    "response/attribute:location"
  ).map((filter) => filter.value ?? FILTER_UNKNOWN)

  const ageFilterValue = getFiltersWithCategory(
    testResultFilters,
    "response/attribute:age"
  ).map((filter) => {
    if (filter.value) {
      return (filter.value.min ?? Number.NEGATIVE_INFINITY).toString()
    }

    return FILTER_UNKNOWN
  })

  const sourceFilterValue = getFiltersWithCategory(
    testResultFilters,
    "response/attribute:source"
  ).map((filter) => filter.value)

  return (
    <>
      <AnswerFilterSection />
      <ClearableCheckboxListSection
        title="Location"
        options={enhancedLocationOptions}
        targetCollapsedCount={TARGET_COLLAPSED_COUNT}
        value={locationsFilterValue}
        onChange={handleLocationsChange}
      />
      <ClearableCheckboxListSection
        title="Age range"
        options={enhancedAgeRangeOptions}
        targetCollapsedCount={TARGET_COLLAPSED_COUNT}
        value={ageFilterValue}
        onChange={handleAgeRangeChange}
      />
      {demographicAttributes.map((demographicAttribute) => (
        <DemographicAttributeFilter
          key={demographicAttribute.id}
          filters={testResultFilters}
          demographicAttribute={demographicAttribute}
          targetCollapsedCount={TARGET_COLLAPSED_COUNT}
        />
      ))}
      <ClearableCheckboxListSection
        title="Sources"
        options={enhancedSourceOptions}
        targetCollapsedCount={TARGET_COLLAPSED_COUNT}
        value={sourceFilterValue}
        onChange={handleSourcesChange}
      />

      <VariableFilters filters={testResultFilters} />
    </>
  )
}

const getDemographicAttributeOptionsFromDemographic = (
  demographicAttribute: DemographicAttribute
): ReactOption<string>[] => [
  ...demographicAttribute.options.map(({ id, value }) => ({
    value: String(id),
    label: value,
  })),
  {
    value: FILTER_UNKNOWN,
    label: "Unknown",
  },
]

interface DemographicAttributeFilterProps {
  filters: ResponseFilter[]
  demographicAttribute: DemographicAttribute
  targetCollapsedCount: number
}

// TODO: don't pass in the whole `demographicFilters`
const DemographicAttributeFilter: React.FC<DemographicAttributeFilterProps> = ({
  demographicAttribute,
  targetCollapsedCount,
  filters,
}) => {
  const allResponses = useSelector(getAllResponses)
  const filteredResponses = useFilteredResponses()
  const updateDemographicAttributeOptionIds =
    useUpdateDemographicAttributeOptionIds(
      demographicAttribute.code,
      demographicAttribute.options.map((option) => option.id)
    )

  const isTestResultsFilterEmpty = useIsTestResultsFiltersEmpty()

  const options =
    getDemographicAttributeOptionsFromDemographic(demographicAttribute)

  // Returns a list of matching DemographicAttributeOption IDs that are on the
  // given Response for the given DemographicAttribute
  const getDemographicAttributeOptionValueFromResponse = (
    response: Response
  ) => {
    // DemographicAttributeOption IDs on the response
    const demographicAttributeOptionIds = demographicAttribute.options.map(
      ({ id }) => id
    )

    // DemographicAttributeOption IDs on the response that match the ones on
    // the DemographicAttribute
    const matchingDemographicAttributeOptionIdsOnResponse = intersection(
      response.response_demographic_profile?.demographic_attribute_option_ids ??
        [],
      demographicAttributeOptionIds
    ).map(String)

    // If we didn't find any matching ones, we group this response into the
    // unknown bucket
    return matchingDemographicAttributeOptionIdsOnResponse.length === 0
      ? FILTER_UNKNOWN
      : matchingDemographicAttributeOptionIdsOnResponse
  }

  const enhancedOptions = getEnhancedOptions(
    options,
    allResponses,
    filteredResponses,
    getDemographicAttributeOptionValueFromResponse,
    { showFilteredCount: !isTestResultsFilterEmpty }
  )

  const demographicAttributeFilters = getFiltersWithCategory(
    filters,
    createDemographicAttributeOptionIdFilterCategory(demographicAttribute.code)
  )

  const value = demographicAttributeFilters.map((filter) => {
    if (filter.type === "attribute/unknown-demographic-attribute-option") {
      return FILTER_UNKNOWN
    }

    return filter.value.toString()
  })

  return (
    <ClearableCheckboxListSection
      title={demographicAttribute.name}
      options={enhancedOptions}
      targetCollapsedCount={targetCollapsedCount}
      value={value}
      onChange={(values: string[]) =>
        updateDemographicAttributeOptionIds(values)
      }
    />
  )
}

type VariableFiltersProps = {
  filters: ResponseFilter[]
}

const VariableFilters: React.FC<VariableFiltersProps> = ({ filters }) => {
  const allResponses = useSelector(getAllResponses)

  const variablesUsed: Record<string, string[]> = {}

  allResponses.forEach((response) =>
    Object.entries(response.query_parameters ?? {}).forEach(([key, value]) => {
      variablesUsed[key] ||= []

      if (!variablesUsed[key].includes(value)) variablesUsed[key].push(value)
    })
  )

  return Object.entries(variablesUsed).map(([variable, possibleValues]) => {
    return (
      <VariableFilter
        key={variable}
        variable={variable}
        possibleValues={possibleValues}
        filters={filters}
      />
    )
  })
}

type VariableFilterProps = {
  variable: string
  possibleValues: string[]
  filters: ResponseFilter[]
}

const VariableFilter: React.FC<VariableFilterProps> = ({
  variable,
  possibleValues,
  filters,
}) => {
  const allResponses = useSelector(getAllResponses)
  const filteredResponses = useFilteredResponses()
  const isTestResultsFilterEmpty = useIsTestResultsFiltersEmpty()
  const updateVariableFilter = useUpdateVariableFilter(variable)

  const options = [
    ...possibleValues.map((option) => ({
      value: option,
      label: option,
    })),
  ]

  const getVariableValueFromResponse = (response: Response) => {
    const value = response.query_parameters?.[variable]

    return value ?? FILTER_UNKNOWN
  }

  const enhancedOptions = getEnhancedOptions(
    options,
    allResponses,
    filteredResponses,
    getVariableValueFromResponse,
    { showFilteredCount: !isTestResultsFilterEmpty }
  )

  const variableFilters = getFiltersWithCategory(
    filters,
    createVariableFilterCategory(variable)
  )

  const value = variableFilters.map((filter) => {
    return filter.value.toString()
  })

  return (
    <ClearableCheckboxListSection
      title={variable}
      options={enhancedOptions}
      targetCollapsedCount={TARGET_COLLAPSED_COUNT}
      value={value}
      onChange={updateVariableFilter}
    />
  )
}
