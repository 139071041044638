import { Icon, useToast } from "@chakra-ui/react"
import { CheckCircleIcon } from "@heroicons/react/outline"
import { AppContext } from "Shared/contexts/AppContext"
import { useCallback, useContext, useEffect, useState } from "react"
import React from "react"
import {
  useApplyDynamicTemplate,
  useCheckDynamicTemplateExists,
} from "~/api/generated/usabilityhub-components"
import { Template } from "~/usabilityhub/types/templateLibrary"

type ApplyTemplateResult = {
  uniqueId: string
}

type Message =
  | {
      action: "complete"
      data: {
        status: 200
        usability_test_id: string
      }
    }
  | {
      action: "failed"
      data: {
        status: 500
        error: string
      }
    }

export const useApplyTemplate = (template: Template) => {
  const { consumer } = useContext(AppContext)

  const toast = useToast()

  const { isLoading: loading, isError } = useCheckDynamicTemplateExists(
    {
      pathParams: { id: template.localTemplateId },
    },
    {
      retry: false,
    }
  )

  const available = !loading && !isError

  const [jobId, setJobId] = useState<string | null>(null)

  const [resolve, setResolve] = useState<(value: ApplyTemplateResult) => void>()

  const [reject, setReject] = useState<(reason?: any) => void>()

  const { mutateAsync } = useApplyDynamicTemplate()

  const apply = useCallback(
    () =>
      new Promise<ApplyTemplateResult>((resolve, reject) => {
        setJobId(null)

        if (!template || !available) {
          reject()
          return
        }

        // Request the job to apply the template
        // Returns a promise that resolves when the template has been applied
        // so that we can capture it all as one action
        mutateAsync(
          {
            pathParams: { id: template.localTemplateId },
            body: { slug: template.id },
          },
          {
            onSuccess: (result) => {
              if (result.job_id) {
                setJobId(result.job_id)
                setResolve(() => resolve)
                setReject(() => reject)
              } else {
                reject("Could not start job")
              }
            },
            onError: reject,
          }
        )
      }),
    [template, available]
  )

  useEffect(() => {
    if (jobId) {
      const identifier = {
        channel: "ApplyDynamicTemplateChannel",
        job_id: jobId,
      }

      const channel = consumer?.subscriptions.create(identifier, {
        received: (message: Message) => {
          if (message.action === "complete") {
            toast({
              description: `A copy of ‘${template.title}’ was created`,
              status: "success",
              title: "Template applied",
              icon: <Icon as={CheckCircleIcon} />,
            })
            resolve?.({ uniqueId: message.data.usability_test_id })
          } else {
            reject?.(message.data.error)
          }
        },
      })

      return () => {
        channel?.unsubscribe()
      }
    }
  }, [jobId, consumer, resolve, reject])

  return { loading, available, apply, applying: !!jobId }
}
