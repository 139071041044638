// JsFromRoutes CacheKey aad17f3dfa9958cc346c2471debd3e15
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/api/subscriptions'),
  get: /* #__PURE__ */ definePathHelper('get', '/api/subscriptions/:guid'),
  update: /* #__PURE__ */ definePathHelper('patch', '/api/subscriptions/:guid'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/api/subscriptions/:guid'),
}
