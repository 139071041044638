import { Box, Flex, Switch, Text } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import { ScreenerForm } from "Shared/components/ScreenerForm/ScreenerForm"
import { SCREENER_QUESTION_TYPES } from "Shared/components/ScreenerForm/constants"
import React, { ChangeEvent } from "react"
import { Card } from "./Card"
import { useStudyDetails } from "./StudyDetailsProvider"

export const Screener: React.FC = () => {
  const {
    separatePages,
    isScreenerEnabled,
    onScreenerToggle,
    screenerQuestions,
    appendScreenerQuestion,
    updateScreenerQuestion,
    removeScreenerQuestion,
    duplicateScreenerQuestion,
    setFormScreenerQuestions,
  } = useStudyDetails()

  const switcher = (
    <Switch
      isChecked={isScreenerEnabled}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        onScreenerToggle(event.target.checked)
      }
      data-qa="screener-toggle-switch"
    />
  )

  const content = isScreenerEnabled && screenerQuestions.length > 0 && (
    <ScreenerForm
      screenerQuestions={screenerQuestions}
      appendScreenerQuestion={appendScreenerQuestion}
      removeScreenerQuestion={(id) => {
        removeScreenerQuestion(id)
        // After removing a question, we need to remove the associated form state too
        setFormScreenerQuestions((oldQuestions) => {
          return oldQuestions.filter((q) => q.questionId !== id)
        })
      }}
      updateScreenerQuestion={updateScreenerQuestion}
      duplicateScreenerQuestion={duplicateScreenerQuestion}
      setFormScreenerQuestions={setFormScreenerQuestions}
      renderFormElement
      validQuestionTypes={[...SCREENER_QUESTION_TYPES]}
      readOnly={false}
      buttonAlignment={separatePages ? "start" : "end"}
      padding={separatePages ? 0 : 6}
    />
  )

  return separatePages ? (
    <Card.Root>
      <Card.Header title="Screener">{switcher}</Card.Header>
      <Box px={6}>
        <Text as="p" textStyle="ds.paragraph.primary">
          Add screener questions to qualify participants based on certain
          targeting criteria. These apply to both your own participants and
          those from the Lyssna panel.
        </Text>
      </Box>
      <Card.Body mt={content ? 6 : undefined}>{content}</Card.Body>
    </Card.Root>
  ) : (
    <Box
      id="screener"
      bg="white"
      rounded="lg"
      borderColor="gray.200"
      borderWidth="thin"
      py="8"
    >
      <Flex justify="space-between" px={8}>
        <Heading as="h3" textStyle="ds.display.primary">
          Screener
        </Heading>
        {switcher}
      </Flex>

      <Box mt={6}>{content}</Box>
    </Box>
  )
}
