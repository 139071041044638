import { Flex, Link, Spacer, Stack } from "@chakra-ui/react"
import React from "react"

import { AdminPhoneFraudStatus } from "./AdminPhoneFraudStatus"
import AdminPhoneInfo, { PhoneInfo } from "./AdminPhoneInfo"

interface Props {
  phoneNumber: string
  phoneVerifications: Omit<PhoneInfo, "userCountry" | "highRiskCountryCodes">[]
  userCountry: string
  highRiskCountryCodes: string[]
  phoneNumberRequiredForPanel: boolean
  togglePhoneVerificationRequirement: string
}

export const AdminRiskFactorsPhoneVerifications: React.FC<Props> = ({
  phoneNumber,
  phoneVerifications,
  userCountry,
  highRiskCountryCodes,
  phoneNumberRequiredForPanel,
  togglePhoneVerificationRequirement,
}) => {
  const currentPhoneVerificaiton = phoneVerifications.find(
    (phone) => phone.phoneNumber === phoneNumber
  )

  return (
    <table className="AdminTable AdminTable--attributes">
      <tbody>
        <tr>
          <td>Phone number</td>
          {currentPhoneVerificaiton ? (
            <>
              <td>
                <AdminPhoneInfo
                  {...currentPhoneVerificaiton}
                  userCountry={userCountry}
                  highRiskCountryCodes={highRiskCountryCodes}
                />
              </td>
              <td className="AdminTable-tag">
                <AdminPhoneFraudStatus
                  {...currentPhoneVerificaiton}
                  userCountry={userCountry}
                  highRiskCountryCodes={highRiskCountryCodes}
                />
              </td>
            </>
          ) : (
            <>
              <td>
                {phoneNumber
                  ? phoneNumber
                  : phoneNumberRequiredForPanel
                    ? "Awaiting verification"
                    : "Verification not required"}
                {!phoneNumber && (
                  <>
                    {" ("}
                    <Link
                      href={togglePhoneVerificationRequirement}
                      data-method="PUT"
                      textDecoration="none"
                    >
                      {phoneNumberRequiredForPanel
                        ? `don${"\u2019"}t require`
                        : "require"}
                    </Link>
                    {")"}
                  </>
                )}
              </td>

              <td className="AdminTable-tag">
                <span className="FraudStatus FraudStatus--warn" />
              </td>
            </>
          )}
        </tr>
        <tr>
          <td>Verification history</td>
          <td colSpan={2}>
            <Stack maxH="120px" overflowY="auto">
              {phoneVerifications.map((phoneVerification) => (
                <Flex key={`${phoneVerification.id}`} justify="space-between">
                  <AdminPhoneInfo
                    {...phoneVerification}
                    userCountry={userCountry}
                    highRiskCountryCodes={highRiskCountryCodes}
                  />
                  <Spacer w={2} />
                  <AdminPhoneFraudStatus
                    {...phoneVerification}
                    userCountry={userCountry}
                    highRiskCountryCodes={highRiskCountryCodes}
                  />
                </Flex>
              ))}
            </Stack>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
