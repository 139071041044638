import { Box } from "@chakra-ui/react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import React from "react"

import { Card, Props as CardProps } from "./Card"
import { useCardSortContext } from "./CardSortContext"

type Props = {
  beingDragged: boolean
} & CardProps
export const DraggableCard: React.FC<Props> = ({ card, ...rest }) => {
  const { active } = useCardSortContext()
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: card.id!, disabled: !active })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    touchAction: "manipulation",
  }

  return (
    <Box
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
      visibility={isDragging ? "hidden" : "visible"}
    >
      <Card card={card} {...rest} />
    </Box>
  )
}
