import { useEffect } from "react"

export const useArrowKeys = (
  isEnabled: boolean,
  {
    onLeftArrow,
    onRightArrow,
    onUpArrow,
    onDownArrow,
  }: {
    onLeftArrow?: () => void
    onRightArrow?: () => void
    onUpArrow?: () => void
    onDownArrow?: () => void
  }
) => {
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (onLeftArrow && e.key === "ArrowLeft") {
        onLeftArrow()
        e.preventDefault()
      }
      if (onRightArrow && e.key === "ArrowRight") {
        onRightArrow()
        e.preventDefault()
      }
      if (onUpArrow && e.key === "ArrowUp") {
        onUpArrow()
        e.preventDefault()
      }
      if (onDownArrow && e.key === "ArrowDown") {
        onDownArrow()
        e.preventDefault()
      }
    }

    if (isEnabled) {
      window.addEventListener("keydown", handleKeydown)
    }

    return () => {
      window.removeEventListener("keydown", handleKeydown)
    }
  }, [isEnabled, onLeftArrow, onRightArrow, onUpArrow, onDownArrow])
}
