import { Tooltip } from "@chakra-ui/react"
import React from "react"

import { Badge, BadgeProps } from "DesignSystem/components/Badge"
import { useTaskGoalNodeId } from "../../hooks/use-goal-node-id"
import { useIsPrototypeFreeRoam } from "../../hooks/use-prototype-type"

interface GoalScreenBadgeProps {
  goalScreenHasBeenReached: boolean
}

export function GoalScreenBadge({
  goalScreenHasBeenReached,
  ...props
}: GoalScreenBadgeProps & Omit<BadgeProps, "label">) {
  const goalNodeId = useTaskGoalNodeId()
  const prototypeIsFreeRoam = useIsPrototypeFreeRoam()

  if (prototypeIsFreeRoam) {
    return null
  }

  if (!goalNodeId) {
    return <Badge variant="subtle" {...props} label="No goal screen set" />
  }

  if (!goalScreenHasBeenReached) {
    return (
      <Tooltip
        hasArrow
        placement="top"
        label={`Participants who took this path and didn${"\u2019"}t reach the goal screen`}
      >
        <Badge
          variant="subtle"
          {...props}
          label={<>Didn{"\u2019"}t reach goal screen</>}
        />
      </Tooltip>
    )
  }

  return (
    <Tooltip
      hasArrow
      placement="top"
      label="Participants who took this path to reach the goal screen"
    >
      <Badge
        variant="subtle"
        colorScheme="success"
        {...props}
        label="Reached goal screen"
      />
    </Tooltip>
  )
}
