import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { SubmitButton } from "Components/button/submit-button"
import { JsForm } from "Components/form/form"
import { ReportUserModal } from "Components/report-user-modal/report-user-modal"
import { Dispatch, State } from "Redux/app-store"
import { deleteResponse } from "Redux/reducers/test-results/action-creators"
import {
  getOrderForResponseId,
  getResponseOrNull,
} from "Redux/reducers/test-results/selectors"
import { OwnerDeletionReason as Reason, Response } from "Types"
import { FunctionalModal } from "Utilities/modals/types"
import { useModal } from "Utilities/modals/use-modal"
import { isPanelOrdered, isThirdPartyOrdered } from "Utilities/response"

interface Props {
  onSuccess?: () => void
  responseId: number
}

export const DeleteResponseModal: FunctionalModal<Props> = ({
  responseId,
  onClose,
  onSuccess,
}) => {
  const dispatch = useDispatch<Dispatch>()

  const response = useSelector<State, Response | null>((state) =>
    getResponseOrNull(state, responseId)
  )

  const withinReviewPeriod = useSelector((state: State) => {
    const order = getOrderForResponseId(state, responseId)
    return order === null || !order.has_response_review_period_elapsed
  })

  const eligibleForReplacement =
    response !== null && isPanelOrdered(response) && withinReviewPeriod
  const { open: openReportUserModal } = useModal(ReportUserModal)
  const onReport = () => {
    onClose()
    setTimeout(() => {
      openReportUserModal({ responseId, onSuccess: onClose })
    }, 400)
  }

  const [reason] = useState<Reason>(Reason.Unspecified)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const toast = useToast()

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      await dispatch(deleteResponse(responseId, reason))
      onSuccess?.()
      onClose()
    } catch (error) {
      toast({
        title: "Something went wrong",
        description: "Please try again.",
        status: "error",
        position: "top-right",
      })
      setIsSubmitting(false)
    }
  }

  // After the response is deleted this thing doesn't make sense. This should
  // be prevented by `shouldComponentUpdate`.
  if (response === null) return null

  return (
    <Modal isOpen size="xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <JsForm onSubmit={handleSubmit}>
          <ModalHeader>
            <Text>Delete response</Text>
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Text>
                This is a response from{" "}
                {isPanelOrdered(response)
                  ? "the Lyssna panel"
                  : isThirdPartyOrdered(response)
                    ? "a third party panel"
                    : "a participant you recruited"}
                .
              </Text>
              <Text>
                It will be permanently removed from your test results. Are you
                sure you want to delete it?
              </Text>
              {eligibleForReplacement ? (
                <Alert status="info">
                  <AlertIcon />
                  <AlertDescription>
                    Was there a problem with the quality of this response?
                    You're eligible for a free automatic replacement if you'd
                    like to
                    <Link onClick={onReport}>
                      {" "}
                      report this response instead.
                    </Link>
                  </AlertDescription>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <SubmitButton colorScheme="red" isLoading={isSubmitting}>
                Delete response
                {eligibleForReplacement ? " without reporting" : null}
              </SubmitButton>
            </HStack>
          </ModalFooter>
        </JsForm>
      </ModalContent>
    </Modal>
  )
}
