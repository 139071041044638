import { createIcon } from "@chakra-ui/react"
import React from "react"

export const CreateStudyInterviewIcon = createIcon({
  displayName: "CreateStudyInterviewIcon",
  viewBox: "0 0 48 48",
  path: (
    <>
      <rect width="48" height="48" rx="6" fill="#DAF9FF" />
      <path
        d="M33.585 11.401c-1.412-.154-4.26.116-5.544 2.657-.762 1.95-1.212 6.013 2.464 7.225 1.309.59 4.522 1.16 6.904-1.283.911-1.117 1.31-3.414.693-5.262-.23-1.06-1.642-3.172-4.517-3.337ZM34.745 33.517c1.785 0 5.438-.255 7.307-1.87-.032-.37-.055-.755-.08-1.149-.145-2.43-.311-5.181-2.724-6.497-2.311-.805-7.615-1.316-11.658-.485 1.606.522 3.858 1.512 5.424 3.929 1.333 2.056 1.731 3.645 1.731 6.072Z"
        fill="#008CAB"
      />
      <path
        d="M22.11 22.196c-.987.447-5.307 2.406-9.562-1.445-1.456-1.808-3.385-6.467.542-10.638 1.498-1.614 4.62-1.947 5.995-1.912 6.792.901 7.55 5.738 7.08 8.043-.174 2.746-2.64 5.057-3.852 5.87-.02 0-.09.03-.203.082ZM16.516 24.552c-4.362.33-7.647.58-9.613 3.907-.822 1.39-1.607 6.552-.423 10.271 8.157 2.114 21.205.761 26.406-1.135.223-4.317.053-7.994-2.356-10.826-2.152-2.15-6.829-2.343-12.427-2.343-.544.046-1.073.086-1.587.126Z"
        fill="#5BD6F1"
      />
    </>
  ),
})
