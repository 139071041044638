import { Button, Flex, Stack, Textarea } from "@chakra-ui/react"
import React, { useState } from "react"

import { AdminNote } from "Types"

interface Props {
  note: AdminNote
  onSave: (note: AdminNote) => void
  onCancel: () => void
}
export const NoteEditor: React.FC<Props> = ({ note, onSave, onCancel }) => {
  const [text, setText] = useState(note.text)

  return (
    <Stack>
      <Textarea
        fontSize="sm"
        fontWeight="normal"
        rows={5}
        value={text}
        bg="white"
        onChange={(e) => setText(e.target.value)}
      />
      <Flex justify="flex-end">
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          colorScheme="brand.primary"
          ms={3}
          onClick={() =>
            onSave({
              ...note,
              text,
            })
          }
        >
          Save
        </Button>
      </Flex>
    </Stack>
  )
}
