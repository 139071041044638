import { createIcon } from "@chakra-ui/react"
import React from "react"

// This isn't actually an official UntitledUI icon!

export const ThinkAloudOutlineIcon = createIcon({
  displayName: "ThinkAloudOutlineIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        d="M10.0001 4C8.06709 4 6.50008 5.567 6.50008 7.5C6.50008 9.433 8.06709 11 10.0001 11C11.9331 11 13.5001 9.433 13.5001 7.5C13.5001 5.567 11.9331 4 10.0001 4ZM4.50008 7.5C4.50008 4.46243 6.96252 2 10.0001 2C13.0376 2 15.5001 4.46243 15.5001 7.5C15.5001 10.5376 13.0376 13 10.0001 13C6.96252 13 4.50008 10.5376 4.50008 7.5ZM7.32635 14.5C7.38341 14.5 7.44132 14.5 7.50009 14.5H12.5001C12.5589 14.5 12.6168 14.5 12.6738 14.5C13.9011 14.4995 14.7391 14.4992 15.4515 14.7153C17.0495 15.2 18.3 16.4506 18.7848 18.0486C19.0009 18.761 19.0006 19.599 19.0001 20.8263C19.0001 20.8833 19.0001 20.9412 19.0001 21C19.0001 21.5523 18.5524 22 18.0001 22C17.4478 22 17.0001 21.5523 17.0001 21C17.0001 19.5317 16.9892 19.0192 16.8709 18.6291C16.5801 17.6703 15.8297 16.92 14.8709 16.6292C14.4809 16.5108 13.9684 16.5 12.5001 16.5H7.50009C6.03177 16.5 5.51929 16.5108 5.12923 16.6292C4.17042 16.92 3.42011 17.6703 3.12926 18.6291C3.01094 19.0192 3.00008 19.5317 3.00008 21C3.00008 21.5523 2.55237 22 2.00008 22C1.4478 22 1.00008 21.5523 1.00008 21C1.00008 20.9412 1.00006 20.8833 1.00004 20.8263C0.999587 19.599 0.999277 18.761 1.21538 18.0486C1.70013 16.4506 2.95065 15.2 4.54866 14.7153C5.26106 14.4992 6.09908 14.4995 7.32635 14.5Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M20.39 1.11127C20.8809 0.85831 21.484 1.05123 21.7369 1.54218C22.5396 3.09989 23.0002 4.98504 23.0002 7.00021C23.0002 9.01538 22.5396 10.9005 21.7369 12.4582C21.484 12.9492 20.8809 13.1421 20.39 12.8891C19.899 12.6362 19.7061 12.0331 19.9591 11.5422C20.6067 10.2853 21.0002 8.71699 21.0002 7.00021C21.0002 5.28343 20.6067 3.71515 19.9591 2.45824C19.7061 1.9673 19.899 1.36424 20.39 1.11127ZM17.5533 3.2485C18.0473 3.00166 18.648 3.20208 18.8948 3.69613C19.3728 4.65289 19.6421 5.79206 19.6421 7.00021C19.6421 8.20836 19.3728 9.34752 18.8948 10.3043C18.648 10.7983 18.0473 10.9988 17.5533 10.7519C17.0592 10.5051 16.8588 9.90447 17.1057 9.41042C17.4369 8.74742 17.6421 7.9167 17.6421 7.00021C17.6421 6.08372 17.4369 5.253 17.1057 4.59C16.8588 4.09594 17.0592 3.49533 17.5533 3.2485Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </>
  ),
})
