// contains ScreenerQuestion['type']
// derived from
// https://github.com/wearelyssna/hub/blob/68f64fcf7d4e27ad50105b14887a34879c7fe37d/db/structure.sql#L836
export const SCREENER_QUESTION_TYPES = [
  "long_text",
  "short_text",
  "single_select",
  "multi_select",
] as const

// contains ScreenerQuestion['option'][0]['rule']
// derived from
// https://github.com/wearelyssna/hub/blob/68f64fcf7d4e27ad50105b14887a34879c7fe37d/db/structure.sql#L825
export const SCREENER_QUESTION_OPTION_RULE = [
  "qualify",
  "disqualify",
  "not_relevant",
] as const
