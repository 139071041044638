import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import _ from "lodash"
import React from "react"
import { LoadingForm } from "./LoadingForm"
import { useOnboardingContext } from "./OnboardingContextProvider"
import { SelectableButton } from "./SelectableButton"
import { ResearchGoal, ResearchGoalsStep } from "./types"
import { useOnboardingStep } from "./useOnboardingStep"

type ResearchGoalsFormProps = {
  step: ResearchGoalsStep
}

const _Goal = (str: string) => ({ id: str as ResearchGoal, label: str })

const GOALS: { id: ResearchGoal; label: string }[] = [
  _Goal("Set up user interviews"),
  _Goal("Conduct usability tests"),
  _Goal("Improve IA"),
  _Goal("Validate concepts"),
  _Goal("Test messaging"),
  _Goal("Discovery research"),
  _Goal("Conversion rate optimization"),
  _Goal("Collect survey results"),
  _Goal("Test Figma prototypes"),
  _Goal("Get design feedback"),
]

export const ResearchGoalsForm: React.FC<ResearchGoalsFormProps> = ({
  step,
}) => {
  const { submit } = useOnboardingContext()

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useOnboardingStep(step)

  const goals = watch("researchGoals")

  return (
    <Stack
      as="form"
      id="onboarding_form"
      spacing={6}
      onSubmit={handleSubmit(submit)}
    >
      <LoadingForm>
        <Heading
          as="h1"
          textStyle="ds.display.emphasized"
          color="ds.text.default"
        >
          What interests you?
        </Heading>

        <Text textStyle="ds.paragraph.emphasized" color="ds.text.default">
          Help us tailor your Lyssna experience by telling us what interests you
          — select as many options as you like.
        </Text>

        <FormControl isInvalid={!!errors.researchGoals || undefined}>
          <HStack flexWrap="wrap" gap={2}>
            {GOALS.map(({ id, label }) => (
              <SelectableButton
                key={id}
                id={id}
                multiple
                isSelected={goals.includes(id) || undefined}
                {...register("researchGoals")}
              >
                {label}
              </SelectableButton>
            ))}
          </HStack>
          {errors.researchGoals && (
            <FormErrorMessage>{errors.researchGoals.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!errors.researchNeeds || undefined}>
          <FormLabel>
            <Text textStyle="ds.heading.primary" color="ds.text.default">
              Don{"\u2019"}t see what you’re looking for?
            </Text>
          </FormLabel>

          <Input
            type="text"
            placeholder="Describe your research needs"
            {...register("researchNeeds")}
          />
          {errors.researchNeeds && (
            <FormErrorMessage>{errors.researchNeeds.message}</FormErrorMessage>
          )}
        </FormControl>
      </LoadingForm>
    </Stack>
  )
}
