import {
  Badge,
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  HStack,
  Heading,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import { PlaySolidIcon } from "Shared/icons/untitled-ui/PlaySolidIcon"
import { StopSolidIcon } from "Shared/icons/untitled-ui/StopSolidIcon"
import { format, isFuture } from "date-fns"
import { first, orderBy } from "lodash"
import React, { useContext, useMemo } from "react"
import { ApiContext } from "./ApiProvider"
import { VariantRow } from "./VariantRow"
import { COLORS, Experiment } from "./types"

type ExperimentRowProps = {
  experiment: Experiment
}

export const ExperimentRow: React.FC<ExperimentRowProps> = ({ experiment }) => {
  const { startExperiment, finishExperiment, pinVariant } =
    useContext(ApiContext)

  const winner = useMemo(
    () =>
      first(
        orderBy(
          experiment.variants.filter((v) => v.statistics.confidence),
          ["statistics.confidence"],
          ["desc"]
        )
      ),
    [experiment]
  )

  const other = useMemo(
    () => experiment.variants.find((v) => v.name !== "control"),
    [experiment]
  )

  const running =
    !!experiment.started_at &&
    (!experiment.finished_at || isFuture(experiment.finished_at))

  return (
    <Card>
      <CardHeader
        display="grid"
        gridTemplateColumns="1fr auto"
        gridTemplateRows="auto auto auto"
        alignItems="center"
      >
        <Heading
          as="h3"
          fontFamily="monospace"
          mb={2}
          gridColumn={1}
          gridRow={1}
        >
          {experiment.name}
        </Heading>
        <Text color="text.subtlest" gridColumn={1} gridRow={2}>
          {experiment.description}
        </Text>
        <Text color="text.subtlest" gridColumn={1} gridRow={3}>
          {running
            ? `Running since ${format(
                experiment.started_at!,
                "h:mmaaa, d/M/yyyy"
              )}`
            : experiment.finished_at
              ? `Stopped at ${format(
                  experiment.finished_at!,
                  "h:mmaaa, d/M/yyyy"
                )}`
              : "Not started"}
        </Text>
        <ButtonGroup
          gridColumn={2}
          gridRow={1}
          w="10.5rem"
          sx={{
            button: {
              flex: "0 0 2.5rem",
              overflow: "hidden",
              transition: "flex-grow 0.2s ease-in-out",
              justifyContent: "start",
              pl: 3,
              "span:not(:has(svg))": {
                opacity: 0,
                transition: "opacity 0.2s ease-in-out",
              },
              "&[aria-pressed=true]": {
                flexGrow: 1,
                span: {
                  opacity: 1,
                },
              },
            },
          }}
        >
          <Button
            variant="solid"
            colorScheme="green"
            rounded="full"
            leftIcon={<PlaySolidIcon />}
            aria-pressed={running}
            onClick={() => startExperiment(experiment)}
          >
            <span>Running</span>
          </Button>
          <Button
            variant="solid"
            colorScheme="red"
            rounded="full"
            leftIcon={<StopSolidIcon />}
            aria-pressed={!running}
            onClick={() => finishExperiment(experiment)}
          >
            <span>Stopped</span>
          </Button>
        </ButtonGroup>
      </CardHeader>
      <Divider color="border.default" />
      <CardBody bg="bg.surface.sunken" overflowX="auto">
        <HStack alignItems="center" spacing={6}>
          <VariantGraph
            experiment={experiment}
            w={32}
            height={32}
            flex="0 0 auto"
          />
          <Stack
            flex={1}
            divider={<StackDivider borderColor="brandPalette.neutral.mid" />}
            spacing={4}
          >
            {experiment.variants.map((variant, i) => (
              <VariantRow
                key={variant.name}
                variant={variant}
                pinned={experiment.pinned === variant.name}
                hidden={
                  !!experiment.pinned && experiment.pinned !== variant.name
                }
                color={COLORS[i]}
                onPin={(variant) => pinVariant(experiment, variant)}
              />
            ))}
          </Stack>
        </HStack>
      </CardBody>
      <Divider color="border.default" />
      <CardFooter>
        {winner ? (
          <Text>
            It looks like the{" "}
            <Badge
              fontFamily="monospace"
              fontSize="1rem"
              colorScheme={
                COLORS[
                  experiment.variants.findIndex((v) => v.name === winner.name)
                ]
              }
              textTransform="none"
            >
              {winner.name}
            </Badge>{" "}
            variant is significantly better than the control. You should
            consider making it the default.
          </Text>
        ) : (
          <Text>
            There is not enough evidence to suggest that{" "}
            {experiment.variants.length === 2 ? (
              <>
                the <code>{other?.name}</code> variant
              </>
            ) : (
              "any of the variants"
            )}{" "}
            is outperforming the control. Try running your experiment for
            longer.
          </Text>
        )}
      </CardFooter>
    </Card>
  )
}

const VariantGraph = ({
  experiment,
  ...props
}: BoxProps & { experiment: Experiment }) => {
  const gradient = useMemo(() => {
    const totalVisitors = experiment.variants.reduce(
      (acc, variant) => acc + (variant.statistics.visitors ?? 0),
      0
    )
    const percentages = experiment.variants.map(
      (variant) => ((variant.statistics.visitors ?? 0) / totalVisitors) * 100
    )
    const cumulativePercentages = percentages.reduce(
      (acc, percentage, i) => [...acc, (acc[i - 1] ?? 0) + percentage],
      []
    )

    return `conic-gradient(${cumulativePercentages
      .map((p, i) => `var(--chakra-colors-${COLORS[i]}-500) 0 ${p}%`)
      .join(", ")})`
  }, [experiment])

  return <Box rounded="full" bg={gradient} {...props} />
}
