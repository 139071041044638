import { useCallback } from "react"

/**
 * Download an SVG element as SVG or PNG
 */
export const useSVGDownload = () => {
  const download = useCallback(
    (format: "svg" | "png", element: SVGSVGElement) =>
      new Promise<void>((resolve, reject) => {
        if (!element) return

        const svg = new XMLSerializer().serializeToString(element)

        const blob = new Blob([svg], { type: "image/svg+xml" })
        const url = URL.createObjectURL(blob)

        // Create a fake link and click it to download the file
        const downloadFrom = (url: string) => {
          const a = document.createElement("a")
          a.href = url
          a.download = `path-diagram.${format}`
          a.click()
        }

        if (format === "svg") {
          downloadFrom(url)
          URL.revokeObjectURL(url)
          resolve()
        } else {
          const image = document.createElement("img")
          image.addEventListener("load", () => {
            const canvas = document.createElement("canvas")
            canvas.width = element.clientWidth
            canvas.height = element.clientHeight
            const context = canvas.getContext("2d")
            context?.drawImage(image, 0, 0)
            downloadFrom(canvas.toDataURL("img/png"))
            URL.revokeObjectURL(url)
            resolve()
          })
          image.addEventListener("error", reject)
          image.src = url
        }
      }),
    []
  )

  return download
}
