import { Box, Flex } from "@chakra-ui/react"
import { Heading, Tag } from "DesignSystem/components"
import { GranularLocationTags } from "UsabilityHub/components/GranularLocationTags/GranularLocationTags"
import { useFlatLocations } from "UsabilityHub/hooks/useFlatLocations"
import React from "react"
import {
  BasicDemographicAttribute,
  ThirdPartyOrderValidationErrors,
} from "~/application/javascripts/types"
import { SaveAsPresetButton } from "./DemographicPresets/SaveAsPresetButton"
import { useOrderForm } from "./OrderFormProvider"
import { ValidationError } from "./ValidationError"
import { DEFAULT_AGE_RANGE } from "./constants"
import { TargetLocation } from "./types"

const toSentence = (arr: string[]) => {
  return arr
    .reduce(
      (prev, curr, i) => prev + curr + (i === arr.length - 2 ? " and " : ", "),
      ""
    )
    .slice(0, -2)
}

interface Props {
  showPresets: boolean
  activeDemographicAttributes: BasicDemographicAttribute[]
  validationErrors?: ThirdPartyOrderValidationErrors
  clearDemographicAttribute?: (demographicAttributeId: number) => void
}

export const DemographicTargetingSummary: React.FC<
  React.PropsWithChildren<Props>
> = ({ showPresets, activeDemographicAttributes, validationErrors = {} }) => {
  const {
    context,
    selectedOptionIds,
    selectOption,
    targetLocations,
    setTargetLocations,
    ageRange,
    clearAgeRange,
  } = useOrderForm()
  const ageRangeTargeting =
    ageRange[0] !== DEFAULT_AGE_RANGE[0] || ageRange[1] !== DEFAULT_AGE_RANGE[1]
  const anyTargeting =
    activeDemographicAttributes.length ||
    targetLocations.length ||
    ageRangeTargeting
  const locationNames = useFlatLocations(context)

  if (!anyTargeting) return null

  const handleRemoveLocations = setTargetLocations
    ? (locationsToRemove: TargetLocation[]) => {
        setTargetLocations(
          locationsWithoutRemoved({
            targetLocations,
            locationsToRemove,
          })
        )
      }
    : undefined

  return (
    <Flex direction="column" gap={2}>
      <Flex justify="space-between" gap={4} alignItems="baseline">
        <Heading as="h3" textStyle="ds.heading.primary">
          Demographics
        </Heading>
        {showPresets && (
          <Flex justify="flex-end">
            <SaveAsPresetButton />
          </Flex>
        )}
      </Flex>
      <Flex flexDirection="column" gap={4}>
        {targetLocations.length ? (
          <Box>
            <Flex direction="column" gap={2}>
              <DemographicAttributeGroupHeading title="Location" />
              <GranularLocationTags
                scope={context}
                targetLocations={targetLocations}
                handleRemoveLocations={handleRemoveLocations}
              />
            </Flex>
            {validationErrors.invalid_countries && (
              <Box mt={4}>
                <ValidationError>
                  {toSentence(
                    validationErrors.invalid_countries.map(
                      (code) =>
                        locationNames.country[code]?.qualifiedName ?? "Unknown"
                    )
                  )}{" "}
                  {validationErrors.invalid_countries.length > 1 ? "are" : "is"}{" "}
                  not supported for external panel orders. Please remove{" "}
                  {validationErrors.invalid_countries.length > 1
                    ? "these selections"
                    : "this selection"}{" "}
                  to continue.
                </ValidationError>
              </Box>
            )}
          </Box>
        ) : null}
        {ageRangeTargeting ? (
          <Flex direction="column" gap={2}>
            <DemographicAttributeGroupHeading title="Age" />
            <TagList>
              <Tag
                onClose={clearAgeRange}
                label={
                  <>
                    {ageRange[0] !== ageRange[1] ? `${ageRange[0]} — ` : null}
                    {ageRange[1] === 100 ? "100+" : ageRange[1]}
                  </>
                }
              />
            </TagList>
          </Flex>
        ) : null}
        {activeDemographicAttributes.map((attribute) => {
          return (
            <Box key={attribute.id}>
              <Flex direction="column" gap={2}>
                <DemographicAttributeGroupHeading title={attribute.name} />
                <TagList>
                  {attribute.options.map((option) => {
                    const selected = selectedOptionIds.includes(option.id)
                    return (
                      <Tag
                        key={`option-${option.value}`}
                        onClose={() => selectOption(option.id, !selected)}
                        label={option.value}
                      />
                    )
                  })}
                </TagList>
              </Flex>
              {validationErrors.unsupported_demographics?.includes(
                attribute.id
              ) ? (
                <Box mt={2}>
                  <ValidationError>
                    {attribute.name} is not a supported target for external
                    panel orders. Please remove this selection to continue.
                  </ValidationError>
                </Box>
              ) : null}
            </Box>
          )
        })}
      </Flex>
    </Flex>
  )
}

const locationsWithoutRemoved = ({
  targetLocations,
  locationsToRemove,
}: {
  targetLocations: TargetLocation[]
  locationsToRemove: TargetLocation[]
}) =>
  targetLocations.filter(
    (location) =>
      !locationsToRemove.some(
        (ltr) => ltr.type === location.type && ltr.id === location.id
      )
  )

const TagList: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Flex flexWrap="wrap" gap={2}>
    {children}
  </Flex>
)

const DemographicAttributeGroupHeading: React.FC<{ title: string }> = ({
  title,
}) => (
  <Heading as="h4" textStyle="ds.heading.secondary">
    {title}
  </Heading>
)
