import { kebabCase } from "lodash"
import { createSelector } from "reselect"

import { State } from "Redux/app-store"
import { FigmaFileFlow, FigmaFileVersion } from "Types"

export const REAL_FIGMA_DOMAIN = "https://www.figma.com"

export const getFigmaFileVersions = (state: State) => {
  return state.figmaFileVersions
}

const getFigmaFileVersionById = (id: number) =>
  createSelector(getFigmaFileVersions, (ffvs): FigmaFileVersion => {
    const ffv = ffvs.find((ffv) => ffv.id === id)
    if (ffv == null) {
      throw new Error(`No figma file version with id: ${id}`)
    } else {
      return ffv
    }
  })

export const getNullableFigmaFileVersionById = (id: number) =>
  createSelector(getFigmaFileVersions, (ffvs): FigmaFileVersion | undefined => {
    return ffvs.find((ffv) => ffv.id === id)
  })

const createFigmaFlowPrototypeUrl = (
  figmaFileVersion: FigmaFileVersion,
  start_node_id: string,
  scaling: string
): string => {
  const { figma_file_key } = figmaFileVersion
  const queryString = new URLSearchParams({
    "starting-point-node-id": start_node_id,
    "node-id": start_node_id, // For multi-page files
    scaling: kebabCase(scaling),
    "hotspot-hints": "false", // Remove hitzone highlighting on misclick
    // Hide figma controls
    "hide-ui": "true", // hide-ui is undocumented in the V2 API but without it none of the other UI controls work
    "page-selector": "false",
    footer: "false",
    "viewport-controls": "false",
    "show-proto-sidebar": "false",
  }).toString()
  // NOTE: `REAL_FIGMA_DOMAIN` instead of `getEnvState().FIGMA_DOMAIN`
  // Backend testing mocks FIGMA_DOMAIN. If we use that here the backend tests
  // need to mock (or store responses for) every API call to Figma involved in
  // loading  a prototype: font, design, media APIs, which is more trouble
  // than it's worth.
  return `${REAL_FIGMA_DOMAIN}/proto/${figma_file_key}?${queryString}`
}

export const getFigmaFlowPrototypeUrl = (figmaFileFlow: FigmaFileFlow) =>
  createSelector(
    getFigmaFileVersionById(figmaFileFlow.figma_file_version_id),
    (figmaFileVersion) =>
      createFigmaFlowPrototypeUrl(
        figmaFileVersion,
        figmaFileFlow.start_node_id,
        figmaFileFlow.scaling
      )
  )

export const getNullableFigmaFlowPrototypeUrl = (
  figmaFileFlow: FigmaFileFlow
) =>
  createSelector(
    getNullableFigmaFileVersionById(figmaFileFlow.figma_file_version_id),
    (figmaFileVersion) => {
      if (figmaFileVersion) {
        return createFigmaFlowPrototypeUrl(
          figmaFileVersion,
          figmaFileFlow.start_node_id,
          figmaFileFlow.scaling
        )
      } else {
        return null
      }
    }
  )
