import { Box, HStack, Spacer, Stack, Text } from "@chakra-ui/react"
import { Props } from "Components/test-results/question-results/props"
import {
  SectionResultsQuestionHeading,
  SectionResultsQuestionText,
} from "UsabilityHub/components/TestResults/SectionResultsLabels"
import { pluralizeWithCount } from "Utilities/string"
import { mean as calculateMean, countBy, inRange, range } from "lodash"
import React, { FC } from "react"
import { LinearScaleItem } from "./linear-scale-item"
import styles from "./linear-scale-question-results.module.scss"

export const LinearScaleQuestionResults: FC<React.PropsWithChildren<Props>> = ({
  question,
  answers,
}) => {
  const { values, passedCount } = answers.reduce(
    (acc, a) => {
      if (a.answer === null) {
        acc.passedCount++
      } else {
        const value = parseInt(a.answer, 10)
        if (
          Number.isFinite(value) &&
          inRange(value, question.min_value, question.max_value + 1)
        ) {
          acc.values.push(value)
        } else {
          // NOTE: If we reach this point we've hit a value that is either not
          // numeric of is out of range. It is possible for this to happen as
          // a result of changing the question type to linear scale after
          // receiving results. For now we're just excluding these invalid
          // values so there's nothing to do.
        }
      }
      return acc
    },
    { values: [] as number[], passedCount: 0 }
  )
  const mean = calculateMean(values)
  const meanNode = <>Mean: {Number.isFinite(mean) ? mean.toFixed(2) : "n/a"}</>
  const counts = countBy(values)
  const totalCount = values.length
  return (
    <Stack spacing={5}>
      <SectionResultsQuestionHeading />
      <SectionResultsQuestionText />
      <Box>
        <div className={styles.chartLabels}>
          <span>{question.min_label}</span>
          <span>{question.max_label}</span>
        </div>
        <ul className={styles.barList}>
          {range(question.min_value, question.max_value + 1).map((i) => {
            const count = counts[i] || 0
            const percentage = count / totalCount
            return (
              <li key={i}>
                <LinearScaleItem
                  questionId={question.id}
                  text={i.toString()}
                  percentage={percentage}
                  count={count}
                />
              </li>
            )
          })}
        </ul>
        <HStack mt={5}>
          {passedCount > 0 && (
            <Text>
              {pluralizeWithCount(passedCount, "participant", "participants")}{" "}
              passed this question
            </Text>
          )}
          <Spacer />
          <Text>{meanNode}</Text>
        </HStack>
      </Box>
    </Stack>
  )
}
