import React, { PureComponent } from "react"
import { connect } from "react-redux"

import { Screenshot as ScreenshotComponent } from "Components/screenshot/screenshot"
import {
  Name,
  Thumbnail,
} from "Components/test-results/progress-box/progress-box"
import { ExpandableSectionScreenshotSummary } from "Components/test-results/section-results/expandable-section-screenshot-summary"
import styles from "Components/test-results/section-results/screenshot-preference-overview.module.scss"
import { State } from "Redux/app-store"
import { getScreenshotWithId } from "Redux/reducers/screenshots/selectors"
import { DeviceFrame, Screenshot } from "Types"

interface ImplProps {
  children: React.ReactNode
  className?: string
  deviceFrame: Readonly<DeviceFrame> | null
  screenshot: Readonly<Screenshot>
  isFilter: boolean
  isFiltered: boolean
  onIsExpandedChange: (isExpanded: boolean) => void
  isExpanded: boolean
  progress: number
  "data-qa"?: string
}

class Impl extends PureComponent<ImplProps> {
  private handleToggleExpandedClick = () => {
    const { onIsExpandedChange, isExpanded } = this.props
    onIsExpandedChange(!isExpanded)
  }

  render() {
    const {
      className,
      deviceFrame,
      isFilter,
      isFiltered,
      screenshot,
      children,
      progress,
      isExpanded,
      "data-qa": dataQa,
    } = this.props

    const summaryNode = (
      <>
        <Thumbnail
          screenshot={screenshot}
          onClick={this.handleToggleExpandedClick}
          isExpanded={isExpanded}
        />
        <Name>{screenshot.name}</Name>
        {children}
      </>
    )

    const detailsNode = (
      <div className={styles.screenshotContainer}>
        <ScreenshotComponent
          className={styles.screenshot}
          screenshot={screenshot}
          constrainWidthToDeviceFrame={deviceFrame}
          onClick={this.handleToggleExpandedClick}
          canSkipAheadOnFirstWatch
        />
      </div>
    )

    return (
      <ExpandableSectionScreenshotSummary
        className={className}
        isExpanded={isExpanded}
        summaryChildren={summaryNode}
        detailsChildren={detailsNode}
        progress={progress}
        isFilter={isFilter}
        isFiltered={isFiltered}
        data-qa={dataQa}
      />
    )
  }
}

interface OwnProps {
  readonly screenshotId: number
}

export const ScreenshotPreferenceResult = connect(
  (state: State, { screenshotId }: OwnProps) => ({
    screenshot:
      screenshotId === -1 ? null : getScreenshotWithId(state, screenshotId),
  })
)(Impl)
