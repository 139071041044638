import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
} from "@chakra-ui/react"
import React from "react"

import Constants from "Constants/shared.json"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"
import { pluralize } from "Utilities/string"

interface ScreenClicksWarningProps extends AlertProps {
  offScreenClicks: NormalizedClick[]
}

export function ScreenClicksWarning({
  offScreenClicks,
  ...alertProps
}: ScreenClicksWarningProps) {
  if (offScreenClicks.length === 0) return null

  return (
    <Alert {...alertProps} status="warning">
      <AlertIcon />
      <AlertTitle>Heads up!</AlertTitle>
      <AlertDescription>
        There{"\u2019"}s {offScreenClicks.length}{" "}
        {pluralize(offScreenClicks.length, "click", "clicks")} in a Figma frame
        with overflow behavior that we can{"\u2019"}t show.{" "}
        <ExternalLink
          href={Constants.HELP_CENTER_TROUBLESHOOTING_PROTOTYPE_TASKS_URL}
        >
          Learn more here
        </ExternalLink>
      </AlertDescription>
    </Alert>
  )
}
