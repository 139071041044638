import { arrayInsert } from "redux-form"

import { State } from "Redux/app-store"
import { getFormName } from "Redux/reducers/test-builder-form/selectors/formValues"
import {
  ThunkAction,
  UsabilityTestSection,
  UsabilityTestSectionQuestion,
  UsabilityTestSectionScreenshot,
} from "Types"

import { insertDefinedSection } from "Redux/reducers/test-builder-form/action-creators/sections"
import {
  ClearDeletedQuestionAction,
  ClearDeletedSectionAction,
  ClearDeletedSectionScreenshotAction,
  ClearUndoCheckpointAction,
  SaveUndoCheckpointAction,
} from "../action-types"
import { Type } from "../constants"
import { isPersisted } from "../helpers"

export type UndoableOperation =
  | {
      type: "delete-section"
      section: UsabilityTestSection
      sectionIndex: number
    }
  | {
      type: "delete-question"
      sectionId: number
      sectionIndex: number
      questionIndex: number
      question: UsabilityTestSectionQuestion
    }
  | {
      type: "delete-screenshot"
      sectionId: number
      sectionIndex: number
      sectionScreenshotIndex: number
      sectionScreenshot: UsabilityTestSectionScreenshot
    }

export function createCheckpoint(
  operation: UndoableOperation
): SaveUndoCheckpointAction {
  return {
    type: Type.SAVE_UNDO_CHECKPOINT,
    payload: operation,
  }
}

function clearCheckpoint(): ClearUndoCheckpointAction {
  return {
    type: Type.CLEAR_UNDO_CHECKPOINT,
  }
}

export const undoLastOperation =
  (): ThunkAction<State> => (dispatch, getState) => {
    const state = getState()
    const operation = state.testBuilder?.lastOperation

    if (!operation) return

    switch (operation.type) {
      case "delete-section":
        dispatch(
          insertDefinedSection({
            ...operation.section,
            position: operation.sectionIndex,
          })
        )

        if (isPersisted(operation.section)) {
          dispatch<ClearDeletedSectionAction>({
            type: Type.CLEAR_DELETED_TEST_FORM_SECTION,
            payload: { sectionId: operation.section.id },
          })
        }

        break
      case "delete-question":
        dispatch(
          arrayInsert(
            getFormName(),
            `sections[${operation.sectionIndex}].questions`,
            operation.questionIndex,
            operation.question
          )
        )

        if (isPersisted(operation.question)) {
          dispatch<ClearDeletedQuestionAction>({
            type: Type.CLEAR_DELETED_TEST_FORM_QUESTION,
            payload: {
              sectionId: operation.sectionId,
              questionId: operation.question.id,
            },
          })
        }

        break
      case "delete-screenshot":
        dispatch(
          arrayInsert(
            getFormName(),
            `sections[${operation.sectionIndex}].section_screenshots`,
            operation.sectionScreenshotIndex,
            operation.sectionScreenshot
          )
        )

        if (isPersisted(operation.sectionScreenshot)) {
          dispatch<ClearDeletedSectionScreenshotAction>({
            type: Type.CLEAR_DELETED_TEST_FORM_SECTION_SCREENSHOT,
            payload: {
              sectionId: operation.sectionId,
              sectionScreenshotId: operation.sectionScreenshot.id,
            },
          })
        }

        break
      default:
        assertNever(operation)
    }

    dispatch(clearCheckpoint())
  }

const assertNever = (val: never): never => {
  throw new Error(`Unsupported operation: ${JSON.stringify(val)}`)
}
