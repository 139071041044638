import { Flex, Progress, Stack, StackProps, Text } from "@chakra-ui/react"
import React from "react"

import { useTranslate } from "Shared/hooks/useTranslate"
import { useTestBranding } from "UsabilityHub/components/UsabilityTest/context/testBranding"

import { useCardSortContext } from "./CardSortContext"

export const CardSortProgress: React.FC<StackProps> = (props) => {
  const { cards, sortData } = useCardSortContext()
  const branding = useTestBranding()
  const translate = useTranslate()

  const total = cards.length
  const unsorted = sortData.unsorted.length

  return (
    <Stack spacing={0} justify="space-between" {...props}>
      <Flex justify="space-between">
        <Text fontSize="sm" noOfLines={1}>
          {translate("test.card_sort.progress_bar")}
        </Text>
        <Text fontSize="sm" fontWeight="bold">
          {total - unsorted}/{total}
        </Text>
      </Flex>

      <Progress
        value={((total - unsorted) / total) * 100}
        rounded="md"
        bg="brand.background.light"
        sx={{
          "& > div:first-of-type": {
            bg: branding.button_color ?? "brand.primary.600",
            transitionProperty: "width",
          },
        }}
      />
    </Stack>
  )
}
