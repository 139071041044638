import * as React from "react"

import { UsabilityTestSectionType } from "~/application/javascripts/types"

const SectionTypeContext = React.createContext<UsabilityTestSectionType | null>(
  null
)

interface SectionTypeProviderProps {
  value: UsabilityTestSectionType
}

export const SectionTypeProvider: React.FC<
  React.PropsWithChildren<SectionTypeProviderProps>
> = ({ value, children }) => {
  return (
    <SectionTypeContext.Provider value={value}>
      {children}
    </SectionTypeContext.Provider>
  )
}

export const useSectionTypeContext = (): UsabilityTestSectionType => {
  const context = React.useContext(SectionTypeContext)

  if (context === null) {
    throw new Error(
      `useSectionTypeContext must be rendered within the SectionTypeProvider`
    )
  }

  return context
}
