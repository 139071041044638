import React from "react"
import { useSelector } from "react-redux"

import { State } from "Redux/app-store"
import {
  getScreenshotWithClientId,
  getScreenshotWithId,
} from "Redux/reducers/screenshots/selectors"
import { Screenshot, UsabilityTestSectionScreenshot } from "Types"

interface SectionMedia {
  sectionMediaIndex: number
  sectionMedia: UsabilityTestSectionScreenshot
  mediaFile: Screenshot
}
const SectionMediaContext = React.createContext<SectionMedia | null>(null)

interface SectionMediaProviderProps {
  value: Omit<SectionMedia, "mediaFile">
}

/**
 * @param {SectionMediaProviderProps} props Props to pass to provider
 * @param {SectionMedia} props.value Initial state for context
 * @returns {React.ReactElement} Context Provider
 */
export const SectionMediaProvider: React.FC<
  React.PropsWithChildren<SectionMediaProviderProps>
> = ({ value, children }) => {
  // Get the Screenshot for this SectionScreenshot and give it to the context.
  /**
   * When users navigate from the results page to the test builder while the results page is still loading,
   * the asynchronous process of the results page might regenerate screenshot clientIds via
   * `getInitializeScreenshots` in `app/frontend/usabilityhub/views/UsabilityTest/ResultDataProvider.tsx`
   * after the test builder loads everything and generates clientIds.
   *
   * This causes the screenshots to be unable to be found by their old `_screenshotClientId`.
   * In this case, we'll find the screenshot by its id instead.
   */
  const mediaFile = useSelector(
    (state: State) =>
      getScreenshotWithClientId(
        state,
        value.sectionMedia._screenshotClientId
      ) || getScreenshotWithId(state, value.sectionMedia.screenshot_id)
  )
  return (
    <SectionMediaContext.Provider value={{ ...value, mediaFile }}>
      {children}
    </SectionMediaContext.Provider>
  )
}

/**
 * Hook to access state
 *
 * @returns {SectionMedia} hook to access state and dispatch
 */
export const useSectionMediaContext = (): SectionMedia => {
  const context = React.useContext(SectionMediaContext)

  if (!context) {
    throw new Error(
      `useSectionMedia must be rendered within the SectionMediaProvider`
    )
  }

  return context
}
