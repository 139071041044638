import { useBeforeUnload } from "UsabilityHub/hooks/useBeforeUnload"
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react"
import { ListDemographicsResponse } from "~/api/generated/usabilityhub-components"
import { DemographicPreset } from "~/api/generated/usabilityhubSchemas"
import { extractLocations } from "./DemographicPresets/extractLocations"
import { DEFAULT_AGE_RANGE } from "./constants"
import { TargetLocation } from "./types"

type OrderFormContextType = {
  context: "usability_tests" | "interviews"
  testHasScreener: boolean
  testHasRecordings: boolean
  testIsExternal: boolean

  estimatedIncidenceRate: number | null
  numParticipants: number
  selectedOptionIds: number[]
  ageRange: [number, number]
  targetLocations: TargetLocation[]
  focusAttributeId: number | null

  setAgeRange: (ageRange: [number, number]) => void
  setNumParticipants: (numParticipants: number) => void
  selectOption: (optionIds: number | number[], selected: boolean) => void
  setFocusAttributeId: (id: number | null) => void
  setEstimatedIncidenceRate: (estimatedIncidenceRate: number | null) => void
  setTargetLocations: Dispatch<SetStateAction<TargetLocation[]>>

  clearTargetLocations: () => void
  clearAgeRange: () => void
  clearAllOptionsForAttribute: (attributeId: number) => void

  appliedPreset: DemographicPreset | null
  applyPreset: (preset: DemographicPreset) => void
}

const OrderFormContext = createContext<OrderFormContextType | null>(null)

export const useOrderForm = () => {
  const context = useContext(OrderFormContext)
  if (!context) {
    throw new Error("useOrderForm must be used within a OrderFormProvider")
  }
  return context
}

type Props = {
  context: OrderFormContextType["context"]
  testHasScreener: boolean
  testHasRecordings: boolean
  testIsExternal: boolean
  demographics: ListDemographicsResponse
  defaultNumberOfPanelists: number
}

export const OrderFormProvider: React.FC<PropsWithChildren<Props>> = ({
  context,
  testHasScreener,
  testHasRecordings,
  testIsExternal,
  demographics,
  defaultNumberOfPanelists,
  children,
}) => {
  const [selectedOptionIds, setSelectedOptionIds] = useState<number[]>([])
  const [numParticipants, setNumParticipants] = useState(
    defaultNumberOfPanelists
  )
  const [ageRange, setAgeRange] = useState<[number, number]>([
    ...DEFAULT_AGE_RANGE,
  ])
  const [targetLocations, setTargetLocations] = useState<TargetLocation[]>([])

  const [estimatedIncidenceRate, setEstimatedIncidenceRate] = useState<
    number | null
  >(null)
  const [focusAttributeId, setFocusAttributeId] = useState<number | null>(null)
  const [appliedPreset, setAppliedPreset] = useState<DemographicPreset | null>(
    null
  )

  // If any changes have been made to the form, shown an "are you sure?" dialog
  // when navigating away or refreshing.
  const isDirty =
    selectedOptionIds.length > 0 ||
    numParticipants !== defaultNumberOfPanelists ||
    ageRange[0] !== DEFAULT_AGE_RANGE[0] ||
    ageRange[1] !== DEFAULT_AGE_RANGE[1] ||
    targetLocations.length > 0

  useBeforeUnload(isDirty)

  const selectOption = (optionIds: number | number[], selected: boolean) => {
    const optionIdsArray = Array.isArray(optionIds) ? optionIds : [optionIds]

    if (selected) {
      setSelectedOptionIds([...selectedOptionIds, ...optionIdsArray])
    } else {
      setSelectedOptionIds(
        selectedOptionIds.filter((option) => !optionIdsArray.includes(option))
      )
    }
  }

  const clearTargetLocations = () => setTargetLocations([])
  const clearAgeRange = () => setAgeRange([...DEFAULT_AGE_RANGE])
  const clearAllOptionsForAttribute = (attributeId: number) => {
    const attribute = demographics
      .flatMap((g) => g.demographic_attributes)
      .find((a) => a.id === attributeId)
    const optionIds = attribute?.options.map((o) => o.id) ?? []
    setSelectedOptionIds(
      selectedOptionIds.filter((id) => !optionIds.includes(id))
    )
  }

  const applyPreset = (preset: DemographicPreset) => {
    setAppliedPreset(preset)
    setTargetLocations(extractLocations(preset))
    setAgeRange([
      preset.min_age || DEFAULT_AGE_RANGE[0],
      preset.max_age || DEFAULT_AGE_RANGE[1],
    ])
    setSelectedOptionIds(preset.demographic_attribute_option_ids)
  }

  return (
    <OrderFormContext.Provider
      value={{
        // Values passed through as props
        context,
        testHasScreener,
        testHasRecordings,
        testIsExternal,

        // Values from local state
        estimatedIncidenceRate,
        numParticipants,
        selectedOptionIds,
        ageRange,
        targetLocations,
        focusAttributeId,

        // Functions to set local state
        setNumParticipants,
        selectOption,
        setFocusAttributeId,
        setEstimatedIncidenceRate,
        setAgeRange,
        setTargetLocations,

        // Functions to clear local state
        clearTargetLocations,
        clearAgeRange,
        clearAllOptionsForAttribute,

        appliedPreset,
        applyPreset,
      }}
    >
      {children}
    </OrderFormContext.Provider>
  )
}
