import { Button, Flex } from "@chakra-ui/react"
import React, { ChangeEvent, useState } from "react"

import { TextareaWithMentions } from "./TextareaWithMentions"

type Props = {
  defaultContent?: string
  isLoading?: boolean
  lockedOpen?: boolean
  closeThread: () => void
  handleSave: (comment: string) => Promise<void>
  saveButtonLabel: string
}

export const CommentEditor: React.FC<Props> = ({
  defaultContent = "",
  isLoading = false,
  lockedOpen = false,
  closeThread,
  handleSave,
  saveButtonLabel,
}) => {
  const [content, setContent] = useState(defaultContent)

  const saveComment = async () => {
    // Remove trailing whitespace from the comment before submitting.
    // (The @mentions system adds a space after the username)
    await handleSave(content.trimEnd())
    setContent("")
  }

  const clearComment = () => {
    setContent("")
    if (lockedOpen) closeThread()
  }

  const showButtons = lockedOpen || content.length > 0

  return (
    <Flex direction="column" gap={3} data-qa="comment-form">
      <TextareaWithMentions
        value={content}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setContent(e.target.value)
        }
        onKeyDown={(e) => {
          if (isLoading) return

          if (e.key === "Escape") {
            if (content === "") {
              closeThread()
            } else {
              clearComment()
            }
          } else if (e.key === "Enter" && e.ctrlKey) {
            setContent((oldContent) => oldContent + "\n")
          } else if (e.key === "Enter" && !e.shiftKey && content !== "") {
            e.preventDefault()
            void saveComment()
          }
        }}
      />

      <Flex
        display={showButtons ? undefined : "none"}
        _peerFocus={{ display: "flex" }}
        gap={2}
      >
        <Button
          colorScheme="brand.primary"
          size="sm"
          isDisabled={content === defaultContent}
          isLoading={isLoading}
          onClick={saveComment}
        >
          {saveButtonLabel}
        </Button>
        <Button variant="outline" size="sm" onClick={clearComment}>
          Cancel
        </Button>
      </Flex>
    </Flex>
  )
}
