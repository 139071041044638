import { getApplicationState, getEnvState } from "JavaScripts/state"

import { Ortto, initializeOrtto } from "./initialize-ortto.js"

let ortto: Ortto | null = null

export const installOrtto = (
  currentUser: { email: string } | null,
  currentAccount: {
    account_type: string
  } | null
) => {
  const { isEmulatingUser } = getApplicationState()
  const env = getEnvState()

  const noOrttoPaths = [
    /^\/do\//,
    /^\/take\//,
    /^\/preview\//,
    /^\/flagged/,
    /^\/third_party/,
    /^\/testers/,
    /^\/payouts/,
    /^\/demo/,
    /^\/admin/,
  ]

  if (
    !process.env.CI &&
    env.ORTTO_TRACKING_CODE !== "" &&
    !isEmulatingUser &&
    !noOrttoPaths.some((r) => r.test(window.location.pathname)) &&
    (currentAccount === null || currentAccount.account_type === "creator")
  ) {
    const email =
      currentUser != null &&
      currentAccount != null &&
      currentAccount.account_type === "creator"
        ? currentUser.email
        : undefined

    ortto = initializeOrtto(
      env.ORTTO_TRACKING_CODE,
      env.ORTTO_TRACKING_API_URL,
      env.ORTTO_TRACKING_CDN_URL,
      email?.toString()
    )
  }
}

/*
 * Adds the Ortto cookie as a query param if it's available.
 * Expects to be used as an onClick function by an Anchor or a Button masquerading as an Anchor.
 */
export const onClickAddOrttoCookieParam = (
  e: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement, MouseEvent>
) => {
  const orttoCookie = ortto?.getCookie ? ortto.getCookie() : null
  if (orttoCookie) {
    const url = new URL(e.currentTarget.href)
    const params = url.searchParams
    params.set("ap3c", orttoCookie)
    e.currentTarget.href = url.toString()
  }
}
