import { Box, Tooltip, TooltipProps } from "@chakra-ui/react"
import { tokens } from "DesignSystem/tokens"
import React, { PropsWithChildren, ReactNode, useState } from "react"

export const CustomTooltip: React.FC<
  { tooltip: string | ReactNode } & PropsWithChildren & TooltipProps
> = ({ children, tooltip, ...rest }) => {
  // This is used to keep chakra tooltip showing when hovering over the tooltip itself
  // Ref: https://github.com/chakra-ui/chakra-ui/issues/3993
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box
      as="span"
      onMouseOver={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Tooltip
        placement="right"
        label={tooltip}
        shadow="ds.overflow"
        sx={{ ...tokens.textStyles.ds.paragraph.emphasized }}
        rounded="md"
        p={2}
        color="ds.text.default"
        backgroundColor="ds.background.input.resting"
        isOpen={isOpen}
        pointerEvents="auto"
        {...rest}
      >
        {children}
      </Tooltip>
    </Box>
  )
}
