import {
  Box,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Button, Heading } from "DesignSystem/components"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import React from "react"
import { DemographicAttributeGroup } from "~/api/generated/usabilityhubSchemas"
import { DemographicTargetingSummary } from "./DemographicTargetingSummary"
import { ExternalPanelTab } from "./ExternalPanelOrders/ExternalPanelTab"
import { LyssnaPanelTab } from "./LyssnaPanelOrders/LyssnaPanelTab"
import { useOrderForm } from "./OrderFormProvider"
import { TestTargetingSummary } from "./TestTargetingSummary"
import { Panel } from "./types"

interface Props {
  demographics: DemographicAttributeGroup[]
  panel: Panel
  setPanel: (panel: Panel) => void
}

const PANEL_OPTIONS: { [key in Panel]: string } = {
  usabilityhub: "Lyssna panel",
  external: "External panel",
}

export const OrderSummaryPanel: React.FC<React.PropsWithChildren<Props>> = ({
  demographics,
  panel,
  setPanel,
}) => {
  const {
    selectedOptionIds,
    clearAllOptionsForAttribute,
    estimatedIncidenceRate,
    setEstimatedIncidenceRate,
    testHasScreener,
    testHasRecordings,
    testIsExternal,
  } = useOrderForm()

  const activeDemographicAttributes = demographics.flatMap((group) => {
    return group.demographic_attributes
      .map((attribute) => ({
        ...attribute,
        options: attribute.options?.filter((option) =>
          selectedOptionIds.includes(option.id)
        ),
      }))
      .filter((attribute) => attribute.options?.length)
  })

  return (
    <Flex
      direction="column"
      bg="white"
      rounded="16px"
      boxShadow="ds.raised"
      p={6}
    >
      <Heading as="h2" textStyle="ds.display.primary" mx={0} mb={6}>
        Order summary
      </Heading>
      <Flex
        justify="space-between"
        align="center"
        borderTopWidth={1}
        borderColor="ds.border.default"
        py={3}
      >
        <Flex align="center" gap={1}>
          <Heading as="h3" textStyle="ds.heading.primary">
            Participant source
          </Heading>
          {testIsExternal ? (
            <HelpPopover>
              Only the Lyssna panel is available when working with external
              studies.
            </HelpPopover>
          ) : (
            <HelpPopover>
              Consider using the External panel when the Lyssna panel isn't able
              to provide enough participants.{" "}
              <Link
                isExternal
                href={Constants.HELP_CENTER_EXTERNAL_PANEL_ORDERS_URL}
              >
                Learn more
              </Link>
              .
            </HelpPopover>
          )}
        </Flex>
        {testIsExternal ? (
          <Text>Lyssna panel</Text>
        ) : (
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownOutlineIcon />}
              data-intercom-target="external-panel-select"
              data-qa="participant-source-dropdown"
            >
              {PANEL_OPTIONS[panel]}
            </MenuButton>
            <MenuList>
              {(Object.keys(PANEL_OPTIONS) as Panel[]).map((key) => (
                <MenuItem key={key} onClick={() => setPanel(key)}>
                  {PANEL_OPTIONS[key]}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </Flex>
      <Box data-intercom-target="order-summary">
        <TestTargetingSummary
          screenerEnabled={testHasScreener}
          recordingEnabled={testHasRecordings}
          setEstimatedIncidenceRate={setEstimatedIncidenceRate}
          estimatedIncidenceRate={estimatedIncidenceRate}
          missingIncidenceRate={estimatedIncidenceRate === null}
        />
        <Box my={6}>
          <DemographicTargetingSummary
            showPresets
            activeDemographicAttributes={activeDemographicAttributes}
            validationErrors={{}} // TODO: ideally get validationErrors back here too
            clearDemographicAttribute={clearAllOptionsForAttribute}
          />
        </Box>
      </Box>
      <Box>
        {panel === "usabilityhub" && <LyssnaPanelTab setPanel={setPanel} />}
        {panel === "external" && <ExternalPanelTab />}
      </Box>
    </Flex>
  )
}
