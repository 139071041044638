import { v4 as uuidv4 } from "uuid"

import { RawState, State } from "Redux/app-store"
import { createInitializer } from "Redux/initialize-state"
import { addUsabilityTestClientFields } from "Redux/reducers/test-builder/helpers"
import { DefinedRawTestBuilderState } from "./reducer"

export const newUsabilityTest = (
  testBuilder: DefinedRawTestBuilderState,
  { screenshots }: { screenshots: State["screenshots"] }
) => {
  const { creditCount, estimatedDurationRange, ...restTestBuildState } =
    testBuilder

  return {
    ...restTestBuildState,
    initialValues: addUsabilityTestClientFields(
      testBuilder.initialValues,
      screenshots
    ),
    questionDeletions: [],
    sectionScreenshotDeletions: [],
    sectionDeletions: [],
    testSetId: null,
    isPreviewClicked: false,
    uuid: uuidv4(),
    estimations: {
      isLoading: false,
      isValid: false,
      creditCount: creditCount,
      estimatedDurationRange: estimatedDurationRange,
    },
    comments: [],
    lastOperation: null,
  }
}

const initializer = createInitializer<
  RawState,
  State,
  "testBuilder",
  "screenshots"
>(
  (testBuilder, { screenshots }) => {
    if (testBuilder === undefined) return null
    return newUsabilityTest(testBuilder, { screenshots })
  },
  ["screenshots"]
)

export default initializer
