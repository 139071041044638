import { useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { responsesOrderedGoogle } from "JavaScripts/analytics/google"
import {
  trackCreditsPurchased,
  trackOrderActivated,
} from "JavaScripts/analytics/track"
import { ROUTES } from "UsabilityHub/views/routes"
import { useState } from "react"
import { useNavigate } from "react-router"
import {
  CalculatePriceAndEstimatedDurationResponse,
  useCreateOrder,
} from "~/api/generated/usabilityhub-components"
import { OrderCreateState } from "../types"

export const useLyssnaPanelOrderSubmit = ({
  confirmCreditPurchase,
}: {
  confirmCreditPurchase: () => void
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const navigate = useNavigate()

  const [orderCreateState, setOrderCreateState] =
    useState<OrderCreateState>("idle")
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false)

  const { mutate: createOrder } = useCreateOrder({
    onSuccess: (data, variables) => {
      if (data.purchase) {
        trackCreditsPurchased(
          "response_order",
          data.purchase.credit_count,
          data.purchase.price_in_cents
        )
      }
      trackOrderActivated(variables.body.requested_response_count)
      responsesOrderedGoogle()

      void queryClient.invalidateQueries(["api", "account", "total_credits"])

      toast({
        title:
          "Your order has been placed. You should start receiving responses any minute!",
        status: "success",
        isClosable: false,
      })

      navigate(
        ROUTES.TEST.RECRUIT.buildPath({
          testId: variables.body.usability_test_unique_id,
        })
      )
    },
    onError: (e) => {
      toast({
        title: "Error",
        description: e.payload.message,
        status: "error",
        isClosable: false,
      })
    },
    onSettled: () => {
      setIsSubmittingOrder(false)
      setOrderCreateState("idle")
      // TODO: do we need to clear isConfirming here somehow?
    },
  })

  const submitOrder = (
    usabilityTestUniqueId: string,
    requestedResponseCount: number,
    quote: CalculatePriceAndEstimatedDurationResponse,
    isConfirming: boolean
  ) => {
    if (!isConfirming && quote.credits_to_be_purchased > 0) {
      confirmCreditPurchase()
      return
    }

    // Prevent double-click
    if (isSubmittingOrder) return
    setIsSubmittingOrder(true)

    setOrderCreateState("loading")

    createOrder({
      body: {
        usability_test_unique_id: usabilityTestUniqueId,
        requested_response_count: requestedResponseCount,
        demographic_target_id: quote.demographic_target_id!,
        estimated_incidence_rate: quote.estimated_incidence_rate,
      },
    })
  }

  return { orderCreateState, submitOrder }
}
