import React from "react"

import { FilterOptionLabel, FilterValue } from "./filter-option-label"
import { ResponseFilter } from "./filters/types"
import { removeFilterById } from "./remove-filter"
import { FilterOption } from "./types"

export const createFilterOption = (
  filterValue: FilterValue,
  updateFilterFunction: (
    updateFn: (filters: ResponseFilter[]) => ResponseFilter[]
  ) => void
): FilterOption => {
  return {
    label: <FilterOptionLabel filterValue={filterValue} />,
    clearAction: () =>
      updateFilterFunction((filters) =>
        removeFilterById(filters, filterValue.filterId)
      ),
  }
}
