import React, { useEffect, useState } from "react"
import { useGetRedirectSession } from "~/api/generated/usabilityhub-components"
import { RedirectToLyssnaLoadingScreen } from "./RedirectToLyssnaLoadingScreen"

export const RedirectToLyssna = () => {
  const time_to_wait = 3000 // how long to wait before redirecting in ms
  const [timerFinished, setTimerFinished] = useState(false)
  const [uuid, setUuid] = useState<string | null>(null)

  useEffect(() => {
    // even if we reach the backend fast enough, let the screen display
    // at least for 3 seconds (or what is set by time_to_wait)
    setTimeout(() => {
      setTimerFinished(true)
    }, time_to_wait)

    // If we haven't redirected them within 10 seconds since this component has rendered,
    // send them to the lyssna login page. This might happen if there's an error or really
    //  bad internet connection
    setTimeout(() => {
      location.replace(location.href.replace("usabilityhub", "lyssna"))
    }, 10000)
  }, [])

  const { data, isSuccess } = useGetRedirectSession({})

  function redirectPost(url: string, data: { [x: string]: string }) {
    const form = document.createElement("form")
    document.body.appendChild(form)
    form.method = "post"
    form.action = url
    for (const name in data) {
      const input = document.createElement("input")
      input.type = "hidden"
      input.name = name
      input.value = data[name]
      form.appendChild(input)
    }
    form.submit()
  }

  useEffect(() => {
    if (data && isSuccess) {
      // use temp to prevent too many state changes
      let tempUuid = data
      if (typeof data === "string") tempUuid = JSON.parse(data)["uuid"]
      else tempUuid = data["uuid"]

      setUuid(tempUuid)
    }
  }, [data, isSuccess])

  useEffect(() => {
    if (uuid && timerFinished) {
      if (uuid === "redirect")
        // Why all the location replaces? Because in test environments, we append
        // the port to all the hosts and it's not in the env variables, so to keep it
        // working nicely in our dev environments, this will do the job
        window.location.replace(location.href.replace("usabilityhub", "lyssna"))
      else
        redirectPost(
          `//${location.host.replace(
            "usabilityhub",
            "lyssna"
          )}/redirect_session`,
          {
            uuid: uuid,
            path:
              window.location.pathname +
              window.location.search +
              window.location.hash,
          }
        )
    }
  }, [timerFinished, uuid])

  return <RedirectToLyssnaLoadingScreen />
}
