import { QuestionType } from "Types"

// The order questions are displayed as options in the UI
export const ORDERED_QUESTION_TYPES = [
  QuestionType.ShortAnswer,
  QuestionType.LongAnswer,
  QuestionType.RadioMultipleChoice,
  QuestionType.CheckboxMultipleChoice,
  QuestionType.LinearScale,
  QuestionType.Ranking,
]

export const QuestionTypes = {
  [QuestionType.ShortAnswer]: {
    label: "Short text",
  },
  [QuestionType.LongAnswer]: {
    label: "Long text",
  },
  [QuestionType.RadioMultipleChoice]: {
    label: "Single Choice",
  },
  [QuestionType.CheckboxMultipleChoice]: {
    label: "Multiple Choice",
  },
  [QuestionType.LinearScale]: {
    label: "Linear scale",
  },
  [QuestionType.Ranking]: {
    label: "Ranking",
  },
}
