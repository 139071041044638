import React from "react"

import { PhoneInfo } from "./AdminPhoneInfo"

// The fraudScore is only applicable to IPQS verification records.
// Otherwise, it will be null.
export const AdminPhoneFraudStatus: React.FC<PhoneInfo> = ({
  country,
  userCountry,
  isVoip,
  fraudScore,
  highRiskCountryCodes,
}) => {
  const countryMatches = country === userCountry

  // According to IPQS documentation, Fraud Scores 75+ are suspicious,
  // while Scores 85+ are risky.
  // Ref: https://www.ipqualityscore.com/documentation/phone-number-validation-api/overview#field-definitions
  const status =
    isVoip ||
    (!countryMatches && highRiskCountryCodes.includes(country)) ||
    fraudScore >= 85
      ? "alert"
      : fraudScore >= 75 || !countryMatches
        ? "warn"
        : "ok"

  return <span className={`FraudStatus FraudStatus--${status}`} />
}
