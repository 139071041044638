import { useSectionContext } from "UsabilityHub/contexts"
import { getPrototypeType } from "Utilities/get-prototype-type"

export function usePrototypeType() {
  const { section } = useSectionContext()

  return getPrototypeType(section)
}

// ts-prune-ignore-next
export function useIsPrototypeTaskFlow() {
  const prototypeType = usePrototypeType()

  return prototypeType === "task_flow"
}

export function useIsPrototypeFreeRoam() {
  const prototypeType = usePrototypeType()

  return prototypeType === "free_roam"
}
