import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react"
import { PurchasesMailtoLink } from "Shared/components/Links/PurchasesMailtoLink"
import {
  useActiveStripeSubscription,
  useCurrentAccount,
} from "UsabilityHub/hooks/useCurrentAccount"
import { useIsTrialing } from "Utilities/account"
import { getDateString } from "Utilities/date-formats"
import {
  billingPeriodForPlan,
  isPaid,
  planCostPerAdditionalSeatInInterval,
} from "Utilities/plan"
import { getRoleOption } from "Utilities/role"
import { FormikProps } from "formik"
import React from "react"
import { Plan } from "~/api/generated/usabilityhubSchemas"
import { InviteUserFormValues } from "./InviteUserForm"

type InviteUserModal = React.FC<
  React.PropsWithChildren<
    {
      currentPlan: Plan
      hasPaidSeatAvailable: boolean
      isSubmitting: boolean
      user: InviteUserFormValues
    } & Pick<React.ComponentProps<typeof Modal>, "isOpen" | "onClose"> &
      Pick<FormikProps<InviteUserFormValues>, "submitForm">
  >
>

export const InviteUserModal: InviteUserModal = ({
  currentPlan,
  hasPaidSeatAvailable,
  isOpen,
  isSubmitting,
  onClose,
  submitForm,
  user,
}) => {
  const account = useCurrentAccount()
  const activeStripeSubscription = useActiveStripeSubscription()

  const roleOption = getRoleOption(user.role)
  const rolePreposition = `${user.role !== "guest" ? "an" : "a"}`
  const roleString = `${rolePreposition} ${roleOption.name}`
  const requiresPayment = roleOption.paid && isPaid(currentPlan)
  const userCost = planCostPerAdditionalSeatInInterval(currentPlan)

  const isManualSubscriptionAtCollaboratorSeatLimit =
    account.manual_subscription && !hasPaidSeatAvailable
  const isStripeSubscriptionAtCollaboratorSeatLimit =
    !hasPaidSeatAvailable &&
    !account.manual_subscription &&
    currentPlan.collaborator_seat_limit !== null &&
    account.paid_seat_count! >= currentPlan.collaborator_seat_limit
  const isDisabled =
    (isManualSubscriptionAtCollaboratorSeatLimit ||
      isStripeSubscriptionAtCollaboratorSeatLimit) &&
    user.role !== "guest"

  const isTrialing = useIsTrialing()

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Invite {roleString}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentPlan.viewer_permissions && !roleOption.paid ? (
              <Text>
                We{"\u2019"}ll send {user.email} an email invitation asking them
                to join your workspace.
              </Text>
            ) : hasPaidSeatAvailable ? (
              <Text>
                You have an available seat on your account, so inviting{" "}
                {user.email} as {roleString} won{"\u2019"}t cost you anything.
              </Text>
            ) : !account.manual_subscription &&
              currentPlan.collaborator_seat_limit !== null &&
              account.paid_seat_count! >=
                currentPlan.collaborator_seat_limit ? (
              <Text>
                You have reached the maximum number of collaborators allowed on
                your subscription. To add more collaborators to your team you
                {"\u2019"}ll need to upgrade your plan.
              </Text>
            ) : account.manual_subscription ? (
              <Text>
                You have reached the maximum number of collaborators allowed on
                your subscription. To add more users to your team, please
                contact <PurchasesMailtoLink />.
              </Text>
            ) : (
              <Stack spacing={3}>
                <Text>
                  Each additional collaborator on the {currentPlan.name} plan
                  costs <strong>{userCost}</strong>.
                </Text>
                {isTrialing ? (
                  <Text>
                    You{"\u2019"}ll be charged the{" "}
                    {billingPeriodForPlan(currentPlan)} fee when your trial ends
                    on {getDateString(activeStripeSubscription!.trial_end!)}.
                  </Text>
                ) : (
                  <Text>
                    You{"\u2019"}ll be charged the pro-rated{" "}
                    {billingPeriodForPlan(currentPlan)} fee when you send the
                    invite.
                  </Text>
                )}
              </Stack>
            )}
          </ModalBody>
          {isManualSubscriptionAtCollaboratorSeatLimit &&
          user.role !== "guest" ? (
            <ModalFooter>
              <Button onClick={onClose} mr={3}>
                Okay
              </Button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button onClick={onClose} mr={3}>
                Cancel
              </Button>
              <Button
                isLoading={isSubmitting}
                isDisabled={isDisabled}
                onClick={submitForm}
                colorScheme="brand.primary"
              >
                Invite {requiresPayment && !hasPaidSeatAvailable && "and pay"}
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
