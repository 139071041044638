import { CardSortFilter } from "../filters/card-sort"

import { generateFilterId } from "./generate-filter-id"

function createCardSortFilterCategory(
  sectionId: number
): CardSortFilter["category"] {
  return `answer/section:${sectionId}/card-sort`
}

export function createCardSortFilter(
  sectionId: number,
  cardId: number,
  categoryIds: number[]
): CardSortFilter {
  return {
    id: generateFilterId(),
    category: createCardSortFilterCategory(sectionId),
    type: "answer/card-sort",
    value: { cardId, categoryIds },
  }
}
