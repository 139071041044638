import { useShowNotice } from "UsabilityHub/hooks/useShowNotice"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"

type Props = DismissibleBannerPersistedProps | DismissibleBannerUnpersistedProps

// banner with noticeSlug persisted to DB to record dismissal
type DismissibleBannerPersistedProps = {
  noticeSlug: Parameters<typeof useShowNotice>[0]
  children: (renderProps: RenderProps) => React.ReactNode
}

// banner with no persistence that reappears on refresh
type DismissibleBannerUnpersistedProps = {
  noticeSlug?: never
  children: (renderProps: RenderProps) => React.ReactNode
}

type RenderProps = {
  dismissBanner: () => void
}

/**
 * Wrapper component for dismissable banners that manages show/hide state and
 * the exit animation. It provides the `dismissBanner` function as a render prop
 * for you to attach it to the `onClick` of your banner's close button.
 *
 * To persist whether the banner has been dismissed, pass a `noticeSlug` prop.
 * This is used by the `useShowNotice` hook which in turn relies on a typed list
 * of possible notice names from the back end (as `UserDismissedNotice`
 * records). To clear local notices in the Rails console so you can see your
 * banner again, use `UserDismissedNotice.delete_all`.
 *
 * @example
 * <DismissableBanner noticeSlug="our_cool_new_feature_banner">
 *   {({ dismissBanner }) => (
 *     <YourBannerComponent
 *       onDismiss={dismissBanner}
 *     />
 *   )}
 * </DismissableBanner>
 */
export const DismissableBanner: React.FC<Props> = ({ noticeSlug, children }) =>
  noticeSlug === undefined ? (
    <DismissableBannerUnpersisted>{children}</DismissableBannerUnpersisted>
  ) : (
    <DismissableBannerPersisted noticeSlug={noticeSlug}>
      {children}
    </DismissableBannerPersisted>
  )

const DismissableBannerPersisted: React.FC<DismissibleBannerPersistedProps> = ({
  noticeSlug,
  children,
}) => {
  const [showBanner, dismissBanner] = useShowNotice(noticeSlug)
  const props = { showBanner, dismissBanner, children }

  return <BaseDismissableBanner {...props} />
}

const DismissableBannerUnpersisted: React.FC<
  DismissibleBannerUnpersistedProps
> = ({ children }) => {
  const [showBanner, setShowBanner] = useState(true)
  const dismissBanner = () => setShowBanner(false)
  const props = { showBanner, dismissBanner, children }

  return <BaseDismissableBanner {...props} />
}

type BaseDismissableBannerProps = {
  showBanner: boolean
  dismissBanner: () => void
  children: (renderProps: RenderProps) => React.ReactNode
}

const BaseDismissableBanner: React.FC<BaseDismissableBannerProps> = ({
  showBanner,
  dismissBanner,
  children,
}) => (
  <AnimatePresence>
    {showBanner && (
      <motion.div
        initial={{ height: "auto", overflow: "hidden" }}
        animate={{ height: "auto", transitionEnd: { overflow: "unset" } }}
        exit={{ height: 0, overflow: "hidden" }}
      >
        {children({ dismissBanner })}
      </motion.div>
    )}
  </AnimatePresence>
)
