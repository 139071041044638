import { createIcon } from "@chakra-ui/react"
import React from "react"

export const MicrophoneIcon = createIcon({
  displayName: "MicrophoneIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="microphone">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66667 4.66665C4.66667 2.8257 6.15905 1.33331 8 1.33331C9.84095 1.33331 11.3333 2.8257 11.3333 4.66665V8.66665C11.3333 10.5076 9.84095 12 8 12C6.15905 12 4.66667 10.5076 4.66667 8.66665V4.66665ZM8 2.66665C6.89543 2.66665 6 3.56208 6 4.66665V8.66665C6 9.77121 6.89543 10.6666 8 10.6666C9.10457 10.6666 10 9.77121 10 8.66665V4.66665C10 3.56208 9.10457 2.66665 8 2.66665ZM2.66667 7.33331C3.03486 7.33331 3.33333 7.63179 3.33333 7.99998V8.66665C3.33333 11.244 5.42267 13.3333 8 13.3333C10.5773 13.3333 12.6667 11.244 12.6667 8.66665V7.99998C12.6667 7.63179 12.9651 7.33331 13.3333 7.33331C13.7015 7.33331 14 7.63179 14 7.99998V8.66665C14 11.9804 11.3137 14.6666 8 14.6666C4.68629 14.6666 2 11.9804 2 8.66665V7.99998C2 7.63179 2.29848 7.33331 2.66667 7.33331Z"
        fill="currentColor"
      />
    </g>
  ),
})
