import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useCallback, useEffect, useReducer, useRef } from "react"
import { useInviteTeamMember } from "~/api/generated/usabilityhub-components"
import { EmailAddress } from "./types"

type InivitationUser = Pick<
  ReturnType<typeof useCurrentUser>,
  "email" | "name" | "role"
>

type Result =
  | InivitationUser
  | {
      status: "pending"
    }
  | {
      status: "failure"
      error: string
    }

type Action =
  | {
      email: EmailAddress
      result: Result
    }
  | "clearErrors"

export type InviteTeamMembersResults = Map<EmailAddress, Result>

type InviteResult = InviteTeamMembersResults extends Map<any, infer I>
  ? I
  : never

export const isSuccessful = (
  v: InviteResult | undefined
): v is InivitationUser =>
  !!v &&
  (!("status" in v) || (v.status !== "failure" && v.status !== "pending"))

export const isFailed = (
  v: InviteResult | undefined
): v is Extract<InviteResult, { status: "failure" }> =>
  !!v && "status" in v && v.status === "failure"

const noop = () => void 0

export const useInviteTeamMembers = () => {
  const [results, dispatch] = useReducer(
    (state: InviteTeamMembersResults, action: Action) => {
      if (action === "clearErrors") {
        return new Map([...state.entries()].filter(([, v]) => !isFailed(v)))
      } else {
        return new Map(state).set(action.email, action.result)
      }
    },
    new Map()
  )

  const resultsRef = useRef(results)

  useEffect(() => {
    resultsRef.current = results
  }, [results])

  const next = useRef<() => void>(noop)
  const fail = useRef<() => void>(noop)

  const { mutateAsync: inviteTeamMember, isLoading } = useInviteTeamMember({
    onSuccess: (_, variables) => {
      const user = variables.body
      dispatch({
        email: user.email as EmailAddress,
        result: user as InivitationUser,
      })
      next.current?.()
    },
    onError: (error, { body }) => {
      dispatch({
        email: body.email as EmailAddress,
        result: { status: "failure", error: error.payload.message },
      })
    },
  })

  const invite = useCallback(
    (
      emails: EmailAddress[],
      callback: (results: InviteTeamMembersResults) => void
    ) => {
      if (!emails.length) {
        callback(results)
        return
      }

      dispatch("clearErrors")

      const doInvite = ([email, ...remaining]: EmailAddress[]) => {
        next.current = () => {
          if (remaining.length) {
            doInvite(remaining)
          } else {
            callback(resultsRef.current)
          }
        }
        fail.current = () => callback(resultsRef.current)
        dispatch({ email, result: { status: "pending" } })
        inviteTeamMember({
          body: {
            name: null,
            email,
            role: "admin",
          },
        }).catch((error) => {
          callback(
            new Map(resultsRef.current).set(email, {
              status: "failure",
              error: error.payload.message,
            })
          )
        })
      }

      doInvite(emails)
    },
    [results]
  )

  return { invite, isLoading, results }
}
