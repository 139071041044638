import {
  NumberAttribute,
  ResponseAttribute,
} from "Components/test-results/types/attributes"
import { Filter } from "Components/test-results/types/filters"

import { getAttribute } from "../get-attribute"
import { getAttributeCategoryFromCategory } from "../get-attribute-from-category"

import { FilterFunction } from "./types"

export type AgeFilter = Filter<
  "response/attribute:age",
  "attribute/range",
  { max: number | null; min: number | null }
>

export type AttributeNumberRangeFilter = AgeFilter

export const filterResponseByAttributeRange: FilterFunction<
  AttributeNumberRangeFilter
> = ({ response, filter }) => {
  const rangeAttribute = getAttribute(
    response,
    getAttributeCategoryFromCategory(filter.category)!
  )

  if (!rangeAttribute || !attributeIsNumeric(rangeAttribute)) return false

  if (rangeAttribute.value < (filter.value.min ?? Number.NEGATIVE_INFINITY))
    return false
  if (rangeAttribute.value > (filter.value.max ?? Number.POSITIVE_INFINITY))
    return false

  return true
}

const attributeIsNumeric = (
  attribute: ResponseAttribute
): attribute is NumberAttribute => {
  return typeof attribute.value === "number"
}
