import { ButtonProps, Link } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Button } from "DesignSystem/components"
import React from "react"

export const ContactSupportButton: React.FC<ButtonProps> = ({ ...props }) => {
  const email = Constants.CUSTOMER_SUPPORT_EMAIL_ADDRESS

  const buttonOrLinkProps = window.Intercom
    ? {
        onClick: () => window.Intercom?.("show"),
      }
    : {
        as: Link,
        href: `mailto:${email}`,
        isExternal: true,
      }

  return (
    <Button {...buttonOrLinkProps} {...props}>
      Contact support
    </Button>
  )
}
