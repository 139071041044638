import useSize from "@react-hook/size"
import React, { useRef } from "react"

import { PreferenceCarousel } from "Components/preference-test-layout/preference-carousel"
import { PreferenceCarouselProps } from "Components/preference-test-layout/preference-carousel"
import PreferenceOverview from "Components/preference-test-layout/preference-overview"
import PreferenceOverviewProps from "Components/preference-test-layout/preference-overview-props"
import { SectionTaskContent } from "UsabilityHub/components/UsabilityTestSectionTask/SectionTask"

export const OverviewSectionTaskContent: React.FC<
  React.PropsWithChildren<PreferenceOverviewProps>
> = (props) => {
  return (
    <SectionTaskContent position="relative">
      <PreferenceOverview {...props} />
    </SectionTaskContent>
  )
}

type CarouselSectionTaskContentProps = Omit<
  PreferenceCarouselProps,
  "maxPlayableSize"
>

export const CarouselSectionTaskContent: React.FC<
  React.PropsWithChildren<CarouselSectionTaskContentProps>
> = (props) => {
  const safeHeight = 48 // Safe height prevents carousel from exceeding content height
  const contentRef = useRef(null)
  const [width, height] = useSize(contentRef)
  return (
    <SectionTaskContent ref={contentRef}>
      <PreferenceCarousel
        maxPlayableSize={{ width, height: height - safeHeight }}
        {...props}
      />
    </SectionTaskContent>
  )
}
