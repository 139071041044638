import { createIcon } from "@chakra-ui/react"
import React from "react"

export const MicrosoftOutlookIcon = createIcon({
  displayName: "MicrosoftOutlookIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path
        d="M13.3991 2H5.9344C5.6024 2 5.33307 2.26933 5.33307 2.60067V3.33333L9.99974 4.33333L13.9997 3.33333V2.60067C13.9997 2.26933 13.7311 2 13.3991 2Z"
        fill="#0364B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5001 7.58171C14.5968 7.62971 14.6648 7.72704 14.6648 7.84238C14.6648 7.94904 14.6048 8.03838 14.5201 8.08971V8.20771L9.74614 10.927C9.72547 10.9404 9.70414 10.953 9.68214 10.9637C9.59414 11.0077 9.49414 11.0324 9.38881 11.0324C9.28347 11.0324 9.18414 11.0077 9.09548 10.9637C9.07347 10.953 9.05214 10.9404 9.03148 10.927L4.27014 8.19571V7.94171H4.34281V7.61638L9.10481 4.90838C9.12547 4.89504 9.14681 4.88304 9.16881 4.87238C9.25747 4.82838 9.35681 4.80371 9.46214 4.80371C9.56748 4.80371 9.66681 4.82838 9.75548 4.87238C9.77747 4.88304 9.79881 4.89504 9.81948 4.90838L14.4995 7.57904L14.5001 7.58171ZM9.46214 7.88238V7.95371H9.71347L9.46214 7.88238Z"
        fill="#0A2767"
      />
      <path
        d="M5.33334 3.3335H10L14 6.66683V11.3335H10L5.33334 6.66683V3.3335Z"
        fill="#28A8EA"
      />
      <path d="M10 6.66683H14V3.3335H10V6.66683Z" fill="#50D9FF" />
      <path d="M5.33334 12.6665H10V6.6665H5.33334V12.6665Z" fill="#176EBE" />
      <path
        opacity="0.5"
        d="M9.32347 11.6668L4.20547 8.09742L4.47814 7.61809C4.47814 7.61809 9.17881 10.3148 9.26347 10.3614C9.28481 10.3714 9.34881 10.3781 9.39547 10.3548C9.46548 10.3154 14.3115 7.57275 14.3115 7.57275L14.4335 8.14142L9.32347 11.6668Z"
        fill="#0A2767"
      />
      <path
        d="M14.4166 13.8941C14.5639 13.7941 14.6666 13.6341 14.6666 13.4441V7.84277C14.6666 7.95077 14.6073 8.04544 14.5186 8.09611L14.5126 8.10011C14.5119 8.10011 14.5113 8.10011 14.5113 8.10077L9.72527 10.8108C9.7046 10.8234 9.6286 10.8594 9.6286 10.8594C9.53927 10.9034 9.4366 10.9281 9.32993 10.9281C9.30393 10.9281 9.27927 10.9214 9.25327 10.9181L10.3859 13.1441L14.4166 13.8941Z"
        fill="#1490DF"
      />
      <path
        opacity="0.1"
        d="M9.8556 10.729L9.6876 10.825C9.66694 10.8383 9.59227 10.8743 9.59227 10.8743C9.5036 10.9177 9.40227 10.943 9.29694 10.943C9.20094 10.943 9.10427 10.9177 9.02094 10.8797V10.893L11.3336 12.9997L14.3569 13.945C14.5029 13.845 14.6076 13.761 14.6623 13.549V13.507L9.8556 10.729Z"
        fill="black"
      />
      <path
        d="M4.57721 13.988L13.9859 14C14.2232 13.996 14.2819 13.9907 14.4212 13.8973L9.14988 10.8587C9.12721 10.8473 9.10521 10.8353 9.08388 10.822L4.16388 8.09467C4.16321 8.09467 4.16321 8.094 4.16254 8.094L4.15988 8.09267L3.99988 8V13.4373C3.99988 13.744 4.25988 13.988 4.57721 13.988Z"
        fill="#28A8EA"
      />
      <path
        opacity="0.5"
        d="M9.16321 12.6665C9.58788 12.6665 9.99988 12.2592 9.99988 11.8925V5.33317C9.99988 4.9665 9.68521 4.6665 9.30054 4.6665H5.20388V6.95184V7.22184L4.16321 7.7865L4.15788 7.79117C4.06521 7.84384 4.00254 7.92317 4.00254 8.03317C4.00254 8.04717 3.99988 8.05784 3.99988 8.04117H4.01121V12.6665H9.16321Z"
        fill="black"
      />
      <path
        d="M8.66668 12H2.00001C1.63334 12 1.33334 11.7 1.33334 11.3333V4.66667C1.33334 4.3 1.63334 4 2.00001 4H8.66668C9.03334 4 9.33334 4.3 9.33334 4.66667V11.3333C9.33334 11.7 9.03334 12 8.66668 12Z"
        fill="#0078D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.0446 5.75273C3.6706 5.97806 3.38726 6.29073 3.19593 6.69073C3.00393 7.09073 2.90793 7.5474 2.90793 8.06073C2.90793 8.54673 3.00526 8.9814 3.2006 9.3634C3.39593 9.74606 3.67526 10.0454 4.03793 10.2614C4.4006 10.4774 4.82593 10.5861 5.31393 10.5861C5.80926 10.5861 6.24126 10.4761 6.60993 10.2561C6.97793 10.0367 7.26193 9.7274 7.46059 9.32873C7.65926 8.93006 7.75859 8.47006 7.75859 7.9494C7.75859 7.45406 7.66193 7.01473 7.46926 6.6314C7.27659 6.2474 7.0006 5.94873 6.6426 5.73473C6.2846 5.5214 5.86859 5.41406 5.39459 5.41406C4.8686 5.41406 4.41926 5.52673 4.0446 5.75273ZM4.5786 9.51006C4.36926 9.36006 4.20993 9.15406 4.1006 8.89273C3.99126 8.6314 3.9366 8.3354 3.9366 8.0054C3.9366 7.6754 3.9926 7.37806 4.10393 7.11406C4.21593 6.85073 4.3786 6.64273 4.5926 6.4914C4.80593 6.34073 5.0606 6.2654 5.35593 6.2654C5.6766 6.2654 5.93993 6.34673 6.14593 6.5094C6.35193 6.67206 6.50126 6.88606 6.59393 7.15073C6.68726 7.41606 6.73326 7.70673 6.73326 8.02273C6.73326 8.33873 6.68393 8.62606 6.58526 8.88406C6.4866 9.14206 6.33193 9.34873 6.12126 9.5034C5.91126 9.6574 5.64526 9.73473 5.3246 9.73473C5.0366 9.73473 4.78793 9.66006 4.5786 9.51006Z"
        fill="white"
      />
    </>
  ),
})
