import { Box, BoxProps, HStack, Stack } from "@chakra-ui/react"
import { Radio } from "DesignSystem/components/Radio"
import React, { ComponentPropsWithRef, forwardRef, ReactNode } from "react"

type RadioCardsContextType<T extends string> = {
  name: string
  value: T
  onChange: (T: string) => void
}

const RadioCardsContext =
  React.createContext<RadioCardsContextType<string> | null>(null)

const useRadioCardsContext = <T extends string>() => {
  const context = React.useContext(
    RadioCardsContext
  ) as RadioCardsContextType<T> | null
  if (!context) {
    throw new Error(
      "RadioCards compound components cannot be rendered outside the RadioCards component"
    )
  }
  return context
}

const RadioCardsRoot = <T extends string>({
  name,
  value,
  onChange,
  children,
  ...props
}: RadioCardsContextType<T> & Omit<BoxProps, "onChange">) => (
  <RadioCardsContext.Provider value={{ name, value, onChange }}>
    <HStack spacing={4} align="stretch" {...props}>
      {children}
    </HStack>
  </RadioCardsContext.Provider>
)

type RadioCardsItemProps<T extends string> = BoxProps & {
  value: T
  isDisabled?: boolean
  description: ReactNode
}

const RadioCardsItem = forwardRef(
  <T extends string>(
    {
      description,
      value,
      isDisabled,
      children,
      ...props
    }: RadioCardsItemProps<T>,
    ref: ComponentPropsWithRef<"label">["ref"]
  ) => {
    const { value: selectedValue, name, onChange } = useRadioCardsContext<T>()
    const isChecked = selectedValue === value

    return (
      <HStack
        ref={ref}
        as="label"
        align="center"
        spacing={4}
        p={4}
        rounded={8}
        cursor="pointer"
        border="1px solid"
        borderColor={isChecked ? "ds.border.selected" : "ds.border.default"}
        bg={
          isChecked
            ? "ds.background.selected.subtle.resting"
            : "ds.surface.default"
        }
        _hover={{
          bg:
            !isChecked && !isDisabled
              ? "ds.background.neutral.subtle.hovered"
              : undefined,
        }}
        opacity={isDisabled ? 0.5 : undefined}
        {...props}
      >
        <Radio
          name={name}
          value={value}
          isChecked={isChecked}
          isDisabled={isDisabled}
          onCheckedChange={(checked) => {
            if (checked) {
              onChange(value)
            }
          }}
        />
        <Stack spacing={2}>
          <HStack spacing={2} align="center" textStyle="ds.heading.secondary">
            {children}
          </HStack>
          <Box textStyle="ds.paragraph.secondary" color="ds.text.subtle">
            {description}
          </Box>
        </Stack>
      </HStack>
    )
  }
)

export const RadioCards = {
  Root: RadioCardsRoot,
  Item: RadioCardsItem,
}
