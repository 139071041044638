import { Box, Flex, Spinner, useToast } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Navigate } from "react-router"
import { useTypedParams } from "react-router-typesafe-routes/dom"

import { TestSetRoute } from "Components/routes/test-set/test-set"
import { PageContent, PageMain } from "Shared/components/Page/Page"
import { useGetVariationSet } from "~/api/generated/usabilityhub-components"

import { ROUTES } from "UsabilityHub/views/routes"

export function ShowTestSetPage() {
  const { testSetId } = useTypedParams(ROUTES.TEST_SET)
  const { data, error } = useGetVariationSet(
    {
      pathParams: {
        uniqueId: testSetId,
      },
    },
    {
      enabled: !!testSetId,
      refetchOnMount: "always",
    }
  )
  const testSet = data?.variation_set

  const toast = useToast()

  useEffect(() => {
    if (error) {
      toast({
        title:
          error.payload.message ??
          "Lyssna has encountered an error while fetching data",
        status: "error",
      })
    }
  }, [error])

  if (error) {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <PageMain>
      <Helmet>
        <title>{testSet ? `Set • ${testSet.name}` : "Loading"}</title>
      </Helmet>
      <PageContent maxW="6xl">
        <Box my={30}>
          {!testSet && (
            <Flex
              mt="32"
              justifyContent="center"
              alignItems="center"
              direction="column"
              gap="4"
            >
              <Spinner size="lg" thickness="4px" color="gray.500" />
              Fetching variation set...
            </Flex>
          )}
          {testSet && <TestSetRoute testSet={testSet} />}
        </Box>
      </PageContent>
    </PageMain>
  )
}
