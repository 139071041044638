import classNames from "classnames"
import React, { MouseEvent, PureComponent } from "react"

import Handle from "Components/hitzone/handle"
import { Hitzone, Props as HitzoneProps } from "Components/hitzone/hitzone"
import styles from "Components/hitzone/hitzone.module.scss"
import { ClientId, Corner } from "Types"
import { isLeftButton } from "Utilities/mouse-event"

const CORNERS: ReadonlyArray<Corner> = [
  [0, 0],
  [1, 0],
  [1, 1],
  [0, 1],
]

function stopDragEvent(event: MouseEvent<any>) {
  // Prevent the mousedown from starting a drag-select.
  event.preventDefault()

  // Prevent the event from bubbling up to parent elements that have different
  // mouse event handlers.
  event.stopPropagation()
}

interface Props extends HitzoneProps {
  displayNumber?: number
  isTooSmall: boolean
  isActive: boolean
  onHandleMouseDown: (
    clientId: ClientId,
    corner: Corner,
    event: MouseEvent<HTMLElement>
  ) => void
  onCenterMouseDown: (
    clientId: ClientId,
    event: MouseEvent<HTMLElement>
  ) => void
}

export class EditableHitzone extends PureComponent<Props> {
  // -- Event handlers --

  private handleHandleMouseDown = (
    corner: Corner,
    event: MouseEvent<HTMLElement>
  ) => {
    if (isLeftButton(event)) {
      stopDragEvent(event)
      const { onHandleMouseDown, hitzone } = this.props
      onHandleMouseDown(hitzone._clientId, corner, event)
    }
  }

  private handleCenterMouseDown = (event: MouseEvent<HTMLElement>) => {
    if (isLeftButton(event)) {
      stopDragEvent(event)
      const { onCenterMouseDown, hitzone } = this.props
      onCenterMouseDown(hitzone._clientId, event)
    }
  }

  // -- React lifecycle --

  render() {
    const {
      displayNumber,
      onHandleMouseDown,
      onCenterMouseDown,
      hitzone,
      isTooSmall,
      isActive,
      ...rest
    } = this.props
    const handleNodes = CORNERS.map((corner, index) => (
      <Handle
        key={index}
        corner={corner}
        onMouseDown={this.handleHandleMouseDown}
      >
        {index === 0 ? displayNumber : null}
      </Handle>
    ))
    return (
      <Hitzone
        className={classNames(
          styles.hitzone,
          styles.editable,
          isTooSmall && styles.isTooSmall,
          isActive && styles.isActive
        )}
        hitzone={hitzone}
        onMouseDown={this.handleCenterMouseDown}
        {...rest}
      >
        {handleNodes}
      </Hitzone>
    )
  }
}
