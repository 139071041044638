import { FigmaTaskScreen } from "Types"

const byTimeSinceStart = (
  clickA: FigmaTaskScreen["clicks"][0],
  clickB: FigmaTaskScreen["clicks"][0]
) => {
  return (clickA.ms_since_start ?? 0) - (clickB.ms_since_start ?? 0)
}

export function getScreenDuration(
  screens: FigmaTaskScreen[],
  screenIndex: number,
  totalDuration: number
) {
  let expiredTime = 0

  for (let i = 0; i < screens.length; i++) {
    const screen = screens[i]

    let screenTime = 0
    if (screen.clicks.length !== 0) {
      const filteredClicks = [...screen.clicks].filter((c) =>
        Number.isFinite(c.ms_since_start)
      )
      if (filteredClicks.length) {
        const sortedClicks = filteredClicks.sort(byTimeSinceStart)
        const lastClick = sortedClicks[sortedClicks.length - 1]!
        screenTime = (lastClick?.ms_since_start ?? 0) - expiredTime
      } else {
        screenTime = NaN
      }
    } else if (screenIndex === screens.length - 1) {
      screenTime = totalDuration - expiredTime
    }

    expiredTime += screenTime

    if (i === screenIndex) return screenTime
  }

  return NaN
}
