import { Text } from "@chakra-ui/react"
import React from "react"
import { Field } from "redux-form"

import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { useSectionIndexContext } from "UsabilityHub/contexts"

import { SectionCard } from "../SectionCard"
import { SectionInstructionField } from "../SectionFields/SectionInstructionField"
import { SectionMedia } from "../SectionMedia/SectionMedia"
import { SectionQuestions } from "../SectionQuestions/SectionQuestions"
import { SectionLogicActions } from "../TestLogicActions/TestLogicActions"
import { WrappedCheckbox } from "../WrappedCheckbox"

export const PreferenceSectionCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const sectionIndex = useSectionIndexContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <SectionCard>
      <SectionLogicActions />
      <SectionInstructionField placeholder="Which design do you prefer?" />
      <SectionMedia allowedMediaTypes={["image", "video", "audio"]} />

      <Field
        name={`sections[${sectionIndex}].randomized`}
        label="Randomize the order of choices"
        component={WrappedCheckbox}
        disabled={readOnly}
      />

      <Text as="label" fontWeight="semibold">
        Follow up questions
      </Text>
      <SectionQuestions />
    </SectionCard>
  )
}
