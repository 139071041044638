import { Button, Flex, Link, Stack, Text } from "@chakra-ui/react"
import React from "react"
import { Helmet } from "react-helmet"

import { NEW_TAB_ATTRIBUTES } from "Components/anchors/basic"
import { DATA_PROCESSING_AGREEMENT } from "Constants/legal-documents"
import { Document, DocumentIntro } from "Shared/components/Document/Document"
import { RoutedLink } from "Shared/components/Links/RoutedLink"
import { ROUTES } from "UsabilityHub/views/routes"
import { getDateString } from "Utilities/date-formats"

export const DataProcessingAgreementRoute: React.FC = () => {
  return (
    <Stack spacing={8}>
      <Helmet>
        <title>Data Processing Agreement</title>
      </Helmet>
      <Document
        title="Data Processing Agreement"
        downloadUrl={DATA_PROCESSING_AGREEMENT.downloadUrl}
      >
        <DocumentIntro>
          <Text>
            Version {DATA_PROCESSING_AGREEMENT.currentVersion}, last updated on{" "}
            <strong>
              {getDateString(DATA_PROCESSING_AGREEMENT.lastUpdated)}
            </strong>
            .
          </Text>
          <Text>
            Lyssna offers a Data Processing Agreement (<strong>DPA</strong>)
            including Standard Contractual Clauses (<strong>SCCs</strong>) as a
            means of meeting the requirements of the General Data Protection
            Regulation (<strong>GDPR</strong>).
          </Text>
          <Text>
            You can subscribe to be notified when our sub-processor list changes
            from{" "}
            <Link as={RoutedLink} to={ROUTES.LEGAL.SUBPROCESSORS.path}>
              our sub-processor page
            </Link>
            .
          </Text>
          <Text>A copy of our DPA is available for signing via DocuSign.</Text>
          <Flex>
            <Link
              href="https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=e737ac46-9836-44ef-9582-b88d8f7a7d94&env=na4&acct=54162faa-2aba-4a9c-8c20-263955a638e0&v=2"
              {...NEW_TAB_ATTRIBUTES}
            >
              <Button colorScheme="brand.primary">Sign DPA via DocuSign</Button>
            </Link>
          </Flex>
        </DocumentIntro>
      </Document>
    </Stack>
  )
}
