import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react"
import { Trash01SolidIcon } from "Shared/icons/untitled-ui/Trash01SolidIcon"
import { ApplicationToDecline } from "UsabilityHub/views/ModeratedStudy/interviewer/ParticipantInfoDrawer/ParticipantInfoDrawerDeclineButton"
import React from "react"

const SELF_RECRUITED_MESSAGE = `This will mark the applicant as unsuitable for your study. The applicant will not receive any notification of the action.`
const PANEL_MESSAGE = `This will mark the applicant as unsuitable for your study. Lyssna will automatically attempt to source another candidate from our panel.`

type Props = {
  applicationToDecline: ApplicationToDecline
  onSubmit: () => void
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">

export const DeclineApplicantModal: React.FC<Props> = ({
  applicationToDecline,
  onSubmit,
  isOpen,
  onClose,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalContent color="text.primary" maxW="30rem">
      <ModalCloseButton />
      <ModalHeader fontWeight="bold">
        Mark this applicant as unsuitable?
      </ModalHeader>
      <ModalBody pb={4}>
        <Flex flexDirection="column" gap={6}>
          <Text>
            {applicationToDecline.is_panelist
              ? PANEL_MESSAGE
              : SELF_RECRUITED_MESSAGE}
          </Text>
        </Flex>
      </ModalBody>
      <ModalFooter gap={2}>
        <Button variant="outline" onClick={onClose}>
          No, keep them
        </Button>
        <Button
          leftIcon={<Trash01SolidIcon />}
          colorScheme="teal"
          onClick={onSubmit}
        >
          Yes, mark as unsuitable
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
)
