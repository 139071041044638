import { meanBy, sortBy } from "lodash"
import { useSelector } from "react-redux"

import { getAllResponseSections } from "Redux/reducers/test-results/selectors"
import {
  PreferenceSummary,
  Response,
  ResponseSection,
  UsabilityTestSection,
} from "Types"
import { getPreferencePerformance } from "Utilities/preference-test"

import { useFilteredResponses } from "./use-filtered-responses"

const getPreferenceSummary = (
  sectionId: number,
  sectionScreenshotId: number,
  filteredResponses: Response[],
  responseSections: ResponseSection[]
): PreferenceSummary => {
  const filteredResponseSections = responseSections.filter(
    (responseSection) =>
      responseSection.usability_test_section_id === sectionId &&
      filteredResponses.some(
        (response) => response.id === responseSection.response_id
      )
  )

  const filteredScreenshotResponseSections = filteredResponseSections.filter(
    (responseSection) =>
      responseSection.selected_usability_test_section_screenshot_id ===
      sectionScreenshotId
  )

  const totalCount = filteredResponseSections.length
  const count = filteredScreenshotResponseSections.length

  return {
    count,
    percentage: totalCount && count / totalCount,
    averageDuration: meanBy(
      filteredScreenshotResponseSections,
      "task_duration_ms"
    ),
    isWinner: false,
    performance: null,
    answersByQuestionId: {},
  }
}

const getAllSectionPreferenceSummaries = (
  section: UsabilityTestSection,
  filteredResponses: Response[],
  responseSections: ResponseSection[]
) => {
  const summaries = section.section_screenshots.map((sectionScreenshot) =>
    getPreferenceSummary(
      section.id,
      sectionScreenshot.id,
      filteredResponses,
      responseSections
    )
  )

  // If we have exactly two options, and one is the winner, we have the ability
  // to provide performance information.
  if (summaries.length === 2 && summaries[0].count !== summaries[1].count) {
    const [loser, winner] = sortBy(summaries, "count")
    winner.isWinner = true
    winner.performance = getPreferencePerformance(winner.count, loser.count)
  }

  return summaries
}

export const useAllSectionPreferenceSummaries = (
  section: UsabilityTestSection
) => {
  const filteredResponses = useFilteredResponses()
  const responseSections = useSelector(getAllResponseSections)

  return getAllSectionPreferenceSummaries(
    section,
    filteredResponses as Response[],
    responseSections as ResponseSection[]
  )
}
