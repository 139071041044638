import React, { PropsWithChildren, createContext } from "react"

import {
  DemographicProfileIndexResponse,
  useDemographicProfileIndex,
} from "~/api/generated/usabilityhub-components"

interface TesterDemographicsContext {
  demographics: DemographicProfileIndexResponse
  isLoading: boolean
}

export const TesterDemographicsContext =
  createContext<TesterDemographicsContext>({
    demographics: [],
    isLoading: true,
  })

// TODO: now that we're using react-query this provider is causing extra re-renders without
// adding much value. Let's use the hooks directly where they are needed.
export const TesterDemographicsContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { data, isLoading } = useDemographicProfileIndex({})

  return (
    <TesterDemographicsContext.Provider
      value={{
        demographics: data ?? [],
        isLoading,
      }}
    >
      {children}
    </TesterDemographicsContext.Provider>
  )
}
