import { BaseError } from "make-error"
import { nanoid } from "nanoid"

import { ClientId } from "Types"

class NoClientIdError extends BaseError {}

interface Model {
  _clientId: ClientId
}

export function nextClientId(): ClientId {
  return nanoid()
}

export function getClientId({
  _clientId: clientId,
}: Readonly<Model>): ClientId {
  if (clientId == null) {
    throw new NoClientIdError(`clientId is ${String(clientId)}`)
  }
  return clientId
}

/* For predicates:
 *
 *     user.findIndex(matchingClientId(id))
 *
 */
export const matchingClientId =
  (clientId: ClientId) => (model: Readonly<Model>) =>
    model._clientId === clientId
