import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Link,
  List,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"

import { CreditCardFormModalLinkButton } from "Components/credit-card-form-modal/credit-card-form-modal"
import Constants from "Constants/shared.json"
import { CustomerSupportMailtoLink } from "Shared/components/Links/CustomerSupportMailtoLink"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"

export const StripeSubscriptionUnpaidAlert: React.FC<
  React.PropsWithChildren
> = () => {
  return (
    <Alert alignItems="flex-start" status="error">
      <AlertIcon />
      <AlertTitle>There is an issue with your subscription</AlertTitle>
      <AlertDescription>
        <Stack spacing={3}>
          <Text>
            We have been unable to process payment for your subscription after
            multiple attempts, so your account currently has limited access.
          </Text>
          <Text>To resolve this issue, you can:</Text>
          <List styleType="disc">
            <ListItem>
              <CreditCardFormModalLinkButton
                modalButtonText="Save new credit card"
                modalHeading="Change my billing details"
              >
                Update your credit card
              </CreditCardFormModalLinkButton>
            </ListItem>
            <ListItem>
              <PlanDrawerTrigger
                as={Link}
                variant="unstyled"
                verticalAlign="inherit"
                userSelect="initial"
                color="brand.primary.500"
                initialState={{
                  screen: "churn-survey",
                  churnSurveySource: "cancel-subscription",
                }}
                source="plan_settings"
              >
                Downgrade to the {Constants.FREE_PLAN_NAME} plan
              </PlanDrawerTrigger>
            </ListItem>
          </List>
          <Text>
            Please contact <CustomerSupportMailtoLink /> if you need any help.
          </Text>
        </Stack>
      </AlertDescription>
    </Alert>
  )
}
