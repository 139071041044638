import React, { ReactNode } from "react"

import { ClearableFormSection } from "Components/column-form/clearable-form-section"
import {
  Props as CheckboxListProps,
  ExpandableCheckboxList,
} from "Components/form/ExpandableCheckboxList"
import { ReactOption } from "Types"

interface Props<T> extends CheckboxListProps<T> {
  title: ReactNode
}

const getHasData = <T,>(options: ReadonlyArray<ReactOption<T>>): boolean =>
  options.length > 0 && !(options.length === 1 && options[0].value === null)

const NoDataMessage =
  "This test does not have participant data for this demographic"

export const ClearableCheckboxListSection = <T,>({
  title,
  options,
  value,
  ...props
}: Props<T>) => {
  const handleClear = () => props.onChange([] as ReadonlyArray<T>)

  const hasData = getHasData(options)

  return (
    <ClearableFormSection
      title={title}
      onClear={handleClear}
      disabled={value.length === 0}
      infoMessage={hasData ? undefined : NoDataMessage}
    >
      {hasData && (
        <ExpandableCheckboxList options={options} value={value} {...props} />
      )}
    </ClearableFormSection>
  )
}
