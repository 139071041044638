import { createIcon } from "@chakra-ui/react"
import React from "react"

export const TagIcon = createIcon({
  displayName: "TagIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="tag">
      <path
        id="tag_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7778 9.59722C13.9259 9.44907 14 9.27083 14 9.0625C14 8.85417 13.9259 8.67593 13.7778 8.52778L7.31944 2.06944C7.27315 2.02315 7.21759 2 7.15278 2H2.75C2.5463 2 2.37037 2.07407 2.22222 2.22222C2.07407 2.37037 2 2.5463 2 2.75V7.15278C2 7.21759 2.02315 7.27315 2.06944 7.31944L8.52778 13.7778C8.67593 13.9259 8.85417 14 9.0625 14C9.27083 14 9.44907 13.9259 9.59722 13.7778L13.7778 9.59722ZM5.43056 3.83333C5.64352 4.0463 5.75 4.31018 5.75 4.625C5.75 4.93982 5.6412 5.20602 5.42361 5.42361C5.20602 5.6412 4.93982 5.75 4.625 5.75C4.31018 5.75 4.04398 5.6412 3.82639 5.42361C3.6088 5.20602 3.5 4.93982 3.5 4.625C3.5 4.31018 3.6088 4.04398 3.82639 3.82639C4.04398 3.6088 4.31018 3.5 4.625 3.5C4.93982 3.5 5.20833 3.61111 5.43056 3.83333Z"
        fill="currentColor"
      />
    </g>
  ),
})
