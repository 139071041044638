import { useTestResultViewState } from "../context/test-result-view-state"

type IndividualResponseFilter = {
  responseId: number | null
  setResponseId: (responseId: number | null) => void
  recordingResponseSectionId: number | null
}

export const useIndividualResponseFilter = (): IndividualResponseFilter => {
  const { viewState, setViewState } = useTestResultViewState()

  const setResponseId = (responseId: number | null) => {
    setViewState((viewState) => ({
      ...viewState,
      individualResponseView: responseId,
      // reset whether to auto-load any recording
      recordingResponseSectionId: null,
    }))
  }
  return {
    responseId: viewState.individualResponseView,
    setResponseId: setResponseId,
    recordingResponseSectionId: viewState.recordingResponseSectionId,
  }
}
