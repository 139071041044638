import { Flex, FlexProps, Icon, IconButton, MenuButton } from "@chakra-ui/react"
import React from "react"

import { PlusSolidIcon } from "Shared/icons/untitled-ui/PlusSolidIcon"
import { AddFilterButton } from "./add-filter-button"
import { AppliedFilter } from "./applied-filter"
import { useFilterContext } from "./filter-context"

export const FilterList: React.FC<FlexProps> = (flexProps) => {
  const { entityBeingFiltered, filters } = useFilterContext()

  if (filters.length === 0) return null

  return (
    <Flex wrap="wrap" gap={2} {...flexProps}>
      {filters.map((filter) => (
        <AppliedFilter
          key={filter.id}
          entityBeingFiltered={entityBeingFiltered}
          filter={filter}
        />
      ))}

      <AddFilterButton>
        <MenuButton
          as={IconButton}
          size="sm"
          variant="outline"
          aria-label="Add filter"
          icon={<Icon as={PlusSolidIcon} />}
        />
      </AddFilterButton>
    </Flex>
  )
}
