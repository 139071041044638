import { GridItem } from "@chakra-ui/react"
import React from "react"
import { StatusTags, SummaryStatus } from "../TestResultsSummary"
import {
  ParticipantsCard,
  ResponsesCard,
  StatusCard,
} from "./SummaryMetricsCards"
import { SummaryMetricsCardsGrid } from "./SummaryMetricsCardsGrid"

export const SummaryMetrics: React.FC<{
  status: SummaryStatus | null
  statusTags: StatusTags
  participantsCount: number
  filteredResponsesCount: number
  responseCap: number
  usabilityTestUniqueId: string
}> = ({
  status,
  statusTags,
  participantsCount,
  filteredResponsesCount,
  responseCap,
  usabilityTestUniqueId,
}) => (
  <SummaryMetricsCardsGrid>
    <GridItem gridArea="status">
      <StatusCard
        status={status}
        statusTags={statusTags}
        usabilityTestUniqueId={usabilityTestUniqueId}
      />
    </GridItem>
    <GridItem gridArea="participants">
      <ParticipantsCard count={participantsCount} />
    </GridItem>
    <GridItem gridArea="responses">
      <ResponsesCard count={filteredResponsesCount} responseCap={responseCap} />
    </GridItem>
  </SummaryMetricsCardsGrid>
)
