import { createContext } from "react"

interface DragLockContextType {
  isDraggingLocked: boolean
  lockDragging: () => void
  unlockDragging: () => void
}

export const DragLockContext = createContext<DragLockContextType>({
  isDraggingLocked: false,
  lockDragging: () => null,
  unlockDragging: () => null,
})
