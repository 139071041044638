import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Collapse,
  Grid,
  HStack,
  Heading,
  Link,
  Tag,
  Text,
} from "@chakra-ui/react"
import React, { FC } from "react"
import { useSelector } from "react-redux"

import { ExpandChevron } from "Components/expand-chevron/expand-chevron"
import Constants from "Constants/shared.json"
import { useToggleState } from "Hooks/use-toggle-state"
import { State } from "Redux/app-store"
import {
  getCreatorAccount,
  getUsabilityTest,
} from "Redux/reducers/test-results/selectors"

interface Props {
  items: Record<string, string> | null
}

export const QueryParameters: FC<React.PropsWithChildren<Props>> = ({
  items,
}) => {
  const isReadonly = useSelector(
    (state: State) => state.testResults?.isReadonly
  )
  const usabilityTest = useSelector(getUsabilityTest)
  const creatorAccount = useSelector(getCreatorAccount)
  const [isOpened, toggleIsOpened] = useToggleState()

  const featureAvailable =
    usabilityTest.upgraded || creatorAccount.has_query_parameters_feature

  // Render nothing if the feature is available but no data has been saved yet
  if ((!items || Object.keys(items).length === 0) && featureAvailable)
    return null

  // Don't show the upsell-banner on share page
  if (isReadonly && !featureAvailable) return null

  return (
    <Box px={8}>
      <Collapse in={isOpened}>
        <section>
          <Heading>Custom Variables</Heading>
          {featureAvailable && items ? (
            <Grid alignItems="center" templateColumns="repeat(4, 1fr)" py={4}>
              {Object.entries(items).map(([key, value]) => (
                <React.Fragment key={key}>
                  <Text>{key}</Text>
                  <Box>
                    <Tag data-qa="query-param-value" py={2}>
                      {value}
                    </Tag>
                  </Box>
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <div>
              <Alert status="info">
                <AlertIcon />
                <AlertDescription>
                  You{"\u2019"}ll need to upgrade your plan to get access to{" "}
                  <Link href={Constants.HELP_CENTER_QUERY_PARAMETERS_URL}>
                    custom variables
                  </Link>
                  .
                </AlertDescription>
              </Alert>
            </div>
          )}
        </section>
      </Collapse>
      <HStack justify="center">
        <Link
          variant="noUnderline"
          onClick={toggleIsOpened}
          data-qa="toggle-collapse-anchor"
        >
          <ExpandChevron isExpanded={isOpened} />
        </Link>
      </HStack>
    </Box>
  )
}
