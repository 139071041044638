import { createIcon } from "@chakra-ui/react"
import React from "react"

export const WaveIcon = createIcon({
  displayName: "WaveIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="currentColor"
      d="M5.96012 2.38556C5.7099 2.05154 5.72186 1.56336 6.05164 1.25339C6.38142 0.94342 6.98049 1.03109 7.2307 1.36512L11.0545 6.46963C11.1545 6.60304 11.2678 6.76213 11.3488 6.71502C11.3918 6.69001 11.4055 6.64861 11.4037 6.60071C11.2494 6.00201 11.124 5.48246 11.0276 5.04206C10.8979 4.44997 10.8196 3.90799 10.7927 3.41612C10.7637 2.88732 11.0886 2.37447 11.5623 2.20135C12.0359 2.02824 12.3488 2.49578 12.3635 2.6768C12.3781 2.85782 12.4947 3.65758 12.6518 4.37487C12.831 5.19298 13.027 6.00209 13.2397 6.8022C13.3325 7.11737 13.7851 8.88638 13.8932 9.31728C14.3416 11.1043 13.2051 12.5517 12.0634 13.5922C10.1444 15.3411 8.04416 15.6884 6.05164 13.7613C5.06171 12.623 2.15246 8.71291 2.15246 8.71291C1.90224 8.37888 1.94928 7.89043 2.27906 7.58046C2.60884 7.27049 3.19383 7.4558 3.44405 7.78983L4.82667 9.56612C4.92676 9.69973 5.11483 9.70753 5.24674 9.58354C5.37865 9.45955 5.40445 9.25072 5.30437 9.11711L2.31308 5.39999C2.06287 5.06596 2.0942 4.60488 2.42398 4.29491C2.73863 3.99916 3.26607 4.18401 3.52334 4.47514L5.89453 7.1882C5.99462 7.32181 6.18269 7.32961 6.3146 7.20562C6.44652 7.08163 6.47232 6.87281 6.37223 6.73919L3.68411 3.15066C3.4339 2.81663 3.44828 2.33812 3.77806 2.02815C4.10784 1.71818 4.68775 1.86733 4.93797 2.20135L7.56647 5.61669C7.66655 5.7503 7.85463 5.7581 7.98654 5.63411C8.11845 5.51013 8.14425 5.3013 8.04416 5.16769L5.96012 2.38556Z"
    />
  ),
})
