import React, { ChangeEvent, PureComponent } from "react"

import { Checkbox } from "Components/form/checkbox"
import styles from "Components/form/checkbox-list/checkbox-list.module.scss"
import { ReactOption } from "Types"

export interface Props<T> {
  value: ReadonlyArray<T>
  options: ReadonlyArray<ReactOption<T>>
  disabled?: boolean
  onChange: (value: ReadonlyArray<T>) => void
}

export class CheckboxList<T> extends PureComponent<Props<T>> {
  private handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: indexString, checked } = event.currentTarget
    const index = parseInt(indexString, 10)
    const optionValue = this.props.options[index].value
    const { onChange, value } = this.props

    onChange(
      checked ? [...value, optionValue] : value.filter((v) => v !== optionValue)
    )
  }

  render() {
    const { value, options, disabled } = this.props

    return (
      <ul className={styles.list}>
        {options.map((option, i) => (
          <li key={i} className={styles.item}>
            <Checkbox
              key={i}
              disabled={disabled || option.disabled}
              onChange={this.handleChange}
              checked={value.includes(option.value)}
              value={i}
              nowrap
            >
              {option.label}
            </Checkbox>
          </li>
        ))}
      </ul>
    )
  }
}
