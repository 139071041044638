export enum ActionType {
  INIT_RESPONSE_DATA = "INIT_RESPONSE_DATA",
  ADD_RESPONSE_DEMOGRAPHICS = "ADD_RESPONSE_DEMOGRAPHICS",
  ADD_RESPONSE_ANSWER = "ADD_RESPONSE_ANSWER",
  ADD_RESPONSE_SECTION = "ADD_RESPONSE_SECTION",
  ADD_SCREENSHOT_CLICK = "ADD_SCREENSHOT_CLICK",
  KEEPALIVE_REQUEST = "RESPONSE_KEEPALIVE_REQUEST",
  KEEPALIVE_SUCCESS = "RESPONSE_KEEPALIVE_SUCCESS",
  KEEPALIVE_FAILURE = "RESPONSE_KEEPALIVE_FAILURE",
  DISCONNECTED = "DISCONNECTED",
  UPDATE_RESPONSE = "UPDATE_RESPONSE",
  ADD_RESPONSE_SECTION_RECORDING = "ADD_RESPONSE_SECTION_RECORDING",
  UPDATE_RESPONSE_SECTION = "UPDATE_RESPONSE_SECTION",
  CANCEL_RESPONSE_REQUEST = "CANCEL_RESPONSE_REQUEST",
  CANCEL_RESPONSE_SUCCESS = "CANCEL_RESPONSE_SUCCESS",
  CANCEL_RESPONSE_FAILURE = "CANCEL_RESPONSE_FAILURE",
  SUBMIT_RESPONSE_REQUEST = "SUBMIT_RESPONSE_REQUEST",
  SUBMIT_RESPONSE_SUCCESS = "SUBMIT_RESPONSE_SUCCESS",
  SUBMIT_RESPONSE_FAILURE = "SUBMIT_RESPONSE_FAILURE",
  JUMP_TO_SECTION = "JUMP_TO_SECTION",
}
