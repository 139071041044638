import React from "react"
import { Helmet } from "react-helmet"

import { ServicesAgreementDocument } from "./ServicesAgreementDocument"

type ServicesAgreement = React.FC<React.PropsWithChildren<unknown>>

export const ServicesAgreementRoute: ServicesAgreement = () => (
  <>
    <Helmet>
      <title>Services agreement</title>
    </Helmet>
    <ServicesAgreementDocument />
  </>
)
