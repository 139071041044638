import { CardSortCategory, CardSortCategoryCard } from "Types"
import { sortBy } from "lodash"
import { EitherCategory, categoryIsOpen, humanizeGroupLabel } from "./grouping"

export type SortType = "position" | "alphabetically" | "numCards"

interface CategoryGroup {
  label: string
  categories: EitherCategory[]
}

export const SORT_TYPE_LABELS: { [t in SortType]: string } = {
  position: "Default",
  alphabetically: "Alphabetically",
  numCards: "Number of cards",
}

const SORT_FUNCTIONS: {
  [t in SortType]: (
    groups: CategoryGroup[],
    sortData: CardSortCategoryCard[]
  ) => CategoryGroup[]
} = {
  alphabetically: (groups) => {
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    })
    return groups.sort((a, b) =>
      collator.compare(
        humanizeGroupLabel(a.label).toLowerCase(),
        humanizeGroupLabel(b.label).toLowerCase()
      )
    )
  },
  position: (groups) => sortBy(groups, getPosition),
  numCards: (groups, sortData) =>
    sortBy(groups, (g) => -getNumberOfCards(g, sortData), getPosition),
}

export const sortCategories = (
  categoryGroups: Record<string, EitherCategory[]>,
  sortType: SortType,
  sortData: CardSortCategoryCard[]
) => {
  const groups = Object.entries(categoryGroups).map(([label, categories]) => ({
    label,
    categories,
  }))
  return SORT_FUNCTIONS[sortType](groups, sortData).reduce(
    (acc, group) => {
      acc[group.label] = group.categories
      return acc
    },
    {} as Record<string, EitherCategory[]>
  )
}

const getNumberOfCards = (
  group: CategoryGroup,
  sortData: CardSortCategoryCard[]
) => {
  const categoryIds = group.categories.map((c) => Number(c.id))

  const relatedSortData = sortData.filter((s) => {
    const categoryId = s.card_sort_open_category_id ?? s.card_sort_category_id
    return categoryIds.includes(Number(categoryId))
  })

  const relatedCardIds = relatedSortData.map((s) => s.card_sort_card_id)

  return new Set(relatedCardIds).size
}

const getPosition = (group: CategoryGroup) => {
  // A group contains only one `CardSortCategory` for closed card sorts.
  // Sorting by earliest `CardSortOpenCategory` within a group for open card sorts
  const minCategory = group.categories.reduce((prev, current) => {
    return Number(prev.id) < Number(current.id) ? prev : current
  })

  if (Number(minCategory.id) === 0) {
    // "Unsorted" category should always be the last
    return Infinity
  }

  if (categoryIsOpen(minCategory)) {
    // Open categories should be always after the pre-defined categories,
    // if it's a hybrid card sort.
    // Sorting by open category id for open card sorts
    return 1000 + Number(minCategory.id)
  }

  // If it doesn't have a position, sort it to the end.
  // Sorting by position for closed card sorts
  return (minCategory as CardSortCategory).position ?? Infinity
}
