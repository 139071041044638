import { UniqueIdentifier } from "@dnd-kit/core"
import { State } from "Redux/app-store"
import { ThunkAction, TreeNode } from "Types"
import { get } from "lodash"
import { arrayPush, change } from "redux-form"
import { getFormName, getFormValues } from "../selectors/formValues"

export const updateTreeId =
  (sectionIndex: number, treeId: string): ThunkAction<State> =>
  (dispatch, _getState) => {
    const path = `sections[${sectionIndex}].tree_test_attributes.tree_id`
    dispatch(change(getFormName(), path, treeId))
  }

export const updateTreeNodes =
  (sectionIndex: number, nodes: TreeNode[]): ThunkAction<State> =>
  (dispatch, _getState) => {
    const path = `sections[${sectionIndex}].tree_test_attributes.nodes`
    dispatch(change(getFormName(), path, nodes))
  }

export const addCorrectNode =
  (sectionIndex: number, node: TreeNode): ThunkAction<State> =>
  (dispatch, _getState) => {
    const path = `sections[${sectionIndex}].tree_test_attributes.correct_nodes`
    dispatch(arrayPush(getFormName(), path, node.id))
  }

export const removeCorrectNode =
  (sectionIndex: number, node: TreeNode): ThunkAction<State> =>
  (dispatch, getState) => {
    const path = `sections[${sectionIndex}].tree_test_attributes.correct_nodes`
    const correctNodes = get(getFormValues(getState()), path).filter(
      (n: UniqueIdentifier) => n !== node.id
    )
    dispatch(change(getFormName(), path, correctNodes))
  }

export const clearCorrectNodes =
  (sectionIndex: number): ThunkAction<State> =>
  (dispatch, _getState) => {
    const path = `sections[${sectionIndex}].tree_test_attributes.correct_nodes`
    dispatch(change(getFormName(), path, []))
  }
