import { Text } from "@chakra-ui/react"
import React from "react"

import { Checkmark, Cross, NotApplicable } from "Components/checkmark/Checkmark"
import { ScreenshotClick } from "Types"

interface Props {
  readonly click: Readonly<ScreenshotClick> | null
  readonly stepNumber: number
}

export const NavigationOutcome = ({ click, stepNumber }: Props) => {
  const stepNode = (
    <Text color="gray.900" fontWeight="semibold">
      &nbsp;Step {stepNumber}&nbsp;
    </Text>
  )
  if (click === null) {
    return (
      <>
        <NotApplicable /> {stepNode} never reached
      </>
    )
  }
  if (click.hit) {
    return (
      <>
        <Checkmark /> {stepNode} navigated successfully
      </>
    )
  }
  return (
    <>
      <Cross /> {stepNode} navigated unsuccessfully
    </>
  )
}
