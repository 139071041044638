import { Box } from "@chakra-ui/react"
import React, { PropsWithChildren, useEffect } from "react"

type Props = {
  stickyElementCSSVarName: string
  topOffset?: string
  bottomOffset?: string
}

/**
 * - `stickyElementCSSVarName`: Name of the CSS variable that will be set to
 *   the height of the sticky element. This is set by a `ResizeObserver` and referenced
 *   in a CSS `min()` expression on the sticky element.
 * - `topOffset`: Amount of space to add to the top of the sticky element when
 *   it's shorter than the viewport and thus won't get pinned to the bottom.
 * - `bottomOffset`: Amount of space to add to the bottom of the sticky
 *   element.
 *
 * @example
 * <StickyToBottom
 *   stickyElementCSSVarName="order-summary-panel-height"
 *   topOffset={`${STUDY_STEPPER_HEIGHT} + 1rem`}
 *   bottomOffset="2.5rem"
 * >
 *   <Box>Content of sticky element</Box>
 * </StickyToBottom>
 */
export const StickyToBottom: React.FC<PropsWithChildren<Props>> = ({
  stickyElementCSSVarName,
  topOffset = "0",
  bottomOffset = "0",
  children,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const cssVarName = `--${stickyElementCSSVarName}`

  useEffect(() => {
    if (!containerRef.current) return

    const resizeObserver = new ResizeObserver((entries) => {
      const height = entries[0].contentRect.height
      const el = containerRef.current
      if (!el) return

      el.style.setProperty(cssVarName, `${height}px`)
    })

    resizeObserver.observe(containerRef.current)

    return () => resizeObserver.disconnect()
  })

  return (
    <Box
      ref={containerRef}
      pos="sticky"
      // To pin the element to the bottom of the screen, we need to subtract its
      // height from the height of the viewport to get the distance that it
      // overflows, and then set that as a negative `top` value so it's pulled up
      // beyond the top of the viewport when you scroll down. But in cases where
      // the sticky element is shorter than the viewport, the `top` value will be
      // positive, pinning it to the bottom of the viewport unnecessarily, which
      // looks wrong, so we rely on min() to avoid a positive `top` value
      // (unless there's a topOffset set, in which case the offset creates a gap
      // at the top.) This keeps it at the top of its container when it's
      // shorter than the viewport.
      top={`min(${topOffset}, -1 * ((var(${cssVarName}) + ${bottomOffset}) - 100dvh))`}
    >
      {children}
    </Box>
  )
}
