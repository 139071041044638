import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react"
import {
  BookOpenIcon,
  ExternalLinkIcon,
  LockClosedIcon,
  LogoutIcon,
  ShieldCheckIcon,
  SupportIcon,
  UserCircleIcon,
  ViewGridIcon,
} from "@heroicons/react/outline"
import Constants from "Constants/shared.json"
import { onClickAddOrttoCookieParam } from "JavaScripts/analytics/ortto"
import { getEnvState } from "JavaScripts/state"
import { RoutedLink } from "Shared/components/Links/RoutedLink"
import { useSignout } from "Shared/hooks/useSignout"
import { Briefcase01OutlineIcon } from "Shared/icons/untitled-ui/Briefcase01OutlineIcon"
import { CreditCard02OutlineIcon } from "Shared/icons/untitled-ui/CreditCard02OutlineIcon"
import { Flag02OutlineIcon } from "Shared/icons/untitled-ui/Flag02OutlineIcon"
import { Settings04OutlineIcon } from "Shared/icons/untitled-ui/Settings04OutlineIcon"
import { Users01OutlineIcon } from "Shared/icons/untitled-ui/Users01OutlineIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { ROUTES } from "UsabilityHub/views/routes"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { FeatureFlagModal } from "../FeatureFlagModal/FeatureFlagModal"
import { CreditsButton } from "./CreditsButton"

export const MobileMenu = () => {
  const signout = useSignout()
  const currentUser = useCurrentUser()

  const showFeatureFlagLink = getEnvState().ANYONE_CAN_EDIT_THEIR_FEATURE_FLAGS
  const { open: showFeatureFlagModal } = useModal(FeatureFlagModal)

  const featureAccessItem = (
    <Button
      variant="ghost"
      justifyContent="flex-start"
      size="sm"
      as={RoutedLink}
      to={ROUTES.SETTINGS.FEATURE_ACCESS.path}
      leftIcon={<Icon as={Settings04OutlineIcon} />}
    >
      Feature access
    </Button>
  )

  return (
    <Stack
      display={{ base: "flex", lg: "none" }}
      p={4}
      bg="white"
      borderBottom="2px solid"
      borderColor="gray.100"
    >
      <Button
        variant="ghost"
        justifyContent="flex-start"
        size="sm"
        height={12}
        as={RoutedLink}
        to={ROUTES.SETTINGS.PROFILE.path}
      >
        <Avatar
          size="sm"
          name={currentUser.name}
          src={currentUser.avatar_url_medium}
        />
        <Box ml={3}>
          <Text>{currentUser.name}</Text>
          <Text color="gray.500">{currentUser.email}</Text>
        </Box>
      </Button>
      <Divider />
      <Flex>
        <Stack width="50%">
          <CreditsButton justifyContent="flex-start" />
          <Button
            variant="ghost"
            justifyContent="flex-start"
            size="sm"
            as={RoutedLink}
            to={ROUTES.SETTINGS.PROFILE.path}
            leftIcon={<Icon as={UserCircleIcon} />}
          >
            Profile
          </Button>
          {currentUser.role !== "admin" && featureAccessItem}
          {currentUser.role === "admin" && (
            <>
              <Button
                variant="ghost"
                justifyContent="flex-start"
                size="sm"
                as={RoutedLink}
                to={ROUTES.SETTINGS.ORGANIZATION.path}
                leftIcon={<Icon as={Briefcase01OutlineIcon} />}
              >
                Organization
              </Button>
              <Button
                variant="ghost"
                justifyContent="flex-start"
                size="sm"
                as={RoutedLink}
                to={ROUTES.SETTINGS.TEAM.path}
                leftIcon={<Icon as={Users01OutlineIcon} />}
              >
                Team members
              </Button>
              <Button
                variant="ghost"
                justifyContent="flex-start"
                size="sm"
                as={RoutedLink}
                to={ROUTES.SETTINGS.BILLING.path}
                leftIcon={<Icon as={CreditCard02OutlineIcon} />}
              >
                Plan &amp; billing
              </Button>
              {featureAccessItem}
              <Button
                variant="ghost"
                justifyContent="flex-start"
                size="sm"
                as={RoutedLink}
                to={ROUTES.SETTINGS.SECURITY.path}
                leftIcon={<Icon as={LockClosedIcon} />}
              >
                Security
              </Button>
            </>
          )}
        </Stack>
        <Stack width="50%">
          <Button
            variant="ghost"
            justifyContent="flex-start"
            size="sm"
            as={RoutedLink}
            to={ROUTES.LEGAL.TERMS.path}
            leftIcon={<Icon as={ShieldCheckIcon} />}
          >
            Privacy &amp; terms
          </Button>
          <Button
            as="a"
            href={Constants.EXAMPLES_URL}
            variant="ghost"
            justifyContent="flex-start"
            size="sm"
            target="_blank"
            rightIcon={<Icon as={ExternalLinkIcon} />}
            leftIcon={<Icon as={ViewGridIcon} />}
            onClick={onClickAddOrttoCookieParam}
          >
            Examples
          </Button>
          <Button
            as="a"
            href={Constants.TESTING_GUIDES_URL}
            variant="ghost"
            justifyContent="flex-start"
            size="sm"
            target="_blank"
            rightIcon={<Icon as={ExternalLinkIcon} />}
            leftIcon={<Icon as={BookOpenIcon} />}
            onClick={onClickAddOrttoCookieParam}
          >
            Testing guides
          </Button>
          <Button
            as="a"
            href={Constants.HELP_CENTER_URL}
            variant="ghost"
            justifyContent="flex-start"
            size="sm"
            target="_blank"
            rightIcon={<Icon as={ExternalLinkIcon} />}
            leftIcon={<Icon as={SupportIcon} />}
          >
            Help center
          </Button>

          {showFeatureFlagLink && (
            <Button
              variant="ghost"
              justifyContent="flex-start"
              size="sm"
              leftIcon={<Icon as={Flag02OutlineIcon} />}
              onClick={showFeatureFlagModal}
            >
              Feature flags
            </Button>
          )}

          <Button
            variant="ghost"
            justifyContent="flex-start"
            size="sm"
            onClick={signout}
            leftIcon={<Icon as={LogoutIcon} />}
          >
            Sign out
          </Button>
        </Stack>
      </Flex>
    </Stack>
  )
}
