import { MotionProps, Point, motion } from "framer-motion"
import React, {
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from "react"

import { Dimensions } from "Types"

type ZoomableContainerProps = PropsWithChildren<{
  targetDimensions: Dimensions
}> &
  MotionProps

export function ZoomableContainer({
  targetDimensions,
  style,
  children,
  ...props
}: ZoomableContainerProps) {
  const [isZoomedIn, setIsZoomedIn] = useState(false)
  const [origin, setOrigin] = useState<Point>({ x: 0.5, y: 0.5 })
  const boxRef = useRef<HTMLDivElement>(null)

  const updateOrigin = useCallback((e: MouseEvent) => {
    const bounds = boxRef.current!.getBoundingClientRect()
    const relativeX = e.clientX - bounds.left
    const relativeY = e.clientY - bounds.top

    const originX = relativeX / bounds.width
    const originY = relativeY / bounds.height

    setOrigin({ x: originX, y: originY })
  }, [])

  const toggleIsZoomedIn = useCallback((e: MouseEvent) => {
    setIsZoomedIn((isZoomedIn) => !isZoomedIn)

    updateOrigin(e)
  }, [])

  const aspectRatio = (
    targetDimensions.width / targetDimensions.height
  ).toString()

  const variants = {
    zoomedOut: {
      scale: 1,
    },
    zoomedIn: {
      scale: 2.5,
    },
  }

  return (
    <motion.div
      {...props}
      ref={boxRef}
      initial={false}
      style={{
        ...style,
        width: "100%",
        overflow: "hidden",
        flex: "1",
        display: "grid",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        gridTemplateRows: "repeat(1, minmax(0, 1fr))",
        originX: origin.x,
        originY: origin.y,
        cursor: isZoomedIn ? "zoom-out" : "zoom-in",
        isolation: "isolate",
      }}
      onClick={toggleIsZoomedIn}
      onMouseMove={updateOrigin}
      variants={variants}
      animate={isZoomedIn ? "zoomedIn" : "zoomedOut"}
    >
      <div
        style={{
          aspectRatio,
          maxHeight: "100%",
          maxWidth: "100%",
          justifySelf: "center",
          alignSelf: "center",
        }}
      >
        {children}
      </div>
    </motion.div>
  )
}
