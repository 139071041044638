import { RawScreenshot, Screenshot } from "Types"
import { nextClientId } from "Utilities/client-id"

export function getInitializeScreenshots(
  rawScreenshotState: ReadonlyArray<RawScreenshot>
): Screenshot[] {
  return rawScreenshotState.map((screenshot) => ({
    ...screenshot,
    _file: null,
    _clientId: nextClientId(),
    _isViewed: false,
    _isLoaded: false,
    _isLoading: false,
    _isSaving: false,
    _progress: 1,
    _url: screenshot.url,
  }))
}
