import {
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import PayoutsApi from "~/api/admin/payoutsApi"

import { SubmitButton } from "Components/button/submit-button"
import { RailsForm } from "Components/form/form"

type RejectPayoutButton = React.FC<
  React.PropsWithChildren<{
    readonly id: string
    readonly isBanned: boolean
  }>
>

export const RejectPayoutButton: RejectPayoutButton = ({ id, isBanned }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button colorScheme="red" onClick={onOpen}>
        Reject
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Reject this payout</ModalHeader>
            <RailsForm action={PayoutsApi.reject.path({ id })} method="PUT">
              <ModalBody>
                <Stack>
                  {isBanned ? (
                    <Text mb={2}>
                      This panelist is already banned so will not be banned
                      again. The payout will be set to rejected.
                    </Text>
                  ) : (
                    <Text mb={2}>
                      This payout will be rejected and the panelist will be
                      banned from testing.
                    </Text>
                  )}
                  <Checkbox
                    variant="mdWithSmFont"
                    defaultChecked
                    id="send_email"
                  >
                    Email the panelist to let them know the payout was rejected
                  </Checkbox>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <HStack>
                  <Button variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                  <SubmitButton colorScheme="red">
                    Reject payout
                    {!isBanned && " and ban panelist"}
                  </SubmitButton>
                </HStack>
              </ModalFooter>
            </RailsForm>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}
