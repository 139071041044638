import { Box, useToast } from "@chakra-ui/react"
import { useLocalCommentContext } from "Components/comment-provider/CommentProvider"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { v4 as uuidv4 } from "uuid"
import { usePostAddComment } from "~/api/generated/usabilityhub-components"
import { Comment } from "~/api/generated/usabilityhubSchemas"
import { CommentEditor } from "./CommentEditor"
import { EntityIdentifier, entityIdOrNull } from "./entities"

type Props = {
  lockedOpen?: boolean
  usabilityTestId: number | null
  entity: EntityIdentifier
  closeThread: () => void
  isEntityPersisted: boolean
  replyingTo?: string
}

export const AddCommentForm: React.FC<Props> = ({
  usabilityTestId,
  entity,
  lockedOpen = false,
  closeThread,
  isEntityPersisted,
  replyingTo = null,
}) => {
  const toast = useToast()
  const {
    activeContext,
    addComment: addCommentToCache,
    addCommentLocally,
  } = useLocalCommentContext()
  const currentUser = useCurrentUser()

  const addCommentMutation = usePostAddComment({
    onSuccess: (_data, variables) => {
      const newComment: Comment = {
        ...variables.body,
        user: {
          id: String(currentUser.id),
          name: currentUser.name ?? "",
          avatar_url: currentUser.avatar_url_medium ?? "",
        },
        created_at: new Date().toISOString(),
        last_edited_at: null,
        is_read: true,
      }

      addCommentToCache(newComment)
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error?.payload.message,
        status: "error",
      })
    },
  })

  const addComment = async (content: string) => {
    const newId = uuidv4()

    const entityType = replyingTo ? "comment" : entity.entityType
    const entityId = replyingTo ?? entityIdOrNull(entity)

    if (isEntityPersisted) {
      await addCommentMutation.mutateAsync({
        body: {
          id: newId,
          usability_test_id: String(usabilityTestId),
          context: activeContext,
          entity_type: entityType,
          entity_id: entityId,
          content,
        },
      })
    } else {
      const newEntity = {
        // TODO: reintroduce this after the results stuff lands in main
        // entityContext: activeContext,
        entityType,
        entityId: entityId!,
      } as EntityIdentifier

      addCommentLocally({
        entity: newEntity,
        comment_id: newId,
        content,
      })
    }
  }
  // Hide the add comment form for signed out users and pages on the admin dashboard.
  if (!currentUser || window.location.pathname.startsWith("/admin/"))
    return null

  return (
    <Box
      borderColor="gray.200"
      px={4}
      pt={3}
      pb={4}
      _notFirst={{ borderTopWidth: 1 }}
    >
      <CommentEditor
        saveButtonLabel="Comment"
        handleSave={addComment}
        isLoading={addCommentMutation.isLoading}
        lockedOpen={lockedOpen}
        closeThread={closeThread}
      />
    </Box>
  )
}
