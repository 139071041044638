import { CardSortCardsImageData } from "Types"
import { reportErrorToSentry } from "Utilities/error"

import {
  fetchDuplicateCardImage,
  fetchRemoveTempCardImage,
  fetchUploadCardImage,
} from "~/api/generated/usabilityhub-components"
import { CardSortCardImage } from "~/api/generated/usabilityhubSchemas"

export async function uploadCardImageFile(
  file: File
): Promise<CardSortCardImage> {
  try {
    // Upload the image file to S3 bucket via Shrine
    const upload_result = fetchUploadCardImage({
      body: { file: file },
      headers: { "Content-Type": "multipart/form-data" },
    })
    return upload_result
  } catch (error) {
    const description =
      typeof error?.payload === "object" && "message" in error.payload
        ? error.payload.message
        : "Failed to upload the image. Please try again."
    throw new Error(description)
  }
}

export async function duplicateCardImage(
  image_data: CardSortCardsImageData
): Promise<CardSortCardImage> {
  try {
    // Upload the image file to S3 bucket via Shrine
    const upload_result = await fetchDuplicateCardImage({
      body: { image_data: image_data },
    })
    return upload_result
  } catch (error) {
    const description =
      typeof error?.payload === "object" && "message" in error.payload
        ? error.payload.message
        : "Failed to upload the image. Please try again."
    throw new Error(description)
  }
}

export async function deleteCardImageFile(
  image_data: string | CardSortCardsImageData
): Promise<string | null> {
  if (typeof image_data !== "string") {
    // If image_data is not string,
    // it's already saved into db.
    // No need to call this api for removing the image,
    // as it will be deleted automatically by shrinerb
    // when saving the usability test.
    image_data = JSON.stringify(image_data)
    return "The card image has been deleted."
  }

  try {
    // Upload the image file to S3 bucket via Shrine
    const { message } = await fetchRemoveTempCardImage({
      body: { image_data: image_data },
    })

    return message
  } catch (error) {
    reportErrorToSentry(error)
  }
  return null
}
