import { getApplicationState } from "JavaScripts/state"
import { useLocalStorage } from "Shared/hooks/useLocalStorage"
import { useMaybeCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React, { useCallback } from "react"
import { useNavigate } from "react-router"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { ROUTES } from "../routes"
import { Layout } from "./Layout"
import { OnboardingContextProvider } from "./OnboardingContextProvider"
import { STEPS } from "./constants"

export const Onboarding: React.FC = () => {
  const navigate = useNavigate()

  const currentUser = useMaybeCurrentUser() ?? undefined
  const { stepId } = useTypedParams(ROUTES.SETUP.ONBOARDING)
  const { recaptchaSiteKey } = getApplicationState()
  const [, setRedirect] = useLocalStorage<string | null>("redirect_url", null)

  const onStepChange = useCallback(
    (id: (typeof STEPS)[number]["id"]) => {
      if (id === stepId) return

      if (id === "account") {
        if (!currentUser) {
          navigate(ROUTES.USERS.SIGN_UP.buildPath({}), { replace: true })
        }
      } else {
        navigate(ROUTES.SETUP.ONBOARDING.buildPath({ stepId: id }), {
          replace: true,
        })
      }
    },
    [stepId]
  )

  const onComplete = useCallback(
    (redirect: string, isFirstTimeSignup: boolean) => {
      setRedirect(null)
      navigate(redirect, {
        state: { onboardingComplete: isFirstTimeSignup },
      })
    },
    []
  )

  return (
    <OnboardingContextProvider
      currentUser={currentUser}
      stepId={stepId}
      recaptchaSiteKey={recaptchaSiteKey ?? ""}
      onStepChange={onStepChange}
      onComplete={onComplete}
    >
      <Layout />
    </OnboardingContextProvider>
  )
}
