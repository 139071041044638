import { Button, Flex } from "@chakra-ui/react"
import React from "react"

import { UsabilityHubLogoIcon } from "Shared/icons/UsabilityHubLogoIcon"

export const MarketingSiteNavbar: React.FC = () => {
  return (
    <Flex justifyContent="space-between" px={5} pt={25}>
      <Flex
        justify="space-between"
        flex={1}
        mx="auto"
        flexDirection="row"
        maxW="1186px"
      >
        <Button
          size="sm"
          variant="ghost"
          as="a"
          href="https://lyssna.com"
          _hover={{}}
        >
          <UsabilityHubLogoIcon w="168px" h="31px" />
        </Button>
      </Flex>
    </Flex>
  )
}
