import { CardGroup } from "Shared/components/Card/Card"
import {
  useCurrentAccount,
  useCurrentUser,
} from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { Helmet } from "react-helmet"
import { ChangePassword } from "./ChangePassword"
import { DeleteAccount } from "./DeleteAccount"
import { Integrations } from "./Integrations"
import { PersonalDetails } from "./PersonalDetails"

export const ProfileRoute: React.FC = () => {
  const currentAccount = useCurrentAccount()
  const currentUser = useCurrentUser()

  return (
    <CardGroup>
      <Helmet>
        <title>Profile settings</title>
      </Helmet>
      <PersonalDetails />
      <Integrations />
      <ChangePassword />
      {currentUser.role === "admin" &&
        currentAccount.owner_id === currentUser.id && <DeleteAccount />}
    </CardGroup>
  )
}
