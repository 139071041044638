import { clamp as clampNumber } from "Math/number"
import { Point } from "Types"

export const ZERO: Point = Object.freeze({ x: 0, y: 0 })

export function magnitude(a: Point): number {
  return Math.sqrt(a.x * a.x + a.y * a.y)
}

export function subtract(a: Point, b: Point): Point {
  return { x: a.x - b.x, y: a.y - b.y }
}

export function clamp(v: Point, minPoint: Point, maxPoint: Point): Point {
  return {
    x: clampNumber(v.x, minPoint.x, maxPoint.x),
    y: clampNumber(v.y, minPoint.y, maxPoint.y),
  }
}
