// JsFromRoutes CacheKey a1a435949b5ef5e4ced41a4fee0ac713
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  cancel: /* #__PURE__ */ definePathHelper('put', '/responses/:id/cancel'),
  keepalive: /* #__PURE__ */ definePathHelper('put', '/responses/:id/keepalive'),
  update: /* #__PURE__ */ definePathHelper('patch', '/responses/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/responses/:id'),
}
