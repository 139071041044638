import {
  Badge,
  Box,
  BoxProps,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { DotsVerticalIcon } from "@heroicons/react/solid"
import ZoomTextLogo from "Images/zoom-text-logo.svg"
import { useModal } from "Utilities/modals/use-modal"
import isSafari from "is-safari"
import React, { ComponentPropsWithoutRef, forwardRef } from "react"
import { GetModeratedStudyBookingRecordingsResponse } from "~/api/generated/usabilityhub-components"
import { RecordingEditorModal } from "./modal-content"

type Recording = GetModeratedStudyBookingRecordingsResponse[number]

export type VideoPlayerProps = BoxProps &
  ComponentPropsWithoutRef<"video"> & {
    recording: Recording
    onDelete: (recordingId: Recording["id"]) => void
    onRename: (recordingId: Recording["id"], newFilename: string) => void
  }

export const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>(
  ({ recording, onDelete, onRename, ...props }, ref) => {
    const { open: openEditorModal } = useModal(RecordingEditorModal)

    return (
      <Grid
        templateColumns="repeat(3, 1fr)"
        templateRows="1fr auto 1fr"
        gridTemplateAreas="
        'vTopLeft vTopMid vTopRight'
        'vMidLeft vMidMid vMidRight'
        'vBotLeft vBotMid vBotRight'
        "
        borderRadius="md"
        overflow="hidden"
        css={{
          "& > :not(#video)": {
            visibility: "hidden",
            opacity: 0,
            transition: "all 300ms ease-out",
          },
          "&:hover > :not(#video), &:focus > :not(#video)": {
            opacity: "100%",
            transition: "opacity 300ms",
            visibility: "visible",
          },
        }}
      >
        <GridItem
          rowSpan={3}
          colSpan={6}
          gridArea="vTopLeft / vTopLeft / vBotLeft / vBotRight"
          id="video"
        >
          <Box
            ref={ref}
            as="video"
            controls
            controlsList="nodownload"
            preload="metadata"
            src={recording.url}
            {...props}
          />
        </GridItem>
        <GridItem
          rowSpan={3}
          colSpan={6}
          gridArea="vTopLeft / vTopLeft / vBotLeft / vBotRight"
          bgGradient="linear-gradient(0deg, rgba(2,0,36,0.9) 0%, rgba(2,0,36,0.8) 5%, rgba(2,0,36,0) 15%, rgba(2,0,36,0) 80%, rgba(2,0,36,0.8) 92%, rgba(2,0,36,0.9) 100%)"
        >
          {/* This is a gradient overlay, it is intentionally left blank */}
        </GridItem>
        <GridItem
          gridArea="vTopLeft / vTopLeft / vTopMid / vTopMid"
          padding={5}
          color="white"
        >
          <Text fontWeight="medium" fontSize="sm">
            {recording.filename}
          </Text>
        </GridItem>
        <GridItem
          gridArea="vTopRight"
          color="white"
          justifySelf="flex-end"
          padding={5}
        >
          <HStack>
            {!recording.auto_uploaded && (
              <Badge
                color="text.inverted"
                h={6}
                minW={6}
                background="none"
                padding={2}
                borderRadius={4}
                sx={{
                  "&::before": {
                    bgColor: "menu.inverted",
                    opacity: 0.7,
                    content: '""',
                    backgroundSize: "cover",
                    position: "absolute",
                    width: 12,
                    height: 6,
                    borderRadius: 4,
                    // The before is starting at the corner of the image not with the padding
                    // It doesn't need this if it's a button to be extra weird
                    // TODO: make this less hacky
                    marginLeft: "calc(var(--chakra-sizes-2)*-1)",
                    marginTop: "calc(var(--chakra-sizes-2)*-1)",
                  },
                  "& img": {
                    width: 8,
                    height: 2,
                    position: "relative",
                  },
                }}
              >
                <Image src={ZoomTextLogo} />
              </Badge>
            )}
            <Menu placement="bottom-end">
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<DotsVerticalIcon />}
                color="text.inverted"
                h={6}
                minW={6}
                bg="none"
                sx={{
                  "&::before": {
                    bgColor: "menu.inverted",
                    opacity: 0.7,
                    content: '""',
                    backgroundSize: "cover",
                    position: "absolute",
                    width: 6,
                    height: 6,
                    borderRadius: 3,
                  },
                  "& svg": {
                    width: 3,
                    height: 3,
                    position: "relative",
                  },
                }}
              />
              <MenuList
                display="flex"
                flexDirection="column"
                minW="32"
                borderRadius="4"
                paddingTop="1"
                paddingBottom="1"
                color="text.primary"
                sx={{
                  "& > button, & > a": {
                    paddingBottom: 1,
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingTop: 1,
                  },
                }}
              >
                <MenuItem
                  onClick={() =>
                    openEditorModal({
                      onSubmit: (newFilename: string): void => {
                        if (newFilename !== recording.filename)
                          onRename(recording.id, newFilename)
                      },
                      prevFilename: recording.filename || undefined,
                      title: "rename",
                    })
                  }
                >
                  Rename
                </MenuItem>
                <MenuItem
                  as="a"
                  href={recording.url!}
                  download
                  // download is "supported" in safari, but opens downloads
                  // in the current tab. So only in safari open a new tab
                  {...(isSafari && { target: "_blank" })}
                >
                  Download
                </MenuItem>
                <MenuItem
                  color="toast.error"
                  onClick={() =>
                    openEditorModal({
                      onSubmit: () => {
                        onDelete(recording.id)
                      },
                      title: "delete",
                    })
                  }
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </GridItem>
      </Grid>
    )
  }
)
