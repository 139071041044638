import { Box, ComponentWithAs, Flex, Icon, IconProps } from "@chakra-ui/react"
import { useTranslate } from "Shared/hooks/useTranslate"
import { MicrophoneIcon } from "Shared/icons/MicrophoneIcon"
import { MonitorIcon } from "Shared/icons/MonitorIcon"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import { VideoRecorderOutlineIcon } from "Shared/icons/untitled-ui/VideoRecorderOutlineIcon"
import { XCircleSolidIcon } from "Shared/icons/untitled-ui/XCircleSolidIcon"
import React, { useEffect } from "react"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"
import { useTestRecordingContext } from "../../context/TestRecordingContext"
import { StepContentProps } from "./RecordingSetupGuideContent"

type RecordingTypeInfo = {
  titleKey: string
  contentKey: string
  type: RecordingType
  icon: ComponentWithAs<"svg", IconProps>
}

const RECORDING_TYPES_INFO: RecordingTypeInfo[] = [
  {
    type: "screen",
    titleKey: "test.recording.screen.label",
    contentKey: "test.recording.screen.all_set_content",
    icon: MonitorIcon,
  },
  {
    type: "microphone",
    titleKey: "test.recording.microphone.label",
    contentKey: "test.recording.microphone.all_set_content",
    icon: MicrophoneIcon,
  },
  {
    type: "camera",
    titleKey: "test.recording.camera.label",
    contentKey: "test.recording.camera.all_set_content",
    icon: VideoRecorderOutlineIcon,
  },
]

export const AllSetContent: React.FC<StepContentProps> = ({ setPassable }) => {
  const { allowedRecordingTypes, audioDeviceId, screenStream, videoDeviceId } =
    useTestRecordingContext()
  useEffect(() => {
    setPassable(true)
  }, [])

  const recordingTypesToShow = allowedRecordingTypes.includes("camera")
    ? allowedRecordingTypes.filter((x) => x !== "microphone")
    : allowedRecordingTypes
  const recordingTypesInfo = RECORDING_TYPES_INFO.filter((recordingTypeInfo) =>
    recordingTypesToShow.includes(recordingTypeInfo.type)
  )

  const isRecordingTypeReady = (recordingType: RecordingType) => {
    switch (recordingType) {
      case "microphone":
        return !!audioDeviceId
      case "screen":
        return !!screenStream
      case "camera":
        return !!videoDeviceId && !!audioDeviceId
      default:
        throw new Error("recording type is not supported")
    }
  }

  const translate = useTranslate()

  return (
    <>
      {recordingTypesInfo.map((recordingTypeInfo, index) => (
        <Flex
          key={index}
          border="1px"
          borderColor="gray.50"
          p={6}
          gap={6}
          borderRadius="md"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Icon as={recordingTypeInfo.icon} boxSize={6} />
          </Box>
          <Flex direction="column" gap={2}>
            <Box fontWeight="medium">
              {translate(recordingTypeInfo.titleKey)}
            </Box>
            <Box fontSize="sm">{translate(recordingTypeInfo.contentKey)}</Box>
          </Flex>
          <Box>
            <Icon
              as={
                isRecordingTypeReady(recordingTypeInfo.type)
                  ? CheckCircleSolidIcon
                  : XCircleSolidIcon
              }
              color={
                isRecordingTypeReady(recordingTypeInfo.type)
                  ? "green.400"
                  : "red.600"
              }
              boxSize={6}
            />
          </Box>
        </Flex>
      ))}
      <Box bg="gray.50" borderRadius="lg" p={4} fontSize="sm">
        <b>{translate("test.recording.important")}: </b>
        {translate("test.recording.warning")}
      </Box>
    </>
  )
}
