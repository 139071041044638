import { Text } from "@chakra-ui/react"
import React from "react"

import Constants from "Constants/shared.json"
import { ExternalLink } from "Shared/components/Links/ExternalLink"

export const CreditCardDeclinedMessage = () => (
  <>
    <Text>Your card was declined</Text>
    <Text>
      {/* White because it's exclusively used in toasts and we want to match. */}
      <ExternalLink
        color="white !important"
        display="inline-flex"
        alignItems="center"
        href={Constants.HELP_CENTER_CARD_DECLINED_URL}
      >
        Why was my card declined?
      </ExternalLink>
    </Text>
  </>
)
