import { createIcon } from "@chakra-ui/react"
import React from "react"

export const InterviewLinkIcon = createIcon({
  displayName: "InterviewLinkIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M2 9a7 7 0 0 1 13.513-2.571c.245.62.986.96 1.56.622.37-.218.554-.658.41-1.063A9 9 0 0 0 0 9c.001 2.526 1.148 4.74 2.709 6.767.762.99 1.648 1.965 2.586 2.935a.272.272 0 0 1-.006-.005c.618.625 1.703.187 1.712-.692v-.015c0-.258-.1-.502-.279-.688 0-.002-.003-.004-.008-.009-.892-.923-1.714-1.829-2.42-2.746C2.852 12.678 2 10.892 2 9ZM14.113 14h.553l-.255-.433a15.69 15.69 0 0 1-.298.433ZM9.016 22h-.032.032ZM9 5a4 4 0 1 0 0 8 4 4 0 0 0 0-8ZM7 9a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM19.198 8.7a4.136 4.136 0 0 0-2.91 1.163l-.009.008-1.101 1.096a.94.94 0 0 0 1.325 1.333l1.095-1.09a2.257 2.257 0 0 1 3.823 1.612c.005.59-.22 1.16-.629 1.585l-1.913 1.915a2.258 2.258 0 0 1-3.406-.245.94.94 0 1 0-1.505 1.126 4.14 4.14 0 0 0 4.76 1.4 4.138 4.138 0 0 0 1.48-.952l1.92-1.92.009-.01.002-.002a4.142 4.142 0 0 0-2.94-7.019ZM15.014 13.147a4.135 4.135 0 0 0-3.222 1.203l-1.919 1.92-.01.01-.001.002a4.143 4.143 0 0 0 2.94 7.018 4.136 4.136 0 0 0 2.91-1.162l.01-.01 1.096-1.097a.94.94 0 0 0-1.329-1.33s-.662.68-1.087 1.09a2.257 2.257 0 0 1-3.822-1.613c-.006-.59.22-1.159.628-1.585l1.913-1.914a2.258 2.258 0 0 1 3.406.244.94.94 0 1 0 1.505-1.126 4.14 4.14 0 0 0-3.018-1.65Z"
      fill="currentColor"
    />
  ),
})
