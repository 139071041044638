import { ThirdPartyQuote } from "Types"
import { ROUTES } from "UsabilityHub/views/routes"
import { debounce } from "lodash"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { thirdPartyOrders } from "~/api"
import { useOrderForm } from "../OrderFormProvider"
import { DEFAULT_AGE_RANGE, QUOTE_DEBOUNCE_MS } from "../constants"
import { QuoteState, TargetLocation } from "../types"

// This hook maintains local state of the latest third party order quote. It takes the demographic data
// from the OrderFormContext and will re-call the API any time they change — but no more often than
// QUOTE_DEBOUNCE_MS.
export const useExternalPanelOrderQuote = () => {
  const { testId: usabilityTestUniqueId } = useTypedParams(ROUTES.TEST.RECRUIT)
  const {
    numParticipants,
    selectedOptionIds,
    ageRange,
    targetLocations,
    testHasScreener,
  } = useOrderForm()

  const [quoteState, setQuoteState] = useState<QuoteState>("idle")
  const [latestQuote, setLatestQuote] = useState<ThirdPartyQuote | null>(null)
  const totalDemographicChanges = useRef<number>(0)

  const debouncedQuote = useMemo(
    () =>
      debounce(
        async (
          testId: string,
          numParticipants: number,
          demographicOptions: number[],
          minAge: number,
          maxAge: number,
          targetLocations: TargetLocation[]
        ) => {
          // We don't currently support tests with screeners for third party orders
          if (testHasScreener) {
            setQuoteState("unavailable")
            return
          }

          const demographicChanges = totalDemographicChanges.current

          try {
            setQuoteState("loading")

            const quote =
              await thirdPartyOrders.calculatePrice<ThirdPartyQuote>({
                data: {
                  usability_test_id: testId,
                  demographic_attribute_option_ids: demographicOptions,
                  min_age: minAge === DEFAULT_AGE_RANGE[0] ? null : minAge,
                  max_age: maxAge === DEFAULT_AGE_RANGE[1] ? null : maxAge,
                  target_locations: targetLocations,
                  requested_response_count: numParticipants,
                },
              })

            // Ignore result if it's not the most recent API call
            if (demographicChanges !== totalDemographicChanges.current) return

            setLatestQuote(quote)
            setQuoteState(quote.available ? "success" : "unavailable")
          } catch (error) {
            setLatestQuote(null)
            setQuoteState("error")
          }
        },
        QUOTE_DEBOUNCE_MS
      ),
    []
  )

  useEffect(() => {
    ++totalDemographicChanges.current

    void debouncedQuote(
      usabilityTestUniqueId,
      numParticipants,
      selectedOptionIds,
      ageRange[0],
      ageRange[1],
      targetLocations
    )
  }, [
    usabilityTestUniqueId,
    numParticipants,
    selectedOptionIds.join("-"),
    ageRange[0],
    ageRange[1],
    JSON.stringify(targetLocations),
  ])

  return { latestQuote, quoteState }
}
