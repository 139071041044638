// JsFromRoutes CacheKey d2018b2638a86238d7cd3ed289fec6a2
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  createAnswerTags: /* #__PURE__ */ definePathHelper('post', '/question_tags/:question_tag_id/answer_tags'),
  destroyAnswerTags: /* #__PURE__ */ definePathHelper('delete', '/question_tags/:question_tag_id/answer_tags'),
  create: /* #__PURE__ */ definePathHelper('post', '/question_tags'),
  update: /* #__PURE__ */ definePathHelper('patch', '/question_tags/:question_tag_id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/question_tags/:question_tag_id'),
}
