import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react"
import React from "react"

import { useTranslate } from "Shared/hooks/useTranslate"

import { usePrototypeTaskContext } from "./PrototypeTaskContext"

export const PrototypeTaskFinishModal: React.FC<
  React.PropsWithChildren<UseDisclosureReturn>
> = ({ isOpen, onClose }) => {
  const prototypeTask = usePrototypeTaskContext()
  const translate = useTranslate()
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered autoFocus={false}>
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader>
          {translate("test.instructions.prototype_task.finish_header")}
        </ModalHeader>
        <ModalBody>
          {translate("test.instructions.prototype_task.finish_body")}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brand.primary"
            onClick={() => prototypeTask.finishTask("gave_up")}
          >
            {translate("test.buttons.give_up_on_task")}
          </Button>
          <Button
            colorScheme="green"
            onClick={() => prototypeTask.finishTask("completed")}
            ml={3}
          >
            {translate("test.buttons.complete_task")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
