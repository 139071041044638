import { CardSort, CardSortCard } from "Types"

const incompleteUploadCardImages = (cards: CardSortCard[]) => {
  return cards.some((card) => card.is_processing)
}

export const validateCardSortCards = (
  cardSort: CardSort | null
): string | undefined => {
  if (cardSort === null) return undefined
  if (
    cardSort.show_images &&
    incompleteUploadCardImages(cardSort.card_sort_cards_attributes)
  ) {
    return `Upload in progress`
  }
}
