import { useMaybeActiveStripeSubscription } from "./useCurrentAccount"

export const useShowStripeSubsriptionPastDueBanner = () => {
  const activeStripeSubscription = useMaybeActiveStripeSubscription()

  return (
    activeStripeSubscription &&
    activeStripeSubscription.stripe_status === "past_due"
  )
}
