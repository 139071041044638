import {
  Code,
  Flex,
  Grid,
  GridItem,
  Kbd,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"

import { Heading } from "DesignSystem/components"
import { Copy06OutlineIcon } from "Shared/icons/untitled-ui/Copy06OutlineIcon"
import React, { useEffect, useState } from "react"

// second arg here = whether it's recursive
const sharedIconsContext = require.context("Shared/icons", false, /\.tsx$/)
const untitledUiIconsContext = require.context(
  "Shared/icons/untitled-ui",
  true,
  /\.tsx$/
)
const UNTITLED_UI_PATH = "Shared/icons/untitled-ui/"
const SHARED_ICONS_PATH = "Shared/icons/"

const extractIconNameFromPath = (path: string) =>
  path.replace(/^.*[\\\/]/, "").replace(".tsx", "")

const generateIconList = ({
  context,
  importPath,
}: {
  context: __WebpackModuleApi.RequireContext
  importPath: string
}): Omit<IconListItemProps, "setIconToCopy">[] => {
  const iconPaths = context.keys()
  return iconPaths
    .map((path) => {
      const name = extractIconNameFromPath(path)
      const IconComponent = context(path)[name]
      return {
        name,
        importPath: `${importPath}${name}`,
        component: <IconComponent key={path} boxSize={12} />,
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))
}

const untitledUiIcons = {
  title: "Untitled UI",
  path: UNTITLED_UI_PATH,
  icons: generateIconList({
    context: untitledUiIconsContext,
    importPath: UNTITLED_UI_PATH,
  }),
}
const sharedIcons = {
  title: "Miscellaneous",
  path: SHARED_ICONS_PATH,
  icons: generateIconList({
    context: sharedIconsContext,
    importPath: SHARED_ICONS_PATH,
  }),
}

type IconToCopy = Pick<IconListItemProps, "importPath" | "name">
type IconToCopySetter = ReturnType<typeof useState<IconToCopy | null>>[1]

export const IconsRoute: React.FC = () => {
  const [iconToCopy, setIconToCopy] = useState<IconToCopy | null>(null)
  const [shiftHeld, setShiftHeld] = useState(false)
  const [metaHeld, setMetaHeld] = useState(false)

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.metaKey) setMetaHeld(true)
      else if (e.shiftKey) setShiftHeld(true)
    }

    const handleKeyup = (e: KeyboardEvent) => {
      if (!e.metaKey) setMetaHeld(false)
      if (!e.shiftKey) setShiftHeld(false)
    }

    document.addEventListener("keydown", handleKeydown)
    document.addEventListener("keyup", handleKeyup)

    return () => {
      document.removeEventListener("keydown", handleKeydown)
      document.removeEventListener("keyup", handleKeyup)
    }
  }, [])

  const textToCopy = !iconToCopy
    ? ""
    : metaHeld && !shiftHeld
      ? getImportLine(iconToCopy)
      : !metaHeld && shiftHeld
        ? `<${iconToCopy.name} />`
        : iconToCopy.name

  const allIconSets = [untitledUiIcons, sharedIcons]

  return (
    <Flex p={16} gap={8} flexDirection="column" color="text.primary">
      <DocsText />
      <Flex flexDirection="column" gap={8}>
        {allIconSets.map((set) => (
          <React.Fragment key={set.path}>
            <Heading as="h2" textStyle="ds.display.emphasized" mt={16}>
              {set.title}
            </Heading>
            <Code w="fit-content">{set.path}</Code>
            <Grid
              flexGrow={1}
              autoRows="130px"
              templateColumns={"repeat(auto-fit,minmax(250px,1fr))"}
              gap={2}
            >
              {set.icons.map((icon) => (
                <IconListItem
                  key={icon.name}
                  {...icon}
                  setIconToCopy={setIconToCopy}
                />
              ))}
            </Grid>
          </React.Fragment>
        ))}
      </Flex>
      <Flex
        pos="fixed"
        fontSize="lg"
        bottom={0}
        left={0}
        right={0}
        h={10}
        border="none"
        justify="center"
        align="center"
        bg="gray.800"
        color="white"
        fontFamily="monospace"
        shadow="lg"
      >
        {textToCopy}
      </Flex>
    </Flex>
  )
}

const DocsText = () => (
  <Flex flexDirection="column" gap={8}>
    <Heading as="h1" textStyle="ds.display.emphasized">
      Icons Overview
    </Heading>
    <Flex flexDirection="column" gap={4}>
      <Text maxW="80ch" textStyle="ds.paragraph.emphasized">
        This is a dynamic list of all the React icon components that currently
        live in <Code>app/frontend/shared/icons/</Code>.
      </Text>
      <Text maxW="80ch" textStyle="ds.paragraph.emphasized">
        When you're doing front-end work, you can use this page to see if the
        icon in a design already exists in the codebase for you to re-use. If
        not, please follow{" "}
        <Link href="https://usabilityhub.gitlab.io/app/code-conventions/frontend#using-icons">
          the documentation
        </Link>{" "}
        to add it.
      </Text>
      <Text maxW="80ch" textStyle="ds.paragraph.emphasized">
        (This page relies on each icon’s filename matching the name of the
        exported component, so please make sure these are always identical when
        you add a new icon.)
      </Text>
      <Heading mt="1em" as="h3" textStyle="ds.display.primary">
        How to use icons
      </Heading>
      <Text maxW="80ch" textStyle="ds.paragraph.emphasized">
        Use icons like this:
      </Text>
      <Code
        p={4}
        display="block"
        whiteSpace="pre"
        maxW="50rem"
        overflowX="auto"
      >
        {`import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"`}
        <br />
        <br />
        {`<CheckCircleSolidIcon />`}
      </Code>
      <Text maxW="80ch" textStyle="ds.paragraph.emphasized">
        <strong>
          Our current default icon library is{" "}
          <Link fontWeight="inherit" href="https://www.untitledui.com/icons">
            Untitled UI
          </Link>
        </strong>
        . We used to use Heroicons, and are moving away from it. If you see an
        icon in a design that looks like a Heroicon, please ask the designer if
        there is an Untitled UI equivalent you could use, and consider replacing
        any existing Heroicons with it.
      </Text>
      <Text maxW="80ch" textStyle="ds.paragraph.emphasized">
        See our{" "}
        <Link href="https://usabilityhub.gitlab.io/app/code-conventions/frontend#using-icons">
          documentation on icons
        </Link>{" "}
        for more info.
      </Text>
      <Heading mt="1em" as="h2" textStyle="ds.display.primary">
        Copy-to-clipboard commands
      </Heading>
      <Table mt={4} w="100%" maxW="50rem" bg="white" rounded="lg" shadow="sm">
        <Thead>
          <Tr>
            <Th>event</Th>
            <Th>action</Th>
          </Tr>
        </Thead>
        <Tbody>
          <CommandTableRow
            cmd="click"
            action="copy icon name"
            example="CheckOutlineIcon"
          />
          <CommandTableRow
            cmd="⌘ click"
            action="copy icon import code"
            example={`import { CheckOutlineIcon } from "Shared/icons/untitled-ui/CheckOutlineIcon"`}
          />
          <CommandTableRow
            cmd="⇧ click"
            action="copy icon as JSX"
            example={`<CheckOutlineIcon />`}
          />
        </Tbody>
      </Table>
    </Flex>
  </Flex>
)

type IconListItemProps = {
  name: string
  importPath: string
  component: React.ReactElement
  setIconToCopy: IconToCopySetter
}

const IconListItem: React.FC<IconListItemProps> = ({
  setIconToCopy,
  ...icon
}) => (
  <GridItem
    as="button"
    bg="white"
    border="2px solid transparent"
    rounded="md"
    onMouseOver={() => setIconToCopy(icon)}
    onMouseOut={() => setIconToCopy(null)}
    onClick={(e) =>
      e.metaKey
        ? copyToClipboard(getImportLine(icon))
        : e.shiftKey
          ? copyToClipboard(`<${icon.name} />`)
          : copyToClipboard(icon.name)
    }
    pos="relative"
    shadow="sm"
    outline="none"
    _hover={{
      shadow: "md",
      "& > svg": { display: "block" },
    }}
    sx={{
      "& > [data-copy-emoji]": {
        opacity: 0,
        transition: "opacity 3s ease-out",
      },
      "& > svg": {
        opacity: 1,
        transition: "opacity 3s ease-in",
      },
    }}
    _active={{
      position: "relative",
      top: "1px",
      shadow: "sm",
      transition: "none",
      "& > [data-copy-emoji]": {
        opacity: 1,
        transition: "none",
      },
      "& > svg": {
        opacity: 0,
        transition: "none",
      },
    }}
  >
    <Copy06OutlineIcon
      pos="absolute"
      display="none"
      color="#0003"
      boxSize={6}
      top={4}
      right={4}
    />
    <Text
      pos="absolute"
      fontSize="21px"
      top={4}
      right={4}
      opacity={0}
      data-copy-emoji
    >
      ✅
    </Text>
    <Flex flexDirection="column" h="full" justify="center">
      <Flex justify="center" w="full" basis="65px">
        {icon.component}
      </Flex>
      <Flex fontSize={12} fontWeight="medium" justify="center">
        {icon.name}
      </Flex>
    </Flex>
  </GridItem>
)

const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text)
}

const getImportLine = ({
  name,
  importPath,
}: Pick<IconListItemProps, "name" | "importPath">) =>
  `import { ${name} } from "${importPath}"`

const CommandTableRow = ({
  cmd,
  action,
  example,
}: {
  cmd: string
  action: string
  example: string
}) => (
  <Tr>
    <Td fontSize="2xl" fontWeight="normal">
      <Kbd p={2} fontFamily="inherit" fontWeight="normal">
        {cmd}
      </Kbd>
    </Td>
    <Td>
      <Text fontWeight="semibold">{action}</Text>
      <Code bg="inherit" mt={2} p={0} fontSize="xs">
        {example}
      </Code>
    </Td>
  </Tr>
)
