import { BoxProps, Center, useToast } from "@chakra-ui/react"
import { trackSignedUp } from "JavaScripts/analytics/track"
import { getEnvState } from "JavaScripts/state"
import { axios, isBadRequestError, isNotFoundError } from "Services/axios"
import { Account } from "Types"
import { useRefreshCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useRefreshTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { ROUTES } from "UsabilityHub/views/routes"
import { throwIfNonAxiosError } from "Utilities/error"
import { ScriptLoadError, loadScript } from "Utilities/script"
import React, { useEffect } from "react"
import { useNavigate } from "react-router"

type GoogleSignInProps = React.PropsWithChildren<
  BoxProps & {
    buttonVariant: "signin_with" | "signup_with" | "continue_with" | "signin"
    intent: "sign_in" | "sign_up"
    requestParams?: Record<string, string>
  }
>

type GoogleResponse = {
  clientId: string
  credential: string
  select_by: string
  g_csrf_token: string
}

type GoogleSigninResponse = {
  account: Account
  redirect_to?: string
}

/**
 * Use for Google auth when accepting an invitation to join an existing team.
 */
export const AcceptInvitationGoogleSignIn: React.FC<
  Omit<GoogleSignInProps, "intent">
> = ({ buttonVariant, requestParams = {} }) => {
  const navigationCallback = async (
    response: GoogleResponse,
    requestParams = {}
  ) => {
    try {
      await axios.post(`/api/google_accept_invitation`, {
        google_response: response,
        ...requestParams,
      })

      // Can't useNavigate as accepting an invitation is outside SPA routing
      window.location.href = ROUTES.DASHBOARD.path
    } catch (error) {
      throwIfNonAxiosError(error)
    }
  }

  return (
    <GoogleSignInInternal
      buttonVariant={buttonVariant}
      navigationCallback={navigationCallback}
      requestParams={requestParams}
    />
  )
}

/**
 * Use for Google auth when signing in or signing up
 */
export const GoogleSignIn: React.FC<GoogleSignInProps> = ({
  buttonVariant,
  intent,
  requestParams = {},
  ...props
}) => {
  const toast = useToast()
  const navigate = useNavigate()
  const refreshTeamMembers = useRefreshTeamMembers()
  const refreshCurrentAccount = useRefreshCurrentAccount()

  const navigationCallback = async (response: {
    clientId: string
    credential: string
    select_by: string
    g_csrf_token: string
  }) => {
    try {
      switch (intent) {
        case "sign_in": {
          const { data } = await axios.post<GoogleSigninResponse>(
            `/api/google_sign_in`,
            {
              google_response: response,
              ...requestParams,
            }
          )

          refreshCurrentAccount()
          refreshTeamMembers()
          navigate(data.redirect_to || ROUTES.DASHBOARD.path)

          return
        }
        case "sign_up": {
          const { data } = await axios.post<GoogleSigninResponse>(
            `/api/google_sign_up`,
            {
              google_response: response,
              ...requestParams,
            }
          )

          trackSignedUp("signup_form")

          refreshCurrentAccount()
          refreshTeamMembers()

          navigate(ROUTES.SETUP.ONBOARDING.buildPath({ stepId: "role" }), {
            replace: true,
            state: {
              redirectTo: data.redirect_to || ROUTES.DASHBOARD.path,
            },
          })

          return
        }
        default: {
          toast({
            title: `Something went wrong, please contact Lyssna Support!`,
            status: "error",
          })

          navigate(ROUTES.DASHBOARD.path)

          return
        }
      }
    } catch (error) {
      toast({
        title: error.response.data.message,
        status: "error",
      })
    }
  }

  return (
    <GoogleSignInInternal
      buttonVariant={buttonVariant}
      navigationCallback={navigationCallback}
      requestParams={requestParams}
      {...props}
    />
  )
}

type InternalGoogleSignIn = React.FC<
  Omit<GoogleSignInProps, "intent"> & {
    navigationCallback: (
      response: GoogleResponse,
      requestParams: unknown
    ) => void
  }
>

/**
 * Internal component that accepts a navigationCallback to redirect once auth is complete, so we can avoid useNavigation outside a router.
 *
 * See GoogleSignIn and AcceptInvitationGoogleSignIn
 */
const GoogleSignInInternal: InternalGoogleSignIn = ({
  buttonVariant,
  navigationCallback,
  requestParams = {},
  ...props
}) => {
  // Ref: https://developers.google.com/identity/gsi/web/guides/migration#the_new_way
  // Use Popup mode with the new library in a simple authentication-only sign-in scenario

  const env = getEnvState()
  const toast = useToast()

  useEffect(() => {
    // Create a callback function which will be invoked by Google
    // after a successful login
    const googleSignInCallback = async (response: GoogleResponse) => {
      try {
        navigationCallback(response, requestParams)
      } catch (error) {
        if (isBadRequestError(error) || isNotFoundError(error)) {
          toast({
            title: error.response.data.message,
            status: "error",
          })
        } else {
          throw error
        }
      }
    }

    window.googleSignInCallback = googleSignInCallback
  }, [requestParams, toast])

  useEffect(() => {
    if (env.GOOGLE_SIGN_IN_ENABLED) {
      const loadGoogleSignIn = async () => {
        try {
          await loadScript("https://accounts.google.com/gsi/client")
        } catch (error) {
          if (error instanceof ScriptLoadError) {
            // If the script fails to load, don't report this to Sentry. There's
            // nothing we can do about it
            return
          }
          throw error
        }
      }
      void loadGoogleSignIn()
    }
  }, [env.GOOGLE_SIGN_IN_ENABLED])

  if (!env.GOOGLE_SIGN_IN_ENABLED) return null

  // The details of the Sign In With Google HTML data attributes:
  // https://developers.google.com/identity/gsi/web/reference/html-reference
  return (
    <Center {...props}>
      <div
        id="g_id_onload"
        data-client_id={env.GOOGLE_CLIENT_ID}
        data-callback="googleSignInCallback"
        data-auto_prompt="false"
      ></div>
      <div
        className="g_id_signin"
        data-type="standard"
        data-size="large"
        data-text={buttonVariant}
      ></div>
    </Center>
  )
}
