import { useQueryClient } from "@tanstack/react-query"

import { PollingIntervalMs } from "Utilities/polling"
import { useUsabilityTestOrders } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

export const usePollTestOrders = (usabilityTestUniqueId: string) => {
  return useUsabilityTestOrders(
    {
      pathParams: { usabilityTestId: usabilityTestUniqueId },
    },
    { refetchInterval: PollingIntervalMs }
  )
}

export const useRefreshTestOrders = (usabilityTestUniqueId: string) => {
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  const queryKey = queryKeyFn({
    path: "/api/usability_tests/{usability_test_id}/orders",
    operationId: "usabilityTestOrders",
    variables: { pathParams: { usabilityTestId: usabilityTestUniqueId } },
  })

  return async () => queryClient.invalidateQueries(queryKey)
}
