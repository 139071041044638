import {
  Button,
  Center,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React, { FormEventHandler } from "react"

import { SubmitButton } from "Components/button/submit-button"
import { FunctionalModal } from "Utilities/modals/types"
import {
  useArchiveModeratedStudy,
  useCanArchiveModeratedStudy,
} from "~/api/generated/usabilityhub-components"

type Props = {
  moderatedStudyId: string
  studyName: string
}

export const ArchiveModeratedStudyModal: FunctionalModal<Props> = ({
  moderatedStudyId,
  studyName,
  isOpen,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { data, isLoading } = useCanArchiveModeratedStudy({
    pathParams: { moderatedStudyId },
  })
  const { mutateAsync: archiveModeratedStudy, isLoading: isArchiving } =
    useArchiveModeratedStudy({
      onSuccess: async () => {
        void queryClient.invalidateQueries(["api", "studies"])
        await queryClient.invalidateQueries(["api", "moderated_studies"], {
          exact: true,
        })

        toast({
          title: "Study archived",
          status: "success",
        })

        onClose()
      },
    })

  const handleArchive: FormEventHandler = async (e) => {
    e.preventDefault()

    try {
      await archiveModeratedStudy({ pathParams: { moderatedStudyId } })
    } catch (e) {
      if (e.status === 400) {
        // Potentially something new preventing the archival has happened since the modal was opened.
        // Invalidate the query so that the modal is re-rendered with the latest reasons.
        await queryClient.invalidateQueries(
          ["api", "moderated_studies", moderatedStudyId, "archive"],
          {
            exact: true,
          }
        )
      } else {
        toast({
          title: "Archive failed",
          status: "error",
        })
      }
    }
  }

  const canArchive = data?.can_archive

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Archive “{studyName}”</ModalHeader>

        <form onSubmit={handleArchive}>
          <ModalBody>
            {isLoading && (
              <Center minH="80px">
                <Spinner />
              </Center>
            )}
            {!isLoading && canArchive && (
              <>
                By archiving this interview study, any active recruitment links
                will be disabled and it will be hidden from your Interviews
                dashboard.
              </>
            )}
            {!isLoading && !canArchive && (
              <>
                <Text mb={2}>This study can't be archived:</Text>
                <UnorderedList>
                  {data?.reasons.map((reason) => (
                    <ListItem key={reason}>{reason}</ListItem>
                  ))}
                </UnorderedList>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button
                variant="outline"
                isDisabled={isArchiving}
                onClick={onClose}
              >
                Cancel
              </Button>
              <SubmitButton
                colorScheme="red"
                isDisabled={!canArchive}
                isLoading={isArchiving}
                loadingAction="Archiving"
              >
                Archive
              </SubmitButton>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
