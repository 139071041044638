import { OtherHitzoneFilter } from "../filters/other-hitzone"

import { generateFilterId } from "./generate-filter-id"
import { createHitzoneFilterCategory } from "./hitzone-filter"

export function createOtherHitzoneFilter(
  sectionId: number,
  screenshotId: number
): OtherHitzoneFilter {
  return {
    id: generateFilterId(),
    category: createHitzoneFilterCategory(sectionId, screenshotId),
    type: "answer/other-hitzone",
    value: undefined,
  }
}
