import { Filter } from "Components/test-results/types/filters"
import { isPointInRectangle } from "Utilities/rectangle"

import { getFilterMeta } from "../get-filter-meta"

import { HitzoneFilterCategory } from "./hitzone"
import { FilterFunction } from "./types"

export type OtherHitzoneFilter = Filter<
  HitzoneFilterCategory,
  "answer/other-hitzone",
  undefined
>

export const filterResponseByOtherHitzone: FilterFunction<
  OtherHitzoneFilter
> = ({
  filter,
  filteredRelatedData: { screenshotClicks },
  allRelatedData: { clientHitzones, hitzones },
}) => {
  const { screenshotId } = getFilterMeta(filter.category)

  const filteredClientHitzone = clientHitzones.filter(
    (clientHitzone) =>
      clientHitzone.usability_test_section_screenshot_id === screenshotId
  )

  const filteredHitzones = hitzones.filter(
    (hitzone) => hitzone.usability_test_section_screenshot_id === screenshotId
  )

  const filteredScreenshotClicks = screenshotClicks.filter(
    (click) => click.usability_test_section_screenshot_id === screenshotId
  )

  if (filteredScreenshotClicks.length === 0) return false

  return filteredScreenshotClicks.every(
    (click) =>
      ![...filteredClientHitzone, ...filteredHitzones].some(
        isPointInRectangle(click)
      )
  )
}
