import React from "react"

import { SectionResultsSectionText } from "UsabilityHub/components/TestResults/SectionResultsLabels"

import { SectionResultsCard } from "./SectionResultsCard"

export const InstructionSectionResultsCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <SectionResultsCard>
      <SectionResultsSectionText />
    </SectionResultsCard>
  )
}
