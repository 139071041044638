import { Consumer, Subscription } from "@rails/actioncable"
import { useContext, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { addFigmaFileVersion } from "Redux/reducers/figma-file-versions/actions"
import { AppContext } from "Shared/contexts/AppContext"
import { FigmaFileVersion } from "Types"

type Message = {
  action: "update"
  data: { status: number; figma_file_version: FigmaFileVersion }
}

/**
 * Subscribe to changes to a FigmaFileVersion.
 * FigmaFileVersions are processed asynchronously on the server so attributes (eg frame titles) may appear later
 *
 * @param figmaFileVersionId id of the FigmaFileVersion to subscribe to for changes
 */
export const useFigmaFileVersionSync = (
  figmaFileVersionId: number | null | undefined
) => {
  const { consumer } = useContext(AppContext)
  const channel = useRef<Subscription<Consumer>>()
  const dispatch = useDispatch<Dispatch>()

  const unsubscribe = () => {
    if (channel.current) {
      channel.current.unsubscribe()
      channel.current = undefined
    }
  }

  useEffect(() => {
    if (figmaFileVersionId) {
      const identifier = {
        channel: "FigmaFileVersionSyncChannel",
        figma_file_version_id: figmaFileVersionId,
      }
      channel.current = consumer?.subscriptions.create(identifier, {
        received: (message: Message) => {
          // Add the updated figma file version
          dispatch(addFigmaFileVersion(message.data.figma_file_version))
        },
      })
    }

    return () => {
      unsubscribe()
    }
  }, [consumer, dispatch, figmaFileVersionId])
}
