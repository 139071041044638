import { Grid, ModalBody, Spinner, Text } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import { range } from "lodash"
import React, { useEffect, useRef } from "react"
import { useTemplateLibrary } from "~/usabilityhub/hooks/useTemplateLibrary"
import { Filters } from "./Filters"
import { HighlightedText } from "./HighlightedText"
import { TemplateCard } from "./TemplateCard"

export const TemplatesList: React.FC = () => {
  const { loading, filtered, filter } = useTemplateLibrary()

  const scrollAreaRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    scrollAreaRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [filtered])

  if (loading) {
    return (
      <ModalBody
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" color="brand.primary.500" thickness="0.25rem" />
      </ModalBody>
    )
  }

  return (
    <ModalBody
      p={0}
      flex={1}
      minH={0}
      display={{ base: "block", md: "flex" }}
      overflowY={{ base: "auto", md: "hidden" }}
    >
      <Grid
        gridTemplate={{
          base: `"filters" auto "thumbnails" 1fr / 1fr`,
          md: `"filters thumbnails" 100% / 1fr 3fr`,
        }}
        gridColumnGap={3}
        p={0}
        minH={0}
        flex={1}
      >
        <Filters />
        {filtered.length > 0 ? (
          <Grid
            gridArea={"thumbnails"}
            gridTemplateColumns={{
              base: "1fr",
              sm: "repeat(auto-fit, minmax(12rem, 1fr))",
              lg: "repeat(auto-fit, minmax(14rem, 1fr))",
            }}
            alignItems="start"
            placeContent="start"
            pl={{ base: 4, md: 0 }}
            pr={5}
            mr={3}
            pb={{ base: 6, md: 3 }}
            pt={{ base: 4, md: 0 }}
            gap={{ base: 4, md: "2rem 1rem" }}
            overflowY={{ md: "auto" }}
            minH={0}
            ref={scrollAreaRef}
          >
            <AnimatePresence mode="popLayout">
              {filtered.map((template, i) => (
                <motion.div
                  key={`${filter?.id || "all"}:${template.item.id}`}
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: { delay: i * 0.02 + 0.2, duration: 0.2 },
                  }}
                  exit={{
                    opacity: 0,
                    scale: 0.95,
                    transition: { delay: i * 0.02, duration: 0.2 },
                  }}
                >
                  <TemplateCard key={template.item.id} template={template.item}>
                    <HighlightedText
                      text={template.item.title}
                      matches={template.matches || []}
                    />
                  </TemplateCard>
                </motion.div>
              ))}
            </AnimatePresence>
            {/* Prevent cards in less-than-full rows from growing too wide */}
            {range(5).map((i) => (
              <div key={i} />
            ))}
          </Grid>
        ) : (
          <Grid placeContent="center" p={8}>
            <Text color="text.secondary">Nothing matched your search</Text>
          </Grid>
        )}
      </Grid>
    </ModalBody>
  )
}
