import { Validator } from "redux-form"

import { containsURL } from "Utilities/url-regex"

export const CONTAINS_URLS_WARNING = "Test includes an external URL"

export const containsURLs: Validator = (fieldValue: string) => {
  // We currently don't show this message anywhere, but we use it to work out
  // whether to show the sidebar alert.
  return containsURL(fieldValue) ? CONTAINS_URLS_WARNING : undefined
}
