import { Box, Flex, Text } from "@chakra-ui/react"
import { cq, cqContainer } from "Shared/helpers/containerQuerySx"
import React, { PropsWithChildren } from "react"

const MID_BREAKPOINT = "40rem"
const DESKTOP_BREAKPOINT = "60rem"

type Props = {
  icon: React.ReactNode
  title: React.ReactNode
  description?: React.ReactNode
  manage: React.ReactNode
  metrics: React.ReactNode
  profile: React.ReactNode
  menu: React.ReactNode
  isAccordionButton?: boolean
}

// All the visibility-related CSS should stay here - I've used data attributes
// to try to keep it as readable as possible
const MIN_STYLES = {
  "[data-ui]:only-child": { flexGrow: 1 },
  "[data-ui$='-mid']": { display: "none" },
  "[data-ui$='-wide']": { display: "none" },
  "[data-ui='title-inner']": { display: "contents" },
}

const MID_STYLES = {
  "[data-ui$='-mid']": { display: "flex" },
  "[data-ui='manage-narrow']": { display: "none" },
}

const DESKTOP_STYLES = {
  "[data-ui$='-narrow']": { display: "none" },
  "[data-ui$='-wide']": { display: "flex" },
  "[data-ui='row']": { display: "contents" },
  "[data-ui='row']:has(> [data-ui='description-narrow'])": { display: "none" },
  "[data-ui='title-inner']": { display: "flex" },
  p: 6,
  display: "grid",
  gridTemplateAreas: `"icon title manage metrics profile menu"`,
  gridTemplateColumns: "auto 1fr 10.875rem 10.875rem auto 2rem",
  columnGap: 3,
}

const containedCardStyles = {
  bg: "ds.surface.raised.resting",
  rounded: "16px",
  shadow: "ds.raised",
  minH: "6rem",
}

const desktopGridArea = (name: string) => ({ gridArea: name })

/**
 * This is a presentational component for link/order items on the Recruit page
 * (mostly just layout - consumers handle the content).
 *
 * The mobile/tablet views here are very Flexbox-like, and the desktop view must
 * be Grid, with necessarily different DOM ordering in each. Making the whole
 * thing Grid is not a good solution, because the DOM order won't be respected
 * in some views, causing problems for accessibility (tab order).
 * See https://drafts.csswg.org/css-grid/#order-accessibility
 *
 * We can choose to render "small" and "large" components based on a media query,
 * but this is not possible with container queries. This component is not related
 * to the viewport size, so a container query is the sensible choice if we don't
 * want the component to break in future when the page layout changes.
 *
 * To use container queries while doing conditional rendering with JS, we would
 * need a listener to respond to container size, and sadly one does not exist in
 * JS yet (or Chakra). Instead we'd need to reach for a ResizeObserver, which
 * feels like overkill here.
 * See "Issue 6" in the CSS spec:
 * https://www.w3.org/TR/css-contain-3/#the-csscontainerrule-interface
 *
 * Since we don't expect there'll ever be a lot of rows rendered on the Recruit
 * page, I've opted to avoid using a ResizeObserver and instead just
 * double-render certain elements and show/hide them with CSS. Double-rendering
 * is also a small perf hit, but I think it's better than a heavy JS approach
 * here, or using media queries and risking the page visually breaking in future
 * when the layout changes.
 *
 * Once a native/performant container query JS listener is available, we should
 * refactor this to conditionally render subcomponents only once, based on
 * container size.
 */
export const RecruitmentSummaryCard: React.FC<Props> = ({
  icon,
  title,
  description,
  manage,
  metrics,
  profile,
  menu,
  isAccordionButton = false,
}) => {
  return (
    <Box sx={cqContainer()} w="full">
      <Flex
        flexDirection="column"
        gap={4}
        {...(!isAccordionButton ? containedCardStyles : {})}
        p={4}
        sx={{
          ...MIN_STYLES,
          ...cq(MID_BREAKPOINT, MID_STYLES),
          ...cq(DESKTOP_BREAKPOINT, DESKTOP_STYLES),
        }}
      >
        <Flex gap={2} data-ui="row">
          <CardIcon>{icon}</CardIcon>
          <Flex grow="1" sx={cq(DESKTOP_BREAKPOINT, desktopGridArea("title"))}>
            <Flex flexDirection="column" data-ui="title-inner">
              <CardTitle>{title}</CardTitle>
              {/* Description sits inside the title area on desktop */}
              {description && (
                <CardDescription showWhen="wide">{description}</CardDescription>
              )}
            </Flex>
          </Flex>
          <CardManage showWhen="mid">{manage}</CardManage>
          <CardMenu showWhen="narrow">{menu}</CardMenu>
        </Flex>
        <CardManage showWhen="narrow">{manage}</CardManage>
        {description && (
          <Flex data-ui="row">
            <CardDescription showWhen="narrow">{description}</CardDescription>
          </Flex>
        )}
        <CardMetrics showWhen="wide">{metrics}</CardMetrics>
        <CardProfile showWhen="wide">{profile}</CardProfile>
        <CardMenu showWhen="wide">{menu}</CardMenu>
        <Flex justifyContent="space-between" gap={2} data-ui="row">
          <CardMetrics showWhen="narrow">{metrics}</CardMetrics>
          <CardProfile showWhen="narrow">{profile}</CardProfile>
        </Flex>
      </Flex>
    </Box>
  )
}

type ShowWhenProps = { showWhen: "narrow" | "mid" | "wide" }

const CardIcon: React.FC<PropsWithChildren> = ({ children }) => (
  <Flex sx={cq(DESKTOP_BREAKPOINT, desktopGridArea("icon"))} data-ui="icon">
    {children}
  </Flex>
)

const CardTitle: React.FC<PropsWithChildren> = ({ children }) => (
  <Flex grow="1" alignItems="center" data-ui="title">
    <Text textStyle="ds.heading.primary">{children}</Text>
  </Flex>
)

const CardDescription: React.FC<PropsWithChildren<ShowWhenProps>> = ({
  showWhen,
  children,
}) => (
  <Flex data-ui={`description-${showWhen}`} sx={getSx("description", showWhen)}>
    <Text textStyle="ds.paragraph.primary" color="ds.text.subtle">
      {children}
    </Text>
  </Flex>
)

const CardManage: React.FC<PropsWithChildren<Omit<ShowWhenProps, "wide">>> = ({
  showWhen,
  children,
}) => (
  <Flex
    alignItems="center"
    data-ui={`manage-${showWhen}`}
    sx={getSx("manage", showWhen)}
  >
    {children}
  </Flex>
)

const CardMetrics: React.FC<PropsWithChildren<ShowWhenProps>> = ({
  showWhen,
  children,
}) => (
  <Flex
    alignItems="center"
    data-ui={`metrics-${showWhen}`}
    sx={getSx("metrics", showWhen)}
    ps={showWhen === "wide" ? 2 : 0}
  >
    <Text textStyle="ds.heading.secondary">{children}</Text>
  </Flex>
)

const CardProfile: React.FC<PropsWithChildren<ShowWhenProps>> = ({
  showWhen,
  children,
}) => (
  <Flex data-ui={`profile-${showWhen}`} sx={getSx("profile", showWhen)}>
    {children}
  </Flex>
)

const CardMenu: React.FC<PropsWithChildren<ShowWhenProps>> = ({
  showWhen,
  children,
}) => (
  <Flex
    alignItems="center"
    data-ui={`menu-${showWhen}`}
    sx={getSx("menu", showWhen)}
  >
    {children}
  </Flex>
)

const getSx = (name: string, showWhen: ShowWhenProps["showWhen"]) =>
  showWhen === "wide" || showWhen === "mid"
    ? cq(DESKTOP_BREAKPOINT, desktopGridArea(name))
    : undefined
