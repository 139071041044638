import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Text,
  useBoolean,
  useOutsideClick,
} from "@chakra-ui/react"
import { Pencil02SolidIcon } from "Shared/icons/untitled-ui/Pencil02SolidIcon"
import { get } from "lodash"
import React from "react"
import {
  FieldErrors,
  FieldPath,
  FieldValues,
  UseFormRegister,
  UseFormResetField,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form"

type InPlaceEditorProps<T extends FieldValues> = {
  fieldName: FieldPath<T>
  label: string
  errors: FieldErrors<T>
  warning?: string
  handleSave: () => Promise<void>
  resetField: UseFormResetField<T>
  isDirty: boolean
  isSubmitting: boolean
  watch: UseFormWatch<T>
  trigger: UseFormTrigger<T>
  register: UseFormRegister<T>
  defaultEditing?: boolean
  isReadOnly: boolean
}

const InPlaceEditor = <T extends FieldValues>({
  fieldName,
  label,
  errors,
  warning,
  handleSave,
  resetField,
  isDirty,
  isSubmitting,
  watch,
  trigger,
  register,
  defaultEditing = false,
  isReadOnly,
}: InPlaceEditorProps<T>) => {
  const [editing, { on, off }] = useBoolean(defaultEditing && !isReadOnly)
  const ref = React.useRef<HTMLInputElement>(null)

  const stopEditing = async () => {
    resetField(fieldName)
    // re-validate previous value
    await trigger()
    off()
  }

  useOutsideClick({
    ref,
    handler: () => {
      if (!editing) return

      stopEditing().catch(() => void 0)
    },
  })

  if (!editing) {
    const value = watch(fieldName)
    const error = errors[fieldName]?.message

    return (
      <Flex gap={2}>
        {error ? (
          <Text color="red.500">{String(error)}</Text>
        ) : warning ? (
          <Text color="red.500">{warning}</Text>
        ) : (
          <Text color="text.primary">{value}</Text>
        )}
        {!isReadOnly && (
          <IconButton
            variant="ghost"
            size="xs"
            aria-label="Edit"
            icon={
              <Pencil02SolidIcon color="brand.neutral.light" boxSize={3.5} />
            }
            onClick={on}
          />
        )}
      </Flex>
    )
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault()
        if (!isDirty || errors[fieldName] !== undefined) return

        await handleSave()
        off()
      }}
      style={{ display: "contents" }}
    >
      <Flex gap={2} ref={ref} flexGrow={1}>
        <FormControl
          isInvalid={errors[fieldName] !== undefined}
          display="flex"
          flexDirection="column"
        >
          <Input
            autoFocus
            aria-label={label}
            focusBorderColor={errors[fieldName] ? "red.500" : undefined}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                stopEditing().catch(() => void 0)
              }
            }}
            {...register(fieldName)}
          />
          <FormErrorMessage>
            <>{get(errors, fieldName)?.message}</>
          </FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          colorScheme="brand.primary"
          isDisabled={!isDirty || errors[fieldName] !== undefined}
          isLoading={isSubmitting}
        >
          Save
        </Button>
      </Flex>
    </form>
  )
}

export default InPlaceEditor
