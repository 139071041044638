import {
  Box,
  Button,
  Collapse,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { SubmitButton } from "Components/button/submit-button"
import { JsForm } from "Components/form/form"
import { ProTrialCallout } from "Components/trial-widget/ProTrialCallout"
import { Alert } from "DesignSystem/components/Alert"
import { ShallowUsabilityTest } from "Types"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import { ProFeatureTooltip } from "UsabilityHub/components/ProFeatureCallout/ProFeatureTooltip"
import { UpgradeUsabilityTestModal } from "UsabilityHub/components/UpgradeUsabilityTestBanner/UpgradeUsabilityTestModal"
import { UpgradeUsabilityTestSuccessModal } from "UsabilityHub/components/UpgradeUsabilityTestBanner/UpgradeUsabilityTestSuccessModal"
import { useShowProTrialWidget } from "Utilities/account"
import React, { useState } from "react"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import type {
  Account,
  RecordingType,
  RecruitmentLink,
  VariationSet,
} from "~/api/generated/usabilityhubSchemas"
import { ConfirmDisableLinkModal } from "./ConfirmDisableLinkModal"
import { RecruitmentLinkFormFields } from "./RecruitmentLinkFormFields"
import { RecruitmentUpgradeBanner } from "./RecruitmentUpgradeBanner"
import { enabledLinkableFeaturesAccountLacks } from "./enabledLinkableFeaturesAccountLacks"
import { usePersistLinkChanges } from "./persistLinkChanges"
interface Props {
  account: Account
  onClose: () => void
  onUpgrade?: (credits: number) => void
  isUpgraded: boolean
  linkable:
    | Pick<
        ShallowUsabilityTest,
        "unique_id" | "name" | "recruited_response_count"
      >
    | Pick<VariationSet, "unique_id" | "name" | "recruited_response_count">
  isTestSetLink: boolean
  recruitmentLink: RecruitmentLink | null
  recordingTypes: RecordingType[]
}

export const RecruitmentLinkFormModal: React.FC<Props> = ({
  linkable,
  isTestSetLink,
  isUpgraded,
  account,
  onClose,
  onUpgrade,
  recruitmentLink,
  recordingTypes,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showDisableConfirmModal, setShowDisableConfirmModal] = useState(false)
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)
  const [upgradeSuccessModalOpen, setUpgradeSuccessModalOpen] = useState(false)

  const showProTrialWidget = useShowProTrialWidget()

  // TODO: This is kind of nonstandard, someday let's refactor it into a form with its own state
  const setRecruitmentLink = (recruitmentLink: Partial<RecruitmentLink>) => {
    const queryKey = queryKeyFn({
      operationId: "getRecruitmentLink",
      path: "/api/recruitment_links",
      variables: {
        queryParams: isTestSetLink
          ? {
              test_set_unique_id: linkable.unique_id,
            }
          : {
              usability_test_unique_id: linkable.unique_id,
            },
      },
    })
    const existingData = queryClient.getQueryData<RecruitmentLink>(queryKey)
    queryClient.setQueryData(queryKey, { ...existingData, ...recruitmentLink })
  }

  const persistLinkChanges = usePersistLinkChanges(
    isTestSetLink
      ? { test_set_unique_id: linkable.unique_id }
      : { usability_test_unique_id: linkable.unique_id }
  )

  if (!recruitmentLink) {
    return (
      <Modal size="xl" isOpen onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Recruitment link
            <Text fontSize="md" fontWeight="normal">
              Loading...
            </Text>
          </ModalHeader>
        </ModalContent>
      </Modal>
    )
  }

  const updatedSuccessMessage = () => {
    toast({
      status: "success",
      title: `Recruitment link for "${linkable.name}" updated`,
      isClosable: false,
    })
  }

  const handleDisable = async (confirmed: boolean) => {
    setShowDisableConfirmModal(false)

    if (!confirmed) return

    await persistLinkChanges({ active: false })
    updatedSuccessMessage()
    onClose()
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)

    try {
      await persistLinkChanges({
        ...recruitmentLink,
        active: true,
      })
      updatedSuccessMessage()
      onClose()
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  const isLinkEnabled = recruitmentLink.active

  const allowTestUpgrades = account.allow_test_upgrades
  const paidFeatures = enabledLinkableFeaturesAccountLacks(
    account,
    recruitmentLink
  )

  const isUpgradeRequired =
    !isTestSetLink && !isUpgraded && paidFeatures.length !== 0

  return (
    <Modal size="xl" isOpen onClose={onClose}>
      <JsForm onSubmit={handleSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Recruitment link
            <Text fontSize="md" fontWeight="normal">
              Share a link with participants to gather responses.
            </Text>
          </ModalHeader>
          <ModalBody>
            {recruitmentLink.plan_response_limit && !isUpgraded && (
              <Alert
                mb={2}
                status="info"
                title={`Only the first ${recruitmentLink.plan_response_limit} responses recruited via this link will be visible`}
                description={
                  <>
                    On the Free plan you can collect unlimited responses, but
                    only the first {recruitmentLink.plan_response_limit} will be
                    visible. To view all responses you
                    {"\u2019"}ll need to{" "}
                    <PlanDrawerTrigger
                      as={Link}
                      variant="unstyled"
                      verticalAlign="inherit"
                      userSelect="initial"
                      color="brand.primary.500"
                      textDecoration="none"
                      requestedFeatures={paidFeatures}
                      source="test_recruitment_settings"
                      // close the recruitment link modal to allow scrolling in the drawer
                      // (TODO: we want to keep the modal open AND also
                      // be able to scroll in the drawer ideally - see PRD-4122)
                      onPlanDrawerOpen={onClose}
                    >
                      upgrade to a paid plan
                    </PlanDrawerTrigger>
                    .
                  </>
                }
              ></Alert>
            )}

            {showProTrialWidget && (
              <Flex mb={2}>
                <ProTrialCallout>
                  You can view{" "}
                  <ProFeatureTooltip
                    label={`Also available on Basic plan. Switching to Free plan will limit your max recruitment link responses to ${recruitmentLink.free_plan_response_limit}.`}
                  >
                    unlimited recruitment link responses
                  </ProFeatureTooltip>{" "}
                  on your{" "}
                  <Text as="span" fontWeight="bold">
                    Pro plan trial
                  </Text>
                </ProTrialCallout>
              </Flex>
            )}

            <RecruitmentLinkFormFields
              testUpgraded={!isTestSetLink && isUpgraded}
              isTestSetLink={isTestSetLink}
              account={account}
              recruitmentLink={recruitmentLink}
              recruitedResponseCount={linkable.recruited_response_count}
              onChange={setRecruitmentLink}
            />
            <RecordingAlert recordingTypes={recordingTypes} />
          </ModalBody>
          <Collapse in={isUpgradeRequired}>
            <RecruitmentUpgradeBanner
              allowTestUpgrades={allowTestUpgrades}
              creditCount={account.total_points}
              openUpgradeModal={() => setUpgradeModalOpen(true)}
              paidFeatures={paidFeatures}
            />
          </Collapse>
          <ModalFooter justifyContent="space-between">
            {isLinkEnabled ? (
              <Link
                href="#"
                colorScheme="red"
                variant="noUnderline"
                onClick={() => setShowDisableConfirmModal(true)}
              >
                Disable link
              </Link>
            ) : (
              <Box />
            )}
            <Box>
              <Button onClick={onClose} isDisabled={isSubmitting} mr={2}>
                Cancel
              </Button>
              <SubmitButton
                colorScheme="brand.primary"
                isLoading={isSubmitting}
                isDisabled={isUpgradeRequired}
              >
                {isLinkEnabled ? "Update link" : "Enable link"}
              </SubmitButton>
            </Box>
          </ModalFooter>
        </ModalContent>
      </JsForm>
      {showDisableConfirmModal && (
        <ConfirmDisableLinkModal onClose={handleDisable} />
      )}
      {upgradeModalOpen && onUpgrade && (
        <UpgradeUsabilityTestModal
          creditCount={account.total_points}
          onUpgrade={(credits) => {
            onUpgrade(credits)
            setUpgradeModalOpen(false)
            setUpgradeSuccessModalOpen(true)
          }}
          onCancel={() => setUpgradeModalOpen(false)}
        />
      )}
      {upgradeSuccessModalOpen && (
        <UpgradeUsabilityTestSuccessModal
          onContinue={() => setUpgradeSuccessModalOpen(false)}
        />
      )}
    </Modal>
  )
}

const RecordingAlert: React.FC<{ recordingTypes: RecordingType[] }> = ({
  recordingTypes,
}) => {
  if (recordingTypes.length < 1) return null

  return (
    <Alert
      status="info"
      description={`${recordingTypesToText(recordingTypes)} required. Be sure to include this information wherever you share the recruitment link.`}
    />
  )
}

const recordingTypesToText = (recordingTypes: RecordingType[]) => {
  if (recordingTypes.length < 1) return "None"

  const microphone = recordingTypes.includes("microphone")
  const screen = recordingTypes.includes("screen")
  if (microphone && screen) {
    return "Think-aloud and screen share"
  } else if (microphone) {
    return "Think-aloud"
  } else {
    return "Screen share"
  }
}
