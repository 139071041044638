import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react"

import {
  getParsedQueryStringByPath,
  pushQueryStringPath,
} from "Utilities/query-string"

import {
  CompressedResponseFilters,
  compressFilters,
  decompressFilters,
} from "../filters/compress-filters"
import { ResponseFilter } from "../filters/filters/types"

export const QUERY_PATH = "filters"

interface TestResultFilterState {
  testResultsFilters: ResponseFilter[]
  setTestResultsFilters: React.Dispatch<React.SetStateAction<ResponseFilter[]>>
}

const initialFilters = (): ResponseFilter[] => {
  let filters: ResponseFilter[] = []
  try {
    // Parses the URL query params to build the initial state
    const urlCompressedFilters =
      getParsedQueryStringByPath<CompressedResponseFilters>(QUERY_PATH)
    if (urlCompressedFilters) {
      filters = decompressFilters(urlCompressedFilters)
    }
  } catch (e) {}

  return filters
}

export const TestResultsFilterState = createContext<TestResultFilterState>({
  testResultsFilters: initialFilters(),
  setTestResultsFilters: () => {
    throw new Error("setTestResultsFilters not implemented")
  },
})

export const TestResultsFilterStateContextProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const [testResultsFilters, setTestResultsFilters] = useState<
    ResponseFilter[]
  >(initialFilters())

  // Keep the URL query params updated
  useEffect(() => {
    pushQueryStringPath(QUERY_PATH, compressFilters(testResultsFilters))
  }, [testResultsFilters])

  return (
    <TestResultsFilterState.Provider
      value={{
        testResultsFilters,
        setTestResultsFilters,
      }}
    >
      {children}
    </TestResultsFilterState.Provider>
  )
}
