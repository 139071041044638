import { Link as ChakraLink, Flex } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Alert } from "DesignSystem/components"
import { BasicDemographicAttribute } from "Types"
import { deviceRequirementsLabel } from "UsabilityHub/components/InterviewDetails/deviceRequirementsLabel"
import { DemographicTargetSummary } from "UsabilityHub/components/recruitment-summary-card/DemographicTargetSummary"
import { DemographicTargetSummaryTableRow } from "UsabilityHub/components/recruitment-summary-card/DemographicTargetSummaryTableRow"
import { getActiveDemographicAttributes } from "Utilities/demographics"
import { formatDuration } from "date-fns"
import React from "react"
import {
  useGetModeratedStudy,
  useListDemographics,
} from "~/api/generated/usabilityhub-components"
import {
  DeviceRequirement,
  ModeratedStudyOrder,
} from "~/api/generated/usabilityhubSchemas"

export const ModeratedStudyOrderSummaryCardExpandedSection: React.FC<{
  order: ModeratedStudyOrder
}> = ({ order }) => {
  const { data: demographics } = useListDemographics({})
  const { data: moderatedStudy } = useGetModeratedStudy({
    pathParams: {
      moderatedStudyId: order.moderated_study_id,
    },
  })

  const activeDemographicAttributes: BasicDemographicAttribute[] = demographics
    ? getActiveDemographicAttributes(
        order.demographic_target.demographic_attribute_option_ids,
        demographics
      )
    : []

  return (
    <Flex direction="column" gap={6}>
      <DemographicTargetSummary
        ageRange={[
          order.demographic_target?.min_age ?? null,
          order.demographic_target?.max_age ?? null,
        ]}
        countries={
          order.demographic_target.locations.length === 0 &&
          order.demographic_target.countries
            ? order.demographic_target.countries
            : []
        }
        granularLocations={{
          scope: "interviews",
          targetLocations: order.demographic_target?.locations ?? [],
        }}
        activeDemographicAttributes={activeDemographicAttributes}
      >
        <InterviewDurationRow eventDurationMins={order.event_duration_mins} />
        <DemographicTargetSummaryTableRow
          heading="Location"
          value="Online meeting"
        />
        {/* moderatedStudy should always be present but if it isn't we can at least show the stuff above */}
        {moderatedStudy ? (
          <>
            <InterviewDeviceRequirementRow
              deviceRequirement={moderatedStudy.device_requirement}
            />
            <InterviewScreenerEnabledRow
              hasScreener={moderatedStudy.screener_enabled}
            />
          </>
        ) : null}
      </DemographicTargetSummary>

      <Alert
        status="info"
        description={
          <>
            Should we be unable to fulfill a session, you will be refunded
            accordingly.{" "}
            <ChakraLink
              isExternal
              href={Constants.HELP_CENTER_INTERVIEW_RECRUITING_URL}
            >
              Read more
            </ChakraLink>{" "}
            about our policies on order fulfillment, cancelation and participant
            privacy.
          </>
        }
      />
    </Flex>
  )
}

const InterviewDurationRow: React.FC<{ eventDurationMins: number }> = (
  props
) => (
  <DemographicTargetSummaryTableRow
    heading="Duration"
    value={formatDuration(
      { minutes: props.eventDurationMins },
      { format: ["minutes"] }
    )}
  />
)

const InterviewDeviceRequirementRow: React.FC<{
  deviceRequirement: Omit<DeviceRequirement, "id">
}> = (props) => (
  <DemographicTargetSummaryTableRow
    heading="Requirements"
    value={deviceRequirementsLabel(props.deviceRequirement)}
  />
)

const InterviewScreenerEnabledRow: React.FC<{ hasScreener: boolean }> = (
  props
) => (
  <DemographicTargetSummaryTableRow
    heading="Screener"
    value={props.hasScreener ? "Enabled" : "Disabled"}
  />
)
