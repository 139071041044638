import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react"
import { AutoUpdatingRemainingTime } from "Components/trial-widget/AutoUpdatingRemainingTime"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import { useIsTrialing, useTrialHasNotSubscribed } from "Utilities/account"
import { intervalAdjective, isFree } from "Utilities/plan"
import { differenceInCalendarDays, parseISO } from "date-fns"
import React from "react"
import { Plan, Subscription } from "~/api/generated/usabilityhubSchemas"
import { CalloutDuringTrial } from "./CalloutDuringTrial"

type YourPlan = React.FC<
  React.PropsWithChildren<{
    activeStripeSubscription?: Subscription
    manualSubscription: boolean
    plan: Plan | undefined
  }>
>

export const YourPlan: YourPlan = ({
  activeStripeSubscription,
  manualSubscription,
  plan,
}) => {
  const isTrial = useIsTrialing()
  const cancelAtTrialEnd = useTrialHasNotSubscribed()
  let link = null
  let cancelLink = null

  // On Free plans, show the Upgrade button.
  if (!manualSubscription && plan && isFree(plan)) {
    link = (
      <PlanDrawerTrigger
        colorScheme="brand.primary"
        variant="solid"
        source="plan_settings"
      >
        Upgrade
      </PlanDrawerTrigger>
    )
  }

  // For trials, show the "Cancel trial" button and the trial notice.
  else if (isTrial) {
    link = cancelAtTrialEnd ? (
      <PlanDrawerTrigger variant="outline" source="plan_settings">
        View plans
      </PlanDrawerTrigger>
    ) : (
      <PlanDrawerTrigger
        colorScheme="brand.primary"
        variant="solid"
        source="plan_settings"
      >
        View plans
      </PlanDrawerTrigger>
    )

    cancelLink = activeStripeSubscription!.cancel_at_period_end ? (
      <Button
        variant="outline"
        ms={2}
        onClick={() => Intercom("showNewMessage", null)}
      >
        Talk to us
      </Button>
    ) : (
      <PlanDrawerTrigger
        variant="outline"
        initialState={{
          screen: "churn-survey",
          churnSurveySource: "cancel-subscription",
        }}
        source="plan_settings"
        ms={2}
      >
        Cancel subscription
      </PlanDrawerTrigger>
    )
  }

  // For renewing paid plans, show the downgrade link.
  else if (
    activeStripeSubscription &&
    !activeStripeSubscription.cancel_at_period_end
  ) {
    link = (
      <PlanDrawerTrigger variant="outline" source="plan_settings">
        Change plan
      </PlanDrawerTrigger>
    )

    cancelLink = (
      <PlanDrawerTrigger
        variant="outline"
        initialState={{
          screen: "churn-survey",
          churnSurveySource: "cancel-subscription",
        }}
        source="plan_settings"
        ms={2}
      >
        Cancel subscription
      </PlanDrawerTrigger>
    )
  }

  const diffDays = isTrial
    ? differenceInCalendarDays(
        parseISO(activeStripeSubscription!.trial_end!),
        parseISO(activeStripeSubscription!.trial_start!)
      )
    : 0
  const trialName = diffDays === 14 ? "14-day free trial" : "free trial"

  return (
    <Stack>
      <Heading>Your plan</Heading>
      <Text fontWeight="semibold">
        {plan && (
          <>
            {plan.name} plan
            {cancelAtTrialEnd && ` ${trialName}`}
            {!cancelAtTrialEnd &&
              !isFree(plan) &&
              `, billed ${intervalAdjective(plan)}`}
            {cancelAtTrialEnd && (
              <Box as="span" fontWeight="normal" color="gray.600">
                {" "}
                (ends{" "}
                <AutoUpdatingRemainingTime
                  date={activeStripeSubscription!.trial_end!}
                />
                )
              </Box>
            )}
          </>
        )}
      </Text>
      {(activeStripeSubscription || !plan) && (
        <CalloutDuringTrial
          plan={plan}
          activeStripeSubscription={activeStripeSubscription}
          trialName={trialName}
        />
      )}
      {(link || cancelLink) && (
        <Box mt={3}>
          {link}
          {cancelLink}
        </Box>
      )}
    </Stack>
  )
}
