import { Icon } from "@chakra-ui/react"
import { LockClosedIcon, UserCircleIcon } from "@heroicons/react/outline"
import { SideNav, SideNavLink } from "Shared/components/SideNav/SideNav"
import { Briefcase01OutlineIcon } from "Shared/icons/untitled-ui/Briefcase01OutlineIcon"
import { CreditCard02OutlineIcon } from "Shared/icons/untitled-ui/CreditCard02OutlineIcon"
import { Settings04OutlineIcon } from "Shared/icons/untitled-ui/Settings04OutlineIcon"
import { Users01OutlineIcon } from "Shared/icons/untitled-ui/Users01OutlineIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { ROUTES } from "../routes"

type SettingsSideNav = React.FC<React.PropsWithChildren<unknown>>

export const SettingsSideNav: SettingsSideNav = () => {
  const currentUser = useCurrentUser()

  const featureAccessItem = (
    <SideNavLink
      leftIcon={<Icon as={Settings04OutlineIcon} />}
      to={ROUTES.SETTINGS.FEATURE_ACCESS.path}
    >
      Feature access
    </SideNavLink>
  )

  return (
    <SideNav>
      <SideNavLink
        leftIcon={<Icon as={UserCircleIcon} />}
        to={ROUTES.SETTINGS.PROFILE.path}
      >
        Profile
      </SideNavLink>
      {currentUser.role !== "admin" && featureAccessItem}
      {currentUser.role === "admin" && (
        <>
          <SideNavLink
            leftIcon={<Icon as={Briefcase01OutlineIcon} />}
            to={ROUTES.SETTINGS.ORGANIZATION.path}
          >
            Organization
          </SideNavLink>
          <SideNavLink
            leftIcon={<Icon as={Users01OutlineIcon} />}
            to={ROUTES.SETTINGS.TEAM.path}
          >
            Team members
          </SideNavLink>
          <SideNavLink
            leftIcon={<Icon as={CreditCard02OutlineIcon} />}
            to={ROUTES.SETTINGS.BILLING.path}
          >
            Plan &amp; billing
          </SideNavLink>
          {featureAccessItem}
          <SideNavLink
            leftIcon={<Icon as={LockClosedIcon} />}
            to={ROUTES.SETTINGS.SECURITY.path}
          >
            Security
          </SideNavLink>
        </>
      )}
    </SideNav>
  )
}
