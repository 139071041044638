import {
  Box,
  Center,
  Link as ChakraLink,
  Flex,
  Grid,
  Icon,
  RadioGroup,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Alert, Button, Heading } from "DesignSystem/components"
import { Radio } from "DesignSystem/components/Radio"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import { PageMain } from "Shared/components/Page/Page"
import { ArrowLeftOutlineIcon } from "Shared/icons/untitled-ui/ArrowLeftOutlineIcon"
import { ClockFastForwardOutlineIcon } from "Shared/icons/untitled-ui/ClockFastForwardOutlineIcon"
import { deviceRequirementsLabel } from "UsabilityHub/components/InterviewDetails/deviceRequirementsLabel"
import { StickyToBottom } from "UsabilityHub/components/StickyToBottom"
import {
  PAGE_CONTENT_MAX_WIDTH,
  STUDY_STEPPER_HEIGHT,
} from "UsabilityHub/constants/layoutConstants"
import { centsToDollarsAndCents } from "Utilities/currency"
import { formatDuration } from "date-fns"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import {
  GetModeratedStudyOrderQuoteResponse,
  useGetModeratedStudy,
  useListDemographics,
  useTrackEvent,
} from "~/api/generated/usabilityhub-components"
import {
  DemographicAttributeGroup,
  ModeratedStudy,
} from "~/api/generated/usabilityhubSchemas"
import { PurchaseConfirmationModal } from "~/application/javascripts/components/payments/purchase-confirmation-modal"
import { CreditPricePopover } from "../NewOrderPage/CreditPricePopover"
import { DemographicTargetingSummary } from "../NewOrderPage/DemographicTargetingSummary"
import { DemographicsPanel } from "../NewOrderPage/DemographicsPanel"
import { NoOfPanelistsSection } from "../NewOrderPage/NoOfPanelistsSection"
import {
  OrderFormProvider,
  useOrderForm,
} from "../NewOrderPage/OrderFormProvider"
import { ROUTES } from "../routes"
import { useInterviewOrder } from "./useInterviewOrder"

const DEFAULT_NUM_PARTICIPANTS = 5
const RESPONSE_SCALE = [1, 5, 10, 15, 20, 25]
const PAGE_BOTTOM_SPACING = "2.5rem"

const estimatedFulfillmentTimeTooltipText = (
  study: ModeratedStudy | undefined
): string => {
  const baseText = "This is the order's estimated fulfilment time."

  if (study?.screener_enabled) {
    return `${baseText} As this study includes a screener, fulfilment time may be longer.`
  } else {
    return baseText
  }
}

/**
 * Note:
 *
 * You can use the `enable_submit=true` query param to force the submit
 * button to be enabled for local dev purposes.
 */
export const InterviewOrder: React.FC = () => {
  const { moderatedStudyId } = useTypedParams(ROUTES.INTERVIEW.RECRUIT.ORDERS)
  const { data: demographics } = useListDemographics({})
  const { data: study } = useGetModeratedStudy({
    pathParams: { moderatedStudyId },
  })

  const { mutate: trackEvent } = useTrackEvent()

  useEffect(() => {
    if (study) {
      trackEvent({
        body: {
          event: "pageview_interview_order_form",
          event_properties: { study_id: study.id },
        },
      })
    }
  }, [study])

  if (!demographics) {
    return (
      <Center flexGrow={1}>
        <Spinner />
      </Center>
    )
  }

  return (
    <PageMain>
      <Flex direction="column" align="center" gap={10} mt={10}>
        <Heading
          as="h1"
          textStyle="ds.display.emphasized"
          mx={8}
          textAlign="center"
          // Balance the wrapped line widths on mobile
          css={{ textWrap: "balance" }}
        >
          Recruit participants from the Lyssna Panel
        </Heading>
        <Button
          as={Link}
          to={ROUTES.INTERVIEW.RECRUIT.buildPath({
            moderatedStudyId,
          })}
          leftIcon={<ArrowLeftOutlineIcon />}
        >
          Back to recruitment options
        </Button>

        <OrderFormProvider
          context="interviews"
          demographics={demographics}
          testHasScreener={false} // Even when an Interview study has a screener, we don't show the screener UI
          testHasRecordings={false}
          testIsExternal={false}
          defaultNumberOfPanelists={DEFAULT_NUM_PARTICIPANTS}
        >
          {study && (
            <InterviewOrderInner study={study} demographics={demographics} />
          )}
        </OrderFormProvider>
      </Flex>
    </PageMain>
  )
}

type InterviewOrderInnerProps = {
  study: ModeratedStudy
  demographics: DemographicAttributeGroup[]
}

const InterviewOrderInner: React.FC<InterviewOrderInnerProps> = ({
  study,
  demographics,
}) => {
  const queryString = new URLSearchParams(window.location.search)
  const forceEnableSubmit = queryString.get("enable_submit") !== null

  const [isConfirming, setIsConfirming] = useState(false)
  const [handpickPanelists, setHandpickPanelists] = useState(false)

  const { selectedOptionIds, clearAllOptionsForAttribute } = useOrderForm()
  const { latestQuote, createOrderLoading, handleSubmit } = useInterviewOrder({
    moderatedStudyId: study.id,
    handpickPanelists,
    isConfirming,
    setIsConfirming,
  })

  const activeDemographicAttributes = demographics.flatMap((group) => {
    return group.demographic_attributes
      .map((attribute) => ({
        ...attribute,
        options: attribute.options?.filter((option) =>
          selectedOptionIds.includes(option.id)
        ),
      }))
      .filter((attribute) => attribute.options?.length)
  })

  return (
    <>
      <Grid
        w={["90%", null, null, `min(90vw, ${PAGE_CONTENT_MAX_WIDTH})`]}
        templateColumns={["1fr", null, null, "1fr 26.25rem"]}
        gap={10}
        pb={PAGE_BOTTOM_SPACING}
        alignItems="start"
      >
        <Flex direction="column" gap={4}>
          <Flex
            direction="column"
            bg="white"
            p={6}
            shadow="ds.raised"
            rounded="16px"
          >
            <NoOfPanelistsSection responseScale={RESPONSE_SCALE}>
              <Flex alignItems="center" gap={1}>
                Number of sessions
                <HelpPopover placement="top">
                  <Text fontSize="md" fontWeight="normal">
                    Specify how many interview sessions you would like to book
                    with Lyssna panelists.
                  </Text>
                </HelpPopover>
              </Flex>
            </NoOfPanelistsSection>

            <SelectionMethod
              handpickPanelists={handpickPanelists}
              setHandpickPanelists={setHandpickPanelists}
            />
          </Flex>

          <Box bg="white" rounded="16px" shadow="ds.raised" p={6}>
            <DemographicsPanel
              showPresets
              demographics={demographics}
              activePanel="usabilityhub"
            />
          </Box>
        </Flex>

        <StickyToBottom
          stickyElementCSSVarName="order-summary-panel-height"
          // This applies when the panel is shorter than the viewport.
          topOffset={`${STUDY_STEPPER_HEIGHT} + 1rem`}
          bottomOffset={PAGE_BOTTOM_SPACING}
        >
          <Flex
            direction="column"
            bg="white"
            shadow="ds.raised"
            rounded="16px"
            p={6}
            gap={4}
          >
            {study && <InterviewSummaryPanel study={study} />}
            <DemographicTargetingSummary
              showPresets
              activeDemographicAttributes={activeDemographicAttributes}
              validationErrors={{}} // Only relevant for third party panel which does not apply here
              clearDemographicAttribute={clearAllOptionsForAttribute}
            />
            {latestQuote ? (
              <>
                <InterviewQuotePanel quote={latestQuote} />
                <Alert
                  status="info"
                  description={
                    <>
                      Should we be unable to fulfill a session, you will be
                      refunded accordingly.{" "}
                      <ChakraLink
                        isExternal
                        href={Constants.HELP_CENTER_INTERVIEW_RECRUITING_URL}
                      >
                        Read more
                      </ChakraLink>{" "}
                      about our policies on order fulfillment, cancelation and
                      participant privacy.
                    </>
                  }
                />
                {latestQuote.estimated_fulfillment_time === null && (
                  <Alert
                    status="warning"
                    w="auto"
                    description={`We can\u2019t fulfil that order from the Lyssna panel.
                      Try expanding your geographic or demographic targeting to
                      improve feasibility.`}
                  />
                )}
                <Button
                  variant="primary"
                  size="emphasized"
                  isLoading={createOrderLoading}
                  isDisabled={
                    !forceEnableSubmit &&
                    latestQuote.estimated_fulfillment_time === null
                  }
                  onClick={handleSubmit}
                >
                  Submit order
                </Button>
                {latestQuote.estimated_fulfillment_time && (
                  <Flex align="center" py={4} w="fit-content" mx="auto">
                    <Icon as={ClockFastForwardOutlineIcon} mr={2} />
                    <Text color="text.primary">Estimated turnaround time:</Text>
                    <Tooltip
                      hasArrow
                      rounded="sm"
                      placement="bottom"
                      label={estimatedFulfillmentTimeTooltipText(study)}
                    >
                      <Text fontWeight="bold" color="green.500" ml={1}>
                        Up to{" "}
                        {formatDuration({
                          days:
                            latestQuote.estimated_fulfillment_time /
                            (60 * 60 * 24),
                        })}
                      </Text>
                    </Tooltip>
                  </Flex>
                )}
              </>
            ) : (
              <Center minH="120px">
                <Spinner />
              </Center>
            )}
          </Flex>
        </StickyToBottom>
      </Grid>
      {isConfirming && latestQuote && (
        <PurchaseConfirmationModal
          submitPurchaseAsync={async () => {
            handleSubmit()
          }}
          onCancel={() => setIsConfirming(false)}
          formattedPrice={centsToDollarsAndCents(latestQuote.price_in_cents)}
          heading={`Purchase ${latestQuote.credit_cost} credits`}
        />
      )}
    </>
  )
}

type InterviewQuotePanelProps = {
  quote: GetModeratedStudyOrderQuoteResponse
}

const InterviewQuotePanel: React.FC<InterviewQuotePanelProps> = ({ quote }) => {
  return (
    <Flex
      direction="column"
      gap={3}
      bg="ds.background.neutral.resting"
      rounded="12px"
      p={6}
    >
      <Flex justify="space-between">
        <Flex flexWrap="wrap" alignItems="center" maxW="50%">
          <Text>
            {quote.requested_response_count} participants &times;&nbsp;
          </Text>
          <Text>{quote.credits_per_response} credits </Text>
          <HelpPopover>
            <Text>
              Based on the duration, each session will cost{" "}
              {quote.credits_per_response} credits, inclusive of participant
              incentive.
            </Text>
          </HelpPopover>
        </Flex>
        <Text textAlign="end">{quote.credit_cost} credits</Text>
      </Flex>

      <Flex justify="space-between">
        <Text>From your balance</Text>
        <Text textAlign="end">
          {quote.credit_cost - quote.credits_to_be_purchased} credits
        </Text>
      </Flex>

      <Flex justify="space-between">
        <Text>Credits required</Text>
        <Text textAlign="end">{quote.credits_to_be_purchased} credits</Text>
      </Flex>

      {quote.credits_to_be_purchased > 0 ? (
        <Flex justify="space-between">
          <Box display="inline-flex" alignItems="center">
            Cost per credit{" "}
            <HelpPopover>
              <CreditPricePopover scale={quote.credits_scale} />
            </HelpPopover>
          </Box>
          <Text textAlign="end">{quote.price_per_credit}</Text>
        </Flex>
      ) : null}

      <Flex justify="space-between">
        <Text textStyle="ds.display.primary">
          Total cost
          <br />
          <Text textStyle="ds.display.secondary">(inc. incentives)</Text>
        </Text>
        <Text textStyle="ds.display.primary" textAlign="end">
          {centsToDollarsAndCents(quote.price_in_cents)}
        </Text>
      </Flex>
    </Flex>
  )
}

type InterviewSummaryPanelProps = {
  study: ModeratedStudy
}
const InterviewSummaryPanel: React.FC<InterviewSummaryPanelProps> = ({
  study,
}) => {
  const eventDurationMins = study.availability.event_duration_mins
  const deviceTypesLabel = deviceRequirementsLabel(study.device_requirement)
  const hasScreener = study.screener_enabled

  return (
    <Box borderBottomWidth={1} borderColor="ds.border.default">
      <Heading as="h2" textStyle="ds.display.primary" mb={4}>
        Order summary
      </Heading>
      <SummaryPanelRow
        heading="Language"
        value="English"
        helpPopoverText="Initially, only participants who speak English will be eligible
              for interviews."
      />
      <SummaryPanelRow
        heading="Duration"
        value={formatDuration(
          { minutes: eventDurationMins },
          { format: ["minutes"] }
        )}
      />
      <SummaryPanelRow heading="Location" value="Online meeting" />
      <SummaryPanelRow heading="Requirements" value={deviceTypesLabel} />
      <SummaryPanelRow
        heading="Screener"
        value={hasScreener ? "Enabled" : "Disabled"}
      />
    </Box>
  )
}

type SummaryPanelRowProps = {
  heading: React.ReactNode
  value: React.ReactNode
  helpPopoverText?: React.ReactNode
}

const SummaryPanelRow: React.FC<SummaryPanelRowProps> = ({
  heading,
  value,
  helpPopoverText,
}) => (
  <Flex
    justify="space-between"
    alignItems="baseline"
    py={4}
    borderTopWidth="1px"
    borderColor="ds.border.default"
  >
    <Flex alignItems="center" gap={1}>
      <Heading as="h3" textStyle="ds.heading.primary">
        {heading}
      </Heading>
      {helpPopoverText && <HelpPopover>{helpPopoverText}</HelpPopover>}
    </Flex>

    <Text maxW={60} textAlign="end" css={{ textWrap: "balance" }}>
      {value}
    </Text>
  </Flex>
)

type SelectionMethodProps = {
  handpickPanelists: boolean
  setHandpickPanelists: Dispatch<SetStateAction<boolean>>
}

const SelectionMethod: React.FC<SelectionMethodProps> = ({
  handpickPanelists,
  setHandpickPanelists,
}) => {
  const onChange = (value: "handpick" | "automatic") => {
    setHandpickPanelists(value === "handpick")
  }
  const value = handpickPanelists ? "handpick" : "automatic"

  return (
    <Box mt={6} py={4} borderTop="1px solid" borderColor="gray.200">
      <Flex alignItems="center" gap={1} mb={4}>
        <Heading as="h3" textStyle="ds.heading.primary">
          Selection method
        </Heading>
        <HelpPopover placement="top">
          You can choose to either allow eligible panelists to automatically{" "}
          book or manually review and invite panelists to your study.
        </HelpPopover>
      </Flex>
      <Box px={4}>
        <RadioGroup onChange={onChange} value={value}>
          <Flex flexDirection="column" gap={4}>
            <Flex flexDirection="column" gap={2}>
              <Radio
                name="automatic"
                value="automatic"
                isChecked={value === "automatic"}
                onCheckedChange={(checked) => {
                  if (checked) onChange("automatic")
                }}
              >
                Automatic
              </Radio>
              <Text
                ml={7}
                textStyle="ds.paragraph.primary"
                color="ds.text.subtle"
              >
                Eligible panelists will be able to book a session with you right
                away.
              </Text>
            </Flex>
            <Flex flexDirection="column" gap={2}>
              <Radio
                name="handpick"
                value="handpick"
                isChecked={value === "handpick"}
                onCheckedChange={(checked) => {
                  if (checked) onChange("handpick")
                }}
              >
                Handpick
              </Radio>
              <Text
                ml={7}
                textStyle="ds.paragraph.primary"
                color="ds.text.subtle"
              >
                Review panelists based on their profile and screener responses,
                and handpick those you would like to send a booking invitation.
              </Text>
            </Flex>
          </Flex>
        </RadioGroup>
      </Box>
    </Box>
  )
}
