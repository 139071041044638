import { Box, Checkbox, Link, Stack, Text, useToast } from "@chakra-ui/react"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { usersSamlSignInPath } from "Shared/constants/routes"
import { SignInForm } from "Shared/forms/SignInForm"
import { LyssnaLogoIcon } from "Shared/icons/LyssnaLogoIcon"
import { GoogleSignIn } from "UsabilityHub/components/GoogleSignIn/GoogleSignIn"
import { getParsedQueryString } from "Utilities/query-string"
import React, { useEffect } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { ROUTES } from "~/usabilityhub/views/routes"
import { RedirectedFromUserCrowdAlert } from "./RedirectedFromUserCrowdAlert"

const TOAST_MESSAGES: Record<string, string> = {
  account_deleted: "Your account has been deleted.",
}

export const SignInRoute: React.FC = () => {
  const toast = useToast()
  const query = getParsedQueryString()
  const wasRedirectedFromUserCrowd = query.from_usercrowd === "1"
  const toastMessage = TOAST_MESSAGES[query.message as string]

  useEffect(() => {
    if (toastMessage) {
      toast({
        title: toastMessage,
        status: "success",
      })
    }
  }, [toastMessage, toast])

  return (
    <Page
      title="Sign in"
      bg="grey.lightest"
      sx={{
        ".chakra-form__required-indicator": {
          display: "none",
        },
        a: {
          textDecoration: "none",
        },
      }}
    >
      {wasRedirectedFromUserCrowd && <RedirectedFromUserCrowdAlert />}
      <PageMain pb="50px" minH="100vh" justifyContent="center">
        <PageContent
          display="grid"
          justifyItems="center"
          alignItems={{ base: "start", md: "center" }}
          sx={{ py: 0 }}
        >
          <Stack
            maxW="28rem"
            w="calc(100vw - 3rem)"
            alignItems="stretch"
            gap={8}
            py={4}
          >
            <Box flex={1} display={{ base: "none", md: "block" }} />
            <Stack spacing={2} alignItems="center">
              <LyssnaLogoIcon w="11.25rem" h="3.125rem" />
              <Text as="span" color="text.subtlest">
                Sign in to continue
              </Text>
            </Stack>
            <SignInForm
              border={0}
              bg="none"
              py={0}
              recaptchaAlign="start"
              rememberMeControl={Checkbox}
            >
              <Text
                textTransform="uppercase"
                color="text.disabled"
                textAlign="center"
                fontWeight={500}
              >
                or
              </Text>
              <GoogleSignIn
                buttonVariant="continue_with"
                intent="sign_in"
                alignSelf="stretch"
                display="flex"
                justifyContent="stretch"
                sx={{
                  ".g_id_signin": {
                    flex: 1,
                    "div[role=button]": {
                      div: {
                        justifyContent: "center",
                      },
                      span: {
                        flexGrow: 0,
                      },
                    },
                  },
                }}
              />
              <Link
                textAlign="center"
                fontSize="md"
                as={ReactRouterLink}
                to={usersSamlSignInPath()}
              >
                Log in with SAML
              </Link>
            </SignInForm>
            <Text
              order={1}
              fontSize="sm"
              textAlign="center"
              color="text.subtlest"
              mt={8}
            >
              Don{"\u2019"}t have a Lyssna account?{" "}
              <Link
                as={ReactRouterLink}
                to={ROUTES.USERS.SIGN_UP.buildPath({})}
              >
                Sign up
              </Link>
            </Text>
          </Stack>
        </PageContent>
      </PageMain>
    </Page>
  )
}
