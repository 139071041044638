import {
  FormLabel,
  FormLabelProps,
  HStack,
  Text,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react"
import React from "react"
import AutosizeTextarea from "react-autosize-textarea"

import { useMarkdownUsage } from "Hooks/use-markdown-usage"
interface MarkdownLabelProps {
  showMarkdownHint?: boolean
}

export const MarkdownFormLabel: React.FC<
  React.PropsWithChildren<FormLabelProps & MarkdownLabelProps>
> = ({ showMarkdownHint = true, ...formLabelProps }) => {
  const markdownHint = useMarkdownUsage("block")
  return (
    <HStack align="baseline">
      <FormLabel flexGrow={1} {...formLabelProps} />
      <Text
        fontSize="xs"
        lineHeight={5}
        color="gray.500"
        transition="opacity 200ms"
        opacity={showMarkdownHint ? 1 : 0}
      >
        {markdownHint}
      </Text>
    </HStack>
  )
}

export const MarkdownTextarea: React.FC<
  React.PropsWithChildren<TextareaProps>
> = (textAreaProps) => (
  <Textarea
    as={AutosizeTextarea}
    transition="none"
    bg="white"
    fontWeight="normal"
    {...textAreaProps}
  />
)
