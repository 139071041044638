import { HStack, Text, TextProps } from "@chakra-ui/react"
import React, { ReactNode } from "react"

import { DEFAULT_TAG_COLORS, textColorForBackground } from "./colors"

type TagProps = {
  readonly color?: string
  readonly tagIndex: number
  readonly children: ReactNode
} & TextProps

const colorToStyle = (color: string) => {
  const foregroundColor = textColorForBackground(color)
  return { color: foregroundColor, backgroundColor: color }
}

export const Tag: React.FC<React.PropsWithChildren<TagProps>> = ({
  children,
  color,
  tagIndex,
  ...rest
}) => {
  const colorOrDefault =
    color || DEFAULT_TAG_COLORS[tagIndex % DEFAULT_TAG_COLORS.length]

  return (
    <Text
      borderRadius={4}
      px={1}
      py={0.5}
      fontSize="sm"
      {...colorToStyle(colorOrDefault)}
      {...rest}
    >
      {children}
    </Text>
  )
}

interface TagsProps {
  children: ReadonlyArray<ReactNode>
}

export const Tags: React.FC<React.PropsWithChildren<TagsProps>> = ({
  children,
  ...rest
}) => <HStack {...rest}>{children}</HStack>
