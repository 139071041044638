import { Progress, ProgressProps } from "@chakra-ui/react"
import { getColor, mode } from "@chakra-ui/theme-tools"
import React from "react"

import { theme } from "~/shared/theme"

interface Props extends ProgressProps {
  values: Record<string, number>
  max: number
}

export const progressColors = {
  success: "green.400",
  tentative: "gray.200",
  failed: "red.500",
}

export const MultiSegmentProgress: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { values, max } = props
  const breakpoints = []
  let totalPct = 0
  const trackColor = getColor(
    theme,
    mode("white", "whiteAlpha.300")(props)
  ) as string
  Object.entries(values).forEach(([color, val]) => {
    const fillColor = getColor(
      theme,
      mode(`${color}`, `${color}`)(props)
    ) as string

    if (fillColor === undefined) {
      throw new Error(`Invalid color in  multi-segment progress bar!`)
    }

    const pct = +Number.parseFloat(((val / max) * 100).toFixed(1))

    breakpoints.push(`${fillColor} ${totalPct}%`)

    totalPct += pct
    if (totalPct > 100) {
      totalPct = 100
    }

    breakpoints.push(`${fillColor} ${totalPct}%`)
  })
  if (totalPct < 100) {
    breakpoints.push(`${trackColor} ${totalPct}%`)
    breakpoints.push(`${trackColor} 100%`)
  }

  const gradient = `
      linear-gradient(
      to right,
      ${breakpoints.join(", ")}
    )`
  return <Progress {...props} bg={gradient} />
}
