import {
  Button,
  Collapse,
  Flex,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react"
import { MAX_AGE, MIN_AGE } from "Constants/ages"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { ChevronUpOutlineIcon } from "Shared/icons/untitled-ui/ChevronUpOutlineIcon"
import { GranularLocationTags } from "UsabilityHub/components/GranularLocationTags/GranularLocationTags"
import React from "react"
import { useListDemographics } from "~/api/generated/usabilityhub-components"
import { DemographicPreset } from "~/api/generated/usabilityhubSchemas"
import { useOrderForm } from "../OrderFormProvider"
import { PresetRowContextMenu } from "./PresetRowContextMenu"
import { extractLocations } from "./extractLocations"

type Props = {
  preset: DemographicPreset
  onClose: () => void
}

export const PresetRow: React.FC<Props> = ({ preset, onClose }) => {
  const toast = useToast()
  const { applyPreset } = useOrderForm()
  const [expandDemographics, { toggle }] = useBoolean(false)

  return (
    <Flex
      justify="space-between"
      gap={4}
      py={6}
      borderBottomWidth={1}
      borderColor="gray.200"
    >
      <Flex direction="column" flexGrow={1} ps={6}>
        <Text fontWeight={500}>{preset.name}</Text>

        <Button
          size="xs"
          w="fit-content"
          variant="text"
          color="text.subtle"
          rightIcon={
            expandDemographics ? (
              <ChevronUpOutlineIcon />
            ) : (
              <ChevronDownOutlineIcon />
            )
          }
          px={0}
          onClick={toggle}
        >
          {expandDemographics ? "Hide demographics" : "Show demographics"}
        </Button>
        <Collapse in={expandDemographics}>
          <PresetDetails preset={preset} />
        </Collapse>
      </Flex>

      <Flex gap={4} pe={6}>
        <Button
          onClick={() => {
            applyPreset(preset)
            onClose()
            toast({
              status: "success",
              title: "Saved demographic group applied",
            })
          }}
        >
          Apply
        </Button>

        <PresetRowContextMenu preset={preset} />
      </Flex>
    </Flex>
  )
}

type PresetDetailsProps = {
  preset: DemographicPreset
}

const PresetDetails: React.FC<PresetDetailsProps> = ({ preset }) => {
  const { context } = useOrderForm()
  const { data: demographics } = useListDemographics({})

  const activeDemographicAttributes = demographics?.flatMap((group) => {
    return group.demographic_attributes
      .map((attribute) => ({
        ...attribute,
        options: attribute.options?.filter((option) =>
          preset.demographic_attribute_option_ids.includes(option.id)
        ),
      }))
      .filter((attribute) => attribute.options?.length)
  })

  const targetedLocations = extractLocations(preset)

  return (
    <Flex direction="column" gap={2} mt={4}>
      {targetedLocations.length > 0 && (
        <Flex direction="column">
          <Text>Locations</Text>
          <GranularLocationTags
            scope={context}
            targetLocations={targetedLocations}
          />
        </Flex>
      )}

      {preset.min_age || preset.max_age ? (
        <Flex direction="column">
          <Text>Age</Text>
          <Text fontSize="sm">
            {preset.min_age || MIN_AGE} - {preset.max_age || MAX_AGE}
          </Text>
        </Flex>
      ) : null}

      {activeDemographicAttributes?.map((attribute) => (
        <Flex direction="column" key={attribute.id}>
          <Text>{attribute.name}</Text>
          <Text fontSize="sm">
            {attribute.options.map((option) => option.value).join(", ")}
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}
