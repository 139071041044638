import { Grid, GridItem, GridProps } from "@chakra-ui/react"
import React from "react"

type Props = {
  stepIndex: number
  totalSteps: number
} & GridProps

export const MultiStepsProgress: React.FC<Props> = ({
  stepIndex,
  totalSteps,
  ...rest
}) => (
  <Grid templateColumns={`repeat(${totalSteps}, 1fr)`} gap={2} {...rest}>
    {Array.from({ length: totalSteps }, (_, index) => index + 1).map(
      (_, index) => (
        <GridItem
          key={index}
          w="100%"
          h={2}
          bg={index < stepIndex ? "purple.500" : "purple.50"}
          borderRadius="md"
        />
      )
    )}
  </Grid>
)
