import { useQueryClient } from "@tanstack/react-query"
import { parseISO } from "date-fns"
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react"
import * as yup from "yup"
import {
  GetOrdealExperimentsResponse,
  useFinishOrdealExperiment,
  useGetOrdealExperiments,
  usePinOrdealExperimentVariant,
  useStartOrdealExperiment,
} from "~/api/generated/usabilityhub-components"
import { Experiment, Variant } from "./types"

type ApiShape = {
  experiments: Experiment[]
  loading: boolean
  startExperiment: (experiment: Experiment) => void
  finishExperiment: (experiment: Experiment) => void
  pinVariant: (experiment: Experiment, variant: Variant | null) => void
}

const ExperimentSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().required(),
  started_at: yup
    .date()
    .transform((_, v) => {
      return (v && parseISO(v)) || null
    })
    .nullable(),
  finished_at: yup
    .date()
    .transform((_, v) => (v && parseISO(v)) || null)
    .nullable(),
  pinned: yup.string().nullable().optional(),
  variants: yup
    .array(
      yup
        .object({
          name: yup.string().required(),
          weight: yup.number().required(),
          statistics: yup
            .object({
              visitors: yup.number(),
              conversions: yup.number(),
              conversion_rate: yup.number().nullable(),
              z_score: yup.number().nullable(),
              confidence: yup.number().nullable(),
            })
            .required(),
        })
        .required()
    )
    .required(),
})

export const ApiContext = createContext<ApiShape>({} as ApiShape)

export const ApiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, data } = useGetOrdealExperiments({})

  const queryClient = useQueryClient()

  const experiments: Experiment[] = useMemo(() => {
    if (isLoading || !data) return []
    const schema = yup.array(ExperimentSchema)
    return (schema.validateSync(data) ?? []) as Experiment[]
  }, [isLoading, data])

  const updateCachedExperiment = (
    data: GetOrdealExperimentsResponse[number]
  ) => {
    queryClient.setQueryData(
      ["admin", "ordeal", "api", "experiments"],
      (existing: GetOrdealExperimentsResponse) =>
        existing.map((e) => (e.name === data.name ? data : e))
    )
  }

  const { mutate: startExperimentMutation } = useStartOrdealExperiment({
    onSuccess: updateCachedExperiment,
  })

  const startExperiment = useCallback(
    (experiment: Experiment) =>
      startExperimentMutation({
        pathParams: { experimentId: experiment.name },
      }),
    []
  )

  const { mutate: finishExperimentMutation } = useFinishOrdealExperiment({
    onSuccess: updateCachedExperiment,
  })

  const finishExperiment = useCallback(
    (experiment: Experiment) =>
      finishExperimentMutation({
        pathParams: { experimentId: experiment.name },
      }),
    []
  )

  const { mutate: pinVariantMutation } = usePinOrdealExperimentVariant({
    onSuccess: updateCachedExperiment,
  })

  const pinVariant = useCallback(
    (experiment: Experiment, variant: Variant | null) =>
      pinVariantMutation({
        pathParams: { experimentId: experiment.name },
        body: { variant: variant?.name ?? null },
      }),
    []
  )

  const contextValue = useMemo(
    () => ({
      experiments,
      loading: isLoading,
      startExperiment,
      finishExperiment,
      pinVariant,
    }),
    [experiments, isLoading, startExperiment, finishExperiment, pinVariant]
  )

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  )
}

export const useApi = () => useContext(ApiContext)
