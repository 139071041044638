import { IconProps } from "@chakra-ui/react"
import React from "react"
import { LyssnaLogoIcon } from "./LyssnaLogoIcon"

type IconColorMode = "color" | "grayscale"

type UsabilityHubLogoIconProps = IconProps & {
  mode?: IconColorMode
  palette?: Palette
}

type Palette = {
  text: string
  base: string
  dark: string
  mid: string
  opacity: number
}

const LYSSNA_PALETTES = {
  color: {
    base: "#0B978E",
    text: "#0B978E",
    dark: "#0B978E",
    mid: "#0B978E",
    opacity: 1,
  },
  grayscale: {
    text: "#38444F",
    base: "#475867",
    dark: "#000000",
    mid: "#34424F",
    opacity: 0.5,
  },
} as const

export const UsabilityHubLogoIcon: React.FC<UsabilityHubLogoIconProps> = ({
  mode = "color",
  palette: customPalette,
  ...props
}) => {
  const palette = customPalette || LYSSNA_PALETTES[mode]
  return <LyssnaLogoIcon mode={mode} palette={palette} {...props} />
}
