import {
  useQuestionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"

export const useQuestionPath = (): string => {
  const { questionIndex } = useQuestionContext()
  const sectionIndex = useSectionIndexContext()
  return `sections[${sectionIndex}].questions[${questionIndex}]`
}
