import { Box, Icon } from "@chakra-ui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { Button } from "DesignSystem/components"
import React, { useEffect, useState } from "react"

import { ScriptLoadError, loadScript } from "Utilities/script"

const headwayAccountId = "7vN5j7"
const headwayElementId = "headway-changelog"
const headwayTriggerId = "headway-changelog-trigger"

type UpdatesMenu = React.FC<React.PropsWithChildren<unknown>>

export const UpdatesMenu: UpdatesMenu = () => {
  const [isHeadwayLoaded, setIsHeadwayLoaded] = useState(false)
  useEffect(() => {
    let skipInitialisation = false
    const loadHeadway = async () => {
      try {
        await loadScript("https://cdn.headwayapp.co/widget.js")
        // Safety check to avoid calling initializeHeadway() if loadScript() is
        // slow, and this component get unmounted before the promise resolves
        if (!skipInitialisation) {
          setIsHeadwayLoaded(true)
        }
      } catch (error) {
        if (error instanceof ScriptLoadError) {
          // If the script fails to load, don't report this to Sentry. There's
          // nothing we can do about it, and the header will just render without
          // a changelog
          return
        }
        throw error
      }
    }
    void loadHeadway()
    return () => {
      skipInitialisation = true
    }
  }, [])
  useEffect(() => {
    const initializeHeadway = () => {
      if (window.Headway !== undefined) {
        window.Headway.init({
          account: headwayAccountId,
          selector: `#${headwayElementId}`,
          trigger: `#${headwayTriggerId}`,
        })
      }
    }
    if (isHeadwayLoaded) {
      initializeHeadway()
    }
  }, [isHeadwayLoaded])

  return (
    <Button
      id={headwayTriggerId}
      variant="secondary"
      rightIcon={<Icon as={ChevronDownIcon} />}
      data-intercom-target="headway-element"
    >
      <Box id={headwayElementId} boxSize={8} ml={-2} mr="-px" />
      Updates
    </Button>
  )
}
