import {
  Icon,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react"
import { LinkIcon } from "@heroicons/react/solid"
import { useQueryClient } from "@tanstack/react-query"
import copy from "copy-to-clipboard"
import React from "react"

import { useUnarchiveProject } from "~/api/generated/usabilityhub-components"
import { NewProject } from "~/api/generated/usabilityhubSchemas"

import { ArchiveProjectModal } from "./ArchiveProjectModal"

import { IconButton } from "DesignSystem/components"
import { DotsVerticalIcon } from "Shared/icons/untitled-ui/DotsVerticalIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { generateFriendlyProjectPath } from "UsabilityHub/utils/generateFriendlyProjectPath"
import { DeleteProjectModal } from "./DeleteProjectModal"
import { RenameProjectModal } from "./RenameProjectModal"

type Props = {
  project: NewProject
} & Partial<IconButtonProps>

export const ProjectContextMenu: React.FC<Props> = ({
  project,
  ...buttonProps
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const userCanManageTests = useCurrentUser().can_manage_tests

  const [isRenaming, { on: showRenameModal, off: hideRenameModal }] =
    useBoolean(false)
  const [isDeleting, { on: showDeleteModal, off: hideDeleteModal }] =
    useBoolean(false)
  const [isArchiving, { on: showArchiveModal, off: hideArchiveModal }] =
    useBoolean(false)

  const { mutateAsync: unarchiveProject } = useUnarchiveProject({
    onSuccess: () => void queryClient.invalidateQueries(["api", "projects"]),
  })
  const handleUnarchive = async () => {
    try {
      await unarchiveProject({
        pathParams: { id: project.id.toString() },
      })
      toast({
        title: "Project unarchived",
        status: "success",
        duration: 3000,
      })
    } catch (error) {
      toast({
        title: "Project unarchiving failed",
        description: "Please try again",
        status: "error",
        duration: 3000,
      })
    }
  }

  return (
    <>
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          icon={<Icon boxSize={4} as={DotsVerticalIcon} />}
          aria-label="Project options"
          {...buttonProps}
        />
        <Portal>
          <MenuList overflow="hidden">
            <MenuItem
              display="flex"
              justifyContent="space-between"
              onClick={(e) => {
                e.stopPropagation()
                copy(generateFriendlyProjectPath(project, { absolute: true }))
                toast({
                  title: "Link copied to clipboard",
                  status: "success",
                  duration: 3000,
                })
              }}
            >
              <Text>Share</Text>
              <Icon as={LinkIcon} color="gray.400" />
            </MenuItem>

            {userCanManageTests && (
              <>
                <MenuItem onClick={showRenameModal}>Rename</MenuItem>

                <MenuItem
                  onClick={
                    project.archived ? handleUnarchive : showArchiveModal
                  }
                >
                  {project.archived ? "Unarchive" : "Archive"}
                </MenuItem>

                <MenuItem color="red.500" onClick={showDeleteModal}>
                  Delete
                </MenuItem>
              </>
            )}
          </MenuList>
        </Portal>
      </Menu>

      {isRenaming && (
        <RenameProjectModal project={project} onClose={hideRenameModal} />
      )}

      {isDeleting && (
        <DeleteProjectModal project={project} onClose={hideDeleteModal} />
      )}

      {isArchiving && (
        <ArchiveProjectModal project={project} onClose={hideArchiveModal} />
      )}
    </>
  )
}
