import { Box, Flex } from "@chakra-ui/react"
import { TestBrandingType } from "Types"
import { WelcomeMessage } from "UsabilityHub/components/UsabilityTest/content-factory/welcome-content"
import MediaPlaceholder from "UsabilityHub/components/UsabilityTestLayout/MediaPlaceholder"
import React from "react"
import { UsabilityTestResponseFlow } from "~/api/generated/usabilityhubSchemas"

type Props = {
  testInfo: Extract<
    UsabilityTestResponseFlow,
    { state: "screener" }
  >["test_info"]
}

export const FakeTestBackground: React.FC<Props> = ({ testInfo }) => {
  return (
    <Flex
      position="fixed"
      top={0}
      left={0}
      w="full"
      h="full"
      pointerEvents="none"
      filter="blur(4px)"
    >
      <Box
        pos="relative"
        flexGrow={1}
        borderRightWidth={1}
        display={["none", null, "block"]}
      >
        <MediaPlaceholder />
      </Box>

      <Flex pos="relative" flexGrow={1} bg="white">
        <WelcomeMessage
          isLoaded
          onStart={() => {}}
          copy={{
            welcome_heading: testInfo.welcome_heading,
            welcome_message: testInfo.welcome_message,
            welcome_button_text: testInfo.welcome_button_text,
          }}
          branding={{
            type: testInfo.type as TestBrandingType,
            button_color: testInfo.button_color ?? "",
            logo_url: testInfo.logo_url,
          }}
          hasPlayableScreenshot={false}
          allowedRecordingTypes={[]}
          isPanelist={false}
          isExternalStudy={false} // TODO: Ideally this would respect the actual value
          isLiveWebsiteTest={false}
        />
      </Flex>
    </Flex>
  )
}
