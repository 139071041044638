import { BoxProps, HStack } from "@chakra-ui/react"
import React from "react"

interface TestFormSidebarListItemProps {
  targetId: string
  isDraggable?: boolean
}

export const SidebarListItem: React.FC<
  React.PropsWithChildren<TestFormSidebarListItemProps & BoxProps>
> = ({ children, targetId, isDraggable = false, ...rest }) => {
  const handleSelect = () => {
    document.getElementById(targetId)!.scrollIntoView({
      behavior: "smooth",
    })
  }

  return (
    <HStack
      spacing={3}
      alignItems="center"
      bg="white"
      borderRadius="lg"
      borderWidth={1}
      borderColor="gray.200"
      p={3}
      onClick={handleSelect}
      cursor={isDraggable ? "grab" : "pointer"}
      {...rest}
    >
      {children}
    </HStack>
  )
}
