import { curry } from "lodash"

import { Dimensions, Point, Rectangle } from "Types"

export const Rectangle0011: Readonly<Rectangle> = {
  xa: 0,
  ya: 0,
  xb: 1,
  yb: 1,
}

function baseRectangleContainsPoint(
  { xa, ya, xb, yb }: Readonly<Rectangle>,
  { x, y }: Readonly<Point>
): boolean {
  return x >= xa && x <= xb && y >= ya && y <= yb
}
export const rectangleContainsPoint = curry(baseRectangleContainsPoint)
export const isPointInRectangle = curry(
  (p: Readonly<Point>, r: Readonly<Rectangle>) => {
    return baseRectangleContainsPoint(r, p)
  }
)

export function getWidth({ xa, xb }: Readonly<Rectangle>): number {
  return xb - xa
}
export function getHeight({ ya, yb }: Readonly<Rectangle>): number {
  return yb - ya
}

export function getDimensions(rectangle: Readonly<Rectangle>): Dimensions {
  return { width: getWidth(rectangle), height: getHeight(rectangle) }
}
