import { Box, Flex, Spinner, Text } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { unstable_usePrompt } from "react-router-dom"

import { AutoUpdatingRelativeTime } from "Components/comment/AutoUpdatingRelativeTime"
import { useBeforeUnload } from "UsabilityHub/hooks/useBeforeUnload"
import { ROUTES } from "UsabilityHub/views/routes"
import { useStudyDetails } from "./EditPage/StudyDetailsProvider"

const STUDY_STATES = {
  saved: {
    color: "green.400",
    message: "All changes saved",
    indicator: (lastSavedAt: Date) => (
      <AutoUpdatingRelativeTime
        color="ds.text.subtle"
        textStyle="ds.paragraph.primary"
        date={lastSavedAt.toISOString()}
      />
    ),
  },
  saving: {
    color: "orange.400",
    message: "Saving...",
    indicator: () => <Spinner size="xs" />,
  },
  invalid: {
    color: "red.500",
    message: "You have unsaved changes",
    indicator: () => null,
  },
}

type Props = {
  isAnyDirty: boolean
  isAnySubmitting: boolean
}

export const ModeratedStudySavingIndicator: React.FC<Props> = ({
  isAnyDirty,
  isAnySubmitting,
}) => {
  const { moderatedStudyId } = useStudyDetails()

  const [lastSavedAt, setLastSavedAt] = React.useState<Date>(() => new Date())

  const state = isAnySubmitting ? "saving" : isAnyDirty ? "invalid" : "saved"
  const { color, message, indicator } = STUDY_STATES[state]

  // Show a browser alert if the user tries to close the tab with unsaved changes
  useBeforeUnload(state !== "saved")
  // And also if they try to route to another page
  unstable_usePrompt({
    when: ({ nextLocation }) =>
      state !== "saved" &&
      !nextLocation.pathname.startsWith(
        ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId })
      ),
    message: `You${"\u2019"}ve made changes to this study that haven${"\u2019"}t been saved. Are you sure you want to leave without saving?`,
  })

  useEffect(() => {
    if (state === "saved") {
      setLastSavedAt(new Date())
    }
  }, [state])

  return (
    <Flex align="center">
      <Box rounded="full" boxSize={2} bg={color} mr={2} />
      <Text textStyle="ds.paragraph.primary" flexGrow={1}>
        {message}
      </Text>
      {indicator(lastSavedAt)}
    </Flex>
  )
}
