import { Flex } from "@chakra-ui/react"
import React from "react"

type BannerType = "info" | "warning" | "danger"

interface Props {
  type: BannerType
}

const getColors = (bannerType: BannerType) =>
  ({
    danger: {
      bg: "red.100",
      text: "red.500",
      links: "red.600",
    },
    warning: {
      bg: "yellow.100",
      text: "yellow.800",
      links: "yellow.800",
    },
    info: {
      bg: "blue.100",
      text: "blue.500",
      links: "blue.600",
    },
  })[bannerType]

export const Banner: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ children, type }) => {
  const colors = getColors(type)

  return (
    <Flex
      alignItems="center"
      p="10px 20px"
      bg={colors.bg}
      textColor={colors.text}
      sx={{
        "p:last-child": { margin: 0 },
        "& svg": { marginRight: "0.5em", color: colors.links },
        "& a, & a:hover": { color: colors.links },
      }}
    >
      {children}
    </Flex>
  )
}
