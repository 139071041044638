import { useMemo } from "react"
import { useSelector } from "react-redux"

import { useFilteredResponseSections } from "Components/test-results/hooks/use-filtered-response-sections"
import { getAllResponseSectionFigmaTasks } from "Redux/reducers/test-results/selectors"
import { useSectionContext } from "UsabilityHub/contexts"

export function useFilteredTasks() {
  const { section } = useSectionContext()
  const filteredResponseSections = useFilteredResponseSections()

  const responseSection = useMemo(
    () =>
      filteredResponseSections.filter(
        (responseSection) =>
          responseSection.usability_test_section_id === section.id
      ),
    [section.id, filteredResponseSections]
  )

  const responseSectionFigmaTasks = useSelector(getAllResponseSectionFigmaTasks)

  // Filter all tasks by checking if the corresponding responseSection exists in the filtered responseSection array
  // the filtered responseSection array is derived from the filtered response array
  return useMemo(
    () =>
      responseSectionFigmaTasks.filter((task) =>
        responseSection.some(
          (responseSection) => task.response_section_id === responseSection.id
        )
      ),
    [responseSectionFigmaTasks, responseSection]
  )
}
