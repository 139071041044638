import { Alert, AlertIcon, AlertTitle, Stack, Text } from "@chakra-ui/react"
import React from "react"
import { Plan, Subscription } from "~/api/generated/usabilityhubSchemas"

const ProLegacy = (_props: Props) => (
  <Stack spacing={2}>
    <Text>
      We discontinued the Lyssna Pro (legacy) plan in January 2017. You
      {"\u2019"}ve been receiving a discount on your subscription up until now
      (lucky you!). You were also able to access a few things we deprecated,
      including discounts and perks associated with credits.
    </Text>
    <Text>
      We{"\u2019"}ll be in touch with you to discuss your options for migrating
      to one of our current plans.
    </Text>
  </Stack>
)

const Team = (_props: Props) => (
  <Stack spacing={2}>
    <Text>
      You{"\u2019"}re on a subscription to our Team plan. We discontinued this
      plan in January 2020.
    </Text>
    <Text>
      We{"\u2019"}ll be in touch with you to discuss your options for migrating
      to one of our current plans.
    </Text>
  </Stack>
)

const BasicLegacy = (props: Props) => {
  let baseRate = "$89/month"
  let additionalRate = "$30/month"
  if (props.planInterval === "year") {
    baseRate = "$900/year"
    additionalRate = "$300/year"
  }

  return (
    <Stack spacing={2}>
      <Text>
        You{"\u2019"}re on a subscription to our legacy Basic plan, which is a
        single user plan. Our new Basic plan includes all the same features,
        plus access for 3 collaborators, for {baseRate}. You can also add
        additional collaborators for {additionalRate}.
      </Text>
      <Text>
        For the time being you can stay on the legacy Basic plan. Or you can
        choose to upgrade to the new Basic plan, and gain access for your team.
      </Text>
    </Stack>
  )
}

const ProV2Legacy = (props: Props) => {
  let baseRate = "$199/month"
  let additionalRate = "$40/month"
  let noCost = " at no additional cost"
  if (props.planInterval === "year") {
    baseRate = "$2100/year"
    additionalRate = "$420/year"
    noCost = ""
  }

  return (
    <Stack spacing={2}>
      <Text>
        You{"\u2019"}re on a subscription to our legacy Pro plan. Our new Pro
        plan includes all the same features, plus access for 5 collaborators,
        for {baseRate}. You can also add additional collaborators for{" "}
        {additionalRate}.
      </Text>
      {props.teamMemberCount > 1 ? (
        <Text>
          We recommend upgrading to the new Pro plan as it will be cheaper than
          your current plan.
        </Text>
      ) : (
        <Text>
          For the time being you can stay on the legacy Pro plan. Or you can
          choose to upgrade to the new Pro plan, and gain access for your team
          {noCost}.
        </Text>
      )}
    </Stack>
  )
}

const planToMigrationNotice = (props: Props) => {
  const { planUniqueId } = props
  if (planUniqueId === "pro_legacy" || planUniqueId === "pro_legacy_annual") {
    return <ProLegacy {...props} />
  } else if (planUniqueId === "team_v2" || planUniqueId === "team_v2_annual") {
    return <Team {...props} />
  } else if (planUniqueId === "basic" || planUniqueId === "basic_annual") {
    return <BasicLegacy {...props} />
  } else if (planUniqueId === "pro_v2" || planUniqueId === "annual") {
    return <ProV2Legacy {...props} />
  }
}

interface Props {
  planInterval: Plan["interval"]
  planName: Plan["name"]
  planUniqueId: Plan["unique_id"]
  subscriptionGuid: Subscription["guid"]
  subscriptionRenewalTime: Subscription["current_period_end"]
  teamMemberCount: number
}

export const DiscontinuedPlanAlert = (props: Props) => {
  const migrationNotice = planToMigrationNotice(props)
  if (!migrationNotice) return null
  return (
    <Alert alignItems="flex-start" status="warning" p={5}>
      <AlertIcon />
      <Stack spacing={2} pr={5}>
        <AlertTitle mr={2}>We{"\u2019"}ve changed our pricing</AlertTitle>
        {migrationNotice}
      </Stack>
    </Alert>
  )
}
