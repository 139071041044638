import { Box, Flex, Text } from "@chakra-ui/react"
import { Button, Heading } from "DesignSystem/components"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

interface Props {
  testSetId: number
}

export const EmptyTestSet = ({ testSetId }: Readonly<Props>) => (
  <Flex w="350px" my="100px" mx="auto" textAlign="center">
    <Box flexGrow={1}>
      <Flex flexDirection="column" gap={6} align="center">
        <Heading
          as="h3"
          textStyle="ds.display.secondary"
          textAlign="center"
          mx={4}
        >
          Add some tests
        </Heading>
        <Text textStyle="ds.paragraph.primary" color="ds.text.subtle">
          Create a new test or add existing tests to get started.
        </Text>
        <Button as={RouterLink} to={`/tests/new?test_set_id=${testSetId}`}>
          Create test
        </Button>
      </Flex>
    </Box>
  </Flex>
)
