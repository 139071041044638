import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Button, Heading, IconButton } from "DesignSystem/components"
import { TimezoneChanger } from "Shared/components/TimezoneChanger/TimezoneChanger"
import { Pencil02OutlineIcon } from "Shared/icons/untitled-ui/Pencil02OutlineIcon"
import { PlusCircleOutlineIcon } from "Shared/icons/untitled-ui/PlusCircleOutlineIcon"
import { Trash01OutlineIcon } from "Shared/icons/untitled-ui/Trash01OutlineIcon"
import { useModal } from "Utilities/modals/use-modal"
import { sortBy } from "lodash"
import React, { Fragment, useCallback } from "react"
import {
  DateOverrides,
  DateType,
  DayOverrideModal,
} from "../../availability/DayOverrideModal"
import { DAYS, DAY_LABELS } from "../../availability/days"
import { AvailabilityOverrides } from "../../moderated-study-builder/forms/useAvailabilitySectionForm"
import { Card } from "../Card"
import { useStudyDetails } from "../StudyDetailsProvider"
import { GeneralAvailability } from "./GeneralAvailability"

export const DatesAndTimes: React.FC = () => {
  const { availabilityForm } = useStudyDetails()

  const { open: editGeneralAvailability } = useModal(GeneralAvailability)

  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = availabilityForm

  const availabilityByDay = watch("availabilityByDay")
  const availabilityOverrides = watch("availabilityOverrides")

  const { open: openDayOverrideModal } = useModal(DayOverrideModal)

  const addOrUpdateAvailabilityOverrides = useCallback(
    (dateOverrides: DateOverrides, oldDate?: DateType) => {
      const availabilityOverrides = getValues("availabilityOverrides")
      const oldDateString = oldDate ? convertNumbersToDateString(oldDate) : ""
      const newDateString = convertNumbersToDateString(dateOverrides.date)
      const newAvailabilityOverrides = oldDate
        ? availabilityOverrides?.map((item) => {
            if (item.date === oldDateString) {
              return {
                ...item,
                date: newDateString,
                availability: dateOverrides.overrides,
              }
            } else {
              return item
            }
          })
        : [
            ...availabilityOverrides,
            {
              date: newDateString,
              availability: dateOverrides.overrides,
            },
          ]

      setValue("availabilityOverrides", newAvailabilityOverrides ?? [])
    },
    [getValues, setValue]
  )

  const convertToDateOverridesArray = useCallback(
    (data: AvailabilityOverrides) => {
      if (!data || Object.keys(data).length === 0) {
        return []
      }
      return data.map((overridesObject) => {
        return {
          date: convertDateStringToNumbers(overridesObject.date),
          overrides: overridesObject.availability,
        }
      })
    },
    []
  )

  return (
    <>
      <Card.Root id="dates-and-times">
        <Card.Header title="Dates and times" />
        <Card.Body>
          <Stack spacing={6} align="start">
            <Text as="p" mb={0} textStyle="ds.paragraph.primary">
              Specify the dates and hours that sessions can be booked. These
              settings, combined with hosts{"\u2019"} calendar availability,
              determine which time slots are offered to participants.
            </Text>
            <Stack spacing={6}>
              <FormControl isInvalid={!!errors.startsAt || !!errors.endsAt}>
                <FormLabel>
                  <Text textStyle="ds.heading.secondary">Study dates</Text>
                </FormLabel>
                <HStack spacing={3} justify="start">
                  <Input type="date" {...register("startsAt")} w="15rem" />
                  <Text textStyle="ds.paragraph.primary" color="ds.text.subtle">
                    to
                  </Text>
                  <Input type="date" {...register("endsAt")} w="15rem" />
                </HStack>
                <FormErrorMessage>
                  {(errors.startsAt ?? errors.endsAt)?.message}
                </FormErrorMessage>
              </FormControl>

              <Stack spacing={3} align="stretch">
                <Stack spacing={2}>
                  <Heading as="h3" textStyle="ds.heading.secondary" m={0}>
                    General availability
                  </Heading>
                  <Text textStyle="ds.paragraph.secondary">
                    Specify your general weekly availability.
                  </Text>
                  <TimezoneChanger
                    showIcon={false}
                    variant="outline"
                    {...register("timezone")}
                  />
                </Stack>

                <Stack
                  rounded={8}
                  p={4}
                  boxShadow="inset 0 0 0 1px var(--chakra-colors-ds-border-default)"
                  alignItems="start"
                >
                  <Grid
                    gridTemplateColumns="auto auto"
                    columnGap={4}
                    rowGap={2}
                    alignItems="start"
                    justifyItems="start"
                  >
                    {DAYS.map((day) => {
                      const availability = availabilityByDay[day]
                      return (
                        <Fragment key={day}>
                          <Text textStyle="ds.paragraph.primary">
                            {DAY_LABELS[day].longLabel}
                          </Text>
                          {availability.length ? (
                            <Stack spacing={1}>
                              {availability.map(
                                ({ starts_at, ends_at }, index) => (
                                  <Text
                                    key={index}
                                    textStyle="ds.paragraph.primary"
                                  >
                                    {`${getFormattedTime(starts_at)} – ${getFormattedTime(ends_at)}`}
                                  </Text>
                                )
                              )}
                            </Stack>
                          ) : (
                            <Text
                              textStyle="ds.paragraph.primary"
                              color="ds.text.subtle"
                            >
                              Unavailable
                            </Text>
                          )}
                        </Fragment>
                      )
                    })}
                  </Grid>

                  <Button
                    leftIcon={<Pencil02OutlineIcon />}
                    onClick={() =>
                      editGeneralAvailability({
                        availabilityForm,
                        onSubmit: (values) =>
                          availabilityForm.setValue(
                            "availabilityByDay",
                            values.availabilityByDay
                          ),
                      })
                    }
                  >
                    Edit hours
                  </Button>
                </Stack>
              </Stack>
              <Stack spacing={3}>
                <Heading as="h3" textStyle="ds.heading.secondary" m={0}>
                  Adjusted availability
                </Heading>
                <Text textStyle="ds.paragraph.secondary">
                  Use date overrides to change the times you{"\u2019"}re
                  available on specific dates.
                </Text>
                {availabilityOverrides.length > 0 && (
                  <Grid
                    gridTemplateColumns="auto 1fr auto auto"
                    columnGap={4}
                    rowGap={3}
                  >
                    {sortBy(availabilityOverrides, "date").map(
                      ({ date, availability }, index) => (
                        <Grid
                          key={`DateOverridesRow.${index}`}
                          gridTemplateColumns="subgrid"
                          gridColumn="1 / -1"
                        >
                          <Grid
                            gridTemplateColumns="subgrid"
                            gridColumn="1 / span 3"
                            boxShadow="inset 0 0 0 1px var(--chakra-colors-ds-border-default)"
                            rounded={8}
                            p={4}
                            alignSelf="center"
                          >
                            <Grid
                              gridTemplateColumns="subgrid"
                              gridColumn="1 / span 2"
                              alignSelf="center"
                            >
                              <Text textStyle="ds.paragraph.primary">
                                {new Date(
                                  `${date}T00:00:00`
                                ).toLocaleDateString(undefined, {
                                  weekday: "short",
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </Text>
                              {availability.length ? (
                                <Stack spacing={1}>
                                  {availability.map(
                                    ({ starts_at, ends_at }, index) => (
                                      <Text
                                        key={index}
                                        textStyle="ds.paragraph.primary"
                                      >
                                        {`${getFormattedTime(starts_at)} – ${getFormattedTime(ends_at)}`}
                                      </Text>
                                    )
                                  )}
                                </Stack>
                              ) : (
                                <Text
                                  textStyle="ds.paragraph.primary"
                                  color="ds.text.subtle"
                                >
                                  Unavailable
                                </Text>
                              )}
                            </Grid>
                            <IconButton
                              alignSelf="center"
                              variant="secondary"
                              aria-label="Edit"
                              onClick={() =>
                                openDayOverrideModal({
                                  AddOrUpdateAvailabilityOverrides:
                                    addOrUpdateAvailabilityOverrides,
                                  availabilityOverrides:
                                    convertToDateOverridesArray(
                                      getValues("availabilityOverrides")
                                    ),
                                  initialDate: convertDateStringToNumbers(date),
                                })
                              }
                            >
                              <Pencil02OutlineIcon />
                            </IconButton>
                          </Grid>
                          <IconButton
                            alignSelf="center"
                            variant="secondary"
                            aria-label="Delete"
                            onClick={() =>
                              setValue(
                                "availabilityOverrides",
                                getValues("availabilityOverrides").filter(
                                  (item) => item.date !== date
                                )
                              )
                            }
                          >
                            <Trash01OutlineIcon />
                          </IconButton>
                        </Grid>
                      )
                    )}
                  </Grid>
                )}
                <Box>
                  <Button
                    variant="default"
                    leftIcon={<Icon as={PlusCircleOutlineIcon} />}
                    onClick={() =>
                      openDayOverrideModal({
                        AddOrUpdateAvailabilityOverrides:
                          addOrUpdateAvailabilityOverrides,
                        availabilityOverrides: convertToDateOverridesArray(
                          getValues("availabilityOverrides")
                        ),
                      })
                    }
                  >
                    Add date override
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Card.Body>
      </Card.Root>
    </>
  )
}

const convertDateStringToNumbers = (dateString: string): DateType => {
  const [year, month, date] = dateString.split("-")
  return [Number(year), Number(month) - 1, Number(date)]
}

const convertNumbersToDateString = (dateNumbers: number[]): string => {
  const [year, month, date] = dateNumbers
  return `${year}-${(month + 1).toString().padStart(2, "0")}-${date
    .toString()
    .padStart(2, "0")}`
}

const getFormattedTime = (timeStr: string) => {
  const [hours, minutes] = timeStr.split(":")
  const date = new Date()
  date.setHours(Number(hours), Number(minutes))

  return date
    .toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "numeric",
      hourCycle: "h12",
    })
    .replace(" ", "")
}
