import React, { FC, PureComponent, useState } from "react"

import {
  Duration,
  Filter,
  Participants,
  Percentage,
} from "Components/test-results/progress-box/progress-box"
import styles from "Components/test-results/section-results/screenshot-preference-overview.module.scss"
import {
  DeviceFrame,
  PreferenceSummary,
  UsabilityTestSectionScreenshot as SectionScreenshot,
} from "Types"
import { useSectionContext } from "UsabilityHub/contexts"

import { usePreferenceFilter } from "../hooks/use-preference-filter"

import { ScreenshotPreferenceResult } from "./screenshot-preference-result"

interface ImplProps {
  deviceFrame: Readonly<DeviceFrame> | null
  sectionScreenshot: Readonly<SectionScreenshot>
  preferenceSummary: Readonly<PreferenceSummary>
  isFilter: boolean
  isFiltered: boolean
  isFilterRequested: boolean
  onFilterChange: (isFilter: boolean) => void
  onIsExpandedChange: (isExpanded: boolean) => void
  isExpanded: boolean
}

class Impl extends PureComponent<ImplProps> {
  render() {
    const {
      deviceFrame,
      isFilter,
      isFiltered,
      isFilterRequested,
      sectionScreenshot,
      preferenceSummary: {
        averageDuration,
        count,
        percentage: chosenPercentage,
      },
      onFilterChange,
      isExpanded,
      onIsExpandedChange,
    } = this.props

    return (
      <ScreenshotPreferenceResult
        deviceFrame={deviceFrame}
        screenshotId={sectionScreenshot.screenshot_id}
        data-qa={`option-${sectionScreenshot.id}`}
        isFilter={isFilter}
        isFiltered={isFiltered}
        onIsExpandedChange={onIsExpandedChange}
        isExpanded={isExpanded}
        progress={chosenPercentage}
      >
        <Duration duration={averageDuration} />
        <Percentage
          className={styles.percentage}
          percentage={chosenPercentage}
        />
        <Participants count={count} />
        <Filter onChange={onFilterChange} value={isFilterRequested} />
      </ScreenshotPreferenceResult>
    )
  }
}

interface OuterProps {
  deviceFrame: DeviceFrame | null
  sectionScreenshot: Readonly<SectionScreenshot>
  preferenceSummary: Readonly<PreferenceSummary>
}

export const ScreenshotPreferenceOverview: FC<OuterProps> = (props) => {
  const { section } = useSectionContext()
  const { isFilter, isFiltered, toggleFilter } = usePreferenceFilter(
    section.id,
    props.sectionScreenshot.id
  )

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Impl
      {...props}
      isFilter={isFilter}
      isFilterRequested={isFilter}
      isFiltered={isFiltered}
      isExpanded={isExpanded}
      onIsExpandedChange={setIsExpanded}
      onFilterChange={toggleFilter}
    />
  )
}
