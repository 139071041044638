// JsFromRoutes CacheKey f158644e6e06d4e975218cfbeef2606c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  makeOwner: /* #__PURE__ */ definePathHelper('post', '/admin/users/:id/make_owner'),
  sendPasswordReset: /* #__PURE__ */ definePathHelper('post', '/admin/users/:id/send_password_reset'),
  logOut: /* #__PURE__ */ definePathHelper('post', '/admin/users/:id/log_out'),
  suspend: /* #__PURE__ */ definePathHelper('put', '/admin/users/:id/suspend'),
  unsuspend: /* #__PURE__ */ definePathHelper('put', '/admin/users/:id/unsuspend'),
  ban: /* #__PURE__ */ definePathHelper('put', '/admin/users/:id/ban'),
  unban: /* #__PURE__ */ definePathHelper('put', '/admin/users/:id/unban'),
  togglePhoneVerificationRequirement: /* #__PURE__ */ definePathHelper('put', '/admin/users/:id/toggle_phone_verification_requirement'),
  assignmentHistory: /* #__PURE__ */ definePathHelper('get', '/admin/users/:id/assignment_history'),
  findTargetAccount: /* #__PURE__ */ definePathHelper('get', '/admin/users/:id/find_target_account'),
  moveToAccount: /* #__PURE__ */ definePathHelper('get', '/admin/users/:id/move_to_account/:to_account_id'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/users/:id/edit'),
  get: /* #__PURE__ */ definePathHelper('get', '/admin/users/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/users/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/users/:id'),
}
