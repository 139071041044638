import {
  Button,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { ChevronDownIcon, CursorClickIcon } from "@heroicons/react/solid"
import isSafari from "is-safari"
import React, { FC } from "react"

import { ClickOccurrence } from "Components/test-results/utils/filter-node-clicks/filter-node-clicks-by-occurrence"
import { getClicksByOccurrence } from "Components/test-results/utils/get-clicks-by-occurrence"
import { getAllClicks } from "Components/test-results/utils/get-node-clicks-by-response/get-all-clicks"
import { getOrdinalNumber } from "Shared/helpers/getOrdinalNumber"
import { FigmaTaskScreenResultMeta } from "Types"
import { formatPercentage01 } from "Utilities/number"
import { formatPreciseDuration } from "Utilities/time"

import { ClockOutlineIcon } from "Shared/icons/untitled-ui/ClockOutlineIcon"
import { ClickOccurrenceFilterContextType } from "../ClickOccurrenceFilterProvider"
import { ScreenView } from "../ScreenResults"

function getLabelForOccurrenceValue(occurrence: ClickOccurrence) {
  if (typeof occurrence === "string") {
    const maxOccurrenceIndex = parseInt(occurrence)
    return `${getOrdinalNumber(maxOccurrenceIndex + 1)}+ clicks`
  }

  return `${getOrdinalNumber(occurrence + 1)} clicks`
}

interface ScreenResultStatsBarProps
  extends Pick<
    ClickOccurrenceFilterContextType,
    "clickOccurrences" | "availableClickOccurrences" | "setClickOccurrences"
  > {
  screenResultMeta: FigmaTaskScreenResultMeta
  screenView: ScreenView
  onDownload: () => void
}

export const ScreenResultStatsBar: FC<ScreenResultStatsBarProps> = ({
  screenResultMeta,
  screenView,
  clickOccurrences,
  availableClickOccurrences,
  setClickOccurrences,
  onDownload,
}: ScreenResultStatsBarProps) => {
  const clicksByOccurrences = getClicksByOccurrence(
    screenResultMeta.clicks,
    availableClickOccurrences
  )
  const allNodeClicks = getAllClicks(screenResultMeta.clicks)

  const isOccurrenceEnabled = (occurrenceOptionValue: ClickOccurrence) => {
    return clickOccurrences.some(
      (occurrence) => occurrence === occurrenceOptionValue
    )
  }

  const allChecked = availableClickOccurrences.every((key) =>
    clickOccurrences.includes(key)
  )
  const someChecked = clickOccurrences.length > 0 && !allChecked

  const showDownloadButton =
    (screenView === "CLICK_MAP" || screenView === "HEAT_MAP") &&
    // Can't download heatmaps or clickmaps on Safari. Downloads require crossOrigin to be set on the source image for
    // CORS, but Safari caches images in a way that means the images sometimes aren't loaded at all when CORS is enabled
    !isSafari

  const totalClicksCount = screenResultMeta.clicks.flat().length

  return (
    <Flex px={4} py={2} justifyContent="space-between">
      <HStack spacing="6">
        <Flex alignItems="center" gap="1" color="gray.500">
          <ClockOutlineIcon />
          <Text as="span" fontWeight="semibold">
            {screenResultMeta.type === "aggregate"
              ? "Avg. time spent"
              : "Time spent"}
            : {formatPreciseDuration(screenResultMeta.duration ?? 0)}
          </Text>
        </Flex>
        <Flex alignItems="center" gap="1" color="gray.500">
          <Icon as={CursorClickIcon} />
          <Text as="span" fontWeight="semibold">
            Total clicks: {totalClicksCount}
          </Text>
          <Text as="span" color="gray.500">
            ({formatPercentage01(screenResultMeta.misclickRate ?? 0)} misclicks)
          </Text>
        </Flex>
      </HStack>

      <Flex alignItems="center" gap="2">
        <Menu closeOnSelect={false}>
          Show
          <MenuButton
            as={Button}
            bg="white"
            px="3"
            py="1.5"
            borderColor="gray.200"
            borderWidth={1}
            borderStyle="solid"
            borderRadius="lg"
            display="flex"
            alignItems="center"
            minWidth="44"
            height={8}
            textAlign="left"
            rightIcon={<Icon as={ChevronDownIcon} />}
          >
            {allChecked
              ? "All clicks"
              : someChecked
                ? "Selected clicks"
                : "No clicks"}
          </MenuButton>
          <MenuList>
            <MenuItem justifyContent="space-between" width="full" p="3">
              <Checkbox
                variant="mdWithSmFont"
                isChecked={allChecked}
                isIndeterminate={someChecked}
                onChange={(e) => {
                  const isChecked = e.target.checked
                  if (isChecked) {
                    setClickOccurrences(availableClickOccurrences)
                  } else {
                    setClickOccurrences([])
                  }
                }}
              >
                All clicks
              </Checkbox>
              <Text fontWeight="normal" color="gray.400">
                {allNodeClicks.length}
              </Text>
            </MenuItem>

            {availableClickOccurrences.map((occurrenceValue) => (
              <MenuItem
                key={occurrenceValue}
                justifyContent="space-between"
                width="full"
                p="3"
              >
                <Checkbox
                  variant="mdWithSmFont"
                  isChecked={isOccurrenceEnabled(occurrenceValue)}
                  onChange={(e) => {
                    const isChecked = e.target.checked
                    if (isChecked) {
                      setClickOccurrences((occurrences) => [
                        ...occurrences,
                        occurrenceValue,
                      ])
                    } else {
                      setClickOccurrences((occurrences) =>
                        occurrences.filter(
                          (occurrence) => occurrence !== occurrenceValue
                        )
                      )
                    }
                  }}
                >
                  {getLabelForOccurrenceValue(occurrenceValue)}
                </Checkbox>
                <Text fontWeight="normal" color="gray.400">
                  {clicksByOccurrences.get(occurrenceValue)?.length ?? 0}
                </Text>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>

        {showDownloadButton && (
          <Button
            height={8}
            colorScheme="brand.primary"
            onClick={onDownload}
            data-qa="download"
          >
            Download
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
