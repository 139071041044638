import { useToast } from "@chakra-ui/react"
import React from "react"

import { useLocalCommentContext } from "Components/comment-provider/CommentProvider"
import { usePutComments } from "~/api/generated/usabilityhub-components"
import { Comment } from "~/api/generated/usabilityhubSchemas"

import { CommentEditor } from "./CommentEditor"

type Props = {
  comment: Comment
  stopEditing: () => void
  isEntityPersisted: boolean
}

export const EditCommentForm: React.FC<Props> = ({
  comment,
  stopEditing,
  isEntityPersisted,
}) => {
  const toast = useToast()
  const { updateComment: updateCommentInCache, updateCommentLocally } =
    useLocalCommentContext()

  const editCommentMutation = usePutComments({
    onSuccess: (_data, variables) => {
      const editedComment: Comment = {
        ...comment,
        content: variables.body.content,
        last_edited_at: new Date().toISOString(),
      }

      updateCommentInCache(editedComment)
      stopEditing()
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error?.payload.message,
        status: "error",
      })
    },
  })

  const editComment = async (newContent: string): Promise<void> => {
    if (isEntityPersisted) {
      await editCommentMutation.mutateAsync({
        pathParams: {
          commentId: comment.id,
        },
        body: {
          content: newContent,
        },
      })
    } else {
      updateCommentLocally({
        comment_id: comment.id,
        content: newContent,
      })
      stopEditing()
    }
  }

  return (
    <CommentEditor
      defaultContent={comment.content}
      saveButtonLabel="Save"
      handleSave={editComment}
      isLoading={editCommentMutation.isLoading}
      closeThread={stopEditing}
      lockedOpen
    />
  )
}
