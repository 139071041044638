import { FC, PropsWithChildren } from "react"
import React from "react"

import { useCardSortFilter } from "Components/test-results/hooks/use-card-sort-filter"
import { CountItem } from "Components/test-results/question-results/answer-counts/count-item"
import { useSectionContext } from "UsabilityHub/contexts"

interface Props {
  sectionId: number
  cardId: number
  categoryIds: number[]
  count: number
  total: number
}

export const CategoryCardPairCountItem: FC<PropsWithChildren<Props>> = ({
  cardId,
  categoryIds,
  count,
  total,
  children,
}) => {
  const { section } = useSectionContext()

  const { isFilter, isFiltered, toggleCardSortFilter } = useCardSortFilter(
    section.id,
    cardId,
    categoryIds
  )

  return (
    <CountItem
      isFilter={isFilter}
      isFilterRequested={isFilter}
      isFiltered={isFiltered}
      onFilterChange={toggleCardSortFilter}
      text={children}
      count={count}
      total={total}
      isLiteral
    />
  )
}
