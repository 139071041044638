import { LocationFilter } from "../filters/attribute-exact-value"
import { UnknownLocationFilter } from "../filters/attribute-unknown"

import { generateFilterId } from "./generate-filter-id"

export function createLocationFilter(
  location: LocationFilter["value"]
): LocationFilter {
  return {
    id: generateFilterId(),
    category: "response/attribute:location",
    type: "attribute/exact-value",
    value: location,
  }
}

export function createUnknownLocationFilter(): UnknownLocationFilter {
  return {
    id: generateFilterId(),
    category: "response/attribute:location",
    type: "attribute/unknown",
    value: undefined,
  }
}
