import {
  Box,
  Flex,
  FlexProps,
  Grid,
  GridProps,
  Heading,
  Text,
} from "@chakra-ui/react"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import { XCircleSolidIcon } from "Shared/icons/untitled-ui/XCircleSolidIcon"
import { capitalize } from "lodash"
import React from "react"
import { PropsWithChildren } from "react"
import { DrawerData } from "./participantInfoDrawerData"

export const DrawerSection: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return <Box p={6}>{children}</Box>
}

export const ParticipantDrawerHeader: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Flex
    p={6}
    flexBasis="4.5rem"
    justify="space-between"
    align="center"
    boxShadow="0 6px 10px rgb(0 0 0 / 5%)"
    clipPath="inset(0 0 -16px 0)"
  >
    {children}
  </Flex>
)

export const ParticipantDrawerAccordionItemHeading: React.FC<
  React.PropsWithChildren
> = ({ children }) => (
  <Heading fontSize="md" fontWeight="semibold">
    {children}
  </Heading>
)

export const FieldTitle: React.FC<PropsWithChildren> = ({ children }) => (
  <Flex as="dt" align="center" gap={2} color="text.secondary">
    <Text>{children}</Text>
  </Flex>
)

export const FieldValueText: React.FC<PropsWithChildren> = ({ children }) => (
  <Text as="dd" color="text.primary">
    {children}
  </Text>
)

export const FieldValueGeneric: React.FC<PropsWithChildren & FlexProps> = ({
  children,
  ...rest
}) => (
  <Flex as="dd" color="text.primary" alignItems="center" h="full" {...rest}>
    {children}
  </Flex>
)

export const DetailsGrid: React.FC<PropsWithChildren & GridProps> = ({
  children,
  ...rest
}) => (
  <Grid
    as="dl"
    templateColumns="8.375rem 1fr"
    gap={3}
    alignItems="flex-start"
    {...rest}
  >
    {children}
  </Grid>
)

export const EligibilityStatus: React.FC<{ status: string }> = ({ status }) => {
  const isQualified = status !== "disqualified"
  const Icon = isQualified
    ? () => <CheckCircleSolidIcon color={"green.400"} />
    : () => <XCircleSolidIcon color={"red.600"} />

  return (
    <Flex gap={1} alignItems="center">
      <Icon />
      {isQualified && <Text color="green.500">Qualified</Text>}
    </Flex>
  )
}

export const formatRecruitmentMethod = (
  s: DrawerData["attributes"]["method"]
) => {
  return s ? `(${capitalize(s)})` : s
}
