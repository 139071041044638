import { Box, Card, Flex, Heading, Image, Tag } from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"
import { Link } from "react-router-dom"
import { Template } from "~/usabilityhub/types/templateLibrary"
import { ROUTES } from "~/usabilityhub/views/routes"

type TemplateCardProps = PropsWithChildren<{
  template: Template
}>

export const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  children,
}) => (
  <Card
    as={Link}
    to={ROUTES.DASHBOARD.TEMPLATES.DETAILS.buildPath({
      templateId: template.id,
    })}
    boxShadow="none"
    borderRadius={0}
    display="grid"
    gridTemplate={{
      base: `"thumbnail title" auto "thumbnail tags" 1fr / 7.5rem 1fr`,
      sm: `"thumbnail" auto "title" auto "tags" auto / 1fr`,
    }}
    gridColumnGap={4}
    gridRowGap={2}
    alignItems="start"
    _hover={{
      "& img": {
        transform: "scale(1.1)",
      },
      "& h4": {
        color: "brand.primary.500",
      },
    }}
  >
    <Box
      gridArea="thumbnail"
      aspectRatio="3 / 2"
      backgroundColor="#E1EBF5"
      borderRadius={12}
    />
    {template.image && (
      <Box
        gridArea="thumbnail"
        aspectRatio="1700 / 1182"
        borderRadius={12}
        overflow="hidden"
      >
        <Image
          display="block"
          src={template.image}
          alt=""
          transition="transform 0.2s ease"
          aspectRatio="1700 / 1182"
          objectFit="cover"
        />
      </Box>
    )}
    <Heading
      as="h4"
      gridArea="title"
      fontSize={{ base: "md", md: "lg" }}
      fontWeight="medium"
      lineHeight={6}
      mt={{ md: 2 }}
    >
      {children || template.title}
    </Heading>
    <Flex gridArea="tags" alignItems="start" wrap="wrap" gap={2}>
      {template.methodologies.map((m) => (
        <Tag key={m.id} bgColor="brand.background.light">
          {m.name}
        </Tag>
      ))}
    </Flex>
  </Card>
)
