import { createIcon } from "@chakra-ui/react"
import React from "react"

export const InterviewEmailsIcon = createIcon({
  displayName: "InterviewEmailsIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M6.759 3H17.24c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 0 1 1.748 1.748c.239.468.339.966.387 1.505a1 1 0 0 1 .03.5c.02.45.02.971.02 1.57v6.482c0 .805 0 1.47-.045 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H6.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C1 16.71 1 16.046 1 15.242V8.758c0-.599 0-1.12.018-1.57a1 1 0 0 1 .031-.5c.048-.54.148-1.037.387-1.505a4 4 0 0 1 1.748-1.748c.485-.247 1.002-.346 1.564-.392C5.29 3 5.954 3 6.758 3ZM3 8.92v6.28c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 0 0 .874.874c.157.08.38.145.82.18C5.361 19 5.943 19 6.8 19h10.4c.857 0 1.439 0 1.889-.038.439-.035.663-.1.82-.18a2 2 0 0 0 .873-.874c.08-.156.145-.38.18-.819.037-.45.038-1.032.038-1.889V8.92l-6.591 4.615a42.26 42.26 0 0 0-.116.08c-.544.383-1.023.719-1.567.855a3 3 0 0 1-1.452 0c-.544-.136-1.022-.472-1.567-.854a42.326 42.326 0 0 0-.115-.081L3 8.92Zm17.917-2.383-7.655 5.36c-.73.51-.884.598-1.02.632a1 1 0 0 1-.484 0c-.136-.034-.29-.123-1.02-.633L3.083 6.537c.036-.207.082-.34.135-.445a2 2 0 0 1 .874-.874c.157-.08.38-.145.82-.18C5.361 5 5.943 5 6.8 5h10.4c.857 0 1.439 0 1.889.038.439.035.663.1.82.18a2 2 0 0 1 .873.874c.053.104.1.238.135.445Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})
