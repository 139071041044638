import React from "react"

import { QuestionResultsList } from "Components/test-results/question-results/question-results-list"
import { SectionResultsSectionText } from "UsabilityHub/components/TestResults/SectionResultsLabels"

import { SectionResultsCard } from "../SectionResultsCard"

import { PrototypeTaskResults } from "./PrototypeTaskResults"
import { TaskFlowPrototypeTaskResults } from "./TaskFlowPrototypeTaskResults"
import { usePrototypeType } from "./hooks/use-prototype-type"

export const PrototypeSectionResultsCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <SectionResultsCard>
      <SectionResultsSectionText />
      <PrototypeResults />
      <QuestionResultsList />
    </SectionResultsCard>
  )
}

function assertPrototypeTypeNever(type: never): never {
  throw new Error(`Prototype type is not supported`, type)
}

const PrototypeResults = () => {
  const prototypeType = usePrototypeType()!

  if (prototypeType === "task_flow") {
    return <TaskFlowPrototypeTaskResults />
  }

  if (prototypeType === "free_roam") {
    return <PrototypeTaskResults />
  }

  assertPrototypeTypeNever(prototypeType)
}
