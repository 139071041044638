import { Button, Image, Link, Text, VStack } from "@chakra-ui/react"
import LyssnaLogoGeneric from "Images/lyssna-logo.png"
import { PageContent, PageMain } from "Shared/components/Page/Page"
import React from "react"

type Props = {
  message: string
}

export const OAuthResponseLayout: React.FC<React.PropsWithChildren<Props>> = ({
  message,
}) => {
  return (
    <PageMain>
      <PageContent maxW="8xl">
        <VStack
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          spacing={8}
          p={12}
        >
          <Link href="/">
            <Image
              src={LyssnaLogoGeneric}
              aria-label="Lyssna logo"
              width="200px"
            />
          </Link>
          <Text fontSize="lg">{message}</Text>
          <Button colorScheme="brand.primary" onClick={() => window.close()}>
            Close
          </Button>
        </VStack>
      </PageContent>
    </PageMain>
  )
}
