import { IndividualScreenshotClickResultOld } from "Components/test-results/screenshot-click-results/individual-screenshot-click-result-old"
import { State } from "Redux/app-store"
import { getScreenshotWithId } from "Redux/reducers/screenshots/selectors"
import {
  DeviceFrame,
  ScreenshotClick,
  ScreenshotHitzone,
  UsabilityTestSectionScreenshot,
} from "Types"
import { useDeviceFramesContext } from "UsabilityHub/contexts"
import React from "react"
import { connect } from "react-redux"

interface Props {
  readonly deviceFrameId: number | null
  readonly sectionScreenshot: Readonly<UsabilityTestSectionScreenshot>
  clicks: ReadonlyArray<ScreenshotClick>
  hitzones: ReadonlyArray<ScreenshotHitzone>
}

export const ClickResult: React.FC<Props> = ({ deviceFrameId, ...rest }) => {
  const deviceFrames = useDeviceFramesContext()
  const deviceFrame =
    deviceFrameId === null
      ? null
      : deviceFrames.find((f) => f.id === deviceFrameId) || null

  return <ClickResultInner deviceFrame={deviceFrame} {...rest} />
}

type InnerProps = {
  deviceFrame: DeviceFrame | null
  sectionScreenshot: Readonly<UsabilityTestSectionScreenshot>
}
const ClickResultInner = connect(
  (state: State, { deviceFrame, sectionScreenshot }: InnerProps) => {
    return {
      constrainWidthToDeviceFrame: deviceFrame,
      screenshot: getScreenshotWithId(state, sectionScreenshot.screenshot_id),
    }
  }
)(IndividualScreenshotClickResultOld)
