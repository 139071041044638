import { Box, Tag, ThemeTypings } from "@chakra-ui/react"
import { capitalize } from "lodash"
import React from "react"

import { GetModeratedStudyApplicationsResponse } from "~/api/generated/usabilityhub-components"

type Status =
  GetModeratedStudyApplicationsResponse["applications"][number]["status"]

const label = (status: Status): string => {
  switch (status) {
    case "canceled":
      return "Canceled"
    case "no_show":
      return "No-show"
    case "auto_complete":
      return "Completed"
    case "reschedule_requested":
      return "Reschedule requested"
    case "declined_by_applicant":
      return "Participant declined"
    case "declined_by_researcher":
      return "Unsuitable"
    case "declined_by_automatic":
      return "Invite expired"
    case "invitation_revoked":
      return "Invite revoked"
    default:
      return capitalize(status)
  }
}

// Note there's a similar map in ParticipantBookingStateBadge for the ParticipantInfoDrawer
const color = (status: Status): ThemeTypings["colorSchemes"] => {
  switch (status) {
    case "complete":
    case "auto_complete":
      return "green"
    case "no_show":
      return "red"
    case "applied":
      return "blue"
    case "invited":
      return "orange"
    case "invitation_revoked":
      return "gray"
    case "booked":
      return "teal"
    case "canceled":
      return "gray"
    case "reported":
      return "red"
    case "reschedule_requested":
      return "orange"
    case "declined_by_applicant":
      return "orange"
    case "declined_by_researcher":
      return "purple"
    case "declined_by_automatic":
      return "orange"
  }
}

interface ApplicationStatusCellProps {
  status: Status
}

const STATUS_OVERFLOW_THRESHOLDS: Partial<Record<Status, string>> = {
  reschedule_requested: "166px",
  declined_by_applicant: "151px",
  declined_by_researcher: "153px",
  declined_by_automatic: "106px",
}

export function ApplicationStatusCell({ status }: ApplicationStatusCellProps) {
  // Hack to avoid full-width tag background when the text wraps
  // (we can't just use display: block on the tag because
  // it's inside a display: table-cell container)
  const sx = Object.keys(STATUS_OVERFLOW_THRESHOLDS).includes(status)
    ? {
        [`@container (max-width: ${STATUS_OVERFLOW_THRESHOLDS[status]})`]: {
          width: status === "declined_by_automatic" ? "67px" : "94px",
        },
      }
    : undefined

  return (
    <Box sx={{ containerType: "inline-size" }}>
      <Tag colorScheme={color(status)} whiteSpace="initial" py={0.5} sx={sx}>
        {label(status)}
      </Tag>
    </Box>
  )
}
