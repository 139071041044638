import { useCallback, useEffect, useRef, useState } from "react"

import {
  FigmaMessage,
  TaskEvent,
  TaskResult,
  UnpersistedResponseSection,
  UsabilityTestSection,
} from "Types"
import {
  isTaskComplete as isComplete,
  sectionStartTime,
} from "Utilities/response"

export interface UsePrototypeReturn {
  startTask: () => void
  finishTask: (
    result: TaskResult,
    taskFlowSuccessAcknowledged?: boolean
  ) => void
  onFigmaMessageEvent: (message: FigmaMessage) => void
  isTaskLoaded: boolean
  isTaskStarted: boolean
  isTaskFinishEnabled: boolean
  isTaskComplete: boolean
  isGoalScreenVisited: boolean
}

const TASK_RESPONSE_INITIAL_STATE = {
  figma_file_version_answer: null,
}

interface UsePrototypeProps {
  section: UsabilityTestSection
  responseSection: UnpersistedResponseSection | null
  updateResponseSection: (
    id: number,
    updateResponseSection: Partial<UnpersistedResponseSection>
  ) => void
  isDesktop: boolean
}

export const usePrototypeTask = ({
  section,
  responseSection,
  updateResponseSection,
  isDesktop,
}: UsePrototypeProps): UsePrototypeReturn => {
  const taskStartTimeout = useRef<null | NodeJS.Timeout>(null)
  const figmaFlow = section.figma_file_flow
  if (!figmaFlow) {
    throw Error("No prototype for section")
  }
  const [preloadMessages, setPreloadMessages] = useState<FigmaMessage[]>([])
  const [taskStartTime, setTaskStartTime] = useState<number | null>(null)
  const [taskEvents, setTaskEvents] = useState<TaskEvent[]>([])
  const [isTaskLoaded, setTaskLoaded] = useState(false)
  const [isTaskFinishEnabled, setTaskFinishEnabled] = useState(false)
  const [isGoalScreenVisited, setIsGoalScreenVisited] = useState(false)
  const isTaskComplete = isComplete(responseSection)

  const startTask = () => {
    const time = performance.now()

    setTaskStartTime(time)
    setTaskEvents(
      // Prefill task events with messages that were captured
      // before the task was 'started'
      preloadMessages.map((message) => ({
        duration_since_task_start_ms: 0,
        figma_message: message,
      }))
    )
    updateResponseSection(section.id, {
      instructions_duration_ms: time - sectionStartTime(responseSection),
    })

    // Enable task completion after 5 seconds
    taskStartTimeout.current = setTimeout(() => {
      setTaskFinishEnabled(true)
      taskStartTimeout.current = null
    }, 5000)
  }

  const finishTask = useCallback(
    (taskResult: TaskResult, taskFlowSuccessAcknowledged = false) => {
      if (taskStartTime === null) {
        throw new Error(`finished task before instructions were read`)
      }
      const time = performance.now()
      const taskDurationMs = time - taskStartTime

      updateResponseSection(section.id, {
        task_duration_ms: taskDurationMs,
        figma_file_version_answer: {
          figma_file_version_id: figmaFlow.figma_file_version_id,
          data: taskEvents,
          task_result: taskResult,
        },
        _taskFlowSuccessAcknowledged: taskFlowSuccessAcknowledged,
      })
    },
    [taskStartTime, updateResponseSection, section.id, figmaFlow, taskEvents]
  )

  const onFigmaMessageEvent = (figmaMessage: FigmaMessage) => {
    // Ignore messages after the task is complete
    if (isTaskComplete) return
    // Set the task to loaded when we get the initial load event
    if (figmaMessage.type === "INITIAL_LOAD") {
      setTaskLoaded(true)
    }

    // PRESENTED_NODE_CHANGED messages represent state transitions within the prototype which can
    // be triggered by different kinds of user interaction.  For now we only want to filter out
    // messages with `isStoredInHistory === false` which are generated when the user hovers over something.
    if (
      figmaMessage.type === "PRESENTED_NODE_CHANGED" &&
      !figmaMessage.data.isStoredInHistory
    ) {
      return
    }

    // Ignore any updates to variables which could be very frequent and don't add
    // any useful information to the task data
    if (figmaMessage.type === "VARIABLE_UPDATED") {
      return
    }

    if (figmaMessage.data?.presentedNodeId === figmaFlow?.goal_node_id) {
      setIsGoalScreenVisited(true)
    }

    if (taskStartTime === null) {
      // If the task hasn't started, save the messages for later
      setPreloadMessages((messages) => [...messages, figmaMessage])
    } else {
      // If the task has started, push a new event into the task events
      setTaskEvents((events) => [
        ...events,
        {
          duration_since_task_start_ms: Math.floor(
            performance.now() - taskStartTime
          ),
          figma_message: figmaMessage,
        },
      ])
    }
  }

  // Reset task on desktop/mobile switch
  useEffect(() => {
    // Make sure we haven't completed the task
    // or we'll clobber the existing response
    if (!isTaskComplete) {
      setTaskLoaded(false)
      setTaskStartTime(null)
      setTaskFinishEnabled(false)
      setPreloadMessages([])
      setIsGoalScreenVisited(false)
      setTaskEvents([])
      updateResponseSection(section.id, {
        instructions_duration_ms: null,
        ...TASK_RESPONSE_INITIAL_STATE,
      })
      // Clear task start timeout
      if (taskStartTimeout.current) {
        clearTimeout(taskStartTimeout.current)
      }
    }
  }, [isTaskComplete, isDesktop, section.id])

  return {
    startTask,
    finishTask,
    onFigmaMessageEvent,
    isTaskLoaded,
    isTaskStarted: taskStartTime !== null,
    isTaskFinishEnabled,
    isTaskComplete,
    isGoalScreenVisited,
  }
}
