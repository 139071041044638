import { Filter } from "Components/test-results/types/filters"

import { getAttributes } from "../get-attribute"
import { getAttributeCategoryFromCategory } from "../get-attribute-from-category"

import { SourceAttribute } from "Components/test-results/types/attributes"
import { FilterFunction } from "./types"

export type LocationFilter = Filter<
  "response/attribute:location",
  "attribute/exact-value",
  string
>

export type SourceFilter = Filter<
  "response/attribute:source",
  "attribute/exact-value",
  SourceAttribute["value"]
>

export type DemographicAttributeOptionIdFilter = Filter<
  `response/attribute:demographic_attribute_option_id/${string}`,
  "attribute/exact-value",
  number
>

export type AttributeExactValueFilter =
  | LocationFilter
  | SourceFilter
  | DemographicAttributeOptionIdFilter

export const filterResponseByAttributeExactValue: FilterFunction<
  AttributeExactValueFilter
> = ({ response, filter }) => {
  const attributeCategory = getAttributeCategoryFromCategory(filter.category)!

  const categoryAttributes = getAttributes(response, attributeCategory)

  if (!categoryAttributes.length) return false

  return categoryAttributes.some(
    (attribute) => attribute.value === filter.value
  )
}
