import { HStack, Icon, Image, Link, Stack, Text } from "@chakra-ui/react"
import { EyeOutlineIcon } from "Shared/icons/untitled-ui/EyeOutlineIcon"
import React from "react"
import { AvailabilityCalendarPreviewModalTrigger } from "../../availability/AvailabilityCalendarPreview"
import { Advanced } from "./Advanced"
import { DatesAndTimes } from "./DatesAndTimes"
import { Hosts } from "./Hosts"
import availabilityIcon from "./availability.png"

export const HostsAndAvailability: React.FC = () => {
  const scrollTo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    const href = e.currentTarget.href
    const targetId = href.substring(href.indexOf("#") + 1)
    const targetElement = document.getElementById(targetId)
    targetElement?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <>
      <HStack
        px={6}
        py={6}
        gap={4}
        bg="ds.background.neutral.resting"
        rounded={12}
      >
        <Image src={availabilityIcon} boxSize={12} />

        <Stack gap={2} flex={1}>
          <Text textStyle="ds.display.primary">View available time slots</Text>
          <Text textStyle="ds.paragraph.emphasized">
            Based on your{" "}
            <Link href="#hosts" onClick={scrollTo}>
              hosts{"\u2019"} availability
            </Link>{" "}
            and{" "}
            <Link href="#dates-and-times" onClick={scrollTo}>
              study dates and times
            </Link>
            .
          </Text>
        </Stack>

        <AvailabilityCalendarPreviewModalTrigger
          leftIcon={<Icon as={EyeOutlineIcon} />}
        >
          Preview availability
        </AvailabilityCalendarPreviewModalTrigger>
      </HStack>
      <Hosts />
      <DatesAndTimes />
      <Advanced />
    </>
  )
}
