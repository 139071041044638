import cx from "classnames"
import React from "react"

import { NewTabAnchor } from "Components/anchors/basic"
import { Text } from "Components/form/text"
import Constants from "Constants/shared.json"

import styles from "./post-test-redirection-fields.module.scss"

interface Props {
  readonly value: string
  readonly onChange: (value: string) => void
  readonly required: boolean
}

export const PostTestRedirectionFields = ({ required, ...rest }: Props) => (
  <>
    <Text
      {...rest}
      placeholder="https://yoursite.com/thank-you.html"
      required={required}
      data-qa="redirection-url-field"
    />
    <p className={cx("para", styles.info)}>
      Need to track unique participants?{" "}
      <NewTabAnchor href={Constants.HELP_CENTER_POST_TEST_REDIRECTS_URL}>
        Learn more.
      </NewTabAnchor>
    </p>
  </>
)
