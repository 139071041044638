import {
  Alert,
  AlertDescription,
  AlertIcon,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { round } from "lodash"
import React from "react"

import { PreferenceConfidence, PreferencePerformance } from "Types"
import { formatPercentage01 } from "Utilities/number"

function humanizeConfidence(confidence: PreferenceConfidence): string {
  switch (confidence) {
    case PreferenceConfidence.InsufficientData:
      return "insufficient data"
    case PreferenceConfidence.VeryConfident:
      return "very confident"
    case PreferenceConfidence.Confident:
      return "confident"
    case PreferenceConfidence.FairlyConfident:
      return "fairly confident"
    case PreferenceConfidence.NoStatisticalSignificance:
      return "not statistically significant"
  }
}

interface Props {
  performance: PreferencePerformance
  optionName: string
}

export const PreferenceTestOptionPerformance: React.FC<
  React.PropsWithChildren<Props>
> = (props) => {
  const { optionName, performance } = props
  const { confidence, percentage, zScore } = performance
  const title = `z-score: ${round(zScore, 2)}`

  if (confidence === PreferenceConfidence.InsufficientData) {
    return (
      <Alert status="info" title={title}>
        <AlertIcon />
        <AlertDescription>
          {optionName} is performing better, but the test has received fewer
          than ten responses, so you should take these results with a grain of
          salt.
        </AlertDescription>
      </Alert>
    )
  }
  if (confidence === PreferenceConfidence.NoStatisticalSignificance) {
    return (
      <Alert status="info">
        <AlertIcon />
        <AlertDescription>
          {optionName} is performing better, but the difference is{" "}
          <Tooltip label={title} placement="top">
            <Text
              as="span"
              textDecoration="underline dotted"
              textUnderlineOffset={1}
              cursor="help"
              fontWeight="bold"
            >
              not statistically significant
            </Text>
          </Tooltip>
          . It may be performing better due to random chance alone.
        </AlertDescription>
      </Alert>
    )
  }
  return (
    <Alert status="info">
      <AlertIcon />
      <AlertDescription>
        {optionName} is performing better, and the difference is{" "}
        <Tooltip label={title} placement="top">
          <Text
            as="span"
            textDecoration="underline dotted"
            textUnderlineOffset={1}
            cursor="help"
            fontWeight="bold"
          >
            {formatPercentage01(percentage, 1)}
          </Text>
        </Tooltip>{" "}
        likely to be statistically significant. This means that you can be
        <strong> {humanizeConfidence(confidence)} </strong>
        that it is actually better, and not performing better due to random
        chance.
      </AlertDescription>
    </Alert>
  )
}
