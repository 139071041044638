import { groupBy } from "lodash"

import { getClicks } from "Components/test-results/utils/response-section-figma-task/get-clicks"
import { CommonFigmaTaskPath, ResponseSectionFigmaTask } from "Types"

import { hasGoalScreenBeenReached } from "../../response-section-figma-task/has-goal-screen-been-reached"

import { getCommonPathKeys } from "./get-common-paths-keys"

export function getCommonFigmaTaskPathsFromTasks(
  tasks: ResponseSectionFigmaTask[],
  commonPathNames: { [commonPath: string]: string },
  goalNodeId: string | null
): CommonFigmaTaskPath[] {
  const commonPathKeys = getCommonPathKeys(tasks)
  const filteredTasksByPath = groupBy(tasks, "path")

  return commonPathKeys.map((path, index) => {
    // Calculate data based on filtered tasks
    const filteredTasks = filteredTasksByPath[path] || []

    const participantCount = filteredTasks.length
    const averageDurationMs = participantCount
      ? filteredTasks.reduce((sum, tr) => sum + tr.task_duration_ms, 0) /
        participantCount
      : 0
    const averageMisclickRate = participantCount
      ? filteredTasks.reduce((sum, tr) => sum + (tr.misclick_rate ?? 0), 0) /
        participantCount
      : 0

    const markedCompleteCount = filteredTasks.filter(
      (t) => t.task_result === "completed"
    ).length

    const pathNumber = index + 1

    return {
      type: "common",
      pathName: commonPathNames[path] || `Path ${pathNumber}`,
      pathId: path,
      tasks: filteredTasks,
      meta: {
        goalScreenHasBeenReached: hasGoalScreenBeenReached(
          filteredTasks[0]!,
          goalNodeId
        ),
        clicks: filteredTasks.flatMap(getClicks),
        misclickRate: averageMisclickRate,
        duration: averageDurationMs,
        participantsCount: participantCount,
        markedAsCompletedPercentage: markedCompleteCount / participantCount,
      },
    }
  })
}
