import { FigmaTaskScreenResult } from "Types"

import { getAggregateScreenResults } from "./get-aggregate-screen-results"

export function getAggregatedScreenResultsByScreenResults(
  screenResults: FigmaTaskScreenResult[][]
) {
  const aggregatedScreenResults: FigmaTaskScreenResult[] = []

  for (let i = 0; i < screenResults[0].length; i++) {
    const sameScreenResults = screenResults.map(
      (screenResults) => screenResults[i]
    )

    aggregatedScreenResults.push(getAggregateScreenResults(sameScreenResults))
  }

  return aggregatedScreenResults
}
