import { Box, Tooltip } from "@chakra-ui/react"
import {
  ClockIcon,
  CursorClickIcon,
  UserGroupIcon,
} from "@heroicons/react/solid"
import React from "react"

import { CommonPathMeta } from "Types"
import { formatPercentage01 } from "Utilities/number"
import { floorMsToS } from "Utilities/time"

import { GoalScreenBadge } from "./GoalScreenBadge"
import { PathStatProps } from "./PathStatProps"
import { PrototypeResultsTextStat } from "./Stat"

export function CommonPathStats({
  pathMeta,
  highlightTime = false,
  highlightClicks = false,
}: PathStatProps<CommonPathMeta>) {
  const { clicks, duration, misclickRate, goalScreenHasBeenReached } = pathMeta

  return (
    <>
      <Box>
        <GoalScreenBadge goalScreenHasBeenReached={goalScreenHasBeenReached} />
      </Box>
      <Tooltip
        hasArrow
        placement="top"
        label="Average time taken by participants who took this path"
      >
        <PrototypeResultsTextStat
          icon={ClockIcon}
          isHighlighted={highlightTime}
        >
          Average time spent: {floorMsToS(duration)}s
        </PrototypeResultsTextStat>
      </Tooltip>
      <Tooltip
        hasArrow
        placement="top"
        label="Total number of clicks by participants who took this path"
      >
        <PrototypeResultsTextStat
          icon={CursorClickIcon}
          isHighlighted={highlightClicks}
        >
          {clicks.length} Clicks ({formatPercentage01(misclickRate)} misclicks)
        </PrototypeResultsTextStat>
      </Tooltip>

      {pathMeta.participantsCount > 1 && (
        <Tooltip
          hasArrow
          placement="top"
          label="Total number of participants who took this path"
        >
          <PrototypeResultsTextStat icon={UserGroupIcon} isHighlighted={false}>
            {pathMeta.participantsCount} Participants
          </PrototypeResultsTextStat>
        </Tooltip>
      )}
    </>
  )
}
