import { Box } from "@chakra-ui/react"
import React from "react"

export const ScreenshotMask: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <Box pos="absolute" cursor="default" top={0} left={0} right={0} bottom={0}>
      <Box w="full" h="full" pointerEvents="none" bg="gray.200" />
    </Box>
  )
}
