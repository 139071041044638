import { Button, useToast } from "@chakra-ui/react"
import React, { useState } from "react"

import { axios, isBadRequestError } from "Services/axios"
import UsersApi from "~/api/usersApi"

type ResendInvitationForm = React.FC<
  React.PropsWithChildren<{
    userId: number
  }>
>

export const ResendInvitationForm: ResendInvitationForm = ({ userId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const toast = useToast()
  return (
    <Button
      size="sm"
      isDisabled={isSubmitting || isSubmitted}
      loadingText="Sending invitation"
      onClick={async () => {
        setIsSubmitting(true)
        try {
          const { data } = await axios.post(
            UsersApi.resendInvitation.path({ id: userId })
          )
          toast({
            title: data.message,
            status: "success",
          })
          setIsSubmitting(false)
          setIsSubmitted(true)
        } catch (error) {
          if (isBadRequestError(error)) {
            toast({
              title: error.response.data.message,
              status: "error",
            })
            setIsSubmitting(false)
          } else {
            throw error
          }
        }
      }}
      variant="outline"
    >
      {isSubmitted ? "Invitation resent" : "Resend invitation"}
    </Button>
  )
}
