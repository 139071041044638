import { Box } from "@chakra-ui/react"
import React from "react"
import { useTestRecordingContext } from "../../context/TestRecordingContext"
import { useAudioVolume } from "./useAudioVolume"

export const SoundMeter: React.FC = () => {
  const { audioDeviceId } = useTestRecordingContext()
  const volumeDecimal = useAudioVolume({ audioDeviceId })

  return (
    <Box
      as="meter"
      width="100%"
      value={volumeDecimal}
      sx={{
        "&::-webkit-meter-optimum-value": {
          background: "orange",
        },
      }}
    />
  )
}
