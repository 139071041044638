import classNames from "classnames"
import React, { HTMLAttributes } from "react"

import styles from "Components/column-form/column-form.module.scss"

type Props = HTMLAttributes<HTMLElement>

export const ColumnFormSection: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  ...rest
}) => (
  <section className={classNames(className, styles.paddedSection)} {...rest} />
)
