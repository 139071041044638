import { Stack } from "@chakra-ui/react"
import classNames from "classnames"
import { sortBy, sum, times } from "lodash"
import React, { CSSProperties, PureComponent } from "react"
import FlipMove from "react-flip-move"

import { ResponseAnswer } from "Types"
import {
  SectionResultsQuestionHeading,
  SectionResultsQuestionText,
} from "UsabilityHub/components/TestResults/SectionResultsLabels"
import { hexToColor, lerpColor } from "Utilities/color"
import { percentage, rgb } from "Utilities/css-helpers"
import { memoizeOnce } from "Utilities/memoization"

import { Props } from "./props"
import { FilterDropdown } from "./ranking-question-dropdown-filter"
import styles from "./ranking-question-results.module.scss"

/** Summary of each ranking item's performance. */
interface Row {
  /** The name of the ranking item. */
  readonly item: string
  /** The number of times the item appeared at each rank. */
  readonly counts: ReadonlyArray<number>
  /** The average rank this item received. */
  readonly mean: number | null
}

interface Summary {
  readonly rows: ReadonlyArray<Row>
  readonly maxCount: number
}

function summarize(
  options: ReadonlyArray<string>,
  answers: ReadonlyArray<Readonly<ResponseAnswer>>
): Summary {
  const rankings = answers.map((a) => a.answers)
  const emptyCounts = options.reduce(
    (acc, o) => {
      acc[o] = []
      return acc
    },
    {} as Record<string, number[]>
  )
  const keyed = rankings.reduce<Record<string, number[]>>((acc, ranking) => {
    ranking.forEach((item, i) => {
      if (acc[item] === undefined) {
        acc[item] = []
      }
      if (acc[item][i] === undefined) {
        acc[item][i] = 1
      } else {
        acc[item][i]++
      }
    })
    return acc
  }, emptyCounts)

  const entries = Object.entries(keyed)
  let maxCount = 0
  const rows = sortBy(
    entries.map(([item, counts]): Row => {
      let total = 0
      for (let i = 0; i < entries.length; i++) {
        if (counts[i] === undefined) {
          counts[i] = 0
        } else {
          const rank = i + 1
          total += counts[i] * rank
          if (counts[i] > maxCount) {
            maxCount = counts[i]
          }
        }
      }
      const answerCount = sum(counts)
      return {
        item,
        counts,
        mean: answerCount === 0 ? null : total / answerCount,
      }
    }),
    (e) => e.mean
  )

  return {
    maxCount,
    rows,
  }
}

const minColor = hexToColor("#EDF2F8")
const maxColor = hexToColor("#B8C9DB")

function fillStyle(count: number, total: number): CSSProperties {
  const scale = count === 0 ? 0 : count / total
  const color = rgb(lerpColor(minColor, maxColor, scale))
  return {
    top: percentage(1 - scale),
    backgroundColor: color,
  }
}

interface ResultRowProps {
  readonly row: Row
  readonly maxCount: number
  readonly questionId: number
}

const ResultRow = React.forwardRef<HTMLUListElement, ResultRowProps>(
  (props, ref) => {
    const {
      questionId,
      row: { item, counts, mean },
      maxCount,
    } = props
    return (
      <ul ref={ref} className={styles.row}>
        <li className={styles.itemD} title={item}>
          {item}
        </li>
        {counts.map((count, i) => (
          <li className={styles.countD} key={i}>
            <div className={styles.countBar}>
              <div
                className={styles.countFill}
                style={fillStyle(count, maxCount)}
              />
            </div>
            <div
              className={classNames(
                styles.countNumber,
                count === 0 && styles.isEmpty
              )}
            >
              {count}
            </div>
          </li>
        ))}
        <li className={styles.meanD}>
          {mean === null ? "n/a" : mean.toFixed(2)}
        </li>
        <li className={styles.filterD}>
          <FilterDropdown
            optionCount={counts.length}
            answer={item}
            questionId={questionId}
          >
            {item}
          </FilterDropdown>
        </li>
      </ul>
    )
  }
)

ResultRow.displayName = "ResultRow"

export class RankingQuestionResults extends PureComponent<Props> {
  private getSummary = memoizeOnce(summarize)
  render() {
    const { question, answers } = this.props
    const { rows, maxCount } = this.getSummary(
      question.multiple_choice_options,
      answers
    )
    return (
      <Stack spacing={5}>
        <SectionResultsQuestionHeading />
        <SectionResultsQuestionText />
        <section className={styles.table}>
          <header>
            <ul className={styles.tableHeader}>
              <li className={styles.itemH}>Item</li>
              {times(rows.length, (i) => (
                <li className={styles.countH} key={i}>
                  {i + 1}
                </li>
              ))}
              <li className={styles.meanH}>Mean</li>
              <li className={styles.filterH} />
            </ul>
          </header>
          <section>
            <FlipMove duration={500}>
              {rows.map((row) => (
                <ResultRow
                  key={row.item}
                  questionId={question.id}
                  row={row}
                  maxCount={maxCount}
                />
              ))}
            </FlipMove>
          </section>
        </section>
      </Stack>
    )
  }
}
