import React, { FC } from "react"
import { Outlet } from "react-router"

import {
  Page,
  PageAside,
  PageContent,
  PageMain,
} from "Shared/components/Page/Page"
import { useScrollToTop } from "Shared/hooks/useScrollToTop"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"

import { LegalsSideNav } from "./LegalsSideNav"

export const LegalLayout: FC = () => {
  useScrollToTop()

  return (
    <Page>
      <UsabilityHubNavbar />
      <PageMain>
        <PageAside flexBasis={{ lg: "xs" }}>
          <LegalsSideNav />
        </PageAside>
        <PageContent maxW="5xl">
          <Outlet />
        </PageContent>
      </PageMain>
    </Page>
  )
}
