import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ReportIcon = createIcon({
  displayName: "ReportIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="report">
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 1.97005V14.5101C14.9946 14.7823 14.7723 15.0001 14.5 15.0001C14.3695 15.0008 14.2439 14.9506 14.15 14.86L12 12.7601H2C1.45 12.7601 1 12.33 1 11.7901V1.97005C1.01094 1.4272 1.45707 0.994461 2 1.00006H14C14.55 1.00006 15 1.44005 15 1.97005ZM8 8.00006C8.55229 8.00006 9 7.55235 9 7.00006V4.00006C9 3.44777 8.55229 3.00006 8 3.00006C7.44771 3.00006 7 3.44777 7 4.00006V7.00006C7 7.55235 7.44771 8.00006 8 8.00006ZM9 10.0001C9 10.5524 8.55229 11.0001 8 11.0001C7.44771 11.0001 7 10.5524 7 10.0001C7 9.44777 7.44771 9.00006 8 9.00006C8.55229 9.00006 9 9.44777 9 10.0001Z"
        fill="currentColor"
      />
    </g>
  ),
})
