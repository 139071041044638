import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useState,
} from "react"

import { ClickOccurrence } from "Components/test-results/utils/filter-node-clicks/filter-node-clicks-by-occurrence"

export type ClickOccurrenceFilterContextType = {
  clickOccurrences: ClickOccurrence[]
  availableClickOccurrences: ClickOccurrence[]
  setClickOccurrences: Dispatch<SetStateAction<ClickOccurrence[]>>
}

export const ClickOccurrenceFilterContext =
  createContext<ClickOccurrenceFilterContextType>({
    clickOccurrences: [],
    availableClickOccurrences: [],
    setClickOccurrences: () => null,
  })

const DEFAULT_AVAILABLE_CLICK_OCCURRENCES: ClickOccurrence[] = [
  0,
  1,
  2,
  3,
  "4+",
]

export function ClickOccurrenceFilterProvider({ children }: PropsWithChildren) {
  const [clickOccurrences, setClickOccurrences] = useState<ClickOccurrence[]>(
    DEFAULT_AVAILABLE_CLICK_OCCURRENCES
  )

  return (
    <ClickOccurrenceFilterContext.Provider
      value={{
        clickOccurrences,
        availableClickOccurrences: DEFAULT_AVAILABLE_CLICK_OCCURRENCES,
        setClickOccurrences,
      }}
    >
      {children}
    </ClickOccurrenceFilterContext.Provider>
  )
}
