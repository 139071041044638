import { useContext } from "react"
import { TestResultsFilterState } from "../context/test-result-filter-state"
import {
  createVariableFilter,
  createVariableFilterCategory,
} from "../filters/create-filters/variable-filter"
import { getFiltersWithoutCategory } from "../filters/get-filters/get-filters-without"

export const useUpdateVariableFilter = (
  variableName: string
): ((variableValues: string[]) => void) => {
  const { setTestResultsFilters } = useContext(TestResultsFilterState)

  return (variableValues: string[]) => {
    setTestResultsFilters((filters) => {
      const filtersWithoutVariables = getFiltersWithoutCategory(
        filters,
        createVariableFilterCategory(variableName)
      )

      const variableFilters = variableValues.map((value) => {
        return createVariableFilter(variableName, value)
      })

      return [...filtersWithoutVariables, ...variableFilters]
    })
  }
}
