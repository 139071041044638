import { Box, Center, Flex } from "@chakra-ui/react"
import { minBy } from "lodash"
import React from "react"

import { CardSortCard, CardSortCategoryCard } from "Types"

import { ScrollingWrapper } from "../ScrollingWrapper"

import { Cell } from "./Cell"

interface Props {
  cards: CardSortCard[]
  sortData: CardSortCategoryCard[]
}

export const SimilarityMatrix: React.FC<Props> = ({ cards, sortData }) => {
  const sortDataWithoutUnsorted = sortData.filter(
    (sd) =>
      sd.card_sort_category_id != null || sd.card_sort_open_category_id != null
  )
  const allResponseIdsInSortData = [
    ...new Set(sortData.map((sd) => sd.response_section_id)),
  ]

  const cardsSeenByResponse = new Map<number, number[]>(
    allResponseIdsInSortData.map((responseSectionId) => {
      const cardsShown = sortData
        .filter((sd) => sd.response_section_id === responseSectionId)
        .map((sd) => sd.card_sort_card_id)

      return [responseSectionId, cardsShown]
    })
  )

  // Precalculate a matrix of card pairings.  There will be one row per card and each row
  // will have the cardId followed by the number of times that card was sorted with each other card
  // (based on the order they appear in the `cards` array)
  const pairData = cards.map((card) => {
    const participantToCategoryMap = new Map(
      sortDataWithoutUnsorted
        .filter((sd) => sd.card_sort_card_id === card.id)
        .map((sd) => [
          sd.response_section_id,
          sd.card_sort_category_id
            ? `category_${sd.card_sort_category_id}`
            : sd.card_sort_open_category_id, // avoid some extreme cases where card_sort_category_id = card_sort_open_category_id
        ])
    )

    return [
      card.id!,
      ...cards.map((otherCard) => {
        const timesSortedTogether = sortDataWithoutUnsorted.filter(
          (sd) =>
            sd.card_sort_card_id === otherCard.id &&
            participantToCategoryMap.get(sd.response_section_id) ===
              (sd.card_sort_category_id
                ? `category_${sd.card_sort_category_id}`
                : sd.card_sort_open_category_id)
        ).length

        const timesSeenTogether = allResponseIdsInSortData.filter(
          (responseSectionId) =>
            cardsSeenByResponse.get(responseSectionId)?.includes(card.id!) &&
            cardsSeenByResponse.get(responseSectionId)?.includes(otherCard.id!)
        ).length

        return timesSeenTogether === 0
          ? 0
          : timesSortedTogether / timesSeenTogether
      }),
    ]
  })

  const [startingPair, ...unsortedPairData] = pairData
  const sortedPairData = [startingPair]

  // Loop over cards and insert them into the sortedPairData array to form clusters
  while (unsortedPairData.length) {
    const [, ...lastPair] = sortedPairData[sortedPairData.length - 1]
    // Find an unused card with minimum total distance from the last one we picked
    const closestPair = minBy(unsortedPairData, ([, ...pd]) =>
      lastPair.reduce((s, v, i) => s + Math.abs(v - pd[i]), 0)
    )!

    // Move the row from the `unsortedPairData` array to the `sortedPairData` array
    sortedPairData.push(closestPair)
    unsortedPairData.splice(
      unsortedPairData.findIndex((pd) => pd[0] === closestPair[0]),
      1
    )
  }

  return (
    <ScrollingWrapper mt={4}>
      <Flex direction="column" gap={0.5} mb={1} ml={1}>
        {sortedPairData.map(([cardId, ...matches], index) => {
          const card = cards.find((c) => c.id === cardId)!
          const relatedCards = sortedPairData.slice(0, index)

          return (
            <Flex key={card.id} gap={0.5} role="group" data-testid="matrix-row">
              {relatedCards.map(([relatedCardId], relatedIndex) => {
                const relatedCardOriginalIndex = cards.findIndex(
                  (c) => c.id === relatedCardId
                )
                const relatedCard = cards[relatedCardOriginalIndex]
                const similarityPercentage = matches[relatedCardOriginalIndex]

                return (
                  <Cell
                    key={relatedCard.id}
                    firstCard={relatedCard}
                    secondCard={card}
                    value={similarityPercentage}
                    data-column-index={relatedIndex}
                  />
                )
              })}

              <Center
                _groupHover={{ fontWeight: "bold" }}
                css={{
                  [`div:has(~ div [data-column-index='${index}']:hover) > &`]: {
                    fontWeight: "bold",
                  },
                }}
                ps={4}
                whiteSpace="nowrap"
              >
                <Box
                  display="inline-block"
                  css={{
                    ["&::after"]: {
                      display: "block",
                      content: `"${card.label}"`,
                      fontWeight: "bold",
                      height: 0,
                      overflow: "hidden",
                      visibility: "hidden",
                    },
                  }}
                  data-qa="similarity-card-label"
                >
                  {card.label}
                </Box>
              </Center>
            </Flex>
          )
        })}
      </Flex>
    </ScrollingWrapper>
  )
}
