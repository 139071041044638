import classNames from "classnames"
import React, { HTMLAttributes } from "react"

import styles from "Components/hitzone/hitzone.module.scss"
import { HITZONE_LEAVE_DURATION_MS } from "Constants/styles"
import { ClientIdRectangle } from "Types"
import { Omit } from "Types"
import { percentage } from "Utilities/css-helpers"

export const transitionClassNames = {
  exit: styles.exit,
  exitActive: styles.exitActive,
}

export const transitionTimeout = HITZONE_LEAVE_DURATION_MS

if (process.env.DEBUG && typeof transitionTimeout !== "number") {
  throw new TypeError(`transitionTimeout === ${String(transitionTimeout)}`)
}

export enum Theme {
  Hitzone = "hitzoneTheme",
  SelectionEditor = "selectionEditorTheme",
}

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, "style"> {
  hitzone: Readonly<ClientIdRectangle>
  isFiltered: boolean
  theme: Theme
}

export const Hitzone: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, className, hitzone, isFiltered, theme, ...rest } = props
  const { xa, xb, ya, yb } = hitzone

  return (
    <li
      className={classNames(
        styles.hitzone,
        className,
        isFiltered && styles.isFiltered,
        styles[theme]
      )}
      style={{
        left: percentage(xa),
        top: percentage(ya),
        right: percentage(1 - xb),
        bottom: percentage(1 - yb),
      }}
      {...rest}
    >
      {children}
    </li>
  )
}
