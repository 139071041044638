import { Box, FlexProps } from "@chakra-ui/react"
import { UsabilityHubNavbar } from "Components/exports"
import { SubscribeToProButton } from "Components/trial-widget/SubscribeToProButton"
import Constants from "Constants/shared.json"
import { Heading } from "DesignSystem/components"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import {
  DisabledPlanButton,
  ManualSubscription,
  NonAdmin,
} from "UsabilityHub/components/PlanChanger/disabled-plan-button"
import { PlanComparisonGridContainer } from "UsabilityHub/components/PlanChanger/plan-comparison-grid"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import { PlanHeaderContainer } from "UsabilityHub/components/PlanChanger/plan-header"
import {
  useCurrentAccount,
  useCurrentUser,
} from "UsabilityHub/hooks/useCurrentAccount"
import { usePlans } from "UsabilityHub/hooks/usePlans"
import { isFree, isPro } from "Utilities/plan"
import React, { useEffect } from "react"
import { useTrackEvent } from "~/api/generated/usabilityhub-components"

// This page will show all plans and features to allow the user to select a plan
export const NoPlanPage: React.FC<FlexProps> = (props) => {
  const { mutate: trackEvent } = useTrackEvent()
  const [showAnnual, setShowAnnual] = React.useState(false)
  const allPlans = usePlans()

  const currentUser = useCurrentUser()
  const isAdmin = currentUser.role === "admin"
  const currentAccount = useCurrentAccount()
  const isManual = currentAccount.manual_subscription
  const disabledVariant = isManual
    ? ManualSubscription
    : isAdmin
      ? null
      : NonAdmin

  useEffect(() => {
    if (allPlans === null) return

    trackEvent({
      body: {
        event: "pageview_trial_end_screen",
      },
    })
  }, [allPlans === null])

  if (!allPlans) return null

  const visiblePlans = allPlans.filter(
    (plan) =>
      !plan.discontinued &&
      plan.unique_id !== "enterprise" &&
      (plan.unique_id === Constants.FREE_PLAN_UNIQUE_ID ||
        plan.interval === (showAnnual ? "year" : "month"))
  )

  return (
    <Page title="Dashboard">
      <UsabilityHubNavbar isOnDashboard />
      <PageMain>
        <PageContent
          maxW="8xl"
          display="flex"
          flexDirection="column"
          gap={6}
          mt={6}
        >
          <Heading as="h1" textStyle="ds.display.emphasized" textAlign="center">
            Please choose a plan to continue using the app
          </Heading>
          <Box>
            <PlanComparisonGridContainer
              showAnnual={showAnnual}
              setShowAnnual={() => setShowAnnual(!showAnnual)}
              visiblePlans={visiblePlans}
              {...props}
            >
              {visiblePlans.map((plan, planIndex) => (
                <PlanHeaderContainer
                  key={`plan-header-${plan.unique_id}`}
                  plan={plan}
                  position={
                    planIndex === 0
                      ? "first"
                      : planIndex === visiblePlans.length - 1
                        ? "last"
                        : "middle"
                  }
                >
                  {disabledVariant === null ? (
                    isPro(plan) ? (
                      <SubscribeToProButton
                        width="full"
                        source="no_plan_dashboard"
                        buttonText="Continue on Pro"
                        plan={plan}
                      />
                    ) : (
                      <PlanDrawerTrigger
                        data-qa={`plan-button-${plan.name}`}
                        width="full"
                        variant="outline"
                        fontSize="sm"
                        fontWeight="medium"
                        initialState={
                          isFree(plan)
                            ? {
                                screen: "continue-on-free",
                              }
                            : {
                                screen: "payment-details",
                                selectedPlan: plan,
                              }
                        }
                        source="no_plan_dashboard"
                      >
                        {isFree(plan) ? "Switch" : "Subscribe"} to {plan.name}
                      </PlanDrawerTrigger>
                    )
                  ) : (
                    <DisabledPlanButton
                      variant={disabledVariant}
                      buttonText={`${
                        isFree(plan) ? "Switch" : "Subscribe"
                      } to ${plan.name}`}
                    />
                  )}
                </PlanHeaderContainer>
              ))}
            </PlanComparisonGridContainer>
          </Box>
        </PageContent>
      </PageMain>
    </Page>
  )
}
