import { countBy } from "lodash"

import { UsabilityTestSection, UsabilityTestSectionType } from "Types"

const hasAtLeastOneNonInformationSection = (
  sections: UsabilityTestSection[]
) => {
  const totalSectionCount = sections.length
  const sectionCountByType = countBy(sections, "type")
  const informationSectionCount =
    sectionCountByType[UsabilityTestSectionType.Information] || 0
  const nonInformationSectionCount = totalSectionCount - informationSectionCount
  return nonInformationSectionCount > 0
}

const hasAtLeastOneSection = (sections: UsabilityTestSection[]) =>
  sections.length !== 0

export const validateSections = (
  sections: UsabilityTestSection[]
): string | undefined => {
  if (!hasAtLeastOneSection(sections)) {
    return `You can${"\u2019"}t create a test without any sections in it`
  }
  if (!hasAtLeastOneNonInformationSection(sections)) {
    return "You need to add at least one non-instruction section"
  }
}
