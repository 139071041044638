import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { RecordingsSimplePreview } from "Components/test-results/section-results/SectionResultsCards/Recordings/RecordingsSimplePreview"
import React from "react"
import { QuestionsIndividualResults } from "../QuestionsIndividualResults"
import { NoResponse } from "../no-data"
import { TaskProps } from "./task-props"

export const LiveWebsiteTestIndividualResults = ({
  usabilityTestSection,
  responseSection,
}: TaskProps) => {
  if (responseSection === null) {
    return <NoResponse />
  }

  if (!usabilityTestSection.live_website_test) {
    throw new TypeError("Live website test not found")
  }

  const tasks = usabilityTestSection.live_website_test.live_website_test_tasks

  return (
    <Flex gap={4} flexFlow="column">
      {/* Recordings exist per section at the moment, but will be per-task in future. */}
      <RecordingsSimplePreview responseSection={responseSection} />

      {tasks.map((task, taskIndex) => {
        // Find IDs of all questions attached to this task so we can filter the QuestionResultsList to just those answers.
        const questionIdAllowlist = task.live_website_test_task_questions.map(
          (q) => q.usability_test_section_question_id
        )

        return (
          <Flex direction="column" gap={4} key={task.id}>
            <Box height="1px" bg="gray.200" mx={-8} />

            <Heading as="h3" mt={4}>
              Task {taskIndex + 1}
            </Heading>

            <Text mb={4}>{task.instructions}</Text>

            <Box height="1px" bg="gray.200" mx={-8} />

            <QuestionsIndividualResults
              key={task.id}
              responseId={responseSection.response_id}
              questionIdAllowlist={questionIdAllowlist}
            />
          </Flex>
        )
      })}
    </Flex>
  )
}
