import { useContext } from "react"

import { TestResultsFilterState } from "../context/test-result-filter-state"
import { createSourceFilter } from "../filters/create-filters/source-filter"
import { getFiltersWithoutCategory } from "../filters/get-filters/get-filters-without"

export const useUpdateSourcesFilter = (): ((sources: string[]) => void) => {
  const { setTestResultsFilters } = useContext(TestResultsFilterState)

  return (sources: string[]) => {
    setTestResultsFilters((filters) => {
      const filtersWithoutSourceFilters = getFiltersWithoutCategory(
        filters,
        "response/attribute:source"
      )

      const sourceFilters = sources.map(createSourceFilter)

      return [...filtersWithoutSourceFilters, ...sourceFilters]
    })
  }
}
