import { CommonFigmaTaskPath, FigmaImage, FigmaTaskScreenResult } from "Types"

import { getAggregatedScreenResultsByScreenResults } from "./aggregate-screen-results/get-aggregated-screen-results-by-screen-results"
import { getScreenResultsFromTasks } from "./get-screen-results-from-tasks"

export function getScreenResultsFromCommonPath(
  path: CommonFigmaTaskPath,
  figmaImages: FigmaImage[]
): FigmaTaskScreenResult[] {
  const screenResults = getScreenResultsFromTasks(path.tasks, figmaImages)

  return getAggregatedScreenResultsByScreenResults(screenResults)
}
