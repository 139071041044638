import classNames from "classnames"
import React from "react"

import { CarouselControls } from "Components/preference-test-layout/carousel-controls"
import styles from "Components/preference-test-layout/preference-carousel.module.scss"
import {
  DeviceFrame,
  Dimensions,
  UsabilityTestSection,
  UsabilityTestSectionScreenshot,
} from "Types"
import ConditionalScreenshotDeviceFrame from "UsabilityHub/components/UsabilityTestSectionTask/conditional-screenshot-device-frame"

export interface PreferenceCarouselProps {
  isReadonly: boolean
  sectionScreenshot: UsabilityTestSectionScreenshot
  selectedIndex: number
  deviceFrame: DeviceFrame | null
  onConfirm: () => void
  onNext: () => void
  onPrevious: () => void
  optionCount: number
  section: UsabilityTestSection
  maxPlayableSize: Dimensions
}

export const PreferenceCarousel: React.FC<
  React.PropsWithChildren<PreferenceCarouselProps>
> = ({
  deviceFrame,
  isReadonly,
  onConfirm,
  onNext,
  onPrevious,
  optionCount,
  sectionScreenshot,
  selectedIndex,
  section,
  maxPlayableSize,
}) => {
  const className = classNames(styles.carouselContainer, {
    [styles.readonly]: isReadonly,
  })
  return (
    <div className={className}>
      {!isReadonly && (
        <CarouselControls
          onNext={onNext}
          onPrevious={onPrevious}
          onConfirm={onConfirm}
          selectedIndex={selectedIndex}
          optionCount={optionCount}
          section={section}
        />
      )}
      <ConditionalScreenshotDeviceFrame
        screenshotId={sectionScreenshot.screenshot_id}
        deviceFrame={deviceFrame}
        maxPlayableSize={maxPlayableSize}
        canSkipAheadOnFirstWatch={false}
      />
    </div>
  )
}
