import { TestResultsFilterState } from "Components/test-results/context/test-result-filter-state"
import { createTreeTestFilter } from "Components/test-results/filters/create-filters/tree-test-filter"
import { getFilterUtilityFunctions } from "Components/test-results/filters/get-filter-utility-functions"
import { useCallback, useContext } from "react"

const { isFilter, isFiltered, addFilter, removeFilter, toggleFilter } =
  getFilterUtilityFunctions(createTreeTestFilter)

export function useTreeTestFilter(
  sectionId: number,
  finalNodeId: string | null
) {
  const { testResultsFilters, setTestResultsFilters } = useContext(
    TestResultsFilterState
  )

  const handleAddTreeTestFilter = () => {
    setTestResultsFilters((filters) =>
      addFilter(filters, sectionId, finalNodeId)
    )
  }

  const handleRemoveTreeTestFilter = () => {
    setTestResultsFilters((filters) =>
      removeFilter(filters, sectionId, finalNodeId)
    )
  }

  const handleToggleFilter = useCallback(() => {
    setTestResultsFilters((filters) =>
      toggleFilter(filters, sectionId, finalNodeId)
    )
  }, [])

  return {
    isFiltered: isFiltered(testResultsFilters, sectionId, finalNodeId),
    isFilter: isFilter(testResultsFilters, sectionId, finalNodeId),
    addTreeTestFilter: handleAddTreeTestFilter,
    removeTreeTestFilter: handleRemoveTreeTestFilter,
    toggleTreeTestFilter: handleToggleFilter,
  }
}
