import React, { PropsWithChildren, createContext, useContext } from "react"

import { DeviceFrame, UsabilityTestSection } from "Types"

type DeviceFramesContextValue = {
  deviceFrames: DeviceFrame[] | null
}

const DeviceFramesContext = createContext<DeviceFramesContextValue>({
  deviceFrames: null,
})

export const useDeviceFramesContext = (): DeviceFrame[] => {
  const context = useContext(DeviceFramesContext)

  if (!context?.deviceFrames) {
    throw new Error(
      "useDeviceFramesContext must be used inside the DeviceFramesProvider"
    )
  }

  return context.deviceFrames
}

export const useDeviceFramesForSectionContext = (
  section: UsabilityTestSection
): DeviceFrame | null =>
  useDeviceFramesContext().find((d) => d.id === section.device_frame_id) || null

interface Props {
  deviceFrames: DeviceFrame[]
}

export const DeviceFramesProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  deviceFrames,
}) => {
  return (
    <DeviceFramesContext.Provider value={{ deviceFrames }}>
      {children}
    </DeviceFramesContext.Provider>
  )
}
