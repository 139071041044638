import {
  AbsoluteCenter,
  Box,
  Center,
  HStack,
  Icon,
  ResponsiveValue,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react"
import { QUERY_PATH } from "Components/test-results/context/test-result-view-state"
import { useIndividualResponseFilter } from "Components/test-results/hooks/use-individual-response-filter"
import { usePrefersReducedMotion } from "Components/trial-widget/usePrefersReducedMotion"
import { IconButton } from "DesignSystem/components"
import { ResponseSectionRecording } from "JavaScripts/types/recording"
import { SpinnerDynamicIcon } from "Shared/icons/SpinnerDynamicIcon"
import { SpinnerStaticIcon } from "Shared/icons/SpinnerStaticIcon"
import { UserResponseIcon } from "Shared/icons/UserResponseIcon"
import { PlaySolidIcon } from "Shared/icons/untitled-ui/PlaySolidIcon"
import { Share07OutlineIcon } from "Shared/icons/untitled-ui/Share07OutlineIcon"
import { getQueryStringPath } from "Utilities/query-string"
import { formatPreciseDuration } from "Utilities/time"
import { debounce } from "lodash"
import React, { useEffect, useRef, useState } from "react"
// @ts-ignore
import AudioSpectrum from "react-audio-spectrum"

type Props = {
  recording: ResponseSectionRecording
  bottomBorder?: boolean
  isParticipantView?: boolean // This is used in RecordingContainer
  hideParticipantButton?: boolean // This is used in RecordingContainerOld and will be removed once we remove the feature flag - new_recordings_results_ui
  hideShareButton?: boolean
  roundedCorners?: boolean
  setHasRecordingError?: (hasError: boolean) => void
  height?: ResponsiveValue<string | number | (string & {})> | undefined
}

export const RecordingPlayer: React.FC<Props> = ({
  recording,
  bottomBorder = false,
  isParticipantView = false,
  hideParticipantButton = false,
  hideShareButton = false,
  height = "150px",
  roundedCorners = true,
  setHasRecordingError = () => {},
}) => {
  const toast = useToast()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [videoDuration, setVideoDuration] = useState<
    string | null | undefined
  >()

  useEffect(() => {
    if (recording.status === "failed") {
      setVideoDuration(null)
    }
  }, [recording.status])

  const { setResponseId } = useIndividualResponseFilter()

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      const duration = formatPreciseDuration(videoRef.current.duration * 1000)

      setVideoDuration(duration)
    }
  }

  const handleRecordingError = () => {
    setHasRecordingError(true)
    setVideoDuration(null)
    setIsPlaying(false)
  }

  const onShare = async (responseId: number, responseSectionId: number) => {
    const url = getShareUrl(responseId, responseSectionId)
    await navigator.clipboard.writeText(url.toString())

    toast({
      title: "Link copied to clipboard",
      status: "success",
    })
  }

  // This id of the HTML video tag must be unique for each recording at each spot to avoid conflicts within the audio spectrum component
  const videoTagId =
    `${recording.id}` + (isParticipantView ? "-in-drawer" : "-in-tab")

  const debouncedSetIsPlaying = debounce(setIsPlaying, 100)

  const prefersReducedMotion = usePrefersReducedMotion()

  const participantButton = !hideParticipantButton && (
    <IconButton
      aria-label="View participant"
      icon={<UserResponseIcon />}
      size="compact"
      bgColor="ds.background.input.resting"
      _hover={{ bgColor: "ds.background.input.hovered" }}
      onClick={() => setResponseId(recording.responseSection.response_id)}
    />
  )
  const shareButton = !hideShareButton && (
    <IconButton
      aria-label="Share"
      icon={<Share07OutlineIcon />}
      size="compact"
      bgColor="ds.background.input.resting"
      _hover={{ bgColor: "ds.background.input.hovered" }}
      onClick={() =>
        onShare(
          recording.responseSection.response_id,
          recording.responseSection.id
        )
      }
    />
  )
  const buttonStack = (
    <HStack
      position="absolute"
      display="none"
      _groupHover={{ display: "flex" }}
      right={0}
      m={2}
      spacing={1}
    >
      {participantButton}
      {shareButton}
    </HStack>
  )

  const ProcessingView = (
    <Stack w="full" position="relative" role="group">
      <Center h={height} bgColor="ds.surface.sunken" borderTopRadius="md">
        <Icon
          as={prefersReducedMotion ? SpinnerStaticIcon : SpinnerDynamicIcon}
          boxSize={14}
          color="ds.icon.disabled"
        />
      </Center>
      {buttonStack}
    </Stack>
  )

  const RecordingView = (
    <Stack
      w="full"
      h="full"
      position="relative"
      role="group"
      sx={{
        "> canvas": {
          position: "absolute",
          width: "100%",
          height: "60%",
          display: isPlaying ? "block" : "none",
        },
      }}
    >
      <Box
        id={videoTagId}
        ref={videoRef}
        as="video"
        height={height}
        objectFit="contain"
        bgColor="ds.text.default"
        controls={isPlaying}
        preload="metadata"
        src={recording.url!}
        borderTopRadius={roundedCorners ? "md" : 0}
        borderBottomRadius={bottomBorder && roundedCorners ? "md" : 0}
        crossOrigin="anonymous"
        onPlay={() => debouncedSetIsPlaying(true)}
        onPause={() => debouncedSetIsPlaying(false)}
        onSeeking={() => debouncedSetIsPlaying(true)}
        onError={handleRecordingError}
        onLoadedMetadata={handleLoadedMetadata}
      />
      {videoDuration === undefined && (
        <AbsoluteCenter>
          <Spinner
            color="ds.text.subtle"
            size="xl"
            thickness="4px"
            speed="0.65s"
            animation={prefersReducedMotion ? "none" : undefined}
          />
        </AbsoluteCenter>
      )}
      {!isPlaying && videoDuration && (
        <Box
          w="full"
          height={height}
          position="absolute"
          display="none"
          bgGradient="linear-gradient(180deg, rgba(99, 99, 99, 0) 0%, rgba(0, 0, 0, 0.39) 34.38%, #000000 100%)"
          _groupHover={{ display: "block" }}
          borderRadius={roundedCorners ? "md" : 0}
        >
          <AbsoluteCenter>
            <Icon
              as={PlaySolidIcon}
              color="ds.background.input.resting"
              boxSize={10}
              onClick={() => videoRef.current?.play()}
              cursor="pointer"
            />
          </AbsoluteCenter>
        </Box>
      )}
      {!isPlaying && videoDuration !== undefined && buttonStack}
      {!isPlaying && videoDuration !== undefined && (
        <Box
          position="absolute"
          bottom={0}
          right={0}
          bgColor="ds.surface.backdrop"
          fontSize="xs"
          color="ds.background.input.resting"
          px={1}
          borderRadius="30px"
          m={2}
        >
          {videoDuration ? videoDuration : "0 sec"}
        </Box>
      )}
      {recording.isAudio && (
        <AudioSpectrum
          id={`audio-canvas-${videoTagId}`}
          height={90}
          // The audioId should be the same ID as the video element above
          audioId={videoTagId}
          capColor={"red"}
          meterColor={[
            { stop: 0, color: "#f00" },
            { stop: 0.5, color: "#0CD7FD" },
            { stop: 1, color: "red" },
          ]}
          gap={4}
        />
      )}
    </Stack>
  )

  return recording.status === "processed" || recording.status === "failed"
    ? RecordingView
    : ProcessingView
}

export const getShareUrl = (responseId: number, responseSectionId: number) => {
  const newSearch = getQueryStringPath(QUERY_PATH, {
    individualResponseView: responseId,
    recordingResponseSectionId: responseSectionId,
  })
  const url = new URL(window.location.href)
  url.search = newSearch
  return url.toString()
}
