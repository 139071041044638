import { useToast } from "@chakra-ui/react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { updateFigmaFileVersion } from "Redux/reducers/figma-file-versions/actions"
import { addFigmaFileVersion } from "Redux/reducers/figma-file-versions/actions"
import { getFigmaFileVersions } from "Redux/reducers/figma-file-versions/selectors"
import { addSectionPrototype as defaultAddSectionPrototype } from "Redux/reducers/test-builder-form/action-creators/prototypes"
import { getSections } from "Redux/reducers/test-builder-form/selectors/sections"
import { isAxiosErrorWithMessage } from "Services/axios"
import { FigmaFileVersion, UsabilityTestSection } from "Types"
import { ConfirmFigmaFileVersionOverwriteModal } from "UsabilityHub/components/ConfirmFigmaFileVersionOverwriteModal/ConfirmFigmaFileVersionOverwriteModal"
import { useModal } from "Utilities/modals/use-modal"
import { reportErrorToSentry } from "~/application/javascripts/utilities/error"
import { isFigmaFileVersionViewable as defaultIsFigmaFileVersionViewable } from "~/application/javascripts/utilities/figma-file-version"

import { useSyncFigma } from "../SyncFigma/useSyncFigma"

import { DEFAULT_SCALING_SETTING, SCALING_SETTINGS } from "./utils"

export const useImportFigmaFlow = (
  sectionIndex: number,
  isFigmaFileVersionViewable = defaultIsFigmaFileVersionViewable,
  addSectionPrototype = defaultAddSectionPrototype
) => {
  const dispatch: Dispatch = useDispatch()
  const [isImporting, setIsImporting] = useState(false)

  const syncFigma = useSyncFigma()

  const figmaFileVersions = useSelector(getFigmaFileVersions)
  const sections = useSelector(getSections)
  const { open: openConfirmModal, close } = useModal(
    ConfirmFigmaFileVersionOverwriteModal
  )
  const closeConfirmModal = () => {
    setIsImporting(false)
    close()
  }
  const toast = useToast()

  const onResponse = async (
    figmaFileVersion: FigmaFileVersion,
    startNodeId: string,
    scaling: string
  ) => {
    try {
      const addSection = () => {
        dispatch(
          addSectionPrototype(
            sectionIndex,
            figmaFileVersion,
            startNodeId,
            scaling
          )
        )
      }

      // Check FigmaFileVersion is visible
      await isFigmaFileVersionViewable(figmaFileVersion, true)

      // Check the scaling value on Submit
      // If it's an unknow scaling from the Figma URL
      // Log it into Sentry and use the default scaling setting instead
      if (!SCALING_SETTINGS.includes(scaling)) {
        reportErrorToSentry(
          new TypeError(
            `An undefined scaling setting "${scaling}" extracted from the Figma URL. Please check and add it if required!`
          )
        )
        scaling = DEFAULT_SCALING_SETTING
      }

      // Check for an existing figma file version with the same figma file key
      // and an older id
      const oldFfv = figmaFileVersions?.find(
        ({ figma_file_key, id }) =>
          figmaFileVersion.figma_file_key === figma_file_key &&
          figmaFileVersion.id > id
      )

      if (oldFfv !== undefined) {
        const hasOldFfv = (section: UsabilityTestSection) =>
          section.figma_file_flow?.figma_file_version_id === oldFfv.id
        const sectionsContainingOldFfvCount = sections.filter((section) =>
          hasOldFfv(section)
        ).length

        // If more than one section points to the old figma file version, require
        // the user to confirm the update
        if (sectionsContainingOldFfvCount > 1) {
          openConfirmModal({
            newFigmaFileVersion: figmaFileVersion,
            oldFigmaFileVersion: oldFfv,
            handleClose: closeConfirmModal,
            afterConfirm: addSection,
          })
        } else {
          // Only this section, update it in Redux
          dispatch(updateFigmaFileVersion(oldFfv, figmaFileVersion))
          addSection()
        }
      } else {
        // Add the figma file version
        dispatch(addFigmaFileVersion(figmaFileVersion))
        addSection()
      }

      setIsImporting(false)
    } catch (error) {
      if (error === "Prototype not viewable") {
        toast({
          title: "Prototype settings need changing",
          description: `Share settings in Figma must be set to "Anyone with this link" "can view"`,
          status: "error",
          duration: null,
        })
      } else {
        const errorMessage = isAxiosErrorWithMessage(error)
          ? error.response.data.message
          : "Sorry, we were unable to sync your prototype. Please check your link is correct and permissions have been correctly configured in Figma, and try again."

        toast({
          title: errorMessage,
          status: "error",
        })
      }
      setIsImporting(false)
    }
  }

  const importFigmaFlow = (
    figmaFileKey: string,
    startNodeId: string,
    scaling: string
  ) => {
    setIsImporting(true)

    syncFigma(
      figmaFileKey,
      startNodeId,
      (figmaFileVersion: FigmaFileVersion) => {
        void onResponse(figmaFileVersion, startNodeId, scaling)
      },
      () => {
        setIsImporting(false)
      }
    )
  }

  return { isImporting, importFigmaFlow }
}
