import * as Yup from "yup"
import { Booking } from "./types"

export type LocationType = Booking["location_type"]

export const BookingSchema = Yup.object().shape(
  {
    incentive: Yup.string()
      .max(255, "Incentive text is too long")
      .optional()
      .defined(),
    location: Yup.string()
      .url("Please enter a valid meeting link")
      .optional()
      .defined(),
    location_type: Yup.string<LocationType>().defined(),
    auto_upload_recordings: Yup.boolean(),
    hosts: Yup.array()
      .of(
        Yup.object().shape({
          userId: Yup.number().required("Host ID is required"),
        })
      )
      .required()
      .min(1),
  },
  [["location", "location"]]
)

export type BookingForm = Yup.InferType<typeof BookingSchema>
