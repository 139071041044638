import { DndContextProps } from "@dnd-kit/core"
import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

import {
  Props as ImplProps,
  ReorderableList,
} from "Components/form/reorderable-list"
import { Omit } from "Types"

type AdapterProps = Omit<ImplProps, "onChange" | "value"> & WrappedFieldProps
function ReorderableListFieldAdapter(props: Readonly<AdapterProps>) {
  const {
    input,
    meta, // unused
    ...rest
  } = props
  return (
    <ReorderableList
      {...rest}
      value={input.value || []}
      onChange={input.onChange}
    />
  )
}

interface Props {
  name: string
}

export function ReorderableListField({
  name,
  ...rest
}: Readonly<Props & Partial<Pick<DndContextProps, "modifiers">>>) {
  return <Field component={ReorderableListFieldAdapter} name={name} {...rest} />
}
