import { Consumer, Subscription } from "@rails/actioncable"
import { AppContext } from "Shared/contexts/AppContext"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useContext, useEffect, useRef } from "react"
import { Comment } from "~/api/generated/usabilityhubSchemas"
import { useTestBuilderCommentCache } from "./useTestBuilderCommentCache"

type Message = {
  action: "comment_added" | "comment_updated" | "comment_deleted"
  data: {
    comment: Comment
  }
}

export const useTestBuilderCommentLiveUpdates = (
  usabilityTestId: number | null
) => {
  const { addComment, updateComment, deleteComment } =
    useTestBuilderCommentCache()
  const currentUser = useCurrentUser()
  const { consumer } = useContext(AppContext)
  const channel = useRef<Subscription<Consumer>>()
  const unsubscribe = () => {
    if (channel.current) {
      channel.current.unsubscribe()
      channel.current = undefined
    }
  }

  useEffect(() => {
    // We don't want to turn it on for unsaved tests since there's nothing useful to sync.
    if (usabilityTestId === null) return

    channel.current = consumer?.subscriptions.create(
      {
        channel: "UsabilityTestCommentsChannel",
        usability_test_id: usabilityTestId,
        context: "test_builder",
      },
      {
        received: (message: Message) => {
          // Ignore events for the current user since we've already applied them locally
          if (message.data.comment.user?.id === String(currentUser?.id)) return

          if (message.action === "comment_added") {
            addComment(message.data.comment)
          } else if (message.action === "comment_updated") {
            updateComment(message.data.comment)
          } else if (message.action === "comment_deleted") {
            deleteComment(message.data.comment)
          }
        },
      }
    )
    return () => {
      unsubscribe()
    }
  }, [usabilityTestId])
}
