import { ROUTES } from "UsabilityHub/views/routes"
import { debounce } from "lodash"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import {
  CalculatePriceAndEstimatedDurationResponse,
  CalculatePriceAndEstimatedDurationVariables,
  useCalculatePriceAndEstimatedDuration,
} from "~/api/generated/usabilityhub-components"
import { useOrderForm } from "../OrderFormProvider"
import { DEFAULT_AGE_RANGE, QUOTE_DEBOUNCE_MS } from "../constants"
import { QuoteState, TargetLocation } from "../types"

// This hook maintains local state of the latest panel order quote. It takes the demographic data
// from the OrderFormContext and will re-call the API any time they change — but no more often then
// QUOTE_DEBOUNCE_MS.
export const useLyssnaPanelOrderQuote = () => {
  const { testId: usabilityTestUniqueId } = useTypedParams(ROUTES.TEST.RECRUIT)
  const {
    testHasScreener,
    numParticipants,
    selectedOptionIds,
    ageRange,
    targetLocations,
    estimatedIncidenceRate,
  } = useOrderForm()

  const [quoteState, setQuoteState] = useState<QuoteState>("idle")
  const [latestQuote, setLatestQuote] =
    useState<CalculatePriceAndEstimatedDurationResponse | null>(null)
  const totalDemographicChanges = useRef<number>(0)

  const { mutateAsync: calculateEstimate } =
    useCalculatePriceAndEstimatedDuration()

  const debouncedQuote = useMemo(
    () =>
      debounce(
        async (
          testId: string,
          numParticipants: number,
          demographicOptions: number[],
          minAge: number,
          maxAge: number,
          targetLocations: TargetLocation[],
          hasScreener: boolean,
          estimatedIncidenceRate: number | null
        ) => {
          const demographicChanges = totalDemographicChanges.current

          try {
            const variables: CalculatePriceAndEstimatedDurationVariables = {
              queryParams: {
                usability_test_id: testId,
                demographic_attribute_option_ids: demographicOptions.join(","),
                requested_response_count: numParticipants,
              },
              body: {
                target_locations: targetLocations,
              },
            }
            if (minAge !== DEFAULT_AGE_RANGE[0])
              variables.queryParams.min_age = minAge
            if (maxAge !== DEFAULT_AGE_RANGE[1])
              variables.queryParams.max_age = maxAge
            if (hasScreener && estimatedIncidenceRate === null) {
              // Can't quote with a screener and no incidence rate
              return
            } else if (hasScreener && estimatedIncidenceRate) {
              variables.queryParams.estimated_incidence_rate =
                estimatedIncidenceRate
            }
            setQuoteState("loading")

            const quote = await calculateEstimate(variables)

            // Ignore result if it's not the most recent API call
            if (demographicChanges !== totalDemographicChanges.current) {
              return
            }

            setLatestQuote(quote)
            setQuoteState(quote.estimated_duration ? "success" : "unavailable")
          } catch (error) {
            setLatestQuote(null)
            setQuoteState("error")
          }
        },
        QUOTE_DEBOUNCE_MS
      ),
    []
  )

  useEffect(() => {
    ++totalDemographicChanges.current

    void debouncedQuote(
      usabilityTestUniqueId,
      numParticipants,
      selectedOptionIds,
      ageRange[0],
      ageRange[1],
      targetLocations,
      testHasScreener,
      estimatedIncidenceRate
    )
  }, [
    usabilityTestUniqueId,
    numParticipants,
    selectedOptionIds.join("-"),
    ageRange[0],
    ageRange[1],
    JSON.stringify(targetLocations),
    testHasScreener,
    estimatedIncidenceRate,
  ])

  return { latestQuote, quoteState }
}
