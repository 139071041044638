import { Text, TextProps, Tooltip } from "@chakra-ui/react"
import { differenceInMinutes, formatDistanceToNow } from "date-fns/esm"
import React, { useEffect, useState } from "react"

type Props = {
  date: string
} & TextProps

const REFRESH_INTERVAL_MS = 30_000 // 30sec

export const AutoUpdatingRelativeTime: React.FC<Props> = ({
  date,
  ...props
}) => {
  const [relativeTime, setRelativeTime] = useState(
    recalculateRelativeTime(date)
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setRelativeTime(recalculateRelativeTime(date))
    }, REFRESH_INTERVAL_MS)

    return () => clearInterval(interval)
  })

  useEffect(() => {
    setRelativeTime(recalculateRelativeTime(date))
  }, [date])

  return (
    <Tooltip
      hasArrow
      placement="top"
      label={new Date(date).toLocaleString(navigator.language, {
        dateStyle: "medium",
        timeStyle: "short",
      })}
    >
      <Text {...props}>{relativeTime}</Text>
    </Tooltip>
  )
}

const recalculateRelativeTime = (date: string) => {
  const commentDate = new Date(date)
  const now = new Date()

  // Show a general message for anything less than one minute
  if (differenceInMinutes(now, commentDate) < 1) {
    return "Just now"
  } else {
    return formatDistanceToNow(commentDate, { addSuffix: true }).replace(
      "about ",
      ""
    )
  }
}
