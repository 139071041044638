import { Filter } from "Components/test-results/types/filters"

import { getFilterMeta } from "../get-filter-meta"

import { FilterFunction } from "./types"

export type PreferenceAnswerFilter = Filter<
  `answer/section:${number}/preference`,
  "answer/preference",
  number
>

export const filterResponseByPreferenceAnswer: FilterFunction<
  PreferenceAnswerFilter
> = ({ filter, filteredRelatedData: { responseSections } }) => {
  const { sectionId } = getFilterMeta(filter.category)

  const responseSection = responseSections.find(
    (rs) => rs.usability_test_section_id === sectionId
  )

  if (responseSection === undefined) {
    return false
  }

  const selectedId =
    responseSection.selected_usability_test_section_screenshot_id

  if (selectedId === null) {
    // This actually shouldn't happen even, but just filter it anyway.
    return false
  }

  // There are filters defined, but this response didn't satisfy them. Rejected.
  return filter.value === selectedId
}
