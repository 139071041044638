import { countBy } from "lodash"

import { Screenshot, UsabilityTest, UsabilityTestSectionQuestion } from "Types"
import { isTestLogicEnabled } from "Utilities/test-logic-statement"
import { isMultipleChoice } from "Utilities/usability-test-section-question"

import { validateTestLogicStatement } from "./validateTestLogicStatement"

export const validateQuestion = (
  question: UsabilityTestSectionQuestion,
  test: UsabilityTest,
  screenshots: readonly Screenshot[]
): Record<string, unknown> => ({
  multiple_choice_options: isMultipleChoice(question.type)
    ? validateMultipleChoiceOptions(question.multiple_choice_options)
    : undefined,
  test_logic_statement: isTestLogicEnabled(question.test_logic_statement)
    ? validateTestLogicStatement(
        question.test_logic_statement,
        question._clientId,
        test,
        screenshots
      )
    : undefined,
})

// ts-prune-ignore-next used in test
export const validateMultipleChoiceOptions = (
  options: readonly string[]
): (string | undefined)[] => {
  const optionCounts = countBy(options)
  return options.map((option) => {
    if (option !== "") {
      if (optionCounts[option] > 1) {
        return "Duplicate option"
      }
    }
  })
}
