import * as Actions from "Redux/reducers/test-builder/action-types"
import { Type } from "Redux/reducers/test-builder/constants"
import {
  QuestionDeletion,
  SectionDeletion,
  SectionScreenshotDeletion,
} from "Redux/reducers/test-builder/types"
import {
  NoopAction,
  Omit,
  RawUsabilityTest,
  ResponseDurationRange,
  UsabilityTest,
} from "Types"

import { EntityIdentifier } from "../../../components/comment-thread/entities"

import { UndoableOperation } from "./action-creators/undo"

type TestEstimations = {
  isLoading: boolean
  error?: string
  isValid: boolean
  creditCount: number | null
  estimatedDurationRange: Readonly<ResponseDurationRange> | null
}

export interface DefinedRawTestBuilderState {
  testSetId: number | null
  creditCount: number | null
  estimatedDurationRange: Readonly<ResponseDurationRange> | null
  hasActiveOrders: boolean
  initialValues: Readonly<RawUsabilityTest>
  responsesCount: number
  uuid: string
}

export type RawTestBuilderState = undefined | DefinedRawTestBuilderState

// This represents a comment we've created locally but not yet saved because it relates
// to part of the test that hasn't been persisted yet.
export type ClientCommentFragment = {
  entity: EntityIdentifier
  comment_id: string
  content: string
}

export type TestBuilderState = null | Readonly<
  Omit<
    DefinedRawTestBuilderState,
    "initialValues" | "creditCount" | "estimatedDurationRange"
  > & {
    estimations: TestEstimations
    isPreviewClicked: boolean
    questionDeletions: ReadonlyArray<Readonly<QuestionDeletion>>
    sectionDeletions: ReadonlyArray<Readonly<SectionDeletion>>
    sectionScreenshotDeletions: ReadonlyArray<
      Readonly<SectionScreenshotDeletion>
    >
    initialValues: Readonly<UsabilityTest>
    comments: ClientCommentFragment[]
    /** The test set to save a new test into  */
    testSetId: number | null
    // Information about the last destructive action so we can undo it
    lastOperation: UndoableOperation | null
  }
>

type AllActions =
  | Actions.InitUsabilityTestAction
  | Actions.SaveTestFormRequestAction
  | Actions.SaveTestFormSuccessAction
  | Actions.SaveTestFormFailureAction
  | Actions.SetIsPreviewClickedAction
  | Actions.SetIsEstimateValidAction
  | Actions.SaveDeletedQuestionAction
  | Actions.SaveDeletedSectionAction
  | Actions.SaveDeletedSectionScreenshotAction
  | Actions.ClearDeletedQuestionAction
  | Actions.ClearDeletedSectionAction
  | Actions.ClearDeletedSectionScreenshotAction
  | Actions.UpdateCreditsAndDurationFailureAction
  | Actions.UpdateCreditsAndDurationRequestAction
  | Actions.UpdateCreditsAndDurationSuccessAction
  | Actions.AddLocalCommentAction
  | Actions.EditLocalCommentAction
  | Actions.DeleteLocalCommentAction
  | Actions.SaveUndoCheckpointAction
  | Actions.ClearUndoCheckpointAction
  | NoopAction

export default function reducer(
  state: TestBuilderState = null,
  action: AllActions = { type: "__NOOP__" }
): TestBuilderState {
  switch (action.type) {
    case Type.INIT_USABILITY_TEST:
      return action.payload
  }

  if (state === null) return state

  switch (action.type) {
    case Type.SET_TEST_FORM_IS_PREVIEW_CLICKED:
      return { ...state, isPreviewClicked: action.payload }
    case Type.SET_TEST_FORM_IS_ESTIMATE_VALID:
      return {
        ...state,
        estimations: { ...state.estimations, isValid: action.payload },
      }
    case Type.SAVE_TEST_FORM_SUCCESS:
      return {
        ...state,
        initialValues: action.payload,
        questionDeletions: [],
        sectionScreenshotDeletions: [],
        sectionDeletions: [],
      }
    case Type.UPDATE_TEST_FORM_CREDITS_AND_DURATION_REQUEST: {
      return {
        ...state,
        estimations: {
          ...state.estimations,
          isLoading: true,
          isValid: true,
        },
      }
    }
    case Type.UPDATE_TEST_FORM_CREDITS_AND_DURATION_SUCCESS: {
      const {
        payload: { creditCount, estimatedDurationRange },
      } = action
      return {
        ...state,
        estimations: {
          isLoading: false,
          isValid: true,
          creditCount,
          estimatedDurationRange,
        },
      }
    }
    case Type.UPDATE_TEST_FORM_CREDITS_AND_DURATION_FAILURE: {
      return {
        ...state,
        estimations: {
          ...state.estimations,
          isLoading: false,
          error: "Something went wrong",
        },
      }
    }
    case Type.SAVE_DELETED_TEST_FORM_SECTION: {
      const { sectionDeletions } = state
      return {
        ...state,
        sectionDeletions: [...sectionDeletions, action.payload],
      }
    }
    case Type.SAVE_DELETED_TEST_FORM_QUESTION: {
      const { questionDeletions } = state
      return {
        ...state,
        questionDeletions: [...questionDeletions, action.payload],
      }
    }
    case Type.SAVE_DELETED_TEST_FORM_SECTION_SCREENSHOT: {
      const { sectionScreenshotDeletions } = state
      return {
        ...state,
        sectionScreenshotDeletions: [
          ...sectionScreenshotDeletions,
          action.payload,
        ],
      }
    }
    case Type.CLEAR_DELETED_TEST_FORM_QUESTION: {
      const { questionDeletions } = state
      return {
        ...state,
        questionDeletions: questionDeletions.filter(
          (questionDeletion) =>
            questionDeletion.sectionId !== action.payload.sectionId ||
            questionDeletion.questionId !== action.payload.questionId
        ),
      }
    }
    case Type.CLEAR_DELETED_TEST_FORM_SECTION: {
      const { sectionDeletions } = state
      return {
        ...state,
        sectionDeletions: sectionDeletions.filter(
          (sectionDeletion) =>
            sectionDeletion.sectionId !== action.payload.sectionId
        ),
      }
    }
    case Type.CLEAR_DELETED_TEST_FORM_SECTION_SCREENSHOT: {
      const { sectionScreenshotDeletions } = state
      return {
        ...state,
        sectionScreenshotDeletions: sectionScreenshotDeletions.filter(
          (sectionScreenshotDeletion) =>
            sectionScreenshotDeletion.sectionId !== action.payload.sectionId ||
            sectionScreenshotDeletion.sectionScreenshotId !==
              action.payload.sectionScreenshotId
        ),
      }
    }
    case Type.ADD_LOCAL_COMMENT: {
      const { comments } = state
      return {
        ...state,
        comments: [...comments, action.payload],
      }
    }
    case Type.EDIT_LOCAL_COMMENT: {
      const { comments } = state
      return {
        ...state,
        comments: comments.map((comment) =>
          comment.comment_id === action.payload.comment_id
            ? { ...comment, ...action.payload }
            : comment
        ),
      }
    }
    case Type.DELETE_LOCAL_COMMENT: {
      const { comments } = state
      return {
        ...state,
        comments: comments.filter(
          (comment) => comment.comment_id !== action.payload.comment_id
        ),
      }
    }
    case Type.SAVE_UNDO_CHECKPOINT: {
      return {
        ...state,
        lastOperation: action.payload,
      }
    }
    case Type.CLEAR_UNDO_CHECKPOINT: {
      return {
        ...state,
        lastOperation: null,
      }
    }
  }
  return state
}
