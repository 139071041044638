import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"

import { PurchasesMailtoLink } from "Shared/components/Links/PurchasesMailtoLink"

type ManualSubscriptionUnpaidAlert = React.FC<React.PropsWithChildren<unknown>>

export const ManualSubscriptionUnpaidAlert: ManualSubscriptionUnpaidAlert =
  () => {
    return (
      <Alert alignItems="flex-start" status="error">
        <AlertIcon />
        <Stack spacing={5}>
          <AlertTitle>There is an issue with your subscription</AlertTitle>
          <AlertDescription>
            <Stack spacing={3}>
              <Text>
                We have not received payment for your subscription, so your
                account currently has limited access.
              </Text>
              <Text>
                Please contact <PurchasesMailtoLink /> to resolve this issue.
              </Text>
            </Stack>
          </AlertDescription>
        </Stack>
      </Alert>
    )
  }
