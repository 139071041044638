import { memoize, throttle } from "lodash"
import React, { PureComponent, Ref, UIEvent } from "react"

import CarouselIndicator from "Components/preference-test-layout/carousel-indicator"
import PreferenceItem from "Components/preference-test-layout/preference-item"
import styles from "Components/preference-test-layout/preference-list.module.scss"
import Props from "Components/preference-test-layout/preference-overview-props"
import { between } from "Math/number"

function listClassName(optionCount: number): string {
  return `${styles.list} ${
    styles[`hasCount${optionCount}` as keyof typeof styles]
  }`
}

const SCROLL_THROTTLE_MS = 50

interface State {
  currentIndex: number
}

class PreferenceList extends PureComponent<Props, State> {
  // -- State --

  state: State = {
    currentIndex: 0,
  }

  private items: Array<HTMLDivElement | null> = []

  // -- Reference handlers --

  private createHandleItemRef = memoize(
    (index: number): Ref<HTMLDivElement> =>
      (element) => {
        this.items[index] = element
      }
  )

  // -- Event handlers --

  private createClickCallback = memoize(
    (index: number) => () => this.props.onSelectOption(index)
  )

  private handleScroll = (event: UIEvent<HTMLElement>) => {
    // Pass the element to the throttled function because the `SyntheticEvent`
    // is pooled and reused by React.
    this.updateIndicator(event.currentTarget)
  }

  private updateIndicator = throttle((scroller: HTMLElement) => {
    const { scrollLeft, clientLeft, clientWidth } = scroller

    const clientCenter = clientLeft + scrollLeft + clientWidth / 2

    const currentIndex = this.items.findIndex((element) => {
      return (
        element !== null &&
        between(
          clientCenter,
          element.offsetLeft,
          element.offsetLeft + element.clientWidth
        )
      )
    })

    if (currentIndex !== -1) {
      this.setState({ currentIndex })
    }
  }, SCROLL_THROTTLE_MS)

  // -- Component lifecycle --

  render() {
    const { sectionScreenshots, isTaskStarted, onStartTask } = this.props
    const elementNodes = sectionScreenshots.map((sectionScreenshot, index) => (
      <li key={index} className={styles.listItem}>
        <PreferenceItem
          innerRef={this.createHandleItemRef(index)}
          sectionScreenshot={sectionScreenshot}
          onSelect={this.createClickCallback(index)}
          onStartTask={onStartTask}
          isTaskStarted={isTaskStarted}
        />
      </li>
    ))
    return (
      <div className={styles.listContainer}>
        <div className={styles.listScroller} onScroll={this.handleScroll}>
          <ol className={listClassName(sectionScreenshots.length)}>
            {elementNodes}
          </ol>
        </div>
        <div className={styles.listIndicator}>
          <CarouselIndicator
            count={sectionScreenshots.length}
            current={this.state.currentIndex}
          />
        </div>
      </div>
    )
  }
}

export default PreferenceList
