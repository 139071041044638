import { Stack, Text } from "@chakra-ui/react"
import React from "react"

import { SectionCard } from "../SectionCard"
import { SectionQuestions } from "../SectionQuestions/SectionQuestions"
import { SectionLogicActions } from "../TestLogicActions/TestLogicActions"

import { PrototypeSectionTask } from "./PrototypeSectionTask"

export const PrototypeSectionCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <SectionCard>
      <SectionLogicActions />
      <Stack spacing={8}>
        <PrototypeSectionTask />
        <Text as="label" fontWeight="semibold">
          Follow up questions
        </Text>
      </Stack>
      <SectionQuestions />
    </SectionCard>
  )
}
