import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"

import { getDateString } from "Utilities/date-formats"

import { ChangeSubscriptionPlanForm } from "./ChangeSubscriptionPlanForm"

type ConfirmResumeSubscriptionModal = React.FC<{
  isOpen: boolean
  onClose: () => void
  currentPlanUniqueId: string
  currentPlanName: string
  subscriptionId: string
  subscriptionRenewalTime: string
}>

export const ConfirmResumeSubscriptionModal: ConfirmResumeSubscriptionModal = ({
  isOpen,
  onClose,
  currentPlanUniqueId,
  currentPlanName,
  subscriptionId,
  subscriptionRenewalTime,
}) => (
  <Modal size="lg" onClose={onClose} isOpen={isOpen}>
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>Resume subscription</ModalHeader>
        <ModalBody>
          <Stack spacing={2}>
            <Text>
              You{"\u2019"}re about to resume your subscription to the{" "}
              {currentPlanName} plan.
            </Text>
            <Text>
              You won{"\u2019"}t be billed until your subscription renewal date
              ({getDateString(subscriptionRenewalTime)}
              ). You{"\u2019"}re free to upgrade, downgrade, or cancel at any
              time.
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ChangeSubscriptionPlanForm
            buttonText="Resume subscription"
            onClose={onClose}
            planUniqueId={currentPlanUniqueId}
            subscriptionId={subscriptionId}
          />
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  </Modal>
)
