import { FormControl, FormLabel } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { Field, WrappedFieldProps } from "redux-form"

import { ProjectSelector } from "UsabilityHub/components/ProjectSelector/project-selector"

import { useUsabilityTestUserActivityContext } from "../../UsabilityTestUserActivityContext"

const NewProjectControl = ({ input }: WrappedFieldProps) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  // Default selection based on the `project_id` query param
  const queryParams = new URLSearchParams(window.location.search)
  const defaultProjectId = queryParams.get("project_id")
  useEffect(() => {
    if (!defaultProjectId) return
    input.onChange(defaultProjectId)
  }, [defaultProjectId])

  return (
    <FormControl id="project_id">
      <FormLabel>Project</FormLabel>
      <ProjectSelector
        isClearable
        value={input.value as string}
        onChange={input.onChange}
        isDisabled={readOnly}
      />
    </FormControl>
  )
}

export const NewProjectField: React.FC = () => (
  <Field name="project_id" component={NewProjectControl} />
)
