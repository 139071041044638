import { Box, Flex } from "@chakra-ui/react"
import React, { useRef, useState } from "react"
import { GetModeratedStudyBookingRecordingsResponse } from "~/api/generated/usabilityhub-components"
import { Transcript } from "./Transcript"
import { VideoPlayer, VideoPlayerProps } from "./VideoPlayer"

type RecordingProps = {
  recording: GetModeratedStudyBookingRecordingsResponse[number]
} & Pick<VideoPlayerProps, "onDelete" | "onRename">

export const Recording: React.FC<RecordingProps> = ({
  recording,
  ...props
}) => {
  const video = useRef<HTMLVideoElement>(null)

  const seek = (time: number) => {
    const media = video.current
    if (!media) return

    media.currentTime = time / 1000
    if (media.paused) media.play()
  }

  const [time, setTime] = useState(0)

  const [playing, setPlaying] = useState(false)

  const timeUpdate = () => {
    const media = video.current
    if (!media) return

    setTime(media.currentTime * 1000)
  }

  return (
    <Flex direction="column" gap={4} maxH="85vh">
      <Box pos="relative" bg="black" aspectRatio="16 / 9" flexShrink={0}>
        <VideoPlayer
          pos="absolute"
          inset={0}
          h="full"
          w="full"
          recording={recording}
          ref={video}
          onPlaying={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onEnded={() => setPlaying(false)}
          onTimeUpdate={timeUpdate}
          {...props}
        />
      </Box>

      <Box flexGrow={1} overflowY="auto" overflowX="hidden">
        <Transcript
          recording={recording}
          time={time}
          playing={playing}
          onSeek={seek}
        />
      </Box>
    </Flex>
  )
}
