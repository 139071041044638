import { useCallback } from "react"
import { ListTargetableLocationsResponse } from "~/api/generated/usabilityhub-components"
import { useOrderForm } from "../OrderFormProvider"
import { TargetLocation } from "../types"
import { useLocationContext } from "./LocationProvider"
import { SelectionState } from "./LocationRow"

// Since there are a number of business rules around what combination of locations can be targeted at once,
// all the logic will live in this hook.
export const useToggleLocation = () => {
  const { targetLocations, setTargetLocations } = useOrderForm()
  const locations = useLocationContext()

  // Turn an individual location on or off.
  // If you leave forceValue undefined it will invert the current state,
  // otherwise it will set it to that value.
  const toggleIndividualLocation = useCallback(
    (location: TargetLocation, forceValue: boolean | undefined = undefined) => {
      setTargetLocations((oldTargetLocations) => {
        if (
          oldTargetLocations.some(
            (tl) => tl.type === location.type && tl.id === location.id
          )
        ) {
          // If it's on already but forceValue = true, leave it on
          if (forceValue) return oldTargetLocations

          // Otherwise, remove it
          return oldTargetLocations.filter(
            (tl) => tl.type !== location.type || tl.id !== location.id
          )
        } else {
          // If it's off already but forceValue = false, leave it off
          if (forceValue === false) return oldTargetLocations

          // Otherwise, add it to the end of the list
          return [...oldTargetLocations, location]
        }
      })
    },
    [setTargetLocations]
  )

  const isCountrySelected = (countryCode: string): SelectionState => {
    const country = findCountry(locations, countryCode)

    const isSelected = targetLocations.some(
      (tl) => tl.type === "country" && tl.id === country.code
    )

    const stateIds = country.states.map((s) => s.id.toString())
    const selectedStateCount = targetLocations.filter(
      (tl) => tl.type === "state" && stateIds.includes(tl.id)
    ).length
    const someStatesSelected = selectedStateCount > 0

    const cityIds = country.states.flatMap((s) =>
      s.cities.map((c) => c.id.toString())
    )
    const selectedCityCount = targetLocations.filter(
      (tl) => tl.type === "city" && cityIds.includes(tl.id)
    )
    const someCitiesSelected = selectedCityCount.length > 0

    if (someStatesSelected || someCitiesSelected) return "indeterminate"

    return isSelected ? "selected" : "unselected"
  }

  const isStateSelected = (
    countryCode: string,
    stateId: number
  ): SelectionState => {
    const [country, state] = findState(locations, countryCode, stateId)

    const isCountrySelected = targetLocations.some(
      (tl) => tl.type === "country" && tl.id === country.code
    )

    const isSelected = targetLocations.some(
      (tl) => tl.type === "state" && tl.id === state.id.toString()
    )

    const cityIds = state.cities.map((s) => s.id.toString())
    const selectedCityCount = targetLocations.filter(
      (tl) => tl.type === "city" && cityIds.includes(tl.id)
    ).length
    const someCitiesSelected = selectedCityCount > 0

    if (someCitiesSelected) return "indeterminate"
    if (isCountrySelected) return "selected"

    return isSelected ? "selected" : "unselected"
  }

  const isCitySelected = (
    countryCode: string,
    stateId: number,
    cityId: number
  ): SelectionState => {
    const [country, state, city] = findCity(
      locations,
      countryCode,
      stateId,
      cityId
    )

    const isCountrySelected = targetLocations.some(
      (tl) => tl.type === "country" && tl.id === country.code
    )
    const isStateSelected = targetLocations.some(
      (tl) => tl.type === "state" && tl.id === state.id.toString()
    )
    const isSelected = targetLocations.some(
      (tl) => tl.type === "city" && tl.id === city.id.toString()
    )

    if (isCountrySelected) return "selected"
    if (isStateSelected) return "selected"

    return isSelected ? "selected" : "unselected"
  }

  // Toggle a country to the opposite of its current state.
  // If a country is not selected, but it has selected states inside, then toggling it will turn off all states.
  // If you pass forceValue, then it will always be that value, regardless of the current state.
  const toggleCountry = (
    countryCode: string,
    forceValue: boolean | undefined = undefined
  ) => {
    const country = locations.find(({ code }) => code === countryCode)

    if (!country) {
      throw new Error("No country found")
    }

    const stateIds = country.states.map((s) => s.id.toString())
    const selectedStateCount = targetLocations.filter(
      (tl) => tl.type === "state" && stateIds.includes(tl.id)
    ).length
    const someStatesSelected = selectedStateCount > 0

    const cityIds = country.states.flatMap((state) =>
      state.cities.map((s) => s.id.toString())
    )
    const selectedCityCount = targetLocations.filter(
      (tl) => tl.type === "city" && cityIds.includes(tl.id)
    ).length
    const someCitiesSelected = selectedCityCount > 0

    if (someStatesSelected || someCitiesSelected) {
      // When the checkbox is indeterminate and you click it we want to
      // turn off all states and all cities underneath this country
      setTargetLocations((oldTargetLocations) => {
        return oldTargetLocations.filter((otl) => {
          return (
            !(otl.type === "state" && stateIds.includes(otl.id)) &&
            !(otl.type === "city" && cityIds.includes(otl.id))
          )
        })
      })

      if (forceValue) {
        toggleIndividualLocation({ type: "country", id: countryCode }, true)
      }
    } else {
      toggleIndividualLocation({ type: "country", id: countryCode }, forceValue)
    }
  }

  const toggleState = (
    countryCode: string,
    stateId: number,
    forceValue: boolean | undefined = undefined
  ) => {
    const [country, state] = findState(locations, countryCode, stateId)
    const isCountrySelected = targetLocations.some(
      (tl) => tl.type === "country" && tl.id === country.code
    )
    const cityIds = state.cities.map((s) => s.id.toString())
    const selectedCityCount = targetLocations.filter(
      (tl) => tl.type === "city" && cityIds.includes(tl.id)
    ).length
    const someCitiesSelected = selectedCityCount > 0

    if (someCitiesSelected) {
      // When the checkbox is indeterminate and you click it we want to
      // turn off all cities underneath this state
      setTargetLocations((oldTargetLocations) => {
        return oldTargetLocations.filter((otl) => {
          return otl.type !== "city" || !cityIds.includes(otl.id)
        })
      })

      if (forceValue) {
        toggleIndividualLocation(
          { type: "state", id: state.id.toString() },
          true
        )
      }
    } else if (isCountrySelected) {
      // If the country is selected and you turn OFF a state, remove the country and add all OTHER states
      setTargetLocations((oldTargetLocations) => {
        return oldTargetLocations.filter((otl) => {
          return otl.type !== "country" || country.code !== otl.id
        })
      })

      setTargetLocations((oldTargetLocations) => {
        return [
          ...oldTargetLocations,
          ...country.states
            .filter((s) => s.id !== state.id) // Only add the states we didn't just toggle off
            .map(
              (s) =>
                ({
                  type: "state",
                  id: s.id.toString(),
                }) as const
            ),
        ]
      })
    } else {
      toggleIndividualLocation(
        { type: "state", id: state.id.toString() },
        forceValue
      )
    }
  }

  const toggleCity = (countryCode: string, stateId: number, cityId: number) => {
    const country = locations.find(({ code }) => code === countryCode)

    if (!country) {
      throw new Error("No country found")
    }

    const state = country.states.find((s) => s.id === stateId)

    if (!state) {
      throw new Error("No state found")
    }

    const city = state.cities.find((s) => s.id === cityId)

    if (!city) {
      throw new Error("No city found")
    }

    const isCountrySelected = targetLocations.some(
      (tl) => tl.type === "country" && tl.id === country.code
    )
    const isStateSelected = targetLocations.some(
      (tl) => tl.type === "state" && tl.id === state.id.toString()
    )

    // When you select the last city in a state, turn them all off and instead turn on the state
    if (isStateSelected) {
      // If the state is selected and you turn OFF a city, remove the state and add all OTHER cities
      setTargetLocations((oldTargetLocations) => {
        return oldTargetLocations.filter((otl) => {
          return otl.type !== "state" || state.id.toString() !== otl.id
        })
      })

      setTargetLocations((oldTargetLocations) => {
        return [
          ...oldTargetLocations,
          ...state.cities
            .filter((s) => s.id !== city.id) // Only add the cities we didn't just toggle off
            .map(
              (s) =>
                ({
                  type: "city",
                  id: s.id.toString(),
                }) as const
            ),
        ]
      })
    } else if (isCountrySelected) {
      // If the grandparent country is causing this city to be selected, turn off the country and turn on all OTHER states
      // and all other CITIES in this state oh my god my head hurts
      setTargetLocations((oldTargetLocations) => {
        return oldTargetLocations.filter(
          (otl) => otl.type !== "country" || country.code !== otl.id
        )
      })

      setTargetLocations((oldTargetLocations) => {
        return [
          ...oldTargetLocations,
          ...country.states
            .filter((s) => s.id !== state.id) // Add other states but not the parent one of this city
            .map(
              (s) =>
                ({
                  type: "state",
                  id: s.id.toString(),
                }) as const
            ),
          ...state.cities
            .filter((c) => c.id !== city.id) // Add other cites but not the one we just turned off
            .map(
              (s) =>
                ({
                  type: "city",
                  id: s.id.toString(),
                }) as const
            ),
        ]
      })
    } else {
      toggleIndividualLocation({ type: "city", id: city.id.toString() })
    }
  }

  return {
    isCountrySelected,
    isStateSelected,
    isCitySelected,
    toggleCountry,
    toggleState,
    toggleCity,
  }
}

type NestedCountry = ListTargetableLocationsResponse["locations"][number]
type NestedState = NestedCountry["states"][number]
type NestedCity = NestedState["cities"][number]

const findCountry = (
  locations: ListTargetableLocationsResponse["locations"],
  countryCode: string
): NestedCountry => {
  const country = locations.find(({ code }) => code === countryCode)

  if (!country) {
    throw new Error("No country found")
  }

  return country
}

const findState = (
  locations: ListTargetableLocationsResponse["locations"],
  countryCode: string,
  stateId: number
): [NestedCountry, NestedState] => {
  const country = findCountry(locations, countryCode)

  const state = country.states.find((s) => s.id === stateId)

  if (!state) {
    throw new Error("No state found")
  }

  return [country, state]
}

const findCity = (
  locations: ListTargetableLocationsResponse["locations"],
  countryCode: string,
  stateId: number,
  cityId: number
): [NestedCountry, NestedState, NestedCity] => {
  const [country, state] = findState(locations, countryCode, stateId)

  const city = state.cities.find((s) => s.id === cityId)

  if (!city) {
    throw new Error("No city found")
  }

  return [country, state, city]
}
