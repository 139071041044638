import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { Role } from "Types"
import { AvatarField } from "UsabilityHub/components/AvatarField/FormikAvatarField"
import { useRefreshCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useRefreshTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { Field, FieldProps, Form, Formik } from "formik"
import React from "react"
import {
  useMakeUserOwner,
  useUpdateUser,
} from "~/api/generated/usabilityhub-components"
import { TeamMember } from "~/api/generated/usabilityhubSchemas"

type EditUserModal = React.FC<
  React.PropsWithChildren<
    {
      user: TeamMember
      canMakeAccountOwner: boolean
    } & Pick<React.ComponentProps<typeof Modal>, "isOpen" | "onClose">
  >
>

type EditUserFormValues = {
  avatar: string
  email: string
  name: string
  make_owner: boolean
  role: Role
}

export const EditUserModal: EditUserModal = ({
  isOpen,
  onClose,
  user,
  canMakeAccountOwner,
}) => {
  const toast = useToast()
  const userIsOmniauthed = user.omniauth_service !== null
  const refreshTeamMembers = useRefreshTeamMembers()
  const refreshCurrentAccount = useRefreshCurrentAccount()

  const { mutate: updateUser } = useUpdateUser({
    onSuccess: async (data) => {
      toast({
        title: data.message,
        status: "success",
      })

      refreshCurrentAccount()
      refreshTeamMembers()
    },
    onError: (error) => {
      toast({
        title: error.payload.message,
        status: "error",
      })
    },
  })

  const { mutate: makeOwner } = useMakeUserOwner({
    onSuccess: async ({ message }) => {
      toast({
        title: message,
        status: "success",
      })

      return refreshCurrentAccount()
    },
    onError: (error) => {
      toast({
        title: error.payload.message,
        status: "error",
      })
    },
  })

  const onEditUserSubmit = async (values: EditUserFormValues) => {
    updateUser({
      pathParams: { userId: user.id },
      body: {
        ...values,
        make_owner: values.make_owner ? "true" : "false",
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }

  return (
    <Formik
      initialValues={{
        avatar: "",
        email: user.email,
        name: user.name,
        make_owner: false,
        role: user.role,
      }}
      onSubmit={onEditUserSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay>
              <ModalContent>
                <ModalHeader>
                  <Flex justifyContent="space-between">
                    <Text>Edit user</Text>
                    {canMakeAccountOwner && (
                      <Button
                        size="sm"
                        colorScheme="red"
                        variant="outline"
                        onClick={() =>
                          makeOwner({ pathParams: { userId: user.id } })
                        }
                      >
                        Make account owner
                      </Button>
                    )}
                  </Flex>
                </ModalHeader>
                <ModalBody>
                  <Stack spacing={3}>
                    <FormControl>
                      <FormLabel>Avatar</FormLabel>
                      <AvatarField
                        name={user.name}
                        avatarUrl={user.avatar_url_medium}
                      />
                    </FormControl>
                    <Field name="name">
                      {({ field }: FieldProps) => (
                        <FormControl>
                          <FormLabel htmlFor="name">Full name</FormLabel>
                          <Input
                            {...field}
                            id="name"
                            isRequired
                            placeholder="Hubby De Niro"
                            type="text"
                            autoFocus
                          />
                        </FormControl>
                      )}
                    </Field>
                    {userIsOmniauthed && (
                      <Alert status="warning">
                        <AlertIcon />
                        <AlertDescription>
                          You can{"\u2019"}t change this user{"\u2019"}s email
                          because their account is authenticated with{" "}
                          {user.omniauth_service}.
                        </AlertDescription>
                      </Alert>
                    )}
                    <Field name="email">
                      {({ field }: FieldProps) => (
                        <FormControl isDisabled={userIsOmniauthed}>
                          <FormLabel htmlFor="email">Email</FormLabel>
                          <Input
                            {...field}
                            id="email"
                            isRequired
                            placeholder="email@domain.com"
                            type="text"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={onClose} mr={3}>
                    Cancel
                  </Button>
                  <Button
                    isLoading={isSubmitting}
                    onClick={async () => {
                      await submitForm()
                      onClose()
                    }}
                    colorScheme="brand.primary"
                  >
                    Save
                  </Button>
                </ModalFooter>
              </ModalContent>
            </ModalOverlay>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}
