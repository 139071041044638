import { UsabilityTestSection } from "Types"
import { TEST_BUILDER_FEATURES } from "UsabilityHub/components/UpgradeUsabilityTestBanner/testBuilderFeatures"
import { RecruitPageUsabilityTest } from "UsabilityHub/hooks/useUsabilityTestRecruit"
import { uniq } from "lodash"
import { Account, Plan } from "~/api/generated/usabilityhubSchemas"

export function getDeviceFrameIds(
  sections: ReadonlyArray<UsabilityTestSection>
): number[] {
  return uniq(
    sections.reduce((acc, section) => {
      if (section.device_frame_id !== null) {
        acc.push(section.device_frame_id)
      }
      return acc
    }, [] as number[])
  )
}

export function isRecruitmentAllowed(
  usabilityTest: Pick<
    RecruitPageUsabilityTest,
    | "upgraded"
    | "last_estimated_duration_upper_minutes"
    | "has_test_logic"
    | "customize_welcome"
    | "customize_thankyou"
    | "test_recording_types"
  >,
  account: Account,
  plans: Plan[]
): boolean {
  return (
    Object.keys(listRestrictedPaywallFeatures(usabilityTest, account, plans))
      .length === 0
  )
}

export type RestrictedPaywallFeature = {
  [key in (typeof TEST_BUILDER_FEATURES)[number]["identifier"]]?:
    | "required"
    | "limited"
}

type PlanWithRestrictedFeatures = {
  [key in Plan["unique_id"]]: RestrictedPaywallFeature
}

export function listRestrictedPaywallFeatures(
  usabilityTest: Pick<
    RecruitPageUsabilityTest,
    | "upgraded"
    | "last_estimated_duration_upper_minutes"
    | "has_test_logic"
    | "customize_welcome"
    | "customize_thankyou"
    | "test_recording_types"
  >,
  account: Account,
  plans: Plan[]
): PlanWithRestrictedFeatures {
  if (usabilityTest.upgraded) {
    return {}
  }

  const planWithRestrictedFeatures: PlanWithRestrictedFeatures = {}

  plans.forEach((plan) => {
    const features: RestrictedPaywallFeature = {}

    if (!account.has_test_logic_feature && usabilityTest.has_test_logic) {
      features.test_logic = plan.test_logic ? "required" : "limited"
    }

    if (
      !account.has_recordings_feature &&
      usabilityTest.test_recording_types.length > 0
    ) {
      features.recordings = plan.recordings ? "required" : "limited"
    }

    if (
      usabilityTest.last_estimated_duration_upper_minutes &&
      account.max_test_duration_in_minutes &&
      usabilityTest.last_estimated_duration_upper_minutes >
        account.max_test_duration_in_minutes
    ) {
      const value = plan.max_test_duration
        ? usabilityTest.last_estimated_duration_upper_minutes >
          plan.max_test_duration
          ? "limited"
          : "required"
        : "required"
      features.max_test_duration = value
    }

    if (
      !account.has_custom_welcome_and_thankyou_messages_feature &&
      (usabilityTest.customize_welcome || usabilityTest.customize_thankyou)
    ) {
      const value = plan.custom_welcome_and_thankyou_messages
        ? "required"
        : "limited"
      if (usabilityTest.customize_welcome) features.custom_welcome = value

      if (usabilityTest.customize_thankyou) features.custom_thankyou = value
    }

    if (Object.keys(features).length > 0) {
      planWithRestrictedFeatures[plan.unique_id] = features
    }
  })

  return planWithRestrictedFeatures
}
