import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { Copy01OutlineIcon } from "Shared/icons/untitled-ui/Copy01OutlineIcon"

import { DAYS, DAY_LABELS, Day } from "./days"

interface Props {
  day: Day
  handleCopy: (source: Day, targets: Day[]) => void
}

export const TimeSlotsCopyMenu: React.FC<Props> = ({
  day: sourceDay,
  handleCopy,
}) => {
  const [targetDays, setTargetDays] = useState<Day[]>([])

  const onClose = () => {
    setTargetDays([])
  }

  return (
    <Menu onClose={onClose}>
      {({ onClose }) => (
        <>
          <Tooltip hasArrow placement="top" label="Copy times to …">
            <MenuButton
              as={IconButton}
              variant="ghost"
              size="sm"
              aria-label="Copy times to …"
              icon={
                <Icon as={Copy01OutlineIcon} color="brand.neutral.default" />
              }
            />
          </Tooltip>
          <MenuList py={2}>
            <MenuGroup title="Copy times to" textColor="text.secondary">
              <CheckboxGroup colorScheme="brand.primary">
                <Flex direction="column">
                  {DAYS.map((d) => (
                    <Checkbox
                      key={`checkbox-${DAY_LABELS[d].shortLabel}`}
                      variant="mdWithSmFont"
                      isChecked={d === sourceDay || targetDays.includes(d)}
                      isDisabled={d === sourceDay}
                      flexGrow={1}
                      flexDirection="row-reverse"
                      onChange={() =>
                        setTargetDays((list) =>
                          list.includes(d)
                            ? list.filter((item) => item !== d)
                            : list.concat(d)
                        )
                      }
                      px={4}
                      sx={{
                        "& > .chakra-checkbox__label": {
                          flexGrow: 1,
                          ms: 0,
                        },
                      }}
                    >
                      <Flex w="100%" align="center" flexGrow={1} py={2}>
                        <Text
                          fontSize="md"
                          fontWeight="medium"
                          textColor="text.primary"
                        >
                          {DAY_LABELS[d].longLabel}
                        </Text>
                      </Flex>
                    </Checkbox>
                  ))}
                </Flex>
              </CheckboxGroup>
            </MenuGroup>
            <MenuDivider />
            <Flex justify="space-around" px={2} gap={1}>
              <Button variant="ghost" onClick={onClose} flexBasis="50%">
                Cancel
              </Button>
              <Button
                colorScheme="brand.primary"
                onClick={() => {
                  handleCopy(sourceDay, targetDays)
                  onClose()
                }}
                flexBasis="50%"
              >
                Apply
              </Button>
            </Flex>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
