import { FigmaTaskClick } from "Types"

import { NodeClicksByResponse } from "../get-node-clicks-by-response/get-node-clicks-by-response"

export type ClickOccurrence = number | `${number}+`

function isClickIncluded(index: number, occurrences: ClickOccurrence[]) {
  return occurrences.some((occurrence) => {
    if (typeof occurrence === "string") {
      const maxOccurrenceIndex = parseInt(occurrence)
      return index >= maxOccurrenceIndex
    }

    return occurrence === index
  })
}

function getFilteredClicks(
  clicks: FigmaTaskClick[],
  occurrences: ClickOccurrence[]
) {
  return clicks.filter((_, index) => isClickIncluded(index, occurrences))
}

export function filterNodeClicksByOccurrences(
  clicks: NodeClicksByResponse,
  occurrences: ClickOccurrence[]
) {
  return clicks.map((clicks) => getFilteredClicks(clicks, occurrences))
}
