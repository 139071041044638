import { Badge, Button } from "DesignSystem/components"
import { ThumbsUpOutlineIcon } from "Shared/icons/untitled-ui/ThumbsUpOutlineIcon"
import React from "react"

export const RateResponsesButton = ({
  isRatingFormVisible,
  hasRatedAlready,
  ...props
}: {
  onClick: () => void
  isRatingFormVisible: boolean
  hasRatedAlready: boolean
}) => (
  <Button
    leftIcon={<ThumbsUpOutlineIcon color="ds.icon.default" />}
    isSelected={isRatingFormVisible}
    rightIcon={
      hasRatedAlready ? undefined : <Badge label="new" colorScheme="new" />
    }
    {...props}
  >
    {hasRatedAlready ? "Update rating" : "Rate responses"}
  </Button>
)
