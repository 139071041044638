// JsFromRoutes CacheKey 290e6d6dd558ffe36dfad366adedb75a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  get: /* #__PURE__ */ definePathHelper('get', '/credit_card'),
  update: /* #__PURE__ */ definePathHelper('patch', '/credit_card'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/credit_card'),
}
