export const defaultColors = {
  bg: "ds.surface.raised.resting",
  _hover: {
    bg: "ds.surface.raised.hovered",
    borderColor: "ds.border.hovered",
  },
  _active: {
    bg: "ds.surface.raised.pressed",
  },
}
export const archivedColors = {
  bg: "ds.background.neutral.resting",
  _hover: {
    bg: "ds.background.neutral.hovered",
    borderColor: "ds.border.hovered",
  },
  _active: {
    bg: "ds.background.neutral.pressed",
  },
}
