import React, { CSSProperties, HTMLAttributes } from "react"

import { Dimensions } from "Types"
import { percentage, pixels } from "Utilities/css-helpers"

function containerStyle({ width }: Readonly<Dimensions>): CSSProperties {
  return {
    position: "relative",
    width: pixels(width),
  }
}

function beforeElementStye({
  width,
  height,
}: Readonly<Dimensions>): CSSProperties {
  return {
    content: "",
    display: "block",
    paddingTop: percentage(height / width),
    position: "relative",
    width: "100%",
  }
}

export interface Props extends HTMLAttributes<HTMLDivElement> {
  dimensions: Dimensions
}

export const MaintainAspectRatioContainer = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const { children, dimensions, ...divProps } = props
  return (
    <div ref={ref} style={containerStyle(dimensions)} {...divProps}>
      <div style={beforeElementStye(dimensions)} />
      {children}
    </div>
  )
})

MaintainAspectRatioContainer.displayName = "MaintainAspectRatioContainer"
