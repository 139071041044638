import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const FirstClickTestIcon = createIcon({
  displayName: "FirstClickTestIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 8A8 8 0 110 8a8 8 0 0116 0zM5.016 5.433a.333.333 0 01.417-.417l5.034 1.582a.333.333 0 01.083.596l-1.483.971 1.835 1.836c.13.13.13.34 0 .47l-.43.431c-.13.13-.341.13-.471 0L8.165 9.067l-.97 1.483a.333.333 0 01-.597-.083L5.016 5.433z"
    />
  ),
})
