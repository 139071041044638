import differenceInWeeks from "date-fns/differenceInWeeks"
import formatDistance from "date-fns/esm/formatDistance"
import React from "react"

import { getDateString } from "Utilities/date-formats"
import { TimeImpl } from "./time-impl"

interface Props {
  date: Date | string | number
}

export function EventTime({ date }: Props) {
  const d = new Date(date)
  const now = new Date()
  const text =
    differenceInWeeks(now, d) >= 1
      ? getDateString(d)
      : `${formatDistance(d, now)} ago`
  return <TimeImpl date={d}>{text}</TimeImpl>
}
