import { Flex, Text, Tooltip } from "@chakra-ui/react"
import React from "react"

import { GetModeratedStudyApplicationsResponse } from "~/api/generated/usabilityhub-components"
import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"

import { AnswerResultIcon } from "./AnswerResultIcon"

type ScreenerQuestionAnswer = NonNullable<
  GetModeratedStudyApplicationsResponse["applications"][number]["response"]
>["answers"][number]

interface ScreenerQuestionAnswerProps {
  screenerQuestion: ScreenerQuestion
  screenerQuestionAnswer?: ScreenerQuestionAnswer
}

export function ScreenerQuestionAnswer({
  screenerQuestion,
  screenerQuestionAnswer,
}: ScreenerQuestionAnswerProps) {
  if (!screenerQuestionAnswer) return <Text as="i">-</Text>

  if (
    screenerQuestionAnswer.type === "short_text" ||
    screenerQuestionAnswer.type === "long_text"
  ) {
    return (
      <Tooltip
        hasArrow
        placement="top"
        label={
          <Text whiteSpace="pre-wrap">{screenerQuestionAnswer.answer}</Text>
        }
      >
        <Text whiteSpace="normal" noOfLines={1}>
          {screenerQuestionAnswer.answer}
        </Text>
      </Tooltip>
    )
  }

  if (
    screenerQuestionAnswer.type === "single_select" ||
    screenerQuestionAnswer.type === "multi_select"
  ) {
    const selectedOptions =
      screenerQuestionAnswer.selected_options?.map((selectedOption) =>
        screenerQuestion.options?.find(
          (option) => option.id === selectedOption.screener_question_option_id
        )
      ) ?? []

    const options = selectedOptions.map((option) => option?.value)

    const displayOptions = selectedOptions.map((option, index) => (
      <React.Fragment key={option?.id}>
        {index > 0 && ", "}
        {option?.deleted_at ? <i>Other</i> : option?.value}
      </React.Fragment>
    ))

    return (
      <Flex gap={1} justifyContent="space-between" alignItems="center">
        <Tooltip
          hasArrow
          placement="top"
          label={
            <Flex flexDirection="column">
              {options.map((option) => (
                <Text key={option}>{option}</Text>
              ))}
            </Flex>
          }
        >
          <Text whiteSpace="normal" noOfLines={1} width="full">
            {displayOptions}
          </Text>
        </Tooltip>
        <AnswerResultIcon
          disqualified={screenerQuestionAnswer.selected_options?.some(
            (option) => option?.rule === "disqualify"
          )}
          notRelevant={screenerQuestionAnswer.selected_options?.every(
            (option) => option?.rule === "not_relevant"
          )}
        />
      </Flex>
    )
  }

  throw new Error("answer type is not supported")
}
