import { Box, Tooltip } from "@chakra-ui/react"
import { ClockIcon, CursorClickIcon } from "@heroicons/react/solid"
import React from "react"

import { IndividualPathMeta } from "Types"
import { formatPercentage01 } from "Utilities/number"
import { floorMsToS } from "Utilities/time"

import { GoalScreenBadge } from "./GoalScreenBadge"
import { PathStatProps } from "./PathStatProps"
import { PrototypeResultsTextStat } from "./Stat"

export function IndividualPathStats({
  pathMeta,
  highlightTime = false,
  highlightClicks = false,
}: PathStatProps<IndividualPathMeta>) {
  const { clicks, duration, misclickRate, goalScreenHasBeenReached } = pathMeta

  return (
    <>
      <Box>
        <GoalScreenBadge goalScreenHasBeenReached={goalScreenHasBeenReached} />
      </Box>
      <Tooltip
        hasArrow
        placement="top"
        label="Total time spent by this participant"
      >
        <PrototypeResultsTextStat
          icon={ClockIcon}
          isHighlighted={highlightTime}
        >
          Time spent: {floorMsToS(duration)}s
        </PrototypeResultsTextStat>
      </Tooltip>
      <Tooltip
        hasArrow
        placement="top"
        label="Total number of clicks by this participant"
      >
        <PrototypeResultsTextStat
          icon={CursorClickIcon}
          isHighlighted={highlightClicks}
        >
          {clicks.length} Clicks ({formatPercentage01(misclickRate)} misclicks)
        </PrototypeResultsTextStat>
      </Tooltip>
    </>
  )
}
