import {
  AddLocalCommentAction,
  DeleteLocalCommentAction,
  EditLocalCommentAction,
} from "../action-types"
import { Type } from "../constants"
import { ClientCommentFragment } from "../reducer"

export const addLocalComment = (
  comment: ClientCommentFragment
): AddLocalCommentAction => ({
  type: Type.ADD_LOCAL_COMMENT,
  payload: comment,
})

export const editLocalComment = (
  comment: Omit<ClientCommentFragment, "entity">
): EditLocalCommentAction => ({
  type: Type.EDIT_LOCAL_COMMENT,
  payload: comment,
})

export const deleteLocalComment = (
  comment_id: string
): DeleteLocalCommentAction => ({
  type: Type.DELETE_LOCAL_COMMENT,
  payload: { comment_id },
})
