import { Updater, useQueryClient } from "@tanstack/react-query"
import { useSelector } from "react-redux"

import { getUniqueId } from "Redux/reducers/test-builder/selectors/test-form"
import { GetTestBuilderResponse } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { Comment } from "~/api/generated/usabilityhubSchemas"

// This hook is a wrapper around tanstack-query's setQueryData specifically for the
// test builder API query.  It's used to perform manual cache updates after doing any mutations
// on that data, for example adding or deleting comments.
export const useTestBuilderCommentCache = () => {
  const queryClient = useQueryClient()
  const usabilityTestUniqueId = String(useSelector(getUniqueId))
  const { queryKeyFn } = useUsabilityhubContext()

  const testBuilderApiQueryKey = queryKeyFn({
    path: "/api/test_builder/{usability_test_unique_id}",
    operationId: "getTestBuilder",
    variables: { pathParams: { usabilityTestUniqueId } },
  })

  const updateQueryCache = (
    callback: Updater<GetTestBuilderResponse, GetTestBuilderResponse>
  ) => {
    queryClient.setQueryData<GetTestBuilderResponse>(
      testBuilderApiQueryKey,
      callback
    )
  }

  const addComment = (newComment: Comment) => {
    updateQueryCache((oldData) => {
      const newComments = [...(oldData?.comments ?? []), newComment]

      return {
        ...oldData,
        projects: oldData?.projects ?? [],
        comments: newComments,
      }
    })
  }

  const updateComment = (editedComment: Comment) => {
    updateQueryCache((oldData) => {
      const updatedCommentList = oldData.comments.map((c) => {
        return c.id === editedComment.id ? editedComment : c
      })

      return {
        ...oldData,
        projects: oldData.projects ?? [],
        comments: updatedCommentList,
      }
    })
  }

  const deleteComment = (deletedComment: Comment) => {
    updateQueryCache((oldData) => {
      const newComments =
        oldData?.comments.filter((c) => c.id !== deletedComment.id) ?? []

      return {
        ...oldData,
        projects: oldData?.projects ?? [],
        comments: newComments,
      }
    })
  }

  const markCommentsAsRead = (comments: Comment[]) => {
    const ids = comments.map((c) => c.id)

    updateQueryCache((oldData) => {
      const updatedCommentList = oldData.comments.map((c) => {
        if (ids.includes(c.id)) {
          return {
            ...c,
            is_read: true,
          }
        } else {
          return c
        }
      })

      return {
        ...oldData,
        projects: oldData.projects ?? [],
        comments: updatedCommentList,
      }
    })
  }

  return { addComment, updateComment, deleteComment, markCommentsAsRead }
}
