import {
  FigmaTaskScreen,
  FigmaTaskScreenResultMeta,
  ResponseSectionFigmaTask,
} from "Types"

import { getMisclickRate } from "./get-misclick-rate"
import { getScreenDuration } from "./get-screen-duration"

export function getScreenResultMeta(
  screen: FigmaTaskScreen,
  index: number,
  allScreens: FigmaTaskScreen[],
  task: ResponseSectionFigmaTask
): FigmaTaskScreenResultMeta {
  return {
    type: "individual",
    clicks: [screen.clicks],
    duration: getScreenDuration(allScreens, index, task.task_duration_ms),
    misclickRate: getMisclickRate(screen),
    participants: [task.id],
  }
}
