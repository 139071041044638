import { useState } from "react"

import { wrap } from "Math/number"

const UNSELECTED_INDEX = -1

interface CarouselState {
  selectedIndex: number
  isSelected: boolean
  handleSelectOption: (index: number) => void
  handleViewAll: () => void
  handlePrevious: () => void
  handleNext: () => void
}

export const useCarousel = (totalLength: number): CarouselState => {
  const [selectedIndex, setSelectedIndex] = useState<number>(UNSELECTED_INDEX)
  const isSelected = selectedIndex !== UNSELECTED_INDEX
  const handleSelectOption = (index: number) => {
    setSelectedIndex(index)
  }
  const handleViewAll = () => {
    setSelectedIndex(UNSELECTED_INDEX)
  }
  const selectIndex = (index: number) => {
    setSelectedIndex(wrap(index, totalLength))
  }
  const handlePrevious = () => {
    selectIndex(selectedIndex - 1)
  }
  const handleNext = () => {
    selectIndex(selectedIndex + 1)
  }
  return {
    selectedIndex,
    isSelected,
    handleSelectOption,
    handleViewAll,
    handlePrevious,
    handleNext,
  }
}
