import {
  Avatar,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react"
import { Field, FieldProps } from "formik"
import React, { useState } from "react"

const AVATAR_MAX_SIZE = 2 * 1024 * 1024

type AvatarField = React.FC<
  React.PropsWithChildren<{
    avatarUrl: string
    name: string
  }>
>

export const AvatarField: AvatarField = ({ avatarUrl, name }) => {
  const [src, setSrc] = useState<FileReader["result"]>(null)

  return (
    <Stack direction="row" align="center" spacing={2}>
      <Avatar size="md" name={name} src={(src as string) || avatarUrl} />
      <Field name="avatar">
        {({ field, form: { setFieldValue } }: FieldProps) => (
          <FormControl>
            <Input
              accept="image/*"
              id={field.name}
              onChange={(event: React.FormEvent<HTMLInputElement>) => {
                const target = event.currentTarget
                if (!target.files?.length) return
                const file = target.files[0]
                if (file.size > AVATAR_MAX_SIZE) {
                  target.value = ""
                  window.alert(
                    "Your picture must be less than 2 MB in size - please choose a smaller file."
                  )
                } else {
                  const fileReader = new FileReader()

                  fileReader.onload = (e) => {
                    if (!e.target) {
                      throw new Error(`Expected nullish event target`)
                    }

                    setSrc(e.target.result)
                  }
                  fileReader.readAsDataURL(file)
                  void setFieldValue(field.name, file)
                }
              }}
              type="file"
              display="none"
            />
            <Stack direction="row" align="center" spacing={2}>
              <Button
                as={FormLabel}
                size="sm"
                htmlFor={field.name}
                pb={0}
                cursor="pointer"
                mb={0}
              >
                Choose file
              </Button>
              <FormHelperText mt={0}>The maximum size is 2MB</FormHelperText>
            </Stack>
          </FormControl>
        )}
      </Field>
    </Stack>
  )
}
