import {
  Button,
  FormControl,
  FormErrorMessage,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"

import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Heading } from "DesignSystem/components"
import coinImage from "Images/app-illustrations/lyssna/coin.png"
import { isBadRequestError } from "Services/axios"
import { AlertCircleOutlineIcon } from "Shared/icons/untitled-ui/AlertCircleOutlineIcon"
import { reportErrorToSentry } from "Utilities/error"
import { useForm } from "react-hook-form"
import * as Yup from "yup"
import { useClaimCreditCode } from "~/api/generated/usabilityhub-components"

const ClaimFormSchema = Yup.object({
  code: Yup.string().required("Code is required"),
})

type FormValues = Yup.InferType<typeof ClaimFormSchema>

export const ClaimRoute: React.FC = () => {
  const queryClient = useQueryClient()
  const [successText, setSuccessText] = useState<string | null>()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setError,
    reset,
  } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(ClaimFormSchema),
    defaultValues: {
      code: "",
    },
  })

  const { mutateAsync: claimCode } = useClaimCreditCode({
    onSuccess: (data) => {
      void queryClient.invalidateQueries(["api", "account", "total_credits"])
      setSuccessText(
        `Offer code redeemed successfully. Congratulations, you${"\u2019"}ve claimed ${
          data.claimed_points
        } credits!`
      )
    },
    onError(error) {
      setError("code", { type: "custom", message: error.payload.message })
    },
  })

  const onSubmit = async (values: FormValues) => {
    try {
      await claimCode({
        body: {
          credit_code: values.code,
        },
      })
    } catch (error) {
      if (!isBadRequestError(error)) {
        reportErrorToSentry(error)
      }
    }
  }

  return (
    <Page title="Purchase credits">
      <UsabilityHubNavbar />
      <PageMain>
        <PageContent maxW="6xl" align="center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.code}>
              <VStack mt={2} spacing={6} p={16} w="450px">
                <Image src={coinImage} w={148} />
                <Heading as="h1" textStyle="ds.display.emphasized">
                  Claim Lyssna credits
                </Heading>
                {successText ? (
                  <>
                    <Text fontSize={16} color="text.default">
                      {successText}
                    </Text>
                    <Button
                      w="full"
                      variant="tab"
                      color="teal.500"
                      onClick={() => {
                        setSuccessText(null)
                        reset()
                      }}
                    >
                      Redeem another code
                    </Button>
                  </>
                ) : (
                  <>
                    <Text fontSize={16} color="text.default">
                      Enter your offer code below to redeem your free credits.
                    </Text>
                    <VStack w="full" spacing={0}>
                      <InputGroup>
                        <Input
                          width="full"
                          placeholder="Enter your code"
                          type="text"
                          backgroundColor="grey.900"
                          errorBorderColor="#fda29b"
                          {...register("code")}
                        />
                        {errors.code && (
                          <InputRightElement>
                            <AlertCircleOutlineIcon color="#e53e3e" />
                          </InputRightElement>
                        )}
                      </InputGroup>
                      {errors.code && (
                        <FormErrorMessage
                          w="full"
                          textAlign="start"
                          color="#f04438"
                          lineHeight={5}
                        >
                          {errors.code.message}
                        </FormErrorMessage>
                      )}
                    </VStack>
                    <Button
                      width="full"
                      isDisabled={isSubmitting || !isValid}
                      isLoading={isSubmitting}
                      type="submit"
                      colorScheme="brand.primary"
                    >
                      Submit code
                    </Button>
                  </>
                )}
              </VStack>
            </FormControl>
          </form>
        </PageContent>
      </PageMain>
    </Page>
  )
}
