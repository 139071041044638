import { Button, ButtonProps } from "@chakra-ui/react"
import React, { ChangeEvent } from "react"

import AutoResetInput from "Components/form/auto-reset-input"
import styles from "Components/form/file-input/file-input-button.module.scss"
import { getMimeTypes } from "Services/media-types"
import validateFiles, { ValidateFilesResult } from "Services/validate-files"
import { ScreenshotMediaType } from "Types"

type FileInputButton = React.FC<
  React.PropsWithChildren<
    {
      id: string
      allowedMediaTypes: ScreenshotMediaType[]
      maxFiles: number
      onFilesSelected: (validatedFiles: ValidateFilesResult) => void
    } & ButtonProps
  >
>

export const FileInputButton: FileInputButton = ({
  id,
  allowedMediaTypes,
  maxFiles,
  onFilesSelected,
  ...buttonProps
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onFilesSelected(
      validateFiles(event.target.files as FileList, {
        allowedMediaTypes,
        maxFiles,
      })
    )
  }

  const handleClick = (targetId: string) => {
    const targetElement = document.getElementById(targetId)
    if (targetElement !== null) {
      targetElement.click()
    }
  }

  const allowedMimeTypes = allowedMediaTypes.flatMap(getMimeTypes)

  return (
    <>
      <Button
        colorScheme="brand.secondary"
        onClick={() => handleClick(id)}
        {...buttonProps}
      />
      <AutoResetInput
        id={id}
        type="file"
        onChange={handleChange}
        accept={allowedMimeTypes.join(",")}
        multiple={maxFiles > 1}
        className={styles.input}
      />
    </>
  )
}
