import { UsabilityTestSection, UsabilityTestSectionType } from "Types"

// We have several representations of a test on the frontend, and this needs to handle them all:
// 1. The API response from useUsabilityTestPage
// 2. DashboardUsabilityTest
// 3. Anything that extends the RawParticipantUsabilityTest interface
// ...and probably others that will come up as we go
export const isExternalStudy = (
  test:
    | {
        sections: UsabilityTestSection[] | { section_type: string }[]
      }
    | { is_external_study: boolean }
) => {
  if ("is_external_study" in test && test.is_external_study) return true

  return (
    test &&
    "sections" in test &&
    test.sections.length === 1 &&
    ("type" in test.sections[0]
      ? test.sections[0].type
      : test.sections[0].section_type) ===
      UsabilityTestSectionType.ExternalStudy
  )
}
