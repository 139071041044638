import { useEffect } from "react"

export function useAllowGoogleTranslate() {
  useEffect(() => {
    const rootElement = document.documentElement
    rootElement.setAttribute("translate", "yes")

    const noTranslateMetaTag = document.querySelector(
      'head meta[name="google"]'
    )
    if (noTranslateMetaTag) {
      document.head.removeChild(noTranslateMetaTag)
    }

    return () => {
      rootElement.setAttribute("translate", "no")

      const newNoTranslateMetaTag = document.createElement("meta")
      newNoTranslateMetaTag.setAttribute("name", "google")
      newNoTranslateMetaTag.setAttribute("content", "notranslate")
      document.head.prepend(newNoTranslateMetaTag)
    }
  }, [])
}
