import { Divider, Heading, List, ListItem, Stack } from "@chakra-ui/react"
import {
  useCurrentAccount,
  useCurrentUser,
} from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { TeamMember } from "~/api/generated/usabilityhubSchemas"
import { UsersListItem } from "./UsersListItem"

type UsersList = React.FC<
  React.PropsWithChildren<{
    currentUserCanManageTeam: boolean
    hasPaidSeatAvailable: boolean
    listTitle: string
    users: TeamMember[]
    jumpToUserId?: number | null
  }>
>

export const UsersList: UsersList = ({
  currentUserCanManageTeam,
  hasPaidSeatAvailable,
  listTitle,
  users,
  jumpToUserId,
}) => {
  const currentAccount = useCurrentAccount()
  const currentUser = useCurrentUser()
  const currentUserIsAccountOwner = currentUser.id === currentAccount.owner_id

  return (
    <Stack spacing={0}>
      <Heading as="h3" mb={2}>
        {listTitle}
      </Heading>
      <Divider />
      <List>
        {users
          .sort((a, b) => a.name?.localeCompare(b.name))
          .map((user, index) => {
            const lastItem = users.length === index + 1
            const isAccountOwner = user.id === currentAccount.owner_id
            const isCurrentUser = user.id === currentUser.id

            // Can't edit the account owner if you're not the account owner.
            const isEditable = !(isAccountOwner && !currentUserIsAccountOwner)

            // Can't delete current user or account owner.
            const isDeletable = !isCurrentUser && !isAccountOwner

            // Can't change role of current user or account owner.
            const canChangeRole = !isCurrentUser && !isAccountOwner

            // Can make user the account owner if:
            // - Current user is the account owner
            // - Target user is not the account owner (ie. they are not us)
            // - Target user is currently an admin
            const canMakeAccountOwner =
              currentUserIsAccountOwner &&
              !isAccountOwner &&
              user.role === "admin"

            return (
              <ListItem
                borderBottomWidth={lastItem ? 0 : 1}
                key={user.id}
                pt={[2, 2, 1]}
                pb={[0, 0, 1]}
              >
                <UsersListItem
                  currentUserCanManageTeam={currentUserCanManageTeam}
                  hasPaidSeatAvailable={hasPaidSeatAvailable}
                  user={user}
                  canMakeAccountOwner={canMakeAccountOwner}
                  isAccountOwner={isAccountOwner}
                  isEditable={isEditable}
                  isDeletable={isDeletable}
                  canChangeRole={canChangeRole}
                  flashHighlight={user.id === jumpToUserId}
                />
              </ListItem>
            )
          })}
      </List>
    </Stack>
  )
}
