import { getHumanizedTimePeriodFromUTC } from "Utilities/time"
import {
  GetModeratedStudyApplicationsResponse,
  GetModeratedStudyBookingsResponse,
} from "~/api/generated/usabilityhub-components"
import {
  ModeratedStudy,
  ScreenerQuestion,
  TeamMember,
} from "~/api/generated/usabilityhubSchemas"
import { BookingStateBadgePropsState } from "../ParticipantBookingStateBadge"
import { Props as ParticipantDrawerScreenerAnswerProps } from "./ParticipantInfoDrawerScreenerAnswer"

export type Application =
  GetModeratedStudyApplicationsResponse["applications"][number]
type Booking = GetModeratedStudyBookingsResponse[number]

export type DrawerData = {
  attributes: {
    fullName: string | null
    eligibilityStatus: string
    bookingStatus: BookingStateBadgePropsState
    email: string | null
    source: Application["source"]
    method: string | null
    preferredName?: string
    location: GetModeratedStudyApplicationsResponse["applications"][number]["location"]
    age: number | null
    demographic_attribute_option_ids: number[]
    incentive?: string
    isPanelist: boolean
  }
  sessionDetails?: Partial<{
    bookingTime: string
    location: string
    hosts: (TeamMember | undefined)[]
  }>
  screenerAnswers?: (ParticipantDrawerScreenerAnswerProps | null)[]
}

export type ScreenerAnswer = NonNullable<
  Application["response"]
>["answers"][number]

export const extractDrawerData = ({
  currentApplication,
  currentBooking,
  moderatedStudyData,
  teamMembers,
}: {
  currentApplication: Application
  currentBooking?: Booking
  moderatedStudyData: ModeratedStudy
  teamMembers: TeamMember[]
}) => {
  if (!currentApplication) return undefined

  const drawerData: DrawerData = {
    attributes: {
      fullName: currentApplication.full_name,
      preferredName: currentApplication.preferred_name,
      email: currentApplication.email,
      eligibilityStatus: currentApplication.response?.outcome ?? "unknown",
      bookingStatus: currentApplication.status,
      location: currentApplication.location,
      age: currentApplication.age,
      demographic_attribute_option_ids:
        currentApplication.demographic_attribute_option_ids,
      source: currentApplication.source,
      method: currentApplication.recruitment_method,
      isPanelist: currentApplication.is_panelist,
      incentive: currentApplication.is_panelist
        ? undefined
        : (currentBooking?.incentive ?? undefined),
    },
  }

  if (
    currentBooking &&
    ["booked", "completed"].includes(currentApplication.status)
  ) {
    drawerData.sessionDetails = {
      bookingTime: getHumanizedTimePeriodFromUTC(
        currentBooking.starts_at,
        currentBooking.ends_at
      ),
      location: currentBooking.location,
      hosts: currentBooking.host_ids.map((id) =>
        teamMembers.find((m) => m.id === id)
      ),
    }
  }

  if (
    currentApplication.response &&
    currentApplication.response.answers.length > 0
  ) {
    drawerData.screenerAnswers = moderatedStudyData.screener_questions.map(
      getParticipantDrawerScreenerAnswerProps(currentApplication)
    )
  }

  return drawerData
}

const getParticipantDrawerScreenerAnswerProps =
  (application: Application) =>
  (
    screenerQuestion: ScreenerQuestion,
    questionIndex: number
  ): ParticipantDrawerScreenerAnswerProps | null => {
    if (!screenerQuestion) return null

    const screenerAnswer = application.response?.answers.find(
      (a) => a.question_id === screenerQuestion.id
    )
    if (!screenerAnswer) return null

    return {
      screenerQuestion,
      screenerAnswer,
      questionIndex,
    }
  }
