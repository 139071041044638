import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import React from "react"

import { DuplicateStudyModal } from "Components/duplicate-usability-test-modal/DuplicateStudyModal"
import { FunctionalModal } from "Utilities/modals/types"
import { useModal } from "Utilities/modals/use-modal"
import { useUsabilityTestPage } from "~/api/generated/usabilityhub-components"

type Props = {
  usabilityTestId: string
  handleAcceptWarning: () => void
}

export const HasResponsesWarningModal: FunctionalModal<Props> = ({
  usabilityTestId,
  handleAcceptWarning,
  isOpen,
  onClose,
}) => {
  const { open: openDuplicateTestModal } = useModal(DuplicateStudyModal)
  const { data } = useUsabilityTestPage({
    pathParams: { usabilityTestId },
  })

  const handleDuplicateTest = () => {
    onClose()
    data &&
      openDuplicateTestModal({
        usabilityTest: data.usability_test,
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Be careful — this test already has responses!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            They might be complete, or somebody may be responding right now.
          </Text>
          <Text>
            Switching images may result in all of your clicks being lost, and
            changing questions or instructions may invalidate the responses you
            {"\u2019"}ve already collected.
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button variant="outline" onClick={handleDuplicateTest} mr={3}>
              Duplicate this test instead
            </Button>
            <Button colorScheme="brand.primary" onClick={handleAcceptWarning}>
              I{"\u2019"}ll proceed with caution
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
