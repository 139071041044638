import { Box } from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import { useTranslate } from "Shared/hooks/useTranslate"
import React from "react"
import { useTestRecordingContext } from "../../context/TestRecordingContext"
import { shareScreen } from "../../useTestTakingRecordings"
import { StepContentProps } from "./RecordingSetupGuideContent"

export const ScreenSharingRequest: React.FC<StepContentProps> = ({
  setPassable,
}) => {
  const { screenStream, setScreenStream, requiresFullScreen } =
    useTestRecordingContext()

  const onShare = () => {
    if (screenStream) {
      setPassable(true)
    } else {
      const success = (stream: MediaStream) => {
        // @ts-ignore
        stream.oninactive = () => {
          setScreenStream && setScreenStream(undefined)
          setPassable(false)
        }
        setScreenStream && setScreenStream(stream)
        setPassable(true)
      }
      shareScreen(requiresFullScreen, success, () => {})
    }
  }

  const translate = useTranslate()

  return (
    <>
      <Box>
        {requiresFullScreen
          ? translate("test.recording.screen.full_screen_body")
          : translate("test.recording.screen.body")}
      </Box>
      <ThemedButton width="fit-content" onClick={onShare}>
        {translate("test.recording.screen.share_screen")}
      </ThemedButton>
    </>
  )
}
