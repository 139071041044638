import { ScaledCanvas } from "Utilities/canvas/scaled-canvas"
import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"

import { getClickMarkerColor } from "./get-click-marker-color"

export function drawClicks(
  canvas: ScaledCanvas,
  clicks: NormalizedClick[],
  pointRadius = 6
) {
  const ctx = canvas.get2DContext()

  clicks.forEach((click) => {
    const circle = new Path2D()
    circle.arc(
      click.x * canvas.getScaledWidth(),
      click.y * canvas.getScaledHeight(),
      pointRadius,
      0,
      2 * Math.PI
    )

    ctx.fillStyle = getClickMarkerColor(
      click.hit === undefined ? "default" : click.hit ? "hit" : "miss"
    )
    ctx.fill(circle)

    ctx.strokeStyle = "white"
    ctx.lineWidth = 2
    ctx.stroke(circle)
  })
}
