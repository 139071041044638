import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Tooltip,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Field, WrappedFieldProps } from "redux-form"

import { getSectionTypeRules } from "Constants/test-section-types"
import { useUndoToast } from "Hooks/use-undo-toast"
import { Dispatch } from "Redux/app-store"
import {
  deleteSection,
  duplicateSection,
} from "Redux/reducers/test-builder-form/action-creators/sections"
import { getFormValue } from "Redux/reducers/test-builder-form/selectors/formValues"
import { Pencil02SolidIcon } from "Shared/icons/untitled-ui/Pencil02SolidIcon"
import {
  TestCardHeading,
  TestCardTitle,
} from "UsabilityHub/components/TestCard/TestCard"
import {
  useSectionContext,
  useSectionIndexContext,
  useSectionTypeContext,
} from "UsabilityHub/contexts"
import { getFullSectionTitle } from "Utilities/get-full-section-title"
import { useMapFieldValue } from "Utilities/use-map-field-value"
import { useSelector } from "react-redux"
import { useUsabilityTestUserActivityContext } from "../../UsabilityTestUserActivityContext"
import { Actions, ActionsVisibilityProps } from "./Actions/Actions"
import { useTestLogicStatement } from "./TestLogicActions/useTestLogicStatement"

export function EditableSectionCardHeader() {
  const [editTitle, setEditTitle] = useState(false)
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const { readOnly } = useUsabilityTestUserActivityContext()
  const { colorScheme, icon, badgeText } = getSectionTypeRules(section.type)

  const sectionPath = `sections[${sectionIndex}]`

  const fullSectionTitle = getFullSectionTitle(section, sectionIndex)

  const { isEnabled } = useTestLogicStatement(sectionPath)
  const isRecordingEnabled = Object.values(
    useSelector(getFormValue(sectionPath))?.recording_attributes || {}
  ).includes(true)

  const [isActionsModalActive, setIsActionsModalActive] = useState(false)

  return (
    <>
      {!editTitle && (
        <>
          <TestCardTitle>
            <HStack>
              <Icon as={icon} boxSize={5} flexShrink={0} color={colorScheme} />
              <TestCardHeading>{fullSectionTitle}</TestCardHeading>
              {!readOnly && (
                <Box
                  sx={{
                    display: "none",
                    // This simulates groupHover functionality but for a specific named group
                    // since otherwise it conflicts with other groups in the hierarchy.
                    [`[data-group-hover='usability-test-section-header']:hover &`]:
                      {
                        display: "block",
                      },
                  }}
                >
                  <Tooltip hasArrow placement="top" label="Rename">
                    <IconButton
                      size="sm"
                      variant="ghost"
                      data-qa="rename-section"
                      onClick={() => setEditTitle(true)}
                      aria-label="rename section"
                      icon={
                        <Icon
                          color="gray.500"
                          boxSize={5}
                          as={Pencil02SolidIcon}
                        />
                      }
                    />
                  </Tooltip>
                </Box>
              )}
              {badgeText && <Badge colorScheme="pink">{badgeText}</Badge>}
            </HStack>
          </TestCardTitle>
          <Box
            visibility={
              isEnabled || isRecordingEnabled || isActionsModalActive
                ? "visible"
                : "hidden"
            }
            sx={{
              // This simulates groupHover functionality but for a specific named group
              // since otherwise it conflicts with other groups in the hierarchy.
              [`[id='section_${sectionIndex}']:hover &`]: {
                visibility: "visible",
              },
            }}
          >
            <SectionCardHeaderActions
              setIsActionsModalActive={setIsActionsModalActive}
            />
          </Box>
        </>
      )}

      {editTitle && !readOnly && (
        <Field
          name={`${sectionPath}.title`}
          component={SectionTitleEdit}
          props={{ onClose: () => setEditTitle(false) }}
        />
      )}
    </>
  )
}

interface SectionTitleEditProps extends WrappedFieldProps {
  onClose?: () => void
}

function SectionTitleEdit({ input, onClose }: SectionTitleEditProps) {
  const sectionType = useSectionTypeContext()
  const { defaultTitle } = getSectionTypeRules(sectionType)
  const [initialValue] = useState(input.value as string)
  const mappedInput = useMapFieldValue(input, (inputValue) => {
    if (inputValue === "") return null
    return inputValue
  })

  const handleCancel = () => {
    mappedInput.setValue(initialValue)
    onClose?.()
  }

  return (
    <Flex gap="2" w="full">
      <Box flexGrow={1}>
        <Input
          {...mappedInput}
          autoFocus
          type="text"
          placeholder={defaultTitle}
          maxLength={40}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault()
              onClose?.()
            }
          }}
        />
      </Box>
      <Button
        colorScheme="brand.primary"
        disabled={initialValue === mappedInput.value}
        onClick={onClose}
      >
        Save
      </Button>
      <Button variant="outline" onClick={handleCancel}>
        Cancel
      </Button>
    </Flex>
  )
}

const SectionCardHeaderActions: React.FC<ActionsVisibilityProps> = ({
  setIsActionsModalActive,
}) => {
  const sectionIndex = useSectionIndexContext()
  const { section } = useSectionContext()
  const dispatch: Dispatch = useDispatch()
  const { readOnly } = useUsabilityTestUserActivityContext()
  const undoToast = useUndoToast()

  const onDelete = () => {
    dispatch(deleteSection(sectionIndex))
    const sectionTitle =
      section.title ??
      `${getSectionTypeRules(section.type).defaultTitle} section`
    undoToast(sectionTitle)
  }
  const onDuplicate = () => dispatch(duplicateSection(sectionIndex))

  const formFieldName = `sections[${sectionIndex}]`
  const { isEnabled, setEnabled } = useTestLogicStatement(formFieldName)

  if (readOnly) return null

  return (
    <Actions
      onDuplicate={onDuplicate}
      onDelete={onDelete}
      isEnabled={isEnabled}
      setEnabled={setEnabled}
      isSection
      setIsActionsModalActive={setIsActionsModalActive}
    />
  )
}
