import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { FunctionalModal } from "Utilities/modals/types"
import { useRenameVariationSet } from "~/api/generated/usabilityhub-components"

interface Props {
  variationSet: {
    id: number
    unique_id: string
    name: string
  }
}

const RenameSetSchema = Yup.object({
  newName: Yup.string()
    .max(255, "Set name is too long")
    .required("Set name is required"),
})

type RenameVariationSet = Yup.InferType<typeof RenameSetSchema>

export const RenameVariationSetModal: FunctionalModal<Props> = ({
  variationSet,
  isOpen,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const {
    handleSubmit,
    register,
    formState: { isValid, isSubmitting, errors },
  } = useForm<RenameVariationSet>({
    mode: "all",
    resolver: yupResolver(RenameSetSchema),
    defaultValues: {
      newName: variationSet.name,
    },
  })

  const { mutateAsync: renameMutation } = useRenameVariationSet({
    onSuccess: () => {
      void queryClient.invalidateQueries(["api", "usability_tests"])
      void queryClient.invalidateQueries(["api", "studies"])
      void queryClient.invalidateQueries(["api", "test_sets", "variation_sets"])
      void queryClient.invalidateQueries([
        "api",
        "test_sets",
        variationSet.unique_id,
        "variation_set",
      ])
    },
  })

  const onSubmit: SubmitHandler<RenameVariationSet> = async ({ newName }) => {
    try {
      await renameMutation({
        pathParams: { uniqueId: variationSet.unique_id },
        body: { name: newName },
      })

      toast({
        title: "Variation set renamed",
        description: `Updated to ${newName}`,
        status: "success",
        duration: 3000,
      })

      onClose()
    } catch (error) {
      toast({
        title: "Variation set rename failed",
        status: "error",
        duration: 3000,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={!isSubmitting}
      closeOnOverlayClick={!isSubmitting}
    >
      <ModalOverlay>
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              <Heading>Rename variation set</Heading>
              <ModalCloseButton />
            </ModalHeader>

            <ModalBody>
              <FormControl isInvalid={!!errors.newName}>
                <FormLabel fontSize="sm">Set name</FormLabel>
                <Input {...register("newName")} autoFocus />
                {errors.newName && (
                  <FormErrorMessage>{errors.newName.message}</FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                isDisabled={!isValid}
                type="submit"
                colorScheme="brand.primary"
                isLoading={isSubmitting}
                ml={3}
              >
                Rename
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
