import {
  ISbResult,
  ISbRichtext,
  ISbStoryData,
  SbBlokData,
} from "@storyblok/react"
import { isArray } from "lodash"

// ts-prune-ignore-next
export type BaseCategory = {
  type: string
  id: string
  name: string
  count: number
}

// ts-prune-ignore-next
export type Team = BaseCategory & {
  type: "team"
}

// ts-prune-ignore-next
export type Methodology = BaseCategory & {
  type: "methodology"
}

// ts-prune-ignore-next
export type UseCase = BaseCategory & {
  type: "useCase"
}

export type Category = Team | Methodology | UseCase

export type Template = {
  id: string
  localTemplateId: string
  teams: Team[]
  methodologies: Methodology[]
  useCases: UseCase[]
  title: string
  shortDescription: string
  overview: string
  discover: string[]
  discoverContent: ISbRichtext | null
  howToUse: string[]
  image?: string | null
}

interface CategoryData extends ISbStoryData {
  id: number
  slug: string
  content: {
    component: "templateTeam" | "templateUseCase" | "templateMethodology"
    name: string
  }
}

interface TemplateBodyBlock extends SbBlokData {
  component: "templateSection"
  templateBody: {
    type: "doc"
    content: ISbRichtext[]
  }
}

interface TemplateData extends ISbStoryData {
  content: {
    component: "template"
    id: string
    templateId: string
    name: string
    team: CategoryData[]
    methodology: CategoryData[]
    useCase: CategoryData[]
    heroTemplate: {
      cover: {
        filename: string
      }
      subtitle: string
    }[]
    blocks: SbBlokData[]
  }
}

const isTemplateData = (data: ISbStoryData): data is TemplateData =>
  data.content.component === "template"

const isTemplateBodyBlock = (data: SbBlokData): data is TemplateBodyBlock =>
  data.component === "templateSection"

const FIELDS_BY_COMPONENT: Record<
  CategoryData["content"]["component"],
  "team" | "methodology" | "useCase"
> = {
  templateTeam: "team",
  templateMethodology: "methodology",
  templateUseCase: "useCase",
}

const hasCategory = (
  data: TemplateData,
  field: (typeof FIELDS_BY_COMPONENT)[keyof typeof FIELDS_BY_COMPONENT],
  slug: string
) => {
  const content = data.content[field] as CategoryData | CategoryData[]
  if (!content) return false
  if (isArray(content)) {
    return content.some((c: CategoryData) => c.slug === slug)
  } else {
    return (content as CategoryData).slug === slug
  }
}

export const CATEGORIES = ["useCase", "methodology", "team"] as const

export const FILTER_FIELDS = {
  useCase: "useCases",
  methodology: "methodologies",
  team: "teams",
} as const

export type AllCategories = {
  [key in (typeof FILTER_FIELDS)[keyof typeof FILTER_FIELDS]]: Map<
    string,
    Category
  >
}

export const loadTemplateData = (
  data: ISbResult["data"]
): { templates: Map<string, Template>; categories: AllCategories } => {
  const categories = data.reduce(
    (acc: AllCategories, story: ISbStoryData) => {
      if (!isTemplateData(story)) return acc

      CATEGORIES.forEach((category) => {
        story.content[category].forEach((c: CategoryData) => {
          acc[FILTER_FIELDS[category]].set(c.slug, {
            type: category,
            id: c.slug,
            name: c.name,
            count: data.filter(
              (s: ISbStoryData) =>
                isTemplateData(s) && hasCategory(s, category, c.slug)
            ).length,
          })
        })
      })

      return acc
    },
    { teams: new Map(), methodologies: new Map(), useCases: new Map() }
  )

  const getCategories = (story: TemplateData, field: Category["type"]) =>
    story.content[field]
      .map((c: CategoryData) => categories[FILTER_FIELDS[field]].get(c.slug))
      .filter(Boolean)

  const templates = data.reduce(
    (acc: Map<string, Template>, story: ISbStoryData) =>
      isTemplateData(story)
        ? acc.set(story.slug, {
            id: story.slug,
            localTemplateId: story.content.templateId ?? null,
            title: story.name,
            teams: getCategories(story, "team"),
            methodologies: getCategories(story, "methodology"),
            useCases: getCategories(story, "useCase"),
            shortDescription: story.content.heroTemplate?.[0]?.subtitle ?? "",
            overview: "",
            discover: [],
            discoverContent: getDiscoverContent(story),
            howToUse: [],
            image: story.content.heroTemplate?.[0]?.cover?.filename ?? null,
          })
        : acc,
    new Map<string, Template>()
  )

  return { categories, templates }
}

const getDiscoverContent = (templateData: TemplateData): ISbRichtext | null => {
  const discoverBlock =
    templateData.content.blocks?.find(isTemplateBodyBlock)?.templateBody

  if (!discoverBlock) return null

  const headingIndex = discoverBlock.content.findIndex(
    (snippet: ISbRichtext) =>
      snippet.type === "heading" &&
      snippet.content?.[0]?.text?.match(/template will help you discover/i)
  )

  if (headingIndex === -1) return null

  const content = discoverBlock.content[headingIndex + 1]

  return content ? { type: "doc", content: [content] } : null
}
