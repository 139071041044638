import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react"
import React, { FC } from "react"

export const NoResponse: FC<React.PropsWithChildren<unknown>> = () => (
  <Alert status="info">
    <AlertIcon />
    <AlertDescription>Did not participate</AlertDescription>
  </Alert>
)
export const OptionDeleted: FC<React.PropsWithChildren<unknown>> = () => (
  <Alert status="warning">
    <AlertIcon />
    <AlertDescription>
      Selected option has been removed from the test
    </AlertDescription>
  </Alert>
)
