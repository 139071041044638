import { ResponseAttribute } from "../types/attributes"

const attributeRegex = /attribute:(?<attribute>[\w\d]+)/

export function getAttributeCategoryFromCategory(
  category: string
): ResponseAttribute["category"] | undefined {
  const match = category.match(attributeRegex)!

  if (match.groups!.attribute) {
    return match.groups!.attribute as ResponseAttribute["category"]
  }

  return undefined
}
