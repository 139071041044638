import { Box, Flex, Image, Text } from "@chakra-ui/react"
import React from "react"

import { Heading } from "DesignSystem/components"
import coinImage from "Images/app-illustrations/lyssna/coin.png"

interface Props {
  readonly formattedTotal: string
  readonly formattedDiscount: string | null
}

export const WelcomePresent: React.FC<React.PropsWithChildren<Props>> = ({
  formattedTotal,
  formattedDiscount,
}) => {
  return (
    <Box bg="ds.background.information.subtle.resting" p={6} rounded="12px">
      <Flex flexDirection="column" gap={4}>
        <Flex gap={3} align="center">
          <Image src={coinImage} w={53} />
          <Text textStyle="ds.paragraph.primary">
            As a welcome present we are applying a {formattedDiscount} discount
            on your first order.
          </Text>
        </Flex>

        <Heading as="h4" textStyle="ds.heading.primary">
          Your total cost is {formattedTotal}
        </Heading>
      </Flex>
    </Box>
  )
}
