import {
  Box,
  Container,
  Flex,
  Grid,
  Stack,
  usePrevious,
} from "@chakra-ui/react"
import { LyssnaLogoIcon } from "Shared/icons/LyssnaLogoIcon"
import {
  AnimatePresence,
  MotionConfig,
  Variants,
  motion,
  useReducedMotion,
} from "framer-motion"
import React from "react"
import { Footer } from "./Footer"
import { useOnboardingContext } from "./OnboardingContextProvider"
import { Progress } from "./Progress"

const pageVariants: Variants = {
  initial: (direction: number) => ({
    x: direction > 0 ? "100%" : "-100%",
    opacity: 0,
  }),
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: (direction: number) => ({
    x: direction > 0 ? "-100%" : "100%",
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: [0.4, 0, 0.2, 1],
    },
  }),
}

const pageVariantsNoSlide: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: [0.4, 0, 0.2, 1],
    },
  },
}

const headerVariants: Variants = {
  out: {
    y: "-100%",
    opacity: 0,
  },
  in: {
    y: 0,
    opacity: 1,
  },
}

const animationVariants: Variants = {
  out: {
    opacity: 0,
    scale: 0,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
}

export const Layout: React.FC = () => {
  const { step, index } = useOnboardingContext()

  const indexWas = usePrevious(index)

  const direction = index - (indexWas ?? index)

  const shouldReduceMotion = useReducedMotion()

  return (
    <MotionConfig
      transition={{
        type: "spring",
        bounce: 0.3,
        duration: shouldReduceMotion ? 0 : 0.3,
      }}
    >
      <link
        href="https://fonts.googleapis.com/css2?family=Caveat:wght@400"
        rel="stylesheet"
      />
      <Grid
        minH="100vh"
        gridTemplate={{
          base: `"header" auto "main" 1fr "footer" auto/ 1fr`,
          xl: `"header illustration" auto "main illustration" 1fr "footer illustration" auto / 50% 50%`,
        }}
        placeContent="center"
        bg="bg.neutral.default"
      >
        <Container
          as={motion.header}
          key="header"
          maxW="532px"
          maxH={{ base: index ? undefined : "0", xl: undefined }}
          py={8}
          gridArea="header"
          variants={headerVariants}
          animate={index === 0 ? "out" : "in"}
        >
          <Flex justify="space-between" align="center">
            <LyssnaLogoIcon h="2rem" w="auto" />

            <Progress />
          </Flex>
        </Container>
        <Stack gridArea="main" justify={{ base: "start", md: "center" }}>
          <AnimatePresence initial={false} mode="popLayout" custom={direction}>
            {"component" in step && (
              <Container
                as={motion.div}
                key={step.id}
                variants={
                  shouldReduceMotion ? pageVariantsNoSlide : pageVariants
                }
                initial="initial"
                animate="animate"
                exit="exit"
                custom={direction}
                maxW="532px"
                display="grid"
                justifyContent="stretch"
                alignContent="center"
              >
                <Box as={step.component} step={step} />
              </Container>
            )}
          </AnimatePresence>
        </Stack>
        <Footer />
        <Grid
          gridArea="illustration"
          display={{ base: "none", xl: "grid" }}
          bg="bg.surface.callout"
          pos="relative"
          placeContent="center"
          overflow="hidden"
          userSelect="none"
        >
          <AnimatePresence mode="popLayout">
            {"animation" in step && (
              <Box
                as={motion.div}
                key={step.id}
                variants={animationVariants}
                initial="out"
                animate="in"
                exit="out"
              >
                <Box as={step.animation} />
              </Box>
            )}
          </AnimatePresence>
        </Grid>
      </Grid>
    </MotionConfig>
  )
}
