import { Box, Text } from "@chakra-ui/react"
import { useDroppable } from "@dnd-kit/core"
import React from "react"

import { useTranslate } from "Shared/hooks/useTranslate"
import { useTestBranding } from "UsabilityHub/components/UsabilityTest/context/testBranding"

export const NEW_CATEGORY_DROP_ZONE_PREFIX = "new-"

export const NewCategoryDropZone: React.FC<{ index: number }> = ({ index }) => {
  const { button_color } = useTestBranding()
  const { setNodeRef, isOver } = useDroppable({
    id: `${NEW_CATEGORY_DROP_ZONE_PREFIX}${index}`,
  })
  const translate = useTranslate()

  return (
    <Box opacity={isOver ? 1 : 0} transition="opacity 0.15s" flexGrow={1}>
      <Box
        ref={setNodeRef}
        minH="200px"
        borderColor={button_color}
        borderStyle="dashed"
        borderWidth={2}
        rounded="lg"
        p={2}
        bg={`${button_color}20`}
      >
        <Text fontSize="md" color={button_color} fontWeight="bold">
          {translate("test.card_sort.category_dropzone")}
        </Text>
      </Box>
    </Box>
  )
}
