import { createSelector } from "reselect"

import { State } from "Redux/app-store"

export const getTestBuilderState = (state: State) => {
  if (state.testBuilder === null) {
    throw new TypeError(`testBuilder state not initialized`)
  }
  return state.testBuilder
}
export const getInitialValues = createSelector(
  getTestBuilderState,
  (testBuilder) => testBuilder.initialValues
)
export const getComments = createSelector(
  getTestBuilderState,
  (testBuilder) => testBuilder.comments
)
export const getId = createSelector(
  getTestBuilderState,
  (testBuilder) => testBuilder.initialValues.id || null
)
export const getUniqueId = createSelector(
  getTestBuilderState,
  (testBuilder) => testBuilder.initialValues.unique_id || null
)
export const responseCount = createSelector(
  getTestBuilderState,
  (testBuilder) => testBuilder.responsesCount || 0
)
export const hasActiveOrders = createSelector(
  getTestBuilderState,
  (testBuilder) => testBuilder.hasActiveOrders || false
)
export const getIsRecruitmentLinkEnabled = createSelector(
  getTestBuilderState,
  (testBuilder) =>
    testBuilder.initialValues.is_recruitment_link_enabled || false
)
export const getIsPreviewClicked = createSelector(
  getTestBuilderState,
  (testBuilder) => testBuilder.isPreviewClicked
)
