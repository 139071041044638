import { useDisclosure } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import React from "react"
import { SavePresetModal } from "./SavePresetModal"

export const SaveAsPresetButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen}>Create a saved group</Button>
      {isOpen && <SavePresetModal onClose={onClose} />}
    </>
  )
}
