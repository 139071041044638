import { ListItem, Text } from "@chakra-ui/react"
import { COOKIES_DOCUMENT } from "Constants/legal-documents"
import {
  Document,
  DocumentIntro,
  DocumentList,
  DocumentSection,
} from "Shared/components/Document/Document"
import React from "react"
import { Helmet } from "react-helmet"

export const CookiesPage: React.FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <Helmet>
      <title>Cookies and tracking notice</title>
    </Helmet>
    <Document title="Cookies and tracking notice">
      <DocumentIntro>
        <Text>
          Last updated:{" "}
          <strong>
            {new Date(COOKIES_DOCUMENT.lastUpdated).toLocaleDateString(
              undefined,
              { dateStyle: "medium" }
            )}
          </strong>
          .
        </Text>
      </DocumentIntro>
      <DocumentSection title="Cookies and other tracking technologies">
        <Text>
          Lyssna and our third party partners use various technologies to
          collect information, such as cookies and web beacons.
        </Text>
        <Text>
          Cookies are small text files that are downloaded to your computer or
          mobile device by a website via your web browser or mobile app when you
          visit a website or other online service. Cookies store information
          about how you use an online service so that the website can recognize
          your device, provide functionality, enhance your experience (like
          remembering your preferred language), analyze usage patterns, and
          personalize content and advertising.
        </Text>
      </DocumentSection>
      <DocumentSection title="What types of technologies do we use?">
        <Text>
          We use cookies, web beacons and other technologies to improve and
          customize our products and websites ("the Services") and your
          experience; to allow you to access and use the Services without
          re-entering your username or password; to understand usage of our
          Services and the interests of our customers; to determine whether an
          email has been opened and acted upon; and to present you with
          advertising relevant to your interests.
        </Text>
      </DocumentSection>
      <DocumentSection title="How do we use them?">
        <DocumentList>
          <ListItem>
            <strong>Necessary cookies:</strong> These cookies are required to
            enable the basic features of this site, such as providing secure
            log-in or adjusting your consent preferences. These cookies do not
            store any personally identifiable data.
          </ListItem>
          <ListItem>
            <strong>Functional cookies:</strong> These cookies remember choices
            you make such as language or search parameters. We use these cookies
            to provide you with an experience more appropriate with your
            selections and to make your use of the Services more tailored.
          </ListItem>
          <ListItem>
            <strong>Analytics cookies:</strong> These cookies and similar
            technologies collect information on how you interact with the
            Services and enable us to improve how the Services operate.
          </ListItem>
          <ListItem>
            <strong>Advertisement cookies:</strong> These cookies collect are
            used to provide you with customized advertisements based on the
            pages you visited previously and to analyze the effectiveness of the
            ad campaigns.
          </ListItem>
        </DocumentList>
      </DocumentSection>
      <DocumentSection title="Disabling cookies">
        <Text>
          To opt-out of our use of cookies, you can instruct your browser to
          stop accepting cookies or to prompt you before accepting a cookie from
          websites you visit. If you do not accept cookies, you may not be able
          to use all aspects of our Services.
        </Text>
        <Text>
          Depending where you live, you may be able to opt out of functional,
          analytics and advertisement cookies by doing so via a cookie consent
          banner displayed on our website.
        </Text>
        <Text>
          You will not be able to opt-out of any cookies or other technologies
          that are necessary for the Services.
        </Text>
      </DocumentSection>
      <DocumentSection title="Updates to this Notice">
        <Text>
          This notice may be updated from time to time. If we make any changes,
          we will notify you by revising the "last updated" date at the top of
          this notice.
        </Text>
      </DocumentSection>
    </Document>
  </>
)
