import { Alert, AlertTitle, Box, Grid, HStack, Portal } from "@chakra-ui/react"
import { motion } from "framer-motion"
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import FullScreen from "react-fullscreen-crossbrowser"
import { usePopper } from "react-popper"
import { ExpandableNode } from "./ExpandableNode"
import { Legend } from "./Legend"
import { Popover } from "./Popover"
import { Toolbar } from "./Toolbar"
import { useSVGDownload } from "./useSVGDownload"
import {
  LaidOutLink,
  LaidOutNode,
  useTreeTestPathDiagramLayout,
} from "./useTreeTestPathDiagramLayout"

export const TreeTestPathDiagram = () => {
  const { roots, expand, collapse, width, height, horizontalGap, margin } =
    useTreeTestPathDiagramLayout()

  const [fullScreen, setFullScreen] = useState(false)

  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  const diagram = useRef<SVGSVGElement>(null)

  const previousWidth = useRef(width)

  // Automatically scroll new nodes into view when expanding
  useLayoutEffect(() => {
    if (!container) return

    const containerWidth = container.clientWidth

    if (width > previousWidth.current && width > containerWidth) {
      setTimeout(() => {
        container.scrollTo({
          // Only scroll one level at a time — if we're deep-expanding we don't want to scroll all the way
          left: container.scrollLeft + horizontalGap,
          behavior: "smooth",
        })
      }, 500)
    }

    previousWidth.current = width
  }, [width])

  const downloadAs = useSVGDownload()

  const download = useCallback(
    (format: "svg" | "png") => {
      const el = diagram.current
      if (!el) return

      // Set the viewBox to ensure the diagram is downloaded with the correct dimensions
      el.setAttributeNS(null, "viewBox", `0 0 ${width} ${height}`)
      el.setAttributeNS(null, "width", `${width}`)
      el.setAttributeNS(null, "height", `${height}`)

      downloadAs(format, el)

      // Remove the viewBox to avoid weird resizing effects because of preserveAspectRatio
      el.removeAttributeNS(null, "viewBox")
    },
    [width, height]
  )

  const [[popoverX, popoverY], setPopoverCoordinates] = useState<
    [number, number]
  >([0, 0])

  const virtualReference = useMemo(
    () => ({
      getBoundingClientRect() {
        return {
          top: popoverY,
          left: popoverX,
          bottom: popoverY,
          right: popoverX,
          width: 0,
          height: 0,
        } as DOMRect
      },
    }),
    [popoverX, popoverY]
  )

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )

  const [hovered, setHovered] = useState<LaidOutNode | LaidOutLink | null>(null)

  const { styles, attributes } = usePopper(virtualReference, popperElement, {
    placement: "bottom-start",
    modifiers: [{ name: "offset", options: { offset: [16, 16] } }],
  })

  const popperTimeout = useRef<number | null>(null)

  const nodeMouseOver = useCallback((node: LaidOutNode) => {
    if (popperTimeout.current) clearTimeout(popperTimeout.current)
    setHovered(node)
  }, [])

  const nodeMouseOut = useCallback(() => {
    if (popperTimeout.current) window.clearTimeout(popperTimeout.current)
    popperTimeout.current = window.setTimeout(() => setHovered(null), 500)
  }, [])

  const linkMouseOver = useCallback((link: LaidOutLink) => {
    if (popperTimeout.current) clearTimeout(popperTimeout.current)
    setHovered(link)
  }, [])

  const linkMouseOut = useCallback(() => {
    if (popperTimeout.current) clearTimeout(popperTimeout.current)
    popperTimeout.current = window.setTimeout(() => setHovered(null), 500)
  }, [])

  const clickedDepth = useRef(0)

  const nodeClicked = useCallback(
    (node: LaidOutNode, e: React.MouseEvent) => {
      if (!node.children.length) return

      clickedDepth.current = node.depth

      if (node.expanded) {
        collapse(node.id)
      } else {
        // Hold down [shift] to deep expand
        expand(node.id, e.shiftKey)
      }
    },
    [expand, collapse]
  )

  return (
    <FullScreen enabled={fullScreen} onChange={setFullScreen}>
      <Grid gridTemplateRows="auto 1fr" minH="100%" bg="white">
        <HStack justify="space-between" py={2}>
          <Legend />
          <Toolbar
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            onDownload={download}
          />
        </HStack>
        {roots.length === 0 && (
          <Alert status="info" mt={8}>
            <AlertTitle>No tree test data available</AlertTitle>
          </Alert>
        )}
        <Grid
          ref={setContainer}
          pos="relative"
          bg="grey.lightest"
          w="100%"
          gridTemplateRows="1fr"
          overflowX="auto"
        >
          <Box
            ref={diagram}
            as="svg"
            width={`${width}px`}
            height={`${height}px`}
            transition="all 0.5s ease-in-out"
            transitionDelay={width < previousWidth.current ? "0.5s" : "0s"}
            userSelect="none"
          >
            <g
              transform={`translate(${margin}, ${margin})`}
              onMouseMove={(e) =>
                setPopoverCoordinates([
                  e.pageX - document.documentElement.scrollLeft,
                  e.pageY - document.documentElement.scrollTop,
                ])
              }
            >
              {roots.map((node) => (
                <motion.g key={node.id} animate={{ y: node.y }}>
                  <ExpandableNode
                    node={node}
                    clickedDepth={clickedDepth.current}
                    horizontalGap={horizontalGap}
                    onClick={nodeClicked}
                    onNodeMouseOver={nodeMouseOver}
                    onNodeMouseOut={nodeMouseOut}
                    onLinkMouseOver={linkMouseOver}
                    onLinkMouseOut={linkMouseOut}
                  />
                </motion.g>
              ))}
            </g>
          </Box>
          <Portal>
            <Popover
              ref={setPopperElement}
              x={popoverX}
              y={popoverY}
              element={hovered}
              style={styles.popper}
              {...attributes.popper}
            />
          </Portal>
        </Grid>
      </Grid>
    </FullScreen>
  )
}
