import { Box, Stack } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import { RecruitPageUsabilityTest } from "UsabilityHub/hooks/useUsabilityTestRecruit"
import React from "react"
import type { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"
import { Order, ThirdPartyOrder } from "~/api/generated/usabilityhubSchemas"
import { UsabilityTestOrderSummaryCard } from "./UsabilityTestOrderSummaryCard"
import { UsabilityTestRecruitmentLinkSummaryCard } from "./UsabilityTestRecruitmentLinkSummaryCard"

interface Props {
  usabilityTestId: string
  orders: Order[]
  thirdPartyOrders: ThirdPartyOrder[]
  usabilityTest: RecruitPageUsabilityTest
  recruitmentLink: RecruitmentLink | null
  onManageLink?: () => void
}

type DecoratedOrder = { index: number } & (
  | (Order & { type: "usabilityhub" })
  | (ThirdPartyOrder & { type: "external" })
)

export const UsabilityTestRecruitmentList: React.FC<
  React.PropsWithChildren<Props>
> = ({
  usabilityTestId,
  orders,
  thirdPartyOrders,
  usabilityTest,
  recruitmentLink,
  onManageLink,
}) => {
  const decoratedOrders: DecoratedOrder[] = orders.map((order, i) => ({
    type: "usabilityhub",
    index: i,
    ...order,
  }))
  const decoratedExternalOrders: DecoratedOrder[] = thirdPartyOrders.map(
    (order, i) => ({ type: "external", index: i, ...order })
  )
  const unifiedOrderList = [
    ...decoratedOrders,
    ...decoratedExternalOrders,
  ].sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))

  if (
    unifiedOrderList.length === 0 &&
    !recruitmentLink?.active &&
    usabilityTest.recruited_response_count === 0
  ) {
    return null
  }

  return (
    <Box data-intercom-target="recruitment-activity-section" mb={20}>
      <Heading as="h2" mb={5} textStyle="ds.display.primary">
        Recruitment activity
      </Heading>
      <Stack spacing={2}>
        <UsabilityTestRecruitmentLinkSummaryCard
          usabilityTestId={usabilityTestId}
          recruitedResponseCount={usabilityTest.recruited_response_count}
          recruitmentLink={recruitmentLink}
          onManageLink={onManageLink}
        />

        {unifiedOrderList.map((order) => (
          <UsabilityTestOrderSummaryCard
            title={
              order.type === "usabilityhub"
                ? `Panel order ${order.index + 1}`
                : `External panel order ${order.index + 1}`
            }
            key={order.id}
            order={order}
            recordingTypes={usabilityTest.test_recording_types}
          />
        ))}
      </Stack>
    </Box>
  )
}
