import React from "react"

import styles from "./individual-answer-result.module.scss"
import { Props } from "./props"

export const RankingAnswer = (props: Props) => (
  <ol className={styles.list}>
    {props.answer.answers.map((a) => (
      <li key={a}>{a}</li>
    ))}
  </ol>
)
