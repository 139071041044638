import {
  Box,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  Stack,
} from "@chakra-ui/react"
import { countBy, get } from "lodash"
import React, { FocusEventHandler } from "react"
import { Draggable } from "react-beautiful-dnd"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Fields, FormSection, WrappedFieldsProps } from "redux-form"

import { Dispatch } from "Redux/app-store"
import {
  addCategory,
  deleteCategory,
  editCategory,
} from "Redux/reducers/test-builder-form/action-creators/card-sort"
import { getFormValues } from "Redux/reducers/test-builder-form/selectors/formValues"
import { CardSortCategory } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"

import { Trash01SolidIcon } from "Shared/icons/untitled-ui/Trash01SolidIcon"
import {
  DraggableQuestionRow,
  DraggableRowHandle,
} from "../SectionQuestions/DragDrop/DraggableQuestionRow"
import { useSetCustomValidity } from "../SectionQuestions/QuestionFields/MultipleChoice/useSetCustomValidity"
import { useShouldAutoFocus } from "../useShouldAutofocus"

interface CardSortSectionSingleCategoryProps {
  onEnterPress: (optionIndex: number) => void
  skipAutoFocus: boolean
  categoryIndex: number
}

const WrappedCardSortSectionSingleCategory: React.FC<
  WrappedFieldsProps & CardSortSectionSingleCategoryProps
> = ({ label, description, categoryIndex, skipAutoFocus, onEnterPress }) => {
  const dispatch: Dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const { section } = useSectionContext()
  const formValues = useSelector(getFormValues)
  const { readOnly } = useUsabilityTestUserActivityContext()

  const categoryCount =
    section.card_sort_attributes?.card_sort_categories_attributes.length ?? 0
  const showDescriptions =
    section?.card_sort_attributes?.show_category_descriptions
  const draggableId = `sections.${sectionIndex}.card_sort_attributes.card_sort_categories_attributes.${categoryIndex}`
  const isDeleteDisabled = categoryCount <= 1
  const autoFocus =
    useShouldAutoFocus(label.input.value) && !skipAutoFocus && categoryIndex > 0 // Won't focus on the first category on card sort initialization

  const labelInputRef = useSetCustomValidity(label.meta.error)

  const onDeleteOption = () => {
    dispatch(deleteCategory(sectionIndex, categoryIndex))
  }

  const onLabelPaste: React.ClipboardEventHandler = (e) => {
    const pastedText = e.clipboardData.getData("text")
    const textArray = pastedText.split("\n")

    // If there's no newlines we just paste the text into the input as normal
    if (textArray.length === 1) return

    // Otherwise we split the text on newlines and create a new category per line
    let categoryOffset = 0
    textArray.forEach((text, index) => {
      const fieldName = `sections[${sectionIndex}].card_sort_attributes.card_sort_categories_attributes`
      let calculatedIndex = categoryIndex + index + categoryOffset
      let existingCategory = get(
        formValues,
        `${fieldName}[${calculatedIndex}]`,
        null
      ) as CardSortCategory | null

      while (existingCategory && existingCategory._destroy) {
        categoryOffset += 1
        calculatedIndex += 1
        existingCategory = get(
          formValues,
          `${fieldName}[${calculatedIndex}]`,
          null
        ) as CardSortCategory | null
      }

      if (existingCategory) {
        // If there's already a category we just update the label
        dispatch(editCategory(sectionIndex, categoryIndex, text))
      } else {
        // For the other pasted rows we'll create a new category
        dispatch(addCategory(sectionIndex, text))
      }
    })

    // Ignore the original paste event otherwise we end up with an extra card
    e.preventDefault()
  }

  return (
    <Draggable
      draggableId={draggableId}
      key={draggableId}
      index={categoryIndex}
      isDragDisabled={readOnly}
    >
      {(draggable, snapshot) => (
        <DraggableQuestionRow draggableProvided={draggable} snapshot={snapshot}>
          <HStack py={2} px={2} data-qa={`card-sort-category-${categoryIndex}`}>
            {!readOnly && <DraggableRowHandle draggableProvided={draggable} />}

            <Stack flexGrow={1}>
              <FormControl isInvalid={!label.meta.valid}>
                <Input
                  ref={labelInputRef}
                  autoFocus={autoFocus}
                  isRequired
                  isReadOnly={readOnly}
                  onKeyDown={(evt) =>
                    evt.key === "Enter" && onEnterPress(categoryIndex)
                  }
                  onPaste={onLabelPaste}
                  placeholder="Category title"
                  data-role="category-label"
                  data-qa="card-sort-category-input"
                  fontWeight="bold"
                  _placeholder={{ fontWeight: "bold" }}
                  {...label.input}
                />
              </FormControl>
              {showDescriptions && (
                <Input
                  placeholder="Category description"
                  isInvalid={!description.meta.valid}
                  isReadOnly={readOnly}
                  onKeyDown={(evt) =>
                    evt.key === "Enter" && onEnterPress(categoryIndex)
                  }
                  {...description.input}
                />
              )}
            </Stack>

            <Box>
              <IconButton
                mr={-2}
                variant="ghost"
                aria-label="Delete category"
                icon={
                  <Icon color="gray.500" boxSize={5} as={Trash01SolidIcon} />
                }
                onClick={onDeleteOption}
                isDisabled={readOnly || isDeleteDisabled}
              />
            </Box>
          </HStack>
        </DraggableQuestionRow>
      )}
    </Draggable>
  )
}

export const CardSortSectionSingleCategory: React.FC<
  CardSortSectionSingleCategoryProps
> = ({ categoryIndex, skipAutoFocus, onEnterPress }) => {
  // Supress onBlur event since it updates empty strings to null
  const onBlur: FocusEventHandler<HTMLInputElement> = (event) =>
    event.preventDefault()
  const sectionIndex = useSectionIndexContext()

  return (
    <FormSection
      name={`sections[${sectionIndex}].card_sort_attributes.card_sort_categories_attributes[${categoryIndex}]`}
    >
      <Fields
        names={["label", "description"]}
        component={WrappedCardSortSectionSingleCategory}
        categoryIndex={categoryIndex}
        skipAutoFocus={skipAutoFocus}
        onEnterPress={onEnterPress}
        onBlur={onBlur}
        validate={validateCategory}
      />
    </FormSection>
  )
}

const validateCategory = (
  value: string | null,
  allValues: any,
  _props: any,
  name: string
) => {
  if (!name.endsWith(".label")) return
  if (!value) return

  const parent = name.replace(/\[\d+\]\.label$/, "")
  const allCategories = get(allValues, parent, []) as CardSortCategory[]
  const activeCategories = allCategories.filter((c) => !c._destroy)
  const countsByLabel = countBy(activeCategories, "label")

  return countsByLabel[value] > 1 ? "Duplicate category" : undefined
}
