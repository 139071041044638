import { Badge } from "@chakra-ui/react"
import React from "react"

import { GetModeratedStudyBookingsResponse } from "~/api/generated/usabilityhub-components"

export type BookingStateBadgePropsState =
  | GetModeratedStudyBookingsResponse[number]["state"]
  | "incentive_paid"
  | "incentive_not_paid"
  | "applied"
  | "invited"
  | "invitation_revoked"
  | "canceled"
  | "completed"
  | "reschedule_requested"
  | "resolved_added_slot"
  | "resolved_refunded_slot"

// Note there's a similar map in ApplicationStatusCell used for the applications table
const COLORS: { [colorKey in BookingStateBadgePropsState]: string } = {
  booked: "teal",
  applied: "blue",
  invited: "yellow",
  invitation_revoked: "gray",
  canceled_by_participant: "gray",
  canceled_by_researcher: "gray",
  declined_by_applicant: "orange",
  declined_by_researcher: "purple",
  canceled: "gray",
  complete: "green",
  auto_complete: "green",
  completed: "green",
  no_show: "red",
  incentive_paid: "green",
  incentive_not_paid: "orange",
  reschedule_requested: "orange",
  reported: "red",
  resolved_added_slot: "teal",
  resolved_refunded_slot: "teal",
  rescheduled_by_participant: "orange",
  rescheduled_by_researcher: "orange",
  declined_by_automatic: "orange",
}

const LABELS: { [labelKey in BookingStateBadgePropsState]: string } = {
  booked: "Booked",
  applied: "Applied",
  invited: "Invited",
  invitation_revoked: "Invite revoked",
  canceled_by_participant: "Canceled",
  canceled_by_researcher: "Canceled",
  declined_by_applicant: "Participant declined",
  declined_by_researcher: "Unsuitable",
  canceled: "Canceled",
  complete: "Completed",
  auto_complete: "Completed",
  completed: "Completed",
  no_show: "No-show",
  incentive_paid: "Incentive paid",
  incentive_not_paid: "Incentive pending",
  reschedule_requested: "Reschedule requested",
  reported: "Reported",
  resolved_added_slot: "Resolved",
  resolved_refunded_slot: "Resolved",
  rescheduled_by_participant: "Participant rescheduled",
  rescheduled_by_researcher: "Researcher rescheduled",
  declined_by_automatic: "Invite expired",
}

type BookingStateProps = {
  status: BookingStateBadgePropsState
}

const ParticipantBookingStateBadge: React.FC<BookingStateProps> = ({
  status,
}) => {
  const color = COLORS[status]

  if (!color) return null

  return (
    <Badge
      colorScheme={color}
      textTransform="none"
      borderRadius="md"
      fontSize="sm"
      fontWeight="medium"
      px={2}
      lineHeight="1.5rem"
    >
      {getBookingStatusLabel(status)}
    </Badge>
  )
}

export const getBookingStatusLabel = (
  status: BookingStateBadgePropsState,
  options?: { lowercase: boolean }
) => (options?.lowercase ? LABELS[status].toLowerCase() : LABELS[status])

export default ParticipantBookingStateBadge
