import { useSelector } from "react-redux"

import { makeGetFigmaTasksForSectionId } from "Redux/reducers/test-results/selectors"
import { ResponseSectionFigmaTask } from "Types"
import { useSectionContext } from "UsabilityHub/contexts"

export function useTasks() {
  const { section } = useSectionContext()
  const getAllResponseSectionFigmaTasks = makeGetFigmaTasksForSectionId(
    section.id
  )
  const allTasks = useSelector(getAllResponseSectionFigmaTasks)

  return allTasks as ResponseSectionFigmaTask[]
}
