import { Box, Button, Flex, Stack, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useDispatch } from "react-redux"
import { Field, WrappedFieldArrayProps } from "redux-form"

import { MAX_CARDS } from "Constants/test-section-types"
import { Dispatch } from "Redux/app-store"
import {
  addCard,
  deleteCardDescriptions,
  deleteCardImages,
} from "Redux/reducers/test-builder-form/action-creators/card-sort"
import { CardSortCard } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"

import { DroppableQuestionRows } from "../SectionQuestions/DragDrop/DroppableQuestionRows"
import { WrappedCheckbox } from "../WrappedCheckbox"

import { CardSortSectionSingleCard } from "./CardSortSectionSingleCard"
import { WrappedSwitch } from "./WrappedSwitch"

interface WrappedCardListProps {
  cards: CardSortCard[]
  sectionIndex: number
  onEnterPress: (optionIndex: number) => void
}

const WrappedCardList: React.FC<
  WrappedCardListProps & WrappedFieldArrayProps
> = ({ cards, sectionIndex, onEnterPress }) => {
  return (
    <Box px={6}>
      <DroppableQuestionRows
        path={`sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes`}
        droppableType="card"
      >
        {cards.map((card, index) =>
          card._destroy ? null : (
            <CardSortSectionSingleCard
              key={index}
              cardIndex={index}
              skipAutoFocus={false}
              onEnterPress={onEnterPress}
            />
          )
        )}
      </DroppableQuestionRows>
    </Box>
  )
}

export const CardSortSectionCardList: React.FC = () => {
  const dispatch: Dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const { section } = useSectionContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const cards = section?.card_sort_attributes?.card_sort_cards_attributes ?? []
  const nonDeletedCards = cards.filter((c) => !c._destroy)

  const onAddCard = () => {
    dispatch(addCard(sectionIndex))
  }

  const onEnterPress = (optionIndex: number) => {
    if (optionIndex === cards.length - 1) {
      onAddCard()
    } else {
      // Autofocus the first blank input box
      // `section_${sectionIndex}` is the id of `TestCard` component
      // Ref: ../SectionCard.tsx
      const input = document.querySelector(
        `#section_${sectionIndex} input[data-role="card-label"][value=""]:first-of-type`
      ) as HTMLElement
      input?.focus()
    }
  }

  const handleShowImagesChange = (checked: boolean) => {
    if (!checked) dispatch(deleteCardImages(sectionIndex))
  }

  const handleShowDescriptionsChange = (checked: boolean) => {
    if (!checked) dispatch(deleteCardDescriptions(sectionIndex))
  }

  return (
    <Stack spacing={6}>
      <Box>
        <Flex justify="space-between" align="center">
          <Text fontSize="sm">
            Cards (Press ⏎ for new line or paste a list. Max. {MAX_CARDS})
          </Text>
          <Flex gap={4}>
            <Field
              name={`sections[${sectionIndex}].card_sort_attributes.show_descriptions`}
              component={WrappedSwitch}
              label="Descriptions"
              handleCheckboxChange={handleShowDescriptionsChange}
              disabled={readOnly}
            />
            <Field
              name={`sections[${sectionIndex}].card_sort_attributes.show_images`}
              component={WrappedSwitch}
              label="Images"
              handleCheckboxChange={handleShowImagesChange}
              disabled={readOnly}
            />
          </Flex>
        </Flex>
        <Field
          name={`sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes`}
          component={WrappedCardList}
          onEnterPress={onEnterPress}
          sectionIndex={sectionIndex}
          cards={cards}
        />
        <VStack align="stretch" spacing={6} ml={6} mt={4}>
          {nonDeletedCards.length < MAX_CARDS ? (
            <Button
              alignSelf="flex-start"
              size="sm"
              colorScheme="brand.primary"
              isDisabled={readOnly}
              onClick={onAddCard}
            >
              Add another card
            </Button>
          ) : (
            <Text>Maximum cards reached ({MAX_CARDS})</Text>
          )}
          <Field
            name={`sections[${sectionIndex}].card_sort_attributes.shuffle_cards`}
            label="Randomize the order of cards"
            component={WrappedCheckbox}
            disabled={readOnly}
          />
        </VStack>
      </Box>
    </Stack>
  )
}
