import { formatDistanceToNowStrict, parseISO } from "date-fns/esm"
import React, { useEffect, useState } from "react"

type Props = {
  date: string | Date
  refreshInterval?: number
  addSuffix?: boolean
}

const REFRESH_INTERVAL_MS = 60_000 // 1 minute

export const AutoUpdatingRemainingTime: React.FC<Props> = ({
  date,
  refreshInterval = REFRESH_INTERVAL_MS,
  addSuffix = true,
}) => {
  let endDate: Date
  if (typeof date === "string") {
    if (date.length < 1) return null

    endDate = parseISO(date)
  } else {
    endDate = date
  }

  if (endDate <= new Date()) return null

  const [relativeTime, setRelativeTime] = useState(
    recalculateRemainingTime(endDate, addSuffix)
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setRelativeTime(recalculateRemainingTime(endDate, addSuffix))
    }, refreshInterval)

    return () => clearInterval(interval)
  })

  useEffect(() => {
    setRelativeTime(recalculateRemainingTime(endDate, addSuffix))
  }, [date])

  return <>{relativeTime}</>
}

const recalculateRemainingTime = (date: Date, addSuffix: boolean) =>
  formatDistanceToNowStrict(date, {
    addSuffix: addSuffix,
  })
