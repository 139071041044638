import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Grid,
  Heading,
  Tag,
  Text,
} from "@chakra-ui/react"
import { DemographicsContext } from "Components/test-results/context/demographics"
import { useFlatLocations } from "UsabilityHub/hooks/useFlatLocations"
import { getActiveDemographicAttributes } from "Utilities/demographics"
import {
  formatResponseSource,
  getResponseSource,
} from "Utilities/response-source"
import React, { useContext } from "react"
import { GetTestResultsIndividualResponseResponse } from "~/api/generated/usabilityhub-components"
import { QueryParameters } from "./QueryParameters"

interface Props {
  response: GetTestResultsIndividualResponseResponse
}

export const ResponseOverview: React.FC<React.PropsWithChildren<Props>> = ({
  response,
}) => {
  const profile = response.response_demographic_profile
  const { demographics } = useContext(DemographicsContext)
  const allLocations = useFlatLocations("usability_tests")
  const locationData =
    profile?.location &&
    allLocations[profile.location.type][profile.location.id]

  return (
    <>
      <Heading p={8}>Participant Information</Heading>
      {profile ? (
        <Grid
          gridTemplateColumns="max-content 1fr"
          alignItems="center"
          gap={3}
          p={8}
          pt={0}
        >
          {locationData && (
            <DemographicField
              label="Location"
              values={locationData.qualifiedName}
            />
          )}
          <DemographicField label="Age" values={profile.age} />

          {getActiveDemographicAttributes(
            profile.demographic_attribute_option_ids,
            demographics
          ).map((attribute) => (
            <DemographicField
              key={attribute.id}
              label={attribute.name}
              values={attribute.options.map((o) => o.value)}
            />
          ))}

          <DemographicField label="Device" values={response.device_type} />
          <DemographicField
            label="Operating system"
            values={response.platform}
          />
          <DemographicField
            label="Source"
            values={formatResponseSource(getResponseSource(response))}
          />
        </Grid>
      ) : (
        <Alert status="warning" px={8}>
          <AlertIcon />
          <Text>
            No demographic profile information could be found for this user.
          </Text>
        </Alert>
      )}

      <QueryParameters items={response.query_parameters} />
    </>
  )
}

interface DemographicField {
  label: string
  values: string[] | string | number | null
}

const DemographicField: React.FC<React.PropsWithChildren<DemographicField>> = ({
  label,
  values,
}) => {
  if (!values) return null

  return (
    <>
      <Text>{label}</Text>
      {Array.isArray(values) ? (
        <Flex flexWrap="wrap" gap={3}>
          {values.map((v) => (
            <Tag key={v} py={2}>
              {v}
            </Tag>
          ))}
        </Flex>
      ) : (
        <Box>
          <Tag py={2}>{values}</Tag>
        </Box>
      )}
    </>
  )
}
