import { AgeFilter } from "../filters/attribute-range"
import { UnknownAgeFilter } from "../filters/attribute-unknown"

import { generateFilterId } from "./generate-filter-id"

export function createAgeFilter(
  minAge: number | null,
  maxAge: number | null
): AgeFilter {
  return {
    id: generateFilterId(),
    category: "response/attribute:age",
    type: "attribute/range",
    value: {
      min: minAge,
      max: maxAge,
    },
  }
}

export function createUnknownAgeFilter(): UnknownAgeFilter {
  return {
    id: generateFilterId(),
    category: "response/attribute:age",
    type: "attribute/unknown",
    value: undefined,
  }
}
