import { Role } from "Types"

type RoleOption = {
  label: string
  name: string
  value: Role
  description: string
  shortDescription: string
  requiredFeature: string | null
  paid: boolean
}

export const ROLE_OPTIONS = [
  {
    label: "Admin",
    name: "Admin",
    value: "admin",
    description:
      "Admins can manage account and billing settings, manage the team, purchase credits, create and manage tests, spend account credits, and view tests and results.",
    shortDescription: "Full access including team and billing management",
    requiredFeature: null,
    paid: true,
  },
  {
    label: "Editor",
    name: "Editor",
    value: "member",
    description:
      "Editors can create tests and manage tests, view tests and their results and spend account credits.",
    shortDescription: "Can create tests and spend account credits",
    requiredFeature: "team_permissions",
    paid: true,
  },
  {
    label: "Viewer",
    name: "Viewer",
    value: "guest",
    description: `Viewers can view tests and results, but can${"\u2019"}t make new tests.`,
    shortDescription: `Can view results, but can${"\u2019"}t create tests`,
    requiredFeature: "viewer_permissions",
    paid: false,
  },
  {
    label: "Archived",
    name: "Archived Team Member",
    value: "archived",
    description:
      "Archived Team Members can only access their own profile. You can change their status any time.",
    shortDescription: "Temporarily disable access",
    requiredFeature: null,
    paid: false,
  },
  // ts-prune-ignore-next the satisfies really confuses ts-prune
] as const satisfies ReadonlyArray<RoleOption>
