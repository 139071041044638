import { Role } from "Types"

const _Role = (str: string) => str as Role

export const JOB_ROLES: Role[] = [
  _Role("Designer"),
  _Role("Researcher"),
  _Role("Marketer"),
  _Role("Product manager"),
  _Role("Founder / CEO"),
  _Role("Student"),
  _Role("Other"),
]
