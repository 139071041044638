import { AlertProps, Flex, Link } from "@chakra-ui/react"
import { Alert } from "DesignSystem/components"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { CalculatePriceAndEstimatedDurationResponse } from "~/api/generated/usabilityhub-components"
import { useOrderForm } from "../OrderFormProvider"
import { QuoteTable } from "../QuoteTable"
import { WelcomePresent } from "../WelcomePresent"
import { Panel } from "../types"
import { EstimatedTurnaround } from "./EstimatedTurnaround"
import { LyssnaPanelSubmitOrder } from "./LyssnaPanelSubmitOrder"
import { useLyssnaPanelOrderQuote } from "./useLyssnaPanelOrderQuote"

type Props = {
  setPanel: (panel: Panel) => void
}

export const LyssnaPanelTab: React.FC<Props> = ({ setPanel }) => {
  const { latestQuote, quoteState } = useLyssnaPanelOrderQuote()
  const canUserPurchaseCredits = useCurrentUser().can_purchase_credits
  const hasQuoteData = Boolean(
    latestQuote && (!("available" in latestQuote) || latestQuote.available)
  )

  return (
    <Flex flexDirection="column" gap={4}>
      {hasQuoteData && (
        <QuoteTable quote={latestQuote} quoteState={quoteState} />
      )}

      {latestQuote?.discount_applied && (
        <WelcomePresent
          formattedTotal={latestQuote.formatted_price}
          formattedDiscount={latestQuote.formatted_discount}
        />
      )}

      {latestQuote && (
        <LyssnaOrderQuoteErrors
          quote={latestQuote}
          canUserPurchaseCredits={canUserPurchaseCredits}
          setPanel={setPanel}
        />
      )}

      <LyssnaPanelSubmitOrder quote={latestQuote} quoteState={quoteState} />

      <EstimatedTurnaround
        isLoading={quoteState === "loading"}
        duration={latestQuote?.estimated_duration ?? null}
      />
    </Flex>
  )
}

type LyssnaOrderQuoteErrorsProps = {
  quote: CalculatePriceAndEstimatedDurationResponse
  canUserPurchaseCredits: boolean
  setPanel: (panel: Panel) => void
}

const LyssnaOrderQuoteErrors: React.FC<
  LyssnaOrderQuoteErrorsProps & AlertProps
> = ({ quote, canUserPurchaseCredits, setPanel }) => {
  const { testHasScreener, testIsExternal } = useOrderForm()

  const considerExternal = !testHasScreener && !testIsExternal

  if (!quote.estimated_duration) {
    return (
      <Alert
        status="warning"
        title="Cannot fulfill order"
        description={
          <>
            Sorry, we can{"\u2019"}t fulfill that order from the Lyssna panel.
            {considerExternal && (
              <>
                {" "}
                Consider{" "}
                <Link as="button" onClick={() => setPanel("external")}>
                  using the external panel
                </Link>
                .
              </>
            )}
          </>
        }
      />
    )
  }

  if (!canUserPurchaseCredits && quote.credits_to_be_purchased) {
    return (
      <Alert
        status="warning"
        description={`Placing this order costs money, but you don\u2019t have permission
          to use the credit card stored on this account. Please ask an account
          admin to place this order, or to pre-purchase credits for you to spend.`}
      ></Alert>
    )
  }

  return null
}
