import React from "react"
import { useSelector } from "react-redux"
import { Fields, FormSection } from "redux-form"

import { getScreenshots } from "Redux/reducers/screenshots/selectors"
import { getFieldByClientId } from "Redux/reducers/test-builder-form/selectors/form"
import {
  QuestionType,
  TestLogicStatement,
  TestLogicTarget,
  UsabilityTestSectionOrQuestion,
  UsabilityTestSectionType,
} from "Types"
import { getAnswers } from "Utilities/test-logic"
import { targetIsOptionalQuestion } from "../../../validators/validateOptionalTarget"
import { TestLogicActionsFields } from "./TestLogicActionsFields"

type TestLogicActionsForm = React.FC<
  React.PropsWithChildren<{
    formFieldName: string
    targets: ReadonlyArray<TestLogicTarget>
    testLogicStatement: TestLogicStatement
    type: "question" | "section"
  }>
>

export const TestLogicActionsForm: TestLogicActionsForm = ({
  formFieldName,
  testLogicStatement,
  targets,
  type,
}) => {
  const screenshots = useSelector(getScreenshots)
  const targetId = testLogicStatement._targetClientId || ""
  const selectedTargetField = useSelector(getFieldByClientId(targetId))
  const isTargetValid =
    selectedTargetField && targets.find((t) => t.targetClientId === targetId)
  const verb = determineVerb(selectedTargetField)
  const answers = isTargetValid
    ? getAnswers(selectedTargetField, screenshots)
    : []
  const name = `${formFieldName}.test_logic_statement`

  return (
    <FormSection name={name}>
      <Fields
        names={["behaviour", "_targetClientId", "values"]}
        component={TestLogicActionsFields}
        warn={{
          _targetClientId: targetIsOptionalQuestion,
        }}
        answers={answers}
        targets={targets}
        verb={verb}
        type={type}
      />
    </FormSection>
  )
}

const determineVerb = (
  selectedTargetField: UsabilityTestSectionOrQuestion | void
) => {
  if (selectedTargetField?.type === UsabilityTestSectionType.PrototypeTask)
    return "is"

  if (selectedTargetField?.type === QuestionType.CheckboxMultipleChoice)
    return "answer contains"

  return "answer is"
}
