import { useToast } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { debounce } from "lodash"
import { useCallback, useMemo } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { usePutApiModeratedStudiesId } from "~/api/generated/usabilityhub-components"
import { ModeratedStudy } from "~/api/generated/usabilityhubSchemas"

import { FORM_SAVE_DEBOUNCE_TIME, GENERIC_FORM_ERROR_TOAST } from "./common"

const ModeratedStudyDetailsFormSchema = Yup.object().shape({
  external_name: Yup.string()
    .min(2, "The study name must be at least 2 characters long")
    .max(100, "The study name must be 100 characters or less")
    .required("Please provide a name for the study"),
  description: Yup.string()
    .min(2, "The description must be at least 2 characters long")
    .required("Please provide a description for the study"),
})

type ModeratedStudyDetailsFormFields = Yup.InferType<
  typeof ModeratedStudyDetailsFormSchema
>

export const useStudyDetailsForm = (moderatedStudy: ModeratedStudy) => {
  const toast = useToast()

  const form = useForm({
    resolver: yupResolver(ModeratedStudyDetailsFormSchema),
    defaultValues: {
      external_name: moderatedStudy.external_name,
      description: moderatedStudy.description,
    },
  })

  const { handleSubmit, reset, getValues } = form

  const { mutateAsync: editModeratedStudy } = usePutApiModeratedStudiesId({
    onSuccess: () => reset(getValues(), { keepValues: true }),
    onError: () => toast(GENERIC_FORM_ERROR_TOAST),
  })

  const onStudyDetailsSubmit: SubmitHandler<ModeratedStudyDetailsFormFields> =
    useCallback(
      async (data) => {
        try {
          await editModeratedStudy({
            body: {
              ...data,
              internal_name: moderatedStudy.internal_name,
            },
            pathParams: { moderatedStudyId: moderatedStudy.id },
          })
        } catch (e) {
          toast({
            title:
              e.payload.message ?? "An error occurred while saving the study",
            status: "error",
          })
        }
      },
      [editModeratedStudy, moderatedStudy.id, moderatedStudy.internal_name]
    )

  const debouncedStudyDetailsSubmit = useMemo(
    () => debounce(handleSubmit(onStudyDetailsSubmit), FORM_SAVE_DEBOUNCE_TIME),
    [handleSubmit, onStudyDetailsSubmit]
  )

  return {
    ...form,
    handleSubmit: debouncedStudyDetailsSubmit,
  }
}
