import { Box, Flex, IconButton, Link, Text, Tooltip } from "@chakra-ui/react"
import { useIsAdminDashboard } from "Admin/hooks/use-is-admin-dashboard"
import { ResponseDuration } from "Components/order-responses-review/response-durations/response-durations"
import { useTestResultViewState } from "Components/test-results/context/test-result-view-state"
import { useFilteredResponses } from "Components/test-results/hooks/use-filtered-responses"
import { useIndividualResponseFilter } from "Components/test-results/hooks/use-individual-response-filter"
import { PreciseDuration } from "Components/time/precise-duration"
import { getUsabilityTest } from "Redux/reducers/test-results/selectors"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { ChevronUpOutlineIcon } from "Shared/icons/untitled-ui/ChevronUpOutlineIcon"
import { AdminUsabilityTest, ResponseSource } from "Types"
import { getAdminDashboardUsersLink } from "Utilities/get-admin-dashboard-users-link"
import { getResponseSource } from "Utilities/response-source"
import React from "react"
import { useSelector } from "react-redux"
import { GetTestResultsIndividualResponseResponse } from "~/api/generated/usabilityhub-components"

interface Props {
  readonly response: GetTestResultsIndividualResponseResponse
}

export const IndividualResponseHeader: React.FC<Props> = ({ response }) => {
  const isAdminDashboard = useIsAdminDashboard()
  // See comment in order-responses-review.tsx about why we have to do this typecast
  const usabilityTest = useSelector(getUsabilityTest) as AdminUsabilityTest
  const responses = useFilteredResponses()
  const { isReviewing } = useTestResultViewState()
  const { responseId, setResponseId } = useIndividualResponseFilter()

  const currentIndex = responses.findIndex((r) => r.id === responseId)

  // When a response is deleted, the IndividualResponseHeader briefly continues to appear, but the deleted responseId is no longer in the responses list.
  if (currentIndex === -1) return null

  const prevResponseId =
    responses[(currentIndex + responses.length - 1) % responses.length].id
  const nextResponseId = responses[(currentIndex + 1) % responses.length].id

  const estimatedDuration =
    response.estimated_duration_ms ||
    usabilityTest.last_estimated_duration_in_seconds * 1000

  const responseSource = getResponseSource(response)

  return (
    <Flex
      top={0}
      flexDirection="row"
      justifyContent="stretch"
      px={5}
      pt={7}
      pb={isReviewing ? 3 : 7}
      gap={2}
      bg="white"
      borderBottomWidth={1}
      borderBottomColor="gray.200"
      zIndex={1}
    >
      <Flex alignItems="center" w="full" gap={2}>
        <IconButton
          aria-label="View previous participant"
          icon={<ChevronUpOutlineIcon boxSize={6} />}
          variant="outline"
          color="brand.neutral.light"
          onClick={() => setResponseId(prevResponseId)}
        />
        <IconButton
          aria-label="View next participant"
          icon={<ChevronDownOutlineIcon boxSize={6} />}
          variant="outline"
          color="brand.neutral.light"
          onClick={() => setResponseId(nextResponseId)}
        />

        <Box flexGrow={1}>
          {isReviewing && (
            <Box fontSize="sm" color="gray.500">
              <Text>
                Estimated: <PreciseDuration ms={estimatedDuration} />
              </Text>
              <Text>
                Completed in <PreciseDuration ms={response.duration_ms} />
              </Text>
            </Box>
          )}
        </Box>

        {isReviewing && (
          <Flex ms="auto" mr={2}>
            <ResponseDuration
              layout="compact"
              response={response}
              usabilityTest={usabilityTest}
            />
          </Flex>
        )}

        {(isAdminDashboard || isReviewing) && (
          <>
            {responseSource === ResponseSource.UhPanel && (
              <Link
                href={getAdminDashboardUsersLink(String(response.user_id!))}
                isExternal
              >
                Admin view
              </Link>
            )}
            {responseSource === ResponseSource.Invite && (
              <Tooltip
                hasArrow
                label="Cannot link to the panelist page, since this response has come from a self-recruited participant"
                placement="top"
              >
                <Text as="i">Self-recruited response</Text>
              </Tooltip>
            )}
            {responseSource === ResponseSource.ExternalPanel && (
              <Tooltip
                hasArrow
                label="Cannot link to the panelist page, since this response has come from an external panel participant"
                placement="top"
              >
                <Text as="i">External panel response</Text>
              </Tooltip>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}
