import { useFigmaOAuthContext } from "UsabilityHub/contexts/FigmaOauthCredentialsContext"
import { useOAuth } from "UsabilityHub/hooks/useOAuth"
import { useCallback } from "react"
import { figmaOauth } from "~/api"

export const useFigmaOAuth = (onSuccess: () => void) => {
  const {
    currentUserHasFigmaOauthCredentials,
    setCurrentUserHasFigmaOauthCredentials,
  } = useFigmaOAuthContext()

  const onSuccessCallback = useCallback(() => {
    setCurrentUserHasFigmaOauthCredentials(true)
    onSuccess()
  }, [onSuccess])

  const { handleWithAuth: handleFigmaOAuth } = useOAuth(
    onSuccessCallback,
    "Figma",
    figmaOauth.redirect.path(),
    currentUserHasFigmaOauthCredentials
  )

  return {
    handleFigmaOAuth,
    hasFigmaOAuthCredentials: currentUserHasFigmaOauthCredentials,
  }
}
