import React from "react"

import { ScreenshotMediaType, UsabilityTestSectionType } from "Types"
import {
  useSectionIndexContext,
  useSectionTypeContext,
} from "UsabilityHub/contexts"

import { ScreenshotDropZone } from "./MediaRow/screenshots/screenshot-drop-zone"

interface SectionMediaDropZoneProps {
  allowedMediaTypes: ScreenshotMediaType[]
  maxFiles: number
}

export const SectionMediaDropZone: React.FC<
  React.PropsWithChildren<SectionMediaDropZoneProps>
> = ({ allowedMediaTypes, maxFiles }) => {
  const sectionType = useSectionTypeContext()
  const sectionIndex = useSectionIndexContext()

  // Autofocus the upload button if the section doesn't have any other fields to edit.
  const shouldAutoFocus = [
    UsabilityTestSectionType.FiveSecondTest,
    UsabilityTestSectionType.DesignQuestions,
  ].includes(sectionType)

  return (
    // TODO: Rebuild ScreenshotDropZone in Chakra.
    <ScreenshotDropZone
      id={`file_drop_zone_${sectionIndex}`}
      allowedMediaTypes={allowedMediaTypes}
      maxFiles={maxFiles}
      autoFocus={shouldAutoFocus}
    />
  )
}
