import { ListCreditBatchesResponse } from "~/api/generated/usabilityhub-components"

export const priceForCredits = (
  batches: ListCreditBatchesResponse["batches"],
  creditAmount: number,
  defaultPricePerCredit: number
): number => {
  // Find nearest discounted batch below `creditAmount`
  const nearestBatch = batches
    .sort((a, b) => b.amount - a.amount)
    .find((b) => b.amount <= creditAmount)
  if (nearestBatch) {
    return nearestBatch.price / nearestBatch.amount
  } else {
    // Fall back to default price per credit
    return defaultPricePerCredit
  }
}
