import { Box } from "@chakra-ui/react"
import React, { useEffect } from "react"

export const ExternalStudiesRedirect: React.FC = () => {
  const broadcast = () => {
    const bc = new BroadcastChannel("external_study_post_redirect")
    bc.postMessage("Redirect")
  }

  useEffect(() => {
    broadcast()
  }, [])

  return <Box p={4}>Please wait while we redirect you...</Box>
}
