import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useBoolean,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import Constants from "Constants/shared.json"
import teamsIcon from "Images/teams-logo.png"
import zoomIcon from "Images/zoom-logo.png"
import { AlertTriangleSolidIcon } from "Shared/icons/untitled-ui/AlertTriangleSolidIcon"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { Link03OutlineIcon } from "Shared/icons/untitled-ui/Link03OutlineIcon"
import { LinkExternal01OutlineIcon } from "Shared/icons/untitled-ui/LinkExternal01OutlineIcon"
import { ZoomSettingsModal } from "UsabilityHub/components/ZoomSettingsModal"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useIntegrations } from "UsabilityHub/hooks/useIntegrations"
import { useMicrosoftOAuth } from "UsabilityHub/hooks/useMicrosoftOAuth"
import { useZoomOAuth } from "UsabilityHub/hooks/useZoomOAuth"
import { AutoUploadRecordingSwitch } from "UsabilityHub/views/ModeratedStudy/interviewer/BookingCard/AutoUploadRecordingSwitch"
import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import { useModal } from "Utilities/modals/use-modal"
import React, { useState } from "react"
import { LocationType, useMeetingUrlForm } from "./forms/useMeetingUrlForm"

type Props = {
  meetingUrlForm: ReturnType<typeof useMeetingUrlForm>
}

export const LocationForm: React.FC<Props> = ({ meetingUrlForm }) => {
  const queryClient = useQueryClient()
  const { open: openZoomSettingsModal } = useModal(ZoomSettingsModal)
  const [
    isRemoveLocationModalVisible,
    { on: showRemoveLocationModal, off: hideRemoveLocationModal },
  ] = useBoolean(false)
  const [
    isConnectIntegrationModalVisible,
    { on: showConnectIntegrationModal, off: hideConnectIntegrationModal },
  ] = useBoolean(false)
  const [selectedLocationType, setSelectedLocationType] =
    useState<LocationType>(meetingUrlForm.watch("location_type"))
  const {
    moderatedStudyId,
    moderatedStudy: { members },
  } = useModeratedStudyContext()

  const { data: integrationData } = useIntegrations()

  const mainHost = members.find((member) => member.role === "main_host")
  const currentUser = useCurrentUser()

  const isCurrentUserMainHost = currentUser
    ? currentUser.id === mainHost?.id
    : false
  const isCurrentUserZoomIntegrated = members.find(
    (member) => member.id === currentUser?.id
  )?.has_zoom_oauth_credentials
  const isCurrentUserMicrosoftIntegrated = members.find(
    (member) => member.id === currentUser?.id
  )?.has_microsoft_oauth_credentials
  const isMainHostZoomIntegrated = mainHost?.has_zoom_oauth_credentials
  const isMainHostMicrosoftIntegrated =
    mainHost?.has_microsoft_oauth_credentials

  const { handleZoomOAuth } = useZoomOAuth(
    isCurrentUserZoomIntegrated,
    async () => {
      await queryClient.invalidateQueries([
        "api",
        "moderated_studies",
        moderatedStudyId,
      ])
      setLocationType("zoom")
      openZoomSettingsModal({})
    }
  )

  const { handleMicrosoftOAuth } = useMicrosoftOAuth(
    isCurrentUserMicrosoftIntegrated,
    async () => {
      await queryClient.invalidateQueries([
        "api",
        "moderated_studies",
        moderatedStudyId,
      ])
      setLocationType("teams")
      // TODO
      // openMicrosoftSettingsModal({})
    }
  )

  const integrationLocationsData = {
    zoom: {
      name: "Zoom",
      handleConnect: handleZoomOAuth,
    },
    teams: {
      name: "Microsoft Teams",
      handleConnect: handleMicrosoftOAuth,
    },
  } as const

  const {
    handleSubmit: handleMeetingUrlSubmit,
    formState: { errors: meetingUrlErrors },
    register,
    setValue: setMeetingUrlValue,
  } = meetingUrlForm

  const locationType = meetingUrlForm.watch("location_type")
  const autoUploadRecordings = meetingUrlForm.watch("auto_upload_recordings")
  const setLocationType = (locationType: LocationType) => {
    setSelectedLocationType(locationType)
    setMeetingUrlValue("location_type", locationType)
    if (locationType === "zoom") {
      // Enable auto-uploading recordings by default for Zoom
      setMeetingUrlValue("auto_upload_recordings", true)
    }
    handleMeetingUrlSubmit()
  }

  const changeLocationTypeToZoom = () => {
    if (isMainHostZoomIntegrated) {
      // Main host is zoom integrated, set location type freely
      setLocationType("zoom")
    } else if (isCurrentUserMainHost) {
      // Current user is main host but not yet connected to zoom, show a connect modal
      // If they confirm the modal, we'll trigger the oauth flow and then update the location type
      setSelectedLocationType("zoom")
      showConnectIntegrationModal()
    }
  }

  const changeLocationTypeToMicrosoftTeams = () => {
    if (isMainHostMicrosoftIntegrated) {
      setLocationType("teams")
    } else if (isCurrentUserMainHost) {
      // Current user is main host but not yet connected to Microsoft, show a connect modal
      // If they confirm the modal, we'll trigger the oauth flow and then update the location type
      setSelectedLocationType("teams")
      showConnectIntegrationModal()
    }
  }

  const removeLocationType = () => {
    if (
      locationType === "custom" &&
      meetingUrlForm.watch("meeting_url") !== ""
    ) {
      showRemoveLocationModal()
    } else {
      setLocationType("none")
    }
  }

  const zoomOptionEnabled =
    isMainHostZoomIntegrated || isCurrentUserMainHost || locationType === "zoom"

  // If you don't have Microsoft connected at all, we show the option as enabled
  // since we don't know whether you have Teams access or not yet.
  const doesCurrentUserHaveTeamsAccess =
    integrationData?.microsoft === null ||
    integrationData?.microsoft.teams_enabled

  const microsoftOptionEnabled =
    (doesCurrentUserHaveTeamsAccess &&
      (isMainHostMicrosoftIntegrated || isCurrentUserMainHost)) ||
    locationType === "teams"
  const microsoftOptionDisabledBecause = !doesCurrentUserHaveTeamsAccess
    ? "Your Microsoft account does not have access to Microsoft Teams."
    : !isCurrentUserMainHost
      ? "Only the main host can add a Microsoft Teams meeting link"
      : ""

  const learnMoreLink = (
    <Link
      display="inline-flex"
      alignItems="center"
      variant="noUnderline"
      isExternal
      href={Constants.HELP_CENTER_MANAGING_INTERVIEW_SESSIONS_URL}
    >
      Learn more.
      <LinkExternal01OutlineIcon ms={1} />
    </Link>
  )

  return (
    <form onChange={handleMeetingUrlSubmit}>
      <Flex flexDir="column" gap={2} align="flex-start">
        <FormLabel fontSize={16} fontWeight={500} mb={0}>
          Location
        </FormLabel>

        <Flex gap={4} w="full">
          <Menu>
            {locationType === "none" ? (
              <MenuButton
                as={Button}
                variant="outline"
                flexShrink={0}
                leftIcon={<Link03OutlineIcon boxSize={6} color="gray.400" />}
                rightIcon={<ChevronDownOutlineIcon />}
              >
                Manually add links to sessions
              </MenuButton>
            ) : (
              <MenuButton
                as={Button}
                variant="outline"
                flexShrink={0}
                leftIcon={
                  locationType === "zoom" ? (
                    <Image src={zoomIcon} boxSize={4} />
                  ) : locationType === "teams" ? (
                    <Image src={teamsIcon} boxSize={4} />
                  ) : (
                    <Link03OutlineIcon boxSize={4} color="gray.400" />
                  )
                }
                rightIcon={<ChevronDownOutlineIcon />}
              >
                {locationType === "zoom" ? (
                  <Flex align="center">Zoom</Flex>
                ) : locationType === "teams" ? (
                  <Flex align="center">Microsoft Teams</Flex>
                ) : (
                  "Custom"
                )}
              </MenuButton>
            )}

            <MenuList>
              <Tooltip
                hasArrow
                rounded="md"
                placement="top"
                label="Only the main host can add a Zoom meeting link"
                isDisabled={zoomOptionEnabled}
              >
                <MenuItem
                  onClick={changeLocationTypeToZoom}
                  icon={<Image src={zoomIcon} boxSize={6} />}
                  isDisabled={!zoomOptionEnabled}
                >
                  <Flex direction="column">
                    <Text color="text.primary">Zoom</Text>
                    <Text color="text.secondary">
                      Automatically generate a Zoom meeting for each new session
                    </Text>
                  </Flex>
                </MenuItem>
              </Tooltip>

              <Tooltip
                hasArrow
                rounded="md"
                placement="top"
                label={microsoftOptionDisabledBecause}
                isDisabled={microsoftOptionEnabled}
              >
                <MenuItem
                  onClick={changeLocationTypeToMicrosoftTeams}
                  icon={<Image src={teamsIcon} boxSize={6} />}
                  isDisabled={!microsoftOptionEnabled}
                >
                  <Flex direction="column">
                    <Text color="text.primary">Microsoft Teams</Text>
                    <Text color="text.secondary">
                      Automatically generate a Microsoft Teams meeting for each
                      new session
                    </Text>
                  </Flex>
                </MenuItem>
              </Tooltip>

              <MenuItem
                onClick={() => setLocationType("custom")}
                icon={<Link03OutlineIcon boxSize={6} color="gray.400" />}
              >
                <Flex direction="column">
                  <Text color="text.primary">Custom</Text>
                  <Text color="text.secondary">
                    Use the same meeting link for all new sessions
                  </Text>
                </Flex>
              </MenuItem>

              <MenuItem
                onClick={removeLocationType}
                icon={<Link03OutlineIcon boxSize={6} color="gray.400" />}
              >
                <Flex direction="column">
                  <Text color="text.primary">
                    Manually add links to sessions
                  </Text>
                  <Text color="text.secondary">
                    Manually add a different meeting link to each new session
                    after it{"\u2019"}s booked
                  </Text>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>

          {locationType === "zoom" && !isMainHostZoomIntegrated && (
            <Flex align="center" gap={2}>
              <AlertTriangleSolidIcon color="red.500" />
              <Text color="red.500">
                Main host{"\u2019"}s ({mainHost?.name}) Zoom account is not
                connected.
              </Text>
              {isCurrentUserMainHost && (
                <Button
                  variant="link"
                  colorScheme="teal"
                  fontSize="inherit"
                  onClick={showConnectIntegrationModal}
                >
                  Connect Zoom
                </Button>
              )}
            </Flex>
          )}

          {locationType === "teams" && !isMainHostMicrosoftIntegrated && (
            <Flex align="center" gap={2}>
              <AlertTriangleSolidIcon color="red.500" />
              <Text color="red.500">
                Main host{"\u2019"}s ({mainHost?.name}) Microsoft account is not
                connected.
              </Text>
              {isCurrentUserMainHost && (
                <Button
                  variant="link"
                  colorScheme="teal"
                  fontSize="inherit"
                  onClick={showConnectIntegrationModal}
                >
                  Connect Microsoft
                </Button>
              )}
            </Flex>
          )}

          {locationType === "custom" && (
            <FormControl
              margin={0}
              isInvalid={!!meetingUrlErrors.meeting_url}
              flexGrow={1}
            >
              <Input
                {...register("meeting_url")}
                type="text"
                placeholder="Provide a meeting link. e.g. Zoom, Google Meet, Microsoft Teams"
              />
              {meetingUrlErrors.meeting_url && (
                <FormErrorMessage>
                  {meetingUrlErrors.meeting_url.message}
                </FormErrorMessage>
              )}
            </FormControl>
          )}
        </Flex>

        {locationType === "zoom" && isMainHostZoomIntegrated && (
          <AutoUploadRecordingSwitch
            id={"auto-upload-recordings"}
            autoUploadRecordings={autoUploadRecordings}
            toggleAutoUploadRecordings={() =>
              setMeetingUrlValue(
                "auto_upload_recordings",
                !autoUploadRecordings
              )
            }
          />
        )}

        <Text fontSize="sm" color="text.secondary">
          {locationType === "none" && (
            <>
              No meeting links added. You can add or edit links up to 10 minutes
              before the session start time. {learnMoreLink}
            </>
          )}
          {locationType === "zoom" && isMainHostZoomIntegrated && (
            <>
              Connected to main host{"\u2019"}s ({mainHost?.name}){" "}
              {integrationLocationsData[locationType].name} account.{" "}
              {isCurrentUserMainHost && (
                <Button
                  variant="link"
                  colorScheme="teal"
                  fontSize="inherit"
                  onClick={openZoomSettingsModal}
                >
                  Configure <LinkExternal01OutlineIcon ms={1} />
                </Button>
              )}
            </>
          )}
          {locationType === "teams" && isMainHostMicrosoftIntegrated && (
            <>
              Connected to main host{"\u2019"}s ({mainHost?.name}){" "}
              {integrationLocationsData[locationType].name} account.{" "}
              {/* {isCurrentUserMainHost && (
                <Button
                  variant="link"
                  colorScheme="teal"
                  fontSize="inherit"
                  // TODO
                  // onClick={openMicrosoftSettingsModal}
                >
                  Configure <LinkExternal01OutlineIcon ms={1} />
                </Button>
              )} */}
            </>
          )}
          {locationType === "custom" && (
            <>
              This meeting link will be added to all new sessions. You can also
              add different links to each session. {learnMoreLink}
            </>
          )}
        </Text>
      </Flex>

      {isRemoveLocationModalVisible && (
        <RemoveLocationModal
          handleRemove={() => setLocationType("none")}
          handleClose={hideRemoveLocationModal}
        />
      )}

      {isConnectIntegrationModalVisible &&
        selectedLocationType !== "none" &&
        selectedLocationType !== "custom" && (
          <ConnectYourAccountModal
            integrationName={
              integrationLocationsData[selectedLocationType].name
            }
            handleConnect={
              integrationLocationsData[selectedLocationType].handleConnect
            }
            handleClose={() => {
              setSelectedLocationType(locationType)
              hideConnectIntegrationModal()
            }}
          />
        )}
    </form>
  )
}

type RemoveLocationModalProps = {
  handleClose: () => void
  handleRemove: () => void
}

const RemoveLocationModal: React.FC<RemoveLocationModalProps> = ({
  handleClose,
  handleRemove,
}) => {
  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Remove location?</ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={2}>
              <Text>
                Are you sure you want to remove this location? The future
                sessions will not have a meeting link added to them.
              </Text>

              <Text>
                <strong>Note:</strong> Links for previously booked sessions
                remain unchanged.
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.primary"
              onClick={() => {
                handleRemove()
                handleClose()
              }}
            >
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

type ConnectYourAccountModalProps = {
  integrationName: "Zoom" | "Microsoft Teams"
  handleClose: () => void
  handleConnect: () => void
}

const ConnectYourAccountModal: React.FC<ConnectYourAccountModalProps> = ({
  integrationName,
  handleClose,
  handleConnect,
}) => {
  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Connect your {integrationName} account</ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={2}>
              <Text>
                Connect your {integrationName} account to automatically add
                meeting links to future sessions.
              </Text>

              <Text>
                <strong>Note:</strong> Links for previously booked sessions
                remain unchanged.
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.primary"
              onClick={() => {
                handleConnect()
                handleClose()
              }}
            >
              Connect {integrationName}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
