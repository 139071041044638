import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react"
import { usersSignInPath } from "Shared/constants/routes"
import React from "react"
import { useDeleteAccount } from "~/api/generated/usabilityhub-components"

type ConfirmDeleteAccountModal = React.FC<
  React.PropsWithChildren<
    Pick<React.ComponentProps<typeof Modal>, "isOpen" | "onClose">
  >
>

export const ConfirmDeleteAccountModal: ConfirmDeleteAccountModal = ({
  isOpen,
  onClose,
}) => {
  const toast = useToast()

  const { mutate } = useDeleteAccount({
    onSuccess: () => {
      // Do a full page reload here to make sure there's no residual state hanging around
      window.location.href = usersSignInPath({
        queryParams: { message: "account_deleted" },
      })
    },
    onError: (error) => {
      if (error.status === 401) {
        // If you haven't recently authenticated this endpoint will return 401
        // which is handled by the global error handler, so no need to show a toast.
        return
      }

      toast({
        title: error.payload.message,
        status: "error",
      })
    },
    onSettled: () => {
      onClose()
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent borderRadius="md">
          <ModalHeader>Do you want to delete your account?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>We{"\u2019"}re just double checking for safety.</Text>
            <Text fontWeight="bold">
              Deleting your account is permanent. If you change your mind later,
              we won{"\u2019"}t be able to restore it for you.
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={() => mutate({})} colorScheme="red">
              I{"\u2019"}m sure I want to delete my account
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
