import { Alert, Box, Button, Text } from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"

import { getAllResponses } from "Redux/reducers/test-results/selectors"

import { useClearAllFilters } from "../hooks/use-clear-all-filters"
import { useFilteredResponses } from "../hooks/use-filtered-responses"

export const ParticipantFiltersActiveAlert = () => {
  const allResponses = useSelector(getAllResponses)
  const filteredResponses = useFilteredResponses()

  const clearAllFilters = useClearAllFilters()

  const omittedResponseCount = allResponses.length - filteredResponses.length

  if (omittedResponseCount === 0) return null

  return (
    <Box p={4}>
      <Alert status="info">
        <Text>
          {omittedResponseCount} participants hidden.
          <br />
          <Button variant="link" onClick={clearAllFilters}>
            Clear filters
          </Button>
        </Text>
      </Alert>
    </Box>
  )
}
