import { createAnswerTagFilterCategory } from "./create-filters/answer-tag-filter"
import { createCommonPathFilterCategory } from "./create-filters/common-path-filter"
import { createDemographicAttributeOptionIdFilterCategory } from "./create-filters/demographic-attribute-option-Id-filter"
import { createExactAnswerCategory } from "./create-filters/exact-answer-filter"
import { createHitzoneFilterCategory } from "./create-filters/hitzone-filter"
import { createPreferenceAnswerFilterCategory } from "./create-filters/preference-answer-filter"
import { createRankedAnswerFilterCategory } from "./create-filters/ranked-answer-filter"
import { ResponseFilter } from "./filters/types"
import { getFilterMeta } from "./get-filter-meta"
import {
  isAnswerTagFilterCategory,
  isCommonPathFilterCategory,
  isDemographicAttributeOptionIdFilterCategory,
  isExactAnswerCategory,
  isHitzoneFilterCategory,
  isPreferenceAnswerFilterCategory,
  isRankedAnswerFilterCategory,
} from "./is-category/is-repsponse-filter-category"

/**
 * Changing any of these shortened category names will break old URLs containing the old shortened category names
 */
export function compressResponseFilterCategory(
  category: ResponseFilter["category"]
) {
  if (category === "response/id") {
    return "rid"
  }

  if (category === "response/attribute:age") {
    return "saa"
  }

  if (category === "response/attribute:location") {
    return "ral"
  }

  if (category === "response/attribute:source") {
    return `ras`
  }

  if (isDemographicAttributeOptionIdFilterCategory(category)) {
    const categoryArray = category.split("/")
    const attributeName = categoryArray[categoryArray.length - 1]!
    return `rdaoid/${attributeName}`
  }

  if (isExactAnswerCategory(category)) {
    const { sectionId, questionId } = getFilterMeta(category)
    return `aex/${sectionId}/${questionId}`
  }

  if (isAnswerTagFilterCategory(category)) {
    const { sectionId, questionId } = getFilterMeta(category)
    return `at/${sectionId}/${questionId}`
  }

  if (isPreferenceAnswerFilterCategory(category)) {
    const { sectionId } = getFilterMeta(category)
    return `apr/${sectionId}`
  }

  if (isHitzoneFilterCategory(category)) {
    const { sectionId, screenshotId } = getFilterMeta(category)
    return `ahit/${sectionId}/${screenshotId}`
  }

  if (isRankedAnswerFilterCategory(category)) {
    const { sectionId, questionId } = getFilterMeta(category)
    return `ara/${sectionId}/${questionId}`
  }

  if (isCommonPathFilterCategory(category)) {
    const { sectionId } = getFilterMeta(category)
    return `aco/${sectionId}`
  }

  return category
}

export type CompressedCategory = ReturnType<
  typeof compressResponseFilterCategory
>

export function decompressResponseFilterCategory(
  compressedCategory: CompressedCategory
): ResponseFilter["category"] {
  if (compressedCategory === "saa") {
    return "response/attribute:age"
  }

  if (compressedCategory === "ral") {
    return "response/attribute:location"
  }

  if (compressedCategory === "ras") {
    return "response/attribute:source"
  }

  if (compressedCategory === "rid") {
    return "response/id"
  }

  if (compressedCategory.startsWith("rdaoid/")) {
    const splitCategory = compressedCategory.split("/")
    return createDemographicAttributeOptionIdFilterCategory(splitCategory[1])
  }

  if (compressedCategory.startsWith("aex/")) {
    const splitCategory = compressedCategory.split("/")
    return createExactAnswerCategory(
      parseInt(splitCategory[1]),
      parseInt(splitCategory[2])
    )
  }

  if (compressedCategory.startsWith("at/")) {
    const splitCategory = compressedCategory.split("/")
    return createAnswerTagFilterCategory(
      parseInt(splitCategory[1]),
      parseInt(splitCategory[2])
    )
  }

  if (compressedCategory.startsWith("apr/")) {
    const splitCategory = compressedCategory.split("/")
    return createPreferenceAnswerFilterCategory(parseInt(splitCategory[1]))
  }

  if (compressedCategory.startsWith("ahit/")) {
    const splitCategory = compressedCategory.split("/")
    return createHitzoneFilterCategory(
      parseInt(splitCategory[1]),
      parseInt(splitCategory[2])
    )
  }

  if (compressedCategory.startsWith("ara/")) {
    const splitCategory = compressedCategory.split("/")
    return createRankedAnswerFilterCategory(
      parseInt(splitCategory[1]),
      parseInt(splitCategory[2])
    )
  }

  if (compressedCategory.startsWith("aco/")) {
    const splitCategory = compressedCategory.split("/")
    return createCommonPathFilterCategory(parseInt(splitCategory[1]))
  }

  return compressedCategory as ResponseFilter["category"]
}
