import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react"
import { isEqual } from "lodash"
import React, { useEffect } from "react"

import {
  AvailableSlotList,
  CalendarDisplay,
} from "../../applicant/BookingCalendar"
import { useBookingCalendar } from "../../applicant/useBookingCalendar"

type Props = {
  moderatedStudyApplicationId: string
  timezone: string
  slot: string | null
  existingBookingId?: string
  onSlotChange: (slot: string | null) => void
  // For the booking page, we need to show a loading state for the whole page during the first time
  // retrieving the calendar data. If there is no available slots for the study, the page should be
  // redirected to the thank you page.
  //
  // If no available slots are found when users are browsing the page or when users are invited,
  // the page will show appropriate messages and a "Continue" button.
  //
  // For the reschedule page, it still show "Select a date to view available times" and "Book" button
  isFirstLoading: boolean
  setIsFirstLoading?: (isFirstLoading: boolean) => void
  setHasAvailableSlots?: (hasAvailableSlots: boolean) => void
  displayFullSlots?: boolean
}

export const BookingCalendar: React.FC<Props> = ({
  moderatedStudyApplicationId,
  timezone,
  slot,
  existingBookingId,
  onSlotChange,
  isFirstLoading,
  setIsFirstLoading,
  setHasAvailableSlots,
  displayFullSlots = false,
}) => {
  const {
    isCalendarOnlyPreview,
    year,
    month,
    setYearAndMonth,
    isLoading,
    isError,
    data,
    refetch,
    selectedDate,
    isRescheduling,
    slotsByDay,
    onDateChange,
  } = useBookingCalendar({
    moderatedStudyApplicationId,
    timezone,
    slot,
    existingBookingId,
    isFirstLoading,
    setIsFirstLoading,
    setHasAvailableSlots,
    onSlotChange,
  })

  useEffect(
    () => data && setHasAvailableSlots?.(data.has_available_booking_slots),
    [data?.has_available_booking_slots, setHasAvailableSlots]
  )

  if (isFirstLoading || isLoading)
    return (
      <Center gridColumn="1 / -1">
        <Spinner />
      </Center>
    )

  if (isError || !data)
    return (
      <Alert status="error" gridColumn="1 / -1">
        <AlertIcon />
        <AlertTitle>There was an error fetching booking slots!</AlertTitle>
        <AlertDescription>
          <Button colorScheme="red" onClick={() => refetch()}>
            Refresh
          </Button>
        </AlertDescription>
      </Alert>
    )

  return (
    <>
      <Box gridArea="calendar">
        <CalendarDisplay
          year={year}
          month={month}
          slotsByDay={slotsByDay}
          earlierData={data.earlier_data}
          laterData={data.later_data}
          setYearAndMonth={setYearAndMonth}
          selectedDate={selectedDate}
          onDateChange={onDateChange}
          hasAvailableSlots={!!data.has_available_booking_slots}
        />
      </Box>

      {selectedDate &&
      isEqual([year, month], [selectedDate[0], selectedDate[1]]) ? (
        <Stack gridArea="times" alignSelf="stretch" minHeight={0} mr={-6}>
          <AvailableSlotList
            slotsByDay={slotsByDay}
            selectedTimezone={timezone}
            selectedSlot={slot}
            selectedDate={selectedDate}
            setSelectedSlot={onSlotChange}
            leftAlignText={!!isCalendarOnlyPreview}
            displayFullSlots={displayFullSlots}
          />
        </Stack>
      ) : (
        <Center flexGrow={isCalendarOnlyPreview ? "1" : "0"}>
          <Flex
            color="text.secondary"
            textAlign="center"
            flexFlow="column"
            w={isCalendarOnlyPreview ? "100%" : "auto"}
            h={isCalendarOnlyPreview ? "100%" : "auto"}
          >
            {isRescheduling.current ? (
              "Select a date to view available times"
            ) : data.has_available_booking_slots ? (
              "No time slots available"
            ) : isCalendarOnlyPreview ? (
              <Center
                borderRadius={6}
                padding={4}
                backgroundColor="bg.surface.callout"
                justifyContent="center"
                alignItems="center"
                flex="1"
              >
                <Text fontSize="md">No time slots available</Text>
              </Center>
            ) : (
              <>
                <Text fontWeight="medium" mb={1}>
                  No time slots available
                </Text>
                <Text fontSize="sm">
                  Currently, all our time slots are booked. Please continue to
                  finish your application.
                </Text>
              </>
            )}
          </Flex>
        </Center>
      )}
    </>
  )
}
