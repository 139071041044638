import {
  Button,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import React from "react"

import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import UsersApi from "~/api/admin/usersApi"

type AdminUserActions = React.FC<{
  id: number
  email: string
  isOwner: boolean
  adminUserPath: string
}>

export const AdminUserActions: AdminUserActions = ({
  id,
  email,
  isOwner,
  adminUserPath,
}) => {
  return (
    <Menu placement="bottom-end" eventListeners={false} isLazy>
      <MenuButton as={Button} size="sm">
        Actions
        <Icon as={ChevronDownOutlineIcon} ml={1} />
      </MenuButton>
      <MenuList>
        <Link
          href={UsersApi.edit.path({ id })}
          _hover={{ textDecoration: "none" }}
        >
          <MenuItem>Edit</MenuItem>
        </Link>
        <Link
          href={UsersApi.sendPasswordReset.path({ id })}
          _hover={{ textDecoration: "none" }}
          data-method="POST"
          data-confirm={`Are you sure you want to send a password reset email to ${email}?`}
        >
          <MenuItem>Send password reset</MenuItem>
        </Link>
        <Link
          href={UsersApi.findTargetAccount.path({ id })}
          _hover={{ textDecoration: "none" }}
        >
          <MenuItem>Move to account</MenuItem>
        </Link>
        <Link
          href={UsersApi.makeOwner.path({ id })}
          _hover={{ textDecoration: "none" }}
          data-method="POST"
          data-confirm={`Are you sure you want to transfer account ownership to ${email}?`}
        >
          <MenuItem isDisabled={isOwner}>Make account owner</MenuItem>
        </Link>
        <Link
          href={UsersApi.logOut.path({ id })}
          _hover={{ textDecoration: "none" }}
          data-method="POST"
          data-confirm={`Are you sure you want to log this user out of all of their sessions? This will force them to log back in on every browser and device they use to access Lyssna.`}
        >
          <MenuItem>Log out</MenuItem>
        </Link>
        <MenuDivider m={0} />
        <Link
          href={adminUserPath}
          _hover={{ textDecoration: "none" }}
          style={{ color: "red", textDecorationColor: "red" }}
          data-method="DELETE"
          data-confirm={`Are you sure you want to delete ${email}? This action cannot be undone.`}
        >
          <MenuItem isDisabled={isOwner}>Delete</MenuItem>
        </Link>
      </MenuList>
    </Menu>
  )
}
