import React from "react"

import { createErrorContent } from "./create-error-content"

export function timedOutErrorContent() {
  return createErrorContent(
    <>
      <p className="para">
        <strong>Timed out</strong>
      </p>
      <p className="para">
        Sorry, you took longer than the maximum allowed time for a response.
      </p>
    </>
  )
}
