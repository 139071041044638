import { Button, Flex, FlexProps, Icon } from "@chakra-ui/react"
import { ArrowLeftIcon } from "@heroicons/react/solid"
import React from "react"
import { usePlanChangerContextOnScreen } from "./plan-changer-context"

const SALES_EMBED_CODE = "https://sales.lyssna.com/meetings/lyssna/talk-2-sales"

export const EnterpriseContact: React.FC<FlexProps> = (props) => {
  const { dispatch } = usePlanChangerContextOnScreen("enterprise-contact")

  return (
    <Flex direction="column" align="flex-start" h="full" {...props}>
      <Button
        variant="ghost"
        color="brand.primary.500"
        leftIcon={<Icon as={ArrowLeftIcon} />}
        onClick={() => dispatch({ type: "back-to-grid" })}
      >
        All plans
      </Button>

      <iframe
        title="Enterprise contact form"
        style={{ flexGrow: 1 }}
        width="100%"
        height="100%"
        src={SALES_EMBED_CODE}
      />
    </Flex>
  )
}
