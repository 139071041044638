import classNames from "classnames"
import React, { ReactNode } from "react"

import { BoxContent } from "Components/test-results/progress-box/progress-box"
import styles from "Components/test-results/section-results/expandable-section-screenshot-summary.module.scss"

interface ImplProps {
  className?: string
  summaryChildren: ReactNode
  detailsChildren: ReactNode
  isExpanded: boolean
  progress?: number
  isFilter: boolean
  isFiltered: boolean
}

export const ExpandableSectionScreenshotSummary: React.FC<
  React.PropsWithChildren<ImplProps>
> = (props) => {
  const {
    className,
    isExpanded,
    summaryChildren,
    detailsChildren,
    progress,
    isFilter,
    isFiltered,
    ...rest
  } = props

  return (
    <div
      className={classNames(
        styles.container,
        className,
        isFiltered && styles.isFiltered,
        isFilter && styles.isFilter
      )}
      {...rest}
    >
      <BoxContent progress={progress} flexWrap="wrap" rowGap={4}>
        {summaryChildren}
      </BoxContent>
      {isExpanded && <div className={styles.details}>{detailsChildren}</div>}
    </div>
  )
}
