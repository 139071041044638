import React, { Component, MouseEvent, Ref } from "react"

import styles from "Components/preference-test-layout/preference-grid.module.scss"
import { UnscaledScreenshotWithId } from "Components/screenshot/screenshot-with-id"
import { UsabilityTestSectionScreenshot } from "Types"
import { ScreenshotMask } from "UsabilityHub/components/UsabilityTestSectionTask/ScreenshotMask"

interface Props {
  innerRef?: Ref<HTMLDivElement>
  isTaskStarted: boolean
  onSelect: () => void
  onStartTask: () => void
  sectionScreenshot: UsabilityTestSectionScreenshot
}

class PreferenceItem extends Component<Props> {
  handleStart = (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation()
    this.props.onStartTask()
  }

  render() {
    const { innerRef, onSelect, sectionScreenshot, isTaskStarted } = this.props
    return (
      // biome-ignore lint/a11y/useKeyWithClickEvents: Only making the minimal set of changes to resolve PRD-4758. We should fix this in a follow-up.
      <div
        ref={innerRef}
        className={styles.item}
        onClick={isTaskStarted ? onSelect : undefined}
      >
        {/* TODO: Show device frame */}
        <UnscaledScreenshotWithId
          screenshotId={sectionScreenshot.screenshot_id}
          className={styles.image}
          canSkipAheadOnFirstWatch
        />
        {!isTaskStarted && <ScreenshotMask />}
      </div>
    )
  }
}
export default PreferenceItem
