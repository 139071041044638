import { Avatar, Flex, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import React, { useCallback } from "react"
import {
  Mention,
  MentionsInput,
  MentionsInputProps,
  SuggestionDataItem,
} from "react-mentions"

const StyledMentionsInput = styled(MentionsInput)`
  &__control {
    font-size: 14px;
    font-weight: normal;
  }

  &__highlighter,
  &__input {
    padding: 6px 12px;
  }

  &__input {
    outline-color: var(--chakra-colors-blue-500);
    /*
      chakra default input styling overrides this without the !important,
      and the textarea and highlighter fonts need
      to match exactly or everything goes crazy
    */
    font-weight: normal !important;
  }

  &__highlighter {
    z-index: 1;
    pointer-events: none;
  }

  &__suggestions {
    background: transparent !important;
  }
`

const StyledMention = styled(Mention)`
  color: var(--chakra-colors-blue-500);
  background-color: white;
`

type Props = Omit<MentionsInputProps, "children">

export const TextareaWithMentions: React.FC<Props> = ({ ...props }) => {
  const usersInTeam = useTeamMembers({
    onlyActive: true,
    ignoreRoles: ["archived"],
  })
  const userMentionData = usersInTeam.map((user) => ({
    id: user.id,
    display: user.name,
    avatarUrl: user.avatar_url_medium,
  }))

  const renderSuggestionContainer = useCallback((children: React.ReactNode) => {
    return (
      <Flex
        direction="column"
        bg="white"
        rounded="md"
        boxShadow="md"
        py={2}
        gap={2}
        maxH="244px"
        overflowY="auto"
      >
        {children}
      </Flex>
    )
  }, [])

  const renderSuggestion = useCallback(
    (
      suggestion: SuggestionDataItem & { avatarUrl: string },
      _search: string,
      highlightedDisplay: React.ReactNode,
      _index: number,
      focused: boolean
    ) => {
      return (
        <Flex bg={focused ? "gray.50" : undefined} px={4} py={2} gap={2}>
          <Avatar
            size="sm"
            boxSize={6}
            fontSize="xs"
            name={suggestion.display}
            src={suggestion.avatarUrl}
          />
          <Text>{highlightedDisplay}</Text>
        </Flex>
      )
    },
    []
  )

  return (
    <StyledMentionsInput
      autoFocus
      allowSpaceInQuery
      placeholder="Reply or add others with @"
      customSuggestionsContainer={renderSuggestionContainer}
      data-peer
      {...props}
    >
      <StyledMention
        trigger="@"
        data={userMentionData}
        displayTransform={(_id, display) => `@${display}`}
        renderSuggestion={renderSuggestion}
        appendSpaceOnAdd
      />
    </StyledMentionsInput>
  )
}
