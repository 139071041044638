import { Box, BoxProps, Button, ButtonProps } from "@chakra-ui/react"
import classNames from "classnames"
import React, { HTMLAttributes } from "react"

import styles from "Components/test-results/progress-box/progress-box.module.scss"
import {
  FilterToggle,
  Props as FilterToggleProps,
} from "Components/test-results/section-results/filter-toggle"
import { PreciseDuration } from "Components/time/precise-duration"
import { ParticipantIcon } from "Shared/icons/ParticipantIcon"
import { backgroundSizePercentage } from "Utilities/css-helpers"
import { formatPercentage01 } from "Utilities/number"

export * from "Components/test-results/progress-box/thumbnail"

interface BoxContentProps extends BoxProps {
  progress?: number
}

export const BoxContent = React.forwardRef<HTMLDivElement, BoxContentProps>(
  ({ className, children, progress, ...boxProps }, ref) => {
    let backgroundStyle
    if (progress !== undefined) {
      backgroundStyle = backgroundSizePercentage(progress, 1)
    }
    return (
      <Box
        ref={ref}
        className={classNames(
          styles.content,
          className,
          progress !== undefined && styles.progress
        )}
        style={backgroundStyle}
        {...boxProps}
      >
        {children}
      </Box>
    )
  }
)
BoxContent.displayName = "BoxContent"

interface ProgressBoxProps extends BoxContentProps {
  progress: number
  isFilter?: boolean
  isFiltered?: boolean
}

export const ProgressBox = React.forwardRef<HTMLDivElement, ProgressBoxProps>(
  (
    { isFilter = false, isFiltered = false, progress, className, ...boxProps },
    ref
  ) => {
    return (
      <BoxContent
        ref={ref}
        className={classNames(
          styles.box,
          className,
          isFilter && styles.isFilter,
          isFiltered && styles.isFiltered
        )}
        progress={progress}
        {...boxProps}
      />
    )
  }
)
ProgressBox.displayName = "ProgressBox"

interface NameProps {
  isLiteral?: boolean
}

export const Name: React.FC<React.PropsWithChildren<NameProps>> = ({
  children,
  isLiteral,
}) => (
  <div
    className={classNames(
      styles.name,
      isLiteral !== undefined && isLiteral === false && styles.isNotLiteral
    )}
  >
    {children}
  </div>
)

export const Item: React.FC<
  React.PropsWithChildren<HTMLAttributes<HTMLElement>>
> = ({ className, ...rest }) => (
  <div className={classNames(styles.item, className)} {...rest} />
)

interface DurationProps {
  duration: number | null
}

export const Duration: React.FC<React.PropsWithChildren<DurationProps>> = ({
  duration,
}) => (
  <div className={styles.duration}>
    <PreciseDuration ms={duration} />
  </div>
)

interface PercentageProps {
  className?: string
  percentage: number
}

export const Percentage: React.FC<React.PropsWithChildren<PercentageProps>> = ({
  className,
  percentage,
}) => (
  <div className={classNames(styles.percentage, className)}>
    {formatPercentage01(percentage)}
  </div>
)

interface ParticipantsProps {
  count: number
}

export const Participants: React.FC<
  React.PropsWithChildren<ParticipantsProps>
> = ({ count }) => (
  <div className={styles.participants}>
    <ParticipantIcon mr="5px" />
    {count}
  </div>
)

export const Filter: React.FC<React.PropsWithChildren<FilterToggleProps>> = (
  props
) => (
  <div className={styles.filter}>
    <FilterToggle {...props} />
  </div>
)

export const WrappedButton: React.FC<React.PropsWithChildren<ButtonProps>> = (
  buttonProps
) => {
  return (
    <div className={styles.buttonContainer}>
      <Button {...buttonProps} />
    </div>
  )
}
