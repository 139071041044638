import {
  Button,
  Circle,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Link,
  Select,
  Spacer,
  Stack,
} from "@chakra-ui/react"
import { ExternalLinkIcon, RefreshIcon } from "@heroicons/react/solid"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Field, WrappedFieldProps } from "redux-form"

import { Dispatch } from "Redux/app-store"
import {
  getNullableFigmaFileVersionById,
  getNullableFigmaFlowPrototypeUrl,
} from "Redux/reducers/figma-file-versions/selectors"
import { removeSectionPrototype } from "Redux/reducers/test-builder-form/action-creators/prototypes"
import { FigmaFileFlow } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { useSectionIndexContext } from "UsabilityHub/contexts"
import { longWithTime } from "Utilities/date-formats"

import { FigmaFlowFileNameTitle } from "../PrototypeSectionFigmaFlow"
import { useFigmaOAuth } from "../useFigmaOAuth"

import { Trash01SolidIcon } from "Shared/icons/untitled-ui/Trash01SolidIcon"
import { useSyncFigmaFlow } from "./useSyncFigmaFlow"

interface FigmaFlowEditorProps {
  figmaFileFlow: FigmaFileFlow
}
export const FigmaFlowEditor: React.FC<
  React.PropsWithChildren<FigmaFlowEditorProps>
> = ({ figmaFileFlow }) => {
  const dispatch: Dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const figmaFileVersion = useSelector(
    getNullableFigmaFileVersionById(figmaFileFlow.figma_file_version_id)
  )
  const { isSyncing, syncFigmaFlow } = useSyncFigmaFlow(figmaFileFlow)
  // OAuth hook with syncFigmaFlow as success callback
  const { handleFigmaOAuth } = useFigmaOAuth(syncFigmaFlow)
  const { readOnly } = useUsabilityTestUserActivityContext()

  const removePrototype = () => {
    dispatch(removeSectionPrototype(sectionIndex))
  }

  const figmaFlowPrototypeUrl = useSelector(
    getNullableFigmaFlowPrototypeUrl(figmaFileFlow)
  )

  if (!figmaFileVersion) {
    return null
  }

  return (
    <>
      <Stack flexGrow={1}>
        <Flex>
          <FigmaFlowFileNameTitle>
            {figmaFlowPrototypeUrl && (
              <Link
                isExternal
                href={figmaFlowPrototypeUrl}
                tabIndex={-1}
                color="text.primary"
                textDecorationLine="none"
                _groupHover={{ textDecorationLine: "underline" }}
              >
                {figmaFileVersion.name}
                <Icon as={ExternalLinkIcon} ml={0.5} />
              </Link>
            )}
          </FigmaFlowFileNameTitle>
          <Spacer />
          <HStack>
            <Circle size="10px" bg="green.500" />
            <FormLabel color="gray.500" mb={0}>
              Last synced on{" "}
              {longWithTime(new Date(figmaFileVersion.last_synced_at))}
            </FormLabel>
          </HStack>
        </Flex>
        <Flex alignItems="center">
          <ScalingField />
          <Spacer />
          <HStack spacing={2}>
            <Button
              isLoading={isSyncing}
              loadingText="Syncing with Figma"
              isDisabled={readOnly}
              onClick={handleFigmaOAuth}
              size="sm"
              variant="outline"
              leftIcon={<Icon as={RefreshIcon} />}
              alignSelf="flex-start"
              data-qa="sync-with-figma-button"
            >
              Resync with Figma
            </Button>
            <Button
              aria-label="Remove prototype"
              size="sm"
              variant="outline"
              leftIcon={
                <Icon color="gray.500" boxSize={4} as={Trash01SolidIcon} />
              }
              isDisabled={readOnly}
              onClick={removePrototype}
            >
              Remove
            </Button>
          </HStack>
        </Flex>
      </Stack>
    </>
  )
}

const WrappedScalingField: React.FC<
  React.PropsWithChildren<WrappedFieldProps>
> = ({ input }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <FormControl id={input.name}>
      <HStack alignItems="center" marginTop={3}>
        <FormLabel fontSize="sm" marginBottom={0}>
          Scaling
        </FormLabel>
        <Select size="sm" w="2xs" isDisabled={readOnly} {...input}>
          <option value="scale_down_width">
            Width &ndash; Scale down to fit width
          </option>
          <option value="min_zoom">100% &ndash; Display at full size</option>
          <option value="scale_down">Fit &ndash; Scale down to fit</option>
          <option value="contain">Fill &ndash; Scale down or up to fill</option>
        </Select>
      </HStack>
    </FormControl>
  )
}

const ScalingField: React.FC<React.PropsWithChildren<unknown>> = () => {
  const sectionIndex = useSectionIndexContext()
  const sectionPath = `sections[${sectionIndex}]`
  return (
    <Field
      name={`${sectionPath}.figma_file_flow.scaling`}
      component={WrappedScalingField}
    />
  )
}
