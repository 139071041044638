import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query"

import {
  ListProjectsError,
  ListProjectsQueryParams,
  ListProjectsResponse,
  fetchListProjects,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

export const useInfiniteListProjects = (
  queryParams: Omit<ListProjectsQueryParams, "cursor">,
  options?: Omit<
    UseInfiniteQueryOptions<ListProjectsResponse, ListProjectsError>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useUsabilityhubContext()

  return useInfiniteQuery<ListProjectsResponse, ListProjectsError>(
    queryKeyFn({
      path: "/api/projects",
      operationId: "listProjects",
      variables: {
        queryParams: {
          ...fetcherOptions.queryParams,
          ...queryParams,
        },
      },
    }),
    (context) =>
      fetchListProjects({
        ...fetcherOptions,
        queryParams: {
          ...queryParams,
          ...(context.pageParam ? { cursor: context.pageParam as string } : {}),
        },
      }),
    {
      ...queryOptions,
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.next_page ?? undefined,
      retry: false,
    }
  )
}
