import { TreeTest } from "Types"

export const validateTreeTest = (
  treeTest: TreeTest | null
): { _error: string } | undefined => {
  if (!treeTest) return undefined

  if (!treeTest.nodes?.length) {
    return { _error: "Your tree must have at least one node" }
  }

  if (!treeTest.correct_nodes?.length) {
    return {
      _error:
        "You must select at least one correct node in the tree you have created above",
    }
  }
}
