import React from "react"
import { Helmet } from "react-helmet"

import { useHashFragment } from "Shared/hooks/useHashFragment"
import { SecurityInformationDocument } from "./SecurityInformationDocument"

type Security = React.FC<React.PropsWithChildren<unknown>>

export const SecurityRoute: Security = () => {
  useHashFragment()
  return (
    <>
      <Helmet>
        <title>Security information</title>
      </Helmet>
      <SecurityInformationDocument />
    </>
  )
}
