import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react"
import { PurchasesMailtoLink } from "Shared/components/Links/PurchasesMailtoLink"
import { usePlan } from "UsabilityHub/hooks/usePlans"
import { getDateString } from "Utilities/date-formats"
import { isPast } from "date-fns"
import React from "react"
import { GetCurrentAccountResponse } from "~/api/generated/usabilityhub-components"
import { Plan } from "~/api/generated/usabilityhubSchemas"

type ManualSubscriptionDetails = React.FC<
  React.PropsWithChildren<{
    activeManualSubscription?: GetCurrentAccountResponse["manual_subscriptions"][number]
    pendingManualSubscription?: GetCurrentAccountResponse["manual_subscriptions"][number]
    plan: Plan
  }>
>

export const ManualSubscriptionDetails: ManualSubscriptionDetails = ({
  activeManualSubscription,
  pendingManualSubscription,
  plan,
}) => (
  <Stack>
    <Heading>Subscription</Heading>
    <Alert alignItems="flex-start" status="info">
      <AlertIcon />
      <AlertTitle>
        Your account uses manual invoice billing for subscriptions
      </AlertTitle>
      <AlertDescription>
        {activeManualSubscription ? (
          <>
            <Text>
              Your current subscription to the {plan.name} plan{" "}
              {activeManualSubscription.period_end
                ? `${
                    isPast(new Date(activeManualSubscription.period_end))
                      ? "ran"
                      : "runs"
                  } until ${getDateString(activeManualSubscription.period_end)}`
                : "runs indefinitely"}
              .
            </Text>
            <Text>
              Please contact <PurchasesMailtoLink /> to discuss your next
              renewal invoice.
            </Text>
          </>
        ) : pendingManualSubscription ? (
          <>
            {!pendingManualSubscription.paid && (
              <UnpaidPendingManulSubscriptionPaymentText
                planUniqueId={pendingManualSubscription.plan_unique_id}
              />
            )}
            <Text>
              Please contact <PurchasesMailtoLink /> if you have any questions.
            </Text>
          </>
        ) : (
          <Text>
            You don{"\u2019"}t currently have an active subscription. Please
            contact <PurchasesMailtoLink /> to arrange a new subscription or if
            you would like to switch to self-managed subscriptions using a
            credit card.
          </Text>
        )}
      </AlertDescription>
    </Alert>
  </Stack>
)

const UnpaidPendingManulSubscriptionPaymentText: React.FC<
  React.PropsWithChildren<{
    planUniqueId: string
  }>
> = ({ planUniqueId }) => {
  const planName = usePlan(planUniqueId)?.name

  return (
    <Text>
      We are currently awaiting payment for your subscription to the {planName}{" "}
      plan. Once this payment has been received your subscription will be
      activated.
    </Text>
  )
}
