import React, { PropsWithChildren, createContext, useContext } from "react"

import { S3Config } from "Types"

type S3ConfigContextValue = {
  s3Config: S3Config | null
}

const S3ConfigContext = createContext<S3ConfigContextValue>({
  s3Config: null,
})

export const useS3ConfigContext = (): S3Config => {
  const context = useContext(S3ConfigContext)

  if (!context?.s3Config) {
    throw new Error(
      "useS3ConfigContext must be used inside the S3ConfigProvider"
    )
  }

  return context.s3Config
}

interface Props {
  s3Config: S3Config
}

export const S3ConfigProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  s3Config,
}) => {
  return (
    <S3ConfigContext.Provider value={{ s3Config }}>
      {children}
    </S3ConfigContext.Provider>
  )
}
