import {
  Checkbox,
  CheckboxProps,
  Icon,
  IconButton,
  IconButtonProps,
  forwardRef,
} from "@chakra-ui/react"
import { FilterIcon } from "@heroicons/react/solid"
import React, { ChangeEvent } from "react"

import { Omit } from "Types"

type Customized = "checked" | "className" | "onChange" | "value"
export interface Props extends Omit<CheckboxProps, Customized> {
  value: boolean
  onChange: (isEnabled: boolean) => void
}

export const FilterToggle: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { id, value, onChange, ...rest } = props

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.checked)
  }

  const CheckboxIcon = forwardRef(() => {
    return <Icon as={FilterIcon} name="filter" boxSize={3} />
  })

  return (
    <Checkbox
      onChange={handleChange}
      id={id}
      icon={<CheckboxIcon />}
      tabIndex={0}
      size="xl"
      variant="iconToggle"
      isChecked={value}
      data-qa="filter-toggle-checkbox"
      {...rest}
    ></Checkbox>
  )
}

type FilterButtonProps = {
  readonly isEnabled: boolean
} & IconButtonProps

export const FilterButton: React.FC<FilterButtonProps> = React.forwardRef(
  ({ isEnabled, ...rest }, ref: React.Ref<HTMLButtonElement>) => (
    <IconButton
      {...rest}
      ref={ref}
      aria-label="Filter"
      variant="iconToggle"
      borderRadius="sm"
      color="gray.400"
      icon={<Icon as={FilterIcon} name="filter" boxSize={3} />}
      size="sm"
      data-qa="filter-toggle-button"
      isActive={isEnabled}
    />
  )
)

FilterButton.displayName = "FilterButton"
