import { SourceAttribute } from "Components/test-results/types/attributes"
import { SourceFilter } from "../filters/attribute-exact-value"

import { generateFilterId } from "./generate-filter-id"

export function createSourceFilter(
  source: SourceAttribute["value"]
): SourceFilter {
  return {
    id: generateFilterId(),
    category: "response/attribute:source",
    type: "attribute/exact-value",
    value: source,
  }
}
