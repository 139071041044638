import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React, { useState } from "react"
import { useNavigate } from "react-router"

import { FunctionalModal } from "Utilities/modals/types"
import { useCreateProject } from "~/api/generated/usabilityhub-components"

import { generateFriendlyProjectPath } from "UsabilityHub/utils/generateFriendlyProjectPath"

export const CreateProjectModal: FunctionalModal = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const toast = useToast()
  const [name, setName] = useState("")

  const { mutate: createProject, isLoading } = useCreateProject({
    onSuccess: ({ id }) => {
      toast({
        title: "New project created",
        description: name,
        status: "success",
        duration: 3000,
      })

      void queryClient.invalidateQueries(["api", "projects"])
      navigate(generateFriendlyProjectPath({ id, name }))
      onClose()
    },
    onError: (error) => {
      toast({
        title: error.payload.message,
        status: "error",
        duration: 3000,
      })
    },
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay>
        <ModalContent>
          {/* * Wrapped up in <form> so pressing Enter can trigger submission
          but the form doesn't handle any inputs */}
          <form
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <ModalHeader>
              <Heading>Create a new project</Heading>
              <ModalCloseButton />
            </ModalHeader>

            <ModalBody>
              <FormControl>
                <FormLabel>Project name</FormLabel>
                <Input
                  autoFocus
                  placeholder="Enter project name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <HStack>
                <Button variant="ghost" disabled={isLoading} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="brand.primary"
                  isLoading={isLoading}
                  isDisabled={name.length === 0}
                  onClick={() =>
                    createProject({
                      body: { name: name },
                    })
                  }
                >
                  Create
                </Button>
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
