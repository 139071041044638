import differenceInSeconds from "date-fns/differenceInSeconds"
import formatDistanceStrict from "date-fns/formatDistanceStrict"
import React, { PureComponent } from "react"

import { durationStringS } from "Utilities/time"

interface Props {
  date: Date | number
}

interface State {
  now: number
}

const OneMinute = 60 * 1000

export class DateDifferenceFromNow extends PureComponent<Props, State> {
  state: Readonly<State> = { now: Date.now() }
  private intervalId = -1

  private updateTime = () => {
    this.setState({ now: Date.now() })
  }

  componentDidMount() {
    this.intervalId = window.setInterval(this.updateTime, OneMinute)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  render() {
    const { date } = this.props
    const { now } = this.state
    return (
      <time dateTime={durationStringS(differenceInSeconds(date, now))}>
        {formatDistanceStrict(date, now)}
      </time>
    )
  }
}
