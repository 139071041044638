import {
  Alert,
  AlertDescription,
  Box,
  Center,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react"
import { PreciseDuration } from "Components/time/precise-duration"
import { State } from "Redux/app-store"
import {
  getAllCardSortOpenCategoriesRanking,
  getResponseSectionCardSortsForResponseSection,
} from "Redux/reducers/test-results/selectors"
import {
  CardSort,
  CardSortCard,
  CardSortCategoryCard,
  ResponseSection,
} from "Types"
import { useSectionContext } from "UsabilityHub/contexts"
import {
  getCategoryLabel,
  isItalicCategory,
} from "UsabilityHub/views/tests/$uniqueId/results/section-results/section-results-cards/card-sort-categories/grouping"
import React from "react"
import { useSelector } from "react-redux"
import { RecordingsSimplePreview } from "./SectionResultsCards/Recordings/RecordingsSimplePreview"

interface CardSortDurationInfoProps {
  responseSection: ResponseSection
  noOfCards: number
  noOfUnsortedCards: number
}
const CardSortDurationInfo: React.FC<CardSortDurationInfoProps> = ({
  responseSection,
  noOfCards,
  noOfUnsortedCards,
}) => {
  const noOfSortedCards = noOfCards - noOfUnsortedCards
  const taskDuration = responseSection.task_duration_ms

  return (
    <Alert status="info">
      <AlertDescription>
        <Text fontSize="sm">
          Completed in <PreciseDuration ms={taskDuration} />.
        </Text>
        <Text fontSize="sm">
          {noOfSortedCards} card{noOfSortedCards !== 1 ? "s" : ""} sorted,{" "}
          {noOfUnsortedCards} card{noOfUnsortedCards !== 1 ? "s" : ""} unsorted.
        </Text>
        {taskDuration && (
          <Text fontSize="sm">
            Average time taken per card sorted is{" "}
            {Math.round(taskDuration / noOfSortedCards / 100) / 10} seconds.
          </Text>
        )}
      </AlertDescription>
    </Alert>
  )
}

interface CategoryBoxProps {
  label: string
  cards: CardSortCard[]
  isItalic?: boolean
}
const CategoryBox: React.FC<CategoryBoxProps> = ({
  label,
  cards,
  isItalic = false,
}) => {
  return (
    <Box
      display="inline-block"
      width="100%"
      mb={3}
      bg="gray.100"
      borderColor="gray.200"
      borderWidth={1}
      rounded="md"
      p={3}
      fontStyle={isItalic ? "italic" : "normal"}
    >
      <Heading size="sm" mb="4">
        {label}
      </Heading>
      <VStack>
        {cards.map((card) => (
          <HStack
            key={card.id}
            w="100%"
            bg="white"
            borderColor="gray.200"
            borderWidth={1}
            rounded="md"
            p={3}
            alignItems="center"
            spacing={2}
          >
            {card.uploaded_image_url && (
              <Center
                bg="white"
                borderColor="gray.200"
                borderWidth={1}
                rounded="sm"
                minW="50px"
                w="50px"
                h="50px"
              >
                <Image
                  borderRadius="sm"
                  maxH="100%"
                  maxW="100%"
                  src={card.uploaded_image_url}
                />
              </Center>
            )}
            <Text wordBreak="break-word">{card.label}</Text>
          </HStack>
        ))}
      </VStack>
    </Box>
  )
}

interface CardSortResultProps {
  cardSort: CardSort | null
  responseSection: ResponseSection
}

export const CardSortResult: React.FC<CardSortResultProps> = ({
  cardSort,
  responseSection,
}) => {
  const { section } = useSectionContext()

  const sortResults = useSelector((state: State) =>
    getResponseSectionCardSortsForResponseSection(state, responseSection.id)
  )

  const CardSortOpenCategoriesRanking = useSelector(
    getAllCardSortOpenCategoriesRanking
  )

  if (!cardSort) return <p>No data!</p>

  const categories = cardSort.card_sort_categories_attributes
  const openCategories = cardSort.card_sort_open_categories_attributes
  const cards = cardSort.card_sort_cards_attributes

  const { noOfCards, noOfUnsortedCards, unsortedCards } =
    getCardSortResponseStats(sortResults, cards)

  // Add ranking to card sort open category
  // in order to show "Untitled #${ranking}" as the label of untitled groups/categories
  const getRankingOfOpenCategory = (card_sort_open_category_id: number) =>
    CardSortOpenCategoriesRanking[section.id].indexOf(
      card_sort_open_category_id
    ) + 1

  return (
    <>
      <RecordingsSimplePreview responseSection={responseSection} />
      <CardSortDurationInfo
        noOfCards={noOfCards}
        noOfUnsortedCards={noOfUnsortedCards}
        responseSection={responseSection}
      />
      <Box
        sx={{
          columnCount: 2,
        }}
      >
        {categories.map((category) => {
          const relatedCards = cards.filter((card) => {
            return sortResults.some(
              (sr) =>
                sr.card_sort_category_id === category.id &&
                sr.card_sort_card_id === card.id
            )
          })

          return (
            <CategoryBox
              key={category.id}
              label={category.label}
              cards={relatedCards}
            />
          )
        })}

        {openCategories.map((category) => {
          const relatedCards = cards.filter((card) => {
            return sortResults.some(
              (sr) =>
                sr.card_sort_open_category_id === Number(category.id) &&
                sr.card_sort_card_id === card.id
            )
          })

          if (relatedCards.length === 0) return null

          category.ranking = getRankingOfOpenCategory(Number(category.id))

          return (
            <CategoryBox
              key={category.id}
              label={getCategoryLabel(category)}
              cards={relatedCards}
              isItalic={isItalicCategory(category)}
            />
          )
        })}

        {noOfUnsortedCards > 1 && (
          <CategoryBox
            key={0}
            label="Unsorted"
            cards={unsortedCards}
            isItalic
          />
        )}
      </Box>
    </>
  )
}

type CardSortResponseStats = {
  noOfCards: number
  noOfUnsortedCards: number
  unsortedCards: CardSortCard[]
}

export const getCardSortResponseStats = (
  sortResults: CardSortCategoryCard[],
  cards: CardSortCard[]
): CardSortResponseStats => {
  const unsortedCardIds = new Set(
    sortResults
      .filter(
        (r) =>
          r.card_sort_category_id === null &&
          r.card_sort_open_category_id === null
      )
      .map((s) => s.card_sort_card_id)
  )
  const unsortedCards = cards.filter((card) => {
    return unsortedCardIds.has(card.id!)
  })
  const noOfUnsortedCards = unsortedCardIds.size

  const noOfCards = new Set(sortResults.map((s) => s.card_sort_card_id)).size

  return { noOfCards, noOfUnsortedCards, unsortedCards }
}
