import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Menu,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { ROLE_OPTIONS } from "Constants/role"
import { Role } from "Types"
import { useRefreshCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useRefreshTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { isValidEmail } from "Utilities/email"
import { Field, FieldProps, Form, Formik } from "formik"
import React, { useState } from "react"
import { useCreateUser } from "~/api/generated/usabilityhub-components"
import { Plan } from "~/api/generated/usabilityhubSchemas"
import { InviteUserModal } from "./InviteUserModal"
import { UserRoleMenuButton } from "./UserRoleMenuButton"
import { UserRoleMenuList } from "./UserRoleMenuList"

type InviteUserForm = React.FC<{
  currentPlan: Plan
  hasPaidSeatAvailable: boolean
}>

export interface InviteUserFormValues {
  email: string
  role: Role
}

export const InviteUserForm: InviteUserForm = ({
  currentPlan,
  hasPaidSeatAvailable,
}) => {
  const {
    isOpen: isInviteUserModalOpen,
    onClose: closeInviteUserModal,
    onOpen: openInviteUserModal,
  } = useDisclosure()

  const toast = useToast()
  const [hasAcceptedCost, setHasAcceptedCost] = useState(false)
  const refreshCurrentAccount = useRefreshCurrentAccount()
  const refreshTeamMembers = useRefreshTeamMembers()

  const { mutateAsync: inviteUser } = useCreateUser({
    onSuccess: async (_, variables) => {
      toast({
        description: `We have sent an invitation to join your team to ${variables.body.email}.`,
        status: "success",
        title: "New team member invited",
      })

      refreshTeamMembers()
      refreshCurrentAccount()

      setHasAcceptedCost(false)
    },
    onError: (error) => {
      toast({
        description: error.payload.message,
        status: "error",
        title: `New team member couldn${"\u2019"}t be invited`,
      })
    },
    onSettled: () => {
      closeInviteUserModal()
      setHasAcceptedCost(false)
    },
  })

  return (
    <Formik<InviteUserFormValues>
      initialValues={{ email: "", role: "admin" }}
      onSubmit={async (values, { resetForm }) => {
        if (!hasAcceptedCost) {
          openInviteUserModal()
        } else {
          try {
            await inviteUser({
              body: {
                email: values.email,
                // The name will be set to the email address by the InviteTeamMember operation
                name: null,
                role: values.role,
              },
            })

            resetForm()
          } catch {
            // handled by the mutation's onError
          }
        }
      }}
    >
      {({ isSubmitting, setFieldValue, submitForm, values }) => (
        <Form>
          <FormLabel>Invite your team</FormLabel>
          <Stack direction="row" spacing={3}>
            <FormControl flexGrow={1} isRequired>
              <Field name="email">
                {({ field }: FieldProps) => (
                  <Input
                    {...field}
                    id="email"
                    placeholder="newuser@email.com"
                    type="email"
                  />
                )}
              </Field>
            </FormControl>
            <FormControl isRequired width="auto" data-qa="invite-user-form">
              <Field name="role">
                {({ field }: FieldProps) => (
                  <Menu placement="bottom-end" eventListeners={false}>
                    <UserRoleMenuButton
                      buttonText={
                        ROLE_OPTIONS.find((opt) => opt.value === field.value)!
                          .label
                      }
                    />
                    <UserRoleMenuList
                      onRoleSelect={(roleValue: string) =>
                        setFieldValue(field.name, roleValue)
                      }
                      isDeletable={false}
                      filterRoleOptions={["archived"]}
                    />
                  </Menu>
                )}
              </Field>
            </FormControl>

            <Button
              colorScheme="brand.primary"
              variant="solid"
              type="submit"
              flexShrink={0}
              isDisabled={
                !values.email ||
                !isValidEmail(values.email) ||
                isInviteUserModalOpen
              }
            >
              Invite
            </Button>
          </Stack>
          <InviteUserModal
            currentPlan={currentPlan}
            hasPaidSeatAvailable={hasPaidSeatAvailable}
            isOpen={isInviteUserModalOpen}
            isSubmitting={isSubmitting}
            onClose={closeInviteUserModal}
            submitForm={() => {
              setHasAcceptedCost(true)
              return submitForm()
            }}
            user={values}
          />
        </Form>
      )}
    </Formik>
  )
}
