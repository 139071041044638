import { Flex, Link } from "@chakra-ui/react"
import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"

import { Card, CardBody, CardHeader } from "Shared/components/Card/Card"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { usersSignInPath } from "Shared/constants/routes"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"

import { SAMLSignInForm } from "./SAMLSignInForm"

export const SAMLSignInRoute: React.FC = () => {
  return (
    <Page title="Sign in with SAML">
      <UsabilityHubNavbar isOnSignInPage />
      <PageMain>
        <PageContent maxW="md">
          <Card>
            <CardHeader as="h1" textAlign="center" margin={6}>
              Sign in with SAML
            </CardHeader>
            <CardBody>
              <SAMLSignInForm />
            </CardBody>
          </Card>
          <Flex justify="center" mt={3} align="baseline">
            <Link
              textAlign="center"
              as={ReactRouterLink}
              ml={1}
              to={usersSignInPath()}
            >
              Sign in without SAML
            </Link>
          </Flex>
        </PageContent>
      </PageMain>
    </Page>
  )
}
