import React, { Component } from "react"

import { percentage } from "Utilities/css-helpers"

import styles from "./range.module.scss"

interface Props {
  index: number
  count: number
  value: number
  onClick: (value: number) => void
}

export class RangeTick extends Component<Props> {
  private handleClick = () => {
    const { onClick, value } = this.props
    onClick(value)
  }

  render() {
    const { index, count, value } = this.props
    const left = percentage(index / (count - 1))
    const width = percentage(1 / (count - 1))

    return (
      // biome-ignore lint/a11y/useKeyWithClickEvents: Only making the minimal set of changes to resolve PRD-4758. We should fix this in a follow-up.
      <div
        className={styles.tick}
        style={{ left, width }}
        onClick={this.handleClick}
      >
        <div className={styles.tickDot} />
        <div className={styles.tickLabel}>{value}</div>
      </div>
    )
  }
}
