import {
  SystemStyleObject,
  Table,
  TableContainer,
  Th,
  Tr,
} from "@chakra-ui/react"
import { Heading, Tag } from "DesignSystem/components"
import { useCountries } from "Hooks/use-countries"
import { GranularLocationTags } from "UsabilityHub/components/GranularLocationTags/GranularLocationTags"
import { DEFAULT_AGE_RANGE } from "UsabilityHub/views/NewOrderPage/constants"
import React, { ComponentProps } from "react"
import { BasicDemographicAttribute } from "~/application/javascripts/types"
import { DemographicTargetSummaryTableRow } from "./DemographicTargetSummaryTableRow"

type Props = {
  activeDemographicAttributes: BasicDemographicAttribute[]
  ageRange: [number | null, number | null]
  countries: string[]
  granularLocations: Pick<
    ComponentProps<typeof GranularLocationTags>,
    "scope" | "targetLocations"
  >
}

// We don't have a Table DS component yet so need to override default
// styling here. Keeping styles in the table's sx prop makes things easier
// to maintain instead of spreading them throughout the table.
// TODO This is a candidate for extraction into the design system.
const tableStyleOverrides: Record<string, SystemStyleObject> = {
  // all cells
  "& td, & th": {
    color: "ds.text.default",
    py: 4,
    borderColor: "ds.border.default",
  },
  // left column only
  "& :where(td,th):first-child": { w: 40, px: 0 },
  // remove outer padding on top and bottom
  "& > tr:first-child > *": { pt: 0 },
  "& > tr:last-child > *": { pb: 0, borderBottom: "none" },
}

export const DemographicTargetSummary: React.FC<
  React.PropsWithChildren<Props>
> = (props) => {
  const ageRangeTargeting =
    props.ageRange[0] !== null || props.ageRange[1] !== null

  const hasDemographicsRows =
    props.countries.length ||
    props.granularLocations.targetLocations.length ||
    ageRangeTargeting ||
    props.activeDemographicAttributes.length

  return (
    <TableContainer>
      <Table sx={tableStyleOverrides}>
        {props.children}

        {/* All rows below are part of the demographic tags section */}
        {hasDemographicsRows ? <DemographicsHeadingRow /> : null}
        {props.countries.length ? (
          <CountriesDemographicsRow {...props} />
        ) : null}
        {props.granularLocations.targetLocations.length ? (
          <LocationsDemographicsRow
            targetLocations={props.granularLocations.targetLocations}
            scope={props.granularLocations.scope}
          />
        ) : null}
        {ageRangeTargeting ? <AgeRangeDemographicsRow {...props} /> : null}
        <DemographicAttributesRows {...props} />
      </Table>
    </TableContainer>
  )
}

const DemographicsHeadingRow: React.FC = () => (
  <Tr
    sx={{
      // direct children of all rows after this one
      "& ~ tr > *": {
        py: 2,
        borderBottom: "none",
        verticalAlign: "top",
      },
      // direct children of next row
      "& + tr > *": { pt: 0 },
    }}
  >
    <Th borderBottom="none">
      <Heading as="h3" textStyle="ds.heading.primary">
        Demographics
      </Heading>
    </Th>
  </Tr>
)

const CountriesDemographicsRow: React.FC<Props> = (props) => {
  const { displayNameByCountryCode } = useCountries()

  return (
    <DemographicTargetSummaryTableRow
      heading={props.countries.length === 1 ? "Country" : "Countries"}
      isSubHeading
      hasTags
      value={props.countries.map((countryCode) => (
        <Tag label={displayNameByCountryCode(countryCode)} key={countryCode} />
      ))}
    />
  )
}

const LocationsDemographicsRow: React.FC<
  Pick<Props["granularLocations"], "targetLocations" | "scope">
> = (props) => (
  <DemographicTargetSummaryTableRow
    heading={props.targetLocations.length === 1 ? "Location" : "Locations"}
    isSubHeading
    value={
      <GranularLocationTags
        scope={props.scope}
        targetLocations={props.targetLocations}
      />
    }
  />
)

const AgeRangeDemographicsRow: React.FC<Props> = (props) => (
  <DemographicTargetSummaryTableRow
    heading="Age"
    isSubHeading
    value={
      <Tag
        label={
          <>
            {props.ageRange[0] ?? DEFAULT_AGE_RANGE[0]} —{" "}
            {props.ageRange[1] ?? `${DEFAULT_AGE_RANGE[1]}+`}
          </>
        }
      />
    }
  />
)

const DemographicAttributesRows: React.FC<Props> = (props) =>
  props.activeDemographicAttributes.map((attribute) => (
    <DemographicTargetSummaryTableRow
      key={attribute.id}
      heading={attribute.name}
      isSubHeading
      hasTags
      value={attribute.options.map((option) => (
        <Tag label={option.value} key={option.id} />
      ))}
    />
  ))
