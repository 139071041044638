import { FigmaFileFlow, FigmaFileVersion, UsabilityTestSection } from "Types"
import { isPrototypeTaskFlow } from "Utilities/get-prototype-type"
import { isPrototypeTaskSection } from "Utilities/usability-test-section"

const needsFigmaFileFlow = (
  figmaFileFlow: FigmaFileFlow | null,
  section: UsabilityTestSection
) => {
  return isPrototypeTaskSection(section) && figmaFileFlow == null
}

const needsValidStartNode = (
  figmaFileFlow: FigmaFileFlow | null,
  section: UsabilityTestSection,
  figmaFileVersions: readonly FigmaFileVersion[]
) => {
  const start_node_id = figmaFileFlow?.start_node_id
  return (
    isPrototypeTaskSection(section) &&
    (start_node_id == null ||
      !figmaFileVersions.some((ffv) =>
        ffv.start_node_ids.includes(start_node_id)
      ))
  )
}

const needsGoal = (
  figmaFileFlow: FigmaFileFlow | null,
  section: UsabilityTestSection
) => {
  return isPrototypeTaskFlow(section) && !figmaFileFlow?.goal_node_id
}

export const validateFigmaFileFlow = (
  figmaFileFlow: FigmaFileFlow | null,
  section: UsabilityTestSection,
  figmaFileVersions: readonly FigmaFileVersion[]
): string | undefined => {
  if (needsFigmaFileFlow(figmaFileFlow, section)) {
    return "You must add a prototype"
  }

  if (needsValidStartNode(figmaFileFlow, section, figmaFileVersions)) {
    return "This prototype link is invalid. Please remove and re-add to continue"
  }

  if (needsGoal(figmaFileFlow, section)) {
    return "You must add a goal"
  }
}
