import { Heading, Stack, Text } from "@chakra-ui/react"
import { HelpCenterLink } from "Shared/components/Links/HelpCenterLink"
import NoStudiesImage from "UsabilityHub/components/ModeratedStudyEmptyState/no-studies.svg"
import React from "react"

export const Empty = () => (
  <Stack alignItems="center" p={12} gap={4}>
    <img src={NoStudiesImage} alt="No sessions yet" />
    <Heading as="h2" fontSize="3xl" lineHeight="3xl">
      No sessions yet
    </Heading>
    <Text color="gray.500">
      Upcoming and completed sessions will appear here.
      <br />
      Need help getting started? Visit our{" "}
      <HelpCenterLink>Help Center</HelpCenterLink>.
    </Text>
  </Stack>
)
