import { HStack, Tooltip, TooltipProps } from "@chakra-ui/react"
import React from "react"

export const EstimateTooltip: React.FC<
  React.PropsWithChildren<TooltipProps>
> = ({ children, label }) => {
  return (
    <Tooltip hasArrow placement="top" mx={4} label={label}>
      <HStack alignItems="center">{children}</HStack>
    </Tooltip>
  )
}
