import { Breadcrumb, BreadcrumbItem } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { ChevronRightOutlineIcon } from "Shared/icons/untitled-ui/ChevronRightOutlineIcon"
import React from "react"
import { Link } from "react-router-dom"

export type DashboardBreadcrumbsData = (
  | {
      name: string
      isCurrentPage: boolean
      to?: never
    }
  | {
      name: string
      to: string
      isCurrentPage?: never
    }
)[]

export const DashboardBreadcrumbs: React.FC<{
  breadcrumbsData: DashboardBreadcrumbsData
}> = ({ breadcrumbsData }) => (
  <Breadcrumb
    textStyle="ds.interface.medium"
    color="ds.text.subtle"
    separator={<ChevronRightOutlineIcon boxSize={4} color="ds.icon.subtle" />}
    spacing={1}
  >
    {/* Note: I tried going for a more composable approach here with
    DashboardBreadcrumbLink as a separate component rather than passing in all
    the data as an array like this, but Chakra apparently relies on
    `cloneElement` to couple <Breadcrumb> with <BreadcrumbItem> which means
    using custom component wrappers seems to make it fail to render the
    separators properly.
    Probably a way to do it but I didn't want to spend too much time on it. */}
    {breadcrumbsData.map((breadcrumb) => {
      const toProps = breadcrumb.to ? { as: Link, to: breadcrumb.to } : {}

      return (
        <BreadcrumbItem
          key={`breadcrumb-${breadcrumb.name}`}
          isCurrentPage={breadcrumb.isCurrentPage}
        >
          <Button
            as={Link}
            variant={breadcrumb.isCurrentPage ? "secondary" : "subtle"}
            size="flush"
            noOfLines={1}
            // This is a hack to correct for the changed line height caused by
            // the CSS rules applied by the noOfLines prop above. Removing both
            // props looks the same in terms of positioning, except it wraps
            // instead of truncating.
            lineHeight={1.2}
            {...toProps}
          >
            {breadcrumb.name}
          </Button>
        </BreadcrumbItem>
      )
    })}
  </Breadcrumb>
)
