import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react"
import { Text } from "@chakra-ui/react"
import { JOB_ROLES } from "Constants/job-role"
import { Heading } from "DesignSystem/components"
import React, { useEffect } from "react"
import { useLocation } from "react-router"
import { LoadingForm } from "./LoadingForm"
import { useOnboardingContext } from "./OnboardingContextProvider"
import { SelectableButton } from "./SelectableButton"
import { RoleStep } from "./types"
import { useOnboardingStep } from "./useOnboardingStep"

type RoleFormProps = {
  step: RoleStep
}

export const RoleForm: React.FC<RoleFormProps> = ({ step }) => {
  const { submit, currentUser, setRedirect } = useOnboardingContext()

  // The redirectTo state is set after google sign up
  const location = useLocation()
  useEffect(() => {
    if (location.state?.redirectTo) {
      setRedirect(location.state.redirectTo)
    }
  }, [location.state?.redirectTo])

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues,
    setFocus,
  } = useOnboardingStep(step)

  const role = watch("role")

  const customRoleTitle = watch("customRoleTitle")

  useEffect(() => {
    if (customRoleTitle) {
      setFocus("roleTitle")
    } else {
      setValue("roleTitle", "", { shouldTouch: true })
    }
  }, [customRoleTitle, setValue, setFocus])

  useEffect(() => {
    if (role === "Other") {
      setValue("customRoleTitle", true, { shouldTouch: true })
    } else if (!getValues().roleTitle?.trim()) {
      setValue("customRoleTitle", false, { shouldTouch: true })
    }
  }, [role, setValue, getValues])

  return (
    <Stack
      as="form"
      id="onboarding_form"
      spacing={6}
      onSubmit={handleSubmit(submit)}
    >
      <LoadingForm>
        <Heading
          as="h1"
          textStyle="ds.display.emphasized"
          color="ds.text.default"
        >
          Set up your personal profile
        </Heading>

        <FormControl isInvalid={!!errors.preferredName || undefined}>
          <FormLabel>
            <Text textStyle="ds.heading.primary" color="ds.text.default">
              What{"\u2019"}s your preferred name?
            </Text>
          </FormLabel>

          <FormHelperText
            textStyle="ds.paragraph.primary"
            color="ds.text.default"
            mb={3}
          >
            This is how your teammates will see you (you can change it later)
          </FormHelperText>

          <Input
            type="text"
            placeholder="Enter your preferred name"
            autoComplete="given-name"
            autoFocus
            {...register("preferredName", {
              value: currentUser?.preferred_name || "",
            })}
          />
          {errors.preferredName && (
            <FormErrorMessage fontWeight="medium">
              {errors.preferredName.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!errors.role || undefined}>
          <FormLabel>
            <Text textStyle="ds.heading.primary" color="ds.text.default">
              What best describes your current role?
            </Text>
          </FormLabel>
          <HStack flexWrap="wrap" gap={2}>
            {JOB_ROLES.map((jr) => (
              <SelectableButton
                key={jr}
                id={jr}
                isSelected={role === jr || undefined}
                {...register("role")}
              >
                {jr}
              </SelectableButton>
            ))}
          </HStack>
          {errors.role && (
            <FormErrorMessage>{errors.role.message}</FormErrorMessage>
          )}
        </FormControl>
        <Stack
          visibility={role && role !== "Unspecified" ? "visible" : "hidden"}
        >
          <Checkbox
            {...register("customRoleTitle")}
            isChecked={customRoleTitle}
            isDisabled={role === "Other"}
            sx={{
              "& span[data-disabled]": {
                opacity: 1,
              },
            }}
          >
            <Text textStyle="ds.paragraph.primary" color="ds.text.default">
              Customize your role title
            </Text>
          </Checkbox>
          <FormControl
            ml={6}
            w="auto"
            visibility={customRoleTitle ? "visible" : "hidden"}
            isInvalid={!!errors.roleTitle || undefined}
          >
            <Input
              type="text"
              placeholder="Enter your role title"
              aria-label="Role title"
              {...register("roleTitle")}
            />
            {errors.roleTitle && (
              <FormErrorMessage>{errors.roleTitle.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>
      </LoadingForm>
    </Stack>
  )
}
