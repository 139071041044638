import { RankedAnswerFilter } from "../filters/ranked-answer"

import { generateFilterId } from "./generate-filter-id"

export function createRankedAnswerFilterCategory(
  sectionId: number,
  questionId: number
): RankedAnswerFilter["category"] {
  return `answer/section:${sectionId}/question:${questionId}/ranked`
}

export function createRankedAnswerFilter(
  sectionId: number,
  questionId: number,
  answer: string,
  ranks: number[]
): RankedAnswerFilter {
  return {
    id: generateFilterId(),
    category: createRankedAnswerFilterCategory(sectionId, questionId),
    type: "answer/ranked",
    value: {
      answer,
      ranks,
    },
  }
}
