import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "DesignSystem/components"
import { ROUTES } from "UsabilityHub/views/routes"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import * as Yup from "yup"
import { useCreateExternalStudy } from "~/api/generated/usabilityhub-components"

const CreateNewModeratedStudyFormSchema = Yup.object({
  name: Yup.string()
    .max(100, "Study name is too long (max 100 characters)")
    .required("Study name is required"),
})

type FormValues = Yup.InferType<typeof CreateNewModeratedStudyFormSchema>

export const CreateNewExternalStudyModal: FunctionalModal = ({ onClose }) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const navigate = useNavigate()

  const { mutateAsync: createExternalStudy } = useCreateExternalStudy({
    onSuccess: (data) => {
      void queryClient.invalidateQueries(["api", "usability_tests"])
      void queryClient.invalidateQueries(["api", "studies"])
      navigate(ROUTES.TEST.EDIT.buildPath({ testId: data.unique_id }))
      onClose()
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(CreateNewModeratedStudyFormSchema),
    defaultValues: {
      name: "",
    },
  })

  const onSubmit = async (values: FormValues) => {
    try {
      await createExternalStudy({
        body: {
          name: values.name,
        },
      })
    } catch (e) {
      toast({
        title: e.payload?.message ?? "Error creating study",
        status: "error",
      })
    }
  }

  return (
    <Modal size="lg" isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Create new external study</ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel htmlFor="email">Study name</FormLabel>
                <Input variant="filled" {...register("name")} />
                <FormHelperText>
                  This is an internal name that participants won{"\u2019"}t see.
                </FormHelperText>
                {errors.name && (
                  <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="primary"
                isDisabled={isSubmitting || !isValid}
                isLoading={isSubmitting}
                type="submit"
                colorScheme="brand.primary"
              >
                Create
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
