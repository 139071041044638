import React from "react"

import styles from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout.module.scss"

interface Props {
  url: string
}

const CustomerLogo: React.FC<React.PropsWithChildren<Props>> = ({ url }) => {
  return (
    <div className={styles.customerLogo}>
      {/* We don't have information about the customer in this context, so can't use more specific alt text */}
      <img alt="Customer logo" src={url} />
    </div>
  )
}

export default CustomerLogo
