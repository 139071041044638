// The ImageMagick limit configured on the heroku usabilityhub-staging and usabilityhub.
// Path: /etc/ImageMagick-6/policy.xml
//   Policy: Resource
//     name: height
//     value: 16KP
//   Policy: Resource
//     name: width
//     value: 16KP
export const MAX_WIDTH = 16000
export const MAX_HEIGHT = 16000
