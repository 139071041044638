import { useEffect } from "react"

import { useTrackEvent } from "~/api/generated/usabilityhub-components"

type testUpgradeSource = "test_builder_sidebar" | "recruitment_page"

export const useTrackTestUpgradeRequired = (
  isUpgradeRequired: boolean,
  usabilityTestId: number | string,
  source: testUpgradeSource
) => {
  const { mutate: trackEvent } = useTrackEvent()

  return useEffect(() => {
    if (isUpgradeRequired) {
      trackEvent({
        body: {
          event: "test_upgrade_required",
          event_properties: { usability_test_id: usabilityTestId, source },
        },
      })
    }
  }, [isUpgradeRequired])
}
