import { Box, Icon, Text } from "@chakra-ui/react"
import { ViewListIcon } from "@heroicons/react/solid"
import React, { FC, ReactNode } from "react"

import { CheckboxIcon } from "Shared/icons/CheckboxIcon"
import { ClickSectionIcon } from "Shared/icons/ClickSectionIcon"
import { PreferenceSectionIcon } from "Shared/icons/PreferenceSectionIcon"
import { PrototypeTestIcon } from "Shared/icons/PrototypeTestIcon"
import { RadioIcon } from "Shared/icons/RadioIcon"
import { RankIcon } from "Shared/icons/RankIcon"
import { TagIcon } from "Shared/icons/TagIcon"
import { Dataflow02OutlineIcon } from "Shared/icons/untitled-ui/Dataflow02OutlineIcon"
import { getQuestionPrefix } from "Utilities/usability-test-section-question"

export type FilterValue = {
  filterId: string
  type:
    | "answer-checkbox"
    | "answer-radio"
    | "common-path"
    | "ranked"
    | "tag"
    | "preference"
    | "hitzone"
    | "card-sort"
    | "tree-test"
    | "reached-goal-screen"
    | "prototype-node"
  stepIndex?: number
  sectionIndex: number
  questionIndex?: number
  label: ReactNode
}

const getFilterPrefix = ({
  sectionIndex,
  stepIndex,
  questionIndex,
}: FilterValue) => {
  if (stepIndex === undefined && questionIndex === undefined) {
    return sectionIndex + 1
  }

  if (stepIndex === undefined) {
    return getQuestionPrefix(sectionIndex + 1, questionIndex!, true)
  }

  if (questionIndex === undefined) {
    return `${sectionIndex + 1}/Step ${stepIndex + 1}`
  }

  return `${getQuestionPrefix(sectionIndex + 1, questionIndex, true)}/Step ${
    stepIndex + 1
  }`
}

interface FilterValueIconProps {
  type: FilterValue["type"]
}

const FilterValueIcon: FC<React.PropsWithChildren<FilterValueIconProps>> = ({
  type,
}) => {
  if (type === "hitzone") return <Icon as={ClickSectionIcon} />
  if (type === "preference") return <Icon as={PreferenceSectionIcon} />
  if (
    type === "common-path" ||
    type === "reached-goal-screen" ||
    type === "prototype-node"
  )
    return <Icon as={PrototypeTestIcon} color="gray.400" />
  if (type === "ranked") return <Icon as={RankIcon} />
  if (type === "tag") return <Icon as={TagIcon} />
  if (type === "answer-checkbox") return <Icon as={CheckboxIcon} />
  if (type === "answer-radio") return <Icon as={RadioIcon} />
  if (type === "card-sort") return <Icon as={ViewListIcon} />
  if (type === "tree-test") return <Icon as={Dataflow02OutlineIcon} />

  throw new Error(`${type} is not supported`)
}

interface Props {
  filterValue: FilterValue
}

export const FilterOptionLabel: FC<React.PropsWithChildren<Props>> = ({
  filterValue,
}) => {
  const prefix = getFilterPrefix(filterValue)

  return (
    <Box as="div" display="flex" alignItems="center" overflow="hidden">
      <FilterValueIcon type={filterValue.type} />

      <Text
        noOfLines={1}
        as="span"
        marginLeft="0.5em"
        title={String(filterValue.label)}
      >
        {prefix}: {filterValue.label}
      </Text>
    </Box>
  )
}
