export function clamp(value: number, min = -Infinity, max = Infinity) {
  return Math.max(Math.min(value, max), min)
}

export function clamp01(value: number) {
  return clamp(value, 0, 1)
}

/**
 * Like modulo (`value % maximum`), but will wrap negatives around.
 * @example
 * wrap(5, 5)  // 0
 * wrap(-1, 5)  // 4
 */
export function wrap(value: number, maximum: number) {
  return ((value % maximum) + maximum) % maximum
}

export function lerp(from: number, to: number, amount: number) {
  return from + (to - from) * amount
}

export function between(value: number, min: number, max: number) {
  return value >= min && value <= max
}
