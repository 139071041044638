import { flatMapDeep, isObject } from "lodash"

export function swapPropertiesInPlace<T extends Record<string, any>>(
  object: T,
  keyA: keyof T,
  keyB: keyof T
): void {
  const temp = object[keyA]
  object[keyA] = object[keyB]
  object[keyB] = temp
}

export function flattenNested<T>(obj: Record<string, T>): T[] {
  return flatMapDeep(obj, (objOrValue: Record<string, T> | T) => {
    return isObject(objOrValue) ? flattenNested(objOrValue) : objOrValue
  })
}
