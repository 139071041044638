import { uniq } from "lodash"
import React, { ChangeEvent, useState } from "react"

import { CheckboxList } from "Components/form/checkbox-list/checkbox-list"
import { TextInput } from "Components/form/text-input/text-input"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ReactOption } from "Types"
import { makeMultipleChoiceOptionsToOptions } from "UsabilityHub/components/UsabilityTestSectionQuestion/helpers"
import { isPresent } from "Utilities/values"

/**
 * Convenience function to get around TypeScript limitation.
 * @see https://github.com/Microsoft/TypeScript/issues/1863
 */
function getSymbolValue(obj: Record<symbol, string>, key: symbol): string {
  return (obj as any)[key]
}

export interface Props {
  max_selected_options: number | null
  options: ReadonlyArray<string>
  randomized: boolean
  showOtherOption: boolean
  onChange: (value: ReadonlyArray<string>) => void
  value: string
}

const firstOptionSymbol = Symbol("other")
const maxOtherOptionCount = 8

export const QuestionCheckboxList: React.FC<React.PropsWithChildren<Props>> = ({
  max_selected_options,
  options: baseOptions,
  randomized,
  showOtherOption,
  onChange,
}) => {
  const translate = useTranslate()
  const [multipleChoiceOptionsToOptions] = useState(
    makeMultipleChoiceOptionsToOptions
  )

  const [selected, setSelected] = useState<ReadonlyArray<symbol | string>>([])
  const [otherOptions, setOtherOptions] = useState<ReadonlyArray<symbol>>([
    firstOptionSymbol,
  ])
  const [otherValues, setOtherValues] = useState<Record<symbol, string>>({
    [firstOptionSymbol]: "",
  })

  // -- Event handlers --
  const handleChange = (value: ReadonlyArray<string | symbol>) => {
    setSelected(value)
    updateValue(value, otherValues)
  }

  const handleOtherFocus = (option: symbol) => () => {
    if (!selected.includes(option)) {
      const nextSelected = [...selected, option]
      setSelected(nextSelected)
      updateValue(nextSelected, otherValues)
    }
  }

  const handleOtherChange =
    (option: symbol) => (event: ChangeEvent<HTMLInputElement>) => {
      const newOtherValues = {
        ...otherValues,
        [option]: event.target.value,
      }
      setOtherValues(newOtherValues)
      updateValue(selected, newOtherValues)
    }

  // -- Private interface --
  const disableOptionsIfMaxReached = (
    options: ReadonlyArray<ReactOption<string | symbol>>
  ) => {
    if (max_selected_options && selected.length >= max_selected_options) {
      return options.map((option) => ({
        ...option,
        disabled: !selected.includes(option.value),
      }))
    }
    return options
  }

  const generateOptions = () => {
    const options: ReadonlyArray<ReactOption<string | symbol>> =
      multipleChoiceOptionsToOptions(baseOptions, randomized)

    if (showOtherOption) {
      return [
        ...options,
        ...otherOptions.map((optionValue) => ({
          value: optionValue,
          label: renderOtherOption(optionValue),
        })),
      ]
    }
    return options
  }

  const updateValue = (
    value: ReadonlyArray<string | symbol>,
    otherValues: Readonly<Record<symbol, string>>
  ) => {
    onChange(
      uniq(
        value
          .map((v) =>
            typeof v === "string" ? v : getSymbolValue(otherValues, v)
          )
          .filter(isPresent)
      )
    )
    // If there's room for more "other" options, add one more if the rest have text
    if (otherOptions.length < maxOtherOptionCount) {
      const allOthersPresent = otherOptions.every((o) =>
        isPresent(getSymbolValue(otherValues, o))
      )
      if (allOthersPresent) {
        const nextSymbol = Symbol("other")
        setOtherOptions([...otherOptions, nextSymbol])
        setOtherValues({ ...otherValues, [nextSymbol]: "" })
      }
    }
  }

  const renderOtherOption = (optionValue: symbol) => {
    return (
      <TextInput
        value={getSymbolValue(otherValues, optionValue)}
        placeholder={translate("test.other")}
        onChange={handleOtherChange(optionValue)}
        onFocus={handleOtherFocus(optionValue)}
      />
    )
  }

  const options = disableOptionsIfMaxReached(generateOptions())
  return (
    <CheckboxList onChange={handleChange} options={options} value={selected} />
  )
}
