import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import React from "react"
import { useRevokeModeratedStudyInvitations } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { ModeratedStudyApplication } from "./types"

type Props = {
  selectedApplication: ModeratedStudyApplication
}

export const RevokeInvitationButton: React.FC<Props> = ({
  selectedApplication,
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { queryKeyFn } = useUsabilityhubContext()
  const { moderatedStudyId } = useModeratedStudyContext()
  const confirmationModal = useDisclosure()

  const invalidateApplicationsQuery = async () => {
    await queryClient.invalidateQueries(
      queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/applications",
        operationId: "getModeratedStudyApplications",
        variables: {
          pathParams: {
            moderatedStudyId,
          },
        },
      })
    )
  }

  const { mutate: revokeInvitations } = useRevokeModeratedStudyInvitations({
    onSuccess: () => {
      toast({
        title: "Invitation revoked for 1 appliacnt",
        status: "success",
      })
      return invalidateApplicationsQuery()
    },
    onError: () => {
      toast({
        title: "Error revoking invitation",
        status: "error",
      })
    },
  })

  const handleRevoke = () => {
    revokeInvitations({
      pathParams: {
        moderatedStudyId,
      },
      body: {
        moderated_study_application_ids: [selectedApplication.id],
      },
    })
  }

  return (
    <>
      <Button
        onClick={confirmationModal.onOpen}
        colorScheme="brand.primary"
        flexShrink={0}
      >
        Revoke invitation
      </Button>
      <RevokeInvitationConfirmationModal
        {...confirmationModal}
        onConfirm={handleRevoke}
      />
    </>
  )
}

type RevokeInvitationConfirmationModalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

const RevokeInvitationConfirmationModal: React.FC<
  RevokeInvitationConfirmationModalProps
> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent color="text.primary" maxW="30rem">
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">Revoke Invitation</ModalHeader>
        <ModalBody>
          <Text mb={4}>
            By revoking the invitation, the applicant will not be able to apply
            for this interview study.
          </Text>

          <Text>
            Once the invitation has been revoked, you will not be able to
            re-send an invitation to the applicant.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" onClick={onConfirm}>
            Revoke
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
