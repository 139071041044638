import {
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { ViewGridAddIcon } from "@heroicons/react/outline"
import {
  ArrowLeftIcon,
  ChevronDownIcon,
  SearchIcon,
} from "@heroicons/react/solid"
import { Button, IconButton } from "DesignSystem/components"
import { useMutableQueryString } from "Shared/hooks/useMutableQueryString"
import { FolderPlusOutlineIcon } from "Shared/icons/untitled-ui/FolderPlusOutlineIcon"
import { useModal } from "Utilities/modals/use-modal"
import { debounce } from "lodash"
import React, { PropsWithChildren } from "react"
import { useMatch, useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { ROUTES } from "../routes"
import { CreateNewStudyButton } from "./CreateNewStudyButton"
import { CreateProjectModal } from "./CreateProjectModal"
import { TemplateLibrary } from "./TemplateLibrary"

const SEARCH_DEBOUNCE_TIME_MS = 600

export const DashboardHeader: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const isSearching = useMatch("/search") !== null

  const [searchParams, setSearchParams] = useMutableQueryString()
  const query = searchParams["q"] ?? ""

  const { open: openCreateProjectModal } = useModal(CreateProjectModal)

  const debouncedSearch = debounce((q: string) => {
    setSearchParams({ q })
  }, SEARCH_DEBOUNCE_TIME_MS)

  return (
    <Flex as="header" w="full" bg="white">
      <Flex
        w="full"
        align="center"
        gap={3}
        px={[6, null, 8, 10]}
        py={[4, null, 3]}
        mx="auto"
      >
        {isSearching && (
          <IconButton
            icon={<Icon as={ArrowLeftIcon} />}
            aria-label="Back"
            variant="secondary"
            size={["default", null, null, "emphasized"]}
            onClick={() => navigate("/dashboard")}
          />
        )}
        <Flex
          display={[!isSearching ? "none" : "flex", null, "flex"]}
          flexGrow={1}
          flexShrink={1}
          overflow="hidden"
        >
          {children || (
            <InputGroup maxW={isSearching ? undefined : "420px"} rounded="md">
              <InputLeftElement h={{ base: 8, lg: 10 }}>
                <Icon boxSize={5} as={SearchIcon} color="gray.400" />
              </InputLeftElement>
              <Input
                defaultValue={query}
                autoFocus={isSearching}
                placeholder="Search"
                variant="outline"
                h={{ base: 8, lg: 10 }}
                onChange={(e) => debouncedSearch(e.target.value)}
                onFocus={isSearching ? undefined : () => navigate("/search")}
                _focusVisible={{}}
                data-intercom-target="search-bar"
                borderColor="ds.border.input"
                rounded="full"
              />
            </InputGroup>
          )}
        </Flex>

        {!isSearching && (
          <>
            <Flex
              display={{ base: "none", lg: "flex" }} // Visible for desktop screens
              alignItems="center"
              gap={3}
              flexShrink={0}
            >
              <Button
                as={Link}
                to={ROUTES.DASHBOARD.TEMPLATES.path}
                variant="secondary"
                leftIcon={<Icon as={ViewGridAddIcon} />}
              >
                Template library
              </Button>

              <Button
                onClick={openCreateProjectModal}
                variant="secondary"
                leftIcon={
                  <Icon
                    as={FolderPlusOutlineIcon}
                    color="brand.neutral.default"
                  />
                }
                data-intercom-target="create-new-project-button"
              >
                Create project
              </Button>

              <CreateNewStudyButton />
            </Flex>
            <HStack
              spacing={2}
              flexBasis={["100%", null, "auto"]}
              display={{ base: "flex", lg: "none" }} // Visible for mobile screens
            >
              <CreateNewStudyButton />

              <Menu placement="bottom-end" eventListeners={false}>
                <MenuButton
                  as={Button}
                  variant="secondary"
                  rightIcon={<Icon as={ChevronDownIcon} />}
                >
                  More
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={Link}
                    to={ROUTES.DASHBOARD.TEMPLATES.path}
                    icon={<Icon as={ViewGridAddIcon} />}
                  >
                    Template library
                  </MenuItem>
                  <MenuItem
                    onClick={openCreateProjectModal}
                    rounded={"md"}
                    icon={<Icon as={FolderPlusOutlineIcon} />}
                  >
                    Create project
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </>
        )}
      </Flex>

      <TemplateLibrary />
    </Flex>
  )
}
