import {
  Box,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"
import { FilterIcon } from "@heroicons/react/solid"
import { ExpandableRegion } from "Components/expandable-region/expandable-region"
import { useTestResultViewState } from "Components/test-results/context/test-result-view-state"
import { useCommonPathFilter } from "Components/test-results/hooks/use-common-path-filter"
import { getScreenResultsFromCommonPath } from "Components/test-results/utils/screen-results/get-screen-results-from-common-path"
import { getCommonFigmaTaskPathsFromTasks } from "Components/test-results/utils/task-paths/get-common-paths/get-common-paths"
import {
  isReadonly,
  makeGetFigmaTasksForSectionId,
} from "Redux/reducers/test-results/selectors"
import { CommonFigmaTaskPath, ResponseSectionFigmaTask } from "Types"
import { useMaybeCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { omit } from "lodash"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { useFigmaImages } from "../../hooks/use-figma-images"
import { useCommonPathNames } from "../../hooks/use-set-common-path-names"
import { PathEntryWithDetailsModal } from "../PathEntryWithDetailsModal"
import { PathStatsContainer } from "../PathStats/PathStatsContainer"
import { PathImages, PathRow, PathSortSelect, PathsList } from "../PathsList"
import { CommonPathName } from "./CommonPathName"
import {
  FigmaTaskPathSortMethod,
  SortMethodOptions,
  useSortedFigmaTaskPaths,
} from "./useSortedFigmaTaskPaths"

// ts-prune-ignore-next used in test
export type CommonPathSortMethod = Exclude<FigmaTaskPathSortMethod, "none">

const SORT_TYPE_LABELS: SortMethodOptions<CommonPathSortMethod> = {
  participants: "Participants",
  goalScreen: "Reached goal screen",
  duration: "Time to complete",
  misclicks: "Misclick rate",
}

const DEFAULT_SORT_METHOD: CommonPathSortMethod = "participants"

interface CommonPathsListProps {
  ffvId: number
  goalNodeId: string | null
  testId: number
  sectionId: number
  filteredTasks: ResponseSectionFigmaTask[]
}

/**
 * `CommonPathsList` renders all paths that at least 2 results have in common. A path is screen
 * based, which means that a node can appear multiple times. Each screen contains its own meta
 * information about clicks, misclicks, duration etc. A common path is based on multiple
 * `ResponseSectionFigmaTask`s.
 */

export const CommonPathsList: React.FC<CommonPathsListProps> = ({
  ffvId,
  goalNodeId,
  testId,
  sectionId,
  filteredTasks,
}) => {
  const getAllResponseSectionFigmaTasks =
    makeGetFigmaTasksForSectionId(sectionId)
  const allTasks = useSelector(
    getAllResponseSectionFigmaTasks
  ) as ResponseSectionFigmaTask[]

  const { commonPathNamesBySectionId } = useTestResultViewState()

  // Calculate common paths from keys and filtered tasks
  const commonPaths = useMemo(
    () =>
      getCommonFigmaTaskPathsFromTasks(
        allTasks,
        commonPathNamesBySectionId[sectionId] || null,
        goalNodeId
      ),
    [commonPathNamesBySectionId, allTasks, goalNodeId, sectionId]
  )

  // Sort the common paths
  const { sortedPaths, sortMethod, setSortMethod } = useSortedFigmaTaskPaths<
    CommonPathSortMethod,
    CommonFigmaTaskPath
  >(commonPaths, DEFAULT_SORT_METHOD)

  if (sortedPaths.length === 0) {
    return (
      <Box borderWidth={1} borderColor="gray.200" rounded="md" p={5}>
        <Text>No common paths</Text>
      </Box>
    )
  }

  return (
    <PathsList>
      {goalNodeId ? (
        <PathSortSelect
          value={sortMethod}
          options={SORT_TYPE_LABELS}
          onSelect={(e) => setSortMethod(e)}
        />
      ) : (
        <PathSortSelect
          value={sortMethod === "goalScreen" ? DEFAULT_SORT_METHOD : sortMethod}
          options={omit(SORT_TYPE_LABELS, ["goalScreen"])}
          onSelect={(e) => setSortMethod(e)}
        />
      )}

      <ExpandableRegion
        maxHeight={600}
        expandText="Expand"
        collapseText="Collapse"
      >
        <Stack spacing={4}>
          {sortedPaths.map((path) => (
            <CommonPath
              key={`common-${path.pathId}`}
              path={path}
              ffvId={ffvId}
              goalNodeId={goalNodeId}
              testId={testId}
              sectionId={sectionId}
              sortMethod={sortMethod}
              filteredResponseCount={filteredTasks.length}
            />
          ))}
        </Stack>
      </ExpandableRegion>
    </PathsList>
  )
}

interface CommonPathProps {
  path: CommonFigmaTaskPath
  ffvId: number
  goalNodeId: string | null
  testId: number
  sectionId: number
  sortMethod: FigmaTaskPathSortMethod
  filteredResponseCount: number
}

const CommonPath: React.FC<CommonPathProps> = ({
  path,
  ffvId,
  testId,
  sectionId,
  sortMethod,
}) => {
  const {
    sectionHasActiveFilter,
    isPathFilteredIn,
    isPathFilteredInRequested,
    toggleIsFiltered,
  } = useCommonPathFilter(sectionId, path.pathId)
  const figmaImages = useFigmaImages(path.tasks[0].figma_file_version_id)
  const setCommonPathNames = useCommonPathNames()

  const isFilteredIn = isPathFilteredIn || isPathFilteredInRequested
  const isFilteredOut = sectionHasActiveFilter && !isPathFilteredIn

  const handleRename = async (name: string) => {
    await setCommonPathNames(testId, path.pathId, name)
  }

  const screenResults = getScreenResultsFromCommonPath(path, figmaImages)

  const readonly = useSelector(isReadonly)
  const canManage = useMaybeCurrentUser()?.can_manage_tests ?? false
  const isEditable = !readonly && canManage

  return (
    <PathRow
      data-qa={`path-row-${path.pathId}`}
      key={path.pathId}
      opacity={isFilteredOut ? 0.5 : 1}
      borderColor={isFilteredIn ? "brand.primary.500" : ""}
    >
      <HStack spacing={3} color="gray.700">
        <VStack alignItems="flex-start">
          <CommonPathName
            name={path.pathName}
            onRename={handleRename}
            isEditable={isEditable}
          />
          <HStack>
            <PathStatsContainer
              type="common"
              pathMeta={path.meta}
              highlightTime={sortMethod === "duration"}
              highlightClicks={sortMethod === "misclicks"}
            />
          </HStack>
        </VStack>
        <Spacer />
        <IconButton
          icon={
            <Icon
              as={FilterIcon}
              color={isPathFilteredIn ? "brand.primary.500" : "gray.500"}
            />
          }
          aria-label="Filter"
          variant="outline"
          size="sm"
          colorScheme={isPathFilteredIn ? "brand.primary" : "gray"}
          onClick={toggleIsFiltered}
        />
      </HStack>
      <PathImages>
        {screenResults.map((screenResult, index) => (
          <Box
            key={`${ffvId}-${screenResult.nodeId}-${index}`}
            width="160px"
            flexShrink="0"
          >
            <PathEntryWithDetailsModal
              index={index}
              screenResults={screenResults}
              path={path}
            />
          </Box>
        ))}
      </PathImages>
    </PathRow>
  )
}
