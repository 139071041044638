import { Heading, ListItem, Text } from "@chakra-ui/react"
import React from "react"

import { PRIVACY_POLICY_DOCUMENT } from "Constants/legal-documents"
import {
  Document,
  DocumentIntro,
  DocumentList,
  DocumentSection,
} from "Shared/components/Document/Document"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { PrivacyMailtoLink } from "Shared/components/Links/PrivacyMailtoLink"
import { getDateString } from "Utilities/date-formats"

export const PrivacyPolicyDocument = () => (
  <Document
    title="Privacy policy"
    downloadUrl={PRIVACY_POLICY_DOCUMENT.downloadUrl}
  >
    <DocumentIntro>
      <Text>
        Version {PRIVACY_POLICY_DOCUMENT.currentVersion}, last updated on{" "}
        <strong>{getDateString(PRIVACY_POLICY_DOCUMENT.lastUpdated)}</strong>.
      </Text>
      <Text>
        This Privacy Policy sets out our commitment to protecting the privacy of
        personal information provided to us, or otherwise collected by us,
        offline or online, including through this website (<strong>Site</strong>
        ) and the websites of our sub-brands (<strong>Sub-brand Sites</strong>).
        In this Privacy Policy we, us or our means UsabilityHub Pty Ltd ABN 65
        158 305 205 trading as Lyssna. The Sub-brand Sites are available at{" "}
        <ExternalLink href="https://www.usercrowd.com">
          www.usercrowd.com
        </ExternalLink>{" "}
        (<strong>UserCrowd</strong>) and (<strong>Five Second Test</strong>){" "}
        <ExternalLink href="https://fivesecondtest.com">
          fivesecondtest.com
        </ExternalLink>
        .
      </Text>
      <Text>
        When we collect, store, and use your personal information, we do so in
        accordance with the rules set down in the Australian Privacy Act 1988
        (Cth) and, to the extent applicable, by the European Union General Data
        Protection Regulation (EU) 2016/279 (the <strong>GDPR</strong>).
      </Text>
    </DocumentIntro>
    <DocumentSection title="Personal information">
      <Text>
        <strong>Personal information</strong>: The types of personal information
        or personal data we may collect about you include:
      </Text>
      <DocumentList>
        <ListItem>your name;</ListItem>
        <ListItem>
          your contact details, including email address, mailing address, street
          address, and/or telephone number;
        </ListItem>
        <ListItem>your marital status and/or anniversary;</ListItem>
        <ListItem>information about your company or team;</ListItem>
        <ListItem>your role within the company or team;</ListItem>
        <ListItem>
          your credit card information or details (stored securely with our
          third party payment service provider);
        </ListItem>
        <ListItem>your age and/or date of birth;</ListItem>
        <ListItem>
          your demographic information, such as age or gender;
        </ListItem>
        <ListItem>
          images and recordings of you, including recordings of screen
          activities;
        </ListItem>
        <ListItem>your preferences and/or opinions;</ListItem>
        <ListItem>information you provide to us through surveys;</ListItem>
        <ListItem>
          your browser session and geo-location data, device and network
          information, statistics on page views and sessions, acquisition
          sources, search queries, and/or browsing behavior;
        </ListItem>
        <ListItem>
          information about your access and use of our Site or our Sub-brand
          Sites, including through the use of Internet cookies, your
          communications with our Site or our Sub-brand Sites, the type of
          browser you are using, the type of operating system you are using, and
          the domain name of your Internet service provider;
        </ListItem>
        <ListItem>
          additional personal information that you provide to us, directly or
          indirectly, through your use of our Site or our Sub-brand Sites,
          through being asked to participate in a survey by a business you are
          connected with, associated applications, associated social media
          platforms and/or accounts from which you permit us to collect
          information;
        </ListItem>
        <ListItem>
          files and assets (Test Assets) provided to us by you (e.g. prototypes,
          image files, audio files, video files, PDFs) either through direct
          upload or accessed through API integrations approved by you; and
        </ListItem>
        <ListItem>
          any other personal information requested by us and/or provided by you
          or a third party.
        </ListItem>
      </DocumentList>
      <Text>
        We may collect these types of personal information directly from you or
        from third parties.
      </Text>
    </DocumentSection>
    <DocumentSection title="Collection and use of personal information">
      <Text>
        We may collect, hold, use and disclose personal information for the
        following purposes:
      </Text>
      <DocumentList>
        <ListItem>
          to enable you to access and use our Site or our Sub-brand Sites,
          associated applications, and associated social media platforms;
        </ListItem>
        <ListItem>
          to provide full access to our service by collecting data around how
          users interact with Test Assets including, but not limited to, clicks,
          interactions, screen and component transitions, content, and prototype
          data;
        </ListItem>
        <ListItem>to contact and communicate with you;</ListItem>
        <ListItem>
          to contact you regarding user research participation opportunities;
        </ListItem>
        <ListItem>
          to provide our business clients with feedback regarding their products
          and services, where you have chosen to participate in user research;
        </ListItem>
        <ListItem>
          to help our business clients target their tests to specific geographic
          areas;
        </ListItem>
        <ListItem>
          for internal record keeping and administrative purposes;
        </ListItem>
        <ListItem>
          for analytics, market research, and business development, including to
          operate and improve our Site or our Sub-brand Sites, associated
          applications, and associated social media platforms;
        </ListItem>
        <ListItem>
          to run competitions and/or offer additional benefits to you;
        </ListItem>
        <ListItem>
          for advertising and marketing, including to send you promotional
          information about our products and services and information about
          third parties that we consider may be of interest to you;
        </ListItem>
        <ListItem>
          to comply with our legal obligations and resolve any disputes that we
          may have; and
        </ListItem>
        <ListItem>
          where you apply for employment with us; to consider your employment
          application.
        </ListItem>
      </DocumentList>
      <Heading as="h3" size="sm">
        Additional limits on the use of your Google user data
      </Heading>
      <Text>
        Lyssna{"\u2019"}s use and transfer to any other app of information
        received from Google APIs will adhere to{" "}
        <ExternalLink href="https://developers.google.com/terms/api-services-user-data-policy">
          Google API Services User Data Policy
        </ExternalLink>
        , including the Limited Use requirements.
      </Text>
    </DocumentSection>
    <DocumentSection title="Disclosure of personal information to third parties">
      <Text>We may disclose personal information to:</Text>
      <DocumentList>
        <ListItem>
          third party service providers for the purpose of enabling them to
          provide their services, including (without limitation) IT service
          providers, data storage, web-hosting and server providers, debt
          collectors, maintenance or problem-solving providers, marketing or
          advertising providers, professional advisors, and payment systems
          operators;
        </ListItem>
        <ListItem>
          our employees, contractors, and/or related entities;
        </ListItem>
        <ListItem>
          our existing or potential agents or business partners;
        </ListItem>
        <ListItem>payment systems operators;</ListItem>
        <ListItem>sponsors or promoters of any competition we run;</ListItem>
        <ListItem>
          anyone to whom our business or assets (or any part of them) are, or
          may (in good faith) be, transferred;
        </ListItem>
        <ListItem>
          credit reporting agencies, courts, tribunals, and regulatory
          authorities, in the event you fail to pay for goods or services we
          have provided to you;
        </ListItem>
        <ListItem>
          courts, tribunals, regulatory authorities, and law enforcement
          officers, as required by law, in connection with any actual or
          prospective legal proceedings, or in order to establish, exercise, or
          defend our legal rights;
        </ListItem>
        <ListItem>
          third parties, including agents or sub-contractors, who assist us in
          providing information, products, services, or direct marketing to you.
          This may include parties located, or that store data, outside of
          Australia; and
        </ListItem>
        <ListItem>
          third parties to collect and process data, such as Google Analytics or
          other relevant businesses. This may include parties that store data
          outside of Australia.
        </ListItem>
      </DocumentList>
      <Text>
        Where we disclose your personal information to third parties, including
        data processors, we will request that the third party handle your
        personal information in accordance with this Privacy Policy. The third
        party will only process your personal information in accordance with
        written instructions from us and we require that the third party either
        complies with the GDPR or another mechanism set out by applicable EU &
        Swiss data protection laws for the transfer and processing of personal
        information. When we refer to ‘processing’ in this clause and this
        Privacy Policy in general, we mean any operation or set of operations
        which is performed on personal information, whether or not by automated
        means, such as collecting, recording, organizing, structuring, storage,
        adaptation or alteration, retrieval, consultation, use, disclosure by
        transmission, dissemination or otherwise making available personal
        information.
      </Text>
      <Text>
        By providing us with personal information, you consent to the disclosure
        of your personal information to third parties who reside outside
        Australia and, if you are a European Union (<strong>EU</strong>)
        citizen, to third parties that reside outside the EU. Where the
        disclosure of your personal information is solely subject to Australian
        privacy laws (and not subject to the GDPR), you acknowledge that we are
        not required to ensure that those third parties comply with Australian
        privacy laws.
      </Text>
    </DocumentSection>
    <DocumentSection title="How we treat personal information that is also sensitive information">
      <Text>
        Sensitive information is a subset of personal information that is given
        a higher level of protection under the Australian Privacy Principles.{" "}
        <strong>Sensitive information</strong> means information relating to
        your racial or ethnic origin, political opinions, religion, trade union
        or other professional associations or memberships, philosophical
        beliefs, sexual orientation, sexual practices or sex life, criminal
        records, health information, or biometric information.
      </Text>
      <Text>
        We will not collect sensitive information about you without first
        obtaining your consent.
      </Text>
      <Text>
        Provided you consent, your sensitive information may only be used and
        disclosed for purposes relating to the primary purpose for which the
        sensitive information was collected.
      </Text>
      <Text>
        Sensitive information may also be used or disclosed if required or
        authorized by law.
      </Text>
    </DocumentSection>
    <DocumentSection title="Our responsibilities as a ‘controller’ under the GDPR">
      <Text>
        Controllers are defined by the GDPR as natural or legal persons, a
        public authority, agency, or other body to which personal information or
        personal data has been disclosed, whether via a third party or not, and
        who determines the purposes and means of processing personal
        information. We are a controller under the GDPR as we collect, use, and
        store your personal information to enable us to provide you with our
        goods and/or services.
      </Text>
      <Text>
        As a controller, we have certain obligations under the GDPR when
        collecting, storing, and using the personal information of EU citizens.
        If you are an EU citizen, your personal data will:
      </Text>
      <DocumentList>
        <ListItem>
          be processed lawfully, fairly, and in a transparent manner by us;
        </ListItem>
        <ListItem>
          only be collected for the specific purposes we have identified in the
          ‘collection and use of personal information’ clause above and personal
          information will not be further processed in a manner that is
          incompatible with the purposes we have identified;
        </ListItem>
        <ListItem>
          be collected in a way that is adequate, relevant, and limited to what
          is necessary in relation to the purpose for which the personal
          information is processed;
        </ListItem>
        <ListItem>
          be kept up to date, where it is possible and within our control to do
          so (please let us know if you would like us to correct any of your
          personal information);
        </ListItem>
        <ListItem>
          be kept in a form which permits us to identify you, but only for so
          long as necessary for the purposes for which the personal data was
          collected;
        </ListItem>
        <ListItem>
          be processed securely and in a way that protects against unauthorized
          or unlawful processing and against accidental loss, destruction, or
          damage.
        </ListItem>
      </DocumentList>
      <Text>
        We also apply these principles to the way we collect, store, and use the
        personal information of our Australian customers or clients.
      </Text>
      <Text>
        Specifically, we have the following measures in place, in accordance
        with the GDPR:
      </Text>
      <DocumentList>
        <ListItem>
          <strong>Data protection policies</strong>: We have internal policies
          in place which set out where and how we collect personal information,
          how it is stored and where it goes after we get it, in order to
          protect your personal information.
        </ListItem>
        <ListItem>
          <strong>Right to ask us to erase your personal information</strong>:
          You may ask us to erase personal information we hold about you.
        </ListItem>
        <ListItem>
          <strong>Right to ask us to restrict data processing</strong>: You may
          ask us to limit the processing of your personal information where you
          believe that the personal information we hold about you is wrong (to
          give us enough time to verify if the information needs to be changed),
          or where processing data is unlawful and you request us to restrict
          the processing of personal information rather than it being erased.
        </ListItem>
        <ListItem>
          <strong>Notification of data breaches</strong>: We will comply with
          the GDPR in respect of any data breach.
        </ListItem>
      </DocumentList>
    </DocumentSection>
    <DocumentSection title="Our responsibilities as a ‘processor’ under the GDPR">
      <Text>
        Where we are a processor, we have contracts containing certain
        prescribed terms in our contracts with controllers (such as businesses
        that ask us to help them conduct surveys testing their products and
        services). Depending on the circumstances, we can be a controller or
        processor or controller and processor. In addition to:
      </Text>
      <DocumentList>
        <ListItem>
          our contractual obligations with controllers (where we are solely a
          processor); and
        </ListItem>
        <ListItem>
          our legal obligations under the GDPR as a controller (where we are
          both a controller and processor) as a processor we also have the
          following responsibilities under the GDPR:
          <DocumentList>
            <ListItem>
              not to use a sub-processor without the prior written authorisation
              of the data controller;
            </ListItem>
            <ListItem>to co-operate with supervisory authorities;</ListItem>
            <ListItem>to ensure the security of its processing;</ListItem>
            <ListItem>to keep records of processing activities;</ListItem>
            <ListItem>
              to notify any personal data breaches to the data controller; and
            </ListItem>
            <ListItem>
              to employ a data protection officer and appoint (in writing) a
              representative within the European Union if required by the GDPR.
            </ListItem>
          </DocumentList>
        </ListItem>
      </DocumentList>
    </DocumentSection>
    <DocumentSection title="Your rights and controlling your personal information">
      <Text>
        <strong>Choice and consent</strong>: Please read this Privacy Policy
        carefully. By providing personal information to us, you consent to us
        collecting, holding, using and disclosing your personal information in
        accordance with this Privacy Policy. If you are under 16 years of age,
        you must have; and warrant to the extent permitted by law to us that you
        have, your parent or legal guardian{"\u2019"}s permission to access and
        use the Site and they (your parents or guardian) have consented to you
        providing us with your personal information. You do not have to provide
        personal information to us, however, if you do not, it may affect your
        use of this Site or the products and/or services offered on or through
        it.
      </Text>
      <Text>
        <strong>Information from third parties</strong>: If we receive personal
        information about you from a third party (such as from a business you
        are connected with, where that business would like you to participate in
        a survey or interview), we will protect it as set out in this Privacy
        Policy. If you are a third party providing personal information about
        somebody else, you represent and warrant that you have such person
        {"\u2019"}s consent to provide the personal information to us.
      </Text>
      <Text>
        <strong>Restrict</strong>: You may choose to restrict the collection or
        use of your personal information. If you have previously agreed to us
        using your personal information for direct marketing purposes, you may
        change your mind at any time by contacting us using the details below,
        or use the functionality provided on your member dashboard (if you are a
        member) to control how we process your personal information. If you ask
        us to restrict how we process your personal information, we will let you
        know how the restriction affects your use of our Site or our Sub-brand
        Sites or products and services.
      </Text>
      <Text>
        <strong>Access and data portability</strong>: You may request details of
        the personal information that we hold about you. You may request a copy
        of the personal information we hold about you. Where possible, we will
        provide this information in CSV format or other easily readable machine
        format. You may request that we erase the personal information we hold
        about you at any time. You may also request that we transfer this
        personal information to another third party (data portability).
      </Text>
      <Text>
        <strong>Correction</strong>: If you believe that any information we hold
        about you is inaccurate, out of date, incomplete, irrelevant, or
        misleading, please contact us using the details below (or use the
        functionality on your member dashboard, if you are a member). We will
        take reasonable steps to correct any information found to be inaccurate,
        incomplete, misleading, or out of date.
      </Text>
      <Text>
        <strong>Complaints</strong>: If you believe that we have breached the
        Australian Privacy Principles or an article of the GDPR and wish to make
        a complaint, please contact us using the details below and provide us
        with full details of the alleged breach. We will promptly investigate
        your complaint and respond to you, in writing, setting out the outcome
        of our investigation and the steps we will take to deal with your
        complaint. You also have the right to contact the Office of the
        Australian Information Commissioner if you wish to make a complaint.
      </Text>
      <Text>
        <strong>Unsubscribe</strong>: To unsubscribe from our e-mail database or
        opt-out of communications (including marketing communications), please
        contact us using the details below or opt-out using the opt-out
        facilities provided in the communication
      </Text>
    </DocumentSection>
    <DocumentSection title="Storage and security">
      <Text>
        We are committed to ensuring that the personal information we collect is
        secure. In order to prevent unauthorized access or disclosure, we have
        put in place suitable physical, electronic, and managerial procedures
        such as the pseudonymization and encryption of personal information, to
        safeguard and secure personal information and protect it from misuse,
        interference, loss, and unauthorized access, modification and
        disclosure.
      </Text>
      <Text>
        We cannot guarantee the security of any information that is transmitted
        to or by us over the Internet. The transmission and exchange of
        information is carried out at your own risk. Although we take measures
        to safeguard against unauthorized disclosures of information, we cannot
        assure you that the personal information we collect will not be
        disclosed in a manner that is inconsistent with this Privacy Policy.
      </Text>
    </DocumentSection>
    <DocumentSection title="Cookies and web beacons">
      <Text>
        We may use cookies on our Site or our Sub-brand Sites from time to time.
        Cookies are text files placed in your computer{"\u2019"}s browser to
        store your preferences. Cookies, by themselves, do not tell us your
        email address or other personally identifiable information. However,
        they do allow third parties, such as Google and Facebook, to cause our
        advertisements to appear on your social media and online media feeds as
        part of our retargeting campaigns. If and when you choose to provide our
        Site or our Sub-brand Sites with personal information, this information
        may be linked to the data stored in the cookie.
      </Text>
      <Text>
        We may use web beacons on our Site or our Sub-brand Sites from time to
        time. Web beacons (also known as Clear GIFs) are small pieces of code
        placed on a web page to monitor the visitor{"\u2019"}s behavior and
        collect data about the visitor{"\u2019"}s viewing of a web page. For
        example, web beacons can be used to count the users who visit a web page
        or to deliver a cookie to the browser of a visitor viewing that page.
      </Text>
    </DocumentSection>
    <DocumentSection title="Links to other websites">
      <Text>
        Our site or our Sub-brand Sites may contain links to other websites. We
        do not have any control over those websites and we are not responsible
        for the protection and privacy of any personal information which you
        provide whilst visiting those websites. Those websites are not governed
        by this Privacy Policy.
      </Text>
    </DocumentSection>
    <DocumentSection title="Amendments">
      <Text>
        We may, at any time and at our discretion, vary this Privacy Policy. We
        will notify you if we amend this Privacy Policy, by contacting you
        through the contact details you have provided to us. Any amended Privacy
        Policy is effective once we notify you of the change.
      </Text>
      <Text>
        For any questions or notices, please contact our Privacy Officer at:
      </Text>
      <Text>UsabilityHub Pty Ltd ABN 65 158 305 205 trading as Lyssna</Text>
      <PrivacyMailtoLink />
    </DocumentSection>
  </Document>
)
