import { Box, Flex } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import { Dispatch } from "Redux/app-store"
import {
  MAX_TASKS,
  addTask,
} from "Redux/reducers/test-builder-form/action-creators/live-website-test"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import React from "react"
import { useDispatch } from "react-redux"
import { LiveWebsiteTask } from "./LiveWebsiteTask"

export const LiveWebsiteTestTaskList: React.FC = () => {
  const dispatch: Dispatch = useDispatch()
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  if (!section.live_website_test) {
    throw new Error("Live website test section is missing attributes")
  }

  const tasks = section.live_website_test.live_website_test_tasks.filter(
    (task) => !task._destroy
  )

  const handleAddTask = () => {
    dispatch(addTask(sectionIndex))
  }

  return (
    <>
      {tasks.map((_task, i) => (
        <React.Fragment key={i}>
          {/* TODO: this needs a stable ID for when we add drag and drop */}
          <LiveWebsiteTask taskIndex={i} />
          <Box as="hr" mx={-8} />
        </React.Fragment>
      ))}

      {!readOnly && (
        <Flex>
          <Button
            variant="primary"
            isDisabled={tasks.length >= MAX_TASKS}
            onClick={handleAddTask}
          >
            Add live website task
          </Button>
        </Flex>
      )}
    </>
  )
}
