import {
  Box,
  BoxProps,
  Center,
  Flex,
  FlexProps,
  Portal,
  Stack,
  StackProps,
  Text,
  TextProps,
  forwardRef,
  usePrefersReducedMotion,
  useToken,
} from "@chakra-ui/react"
import { motion } from "framer-motion"
import React, { PropsWithChildren } from "react"

import { BottomPanelPortalContext } from "UsabilityHub/components/UsabilityTestLayout/BottomPanelPortal"
import { RecordingThumbnail } from "./RecordingThumbnail"

type SectionTaskProps = {
  boxProps?: BoxProps
} & FlexProps

export const SectionTask = forwardRef<
  PropsWithChildren<SectionTaskProps>,
  typeof Flex
>(({ children, boxProps = {}, ...flexProps }, ref) => {
  return (
    <Box
      pos="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      overflowX="hidden"
      overflowY="auto"
      data-testid="section-task"
      {...boxProps}
    >
      <Flex
        direction="column"
        justify="top"
        align="center"
        ref={ref}
        w="full"
        minH="full"
        minW="300px"
        {...flexProps}
      >
        {children}
      </Flex>
      <RecordingThumbnail />
    </Box>
  )
})

export const SectionTaskHeader: React.FC<
  React.PropsWithChildren<StackProps>
> = ({ children, ...props }) => {
  return (
    <Stack align="center" direction="column" p={4} flexShrink={0} {...props}>
      {children}
    </Stack>
  )
}

interface SectionTaskInstructionsProps extends TextProps {
  variant?: "primary" | "secondary"
  align?: "center" | "left"
}

export const SectionTaskInstructions: React.FC<
  React.PropsWithChildren<SectionTaskInstructionsProps>
> = ({ children, variant = "primary", align = "center", ...props }) => {
  const isPrimary = variant === "primary"
  const prefersReducedMotion = usePrefersReducedMotion()
  return (
    <Text
      transitionDuration={prefersReducedMotion ? "0ms" : "200ms"}
      transitionProperty="font-size, font-weight, color"
      maxW="xl"
      align={align}
      color={isPrimary ? "gray.700" : "text.secondary"}
      fontWeight={isPrimary ? "semibold" : "regular"}
      fontSize={isPrimary ? "lg" : "md"}
      {...props}
    >
      {children}
    </Text>
  )
}
export const SectionTaskContent = forwardRef<FlexProps, typeof Flex>(
  ({ children, ...flexProps }, ref) => {
    return (
      <Flex ref={ref} w="full" justify="center" flex={1} {...flexProps}>
        <Center w="full">{children}</Center>
      </Flex>
    )
  }
)

// SectionTaskFooter is rendered in the BottomPanel to keep the SectionTask
// scrollable and collapse when the footer is shown. This means we don't have to
// share the height of the footer around. We use a PortalContext to get a ref
// to the container div and animate the height with framer-motion.
const MotionFlex = motion(Flex)
export const SectionTaskFooter = forwardRef<
  FlexProps & { animateHeight?: number | string },
  typeof Flex
>(({ animateHeight, children, ...flexProps }, ref) => {
  const [height] = useToken("sizes", ["32"])
  const prefersReducedMotion = usePrefersReducedMotion()
  return (
    <BottomPanelPortalContext.Consumer>
      {(containerRef) => {
        if (!containerRef) return null
        return (
          <Portal containerRef={containerRef}>
            <MotionFlex
              animate={
                prefersReducedMotion
                  ? { height }
                  : { height: animateHeight ?? height }
              }
              initial={prefersReducedMotion ? { height } : { height: 0 }}
              pos="sticky"
              direction="column"
              bottom={0}
              left={0}
              right={0}
              ref={ref}
              justify="center"
              overflow="hidden"
              flex={1}
              bg="white"
              borderTop="2px solid"
              borderColor="gray.200"
              {...flexProps}
            >
              {children}
            </MotionFlex>
          </Portal>
        )
      }}
    </BottomPanelPortalContext.Consumer>
  )
})
