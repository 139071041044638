import {
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline"
import React, { useCallback, useRef, useState } from "react"

import { FigmaTaskPath, FigmaTaskScreenResult } from "Types"

import { ClickOccurrenceFilterProvider } from "../../ScreenResults/ClickOccurrenceFilterProvider"
import { ScreenResultDetails } from "../../ScreenResults/ScreenResultDetails"
import { ScreenThumbnailContainer } from "../../ScreenResults/ScreenThumbnail"
import { useArrowKeys } from "../../hooks/use-arrow-keys"
import { PathStatsContainer } from "../PathStats/PathStatsContainer"

interface IndividualScreenModalProps extends Omit<ModalProps, "children"> {
  initialIndex: number
  screenResults: FigmaTaskScreenResult[]
  path: FigmaTaskPath
}

export function PathEntryDetailsModal({
  initialIndex,
  screenResults,
  path,
  ...props
}: IndividualScreenModalProps) {
  const listRef = useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = useState(initialIndex)

  const setIndexAndScrollIntoView = useCallback(
    (setter: (currentIndex: number) => number) => {
      setSelectedIndex((currentIndex) => {
        const newIndex = setter(currentIndex)

        const listEntry = listRef.current?.children.item(newIndex)
        if (listEntry) {
          listEntry.scrollIntoView()
        }

        return newIndex
      })
    },
    [setSelectedIndex]
  )

  const handlePreviousSelectedNodeId = () =>
    setIndexAndScrollIntoView((currentIndex) => Math.max(0, currentIndex - 1))

  const handleNextSelectedNodeId = () =>
    setIndexAndScrollIntoView((currentIndex) =>
      Math.min(screenResults.length - 1, currentIndex + 1)
    )

  useArrowKeys(
    {
      onLeft: handlePreviousSelectedNodeId,
      onRight: handleNextSelectedNodeId,
    },
    props.isOpen
  )

  const selectedScreenResult = screenResults[selectedIndex]

  return (
    <ClickOccurrenceFilterProvider>
      <Modal {...props}>
        <ModalOverlay>
          <ModalContent
            overflow="hidden"
            height="calc(100% - 64px)"
            marginY="32px"
            display="flex"
            flexDirection="column"
            data-qa={`path-modal-${path.pathId}`}
          >
            <ModalCloseButton />
            <ModalBody
              p={0}
              flex="1"
              overflow="hidden"
              display="flex"
              flexDirection="column"
            >
              <Box as="header" pt={6} px={6} pb={3}>
                <Heading size="sm">
                  {path.type === "common" && path.pathName}
                  {path.type === "individual" && "Individual Path"}
                </Heading>
                <HStack spacing={2} textStyle="label" mt={3}>
                  {path.type === "common" && (
                    <PathStatsContainer type="common" pathMeta={path.meta} />
                  )}
                  {path.type === "individual" && (
                    <PathStatsContainer
                      type="individual"
                      pathMeta={path.meta}
                    />
                  )}
                </HStack>
              </Box>
              <Box bg="gray.50">
                <Flex
                  ref={listRef}
                  gap="2"
                  overflowX="auto"
                  bg="gray.50"
                  py={3}
                  px={6}
                  borderColor="gray.200"
                  borderTopWidth={1}
                  borderBottomWidth={1}
                >
                  {screenResults.map((screenResult, index) => (
                    <Box
                      as="button"
                      outline="none"
                      role="group"
                      key={index}
                      width="160px"
                      flexShrink="0"
                      onClick={() => setSelectedIndex(index)}
                    >
                      <ScreenThumbnailContainer
                        hideStats
                        screenResult={screenResult}
                        isSelected={index === selectedIndex}
                      />
                    </Box>
                  ))}
                </Flex>
              </Box>
              <Flex
                as="section"
                flex={1}
                position="relative"
                overflow="hidden"
                flexDirection="column"
              >
                <ScreenResultDetails.Provider
                  screenResult={selectedScreenResult}
                >
                  <ScreenResultDetails.Tabs />
                  <ScreenResultDetails.ZoomablePanel />
                  <ScreenResultDetails.StatusBar />
                </ScreenResultDetails.Provider>
                <Flex
                  position="absolute"
                  top="50%"
                  width="100%"
                  px="8"
                  justifyContent="space-between"
                  color="gray.500"
                >
                  <button
                    type="button"
                    onClick={handlePreviousSelectedNodeId}
                    data-qa="previous"
                  >
                    <Icon
                      as={ChevronLeftIcon}
                      fontSize="2xl"
                      cursor="pointer"
                    />
                  </button>

                  <button
                    type="button"
                    onClick={handleNextSelectedNodeId}
                    data-qa="next"
                  >
                    <Icon
                      as={ChevronRightIcon}
                      fontSize="2xl"
                      cursor="pointer"
                    />
                  </button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </ClickOccurrenceFilterProvider>
  )
}
