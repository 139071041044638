const missColor = "rgba(240,62,62,.8)"
const hitColor = "rgba(125,191,98,.8)"
const defaultColor = "rgba(118,169,250,.8)"

export type ClickType = "default" | "hit" | "miss"

export function getClickMarkerColor(clickType: ClickType) {
  if (clickType === "miss") return missColor
  if (clickType === "hit") return hitColor

  return defaultColor
}
