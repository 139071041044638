import { Text } from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"

export const TextHighlight: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text
      as="span"
      position="relative"
      fontWeight="semibold"
      p={1.5}
      _before={{
        content: '""',
        position: "absolute",
        inset: "0",
        bg: "brand.primary.200",
        rounded: "sm",
        transform: "skew(-10deg) rotate(-0.002turn)",
        zIndex: -1,
      }}
    >
      {children}
    </Text>
  )
}
