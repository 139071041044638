import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import React, { useState } from "react"

import Illustration from "Images/app-illustrations/trial-used.png"
import { useModal } from "Utilities/modals/use-modal"
import { useLocation, useNavigate } from "react-router"

import { IntercomTriggerLink } from "Components/IntercomTriggerLink"
import { StaticPlanDrawer } from "UsabilityHub/components/PlanChanger/plan-drawer"

export const UsedFreeTrial: React.FC = () => {
  const location = useLocation()

  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(
    () => location.state?.freeTrial === "used"
  )

  const { open: openPlanDrawer } = useModal(StaticPlanDrawer, "plan-drawer")

  const close = () => {
    setIsOpen(false)
    navigate(location.pathname, { state: null, replace: true })
  }

  const viewPlans = () => {
    close()
    openPlanDrawer({
      source: "free_trial_used_modal",
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={close} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton right={2} />
        <ModalBody display="flex" flexDirection="column" gap={4} p={10}>
          <Image
            src={Illustration}
            alt="A UX researcher with lots of options"
            borderRadius="xl"
          />
          <Heading fontSize="3xl" color="chakra-subtle-text">
            Looks like you{"\u2019"}ve already used your 14-day Pro plan free
            trial.
          </Heading>
          <Text as="p">
            No problem, you can continue to use Lyssna on a Free plan, or choose
            to upgrade to a paid subscription at any time. Need some help?{" "}
            <IntercomTriggerLink />
          </Text>
        </ModalBody>
        <ModalFooter
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap={4}
          px={10}
          pt={0}
          pb={10}
        >
          <Button variant="outline" onClick={viewPlans}>
            View plans
          </Button>
          <Button
            variant="solid"
            colorScheme="brand.primary"
            autoFocus
            onClick={close}
          >
            Continue with Free plan
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
