import React from "react"

import { createErrorContent } from "./create-error-content"

export function unhandledDeletionReasonContent() {
  return createErrorContent(
    <>
      <p className="para">
        <strong>Response canceled</strong>
      </p>
      <p className="para">
        This response cannot be continued because it has been marked canceled.
      </p>
    </>
  )
}
