import { useState } from "react"

import {
  DEFAULT_SCALING_SETTING,
  getFigmaFileKey,
  getFigmaScaling,
  getStartNodeId,
  isFigmaEmbedUrl,
} from "./utils"

export const useImportUrl = () => {
  const [importUrl, setImportUrl] = useState("")
  const startNodeId = getStartNodeId(importUrl)
  const figmaFileKey = getFigmaFileKey(importUrl)

  const figmaScaling = getFigmaScaling(importUrl)
  let scaling = DEFAULT_SCALING_SETTING
  if (figmaScaling) {
    scaling = figmaScaling.replaceAll("-", "_")
  }

  const errorMessage = getErrorMessage(importUrl, startNodeId)

  return {
    setImportUrl,
    importUrl,
    error: errorMessage,
    figmaFileKey,
    startNodeId,
    scaling,
  }
}

const getErrorMessage = (importUrl: string, startNodeId: string | null) => {
  const isInvalidUrl = !isFigmaEmbedUrl(importUrl) || startNodeId === null
  if (isInvalidUrl && importUrl !== "") {
    if (isFigmaEmbedUrl(importUrl) && startNodeId === null) {
      return `Please check that the prototype has a Flow set up and use the direct link to the Flow you${"\u2019"}re testing.`
    } else {
      return `We couldn${"\u2019"}t recognise that Figma link, please check it and try again.`
    }
  } else {
    return null
  }
}
