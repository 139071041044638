import { Box } from "@chakra-ui/react"
import {
  DesktopOnly,
  MobileOnly,
} from "Components/break-points/desktop-and-mobile-break-points"
import DeviceFrame from "Components/device-frame/device-frame"
import {
  ScreenshotWithId,
  UnscaledScreenshotWithId,
} from "Components/screenshot/screenshot-with-id"
import { Dimensions, DeviceFrame as IDeviceFrame } from "Types"
import React, { Ref } from "react"

interface Props {
  deviceFrame: IDeviceFrame | null
  screenshotId: number
  deviceFrameContentRef?: Ref<HTMLDivElement>
  isScrollable?: boolean
  maxPlayableSize?: Dimensions
  canSkipAheadOnFirstWatch: boolean
}

const ConditionalScreenshotDeviceFrame: React.FC<
  React.PropsWithChildren<Props>
> = (props) => {
  const {
    children,
    deviceFrame,
    deviceFrameContentRef,
    isScrollable,
    screenshotId,
    maxPlayableSize,
    canSkipAheadOnFirstWatch,
  } = props
  const unframedNode = (
    <ScreenshotWithId
      constrainWidthToDeviceFrame={deviceFrame}
      screenshotId={screenshotId}
      draggable={false}
      maxPlayableSize={maxPlayableSize}
      canSkipAheadOnFirstWatch={canSkipAheadOnFirstWatch}
    >
      {children}
    </ScreenshotWithId>
  )

  return deviceFrame === null ? (
    unframedNode
  ) : (
    <Box maxW="100%">
      <DesktopOnly>
        <DeviceFrame
          contentRef={deviceFrameContentRef}
          deviceFrame={deviceFrame}
          isScrollable={isScrollable}
        >
          <UnscaledScreenshotWithId
            screenshotId={screenshotId}
            width="100%"
            draggable={false}
            canSkipAheadOnFirstWatch={canSkipAheadOnFirstWatch}
          />
          {children}
        </DeviceFrame>
      </DesktopOnly>
      <MobileOnly>{unframedNode}</MobileOnly>
    </Box>
  )
}

export default ConditionalScreenshotDeviceFrame
