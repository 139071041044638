import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

const PromptConfigurationContext = createContext<{
  isPromptEnabled: boolean
  disablePrompt: () => void
}>({
  // Set defaults so that the prompt is enabled when outside a provider
  // (For example, on the dashboard)
  isPromptEnabled: true,
  disablePrompt: () => {},
})

export const usePromptConfiguration = () => {
  return useContext(PromptConfigurationContext)
}

export const PromptConfigurationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isPromptEnabled, setIsPromptEnabled] = useState(true)

  const disablePrompt = useCallback(() => {
    setIsPromptEnabled(false)
  }, [setIsPromptEnabled])

  const contextValue = useMemo(
    () => ({
      isPromptEnabled,
      disablePrompt,
    }),
    [isPromptEnabled, disablePrompt]
  )

  return (
    <PromptConfigurationContext.Provider value={contextValue}>
      {children}
    </PromptConfigurationContext.Provider>
  )
}
