import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Text,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { groupBy } from "lodash"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"
import {
  useCreateDemographicPreset,
  useUpdateDemographicPreset,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { useOrderForm } from "../OrderFormProvider"

type Props = {
  onClose: () => void
}

const CreateFormSchema = Yup.object().shape({
  preset_name: Yup.string()
    .max(100, "Saved group name must be 100 characters or less")
    .when("update_existing", ([updateExisting], schema) => {
      return updateExisting
        ? schema
        : schema.required("Saved group name is required")
    }),
  update_existing: Yup.boolean().defined(),
})

type CreateForm = Yup.InferType<typeof CreateFormSchema>

export const SavePresetModal: React.FC<Props> = ({ onClose }) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const { selectedOptionIds, ageRange, targetLocations, appliedPreset } =
    useOrderForm()

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { isValid, isSubmitting, errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(CreateFormSchema),
    defaultValues: {
      preset_name: "",
      update_existing: appliedPreset !== null,
    },
  })

  const { mutateAsync: createPreset } = useCreateDemographicPreset({
    onSuccess: async () => {
      const key = queryKeyFn({
        path: "/api/demographic_presets",
        operationId: "listDemographicPresets",
        variables: {},
      })

      await queryClient.invalidateQueries(key)
      onClose()

      toast({
        title: "Saved demographic group created",
        status: "success",
      })
    },
    onError: () => {
      toast({
        title: "Failed to save demographic group",
        status: "error",
      })
    },
  })

  const { mutateAsync: updatePreset } = useUpdateDemographicPreset({
    onSuccess: async () => {
      const key = queryKeyFn({
        path: "/api/demographic_presets",
        operationId: "listDemographicPresets",
        variables: {},
      })

      await queryClient.invalidateQueries(key)
      onClose()

      toast({
        title: "Demographic group updated",
        status: "success",
      })
    },
    onError: () => {
      toast({
        title: "Failed to update saved demographic group",
        status: "error",
      })
    },
  })

  const onSubmit: SubmitHandler<CreateForm> = async (values) => {
    const groupedLocations = groupBy(targetLocations, "type")

    const countries = groupedLocations.country?.map((l) => l.id) ?? []
    const location_state_ids =
      groupedLocations.state?.map((l) => Number(l.id)) ?? []
    const location_city_ids =
      groupedLocations.city?.map((l) => Number(l.id)) ?? []

    const params = {
      demographic_attribute_option_ids: selectedOptionIds,
      min_age: ageRange[0],
      max_age: ageRange[1],
      countries,
      location_state_ids,
      location_city_ids,
    }

    try {
      if (values.update_existing && appliedPreset) {
        await updatePreset({
          pathParams: { id: appliedPreset.id },
          body: {
            ...params,
            name: appliedPreset.name,
          },
        })
      } else {
        await createPreset({
          body: {
            ...params,
            name: values.preset_name ?? "",
          },
        })
      }
    } catch (error) {
      // The error will be handled by the mutation's onError handler
    }
  }

  const updateExisting = watch("update_existing")

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />

      <ModalContent maxW="xl" my="auto">
        <ModalHeader borderBottomColor="gray.200" pb={0}>
          <Heading>Create a new saved demographic group</Heading>

          <ModalCloseButton />
        </ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody py={4}>
            {appliedPreset ? (
              <>
                <Text fontSize="md" fontWeight={400} mt={2} mb={6}>
                  Would you like to update your most recently applied group with
                  the current demographic selections?
                </Text>

                <Flex direction="column" mb={updateExisting ? 0 : 6}>
                  <Radio
                    fontSize="sm"
                    // Can't use ...register/value because they don't handle the Chakra propnames and
                    // don't coerce string values into booleans properly.
                    isChecked={updateExisting}
                    onChange={() =>
                      setValue("update_existing", true, {
                        shouldValidate: true,
                      })
                    }
                  >
                    Yes - update the {appliedPreset.name} saved group
                  </Radio>
                  <Radio
                    fontSize="sm"
                    isChecked={!updateExisting}
                    onChange={() => setValue("update_existing", false)}
                  >
                    No - create a new saved group
                  </Radio>
                </Flex>
              </>
            ) : (
              <Text fontSize="sm" fontWeight={400} mt={2} mb={6}>
                Your current selection of demographics will be created as a
                saved demographic group, available to all members of your team.
              </Text>
            )}

            {!updateExisting && (
              <FormControl isInvalid={!!errors.preset_name}>
                <FormLabel fontWeight={500} mb={2}>
                  Saved group name
                </FormLabel>

                <Input
                  autoFocus
                  focusBorderColor={errors.preset_name ? "red.500" : undefined}
                  placeholder="Enter saved group name"
                  {...register("preset_name")}
                />

                {errors.preset_name && (
                  <FormErrorMessage>
                    {errors.preset_name.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>

            <Button
              colorScheme="brand.primary"
              type="submit"
              variant="solid"
              isLoading={isSubmitting}
              isDisabled={!isValid}
            >
              {updateExisting ? "Confirm" : "Create"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
