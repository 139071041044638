import { Collapse, Flex, FormLabel, Switch } from "@chakra-ui/react"
import { Dispatch } from "Redux/app-store"
import { updateScreenshotSection } from "Redux/reducers/test-builder-form/action-creators/screenshots"
import { getFormValues } from "Redux/reducers/test-builder-form/selectors/formValues"
import { useSectionIndexContext } from "UsabilityHub/contexts"
import { get } from "lodash"
import React, { ChangeEvent, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SectionMedia } from "../SectionMedia/SectionMedia"

// ts-prune-ignore-next
export const AttachInstructionsMedia: React.FC = () => {
  const usabilityTest = useSelector(getFormValues)
  const sectionIndex = useSectionIndexContext()
  const dispatch: Dispatch = useDispatch()

  const field = `sections[${sectionIndex}].section_screenshots`
  const sectionScreenshots = get(usabilityTest, field)
  const [isToggleEnabled, setIsToggleEnabled] = useState(
    sectionScreenshots.length > 0 && !sectionScreenshots[0]._destroy
  )

  // We only ever have one possible screenshot/video
  const SECTION_MEDIA_INDEX = 0

  const onToggle = (value: boolean) => {
    const field = `sections[${sectionIndex}].section_screenshots`
    const sectionScreenshots = get(usabilityTest, field)

    setIsToggleEnabled(value)

    if (sectionScreenshots.length === 0) return

    // We set a _destroy flag for the screenshot to be removed on save instead
    // of deleting it from the Redux state immediately, because we want users to
    // still have it there if they toggle the switch back on.
    const shouldDestroy = !value
    dispatch(
      updateScreenshotSection(
        sectionScreenshots[SECTION_MEDIA_INDEX]._screenshotClientId,
        { _destroy: shouldDestroy }
      )
    )
  }

  const switchId = `attach-media-toggle-${sectionIndex}`

  return (
    <Flex flexDirection="column" gap={2}>
      <Flex gap={2}>
        <Switch
          id={switchId}
          isChecked={isToggleEnabled}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onToggle(event.target.checked)
          }}
        />
        <FormLabel htmlFor={switchId}>Add image or video</FormLabel>
      </Flex>
      <Collapse in={isToggleEnabled} animateOpacity>
        <SectionMedia allowedMediaTypes={["image", "video"]} hideDesignHeader />
      </Collapse>
    </Flex>
  )
}
