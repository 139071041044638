import { Ref } from "react"

export const multiRef =
  <T>(...refs: ReadonlyArray<Ref<T>>) =>
  (instance: T | null) => {
    refs.forEach((ref) => {
      setRef(ref, instance)
    })
  }

const setRef = <T>(ref: Ref<T>, instance: T | null) => {
  if (ref !== null) {
    if (typeof ref === "function") {
      ref(instance)
    } else {
      ;(ref.current as any) = instance
    }
  }
}
