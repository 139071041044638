import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const VariationSetIcon = createIcon({
  displayName: "VariationSetIcon",
  viewBox: "0 0 24 24",
  path: (
    <g stroke="currentColor" strokeWidth="2" fill="none">
      <rect x="9" y="15" width="12" height="6" rx="2" />
      <rect x="9" y="3" width="12" height="6" rx="2" />
      <path d="M8 18H5a2 2 0 01-2-2V8a2 2 0 012-2h3" />
    </g>
  ),
})
