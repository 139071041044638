import { Heading } from "@chakra-ui/react"
import React from "react"
import { Outlet } from "react-router"

import { Header, HeaderContent } from "Shared/components/Header/Header"
import { PageAside, PageContent, PageMain } from "Shared/components/Page/Page"

import { SettingsSideNav } from "./SettingsSideNav"

export const SettingsWithSidebarLayout: React.FC = () => {
  return (
    <>
      <Header>
        <HeaderContent maxW="8xl">
          <Heading size="lg">Settings</Heading>
        </HeaderContent>
      </Header>
      <PageMain>
        <PageAside flexBasis={{ lg: "xs" }}>
          <SettingsSideNav />
        </PageAside>
        <PageContent maxW="5xl">
          <Outlet />
        </PageContent>
      </PageMain>
    </>
  )
}
