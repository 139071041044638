import { Button, Icon, MenuButton } from "@chakra-ui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import React from "react"

// Set the min width to the largest option for alignment,
// in this case 'Viewer'
const BUTTON_MAX_WIDTH = "5.2rem"

type UserRoleMenuButton = React.FC<
  React.PropsWithChildren<{
    buttonText: string
    isDisabled?: boolean
    size?: string
    textColor?: string
  }>
>

export const UserRoleMenuButton: UserRoleMenuButton = ({
  buttonText,
  isDisabled,
  size,
  textColor,
}) => (
  <MenuButton
    as={Button}
    data-qa="user-role"
    variant="outline"
    isDisabled={isDisabled}
    size={size}
    rightIcon={<Icon as={ChevronDownIcon} />}
    minWidth={BUTTON_MAX_WIDTH}
    textColor={textColor}
  >
    {buttonText}
  </MenuButton>
)
