import {
  canExportCsv,
  getCompletedPanelResponsesCount,
  getHiddenResponseCount,
  getPanelOrdersCount,
  getResponsesCount,
  getResponsesLimit,
  getUsabilityTest,
} from "Redux/reducers/test-results/selectors"
import { Response } from "Types"
import { useSelector } from "react-redux"
import { useFilteredResponses } from "../hooks/use-filtered-responses"

export const useTestResultsSummary = () => {
  const usabilityTest = useSelector(getUsabilityTest)

  const filteredResponses = useFilteredResponses() as Response[]
  const filteredResponseIds = filteredResponses.map((response) => response.id)

  const allResponsesCount = useSelector(getResponsesCount)
  const completedPanelResponsesCount = useSelector(
    getCompletedPanelResponsesCount
  )

  const panelOrdersCount = useSelector(getPanelOrdersCount)
  const responsesLimitCount = useSelector(getResponsesLimit)
  const hiddenResponseCount = useSelector(getHiddenResponseCount)
  const accountCanExportCsv = useSelector(canExportCsv)

  return {
    usabilityTest,
    filteredResponseIds,
    completedPanelResponsesCount,
    panelOrdersCount,
    allResponsesCount,
    responsesLimitCount,
    hiddenResponseCount,
    accountCanExportCsv,
  }
}
