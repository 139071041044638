import { Link, Text } from "@chakra-ui/react"
import anchorme from "anchorme"
import escapeStringRegexp from "escape-string-regexp"
import React from "react"

import { UserPopover } from "./UserPopover"

// Matches any @mentions in the comment text, which take the format:
// `@[Username goes here](123)` (default format for react-mentions)
const MENTION_REGEX = /@\[(.*?)\]\((\d+)\)/g

// Process @mentions and plaintext URLs in comment text
export const processContent = (content: string) => {
  const urls = anchorme.list(content)
  const urlRegex = new RegExp(
    `(${urls.map((url) => escapeStringRegexp(url.string)).join("|")})`
  )

  // Since we're splitting on the mention regex, the array will consist of plain text fragments
  // interspersed with pairs of mention name / mention ID.  They will always alternate.
  // For example, "this comment for @[Maknoon](123) is great" will be split into:
  // ["this comment for ", "Maknoon", "123", " is great"]
  // (If there's no plain text between two mentions, split will still put an empty string)
  const fragmentArray = content.split(MENTION_REGEX)

  return fragmentArray.flatMap((text, fragmentIndex) => {
    if (fragmentIndex % 3 === 0) {
      // Plain text might include URLs so we need to go through a similar exercise here.
      // We're turning URLs into Chakra links for consistency with the rest of the app.
      return text.split(urlRegex).map((innerText, urlFragmentIndex) => {
        if (urlFragmentIndex % 2 === 0)
          return (
            <React.Fragment key={`plain-${fragmentIndex}-${urlFragmentIndex}`}>
              {innerText}
            </React.Fragment>
          )

        return (
          <Link
            key={`url-${fragmentIndex}-${urlFragmentIndex}`}
            target="_blank"
            rel="noopener noreferer"
            href={innerText}
          >
            {innerText}
          </Link>
        )
      })
    } else if (fragmentIndex % 3 === 1) {
      return (
        <UserPopover
          fallbackName={text}
          userId={Number(fragmentArray[fragmentIndex + 1])}
          key={`text-${fragmentIndex}`}
        >
          {(userName) => (
            <Text as="span" color="brand.primary.600">
              @{userName}
            </Text>
          )}
        </UserPopover>
      )
    }
  })
}
