import { Box, Checkbox, HStack, IconButton, Text } from "@chakra-ui/react"
import { TagIcon, UserIcon } from "@heroicons/react/solid"
import { Tag, Tags } from "Components/tags/tags"
import { useIndividualResponseFilter } from "Components/test-results/hooks/use-individual-response-filter"
import { AnswerTagEditorTooltipTarget } from "Components/test-results/question-results/written-answer-question-results/answer-tag-editor-tooltip-target"
import { State } from "Redux/app-store"
import {
  isReadonly as getIsReadonly,
  makeAnswerTagsForAnswerIdSelector,
  tagsByIdForQuestionIdSelector,
} from "Redux/reducers/test-results/selectors"
import { UsabilityTestSectionQuestion as Question, ResponseAnswer } from "Types"
import { useMaybeCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import {
  isMultipleAnswer,
  isMultipleChoice,
} from "Utilities/usability-test-section-question"
import { isBlank } from "Utilities/values"
import React, { ChangeEvent } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"

export type Props = {
  answer: Readonly<ResponseAnswer>
  isSelectable: boolean
  isSelected: boolean
  onDeselect: (answer: Readonly<ResponseAnswer>) => void
  onSelect: (answer: Readonly<ResponseAnswer>) => void
  question: Readonly<Readonly<Question>>
  showAnswerTagButton: boolean
}

export const Answer: React.FC<React.PropsWithChildren<Props>> = ({
  answer,
  isSelectable,
  isSelected,
  onDeselect,
  onSelect,
  question,
  showAnswerTagButton,
}) => {
  const { setResponseId } = useIndividualResponseFilter()
  const useAnswers = isMultipleAnswer(question.type)
  const tags = useSelector((state: State) =>
    getAnswerTagsSelector(state, answer)
  )
  const canManage = useMaybeCurrentUser()?.can_manage_tests ?? false
  const isReadonly = useSelector(getIsReadonly) || !canManage

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onSelect(answer)
    } else {
      onDeselect(answer)
    }
  }

  const renderSingleAnswer = (
    answer: string,
    multipleChoiceOptions: ReadonlyArray<string>
  ) => {
    const fontWeight = multipleChoiceOptions.includes(answer)
      ? "bold"
      : undefined

    return (
      <Text fontWeight={fontWeight} key={answer}>
        {answer}
      </Text>
    )
  }

  const renderAnswerText = (
    answer: Readonly<ResponseAnswer>,
    isPassed: boolean,
    useAnswers: boolean,
    multipleChoiceOptions: ReadonlyArray<string>
  ) => {
    if (isPassed) {
      return (
        <Text fontStyle="italic" color="gray.400">
          No answer given
        </Text>
      )
    }
    if (useAnswers) {
      return (
        <HStack>
          {answer.answers.map((a) =>
            renderSingleAnswer(a, multipleChoiceOptions)
          )}
        </HStack>
      )
    }
    return renderSingleAnswer(answer.answer!, multipleChoiceOptions)
  }

  // Don't use the multiple choice answers from the question if it's not a
  // multiple choice question. It's not guaranteed to be empty in the case
  // that its type has changed.
  //
  // NOTE: Although it may be desirable to keep the bold formatting even after
  //       changing.
  const multipleChoiceOptions = isMultipleChoice(question.type)
    ? question.multiple_choice_options
    : []
  const isPassed = useAnswers
    ? answer.answers.length === 0
    : isBlank(answer.answer)
  // NOTE: This is tightly coupled to test results page integration test.
  const checkboxId = `select-${answer.id}`
  const showCheckbox = isSelectable && !isReadonly

  return (
    <HStack
      border="1px"
      borderColor="gray.200"
      px={4}
      borderRadius={4}
      position="relative"
      role="group"
      as="label"
      data-qa="answer-item"
      htmlFor={checkboxId}
      _hover={{
        borderColor: "gray.400",
      }}
    >
      {showCheckbox && (
        <Checkbox
          id={checkboxId}
          isChecked={isSelected}
          onChange={handleCheckboxChange}
        />
      )}
      <Box py={2}>
        {renderAnswerText(answer, isPassed, useAnswers, multipleChoiceOptions)}
        {tags.length > 0 && (
          <Tags data-qa="answer-tags">
            {tags.map((tag) => (
              <Tag
                key={tag.name}
                color={tag.color}
                tagIndex={tag._index}
                data-qa="answer-tags-tag"
              >
                {tag.name}
              </Tag>
            ))}
          </Tags>
        )}
      </Box>
      {!isReadonly && (
        <HStack
          position="absolute"
          right={2}
          top={-3}
          opacity={0}
          spacing={1}
          _groupHover={{ opacity: 100 }}
          data-qa="answer-item-actions"
        >
          {showAnswerTagButton && (
            <AnswerTagEditorTooltipTarget
              answers={[answer]}
              question={question}
            >
              <IconButton
                icon={<TagIcon width="16px" height="16px" />}
                variant="outline"
                p={1}
                size="xs"
                color="gray.500"
                aria-label="Edit tags"
              />
            </AnswerTagEditorTooltipTarget>
          )}
          <IconButton
            icon={<UserIcon width="16px" height="16px" />}
            variant="outline"
            p={1}
            size="xs"
            color="gray.500"
            aria-label="Open response"
            onClick={() => setResponseId(answer.response_id)}
          />
        </HStack>
      )}
    </HStack>
  )
}

const getAnswerTagsSelector = createSelector(
  (state: State, answer: Readonly<ResponseAnswer>) => ({
    answerTags: makeAnswerTagsForAnswerIdSelector()(state, answer.id),
    questionTags: tagsByIdForQuestionIdSelector(state)(
      answer.usability_test_section_question_id
    ),
  }),
  ({ answerTags, questionTags }) => {
    if (answerTags.length <= 0) {
      return []
    }
    return answerTags.map(
      (answerTag) => questionTags[answerTag.question_tag_id]
    )
  }
)
