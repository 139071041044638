// JsFromRoutes CacheKey d9b1808224c60de5609800ceb6c5fd8d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  orders: /* #__PURE__ */ definePathHelper('get', '/admin/payouts/orders'),
  order: /* #__PURE__ */ definePathHelper('get', '/admin/payouts/orders/:id'),
  reviewResponses: /* #__PURE__ */ definePathHelper('post', '/admin/payouts/orders/:id/review_responses'),
  cancelOrderReview: /* #__PURE__ */ definePathHelper('post', '/admin/payouts/orders/:id/cancel_order_review'),
  reject: /* #__PURE__ */ definePathHelper('put', '/admin/payouts/:id/reject'),
}
