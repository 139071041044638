import { useToast } from "@chakra-ui/react"
import { useTestResultViewState } from "Components/test-results/context/test-result-view-state"
import { isAxiosError } from "Services/axios"
import { useSectionContext } from "UsabilityHub/contexts"
import { useUpdateFigmaSection } from "./use-update-figma-section"

export function useCommonPathNames() {
  const { section } = useSectionContext()
  const { commonPathNamesBySectionId } = useTestResultViewState()
  const updateFigmaSection = useUpdateFigmaSection()
  const toast = useToast()

  // Rename a common path
  return async (usabilityTestId: number, commonPath: string, name: string) => {
    try {
      const sectionId = section.id

      const commonPathNames = {
        ...commonPathNamesBySectionId[sectionId],
        [commonPath]: name,
      }

      await updateFigmaSection({
        usabilityTestId,
        sectionId,
        commonPathNames,
      })
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        toast({
          title: "Unable to rename common path",
          status: "error",
        })
      }
    }
  }
}
