import { useEffect, useRef } from "react"

// Use native validity to jump to element when invalid
export const useSetCustomValidity = (
  error: string
): React.RefObject<HTMLInputElement> => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputRef.current?.setCustomValidity(error || "")
  }, [error])
  return inputRef
}
