import React, { MouseEvent, PureComponent, ReactNode } from "react"

import { percentage } from "Utilities/css-helpers"
import { isLeftButton } from "Utilities/mouse-event"

import styles from "./range.module.scss"

function stopPropagation(event: MouseEvent<any>) {
  event.stopPropagation()
}
interface Props {
  label?: ReactNode
  name: string
  position01: number
  onMouseDown: (name: string) => void
}

export class RangeKnob extends PureComponent<Props> {
  private handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    if (isLeftButton(event)) {
      event.stopPropagation()
      this.props.onMouseDown(this.props.name)
    }
  }

  render() {
    const { label, position01 } = this.props
    return (
      // biome-ignore lint/a11y/useKeyWithClickEvents: Only making the minimal set of changes to resolve PRD-4758. We should fix this in a follow-up.
      <div
        className={styles.thumb}
        style={{ left: percentage(position01) }}
        onMouseDown={this.handleMouseDown}
        onClick={stopPropagation}
      >
        <div className={styles.thumbHandle} />
        {label !== null && <div className={styles.thumbLabel}>{label}</div>}
      </div>
    )
  }
}
