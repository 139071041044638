import { FigmaFileVersionAnswer } from "JavaScripts/types/figma-file-version-answer"
import {
  ParticipantResponse,
  ParticipantUsabilityTest,
  UsabilityTestSection,
} from "Types"
import {
  ResponsePhase,
  SectionState,
  calculateSectionState as defaultCalculateSectionState,
  requiresTaskFlowSuccessAcknowledged as defaultRequiresTaskFlowSuccessAcknowledged,
} from "Utilities/response"
import { useCallback } from "react"

const PHASES = ["interacting", "success_screen", "answering_questions"] as const
export type PrototypeTaskPhase = (typeof PHASES)[number]

export const phasesWithReportButtonVisible: PrototypeTaskPhase[] = [
  "interacting",
  "success_screen",
]

export const usePrototypeTaskPhases = (
  usabilityTest: Readonly<ParticipantUsabilityTest> | null,
  response: Readonly<ParticipantResponse>,
  section: UsabilityTestSection,

  // for test injection
  calculateSectionState: (
    usabilityTest: Readonly<ParticipantUsabilityTest>,
    section: UsabilityTestSection,
    response: Readonly<ParticipantResponse>
  ) => SectionState | null = defaultCalculateSectionState,
  requiresTaskFlowSuccessAcknowledged: (
    section: UsabilityTestSection,
    totalDurationMs: number | null | undefined,
    figmaFileVersionAnswer: FigmaFileVersionAnswer | null | undefined,
    _taskFlowSuccessAcknowledged: boolean | null | undefined
  ) => boolean = defaultRequiresTaskFlowSuccessAcknowledged
) => {
  const responseSection = response?.sections?.find(
    (rs) => rs.usability_test_section_id === section.id
  )
  const sectionState = usabilityTest
    ? calculateSectionState(usabilityTest, section, response)
    : null

  let prototypeTaskPhase: PrototypeTaskPhase = "interacting"
  let advanceToNextPhase = useCallback(
    (finishTaskFn: (taskFlowSuccessAcknowledged?: boolean) => void) =>
      finishTaskFn(),
    []
  )
  let question
  if (sectionState?.phase === ResponsePhase.TakingTest) {
    if (sectionState.question === null) {
      if (
        requiresTaskFlowSuccessAcknowledged(
          section,
          responseSection?.total_duration_ms,
          responseSection?.figma_file_version_answer,
          responseSection?._taskFlowSuccessAcknowledged
        )
      ) {
        prototypeTaskPhase = "success_screen"
        advanceToNextPhase = (
          finishTaskFn: (taskFlowSuccessAcknowledged?: boolean) => void
        ) => finishTaskFn(true)
      } else {
        // base "interacting" phase
      }
    } else {
      prototypeTaskPhase = "answering_questions"
      question = sectionState.question
    }
  }

  return {
    prototypeTaskPhase,
    advanceToNextPhase,
    question,
  }
}
