import { Flex, Text } from "@chakra-ui/react"
import { range } from "lodash"
import React from "react"

import { ButtonSelectField } from "Components/redux-form/button-select"
import {
  AnswerForm,
  Props,
} from "UsabilityHub/components/UsabilityTestSectionQuestion/answer-form"

export const LinearScaleQuestion = (props: Readonly<Props>) => {
  const { question } = props
  const options = range(question.min_value, question.max_value + 1).map(
    (i) => ({
      value: i.toString(),
      label: i.toString(),
    })
  )
  return (
    <AnswerForm {...props}>
      <Flex justifyContent="space-between" fontWeight="medium" mb={5}>
        <Text mr={3}>{question.min_label}</Text>
        <Text ml={3} textAlign="right">
          {question.max_label}
        </Text>
      </Flex>
      <ButtonSelectField name="answer" options={options} />
    </AnswerForm>
  )
}
