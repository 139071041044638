import React from "react"

import { ProTrialIcon } from "Components/trial-widget/ProTrialIcon"
import { useShowProTrialWidget } from "Utilities/account"

export const PaidFeatureTag: React.FC<{
  hasFeature: boolean
}> = ({ hasFeature }) => {
  const showProTrialWidget = useShowProTrialWidget()

  const isIconAttractive = !!(showProTrialWidget || !hasFeature)

  return (
    <ProTrialIcon isAttractive={isIconAttractive} withTooltip={!hasFeature} />
  )
}
