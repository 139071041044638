import classNames from "classnames"
import React, { Component, PropsWithChildren, ReactNode } from "react"
import FlipMove from "react-flip-move"

import styles from "Components/test-results/section-results/section-screenshot-summary-list.module.scss"

interface SummaryListProps {
  readonly className?: string
  readonly children: ReactNode
}

export const SummaryList = ({ className, ...rest }: SummaryListProps) => (
  <ol className={classNames(styles.list, className)} {...rest} />
)

export const AnimatedSummaryList: React.FC<React.PropsWithChildren<unknown>> = (
  props
) => <FlipMove typeName="ol" className={styles.list} {...props} />

// NOTE: Statless components are incompatible with react-flip-move.
export class SummaryItem extends Component<PropsWithChildren<unknown>> {
  render() {
    return <li className={styles.item} {...this.props} />
  }
}
