import { useToast } from "@chakra-ui/react"
import { camelCase, mapKeys, snakeCase } from "lodash"
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react"
import {
  useGetOnboarding as defaultUseGetOnboarding,
  useUpdateOnboarding as defaultUseUpdateOnboarding,
} from "~/api/generated/usabilityhub-components"
import { STEPS } from "./constants"
import { EmailAddress } from "./types"
import {
  InviteTeamMembersResults,
  useInviteTeamMembers,
} from "./useInviteTeamMembers"
import { AllStepData, UseStepsOptions, useSteps } from "./useSteps"

type OnboardingContextType = ReturnType<typeof useSteps> & {
  isLoading: boolean
  isSaving: boolean
  recaptchaSiteKey: string
  inviteTeamMembers: (
    emails: EmailAddress[],
    callback: (results: InviteTeamMembersResults) => void
  ) => void
  invitedTeamMembers: InviteTeamMembersResults
}

const Context = createContext<OnboardingContextType>(
  {} as OnboardingContextType
)

export const useOnboardingContext = () => useContext(Context)

export type OnboardingContextProviderProps = PropsWithChildren<
  UseStepsOptions & {
    recaptchaSiteKey: string
    // Dependency injection: we want the minimum signature possible to make testing easy
    useGetOnboarding?: () => Pick<
      ReturnType<typeof defaultUseGetOnboarding>,
      "refetch" | "data" | "isLoading" | "isFetched"
    >
    useUpdateOnboarding?: () => Pick<
      ReturnType<typeof defaultUseUpdateOnboarding>,
      "mutateAsync" | "isLoading"
    >
  }
>

export const OnboardingContextProvider: React.FC<
  OnboardingContextProviderProps
> = ({
  children,
  recaptchaSiteKey,
  useGetOnboarding = defaultUseGetOnboarding,
  useUpdateOnboarding = defaultUseUpdateOnboarding,
  onComplete,
  ...props
}) => {
  const toast = useToast()
  const { mutateAsync: save, isLoading: isSaving } = useUpdateOnboarding({
    onError: (error) => {
      toast({
        title: "Error",
        description: error.payload.message,
        status: "error",
      })
    },
  })

  const complete: typeof onComplete = (redirect) => {
    save({
      body: { completed_onboarding: true },
    }).then(() => {
      onComplete?.(redirect, true)
    })
  }

  const value = useSteps({ ...props, onComplete: complete })

  const {
    refetch: fetchExistingData,
    isFetched,
    isLoading,
  } = useGetOnboarding({}, { enabled: false, retry: false })

  const {
    step: { id },
  } = value

  const submit = useCallback(
    async (data: (typeof STEPS)[number]["data"]) => {
      if (value.step.id !== "account") {
        try {
          await save({
            body: mapKeys(data, (_, k) => snakeCase(k)),
          })

          value.submit(data)
        } catch (error) {
          // Handled by the onError handler in the mutation already above
        }
      } else {
        value.submit(data)
      }
    },
    [save, value.step.id, value.submit]
  )

  useEffect(() => {
    if (isFetched || id === "account") return
    fetchExistingData().then(({ data }) => {
      value.update(
        mapKeys(data as Record<string, unknown>, (_, k) =>
          camelCase(k)
        ) as AllStepData
      )
    })
  }, [id, isFetched, fetchExistingData])

  const {
    invite: inviteTeamMembers,
    isLoading: isInviting,
    results: invitedTeamMembers,
  } = useInviteTeamMembers()

  return (
    <Context.Provider
      value={{
        ...value,
        submit,
        recaptchaSiteKey,
        isSaving: isSaving || isInviting,
        isLoading,
        inviteTeamMembers,
        invitedTeamMembers,
      }}
    >
      {children}
    </Context.Provider>
  )
}

// for testing
export const OnboardingContextConsumer = Context.Consumer
