import { UsabilityTest } from "Types"

export const getHitzoneIndexById = (hitzoneId: number, test: UsabilityTest) => {
  return getScreenshotSectionByHitzoneId(
    hitzoneId,
    test
  )?.screenshot_hitzones.findIndex(
    (screenshotHitzone) => screenshotHitzone.id === hitzoneId
  )
}

export const getSectionByHitzoneId = (
  hitzoneId: number,
  test: UsabilityTest
) => {
  return test.sections.find((section) => {
    return section.section_screenshots
      .flatMap((sectionScreenshot) => sectionScreenshot.screenshot_hitzones)
      .some((screenshotHitzone) => screenshotHitzone.id === hitzoneId)
  })
}

export const getScreenshotSectionByHitzoneId = (
  hitzoneId: number,
  test: UsabilityTest
) => {
  return getSectionByHitzoneId(hitzoneId, test)?.section_screenshots.find(
    (sectionScreenshot) =>
      sectionScreenshot.screenshot_hitzones.some(
        (screenshotHitzone) => screenshotHitzone.id === hitzoneId
      )
  )
}
