import { countries } from "country-flag-icons"
import Flags from "country-flag-icons/react/3x2"
import React from "react"

interface Props {
  readonly country: string
}

export const Flag = ({ country }: Props) => {
  if (countries.includes(country)) {
    const Flag = Flags[country]
    return <Flag />
  } else {
    return null
  }
}
