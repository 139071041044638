import { ReachedGoalScreenFilter } from "../filters/reached-goal-screen"

import { generateFilterId } from "./generate-filter-id"

function createReachedGoalScreenFilterCategory(
  sectionId: number
): ReachedGoalScreenFilter["category"] {
  return `answer/section:${sectionId}/reached-goal-screen`
}

export function createReachedGoalScreenFilter(
  sectionId: number,
  goalNodeId: string
): ReachedGoalScreenFilter {
  return {
    id: generateFilterId(),
    category: createReachedGoalScreenFilterCategory(sectionId),
    type: "answer/reached-goal-screen",
    value: goalNodeId,
  }
}
