import {
  Alert,
  AlertDescription,
  AlertIcon,
  Center,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { useListDemographicPresets } from "~/api/generated/usabilityhub-components"
import NoDemographicPresetsImage from "./NoDemographicPresets.png"
import { PresetRow } from "./PresetRow"

type Props = {
  onClose: () => void
}

export const PresetList: React.FC<Props> = ({ onClose }) => {
  const { data, isLoading, isError } = useListDemographicPresets({})

  if (isLoading) {
    return (
      <Center minH="100px">
        <Spinner />
      </Center>
    )
  }

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>
          Something went wrong loading your saved groups.
        </AlertDescription>
      </Alert>
    )
  }

  if (data.length === 0) {
    return (
      <Center minH="200px">
        <Flex direction="column" align="center" maxW="340px" gap={3}>
          <Image
            src={NoDemographicPresetsImage}
            alt="No saved groups"
            maxW="100px"
          />

          <Heading as="h3" fontWeight={500}>
            You have no saved groups
          </Heading>

          <Text fontSize="sm" fontWeight={400} textAlign="center">
            You can save a new saved group from the demographics selection of
            your order summary.
            {/* Once we have a help centre article we can bring this "read more" link back. */}
            {/* {" "}<Link
              target="_blank"
              rel="noopener noreferer"
              href="http://example.com"
            >
              Read more
            </Link>{" "}
            about saved demographic groups. */}
          </Text>
        </Flex>
      </Center>
    )
  }

  return (
    <Flex direction="column">
      {data.map((preset) => (
        <PresetRow key={preset.id} preset={preset} onClose={onClose} />
      ))}
    </Flex>
  )
}
