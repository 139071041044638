import { ResultDataProvider } from "UsabilityHub/views/tests/$uniqueId/results/ResultDataProvider"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import React from "react"
import { SharedTestResultsContextProvider } from "./context/shared-test-results"
import { TestResults } from "./test-results-container"

type Props = {
  usabilityTestUniqueId: string
  privateId: string
  responseId: number | null
}

// Since we render the TestResults component from a mount_react in `share_results.haml`,
// and within admin, it won't have all the providers it needs.
export const StandaloneTestResultsWrapper: React.FC<Props> = ({
  usabilityTestUniqueId,
  privateId,
  responseId,
}) => {
  const isSharedApi = !!privateId

  return (
    <ModalContextProvider>
      <ResultDataProvider
        usabilityTestUniqueId={usabilityTestUniqueId}
        useSharedApi={isSharedApi}
        isAdmin={!isSharedApi}
        responseId={responseId}
        privateId={privateId}
        orderId={null}
        isThirdPartyOrder={false}
        revising={false}
      >
        <SharedTestResultsContextProvider isShared>
          <TestResults />
        </SharedTestResultsContextProvider>
      </ResultDataProvider>
    </ModalContextProvider>
  )
}
