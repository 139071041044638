import { Box, BoxProps, Image, ImageProps } from "@chakra-ui/react"
import isSafari from "is-safari"
import React from "react"

import { ClickHeatMap } from "Components/click-map/click-heat-map"
import { ClickMap } from "Components/click-map/click-map"
import { FigmaImage } from "Types"
import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"

import { ScreenView } from "./ScreenResults"

interface ScreenClickProps extends BoxProps {
  figmaImage: FigmaImage
  clicks: NormalizedClick[]
  clickView: ScreenView
}

export const ScreenClicks: React.FC<
  React.PropsWithChildren<ScreenClickProps>
> = ({ figmaImage, clicks, clickView, ...boxProps }) => {
  const { width, height } = figmaImage
  const imageProps: ImageProps = {}
  if (!isSafari) {
    imageProps.crossOrigin = "anonymous"
  }

  return (
    <Box {...boxProps} data-qa={`screen-clicks-${figmaImage.figma_node_id}`}>
      {clickView === "IMAGE" && (
        <Image
          {...imageProps}
          src={figmaImage.url}
          style={{ width: "100%", height: "100%" }}
        />
      )}
      {clickView === "HEAT_MAP" && (
        <ClickHeatMap
          clicks={clicks}
          imageUrl={figmaImage.url}
          width={width}
          height={height}
          style={{ width: "100%", height: "100%", maxWidth: width }}
        />
      )}
      {clickView === "CLICK_MAP" && (
        <ClickMap
          clicks={clicks}
          imageUrl={figmaImage.url}
          width={width}
          height={height}
          style={{ width: "100%", height: "100%", maxWidth: width }}
        />
      )}
    </Box>
  )
}
