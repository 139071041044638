import {
  FigmaImage,
  FigmaTaskScreenResult,
  IndividualFigmaTaskPath,
} from "Types"

import { getScreenResultsFromTask } from "./get-screen-results-from-tasks"

export function getScreenResultsFromIndividualPath(
  path: IndividualFigmaTaskPath,
  figmaImages: FigmaImage[]
): FigmaTaskScreenResult[] {
  return getScreenResultsFromTask(path.task, figmaImages)
}
