import { Page } from "Shared/components/Page/Page"
import { OAuthResponseLayout } from "Shared/components/oauth/OAuthResponseLayout"
import { getIntegrationName } from "Shared/helpers/getIntegrationName"
import React, { useEffect } from "react"

export const OAuthSuccessRoute: React.FC = () => {
  useEffect(() => {
    window.opener?.postMessage({ type: "OAUTH_SUCCESS", integration }, "*")
  })
  const queryParams = new URLSearchParams(window.location.search)
  const integration = getIntegrationName(queryParams.get("integration"))
  const message = `We connected Lyssna and your ${integration} account. Close this window to return to your study.`
  return (
    <Page title="OAuth succeeded">
      <OAuthResponseLayout message={message} />
    </Page>
  )
}
