import { State } from "Redux/app-store"
import { ThunkAction } from "Types"
import { get } from "lodash"
import { arraySplice } from "redux-form"
import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"
import { getFormName, getFormValues } from "../selectors/formValues"

export const editScreenerQuestion =
  (question: ScreenerQuestion): ThunkAction<State> =>
  (dispatch, getState) => {
    const questionIndex = (
      get(
        getFormValues(getState()),
        "screener.questions"
      ) as unknown as ScreenerQuestion[]
    ).findIndex((q) => q.id === question.id)

    dispatch(
      arraySplice(
        getFormName(),
        "screener.questions",
        questionIndex,
        1,
        question
      )
    )
  }
