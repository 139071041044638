import { Flex, useBreakpoint, useBreakpointValue } from "@chakra-ui/react"
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { hasSortableData, sortableKeyboardCoordinates } from "@dnd-kit/sortable"
import React from "react"
import { createPortal } from "react-dom"

import {
  SectionTask,
  SectionTaskContent,
  SectionTaskHeader,
} from "../../SectionTask"

import { Card } from "./Card"
import { useCardSortContext } from "./CardSortContext"
import { CardSortHeader } from "./CardSortHeader"
import { CardSortInstructions } from "./CardSortInstructions"
import { EMPTY_CATEGORY_DROP_ZONE_PREFIX } from "./Category"
import { CategoryArea } from "./CategoryArea"
import { NEW_CATEGORY_DROP_ZONE_PREFIX } from "./NewCategoryDropZone"
import { UncategorizedCardList } from "./UncategorizedCardList"
import { SortDataMap } from "./types"

interface Props {
  instructions: string
  isStarted: boolean
  isComplete: boolean
  requireSortAll: boolean
  requireNameAll: boolean
  onStart: () => void
  onComplete: (sortData: SortDataMap) => void
  onDragCardStart: (cardId: number) => void
  onDragCardEnd: (cardId: number) => void
}

export const CardSort: React.FC<Props> = ({
  instructions,
  isStarted,
  isComplete,
  requireSortAll,
  requireNameAll,
  onComplete,
  onStart,
  onDragCardStart,
  onDragCardEnd,
}) => {
  const {
    active,
    cardSortType,
    cards,
    sortCard,
    isAllCardsSorted,
    numUnnamedOpenCategories,
    openCategories,
    sortData,
    duplicatedCategoryLabels,
  } = useCardSortContext()
  const [draggingCardId, setDraggingCardId] = React.useState<number | null>(
    null
  )
  const breakpoint = useBreakpoint({ ssr: false })
  const sidebarWidth = useBreakpointValue<number>(
    {
      base: 260,
      md: 480,
    },
    { ssr: false }
  )!
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 2, // Just to avoid capturing clicks
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 4,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const enableDoneButton =
    !isComplete &&
    (!requireSortAll || isAllCardsSorted) &&
    (!requireNameAll || numUnnamedOpenCategories === 0) &&
    duplicatedCategoryLabels.length === 0

  const onDragStart = (event: DragStartEvent) => {
    const cardId = Number(event.active.id)
    setDraggingCardId(cardId)

    // Update start time of the card in the section
    onDragCardStart(cardId)
  }

  const onDragOver = (event: DragOverEvent) => {
    if (!hasSortableData(event.active)) return
    if (!hasSortableData(event.over)) return
    const cardId = Number(event.active.id)
    const { containerId: oldCategoryId, index: oldIndex } =
      event.active.data.current.sortable
    const { containerId: newCategoryId, index: newIndex } =
      event.over.data.current.sortable

    if (oldCategoryId !== newCategoryId || oldIndex !== newIndex) {
      sortCard(
        Number(cardId),
        oldCategoryId as string,
        newCategoryId as string,
        newIndex
      )
    }
  }

  const onDragEnd = (event: DragEndEvent) => {
    const cardId = Number(event.active.id)
    setDraggingCardId(null)

    if (!hasSortableData(event.active)) return
    if (!event.over) return

    if (hasSortableData(event.over)) {
      const { containerId: newCategoryId } = event.over.data.current.sortable

      // Update end time of the card in the section if the card is moved into a category
      if (newCategoryId !== "unsorted") onDragCardEnd(Number(cardId))

      // We've already done the sortCard in the dragOver handler so it's not needed here
    } else {
      const oldCategoryId = event.active.data.current.sortable
        .containerId as string
      // If there's no sortableData it's either an empty category or a new category dropzone
      let newContainerId = event.over.id
        .toString()
        .replace(EMPTY_CATEGORY_DROP_ZONE_PREFIX, "")

      // Each dropzone has a uniqueId so they don't share hover state, strip it off here
      if (newContainerId.startsWith(NEW_CATEGORY_DROP_ZONE_PREFIX))
        newContainerId = "new"

      if (newContainerId !== "unsorted") onDragCardEnd(Number(cardId))

      sortCard(Number(cardId), oldCategoryId, newContainerId, 0)
    }
  }

  const replaceCategoryNamesForOpenCardSort = (): SortDataMap => {
    const data: SortDataMap = { ...sortData }

    Object.values(openCategories).forEach((category) => {
      delete Object.assign(data, {
        [`open_${category.label}_uniqueid_${category.id}`]: data[category.id],
      })[category.id]
    })

    return data
  }

  const getSortData = () =>
    cardSortType === "closed" ? sortData : replaceCategoryNamesForOpenCardSort()

  // This header is rendered in a different part of the DOM on mobile vs desktop
  const header = active ? (
    <CardSortHeader
      instructions={instructions}
      enableDoneButton={enableDoneButton}
      onComplete={() => onComplete(getSortData())}
    />
  ) : null

  const draggingCard =
    draggingCardId !== null
      ? cards.find((c) => c.id === Number(draggingCardId))
      : undefined

  return (
    <SectionTask
      h="100%"
      boxProps={{ overflowX: "initial", overflowY: "initial" }}
    >
      {isStarted ? (
        <DndContext
          sensors={sensors}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          collisionDetection={closestCenter}
        >
          <SectionTaskContent h="100%">
            <Flex direction="column" w="100%" h="100%">
              {breakpoint === "base" && header}
              <Flex w="100%" h={["calc(100% - 73px)", "100%"]}>
                <Flex
                  position="relative"
                  display={isComplete ? "none" : "flex"}
                  direction="column"
                  bg="white"
                  h="100%"
                  w={`min(50vw, ${sidebarWidth}px)`}
                  flexGrow={[0, "unset"]}
                >
                  {breakpoint !== "base" && header}

                  <UncategorizedCardList draggingCardId={draggingCardId} />
                </Flex>

                <CategoryArea
                  draggingCardId={draggingCardId}
                  isComplete={isComplete}
                />

                {createPortal(
                  <DragOverlay>
                    {draggingCard ? (
                      <Card
                        isDragging
                        card={draggingCard}
                        parentCategoryId=""
                      />
                    ) : null}
                  </DragOverlay>,
                  document.body
                )}
              </Flex>
            </Flex>
          </SectionTaskContent>
        </DndContext>
      ) : (
        <SectionTaskHeader w="100%" h="100%" p={0}>
          {!isStarted && (
            <CardSortInstructions
              instructions={instructions}
              onStart={onStart}
            />
          )}
        </SectionTaskHeader>
      )}
    </SectionTask>
  )
}
