import { UsabilityTest } from "Types"

export const getSectionByScreenshotSectionId = (
  screenshotSectionId: number,
  test: UsabilityTest
) => {
  return test.sections.find((section) =>
    section.section_screenshots.some(
      (sectionScreenshot) => sectionScreenshot.id === screenshotSectionId
    )
  )
}

export const getSectionIndexByScreenshotSectionId = (
  screenshotSectionId: number,
  test: UsabilityTest
) => {
  return test.sections.findIndex((section) =>
    section.section_screenshots.some(
      (sectionScreenshot) => sectionScreenshot.id === screenshotSectionId
    )
  )
}

export const getScreenshotSectionByScreenshotId = (
  screenshotId: number,
  test: UsabilityTest
) => {
  return test.sections.reduce((result, section) => {
    if (result) return result

    if (section.section_screenshots.length === 0) return undefined

    return section.section_screenshots.find(
      (sectionScreenshot) => sectionScreenshot.id === screenshotId
    )
  }, undefined)
}
