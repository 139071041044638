import { useEffect } from "react"

type Callback = () => void

interface Options {
  onLeft?: Callback
  onRight?: Callback
}

export function useArrowKeys({ onLeft, onRight }: Options, isEnabled: boolean) {
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        onLeft?.()
        e.preventDefault()
      }
      if (e.key === "ArrowRight") {
        onRight?.()
        e.preventDefault()
      }
    }

    if (isEnabled) {
      window.addEventListener("keydown", handleKeydown)
    }

    return () => {
      window.removeEventListener("keydown", handleKeydown)
    }
  }, [isEnabled, onLeft, onRight])
}
