import { Tag } from "DesignSystem/components"
import { Users01SolidIcon } from "Shared/icons/untitled-ui/Users01SolidIcon"
import { InterviewMetrics } from "UsabilityHub/components/InterviewMetrics"
import { StudyCard } from "UsabilityHub/components/StudyCard/StudyCard"
import React, { useState } from "react"
import { Study } from "~/api/generated/usabilityhubSchemas"
import ContextMenu from "./ContextMenu"
import RecruitButton from "./RecruitButton"
import StudyName from "./StudyName"

type Props = {
  study: Extract<Study, { type: "moderated_study" }>
}

const ModeratedStudyCard: React.FC<Props> = ({ study }) => {
  const [renaming, setRenaming] = useState(false)

  const linkTo = (path: string) => `/interviews/${study.id}/${path}`

  return (
    <StudyCard
      isSelectable={false}
      study={study}
      link={linkTo("sessions")}
      tagSlot={
        <Tag label="Interview" leftIcon={Users01SolidIcon} colorScheme="cyan" />
      }
      studyNameSlot={
        <StudyName
          study={study}
          renaming={renaming}
          setRenaming={setRenaming}
        />
      }
      statsSlot={
        <InterviewMetrics
          bookingCounts={study.booking_counts}
          applicantCounts={study.applicant_counts}
        />
      }
      projectInfoSlot={null}
      manageButtonSlot={
        !study.archived ? <RecruitButton study={study} linkTo={linkTo} /> : null
      }
      contextMenuSlot={
        <ContextMenu
          study={study}
          onRename={() => setRenaming(true)}
          linkTo={linkTo}
        />
      }
    />
  )
}

export default ModeratedStudyCard
