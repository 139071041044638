import { Flex, FlexProps, Icon, Text } from "@chakra-ui/react"
import React from "react"

import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import { usePlanChangerContextOnScreen } from "./plan-changer-context"

export const PlanChangeFailed: React.FC<FlexProps> = (props) => {
  const { error } = usePlanChangerContextOnScreen("plan-change-failed")

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      background="gray.50"
      {...props}
    >
      <Icon as={XOutlineIcon} boxSize={12} color="red.500" />
      <Text
        align="center"
        fontSize="3xl"
        fontWeight="semibold"
        color="red.500"
        mt={2}
      >
        Payment failed
      </Text>
      <Text align="center" color="text.primary" fontWeight="normal" mt={4}>
        {error}
      </Text>
    </Flex>
  )
}
