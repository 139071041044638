import { ExactAnswerFilter } from "../filters/exact-answer"

import { generateFilterId } from "./generate-filter-id"

export function createExactAnswerCategory(
  sectionId: number,
  questionId: number
): ExactAnswerFilter["category"] {
  return `answer/section:${sectionId}/question:${questionId}/answer`
}

export function createExactAnswerFilter(
  sectionId: number,
  questionId: number,
  answer: string
): ExactAnswerFilter {
  return {
    id: generateFilterId(),
    category: createExactAnswerCategory(sectionId, questionId),
    type: "answer/exact-answer",
    value: answer,
  }
}
