import { Response } from "Types"

import { FilteredRelatedData, RelatedData } from "../types/related-data"

export function getFilteredResponseRelatedData(
  response: Response,
  relatedData: RelatedData
): FilteredRelatedData {
  const responseAnswers = relatedData.responseAnswers.filter(
    (answer) => answer.response_id === response.id
  )

  const responseSections = relatedData.responseSections.filter(
    (section) => section.response_id === response.id
  )

  const responseSectionFigmaTasks =
    relatedData.responseSectionFigmaTasks.filter((figmaTask) =>
      responseSections.some(
        (responseSection) =>
          figmaTask.response_section_id === responseSection.id
      )
    )

  const screenshotClicks = relatedData.screenshotClicks.filter(
    (click) => click.response_id === response.id
  )

  return {
    responseAnswers,
    responseSections,
    screenshotClicks,
    responseSectionFigmaTasks,
  }
}
