import {
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { Dispatch } from "Redux/app-store"
import { addQuestion } from "Redux/reducers/test-builder-form/action-creators/questions"
import {
  getFormName,
  getFormValue,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { SectionQuestion } from "UsabilityHub/components/TestForm/TestFormCards/SectionCards/SectionQuestions/SectionQuestion"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  QuestionProvider,
  useSectionContext,
  useSectionIndexContext,
  useSectionTypeContext,
} from "UsabilityHub/contexts"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { change } from "redux-form"
import { DroppableQuestionRows } from "./DragDrop/DroppableQuestionRows"

export const SectionQuestions: React.FC = () => {
  const dispatch: Dispatch = useDispatch()
  const sectionType = useSectionTypeContext()
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const { questions, questions_randomized: isRandom } = section
  const onAddQuestion = () => {
    dispatch(addQuestion(sectionIndex, sectionType))
  }

  const questionClientIds = questions.map((question) => question._clientId)

  // When the user turns on randomization of question order for a section, we need to check
  // for any questions in that section that have test logic targeting other questions in the
  // same section, and disable it.
  useEffect(() => {
    if (isRandom) {
      questions.forEach((question) => {
        if (!question.test_logic_statement) return

        const targetClientId = question.test_logic_statement._targetClientId

        if (targetClientId && questionClientIds.includes(targetClientId)) {
          const questionIndex = questionClientIds.indexOf(question._clientId)
          dispatch(
            change(
              getFormName(),
              `sections[${sectionIndex}].questions[${questionIndex}].test_logic_statement._destroy`,
              true
            )
          )
        }
      })
    }
  }, [isRandom])

  return (
    <Box>
      <DroppableQuestionRows
        path={`sections[${sectionIndex}].questions`}
        droppableType="question"
      >
        {questions.map((question) => {
          const questionIndex = questionClientIds.indexOf(question._clientId)

          return (
            <QuestionProvider
              key={question._clientId}
              value={{ question, questionIndex }}
            >
              <SectionQuestion />
            </QuestionProvider>
          )
        })}
      </DroppableQuestionRows>

      <Flex justifyContent="space-between" direction="row">
        <RandomizeQuestionsCheckbox />
        {!readOnly && (
          <Button
            size="sm"
            colorScheme={"brand.primary"}
            onClick={onAddQuestion}
            width={"fit-content"}
          >
            {questions.length === 0 ? "Add a question" : "Add another question"}
          </Button>
        )}
      </Flex>
    </Box>
  )
}

const RandomizeQuestionsCheckbox: React.FC = () => {
  const dispatch = useDispatch<Dispatch>()
  const sectionIndex = useSectionIndexContext()
  const { section } = useSectionContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const [showConfirmation, setShowConfirmation] = useState(false)

  const fieldName = `sections[${sectionIndex}].questions_randomized`

  // We only need to show the confirmation dialog if this section has questions with test logic
  // that target other questions in the same section.
  const allClientIds = section.questions.map((q) => q._clientId)
  const needToConfirm = section.questions.some((question) => {
    if (
      !question.test_logic_statement ||
      question.test_logic_statement._destroy
    )
      return false

    if (!question.test_logic_statement._targetClientId) return false

    return allClientIds.includes(question.test_logic_statement._targetClientId)
  })

  const value = useSelector(getFormValue(fieldName))
  const setValue = (newValue: boolean) => {
    dispatch(change(getFormName(), fieldName, newValue))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked

    if (newValue && needToConfirm) {
      setShowConfirmation(true)
    } else {
      setValue(newValue)
    }
  }

  return (
    <>
      <Checkbox
        isChecked={value}
        isDisabled={readOnly}
        onChange={handleChange}
        variant="mdWithSmFont"
      >
        Randomize the order of questions
      </Checkbox>

      <Modal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <ModalOverlay />

        <ModalContent my="auto">
          <ModalHeader>
            Are you sure you want to randomize the order of questions?
          </ModalHeader>
          <ModalBody>
            Randomizing the order of questions in this section will remove any
            test logic based on questions within the same section.
          </ModalBody>
          <ModalFooter gap={2}>
            <Button
              variant="outline"
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                setValue(true)
                setShowConfirmation(false)
              }}
            >
              Remove test logic
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
