import { ReactOption, Response } from "Types"
import { groupBy } from "lodash"
import { useFlatLocations } from "~/usabilityhub/hooks/useFlatLocations"
import { Option, getOptionsFromMap } from "./get-options"
import { FILTER_UNKNOWN } from "./unknown"

const VALID_LOCATION_TYPES = ["country", "state", "city"] as const

const getLocationValueFromResponse = (response: Response): string => {
  const location = response.response_demographic_profile?.location

  if (!location) return FILTER_UNKNOWN

  return `${location.type}-${location.id}`
}

const byCountDescending = (responses: Response[]) => {
  const countries = responses.map(getLocationValueFromResponse)

  const countMap = groupBy(countries)

  return (a: Option, b: Option) => {
    const countA = a.value === FILTER_UNKNOWN ? -1 : countMap[a.value].length
    const countB = b.value === FILTER_UNKNOWN ? -1 : countMap[b.value].length

    return countB - countA
  }
}

export const useLocationsFromResponses = () => {
  const allLocations = useFlatLocations("usability_tests")

  const getLocationName = (locationString: string) => {
    if (locationString === FILTER_UNKNOWN) return "Unknown"

    const [type, id] = locationString.split("-")

    // Extraordinarily unlikely but let's keep typescript happy
    if (!typeIsValidLocationType(type)) throw new Error("Invalid location type")

    return allLocations[type][id]?.qualifiedName ?? "Unknown"
  }

  const getLocationsFromResponses = (
    responses: Readonly<Response[]>
  ): Map<string, string> => {
    const locations = responses.map(getLocationValueFromResponse)

    // Deduplicate locations and create a map of loc -> name
    const locationStringToNameMap = locations.reduce((map, locationString) => {
      if (!map.has(locationString)) {
        map.set(locationString, getLocationName(locationString))
      }

      return map
    }, new Map<string, string>())

    return locationStringToNameMap
  }

  const getLocationOptionFromResponses = (
    responses: Readonly<Response[]>
  ): ReactOption<string>[] => {
    return getOptionsFromMap(getLocationsFromResponses(responses)).sort(
      byCountDescending(responses as Response[])
    )
  }

  return { getLocationValueFromResponse, getLocationOptionFromResponses }
}

const typeIsValidLocationType = (
  type: string
): type is (typeof VALID_LOCATION_TYPES)[number] => {
  return (VALID_LOCATION_TYPES as readonly string[]).includes(type)
}
