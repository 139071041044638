import { Button, ButtonProps, Link, Text } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Template } from "UsabilityHub/types/templateLibrary"
import { ROUTES } from "UsabilityHub/views/routes"
import React, { Dispatch, ReactNode } from "react"
import { NavigateFunction } from "react-router-dom"
import { useApplyTemplate } from "./useApplyTemplate"

type UseTemplateButtonProps = Omit<ButtonProps, "onError"> & {
  template: Template
  onNavigate: NavigateFunction
  onError: Dispatch<React.SetStateAction<ReactNode>>
}

export const UseTemplateButton: React.FC<UseTemplateButtonProps> = ({
  template,
  onNavigate,
  onError,
  ...props
}) => {
  const {
    available,
    loading: checkingAvailability,
    apply,
    applying,
  } = useApplyTemplate(template)

  const clicked = () => {
    apply()
      .then(({ uniqueId }) => {
        // Replace the state in the history so that the modal doesn't reopen when we hit back
        onNavigate(ROUTES.DASHBOARD.path, { replace: true })
        onNavigate(ROUTES.TEST.EDIT.buildPath({ testId: uniqueId }))
      })
      .catch(() => {
        onError(
          <Text>
            Something went wrong creating your test. Please try again or{" "}
            <Link href={`mailto:${Constants.CUSTOMER_SUPPORT_EMAIL_ADDRESS}`}>
              contact support
            </Link>
            .
          </Text>
        )
      })
  }

  return available ? (
    <Button
      variant="solid"
      colorScheme="brand.primary"
      isLoading={applying}
      loadingText="Applying template"
      onClick={clicked}
      {...props}
    >
      Use this template
    </Button>
  ) : (
    <Button
      variant="solid"
      colorScheme="brand.primary"
      isLoading={checkingAvailability}
      isDisabled
    >
      Coming soon
    </Button>
  )
}
