import { useToast } from "@chakra-ui/react"
import { Alert } from "DesignSystem/components"
import { getEnvState } from "JavaScripts/state"
import React, { useEffect, useState } from "react"
import { useGetVersion } from "~/api/generated/usabilityhub-components"

const NEW_VERSION_TOAST_ID = "new-version-toast"
const POLL_INTERVAL = 1000 * 60 * 10 // 10 minutes

// This component polls the backend to check the current version and if there's been a new one
// then we'll show a toast to the user encouraging them to refresh the page.
export const NewVersionChecker: React.FC = () => {
  const toast = useToast()
  const [isDismissed, setIsDismissed] = useState(false)
  const [lastVersion, setLastVersion] = useState<string>(() => {
    // The current version is serialized into the env state on page load
    return getEnvState().LATEST_VERSION
  })

  const { data } = useGetVersion(
    {},
    {
      // No need to run on initial mount since we have the version serialized on page load
      refetchOnMount: false,
      initialData: { version: lastVersion },
      // Poll to check for new versions
      refetchInterval: POLL_INTERVAL,
      // If they dismiss it, might as well turn off the polling
      enabled: !isDismissed,
    }
  )

  useEffect(() => {
    if (!data) return
    if (isDismissed) return
    if (toast.isActive(NEW_VERSION_TOAST_ID)) return

    if (data.version !== lastVersion) {
      toast({
        id: NEW_VERSION_TOAST_ID, // Prevents multiple toasts from showing
        render: () => (
          <RefreshToast
            onClose={() => {
              toast.close(NEW_VERSION_TOAST_ID)
              setIsDismissed(true)
            }}
          />
        ),
        duration: null,
      })
    }

    setLastVersion(data.version)
  }, [data?.version, lastVersion])

  return null
}

type RefreshToastProps = {
  onClose: () => void
}

const RefreshToast: React.FC<RefreshToastProps> = ({ onClose }) => {
  return (
    <Alert
      status="info"
      onClose={onClose}
      title="New version available"
      description="Please refresh the page to get the latest version"
      actions={[
        {
          label: "Refresh",
          onClick: () => window.location.reload(),
        },
      ]}
    />
  )
}
