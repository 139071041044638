import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Stack,
} from "@chakra-ui/react"
import { Permission } from "Constants/user-role-permission-matrix"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { userRoleString } from "Utilities/user"
import React from "react"

function getPermissionLabel(permission: Permission) {
  if (permission === "manage_account") {
    return "manage account and billing settings"
  }

  if (permission === "manage_team") {
    return "manage the team"
  }

  if (permission === "purchase_credits") {
    return "purchase credits"
  }

  if (permission === "manage_tests") {
    return "create and manage tests"
  }

  if (permission === "spend_credits") {
    return "spend account credits"
  }

  if (permission === "view_tests") {
    return "view tests and results"
  }

  throw new Error(`${permission} is not supported`)
}

const formatter = new Intl.ListFormat("en", {
  style: "long",
  type: "disjunction",
})

interface NoPermissionWarningProps {
  permission: Permission
}

export function NoPermissionWarning({ permission }: NoPermissionWarningProps) {
  const currentUser = useCurrentUser()
  const accountAdminNames = useTeamMembers({
    onlyRoles: ["admin"],
  }).map((user) => user.name)

  return (
    <Alert
      status="warning"
      alignItems="flex-start"
      maxWidth="2xl"
      p={5}
      mx="auto"
    >
      <AlertIcon />
      <Stack>
        <AlertTitle>You don{"\u2019"}t have permission to do that</AlertTitle>
        <AlertDescription>
          <Box>
            You can{"\u2019"}t {getPermissionLabel(permission)} because you
            {"\u2019"}re{" "}
            {currentUser.role === "guest"
              ? userRoleString(currentUser.role)
              : `not ${userRoleString("admin")}`}
            . Contact an account admin ({formatter.format(accountAdminNames)})
            if you{"\u2019"}d like your role to be changed.
          </Box>
        </AlertDescription>
      </Stack>
    </Alert>
  )
}
