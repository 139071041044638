import { createIcon } from "@chakra-ui/react"
import React from "react"

export const RangeIcon = createIcon({
  displayName: "RangeIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="range">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.73244 9C6.38663 9.5978 5.74028 10 5 10C3.89543 10 3 9.10457 3 8C3 6.89543 3.89543 6 5 6C5.74028 6 6.38663 6.4022 6.73244 7H10.2676C10.6134 6.4022 11.2597 6 12 6C13.1046 6 14 6.89543 14 8C14 9.10457 13.1046 10 12 10C11.2597 10 10.6134 9.5978 10.2676 9H6.73244Z"
        fill="currentColor"
      />
    </g>
  ),
})
