import { Box, BoxProps, HStack } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import React, { ReactNode } from "react"

const CardRoot: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box
    bg="ds.surface.raised.resting"
    rounded={16}
    boxShadow="ds.raised"
    w="full"
    maxW="64rem"
    scrollMarginTop="5rem"
    {...props}
  >
    {children}
  </Box>
)

type CardHeaderProps = BoxProps & {
  title: ReactNode
}

const CardHeader: React.FC<CardHeaderProps> = ({
  title,
  children,
  ...props
}) => (
  <HStack
    as="header"
    align="center"
    justify="space-between"
    spacing={6}
    p={6}
    roundedTop={16}
    {...props}
  >
    <Heading as="h2" textStyle="ds.display.primary">
      {title}
    </Heading>
    {children}
  </HStack>
)

const CardBody: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box px={6} pb={6} roundedBottom={16} {...props}>
    {children}
  </Box>
)

export const Card = {
  Root: CardRoot,
  Header: CardHeader,
  Body: CardBody,
}
