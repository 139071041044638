import React from "react"

import { createErrorContent } from "./create-error-content"

export function loadErrorContent() {
  return createErrorContent(
    <>
      <p className="para">
        <strong>Sorry, this test failed to load.</strong>
      </p>
      <p className="para">This test has been reported for review.</p>
    </>
  )
}
