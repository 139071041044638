import {
  useActiveStripeSubscription,
  useMaybeActiveStripeSubscription,
  useMaybeCurrentAccount,
  useMaybeCurrentPlan,
} from "UsabilityHub/hooks/useCurrentAccount"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { Account } from "~/api/generated/usabilityhubSchemas"
import { useMaybeManualSubscription } from "~/usabilityhub/hooks/useCurrentAccount"
import { isPro } from "./plan"

export const useHasPaidSeatAvailable = (account: Account): boolean => {
  const paidTeamMembers = useTeamMembers({
    ignoreRoles: ["archived", "guest"],
  })

  const activeStripeSubscription = useActiveStripeSubscription()
  const activeManualSubscription = useMaybeManualSubscription("active")

  if (account.paid_seat_count === null) {
    //this is used for some plans without seat limit
    return true
  }

  // Otherwise we can use paid_seat_count only to check the available paid seats
  if (account.paid_seat_count > paidTeamMembers.length) {
    return true
  }

  if (account.has_active_stripe_subscription && activeStripeSubscription) {
    return activeStripeSubscription.quantity > paidTeamMembers.length
  }
  if (account.manual_subscription && activeManualSubscription) {
    return (
      activeManualSubscription.paid_seat_count == null || // Let manual users without paid seat counts add unlimited seats for now
      activeManualSubscription.paid_seat_count > paidTeamMembers.length
    )
  }

  return false
}

export const useIsTrialing = (): boolean => {
  const activeStripeSubscription = useMaybeActiveStripeSubscription()
  return activeStripeSubscription?.stripe_status === "trialing"
}

export const useHasUpcomingSubscriptionDuringATrial = (): boolean => {
  const activeStripeSubscription = useMaybeActiveStripeSubscription()
  const isTrialing = useIsTrialing()

  return !!(isTrialing && !activeStripeSubscription!.cancel_at_period_end)
}

export const useTrialHasNotSubscribed = (): boolean => {
  const activeStripeSubscription = useMaybeActiveStripeSubscription()
  const isTrialing = useIsTrialing()

  return !!(isTrialing && activeStripeSubscription!.cancel_at_period_end)
}

export const useInNoPlanState = (): boolean => {
  const currentAccount = useMaybeCurrentAccount()
  return !!currentAccount && currentAccount.plan_unique_id == null
}

export const useShowProTrialWidget = (): boolean => {
  const isTrialing = useIsTrialing()
  const currentPlan = useMaybeCurrentPlan()
  const isProPlan = currentPlan ? isPro(currentPlan) : false
  const hasUpcomingSubscription = useHasUpcomingSubscriptionDuringATrial()

  return isTrialing && isProPlan && !hasUpcomingSubscription
}
