import { Box, Button, Circle, Icon, Text, Tooltip } from "@chakra-ui/react"
import { throttle } from "lodash"
import React, { useCallback, useEffect } from "react"

import { useLocalCommentContext } from "Components/comment-provider/CommentProvider"
import { ChatPlusIcon } from "Shared/icons/ChatPlusIcon"
import { usePostCommentsMarkAsRead } from "~/api/generated/usabilityhub-components"
import { Comment } from "~/api/generated/usabilityhubSchemas"

import { AvatarList } from "./AvatarList"
import { EntityIdentifier } from "./entities"

type Props = {
  entity: EntityIdentifier
  comments: Comment[]
  usersInvolved: NonNullable<Comment["user"]>[]
  isOpen: boolean
  canAddComments: boolean
  badgePosition: "left" | "right"
}

export const CommentMarker: React.FC<Props> = ({
  entity,
  comments,
  usersInvolved,
  isOpen,
  canAddComments,
  badgePosition,
}) => {
  const { openThread, closeActiveThread, markCommentsAsRead } =
    useLocalCommentContext()
  const markCommentsAsReadMutation = usePostCommentsMarkAsRead({
    onSuccess: (data) => {
      markCommentsAsRead(
        comments.filter((c) => data.comment_ids.includes(c.id))
      )
    },
  })

  const unreadComments = comments.filter((c) => !c.is_read)
  const numberOfUnreadComments = unreadComments.length

  const throttledMarkCommentsAsRead = useCallback(
    throttle((unreadComments: Comment[]) => {
      markCommentsAsReadMutation.mutate({
        body: {
          comment_ids: unreadComments.map((c) => c.id),
        },
      })
    }, 1000),
    [markCommentsAsReadMutation.mutate]
  )

  useEffect(() => {
    if (isOpen && numberOfUnreadComments > 0) {
      throttledMarkCommentsAsRead(unreadComments)
    }
  }, [
    isOpen,
    throttledMarkCommentsAsRead,
    numberOfUnreadComments,
    unreadComments,
  ])

  const numberOfComments = comments.length

  // Don't show the add comment button if the current user can't create them
  // (but we still want to show it if there are existing comments)
  if (!canAddComments && numberOfComments === 0) return null

  const tooltip = numberOfComments
    ? `${numberOfComments} comment${numberOfComments === 1 ? "" : "s"}`
    : "Add a comment"

  return (
    <Tooltip hasArrow placement="top" label={tooltip}>
      <Box position="relative" height="fit-content">
        <Button
          display="flex"
          alignItems="center"
          justifyContent="center"
          rounded="full"
          shadow="md"
          bg="white"
          _hover={{ bg: "gray.100" }}
          borderWidth={1}
          borderColor={isOpen ? "brand.primary.600" : "transparent"}
          onClick={() => (isOpen ? closeActiveThread() : openThread(entity))}
          h={usersInvolved.length ? "44px" : "44px"}
          px={usersInvolved.length ? "7px" : "9px"}
          data-qa="expand-comment-thread-button"
          data-intercom-target="expand-comment-thread-button"
        >
          {usersInvolved.length ? (
            <AvatarList users={usersInvolved} />
          ) : (
            <Icon as={ChatPlusIcon} boxSize={6} color="gray.500" />
          )}
        </Button>
        {numberOfUnreadComments > 0 && (
          <Circle
            size={6}
            bg="red.600"
            color="white"
            fontSize="xs"
            fontWeight="bold"
            position="absolute"
            top={-2}
            left={badgePosition === "left" ? -2 : undefined}
            right={badgePosition === "right" ? -2 : undefined}
          >
            <Text as="span">
              {numberOfUnreadComments > 9 ? "9+" : numberOfUnreadComments}
            </Text>
          </Circle>
        )}
      </Box>
    </Tooltip>
  )
}
