import { Flex, Icon, Text } from "@chakra-ui/react"
import { useTranslate } from "Shared/hooks/useTranslate"
import { MonitorIcon } from "Shared/icons/MonitorIcon"
import { Laptop01OutlineIcon } from "Shared/icons/untitled-ui/Laptop01OutlineIcon"
import { RecordThinkAloudIcon } from "Shared/icons/untitled-ui/RecordThinkAloudIcon"
import React, { PropsWithChildren } from "react"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"

interface Props {
  allowedRecordingTypes: RecordingType[]
  additionalPermissions?: string[]
}

export const RecordingRequirements: React.FC<Props> = ({
  allowedRecordingTypes = [],
  additionalPermissions = [],
}) => {
  const translate = useTranslate()

  const camera = allowedRecordingTypes?.includes("camera")
  const microphone = allowedRecordingTypes?.includes("microphone")
  const externalWebsite = additionalPermissions?.includes("external_website")

  return (
    <>
      {allowedRecordingTypes?.includes("screen") && (
        <RecordingsPermissionItem>
          <Icon as={MonitorIcon} textColor="ds.icon.default" boxSize={6} />
          <Flex direction="column">
            <Text>{translate("test.recording.screen.label")}</Text>
            <Text fontSize="xs">{translate("test.recording.screen.body")}</Text>
          </Flex>
        </RecordingsPermissionItem>
      )}
      {/* Displays Camera and Audio requirement */}
      {microphone && (
        <RecordingsPermissionItem>
          <Icon
            as={RecordThinkAloudIcon}
            textColor="ds.icon.default"
            boxSize={6}
          />
          <Flex direction="column">
            {translate("test.recording.think_aloud.label")}
            {camera ? (
              <Text fontSize="xs">
                {translate("test.recording.camera.prompt")}
              </Text>
            ) : (
              <Text fontSize="xs">
                {translate("test.recording.microphone.prompt")}
              </Text>
            )}
          </Flex>
        </RecordingsPermissionItem>
      )}
      {/* External website warning - for Live Website Tests */}
      {externalWebsite && (
        <RecordingsPermissionItem>
          <Icon
            as={Laptop01OutlineIcon}
            textColor="ds.icon.default"
            boxSize={6}
          />
          <Flex direction="column">
            <Text>{translate("test.recording.external.label")}</Text>
            <Text fontSize="xs">
              {translate("test.recording.external.body")}
            </Text>
          </Flex>
        </RecordingsPermissionItem>
      )}
    </>
  )
}

const RecordingsPermissionItem: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Flex
    p={6}
    borderRadius={4}
    border="1px solid"
    borderColor="border.default"
    fontWeight="medium"
    color="ds.text.default"
    gap={4}
    alignItems="center"
  >
    {children}
  </Flex>
)
