import React from "react"
import { Helmet } from "react-helmet"

import { PrivacyPolicyDocument } from "./PrivacyPolicyDocument"

type Privacy = React.FC<React.PropsWithChildren<unknown>>

export const PrivacyRoute: Privacy = () => (
  <>
    <Helmet>
      <title>Privacy policy</title>
    </Helmet>
    <PrivacyPolicyDocument />
  </>
)
