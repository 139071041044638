import {
  DashboardUsabilityTest,
  Study,
} from "~/api/generated/usabilityhubSchemas"
import { isExternalStudy } from "./isExternalStudy"

export const convertUsabilityTestToStudy = (
  test: DashboardUsabilityTest
): Extract<Study, { type: "usability_test" }> => {
  return {
    ...test,
    id: test.id.toString(),
    type: "usability_test",
    is_external_study: isExternalStudy(test),
  }
}
