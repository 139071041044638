import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Heading,
  Link,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { isAxiosError } from "Services/axios"
import { PageContent, PageMain } from "Shared/components/Page/Page"
import { formatDollars } from "Utilities/currency"
import { reportErrorToSentry } from "Utilities/error"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link as RouterLink } from "react-router-dom"
import { settings } from "~/api"
import {
  BillingHistoryResponse,
  fetchBillingHistory,
} from "~/api/generated/usabilityhub-components"
import { ROUTES } from "~/usabilityhub/views/routes"

type FetchState = "initial" | "loading" | "success" | "error"

export const BillingHistoryRoute: React.FC = () => {
  const [billingHistory, setBillingHistory] = useState<BillingHistoryResponse>({
    stripe_charges: [],
    stripe_next_page: null,
  })
  const [fetchState, setFetchState] = React.useState<FetchState>("initial")

  const loadPage = async () => {
    setFetchState("loading")

    try {
      const queryParams = billingHistory.stripe_next_page
        ? { stripe_cursor: billingHistory.stripe_next_page }
        : {}
      const data = await fetchBillingHistory({
        queryParams,
      })

      setBillingHistory((oldHistory) => ({
        stripe_charges: [...oldHistory.stripe_charges, ...data.stripe_charges],
        stripe_next_page: data.stripe_next_page,
      }))

      setFetchState("success")
    } catch (error) {
      if (!isAxiosError(error)) {
        reportErrorToSentry(error)
      }
      setFetchState("error")
    }
  }

  useEffect(() => {
    void loadPage()
  }, [])

  return (
    <PageMain>
      <Helmet>
        <title>Billing history</title>
      </Helmet>
      <PageContent maxW="5xl">
        <Stack align="center" my={4}>
          <Heading fontSize="2xl" fontWeight="normal" mb={2}>
            Billing history
          </Heading>
          <Link
            variant="noUnderline"
            as={RouterLink}
            to={ROUTES.SETTINGS.BILLING.path}
          >
            &larr; Back to billing settings
          </Link>
        </Stack>

        <Stack mt={10}>
          {billingHistory.stripe_charges.length > 0 ? (
            <TableContainer
              bg="white"
              rounded="md"
              borderColor="gray.200"
              borderWidth={1}
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Amount</Th>
                    <Th>Type</Th>
                    <Th>Invoice</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {billingHistory.stripe_charges.map((charge) => {
                    const formattedDate = new Date(
                      charge.created * 1000
                    ).toLocaleDateString(undefined, {
                      dateStyle: "medium",
                    })

                    const amountPaid = charge.amount - charge.amount_refunded

                    return (
                      <Tr key={charge.id}>
                        <Td>{formattedDate}</Td>
                        <Td>
                          {amountPaid !== charge.amount ? (
                            <>
                              <del>
                                {formatDollars(charge.amount / 100)}{" "}
                                {charge.currency.toUpperCase()}
                              </del>{" "}
                            </>
                          ) : null}
                          {formatDollars(amountPaid / 100)}{" "}
                          {charge.currency.toUpperCase()}
                        </Td>
                        <Td>
                          {charge.invoice
                            ? "Subscription payment"
                            : "Credit purchase"}
                        </Td>
                        <Td>
                          <Link
                            variant="noUnderline"
                            href={settings.stripeInvoice.path({
                              id: charge.id,
                            })}
                            target="_blank"
                            rel="noopener noreferer"
                          >
                            View invoice
                          </Link>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          ) : null}
          {fetchState === "loading" ? (
            <Stack align="center" py={10}>
              <Spinner mx="auto" />
            </Stack>
          ) : fetchState === "error" ? (
            <Alert w="fit-content" status="error" mx="auto">
              <AlertIcon />
              <Stack>
                <AlertTitle>Error loading billing history.</AlertTitle>
                <AlertDescription>
                  Try refreshing the page. If this persists, please contact
                  support.
                </AlertDescription>
              </Stack>
            </Alert>
          ) : billingHistory.stripe_charges.length === 0 ? (
            <Alert w="fit-content" status="info" mx="auto">
              <AlertIcon />
              <AlertTitle>No billing history to display.</AlertTitle>
            </Alert>
          ) : null}

          {billingHistory.stripe_next_page && (
            <Flex justify="center">
              <Button colorScheme="brand.primary" onClick={() => loadPage()}>
                Load more
              </Button>
            </Flex>
          )}
        </Stack>
      </PageContent>
    </PageMain>
  )
}
