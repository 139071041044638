import { TestResults as Impl } from "Components/test-results/test-results"
import { getUsabilityTest } from "Redux/reducers/test-results/selectors"
import React from "react"
import { useSelector } from "react-redux"

export const TestResults: React.FC = () => {
  const usabilityTest = useSelector(getUsabilityTest)

  return <Impl usabilityTest={usabilityTest} />
}
