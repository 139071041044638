export enum Type {
  INIT_USABILITY_TEST = "INIT_USABILITY_TEST",
  SAVE_TEST_FORM_REQUEST = "SAVE_TEST_FORM_REQUEST",
  SAVE_TEST_FORM_SUCCESS = "SAVE_TEST_FORM_SUCCESS",
  SAVE_TEST_FORM_FAILURE = "SAVE_TEST_FORM_FAILURE",
  SET_TEST_FORM_IS_PREVIEW_CLICKED = "SET_TEST_FORM_IS_PREVIEW_CLICKED",
  SET_TEST_FORM_IS_ESTIMATE_VALID = "SET_TEST_FORM_IS_ESTIMATE_VALID",
  CREATE_PROJECT = "CREATE_PROJECT",
  SAVE_DELETED_TEST_FORM_QUESTION = "SAVE_DELETED_TEST_FORM_QUESTION",
  SAVE_DELETED_TEST_FORM_SECTION = "SAVE_DELETED_TEST_FORM_SECTION",
  SAVE_DELETED_TEST_FORM_SECTION_SCREENSHOT = "SAVE_DELETED_TEST_FORM_SECTION_SCREENSHOT",
  CLEAR_DELETED_TEST_FORM_QUESTION = "CLEAR_DELETED_TEST_FORM_QUESTION",
  CLEAR_DELETED_TEST_FORM_SECTION = "CLEAR_DELETED_TEST_FORM_SECTION",
  CLEAR_DELETED_TEST_FORM_SECTION_SCREENSHOT = "CLEAR_DELETED_TEST_FORM_SECTION_SCREENSHOT",
  UPDATE_TEST_FORM_CREDITS_AND_DURATION_REQUEST = "UPDATE_TEST_FORM_CREDITS_AND_DURATION_REQUEST",
  UPDATE_TEST_FORM_CREDITS_AND_DURATION_SUCCESS = "UPDATE_TEST_FORM_CREDITS_AND_DURATION_SUCCESS",
  UPDATE_TEST_FORM_CREDITS_AND_DURATION_FAILURE = "UPDATE_TEST_FORM_CREDITS_AND_DURATION_FAILURE",
  ADD_LOCAL_COMMENT = "ADD_LOCAL_COMMENT",
  EDIT_LOCAL_COMMENT = "EDIT_LOCAL_COMMENT",
  DELETE_LOCAL_COMMENT = "DELETE_LOCAL_COMMENT",
  SAVE_UNDO_CHECKPOINT = "SAVE_UNDO_CHECKPOINT",
  CLEAR_UNDO_CHECKPOINT = "CLEAR_UNDO_CHECKPOINT",
}
