import React, { useState } from "react"

import {
  NoResponse,
  OptionDeleted,
} from "Components/individual-response/individual-section-result/no-data"
import { Duration } from "Components/test-results/progress-box/progress-box"
import { ScreenshotPreferenceResult } from "Components/test-results/section-results/screenshot-preference-result"
import {
  DeviceFrame,
  UsabilityTestSection,
  UsabilityTestSectionScreenshot,
} from "Types"
import { useDeviceFramesContext } from "UsabilityHub/contexts"

import { TaskProps } from "./task-props"

interface ResultImplProps {
  readonly deviceFrame: Readonly<DeviceFrame> | null
  readonly screenshotId: number
  readonly duration: number | null
}

const ResultImpl = ({
  deviceFrame,
  duration,
  screenshotId,
}: ResultImplProps) => {
  const [isExpanded, setIsExapanded] = useState(false)
  return (
    <ScreenshotPreferenceResult
      deviceFrame={deviceFrame}
      screenshotId={screenshotId}
      isFilter={false}
      isFiltered={false}
      onIsExpandedChange={setIsExapanded}
      isExpanded={isExpanded}
      progress={0}
    >
      <Duration duration={duration} />
    </ScreenshotPreferenceResult>
  )
}

interface ResultProps {
  sectionScreenshot: Readonly<UsabilityTestSectionScreenshot>
  usabilityTestSection: Readonly<UsabilityTestSection>
  duration: number | null
}

const Result: React.FC<ResultProps> = ({
  sectionScreenshot,
  usabilityTestSection,
  duration,
}) => {
  let screenshotId: number
  if (sectionScreenshot === undefined) {
    screenshotId = -1
  } else {
    screenshotId = sectionScreenshot.screenshot_id
  }
  const deviceFrames = useDeviceFramesContext()
  const deviceFrame =
    deviceFrames.find(
      (frame) => frame.id === usabilityTestSection.device_frame_id
    ) || null

  return (
    <ResultImpl
      deviceFrame={deviceFrame}
      screenshotId={screenshotId}
      duration={duration}
    />
  )
}

export const PreferenceSectionIndividualResults = ({
  responseSection,
  usabilityTestSection,
}: TaskProps) => {
  const sectionScreenshot =
    (responseSection &&
      usabilityTestSection.section_screenshots.find(
        (ss) =>
          ss.id ===
          responseSection.selected_usability_test_section_screenshot_id
      )) ||
    null
  return responseSection === null ? (
    <NoResponse />
  ) : sectionScreenshot === null ? (
    <OptionDeleted />
  ) : (
    <Result
      sectionScreenshot={sectionScreenshot}
      duration={responseSection.task_duration_ms}
      usabilityTestSection={usabilityTestSection}
    />
  )
}
