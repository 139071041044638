import { sortBy } from "lodash"
import { useListPlans } from "~/api/generated/usabilityhub-components"

export const usePlans = () => {
  const {
    data: planResponse,
    isLoading,
    isError,
  } = useListPlans(
    {},
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  )

  if (isLoading || isError) {
    return null
  }

  return sortBy(planResponse, "monthly_price")
}

export const usePlan = (uniqueId: string) => {
  const plans = usePlans()
  return plans?.find((plan) => plan.unique_id === uniqueId)
}
