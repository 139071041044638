import { UnscaledScreenshotWithId } from "Components/screenshot/screenshot-with-id"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"
import {
  Body,
  Container,
} from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout"
import React from "react"

export const InformationTaskMedia: React.FC<
  Pick<InnerProps, "usabilityTestSection">
> = ({ usabilityTestSection }) => {
  const { section_screenshots } = usabilityTestSection

  const screenshot =
    section_screenshots.length > 0 ? section_screenshots[0] : null

  return (
    <Container>
      <Body>
        {screenshot && (
          <UnscaledScreenshotWithId
            screenshotId={screenshot.screenshot_id}
            width="100%"
            draggable={false}
            canSkipAheadOnFirstWatch={false}
          />
        )}
      </Body>
    </Container>
  )
}
