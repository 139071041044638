import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"

import {
  EntityContext,
  EntityIdentifier,
} from "Components/comment-thread/entities"
import { ClientCommentFragment } from "Redux/reducers/test-builder/reducer"
import { Comment } from "~/api/generated/usabilityhubSchemas"

type LocalCommentState = {
  activeContext: EntityContext
  activeThread: ActiveThread | null
  usabilityTestId: number | null
  usabilityTestUniqueId: string | null
  comments: Comment[]
  openThread: (entity: EntityIdentifier | null) => void
  closeActiveThread: () => void
  expandActiveThread: () => void
  addComment: (comment: Comment) => void
  updateComment: (comment: Comment) => void
  deleteComment: (comment: Comment) => void
  markCommentsAsRead: (comments: Comment[]) => void
  addCommentLocally: (comment: ClientCommentFragment) => void
  updateCommentLocally: (comment: Omit<ClientCommentFragment, "entity">) => void
  deleteCommentLocally: (commentId: string) => void
}

const LocalCommentContext = React.createContext<LocalCommentState>({
  activeContext: "test_builder", // Need a default to avoid making it nullable
  activeThread: null,
  usabilityTestId: null,
  usabilityTestUniqueId: null,
  comments: [],
  openThread: () => {
    throw new Error("Not inside a CommentProvider")
  },
  closeActiveThread: () => {
    throw new Error("Not inside a CommentProvider")
  },
  expandActiveThread: () => {
    throw new Error("Not inside a CommentProvider")
  },
  addComment: () => {
    throw new Error("Not inside a CommentProvider")
  },
  updateComment: () => {
    throw new Error("Not inside a CommentProvider")
  },
  deleteComment: () => {
    throw new Error("Not inside a CommentProvider")
  },
  markCommentsAsRead: () => {
    throw new Error("Not inside a CommentProvider")
  },
  addCommentLocally: () => {
    throw new Error("Not inside a CommentProvider")
  },
  updateCommentLocally: () => {
    throw new Error("Not inside a CommentProvider")
  },
  deleteCommentLocally: () => {
    throw new Error("Not inside a CommentProvider")
  },
})

export const useLocalCommentContext = (): LocalCommentState => {
  return useContext(LocalCommentContext)
}

type ActiveThread = EntityIdentifier & {
  collapsed: boolean
}

type Props = {
  activeContext: EntityContext
  usabilityTestId: number | null
  usabilityTestUniqueId: string | null
  comments: Comment[]
  addComment: (comment: Comment) => void
  updateComment: (comment: Comment) => void
  deleteComment: (comment: Comment) => void
  markCommentsAsRead: (comments: Comment[]) => void
  addCommentLocally: (comment: ClientCommentFragment) => void
  updateCommentLocally: (comment: Omit<ClientCommentFragment, "entity">) => void
  deleteCommentLocally: (commentId: string) => void
}

// All the server-side state for comments comes from tanstack-query,
// but we need to keep track of some client state too like which comment
// thread is currently open.  This provider will handle that.
export const CommentProvider: React.FC<PropsWithChildren<Props>> = ({
  activeContext,
  usabilityTestId,
  usabilityTestUniqueId,
  comments,
  addComment,
  updateComment,
  deleteComment,
  markCommentsAsRead,
  addCommentLocally,
  updateCommentLocally,
  deleteCommentLocally,
  children,
}) => {
  const [activeThread, setActiveThread] = useState<ActiveThread | null>(null)

  const commentIdFromUrl = window.location.hash.replace(/^#comment-/, "")
  const comment =
    commentIdFromUrl === ""
      ? null
      : comments.find((c) => c.id === commentIdFromUrl)

  useEffect(() => {
    if (!comment) return

    // If this is a child comment, we need to find the parent to know which thread to open
    const rootComment =
      comment.entity_type === "comment"
        ? comments.find((c) => c.id === comment.entity_id)
        : comment

    if (rootComment) {
      setActiveThread({
        entityContext: activeContext,
        entityType: rootComment.entity_type,
        entityId: rootComment.entity_id,
        collapsed: false,
      } as ActiveThread)

      setTimeout(() => {
        document
          .getElementById(`c-${comment.id}`)
          ?.scrollIntoView({ behavior: "smooth" })
      }, 800)
    }
  }, [comment])

  const openThread = useCallback((entity: EntityIdentifier) => {
    // We're expanding all threads by default, but leaving the collapse code in since we'll
    // most likely use it later.
    // (If we want to collapse threads of a certain size, this would be the place to do it!)
    setActiveThread({ ...entity, collapsed: false })
  }, [])

  const closeActiveThread = useCallback(() => {
    setActiveThread(null)
  }, [])

  const expandActiveThread = useCallback(() => {
    setActiveThread((current) =>
      current ? { ...current, collapsed: false } : null
    )
  }, [])

  return (
    <LocalCommentContext.Provider
      value={{
        activeContext,
        usabilityTestId,
        usabilityTestUniqueId,
        activeThread,
        comments,
        openThread,
        closeActiveThread,
        expandActiveThread,
        addComment,
        updateComment,
        deleteComment,
        markCommentsAsRead,
        addCommentLocally,
        updateCommentLocally,
        deleteCommentLocally,
      }}
    >
      {children}
    </LocalCommentContext.Provider>
  )
}
