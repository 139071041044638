import { Box, HStack, Text, useToken } from "@chakra-ui/react"
import { uniq } from "lodash"
import React from "react"
import { PathDiagramResult } from "./useTreeTestPathDiagramLayout"

export const usePathDiagramColors = () => {
  const [
    green100,
    green200,
    green300,
    green400,
    green500,
    red100,
    red300,
    red400,
    gray200,
    gray300,
    gray400,
    textPrimary,
  ] = useToken("colors", [
    "green.100",
    "green.200",
    "green.300",
    "green.400",
    "green.500",
    "red.100",
    "red.300",
    "red.400",
    "gray.200",
    "gray.300",
    "gray.400",
    "text.primary",
  ])

  return {
    node: {
      direct_success: {
        stroke: green500,
        fill: green400,
      },
      indirect_success: {
        stroke: green200,
        fill: green100,
      },
      direct_failure: {
        stroke: red400,
        fill: red100,
      },
      indirect_failure: {
        stroke: red400,
        fill: red100,
      },
      direct_skipped: {
        stroke: gray400,
        fill: gray200,
      },
      indirect_skipped: {
        stroke: gray400,
        fill: gray200,
      },
      neutral: {
        stroke: gray400,
        fill: "white",
      },
    },
    link: {
      direct_success: green500,
      indirect_success: green300,
      direct_failure: red300,
      indirect_failure: red300,
      direct_skipped: gray300,
      indirect_skipped: gray300,
    },
    label: textPrimary,
  } as const
}

export const MERGE_RESULTS: Record<PathDiagramResult, PathDiagramResult> = {
  direct_success: "direct_success",
  indirect_success: "indirect_success",
  direct_failure: "indirect_failure",
  indirect_failure: "indirect_failure",
  direct_skipped: "indirect_skipped",
  indirect_skipped: "indirect_skipped",
} as const

const LABELS = {
  direct_success: "Direct success",
  indirect_success: "Indirect success",
  direct_failure: "Failure",
  indirect_failure: "Failure",
  direct_skipped: "Pass",
  indirect_skipped: "Pass",
} as const

export const Legend: React.FC = () => {
  const colors = usePathDiagramColors()

  const keys = uniq(
    Object.keys(colors.node)
      .map((key: PathDiagramResult) => MERGE_RESULTS[key])
      .filter(Boolean)
  )

  return (
    <HStack align="center" spacing={4}>
      {keys.map((key) => (
        <Key key={key} result={key} colors={colors} />
      ))}
    </HStack>
  )
}

const Key: React.FC<{
  result: PathDiagramResult
  colors: ReturnType<typeof usePathDiagramColors>
}> = ({ result, colors }) => (
  <HStack align="center" spacing={0} py={2}>
    <Box w={4} h="2px" bg={colors.link[result]} opacity={0.25} />
    <Box
      w={6}
      h={6}
      bg={colors.node[result]?.fill}
      border={`2px solid ${colors.node[result]?.stroke}`}
      rounded={4}
    />
    <Text ml={2} fontSize="sm" color="text.subtlest">
      {LABELS[result]}
    </Text>
  </HStack>
)
