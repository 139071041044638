import { OtherAnswerFilter } from "../filters/other-answer"

import { generateFilterId } from "./generate-filter-id"

function createOtherAnswerFilterCategory(
  sectionId: number,
  questionId: number
): OtherAnswerFilter["category"] {
  return `answer/section:${sectionId}/question:${questionId}/answer`
}

export function createOtherAnswerFilter(
  sectionId: number,
  questionId: number
): OtherAnswerFilter {
  return {
    id: generateFilterId(),
    category: createOtherAnswerFilterCategory(sectionId, questionId),
    type: "answer/other-answer",
    value: undefined,
  }
}
