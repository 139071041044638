import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { Download01OutlineIcon } from "Shared/icons/untitled-ui/Download01OutlineIcon"
import { Expand06OutlineIcon } from "Shared/icons/untitled-ui/Expand06OutlineIcon"
import { Image03OutlineIcon } from "Shared/icons/untitled-ui/Image03OutlineIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import React from "react"

type ToolbarProps = {
  fullScreen: boolean
  setFullScreen: (fullScreen: boolean) => void
  onDownload: (format: "svg" | "png") => void
}

export const Toolbar: React.FC<ToolbarProps> = ({
  fullScreen,
  setFullScreen,
  onDownload,
}: ToolbarProps) => {
  return (
    <HStack>
      <Menu>
        <MenuButton
          as={IconButton}
          variant="ghost"
          icon={<Download01OutlineIcon />}
          aria-label="Download"
        />
        <MenuList py={2}>
          <MenuItem
            icon={<Image03OutlineIcon />}
            onClick={() => onDownload("svg")}
          >
            Download as SVG
          </MenuItem>
          <MenuItem
            icon={<Image03OutlineIcon />}
            onClick={() => onDownload("png")}
          >
            Download as PNG
          </MenuItem>
        </MenuList>
      </Menu>
      <IconButton
        variant="ghost"
        icon={fullScreen ? <XOutlineIcon /> : <Expand06OutlineIcon />}
        aria-label={`${fullScreen ? "Exit" : "Enter"} full screen`}
        onClick={() => setFullScreen(!fullScreen)}
      />
    </HStack>
  )
}
