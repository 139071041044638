import { Box, BoxProps } from "@chakra-ui/react"
import validateFiles, { ValidateFilesResult } from "Services/validate-files"
import { ScreenshotMediaType } from "Types"
import React, { useEffect, useRef, useState } from "react"

type RenderProps = {
  isOver: boolean
}

type Props = {
  allowedMediaTypes: ScreenshotMediaType[]
  maxFiles: number
  handleValidateFilesResult: (validateFilesResult: ValidateFilesResult) => void
  children: (renderProps: RenderProps) => React.ReactNode
} & Omit<BoxProps, "children">

/**
 * Wraps a drop zone where you can drag and drop files from outside the browser.
 *
 * - You provide the drop zone look & feel as children
 * - You provide some validation rules
 * - You provide a callback to act on the result of validation
 * - When the drop event is fired, this component handles validation and then
 *   calls your callback with the result
 *
 * Use the `isOver` render prop to access the dragging-over-drop-zone state.
 * @example
 * <FileDragAndDrop
 *   allowedMediaTypes={["image"]}
 *   maxFiles={1}
 *   handleValidateFilesResult={(result: ValidateFilesResult) => {
 *     // do stuff with the result
 *   }}
 * >
 *   {({ isOver }) => (
 *     <YourCustomDropZoneStuff isDraggingOverDropZone={isOver} />
 *   )}
 * </FileDragAndDrop>
 */
export const FileDragAndDrop: React.FC<Props> = ({
  allowedMediaTypes,
  maxFiles,
  handleValidateFilesResult,
  children,
  ...boxProps
}) => {
  const dropRef = useRef<HTMLDivElement>(null)
  const [isOver, setIsOver] = useState(false)

  // nested children will propagate drag events up, so to avoid
  // the isOver state flickering on and off, we track the drag events and only
  // setIsOver(false) when we know the outer node's dragleave event has fired
  const [childNodeDragCounter, setChildNodeDragCounter] = useState(0)

  const handleDragEnter = (e: DragEvent) => {
    preventDefaultAndPropagation(e)
    setIsOver(true)
    setChildNodeDragCounter((c) => c + 1)
  }
  const handleDragLeave = (e: DragEvent) => {
    preventDefaultAndPropagation(e)
    setChildNodeDragCounter((c) => c - 1)
  }
  const handleDrop = (e: DragEvent) => {
    preventDefaultAndPropagation(e)
    setChildNodeDragCounter(0)
    setIsOver(false)

    if (!e.dataTransfer) return
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = e.dataTransfer.files
      const validateFilesResult = validateFiles(files, {
        allowedMediaTypes,
        maxFiles,
      })
      handleValidateFilesResult(validateFilesResult)
    }
  }

  useEffect(() => {
    if (childNodeDragCounter === 0) setIsOver(false)
  }, [childNodeDragCounter])

  useEffect(() => {
    const node = dropRef.current
    if (!node) return

    node.addEventListener("dragenter", handleDragEnter)
    node.addEventListener("dragleave", handleDragLeave)
    node.addEventListener("dragover", preventDefaultAndPropagation)
    node.addEventListener("drop", handleDrop)

    return () => {
      node.removeEventListener("dragenter", handleDragEnter)
      node.removeEventListener("dragleave", handleDragLeave)
      node.removeEventListener("dragover", preventDefaultAndPropagation)
      node.removeEventListener("drop", handleDrop)
    }
  }, [])

  return (
    <Box ref={dropRef} {...boxProps}>
      {children({ isOver })}
    </Box>
  )
}

const preventDefaultAndPropagation = (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()
}
