import { Badge, BadgeProps, Flex, Icon } from "@chakra-ui/react"
import { EyeOffIcon } from "@heroicons/react/outline"
import React, { forwardRef } from "react"

type TestLogicBadge = React.FC<React.PropsWithChildren<BadgeProps>>

export const TestLogicBadge: TestLogicBadge = forwardRef<HTMLSpanElement>(
  (badgeProps, ref) => (
    <Badge colorScheme="orange" ref={ref} {...badgeProps}>
      <Flex align="center" gap={1}>
        <Icon as={EyeOffIcon} strokeWidth="3px" fontSize="0.9em" />
        Logic
      </Flex>
    </Badge>
  )
)

TestLogicBadge.displayName = "TestLogicBadge"
