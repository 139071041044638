import { getSections } from "Redux/reducers/test-builder-form/selectors/sections"
import { ClientId, UsabilityTestSection } from "Types"
import { hasSubmitFailed as hasReduxFormSubmitFailed } from "redux-form"
import { createSelector } from "reselect"
import { getFieldWithClientIdIn } from "~/application/javascripts/utilities/usability-test-field"

const form = "test-form"

export const getHasSubmitFailed = hasReduxFormSubmitFailed(form)

export const getFieldByClientId = (fieldClientId: ClientId) =>
  createSelector(getSections, (sections: ReadonlyArray<UsabilityTestSection>) =>
    getFieldWithClientIdIn(fieldClientId, sections as UsabilityTestSection[])
  )
