import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react"
import React from "react"
import Confetti from "react-confetti"

import upgradeImage from "Images/app-illustrations/lyssna/upgrade.png"

type UpgradeUsabilityTestSuccessModal = React.FC<
  React.PropsWithChildren<{
    onContinue: () => void
  }>
>

export const UpgradeUsabilityTestSuccessModal: UpgradeUsabilityTestSuccessModal =
  ({ onContinue }) => {
    return (
      <Modal size="sm" isOpen onClose={onContinue}>
        <ModalOverlay>
          <Confetti
            style={{ zIndex: 1350 }}
            numberOfPieces={250}
            tweenDuration={1000}
            recycle={false}
            initialVelocityX={10}
            initialVelocityY={20}
            confettiSource={{
              w: 120,
              h: 100,
              x: window.innerWidth / 2,
              y: 100,
            }}
          />
          <ModalContent>
            <ModalBody textAlign="center">
              <Box py={5}>
                <Center>
                  <Image src={upgradeImage} w="32" h="32" />
                </Center>
              </Box>
              <Heading py={2}>Your test was upgraded!</Heading>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={onContinue}
                colorScheme="brand.primary"
                width="full"
              >
                Continue
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    )
  }
