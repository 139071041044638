import { Button, Icon } from "@chakra-ui/react"
import { ChevronLeftOutlineIcon } from "Shared/icons/untitled-ui/ChevronLeftOutlineIcon"
import React, { Dispatch, SetStateAction } from "react"
import { TargetLocation } from "../types"
import { LocationList } from "./LocationList"
import { useLocationContext } from "./LocationProvider"
import { LocationRow } from "./LocationRow"
import { useToggleLocation } from "./useToggleLocation"

type DrillInLevel = {
  type: TargetLocation["type"]
  id: string
  scrollPosition: number
}

type Props = {
  menuRef: React.RefObject<HTMLDivElement>
  children?: (
    setCurrentLevel: Dispatch<SetStateAction<DrillInLevel[]>>
  ) => React.ReactNode
}

export const LocationSelectMenu: React.FC<Props> = ({ menuRef, children }) => {
  const locations = useLocationContext()
  const {
    toggleCountry,
    toggleState,
    toggleCity,
    isCountrySelected,
    isStateSelected,
    isCitySelected,
  } = useToggleLocation()

  const [currentLevel, setCurrentLevel] = React.useState<DrillInLevel[]>([])

  // After clicking the back button to go back to the previous level, we need to
  // restore the scroll position of the menu to the position it was before.
  const afterBack = (scrollPosToRestore: number) => {
    if (!menuRef.current) return

    menuRef.current.scrollTop = scrollPosToRestore
  }

  const afterDrill = () => {
    if (!menuRef.current) return

    menuRef.current.scrollTop = 0
  }

  if (currentLevel.length === 0) {
    // If we have a children prop, render that as the "base" list of locations
    // Otherwise render the full country list
    if (children) return children(setCurrentLevel)

    return (
      <LocationList>
        {locations.map((location) => {
          return (
            <LocationRow
              key={location.code}
              label={location.name}
              selectionState={isCountrySelected(location.code)}
              onToggle={() => toggleCountry(location.code)}
              onDrill={
                location.states.length > 0
                  ? () => {
                      setCurrentLevel([
                        {
                          type: "country",
                          id: location.code,
                          scrollPosition: menuRef.current?.scrollTop ?? 0,
                        },
                      ])
                      afterDrill()
                    }
                  : undefined
              }
            />
          )
        })}
      </LocationList>
    )
  }

  if (currentLevel.length === 1) {
    const country = locations.find(({ code }) => code === currentLevel[0].id)

    if (!country) {
      throw new Error("No country found")
    }

    // Note that if the country is indeterminate the "all of country" checkbox will NOT be checked
    const isAllOfCountrySelected =
      isCountrySelected(country.code) === "selected"

    return (
      <LocationList>
        <BackButton setCurrentLevel={setCurrentLevel} afterBack={afterBack}>
          {country.name}
        </BackButton>

        <LocationRow
          label={`All of ${country.name}`}
          selectionState={isAllOfCountrySelected ? "selected" : "unselected"}
          onToggle={() =>
            toggleCountry(
              country.code,
              // Toggles normally when the whole country is selected, but if the country is
              // "partially" selected (meaning some states or cities are selected inside it)
              // then clicking this should always turn it on.
              isAllOfCountrySelected ? undefined : true
            )
          }
        />

        {country.states.map((state) => {
          return (
            <LocationRow
              key={state.id}
              label={state.long_name}
              selectionState={isStateSelected(country.code, state.id)}
              onToggle={() => toggleState(country.code, state.id)}
              onDrill={
                state.cities.length > 0
                  ? () =>
                      setCurrentLevel([
                        ...currentLevel,
                        {
                          type: "state",
                          id: state.id.toString(),
                          scrollPosition: menuRef.current?.scrollTop ?? 0,
                        },
                      ])
                  : undefined
              }
            />
          )
        })}
      </LocationList>
    )
  }

  if (currentLevel.length === 2) {
    const country = locations.find(({ code }) => code === currentLevel[0].id)

    if (!country) {
      throw new Error("No country found")
    }

    const state = country.states.find(
      ({ id }) => id.toString() === currentLevel[1].id
    )

    if (!state) {
      throw new Error("No state found")
    }

    // Note that if the state is indeterminate the "all of state" checkbox will NOT be checked
    const isAllOfStateSelected =
      isStateSelected(country.code, state.id) === "selected"

    return (
      <LocationList>
        <BackButton setCurrentLevel={setCurrentLevel} afterBack={afterBack}>
          {state.long_name}
        </BackButton>

        <LocationRow
          label={`All of ${state.long_name}`}
          selectionState={isAllOfStateSelected ? "selected" : "unselected"}
          onToggle={() =>
            toggleState(
              country.code,
              state.id,
              // Toggles normally when the whole state is selected, but if the state is
              // "partially" selected (meaning some or all cities are selected inside it)
              // then clicking this should always turn it on.
              isAllOfStateSelected ? undefined : true
            )
          }
        />

        {state.cities.map((city) => {
          return (
            <LocationRow
              key={city.id}
              label={city.long_name}
              selectionState={isCitySelected(country.code, state.id, city.id)}
              onToggle={() => toggleCity(country.code, state.id, city.id)}
            />
          )
        })}
      </LocationList>
    )
  }
}

type BackButtonProps = {
  setCurrentLevel: (func: (oldArray: DrillInLevel[]) => DrillInLevel[]) => void
  afterBack: (scrollPosToRestore: number) => void
  children?: React.ReactNode
}

const BackButton: React.FC<BackButtonProps> = ({
  setCurrentLevel,
  afterBack,
  children,
}) => {
  return (
    <Button
      variant="ghost"
      size="xs"
      w="fit-content"
      textStyle="ds.interface.xsmall"
      color="ds.text.subtle"
      textAlign="left"
      leftIcon={<Icon as={ChevronLeftOutlineIcon} />}
      ms={2}
      _hover={{
        backgroundColor: "ds.background.neutral.subtle.hovered",
      }}
      onClick={() => {
        let previousScrollPosition = 0

        setCurrentLevel((array: DrillInLevel[]) => {
          previousScrollPosition = array[array.length - 1].scrollPosition
          return array.slice(0, -1)
        })

        // Schedule this for after the render so the scroll will change
        // once we're back on the previous level.
        setTimeout(() => afterBack(previousScrollPosition), 0)
      }}
    >
      {children ?? "Back"}
    </Button>
  )
}
