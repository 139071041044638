import { Center, Spinner } from "@chakra-ui/react"
import { CardGroup } from "Shared/components/Card/Card"
import React, { Suspense } from "react"
import { Helmet } from "react-helmet"
import { FeatureAccessForm } from "./FeatureAccessForm"

export const FeatureAccessRoute: React.FC = () => (
  <CardGroup>
    <Helmet>
      <title>Feature Access</title>
    </Helmet>
    <Suspense
      fallback={
        <Center minH="200px">
          <Spinner />
        </Center>
      }
    >
      <FeatureAccessForm />
    </Suspense>
  </CardGroup>
)
