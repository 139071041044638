import { Badge, BadgeProps } from "@chakra-ui/react"
import { useIsFirstRender } from "Shared/hooks/useIsFirstRender"
import { motion } from "framer-motion"
import React from "react"

const MotionBadge = motion(Badge)

export const AnimatingBadge: React.FC<React.PropsWithChildren<BadgeProps>> = (
  props
) => {
  const isFirstRender = useIsFirstRender()
  return (
    <MotionBadge
      animate={{
        y: isFirstRender ? 0 : [0, -6, 0, -3, 0, -1.5, 0],
      }}
      transition={{
        times: [0, 0.5, 0.75, 0.8, 0.9, 1],
        duration: 0.5,
        repeat: Infinity,
        repeatDelay: 3,
      }}
      {...props}
    />
  )
}
