import { Box } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import { ModeratedStudyDetailsForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/ModeratedStudyDetailsForm"
import React from "react"
import { useStudyDetails } from "./StudyDetailsProvider"

export const Details: React.FC = () => {
  const {
    studyDetailsForm,
    meetingUrlForm,
    deviceRequirementForm,
    availabilityForm,
  } = useStudyDetails()

  return (
    <Box
      id="study_details"
      bg="white"
      rounded="lg"
      p="8"
      borderColor="gray.200"
      borderWidth="thin"
    >
      <Heading as="h3" textStyle="ds.display.primary">
        Study details
      </Heading>

      <Box mt="6">
        <ModeratedStudyDetailsForm
          studyDetailsForm={studyDetailsForm}
          meetingUrlForm={meetingUrlForm}
          deviceRequirementForm={deviceRequirementForm}
          availabilityForm={availabilityForm}
        />
      </Box>
    </Box>
  )
}
