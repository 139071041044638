import { Box, Spinner } from "@chakra-ui/react"
import React from "react"

import { useTranslate } from "Shared/hooks/useTranslate"
import {
  AppearanceProps,
  LayoutState,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"

const Submitting: React.FC<React.PropsWithChildren<unknown>> = () => {
  const translate = useTranslate()

  return (
    <Box>
      {translate("test.submitting")}
      <Spinner size="xs" ml={2} />
    </Box>
  )
}

export default function submittingContent(): AppearanceProps {
  return {
    isReportButtonVisible: false,
    layoutState: LayoutState.FocusQuestion,
    mediaContent: null,
    questionContent: <Submitting />,
  }
}
