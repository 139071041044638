import { Box, Stack, useToken } from "@chakra-ui/react"
import { CardElement } from "@stripe/react-stripe-js"
import { nanoid } from "nanoid"
import React from "react"

import { Label } from "Components/form/label/label"
import { Text } from "Components/form/text"

interface Props {
  name: string
  onNameChange: (name: string) => void
}

const nameId = nanoid()
const cardId = nanoid()

export const CreditCardFields = ({ name, onNameChange }: Props) => {
  // TODO this should be updated to use a semantic token when we're ready
  const [gray700] = useToken("colors", ["gray.700"])

  return (
    <Stack>
      <Label htmlFor={nameId}>Cardholder name</Label>
      <Text
        id={nameId}
        name="ccname"
        autoComplete="cc-name"
        value={name}
        onChange={onNameChange}
        required
      />
      <Label htmlFor={cardId}>Credit card</Label>
      <Box
        borderWidth={1}
        bg="white"
        borderColor="gray.200"
        borderRadius="md"
        px={2}
      >
        <CardElement
          options={{
            style: {
              base: {
                lineHeight: "40px",
                color: gray700,
                "::placeholder": { color: gray700 },
              },
            },
          }}
          id={cardId}
        />
      </Box>
    </Stack>
  )
}
