import { Link } from "@chakra-ui/react"
import { Alert } from "DesignSystem/components"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import { UNLIMITED_VISIBLE_RESPONSES_FEATURE } from "UsabilityHub/components/RecruitmentLink/enabledLinkableFeaturesAccountLacks"
import React from "react"

export const HiddenResponsesAlert: React.FC<{
  hiddenResponseCount: number
}> = ({ hiddenResponseCount }) => {
  return (
    <Alert
      status="info"
      title="Some responses are hidden"
      description={
        <>
          {`On the Free plan you can collect as many responses as you like, but
          only the first 15 collected via the recruitment link will be visible.
          To view the other ${hiddenResponseCount} responses on this test
          you\u2019ll need to `}
          <PlanDrawerTrigger
            as={Link}
            variant="unstyled"
            userSelect="initial"
            source="test_results_response_limit_banner"
            requestedFeatures={[UNLIMITED_VISIBLE_RESPONSES_FEATURE]}
            // override legacy styles (TODO remove when we switch plan drawer trigger to DS button/link)
            verticalAlign="inherit"
          >
            upgrade to a paid plan.
          </PlanDrawerTrigger>
        </>
      }
    />
  )
}
