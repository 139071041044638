import { createIcon } from "@chakra-ui/react"
import React from "react"

export const VariationIcon = createIcon({
  displayName: "VariationIcon",
  viewBox: "0 0 16 16",
  path: (
    <g id="variation">
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.81036 12.97C1.36301 12.97 1.00036 12.6074 1.00036 12.16V3.84C0.990955 3.52582 1.16641 3.23524 1.44883 3.09726C1.73125 2.95928 4.06036 3.03 4.06036 3.03C4.06036 1.9 4.96036 1 6.06036 1H13.01C14.1107 1.0055 15 1.89932 15 3V4.68C15 5.78457 14.1046 6.68 13 6.68H6.06036C4.95579 6.68 4.06036 5.78457 4.06036 4.68L2.63036 4.66V11.34H4.06036C4.06036 10.21 4.96036 9.32 6.06036 9.32H13.01C14.1107 9.3255 15 10.2193 15 11.32V12.99C15.0027 13.5222 14.7931 14.0334 14.4178 14.4107C14.0424 14.7879 13.5322 15 13 15H6.06036C4.95579 15 4.06036 14.1046 4.06036 13L1.81036 12.97ZM5.69451 12.3659C5.69222 12.9198 6.1406 13.37 6.6945 13.37H12.37C12.9223 13.37 13.37 12.9223 13.37 12.37V11.95C13.37 11.3977 12.9223 10.95 12.37 10.95H6.69624C6.14556 10.95 5.69852 11.3952 5.69624 11.9459L5.69451 12.3659ZM5.69449 4.05589C5.69221 4.60977 6.14059 5.06 6.69448 5.06H12.37C12.9223 5.06 13.37 4.61228 13.37 4.06V3.63C13.37 3.07772 12.9223 2.63 12.37 2.63H6.69625C6.14558 2.63 5.69853 3.07521 5.69626 3.62588L5.69449 4.05589Z"
        fill="currentColor"
      />
    </g>
  ),
})
