import React from "react"

import { SectionCard } from "../SectionCard"
import { SectionLogicActions } from "../TestLogicActions/TestLogicActions"

import { AttachInstructionsMedia } from "./AttachInstructionsMedia"
import { InstructionSectionMessage } from "./InstructionSectionMessage"

export const InstructionSectionCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <SectionCard>
      <SectionLogicActions />
      <InstructionSectionMessage />
      <AttachInstructionsMedia />
    </SectionCard>
  )
}
