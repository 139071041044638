import { ResponseSectionFigmaTask } from "Types"

import { getCommonPathKeys } from "../task-paths/get-common-paths/get-common-paths-keys"

export function getCommonPathIndex(
  tasks: ResponseSectionFigmaTask[],
  commonPathKey: string
) {
  const commonPathKeys = getCommonPathKeys(tasks)

  return commonPathKeys.indexOf(commonPathKey)
}
