import { Tab, TabList, Text } from "@chakra-ui/react"
import { UseInfiniteQueryResult, UseQueryResult } from "@tanstack/react-query"
import React from "react"

import {
  ListModeratedStudiesError,
  ListModeratedStudiesResponse,
  ListProjectsError,
  ListProjectsResponse,
  ListUsabilityTestsError,
  ListUsabilityTestsResponse,
  ListVariationSetsError,
  ListVariationSetsResponse,
} from "~/api/generated/usabilityhub-components"

const tabStyles = {
  px: [2, null, 4],
  py: [1, null, 2],
  bg: ["gray.100", null, "none"],
  _selected: {
    color: "brand.primary.800",
    bg: "brand.primary.100",
  },
}

interface SearchTabsProps {
  testQuery: UseInfiniteQueryResult<
    ListUsabilityTestsResponse,
    ListUsabilityTestsError
  >
  projectQuery: UseInfiniteQueryResult<ListProjectsResponse, ListProjectsError>
  variationSetsQuery: UseInfiniteQueryResult<
    ListVariationSetsResponse,
    ListVariationSetsError
  >
  moderatedStudiesQuery: UseQueryResult<
    ListModeratedStudiesResponse,
    ListModeratedStudiesError
  >
}

export const SearchTabs = ({
  testQuery,
  projectQuery,
  variationSetsQuery,
  moderatedStudiesQuery,
}: SearchTabsProps) => (
  <TabList gap={3}>
    <Tab {...tabStyles} whiteSpace="nowrap">
      Tests
      <Text as="span" fontWeight="normal" ml={1}>
        {testQuery.data?.pages[0].total_records ?? 0}
      </Text>
    </Tab>

    <Tab {...tabStyles} whiteSpace="nowrap">
      Interview studies
      <Text as="span" fontWeight="normal" ml={1}>
        {moderatedStudiesQuery.isLoading
          ? ""
          : moderatedStudiesQuery.data?.length}
      </Text>
    </Tab>

    <Tab {...tabStyles} whiteSpace="nowrap">
      Projects
      <Text as="span" fontWeight="normal" ml={1}>
        {projectQuery.data?.pages[0].total_records ?? 0}
      </Text>
    </Tab>

    <Tab {...tabStyles} whiteSpace="nowrap">
      Variation sets
      <Text as="span" fontWeight="normal" ml={1}>
        {variationSetsQuery.data?.pages[0].total_records ?? 0}
      </Text>
    </Tab>
  </TabList>
)
