/**
 * Sort booleans in descending order: items that are true will appear
 * above items that are false.
 *
 * @param a
 * @param b
 */
export const compareBoolDesc = (a: boolean, b: boolean) => {
  // If A happened and B didn't, A should come before B.
  if (a && !b) {
    return -1
  }
  // If B happened and A didn't, B should come before A.
  else if (!a && b) {
    return 1
  }
  // If they're both the same then we don't care.
  else {
    return 0
  }
}
