import { Link, LinkProps } from "@chakra-ui/react"
import React from "react"

import Constants from "Constants/shared.json"

type PrivacyMailtoLink = React.FC<React.PropsWithChildren<LinkProps>>

export const PrivacyMailtoLink: PrivacyMailtoLink = ({
  children,
  ...linkProps
}) => {
  return (
    <Link
      href={`mailto:${Constants.PRIVACY_EMAIL_ADDRESS}`}
      isExternal
      {...linkProps}
    >
      {children ? children : Constants.PRIVACY_EMAIL_ADDRESS}
    </Link>
  )
}
