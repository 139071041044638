import React from "react"

import { fraudStatus, useAdminIpLookup } from "./AdminIpAddressContext"

interface Props {
  ipAddress: string | null
  date: string
  userCountry: string
  highRiskCountryCodes: string[]
}

export const AdminIpAddressFraudStatus: React.FC<Props> = ({
  ipAddress,
  date,
  userCountry,
  highRiskCountryCodes,
}) => {
  const ipState = useAdminIpLookup(ipAddress, date)

  if (ipState.state !== "ready") return null
  const ip = ipState.ipInfo

  const status = fraudStatus(userCountry, ip, highRiskCountryCodes)

  return <span className={`FraudStatus FraudStatus--${status}`} />
}
