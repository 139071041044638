import { Box, Flex, Image } from "@chakra-ui/react"
import { assertNever } from "Components/filter-controls/utils"
import { Button, Heading } from "DesignSystem/components"
import emptyAllSecondarySrc from "Images/app-illustrations/lyssna/empty-all.png"
import React from "react"
import { useMatch, useNavigate } from "react-router-dom"
import { ROUTES } from "../routes"
import { TestRowListContext } from "./types"

type Props = {
  readonly isUserAllowedToCreateTests: boolean
  context: TestRowListContext
}

export const EmptyStateNoTests: React.FC<Props> = ({
  isUserAllowedToCreateTests,
  context,
}) => {
  const navigate = useNavigate()

  // If we're on an individual project page, let's pass that through to the test builder as a query
  // param so that it can pre-select the project.
  const projectIdWithSlug = useMatch("/projects/:idWithSlug")?.params.idWithSlug
  const viewingProjectId = projectIdWithSlug
    ?.replaceAll(/[^0-9a-f]+/gi, "")
    .slice(-32)
  const createTestUrl = ROUTES.TESTS.NEW.buildPath(
    {},
    viewingProjectId ? { project_id: viewingProjectId } : {}
  )

  switch (context) {
    case "dashboard":
    case "search":
    case "project":
      return (
        <EmptyStateNoTestsWithCreateNewButton
          isUserAllowedToCreateTests={isUserAllowedToCreateTests}
          onClick={() => navigate(createTestUrl)}
        />
      )
    case "archived":
      return (
        <EmptyStateNoTestsContent
          headingContent="You have no archived studies"
          bodyContent="Studies that are archived will appear here for you to manage or delete permanently."
        />
      )
    default:
      assertNever(context)
  }
}

type EmptyStateNoTestsContentProps = {
  headingContent: React.ReactNode
  bodyContent: React.ReactNode
}

const EmptyStateNoTestsContent: React.FC<EmptyStateNoTestsContentProps> = ({
  headingContent,
  bodyContent,
}) => {
  return (
    <Flex align="center" flexDirection="column" gap={4} color="text.primary">
      <Image src={emptyAllSecondarySrc} maxWidth="104px" />
      <Flex flexDirection="column" gap={3}>
        <Heading textAlign="center" as="h2" textStyle="ds.display.primary">
          {headingContent}
        </Heading>
        <Box
          textStyle="ds.paragraph.primary"
          textAlign="center"
          maxWidth="33rem"
          mx={["2rem", null, "auto"]}
        >
          {bodyContent}
        </Box>
      </Flex>
    </Flex>
  )
}

const EmptyStateNoTestsWithCreateNewButton: React.FC<{
  isUserAllowedToCreateTests: boolean
  onClick: () => void
}> = ({ isUserAllowedToCreateTests, onClick }) => {
  return (
    <EmptyStateNoTestsContent
      headingContent="You have no studies"
      bodyContent={
        <Flex flexDirection="column" gap={8} alignItems="center">
          <Box>
            Looks like you haven{"\u2019"}t created a study yet.
            {isUserAllowedToCreateTests && (
              <>
                <br />
                You can create one by clicking the button below.
              </>
            )}
          </Box>

          {isUserAllowedToCreateTests && (
            <Button onClick={onClick} variant="primary">
              Create new study
            </Button>
          )}
        </Flex>
      }
    />
  )
}
