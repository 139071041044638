import {
  FigmaFileVersion,
  Screenshot,
  UsabilityTest,
  UsabilityTestSection,
} from "Types"
import { isTestLogicEnabled } from "Utilities/test-logic-statement"

import { validateCardSortCards } from "./validateCardSortCards"
import { validateFigmaFileFlow } from "./validateFigmaFileFlow"
import { validateQuestion } from "./validateQuestion"
import { validateQuestions } from "./validateQuestions"
import { validateScreenshot } from "./validateScreenshot"
import { validateScreenshots } from "./validateScreenshots"
import { validateTestLogicStatement } from "./validateTestLogicStatement"
import { validateTreeTest } from "./validateTreeTest"

export const validateSection = (
  section: UsabilityTestSection,
  test: UsabilityTest,
  screenshots: readonly Screenshot[],
  figmaFileVersions: readonly FigmaFileVersion[]
): Record<string, unknown> => ({
  questions: {
    _error: validateQuestions(section.questions, section),
    ...section.questions.map((question) =>
      validateQuestion(question, test, screenshots)
    ),
  },
  section_screenshots: {
    _error: validateScreenshots(section.section_screenshots, section),
    ...section.section_screenshots.map((screenshot) =>
      validateScreenshot(screenshot, section)
    ),
  },
  test_logic_statement: isTestLogicEnabled(section.test_logic_statement)
    ? validateTestLogicStatement(
        section.test_logic_statement,
        section._clientId,
        test,
        screenshots
      )
    : undefined,
  figma_file_flow: validateFigmaFileFlow(
    section.figma_file_flow,
    section,
    figmaFileVersions
  ),
  card_sort_attributes: {
    card_sort_cards_attributes: validateCardSortCards(
      section.card_sort_attributes
    ),
  },
  tree_test_attributes:
    section.type === "tree_test"
      ? validateTreeTest(
          section.tree_test_attributes || { nodes: [], correct_nodes: [] }
        )
      : undefined,
})
