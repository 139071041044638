import { createIcon } from "@chakra-ui/react"
import React from "react"

export const CursorClick01SolidIcon = createIcon({
  displayName: "CursorClick01SolidIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        d="M9 1C9.55229 1 10 1.44772 10 2V3.5C10 4.05228 9.55229 4.5 9 4.5C8.44772 4.5 8 4.05228 8 3.5V2C8 1.44772 8.44772 1 9 1Z"
        fill="currentColor"
      />
      <path
        d="M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L5.76777 4.35355C6.15829 4.74408 6.15829 5.37724 5.76777 5.76777C5.37724 6.15829 4.74408 6.15829 4.35355 5.76777L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z"
        fill="currentColor"
      />
      <path
        d="M5.76777 13.7071C6.15829 13.3166 6.15829 12.6834 5.76777 12.2929C5.37724 11.9024 4.74408 11.9024 4.35355 12.2929L3.29289 13.3536C2.90237 13.7441 2.90237 14.3772 3.29289 14.7678C3.68342 15.1583 4.31658 15.1583 4.70711 14.7678L5.76777 13.7071Z"
        fill="currentColor"
      />
      <path
        d="M14.7678 3.29289C15.1583 3.68342 15.1583 4.31658 14.7678 4.70711L13.7071 5.76777C13.3166 6.15829 12.6834 6.15829 12.2929 5.76777C11.9024 5.37724 11.9024 4.74408 12.2929 4.35355L13.3536 3.29289C13.7441 2.90237 14.3772 2.90237 14.7678 3.29289Z"
        fill="currentColor"
      />
      <path
        d="M2 8C1.44772 8 1 8.44772 1 9C1 9.55229 1.44772 10 2 10H3.5C4.05228 10 4.5 9.55229 4.5 9C4.5 8.44772 4.05228 8 3.5 8H2Z"
        fill="currentColor"
      />
      <path
        d="M9.70706 7.47947C9.50146 7.41608 9.28693 7.34995 9.10414 7.31146C8.92239 7.2732 8.59215 7.21844 8.23933 7.34791C7.82569 7.4997 7.49971 7.82568 7.34791 8.23932C7.21844 8.59214 7.2732 8.92239 7.31146 9.10413C7.34994 9.28692 7.41608 9.50146 7.47946 9.70706L10.9591 21.0031C11.0378 21.259 11.1155 21.5112 11.1961 21.7089C11.2685 21.8862 11.4259 22.2463 11.7866 22.4761C12.1902 22.7333 12.6923 22.7824 13.1382 22.6084C13.5365 22.4529 13.7608 22.1302 13.8662 21.9702C13.9836 21.7919 14.1087 21.5596 14.2356 21.3238L16.7165 16.7165L21.3238 14.2356C21.5596 14.1087 21.7919 13.9837 21.9702 13.8662C22.1301 13.7609 22.4528 13.5366 22.6083 13.1382C22.7824 12.6924 22.7333 12.1903 22.4761 11.7866C22.2462 11.426 21.8862 11.2685 21.7089 11.1962C21.5112 11.1155 21.259 11.0379 21.0031 10.9591L9.70706 7.47947Z"
        fill="currentColor"
      />
    </>
  ),
})
