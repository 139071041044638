import React, { ComponentType } from "react"
import FlipMove from "react-flip-move"

import { SortMethod } from "Types"

import styles from "./answer-counts.module.scss"
import { Props as RawCountItemProps } from "./count-item"
import { sortEntries } from "./helpers"

export interface CountItemProps<T>
  extends Pick<RawCountItemProps, "count" | "total"> {
  questionId: number
  option: T
}

interface Props<T> {
  countItemComponent: ComponentType<React.PropsWithChildren<CountItemProps<T>>>
  countByAnswer: Map<T, number>
  questionId: number
  responseCount: number
  sortMethod: SortMethod
  getKey: (option: T) => string
}

export function AnswerCounts<T>(props: Props<T>) {
  const {
    countByAnswer,
    countItemComponent: CountItemComponent,
    getKey,
    questionId,
    responseCount,
    sortMethod,
  } = props

  const sortedItems = sortEntries(
    Array.from(countByAnswer.entries()),
    sortMethod
  )

  const itemNodes = sortedItems.map(([option, count]) => (
    <li key={getKey(option)}>
      <CountItemComponent
        count={count}
        questionId={questionId}
        option={option}
        total={responseCount}
      />
    </li>
  ))

  return (
    <FlipMove className={styles.list} typeName="ol">
      {itemNodes}
    </FlipMove>
  )
}
