import { Button } from "DesignSystem/components"
import React from "react"
import { useCheckDynamicTemplateExists } from "~/api/generated/usabilityhub-components"
import { Template } from "~/usabilityhub/types/templateLibrary"

type PreviewButtonProps = {
  template: Template
}

export const PreviewButton: React.FC<PreviewButtonProps> = ({ template }) => {
  const { isLoading: loading, isError } = useCheckDynamicTemplateExists(
    {
      pathParams: { id: template.localTemplateId },
    },
    {
      retry: false,
    }
  )

  const available = !loading && !isError

  return available ? (
    <Button
      as="a"
      target="_blank"
      href={`/preview/${template.localTemplateId}`}
      size="emphasized"
    >
      Preview the test
    </Button>
  ) : null
}
