import { get } from "lodash"
import {
  arrayMove,
  arrayPush,
  arrayRemove,
  arraySplice,
  change,
} from "redux-form"

import { MAX_CARDS } from "Constants/test-section-types"
import { State } from "Redux/app-store"
import {
  getFormName,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { deleteCardImageFile } from "Services/card-sort-cards-image"
import { CardSortCard, CardSortCategory, ThunkAction } from "Types"

export const addCard =
  (sectionIndex: number, label?: string): ThunkAction<State> =>
  (dispatch, getState) => {
    const cardListPath = `sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes`
    const cardList = get(getFormValues(getState()), cardListPath) as string[]

    if (cardList.length >= MAX_CARDS) return

    dispatch(
      arrayPush(getFormName(), cardListPath, {
        label: label ?? "",
      })
    )
  }

export const editCard =
  (
    sectionIndex: number,
    cardIndex: number,
    label: string
  ): ThunkAction<State> =>
  (dispatch, getState) => {
    const cardListPath = `sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes`
    const card = get(
      getFormValues(getState()),
      `${cardListPath}.${cardIndex}`
    ) as CardSortCard | undefined

    dispatch(
      arraySplice(getFormName(), cardListPath, cardIndex, 1, {
        ...card,
        label,
      })
    )
  }

export const removeCardImage =
  (sectionIndex: number, cardIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    const cardListPath = `sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes`
    const card = get(
      getFormValues(getState()),
      `${cardListPath}.${cardIndex}`
    ) as CardSortCard | undefined

    if (card?.image_data) void deleteCardImageFile(card.image_data)

    dispatch(
      arraySplice(getFormName(), cardListPath, cardIndex, 1, {
        ...card,
        image_data: null,
        uploaded_image_url: null,
      })
    )
  }

export const deleteCard =
  (sectionIndex: number, cardIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    const key = `sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes`

    const card = get(getFormValues(getState()), `${key}[${cardIndex}]`) as
      | CardSortCard
      | undefined

    // Delete the temp card image if any
    if (card && "image_data" in card && card.image_data) {
      void deleteCardImageFile(card.image_data)
    }

    // If card is persisted, set _destroy to true.  Otherwise we can just remove it.
    if (card?.id) {
      dispatch(
        arraySplice(getFormName(), key, cardIndex, 1, {
          ...card,
          _destroy: true,
        })
      )
    } else {
      dispatch(arrayRemove(getFormName(), key, cardIndex))
    }
  }

export const moveCard =
  (cardPath: string, from: number, to: number): ThunkAction<State> =>
  (dispatch) => {
    dispatch(arrayMove(getFormName(), cardPath, from, to))
  }

export const addCategory =
  (sectionIndex: number, label?: string): ThunkAction<State> =>
  (dispatch, getState) => {
    const categoryListPath = `sections[${sectionIndex}].card_sort_attributes.card_sort_categories_attributes`
    const categoryList = get(
      getFormValues(getState()),
      categoryListPath
    ) as string[]

    if (categoryList.length >= MAX_CARDS) return

    dispatch(
      arrayPush(getFormName(), categoryListPath, {
        label: label ?? "",
      })
    )
  }

export const editCategory =
  (
    sectionIndex: number,
    categoryIndex: number,
    label: string
  ): ThunkAction<State> =>
  (dispatch) => {
    const categoryListPath = `sections[${sectionIndex}].card_sort_attributes.card_sort_categories_attributes`

    dispatch(
      arraySplice(getFormName(), categoryListPath, categoryIndex, 1, {
        label,
      })
    )
  }

export const deleteCategory =
  (sectionIndex: number, categoryIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    const key = `sections[${sectionIndex}].card_sort_attributes.card_sort_categories_attributes`

    const category = get(
      getFormValues(getState()),
      `${key}[${categoryIndex}]`
    ) as CardSortCategory | undefined

    // If category is persisted, set _destroy to true.  Otherwise we can just remove it.
    if (category?.id) {
      dispatch(
        arraySplice(getFormName(), key, categoryIndex, 1, {
          ...category,
          _destroy: true,
        })
      )
    } else {
      dispatch(arrayRemove(getFormName(), key, categoryIndex))
    }
  }

export const moveCategory =
  (cardPath: string, from: number, to: number): ThunkAction<State> =>
  (dispatch) => {
    dispatch(arrayMove(getFormName(), cardPath, from, to))
  }

export const deleteCardImages =
  (sectionIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    const cardListPath = `sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes`
    const cardList = get(
      getFormValues(getState()),
      cardListPath
    ) as CardSortCard[]

    const newCardList = cardList.map((option) => {
      // Delete the temp card image if any
      if ("image_data" in option && option.image_data) {
        void deleteCardImageFile(option.image_data)
      }

      return {
        ...option,
        image_data: null,
        uploaded_image_url: null,
      }
    })

    dispatch(change(getFormName(), cardListPath, newCardList))
  }

export const deleteCardDescriptions =
  (sectionIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    const cardListPath = `sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes`
    const cardList = get(
      getFormValues(getState()),
      cardListPath
    ) as CardSortCard[]

    const newCardList = cardList.map((option) => {
      // Delete card description if any
      return {
        ...option,
        description: null,
      }
    })

    dispatch(change(getFormName(), cardListPath, newCardList))
  }
