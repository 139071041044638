import { createIcon } from "@chakra-ui/react"
import React from "react"

export const InterviewSettingsIcon = createIcon({
  displayName: "InterviewSettingsIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M2 6a1 1 0 0 1 1-1h17a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h17a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Z"
      fill="currentColor"
    />
  ),
})
