import { createIcon } from "@chakra-ui/icon"
import React from "react"

export const MinimizeIcon = createIcon({
  displayName: "MinimizeIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        fill="currentColor"
        d="M13.0759 10.3828C13.1243 10.4999 13.196 10.6096 13.2908 10.705L13.295 10.7092C13.4758 10.8889 13.7251 11 14.0002 11H19C19.5523 11 20 10.5523 20 10C20 9.44772 19.5523 9 19 9H16.4142L20.7071 4.70711C21.0976 4.31658 21.0976 3.68342 20.7071 3.29289C20.3166 2.90237 19.6834 2.90237 19.2929 3.29289L15 7.58579L15 5C15 4.44772 14.5523 4 14 4C13.4477 4 13 4.44772 13 5V10L13 10.003C13.0004 10.1375 13.0273 10.2657 13.0759 10.3828Z"
      />
      <path
        fill="currentColor"
        d="M10.9241 13.6172C10.8757 13.5001 10.804 13.3904 10.7092 13.295L10.705 13.2908C10.5242 13.1111 10.2749 13 9.99983 13H5C4.44772 13 4 13.4477 4 14C4 14.5523 4.44772 15 5 15H7.58579L3.29289 19.2929C2.90237 19.6834 2.90237 20.3166 3.29289 20.7071C3.68342 21.0976 4.31658 21.0976 4.70711 20.7071L9 16.4142V19C9 19.5523 9.44772 20 10 20C10.5523 20 11 19.5523 11 19V14L11 13.997C10.9996 13.8625 10.9727 13.7343 10.9241 13.6172Z"
      />
    </>
  ),
})
