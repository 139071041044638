import { Box, Flex, List, ListItem, Tooltip } from "@chakra-ui/react"
import { Tag } from "DesignSystem/components"
import { TagColorScheme } from "DesignSystem/components/Tag"
import { useFlatLocations } from "UsabilityHub/hooks/useFlatLocations"
import { TargetLocation } from "UsabilityHub/views/NewOrderPage/types"
import { groupBy } from "lodash"
import React from "react"

type Props = {
  scope: "usability_tests" | "interviews"
  targetLocations: TargetLocation[]
  // If you don't provide a function for removal no close button will be rendered
  handleRemoveLocations?: (locations: TargetLocation[]) => void
  colorScheme?: TagColorScheme
}

export const GranularLocationTags: React.FC<Props> = ({
  scope,
  targetLocations,
  handleRemoveLocations,
  colorScheme,
}) => {
  const locationData = useFlatLocations(scope)

  // If location data hasn't loaded yet, don't render anything
  if (Object.keys(locationData.country).length === 0) return null

  if (targetLocations.length === 0) return null

  const groupedLocations = groupLocations(targetLocations, locationData)

  return (
    <Flex flexWrap="wrap" gap={2}>
      {groupedLocations.map((locationGroup) => {
        return (
          <Tooltip
            hasArrow
            isDisabled={locationGroup.locations.length === 1}
            placement="top"
            key={locationGroup.label}
            label={
              <List>
                {locationGroup.locations.map((l) => (
                  <ListItem key={l.qualifiedName}>{l.qualifiedName}</ListItem>
                ))}
              </List>
            }
          >
            {/* TODO this is necessary to get the tooltip working, even though
            the DS Tag uses forwardRef. This should be removed once the tooltip
            works without it */}
            <Box display="inline-flex">
              <Tag
                colorScheme={colorScheme}
                onClose={
                  handleRemoveLocations
                    ? () => handleRemoveLocations(locationGroup.locations)
                    : undefined
                }
                label={locationGroup.label}
              />
            </Box>
          </Tooltip>
        )
      })}
    </Flex>
  )
}

// We group all target locations together so we only have one tag per country.
// That might be a single country, single state, or single city. Or it might be
// a grouping of states/cities within a country, if there are multiples.
//
// Returns an array of { label, locations } objects.
const groupLocations = (
  targetLocations: TargetLocation[],
  locationData: ReturnType<typeof useFlatLocations>
) => {
  const fullLocations = targetLocations.map((targetLocation) => {
    const location = locationData[targetLocation.type][targetLocation.id]

    // If for some reason we can't find the location ID, return a placeholder
    if (!location) {
      return {
        targetLocation,
        qualifiedName: `Unknown ${targetLocation.type} (${targetLocation.id})`,
      }
    }

    return {
      ...location,
      targetLocation,
    }
  })

  const groupedLocations = groupBy(fullLocations, "countryCode")

  const finalGroups = Object.entries(groupedLocations).map(
    ([countryCode, locations]) => {
      if (locations.length === 1) {
        return {
          label: locations[0].qualifiedName,
          locations: [
            {
              ...locations[0].targetLocation,
              qualifiedName: locations[0].qualifiedName,
            },
          ],
        }
      } else {
        const countryName =
          locationData.country[countryCode]?.qualifiedName ?? "Unknown country"
        const sortedLocations = locations.sort((a, b) =>
          a.qualifiedName.localeCompare(b.qualifiedName)
        )

        return {
          label: `${countryName} (${locations.length} locations)`,
          locations: sortedLocations.map((l) => ({
            ...l.targetLocation,
            // Remove the country name from the end of the location name since we're in
            // the context of a specific country
            qualifiedName: l.qualifiedName.replace(/, \w+$/, ""),
          })),
        }
      }
    }
  )

  return finalGroups
}
