import { Alert, AlertDescription, Link } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { AlertCircleOutlineIcon } from "Shared/icons/untitled-ui/AlertCircleOutlineIcon"
import { PlanDrawerTrigger } from "UsabilityHub/components/PlanChanger/plan-drawer"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { fullWithoutTime } from "Utilities/date-formats"
import React from "react"
import { useGetModeratedStudyQuota } from "~/api/generated/usabilityhub-components"

const TRANSCRIPTION_FEATURE = {
  feature: "interview_transcription_hours",
} as const

// For now this banner only reflects the transcription quota, but in future it will do storage as well.
export const StorageOrTranscriptionExhaustedBanner: React.FC = () => {
  const currentAccount = useCurrentAccount()
  const isFreePlan =
    currentAccount?.plan_unique_id === Constants.FREE_PLAN_UNIQUE_ID
  const { data } = useGetModeratedStudyQuota({})

  if (!data) return null
  if (data.transcription_hours.used < data.transcription_hours.quota)
    return null

  return (
    <Alert bg="purple.50" justifyItems="center">
      {/* The status="info" icon is a fixed-color PNG so we have to override it */}

      <AlertDescription fontWeight="normal">
        <AlertCircleOutlineIcon
          boxSize="14px"
          color="purple.700"
          mb={0.5}
          me={0.5}
        />
        {isFreePlan
          ? "You've reached your transcription limit."
          : `
              You've reached your monthly transcription limit. Resets on ${fullWithoutTime(
                data.period_end
              )}.`}{" "}
        <PlanDrawerTrigger
          as={Link}
          variant="unstyled"
          verticalAlign="inherit"
          userSelect="initial"
          fontSize="inherit"
          color="purple.700"
          textDecoration="none"
          requestedFeatures={[TRANSCRIPTION_FEATURE]}
          source="test_recruitment_settings"
        >
          Upgrade plan
        </PlanDrawerTrigger>
      </AlertDescription>
    </Alert>
  )
}
