import { useToast } from "@chakra-ui/react"
import React, { Dispatch, SetStateAction } from "react"
import {
  useAddPanelResponsesComment,
  useRatePanelResponses,
} from "~/api/generated/usabilityhub-components"
import { ratingFormReducer } from "./ratingFormReducer"
import { Rating, RatingFormState } from "./types"

const DEFAULT_INITIAL_STATE: RatingFormState = {
  stage: "default",
  rating: null,
}

export const useRatingFormState = ({
  ratingId,
  lastRating,
  setLastRating,
  usabilityTestUniqueId,
}: {
  ratingId: string
  lastRating: Rating | null
  setLastRating: Dispatch<SetStateAction<Rating | null>>
  usabilityTestUniqueId: string
}) => {
  const initialState: RatingFormState = {
    ...DEFAULT_INITIAL_STATE,
    ...(lastRating && { rating: lastRating }),
  }
  const [state, dispatch] = React.useReducer(ratingFormReducer, initialState)

  const toast = useToast()

  const { mutate: ratePanelResponses } = useRatePanelResponses({
    onSuccess: () => {
      setLastRating(state.rating)
    },
    onError: () => {
      toast({
        description: "We couldn\u2019t save your rating. Please try again.",
        status: "error",
      })
    },
  })

  const { mutate: addPanelResponsesComment } = useAddPanelResponsesComment({
    onError: () => {
      toast({
        description: "We couldn\u2019t save your feedback. Please try again.",
        status: "error",
      })
    },
  })

  const submitRating = (rating: Rating) => {
    ratePanelResponses({
      pathParams: { usabilityTestUniqueId },
      body: { id: ratingId, rating },
    })
    dispatch({ type: "RATE", payload: { rating } })
  }

  const submitFeedback = (comment: string) => {
    addPanelResponsesComment({
      pathParams: { usabilityTestUniqueId },
      body: { id: ratingId, comment },
    })
    dispatch({ type: "SUBMIT_FEEDBACK" })
  }

  const skipFeedback = () => {
    dispatch({ type: "SKIP_FEEDBACK" })
  }

  const { stage, rating } = state

  return {
    submitRating,
    submitFeedback,
    skipFeedback,
    stage,
    rating,
  }
}
