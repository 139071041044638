import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react"
import { capitalize, lowerCase, sortBy } from "lodash"
import React, { useMemo } from "react"
import { useTemplateLibrary } from "~/usabilityhub/hooks/useTemplateLibrary"
import { Category } from "~/usabilityhub/types/templateLibrary"
import { FilterButton } from "./FilterButton"

type FilterListProps = {
  category: Category["type"]
}

const FIELDS = {
  useCase: "useCases",
  methodology: "methodologies",
  team: "teams",
} as const

export const FilterList: React.FC<FilterListProps> = ({ category }) => {
  const {
    categories: { [FIELDS[category]]: categories },
  } = useTemplateLibrary()

  const items = useMemo(
    () => sortBy([...(categories?.values() || [])], "name").filter(Boolean),
    [categories]
  )

  return (
    <AccordionItem
      border={0}
      mx={-2}
      mb={1}
      px={2}
      sx={{
        '&:hover,&:has([aria-expanded="true"])': {
          boxShadow: "inset 0 0 0 1px var(--chakra-colors-gray-200)",
        },
      }}
      rounded="md"
    >
      <AccordionButton
        px={3}
        py={4}
        borderRadius="base"
        _hover={{ bgColor: "transparent" }}
      >
        <Text
          flex={1}
          fontWeight="semibold"
          color="text.secondary"
          textAlign="left"
        >
          {capitalize(lowerCase(category))}
        </Text>
        <AccordionIcon color="gray.400" boxSize={6} />
      </AccordionButton>
      <AccordionPanel p={0}>
        <List
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          gap={1}
          pb={4}
        >
          {items.map((c) => (
            <ListItem as={FilterButton} key={c.id} category={c} />
          ))}
        </List>
      </AccordionPanel>
    </AccordionItem>
  )
}
