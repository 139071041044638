import { State } from "Redux/app-store"
import { LiveWebsiteTestTask, ThunkAction } from "Types"
import { get } from "lodash"
import { arrayPush, arrayRemove, arraySplice } from "redux-form"
import { v4 as uuidv4 } from "uuid"
import { getFormName, getFormValues } from "../selectors/formValues"

export const MAX_TASKS = 10

export const createBlankTask = (): LiveWebsiteTestTask => ({
  id: uuidv4(),
  url: "",
  instructions: "",
  estimated_duration_in_minutes: 2,
  live_website_test_task_questions: [],
  _unpersisted: true,
})

export const addTask =
  (sectionIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    const taskPath = `sections[${sectionIndex}].live_website_test.live_website_test_tasks`
    const tasks = get(getFormValues(getState()), taskPath) as string[]

    if (tasks.length >= MAX_TASKS) return

    dispatch(arrayPush(getFormName(), taskPath, createBlankTask()))
  }

export const deleteTask =
  (sectionIndex: number, taskIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    const taskPath = `sections[${sectionIndex}].live_website_test.live_website_test_tasks`

    const task = get(getFormValues(getState()), `${taskPath}[${taskIndex}]`) as
      | LiveWebsiteTestTask
      | undefined

    // If task is unpersisted, just remove it on the frontend.
    // Otherwise set _destroy to true so the backend can delete it too.
    if (task?._unpersisted) {
      dispatch(arrayRemove(getFormName(), taskPath, taskIndex))
    } else {
      dispatch(
        arraySplice(getFormName(), taskPath, taskIndex, 1, {
          ...task,
          _destroy: true,
        })
      )
    }
  }
