import { createIcon } from "@chakra-ui/react"
import React from "react"

export const GoogleCalendarIcon = createIcon({
  displayName: "GoogleCalendarIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="#FFFFFF"
        d="M17.8658 5.2342 l-5.6842 -0.6316 l-6.9474 0.6316 L4.6026 11.55 l0.6316 6.3158 l6.3158 0.7895 l6.3158 -0.7895 l0.6316 -6.4736 L17.8658 5.2342 z"
      />
      <path
        fill="#1A73E8"
        d="M7.8253 15.0331 c-0.4721 -0.319 -0.799 -0.7847 -0.9774 -1.4005 l1.0958 -0.4516 c0.0995 0.379 0.2731 0.6726 0.521 0.881 c0.2464 0.2084 0.5464 0.311 0.8969 0.311 c0.3584 0 0.6664 -0.109 0.9236 -0.3269 s 0.3869 -0.4958 0.3869 -0.8321 c0 -0.3442 -0.1358 -0.6253 -0.4074 -0.8431 s -0.6126 -0.3269 -1.02 -0.3269 h-0.6331 v-1.0847 H9.18 c0.3505 0 0.6458 -0.0947 0.8858 -0.2842 c0.24 -0.1895 0.36 -0.4484 0.36 -0.7784 c0 -0.2936 -0.1074 -0.5274 -0.3221 -0.7026 s -0.4864 -0.2636 -0.8164 -0.2636 c-0.3221 0 -0.5779 0.0853 -0.7674 0.2574 s -0.3269 0.3836 -0.4136 0.6331 l-1.0847 -0.4516 c0.1436 -0.4074 0.4074 -0.7674 0.7942 -1.0784 c0.3869 -0.311 0.881 -0.4674 1.481 -0.4674 c0.4436 0 0.8431 0.0853 1.1969 0.2574 c0.3536 0.1721 0.6316 0.4105 0.8321 0.7136 c0.2005 0.3047 0.3 0.6458 0.3 1.0247 c0 0.3869 -0.0931 0.7136 -0.2795 0.9821 c-0.1864 0.2684 -0.4153 0.4736 -0.6869 0.6174 v0.0647 c0.3584 0.15 0.6505 0.379 0.881 0.6869 c0.229 0.3079 0.3442 0.6758 0.3442 1.1053 s -0.109 0.8131 -0.3269 1.1495 c-0.2179 0.3364 -0.5195 0.6016 -0.9016 0.7942 c-0.3836 0.1926 -0.8147 0.2905 -1.2931 0.2905 C8.809 15.5116 8.2974 15.3521 7.8253 15.0331 z"
      />
      <path
        fill="#1A73E8"
        d="M14.55 9.5953 l-1.1969 0.87 l-0.6016 -0.9126 l2.1584 -1.5569 h0.8274 v7.3436 h-1.1874 L14.55 9.5953 z"
      />
      <path
        fill="#EA4335"
        d="M17.8658 23.55 l5.6842 -5.6842 l-2.8421 -1.2631 l-2.8421 1.2631 l-1.2631 2.8421 L17.8658 23.55 z"
      />
      <path
        fill="#34A853"
        d="M3.971 20.7079 l1.2631 2.8421 h12.6316 v-5.6842 H5.2342 L3.971 20.7079 z"
      />
      <path
        fill="#4285F4"
        d="M1.4447 -0.45 C0.3979 -0.45 -0.45 0.3979 -0.45 1.4447 v16.421 l2.8421 1.2631 l2.8421 -1.2631 V5.2342 h12.6316 l1.2631 -2.8421 L17.8658 -0.45 H1.4447 z"
      />
      <path
        fill="#188038"
        d="M-0.45 17.8658 v3.7895 c0 1.0469 0.8479 1.8947 1.8947 1.8947 h3.7895 v-5.6842 H-0.45 z"
      />
      <path
        fill="#FBBC04"
        d="M17.8658 5.2342 v12.6316 h5.6842 V5.2342 l-2.8421 -1.2631 L17.8658 5.2342 z"
      />
      <path
        fill="#1967D2"
        d="M23.55 5.2342 V1.4447 c0 -1.0469 -0.8479 -1.8947 -1.8947 -1.8947 h-3.7895 v5.6842 H23.55 z"
      />
    </g>
  ),
})
