import { Box, Grid, HStack, Stack, Text } from "@chakra-ui/react"
import { RecordingsSimplePreview } from "Components/test-results/section-results/SectionResultsCards/Recordings/RecordingsSimplePreview"
import { State } from "Redux/app-store"
import { ClockSolidIcon } from "Shared/icons/untitled-ui/ClockSolidIcon"
import { TreeNode } from "Types"
import { floorMsToS } from "Utilities/time"
import { keyBy, last } from "lodash"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { TaskProps } from "../task-props"
import { Badge } from "./Badge"
import { Path } from "./Path"

const EXPLANATIONS = {
  direct_success:
    "Participant went directly down one path to the correct answer.",
  indirect_success:
    "Participant went down one or more incorrect paths before reaching the correct answer.",
  direct_failure:
    "Participant went directly down one path to an incorrect answer.",
  indirect_failure:
    "Participant went down one or more incorrect paths before reaching an incorrect answer.",
  direct_skipped:
    "Participant clicked the “Pass” button without selecting any path.",
  indirect_skipped:
    "Participant tried one or more paths before clicking the “Pass” button.",
}

export const TreeTestSectionIndividualResults = ({
  responseSection,
  usabilityTestSection,
}: TaskProps) => {
  const { path, result, direct } = getTreeTestResponseStats({
    responseSection,
    usabilityTestSection,
  })

  return (
    <Stack
      border="1px solid"
      borderColor="border.default"
      rounded="base"
      p={4}
      gap={4}
    >
      {responseSection && (
        <RecordingsSimplePreview responseSection={responseSection} />
      )}
      <Box border="1px solid" borderColor="border.default" rounded="base" p={4}>
        <Path path={path} />
      </Box>
      <Grid
        gridTemplateColumns="1fr auto"
        columnGap={6}
        rowGap={3}
        alignItems="start"
      >
        <Box gridColumn="1 / -1">
          <Badge result={result} directness={direct ? "direct" : "indirect"} />
        </Box>
        <Text fontSize="sm" color="text.subtlest">
          {
            EXPLANATIONS[
              [direct ? "direct" : "indirect", result].join(
                "_"
              ) as keyof typeof EXPLANATIONS
            ]
          }
        </Text>
        <HStack alignItems="center" fontSize="sm" color="text.subtlest" gap={1}>
          <ClockSolidIcon />
          {!!responseSection?.task_duration_ms && (
            <Text>
              Time taken: {floorMsToS(responseSection.task_duration_ms)}s
            </Text>
          )}
        </HStack>
      </Grid>
    </Stack>
  )
}

type TreeTestStatsProps = {
  path: (TreeNode | null)[]
  result: "success" | "failure" | "skipped"
  direct: boolean | null
}

export const getTreeTestResponseStats = ({
  responseSection,
  usabilityTestSection,
}: TaskProps): TreeTestStatsProps => {
  const pathNodeIds: readonly (string | null)[] = useSelector(
    (state: State) =>
      (responseSection &&
        state.testResults?.responseSectionTreeTestPaths.find(
          (path) => path.response_section_id === responseSection.id
        )?.path) ||
      []
  )

  const nodesById: Record<string, TreeNode> = useMemo(
    () => keyBy(usabilityTestSection.tree_test_attributes?.nodes || [], "id"),
    [usabilityTestSection]
  )

  const path = useMemo(
    () =>
      (pathNodeIds || [])?.map(
        (id) => (id && nodesById[id]) ?? null
      ) as (TreeNode | null)[],
    [nodesById, pathNodeIds]
  )

  const lastNode = last(path) ?? null

  const skipped = !lastNode

  const clickedNodes = path.filter(Boolean)

  const lastClickedNode = last(clickedNodes) ?? null

  const direct =
    (skipped && (!path.length || (path.length === 1 && !path[0]))) ||
    (!skipped && lastClickedNode && lastClickedNode.depth === path.length - 1)

  const result = skipped
    ? "skipped"
    : lastClickedNode &&
        usabilityTestSection.tree_test_attributes?.correct_nodes?.includes(
          lastClickedNode.id
        )
      ? "success"
      : "failure"

  return { path, result, direct }
}
