import React from "react"

import { QuestionResultsList } from "Components/test-results/question-results/question-results-list"

import { SectionResultsCard } from "./SectionResultsCard"

export const QuestionsSectionResultsCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <SectionResultsCard>
      <QuestionResultsList />
    </SectionResultsCard>
  )
}
