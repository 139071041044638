import {
  useMaybeActiveStripeSubscription,
  useMaybeCurrentAccount,
  useMaybeCurrentPlan,
  useMaybeCurrentUser,
  useMaybeManualSubscription,
} from "./useCurrentAccount"

const useIsAccountInGoodStanding = () => {
  const activeManualSubscription = useMaybeManualSubscription("active")
  const activeStripeSubscription = useMaybeActiveStripeSubscription()
  const currentAccount = useMaybeCurrentAccount()
  const currentPlan = useMaybeCurrentPlan()

  // Hooks can't be wrapped in conditions so we have to check here if the user
  // is "logged in" and if not, we assume the account is in good standing
  if (!currentAccount || !currentPlan) {
    return true
  }
  if (currentPlan.unique_id === "free") {
    return true
  }
  if (currentAccount.manual_subscription) {
    return !!activeManualSubscription
  } else {
    return (
      activeStripeSubscription &&
      activeStripeSubscription.stripe_status !== "unpaid"
    )
  }
}

const useIsUserInReadOnlyMode = () => {
  const currentAccount = useMaybeCurrentAccount()
  const currentUser = useMaybeCurrentUser()

  // Hooks can't be wrapped in conditions so we have to check here if the user
  // is "logged in" and if not, we assume the account is not in read only mode
  if (!currentAccount || !currentUser) {
    return false
  }
  return (
    !currentAccount.has_multiple_users_feature &&
    currentUser.id !== currentAccount.owner_id
  )
}

export const useShowAccountNotInGoodStandingBanner = () => {
  const currentUser = useMaybeCurrentUser()
  const isAccountInGoodStanding = useIsAccountInGoodStanding()
  const isUserInReadOnlyMode = useIsUserInReadOnlyMode()

  if (!currentUser) {
    return false
  }
  return (
    (!isAccountInGoodStanding || isUserInReadOnlyMode) &&
    (currentUser.role === "admin" || currentUser.role === "member")
  )
}
