import classNames from "classnames"
import { times } from "lodash"
import React from "react"

import styles from "Components/preference-test-layout/carousel-indicator.module.scss"

interface Props {
  count: number
  current: number
}

const CarouselIndicator: React.FC<React.PropsWithChildren<Props>> = ({
  count,
  current,
}) => {
  return (
    <ol className={styles.container}>
      {times(count, (index) => (
        <li
          key={index}
          className={classNames(styles.item, {
            [styles.current]: current === index,
          })}
        />
      ))}
    </ol>
  )
}

export default CarouselIndicator
